import { useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Tooltip,
} from 'recharts';
import { Chart, ChartProps, currencyFormatter } from '../common';
import { useHistory } from 'react-router-dom';
import { globalMode } from 'utility/cache/ui';
import { useEffect } from 'react';
import { loader } from 'graphql.macro';
import { DepartmentsChartData } from './__generated__/DepartmentsChartData';

const query = loader('./DepartmentsChartData.graphql');
const Component: React.FC<ChartProps<DepartmentsChartData>> = ({
  data,
  height,
  width,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [animation, setAnimation] = useState(true);

  const maxDepartmentAmount = data?.kpiDepartments?.nodes
    ? Math.max(...data.kpiDepartments.nodes.map((dept) => dept.amount || 0))
    : 0;

  const dataWithFullMark = data?.kpiDepartments?.nodes.map((dept) => ({
    ...dept,
    fullMark: maxDepartmentAmount,
  }));

  // HACK to set labels to correct color after theme switch (because their values are set
  //  directly on the svg tspan element while doing mouse enters/leaves and do not update
  //  automatically when we change themes)
  useEffect(() => {
    Array.from(
      document.getElementsByClassName('recharts-polar-angle-axis-tick-value')
    ).forEach((tick) => {
      tick.firstElementChild?.setAttribute(
        'fill',
        theme.palette.neutralPrimary
      );
    });
  }, [data, theme.palette.neutralPrimary]);

  return (
    <RadarChart
      height={height}
      width={width}
      cx="50%"
      cy="50%"
      outerRadius="80%"
      data={dataWithFullMark}
    >
      <PolarGrid stroke={theme.palette.neutralQuaternary} />
      <PolarAngleAxis
        dataKey="title"
        tick={{
          cursor: 'pointer',
        }}
        onMouseEnter={(_: any, index: number, event) => {
          (event.target as SVGTSpanElement).setAttribute(
            'fill',
            theme.palette.themePrimary
          );
          setActiveIndex(index);
        }}
        onMouseLeave={(_: any, index: number, event) => {
          (event.target as SVGTSpanElement).setAttribute(
            'fill',
            theme.palette.neutralPrimary
          );
          if (activeIndex === index) setActiveIndex(undefined);
        }}
        onClick={() => {
          if (dataWithFullMark && activeIndex !== undefined) {
            globalMode(true);
            history.push(
              `signing?t=${dataWithFullMark[activeIndex].linkSearchValue}`
            );
          }
        }}
      />
      <PolarRadiusAxis />
      <Radar
        dataKey="amount"
        stroke={theme.palette.magenta}
        fill={theme.palette.magenta}
        fillOpacity={0.6}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      />
      <Tooltip
        formatter={(value: number) => [currencyFormatter.format(value), '']}
        separator=""
        contentStyle={{
          backgroundColor: theme.palette.white,
          borderColor: theme.palette.neutralLighter,
        }}
        itemStyle={{
          color: theme.palette.neutralPrimary,
        }}
        isAnimationActive={false}
        labelStyle={{ fontWeight: 600 }}
      />
    </RadarChart>
  );
};

export const DepartmentsChart: Chart<DepartmentsChartData> = {
  Component,
  query,
  hasData: (data) => !!data.kpiDepartments?.nodes.length,
};
