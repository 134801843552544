import { useApolloClient } from '@apollo/client';
import {
  ContextualMenu,
  IDragOptions,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  Separator,
  Stack,
} from '@fluentui/react';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { UploadStatusType } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { SectionProps } from '..';
import { Trip_trip } from '../__generated__/Trip';
import { useStyles } from '../index.styles';
import { ActionMenu } from './ActionMenu';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { Header } from './Header';
import { MiniTrips } from './Sections';
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

interface FormViewProps {
  onSave: (closeAfterComplete: boolean, addMore: boolean) => void;
  tripItemsState: Map<string, SectionProps>;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
  layoutAdded: string | null;
  onTripItemsUpdate: (
    mapData: Map<string, SectionProps>,
    layoutAdded: string,
    adding: boolean
  ) => void;
  commonData: TravelerAuthorizationCommonData | undefined;
  tripData: Trip_trip | null | undefined;
  loading: boolean;
  createTripLoading: boolean;
  updateTripLoading: boolean;
  loadingTrip: boolean;
  refetch: () => void;
  inputsDisabled: boolean;
  businessUnitId: string | null;
  isOpen: boolean;
}

export const FormView: React.FC<FormViewProps> = ({
  onSave,
  tripItemsState,
  onTripItemsUpdate,
  commonData,
  layoutAdded,
  tripData,
  loading,
  travelAuthorizationData,
  createTripLoading,
  updateTripLoading,
  loadingTrip,
  refetch,
  inputsDisabled,
  businessUnitId,
  isOpen,
}) => {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const { updateToast } = useToasts();
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <ModalWrapper
      isOpen={isOpen}
      isBlocking
      focusTrapZoneProps={{
        style: {
          overflowY: 'hidden',
        },
      }}
      dragOptions={dragOptions}
    >
      <Header
        tripData={tripData}
        onIsLoading={setIsLoading}
        inputsDisabled={inputsDisabled}
      />

      <Stack className={styles.container}>
        {/* HEADER */}

        {/* CORE MIDDLE COMPONENTS */}
        {!loadingTrip && (
          <>
            <Stack className={styles.formContainer}>
              {tripData?.id &&
                tripData &&
                !!tripData?._requiredApprovalDocuments && (
                  <MessageBar messageBarType={MessageBarType.error}>
                    {tripData?._requiredApprovalDocuments}
                  </MessageBar>
                )}
              {tripData?.id && tripData && !!tripData?._stopsValidation && (
                <MessageBar messageBarType={MessageBarType.error}>
                  {tripData?._stopsValidation}
                </MessageBar>
              )}
              <ActionMenu
                inputsDisabled={inputsDisabled}
                onTripItemsUpdate={onTripItemsUpdate}
                travelAuthorizationData={travelAuthorizationData}
                tripItemsState={tripItemsState}
                commonData={commonData}
                onRefetch={refetch}
                tripData={tripData}
                onUpload={async (fileSelected, document, toastId) => {
                  const observer = client.subscribe({
                    query: DOCUMENT_UPLOAD_STATUS,
                    variables: {
                      documentId: document.document._documentFileId!,
                    },
                  });
                  const subscription = observer.subscribe((response) => {
                    const subscribedData =
                      response.data as OnDocumentUploadStatus;

                    const { status, document } = {
                      ...subscribedData.documentUploadStatus,
                    };

                    if (!document) {
                      if (status.type === UploadStatusType.VALIDATING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Validating files ${fileSelected.name} - ${status.message}`
                            : `Validating files ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.EXTRACTING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Extracting data from ${fileSelected.name} - ${status.message}`
                            : `Extracting data from ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.FAILURE) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Upload of ${fileSelected.name} failed - ${status.message}`
                            : `Upload of ${fileSelected.name} failed`,
                          appearance: 'error',
                          autoDismiss: true,
                        });
                      } else if (status.type === UploadStatusType.WARNING) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Warning for file ${fileSelected.name}: ${status.message}`
                            : `Warning for file ${fileSelected.name}`,
                          appearance: 'warning',
                          autoDismiss: true,
                        });
                      }
                    } else {
                      subscription.unsubscribe();
                      updateToast(toastId!, {
                        content: status.message
                          ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                          : `Successfully uploaded ${fileSelected.name}`,
                        appearance: 'success',
                        autoDismiss: true,
                      });
                      refetch?.();
                    }
                  });
                }}
              />
              <Separator />
              <BasicForm tripData={tripData} inputsDisabled={inputsDisabled} />
              <MiniTrips
                businessUnitId={businessUnitId}
                layoutAdded={layoutAdded}
                tripItemsState={tripItemsState}
                onTripItemsUpdate={onTripItemsUpdate}
                commonData={commonData}
                tripData={tripData}
                travelAuthorizationData={travelAuthorizationData}
                inputsDisabled={inputsDisabled}
              />
            </Stack>
            {/* FOOTER */}
            <Footer
              loading={isLoading || createTripLoading || updateTripLoading}
              onSave={onSave}
            />
          </>
        )}

        {loadingTrip && (
          <Stack
            className={styles.loadingFill}
            horizontalAlign="center"
            verticalAlign="center"
          >
            <Stack className={styles.loaderContainer}>
              <ProgressIndicator label={'Loading trip'} />
            </Stack>
          </Stack>
        )}
      </Stack>
    </ModalWrapper>
  );
};
