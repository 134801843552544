import React, { useState } from 'react';
import { ActionButton, Stack, TooltipHost } from '@fluentui/react';

import { Supplier_supplier } from '../../__generated__/Supplier';
import { AliasModal } from './AliasModal';
import { SupplierCreate_supplierCreate_supplier } from '../../__generated__/SupplierCreate';

type SupplierType = Pick<
  Supplier_supplier,
  'id' | '_isAliasOriginalNameViewable' | '_isAliasInvoked' | '_isUpdatable'
>;
interface SupplierAliasProps {
  supplier: SupplierType | null | undefined;
  isPurchaseOrderMutation?: boolean | null;
  isProtected?: boolean;
  onAliasUpdate?: (param: SupplierCreate_supplierCreate_supplier) => void;
}

export const SupplierAlias: React.FC<SupplierAliasProps> = ({
  supplier,
  isPurchaseOrderMutation,
  isProtected,
  onAliasUpdate,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { _isAliasOriginalNameViewable, _isAliasInvoked, _isUpdatable, id } = {
    ...supplier,
  };
  const isAliasDisabled = !_isAliasOriginalNameViewable;
  const showAddButton =
    !!_isUpdatable &&
    !isProtected &&
    !!_isAliasOriginalNameViewable &&
    !_isAliasInvoked;
  return (
    <Stack>
      <TooltipHost
        content="Alias"
        tooltipProps={{ styles: { root: { marginBottom: -10 } } }}
      >
        {!!_isAliasInvoked && (
          <ActionButton
            onClick={() => setIsVisible(true)}
            iconProps={{
              iconName: 'Incognito',
            }}
            disabled={isAliasDisabled}
          />
        )}
        {showAddButton && (
          <ActionButton
            onClick={() => setIsVisible(true)}
            iconProps={{
              iconName: 'Add',
            }}
            text="Alias"
            disabled={isAliasDisabled}
          />
        )}
      </TooltipHost>
      {isVisible && (
        <AliasModal
          id={id}
          onClose={() => setIsVisible(false)}
          isPurchaseOrderMutation={isPurchaseOrderMutation}
          onAliasUpdate={onAliasUpdate}
          isProtected={isProtected}
        />
      )}
    </Stack>
  );
};
