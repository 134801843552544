import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isCollapsible: true,
  toggleVisibility: true,
  isVisible: true,
};
export const documentColumns: ColumnData[] = [
  {
    key: 'documentType',
    name: 'Type',
    fieldName: 'documentType',
    minWidth: 120,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'indexName',
    name: 'Name',
    fieldName: 'indexName',
    minWidth: 200,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'indexReferenceNumber',
    name: 'Number',
    fieldName: 'indexReferenceNumber',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: '_documentContents',
    name: 'Contents',
    fieldName: '_documentContents',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: 'indexAmount',
    name: 'Total',
    fieldName: 'indexAmount',
    minWidth: 70,
    maxWidth: 70,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: 'usedTotal',
    name: 'Used',
    fieldName: 'usedTotal',
    minWidth: 70,
    maxWidth: 70,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: 'remainingTotal',
    name: 'Remaining',
    fieldName: 'remainingTotal',
    minWidth: 70,
    maxWidth: 70,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: 'isoCode',
    name: 'Currency',
    fieldName: 'isoCode',
    minWidth: 70,
    maxWidth: 70,
    ...commonColumnProps,
  },
  {
    key: 'comment',
    name: 'Comments',
    fieldName: 'comment',
    minWidth: 100,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'view',
    name: '',
    fieldName: 'view',
    minWidth: 20,
    maxWidth: 20,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
    isResizable: false,
  },
  

  {
    key: 'fileReference',
    name: 'Documents',
    fieldName: 'fileReference',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: '_uploadDate',
    name: 'Uploaded',
    fieldName: '_uploadDate',
    minWidth: 160,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: '_sharedByUser',
    name: 'Shared By',
    fieldName: '_sharedByUser',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'action',
    name: '',
    fieldName: 'action',
    minWidth: 20,
    maxWidth: 20,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
    isResizable: false,
  },
  {
    key: 'edit',
    name: '',
    fieldName: 'edit',
    minWidth: 20,
    maxWidth: 50,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
    isResizable: false,
  },
  
];
