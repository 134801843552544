import { Stack, Text, Callout, DirectionalHint, Icon } from '@fluentui/react';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { CommunicationGroups_communicationGroups_nodes_communicationGroupItemsByCommunicationGroupId_nodes } from '../__generated__/CommunicationGroups';

interface CommunicationGroupItemProps {
  groupItemdata: CommunicationGroups_communicationGroups_nodes_communicationGroupItemsByCommunicationGroupId_nodes;
}

export const CommunicationGroupItemCallout: React.FC<
  CommunicationGroupItemProps
> = ({ groupItemdata }) => {
  const styles = useStyles();
  const calloutId = `callOutId_View_${groupItemdata?.id}`;
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  return (
    <Stack>
      <Stack verticalAlign="center">
        <Stack
          verticalAlign="center"
          className={clsx(
            styles.onrenderColumnStack,
            styles.groupItemHeight,
            groupItemdata.communicationChannel?._isAlerts
              ? styles.backgroundWithIsAlert
              : styles.backgroundWithoutIsAlert
          )}
        >
          <Text
            onMouseEnter={() => setIsCalloutVisible(true)}
            onMouseLeave={() => setIsCalloutVisible(false)}
            id={calloutId}
            className={styles.fontColor}
            variant="medium"
          >
            {groupItemdata._communicationChannelName}
          </Text>
        </Stack>

        {isCalloutVisible &&
          (groupItemdata.isSenderOnly || groupItemdata.isRecipientOnly) && (
            <Callout
              className={styles.callout}
              gapSpace={0}
              target={`#${calloutId}`}
              directionalHint={DirectionalHint.topCenter}
              onDismiss={() => setIsCalloutVisible(false)}
              setInitialFocus
            >
              <>
                <Stack
                  horizontalAlign="center"
                  horizontal
                  className={styles.typeContainer}
                  tokens={{ childrenGap: 10 }}
                >
                  {groupItemdata.isSenderOnly ||
                    (groupItemdata.isRecipientOnly && (
                      <Icon
                        className={styles.categoryType}
                        iconName={
                          groupItemdata.isSenderOnly &&
                          !groupItemdata.isRecipientOnly
                            ? 'SortUp'
                            : 'SortDown'
                        }
                      />
                    ))}
                  <Text className={styles.categoryType} variant="medium">
                    {!groupItemdata.isSenderOnly &&
                    !groupItemdata.isRecipientOnly
                      ? 'No Sender / Receiver'
                      : groupItemdata.isSenderOnly &&
                        !groupItemdata.isRecipientOnly
                      ? 'Send only'
                      : 'Receive only'}
                  </Text>
                </Stack>
              </>
            </Callout>
          )}
      </Stack>
    </Stack>
  );
};
