import { FontSizes, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  defaultButtonContainer: {
    marginBottom: 10,
  },
  root: {
    height: 60,
    justifyContent: 'space-between',
  },
  addressTypeLabel: {
    color: theme.palette.neutralDark,
    fontSize: FontSizes.size14,
  },
  rowCell: {
    padding: '20px 0px',
  },
  addressSubtitle: {
    color: theme.palette.neutralSecondary,
  },
  setPrimaryButton: {
    visibility: 'hidden',
    padding: 10,
    borderRadius: 8,
  },
  deleteIcon: {
    color: theme.palette.accent,
  },
  primaryLabel: {
    fontWeight: 'bold',
    color: theme.palette.accent,
  },
  secondaryLabel: {
    fontWeight: 'bold',
    color: theme.palette.neutralSecondaryAlt,
  },
  AddressListItem: {
    ':hover .setPrimaryButtonGlobal': {
      visibility: 'visible',
    },
  },
}));
