import { getColorFromString, getShade, Shade, useTheme } from '@fluentui/react';
import { getHoverColor } from 'common/utils/color';

export const capitalize = (value: string) =>
  value[0].toUpperCase() + value.slice(1);

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const getSecondaryShade = (color: string) =>
  getShade(getColorFromString(color)!, Shade.Shade4, false)!.str;

export const useColors = () => {
  const theme = useTheme();

  return [
    theme.palette.blue,
    theme.palette.green,
    theme.palette.orangeLight,
    theme.palette.magenta,
  ].map((col) => [col, getHoverColor(col, theme)]);
};
export const useRightBarColor = () => {
  const theme = useTheme();

  return [theme.palette.green, theme.palette.red].map((col) => [
    col,
    getHoverColor(col, theme),
  ]);
};
