import { Modal, Stack, Text } from '@fluentui/react'
import { CloseButton } from 'common/components/Buttons'
import React from 'react';
import { useStyles } from './index.styles'
import { HierarchyData } from 'common/components/TransactionSection/TransactionDetail/HierarchyData';
const HEADER_TITLE = 'Preview Hierarchy';

interface PreviewHierarchyModalProps {
  entityId: string;
  onDismiss: () => void;
}

export const PreviewHierarchyModal: React.FC<PreviewHierarchyModalProps> = ({
  entityId,
  onDismiss
}) => {
  const styles = useStyles();
  return (
    <Modal
      isOpen
      scrollableContentClassName={styles.noScrollContainer}
      containerClassName={styles.modalContainer}
    >
      <Stack className={styles.container}>
        <Stack
          className={styles.headerContainer}
          tokens={{ padding: 25 }}
        >
          <Stack
            horizontalAlign="space-between"
            horizontal
            className={styles.alignItemsCenter}
            tokens={{ childrenGap: 10 }}
          >
            <Stack
              className={styles.alignItemsCenter}
              horizontal
              tokens={{ childrenGap: 10 }}
            >
              <Text variant={'xLarge'}>{HEADER_TITLE}</Text>
            </Stack>

            <Stack>
              <CloseButton onClick={onDismiss} />
            </Stack>
          </Stack>
        </Stack>
        <HierarchyData entityId={entityId} />
      </Stack>
    </Modal>
  )
}
