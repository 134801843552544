import { FilterArrayType } from '.';

/**
 *
 * @param filterdata This contains data of filter given as input
 * @param availableFilters Contains array of all filters that are currently applied.
 * @returns
 */
export const getUpdatedFilterArray = (
  filterdata: FilterArrayType,
  availableFilters: FilterArrayType[]
) => {
  const position = availableFilters.findIndex(
    (filter) => filter.filterKey === filterdata.filterKey
  );
  if (position === -1) {
    if (filterdata.value !== -1) availableFilters.push(filterdata);
  } else {
    if (filterdata.value === -1) availableFilters.splice(position, 1);
    else availableFilters.splice(position, 1, filterdata);
  }
  return availableFilters;
};
