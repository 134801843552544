import { Stack, Text, Separator } from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useStyles } from './index.styles';

export interface CalculateBoxProps {
  appliedAmount: number;
  remainingTotal: number;
  controlTotalAmount: string;
  currency: string;
}

export const CalculateBox: React.FC<CalculateBoxProps> = ({
  appliedAmount,
  remainingTotal,
  controlTotalAmount,
  currency,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <Stack horizontal tokens={{ childrenGap: 10 }}>
      {
        controlTotalAmount !== null &&
        <>
          <Stack horizontalAlign="end" tokens={{ padding: '10px 25px 10px 25px' }}>
            <Text>Transaction Amount</Text>
            <Stack horizontal>
              <Text
                variant="xLarge"
                className={clsx(
                  styles.textAlignRight,
                  commonStyles.colorThemePrimary,
                  commonStyles.bold
                )}
              >
                {currency}
              </Text>
              <AmountTextView
                className={clsx(
                  styles.textAlignRight,
                  styles.amount,
                  commonStyles.colorThemePrimary,
                  commonStyles.bold,
                )}
                value={controlTotalAmount}
                variant="xLarge"
              />
            </Stack>
          </Stack>
          <Separator vertical />
        </>
      }


      <Stack horizontalAlign="end" tokens={{ padding: '10px 25px 10px 25px' }}>
        <Text>Applied</Text>
        <Stack horizontal>
          <Text
            variant="xLarge"
            className={clsx(
              styles.textAlignRight,
              commonStyles.colorThemePrimary,
              commonStyles.bold
            )}
          >
            {currency}
          </Text>
          <AmountTextView
            className={clsx(
              styles.textAlignRight,
              styles.amount,
              commonStyles.colorThemePrimary,
              commonStyles.bold,
            )}
            value={appliedAmount.toString()}
            variant="xLarge"
          />
        </Stack>
      </Stack>
      <Separator vertical />
      <Stack horizontalAlign="end" tokens={{ padding: '10px 25px 10px 25px' }}>
        <Text>Remaining</Text>
        <Stack horizontal>
          <Text
            variant="xLarge"
            className={clsx(
              styles.textAlignRight,
              remainingTotal < 0 ? styles.errorRedColor : '',
              commonStyles.bold
            )}
          >
            {currency}
          </Text>
          <AmountTextView
            className={clsx(
              styles.amount,
              styles.textAlignRight,
              remainingTotal < 0 ? styles.errorRedColor : '',
              commonStyles.bold
            )}
            value={remainingTotal.toString()}
            variant="xLarge"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
