import { useMutation } from '@apollo/client';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { DepartmentRowItem } from '..';
import {
  DepartmentDelete,
  DepartmentDeleteVariables,
} from './__generated__/DepartmentDelete';

const DOCUMENT_DELETE = loader('./DepartmentDelete.graphql');

interface DeleteDepartmentProps {
  selectedRows: DepartmentRowItem[];
}

export const DeleteDepartment: React.FC<DeleteDepartmentProps> = ({
  selectedRows,
}) => {
  const { addToast } = useToasts();
  const [deleteDepartment] = useMutation<
    DepartmentDelete,
    DepartmentDeleteVariables
  >(DOCUMENT_DELETE, { errorPolicy: 'all' });

  const _onConfirm = async () => {
    const deletableRows: DepartmentRowItem[] = selectedRows.filter(
      (item) => item._isDeletable
    );
    const entityDelete: EntityDeleteInput[] = deletableRows.map((item) => {
      return { id: item.id, rowTimestamp: item._rowTimestamp! };
    });
    const { errors } = await deleteDepartment({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache, { data }) {
        if (!!data?.departmentDelete?.deletedEntities?.length)
          deletableRows.forEach((item) => {
            const identity = cache.identify({
              ...item,
            });
            cache.evict({ id: identity });
            cache.gc();
          });
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Occupation deleted successfully.', {
        appearance: 'success',
      });
    }
  };

  return (
    <ActionMessageModal
      entityType={EntityType.Department}
      action={EntityAction.Remove}
      disabled={!selectedRows.some((selected) => selected._isDeletable)}
      visible={!!selectedRows.length}
      multiple={{
        validCount: selectedRows.filter((selected) => selected._isDeletable)
          .length,
        invalidNames: selectedRows
          .filter((selected) => !selected._isDeletable)
          .map((selected) => selected.name || ''),
      }}
      onConfirm={_onConfirm}
    />
  );
};
