import { IconButton, Stack } from '@fluentui/react'
import React, { useState } from 'react';
import { BusinessUnitRow } from '../list';
import { FormModal } from './FormModal';

export interface BusinessUnitModalProps {
  businessUnit: BusinessUnitRow;
}

export const BusinessUnitModal: React.FC<BusinessUnitModalProps> = ({
  businessUnit,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    _isUpdatable
  } = { ...businessUnit }

  return (
    <Stack>
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        onClick={() => setIsOpen(true)}
        disabled={!_isUpdatable}
      />
      {isOpen && (
        <FormModal
          businessUnit={businessUnit}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Stack>
  )
}
