import { useQuery } from '@apollo/client';
import { Stack, Text } from '@fluentui/react';
import { FormHookDropdown } from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { useCommonStyles } from 'common/styles';
import { TransactionLayout } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trip_trip } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/__generated__/Trip';
import { TripCreateFormValues } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/types';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { SupplierTripSectionAutoComplete } from './SupplierAutoComplete';
const TRAVELER_AUTHORIZATION_COMMON_DATA = loader(
  '../../../../../../../TravelerAuthorizationCommonData.graphql'
);
interface SuppliersSectionProps {
  accountingVisibility: boolean;
  index: number;
  layout: string;
  tripData: Trip_trip | null | undefined;
  travelAuthorizationData:
  | Partial<TravelAuthorization_travelAuthorization>
  | null
  | undefined;

  inputsDisabled: boolean;
}
export const SuppliersSection: React.FC<SuppliersSectionProps> = ({
  accountingVisibility,
  index,
  tripData,
  layout,
  travelAuthorizationData,
  inputsDisabled,
}) => {
  const commonStyles = useCommonStyles();
  const { data: commonData } = useQuery<TravelerAuthorizationCommonData>(
    TRAVELER_AUTHORIZATION_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const companyCurrenciesOptions =
    commonData?.companyCurrencies?.nodes.map((ele) => {
      const currency = !!ele.name
        ? `${ele.isoCode} - ${ele.name}`
        : ele.isoCode || '';
      return {
        key: ele.id,
        text: currency,
      };
    }) || [];

  const { travelerId, tripItems } = { ...tripData };
  const {
    supplier,
  } = { ...tripItems?.nodes?.[index] };
  const tripSectionData = useWatch({
    name: `tripItems.${index}`,
  });

  const { isSupplierBreakdownRequired, tripSectionItemId } = {
    ...tripSectionData,
  } as any;

  const [supplierState, setSupplierState] = useState(supplier);
  const { setValue } = useFormContext<TripCreateFormValues>();

  const isRowUpdatable = travelAuthorizationData?._isUpdatable
    ? true
    : !!travelAuthorizationData?._isDistributionUpdateAllowed;

  useEffect(() => {
    setSupplierState(supplier);
  }, [
    supplier
  ]);

  const isVisible = layout === TransactionLayout.TRAVEL_BOOKING_GROUND_TRANS;

  return (
    <Stack grow={true}>
      <Text variant="large" className={commonStyles.colorThemePrimary}>
        Optional
      </Text>
      <Stack
        horizontal
        verticalAlign="end"
        tokens={{
          childrenGap: 20,
        }}
        style={{ marginTop: 5 }}
      >
        <SupplierTripSectionAutoComplete
          name={`tripItems.${index}.supplierId`}
          label="Supplier"
          placeholder="Supplier"
          disabled={!isRowUpdatable || !!isSupplierBreakdownRequired}
          lookUpCalloutWidth={400}
          id={'tripItems-supplierId'}
          aria-labelledby={`tripItems-${index}-supplierId`}
          showAddIcon
          style={{ width: 180 }}
          supplier={supplierState!}
          required
          travelerId={travelerId!}
          tripSectionId={tripSectionItemId}
        />        {isVisible && (
          <FormHookCheckBox
            name={`tripItems.${index}.isSupplierBreakdownRequired`}
            label="Multiple suppliers"
            onChange={(event, checked) => {
              if (event) {
                if (!!checked) {
                  setValue(`tripItems.${index}.supplierId`, null);
                  setSupplierState(null);
                } else {
                  setValue(`tripItems.${index}.pickupSupplierId`, null, { shouldDirty: true });
                  setValue(`tripItems.${index}.pickupDropSupplierId`, null, { shouldDirty: true });
                  setValue(`tripItems.${index}.returnSupplierId`, null, { shouldDirty: true });
                  setValue(`tripItems.${index}.returnDropSupplierId`, null, { shouldDirty: true });
                  setValue(`tripItems.${index}.pickupCurrencyId`, null, { shouldDirty: true });
                  setValue(`tripItems.${index}.pickupDropCurrencyId`, null, { shouldDirty: true });
                  setValue(`tripItems.${index}.returnCurrencyId`, null, { shouldDirty: true });
                  setValue(`tripItems.${index}.returnDropCurrencyId`, null, { shouldDirty: true });
                }
              }
            }}
            styles={{ root: { marginBottom: 5 } }}
          />
        )}
        <FormHookDropdown
          name={`tripItems.${index}.currencyId`}
          options={companyCurrenciesOptions}
          placeholder="TA Currency"
          width={180}
          disabled={!isRowUpdatable || !!isSupplierBreakdownRequired}
        />
      </Stack>
    </Stack>
  );
};
