import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 600,
    minHeight: 175,
    maxHeight: 300,
    paddingBottom: 10,
  },
  headerContainer: {
    height: 70,
    padding: 20,
    justifyContent: 'space-between',
  },
  applyAmount: {
    flex: 1,
  },
  checkboxStyles: {
    marginTop: 26,
  },
  textFieldStyles: {
    flex: 1,
  },
  footersContainer: {
    height: 70,
    padding: 20,
  },
  boldText: {
    color: theme.palette.themePrimary,
    fontWeight: FontWeights.semibold,
    // paddingRight: 10,
  },
}));
