import { useMutation } from '@apollo/client';
import { Stack, TextField, ActionButton } from '@fluentui/react';
import { PivotKeys } from 'common/components/MessagesSections';
import {
  messageResponseUpdate,
  messageResponseUpdateVariables,
} from 'common/graphql/__generated__/messageResponseUpdate';
import { loader } from 'graphql.macro';
import { ListLoader } from 'notification/view';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';
const MESSAGE_RESPONSE_UPDATE = loader(
  '../../../../../graphql/SendMessageResponse.graphql'
);
interface ReceivedRowActionProps {
  id: string;
  refetchList: (listName?: string) => void;
  closeReceivedRowActions: (param: boolean) => void;
}

export const ReceivedRowAction: React.FC<ReceivedRowActionProps> = ({
  id,
  refetchList,
  closeReceivedRowActions,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [messageCommentText, setMessageComment] = React.useState<string>();
  const [respondId] = React.useState<string | undefined>(id);

  const [respondToMessage, { loading: reponseMessageLoading }] = useMutation<
    messageResponseUpdate,
    messageResponseUpdateVariables
  >(MESSAGE_RESPONSE_UPDATE, { errorPolicy: 'all' });

  const onRespond = async () => {
    const { errors } = await respondToMessage({
      variables: {
        first: 10,
        input: {
          id: respondId!,
          responseMessage: messageCommentText!,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      setMessageComment('');
      refetchList?.(PivotKeys.received);
      addToast('Message response sent successfully.', {
        appearance: 'success',
      });
      closeReceivedRowActions(false);
    }
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 30 }}
        horizontal
        className={styles.container}
      >
        <Stack grow={1}>
          <TextField
            placeholder="Type your message"
            value={messageCommentText}
            onChange={(_event, value) => setMessageComment(value || '')}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <ActionButton
            iconProps={{ iconName: 'Send' }}
            allowDisabledFocus
            onClick={onRespond}
            disabled={messageCommentText?.length! === 0}
          >
            Send
          </ActionButton>
          <ActionButton
            iconProps={{ iconName: 'Cancel' }}
            allowDisabledFocus
            onClick={() => closeReceivedRowActions(false)}
            disabled={reponseMessageLoading}
          >
            Cancel
          </ActionButton>
        </Stack>
      </Stack>
      {reponseMessageLoading && (
        <Stack className={styles.listLoaderContainer}>
          <ListLoader
            label="Sending Response"
            loaderStatus={reponseMessageLoading}
          />
        </Stack>
      )}
    </>
  );
};
