import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
  columnActionsMode: ColumnActionsMode.disabled,
};
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

export const invoiceColumns: ColumnData[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 150,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 150,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'invoiceNumber',
    name: 'Invoice / Ref. No.',
    fieldName: 'invoiceNumber',
    minWidth: 100,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'invoiceDate',
    name: 'Date',
    fieldName: 'invoiceDate',
    minWidth: 80,
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'amount',
    name: 'Amount',
    fieldName: 'amount',
    minWidth: 80,
    maxWidth: 100,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'action',
    name: 'Action',
    fieldName: 'action',
    minWidth: 40,
    maxWidth: 60,
    ...commonColumnProps,
  },
];
