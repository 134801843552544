export interface GridColumnProps {
  key: string;
  headerName: string;
  fixedWidth: number | boolean;
  flexProportion?: number;
  alignItems:
    | 'center'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'self-end'
    | 'self-start'
    | 'start';
}

export const gridColumns: GridColumnProps[] = [
  {
    key: 'itemNumber',
    headerName: 'Item#',
    fixedWidth: false,
    flexProportion: 0.6,
    alignItems: 'center',
  },
  {
    key: 'displayPoItemType',
    headerName: 'Type',
    fixedWidth: false,
    flexProportion: 2,
    alignItems: 'start',
  },
  {
    key: 'displayPoItemDescription',
    headerName: 'Description',
    fixedWidth: false,
    flexProportion: 6,
    alignItems: 'start',
  },
  {
    key: 'displayPoItemAccount',
    headerName: 'Account#',
    fixedWidth: false,
    flexProportion: 2,
    alignItems: 'start',
  },
  {
    key: 'displayPoItemQuantity',
    headerName: 'Qty',
    fixedWidth: false,
    flexProportion: 1,
    alignItems: 'center',
  },
  {
    key: 'displayPoItemRentalTerm',
    headerName: 'Term',
    fixedWidth: false,
    flexProportion: 2,
    alignItems: 'start',
  },
  {
    key: 'displayPoItemUnitPrice',
    headerName: 'Unit Price',
    fixedWidth: false,
    flexProportion: 2,
    alignItems: 'end',
  },
  {
    key: '_extendedItemTotal',
    headerName: 'Total',
    fixedWidth: false,
    flexProportion: 2,
    alignItems: 'end',
  },
];
