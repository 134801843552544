import {
  ContextualMenu,
  IColumn,
  makeStyles,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Stack,
  Text,
  TooltipHost
} from "@fluentui/react";
import { CloseButton } from "common/components/Buttons";
import { InfiniteList } from "common/components/InfiniteList";
import React, { useState } from "react";
import { FileCabinetPoolRecipients_fileCabinetPoolRecipients_nodes } from "../__generated__/FileCabinetPoolRecipients";
import { columns } from "./column.data";
const useStyles = makeStyles(() => ({
  mainContainer: {
    width: 800,
    height: 700,
  },
}));
interface MoveToFolderModalProps {
  poolSelectedID?: string;
  fileCabinetPoolRecipients: FileCabinetPoolRecipients_fileCabinetPoolRecipients_nodes[];
  foldersLoading: boolean;
  moveDocumentsLoading: boolean;
  closeModal: () => void;
  onMove: (selectedFolder: FileCabinetPoolRecipients_fileCabinetPoolRecipients_nodes) => void;
};
export const MoveToFolderModal: React.FC<MoveToFolderModalProps> = ({
  poolSelectedID,
  fileCabinetPoolRecipients,
  foldersLoading,
  moveDocumentsLoading,
  closeModal,
  onMove
}) => {
  const styles = useStyles();
  const [selectedList, setSelectedList] = useState<FileCabinetPoolRecipients_fileCabinetPoolRecipients_nodes[]>([]);
  let _renderItemColumn = (
    item: FileCabinetPoolRecipients_fileCabinetPoolRecipients_nodes,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    switch (column?.key) {
      case 'name':
        return <Text>{item.name}</Text>;
      case 'documentType':
        return <Text>{item.fileCabinetRecipientDefaultDocumentTypes?.documentType}</Text>;
      case 'availableDocuments':
        return (
          <TooltipHost
            content={`${item.fileCabinetRecipientPoolTotals?.selectedDocuments} Attached`}
          >
            <Text>{item.fileCabinetRecipientPoolTotals?.availableDocuments}</Text>
          </TooltipHost>
        );
    }
  };
  const documentPoolRows = poolSelectedID
    ? fileCabinetPoolRecipients.filter(
      (item) => item.id !== poolSelectedID
    )
    : fileCabinetPoolRecipients;
  return (
    <Modal
      isOpen={true}
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
    >
      <Stack className={styles.mainContainer} tokens={{ childrenGap: 20 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ padding: 25 }}
        >
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <Text variant={'xLarge'}>Document Folders</Text>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              text="Move"
              disabled={!selectedList.length || moveDocumentsLoading}
              iconProps={{ iconName: 'OpenInNewWindow' }}
              onClick={() => {
                setSelectedList([])
                onMove(selectedList[0])
              }}
            />
            <CloseButton onClick={closeModal} />
          </Stack>
        </Stack>
        <ProgressIndicator progressHidden={!moveDocumentsLoading} />
        <InfiniteList<FileCabinetPoolRecipients_fileCabinetPoolRecipients_nodes>
          items={documentPoolRows}
          loading={foldersLoading}
          selectionMode={SelectionMode.single}
          columns={columns}
          onRenderItemColumn={_renderItemColumn}
          onSelectionChanged={setSelectedList}
        />
      </Stack>
    </Modal>
  );
};