import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  listLoaderMainContainer: {
    margin: 20,
  },
  loadMoreContainer: {
    paddingTop: 20,
    margin: 10,
  },
}));
