import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    fullWidthContainer: {
      width: '100%',
    },
    dropDownContainer: {
      width: 180,
      marginLeft: 10,
    },
    dropDownContainer260: {
      width: 260,
      marginLeft: 10,
    },
    marginL5: {
      marginLeft: 10,
    },
    renderAccountingOptions: {
      marginBottom: 20,
    },
    accountingRowMargin: {
      marginTop: '5px !important',
    },
    marginLeft5: {
      marginLeft: 5,
      minWidth: 150,
      justifyContent: 'center',
      alignItems: 'center',
    },
    width30: {
      marginLeft: 5,
      width: 30,
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      backgroundColor: theme.palette.neutralLighter,
      paddingLeft: 70,
    },
    paddingLeft10: {
      paddingLeft: 10,
    },
    formikCheckboxStyle: {
      width: 130,
      marginRight: 10,
      alignItems: 'center',
    },
    formikCheckbox: {
      width: 70,
      paddingLeft: 15,
      alignItems: 'center',
    },
    removeIconContainer: {
      alignItems: 'center',
      width: 20,
      marginRight: 10,
    },
    deleteIconColor: {
      color: 'red',
    },
    exclamationIconColor: {
      color: 'yellow',
    },
  };
});
