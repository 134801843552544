import { useQuery } from '@apollo/client';
import { EnvironmentalMetrics } from 'common/graphql/__generated__/EnvironmentalMetrics';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useStyles } from './index.styles'
import { IDropdownOption, Link, Stack, Text } from '@fluentui/react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as CarbonAccountingSVG } from 'assets/svgs/carbon_accounting.svg';
import { ReactComponent as CarbonAccountingFillingSVG } from 'assets/svgs/carbon_accounting_filled.svg';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import { PurchaseOrder_purchaseOrder_entityEnvironmentalsByEntityId } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { FormModal } from './FormModal';
import { TransactionLayout } from 'common/types/globalTypes';
const GET_ENVIRONMENT_CATEGORY = loader('../../../../common/graphql/EnvironmentalMetrics.graphql');

interface CarbonAccountingProps {
  entityId: string;
  purchaseOrderDetails:
  | PurchaseOrder_purchaseOrder_entityEnvironmentalsByEntityId
  | undefined;
  refetchPO: (isdeleted: boolean) => void;
  isEnvironmentalsExist: boolean;
}

export interface CategoryOptionsType extends IDropdownOption {
  key: string | number;
  text: string;
  layoutType: TransactionLayout | undefined | null;
}

export const CarbonAccounting: React.FC<CarbonAccountingProps> = ({
  entityId,
  purchaseOrderDetails,
  refetchPO,
  isEnvironmentalsExist
}) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: environmentCategoryData } = useQuery<EnvironmentalMetrics>(
    GET_ENVIRONMENT_CATEGORY
  );

  const categoryOptions: CategoryOptionsType[] =
    environmentCategoryData?.environmentalMetrics?.nodes.map((item) => ({
      key: item.id,
      text: item.environmentalMetric || '',
      layoutType: item.layoutType,
    })) || [];

  const {
    formState: { isDirty },
  } = useFormContext<PurchaseOrderValues>();

  return (
    <Stack verticalAlign="center" className={styles.containerMargin}>
      <Link
        className={styles.textDecorationNone}
        underline={false}
        onClick={() => setIsOpen(true)}
        disabled={isDirty}
      >
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Stack horizontal verticalAlign="center">
            {isEnvironmentalsExist ? (
              <CarbonAccountingFillingSVG className={styles.svgImage} />
            ) : (
              <CarbonAccountingSVG className={styles.svgImage} />
            )}
          </Stack>
          <Text>Carbon accounting</Text>
        </Stack>
      </Link>
      {isOpen && (
        <FormModal
          entityId={entityId}
          isEnvironmentalsExist={isEnvironmentalsExist}
          categoryOptions={categoryOptions}
          purchaseOrderDetails={purchaseOrderDetails}
          refetchPO={refetchPO}
          setOpen={setIsOpen}
        />
      )}
    </Stack>
  )
}
