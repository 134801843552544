import {
  IconButton,
  makeStyles,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
import Decimal from 'decimal.js';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ColumnData } from '../columns.data';
import { TripCreateFormValues } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/types';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '25px !important',
  },
  amountText: {
    textAlign: 'right',
  },
}));

interface DistributionRowTotalProps {
  columnsArray: ColumnData[];
  tripItemIndex: number;
}

export const DistributionRowTotal: React.FC<DistributionRowTotalProps> = ({
  columnsArray,
  tripItemIndex,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { control } = useFormContext<TripCreateFormValues>();
  const watchDistribution =
    useWatch({
      control,
      name: `tripItems.${tripItemIndex}.tripDistributions`,
    }) || [];

  const totalAllocation = watchDistribution.every((item) => {
    return item.allocationPercent === null || item.allocationPercent === 'NaN';
  })
    ? null
    : (watchDistribution.reduce((sum: Decimal, current) => {
        return new Decimal(sum || 0).plus(
          current?.allocationPercent === null ||
            current?.allocationPercent === 'NaN'
            ? 0
            : current?.allocationPercent
        );
      }, new Decimal(0)) as Decimal);

  const total = watchDistribution.reduce((sum: Decimal, current) => {
    const distributionAmount = current.distributionAmount || 0;
    try {
      return new Decimal(sum || 0.0).plus(distributionAmount);
    } catch (error) {
      return sum || 0.0;
    }
  }, new Decimal(0)) as Decimal;

  if (!watchDistribution.length) return null;
  else
    return (
      <>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
          className={styles.container}
        >
          {columnsArray?.map((ele, index) => {
            switch (ele.key) {
              case 'delete':
                return null;
              case 'distributionAmount':
                return (
                  <Stack
                    key={index}
                    style={{
                      minWidth: ele.maxWidth,
                    }}
                  >
                    <AmountTextView
                      className={clsx(
                        styles.amountText,
                        commonStyles.semibold,
                        commonStyles.colorThemePrimary
                      )}
                      value={total.toNumber().toFixed(2)}
                    />
                  </Stack>
                );
              case 'allocationPercent':
                return (
                  <Stack
                    key={index}
                    style={{
                      width: ele.maxWidth,
                    }}
                  >
                    {totalAllocation ? (
                      totalAllocation.toNumber() > 100 ? (
                        <Text
                          className={clsx(
                            commonStyles.colorThemeRed,
                            styles.amountText
                          )}
                        >
                          Cannot be more than 100
                        </Text>
                      ) : (
                        <Stack
                          horizontal
                          horizontalAlign="end"
                          verticalAlign="center"
                        >
                          {totalAllocation.toNumber() < 100 && (
                            <TooltipHost
                              content={"Allocation has to be '100%'"}
                            >
                              <IconButton iconProps={{ iconName: 'Warning' }} />
                            </TooltipHost>
                          )}
                          <Text
                            className={clsx(
                              commonStyles.semibold,
                              commonStyles.colorThemePrimary
                            )}
                          >
                            {totalAllocation.toNumber().toString()}
                          </Text>
                        </Stack>
                      )
                    ) : null}
                  </Stack>
                );
              default:
                return (
                  <Stack
                    key={index}
                    style={{
                      minWidth: ele.minWidth,
                    }}
                  />
                );
            }
          })}
        </Stack>
      </>
    );
};
