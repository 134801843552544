import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import {
  GetStateRegionsByCountryId,
  GetStateRegionsByCountryIdVariables,
  GetStateRegionsByCountryId_country_stateRegions_nodes,
} from './__generated__/GetStateRegionsByCountryId';
import {
  ComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  IconButton,
  makeStyles,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
  },
}));

export interface Country {
  key: number;
  text: string | null;
}
export interface StateRegion {
  key: number;
  text: string | null;
}
interface AutoCompleteProps {
  value?: number | null;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  errorMessage: string;
  required?: boolean;
}
export interface CountryRegionSelectorProps {
  countries: IComboBoxOption[];
  disabled?: boolean;
  CountryAutocompleteProps?: AutoCompleteProps & {
    onChange?: (
      event: React.ChangeEvent<{}>,
      value: IComboBoxOption | undefined
    ) => void;
  };
  isCountryClearable?: boolean;
  StateRegionAutocompleteProps?: AutoCompleteProps & {
    onChange?: (
      event: React.ChangeEvent<{}>,
      value: IComboBoxOption | null,
      countryValue: IComboBoxOption
    ) => void;
  };
  isStateRegionSelectable?: boolean;
  isStateRegionClearable?: boolean;
  countryPickerStyles?: Partial<IComboBoxStyles> | undefined;
  statePickerStyles?: Partial<IComboBoxStyles> | undefined;
}
const STATE_REGIONS = loader('./GetStateRegionsByCountryId.graphql');
// FOR RENDERING COUNTRIES WITH CUSTOM KEYS AND VALUE NAMES  ACCORDING TO IDropdown Interface Structure

export const CountryRegionSelector: React.FC<CountryRegionSelectorProps> = ({
  countries,
  CountryAutocompleteProps,
  StateRegionAutocompleteProps,
  disabled,
  isStateRegionSelectable = true,
  isCountryClearable,
  isStateRegionClearable,
  countryPickerStyles,
  statePickerStyles,
}) => {
  const styles = useStyles();
  const {
    value: countryIdValue,
    onChange: onCountryChange,
    label: countryLabel,
    required: countryRequired,
    ...countryProps
  } = CountryAutocompleteProps || {};

  const {
    value: stateRegionIdValue,
    onChange: onStateRegionChange,
    label: stateRegionLabel,
    required: stateRegionRequired,
    ...stateRegionProps
  } = StateRegionAutocompleteProps || {};

  const [taxRegions, setTaxRegions] = useState<IComboBoxOption[]>([]);
  const [getStateRegions, { data: stateRegions }] = useLazyQuery<
    GetStateRegionsByCountryId,
    GetStateRegionsByCountryIdVariables
  >(STATE_REGIONS, {
    onCompleted: (data) => {
      if (
        data.country?.stateRegions.nodes !== undefined &&
        data.country?.stateRegions.nodes.length > 0
      ) {
        let stateArray = data.country?.stateRegions.nodes.map(
          (elm: GetStateRegionsByCountryId_country_stateRegions_nodes) => ({
            key: elm.id,
            text: elm.stateRegion!,
          })
        );
        if (stateArray) {
          setTaxRegions(stateArray);
        }
      } else {
        setTaxRegions([]);
      }
    },
  });

  const selectedOption = countries?.find(
    (country) => country.key === countryIdValue
  );

  const stateRegionValue =
    stateRegionIdValue && stateRegions
      ? stateRegions.country?.stateRegions.nodes.find(
          (region) => region.id === stateRegionIdValue
        )
      : undefined;

  useEffect(() => {
    if (countryIdValue) {
      getStateRegions({ variables: { countryId: countryIdValue } });
    }
  }, [countryIdValue, getStateRegions]);

  const showCountryClearIcon = isCountryClearable && !!countryIdValue;
  const showStateRegionClearIcon =
    isStateRegionClearable && stateRegionValue && !!stateRegionValue?.id;

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack
        horizontal
        // className={classStyles.fieldContainer}
        verticalAlign="end"
        // tokens={{
        //   childrenGap: 6
        // }}
      >
        <ComboBox
          scrollSelectedToTop={true}
          {...countryProps}
          label={countryLabel}
          allowFreeform
          dropdownWidth={200}
          required={countryRequired}
          selectedKey={selectedOption?.key || null}
          placeholder={countryLabel}
          disabled={!countries || disabled}
          options={countries}
          onChange={onCountryChange}
          styles={{
            optionsContainerWrapper: { height: 300 },
            ...countryPickerStyles,
            // root: { width: 400 },
          }}
        />
        {showCountryClearIcon && (
          <IconButton
            type="button"
            onClick={(e) => {
              onCountryChange?.(e, undefined);
              onStateRegionChange?.(e, null, selectedOption!);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        )}
      </Stack>
      {isStateRegionSelectable && (
        <Stack
          horizontal
          className={classStyles.fieldContainer}
          verticalAlign="end"
          tokens={{
            childrenGap: 6,
          }}
        >
          <ComboBox
            dropdownWidth={200}
            scrollSelectedToTop={true}
            {...stateRegionProps}
            label={stateRegionLabel}
            allowFreeform
            selectedKey={stateRegionValue?.id || null}
            styles={{
              optionsContainerWrapper: { height: 300 },
              ...statePickerStyles,
            }}
            placeholder={stateRegionLabel}
            disabled={!selectedOption || disabled}
            options={taxRegions}
            required={stateRegionRequired}
            onChange={(event, SelectedOption) => {
              if (onStateRegionChange && SelectedOption) {
                onStateRegionChange(event, SelectedOption, selectedOption!);
              }
            }}
          />
          {showStateRegionClearIcon && (
            <IconButton
              type="button"
              onClick={(e) => {
                onStateRegionChange?.(e, null, selectedOption!);
              }}
              iconProps={{ iconName: 'cancel' }}
              className={styles.icon}
              ariaLabel="Close"
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

const classStyles = mergeStyleSets({
  fieldContainer: {
    flex: 1,
  },
});
