import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const centerAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'center',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: '_documentType',
    name: 'Type',
    fieldName: '_documentType',
    minWidth: 120,
    maxWidth: 120,
    ...commonColumnProps,
  },

  {
    key: 'indexName',
    name: 'Name',
    fieldName: 'indexName',
    minWidth: 200,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'indexReferenceNumber',
    name: 'Number',
    fieldName: 'indexReferenceNumber',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: 'indexTransactionDate',
    name: 'Date',
    fieldName: 'indexTransactionDate',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
  },

  {
    key: '_documentContents',
    name: 'Contents',
    fieldName: '_documentContents',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },

  {
    key: 'indexAmount',
    name: 'Total',
    fieldName: 'indexAmount',
    minWidth: 70,
    maxWidth: 70,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },

  {
    key: 'isoCode',
    name: 'Currency',
    fieldName: 'isoCode',
    minWidth: 70,
    maxWidth: 70,
  },

  {
    key: 'comment',
    name: 'Comments',
    fieldName: 'comment',
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: 'view',
    name: '',
    fieldName: '',
    minWidth: 20,
    maxWidth: 20,
  },
  {
    key: 'download',
    name: '',
    fieldName: '',
    minWidth: 20,
    maxWidth: 20,
  },
  {
    key: 'fileReference',
    name: 'Documents',
    fieldName: 'fileReference',
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: '_uploadDate',
    name: 'Created',
    fieldName: '_uploadDate',
    minWidth: 150,
    maxWidth: 150,
    styles: centerAlignHeaderStyle,
  },
  {
    key: '_exportDate',
    name: 'Exported On',
    fieldName: '_exportDate',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
];
