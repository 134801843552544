export const getConsciseList = (selectedList: string[], type: string) => {
  switch (selectedList.length) {
    case 0:
      return '';
    case 1:
      return `${selectedList[0]} is not ${type}`;
    case 2:
      return `${selectedList[0]} and ${selectedList[1]}  Files are not ${type}`;
    default:
      return `${selectedList[0]}, ${selectedList[1]} and +${
        selectedList.length - 2
      }  Files are not ${type}`;
  }
};
