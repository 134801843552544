import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  pivot: {
    marginLeft: 20,
  },
  inputContainer: {
    flex: 0.5,
  },
  mT20: {
    marginTop: 20,
  },
  mT30: {
    marginTop: '30px !important',
  },
  checkboxContainer: {
    flex: 1,
  },
  bookingRateContainer: {
    width: '200px !important',
  },
}));
