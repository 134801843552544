import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    search: {
      borderColor: theme.palette.neutralTertiary,
      ':hover': {
        borderColor: theme.palette.neutralSecondary,
      },
    },
    lookupCode: {
      border: `1px solid ${theme.palette.themePrimary}`,
      borderRadius: 5,
      padding: '0px 5px',
    },
    separator: {
      height: 1,
      color: theme.palette.neutralLight
    },
    callout: {
      maxHeight: 300,
      flexGrow: 1,
    },
    detailsCallout: {
      width: 400,
      flexGrow: 1,
    },
    searchAreaContainer: {
      overflow: 'hidden',
      padding: 10,
      backgroundColor: theme.palette.neutralLighterAlt,
    },
    resultItem: {
      textDecoration: 'none',
      margin: '0px -20px',
      padding: '6px 20px',
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
      ':active': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    calloutContainer: {
        backgroundColor: theme.palette.neutralLighterAlt,
    }
  };
});
