import React, { FC } from 'react';
import { useField } from 'formik';
import { RequiredNameProps } from 'common/types/utility';
import { IToggleProps, Toggle } from '@fluentui/react';
import { ErrorText } from '../ErrorText';

export const FormikToggle: FC<RequiredNameProps<IToggleProps>> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField<boolean | undefined>(name);
  const { value, onChange, ...rest } = field;

  const checked = value === true;
  return (
    <>
      <Toggle
        onChange={(event, checked) => {
          helpers.setValue(checked);
        }}
        {...rest}
        {...props}
        checked={checked}
      />

      {meta.touched && meta.error && <ErrorText message={meta.error} />}
    </>
  );
};
