import { ColumnActionsMode, IColumn } from '@fluentui/react';

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

export const columnsBatchTransaction: IColumn[] = [
  {
    key: '_isVerified',
    name: '',
    fieldName: '_isVerified',
    minWidth: 20,
    styles: { cellTitle: { marginTop: 5 } },
    ...commonProps,
  },
  {
    key: 'itemDescription1',
    name: 'Type',
    fieldName: 'itemDescription1',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: 'itemDescription2',
    name: 'Name',
    fieldName: 'itemDescription2',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: 'itemDescription3',
    name: 'Description',
    fieldName: 'itemDescription3',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: '_accountingStampTransactionReference',
    name: 'Transaction No',
    fieldName: '_accountingStampTransactionReference',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: 'itemDate1',
    name: 'Date',
    fieldName: 'itemDate1',
    minWidth: 100,
    isPadded: true,
    styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
    ...commonProps,
  },

  {
    key: 'amount',
    name: 'Amount',
    fieldName: 'itemAmount1',
    minWidth: 100,
    isPadded: true,
    styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
    ...commonProps,
  },
];
