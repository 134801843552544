import { useMutation } from '@apollo/client';
import {
  Checkbox,
  ContextualMenu,
  DefaultButton,
  Icon,
  IDragOptions,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { CloseButton } from 'common/components/Buttons';
import {
  EntityDocumentsFields,
  EntityDocumentsFields_documentAppliedAmounts,
} from 'common/graphql/__generated__/EntityDocumentsFields';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import Decimal from 'decimal.js';
import { loader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useToasts } from 'react-toast-notifications';
import { ViewDocument } from '../ViewDocument';
import { useStyles } from './index.styles';
import {
  GetAppliedAmount,
  getRemainingTotal,
  showOverageCheckBox,
  showRetireCheckBox,
} from './utils';
import {
  EntityDocumentAppliedUpdate,
  EntityDocumentAppliedUpdateVariables,
} from './__generated__/EntityDocumentAppliedUpdate';
const ENTITY_DOCUMENT_APPLIED_UPDATE = loader(
  './EntityDocumentAppliedUpdate.graphql'
);

type DocumentAppliedAmountsType = Pick<
  EntityDocumentsFields_documentAppliedAmounts,
  'remainingTotal' | 'usedTotal'
>;

export type DocumentsType = Pick<
  EntityDocumentsFields,
  | 'id'
  | 'entityId'
  | 'documentRetiredAmount'
  | 'isAppliedAmountRetired'
  | 'isAppliedAmountOverage'
  | 'documentStatusExplanation'
  | '_rowTimestamp'
  | '_poAllocationTotal'
  | 'iconType'
  | 'fileReference'
  | '_fileType'
  | '_fileViewer'
// "documentAppliedAmounts" |
  | '_isDocumentAmountApplied'
  | '_isDocumentAmountAvailable'
  | 'indexAmount'
  | 'documentAppliedAmount'
  | 'documentOverageAmount'
> & {
  documentAppliedAmounts: DocumentAppliedAmountsType | null;
};

interface EditDocumentModalProps {
  isOpen: boolean;
  isNew: boolean;
  document: DocumentsType;
  onChange: (item: DocumentMetaForm) => void;
  onDismiss: () => void;
  onUpdate?: () => void;
}

export interface DocumentMetaForm {
  id?: string;
  remainingTotal?: string;
  documentAppliedAmount?: string | null;
  isAppliedAmountRetired?: boolean;
  documentStatusExplanation?: string;
  isAppliedAmountOverage?: boolean;
  documentRetiredAmount?: string | null;
}

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

export const EditDocumentModal: React.FC<EditDocumentModalProps> = ({
  isOpen,
  isNew,
  document,
  onDismiss,
  onChange,
  onUpdate,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const remainingTotal = getRemainingTotal({
    isNew,
    document,
  });

  const appliedAmount = GetAppliedAmount({
    isNew,
    document,
  });

  const [metaData, setMetaData] = useState<DocumentMetaForm | undefined>({
    id: document.id,
    remainingTotal,
    documentAppliedAmount: appliedAmount,
    documentRetiredAmount: document.documentRetiredAmount,
    isAppliedAmountRetired: !!document.isAppliedAmountRetired,
    // parseFloat(document.documentRetiredAmount || '0.00') > 0,
    isAppliedAmountOverage: !!document.isAppliedAmountOverage,
    documentStatusExplanation: document.documentStatusExplanation || '',
  });

  const [showRetireAmountFields, setShowRetireAmountFields] = useState<boolean>(
    // parseFloat(document.documentRetiredAmount || '0.00') > 0
    !!document.isAppliedAmountRetired
  );

  const [updateAppliedDocument] = useMutation<
    EntityDocumentAppliedUpdate,
    EntityDocumentAppliedUpdateVariables
  >(ENTITY_DOCUMENT_APPLIED_UPDATE, { errorPolicy: 'all' });

  // const retireCheckBoxVisible =
  //   parseFloat(metaData?.documentAppliedAmount!) <
  //   parseFloat(document?.documentAppliedAmounts?.remainingTotal!);

  const retireCheckBoxVisible = showRetireCheckBox({
    isNew,
    document,
    metaData,
  });

  // const overageCheckBoxVisible =
  //   parseFloat(metaData?.documentAppliedAmount!) >
  //   parseFloat(document?.documentAppliedAmounts?.remainingTotal!);

  const overageCheckBoxVisible = showOverageCheckBox({
    isNew,
    document,
    metaData,
  });

  useEffect(() => {
    if (!overageCheckBoxVisible)
      setMetaData((prevState) => {
        return {
          ...prevState,
          isAppliedAmountOverage: false,
          // documentStatusExplanation: undefined
        };
      });
  }, [overageCheckBoxVisible]);

  useEffect(() => {
    if (!retireCheckBoxVisible)
      setMetaData((prevState) => {
        return {
          ...prevState,
          isAppliedAmountRetired: false,
          // documentStatusExplanation: undefined
        };
      });
  }, [retireCheckBoxVisible]);

  const explanationErrorhandler = metaData?.isAppliedAmountOverage
    ? metaData?.documentStatusExplanation?.length! > 0
      ? ''
      : 'Explanation required'
    : '';

  const inValidRetireAmount = metaData?.isAppliedAmountRetired
    ? new Decimal(metaData?.documentRetiredAmount || 0).greaterThan(
        new Decimal(metaData?.remainingTotal || 0).minus(
          metaData?.documentAppliedAmount || 0
        )
      )
    : false;

  const inValidRetireAmountError = inValidRetireAmount
    ? 'Retire amount cannot be greater than applied amount.'
    : '';

  const nullRetireAmountValueError =
    metaData?.isAppliedAmountRetired && !Number(metaData?.documentRetiredAmount)
      ? 'Please enter Retire Amount.'
      : '';
  const inValidOverageAmount =
    !!metaData?.documentAppliedAmount &&
    !isNaN(parseFloat(metaData?.documentAppliedAmount)) &&
    !metaData?.isAppliedAmountOverage
      ? new Decimal(metaData?.documentAppliedAmount).greaterThan(
          new Decimal(metaData?.remainingTotal || 0)
        )
      : false;
  const disableAttachButton =
    inValidRetireAmountError ||
    nullRetireAmountValueError ||
    explanationErrorhandler ||
    inValidOverageAmount;

  const onSubmit = async () => {
    if (metaData) onChange(metaData);
    if (!isNew) {
      if (metaData) {
        const { errors } = await updateAppliedDocument({
          variables: {
            input: {
              id: document.id,
              entityId: document.entityId,
              rowTimestamp: document._rowTimestamp!,
              documentAppliedAmount: metaData.documentAppliedAmount!,
              documentRetiredAmount: metaData?.documentRetiredAmount || '0.00',
              isAppliedAmountRetired: metaData?.isAppliedAmountRetired!,
              isAppliedAmountOverage: metaData?.isAppliedAmountOverage!,
              documentStatusExplanation: metaData?.documentStatusExplanation!,
            },
          },
        });
        if (errors?.length) {
          addToast(errors[0].message, {
            appearance: 'error',
          });
        } else {
          onUpdate?.();
          addToast('Document updated successfully', {
            appearance: 'success',
          });
        }
      }
    }
    setMetaData(undefined);
    onDismiss();
  };

  const openDocuments = useRef(new Set<number>());

  // const total = parseFloat(metaData?.documentAppliedAmount || "0.00") + parseFloat(metaData?.documentOverageAmount || "0.00");
  const total = parseFloat(metaData?.documentAppliedAmount || '0.00');
  const distributed = parseFloat(document?._poAllocationTotal || '0.00');
  const difference = (total || 0) - distributed;

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} dragOptions={dragOptions}>
      <Stack className={styles.headerContainer} horizontal>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <Icon iconName="TextDocument" style={{ fontSize: 20 }} />
          <Text variant={'xLarge'}>Edit Document</Text>
        </Stack>
        <CloseButton onClick={() => onDismiss()} />
      </Stack>
      <Separator />
      <Stack className={styles.container} verticalAlign="space-between" grow>
        <Stack
          tokens={{
            padding: 20,
            childrenGap: 20,
          }}
        >
          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
            horizontalAlign="space-between"
          >
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              <ViewDocument
                document={document}
                disabled={false}
                focusTrap
                paddingLeft={10}
                // onOpen={(currentIndex) => {
                //   // already open
                //   if (currentIndex !== undefined) return currentIndex;
                //   // find the next available slot
                //   const sorted = Array.from(
                //     openDocuments.current.values()
                //   ).sort();
                //   const nextIndex = sorted.findIndex((v, i) => v !== i);
                //   const targetIndex =
                //     nextIndex !== -1 ? nextIndex : sorted.length;

                //   openDocuments.current.add(targetIndex);
                //   return targetIndex;
                // }}
                onDismiss={(index) => openDocuments.current.delete(index)}
              />
              <Icon
                className={fileTypeColor(document?.iconType || 'OTHER')}
                iconName={fileType(document?.iconType || 'OTHER')}
              />
              <Text>{document?.fileReference}</Text>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <Stack>
                <Stack horizontalAlign="end">
                  <Text>Applied</Text>

                  <Stack horizontal>
                    <AmountTextView
                      className={styles.boldText}
                      value={total.toString()}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <Stack horizontalAlign="end">
                  <Stack>
                    <Text>Distributed</Text>
                  </Stack>
                  <Stack horizontal>
                    <AmountTextView
                      className={styles.boldText}
                      value={distributed.toString()}
                    />
                    {/* <Text className={styles.boldText}>
                      {document.currency?.isoCode}
                    </Text> */}
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <Stack horizontalAlign="end">
                  <Stack>
                    <Text>Difference</Text>
                  </Stack>
                  <Stack horizontal>
                    {difference !== 0 ? (
                      <AmountTextView
                        className={styles.boldText}
                        value={difference ? difference.toString() : null}
                      />
                    ) : (
                      <Text className={styles.boldText}>---</Text>
                    )}
                    {/* <Text className={styles.boldText}>
                      {document.currency?.isoCode}
                    </Text> */}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="center">
            <NumberFormat
              className={styles.applyAmount}
              value={metaData?.documentAppliedAmount || ''}
              placeholder="Apply Amount"
              label="Apply Amount"
              customInput={TextField}
              thousandSeparator
              allowNegative={true}
              fixedDecimalScale
              decimalScale={2}
              onBlur={() =>
                setMetaData((prevState) => ({
                  ...prevState!,
                  documentAppliedAmount: (
                    Number(metaData?.documentAppliedAmount) || 0
                  ).toFixed(2),
                }))
              }
              onValueChange={(valuesObject, info) => {
                if (info.source === 'event') {
                  if (valuesObject.value) {
                    setMetaData((prevState) => ({
                      ...prevState!,
                      documentAppliedAmount: valuesObject.value,
                      documentRetiredAmount: null,
                    }));
                  } else {
                    setMetaData((prevState) => ({
                      ...prevState!,
                      documentAppliedAmount: null,
                    }));
                  }
                  // setMetaData(onAppliedAmountChange(valuesObject, metaData))
                }
              }}
            />
            {overageCheckBoxVisible && (
              <Stack
                horizontalAlign="center"
                horizontal
                styles={{ root: { marginTop: 5 } }}
                tokens={{ childrenGap: 10 }}
              >
                <Checkbox
                  required
                  className={styles.checkboxStyles}
                  checked={metaData?.isAppliedAmountOverage}
                  label="Overages"
                  onChange={(_, checked) => {
                    setMetaData((prevState) => ({
                      ...prevState!,
                      isAppliedAmountOverage: !!checked,
                      documentStatusExplanation: undefined,
                    }));
                  }}
                />
                {!metaData?.isAppliedAmountOverage && (
                  <TooltipHost
                    content="Acknowledge the overage and provide an explanation."
                    styles={{
                      root: {
                        marginTop: 26,
                      },
                    }}
                  >
                    <Icon
                      iconName="Info"
                      styles={{ root: { color: 'red', fontSize: 20 } }}
                    />
                  </TooltipHost>
                )}
              </Stack>
            )}
            {retireCheckBoxVisible && (
              <Stack
                horizontalAlign="center"
                horizontal
                styles={{ root: { marginTop: 5 } }}
              >
                <Checkbox
                  className={styles.checkboxStyles}
                  checked={metaData?.isAppliedAmountRetired}
                  label="Retire"
                  onChange={(_, checked) => {
                    setShowRetireAmountFields(!!checked);

                    if (checked === true) {
                      if (
                        metaData?.remainingTotal &&
                        metaData?.documentAppliedAmount
                      ) {
                        let difference = 0.0;
                        difference = new Decimal(metaData?.remainingTotal)
                          .minus(metaData?.documentAppliedAmount)
                          .toNumber();
                        setMetaData((prevState) => {
                          return {
                            ...prevState,
                            isAppliedAmountRetired: true,
                            documentStatusExplanation: undefined,
                            documentRetiredAmount: difference
                              .toFixed(2)
                              .toString(),
                          };
                        });
                      }
                    } else {
                      setMetaData((prevState) => ({
                        ...prevState,
                        isAppliedAmountRetired: false,
                        documentStatusExplanation: undefined,
                        documentRetiredAmount: null,
                      }));
                    }
                  }}
                />
              </Stack>
            )}
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            {showRetireAmountFields && metaData?.isAppliedAmountRetired && (
              <NumberFormat
                className={styles.textFieldStyles}
                value={metaData?.documentRetiredAmount || ''}
                placeholder="Retire Amount"
                label="Retire Amount"
                customInput={TextField}
                thousandSeparator
                allowNegative={true}
                fixedDecimalScale
                decimalScale={2}
                errorMessage={
                  inValidRetireAmountError || nullRetireAmountValueError
                }
                onBlur={() =>
                  setMetaData((prevState) => ({
                    ...prevState,
                    documentRetiredAmount: (
                      Number(metaData?.documentRetiredAmount) || 0
                    ).toFixed(2),
                  }))
                }
                onValueChange={(valuesObject, info) => {
                  if (info.source === 'event') {
                    if (valuesObject.value) {
                      setMetaData((prevState) => ({
                        ...prevState,
                        documentRetiredAmount: valuesObject.value,
                      }));
                    } else {
                      setMetaData((prevState) => ({
                        ...prevState,
                        documentRetiredAmount: null,
                      }));
                    }
                  }
                }}
              />
            )}
            {(metaData?.isAppliedAmountOverage ||
              metaData?.isAppliedAmountRetired) && (
              <TextField
                className={styles.textFieldStyles}
                label="Explanation"
                placeholder="Explanation"
                value={metaData?.documentStatusExplanation}
                errorMessage={explanationErrorhandler}
                onChange={(_, newValue) => {
                  setMetaData((prevState) => ({
                    ...prevState,
                    documentStatusExplanation: newValue,
                  }));
                }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Separator />
      <Stack
        className={styles.footersContainer}
        horizontal
        tokens={{ childrenGap: 20 }}
      >
        <PrimaryButton
          disabled={!!disableAttachButton}
          onClick={onSubmit}
          text="Update"
        />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </Stack>
    </Modal>
  );
};
