import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';

import { BlockBlobClient } from '@azure/storage-blob';
import { AccountingEntryInvoice_invoice } from 'ap/signing/accountingEntry/view/__generated__/AccountingEntryInvoice';
import {
  AvailableInvoiceDocumentTypes,
  AvailableInvoiceDocumentTypesVariables,
} from 'ap/signing/transactionSigning/view/FormView/ActionMenu/__generated__/AvailableInvoiceDocumentTypes';
import {
  InvoiceUploadDocument,
  InvoiceUploadDocumentVariables,
  InvoiceUploadDocument_invoiceUploadDocument,
} from 'ap/signing/transactionSigning/view/FormView/ActionMenu/actionMenuContents/Attach/UploadForm/__generated__/InvoiceUploadDocument';
import { UploadFiles } from 'common/components/UploadFiles';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

const INVOICE_UPLOAD_DOCUMENT = loader(
  '../../../../../../transactionSigning/view/FormView/ActionMenu/actionMenuContents//Attach//UploadForm/InvoiceUploadDocument.graphql'
);
const AVAILABLE_DOCUMENT_TYPES = loader(
  '../../../../../../transactionSigning/view/FormView/ActionMenu/AvailableInvoiceDocumentTypes.graphql'
);
interface UploadFormProps {
  // invoice?: AccountingEntryInvoice;
  invoice: AccountingEntryInvoice_invoice;
  onUpload?: (
    fileSelected: File,
    documentData: InvoiceUploadDocument_invoiceUploadDocument,
    toastId: string
  ) => void;
}
export const UploadForm: React.FC<UploadFormProps> = ({
  invoice,
  onUpload,
}) => {
  const {
    formState: { isDirty },
  } = useFormContext();
  const { updateToast, addToast } = useToasts();
  const [uploadDocument] = useMutation<
    InvoiceUploadDocument,
    InvoiceUploadDocumentVariables
  >(INVOICE_UPLOAD_DOCUMENT);

  const { data: AvailableDocumentTypes } = useQuery<
    AvailableInvoiceDocumentTypes,
    AvailableInvoiceDocumentTypesVariables
  >(AVAILABLE_DOCUMENT_TYPES, {
    variables: {
      transactionTypeId: invoice.transactionTypeId,
      isDocumentUpload: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const documentTypeOptions =
    AvailableDocumentTypes?.invoiceSigningAvailableDocumentTypes?.nodes?.map(
      (doctype) =>
      ({
        key: doctype.id,
        text: doctype.documentType || '',
        isAccountingDocument: doctype.isAccountingDocument,
        extractionTypes: doctype.extractionTypes,
        title:
          (!invoice.isDraft &&
            doctype.isAccountingDocument &&
            'Upload prohibited during/ after the approval process') ||
          undefined,
        disabled:
          (!invoice.isDraft && doctype.isAccountingDocument) ||
          undefined,
      } as DocumentTypeOption)
    ) || [];

  return (
    <UploadFiles
      documentTypes={documentTypeOptions!}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, fileSelected) => {
          fileSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });

            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                invoiceId: invoice.id,
              },
            });

            if (uploadMutationResults.errors)
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });

            if (
              uploadMutationResults.data?.invoiceUploadDocument?.document &&
              uploadMutationResults.data.invoiceUploadDocument.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data?.invoiceUploadDocument,
                toastId!
              );

              const client = new BlockBlobClient(
                uploadMutationResults.data?.invoiceUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
      disableUpload={isDirty}
    />
  );
};
