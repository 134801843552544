import { useQuery } from '@apollo/client';
import { IDropdownOption, Stack } from '@fluentui/react';
import { FormHookDropdown, FormHookTextField } from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { loader } from 'graphql.macro';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CorporateAccountValues } from '../types';
import { CorporateAccountCommonData } from './__generated__/CorporateAccountCommonData';
import { useStyles } from './index.styles';
const CORPORATE_ACCOUNT_COMMON_DATA = loader('./CorporateAccountCommonData.graphql')

export const BasicForm = () => {
  const styles = useStyles();
  const { control } = useFormContext<CorporateAccountValues>();
  const departmentGroupId = useWatch({ name: 'departmentGroupId', control });
  const isTravelAccountForAllDepartments = useWatch({ name: 'isTravelAccountForAllDepartments', control });
  const {
    data: corporateChartOfAccounts,
  } = useQuery<CorporateAccountCommonData>(CORPORATE_ACCOUNT_COMMON_DATA);

  const {
    departmentGroups,
    travelAccountTypes,
  } = { ...corporateChartOfAccounts }

  const departmentGroupsOptions: IDropdownOption[]
    = departmentGroups?.nodes.map((item) => ({ key: item.id, text: item.name })) || [];

  const travelAccountTypesOptions: IDropdownOption[]
    = travelAccountTypes?.nodes.map((item) => ({ key: item.id, text: item.accountType || '' })) || [];

  return (
    <Stack
      tokens={{
        padding: 20,
      }}
      className={styles.container}
    >
      <Stack
        horizontal
        tokens={{
          childrenGap: 15,
        }}
        className={styles.mtb3}
      >
        <Stack
          className={styles.fieldContainer}
        >
          <FormHookTextField label="(SB) Account" name="account" required />
        </Stack>
        <Stack
          className={styles.fieldContainer}
        >
          <FormHookTextField label="(DT) Account" name="subAccount" />
        </Stack>
      </Stack>
      <Stack
        className={styles.mtb3}
      >
        <FormHookTextField label="Name" name="lookupName" required />
      </Stack>
      <Stack
        className={styles.mt15}
      >
        <Stack horizontal>
          <Stack
            style={{ flex: 1 }}
            tokens={{
              childrenGap: 10
            }}
          >
            <FormHookDropdown
              label='Departments'
              placeholder="Select"
              options={departmentGroupsOptions}
              name="departmentGroupId"
              disabled={!!isTravelAccountForAllDepartments}
            />
            <FormHookCheckBox
              label="All Departments"
              name="isTravelAccountForAllDepartments"
              disabled={!!departmentGroupId}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        horizontal
        className={styles.mt20}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label='Travel Section'
            placeholder="Select"
            options={travelAccountTypesOptions}
            name="travelAccountTypeId"
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
