import React, { FC } from 'react';
import { useField } from 'formik';
import { RequiredNameProps } from 'common/types/utility';
import { TextField, ITextFieldProps } from '@fluentui/react';
interface NumberTextFieldProps extends ITextFieldProps {
  allowZeroValue?: boolean;
}

export const FormikNumberTextField: FC<RequiredNameProps<NumberTextFieldProps>> = (
  props
) => {
  const [field, meta, helpers] = useField<string | undefined | null>(
    props.name
  );
  const { value, ...rest } = field;
  const textFieldValue = props.allowZeroValue ? value! : (value || '')
  return (
    <TextField
      {...rest}
      {...props}
      errorMessage={meta.error}
      value={textFieldValue}
      onChange={(_e, value) => {
        if (value) {
          const re = /^[+-]?\d*(?:[.,]\d*)?$/;
          if (re.test(value!)) {
            helpers.setValue(value);
          }
        } else {
          helpers.setValue(null);
        }
      }}
    />
  );
};
