import { TABLE_ROWS } from 'common/constants';
import { UserOccupationTitlesOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { filterOptionProps } from './Filters';
import { Occupations, OccupationsVariables } from './__generated__/Occupations';

type OrderLookup = {
  [direction in OrderDirection]: UserOccupationTitlesOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'userOccupationTitle',
    {
      [OrderDirection.ASC]: [
        UserOccupationTitlesOrderBy.USER_OCCUPATION_TITLE_ASC,
      ],
      [OrderDirection.DESC]: [
        UserOccupationTitlesOrderBy.USER_OCCUPATION_TITLE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const defaultOrderBy = [
    UserOccupationTitlesOrderBy.USER_OCCUPATION_TITLE_ASC,
    UserOccupationTitlesOrderBy.PRIMARY_KEY_ASC,
  ];
  const orderByArray = [UserOccupationTitlesOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return defaultOrderBy;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const onHandleSearch = (
  showMore: boolean,
  sortOrderParam: SortOrder | undefined,
  occupations: Occupations | undefined
) => {
  const variables: OccupationsVariables = {
    first: TABLE_ROWS,
    orderBy: toOrderByVariable(sortOrderParam),
    after: showMore
      ? occupations?.userOccupationTitles?.pageInfo.endCursor
      : undefined,
  };
  return {
    queryVariables: variables,
  };
};

export const toFilterVariable = (filterOptions: filterOptionProps) =>
  filterOptions.filterTypes.map((filter) => {
    switch (filter.filterKey) {
      case 'userOccupationTitle':
        return {
          [filter.filterKey]: {
            [filterOptions.startsWith
              ? 'startsWithInsensitive'
              : 'includesInsensitive']: filter.value,
          },
        };

      default:
        return undefined;
    }
  });
