import { PrimaryButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { AssignmentModal } from './AssignmentModal';
import { ChartOfAccounts_chartOfAccounts_nodes } from '../chartAccount/__generated__/ChartOfAccounts';

export const CorporateAccountAssignment: React.FC<{
  chartOfAccount: ChartOfAccounts_chartOfAccounts_nodes | null;
  onRefetch: () => void;
}> = ({ chartOfAccount, onRefetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Stack>
      <PrimaryButton
        text="Assign Corporate Accounts"
        onClick={() => setIsOpen(true)}
      />
      {isOpen && !!chartOfAccount && (
        <AssignmentModal
          onClose={() => setIsOpen(false)}
          chartOfAccount={chartOfAccount}
          onRefetch={onRefetch}
        />
      )}
    </Stack>
  );
};
