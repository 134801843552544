import {
  ActionButton,
  IStackStyles,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import React, { useState } from 'react';
import { AttachableBatchTransactionDocuments_attachableBatchTransactionDocuments_nodes } from '../../__generated__/AttachableBatchTransactionDocuments';
import { AttachBatchDocumentsModal } from './AttachBatchDocumentsModal';
const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };

export type AttachableBatchDocumentsType =
  AttachableBatchTransactionDocuments_attachableBatchTransactionDocuments_nodes;

export interface AttachedBatchDocuments {
  attachedDocuments: AttachableBatchDocumentsType[];
}

export interface AttachBatchDocumentsProps {
  // onDocumentsAttached: (
  //   attachedDocumentsData: AttachedBatchDocuments
  // ) => void;
  onDocumentsAttached: (
    attachedDocumentsData: AttachableBatchDocumentsType[]
  ) => void;
  prevSelectedDocuments?: AttachableBatchDocumentsType[];
}
export const AttachBatchDocuments: React.FC<AttachBatchDocumentsProps> = (
  props
) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  return (
    <>
      <Stack
        horizontal
        styles={stackStyles}
        horizontalAlign="end"
        style={{ padding: '30px 20px' }}
      >
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Documents"
            checked={true}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachBatchDocumentsModal {...props} setOpen={setDialogVisible} />
      )}
    </>
  );
};
