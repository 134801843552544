import { Stack, Text } from "@fluentui/react";
import { ExtendedMessageFields_extendedMessage_messageSections_nodes } from "common/graphql/__generated__/ExtendedMessageFields";
import { useCommonStyles } from "common/styles";
import React from "react";
import { useStyles } from './index.styles';
import { TableCell } from "./TableCell";
import { TableHeader } from "./TableHeader";
export type ExtendedMessageData = ExtendedMessageFields_extendedMessage_messageSections_nodes;
interface ExtendedMessageProps {
    extendedData: ExtendedMessageData[];
}

export const ExtendedMessage: React.FC<ExtendedMessageProps> =
    ({ extendedData }) => {
        const styles = useStyles();
        const commonStyles = useCommonStyles();
        return (
            <Stack
                className={styles.sectionMainContainer}
                tokens={{ childrenGap: 20 }}
            >
                {
                    extendedData.map((item, index) => {
                        const columns = new Array(item.tableColumns).fill(null);
                        return (
                            <Stack key={index}>
                                <Text variant={'medium'} className={commonStyles.bold}>
                                    {item.sectionHeader}
                                </Text>
                                {item.sectionBody && <Text>{item.sectionBody}</Text>}
                                <Stack className={styles.marginTop10} horizontal>
                                    {
                                        columns.map((_ele, index) =>
                                            <TableHeader
                                                column={item}
                                                key={index}
                                                index={index}
                                                numberOfColumns={item.tableColumns!}
                                            />
                                        )
                                    }
                                </Stack>
                                <Stack>
                                    {item.messageTableColumns.nodes.map((tableItem, tableIndex) => {
                                        const tableRows = item.messageTableColumns.nodes.length;
                                        return (
                                            <Stack horizontal key={tableIndex}>
                                                {columns.map((_, rowIndex: number) => (
                                                    <TableCell
                                                        column={item}
                                                        cellData={tableItem}
                                                        cellIndex={rowIndex}
                                                        numberOfColumns={item.tableColumns!}
                                                        numberOfRows={tableRows}
                                                        rowNumber={tableIndex}
                                                        key={tableIndex + rowIndex}
                                                    />
                                                ))}
                                            </Stack>
                                        );
                                    })}
                                </Stack>
                                <Stack className={styles.marginTop10}>
                                    {item.sectionFooter && <Text>{item.sectionFooter}</Text>}
                                </Stack>
                            </Stack>
                        )
                    })
                }
            </Stack>
        );
    };