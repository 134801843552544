import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.white,
    paddingRight: 20,
  },
  closeButtonHoverStyle: {
    ':hover': {
      backgroundColor: theme.palette.orange,
      color: theme.palette.white,
    },
  },
  containerStyle: {
    position: 'absolute',
    width: 180,
    right: 20,
    backgroundColor: theme.palette.orangeLighter,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    zIndex: 1000000,
    borderRadius: 2,
    marginRight: 5,
  },
}));
