import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const getColumns = () => {
  const columns: ColumnData[] = [
    {
      key: 'workgroupNumber',
      name: 'Workgroup #',
      fieldName: 'workgroupNumber',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'name',
      name: 'Title',
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 200,
      ...commonColumnProps,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 300,
      maxWidth: 300,
      ...commonColumnProps,
    },
    {
      key: 'businessUnit',
      name: 'Business Unit',
      fieldName: 'businessUnit',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'corporateChartOfAccount',
      name: 'Chart of Accounts',
      fieldName: 'corporateChartOfAccount',
      minWidth: 180,
      maxWidth: 180,
      ...commonColumnProps,
    },
    {
      key: 'productionPhase',
      name: 'Production Phase',
      fieldName: 'productionPhase',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'isNewWorkgroupSelectable',
      name: 'Active',
      fieldName: 'isNewWorkgroupSelectable',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
  ];
  return columns;
};
