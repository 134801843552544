import { dateConvertions } from 'common/utils/dateFormats';
import { PURCHASE_ORDER_INITIAL_VALUES } from './constant';
import { PurchaseOrderValues } from './types';
import { PurchaseOrder } from './__generated__/PurchaseOrder';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';

interface DefaultValueProps {
  isNew: boolean;
  purchaseOrderData: PurchaseOrder | undefined;
  userDefaultsData: UserDefaults_userDefaults_nodes[];
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, purchaseOrderData, userDefaultsData } = { ...props };
  const {
    businessUnitId,
    currencyId,
    departmentId,
    defaultPurchaseOrderTypeId,
  } = {
    ...userDefaultsData?.[0],
  };

  const { supplier, ...purchaseOrderDetails } = {
    ...purchaseOrderData?.purchaseOrder,
  };

  let defaultValues: PurchaseOrderValues = {
    ...PURCHASE_ORDER_INITIAL_VALUES,
    purchaseOrderTypeId: defaultPurchaseOrderTypeId || null,
    businessUnitId: businessUnitId || null,
    currencyId: currencyId || null,
    departmentId: departmentId || null,
  };

  if (purchaseOrderData?.purchaseOrder && !isNew) {
    defaultValues = {
      ...purchaseOrderData?.purchaseOrder,
      purchaseOrderDate: purchaseOrderDetails.purchaseOrderDate
        ? dateConvertions(purchaseOrderDetails.purchaseOrderDate)
        : null,
    };
  }

  return defaultValues as PurchaseOrderValues;
};
