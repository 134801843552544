import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  DirectionalHint,
  FocusTrapCallout,
  FocusZone,
  FocusZoneTabbableElements,
  IconButton,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { CardCompanyRowItem } from '../../list';
import {
  CompanyCardCompanies,
  CompanyCardCompaniesVariables
} from '../../list/__generated__/CompanyCardCompanies';
import { useStyles } from './index.styles';
import {
  CompanyCardCompanyDelete,
  CompanyCardCompanyDeleteVariables
} from './__generated__/CompanyCardCompanyDelete';
const DELETE_COMPANY_CARD = loader('./CompanyCardCompanyDelete.graphql');
const CARD_COMPANIES = loader('../../list/CompanyCardCompanies.graphql');
interface DeleteCardCompanyProps {
  item: CardCompanyRowItem;
  onDelete: () => void;
}
export const DeleteCardCompany: React.FC<DeleteCardCompanyProps> = ({
  item,
  onDelete
}) => {
  const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
  const { addToast } = useToasts();
  const styles = useStyles();
  const buttonId = useId('callout-button');

  const [deleteCompanyCard, { loading, }] = useMutation<
    CompanyCardCompanyDelete,
    CompanyCardCompanyDeleteVariables
  >(DELETE_COMPANY_CARD, { errorPolicy: 'all' });

  const toogleCalloutVisiblity = () => {
    setCalloutVisible((prevState) => !prevState);
  }

  const _onDeleteCompanyCard = async () => {
    const { errors } = await deleteCompanyCard({
      variables: {
        input: {
          entityDelete: [{ id: item.id!, rowTimestamp: item._rowTimestamp! }],
        },
      },
      update: (cache, { data }) => {
        const deletedIds = data?.companyCardCompanyDelete?.deletedEntities?.map(
          (entity) => entity?.id
        );

        const cacheData = cache.readQuery<
          CompanyCardCompanies,
          CompanyCardCompaniesVariables
        >({
          query: CARD_COMPANIES,
        });
        if (deletedIds) {
          const filteredList = cacheData?.companyCardCompanies?.nodes.filter(
            (emp) => deletedIds.indexOf(emp.id) === -1
          );
          const newData: CompanyCardCompanies = {
            ...cache,
            companyCardCompanies: {
              ...cacheData?.companyCardCompanies,
              nodes: filteredList!,
            },
          };
          cache.writeQuery<CompanyCardCompanies, CompanyCardCompaniesVariables>(
            {
              query: CARD_COMPANIES,
              data: newData,
            }
          );
        }
      },
    });

    if (!errors) {
      onDelete();
      toogleCalloutVisiblity();
      addToast('Company Card deleted Successfully', {
        appearance: 'success',
      });
    } else
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
  };

  return (
    <Stack>
      <IconButton
        disabled={!item._isDeletable!}
        iconProps={{ iconName: 'Delete' }}
        id={buttonId}
        onClick={() => setCalloutVisible(true)}
      />

      {calloutVisible && (
        <FocusTrapCallout
          role="alertdialog"
          className={styles.callout}
          gapSpace={0}
          directionalHint={DirectionalHint.rightCenter}
          target={`#${buttonId}`}
          onDismiss={() => setCalloutVisible(false)}
          setInitialFocus
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <Text block variant="xLarge">
              Delete Card Company ?
            </Text>
            <Text block variant="small">
              Clicking on Delete will remove this card company from this list
            </Text>

            {loading && <ProgressIndicator />}
            <FocusZone
              handleTabKey={FocusZoneTabbableElements.all}
              isCircularNavigation
            >
              <Stack className={styles.buttons} gap={8} horizontal>
                <PrimaryButton onClick={_onDeleteCompanyCard}>Delete</PrimaryButton>
                <DefaultButton onClick={toogleCalloutVisiblity}>
                  Cancel
                </DefaultButton>
              </Stack>
            </FocusZone>
          </Stack>
        </FocusTrapCallout>
      )}
    </Stack>
  );
};
