import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { ColumnData } from 'common/components/SearchBar';
import { CurrentUserProfile } from 'common/graphql/__generated__/CurrentUserProfile';
import { TransactionLayout } from 'common/types/globalTypes';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 0,
  },
};

export const getColumns = (
  currentUserProfile: CurrentUserProfile | undefined,
  usersDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const isSm =
    usersDefaults?.listviewSize === TransactionLayout.LISTVIEW_SIZE_SMALL;
  const isLg =
    usersDefaults?.listviewSize === TransactionLayout.LISTVIEW_SIZE_LARGE;

  const columns: ColumnData[] = [
    {
      key: '_urgencyLevel',
      name: '',
      fieldName: '_urgencyLevel',
      showColumnTitle: 'Urgent',
      minWidth: 10,
      maxWidth: 10,
      styles: colHeaderLeftAlignStyle,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
    {
      key: 'travelAuthorizationNumber',
      name: 'Number',
      fieldName: 'travelAuthorizationNumber',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isSorted: true,
      isSortedDescending: true,
    },
    {
      key: 'comment',
      name: 'Travel Plan',
      fieldName: 'comment',
      minWidth: 140,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: '_travelerReference',
      name: 'Travelers',
      fieldName: '_travelerReference',
      minWidth: 100,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'tripPurpose',
      name: 'Purpose of Trip',
      fieldName: 'tripPurpose',
      minWidth: 100,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: !isSm,
    },

    {
      key: '_suppliers',
      name: 'Suppliers',
      fieldName: '_suppliers',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_poStatus',
      name: 'PO Status',
      fieldName: '_poStatus',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'travelAuthorizationDate',
      name: 'Date',
      fieldName: 'travelAuthorizationDate',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
    },

    {
      key: 'department',
      name: 'Department',
      fieldName: 'department',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: 'businessUnit',
      name: 'Business Unit',
      fieldName: 'businessUnit',
      minWidth: 90,
      maxWidth: 90,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'corporateWorkgroupName',
      name: 'Workgroup',
      fieldName: 'corporateWorkgroupName',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_isCorporateTravelAuthorization',
      name: 'Origin',
      fieldName: '_isCorporateTravelAuthorization',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'currency',
      name: 'Currency',
      fieldName: 'currency',
      minWidth: 60,
      maxWidth: 60,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: 'budgetAmount',
      name: 'Budget',
      fieldName: 'budgetAmount',
      minWidth: 140,
      maxWidth: 140,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },

    {
      key: 'controlTotalAmount',
      name: 'Total',
      fieldName: 'controlTotalAmount',
      minWidth: 140,
      maxWidth: 140,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: 'transactionStamp',
      name: 'Accounting Trans #',
      fieldName: 'transactionStamp',
      minWidth: 50,
      maxWidth: 150,
      ...commonColumnProps,
      isVisible: false,
      toggleVisibility: false,
    },
    {
      key: '_accountingStampTransactionReference',
      name: 'Transaction #',
      fieldName: '_accountingStampTransactionReference',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_createdByUserName',
      name: 'Created By',
      fieldName: '_createdByUserName',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_createdDate',
      name: 'Created On',
      fieldName: '_createdDate',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 160,
      ...commonColumnProps,
    },
    {
      key: 'sectionIcons',
      name: '',
      fieldName: 'sectionIcons',
      minWidth: 100,
      maxWidth: 200,
      ...commonColumnProps,
      toggleVisibility: false,
    },
  ];

  return columns;
};
