import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  logo: {
    fill: theme.palette.neutralPrimary,
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  amountStack: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
    textAlign: 'right',
  },
  footerRow: {
    backgroundColor: theme.palette.neutralLighter,
  },
  onrenderColumnStack: {
    height: 25,
  },
  currencyCell: {
    height: 25,
    alignItems: "center"
  },
  marginT10: {
    marginTop: 10,
  },
}));
