import { FilterArrayType } from 'common/components/Filters';
import { ApprovalsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: ApprovalsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'approvalType',
    {
      [OrderDirection.ASC]: [
        ApprovalsOrderBy.APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        ApprovalsOrderBy.APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_DESC,
      ],
    },
  ],
  [
    'name',
    {
      [OrderDirection.ASC]: [ApprovalsOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [ApprovalsOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [ApprovalsOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [ApprovalsOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'priorityNumber',
    {
      [OrderDirection.ASC]: [ApprovalsOrderBy.PRIORITY_NUMBER_ASC],
      [OrderDirection.DESC]: [ApprovalsOrderBy.PRIORITY_NUMBER_DESC],
    },
  ],
  [
    'isAddApproversEligible',
    {
      [OrderDirection.ASC]: [ApprovalsOrderBy.IS_ADD_APPROVERS_ELIGIBLE_ASC],
      [OrderDirection.DESC]: [ApprovalsOrderBy.IS_ADD_APPROVERS_ELIGIBLE_DESC],
    },
  ],
  [
    'phaseName',
    {
      [OrderDirection.ASC]: [
        ApprovalsOrderBy.APPROVAL_PHASE_BY_ID__PHASE_NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        ApprovalsOrderBy.APPROVAL_PHASE_BY_ID__PHASE_NAME_DESC,
      ],
    },
  ],
  [
    'status',
    {
      [OrderDirection.ASC]: [
        ApprovalsOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        ApprovalsOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    ApprovalsOrderBy.APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC,
    ApprovalsOrderBy.PRIORITY_NUMBER_ASC,
  ];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const toPaymentFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'approvalTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      case '_statusTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      default:
        return undefined;
    }
  });
