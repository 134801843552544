import { NetworkStatus, useQuery, useReactiveVar } from '@apollo/client';
import {
  DetailsRow,
  DirectionalHint,
  IColumn,
  IDetailsListProps,
  IDetailsRowBaseProps,
  IDetailsRowStyles,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';
import {
  PayCycleSelectedType,
  PayTrackngPivotTabs,
  PaymentCyclesOptions,
  paymentTrackingTabMode,
} from 'ap/paymentTracking';

import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { InvoiceFilter, InvoicesOrderBy } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentProfile, setGlobalSearchText } from 'utility/cache/ui';
import { TransactionSearchBar } from '../TransactionSearchBar';
import { useStyles } from './index.styles';

import {
  GetPaymentCycles,
  GetPaymentCyclesVariables,
} from 'ap/paymentTracking/view/PaymentTrackingView/BasicForm/__generated__/GetPaymentCycles';
import clsx from 'clsx';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { FilterArrayType } from 'common/components/Filters';
import { StatusIcon } from 'common/components/StatusIcon';
import { getFilterParams, toFilterVariable, toOrderByVariable } from '../utils';
import {
  PaymentsInvoicesSigningSearch,
  PaymentsInvoicesSigningSearchVariables,
  PaymentsInvoicesSigningSearch_invoiceSigningSearch_nodes,
} from 'ap/paymentTracking/__generated__/PaymentsInvoicesSigningSearch';
import {
  PaymentsInvoicesSearchFilterTotals,
  PaymentsInvoicesSearchFilterTotalsVariables,
} from 'ap/paymentTracking/__generated__/PaymentsInvoicesSearchFilterTotals';
import { useColumns } from './columns.data';
const PAYMENT_CYCLES = loader(
  '../../view/PaymentTrackingView/BasicForm/GetPaymentCycles.graphql'
);
const INVOICES = loader('../../PaymentsInvoicesSigningSearch.graphql');
const INVOICES_FILTER_TOTAL = loader(
  '../../PaymentsInvoicesSearchFilterTotals.graphql'
);

let selectedPayCycle: PaymentCyclesOptions | null;

export type InvoiceRow =
  PaymentsInvoicesSigningSearch_invoiceSigningSearch_nodes & {
    name: string | null | undefined;
    date: string | null;
    purchaseOrder: string | null | undefined;
    corporatePeriod: string | null | undefined;
    amount: string;
    status: string | null | undefined;
    requesterDate: string;
    approvers: string;
    approvedDate: string;
    amendedByName: string;
    rejectors: string;
    rejectedDate: string;
    amendedDate: string;
    requesterName: string;
    transactionType: string;
    isDocumentSigningPayment: boolean | undefined;
    production: string | null;
    currencyName: string | null;
  };

export interface paymentTrackingNavProps {
  clearSelection?: boolean | undefined;
  reloadList?: boolean | undefined;
}

interface StampedTransactionsProps {
  payCycle: PayCycleSelectedType | undefined;
  onPayCycleChange: (payCycle: PayCycleSelectedType | undefined) => void;
}

export const StampedTransactions: React.FC<StampedTransactionsProps> = ({
  payCycle,
  onPayCycleChange,
}) => {
  const commonStyles = useCommonStyles();
  const { state } = useLocation<paymentTrackingNavProps | undefined>();
  const history = useHistory();
  const styles = useStyles();
  const { columns } = useColumns();
  const renderRef = useRef(false);
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const payTrackTabState = useReactiveVar(paymentTrackingTabMode);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>();
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const [selectedRows, setSelectedRows] = useState<InvoiceRow[]>([]);
  const [paymentRowDocument, setPaymentRowDocument] = useState<InvoiceRow>();
  const [searchTextValue, setSearchTextValue] = useState<string | null>(null);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>({
    column: '_accountingStampTransactionReference',
    direction: 'asc' as OrderDirection,
  });
  const [payCycleSelected, setPayCycleSelected] = useState<
    PayCycleSelectedType | undefined
  >(payCycle);
  const currentProfileData = useReactiveVar(setCurrentProfile);
  const [paymentCyclesOptions, setPaymentCyclesOptions] = useState<
    PaymentCyclesOptions[]
  >([]);

  const [isShowSelectError, setIsShowSelectError] = useState(false);

  const {
    loading: invoicesLoading,
    data: invoicesData,
    networkStatus,
    fetchMore,
    refetch,
    variables: invoicesSigningVariables,
  } = useQuery<
    PaymentsInvoicesSigningSearch,
    PaymentsInvoicesSigningSearchVariables
  >(INVOICES, {
    variables: {
      isSigningSearch: false,
      isHistorySearch: true,
      first: TABLE_ROWS,
      invoiceSigningSearchSearchRequest: {
        searchText: [''],
        searchHintId: 100,
      },
      orderBy: [
        InvoicesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
        InvoicesOrderBy.PRIMARY_KEY_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const {
    data: invoicesFilterTotal,
    refetch: refetchFilterTotal,
    variables: FilterTotalVariables,
  } = useQuery<
    PaymentsInvoicesSearchFilterTotals,
    PaymentsInvoicesSearchFilterTotalsVariables
  >(INVOICES_FILTER_TOTAL, {
    variables: {
      isSigningSearch: false,
      isHistorySearch: true,
      invoiceSigningSearchSearchRequest: {
        searchText: [''],
        searchHintId: 100,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const { data: paymentCycles } = useQuery<
    GetPaymentCycles,
    GetPaymentCyclesVariables
  >(PAYMENT_CYCLES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (paymentCycles?.payCycles?.nodes) {
      const cycles =
        paymentCycles.payCycles?.nodes
          .filter((item) => !!item._isPaymentSelectable)
          .map((item) => {
            const bankAccountNumber = item.paymentAccount?.bankAccountNumber;
            const bankName = `${item.paymentAccount?.name} ${
              bankAccountNumber ? bankAccountNumber : ''
            }`;
            return {
              key: item.id,
              text: `${item.description} ${bankName}` || '',
              currency: item.currency?.isoCode + '-' + item.currency?.name!,
              defaultPaymentDate: item.defaultPaymentDate
                ? new Date(dateConvertions(item.defaultPaymentDate!))
                : null,
              transactionTypeId: item.transactionTypeId,
              currencyId: item.currency?.id!,
              businessUnit: item.businessUnit?.name!,
              businessUnitId: item.businessUnitId!,
            };
          }) || [];
      setPaymentCyclesOptions(cycles);
    }
  }, [paymentCycles]);

  useEffect(() => {
    if (state?.clearSelection) {
      setSelectedRows([]);
      setPaymentRowDocument(undefined);
    }
    if (state?.reloadList) {
      refetch();
      refetchFilterTotal();
    }

    window.history.replaceState({}, document.title);
  }, [state, refetch, refetchFilterTotal]);

  const refetching =
    invoicesLoading && networkStatus !== NetworkStatus.fetchMore;

  let transformedData = refetching
    ? undefined
    : invoicesData?.invoiceSigningSearch?.nodes.map(
        (inv) =>
          ({
            ...inv,
            ...inv.invoiceHistoryApprover,
            id: inv.id,
            transactionType: inv.transactionType?.transactionType,
            businessUnit: inv.businessUnit?.name,
            name: inv.vendorReference,
            description: inv.description,
            department: inv.department?.name,
            invoiceNumber: inv.invoiceNumber,
            date: inv.invoiceDate
              ? dateFormat(dateConvertions(inv.invoiceDate))
              : '',
            corporatePeriod: inv.companyCorporatePeriod?._periodYear,
            amount: inv.controlTotalAmount,
            _baseCurrencyAmount: inv._baseCurrencyAmount,
            _spotCurrencyAmount: inv._spotCurrencyAmount,
            currency: inv.currency?.isoCode,
            status: inv.statusType?.statusType,
            production: inv.company?.name,
            isDocumentSigningPayment:
              inv.transactionType?.isDocumentSigningPayment,
          } as InvoiceRow)
      );

  const _renderItemColumn = (
    item: InvoiceRow | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof InvoiceRow
      ] as string;

      const approverData = item.invoiceHistoryApprover?.approvers
        ? item.invoiceHistoryApprover?.approvers.split(',').join(', ')
        : '';
      switch (column?.key) {
        case 'transactionType':
          return (
            <HighLightActiveLink
              highlightText={globalSearchText}
              text={item?.transactionType}
              preventDefault
              onClick={() => {
                setSelectedInvoice(item.id);
              }}
            />
          );
        case '_baseCurrencyAmount':
        case '_spotCurrencyAmount':
        case 'amount':
          return (
            <AmountColumnTextView
              value={fieldContent}
              searchText={globalSearchText}
            />
          );
        case 'currency':
          return (
            <Stack verticalAlign="center" className={styles.currencyCell}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );

        case 'requesterDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.requesterDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.requesterDate!
                  )}
              </Text>
            </Stack>
          );
        case 'approvedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.approvedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.approvedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'rejectedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.rejectedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.rejectedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'amendedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.amendedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.amendedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 10 }}
            >
              <HighlightTextView
                className={styles.statusType}
                highlightText={globalSearchText}
                text={convertToTitleCase(item?.status!)!}
              />
              <Stack>
                <StatusIcon
                  approval={false}
                  iconType={item.statusType!}
                  approvalData={item}
                />
              </Stack>
            </Stack>
          );

        case 'approvers':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={approverData!}
              />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const filterParams = getFilterParams(searchFilters || []);

  const handleSearch = async (showMore: boolean) => {
    if (!showMore) setSelectedRows([]);
    const searchRequest = {
      searchText: [searchTextValue ? searchTextValue : ''],
      searchHintId: 100,
    };

    let filter = searchFilters?.length
      ? ({ and: toFilterVariable(searchFilters) } as InvoiceFilter)
      : undefined;

    if (selectedPayCycle?.businessUnitId && selectedPayCycle?.currencyId) {
      filter = {
        currencyId: { equalTo: selectedPayCycle?.currencyId },
        businessUnitId: { equalTo: selectedPayCycle?.businessUnitId },
        and: searchFilters?.length
          ? (toFilterVariable(searchFilters) as InvoiceFilter[])
          : [],
      };
    }
    const variables: PaymentsInvoicesSigningSearchVariables = {
      isSigningSearch: false,
      isHistorySearch: true,
      first: TABLE_ROWS,
      after: showMore
        ? invoicesData?.invoiceSigningSearch?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
      filter: filter,
      invoiceSigningSearchSearchRequest: searchRequest,
      isLinkSearch: false,
    };
    const totalFilterVariables: PaymentsInvoicesSearchFilterTotalsVariables = {
      isSigningSearch: false,
      isHistorySearch: true,
      invoiceSigningSearchSearchRequest: searchRequest,
      isLinkSearch: false,
      ...filterParams,
    };
    if (showMore) await fetchMore?.({ variables });
    else {
      refetch(variables);
      refetchFilterTotal(totalFilterVariables);
    }
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [searchFilters, sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    if (!renderRef.current) renderRef.current = true;
    else {
      handleSearchMemo(false);
    }
  }, [searchTextValue, handleSearchMemo]);

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const { totalAmount1, totalAmount2, totalAmount3 } = {
        ...invoicesFilterTotal?.invoiceSigningSearchFilterTotals,
      };
      const fieldContent =
        column?.key === 'amount'
          ? totalAmount1
          : column?.key === '_baseCurrencyAmount'
          ? totalAmount2
          : column?.key === '_spotCurrencyAmount'
          ? totalAmount3
          : '';

      return (
        <AmountTextView
          variant="medium"
          className={clsx(styles.amountStack, styles.contentColumnAlignRight)}
          value={fieldContent!}
        />
      );
    };

  const showFooter =
    !refetching &&
    parseFloat(
      invoicesFilterTotal?.invoiceSigningSearchFilterTotals?.totalAmount1 ||
        '0.0'
    ) > 0 &&
    invoicesFilterTotal?.invoiceSigningSearchFilterTotals?.totalCount1! > 0;

  const setBaseAmountHeading = () => {
    if (currentProfileData) {
      const updatedColumns: ColumnData[] = gridColumns.map((col) => {
        switch (col.fieldName) {
          case '_baseCurrencyAmount':
            return {
              ...col,
              name: `${currentProfileData.currentUserProfile?.company?.currency?.isoCode} Amount`,
            };
          case '_spotCurrencyAmount':
            return {
              ...col,
              name: `${currentProfileData.currentUserProfile?.company?.currency?.isoCode} Spot Rate`,
            };
          default:
            return { ...col };
        }
      });
      setGridColumns(updatedColumns);
    }
  };

  const setBaseAmountHeadingMemo = useCallback(setBaseAmountHeading, [
    currentProfileData,
  ]);

  useEffect(() => {
    setBaseAmountHeadingMemo();
  }, [setBaseAmountHeadingMemo]);

  const canSelectItem = useCallback((item) => {
    return (
      item.currencyId === selectedPayCycle?.currencyId &&
      item.businessUnit === selectedPayCycle?.businessUnit
    );
  }, []);

  useEffect(() => {
    if (selectedRows.length) {
      const data = selectedRows.filter((item) => item.isDocumentSigningPayment);
      if (data.length === 1) {
        setIsShowSelectError(false);
      } else if (data.length > 1) {
        setIsShowSelectError(true);
      } else {
        setIsShowSelectError(false);
      }
    } else {
      setIsShowSelectError(false);
    }
  }, [selectedRows]);

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const { item } = { ...props };
    const customStyles: Partial<IDetailsRowStyles> = {};
    const tootTipMessage = 'Approved Payment Request';
    const tooltipVisible = item.isDocumentSigningPayment;
    if (!props) {
      return null;
    }
    if (tooltipVisible) {
      customStyles.root = {
        backgroundColor: '#1dba6e1c',
      };
      return (
        <TooltipHost
          content={tootTipMessage}
          directionalHint={DirectionalHint.leftCenter}
        >
          <DetailsRow {...props} styles={customStyles} />
        </TooltipHost>
      );
    } else return <DetailsRow {...props} />;
  };

  const onPaymentTrackingClick = () => {
    //Check if isDocumentSigningPayment exist in the selected documents
    const isPaymentRowExist = selectedRows.some(
      (row) => row.isDocumentSigningPayment
    );
    if (isPaymentRowExist) {
      //Select the last payment row from all the selected rows
      const paymentRowDocument = selectedRows
        .filter((row) => row.isDocumentSigningPayment)
        .pop();
      if (paymentRowDocument) {
        setPaymentRowDocument(paymentRowDocument);
        const amountTotal: number = parseFloat(paymentRowDocument.amount);
        const paymentCycleId = selectedPayCycle?.key;
        const paymentCycleCurrency = selectedPayCycle?.currency;
        const paymentCycleTransactionTypeId =
          selectedPayCycle?.transactionTypeId;
        history.push('/pay/payment_tracking/payment', {
          selectedRows,
          amountTotal,
          paymentRowDocument,
          paymentCycleId,
          paymentCycleCurrency,
          paymentCycleTransactionTypeId,
        });
      }
    } else {
      setPaymentRowDocument(undefined);
      const amountTotal: number = selectedRows
        .filter((row) => !row.isDocumentSigningPayment)
        .map((item) => item.amount)
        .reduce((prev, curr) => {
          return prev + parseFloat(curr || '0.0');
        }, 0);
      const paymentCycleId = selectedPayCycle?.key;
      const paymentCycleCurrency = selectedPayCycle?.currency;
      const paymentCycleDate = selectedPayCycle?.defaultPaymentDate;
      const paymentCycleTransactionTypeId = selectedPayCycle?.transactionTypeId;
      history.push('/pay/payment_tracking/payment', {
        selectedRows,
        amountTotal,
        paymentRowDocument,
        paymentCycleId,
        paymentCycleCurrency,
        paymentCycleDate,
        paymentCycleTransactionTypeId,
      });
    }
  };

  //Function to to retain the selected Pay Cycle value on the tab switch.
  const refreshInvoiceList = () => {
    if (payCycle) {
      const variables: PaymentsInvoicesSigningSearchVariables = {
        ...invoicesSigningVariables,
        filter: {
          currencyId: { equalTo: payCycleSelected?.currencyId },
          businessUnitId: { equalTo: payCycleSelected?.businessUnitId },
          and: searchFilters?.length
            ? (toFilterVariable(searchFilters) as InvoiceFilter[])
            : [],
        },
      };
      const filterTotalVariables: PaymentsInvoicesSearchFilterTotalsVariables =
        {
          ...FilterTotalVariables,
          ...filterParams,
          filterBusinessUnitId: payCycleSelected?.businessUnitId,
          filterCurrencyId: payCycleSelected?.currencyId,
        };
      refetch(variables);
      refetchFilterTotal(filterTotalVariables);
    }
  };

  const refreshInvoiceListMemo = useCallback(refreshInvoiceList, [
    payCycle,
    invoicesSigningVariables,
    payCycleSelected,
    searchFilters,
  ]);

  useEffect(() => {
    refreshInvoiceListMemo();
  }, [refreshInvoiceListMemo]);

  const resetInvoiceList = () => {
    const variables: PaymentsInvoicesSigningSearchVariables = {
      ...invoicesSigningVariables,
      filter: searchFilters?.length
        ? ({ and: toFilterVariable(searchFilters) } as InvoiceFilter)
        : undefined,
    };
    const filterTotalVariables: PaymentsInvoicesSearchFilterTotalsVariables = {
      ...FilterTotalVariables,
      ...filterParams,
    };
    refetch(variables);
    refetchFilterTotal(filterTotalVariables);
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Payments</Text>
          {selectedRows.length > 0 && selectedPayCycle?.key && (
            <PrimaryButton
              onClick={onPaymentTrackingClick}
              iconProps={{ iconName: 'Add' }}
              text="New Payment"
            />
          )}
        </Stack>
      </Stack>
      {isShowSelectError && (
        <Stack
          tokens={{ padding: '0px 20px 0px 0px' }}
          horizontalAlign="end"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="small" style={{ color: 'red' }}>
            Only 1 Approved transaction request can be selected
          </Text>
        </Stack>
      )}

      <Stack tokens={{ padding: '0px 0px 10px 20px', childrenGap: 10 }}>
        <Stack horizontal>
          <Stack className={styles.pivotContainer}>
            <Pivot
              selectedKey={payTrackTabState}
              onLinkClick={(item: PivotItem | undefined) => {
                paymentTrackingTabMode(
                  item?.props.itemKey || PayTrackngPivotTabs.paymentTracking
                );
                selectedPayCycle = null;
              }}
              aria-label="Menu_Actions"
            >
              <PivotItem
                itemKey={PayTrackngPivotTabs.paymentTracking}
                headerText="Payments"
              />
              <PivotItem
                itemKey={PayTrackngPivotTabs.stampedTransaction}
                headerText="Available Transactions"
              />
            </Pivot>
          </Stack>
          <Stack className={styles.paymentCyclesDropdownContainer}>
            <CustomDropdown
              placeholder="Choose pay cycle"
              options={paymentCyclesOptions}
              selectedKey={payCycleSelected?.key ? payCycleSelected.key : null}
              onChange={(_, option) => {
                const selectedOption = option as PaymentCyclesOptions;
                const { text, ...payCycleProperties } = { ...selectedOption };
                selectedPayCycle = selectedOption;
                setSelectedRows([]);
                onPayCycleChange(payCycleProperties);
                setPayCycleSelected(payCycleProperties);
              }}
              onClear={() => {
                selectedPayCycle = null;
                resetInvoiceList();
                onPayCycleChange(undefined);
                setPayCycleSelected(undefined);
              }}
              className={styles.paymentCyclesDropdown}
              underlined
            />
          </Stack>
        </Stack>
        <TransactionSearchBar
          columns={gridColumns}
          onRefresh={() => handleSearch(false)}
          onEnterPress={(value) => {
            setSearchTextValue(value);
            setGlobalSearchText(value);
          }}
          onFilterChange={setSearchFilters}
          onToggleVisibility={(columns) => {
            setGridColumns(columns);
          }}
        />
      </Stack>
      <InfiniteList
        loading={invoicesLoading}
        items={transformedData}
        hasNextPage={invoicesData?.invoiceSigningSearch?.pageInfo.hasNextPage}
        showFooter={showFooter}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true)}
        onSelectionChanged={setSelectedRows}
        selectedRows={selectedRows}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        canSelectItem={canSelectItem}
        onRenderRow={onRenderRow}
        totalRowsCount={
          invoicesFilterTotal?.invoiceSigningSearchFilterTotals?.totalCount1
        }
      />

      {selectedInvoice !== undefined && (
        <TransactionModal
          entityId={selectedInvoice}
          isOpen={selectedInvoice !== undefined}
          onDismiss={() => setSelectedInvoice(undefined)}
        />
      )}
    </>
  );
};
