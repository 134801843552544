import React, { useState, useEffect } from 'react';
import { Stack, Text } from '@fluentui/react';
import { PaymentDataItem } from './PaymentDataItem';
import { useStyles } from './index.styles';
import { TransactionModal } from '../TransactionModal';
import {
  GetPayCycleDetails,
  GetPayCycleDetails_payCycle_payments,
  GetPayCycleDetails_payCycle_payments_nodes,
} from '../__generated__/GetPayCycleDetails';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DocumentViewModalState } from 'common/components/DocumentList';

interface PaymentsProps {
  payCycle?: GetPayCycleDetails;
  paymentListData?: GetPayCycleDetails_payCycle_payments;
}

export const Payments: React.FC<PaymentsProps> = ({
  paymentListData,
  payCycle,
}) => {
  const styles = useStyles();
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const [paymentSelected, setPaymentSelected] = useState<
    undefined | null | string | number
  >();
  const [transactionSelected, setTransactionSelected] = useState<
    string | number | undefined
  >();
  const onItemClick = (index: number) => {
    setPaymentSelected(index === paymentSelected ? null : index);
  };
  const [paymentsData, setPaymentData] =
    useState<GetPayCycleDetails_payCycle_payments>();

  useEffect(() => {
    setPaymentData(paymentListData);
  }, [paymentListData]);

  return (
    <Stack tokens={{ padding: 20 }}>
      <Stack>
        <Text variant="xLarge">Payments</Text>
      </Stack>

      <Stack
        horizontalAlign="space-between"
        horizontal
        tokens={{ padding: '50px 50px 10px 53px ' }}
      >
        <Text className={styles.wideColoumn}>PayTo (Name)</Text>
        <Stack className={styles.downloadSection} />
        <Text className={styles.column}>Payment Date</Text>
        <Text className={styles.column}>Payment #</Text>
        <Text className={styles.column}>Amount</Text>
      </Stack>
      {paymentsData !== undefined && (
        <Stack className={styles.paymentListsContainer}>
          {paymentsData?.nodes.map(
            (
              _payment: GetPayCycleDetails_payCycle_payments_nodes,
              index: number
            ) => {
              return (
                <PaymentDataItem
                  payCycle={payCycle}
                  payments={_payment}
                  selectedTransaction={transactionSelected}
                  onTransactionClick={(index) => {
                    setTransactionSelected(index);
                  }}
                  isSelected={paymentSelected === index}
                  key={index.toString()}
                  onItemClick={() => onItemClick(index)}
                  onFileView={setDocViewState}
                />
              );
            }
          )}
        </Stack>
      )}
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />

      <Stack
        className={styles.footerwrapper}
        tokens={{ padding: '10px 50px 10px 53px ' }}
      >
        <AmountTextView
          value={parseFloat(
            paymentListData?.aggregates?.sum?.paidAmount!
          ).toFixed(2)}
          className={styles.amountTotal}
        />
      </Stack>
      {transactionSelected !== undefined && (
        <TransactionModal
          entityId={transactionSelected?.toString()!}
          isOpen={transactionSelected !== undefined}
          onDismiss={() => setTransactionSelected(undefined)}
        />
      )}
    </Stack>
  );
};
