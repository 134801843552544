import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 800,
    padding: 25,
  },
  formContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  accountingType: {
    color: theme.palette.themePrimary,
  },
  header: {
    ...theme.fonts.large,
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    justifyContent:'space-between',
    padding:25,
    paddingBottom:0,
  },
}));
