import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
} from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { TravelerValues } from '../../types';

type FareClassesProps = IComboBoxProps & {
  selectedFareClass?: string | null;
  onClassChange?: (key: string | number) => void;
};

export const FareClasses: React.FC<FareClassesProps> = ({
  selectedFareClass,
  options,
  onClassChange,
  ...props
}) => {
  const { setFieldValue } = useFormikContext<TravelerValues>();
  const [selectedOption, setSelectedOption] = useState<
    string | number | null | undefined
  >(undefined);

  const handleOptionChange = (
    _event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    const departureClass = option?.key as keyof TravelerValues;
    if (departureClass) {
      setFieldValue(departureClass, true);
      options.forEach((ele) => {
        if (ele.key !== departureClass) {
          setFieldValue(ele.key as keyof TravelerValues, false);
        }
      });
      setSelectedOption(option?.key);
      if (option?.key) onClassChange?.(option?.key);
    }
  };

  useEffect(() => {
    setSelectedOption(selectedFareClass);
  }, [selectedFareClass]);

  return (
    <ComboBox
      label="Class"
      placeholder="Select Class"
      multiSelect
      allowFreeform={false}
      options={options}
      selectedKey={selectedOption || null}
      onChange={handleOptionChange}
      styles={{
        container: {
          width: 210,
        },
        callout: {
          width: 206,
        },
      }}
      {...props}
    />
  );
};
