import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  stickyInsideStack: {
    alignItems: 'self-start',
    margin: 25,
  },
  documentTypeDropdown: {
    width: 250,
  },
  messageBarStyle: { width: 330 },
  sticky: {
    zIndex: 1000,
  },
}));
