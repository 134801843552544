import {
  ContextualMenu,
  DefaultButton,
  IDragOptions,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Sticky,
  StickyPositionType,
  Text
} from '@fluentui/react';

import { EntityDocumentFilter } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import React, { useCallback, useState } from 'react';
import {
  AttachDocumentFilters,
  filterOptionProps,
} from '../AttachDocumentFilters';
import { CloseButton } from '../Buttons';
import { CustomDropdown } from '../CustomDropdown';
import { InfiniteList, InfiniteListProps } from '../InfiniteList';
import { ModalWrapper } from '../ModalWrapper';
import { ColumnData } from '../SearchBar';
import { CalculateBox, CalculateBoxProps } from './CalculateBox';
import { InitialDocumentData } from './InitialDocumentData';
import { useStyles } from './index.styles';
import { toFilterVariable } from './utils';

/**
 *
 * @returns Modal listing down all Attachable Documents for that modal
 */

interface IWithId {
  id: string;
}

export interface InitialDocumentMetaData {
  id?: string;
  indexName: string | null;
  comment: string | null;
}

const dragOptions: IDragOptions | undefined = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div > div:first-child',
}

export type AttachDocumentModalProps<T extends IWithId> = {
  modalWidth?: number;
  availableDocumentTypes: IDropdownOption[];
  setOpen: (open: boolean) => void;
  attachLoading: boolean;
  onSortReload?: (sort?: SortOrder) => void;
  onFiltersReload?: (filters: EntityDocumentFilter | undefined) => void;
  onSelectionChanged?: (items: T[]) => void;
  onDocumentTypeChange?: (documentType: number | null) => void;
  onAttachDocuments?: () => void;
  onCancel?: () => void;
  attachDisabled?: boolean;
  dropdownDisabled?: boolean;
  CalculateBoxProps?: CalculateBoxProps;
  remainingTotal?: number;
  columns: ColumnData[];
  isNegativeDocError?: boolean;
  appliedAmountLogic?: boolean;
  initialDocumentMetaData?: InitialDocumentMetaData;
  isDraggable?: boolean;
} & Pick<
  InfiniteListProps<T>,
  | 'items'
  | 'loading'
  | 'hasNextPage'
  | 'canSelectItem'
  | 'onLoadMore'
  | 'onRenderItemColumn'
  | 'onRenderRow'
>

export const AttachDocumentModal = <T extends IWithId>({
  availableDocumentTypes,
  setOpen,
  attachLoading,
  onSortReload,
  onFiltersReload,
  attachDisabled,
  onDocumentTypeChange,
  dropdownDisabled,
  CalculateBoxProps,
  remainingTotal,
  onAttachDocuments,
  onCancel,
  columns,
  modalWidth = 1200,
  isNegativeDocError = false,
  appliedAmountLogic,
  initialDocumentMetaData,
  isDraggable,
  ...props
}: AttachDocumentModalProps<T>) => {
  const [filtersArray, setFiltersArray] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: true,
  });
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [documentTypeId, setDocumentTypeId] = useState<number | string>();
  const styles = useStyles();
  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        await onSortReload?.({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
      }
    },
    [gridColumns, onSortReload]
  );

  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFiltersArray(filterOptions);
    const entityDocumentsFilterArray: EntityDocumentFilter | undefined =
      filterOptions.filterTypes?.length
        ? ({ and: toFilterVariable(filterOptions) } as EntityDocumentFilter)
        : undefined;
    onFiltersReload?.(entityDocumentsFilterArray);
  };

  return (
    <ModalWrapper
      isOpen
      isBlocking
      onDismiss={() => setOpen(false)}
      dragOptions={isDraggable ? dragOptions : undefined}
    >
      <Stack styles={{ root: { width: modalWidth } }}>
        <Stack
          styles={{
            root: {
              overflowY: "auto",
              height: "85vh",
            }
          }}
        >
          <Sticky
            stickyPosition={StickyPositionType.Header}
            stickyClassName={styles.sticky}
          >
            <Stack
              //Inline style added because the moveMenuItemText property of dragOptions is not working.
              style={{ cursor: isDraggable ? "move" : "default" }}
            >

              <Stack
                horizontal
                horizontalAlign="space-between"
                className={styles.stickyInsideStack}
              >
                <Text variant="xLarge">Attach Documents</Text>
                <CloseButton onClick={() => setOpen(false)} />
              </Stack>
              {(initialDocumentMetaData?.indexName ||
                initialDocumentMetaData?.comment) && (
                  <InitialDocumentData
                    initialDocumentMetaData={initialDocumentMetaData}
                  />
                )}
              <Stack
                styles={{ root: { margin: 25 } }}
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
              >
                <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="end">
                  <CustomDropdown
                    className={styles.documentTypeDropdown}
                    selectedKey={documentTypeId}
                    disabled={dropdownDisabled}
                    label="Document Type"
                    placeholder="All"
                    options={availableDocumentTypes}
                    onChange={(_, option) => {
                      if (option?.key) {
                        onDocumentTypeChange?.(parseInt(option?.key + '') || null);
                        setDocumentTypeId(parseInt(option.key.toString()));
                      }
                    }}
                    onClear={() => {
                      onDocumentTypeChange?.(null);
                      setDocumentTypeId('');
                    }}
                  />
                  <AttachDocumentFilters
                    onFilterChange={onFilterChange}
                    filterOptions={filtersArray}
                  />
                </Stack>
                {CalculateBoxProps && <CalculateBox {...CalculateBoxProps} />}
              </Stack>
            </Stack>

          </Sticky>
          <InfiniteList
            showFooter={false}
            columns={gridColumns}
            onColumnHeaderClick={_onColumnClick}
            isSelectedOnFocus={false}
            {...props}
          />
          <Stack>
            {attachLoading && <ProgressIndicator />}
            <Stack
              horizontal
              verticalAlign="end"
              styles={{ root: { margin: 25 } }}
            >
              <Stack
                horizontal
                tokens={{ childrenGap: 20 }}
                horizontalAlign="end"
                grow={1}
              >
                {appliedAmountLogic && (
                  <Stack className={styles.messageBarStyle}>
                    <MessageBar
                      messageBarType={MessageBarType.warning}
                      isMultiline={false}
                    >
                      Applied amount exceeds Total Transaction amount
                    </MessageBar>
                  </Stack>
                )}

                {isNegativeDocError && (
                  <Stack className={styles.messageBarStyle}>
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={false}
                    >
                      Negative Document not allowed over here
                    </MessageBar>
                  </Stack>
                )}

                <PrimaryButton
                  disabled={attachDisabled}
                  onClick={onAttachDocuments}
                  text="Attach Documents"
                />
                <DefaultButton
                  onClick={() => {
                    onCancel?.();
                    setOpen(false);
                  }}
                  text="Cancel"
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ModalWrapper>
  );
};
