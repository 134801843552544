import { FontWeights, makeStyles } from '@fluentui/react';

export const useCommonStyles = makeStyles((theme) => ({
  semibold: {
    fontWeight: FontWeights.semibold,
  },
  bold: {
    fontWeight: FontWeights.bold,
  },
  warning: {
    color: theme.palette.yellowDark,
  },
  semilight: {
    fontWeight: FontWeights.semilight,
  },
  light: {
    fontWeight: FontWeights.light,
  },
  noScroll: {
    overflow: 'hidden',
  },
  listHeaderContainer: {
    padding: '25px 25px 25px 25px',
  },
  listTitleContainer: {
    height: 32,
  },
  colorThemePrimary: {
    color: theme.palette.themePrimary,
  },
  themeGreen: {
    color: theme.palette.green,
  },
  colorThemeRed: {
    color: theme.palette.red,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  headerContainer: {
    color: theme.palette.themePrimary,
    marginTop: -20,
  },
  urgentBox: {
    color: theme.palette.redDark,
    fontSize: 20,
  },

  urgentRow: {
    border: `1px solid ${theme.palette.redDark}22`,
    borderLeft: `5px solid ${theme.palette.redDark}99`,
    backgroundColor: `${theme.palette.redDark}09`,
    ':hover': {
      backgroundColor: `${theme.palette.redDark}22`,
    },
  },
}));
