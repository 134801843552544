import { NetworkStatus, useQuery } from '@apollo/client';
import { IconButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  UserOccupationTitleFilter,
  UserOccupationTitlesOrderBy,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ListFilters, filterOptionProps } from './Filters';
import { OccupationViewModal } from '../view/OccupationViewModal';
import { DeleteOccupation } from './DeleteOccupation';
import {
  Occupations,
  OccupationsVariables,
  Occupations_userOccupationTitles_nodes,
} from './__generated__/Occupations';
import { getColumns } from './column.data';
import { ColumnStylesProps, onRenderItems } from './columns.render';
import { useStyles } from './index.styles';
import { onHandleSearch, toFilterVariable } from './utils';

const OCCUPATIONS = loader('./Occupations.graphql');

export type OccupationRowItem = Occupations_userOccupationTitles_nodes;

export const OccupationsList: React.FC = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [filterOptions, setFilterOptions] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: false,
  });
  const renderRef = useRef(false);
  const columnStyles: ColumnStylesProps = {
    onRenderColumnStack: styles.onRenderColumnStack,
  };
  const [selectedRows, setSelectedRows] = useState<OccupationRowItem[]>([]);

  const {
    data: occupationsData,
    loading: occupationsLoading,
    variables,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery<Occupations, OccupationsVariables>(OCCUPATIONS, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [
        UserOccupationTitlesOrderBy.USER_OCCUPATION_TITLE_ASC,
        UserOccupationTitlesOrderBy.PRIMARY_KEY_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;
  const { nodes, pageInfo } = {
    ...occupationsData?.userOccupationTitles,
  };
  const { hasNextPage } = { ...pageInfo };
  const transformedData: OccupationRowItem[] = refetching
    ? []
    : nodes?.map((item) => ({
        ...item,
      })) || [];

  const handleSearch = async (showMore: boolean) => {
    const { queryVariables } = onHandleSearch(
      showMore,
      sortOrderParam,
      occupationsData
    );
    if (showMore) fetchMore?.({ variables: queryVariables });
    else refetch(queryVariables);
  };

  const handleSearchMemo = useCallback(handleSearch, [sortOrderParam]);
  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const _renderItemColumn = useCallback(onRenderItems(columnStyles), [
    columnStyles,
  ]);

  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);
  const onRefresh = () => {
    refetch();
  };
  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFilterOptions(filterOptions);
    const filterArray: UserOccupationTitleFilter | undefined = filterOptions
      .filterTypes?.length
      ? ({ and: toFilterVariable(filterOptions) } as UserOccupationTitleFilter)
      : undefined;
    refetch({ ...variables, filter: filterArray! });
  };
  const onLoadMore = () => {
    const getOccupationsVariables: OccupationsVariables = {
      ...variables,
      after: occupationsData?.userOccupationTitles?.pageInfo.endCursor,
    };
    fetchMore({ variables: getOccupationsVariables });
  };
  useEffect(() => {
    setCustomColumns();
  }, [setCustomColumns]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
    else renderRef.current = true;
  }, [handleSearchMemo]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Occupations</Text>
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            <ListFilters
              filterOptions={filterOptions}
              onFilterChange={onFilterChange}
            />
            <IconButton
              iconProps={{ iconName: 'refresh' }}
              onClick={onRefresh}
            />
            <DeleteOccupation selectedRows={selectedRows} />
            <PrimaryButton
              iconProps={{
                iconName: 'Add',
              }}
              text="New Occupation"
              onClick={() => setIsOpen(true)}
            />
          </Stack>
        </Stack>
      </Stack>
      <InfiniteList
        loading={occupationsLoading}
        items={transformedData}
        hasNextPage={hasNextPage}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onSelectionChanged={setSelectedRows}
        onLoadMore={onLoadMore}
      />
      {isOpen && <OccupationViewModal onClose={() => setIsOpen(false)} />}
    </>
  );
};
