import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useStyles } from './index.styles';

import { GlobalActions } from 'common/constants';

import { HistoryActionMenuView } from 'common/components/History';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';
import { UserTemplate } from '../__generated__/UserTemplate';

interface UserTemplateProps {
  userTemplatedata: UserTemplate | undefined;
}
export const ActionsMenu: React.FC<UserTemplateProps> = ({
  userTemplatedata,
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    GlobalActions.history
  );
  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.history:
        return (
          <HistoryActionMenuView
            moduleName="User Template"
            historyData={
              userTemplatedata?.departmentOccupationTemplate
                ?.departmentOccupationTemplatesHistoriesByEntityId.nodes || []
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                  <PivotItem
                    itemKey={GlobalActions.history}
                    itemIcon="History"
                    headerText="History"
                    itemCount={
                      userTemplatedata?.departmentOccupationTemplate
                        ?.departmentOccupationTemplatesHistoriesByEntityId.nodes
                        .length
                    }
                    onRenderItemLink={PivotCustomRender}
                  />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
          </Stack>

          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack horizontalAlign="space-between" horizontal>
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="History"
                iconName="History"
                count={
                  userTemplatedata?.departmentOccupationTemplate
                    ?.departmentOccupationTemplatesHistoriesByEntityId?.nodes
                    .length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.history);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
