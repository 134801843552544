import { FooterActionBar } from 'common/components/FooterActionBar';
import { isPOCreated } from 'purchaseOrder/view';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

interface FooterProps {
  isNew: boolean;
  isLoading: boolean;
  children?: ReactNode;
  onSave: (saveAnother: boolean) => void;
}

export const Footer: React.FC<FooterProps> = ({
  isNew,
  onSave,
  isLoading,
  children,
}) => {
  const {
    formState: { isDirty, isSubmitting, isValid },
  } = useFormContext<PurchaseOrderValues>();

  const history = useHistory();

  return (
    <>
      <FooterActionBar
        createNewText="New Purchase Order"
        saveButtonText="Create PO + Add Item"
        saveNewButtonText="Save"
        addNewForm={() => {
          history.replace('/purchase-orders/purchase-order');
        }}
        disabled={{
          save: !isDirty || !isValid,
          cancel: !isDirty,
        }}
        isSubmitting={isSubmitting}
        isCreate={isNew}
        onCancel={() => history.replace('/purchase-orders')}
        onSave={() => {
          onSave(false);
        }}
        onSaveAnother={async () => {
          onSave(true);
          isPOCreated(false);
        }}
        isLoading={isLoading}
        children={children}
      />
    </>
  );
};
