import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  
  centerAlign: {
    alignItems: 'center',
  },
  iconButtonColor: {
    marginLeft: 30,
    color: theme.palette.neutralDark,
  },
}));
