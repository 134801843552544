import { IDropdownProps } from '@fluentui/react';
import { useField } from 'formik';
import React, { FC } from 'react';
import { CustomDropdown } from '../CustomDropdown';

type FormikDropdownProps<T> = Omit<T, 'name'> & {
  name: string;
  underlined?: boolean;
  onClear?: () => void;
  width?: number;
};

export const FormikDropdown: FC<FormikDropdownProps<IDropdownProps>> = ({
  name,
  onChange,
  onClear,
  ...props
}) => {
  const [field, meta, helpers] = useField<string | number | undefined | null>(
    name
  );

  return (
    <CustomDropdown
      errorMessage={meta.error}
      selectedKey={field.value}
      onChange={(event, option, index) => {
        helpers.setValue(option?.key);
        onChange?.(event, option, index);
      }}
      onClear={() => {
        helpers.setValue(null);
        onClear?.();
      }}
      {...props}
    />
  );
};
