import React, { useState } from 'react';
import {
  Stack,
  IconButton,
  Text,
  PrimaryButton,
  DefaultButton,
  Sticky,
  StickyPositionType,
  Dialog,
  ProgressIndicator,
  Pivot,
  PivotItem,
  DialogType,
  DialogFooter,
  MessageBarType,
  TooltipHost,
} from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import { useCommonStyles } from 'common/styles';
import { MessageBar } from '@fluentui/react';
import { MyDocumentList } from './MyDocumentsList';
import { ReportList } from './ReportList';
import { useStyles } from './index.styles';
import { ContactListPicker } from 'common/components/ContactsListPicker';
import { getConsciseList } from 'common/utils/listError';
import {
  PersoanlDocuments,
  PersoanlDocumentsVariables,
  PersoanlDocuments_entityDocumentPersonalPools_nodes,
} from './MyDocumentsList/__generated__/PersoanlDocuments';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { DocumentShareLists } from 'common/graphql/__generated__/DocumentShareLists';
import {
  DocumentShareCreate,
  DocumentShareCreateVariables,
} from 'common/graphql/__generated__/DocumentShareCreate';
import { MoveToFolder } from 'documents/MoveToFolder';
import { ListFilters } from 'documents/Filters';
import { filterOptionProps } from 'documents/documentAssignment/folder/list/GroupHeader';
import { EntityType } from 'common/types/utility';
import { formatDropdownOptions } from 'common/utils';
import { PersonalPoolAvailableDocumentTypes } from './__generated__/PersonalPoolAvailableDocumentTypes';

const getTabId = (itemKey: string) => {
  return `ShapeColorPivot_${itemKey}`;
};
const DOCUMENT_SHARE_LIST = loader(
  '../../common/graphql/DocumentShareLists.graphql'
);
const SHARE_DOCUMENTS = loader(
  '../../common/graphql/DocumentShareCreate.graphql'
);
const DOCUMENTSLIST = loader('./MyDocumentsList/PersonalDocuments.graphql');
const PERSONAL_POOL_AVAILABLE_DOCUMENT_TYPE = loader('./PersonalPoolAvailableDocumentTypes.graphql');
const dialogStyles = { main: { maxWidth: 500 } };

type DocumentProps = PersoanlDocuments_entityDocumentPersonalPools_nodes;

export const PersonalPool: React.FC = () => {
  const styles = useStyles();
  const client = useApolloClient();
  const commonStyles = useCommonStyles();
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const { addToast } = useToasts();
  const [sharableState, setSharableState] = useState(true);
  const [filterOptions, setFilterOptions] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: true,
  });
  const [selectedKey, setSelectedKey] = React.useState('documentList');
  const [selectedUserID, setSelectedUserID] = React.useState<string[]>([]);
  const [selectedDepartMentID, setSelectedDepartmentID] = React.useState<
    string[]
  >([]);
  const [selectedChannelID, setSelectedChannelID] = React.useState<string[]>(
    []
  );
  const [nonSharebleArray, setNonSharebleArray] = useState<string>();
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [hideShareDialog, { toggle: toggleHideShareDialog }] = useBoolean(true);
  const [hideUploadDialog, setHideUploadDialog] = useState(false);
  const [callDocumentDeleteFunction, setDeleteFunctionCall] =
    useState<boolean>(false);
  const [callReportDeleteFunction, setReportDeleteFunctionCall] =
    useState<boolean>(false);

  const { data: documentShareListData } = useQuery<DocumentShareLists>(
    DOCUMENT_SHARE_LIST,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const [shareDocuments, { loading: shareDocumentLoading }] = useMutation<
    DocumentShareCreate,
    DocumentShareCreateVariables
  >(SHARE_DOCUMENTS, { errorPolicy: 'all' });

  const [selectedList, setSelectedList] = useState<DocumentProps[]>([]);
  const [selectedReportList, setSelectedReportList] = useState<DocumentProps[]>(
    []
  );
  const [msgForNonSharable, setmsgForNonSharable] = useState<
    string | undefined
  >();

  const _onShareDocument = async () => {
    let list =
      selectedKey === 'documentList' ? selectedList : selectedReportList;
    let arr: string[] = list
      .map((data) => data)
      .filter((data) => data.isShareable === true)
      .map((data) => {
        return data.id;
      });

    if (arr.length === 0) {
      setmsgForNonSharable('Select Shareble file from list');
    } else {
      const { errors } = await shareDocuments({
        variables: {
          input: {
            entityDocument: arr,
            department: selectedDepartMentID,
            communicationChannel: selectedChannelID,
            user: selectedUserID,
          },
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        toggleHideShareDialog();
        setSelectedList([]);
        setSelectedReportList([]);
        addToast('File Shared Successfully', { appearance: 'success' });
      }
    }
  };
  // SHARE MODAL OPEN SECTION
  const _onShareModal = async () => {
    toggleHideShareDialog();
    let newList =
      selectedKey === 'documentList' ? selectedList : selectedReportList;
    let nonSharable = newList
      .filter((obj) => !obj.isShareable)
      .map((obj) => obj.fileReference);
    if (newList.length === nonSharable.length) setSharableState(false);
    else setSharableState(true);
    const nonSharebleMsg = getConsciseList(nonSharable, 'Shareble');
    setNonSharebleArray(nonSharebleMsg);
  };
  // UPLOAD MODAL OPEN SECTION
  const _onUploadModal = () => {
    setHideUploadDialog(true);
  };
  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      containerClassName: 'ms-dialogMainOverride',
    }),
    [labelId, subTextId]
  );

  // DELETE MODAL OPEN SECTION
  const handleLinkClick = (item?: PivotItem) => {
    //For Delete modal
    setDeleteFunctionCall(false);
    setReportDeleteFunctionCall(false);
    setSelectedList([]);
    setSelectedReportList([]);
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFilterOptions(filterOptions);
  };

  const { data: personalPoolAvailableDocumentTypesData } = useQuery<PersonalPoolAvailableDocumentTypes>(PERSONAL_POOL_AVAILABLE_DOCUMENT_TYPE);

  const documentTypesOptions = formatDropdownOptions(
    personalPoolAvailableDocumentTypesData?.personalPoolAvailableDocumentTypes?.nodes,
    {
      getKey: (item) => item.id!,
      getText: (item) => item.documentType || "",
      includeAll: false,
    }
  );

  return (
    <>
      <Sticky stickyPosition={StickyPositionType.Header}>
        <Stack className={commonStyles.listHeaderContainer}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={commonStyles.listTitleContainer}
          >
            <Text variant="xLarge">My Documents</Text>
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              {(selectedList.length > 0 || selectedReportList.length > 0) && (
                <>
                  <ActionMessageModal
                    entityType={EntityType.Document}
                    disabled={
                      !selectedList.some((selected) => selected._isDeletable)
                    }
                    multiple={{
                      validCount: selectedList.filter(
                        (selected) => selected._isDeletable
                      ).length,
                      invalidNames: selectedList
                        .filter((selected) => !selected._isDeletable)
                        .map((cannotDelete) => cannotDelete.fileReference),
                    }}
                    onConfirm={() => {
                      if (selectedKey === 'documentList') {
                        setDeleteFunctionCall(true);
                      } else {
                        setReportDeleteFunctionCall(true);
                      }
                    }}
                  />
                  <TooltipHost content={'Share'}>
                    <IconButton
                      onClick={_onShareModal}
                      iconProps={{ iconName: 'Share' }}
                      ariaLabel="Share"
                      disabled={
                        !selectedList.some((selected) => selected.isShareable)
                      }
                    />
                  </TooltipHost>
                </>
              )}

              <Stack tokens={{ padding: '0px 10px 0px 0px' }}>
                <ListFilters
                  filterOptions={filterOptions}
                  documentPoolId={'MyDocuments'}
                  documentTypeOptions={documentTypesOptions}
                  onFilterChange={onFilterChange}
                />
              </Stack>

              {selectedKey === 'documentList' && (
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 20 }}
                >
                  {selectedList.length > 0 && (
                    <MoveToFolder
                      documentsSelected={selectedList}
                      onSuccess={() => {
                        const cacheData = client.readQuery<
                          PersoanlDocuments,
                          PersoanlDocumentsVariables
                        >({
                          query: DOCUMENTSLIST,
                        });

                        const filteredList =
                          cacheData?.entityDocumentPersonalPools?.nodes.filter(
                            (ele) =>
                              selectedList.findIndex(
                                (item) => item.id === ele.id
                              ) === -1
                          );

                        const newData: PersoanlDocuments = {
                          ...cacheData!,
                          entityDocumentPersonalPools: {
                            ...cacheData?.entityDocumentPersonalPools!,
                            nodes: filteredList!,
                          },
                        };
                        client.writeQuery<
                          PersoanlDocuments,
                          PersoanlDocumentsVariables
                        >({
                          query: DOCUMENTSLIST,
                          data: newData,
                        });
                      }}
                    />
                  )}

                  <PrimaryButton
                    onClick={_onUploadModal}
                    text="Upload"
                    iconProps={{
                      iconName: 'Upload',
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Pivot
          className={styles.pivotStack}
          aria-label="Separately Rendered Content Pivot"
          selectedKey={selectedKey}
          onLinkClick={handleLinkClick}
          headersOnly={false}
          getTabId={getTabId}
        >
          <PivotItem headerText="Document" itemKey="documentList" />
          <PivotItem headerText="Report" itemKey="reportList" />
        </Pivot>
      </Sticky>

      {selectedKey === 'documentList' ? (
        <MyDocumentList
          resetFunctionCall={setDeleteFunctionCall}
          callDeleteFunction={callDocumentDeleteFunction}
          openDeleteDialog={hideDialog}
          onDismiss={toggleHideDialog}
          selectedItems={setSelectedList}
          uploadFormVisibility={hideUploadDialog}
          filterOptions={filterOptions}
          toggleHideUploadDialog={() => setHideUploadDialog(false)}
        />
      ) : (
        <ReportList
          resetFunctionCall={setReportDeleteFunctionCall}
          callDeleteFunction={callReportDeleteFunction}
          selectedItems={(selectedItems) => {
            setSelectedReportList(selectedItems);
          }}
        />
      )}

      <Dialog
        hidden={hideShareDialog}
        onDismiss={toggleHideShareDialog}
        dialogContentProps={{
          title: sharableState ? 'Share documents' : 'File cannot be shared',
          type: DialogType.largeHeader,
        }}
        modalProps={modalProps}
        minWidth="600px"
      >
        {sharableState && (
          <Stack className={styles.contactListUpperStack}>
            <ContactListPicker
              data={documentShareListData?.userDocumentShareLists?.nodes!}
              selectedContacts={(items) => {
                setSelectedDepartmentID(items.department);
                setSelectedChannelID(items.communicationChannel);
                setSelectedUserID(items.user);
              }}
            />
          </Stack>
        )}

        {nonSharebleArray?.length === 0 ? null : (
          <Stack className={styles.messageBarContainer}>
            <MessageBar messageBarType={MessageBarType.severeWarning}>
              {nonSharebleArray}
            </MessageBar>
          </Stack>
        )}
        {shareDocumentLoading ? (
          <Stack className={styles.progressIndicatorStack}>
            <ProgressIndicator />
          </Stack>
        ) : null}
        {msgForNonSharable && (
          <Stack className={styles.messageBarContainer}>
            <MessageBar messageBarType={MessageBarType.severeWarning}>
              {msgForNonSharable}
            </MessageBar>
          </Stack>
        )}
        <DialogFooter>
          <PrimaryButton
            onClick={_onShareDocument}
            text="Share Document"
            disabled={
              selectedChannelID.length === 0 &&
              selectedDepartMentID.length === 0 &&
              selectedUserID.length === 0
            }
          />
          <DefaultButton
            onClick={() => {
              toggleHideShareDialog();
              setSelectedDepartmentID([]);
              setSelectedChannelID([]);
              setSelectedUserID([]);
              setmsgForNonSharable('');
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};
