import { makeStyles } from '@fluentui/react';
import { DRAWER_CLOSE_WIDTH, DRAWER_OPEN_WIDTH } from 'common/constants';

export const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.neutralQuaternaryAlt}`;
  return {
    root: {
      borderRight: border,
      borderTop: border,
      backgroundColor: theme.palette.neutralLighter,
    },
    separator: {
      '::before': {
        background: theme.palette.neutralQuaternaryAlt,
        position: 'relative',
      },
      margin: 8,
      fontSize: 0,
      padding: 0,
    },
    bottomSeparator: {
      marginBottom: 1,
    },
    expanded: {
      width: DRAWER_OPEN_WIDTH,
      transition: 'width 200ms',
    },
    collapsed: {
      width: DRAWER_CLOSE_WIDTH,
    },
    expandCollapseBtn: {
      color: theme.palette.neutralDark,
      ':hover': {
        color: theme.palette.accent,
      },
      height: 46,
      width: 46,
    },
    bottomExpandedNavItem: {
      height: 46,
    },
    expandCollapseIcon: {
      fontSize: 18,
    },
    mainLinks: {
      overflowY: 'auto',
    },
    smallScroll: {
      '::-webkit-scrollbar': {
        width: 18,
        height: 18,
      },
      '::-webkit-scrollbar-thumb': {
        border: '6px solid transparent',
        background: theme.palette.neutralTertiaryAlt,
        borderRadius: 10,
        backgroundClip: 'padding-box',
      },
    },
    hideScroll: {
      '::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  };
});
