import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
  makeStyles,
} from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData, SearchBar } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  EntityDeleteInput,
  LookupAccountsOrderBy,
} from 'common/types/globalTypes';
import { EntityType } from 'common/types/utility';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { LookupAccountFields } from '../__generated__/LookupAccountFields';
import { CreateChartOfAccounts } from '../components/CreateChartOfAccounts';
import { EditChartAccounts } from '../components/EditChartOfAccounts';
import { AccountsEntryModal } from '../view/AccountsEntryModal';
import { CorporateAccountAssignment } from './CorporateAccountAssignment';
import {
  LookupAccountCoaSearch,
  LookupAccountCoaSearchVariables,
} from './__generated__/LookupAccountCoaSearch';
import {
  LookupAccountDelete,
  LookupAccountDeleteVariables,
} from './__generated__/LookupAccountDelete';
import { ChartAccount } from './chartAccount';
import { getColumns } from './columns.data';
import { ColumnStylesProps, onRenderItems } from './columns.render';
import { getTransformedData, onHandleSearch } from './utils';
import { ChartOfAccounts_chartOfAccounts_nodes } from './chartAccount/__generated__/ChartOfAccounts';
const LOOKUP_ACCOUNTS_COA_SEARCH = loader(
  './lookupAccountCoaSearch.graphql'
);
const LOOKUP_ACCOUNTS_DELETE = loader('./LookupAccountDelete.graphql');

const useStyles = makeStyles(() => ({
  onRenderColumnStack: {
    height: 25,
  },
}));

export type LookupAccountSearchFields = LookupAccountFields & {
  corporateAccountName: string;
};

export const AccountsEntryList = () => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const renderRef = useRef(false);
  const [lookupName, setLookupName] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<LookupAccountSearchFields[]>(
    []
  );
  const [chartOfAccount, setChartOfAccount] =
    useState<ChartOfAccounts_chartOfAccounts_nodes | null>(null);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [
    getAccounts,
    {
      loading: lookupAccountsSearchLoading,
      data: lookupAccountsSearchData,
      fetchMore,
      networkStatus,
      refetch,
    },
  ] = useLazyQuery<LookupAccountCoaSearch, LookupAccountCoaSearchVariables>(
    LOOKUP_ACCOUNTS_COA_SEARCH,
    {
      variables: {
        first: TABLE_ROWS,
        orderBy: [
          LookupAccountsOrderBy.LOOKUP_ACCOUNT_ASC,
          LookupAccountsOrderBy.LOOKUP_NAME_ASC,
          LookupAccountsOrderBy.PROJECT_ACCOUNT_ASC,
          LookupAccountsOrderBy.PRIMARY_KEY_ASC,
        ],
        lookupName: '',
        chartOfAccountId: '',
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [deleteCorporateAccounts] = useMutation<
    LookupAccountDelete,
    LookupAccountDeleteVariables
  >(LOOKUP_ACCOUNTS_DELETE, { errorPolicy: 'all' });

  const handleSearch = async (showMore: boolean) => {
    setSelectedRows([]);
    if (chartOfAccount) {
      const { queryVariables } = onHandleSearch(
        lookupName,
        showMore,
        lookupAccountsSearchData,
        chartOfAccount.id,
        sortOrderParam
      );
      if (showMore) fetchMore?.({ variables: queryVariables });
      else getAccounts({ variables: queryVariables });
    }
  };

  const handleSearchMemo = useCallback(handleSearch, [
    sortOrderParam,
    lookupName,
    chartOfAccount?.id,
  ]);

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const { nodes, pageInfo } = {
    ...lookupAccountsSearchData?.lookupAccountCoaSearch,
  };
  const columnStyles: ColumnStylesProps = {
    onRenderColumnStack: styles.onRenderColumnStack,
  };

  const transformedData = getTransformedData(refetching, nodes);

  const _renderItemColumn = useCallback(
    onRenderItems(columnStyles, chartOfAccount),
    [columnStyles]
  );

  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);

  const _onConfirm = async () => {
    const selectedData: LookupAccountSearchFields[] = selectedRows;
    const deletedRows: LookupAccountSearchFields[] = selectedData.filter(
      (item) => item._isDeletable
    );
    const entityDelete: EntityDeleteInput[] = selectedData
      .filter((item) => item._isDeletable)
      .map((item) => {
        return { id: item.id, rowTimestamp: item._rowTimestamp! };
      });
    const { errors } = await deleteCorporateAccounts({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache) {
        deletedRows.forEach((item) => {
          const identity = cache.identify({
            ...item,
          });
          cache.evict({ id: identity });
          cache.gc();
        });
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted successfully.', {
        appearance: 'success',
      });
    }
  };

  useEffect(() => {
    setCustomColumns();
  }, [setCustomColumns]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
    else renderRef.current = true;
  }, [handleSearchMemo]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          className={commonStyles.listTitleContainer}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">Accounts</Text>
            <ChartAccount
              chartOfAccountId={chartOfAccount}
              onSelectOption={setChartOfAccount}
            />
            <CreateChartOfAccounts chartOfAccountId={chartOfAccount?.id!} />
            <EditChartAccounts chartOfAccountId={chartOfAccount?.id!} />
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.Account}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((selected) => selected.lookupName || ''),
              }}
              onConfirm={_onConfirm}
            />
            <CorporateAccountAssignment
              chartOfAccount={chartOfAccount}
              onRefetch={() => refetch?.()}
            />
            <PrimaryButton
              text="New Account"
              iconProps={{ iconName: 'Add' }}
              onClick={() => setIsOpen(true)}
            />
          </Stack>
        </Stack>
        <SearchBar
          columns={[]}
          searchEnabled
          isGlobalAvailable={false}
          onEnterPress={(value) => setLookupName(value)}
          onRefresh={() => handleSearch(false)}
        />
      </Stack>
      <InfiniteList
        loading={lookupAccountsSearchLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={pageInfo?.hasNextPage}
        items={refetching ? undefined : transformedData}
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={setSelectedRows}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true)}
      />
      {isOpen && !!chartOfAccount && (
        <AccountsEntryModal
          chartOfAccount={chartOfAccount}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
