import { Stack } from '@fluentui/react';
import { DocumentList } from 'common/components/DocumentList';
import React from 'react';
import { useStyles } from './index.styles';
import { columns } from './column.data';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { TransactionData_invoice_entityDocumentsByEntityId } from 'common/components/TransactionSection/__generated__/TransactionData';

interface FileSectionProps {
  fileListData: TransactionData_invoice_entityDocumentsByEntityId;
}
export const FilesSection: React.FC<FileSectionProps> = ({ fileListData }) => {
  const styles = useStyles();

  const { width } = getWindowDimensions();
  const documentViewPosition = width ? width * 0.8 - 500 : 50;

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      <Stack tokens={{ childrenGap: 10, padding: 10 }}>
        {fileListData?.nodes?.length !== 0 && (
          <DocumentList
            isNew={false}
            columns={columns}
            data={fileListData?.nodes! || []}
            paddingLeft={documentViewPosition}
          />
        )}
      </Stack>
    </Stack>
  );
};
