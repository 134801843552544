import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, IModalProps, PrimaryButton, ProgressIndicator, Stack, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { PurchaseOrderPromoteDeliveryAddress, PurchaseOrderPromoteDeliveryAddressVariables } from './__generated__/PurchaseOrderPromoteDeliveryAddress';
import { PurchaseOrderPromoteDeliveryAddressInput } from 'common/types/globalTypes';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { useToasts } from 'react-toast-notifications';
import { SelectedDeliveryAddress } from '..';
const PURCHASE_ORDER_PROMOTE_DELIVERY_ADDRESS = loader("./PurchaseOrderPromoteDeliveryAddress.graphql")
const TITLE = "Enter a brief description of the delivery address:";
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};
const modalProps: IModalProps = {
  isBlocking: false,
  dragOptions: dragOptions
}
const dialogContentProps: IDialogContentProps = {
  title: TITLE,
  type: DialogType.largeHeader,
}
interface PromoteDeliveryAddressProps {
  hidden: boolean;
  purchaseOrder: PurchaseOrder | undefined;
  onConfirm: (data: SelectedDeliveryAddress) => void;
  onDismiss: () => void;
}

export const PromoteDeliveryAddress: React.FC<PromoteDeliveryAddressProps> = ({
  hidden,
  purchaseOrder,
  onConfirm,
  onDismiss
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [description, setDescription] = useState<string | null>(null);

  const { id, _rowTimestamp } = { ...purchaseOrder?.purchaseOrder };

  const [promoteDeliveryAddress, { loading }] =
    useMutation<PurchaseOrderPromoteDeliveryAddress, PurchaseOrderPromoteDeliveryAddressVariables>(
      PURCHASE_ORDER_PROMOTE_DELIVERY_ADDRESS,
      { errorPolicy: 'all' }
    );

  const onSubmit = async () => {
    const inputVariables: PurchaseOrderPromoteDeliveryAddressInput = {
      aliasName: description!,
      purchaseOrderId: id!,
      rowTimeStamp: _rowTimestamp!,
    };
    const { data, errors } = await promoteDeliveryAddress({
      variables: {
        input: inputVariables,
      },
      update(cache, { data }) {
        const identity = cache.identify({ ...purchaseOrder?.purchaseOrder });
        cache.modify({
          id: identity,
          fields: {
            _isPromoteAddressAllowed: () => {
              return data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?._isPromoteAddressAllowed;
            },
          },
        });
      }
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      setDescription(null);
      addToast('Address added successfully.', {
        appearance: 'success',
      });
      onConfirm({
        id: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryAddressId!,
        addressLine1: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryAddressLine1!,
        addressLine2: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryAddressLine2!,
        cityName: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryCityName!,
        countryId: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryCountryId!,
        stateRegionId: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryStateRegionId!,
        postalCode: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryPostalCode!,
        telephoneNumber: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryTelephoneNumber!,
        emailAddress: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryEmailAddress!,
        addressAlias: data?.purchaseOrderPromoteDeliveryAddress?.purchaseOrder?.deliveryAddressAlias!,
      })
    }
  }

  const disableConfirm = !description?.length || loading;

  return (
    <>
      <Dialog
        hidden={hidden}
        onDismiss={() => {
          setDescription(null)
          onDismiss()
        }}
        dialogContentProps={dialogContentProps}
        minWidth={400}
        modalProps={modalProps}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <TextField
            multiline
            rows={3}
            value={description || ""}
            onChange={(_e, value) => {
              if (value)
                setDescription(value)
            }}
          />
          {loading && <ProgressIndicator />}
        </Stack>
        <DialogFooter>
          <PrimaryButton
            disabled={disableConfirm}
            text="Confirm"
            onClick={onSubmit}
            className={styles.root}
          />
          <DefaultButton
            onClick={() => {
              setDescription(null)
              onDismiss()
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </>
  )
}
