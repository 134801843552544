import { Label, Stack, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React, { useState } from 'react'
import { RentalDatesCallout } from './RentalDatesCallout';
import { getConcatenatedDates } from './utils';
interface RentalDatesProps {
  dates: (string | null)[] | null;
}
export const RentalDates: React.FC<RentalDatesProps> = ({
  dates
}) => {
  const calloutId = useId(`rentalDatesId`);
  const [visible, setVisible] = useState(false);
  const formattedRentalDates = !!dates?.length ? dates?.map(date => date && dateFormat(dateConvertions(date))) : [];
  const formattedRentalDatesString: string = formattedRentalDates?.join(",  ");
  const rentalDates = !!formattedRentalDates.length ? getConcatenatedDates(formattedRentalDates) : null;
  const showCallout = visible && !!dates?.length && dates?.length > 2;
  return (
    <>
      <Stack>
        <Stack
          id={calloutId}
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        >
          <RentalDatesCallout
            calloutId={calloutId}
            visible={showCallout}
            dates={formattedRentalDatesString}
          />
          <Label>Selected Dates</Label>
          <Text
            style={{
              width: 228
            }}
          >
            {rentalDates || "No dates selected"}
          </Text>
        </Stack>
      </Stack>
    </>
  )
}
