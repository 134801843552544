import { makeVar, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Documents } from './Documents';
import { FolderList } from './folder/list';

export enum DocumentAssignmentTabs {
  folder = 'folder',
  documents = 'documents',
}

export const setTabMode = makeVar<string>(DocumentAssignmentTabs.folder);

export const DocumentAssignmentSection: React.FC = () => {
  const batchState = useReactiveVar(setTabMode);
  useEffect(() => {
    return () => {
      setTabMode(DocumentAssignmentTabs.folder);
    };
  }, []);

  const tabSwitchLogic = batchState === DocumentAssignmentTabs.folder;

  return <>{tabSwitchLogic ? <FolderList /> : <Documents />}</>;
};
