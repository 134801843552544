import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  paddingLeft20: {
    paddingLeft: 20,
  },
  logo: {
    fill: theme.palette.neutralPrimary,
  },
  signatureImage: {
    borderRadius: 5,
  },
  customWidth: {
    width: '400px',
  },
  gray: {
    color: theme.palette.neutralSecondaryAlt,
  },
}));
