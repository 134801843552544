import { useLazyQuery } from '@apollo/client';
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsFooterProps,
  IRenderFunction,
  SelectionMode,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react';
import {
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId,
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes,
} from 'approvals/ApprovalDetails/__generated__/userApproval';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { StyledLink } from 'common/components/StyledLink';
import { getOverageInfo } from 'common/utils';
import Decimal from 'decimal.js';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { setTripsModalState } from 'travelAuthorization/TravelPlan/view/FormView/Trips';
import { closeOpenedDocument } from 'utility/cache/ui';
import { TripModalView } from '../TripModalView';
import {
  TravelAuthorizationApprovalHistoryItem,
  TravelAuthorizationApprovalHistoryItemVariables,
} from './__generated__/TravelAuthorizationApprovalHistoryItem';
import { columns } from './column.data';
import { useStyles } from './index.styles';
const TRavel_AUTHORIZATION_APPROVAL_HISTORY_ITEM = loader(
  './TravelAuthorizationApprovalHistoryItem.graphql'
);
interface TripListProps {
  tripsData: userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId;
  onRefetch: () => void;
}

export type ItemRow =
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes;

export const TripList: React.FC<TripListProps> = ({ tripsData, onRefetch }) => {
  const styles = useStyles();
  const [selectedTripItem, setSelectedTripItem] = useState<ItemRow>();
  const { nodes, aggregates } = { ...tripsData };
  const [fetchApprovalHistoryItem, { data: approvalHistoryItemData }] =
    useLazyQuery<
      TravelAuthorizationApprovalHistoryItem,
      TravelAuthorizationApprovalHistoryItemVariables
    >(TRavel_AUTHORIZATION_APPROVAL_HISTORY_ITEM, {
      onCompleted: (data) => {
        if (data) {
          const { nodes } = {
            ...data.approvalHistoryItem?.trip?.travelAuthorization?.trips,
          };
          if (!!nodes?.length) {
            const tripItem = nodes?.find(
              (ele) => ele.id === selectedTripItem?.id1
            );
            setTripsModalState({
              trip: tripItem,
              isModalOpen: true,
              isNew: false,
            });
          }
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const { travelAuthorization } = {
    ...approvalHistoryItemData?.approvalHistoryItem?.trip,
  };

  const renderColumn = (item?: ItemRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;
    const fieldContent = item[column.fieldName as keyof ItemRow] as
      | string
      | null;
    switch (column?.key) {
      case 'itemDescription1':
        return (
          <StyledLink
            onLinkClick={() => showTripItemsModal(item)}
            linkText={fieldContent}
          />
        );
      case 'itemAmount1':
        const isOverBudget = new Decimal(item?.itemAmount5 || 0).greaterThan(
          item.itemAmount4 || 0
        );
        if (isOverBudget) {
          const toolTipContent = getOverageInfo(
            item?.itemAmount5,
            item.itemAmount4
          );
          return (
            <TooltipHost content={toolTipContent}>
              <AmountTextView
                value={fieldContent}
                className={clsx(styles.amount, styles.overageAmount)}
              />
            </TooltipHost>
          );
        } else {
          return (
            <AmountTextView className={styles.amount} value={fieldContent} />
          );
        }
      case 'itemUnit1':
      case 'itemDescription5':
        return <Text className={clsx(styles.amount)}>{fieldContent}</Text>;
      default:
        return <Text>{fieldContent}</Text>;
    }
  };

  const renderFooterColumn = (
    _item?: ItemRow,
    _index?: number,
    column?: IColumn
  ) => {
    if (column?.key === 'itemAmount1' && aggregates?.sum)
      return (
        <Text className={clsx(styles.amount, styles.amountTotal)}>
          {getFormattedAmountValue(
            Number(aggregates.sum.itemAmount1).toFixed(2)
          )}
        </Text>
      );
    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !aggregates?.sum?.itemAmount1) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  const showTripItemsModal = (data: ItemRow) => {
    if (data) {
      setSelectedTripItem(data);
      fetchApprovalHistoryItem({
        variables: {
          id: data.id,
        },
      });
      closeOpenedDocument(true);
    }
  };

  return (
    <Stack tokens={{ padding: '0px 0px 0px 10px' }}>
      <Text variant="xLarge">Trips</Text>
      <DetailsList
        compact
        columns={columns}
        items={nodes || []}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        onRenderItemColumn={renderColumn}
        onRenderDetailsFooter={renderFooter}
      />
      {selectedTripItem && (
        <TripModalView
          travelAuthorizationData={travelAuthorization}
          onRefetch={onRefetch}
        />
      )}
    </Stack>
  );
};
