import { IColumn } from '@fluentui/react';

export const getColumns = (
  onColumnClickCallback: (column: IColumn) => void
) => {
  const columnsData: IColumn[] = [
    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 350,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      onColumnClick: (_, column) => onColumnClickCallback(column),
      data: 'string',
      isPadded: true,
      isCollapsible: true,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 100,
      maxWidth: 350,
      isResizable: true,
      isSortedDescending: false,
      onColumnClick: (_, column) => onColumnClickCallback(column),
      data: 'string',
      isPadded: true,
      isCollapsible: true,
    },
    {
      key: 'defaultDirectoryTypes.description',
      name: 'Storage',
      fieldName: 'storage',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSortedDescending: false,
      onColumnClick: (_, column) => onColumnClickCallback(column),
      data: 'string',
      isPadded: true,
      isCollapsible: true,
    },
    {
      key: 'defaultDocumentTypes.documentType',
      name: 'Document Type',
      fieldName: 'documentType',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isSortedDescending: false,
      onColumnClick: (_, column) => onColumnClickCallback(column),
      data: 'string',
      isPadded: true,
      isCollapsible: true,
    },
    {
      key: 'documentPoolTotals.availableDocuments',
      name: 'Documents',
      fieldName: 'count',
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      isSortedDescending: false,
      onColumnClick: (_, column) => onColumnClickCallback(column),
      data: 'number',
      isPadded: true,
      isCollapsible: true,
    },
  ];

  return columnsData;
};
