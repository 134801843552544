import React from 'react';
import OrganizationChart from '@dabeng/react-orgchart';
import { NodeView } from './NodeView';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { ApprovalMapTypesOrderBy } from 'common/types/globalTypes';
import { ProgressIndicator, Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';
import './NodeView/my-node.css';
import clsx from 'clsx';
import { StampOptions } from '../StamperView';
import { useCallback } from 'react';
import {
  ApprovalSetupMap,
  ApprovalSetupMapVariables,
} from './__generated__/ApprovalSetupMap';
const APPROVAL_SETUP_MAP = loader('./ApprovalSetupMap.graphql');

export interface HierarchyNode {
  children?: HierarchyNode[];
  isBranch?: boolean | null;
  isTree?: boolean | null;
  id?: string | null;
  name?: string | null;
  subTopic?: string | null;
  topic?: string | null;
}

interface ApprovalFlowProps {
  entityId: string;
  getApprovalType: (approvalType: string) => void;
  stampEnteredData?: (data: StampOptions) => void;
}
/**
 * Main hierarchy module
 * This module call data from server and runs a recursive algorithm to restructure the data
 * Once the data has been restructured it feeds into the @dabeng/react-orgchart to get the graph rendered as expected
 */
export const ApprovalFlow: React.FC<ApprovalFlowProps> = ({
  entityId,
  getApprovalType,
  stampEnteredData,
}) => {
  const [hierarchyData, setHierarchyData] = React.useState<HierarchyNode[]>([]);

  const Toast = useToasts();

  const [
    getApprovalData,
    {
      loading: approvalDataLoading,
      data: approvalData,
      error: approvalDataError,
    },
  ] = useLazyQuery<ApprovalSetupMap, ApprovalSetupMapVariables>(
    APPROVAL_SETUP_MAP,
    {
      fetchPolicy: 'network-only',
    }
  );
  const getApprovalDetails = () => {
    getApprovalData({
      variables: {
        entityId,
        orderBy: [ApprovalMapTypesOrderBy.SORT_ORDER_DESC],
      },
    });
    if (approvalDataError) {
      Toast.addToast('Could not load data', { appearance: 'error' });
    }
  };
  const getApproval = useCallback(getApprovalDetails, []);

  React.useEffect(() => {
    getApproval();
  }, [getApproval]);

  const getChildren = (arr: HierarchyNode[] = []) => {
    let children: HierarchyNode[] = [];
    let iterator = 0;
    while (iterator <= arr.length) {
      if (arr[iterator]) {
        if (arr[iterator].isBranch! === true) {
          children.push(arr[iterator]);
        } else {
          let treeObj = arr[iterator];
          treeObj.children = getChildren(
            arr.slice(iterator + 1) ? arr.slice(iterator + 1) : []
          );
          children.splice(children.length / 2, 0, treeObj);
          break;
        }
      } else break;
      iterator++;
    }
    return children;
  };

  const memoizedChildrenData = useCallback(getChildren, []);
  React.useEffect(() => {
    if (
      !approvalDataLoading &&
      approvalData?.approvalSetupMap?.nodes.length !== 0
    ) {
      // Implicity adding children property to avoid an error caused
      const childrenAdded = approvalData?.approvalSetupMap?.nodes.map(
        (item) => ({
          ...item,
          children: [],
        })
      );

      const newData = memoizedChildrenData(childrenAdded);

      setHierarchyData(newData);
      getApprovalType(approvalData?.approvalSetupMap?.nodes[0].approvalType!);
    }
  }, [
    approvalDataLoading,
    approvalData,
    memoizedChildrenData,
    getApprovalType,
    stampEnteredData,
  ]);

  // return null
  if (hierarchyData.length > 0)
    return (
      <OrganizationChart
        datasource={hierarchyData[0]}
        chartClass="myChart"
        // Any had to be used over here because the structure is coming from
        // a javascript library and we are unknown about its type.
        NodeTemplate={(node: any) => (
          <NodeView nodeData={node.nodeData} key={node.nodeData.id} />
        )}
        pan={true}
        containerClass={'orgchartcontainer'}
        zoom={true}
        zoominLimit={1.2}
        zoomoutLimit={0.5}
        draggable={false}
        collapsible={false}
      />
    );
  else return <LoadingHierarchy />;
};

const LOADING_TEXT = 'Loading hierarchy';
const LoadingHierarchy = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      className={styles.loaderContainer}
    >
      <Stack className={styles.loadingInnerContainer}>
        <Text className={clsx(commonStyles.semibold, styles.loadingText)}>
          {LOADING_TEXT}
        </Text>
        <ProgressIndicator />
      </Stack>
    </Stack>
  );
};
