import React from 'react';
import { useStyles } from './index.styles';
import { TransactionLayout } from 'common/types/globalTypes';
import {
  FontWeights,
  ILabelStyles,
  Label,
  Separator,
  Stack,
} from '@fluentui/react';
import { InfoView } from 'common/components/InfoView';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import clsx from 'clsx';
import { ActionMenu } from './ActionMenu';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { InvoiceModalDetails_invoice } from '../__generated__/InvoiceModalDetails';
import { AccountingRowsView } from 'ap/paymentCycle/view/TransactionModal/AccountingRowsView';
const labelStyles: Partial<ILabelStyles> = {
  root: {
    fontWeight: FontWeights.bold,
  },
};
export interface InvoiceDetailProps {
  invoice: InvoiceModalDetails_invoice;
  loading: boolean;
}

export const InvoiceDetail: React.FC<InvoiceDetailProps> = ({
  invoice,
  loading,
}) => {
  const styles = useStyles();
  const {
    transactionType,
    vendorReference,
    businessUnit,
    department,
    invoiceDate,
    description,
    invoiceNumber,
    currency,
    controlTotalAmount,
    companyCorporatePeriod,
    reimburseAmount,
    reimburseAccountReference,
    invoiceDistributions,
  } = { ...invoice };

  const isPettyCashLayout: boolean =
    transactionType?.layoutType === TransactionLayout.PETTY_CASH;

  return (
    <Stack tokens={{ padding: '0px 0px 100px' }}>
      {loading ? (
        <ShimmerView />
      ) : (
        <>
          <Stack
            horizontal
            verticalAlign="center"
            className={styles.infoViewWrapper}
          >
            <Stack className={styles.container}>
              <InfoView
                title="Name"
                description={`${vendorReference}` || '-'}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title="Business Unit"
                description={`${businessUnit?.name ? businessUnit?.name : '-'}`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title="Department"
                description={`${department?.name ? department?.name : '-'}`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title="Date"
                description={`${invoiceDate
                  ? dateFormat(dateConvertions(invoiceDate || ''))
                  : '-'
                  }`}
              />
            </Stack>
          </Stack>
          <Separator />
          <Stack
            horizontal
            verticalAlign="center"
            className={styles.infoViewWrapper}
          >
            <Stack className={styles.container}>
              <InfoView
                title={'Description'}
                description={`${description ? description : '-'}`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Number'}
                description={`${invoiceNumber ? invoiceNumber : '-'}`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Amount'}
                currency={currency?.isoCode || ''}
                description={controlTotalAmount || '-'}
                isAmount
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Period'}
                description={`${companyCorporatePeriod?._periodYear
                  ? companyCorporatePeriod?._periodYear
                  : '-'
                  }`}
              />
            </Stack>
          </Stack>
          <Separator />
          {isPettyCashLayout && (
            <>
              <Stack
                horizontal
                verticalAlign="center"
                className={styles.infoViewWrapper}
              >
                <Stack
                  className={clsx(
                    styles.reimbursedAmountContainer,
                    styles.width25
                  )}
                >
                  <Label styles={labelStyles}>Reimbursed Amount</Label>
                  <AmountTextView value={reimburseAmount || '0'} />
                </Stack>
                <Stack className={styles.width25}>
                  <InfoView
                    title="Account"
                    description={reimburseAccountReference || '-'}
                  />
                </Stack>
              </Stack>
              <Separator />
            </>
          )}
          <ActionMenu invoice={invoice} />
          {
            !!invoice?.id && !!invoiceDistributions?.nodes.length &&
            <Stack tokens={{ padding: "0px 20px" }}>
              <AccountingRowsView invoiceDetails={invoice} />
            </Stack>
          }
        </>
      )}
    </Stack>
  );
};
