import { Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useStyles } from './index.styles';
interface HeaderProps {
  columnHeadings: string[];
}
export const Header: React.FC<HeaderProps> = ({
  columnHeadings
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <Stack horizontal >
      {
        columnHeadings.map(
          (heading, index) => {
            return (
              <Stack
                className={
                  clsx(
                    styles.root,
                    styles.linkLightHover,
                    heading === "Action" ?
                      styles.actionColumnWidth :
                      heading === "Year" ?
                        styles.yearHeaderCell :
                        styles.regularColumnWidth
                  )
                }
                key={index}
              >
                <Stack
                  verticalAlign="center"
                  // className={clsx(
                  //   styles.linkLightHover,
                  //   heading === "Year" ?
                  //     styles.yearHeaderCell :
                  //     heading === "Action" ?
                  //       styles.actionHeaderCell :
                  //       styles.headerCell
                  // )}
                  horizontal
                  tokens={{ childrenGap: 20 }}
                >
                  <Text
                    nowrap
                    block
                    className={commonStyles.semibold}
                    variant="medium"
                  >
                    {heading}
                  </Text>
                </Stack>
              </Stack>
            )
          }
        )
      }
    </Stack>
  )
}
