import {
  IconButton,
  Stack,
  Text,
  TooltipHost,
  makeStyles
} from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 970,
    cursor: 'move',
    marginBottom: 80,
  },
  header: {
    backgroundColor: theme.palette.neutralLighterAlt,
    position: 'absolute',
    width: '100%',
    zIndex: 99,
    top: 0,
  },
}));

interface HeaderProps {
  purchaseOrderNumber: string | null;
  onDismiss: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  purchaseOrderNumber,
  onDismiss,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        className={styles.header}
        tokens={{
          padding: 20,
        }}
      >
        <Stack
          horizontal
          verticalAlign="baseline"
          tokens={{ childrenGap: 10 }}
          wrap
        >
          <Text variant="xLarge">Invoice Schedule</Text>
          <Text variant="large" className={commonStyles.colorThemePrimary}>
            {purchaseOrderNumber}
          </Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="baseline">
          <TooltipHost content="Close" id="tooltipId">
            <IconButton
              onClick={() => onDismiss()}
              iconProps={{ iconName: 'ChromeClose' }}
              ariaLabel="Close"
            />
          </TooltipHost>
        </Stack>
      </Stack>
    </Stack>
  );
};
