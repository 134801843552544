import { useQuery } from '@apollo/client';
import { Dropdown, IDropdownStyles, Stack, useTheme } from '@fluentui/react';
import { CorporateChartOfAccounts } from 'common/graphql/__generated__/CorporateChartOfAccounts';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React from 'react';
import { CreateCorporateChartAccounts } from './CreateCorporateChartAccounts';
import { EditCorporateChartAccounts } from './EditCorporateChartAccounts';
const CORPORATE_CHART_OF_ACCOUNTS = loader('../../../../../common/graphql/CorporateChartOfAccounts.graphql');

interface CorporateChartAccountsViewProps {
  chartOfAccountId: string | null;
  onChartOfAccountChange: (param: string | null) => void;
}

export const CorporateChartAccountsView: React.FC<CorporateChartAccountsViewProps> = ({
  chartOfAccountId,
  onChartOfAccountChange
}) => {
  const theme = useTheme();

  const dropdownStyles: Partial<IDropdownStyles> = {
    title: {
      border: 0,
      outline: 0,
      borderBottom: `1px solid ${theme.semanticColors.inputBorder}`,
    },
    dropdown: {
      width: 190,
    },
  };

  const {
    data: corporateChartOfAccounts,
  } = useQuery<CorporateChartOfAccounts>(CORPORATE_CHART_OF_ACCOUNTS,
    {
      onCompleted: (data) => {
        if (!!data?.corporateChartOfAccounts?.nodes.length && !chartOfAccountId) {
          onChartOfAccountChange(data?.corporateChartOfAccounts?.nodes?.[0]?.id);
        }
        else onChartOfAccountChange(null);
      }
    }
  )

  const chartOfAccountsOptions = formatDropdownOptions(
    corporateChartOfAccounts?.corporateChartOfAccounts?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: false,
    }
  );

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 20 }}
    >
      <Dropdown
        placeholder="Select Chart of Accounts"
        selectedKey={chartOfAccountId}
        options={chartOfAccountsOptions}
        onChange={(_event, option) => {
          if (option?.key) {
            onChartOfAccountChange(option?.key?.toString())
          }
        }}
        styles={dropdownStyles}
      />
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
      >
        <CreateCorporateChartAccounts
          chartOfAccountId={chartOfAccountId}
        />
        <EditCorporateChartAccounts
          chartOfAccountId={chartOfAccountId}
        />
      </Stack>
    </Stack>
  )
}
