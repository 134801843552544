import { ActionButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { AttachListBadge } from './Badge'
import { useId } from '@fluentui/react-hooks';
import { FilterArrayType } from 'common/components/Filters';
import { FiltersCallout } from './FiltersCallout';

export type filterOptionProps = {
  filterTypes: FilterArrayType[];
  startsWith: boolean;
};

export interface AttachDocumentFiltersProps {
  filterOptions: filterOptionProps;
  onFilterChange: (filterOptions: filterOptionProps) => void;
}

export const Filters: React.FC<AttachDocumentFiltersProps> = ({
  ...props
}) => {
  const calloutId = useId(`attach-filter-callOutId`);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const filterToggle = () => setFiltersApplied((prevState) => !prevState);
  const filtersCount = props?.filterOptions.filterTypes.length;

  return (
    <Stack horizontal>
      <AttachListBadge visible={filtersCount > 0} filtersCount={filtersCount}>
        <ActionButton
          onClick={filterToggle}
          id={calloutId}
          iconProps={{ iconName: filtersApplied ? 'ClearFilter' : 'Filter' }}
          text="Filters"
        />
      </AttachListBadge>
      {filtersApplied && (
        <FiltersCallout
          onDismiss={filterToggle}
          calloutId={calloutId}
          {...props}
        />
      )}
    </Stack>
  )
}
