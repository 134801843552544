import { BusinessUnitModalProps } from '..';
import { BUSINESS_UNIT_VALUES } from './constants';
import { BusinessUnitValues } from './types';

type DefaultValueProps = Pick<BusinessUnitModalProps, 'businessUnit'>;

export const getDefaultValues = (props: DefaultValueProps) => {
  const { businessUnit } = { ...props };
  const {
    __typename,
    id,
    _isUpdatable,
    _rowTimestamp,
    chartOfAccount,
    companyChartOfAccount,
    companyAddressesByCompanyIdAndEntityId,
    companyBusinessUnitCurrencies,
    ...businessUnitFields
  } = {
    ...businessUnit,
  };
  const { nodes } = { ...companyAddressesByCompanyIdAndEntityId };
  let defaultValues: BusinessUnitValues = BUSINESS_UNIT_VALUES;
  if (!!id) {
    const currencies =
      companyBusinessUnitCurrencies.nodes.map(
        ({ companyCurrency, ...currencyField }) => ({ ...currencyField })
      ) || [];
    defaultValues = {
      ...businessUnitFields,
      companyAddressesByCompanyIdAndEntityId: !!nodes.length ? [...nodes] : [],
      companyBusinessUnitCurrencies: !!currencies.length ? currencies : [],
    };
  }
  return defaultValues;
};
