import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 100
  },
  amount: {
    textAlign: 'right',
    display: 'block',
    marginLeft: '4px !important',
  },
  amountTotal: {
    fontWeight: 'bold',
    color: theme.palette.themePrimary,
  },
  logo: {
    fill: theme.palette.neutralPrimary,
  },
  columnHeight: {
    height: 30,
  },
  rightAlignContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}));
