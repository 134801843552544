import { useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { PivotCustomRender } from 'common/components/PivotComponent';
import {
  PivotDisplay,
  PivotMenuItemProps,
} from 'common/components/PivotDisplay';
import { GlobalActions } from 'common/constants';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import {
  SupplierPropertiesCount,
  getUpdatedPivotActionItems,
  pivotActionItems,
} from './constants';
import { useStyles } from './index.styles';
import { HistoryActionMenuView } from 'common/components/History';
import { SupplierUploadDocument_supplierUploadDocument } from 'settings/account/supplier/view/ActionMenu/SupplierAttach/UploadForm/__generated__/SupplierUploadDocument';
import {
  ListSupplierNotes,
  ListSupplierNotesVariables,
} from 'settings/account/supplier/view/ActionMenu/__generated__/ListSupplierNotes';
import { Attach } from './Attach';
import { Notes } from './Notes';
import { TagsSection } from 'settings/account/supplier/view/ActionMenu/SupplierTags';
import { TASupplierDetails } from '../../__generated__/TASupplierDetails';
const SUPPLIER_NOTES_LIST = loader(
  '../../../../../../../../../settings/account/supplier/view/ActionMenu/ListSupplierNotes.graphql'
);

interface ActionsMenuProps {
  supplier: TASupplierDetails | undefined;
  onRemove?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: SupplierUploadDocument_supplierUploadDocument,
    toastId: string
  ) => void;
}

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  supplier,
  onUpload,
  onRemove,
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    GlobalActions.attach
  );

  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();

  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListSupplierNotes,
    ListSupplierNotesVariables
  >(SUPPLIER_NOTES_LIST, {
    variables: {
      id: supplier?.supplier?.id!,
    },
    skip: !supplier?.supplier?.id,
  });

  const getEntityNotes = () => {
    const variables: ListSupplierNotesVariables = {
      id: supplier?.supplier?.id!,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    supplier?.supplier?.id!,
  ]);

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.notes:
        return (
          <Stack>
            <Notes
              listRefreshRequest={getEntityNotesMemo}
              id={supplier?.supplier?.id!}
              data={entityNotesData?.supplier?.notesByEntityId.nodes!}
            />
          </Stack>
        );
      case GlobalActions.history:
        return (
          <Stack>
            <HistoryActionMenuView
              moduleName="Supplier"
              numberOfItems={3}
              historyData={
                supplier?.supplier?.supplierHistoriesByEntityId?.nodes || []
              }
            />
          </Stack>
        );
      case GlobalActions.attach:
        return (
          <Stack>
            <Attach
              supplier={supplier?.supplier!}
              onUpload={onUpload}
              onRemove={onRemove}
            />
          </Stack>
        );
      case GlobalActions.tags:
        return (
          <Stack>
            <TagsSection supplierID={supplier?.supplier?.id} />;
          </Stack>
        );
      default:
        return null;
    }
  };

  //Update the values of the count property in the pivotActionItems array

  let updatedPivotActionItems: PivotMenuItemProps[] = [];
  if (supplier?.supplier) {
    const {
      notesByEntityId,
      entityDocumentsByEntityId,
      supplierHistoriesByEntityId,
      entityTagsByEntityId,
    } = supplier?.supplier;

    const supplierTransactionCountArray: SupplierPropertiesCount[] = [
      {
        name: 'Files',
        count: entityDocumentsByEntityId?.totalCount || 0,
      },
      {
        name: 'Notes',
        count: notesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'History',
        count: supplierHistoriesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'Tags',
        count: entityTagsByEntityId?.nodes?.length || 0,
      },
    ];
    updatedPivotActionItems = getUpdatedPivotActionItems(
      supplierTransactionCountArray
    );
  } else updatedPivotActionItems = pivotActionItems;

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.pivotContainer}
            >
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                {updatedPivotActionItems.map((item, index) => {
                  return (
                    <PivotItem
                      key={index}
                      itemKey={item.itemKey}
                      itemIcon={item.iconName}
                      headerText={item.name}
                      itemCount={item.count}
                      onRenderItemLink={PivotCustomRender}
                    />
                  );
                })}
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
          </Stack>
          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack
          horizontalAlign="space-between"
          horizontal
          className={styles.pivotContainer}
        >
          <Stack horizontal verticalAlign="center">
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              {updatedPivotActionItems.map((item, index) => {
                return (
                  <PivotDisplay
                    key={index}
                    {...item}
                    onPivotClick={() => {
                      setSelectedKey(item.itemKey);
                      setExpandMenu(true);
                    }}
                  />
                );
              })}
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
