import { CommandBarButton, IDropdownOption, Stack } from '@fluentui/react';
import {
  FormikCheckBox,
  FormikCountryRegion,
  FormikTextField,
} from 'common/components';
import { useFormikContext } from 'formik';
import React from 'react';
import { TravelerCommonData_countries_nodes } from '../../__generated__/TravelerCommonData';
import { TravelerValues } from '../../types';
import { useStyles } from './index.styles';
interface AddressFormFieldsProps {
  addressName: string;
  toList: () => void;
  countries: TravelerCommonData_countries_nodes[];
  isEdit: boolean;
  remove<T>(index: number): T | undefined;
  inputsDisabled: boolean;
}
export const AddressFormFields: React.FC<AddressFormFieldsProps> = ({
  addressName,
  toList,
  countries,
  isEdit,
  remove,
  inputsDisabled,
}) => {
  const styles = useStyles();
  const { values } = useFormikContext<TravelerValues>();
  const countryOptions: IDropdownOption[] =
    countries.map((ele) => ({
      key: ele.id,
      text: ele.country,
    })) || [];

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        className={styles.defaultButtonContainer}
      >
        <CommandBarButton
          text="Address List"
          iconProps={{ iconName: 'Back' }}
          onClick={() => {
            if (
              values.travelerAddressesByTravelerId &&
              values.travelerAddressesByTravelerId[0].addressLine1 === null
            ) {
              remove(0);
            }
            toList();
          }}
          className={styles.addressListButton}
        />
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        className={styles.formRow}
      >
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name={`${addressName}.addressLine1`}
            label="Street"
            placeholder="Street and Number, P.O. Box"
            disabled={inputsDisabled}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name={`${addressName}.addressLine2`}
            label="Apartment"
            placeholder="Apartment, Suite, Unit, Building, Floor"
            disabled={inputsDisabled}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name={`${addressName}.cityName`}
            label="City"
            placeholder="City, Town"
            disabled={inputsDisabled}
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        className={styles.formRow}
      >
        <Stack.Item className={styles.countryRegionContainer}>
          <FormikCountryRegion
            countries={countryOptions}
            countryName={`${addressName}.countryId`}
            regionName={`${addressName}.stateRegionId`}
            CountryAutocompleteProps={{
              label: 'Country',
              errorMessage: '',
            }}
            StateRegionAutocompleteProps={{
              label: 'State / Region / Province',
              errorMessage: '',
            }}
            disabled={inputsDisabled}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name={`${addressName}.postalCode`}
            label="Zip/Postal Code"
            placeholder="Zip/Postal Code"
            disabled={inputsDisabled}
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        className={styles.formRow}
      >
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name={`${addressName}.telephoneNumber`}
            label="Phone"
            placeholder="Phone"
            disabled={inputsDisabled}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name={`${addressName}.emailAddress`}
            label="Email"
            placeholder="Email"
            disabled={inputsDisabled}
          />
        </Stack.Item>
        <Stack horizontal verticalAlign="end" className={styles.fieldContainer}>
          <Stack.Item className={styles.sendCheckBoxContainer}>
            <FormikCheckBox
              name={`${addressName}.isGroundTransferAddress`}
              label="Ground Transfer"
              disabled={inputsDisabled}
            />
          </Stack.Item>
          <Stack.Item>
            <FormikCheckBox
              name={`${addressName}.isDeliveryAddress`}
              label="Delivery"
              disabled={inputsDisabled}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
};
