import {
  ActionButton,
  Callout,
  DirectionalHint,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  Checkbox,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { TextSearchFilter } from 'common/components/Filters/TextSearchFilter';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { InvoiceFiltersProps } from '..';
import { TransactionPaymentTypes } from '../__generated__/TransactionPaymentTypes';
const TRANSACTION_TYPES = loader('../TransactionPaymentTypes.graphql');

type InvoiceFiltersCalloutProps = Omit<
  InvoiceFiltersProps,
  'documentPoolId'
> & {
  onDismiss?: () => void;
  calloutId: string;
};

export const InvoiceFiltersCallout: React.FC<InvoiceFiltersCalloutProps> = ({
  onDismiss,
  calloutId,
  onFilterChange,
  filterOptions,
}) => {
  const { filterTypes: filters, startsWith: startsWithDefault } = {
    ...filterOptions,
  };
  const styles = useStyles();
  const [transactionTypeId, setTransactionTypeId] = useState<
    number | undefined | null
  >();
  const [vendorReference, setVendorReference] = useState<string | undefined>();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const [description, setDescription] = useState<string | undefined>();
  const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>();
  const [startsWith, setStartsWith] = useState<boolean | undefined>();

  const onFilterApply = () => {
    onFilterChange({ filterTypes, startsWith: startsWith! });
    onDismiss?.();
  };

  const onCancel = () => {
    onDismiss?.();
  };

  useEffect(() => {
    if (filters) {
      setFilterTypes(filters);
      setStartsWith(startsWithDefault);
      const transactionTypeIdPos = filters.findIndex(
        (item) => item.filterKey === 'transactionTypeId'
      );
      const vendorReferencePos = filters.findIndex(
        (item) => item.filterKey === 'vendorReference'
      );
      const descriptionPos = filters.findIndex(
        (item) => item.filterKey === 'description'
      );
      const invoiceNumberPos = filters.findIndex(
        (item) => item.filterKey === 'invoiceNumber'
      );
      if (invoiceNumberPos !== -1)
        setInvoiceNumber(filters[invoiceNumberPos].value.toString());

      if (descriptionPos !== -1)
        setDescription(filters[descriptionPos].value.toString());

      if (vendorReferencePos !== -1)
        setVendorReference(filters[vendorReferencePos].value.toString());

      if (transactionTypeIdPos !== -1)
        setTransactionTypeId(
          parseInt(filters[transactionTypeIdPos].value.toString())
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useQuery<TransactionPaymentTypes>(TRANSACTION_TYPES, {
    variables: {
      isDocumentUpload: true,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const transactionTypeOptions = formatDropdownOptions(
    data?.transactionPaymentTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.transactionType!,
      includeAll: false,
    }
  );

  const onOptionChange = (filterData: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterData, availableFilters);
    newFilters = newFilters.filter((element) => element.value);
    setFilterTypes(newFilters);
  };

  const onReset = () => {
    setDescription(undefined);
    setInvoiceNumber(undefined);
    setTransactionTypeId(null);
    setVendorReference(undefined);
    setStartsWith(true);
    setFilterTypes([]);
  };

  return (
    <Callout
      className={styles.callout}
      gapSpace={0}
      target={`#${calloutId}`}
      directionalHint={DirectionalHint.leftTopEdge}
      onDismiss={onDismiss}
      setInitialFocus
    >
      <Stack className={styles.container}>
        <Text variant={'xLarge'}>Filters</Text>
        <Stack tokens={{ childrenGap: 10, padding: '20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item style={{ width: 245 }}>
              <DropdownFilter
                controlled
                placeholder={'Type'}
                options={transactionTypeOptions}
                filterKey={'transactionTypeId'}
                defaultFilter
                selectedKey={transactionTypeId}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setTransactionTypeId(parseInt(value.value.toString()));
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Pay To (Name)"
                defaultFilter
                value={vendorReference}
                filterKey={'vendorReference'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setVendorReference(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Description"
                defaultFilter
                value={description}
                filterKey={'description'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setDescription(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Invoice / Ref. Number"
                defaultFilter
                value={invoiceNumber}
                filterKey={'invoiceNumber'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setInvoiceNumber(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center">
            <ActionButton
              text="Reset"
              onClick={onReset}
              iconProps={{ iconName: 'RevToggleKey' }}
            />
            <Checkbox
              label="Starts with"
              checked={startsWith}
              onChange={(_, value) => {
                setStartsWith(value);
              }}
            />
          </Stack>
          <Stack tokens={{ padding: '10px 0px', childrenGap: 20 }} horizontal>
            <PrimaryButton text="Apply" onClick={onFilterApply} />
            <DefaultButton text="Cancel" onClick={onCancel} />
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
