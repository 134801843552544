import { IColumn, Stack, Text } from '@fluentui/react';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { StarRatings } from 'common/components/StarRatings';
import React from 'react';
import { SupplierRowItem } from '.';
import { useStyles } from './index.styles';
import { StatusIcon } from 'common/components/StatusIcon';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';

export const _renderItemColumn = (
  item: SupplierRowItem | undefined,
  _index: number | undefined,
  column: IColumn | undefined
) => {
  const styles = useStyles();
  if (item) {
    const fieldContent = item[
      column?.fieldName as keyof SupplierRowItem
    ] as string;
    const _isUrgentApproval = !Boolean(item._urgencyLevel);
    switch (column?.key) {
      case '_urgencyLevel':
        return (
          <RedBoxIndicator
            _isUrgentApproval={_isUrgentApproval}
            itemId={item?.id}
          />
        );
      case '_sortName':
        return (
          <Stack
            className={styles.statusColumnContainer}
            verticalAlign="center"
          >
            <ActiveLink
              to={`/account-management/suppliers/supplier/${item.id}`}
            >
              {fieldContent}
            </ActiveLink>
          </Stack>
        );
      case 'status':
        return (
          <Stack
            verticalAlign="center"
            horizontal
            horizontalAlign="space-between"
          >
            <StatusIcon
              approval={false}
              approvalData={item}
              iconType={item.statusType!}
            />
          </Stack>
        );
      case 'rating':
        return (
          <Stack
            verticalAlign="center"
            horizontal
            tokens={{ childrenGap: 10 }}
            className={styles.statusColumnContainer}
          >
            <Text>{fieldContent && fieldContent}</Text>
            {fieldContent && (
              <StarRatings readOnly defaultRating={Number(fieldContent)} />
            )}
          </Stack>
        );
      default:
        return (
          <Stack
            verticalAlign="center"
            className={styles.statusColumnContainer}
          >
            <Text>{fieldContent && fieldContent}</Text>
          </Stack>
        );
    }
  }
};
