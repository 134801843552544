import { Text } from '@fluentui/react';
import React from 'react';
interface AddressLineProps {
  data: string | null | undefined;
}

export const AddressLine: React.FC<AddressLineProps> = ({ data }) => {
  if (data)
    return (
      <Text>{data}</Text>
    )
  else return null
}
