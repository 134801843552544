import { useQuery } from '@apollo/client';
import { HistoryActionMenuView } from 'common/components/History';
import { MenuPropertiesCount, getUpdatedPivotActionItems } from 'common/components/Modals/utils';
import { PivotDisplay, PivotMenuItemProps } from 'common/components/PivotDisplay';
import { GlobalActions } from 'common/constants';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { Pivot, PivotItem, Stack, makeStyles } from '@fluentui/react';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { ListPurchaseOrderNotes, ListPurchaseOrderNotesVariables } from 'common/graphql/__generated__/ListPurchaseOrderNotes';
import { PurchaseOrderDetailProps } from '..';
import { Files } from './Files';
import { Notes } from './Notes';
import { Tags } from './Tags';
const ENTITY_NOTES_LIST = loader('../../../../../graphql/ListPurchaseOrderNotes.graphql');
type ActionMenuProps = Pick<PurchaseOrderDetailProps, "purchaseOrder">;

const useStyles = makeStyles(() => ({
  centerAlign: {
    alignItems: 'center',
  },
}))

const pivotActionItems: PivotMenuItemProps[] = [
  {
    name: 'Files',
    iconName: 'Attach',
    itemKey: GlobalActions.attach,
    count: 0,
  },
  {
    name: 'Notes',
    iconName: 'Page',
    itemKey: GlobalActions.notes,
    count: 0,
  },
  {
    name: 'History',
    iconName: 'History',
    itemKey: GlobalActions.history,
    count: 0,
  },
  {
    name: 'Tags',
    iconName: 'Tag',
    itemKey: GlobalActions.tags,
    count: 0,
  },
];


export const ActionMenu: React.FC<ActionMenuProps> = ({
  purchaseOrder,
}) => {
  const styles = useStyles()
  const {
    id,
    entityDocumentsByEntityId,
    notesByEntityId,
    purchaseOrderHistoriesByEntityId,
    entityTagsByEntityId,
  } = { ...purchaseOrder }
  let updatedPivotActionItems: PivotMenuItemProps[] = [];
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(true);
  const [selectedKey, setSelectedKey] = useState<string>(GlobalActions.attach);

  const { data: entityNotesData, refetch: entityNotesListRefetch } =
    useQuery<
      ListPurchaseOrderNotes,
      ListPurchaseOrderNotesVariables
    >(ENTITY_NOTES_LIST, {
      variables: {
        id: id!,
      },
    });

  const { nodes } = { ...entityNotesData?.purchaseOrder?.notesByEntityId }

  const getEntityNotes = () => {
    const variables: ListPurchaseOrderNotesVariables = {
      id: id!,
    };
    entityNotesListRefetch(variables);
  };

  const getEntityNotesMemo = useCallback(getEntityNotes, [id!]);

  const menuCountArray: MenuPropertiesCount[] = [
    {
      name: 'Files',
      iconName: 'Attach',
      count: entityDocumentsByEntityId?.nodes.length || 0,
    },
    {
      name: 'Notes',
      iconName: 'Page',
      count: notesByEntityId?.nodes?.length || 0,
    },
    {
      name: 'History',
      iconName: 'History',
      count: purchaseOrderHistoriesByEntityId?.totalCount || 0,
    },
    {
      name: 'Tags',
      iconName: 'Tag',
      count: entityTagsByEntityId?.nodes?.length || 0,
    },
  ];

  updatedPivotActionItems = purchaseOrder ?
    getUpdatedPivotActionItems(
      menuCountArray,
      pivotActionItems
    ) : updatedPivotActionItems = pivotActionItems;

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.attach:
        return (
          <Files
            fileListData={entityDocumentsByEntityId!}
          />
        );
      case GlobalActions.notes:
        return (
          <Notes
            purchaseOrderId={id!}
            data={nodes || []}
            listRefreshRequest={getEntityNotesMemo}
          />
        );
      case GlobalActions.history:
        return (
          <HistoryActionMenuView
            moduleName="Purchase Order"
            historyData={purchaseOrderHistoriesByEntityId?.nodes || []}
          />
        );
      case GlobalActions.tags:
        return <Tags purchaseOrderId={id || ''} />;
      default:
        return null;
    }
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Stack tokens={{ padding: '10px 20px' }}>
        {
          isMenuExpanded ?
            <Stack>
              <Stack horizontalAlign="space-between" horizontal>
                <Stack horizontal className={styles.centerAlign}>
                  <Pivot
                    selectedKey={selectedKey}
                    onLinkClick={(item: PivotItem | undefined) => {
                      setSelectedKey(item?.props.itemKey || GlobalActions.attach);
                    }}
                  >
                    {
                      updatedPivotActionItems.map((item, index) => {
                        return (
                          <PivotItem
                            key={index}
                            itemKey={item.itemKey}
                            itemIcon={item.iconName}
                            headerText={item.name}
                            itemCount={item.count}
                            onRenderItemLink={PivotCustomRender}
                          />
                        );
                      })
                    }
                  </Pivot>
                </Stack>
              </Stack>
              <Stack>{getSelectedSection(selectedKey)}</Stack>
            </Stack>
            : <Stack horizontalAlign="space-between" horizontal>
              <Stack horizontal verticalAlign="center">
                <Stack horizontal verticalAlign="center">
                  {updatedPivotActionItems.map((item, index) => {
                    return (
                      <PivotDisplay
                        key={index}
                        {...item}
                        onPivotClick={() => {
                          setSelectedKey(item.itemKey!);
                          setIsMenuExpanded(true);
                        }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
        }
      </Stack>
    </Stack>
  )
}
