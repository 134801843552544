import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      borderRadius: 10,
      border: `2px solid ${theme.palette.neutralLight}`,
      backgroundColor: theme.palette.neutralLighterAlt,
    },
    labelContainer: {
      margin: '0px 50px 20px 50px',
    },
  };
});
