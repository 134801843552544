import { useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Chart,
  ChartProps,
  currencyFormatter,
  CustomizedAxisTick,
  useColors,
  useStyles,
} from '../common';
import { useHistory } from 'react-router-dom';
import { globalMode } from 'utility/cache/ui';
import { loader } from 'graphql.macro';
import { POStatusChartData } from './__generated__/POStatusChartData';
import { MyPOStatusChartData } from './__generated__/MyPOStatusChartData';

const STATUS_QUERY = loader('./POStatusChartData.graphql');
const MY_STATUS_QUERY = loader('./MyPOStatusChartData.graphql');

type Data = POStatusChartData | MyPOStatusChartData;

const isPOStatusChartData = (data: Data): data is POStatusChartData =>
  (data as POStatusChartData).kpiPoStatuses !== undefined;

const getNodes = (data: Data) =>
  isPOStatusChartData(data)
    ? data.kpiPoStatuses?.nodes
    : data.kpiMyPoStatuses?.nodes;

const Component = <T extends Data>({ data, height, width }: ChartProps<T>) => {
  const styles = useStyles();
  const theme = useTheme();
  const colors = useColors();
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [animation, setAnimation] = useState(true);
  const history = useHistory();

  const nodes = getNodes(data);

  return (
    <BarChart
      height={height}
      width={width}
      data={nodes}
      margin={{
        top: 20,
        right: 20,
        left: 10,
        bottom: 20,
      }}
      barGap={20}
      maxBarSize={80}
      onClick={(state) => {
        if (state?.activeTooltipIndex !== undefined) {
          globalMode(true);
          const searchTerm = nodes?.[state.activeTooltipIndex].searchHint
            ? `?t=${nodes?.[state.activeTooltipIndex].searchHint}`
            : '';
          history.push(`purchase-orders${searchTerm}`);
        }
      }}
    >
      <CartesianGrid
        strokeDasharray="3 3"
        stroke={theme.palette.neutralQuaternary}
      />
      <XAxis
        dataKey="title"
        interval={0}
        tick={
          <CustomizedAxisTick
            fill={theme.palette.neutralPrimary}
            width={120}
            maxLines={2}
          />
        }
      />
      <YAxis tick={{ fill: theme.palette.neutralPrimary }} />
      <Tooltip
        formatter={(value: number) => [currencyFormatter.format(value), '']}
        separator=""
        contentStyle={{
          backgroundColor: theme.palette.white,
          borderColor: theme.palette.neutralLighter,
        }}
        itemStyle={{
          color: theme.palette.neutralPrimary,
        }}
        cursor={{
          fill: theme.palette.neutralQuaternaryAlt,
          opacity: 0.4,
          cursor: 'pointer',
          pointerEvents: 'all',
        }}
        isAnimationActive={false}
        labelStyle={{ fontWeight: 600 }}
      />
      <Bar
        dataKey="amount"
        onMouseEnter={(_, index) => setActiveIndex(index)}
        onMouseLeave={(_, index) => {
          if (activeIndex === index) setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
        className={styles.pointer}
      >
        {nodes?.map((_entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={colors[index % colors.length][index === activeIndex ? 1 : 0]}
          />
        ))}
      </Bar>
    </BarChart>
  );
};

export const POStatusChart: Chart<POStatusChartData> = {
  Component,
  query: STATUS_QUERY,
  hasData: (data) => !!data.kpiPoStatuses?.nodes.length,
};

export const MyPOStatusChart: Chart<MyPOStatusChartData> = {
  Component,
  query: MY_STATUS_QUERY,
  hasData: (data) => !!data.kpiMyPoStatuses?.nodes.length,
};
