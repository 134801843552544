import { FontSizes, makeStyles } from '@fluentui/react';

const RED_600 = '#E8C5C5';
const RED_700 = '#D32F2F';
const RED_800 = '#C62828';
const RED_900 = '#B71C1C';
const GREEN_600 = '#b6e3c8';
const BLUE_600 = '#71afe5';
const GREEN_800 = '#1ea866';
const BLUE_800 = '#0078d4';
const GREEN_900 = '#228f5b';
const BLUE_900 = '#004578';
const GREEN_600_DARK = '#506157';
const RED_600_DARK = '#635151';

export const buttonColors = {
  RED_600,
  RED_700,
  RED_800,
  RED_900,
  GREEN_600,
  GREEN_800,
  GREEN_900,
  BLUE_600,
  BLUE_800,
  BLUE_900,
  GREEN_600_DARK,
  RED_600_DARK,
};
export const useStyles = makeStyles((theme) => ({
  additionInputCallout: {
    width: 300,
    padding: 20,
  },
  AcceptButton: {
    color: 'white',
    backgroundColor: GREEN_800,
    borderColor: GREEN_800,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: GREEN_900,
      borderColor: GREEN_900,
    },
    ':active': {
      color: 'white',
      backgroundColor: GREEN_800,
      borderColor: GREEN_800,
    },
  },
  ValidateButton: {
    color: 'white',
    backgroundColor: BLUE_800,
    borderColor: BLUE_800,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: BLUE_900,
      borderColor: BLUE_900,
    },
    ':active': {
      color: 'white',
      backgroundColor: BLUE_800,
      borderColor: BLUE_800,
    },
  },

  AcceptButtonDisabled: {
    backgroundColor: theme.isInverted ? GREEN_600_DARK : GREEN_600,
    borderColor: theme.isInverted ? GREEN_600_DARK : GREEN_600,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: GREEN_600,
      borderColor: GREEN_600,
    },
    ':active': {
      color: 'white',
      backgroundColor: GREEN_600,
      borderColor: GREEN_600,
    },
  },

  ValidateButtonDisabled: {
    backgroundColor: theme.isInverted ? BLUE_600 : BLUE_600,
    borderColor: theme.isInverted ? BLUE_600 : BLUE_600,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: BLUE_600,
      borderColor: BLUE_600,
    },
    ':active': {
      color: 'white',
      backgroundColor: BLUE_600,
      borderColor: BLUE_600,
    },
  },
  declineButton: {
    color: 'white',
    backgroundColor: RED_700,
    borderColor: RED_700,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: RED_800,
      borderColor: RED_800,
    },
    ':active': {
      color: 'white',
      backgroundColor: RED_900,
      borderColor: RED_900,
    },
  },
  declineButtonDisabled: {
    backgroundColor: theme.isInverted ? RED_600_DARK : RED_600,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.isInverted ? RED_600_DARK : RED_600,
    ':hover': {
      color: 'white',
      backgroundColor: RED_600,
      borderColor: RED_600,
    },
    ':active': {
      color: 'white',
      backgroundColor: RED_600,
      borderColor: RED_600,
    },
  },
  container: {},
  checkBoxContainer: {
    marginLeft: 20,
  },
  textFieldContainer: {
    marginLeft: 20,
    width: 300,
  },
  textInputField: {
    width: 300,
  },
  closeIconStyle: {
    fontSize: FontSizes.size18,
  },
  editIconStyle: { fontSize: FontSizes.large, marginLeft: 18 },
  approvedIconStyle: { padding: 6, width: 24, height: 18 },
  actionRequired: { fontSize: FontSizes.size28 },
  approvedContainer: {},
  approvalStatusStyle: { padding: 6 },
  iconStyles: {
    color: theme.palette.black,
    paddingTop: 10,
    marginLeft: 10,
  },
}));
