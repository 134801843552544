import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  stickyInsideStack: {
    alignItems: 'self-start',
    padding: 25,
  },
  stack: {
    padding: 25,
  },
  modalInsideStack: {
    width: 1400,
    height: 800,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },

  messageBarStyle: { width: 330 },
  dropdownWrapper: {
    width: 500,
  },
  columnHeight: {
    height: 30,
  },
  buttonWrapper: {
    padding: '0px 20px',
  },
}));
