import { IDetailsColumnStyles, Stack, Text } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import React, { ReactNode } from 'react';
import { ReactComponent as FlightMiniIcon } from 'assets/svgs/TripIcons/Mini/flightMiniIcon.svg';
import { ReactComponent as AccommodationMiniTrip } from 'assets/svgs/TripIcons/Mini/AccomodationMiniTrip.svg';
import { ReactComponent as IncidentalMiniTrip } from 'assets/svgs/TripIcons/Mini/IncidentalMiniTrip.svg';
import { ReactComponent as TransportationMiniTrip } from 'assets/svgs/TripIcons/Mini/TransportationMiniTrip.svg';
import { ReactComponent as VehicleMiniTrip } from 'assets/svgs/TripIcons/Mini/VehicleMiniTrip.svg';
import { ReactComponent as TrainMiniTrip } from 'assets/svgs/TripIcons/Mini/TrainMiniTrip.svg';
import { CurrentUserProfile } from 'common/graphql/__generated__/CurrentUserProfile';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { TransactionLayout } from 'common/types/globalTypes';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

const IconHeaderTitle: React.FC<{ title: string; children: ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      horizontal
      tokens={{ childrenGap: 10 }}
    >
      {children}
      <Text nowrap>{title}</Text>
    </Stack>
  );
};

export const getColumns = (
  currentUserProfile: CurrentUserProfile | undefined,
  usersDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const { company } = { ...currentUserProfile?.currentUserProfile };
  const isSm =
    usersDefaults?.listviewSize === TransactionLayout.LISTVIEW_SIZE_SMALL;
  const isLg =
    usersDefaults?.listviewSize === TransactionLayout.LISTVIEW_SIZE_LARGE;
  const columns: ColumnData[] = [
    {
      key: 'tripLocator',
      name: 'Trip Locator',
      fieldName: 'tripLocator',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isSorted: true,
      isSortedDescending: true,
    },
    {
      key: 'travelerReferenceName',
      name: 'Traveler',
      fieldName: 'travelerReferenceName',
      minWidth: 140,
      ...commonColumnProps,
    },
    {
      key: 'travelAuthorizationNumber',
      name: 'TA Number',
      fieldName: 'travelAuthorizationNumber',
      minWidth: 80,
      ...commonColumnProps,
    },
    {
      key: 'comment',
      name: 'Travel Plan',
      fieldName: 'comment',
      minWidth: 80,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'travelAuthorizationDate',
      name: 'Date',
      fieldName: 'travelAuthorizationDate',
      minWidth: 70,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: '_departmentName',
      name: 'Department',
      fieldName: '_departmentName',
      minWidth: 80,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: '_businessUnitName',
      name: 'Business Unit',
      fieldName: '_businessUnitName',
      minWidth: 120,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'corporateWorkgroupName',
      name: 'Workgroup',
      fieldName: 'corporateWorkgroupName',
      minWidth: 180,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_isCorporateTravelAuthorization',
      name: 'Origin',
      fieldName: '_isCorporateTravelAuthorization',
      minWidth: 180,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_travelTags',
      name: 'Travel Tags',
      fieldName: '_travelTags',
      minWidth: 140,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'tripPurpose',
      name: 'Trip Purpose',
      fieldName: 'tripPurpose',
      minWidth: 140,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_travelPolicyDescription',
      name: 'Travel Policy',
      fieldName: '_travelPolicyDescription',
      minWidth: 140,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_companionTicketUsed',
      name: 'Companion Ticket',
      fieldName: '_companionTicketUsed',
      minWidth: 140,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
      isVisible: false,
    },
    {
      key: '_companionAmountUsed',
      name: 'Companion Amount',
      fieldName: '_companionAmountUsed',
      minWidth: 140,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
      isVisible: false,
    },
    {
      key: '_tripWarningInfo',
      name: 'Companion Info',
      fieldName: '_tripWarningInfo',
      minWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_primaryTravelerName',
      name: 'Primary Traveler',
      fieldName: '_primaryTravelerName',
      minWidth: 140,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_isoCode',
      name: 'Currency',
      fieldName: '_isoCode',
      minWidth: 70,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'airfareAmount',
      name: 'Airfare',
      fieldName: 'airfareAmount',
      minWidth: 80,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
      onRenderHeader() {
        return (
          <IconHeaderTitle title="Airfare">
            <FlightMiniIcon />
          </IconHeaderTitle>
        );
      },
    },
    {
      key: 'accommodationAmount',
      name: 'Hotel',
      fieldName: 'accommodationAmount',
      minWidth: 80,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
      onRenderHeader() {
        return (
          <IconHeaderTitle title="Hotel">
            <AccommodationMiniTrip />
          </IconHeaderTitle>
        );
      },
    },

    {
      key: 'vehicleRentalAmount',
      name: 'Vehicle',
      fieldName: 'vehicleRentalAmount',
      minWidth: 80,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
      isVisible: !isSm,
      onRenderHeader() {
        return (
          <IconHeaderTitle title="Vehicle">
            <VehicleMiniTrip style={{ width: 20 }} />
          </IconHeaderTitle>
        );
      },
    },

    {
      key: 'transportationAmount',
      name: 'Ground',
      fieldName: 'transportationAmount',
      minWidth: 90,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
      onRenderHeader() {
        return (
          <IconHeaderTitle title="Ground">
            <TransportationMiniTrip />
          </IconHeaderTitle>
        );
      },
    },
    {
      key: 'trainAmount',
      name: 'Train',
      fieldName: 'trainAmount',
      minWidth: 80,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
      onRenderHeader() {
        return (
          <IconHeaderTitle title="Train">
            <TrainMiniTrip />
          </IconHeaderTitle>
        );
      },
    },

    {
      key: 'incidentalAmount',
      name: 'Other',
      fieldName: 'incidentalAmount',
      minWidth: 80,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
      onRenderHeader() {
        return (
          <IconHeaderTitle title="Other">
            <IncidentalMiniTrip style={{ height: 15 }} />
          </IconHeaderTitle>
        );
      },
    },

    {
      key: 'controlTotalAmount',
      name: 'Total',
      fieldName: 'controlTotalAmount',
      minWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: '_baseCurrencyAmount',
      name: `${company?.currency?.isoCode} Amount`,
      fieldName: '_baseCurrencyAmount',
      minWidth: 120,
      ...commonColumnProps,
      isVisible: false,
      styles: rightAlignHeaderStyle,
    },
    {
      key: '_spotCurrencyAmount',
      name: `${company?.currency?.isoCode} Spot Rate`,
      fieldName: '_spotCurrencyAmount',
      minWidth: 120,
      ...commonColumnProps,
      isVisible: false,
      styles: rightAlignHeaderStyle,
    },
    {
      key: '_taStatusType',
      name: 'Status',
      fieldName: '_taStatusType',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      toggleVisibility: false,
    },

    {
      key: '_createdDate',
      name: 'Created On',
      fieldName: '_createdDate',
      minWidth: 140,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_createdByUserName',
      name: 'Created By',
      fieldName: '_createdByUserName',
      minWidth: 140,
      ...commonColumnProps,
      isVisible: false,
    },
  ];

  return columns;
};
