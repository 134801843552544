import { ITextFieldProps, Icon, Label, Stack, makeStyles } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { DepotSelectionCallout } from './DepotSelectionCallout';
import { DepotListRow } from './DepotSelectionCallout/DepotList';

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    cursor: "pointer"
  },
  label: {
    color: theme.palette.themePrimary
  },
}));

interface DepotSelectionProps extends ITextFieldProps {
  targetId: string;
  isAirport?: boolean;
  isTrain?: boolean;
  onDepotAreaSelect: (depot: DepotListRow) => void;
}

export const DepotSelection: React.FC<DepotSelectionProps> = ({
  targetId,
  label,
  isAirport,
  isTrain,
  onDepotAreaSelect,
}) => {
  const styles = useStyles();
  const calloutId = useId(targetId);
  const [visible, setVisible] = useState(false)
  return (
    <>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{
          childrenGap: 6
        }}
        onClick={() => setVisible(true)}
        className={styles.labelContainer}
      >
        <Label
          id={calloutId}
          className={styles.label}
        >
          {label}
        </Label>
        <Icon iconName="Search" className={styles.label} />
      </Stack>
      {
        visible &&
        <DepotSelectionCallout
          targetId={`#${calloutId}`}
          onDepotAreaSelect={onDepotAreaSelect}
          onDismiss={() => setVisible(false)}
          isAirport={isAirport}
          isTrain={isTrain}
        />
      }
    </>
  )
}
