import React from 'react';
import './my-node.css';
import { Stack, Text, DirectionalHint, TooltipHost } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { useId } from '@fluentui/react-hooks';
import { HierarchyNode } from '../index';

interface NodeViewProps {
  nodeData: HierarchyNode;
}

export const NodeView: React.FC<NodeViewProps> = ({ nodeData }) => {
  const nodeId = useId(`nodeId-label${nodeData.id}`);
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  return (
    <div>
      <Stack
        verticalAlign="space-between"
        tokens={{ childrenGap: 15 }}
        className={
          nodeData.isTree
            ? styles.treeContainer
            : clsx(styles.nodeContainer, styles.backgroundDefault)
        }
      >
        <Stack id={nodeId} tokens={{ childrenGap: 10 }}>
          <Stack
            className={clsx(nodeData.isTree && styles.treeHeadingStyle)}
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
          >
            <TooltipHost
              content={nodeData.topic || undefined}
              directionalHint={DirectionalHint.topLeftEdge}
            >
              <Text
                variant={nodeData.isTree ? 'mediumPlus' : 'medium'}
                className={clsx(
                  commonStyles.semibold,
                  nodeData.isTree && styles.headerPending
                )}
              >
                {nodeData.name}
              </Text>
            </TooltipHost>
          </Stack>
        </Stack>
        {!nodeData.isTree && (
          <Stack grow className={styles.innerBox}>
            {nodeData.subTopic?.split(',').map((line, i) => (
              <Text key={i} className={styles.textviewStyles} variant="small">
                {line.trim()}
              </Text>
            ))}
          </Stack>
        )}
      </Stack>
    </div>
  );
};
