import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentStyles,
  Stack,
  makeStyles,
} from '@fluentui/react';
import React, { useState } from 'react';
import { MovementOrders } from '../../../__generated__/MovementOrders';
import { MovementOrderTraveler } from '../../Sidebar';
import { useToasts } from 'react-toast-notifications';
import {
  MovementReportRequest,
  MovementReportRequestVariables,
} from './__generated__/MovementReportRequest';
import { useApolloClient, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { SuccessButton } from 'common/components/SuccessButton';
import {
  TravelAuthorizationMovementReportStatus,
  TravelAuthorizationMovementReportStatusVariables,
} from './__generated__/TravelAuthorizationMovementReportStatus';
import { ReportStatusType } from 'common/types/globalTypes';
const PRINT_MOVEMENT_ORDERS = loader('./MovementReportRequest.graphql');
const TRAVEL_MOVEMENT_REPORT_STATUS = loader(
  './TravelAuthorizationMovementReportStatus.graphql'
);
const dialogStyles: Partial<IDialogContentStyles> = {
  subText: {
    margin: 0
  }
}

interface PrintProps {
  data: MovementOrders | undefined;
  selectedTraveler: MovementOrderTraveler | undefined;
  travelerSet: Set<string>;
  onRefetch: () => void;
}

export const Print: React.FC<PrintProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const { addToast } = useToasts();
  const client = useApolloClient();
  const [onPrint] = useMutation<
    MovementReportRequest,
    MovementReportRequestVariables
  >(PRINT_MOVEMENT_ORDERS, { errorPolicy: 'all' });

  const onProceed = async () => {
    setVisible(false);
    const errors = await onPrint({
      variables: {
        input: {
          entityId: Array.from(props.travelerSet),
        },
      },
    });

    if (!!errors.errors?.length)
      addToast('Error while printing movement orders', {
        appearance: 'error',
      });
    else {
      Array.from(props.travelerSet).forEach((item) => {
        const observer = client.subscribe<
          TravelAuthorizationMovementReportStatus,
          TravelAuthorizationMovementReportStatusVariables
        >({
          query: TRAVEL_MOVEMENT_REPORT_STATUS,
          variables: {
            id: item!,
          },
        });
        const subscription = observer.subscribe(({ data, errors }) => {
          if (errors) {
            addToast('Errors received while subscribing to report status', {
              appearance: 'error',
            });
          } else {
            const { status } = {
              ...data?.travelAuthorizationMovementReportStatus,
            };
            if (status === ReportStatusType.SUCCESS) {
              addToast('Report generation successful.', {
                appearance: 'success',
              });
              props.onRefetch();
            }
            if (status === ReportStatusType.FAILURE) {
              addToast('Report generation failed.', { appearance: 'error' });
            }
          }
          subscription.unsubscribe();
        });
      });
      //   addToast('Successfully Printed movement orders', {
      //   appearance: 'success',
      // });
    }
  };

  if (!!Array.from(props.travelerSet).length)
    return (
      <Stack>
        <DefaultButton
          iconProps={{ iconName: 'Print' }}
          text="Print Movement Order"
          onClick={() => setVisible(true)}
        />
        <RebuildModal
          visible={visible}
          onCancel={() => setVisible(false)}
          onProceed={onProceed}
          {...props}
        />
      </Stack>
    );
  else return null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: 40,
  },
}));

const RebuildModal: React.FC<
  PrintProps & {
    visible: boolean;
    onCancel: () => void;
    onProceed: () => void;
  }
> = ({ visible, onCancel, onProceed }) => {
  const styles = useStyles();

  if (visible)
    return (
      <Dialog
        hidden={false}
        dialogContentProps={{
          title: `Print Movement Orders`,
          type: DialogType.largeHeader,
          subText: "Press Print to create new movement order documents for the selected travelers.",
          styles: dialogStyles
        }}
        minWidth={350}
      >
        <Stack className={styles.container}>
          <DialogFooter>
            <SuccessButton onClick={onProceed} text={'Print'} />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </DialogFooter>
        </Stack>
      </Dialog>
    );
  else return null;
};
