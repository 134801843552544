import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import {
  EntityDeleteInput,
  PurchaseOrderInvoiceScheduleDistributionPatch,
  PurchaseOrderInvoiceScheduleDistributionUpdateTypeInput,
  TransactionLayout,
} from 'common/types/globalTypes';
import { dateConvertions } from 'common/utils/dateFormats';
import { isEmpty } from 'lodash';
import {
  DISTRIBUTION_INITIAL_VALUES,
  INVOICE_SCHEDULE_INITIAL_VALUES,
} from './constants';
import {
  ItemDistributionValues,
  PurchaseOrderInvoiceScheduleValues,
} from './interface';
import { PurchaseOrderInvoiceSchedule_purchaseOrderInvoiceSchedule } from './__generated__/PurchaseOrderInvoiceSchedule';

interface DefaultValueProps {
  isNew: boolean;
  userDefaults?: UserDefaults_userDefaults_nodes | undefined;
  invoiceSchedule:
    | PurchaseOrderInvoiceSchedule_purchaseOrderInvoiceSchedule
    | undefined;
  isScheduleUpdatable: boolean;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, invoiceSchedule, userDefaults, isScheduleUpdatable } = {
    ...props,
  };
  const {
    scheduledDate,
    scheduledAmount,
    scheduledNote,
    invoiceSequence,
    purchaseOrderInvoiceScheduleDistributions,
  } = {
    ...invoiceSchedule,
  };
  const {
    referenceCode6,
    referenceCode7,
    project,
    accountCode,
    lookupAccounts,
  } = {
    ...userDefaults,
  };
  const projectReference =
    userDefaults?.distributionLayoutType?.layoutType ===
    TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT
      ? project?.code
      : undefined;
  let defaultValues: PurchaseOrderInvoiceScheduleValues = {
    ...INVOICE_SCHEDULE_INITIAL_VALUES,
  };
  if (invoiceSchedule && !isNew) {
    const { nodes } = {
      ...purchaseOrderInvoiceScheduleDistributions,
    };

    defaultValues = {
      scheduledDate: scheduledDate ? dateConvertions(scheduledDate) : null,
      scheduledAmount: scheduledAmount || null,
      scheduledNote: scheduledNote || null,
      invoiceSequence: invoiceSequence || null,
      purchaseOrderItemDistribution: !!nodes?.length
        ? [
            ...nodes.map((row) => {
              const patch = Object.entries(row).reduce((res, [key, val]) => {
                if (key === '_createdDate' || key === '__typename') return res;
                else return { ...res, [key]: val };
              }, {});
              return patch as ItemDistributionValues;
            }),
            //Add an empty distribution row only if the isScheduleUpdatable flag is true
            ...(isScheduleUpdatable
              ? [
                  {
                    ...DISTRIBUTION_INITIAL_VALUES,
                    referenceCode6: referenceCode6!,
                    referenceCode7: referenceCode7!,
                    projectReference: projectReference!,
                    accountReference:
                      accountCode === undefined ? null : accountCode,
                    _accountName: lookupAccounts?.lookupName ?? null,
                  },
                ]
              : []),
          ]
        : [
            ...(isScheduleUpdatable
              ? [
                  {
                    ...DISTRIBUTION_INITIAL_VALUES,
                    referenceCode6: referenceCode6!,
                    referenceCode7: referenceCode7!,
                    projectReference: projectReference!,
                    accountReference:
                      accountCode === undefined ? null : accountCode,
                    _accountName: lookupAccounts?.lookupName ?? null,
                  },
                ]
              : []),
          ],
    };
  }
  return defaultValues as PurchaseOrderInvoiceScheduleValues;
};

export const getDefaultDistribution = (
  userDefaults?: UserDefaults_userDefaults_nodes | undefined
) => {
  const {
    referenceCode6,
    referenceCode7,
    project,
    accountCode,
    lookupAccounts,
  } = {
    ...userDefaults,
  };

  const projectReference =
    userDefaults?.distributionLayoutType?.layoutType ===
    TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT
      ? project?.code
      : undefined;

  let defaultValues: ItemDistributionValues | undefined = {
    ...DISTRIBUTION_INITIAL_VALUES,
    referenceCode6: referenceCode6 === undefined ? null : referenceCode6,
    referenceCode7: referenceCode7 === undefined ? null : referenceCode7,
    projectReference: projectReference!,
    accountReference: accountCode === undefined ? null : accountCode,
    _accountName: lookupAccounts?.lookupName ?? null,
  };
  return defaultValues;
};

export const getUpdatedDistributions = (
  formikValues: PurchaseOrderInvoiceScheduleValues,
  defaultValues: PurchaseOrderInvoiceScheduleValues
) => {
  const { purchaseOrderItemDistribution: defaultDistribution } = {
    ...defaultValues,
  };
  return formikValues.purchaseOrderItemDistribution
    ?.filter((row) => {
      const index = defaultDistribution?.findIndex(
        (item) => item.id === row.id && row.id !== null
      );

      if (index !== -1) {
        const patch = Object.entries(row).reduce((res, [key, val]) => {
          if (
            val !==
            defaultDistribution?.[index!][key as keyof ItemDistributionValues]
          )
            return { ...res, [key]: val };
          else return res;
        }, {});
        return !isEmpty(patch);
      } else return false;
    })
    .map((item) => {
      const patch = Object.entries(item).reduce((res, [key, val]) => {
        if (
          key === '_createdDate' ||
          key === '_rowTimestamp' ||
          key === '__typename' ||
          key === '_isDeletable' ||
          key === '_isUpdatable' ||
          key === 'id' ||
          key === '_accountName'
        )
          return res;
        else return { ...res, [key]: val };
      }, {});

      return {
        id: item.id,
        rowTimestamp: item._rowTimestamp,
        purchaseOrderInvoiceScheduleDistributionUpdatePatch:
          patch as PurchaseOrderInvoiceScheduleDistributionPatch,
      } as PurchaseOrderInvoiceScheduleDistributionUpdateTypeInput;
    });
};

const getNonNullRows = (
  distributionRows: ItemDistributionValues[],
  userDefaults?: UserDefaults_userDefaults_nodes | undefined
) => {
  return distributionRows.filter((row) => {
    const patch = Object.entries(row).reduce((res, [key, val]) => {
      if (
        key === 'referenceCode6' ||
        key === 'referenceCode7' ||
        key === 'accountReference'||
        key==='_accountName'
      )
        return res;
      else if (
        (key === 'projectReference' &&
          val === TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT) ||
        (key === 'projectReference' &&
          val !== TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT)
      )
        return res;
      else if (
        val !== DISTRIBUTION_INITIAL_VALUES[key as keyof ItemDistributionValues]
      )
        return { ...res, [key]: val };
      return res;
    }, {});

    return !isEmpty(patch);
  });
};

const getDistributionFormValues = (
  values: ItemDistributionValues,
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const patch = Object.entries(values).reduce((res, [key, val]) => {
    if (
      key === 'id' ||
      key === '_rowTimestamp' ||
      (key === 'projectReference' &&
        userDefaults?.distributionLayoutType?.layoutType ===
          TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT)
    )
      return res;
    else return { ...res, [key]: val };
  }, {});
  return patch;
};

export const getNewDistributions = (
  formikValues: PurchaseOrderInvoiceScheduleValues,
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const distributions = formikValues.purchaseOrderItemDistribution
    ? getNonNullRows(
        formikValues.purchaseOrderItemDistribution || [],
        userDefaults
      )
        ?.filter((row) => row.id === null)
        .map((item) => getDistributionFormValues(item, userDefaults))
    : [];

  return distributions as ItemDistributionValues[];
};

export const getRemovedDistributions = (
  // data: InvoiceSchedulesItem | undefined,
  data: PurchaseOrderInvoiceSchedule_purchaseOrderInvoiceSchedule | undefined,
  formikValues: PurchaseOrderInvoiceScheduleValues
) => {
  return data?.purchaseOrderInvoiceScheduleDistributions?.nodes
    .filter((item) => {
      return (
        formikValues.purchaseOrderItemDistribution?.findIndex((innerItem) => {
          return innerItem.id === item.id;
        }) === -1
      );
    })
    .map(
      (item) =>
        ({
          rowTimestamp: item._rowTimestamp,
          id: item.id,
        } as EntityDeleteInput)
    );
};
