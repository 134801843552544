import { InvoicesOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: InvoicesOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'type',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC],
    },
  ],
  [
    'vendorReference',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.VENDOR_REFERENCE_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.VENDOR_REFERENCE_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'invoiceNumber',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.INVOICE_NUMBER_ASC],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.INVOICE_NUMBER_DESC,
      ],
    },
  ],
  [
    'invoiceDate',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.INVOICE_DATE_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.INVOICE_DATE_DESC],
    },
  ],
  [
    'amount',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.CONTROL_TOTAL_AMOUNT_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.CONTROL_TOTAL_AMOUNT_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [InvoicesOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), InvoicesOrderBy.PRIMARY_KEY_ASC];
};
