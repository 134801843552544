import { Stack, Text } from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';
interface UnsavedIndicatorProps {
  visible: boolean;
}

export const UnsavedIndicator: React.FC<UnsavedIndicatorProps> = ({
  visible,
}) => {
  const styles = useStyles();
  return (
    <>
      {visible && (
        <Stack className={styles.unsavedMessage}>
          <Text className={styles.textStyles}>Unsaved</Text>
        </Stack>
      )}
    </>
  );
};
