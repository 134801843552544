import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const centerAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'center',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: '_urgencyLevel',
    name: '',
    fieldName: '_urgencyLevel',
    showColumnTitle: 'Urgent',
    minWidth: 10,
    maxWidth: 10,
    //  styles: colHeaderLeftAlignStyle,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
  },
  {
    key: 'approvalType',
    name: 'Type',
    fieldName: 'approvalType',
    minWidth: 150,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 200,
    maxWidth: 250,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 260,
    ...commonColumnProps,
  },
  {
    key: 'priorityNumber',
    name: 'Priority Number',
    fieldName: 'priorityNumber',
    minWidth: 90,
    maxWidth: 120,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: 'isAddApproversEligible',
    name: 'Add Approvers',
    fieldName: 'isAddApproversEligible',
    minWidth: 100,
    maxWidth: 120,
    ...commonColumnProps,
    styles: centerAlignHeaderStyle,
  },
  {
    key: 'phaseName',
    name: 'Project phase',
    fieldName: 'phaseName',
    minWidth: 100,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 120,
    maxWidth: 150,
    ...commonColumnProps,
  },
];
