import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  root: { height: 720, width: 1200, overflow: 'hidden' },
  header: {
    marginBottom: 20,
    border: theme.isInverted ? 'none' : '1px solid white',
    borderBottomColor: theme.palette.neutralQuaternaryAlt,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  modalContainer: {
    overflow: 'hidden',
  },
}));
