import { useMutation } from '@apollo/client';
import { ContextualMenu, DayOfWeek, DefaultButton, IDragOptions, Label, Modal, PrimaryButton, ProgressIndicator, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseButton } from 'common/components/Buttons';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { FormHookDatePicker, FormHookDropdown, FormHookTextField } from 'common/components/FormHooksFields';
import { EntityEnvironmentalDelete, EntityEnvironmentalDeleteVariables } from 'common/graphql/__generated__/EntityEnvironmentalDelete';
import { EntityEnvironmentalUpdate, EntityEnvironmentalUpdateVariables } from 'common/graphql/__generated__/EntityEnvironmentalUpdate';
import { EntityEnvironmentalItemUpdateTypeInput, TransactionLayout } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { PurchaseOrder_purchaseOrder_entityEnvironmentalsByEntityId } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { CategoryOptionsType } from '..';
import { CarbonAccountingValues } from '../types';
import { validationSchema } from '../validation';
import { EnvironmentalItemsView } from './EnvironmentalItemsView';
import { EntityEnvironmentalPurchaseOrderCreate, EntityEnvironmentalPurchaseOrderCreateVariables } from './__generated__/EntityEnvironmentalPurchaseOrderCreate';
import { useStyles } from './index.styles';
import { PurchaseOrder_purchaseOrder_entityEnvironmentalsByEntityId_nodes_entityEnvironmentalItems_nodes } from '../../../__generated__/PurchaseOrder'
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { CARBONACCOUNTING_INITIAL_VALUES, ENVIRONMENTAL_ITEMS_VALUES } from '../constant';
const ENTITY_ENVIRONMENT_PURCHASE_ORDER_CREATE = loader(
  './EntityEnvironmentalPurchaseOrderCreate.graphql'
);
const ENTITY_ENVIRONMENT_DELETE = loader('../../../../../common/graphql/EntityEnvironmentalDelete.graphql');
const ENTITY_ENVIRONMENT_UPDATE = loader('../../../../../common/graphql/EntityEnvironmentalUpdate.graphql');

interface FormModalProps {
  entityId: string;
  isEnvironmentalsExist: boolean;
  categoryOptions: CategoryOptionsType[];
  purchaseOrderDetails: PurchaseOrder_purchaseOrder_entityEnvironmentalsByEntityId | undefined;
  refetchPO: (isDeleted: boolean) => void;
  setOpen: (open: boolean) => void;
}

export type EntityEnvironmentalItemsType = Omit<
  PurchaseOrder_purchaseOrder_entityEnvironmentalsByEntityId_nodes_entityEnvironmentalItems_nodes,
  '_isIntegerFormat' | '_isFloatFormat' | '_isAmountFormat'
> & {
  isIntegerFormat: boolean | null;
  isFloatFormat: boolean | null;
  isAmountFormat: boolean | null;
};

const CONFIRM_DELETE_DIALOG_TITLE = 'Please confirm the action.';
const CONFIRM_DELETE_DIALOG_SUBTEXT = 'This will remove the accounting from this purchase order.';

const modalDragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
}

export const FormModal: React.FC<FormModalProps> = ({
  entityId,
  isEnvironmentalsExist,
  categoryOptions,
  purchaseOrderDetails,
  refetchPO,
  setOpen
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [categoryId, setCategoryId] = useState<string | undefined>();
  const [layoutType, setLayoutType] = useState<TransactionLayout | undefined>();
  const [categoryName, setCategoryName] = React.useState<string | undefined>();
  const [entityEnvironmentalItems, setEntityEnvironmentalItems] = useState<EntityEnvironmentalItemsType[]>([]);
  const [hideConfirmDialog, { toggle: toggleConfirmDialog }] = useBoolean(true);

  const {
    id,
    _rowTimestamp
  } = { ...purchaseOrderDetails?.nodes[0] }

  const [
    createEnvironment,
    { loading: createLoading },
  ] = useMutation<
    EntityEnvironmentalPurchaseOrderCreate,
    EntityEnvironmentalPurchaseOrderCreateVariables
  >(ENTITY_ENVIRONMENT_PURCHASE_ORDER_CREATE, { errorPolicy: 'all' });

  const [deleteEnvironment, { loading: deleteLoading }] =
    useMutation<EntityEnvironmentalDelete, EntityEnvironmentalDeleteVariables>(
      ENTITY_ENVIRONMENT_DELETE,
      { errorPolicy: 'all' }
    );

  const [updateEnvironment, { loading: updateEnvironmentLoading }] =
    useMutation<EntityEnvironmentalUpdate, EntityEnvironmentalUpdateVariables>(
      ENTITY_ENVIRONMENT_UPDATE,
      { errorPolicy: 'all' }
    );

  const formMethods = useForm<CarbonAccountingValues>({
    mode: "all",
    resolver: yupResolver(validationSchema()),
  });

  const {
    handleSubmit,
    reset,
    trigger,
    formState: {
      errors,
      isDirty,
      isSubmitting
    }
  } = { ...formMethods }

  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack])

  const showProgressIndicator = createLoading || deleteLoading || updateEnvironmentLoading;

  const onHandleSubmit = async (values: CarbonAccountingValues) => {
    if (isEnvironmentalsExist && categoryId) {
      //Map the initial values of Environmental Items row to the EntityEnvironmentalItemUpdateTypeInput type array
      let itemUpdateFilter: EntityEnvironmentalItemUpdateTypeInput[] = [];
      if (values.entityEnvironmentalItems?.length! > 0) {
        values.entityEnvironmentalItems?.map((item) => {
          if (item.id) {
            const environmentalItemObj: EntityEnvironmentalItemUpdateTypeInput = {
              id: item.id,
              rowTimestamp: purchaseOrderDetails?.nodes[0]._rowTimestamp,
              entityEnvironmentalItemPatch: {
                environmentalImpactTypeId: item.environmentalImpactTypeId,
                carbonCreditsAmount: item.carbonCreditsAmount,
                feet: item.feet,
                gallons: item.gallons,
                hours: item.hours,
                kilogram: item.kilogram,
                kilometers: item.kilometers,
                kilowattHours: item.kilowattHours,
                litres: item.litres,
                meters: item.meters,
                metricTonnes: item.metricTonnes,
                miles: item.miles,
                omissionsEquivalent: item.omissionsEquivalent,
                pounds: item.pounds,
                quantity: item.quantity,
                therms: item.therms,
                tons: item.tons,
                yards: item.yards,
              },
            };
            itemUpdateFilter.push(environmentalItemObj);
          }
        });
      }
      const { errors } = await updateEnvironment({
        variables: {
          input: {
            id: purchaseOrderDetails?.nodes[0].id!,
            rowTimestamp: purchaseOrderDetails?.nodes.length
              ? purchaseOrderDetails.nodes[0]._rowTimestamp!
              : '',
            entityEnvironmentalPatch: {
              description: values.description,
              startDate: values.startDate ? dateFormat(values.startDate) : null,
              endDate: values.endDate ? dateFormat(values.endDate) : null,
            },
            entityEnvironmentalItemsUpdate: itemUpdateFilter,
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Environmental updated successfully', {
          appearance: 'success',
        });
        setOpen(false);
        refetchPO(true);
      }
    } else {
      const { errors } = await createEnvironment({
        variables: {
          input: {
            entityEnvironmental: {
              description: values.description,
              entityId: entityId,
              environmentalMetricId: values.environmentalMetricId!,
              startDate: values.startDate ? dateFormat(values.startDate) : null,
              endDate: values.endDate ? dateFormat(values.endDate) : null,
            },
            entityEnvironmentalItems: values.entityEnvironmentalItems?.map(
              ({ isIntegerFormat, isFloatFormat, isAmountFormat, ...rest }) =>
                rest
            ),
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Environmental added successfully', { appearance: 'success' });
        setOpen(false);
        refetchPO(true);
      }
    }
  }

  const entityEnvironmentalDelete = async () => {
    const { errors } = await deleteEnvironment({
      variables: {
        input: {
          entityDelete: [
            {
              id: id!,
              rowTimestamp: _rowTimestamp!,
            },
          ],
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Environmental deleted successfully', { appearance: 'success' });
      setEntityEnvironmentalItems([]);
      setCategoryId(undefined);
      setOpen(false);
      refetchPO(true);
    }
  };

  const showDeleteButton = purchaseOrderDetails?.nodes && purchaseOrderDetails?.nodes?.length > 0;

  useEffect(() => {
    if (purchaseOrderDetails?.nodes.length! > 0) {
      setCategoryId(purchaseOrderDetails?.nodes[0].environmentalMetricId!);
      setLayoutType(purchaseOrderDetails?.nodes[0].environmentalMetric?.layoutType!);
      setCategoryName(
        purchaseOrderDetails?.nodes[0].environmentalMetric?.environmentalMetric!
      );
      //Map the values coming from purchaseOrderDetails to a entityEnvironmentalItems state used to set the initial values of entityEnvironmentalItems.
      if (purchaseOrderDetails?.nodes[0].entityEnvironmentalItems.nodes.length! > 0) {
        const environmentalItemsArray: EntityEnvironmentalItemsType[] =
          purchaseOrderDetails!.nodes[0]!.entityEnvironmentalItems.nodes!.map(
            (ele) => {
              return {
                carbonCreditsAmount: ele.carbonCreditsAmount,
                entityEnvironmentalId: ele.entityEnvironmentalId,
                environmentalImpactTypeId: ele.environmentalImpactTypeId,
                isIntegerFormat: ele._isIntegerFormat,
                isFloatFormat: ele._isFloatFormat,
                isAmountFormat: ele._isAmountFormat,
                feet: ele.feet,
                gallons: ele.gallons,
                hours: ele.hours,
                id: ele.id,
                kilogram: ele.kilogram,
                kilometers: ele.kilometers,
                kilowattHours: ele.kilowattHours,
                litres: ele.litres,
                meters: ele.meters,
                metricTonnes: ele.metricTonnes,
                miles: ele.miles,
                omissionsEquivalent: ele.omissionsEquivalent,
                pounds: ele.pounds,
                quantity: ele.quantity,
                therms: ele.therms,
                tons: ele.tons,
                yards: ele.yards,
                _rowTimestamp: ele._rowTimestamp,
                _isUpdatable: ele._isUpdatable,
                _isDeletable: ele._isDeletable,
              };
            }
          );
        setEntityEnvironmentalItems(environmentalItemsArray);
      }
    }
  }, [purchaseOrderDetails]);

  useEffect(() => {
    let initialValues: CarbonAccountingValues = CARBONACCOUNTING_INITIAL_VALUES;
    if (purchaseOrderDetails?.nodes.length! > 0) {
      initialValues = {
        environmentalMetricId: purchaseOrderDetails?.nodes[0].environmentalMetricId!,
        startDate: purchaseOrderDetails?.nodes[0].startDate
          ? dateConvertions(purchaseOrderDetails?.nodes[0].startDate)
          : null,
        endDate: purchaseOrderDetails?.nodes[0].endDate
          ? dateConvertions(purchaseOrderDetails?.nodes[0].endDate)
          : null,
        description: purchaseOrderDetails?.nodes[0].description!,
        entityEnvironmentalItems:
          entityEnvironmentalItems.length > 0
            ? [...entityEnvironmentalItems]
            : [ENVIRONMENTAL_ITEMS_VALUES],
      };
    }
    reset(initialValues);
  }, [purchaseOrderDetails, entityEnvironmentalItems, reset]);

  return (
    <>
      <Modal
        isOpen
        dragOptions={modalDragOptions}
      >
        <div className={styles.header}>
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <Text variant={'xLarge'}>Carbon accounting</Text>
            {isEnvironmentalsExist && categoryName && (
              <Text variant={'xLarge'} className={styles.accountingType}>
                {categoryName}
              </Text>
            )}
          </Stack>
          <Stack>
            <CloseButton
              onClick={
                () => {
                  reset();
                  setOpen(false)
                }}
            />
          </Stack>
        </div>
        <FormProvider {...formMethods}>
          <Stack className={styles.mainContainer} tokens={{ childrenGap: 20 }}>
            <Stack
              className={styles.formContainer}
              tokens={{ childrenGap: 20 }}
            >
              <FormHookDropdown
                label="Category"
                placeholder="Select"
                options={categoryOptions}
                name="environmentalMetricId"
                onChange={(_event, option) => {
                  const selectedOption = option as CategoryOptionsType;
                  setCategoryId(selectedOption.key.toString());
                  setLayoutType(selectedOption.layoutType!);
                }}
                disabled={isEnvironmentalsExist}
                required
              />
              <Stack>
                <Label>Time period</Label>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Stack.Item grow={1}>
                    <FormHookDatePicker
                      name="startDate"
                      placeholder="From date..."
                      ariaLabel="From date"
                      firstDayOfWeek={DayOfWeek.Monday}
                      firstWeekOfYear={1}
                      showMonthPickerAsOverlay
                      showGoToToday
                    />
                  </Stack.Item>
                  <Stack.Item grow={1}>
                    <FormHookDatePicker
                      name="endDate"
                      placeholder="To date..."
                      ariaLabel="To date"
                      firstDayOfWeek={DayOfWeek.Monday}
                      firstWeekOfYear={1}
                      showMonthPickerAsOverlay
                      showGoToToday
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
              <FormHookTextField
                name="description"
                label="Comment"
                placeholder="Enter a comment"
              />
            </Stack>
            {categoryId && layoutType && (
              <EnvironmentalItemsView
                categoryId={categoryId}
                layoutType={layoutType}
                isEnvironmentalsExist={isEnvironmentalsExist}
              />
            )}
            {showProgressIndicator && <ProgressIndicator />}
            <ConfirmDialog
              hidden={hideConfirmDialog}
              title={CONFIRM_DELETE_DIALOG_TITLE}
              subText={CONFIRM_DELETE_DIALOG_SUBTEXT}
              onDismiss={toggleConfirmDialog}
              onConfirm={() => {
                toggleConfirmDialog();
                entityEnvironmentalDelete();
              }}
            />
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 20 }}
            >
              {showDeleteButton && (
                <PrimaryButton
                  onClick={() => toggleConfirmDialog()}
                  text="Delete"
                />
              )}
              <PrimaryButton
                disabled={
                  !isDirty || Object.keys(errors).length > 0 || isSubmitting
                }
                onClick={handleSubmit(onHandleSubmit)}
                text="Save"
              />
              <DefaultButton
                onClick={() => {
                  reset();
                  setOpen(false);
                }}
                text="Cancel"
              />
            </Stack>
          </Stack>
        </FormProvider>
      </Modal>
    </>
  )
}
