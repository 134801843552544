import { Stack, Text } from "@fluentui/react";
import { useCommonStyles } from "common/styles";
import React from "react";
interface DataViewProps {
  title: string;
  content: string;
}
export const DataView: React.FC<DataViewProps> = ({ title, content }) => {
  const commonStyles = useCommonStyles();
  return (
    <Stack>
      <Text className={commonStyles.semibold} variant="medium">
        {title}
      </Text>
      <Text>{content}</Text>
    </Stack>
  );
};