import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { BatchTransaction_batchTransaction } from '../../../__generated__/BatchTransaction';
import { BatchTransactionAvailableDocumentTypes } from '../../../__generated__/BatchTransactionAvailableDocumentTypes';
import { AttachFormModal } from './AttachFormModal';
export interface AttachFormProps {
  availableDocumentTypes: BatchTransactionAvailableDocumentTypes | undefined;
  batchTransaction: BatchTransaction_batchTransaction;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  batchTransaction,
  availableDocumentTypes,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { dirty } = useFormikContext<any>();

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={dirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          batchTransaction={batchTransaction}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  );
};
