import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexDirection: 'row',
      height: 60,
      paddingLeft: 20,
      alignItems: 'center',
    },
    groupSelected: {
      backgroundColor: theme.palette.neutralLighter,
    },

    linkHover: {
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    linkLightHover: {
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    redColor: {
      color: 'red',
    },
    listCol1: {
      minWidth: 30,
    },
    payToColumn: {
      flex: 1, 
    },
    hideCollapseButton: {
      width: 14,
    },
    listCol2: {
      flex: 1,
      textAlignLast: 'end' 
    },
    listCol3: {
      flex: 1,
     textAlignLast: 'end'  
    },
    listCol4: {
      flex: 1,
      textAlignLast: 'end'  
    },
    listCol5: {
      flex: 1,
      textAlignLast: 'end'  
    },
    fullWidthContainer: {
      width: '96%',
    },
    isTransactionPaymentRow:{
      backgroundColor: "#1dba6e1c",
    },
    differenceText: {
      color:theme.palette.themePrimary,
      marginLeft: "6px !important",
      marginTop: 5,
    },
    differenceContainer: {
      justifyContent: "flex-end"
    }
  };
});
