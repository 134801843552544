import { useMutation } from '@apollo/client';
import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import { ConfirmDialog } from 'common/components/UrgencySelector/ConfirmDialog';
import { useCommonStyles } from 'common/styles';
import { InvoiceSigningUrgentUpdateInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  TravelAuthorizationUrgentUpdate,
  TravelAuthorizationUrgentUpdateVariables,
} from './__generated__/TravelAuthorizationUrgentUpdate';
import { useStyles } from './index.styles';
const TRAVEL_AUTH_URGENCY_UPDATE = loader(
  './TravelAuthorizationUrgentUpdate.graphql'
);
interface UrgencyFolderProps {
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
}

export const UrgencyFolder: React.FC<UrgencyFolderProps> = ({
  travelAuthorizationData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const [isVisible, setIsVisible] = useState(false);
  const isUrgent = !Boolean(travelAuthorizationData?._urgencyLevel);
  const urgencyLevelToolTip = isUrgent ? 'Remove Urgency' : 'Elevate to urgent';
  const iconName = isUrgent ? 'FabricFolderFill' : 'FabricFolder';
  const iconStyle = isUrgent ? commonStyles.urgentBox : styles.standardBox;
  const isChecked = !isUrgent;
  const [invoiceSigningUrgencyUpdate] = useMutation<
    TravelAuthorizationUrgentUpdate,
    TravelAuthorizationUrgentUpdateVariables
  >(TRAVEL_AUTH_URGENCY_UPDATE, { errorPolicy: 'all' });

  const onSubmit = async (requestComment: string | undefined | null) => {
    const updatedUrgencyLevel =
      travelAuthorizationData?._urgencyLevel === 0 ? 1 : 0;

    const { errors } = await invoiceSigningUrgencyUpdate({
      variables: {
        input: {
          entityId: travelAuthorizationData?.id!,
          rowTimestamp: travelAuthorizationData?._rowTimestamp,
          urgencyLevel: updatedUrgencyLevel,
          explanationComment: requestComment,
        } as InvoiceSigningUrgentUpdateInput,
      },
      update(cache, { data }) {
        if (!!travelAuthorizationData?.id) {
          cache.modify({
            id: cache.identify({ ...travelAuthorizationData }),
            fields: {
              _urgencyLevel: () => {
                return data?.travelAuthorizationUrgentUpdate?._newUrgentLevel;
              },
            },
          });
        }
        setIsVisible(false);
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Urgency status updated successfully', {
        appearance: 'success',
      });
    }
  };

  return (
    <Stack>
      <TooltipHost content={urgencyLevelToolTip}>
        <IconButton
          iconProps={{
            iconName: iconName,
          }}
          className={iconStyle}
          onClick={() => setIsVisible(true)}
          styles={{
            rootHovered: { color: 'redDark' },
            rootPressed: { color: 'redDark' },
          }}
        />
      </TooltipHost>
      <ConfirmDialog
        hidden={!isVisible}
        minWidth={500}
        checked={isChecked}
        onConfirm={(requestComment: string | null | undefined) =>
          onSubmit(requestComment)
        }
        onDismiss={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
      />
    </Stack>
  );
};
