import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 999,
    position: 'sticky',
    top: 0,
  },
  panelHeader: {
    zIndex: 999,
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.33)`,
    backgroundColor: theme.palette.neutralLighterAlt,
    position: 'sticky',
    top: 0,
  },
  panelIcon: {
    marginTop: 5,
    color: '#929292',
  },
}));
