import * as React from 'react';
import { 
  Shimmer, 
  ShimmerElementType,
} from '@fluentui/react/lib/Shimmer';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { mergeStyles } from '@fluentui/react/lib/Styling';

const wrapperClass = mergeStyles({
  padding: 25,
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '25px 0',
    },
  },
});
 
const shimmerWithElementSecondRow = [
 
  { type: ShimmerElementType.line, height: 16, width: '20%' },
  { type: ShimmerElementType.gap, width: '5%' },
  { type: ShimmerElementType.line, height: 16, width: '20%' },
  { type: ShimmerElementType.gap, width: '10%' },
  { type: ShimmerElementType.line, height: 16, width: '20%' },
  { type: ShimmerElementType.gap, width: '10%' },
  { type: ShimmerElementType.line, height: 16, width: '20%' },
]; 
export const DocumentLoaderShimmer: React.FunctionComponent = () => (
  <Fabric className={wrapperClass}>
  
    <Shimmer width="20%" height={40} />
    <Shimmer width="10%" height={40} />
    <Shimmer width="40%" height={40} /> 
    <Shimmer shimmerElements={shimmerWithElementSecondRow} />
    <Shimmer shimmerElements={shimmerWithElementSecondRow} />
    <Shimmer shimmerElements={shimmerWithElementSecondRow} />
    <Shimmer shimmerElements={shimmerWithElementSecondRow} />
    <Shimmer shimmerElements={shimmerWithElementSecondRow} />


    <Shimmer width="20%" height={40} />
    <Shimmer width="10%" height={40} />
    <Shimmer width="40%" height={40} />
    <Shimmer width="20%" height={40} />
    <Shimmer width="10%" height={40} />
    <Shimmer width="40%" height={40} />
    <Shimmer width="20%" height={40} />
    <Shimmer width="10%" height={40} />
    <Shimmer width="40%" height={40} /> 
  </Fabric>
);
