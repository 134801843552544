import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { DepartmentOccupationTemplatesOrderBy } from 'common/types/globalTypes';
import { UserTemplatesVariables } from './__generated__/UserTemplates';

type OrderLookup = {
  [direction in OrderDirection]: DepartmentOccupationTemplatesOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [DepartmentOccupationTemplatesOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [DepartmentOccupationTemplatesOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [DepartmentOccupationTemplatesOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [DepartmentOccupationTemplatesOrderBy.DESCRIPTION_DESC],
    },
  ],
  
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const defaultOrderBy = [
    DepartmentOccupationTemplatesOrderBy.NAME_ASC,
    DepartmentOccupationTemplatesOrderBy.PRIMARY_KEY_ASC,
  ];
  const orderByArray = [DepartmentOccupationTemplatesOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return defaultOrderBy;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const onHandleSearch = (sortOrderParam: SortOrder | undefined) => {
  const variables: UserTemplatesVariables = {
    orderBy: toOrderByVariable(sortOrderParam),
  };
  return {
    queryVariables: variables,
  };
};
