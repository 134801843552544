import { Callout, DirectionalHint, Separator, Stack } from '@fluentui/react'
import React from 'react';
import { useStyles } from './index.styles'
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { AddressLine } from './AddressLine';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { Heading } from './Heading';

interface DeliveryAddressCalloutProps {
  targetID: string;
  purchaseOrder: PurchaseOrder | undefined;
}
export const DeliveryAddressCallout: React.FC<DeliveryAddressCalloutProps> = ({
  targetID,
  purchaseOrder,
}) => {
  const styles = useStyles();

  const {
    deliveryAddressAlias,
    deliveryAddressLine1,
    deliveryAddressLine2,
    deliveryCityName,
    deliveryTelephoneNumber,
    deliveryEmailAddress,
    scheduledDeliveryDate,
  } = { ...purchaseOrder?.purchaseOrder }

  const heading = "Delivery Address";

  return (
    <Stack>
      <Callout
        target={targetID}
        directionalHint={DirectionalHint.leftCenter}
        setInitialFocus
      >
        <Stack className={styles.container}>
          <Stack
            horizontalAlign="center"
            tokens={{ padding: "10px 0px 0px 0px" }}
          >
            <Heading data={heading} />
          </Stack>
          <Separator />
          <Stack tokens={{ padding: 10 }}>
            <AddressLine data={deliveryAddressAlias} />
            <AddressLine data={deliveryAddressLine1} />
            <AddressLine data={deliveryAddressLine2} />
            <AddressLine data={deliveryCityName} />
            <AddressLine data={deliveryTelephoneNumber} />
            <AddressLine data={deliveryEmailAddress} />
            {
              scheduledDeliveryDate &&
              <AddressLine data={dateFormat(dateConvertions(scheduledDeliveryDate))} />
            }
          </Stack>
        </Stack>
      </Callout>
    </Stack>
  )
}
