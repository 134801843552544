import { useLazyQuery } from '@apollo/client';
import {
  ITextFieldProps,
  ITextFieldStyles,
  IconButton,
  Label,
  Link,
  Spinner,
  Stack,
  Text,
  TextField,
  makeStyles,
  useTheme,
} from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { LookupCorporateAccountFields } from 'settings/account/corporateAccounts/__generated__/LookupCorporateAccountFields';
import {
  LookupCorporateAccountSearch,
  LookupCorporateAccountSearchVariables,
} from 'settings/account/corporateAccounts/list/__generated__/LookupCorporateAccountSearch';
import { LookupAccountFields_corporateAccount } from '../../__generated__/LookupAccountFields';
import { LookUpCallout } from './Lookup';
const LOOKUP_CORPORATE_ACCOUNT_SEARCH = loader(
  '../../../corporateAccounts/list/LookupCorporateAccountSearch.graphql'
);

const useStyles = makeStyles((theme) => ({
  resultItem: {
    width: 500,
    backgroundColor: theme.palette.neutralLighterAlt,
    textDecoration: 'none',
    margin: '0px -20px',
    padding: '6px 20px',
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
    ':active': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
  icon: {
    color: theme.palette.black,
  },
}));

type CorporateAccountAutoCompleteProps = Partial<ITextFieldProps> & {
  lookUpCalloutWidth?: number;
  chartOfAccountId: string;
  corporateAccount?: LookupAccountFields_corporateAccount;
  onAccountSelect?: (account: LookupCorporateAccountFields | null) => void;
};

export const CorporateAccountAutoComplete: React.FC<
  CorporateAccountAutoCompleteProps
> = ({
  lookUpCalloutWidth = 500,
  corporateAccount,
  onAccountSelect,
  chartOfAccountId,
  ...props
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [value, setValue] = useState<string | null>();
  const [disableTextfield, setDisableTextfield] = useState<
    boolean | null | undefined
  >(false);

  const textFieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: theme.palette.white,
    },
  };

  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupCorporateAccountSearch,
    LookupCorporateAccountSearchVariables
  >(LOOKUP_CORPORATE_ACCOUNT_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { id, disabled, label, required, ...textFieldProps } = { ...props };

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          chartOfAccountId,
          lookupName: value,
        },
      });
      queryCalled();
    }, 350),
    [value]
  );

  const renderSpinner = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disabled ? null : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              setValue(null);
              setDisableTextfield(false);
              onAccountSelect?.(null);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };

  useEffect(() => {
    if (corporateAccount?.id) {
      setValue(corporateAccount?.lookupAccount);
      setDisableTextfield(true);
    } else {
      setDisableTextfield(false);
      setValue(null);
    }
  }, [corporateAccount]);

  return (
    <Stack>
      {label && <Label required={required}>{label}</Label>}
      <TextField
        {...textFieldProps}
        id={id}
        autoComplete={'off'}
        value={value || ''}
        onChange={(_event, value) => {
          if (value) {
            setValue(value);
            callbackLookUp(value, () => {
              if (!calloutVisible) setCalloutVisible(true);
            });
          } else {
            setValue(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textFieldStyles}
        disabled={disabled ? true : disableTextfield ? true : false}
      />
      {calloutVisible &&
        !loading &&
        data?.lookupCorporateAccountSearch?.nodes.length! > 0 && (
          <LookUpCallout
            lookUpCalloutWidth={lookUpCalloutWidth}
            onDismiss={() => setCalloutVisible(false)}
            targetID={`#${id!}`}
          >
            {data?.lookupCorporateAccountSearch?.nodes.map((data, index) => {
              return (
                <Link
                  onClick={() => {
                    setValue(data.lookupAccount);
                    setCalloutVisible(false);
                    onAccountSelect?.(data);
                    setDisableTextfield(true);
                  }}
                  className={styles.resultItem}
                  key={index.toString()}
                >
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Text className={commonStyles.colorThemePrimary}>
                      {data.lookupAccount}
                    </Text>
                    <Text nowrap>{data.lookupName}</Text>
                  </Stack>
                </Link>
              );
            })}
          </LookUpCallout>
        )}
    </Stack>
  );
};
