import { makeStyles, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%"
  },
  loaderLabel: {
    paddingTop: 8
  },
}));

export const Loader = () => {
  const styles = useStyles();
  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      className={styles.container}
    >
      <Spinner
        size={SpinnerSize.large}
      />
      <Text
        className={styles.loaderLabel}
        variant="medium"
      >
        Logging in...
      </Text>
    </Stack>
  )
}
