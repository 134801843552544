import { useReactiveVar } from '@apollo/client';
import {
  ActionButton,
  ContextualMenu,
  IconButton,
  Modal,
  Stack,
} from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { setUserDefaults } from 'utility/cache/ui';
import { Trip_trip } from '../../../../../__generated__/Trip';
import { STOP_MODAL_INITIAL_VALUES } from '../../../../../constants';
import { TripCreateFormValues } from '../../../../../types';
import { useStyles } from '../index.styles';
import { FormField } from './FormField';
import { Header } from './Header';
import { getColumns } from './columns.data';
interface StopsModalProps {
  isOpen: boolean;
  disabled: boolean;
  isDepart?: boolean;
  onDismiss: () => void;
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
}

export const StopsModal: React.FC<StopsModalProps> = ({
  isOpen,
  onDismiss,
  tripIndex,
  disabled,
  isDepart = false,
}) => {
  const fieldArrayName = isDepart ? 'stopModalItems' : 'stopModalReturnItems';
  const styles = useStyles();
  const userDefaults = useReactiveVar(setUserDefaults);
  const { columnArray } = getColumns(userDefaults);
  const { watch, setValue } = useFormContext<TripCreateFormValues>();
  const { fields, remove, append } = useFieldArray({
    name: `tripItems.${tripIndex}.${fieldArrayName}`,
  });
  const watchStopModalItem = watch(`tripItems.${tripIndex}.${fieldArrayName}`);

  const watchDepartureDate = watch(`tripItems.${tripIndex}.departureDate`);
  const watchReturnDate = watch(`tripItems.${tripIndex}.returnDate`);

  const stopsLength = watchStopModalItem?.filter(
    (item) => item.depotName !== null
  ).length;

  const onAdd = () => {
    if (fields.length < 4) {
      append(
        {
          ...STOP_MODAL_INITIAL_VALUES,
          arriveDate: isDepart ? watchDepartureDate : watchReturnDate,
          departDate: isDepart ? watchDepartureDate : watchReturnDate,
        },
        {
          shouldFocus: false,
        }
      );
    }
  };

  return (
    <Stack>
      <Modal
        isOpen={isOpen}
        isBlocking
        focusTrapZoneProps={{
          style: {
            overflowY: 'hidden',
          },
        }}
        onDismiss={onDismiss}
        dragOptions={{
          moveMenuItemText: 'Move',
          closeMenuItemText: 'Close',
          menu: ContextualMenu,
          dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
        }}
      >
        <Header
          onClose={() => {
            setValue(
              `tripItems.${tripIndex}.${
                isDepart ? 'departureStops' : 'returnStops'
              }`,
              stopsLength || null,
              { shouldDirty: true }
            );

            // watchStopModalItem

            onDismiss();
          }}
          title={isDepart ? 'Departure Stops' : 'Return Stops'}
        />
        <Stack className={styles.container}>
          {!fields.length && (
            <Stack tokens={{ padding: 20 }}>
              <NoDataView show />
            </Stack>
          )}

          {fields.map((field, index) => {
            const baseField = `tripItems.${tripIndex}.${fieldArrayName}.${index}.`;
            return (
              <Stack
                key={field.id}
                horizontal
                tokens={{ childrenGap: 10 }}
                verticalAlign="end"
                styles={{ root: { padding: 20 } }}
              >
                {columnArray.map((value, key) => {
                  return (
                    <FormField
                      tripIndex={tripIndex}
                      index={index}
                      baseField={baseField}
                      columnData={value}
                      key={field.id + key}
                      item={watchStopModalItem?.[index]}
                      isDepart={isDepart}
                      disabled={disabled}
                      defaultLocationName={
                        watchStopModalItem?.[index].depotName
                      }
                    />
                  );
                })}

                <Stack>
                  {
                    <IconButton
                      disabled={disabled}
                      iconProps={{ iconName: 'Cancel' }}
                      ariaLabel="Close popup modal"
                      className={styles.icon}
                      onClick={() => {
                        if (index > -1) {
                          remove(index);
                        }
                      }}
                    />
                  }
                </Stack>
              </Stack>
            );
          })}

          <Stack horizontalAlign="start" tokens={{ padding: '10px 20px' }}>
            <ActionButton
              iconProps={{ iconName: 'add' }}
              disabled={fields.length >= 4 || disabled}
              text="Add Stop"
              onClick={() => onAdd()}
            />
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
};
