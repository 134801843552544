import { IconButton, Stack, TextField, makeStyles } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import React, { FC, useEffect, useState } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface ArrivalPriceProps extends NumberFormatProps {
  label?: string;
  placeholder?: string;
  name: string;
  rules?: UseControllerProps['rules'];
  defaultValue?: any;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: "0px 4px"
  },
}));
export const ArrivalPrice: FC<RequiredNameProps<ArrivalPriceProps>> = ({
  onChange,
  name,
  rules,
  disabled,
  ...props
}) => {
  const styles = useStyles();
  const [isDisabled, setIsDisabled] = useState(false);
  const [placeholder, setPlaceholder] = useState<string>();

  const {
    field: { value, onChange: onFieldChange },
    fieldState: { error },
  } = useController({ name });

  useEffect(() => {
    if (!value) {
      setIsDisabled(true)
      setPlaceholder("Included Above")
    } else {
      setIsDisabled(false)
      setPlaceholder(props.placeholder)
    }
  }, [value, props.placeholder])

  return (
    <Stack
      horizontal
      tokens={{
        childrenGap: 4
      }}
    >
      <NumberFormat
        {...props}
        value={value || ''}
        errorMessage={error?.message}
        customInput={TextField}
        thousandSeparator
        onValueChange={(valuesObject, info) => {
          if (info.source === 'event')
            onFieldChange?.(valuesObject.value || null);
        }}
        disabled={disabled || isDisabled}
        placeholder={placeholder}
        fixedDecimalScale
        decimalScale={2}
        allowNegative={false}
      />
      {
        !disabled && isDisabled &&
        <IconButton
          type="button"
          onClick={() => {
            setIsDisabled(false)
            setPlaceholder(props.placeholder)
            onFieldChange(null)
          }}
          iconProps={{ iconName: 'cancel' }}
          className={styles.icon}
          ariaLabel="Close"
        />
      }
    </Stack>
  );
};
