import { useLazyQuery } from '@apollo/client';
import OrganizationChart from '@dabeng/react-orgchart';
import {
  ProgressIndicator,
  Stack,
  Text
} from '@fluentui/react';
import clsx from 'clsx';
import { getTreeGraph } from 'common/components/Hierarchy/utils';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import React, { useCallback } from 'react';
import { NodeView } from './NodeView';
import './NodeView/my-node.css';
import {
  InvoiceSigningPreviewApprovalMap,
  InvoiceSigningPreviewApprovalMapVariables,
  InvoiceSigningPreviewApprovalMap_invoiceSigningPreviewApprovalMap_nodes,
} from './__generated__/InvoiceSigningPreviewApprovalMap';
import { useStyles } from './index.styles';
const ENTITY_APPROVAL_MAP = loader(
  './invoiceSigningPreviewApprovalMap.graphql'
);

export interface HierarchyNode
  extends InvoiceSigningPreviewApprovalMap_invoiceSigningPreviewApprovalMap_nodes {
  children?: HierarchyNode[];
}

interface HierarchyProps {
  entityId: string;
}
/**
 * Main hierarchy module
 * This module call data from server and runs a recursive algorithm to restructure the data
 * Once the data has been restructured it feeds into the @dabeng/react-orgchart to get the graph rendered as expected
 */
export const Hierarchy: React.FC<HierarchyProps> = ({ entityId }) => {
  const [hierarchyData, setHierarchyData] = React.useState<HierarchyNode[]>([]);

  const [getApprovalMap, { loading, data, error }] = useLazyQuery<
    InvoiceSigningPreviewApprovalMap,
    InvoiceSigningPreviewApprovalMapVariables
  >(ENTITY_APPROVAL_MAP, { fetchPolicy: 'network-only' });

  const getApprovalDetails = () => {
    getApprovalMap({
      variables: {
        entityId,
      },
    });
  };
  const getApproval = useCallback(getApprovalDetails, []);

  React.useEffect(() => {
    getApproval();
  }, [getApproval]);

  const memoizedChildrenData = useCallback(getTreeGraph, []);

  React.useEffect(() => {
    if (
      !loading &&
      data?.invoiceSigningPreviewApprovalMap?.nodes?.length !== 0
    ) {
      // Implicity adding children property to avoid an error caused
      const childrenAdded = data?.invoiceSigningPreviewApprovalMap?.nodes.map(
        (item) => ({
          ...item,
          children: [] as HierarchyNode[],
        })
      );

      const newData = memoizedChildrenData(childrenAdded) as HierarchyNode[];
      setHierarchyData(newData);
    }
  }, [data, loading, memoizedChildrenData]);

  // return null
  if (hierarchyData.length > 0)
    return (
      <OrganizationChart
        datasource={hierarchyData[0]}
        chartClass="myChart"
        NodeTemplate={(node: any) => <NodeView nodeData={node.nodeData} />}
        pan={true}
        containerClass={'orgchartcontainer'}
        zoom={true}
        zoominLimit={1.2}
        zoomoutLimit={0.5}
        draggable={false}
        collapsible={false}
      />
    );
  else {
    if (error)
      return (
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          style={{ height: 600 }}
        >
          <Stack style={{ width: 800 }}>
            <Text variant="xLarge">{error.message}</Text>
          </Stack>
        </Stack>
      );
    else return <LoadingHierarchy />;
  }
};

const LOADING_TEXT = 'Loading hierarchy';
const LoadingHierarchy = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      className={styles.loaderContainer}
    >
      <Stack className={styles.loadingInnerContainer}>
        <Text className={clsx(commonStyles.semibold, styles.loadingText)}>
          {LOADING_TEXT}
        </Text>
        <ProgressIndicator />
      </Stack>
    </Stack>
  );
};
