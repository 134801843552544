import React, { useEffect, useState } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { globalMode } from 'utility/cache/ui';
import { PaymentTracking } from './list';
import { StampedTransactions } from './StampedTransactions/list';
import { IDropdownOption } from '@fluentui/react';

export const PayTrackngPivotTabs = {
  paymentTracking: 'paymentTracking',
  stampedTransaction: 'stampedTransaction',
};

export const paymentTrackingTabMode = makeVar<string>(
  PayTrackngPivotTabs.paymentTracking
);

export interface PaymentCyclesOptions extends IDropdownOption {
  key: string | number;
  text: string;
  currency: string | undefined | null;
  defaultPaymentDate: Date | null;
  transactionTypeId: number;
  businessUnit: string;
  currencyId: number;
  businessUnitId: string;
}

export type PayCycleSelectedType = Omit<PaymentCyclesOptions, "text">;

export const PayTrackingSection: React.FC = () => {
  const payTrackTabState = useReactiveVar(paymentTrackingTabMode);
  const globalState = useReactiveVar(globalMode);
  const [payCycleSelected, setPayCycleSelected] = useState<PayCycleSelectedType | undefined>()

  if (!payTrackTabState && globalState)
    paymentTrackingTabMode(PayTrackngPivotTabs.paymentTracking);

  // defaulting to Payment Tracking list after Route change
  useEffect(() => {
    return () => {
      paymentTrackingTabMode(PayTrackngPivotTabs.paymentTracking);
    };
  }, []);

  const tabSwitchLogic =
    payTrackTabState === PayTrackngPivotTabs.paymentTracking;

  return <>{tabSwitchLogic ? <PaymentTracking /> : <StampedTransactions payCycle={payCycleSelected} onPayCycleChange={setPayCycleSelected} />}</>;
};
