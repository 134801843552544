import { Link, Stack, Text, TooltipHost } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { FilterArrayType } from 'common/components/Filters';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { FolderExpand } from '../FolderExpand';
import { nodeMapType } from '../utils';
import { useStyles } from './index.styles';

type DocumentPoolItemType = nodeMapType;

export type filterOptionProps = {
  filterTypes: FilterArrayType[];
  startsWith: boolean;
};
interface GroupHeaderProps {
  isOpen: boolean;
  documentPool: DocumentPoolItemType;
  onHeaderClick: (documentPool: nodeMapType) => void;
}

export const GroupHeader: React.FC<GroupHeaderProps> = ({
  isOpen,
  documentPool,
  onHeaderClick,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [isHovered, { setFalse: onMouseLeave, setTrue: onMouseEnter }] =
    useBoolean(false);
  const historyCount =
    documentPool?._subfolderDocumentFileCount?.selectedDocuments;
  const activeCount =
    documentPool?._subfolderDocumentFileCount?.availableDocuments;
  return (
    <Stack
      horizontal
      className={clsx(
        styles.root,
        isOpen && styles.groupSelected,
        isHovered ? styles.linkLightHover : styles.linkHover
      )}
      tokens={{ childrenGap: 50 }}
    >
      <Link
        style={{ textDecoration: 'none' }}
        onClick={() => onHeaderClick(documentPool)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <TooltipHost
          content={
            historyCount && historyCount > 0 ? (
              <Text>
                History Documents: {historyCount?.toString()}
              </Text>
            ) : undefined
          }
          calloutProps={{ gapSpace: 4 }}
        >
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 20 }}>
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              style={{ width: 300 }}
              verticalAlign="center"
            >
              <FolderExpand isOpen={isOpen} />
              <Text
                nowrap
                block
                className={commonStyles.semibold}
                variant="medium"
              >
                {documentPool.name}
              </Text>
              <Text variant="medium" className={styles.groupName}>
                {`(${activeCount})`}
              </Text>
            </Stack>
          </Stack>
        </TooltipHost>
      </Link>
    </Stack>
  );
};
