import {
  ActionButton, AnimationClassNames, Callout, Checkbox, DefaultButton, DirectionalHint, PrimaryButton, Stack,
  Text
} from '@fluentui/react';
import clsx from 'clsx';
import {
  DropdownFilter,
  FilterArrayType,
  filterCombineType
} from 'common/components/Filters';
import { TextSearchFilter } from 'common/components/Filters/TextSearchFilter';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import React, { useEffect, useState } from 'react';
import { ListFiltersProps } from '..';
import { useStyles } from './index.styles';

type ListFilterCalloutProps = Omit<ListFiltersProps, 'documentPoolId'> & {
  onDismiss?: () => void;
  calloutId: string;
};

export const historyFilterObject: FilterArrayType = {
  value: false,
  filterKey: '_isSelected',
  label: '_isSelected',
  combineType: filterCombineType.AND,
};

export const ListFilterCallout: React.FC<ListFilterCalloutProps> = ({
  onDismiss,
  calloutId,
  onFilterChange,
  filterOptions,
  documentTypeOptions,
}) => {
  const { filterTypes: filters, startsWith: startsWithDefault } = {
    ...filterOptions,
  };
  const styles = useStyles();
  const [indexName, setIndexName] = useState<string | undefined>();
  const [documentTypeId, setDocumentTypeId] = useState<
    number | undefined | null
  >();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([
    historyFilterObject,
  ]);
  const [comment, setComment] = useState<string | undefined>();
  const [indexRefNumber, setIndexRefNumber] = useState<string | undefined>();
  const [fileReference, setFileReference] = useState<string | undefined>();
  const [contents, setContents] = useState<string | undefined>();
  const [startsWith, setStartsWith] = useState<boolean | undefined>();
  const [history, setHistory] = useState<boolean | undefined>(false);
  const [activeTransaction, setActiveTransaction] = useState<
    boolean | undefined
  >(false);
  const [enterTransaction, setEnterTransaction] = useState<boolean | undefined>(
    false
  );
  const [activePayments, setActivePayments] = useState<boolean | undefined>(
    false
  );
  const [enteredPayments, setEnteredPayments] = useState<boolean | undefined>(
    false
  );

  const onFilterApply = () => {
    onFilterChange({ filterTypes, startsWith: startsWith! });
    onDismiss?.();
  };

  const onCancel = () => {
    onDismiss?.();
  };

  useEffect(() => {
    if (filters) {
      setFilterTypes(filters);
      setStartsWith(startsWithDefault);

      const indexNamePos = filters.findIndex(
        (item) => item.filterKey === 'indexName'
      );
      const documentTypeIdPos = filters.findIndex(
        (item) => item.filterKey === 'documentTypeId'
      );
      const commentPos = filters.findIndex(
        (item) => item.filterKey === 'comment'
      );

      const indexRefNumberPos = filters.findIndex(
        (item) => item.filterKey === 'indexReferenceNumber'
      );
      const fileReferencePos = filters.findIndex(
        (item) => item.filterKey === 'fileReference'
      );

      const contentsPos = filters.findIndex(
        (item) => item.filterKey === '_documentContents'
      );

      const historyPos = filters.findIndex(
        (item) => item.filterKey === '_isSelected'
      );

      // case '_isFolderStatusActiveTransactions':
      //   case '_isFolderStatusEnteredTransactions':
      //   case '_isFolderStatusActivePayments':
      //   case '_isFolderStatusEnteredPayments':

      const actTranscPos = filters.findIndex(
        (item) => item.filterKey === '_isFolderStatusActiveTransactions'
      );
      const entTranscPos = filters.findIndex(
        (item) => item.filterKey === '_isFolderStatusEnteredTransactions'
      );
      const actpaymentPos = filters.findIndex(
        (item) => item.filterKey === '_isFolderStatusActivePayments'
      );
      const entPaymentPos = filters.findIndex(
        (item) => item.filterKey === '_isFolderStatusEnteredPayments'
      );

      if (actTranscPos !== -1)
        setActiveTransaction(filters[actTranscPos].value.toString() === 'true');
      if (entTranscPos !== -1)
        setEnterTransaction(filters[entTranscPos].value.toString() === 'true');
      if (actpaymentPos !== -1)
        setActivePayments(filters[actpaymentPos].value.toString() === 'true');
      if (entPaymentPos !== -1)
        setEnteredPayments(filters[entPaymentPos].value.toString() === 'true');

      if (historyPos !== -1)
        setHistory(filters[historyPos].value.toString() === 'true');

      if (fileReferencePos !== -1)
        setFileReference(filters[fileReferencePos].value.toString());

      if (contentsPos !== -1)
        setContents(filters[contentsPos].value.toString());

      if (indexRefNumberPos !== -1)
        setIndexRefNumber(filters[indexRefNumberPos].value.toString());

      if (commentPos !== -1) setComment(filters[commentPos].value.toString());

      if (indexNamePos !== -1)
        setIndexName(filters[indexNamePos].value.toString());

      if (documentTypeIdPos !== -1)
        setDocumentTypeId(
          parseInt(filters[documentTypeIdPos].value.toString())
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    newFilters = newFilters.filter(
      (element) =>
        element.value ||
        (element.value === false && element.filterKey === '_isSelected')
    );

    const historyPos = newFilters.findIndex(
      (item) => item.filterKey === '_isSelected'
    );
    if (historyPos !== -1) {
      if (newFilters[historyPos].value === false) {
        newFilters = newFilters.filter(
          (element) => element.combineType !== filterCombineType.OR
        );
      }
    }

    setFilterTypes(newFilters);
  };

  const onReset = () => {
    setComment(undefined);
    setContents(undefined);
    setFileReference(undefined);
    setIndexRefNumber(undefined);
    setDocumentTypeId(null);
    setHistory(false);
    setIndexName(undefined);
    setStartsWith(true);
    setFilterTypes([historyFilterObject]);
    setActivePayments(false);
    setActiveTransaction(false);
    setEnteredPayments(false);
    setEnterTransaction(false);
  };

  const SlideDown = AnimationClassNames.slideDownIn20;

  return (
    <Callout
      className={styles.callout}
      gapSpace={0}
      target={`#${calloutId}`}
      directionalHint={DirectionalHint.leftTopEdge}
      onDismiss={onDismiss}
      setInitialFocus
    >
      <Stack className={styles.container}>
        <Text variant={'xLarge'}>Filters</Text>

        <Stack tokens={{ childrenGap: 10, padding: '20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item style={{ width: 245 }}>
              <DropdownFilter
                controlled
                placeholder={'Type'}
                options={documentTypeOptions}
                filterKey={'documentTypeId'}
                defaultFilter
                selectedKey={documentTypeId}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setDocumentTypeId(parseInt(value.value.toString()));
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Name"
                defaultFilter
                value={indexName}
                filterKey={'indexName'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setIndexName(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Number"
                defaultFilter
                value={indexRefNumber}
                filterKey={'indexReferenceNumber'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setIndexRefNumber(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Contents"
                defaultFilter
                value={contents}
                filterKey={'_documentContents'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setContents(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Comment"
                defaultFilter
                value={comment}
                filterKey={'comment'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setComment(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Document"
                defaultFilter
                value={fileReference}
                filterKey={'fileReference'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setFileReference(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
        </Stack>
        {history && (
          <Stack
            tokens={{ childrenGap: 20 }}
            className={clsx(styles.advancedFilterContainer, SlideDown)}
          >
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <Checkbox
                label="Active Transactions"
                checked={activeTransaction}
                onChange={(_, value) => {
                  onOptionChange({
                    label: '_isFolderStatusActiveTransactions',
                    filterKey: '_isFolderStatusActiveTransactions',
                    value: value?.toString() === 'true',
                    combineType: filterCombineType.OR,
                  });
                  setActiveTransaction(value);
                }}
              />

              <Checkbox
                label="Entered Transactions"
                checked={enterTransaction}
                onChange={(_, value) => {
                  onOptionChange({
                    label: '_isFolderStatusEnteredTransactions',
                    filterKey: '_isFolderStatusEnteredTransactions',
                    value: value?.toString() === 'true',
                    combineType: filterCombineType.OR,
                  });
                  setEnterTransaction(value);
                }}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 55 }}>
              <Checkbox
                label="Active Payments"
                checked={activePayments}
                onChange={(_, value) => {
                  onOptionChange({
                    label: '_isFolderStatusActivePayments',
                    filterKey: '_isFolderStatusActivePayments',
                    value: value?.toString() === 'true',
                    combineType: filterCombineType.OR,
                  });
                  setActivePayments(value);
                }}
              />
              <Checkbox
                label="Entered Payments"
                checked={enteredPayments}
                onChange={(_, value) => {
                  onOptionChange({
                    label: '_isFolderStatusEnteredPayments',
                    filterKey: '_isFolderStatusEnteredPayments',
                    value: value?.toString() === 'true',
                    combineType: filterCombineType.OR,
                  });
                  setEnteredPayments(value);
                }}
              />
            </Stack>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center">
            <ActionButton
              text="Reset"
              onClick={onReset}
              iconProps={{ iconName: 'RevToggleKey' }}
            />
            <Checkbox
              label="Starts with"
              checked={startsWith}
              onChange={(_, value) => {
                setStartsWith(value);
              }}
            />

            <Checkbox
              label="History"
              checked={history}
              onChange={(_, value) => {
                if (!value) {
                  setActivePayments(false);
                  setActiveTransaction(false);
                  setEnterTransaction(false);
                  setEnteredPayments(false);
                }
                onOptionChange({
                  label: '_isSelected',
                  filterKey: '_isSelected',
                  value: value?.toString() === 'true',
                  combineType: filterCombineType.AND,
                });
                setHistory(value);
              }}
            />
          </Stack>

          <Stack tokens={{ padding: '10px 0px', childrenGap: 20 }} horizontal>
            <PrimaryButton text="Apply" onClick={onFilterApply} />
            <DefaultButton text="Cancel" onClick={onCancel} />
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
