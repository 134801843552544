import { Stack } from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import Decimal from 'decimal.js';
import React from 'react';
import { ColumnData } from './columns.data';
import { useStyles } from './index.styles';

interface ColumnAmountBottomProps {
  totalSum: Decimal | null;
  columnArray: ColumnData[];
}

export const ColumnAmountBottom: React.FC<ColumnAmountBottomProps> = ({
  totalSum,
  columnArray,
}) => {
  const style = useStyles();
  const widthTotal =
    columnArray.reduce((prev, curr) => curr.maxWidth! + prev, 0) +
    columnArray.length * 10;
  const renderItem = (value: ColumnData, key: number) => {
    switch (value.key) {
      case 'delete':
        return null;
      case 'distributionAmount':
        return (
          <Stack
            style={{
              height: 50,
              width: value.maxWidth,
              textAlign: 'end',
            }}
            key={key}
          >
            <AmountTextView
              value={totalSum?.toNumber().toString()!}
              className={style.amountText}
            />
          </Stack>
        );

      default:
        return (
          <Stack
            style={{
              height: 50,
              width: value.maxWidth,
            }}
            key={key}
          />
        );
    }
  };
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 6 }}
      style={{ width: widthTotal }}
    >
      {columnArray.map((value, index) => {
        return <Stack key={index}>{renderItem(value, index)}</Stack>;
      })}
    </Stack>
  );
};
