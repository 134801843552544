import { IconButton, Link, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CardAccountType } from '..';
import { useStyles } from './index.styles';
interface CardAccountProps {
  isOpen: boolean;
  refetching: boolean;
  cardAccount: CardAccountType;
  availableCount: number;
  onHeaderClick?: (cardAccount: CardAccountType) => void;
}

export const CardAccountsList: React.FC<CardAccountProps> = ({
  isOpen,
  cardAccount,
  availableCount = 0,
  onHeaderClick,
}) => {
  const styles = useStyles();
  const type = 'account';
  const editRoute = `/project-settings/purchase-cards/card-${type}/${cardAccount.id}`;
  const history = useHistory();
  const match = useRouteMatch(editRoute!);
  return (
    <>
      <Stack
        horizontal
        verticalAlign="center"
        className={clsx(
          styles.root,
          isOpen && styles.groupSelected,
          styles.linkLightHover
        )}
      >
        <Stack
          horizontal
          verticalAlign="center"
          className={styles.accountRowCell}
          tokens={{ childrenGap: 10 }}
        >
          {isOpen ? (
            <IconButton
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
              ariaLabel="Expand"
              onClick={() => onHeaderClick?.(cardAccount)}
            />
          ) : (
            <IconButton
              iconProps={{ iconName: 'ChevronRight' }}
              className={styles.iconButtonColor}
              ariaLabel="Collapse"
              onClick={() => onHeaderClick?.(cardAccount)}
            />
          )}
          <Link
            className={styles.cardAccountName}
            onClick={() => {
              history.push(editRoute);
            }}
          >
            <Text
              nowrap
              block
              className={clsx(!!match && styles.active)}
              variant="medium"
            >
              {cardAccount.name}
            </Text>
          </Link>
          <Text variant="medium" className={styles.groupName}>
            {`(${availableCount})`}
          </Text>
        </Stack>
        <Stack className={styles.rowCell}>
          <Text nowrap block variant="medium">
            {cardAccount.description}
          </Text>
        </Stack>
        <Stack className={styles.rowCell}>
          <Text nowrap block variant="medium">
            {cardAccount.businessUnits?.name}
          </Text>
        </Stack>
        <Stack className={styles.rowCell}>
          <Text nowrap block variant="medium">
            {cardAccount.documentPools?.name}
          </Text>
        </Stack>
      </Stack>
    </>
  );
};
