import React from 'react';
import { IconButton, Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { currentUserRole } from 'utility/cache';
import { useStyles } from './index.styles';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';
import { ChangeProjectRoleCallout } from './ChangeProjectRoleCallout';

interface ProjectAndRoleProps {
  expanded: boolean;
}

export const ProjectAndRole: React.FC<ProjectAndRoleProps> = ({ expanded }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const [isChangeRoleVisible, { toggle }] = useBoolean(false);
  const buttonId = useId('change-role-button');
  const { description, tenantName } = currentUserRole()!;

  return (
    <>
      <Stack
        horizontal={expanded}
        verticalAlign="center"
        horizontalAlign={expanded ? 'space-between' : 'center'}
      >
        <Stack
          horizontal
          verticalAlign="center"
          className={commonStyles.noScroll}
        >
          <Persona
            className={clsx(
              styles.projectPersona,
              expanded ? styles.personaExpanded : styles.personaCollapsed
            )}
            text={currentUserRole()?.tenantName}
            hidePersonaDetails
            size={PersonaSize.size32}
          />

          {expanded && (
            <Stack className={commonStyles.noScroll}>
              <Text
                variant="medium"
                nowrap
                block
                className={commonStyles.semibold}
                title={tenantName}
              >
                {tenantName}
              </Text>
              <Text
                variant="small"
                nowrap
                block
                className={styles.roleText}
                title={description}
              >
                {description}
              </Text>
            </Stack>
          )}
        </Stack>
        <IconButton
          id={buttonId}
          iconProps={{
            iconName: 'EditContact',
          }}
          className={styles.editButton}
          onClick={toggle}
        />
      </Stack>
      {isChangeRoleVisible && (
        <ChangeProjectRoleCallout target={`#${buttonId}`} onDismiss={toggle} />
      )}
    </>
  );
};
