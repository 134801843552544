import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './index.styles';
import { PrimaryButton, Stack, Text } from '@fluentui/react';

export const NotFound: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <Stack
      grow
      verticalAlign="center"
      horizontalAlign="center"
      className={styles.container}
    >
      <Stack tokens={{ childrenGap: 16 }} className={styles.root}>
        <Text variant="xxLargePlus">Oops!</Text>
        <Text variant="mediumPlus">
          Unfortunately the content you’re looking for isn’t here. There may be
          a misspelling in your web address or you may have clicked a link for
          content that no longer exists.
        </Text>
        <Stack horizontal tokens={{ padding: '8px 0 0 0' }}>
          <PrimaryButton text="Home" onClick={() => history.push('/')} />
        </Stack>
      </Stack>
    </Stack>
  );
};
