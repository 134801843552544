import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

export const columns: ColumnData[] = [
  {
    key: 'vendorReference',
    name: 'Name',
    fieldName: 'vendorReference',
    minWidth: 200,
    maxWidth: 280,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 280,
    maxWidth: 360,
    ...commonColumnProps,
  },
  {
    key: 'invoiceNumber',
    name: 'Invoice / Ref. No.',
    fieldName: 'invoiceNumber',
    minWidth: 200,
    maxWidth: 280,
    ...commonColumnProps,
  },
  {
    key: 'invoiceDate',
    name: 'Date',
    fieldName: 'invoiceDate',
    minWidth: 80,
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'controlTotalAmount',
    name: 'Amount',
    fieldName: 'controlTotalAmount',
    minWidth: 150,
    maxWidth: 270,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
];
