import { useMutation } from '@apollo/client';
import { ContextualMenu, DefaultButton, Dialog, DialogFooter, IDialogProps, IModalProps, ProgressIndicator, TextField } from '@fluentui/react';
import clsx from 'clsx';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ActiveApproverListItem } from '..';
import { Header } from './Header';
import { useStyles } from './index.styles';
import { ReplaceApprover, ReplaceApproverVariables } from '../__generated__/ReplaceApprover';
const REPLACE_APPROVER = loader('../ReplaceApprover.graphql');
const ACTIVE_APPROVERS = loader('../ActiveApproverList.graphql');

export const modalProps: IModalProps = {
  isBlocking: false,
  dragOptions: {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  },
};

interface ConfirmDialogRemoveProps extends IDialogProps {
  hidden: boolean;
  selectedUser: ActiveApproverListItem | undefined;
  onDismiss: () => void;
}

export const ConfirmDialogRemove: React.FC<ConfirmDialogRemoveProps> = ({
  hidden,
  selectedUser,
  onDismiss,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [reason, setReason] = useState<string>("");

  const [
    replaceApprover,
    {
      loading: replaceApproverLoading
    }
  ] = useMutation<ReplaceApprover, ReplaceApproverVariables>(
    REPLACE_APPROVER,
    { errorPolicy: 'all' }
  );

  const onSubmit = async () => {
    const { errors } = await replaceApprover({
      variables: {
        input: {
          isBypassOnly: true,
          newUserProfileId: null,
          originalUserProfileId: selectedUser?.id || "",
          reason: reason
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ACTIVE_APPROVERS,
        },
      ]
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Approver removed successfully.', {
        appearance: 'success',
      });
      setReason("");
      onDismiss();
    }
  }

  const onDismissDialog = () => {
    onDismiss();
    setReason("");
  }

  const disabledRemoveButton = reason.length === 0 || replaceApproverLoading;
  const removeButtonStyle = clsx(disabledRemoveButton ? styles.acceptButtonDisabled : styles.acceptButton);

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismissDialog}
      minWidth={400}
      modalProps={modalProps}
    >
      {
        selectedUser?.user?.name &&
        <Header
          selectedUsername={selectedUser?.user?.name}
        />
      }
      <TextField
        multiline
        rows={3}
        value={reason}
        placeholder="Please write your reason here."
        resizable={false}
        onChange={(_event, value) => setReason(value || '')}
      />
      <DialogFooter>
        {
          replaceApproverLoading &&
          <ProgressIndicator className={styles.progress} />
        }
        <DefaultButton
          toggle
          primary={true}
          className={removeButtonStyle}
          selected={true}
          onClick={onSubmit}
          text="Remove"
          disabled={disabledRemoveButton}
        />
        <DefaultButton
          onClick={onDismissDialog}
          text="Cancel"
        />
      </DialogFooter>
    </Dialog>
  )
}
