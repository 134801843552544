import { DepartmentProps } from '..';
import { DEPARTMENT_VALUES } from './constants';
import { DepartmentValues } from './types';

type DefaultValueProps = Pick<DepartmentProps, 'department'>;

export const getDefaultValues = (props: DefaultValueProps) => {
  const { department } = { ...props };
  const {  ...departmentFields } = {
    ...department,
  };
  let defaultValues: DepartmentValues = DEPARTMENT_VALUES;
     defaultValues = { ...departmentFields };

  return defaultValues;
};
