import {
  Callout,
  CommandBarButton,
  IDropdownOption,
  IconButton,
  Stack,
  TooltipHost,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { AlertDialog } from 'common/components/AlertDialog ';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { ColumnData } from 'common/components/SearchBar';
import { CalloutContainer } from 'common/components/SearchBar/ShowColumns';
import React, { useState } from 'react';
import { useStyles } from './index.styles';

interface ActionBarProps {
  setGridColumns: React.Dispatch<React.SetStateAction<ColumnData[]>>;
  gridColumns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  departmentsOptions: IDropdownOption[];
  businessUnitOptions: IDropdownOption[];
  budgetTypeOptions: IDropdownOption[];
  currencyOptions: IDropdownOption[];
  isDirty: boolean;
}
export const ActionBar: React.FC<ActionBarProps> = ({
  gridColumns,
  setGridColumns,
  onFilterChange,
  departmentsOptions,
  businessUnitOptions,
  budgetTypeOptions,
  currencyOptions,
  isDirty,
}) => {
  const styles = useStyles();
  const [calloutVisibility, setCalloutVisibility] = useState<boolean>(false);
  const [saveFormModal, setSaveFormModal] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const showColumnsButtonId = useId('showColumns-callout-label');
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);

  const toggleVisibility = () => {
    setCalloutVisibility((prevState) => !prevState);
  };
  const CONFIRM_DIALOG_TITLE = 'Unsaved changes!';
  const FILTER_TEXT = showFilters ? 'removing filters' : 'applying any filter';
  const CONFIRM_DIALOG_SUBTEXT = `Please save your changes before ${FILTER_TEXT}`;

  const onOptionChange = (filterdata: FilterArrayType) => {
    if (isDirty) {
      setSaveFormModal(true);
    } else {
      const availableFilters = [...filterTypes];
      const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
      setFilterTypes(newFilters);
      onFilterChange?.(newFilters);
    }
  };
  const onFilterClear = () => {
    if (filterTypes.length) onFilterChange([]);
    setFilterTypes([]);
  };

  return (
    <Stack horizontal horizontalAlign="end" className={styles.container}>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ padding: '0px 10px 0px 10px', childrenGap: 20 }}
      >
        {showFilters && (
          <>
            <DropdownFilter
              filterKey={'departmentId'}
              options={departmentsOptions}
              placeholder={'Departments'}
              onOptionChange={onOptionChange}
              NoBgColor
              disabled={isDirty}
            />
            <DropdownFilter
              filterKey={'businessUnitId'}
              options={businessUnitOptions}
              placeholder={'Business Unit'}
              onOptionChange={onOptionChange}
              NoBgColor
              disabled={isDirty}
            />
            <DropdownFilter
              filterKey={'budgetTypeId'}
              options={budgetTypeOptions}
              placeholder={'Budget Type'}
              onOptionChange={onOptionChange}
              NoBgColor
              disabled={isDirty}
            />
            <DropdownFilter
              filterKey={'currencyId'}
              options={currencyOptions}
              placeholder={'Currency'}
              onOptionChange={onOptionChange}
              NoBgColor
              disabled={isDirty}
            />
          </>
        )}

        <TooltipHost content={showFilters ? 'Close filters' : 'Open Filters'}>
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 8 }}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (isDirty) {
                setSaveFormModal(true);
              } else {
                if (showFilters) onFilterClear?.();
                setShowFilters((prevState) => !prevState);
              }
            }}
          >
            <IconButton
              key="filters"
              className={
                showFilters
                  ? styles.iconButtonPrimaryStyles
                  : styles.iconButtonStyles
              }
              iconProps={{ iconName: showFilters ? 'ClearFilter' : 'Filter' }}
            />
            <Text>Filters</Text>
          </Stack>
        </TooltipHost>
        <CommandBarButton
          key="columns"
          text="Columns"
          iconProps={{ iconName: 'TripleColumn' }}
          onClick={() => toggleVisibility()}
          id={showColumnsButtonId}
        />
        {calloutVisibility && (
          <Callout
            calloutMaxWidth={400}
            role="alertdialog"
            gapSpace={0}
            target={`#${showColumnsButtonId}`}
            onDismiss={toggleVisibility}
            setInitialFocus
          >
            <CalloutContainer
              columns={gridColumns}
              onToggleVisibility={(columns) => {
                setGridColumns(columns);
              }}
            />
          </Callout>
        )}
        {saveFormModal && (
          <AlertDialog
            hidden={!saveFormModal}
            title={CONFIRM_DIALOG_TITLE}
            subText={CONFIRM_DIALOG_SUBTEXT}
            onConfirm={async () => {
              setSaveFormModal(false);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
