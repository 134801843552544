import React from 'react';
import {
  Checkbox,
  DatePicker,
  DayOfWeek,
  Stack,
  TextField,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { includes } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { Chip } from 'common/components/Chip';

interface MessageCreateProps {
  onDatesUpdate?: (dates: string[]) => void;
  existingDates?: string[];
  onMessageUpdate?: (message: string) => void;
  onRequestCheck?: (checked: boolean) => void;
}

export const MessageCreate: React.FC<MessageCreateProps> = ({
  onDatesUpdate,
  existingDates,
  onMessageUpdate,
  onRequestCheck,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [selectedDate, setSelectedDate] = React.useState<
    Date | null | undefined
  >(null);

  const onSelectDate = (date: Date | null | undefined): void => {
    setSelectedDate(date!);
    if (!includes(existingDates, getReminderDateFormat(date!.toString()!))) {
      onDatesUpdate?.([
        ...existingDates!,
        getReminderDateFormat(date!.toString()!),
      ]);
    } else {
      addToast(
        `${dateFormat(
          getReminderDateFormat(date!.toString()!)
        )} is already selected`,
        { appearance: 'error' }
      );
    }
  };

  const getReminderDateFormat = (date: string) => new Date(date).toISOString();

  return (
    <Stack
      className={styles.root}
      tokens={{ padding: '0px 25px', childrenGap: 20 }}
    >
      <TextField
        multiline
        rows={10}
        placeholder="Write your message..."
        resizable={false}
        onChange={(_, newValue) => onMessageUpdate?.(newValue!)}
      />

      <Checkbox
        label="Request response"
        onChange={(_, checked) => onRequestCheck?.(checked!)}
      />

      <Stack.Item tokens={{ padding: '20px 0px 0px 0px' }}>
        <DatePicker
          formatDate={onFormatDate}
          minDate={new Date()}
          firstDayOfWeek={DayOfWeek.Sunday}
          showWeekNumbers
          firstWeekOfYear={1}
          showMonthPickerAsOverlay
          placeholder="Select dates to remind recipients"
          value={selectedDate!}
          onSelectDate={onSelectDate}
          label="Reminder"
        />
      </Stack.Item>

      <Stack horizontal wrap>
        {existingDates?.map((item, index) => {
          return (
            <Stack className={styles.showDates} key={index}>
              <Chip
                onClick={() => {
                  let tempDates: string[] = [...existingDates!];
                  tempDates.splice(tempDates.indexOf(item), 1);
                  onDatesUpdate?.(tempDates);
                }}
                text={dateFormat(item!).toString()}
              />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
