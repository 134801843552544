import { DirectionalHint, FontIcon, Link, Stack, Text } from '@fluentui/react';
import React, { useState } from 'react';
import { SupplierLookUpCalloutProps } from '..';
import { AddressDetailsCallout } from './AddressDetailsCallout';
import { useStyles } from './index.styles';
import { LookupSupplierSearch_lookupSupplierSearch_nodes } from 'common/graphql/__generated__/LookupSupplierSearch';

export type DetailsCallout = Pick<
  SupplierLookUpCalloutProps,
  'onDismiss' | 'onSelect'
> & {
  data: LookupSupplierSearch_lookupSupplierSearch_nodes;
  detailsTargetId: string;
};

export const DetailsCallout: React.FC<DetailsCallout> = ({
  onSelect,
  onDismiss,
  data,
  detailsTargetId,
}) => {
  const styles = useStyles();
  const [calloutVisible, setCalloutVisible] = useState(false);

  const { _fullName, _isApproved } = data;

  return (
    <>
      <Link
        onClick={() => {
          onSelect(data);
          onDismiss?.();
        }}
        id={detailsTargetId}
        onMouseEnter={() => setCalloutVisible(true)}
        onMouseLeave={() => setCalloutVisible(false)}
        className={styles.resultItem}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Text>{_fullName} </Text>
          {_isApproved && <FontIcon iconName="accept" />}
        </Stack>
      </Link>
      {calloutVisible && (
        <AddressDetailsCallout
          // onDismiss={onDismiss}
          data={data}
          detailsTargetId={detailsTargetId}
          directionalHint={DirectionalHint.rightCenter}
        />
      )}
    </>
  );
};
