import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  infoViewWrapper: {
    minHeight: 80,
  },
  amountRow: {
    flex: 0.4,
  },
  instructionsRow: {
    flex: 0.6,
  },
}));
