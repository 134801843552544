import { Stack } from '@fluentui/react';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import React from 'react';
import { NotesForm, NotesFormProps } from './NotesForm';
import { NotesList } from './NotesList';

export interface NotesSectionProps extends NotesFormProps {
  data: NotesEntityFields[];
  dataLoading: boolean;
  listRefreshRequest?: () => void;
  isViewOnly?: boolean;
}

export const NotesSection: React.FC<NotesSectionProps> = ({
  data,
  dataLoading,
  isViewOnly = false,
  ...props
}) => {
  return (
    <Stack tokens={{ childrenGap: 10, padding: '20px 25px 0px' }}>
      {!isViewOnly && <NotesForm {...props} />}
      <NotesList dataLoading={dataLoading} data={data} isViewOnly={isViewOnly} {...props} />
    </Stack>
  );
};
