import { AccountingEntryInvoice } from './__generated__/AccountingEntryInvoice';
import { AccountingEntryValues } from './types';
import { INVOICE_INITIAL_VALUES } from './constants';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { getDefaultDistribution } from 'common/components/Modules/TransactionEdit/utils';

export const getDefaultValues = (
  invoiceDetailsData: AccountingEntryInvoice | undefined,
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const initialRow = getDefaultDistribution(userDefaults);

  let defaultValues: AccountingEntryValues = {
    ...INVOICE_INITIAL_VALUES,
    invoiceDistributions: [{ ...initialRow }],
  };
  if (invoiceDetailsData?.invoice) {
    const { invoiceDistributions } = { ...invoiceDetailsData.invoice };
    const invoiceDistributionsRows = invoiceDistributions.nodes.map((item) => {
      const {
        productionIncentive,
        businessUnit,
        department,
        statusType,
        tax1099t4Type,
        ...itemFields
      } = { ...item };
      return itemFields;
    });

    if (invoiceDetailsData.invoice._isUpdatable) {
      defaultValues = {
        ...invoiceDetailsData.invoice,
        invoiceDistributions: !!invoiceDetailsData.invoice.invoiceDistributions
          .nodes.length
          ? [...invoiceDistributionsRows, { ...initialRow }]
          : [{ ...initialRow }],
      };
    }
  }
  return defaultValues as AccountingEntryValues;
};
