import {
  CommandBarButton,
  DefaultButton,
  IconButton,
  Link,
  Stack,
  Text,
  TooltipHost,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CompanyValues } from '../types';
import { AddressFormFields } from './AddressFormFields';
import { useStyles } from './index.styles';
import { NoDataView } from 'common/components/DataPlaceholders';
import { ADDRESS_INITIAL_VALUES } from '../constants';
interface AddressFormProps {
  isUpdatable: boolean | null | undefined;
}
export const AddressForm: React.FC<AddressFormProps> = ({ isUpdatable }) => {
  const styles = useStyles();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CompanyValues>();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'companyAddresses', // unique name for your Field Array
  });
  const watchCompanyAddresses = useWatch({
    name: 'companyAddresses',
    control,
  });
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false);
  const [addressIndex, setAddressIndex] = useState<number>(0);
  const isDisabled = isUpdatable === false ? true : false;

  return (
    <Stack tokens={{ padding: '10px 0px' }}>
      {showAddressForm && addressIndex !== -1 ? (
        <AddressFormFields
          addressIndex={addressIndex}
          toList={() => {
            remove(fields.length);
            setShowAddressForm(false);
          }}
          isUpdatable={isUpdatable}
        />
      ) : (
        <>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={styles.defaultButtonContainer}
          >
            <Text variant="xLarge">Addresses</Text>
            <DefaultButton
              text="Add Address"
              iconProps={{ iconName: 'Add' }}
              onClick={() => {
                setAddressIndex(fields.length);
                setShowAddressForm(true);
                if (!fields.length)
                  append({
                    ...ADDRESS_INITIAL_VALUES,
                    isPrimaryAddress: true,
                  });
                else
                  append({
                    ...ADDRESS_INITIAL_VALUES,
                  });
              }}
              disabled={isDisabled}
            />
          </Stack>
          {/* ERROR MESSAGE */}
          {errors.companyAddresses && (
            <>
              <MessageBar messageBarType={MessageBarType.error}>
                At least one address is currently invalid
              </MessageBar>
            </>
          )}
          {/* ADDRESS LIST */}

          {fields?.map((ele, index) => {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                className={clsx(styles.root, styles.AddressListItem)}
                key={ele.id}
              >
                <Stack className={styles.rowCell}>
                  <Link
                    className={styles.addressTypeLabel}
                    onClick={(event) => {
                      event.preventDefault();
                      setAddressIndex(index);
                      setShowAddressForm(true);
                    }}
                  >
                    {/* To remove 'null' from address string. */}
                    {`${ele.addressLine1}, ${ele.addressLine2}`.replace(
                      ', null',
                      ''
                    )}
                  </Link>
                  <Text className={styles.addressSubtitle} variant="small">
                    {/* To remove 'null' from address string. */}
                    {ele.cityName ? `${ele.cityName}, ` : ''}
                    {ele.postalCode ? `${ele.postalCode}, ` : ''}
                    {ele.mainContact ? `${ele.mainContact}, ` : ''}
                    {ele.telephoneNumber ? `${ele.telephoneNumber}, ` : ''}
                    {ele.emailAddress ? `${ele.emailAddress} ` : ''}
                  </Text>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 50 }} key={ele.id}>
                  {watchCompanyAddresses?.[index]?.isPrimaryAddress ? (
                    <Text className={styles.primaryLabel}>Primary</Text>
                  ) : watchCompanyAddresses?.[index]?.isDeliveryAddress ? (
                    <Text className={styles.secondaryLabel}>
                      Delivery Address
                    </Text>
                  ) : null}

                  {!watchCompanyAddresses?.[index]?.isDeliveryAddress &&
                    !watchCompanyAddresses?.[index]?.isPrimaryAddress &&
                    !isDisabled && (
                      <CommandBarButton
                        iconProps={{ iconName: 'HomeVerify' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          fields?.forEach((_addr, i) => {
                            setValue(
                              `companyAddresses.${i}.isDeliveryAddress`,
                              i === index,
                              { shouldDirty: true }
                            );
                          });
                        }}
                        text="Set delivery address"
                        className={clsx(
                          styles.setPrimaryButton,
                          'setPrimaryButtonGlobal'
                        )}
                      />
                    )}
                  {!watchCompanyAddresses?.[index]?.isPrimaryAddress &&
                    !watchCompanyAddresses?.[index]?.isDeliveryAddress &&
                    !isDisabled && (
                      <CommandBarButton
                        iconProps={{ iconName: 'HomeVerify' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          fields?.forEach((_addr, i) => {
                            setValue(
                              `companyAddresses.${i}.isPrimaryAddress`,
                              i === index
                            );
                          });
                        }}
                        text="Set primary"
                        className={clsx(
                          styles.setPrimaryButton,
                          'setPrimaryButtonGlobal'
                        )}
                      />
                    )}

                  {!watchCompanyAddresses?.[index]?.isPrimaryAddress &&
                  !isDisabled ? (
                    <TooltipHost content="Delete">
                      <IconButton
                        iconProps={{ iconName: 'delete' }}
                        className={styles.deleteIcon}
                        ariaLabel="Delete"
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    </TooltipHost>
                  ) : (
                    <Stack style={{ width: 80 }}></Stack>
                  )}
                </Stack>
              </Stack>
            );
          })}
          <Stack tokens={{ padding: '20px 0px' }}>
            <NoDataView show={fields.length === 0} title="Address not found" />
          </Stack>
        </>
      )}
    </Stack>
  );
};
