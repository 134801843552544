import { useMutation } from '@apollo/client';
import {
  NotesSection,
  NotesSectionProps,
} from 'common/components/NotesSection';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import { NoteTravelAuthorizationCreate, NoteTravelAuthorizationCreateVariables } from 'common/graphql/__generated__/NoteTravelAuthorizationCreate';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import { loader } from 'graphql.macro';
import React from 'react';
const NOTE_TRAVEL_AUTHORIZATION_CREATE = loader(
  '../../../../../../graphql/NoteTravelAuthorizationCreate.graphql'
);

type NotesProps = Pick<
  NotesSectionProps,
  'listRefreshRequest'
> & {
  travelAuthorizationId: string;
  data: NotesEntityFields[];
  loading?: boolean;
};

export const Notes: React.FC<NotesProps> =
  ({ travelAuthorizationId, data, loading, listRefreshRequest }) => {
    const [createNote, { loading: createNoteInProgress, error }] = useMutation<
      NoteTravelAuthorizationCreate,
      NoteTravelAuthorizationCreateVariables
    >(NOTE_TRAVEL_AUTHORIZATION_CREATE);

    const noteAddMutation: NoteAddMutationProps = {
      loading: createNoteInProgress,
      createNote: async (formData) => {
        const { noteComment, isShared } = { ...formData };
        await createNote({
          variables: {
            input: {
              note: {
                entityId: travelAuthorizationId,
                noteComment: noteComment!,
                isShared: isShared!,
              },
            },
          },
        });
        if (!error) listRefreshRequest?.();
        return error;
      },
    };

    return (
      <NotesSection
        dataLoading={loading!}
        noteAddMutation={noteAddMutation}
        addingNoteInProgress={createNoteInProgress}
        listRefreshRequest={listRefreshRequest}
        data={data}
      />
    );
  };
