import { TravelerAvailableDocumentTypes } from 'common/graphql/__generated__/TravelerAvailableDocumentTypes';
import React, { useState } from 'react';
import { TravelerDetails_traveler } from '../../../__generated__/TravelerDetails';
import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import { useFormContext } from 'react-hook-form';
import { TravelerValues } from '../../../interfaces';
import { AttachFormModal } from './AttachFormModal';

export interface AttachFormProps {
  availableDocumentTypes: TravelerAvailableDocumentTypes | undefined;
  traveler: TravelerDetails_traveler;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  traveler,
  availableDocumentTypes,
}) => {

  const [dialogVisible, setDialogVisible] = useState(false);
  const { formState: { isDirty } } = useFormContext<TravelerValues>();


  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={isDirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          traveler={traveler}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  )
}
