import {
  AnimationClassNames,
  DefaultButton,
  Stack,
} from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './index.styles';
interface NewMessageAlertProps {
  closeAlert: () => void;
  isNewMessage?: boolean;
}

export const NewMessageAlert: React.FC<NewMessageAlertProps> = ({
  closeAlert,
  isNewMessage = false,
}) => {
  const styles = useStyles();
  const SlideLeftIn = AnimationClassNames.slideLeftIn20;

  return (
    <Stack>
      <Stack grow horizontalAlign="center">
        <DefaultButton
          onClick={closeAlert}
          text={isNewMessage ? 'New Messages' : 'New Notifications'}
          className={clsx(
            styles.containerStyle,
            styles.closeButtonHoverStyle,
            SlideLeftIn
          )}
          style={{ top: isNewMessage ? 90 : 55 }}
        />
      </Stack>
    </Stack>
  );
};
