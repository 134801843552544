import {
  Callout,
  CommandBarButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IContextualMenuProps,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Stack,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import {
  getConfirmButton,
  invalidActionMessage,
} from 'common/components/ActionMessageModal/utils';
import { FilterArrayType } from 'common/components/Filters';
import { ColumnData } from 'common/components/SearchBar';
import { CalloutContainer } from 'common/components/SearchBar/ShowColumns';
import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import { EntityAction, EntityType } from 'common/types/utility';
import { Badge } from 'documents/Filters/Badge';
import { ListFilterCallout } from 'documents/Filters/ListFilterCallout';
import { MoveToFolderModal } from 'documents/MoveToFolder';
import { GetDocumentPoolCommonData_companyDepartments } from 'documents/__generated__/GetDocumentPoolCommonData';
import { CommonData_companyBusinessUnits } from 'documents/documentAssignment/Documents/__generated__/CommonData';
import { EntityDocumentSearch_entityDocumentSearch_nodes } from 'documents/documentAssignment/Documents/__generated__/EntityDocumentSearch';
import React, { useState } from 'react';
import { FolderViewHeaderBuildTransaction } from './FolderViewHeaderBuildTransactions';
import { useStyles } from './index.styles';
import { useReactiveVar } from '@apollo/client';
import { setActivePoolDocument } from 'utility/cache/ui';

export type filterOptionProps = {
  filterTypes: FilterArrayType[];
  startsWith: boolean;
};
interface FolderViewHeaderProps {
  onToggleVisibility?: (columns: ColumnData[]) => void;
  columns: ColumnData[];
  filterOptions: filterOptionProps;
  onUploadClick: () => void;
  onFilterChange: (filterOptions: filterOptionProps) => void;
  documentTypeOptions: IDropdownOption[];
  entityType: EntityType;
  action?: EntityAction;
  onConfirm: () => void;
  iconName?: string;
  disabled?: boolean;
  multiple?: {
    validCount: number;
    invalidNames?: string[];
  };
  poolSelectedID?: string | undefined;
  documentsSelected: Partial<EntityDocumentsFields>[];
  onSuccess: (poolMovedToId: string, filesMovedCount: number) => void;
  allowMultiFileBundleToggle: boolean;
  companyBusinessUnits: CommonData_companyBusinessUnits | null | undefined;
  companyDepartments:
    | GetDocumentPoolCommonData_companyDepartments
    | null
    | undefined;
  onBuildComplete?: () => void;
  onCloseDocumentView?: () => void;
}
type SelectedListProps = Pick<
  EntityDocumentSearch_entityDocumentSearch_nodes,
  '_isSelected' | 'id' | 'documentTypes'
>;

export const FolderViewHeader: React.FC<FolderViewHeaderProps> = ({
  onToggleVisibility,
  onUploadClick,
  documentTypeOptions,
  filterOptions,
  onFilterChange,
  columns,
  entityType,
  action = EntityAction.Delete,
  onConfirm,
  disabled = false,
  multiple,
  poolSelectedID,
  documentsSelected,
  onSuccess,
  companyBusinessUnits,
  companyDepartments,
  onBuildComplete,
  onCloseDocumentView,
  allowMultiFileBundleToggle,
}) => {
  const styles = useStyles();
  const calloutId = useId(`filter-callOutId${poolSelectedID}`);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [buildTransactionVisible, setBuildTransactionVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const selectedList = documentsSelected as SelectedListProps[];

  const filterToggle = () => {
    setFiltersApplied((prevState) => !prevState);
  };

  const filtersCount = filterOptions?.filterTypes?.length;

  const historyPos = filterOptions.filterTypes.findIndex(
    (item) => item.filterKey === '_isSelected'
  );

  const filtersNumber =
    historyPos === -1
      ? 0
      : filterOptions.filterTypes[historyPos].value === false
      ? filtersCount - 1
      : filtersCount;
  const showColumnsButtonId = useId('showColumns-callout-label');
  const [calloutVisibility, setCalloutVisibility] = useState<boolean>(false);
  const toggleVisibility = () => {
    setCalloutVisibility((prevState) => !prevState);
  };
  const openModal = () => {
    setBuildTransactionVisible(true);
    onCloseDocumentView?.();
  };
  const closeModal = () => {
    setBuildTransactionVisible(false);
    setEdit(false);
  };
  const selectedListNonHistory = selectedList?.[0]
    ? selectedList.filter(
        (item) =>
          !item._isSelected &&
          !!item.documentTypes?.transactionTypeId &&
          !item.documentTypes._isAppliableDocumentType
      )
    : [];

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'buildTransactionsView',
        text: 'Build Transactions',
        preferMenuTargetAsEventTarget: true,
        onClick: () => {
          openModal();
        },
      },
      {
        key: 'buildTransactionsEdit',
        text: 'Build Transactions + Edit',
        preferMenuTargetAsEventTarget: true,
        onClick: () => {
          setEdit(true);
          openModal();
        },
      },
    ],
  };
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(true);
  const [moveToFolderVisibility, moveToFolderSetVisibility] = useState(false);

  const pastTenseAction = `${action.toLowerCase()}d`;
  const subText = multiple
    ? `The selected ${entityType.toLowerCase()}${
        multiple.validCount > 1 ? `s (${multiple.validCount})` : ''
      } will be ${pastTenseAction}.`
    : `The current ${entityType.toLowerCase()} will be ${pastTenseAction}.`;
  const ConfirmButton = getConfirmButton(action);
  const activeDocument = useReactiveVar(setActivePoolDocument);

  return (
    <Stack>
      <Stack horizontal styles={{ root: { height: 50 } }}>
        {!disabled && (
          <CommandBarButton
            key="delete"
            text="Delete"
            iconProps={{ iconName: 'Delete' }}
            onClick={() => setDialogVisibility(!dialogVisibility)}
          />
        )}
        {!!documentsSelected.length && (
          <CommandBarButton
            key="move"
            text="Move to Folder"
            iconProps={{ iconName: 'OpenInNewWindow' }}
            onClick={() => moveToFolderSetVisibility(true)}
          />
        )}
        {!!selectedListNonHistory.length && (
          <CommandBarButton
            key="buildTransactionsView"
            text="Build Transactions"
            iconProps={{ iconName: 'AddToShoppingList' }}
            menuProps={menuProps}
            onClick={() => openModal()}
          />
        )}
        <CommandBarButton
          key="upload"
          text="Upload"
          iconProps={{ iconName: 'Upload' }}
          onClick={() => onUploadClick()}
        />
        <Badge visible={filtersNumber > 0} filtersCount={filtersNumber}>
          <CommandBarButton
            key="filter"
            text="Filter"
            iconProps={{ iconName: 'Filter' }}
            onClick={() => filterToggle()}
            id={calloutId}
          />
        </Badge>
        <CommandBarButton
          key="columns"
          text="Columns"
          iconProps={{ iconName: 'TripleColumn' }}
          onClick={() => toggleVisibility()}
          id={showColumnsButtonId}
        />
      </Stack>
      <Stack
        className={styles.optionsSection}
        horizontal
        tokens={{ childrenGap: 20 }}
        verticalAlign="center"
      >
        {calloutVisibility && (
          <Callout
            calloutMaxWidth={400}
            role="alertdialog"
            gapSpace={0}
            target={`#${showColumnsButtonId}`}
            onDismiss={toggleVisibility}
            setInitialFocus
          >
            <CalloutContainer
              columns={columns}
              onToggleVisibility={(columnsArray) => {
                onToggleVisibility?.(columnsArray);
              }}
            />
          </Callout>
        )}
        {filtersApplied && (
          <ListFilterCallout
            onDismiss={filterToggle}
            calloutId={calloutId}
            filterOptions={filterOptions}
            documentTypeOptions={documentTypeOptions}
            onFilterChange={onFilterChange}
          />
        )}

        {!dialogVisibility && (
          <Dialog
            hidden={false}
            onDismiss={() => setDialogVisibility(!dialogVisibility)}
            dialogContentProps={{
              title: `Are you sure?`,
              type: DialogType.largeHeader,
              subText: subText,
            }}
          >
            {multiple &&
              multiple.invalidNames &&
              multiple.invalidNames.length > 0 && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                  {invalidActionMessage(multiple.invalidNames, entityType)}
                </MessageBar>
              )}
            <DialogFooter>
              <ConfirmButton
                onClick={() => {
                  onConfirm();
                  setDialogVisibility(!dialogVisibility);
                }}
                text={action}
              />
              <DefaultButton
                onClick={() => setDialogVisibility(!dialogVisibility)}
                text="Cancel"
              />
            </DialogFooter>
          </Dialog>
        )}
        {moveToFolderVisibility && (
          <MoveToFolderModal
            poolSelectedID={poolSelectedID}
            documentsSelected={documentsSelected}
            closeModal={() => {
              moveToFolderSetVisibility(false);
            }}
            onSuccess={(poolMovedToId, filesMovedCount) => {
              moveToFolderSetVisibility(false);
              onSuccess(poolMovedToId, filesMovedCount);
              const activeDocCount =
                activeDocument?._documentFileCount?.availableDocuments ?? 0;

              setActivePoolDocument(
                activeDocument
                  ? {
                      ...activeDocument,
                      _documentFileCount: {
                        availableDocuments: activeDocCount - filesMovedCount,
                        selectedDocuments:
                          activeDocument?._documentFileCount
                            ?.selectedDocuments ?? 0,
                      },
                    }
                  : undefined
              );
            }}
          />
        )}
        {buildTransactionVisible && !!selectedListNonHistory.length && (
          <FolderViewHeaderBuildTransaction
            allowMultiFileBundleToggle={allowMultiFileBundleToggle}
            companyBusinessUnits={companyBusinessUnits}
            companyDepartments={companyDepartments}
            selectedList={selectedList}
            onBuildComplete={onBuildComplete}
            closeModal={closeModal}
            edit={edit}
            selectedListNonHistory={selectedListNonHistory}
          />
        )}
      </Stack>
    </Stack>
  );
};
