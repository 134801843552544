import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

export const columns: ColumnData[] = [
  {
    key: 'itemNumber',
    name: 'Item#',
    fieldName: 'itemNumber',
    minWidth: 50,
    maxWidth: 50,
    ...commonColumnProps,
  },
  {
    key: 'displayPoItemType',
    name: 'Type',
    fieldName: 'displayPoItemType',
    minWidth: 120,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'displayPoItemDescription',
    name: 'Description',
    fieldName: 'displayPoItemDescription',
    minWidth: 220,
    maxWidth: 300,
    ...commonColumnProps,
  },
  {
    key: 'displayPoItemAccount',
    name: 'Account#',
    fieldName: 'displayPoItemAccount',
    minWidth: 120,
    maxWidth: 160,
    ...commonColumnProps,
  },
  {
    key: 'displayPoItemQuantity',
    name: 'Qty',
    fieldName: 'displayPoItemQuantity',
    minWidth: 100,
    maxWidth: 120,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'displayPoItemRentalTerm',
    name: 'Term',
    fieldName: 'displayPoItemRentalTerm',
    minWidth: 120,
    maxWidth: 160,
    ...commonColumnProps,
  },
  {
    key: 'displayPoItemUnitPrice',
    name: 'Unit Price',
    fieldName: 'displayPoItemUnitPrice',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: '_extendedItemTotal',
    name: 'Total',
    fieldName: '_extendedItemTotal',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
];
