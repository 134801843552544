import { useMutation, useQuery } from '@apollo/client';
import { Separator, Stack, Text } from '@fluentui/react';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import { PanelCommonProps } from 'common/types/utility';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { setCompanyAccountId } from '../../list/CardHolders/utils';
import { PurchaseCardCommonData } from '../../__generated__/PurchaseCardCommonData';
import { CARD_ACCOUNT_INITIAL_VALUES } from '../constants';
import { ShimmerView } from '../ShimmerView/ShimmerViews';
import { TransactionHistory } from '../TransactionHistory';
import { CardAccountValues } from '../types';
import {
  CardAccount,
  CardAccountVariables
} from '../__generated__/CardAccount';
import {
  CardAccountUpdate,
  CardAccountUpdateVariables
} from '../__generated__/CardAccountUpdate';
import { ActionsMenu } from './ActionMenu';
import { BasicForm } from './BasicForm';
import { useStyles } from './index.styles';
import { validationSchema } from './validations';
const GET_CARD_ACCOUNT_DETAILS = loader('../CardAccount.graphql');
const CARD_COMMON_DATA = loader('../../PurchaseCardCommonData.graphql');
const UPDATE_CARD_ACCOUNT_SETUP = loader('../CardAccountUpdate.graphql');
export const CardAccountView = () => {
  const history = useHistory();
  const styles = useStyles();
  const { addToast } = useToasts();
  const { cardAccountId } = useParams<{ cardAccountId: string | undefined }>();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const commonStyles = useCommonStyles();

  const { data: cardAccountDetailsData, loading: cardAccountDetailsLoading } =
    useQuery<CardAccount, CardAccountVariables>(GET_CARD_ACCOUNT_DETAILS, {
      fetchPolicy: 'network-only',
      variables: { id: cardAccountId! },
      skip: !cardAccountId,
    });

  const { data: commonData } = useQuery<PurchaseCardCommonData>(
    CARD_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [updateCardAccount, { loading: updateCardAccountLoading }] =
    useMutation<CardAccountUpdate, CardAccountUpdateVariables>(
      UPDATE_CARD_ACCOUNT_SETUP,
      { errorPolicy: 'all' }
    );

  const handleSubmit = async (values: CardAccountValues) => {
    const { errors } = await updateCardAccount({
      variables: {
        input: {
          id: values.id!,
          rowTimestamp: values._rowTimestamp!,
          cardAccountPatch: {
            description: values.description,
            businessUnitId: values.businessUnitId,
            documentPoolId: values.documentPoolId,
          },
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_CARD_ACCOUNT_DETAILS,
          variables: {
            id: cardAccountId!,
          },
        },
      ],
    });
    if (!errors) {
      addToast('Card Account updated successfully.', {
        appearance: 'success',
      });
    } else {
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    }
  };

  let initialValues: CardAccountValues = CARD_ACCOUNT_INITIAL_VALUES;
  if (cardAccountDetailsData) {
    if (cardAccountDetailsData.cardAccount?._isUpdatable) {
      initialValues = {
        ...cardAccountDetailsData.cardAccount!,
      };
    } else {
      initialValues = {
        ...cardAccountDetailsData.cardAccount!,
      };
    }
  }

  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/project-settings/purchase-cards');
          setIsOpen(true);
        }}
      >
        <Stack grow horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">Edit Account</Text>
            <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
              {cardAccountDetailsData?.cardAccount?.name}
            </Text>
            <UnsavedIndicator
              visible={!cardAccountDetailsLoading && dirty && !isSubmitting}
            />
          </Stack>
          {cardAccountDetailsData?.cardAccount?.statusType?.statusType && (
            <Stack>
              {
                <Text
                  className={styles.status}
                >{`(${cardAccountDetailsData?.cardAccount?.statusType?.statusType})`}</Text>
              }
            </Stack>
          )}
        </Stack>
      </PanelHeader>
    );
  };

  useEffect(() => {
    if (cardAccountId) {
      setCompanyAccountId(cardAccountId)
    } else {
      setCompanyAccountId(null)
    }
  }, [cardAccountId])

  return (
    <Formik<CardAccountValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ submitForm, isSubmitting, dirty, errors }) => {
        return (
          <Form>
            <DraggablePanel
              {...PanelCommonProps}
              isOpen={isOpen}
              initialWidth={900}
              minWidth={900}
              isBlocking={false}
              onRenderHeader={() => _onRenderHeader(dirty, isSubmitting)}
              onRenderFooter={() => (
                <FooterActionBar
                  isLoading={updateCardAccountLoading}
                  createNewText="New Card Holder"
                  addNewForm={() => {
                    history.replace(
                      `/project-settings/purchase-cards/card-holder`
                    );
                  }}
                  disabled={{
                    save: !dirty || Object.keys(errors).length > 0,
                    cancel: !dirty,
                  }}
                  isSubmitting={isSubmitting}
                  onCancel={() =>
                    history.replace('/project-settings/purchase-cards')
                  }
                  onSave={async () => await submitForm()}
                />
              )}
              isLightDismiss
            >
              {cardAccountDetailsLoading ? (
                <ShimmerView />
              ) : (
                <Stack>
                  <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 10, padding: '0px 25px' }}
                      horizontalAlign="space-between"
                    >
                      <Stack
                        className={styles.actionsMenuContainer}
                        horizontalAlign="space-between"
                      >
                        <ActionsMenu
                          cardAccountDetails={cardAccountDetailsData!}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                  <Separator />
                  <BasicForm
                    cardAccount={cardAccountDetailsData}
                    data={commonData!}
                    isUpdatable={
                      cardAccountDetailsData?.cardAccount?._isUpdatable
                    }
                  />
                  {cardAccountDetailsData?.cardAccount
                    ?.cardAccountHistoriesByEntityId &&
                    cardAccountDetailsData?.cardAccount
                      ?.cardAccountHistoriesByEntityId.nodes.length! > 0 && (
                      <Stack>
                        <Separator />
                        <Stack
                          className={styles.approvalHistoryContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text
                            variant="xLarge"
                            className={styles.transactionHistoryHeading}
                          >
                            Transaction History
                          </Text>
                          <TransactionHistory
                            data={
                              cardAccountDetailsData.cardAccount
                                .cardAccountTransactions.nodes!
                            }
                          />
                        </Stack>
                      </Stack>
                    )}
                </Stack>
              )}
            </DraggablePanel>
          </Form>
        );
      }}
    </Formik>
  );
};
