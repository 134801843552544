import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  projectPersona: {
    '& .ms-Persona-initials': {
      borderRadius: 4,
    },
  },
  personaExpanded: {
    margin: '14px 8px',
  },
  personaCollapsed: {
    margin: '14px 8px 4px',
  },
  roleText: {
    color: theme.palette.neutralSecondary,
  },
  editButton: {
    width: 42,
    height: 40,
    color: theme.palette.neutralDark,
    ':hover': {
      color: theme.palette.accent,
    },
  },
}));
