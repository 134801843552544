import { Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useStyles } from './index.styles';

interface HeaderProps {
  title: string;
}

export const Header: React.FC<HeaderProps> = ({ title }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20,
      }}
    >
      <Stack tokens={{ childrenGap: 10 }}>
        <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
          {title}
        </Text>
      </Stack>
    </Stack>
  );
};
