import { useReactiveVar } from '@apollo/client';
import { Stack } from '@fluentui/react';
import { HighLightActiveLink } from 'common/components/HighLight';
import React from 'react';
import { setTripsModalState } from 'travelAuthorization/TravelPlan/view/FormView/Trips';
import { TripsModal } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal';
import { TripUpdate } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/__generated__/TripUpdate';
import { TripBreakdownSearch_tripBreakdownSearch_nodes } from '../list/__generated__/TripBreakdownSearch';

interface TripBreakdownViewProps {
  fieldContent: string;
  globalSearchText: string;
  item: TripBreakdownSearch_tripBreakdownSearch_nodes;
  onTripUpdate?: (
    item: TripBreakdownSearch_tripBreakdownSearch_nodes,
    data: TripUpdate | null | undefined
  ) => void;
}
export const TripBreakdownView: React.FC<TripBreakdownViewProps> = ({
  globalSearchText,
  fieldContent,
  item,
  onTripUpdate,
}) => {
  const tripsModalState = useReactiveVar(setTripsModalState);
  const inputDisabled = !item.travelAuthorization?._isUpdatable;

  return (
    <Stack>
      <HighLightActiveLink
        highlightText={globalSearchText}
        text={fieldContent}
        preventDefault={true}
        onLinkClick={() => {
          setTripsModalState({
            trip: item,
            isModalOpen: true,
            isNew: false,
          });
        }}
      />

      {tripsModalState.isModalOpen && item.id === tripsModalState.trip?.id && (
        <TripsModal
          isOpen={
            tripsModalState.isModalOpen && item.id === tripsModalState.trip?.id
          }
          businessUnitId={item.businessUnitId}
          inputsDisabled={inputDisabled}
          travelAuthorizationData={item.travelAuthorization}
          onTripUpdate={(data) => onTripUpdate?.(item, data)}
        />
      )}
    </Stack>
  );
};
