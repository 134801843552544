import { IColumn, IDetailsColumnStyles } from "@fluentui/react";
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
export const columns: IColumn[] = [
  {
    key: 'fileReference',
    name: 'File',
    fieldName: 'fileReference',
    minWidth: 150,
    maxWidth: 210,
    isResizable: true
  },
  {
    key: 'indexName',
    name: 'Name',
    fieldName: 'indexName',
    minWidth: 150,
    maxWidth: 210,
    isResizable: true
  },
  {
    key: '_documentContents',
    name: 'Description',
    fieldName: '_documentContents',
    minWidth: 170,
    maxWidth: 230,
    isResizable: true
  },
  {
    key: 'indexReferenceNumber',
    name: 'Number',
    fieldName: 'indexReferenceNumber',
    minWidth: 200,
    maxWidth: 280,
    isResizable: true
  },
  {
    key: 'amount',
    name: 'Amount',
    fieldName: 'amount',
    minWidth: 130,
    maxWidth: 180,
    isResizable: true,
    styles: rightAlignHeaderStyle,
  },
];