import { AccountingDistributionRows, AccountingEntryValues } from './types';

export const ACCOUNTING_INITIAL_VALUES: AccountingDistributionRows = {
  id: null,
  _createdDate: null,
  _isDeletable: null,
  _isUpdatable: null,
  _rowTimestamp: null,
  accountReference: null,
  additionalDescription: null,
  businessUnitId: null,
  departmentId: null,
  distributionAmount: null,
  productionIncentiveId: null,
  projectReference: null,
  purchaseOrderReference: null,
  statusTypeId: null,
  setReference: null,
  tax1099T4TypeId: null,
  referenceCode1: null,
  referenceCode2: null,
  referenceCode3: null,
  referenceCode4: null,
  referenceCode5: null,
  _ff1Name: null,
  _ff2Name: null,
  _ff3Name: null,
  _ff4Name: null,
  statusExplanation: null,
  poEntityDocumentId: null,
  isPoCharge: null,
  referenceCode6: null,
  referenceCode7: null,
  _accountName: null,
};

export const INVOICE_INITIAL_VALUES: AccountingEntryValues = {
  id: null,
  _rowTimestamp: null,
  invoiceDistributions: [{ ...ACCOUNTING_INITIAL_VALUES }],
};
