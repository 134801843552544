import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    overflowX: 'clip',
  },
  renderAccountingOptionsContainer: {
    marginLeft: 25,
    marginTop: 10,
    alignItems: 'center',
  },
  amountText: {
    color: theme.palette.themePrimary,
    fontWeight: 500,
    marginTop: 20,
    marginRight: 10,
  },
}));
