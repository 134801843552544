import { useMutation, useQuery } from '@apollo/client';
import {
  ContextualMenu,
  IconButton,
  ProgressIndicator,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { ModalWrapper } from 'common/components/ModalWrapper';
import { SupplierCreate_supplierCreate_supplier } from '../../../__generated__/SupplierCreate';
import {
  SupplierAlias,
  SupplierAliasVariables,
} from './__generated__/SupplierAlias';
import {
  SupplierAliasNameUpdate,
  SupplierAliasNameUpdateVariables,
} from './__generated__/SupplierAliasNameUpdate';
import { Footer } from './Footer';
import { Header } from './Header';
import { useStyles } from './index.styles';
const SUPPLIER_ALIAS = loader('./SupplierAliasModal.graphql');
const SUPPLIER_ALIAS_NAME_UPDATE = loader('./SupplierAliasNameUpdate.graphql');

interface AliasModalProps {
  id: string | undefined;
  onClose: () => void;
  isPurchaseOrderMutation?: boolean | null;
  isProtected?: boolean;
  onAliasUpdate?: (param: SupplierCreate_supplierCreate_supplier) => void;
}
const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
});
export const AliasModal: React.FC<AliasModalProps> = ({
  onClose,
  id,
  isPurchaseOrderMutation,
  isProtected,
  onAliasUpdate,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [aliasValue, setAliasValue] = useState<string>('');
  const [nameValue, setNameValue] = useState<string>('');
  const [familyNameValue, setFamilyNameValue] = useState<string>('');
  const [isEdited, setisEdited] = useState<boolean>(false);
  const [toggleEdit, setToggleEdit] = useState<boolean>(false);
  const { loading, data: supplierAliasData } = useQuery<
    SupplierAlias,
    SupplierAliasVariables
  >(SUPPLIER_ALIAS, {
    variables: { id: id! },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [updateAlias, { loading: updateLoading }] = useMutation<
    SupplierAliasNameUpdate,
    SupplierAliasNameUpdateVariables
  >(SUPPLIER_ALIAS_NAME_UPDATE, { errorPolicy: 'all' });

  const onSubmit = async () => {
    const SupplierAliasNameUpdateInput = {
      id: id!,
      nameAlias: aliasValue,
      firstName: nameValue,
      rowTimestamp: supplierAliasData?.supplierAlias?._rowTimestamp!,
      companyOrLastName: familyNameValue,
      isPurchaseOrderMutation: !!isPurchaseOrderMutation,
    };
    const { errors, data } = await updateAlias({
      variables: {
        input: SupplierAliasNameUpdateInput,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: SUPPLIER_ALIAS,
          variables: {
            id: id,
          },
        },
      ],
    });
    if (!!errors?.length) {
      addToast('invalid data !', {
        appearance: 'error',
      });
    } else {
      onAliasUpdate?.(data?.supplierAliasNameUpdate?.supplier!);
      onClose();
    }
  };
  useEffect(() => {
    setAliasValue(supplierAliasData?.supplierAlias?.nameAlias!);
    setFamilyNameValue(supplierAliasData?.supplierAlias?.companyOrLastName!);
    setNameValue(supplierAliasData?.supplierAlias?.firstName!);
  }, [supplierAliasData]);
  const isDisabled =
    !supplierAliasData?.supplierAlias?._isUpdatable || !!isProtected;

  return (
    <ModalWrapper
      isOpen
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
    >
      {loading || updateLoading ? (
        <ProgressIndicator label="Loading" className={styles.loadingScreen} />
      ) : (
        <Stack className={styles.modelContainer}>
          (
          <>
            <Header />
            <Stack
              tokens={{ childrenGap: 10 }}
              className={styles.formContainer}
            >
              <Stack horizontal style={{ width: '100%' }}>
                <Stack.Item className={styles.flexContainer}>
                  <TextField
                    label="Alias"
                    name="alias"
                    value={aliasValue}
                    onChange={(e) => {
                      e.currentTarget.value || nameValue || familyNameValue
                        ? setisEdited(true)
                        : setisEdited(false);
                      setAliasValue(e.currentTarget.value);
                    }}
                    disabled={isDisabled}
                    styles={{
                      root: {
                        flex: '1',
                      },
                    }}
                  />
                </Stack.Item>
                <Stack.Item className={styles.cancelIconContainer}>
                  {supplierAliasData?.supplierAlias?._isUpdatable &&
                    aliasValue?.length > 0 &&
                    !isProtected && (
                      <IconButton
                        aria-label="Cancel"
                        iconProps={{ iconName: 'Cancel' }}
                        className={iconClass}
                        onClick={() => {
                          setAliasValue('');
                          setisEdited(true);
                        }}
                      />
                    )}
                </Stack.Item>
              </Stack>
              <Stack className={styles.inlineLabelStyle}>
                <Toggle
                  inlineLabel
                  label="View Name"
                  onChange={() => setToggleEdit(!toggleEdit)}
                />
              </Stack>

              {toggleEdit && (
                <TextField
                  label="Given Name"
                  placeholder="Please enter name"
                  value={nameValue}
                  onChange={(e) => {
                    e.currentTarget.value || aliasValue || familyNameValue
                      ? setisEdited(true)
                      : setisEdited(false);
                    setNameValue(e.currentTarget.value);
                  }}
                  disabled={isDisabled}
                />
              )}
              {toggleEdit && (
                <TextField
                  label="Family Name"
                  placeholder="Please enter family name"
                  value={familyNameValue}
                  onChange={(e) => {
                    e.currentTarget.value || aliasValue || nameValue
                      ? setisEdited(true)
                      : setisEdited(false);
                    setFamilyNameValue(e.currentTarget.value);
                  }}
                  disabled={isDisabled}
                />
              )}
            </Stack>
            <Footer
              DisplaySaveButton={!isDisabled && isEdited}
              onClose={onClose}
              onSave={onSubmit}
            />
          </>
        </Stack>
      )}
    </ModalWrapper>
  );
};
