import {
  Callout,
  DirectionalHint,
  Icon,
  IDropdownOption,
  IIconStyles,
  IRenderFunction,
  ITextFieldProps,
  Label,
  Stack,
  Text,
  TextField,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import { useId } from '@fluentui/react-hooks';
import { RequiredNameProps } from 'common/types/utility';
import { useController } from 'react-hook-form';
import { useCommonStyles } from 'common/styles';
interface SelectFieldProps
  extends Pick<ITextFieldProps, 'disabled' | 'placeholder'> {
  label?: string;
  info?: string | null;
  options?: IDropdownOption[];
  onSelect?: (item: IDropdownOption) => void;
  isCheck?: boolean;
  onRemoveSelection?: () => void;
}
export const SelectField: React.FC<RequiredNameProps<SelectFieldProps>> = ({
  label,
  disabled = false,
  placeholder,
  options,
  name,
  onSelect,
  isCheck = false,
  onRemoveSelection,
  info,
}) => {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const [visible, setVisible] = useState(false);
  const styles = useStyles();
  const id = useId(name);
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
  });

  const renderSpinner: IRenderFunction<ITextFieldProps> = () => {
    return (
      <Stack
        onClick={() => {
          if (isCheck) {
            onChange(null);
            onRemoveSelection?.();
          } else setVisible(true);
        }}
        className={styles.suffixIconStyles}
      >
        <Icon iconName={value !== null && isCheck ? 'cancel' : 'ChevronDown'} />
      </Stack>
    );
  };

  const checkIconStyles: Partial<IIconStyles> = {
    root: {
      color: theme.palette.themePrimary,
      margin: '6px 0px 0px 6px',
    },
  };
  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Label disabled={disabled}>{label}</Label>
        <TooltipHost content={'Verified Card Holder'}>
          {isCheck && <Icon iconName="CheckMark" styles={checkIconStyles} />}
          {isCheck && (
            <Text className={commonStyles.colorThemePrimary}>{info}</Text>
          )}
        </TooltipHost>
      </Stack>

      <TextField
        id={id}
        autoComplete={'off'}
        errorMessage={error?.message}
        value={value || ''}
        placeholder={placeholder}
        onChange={(_event, value) => {
          onChange(value ? value : null);
        }}
        styles={{
          suffix: {
            backgroundColor: theme.palette.white,
          },
        }}
        onRenderSuffix={
          options?.length! > 0 && !disabled ? renderSpinner : undefined
        }
        disabled={disabled ? true : isCheck}
      />

      {visible && (
        <Callout
          className={styles.container}
          gapSpace={10}
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          target={`#${id}`}
          onDismiss={() => setVisible(false)}
        >
          <Stack tokens={{ padding: '0px 0px' }}>
            {options?.map((item, index) => {
              return (
                <Stack
                  onClick={() => {
                    onSelect?.(item);
                    setVisible(false);
                  }}
                  className={styles.item}
                  key={index}
                >
                  <Text>{item.text}</Text>
                </Stack>
              );
            })}
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};
