import { makeVar } from "@apollo/client";
import { CardHoldersOrderBy } from "common/types/globalTypes";
import { OrderDirection, SortOrder } from "common/utils/commonTypes";
export const toOrderByVariable = (sort?: SortOrder) => {
  type OrderLookup = {
    [direction in OrderDirection]: CardHoldersOrderBy[];
  };
  const orderByMap = new Map<string, OrderLookup>([
    [
      'cardHolder',
      {
        [OrderDirection.ASC]: [
          CardHoldersOrderBy.CARD_FIRST_NAME_ASC,
        ],
        [OrderDirection.DESC]: [
          CardHoldersOrderBy.CARD_FIRST_NAME_DESC,
        ],
      },
    ],
    [
      'cardNumber',
      {
        [OrderDirection.ASC]: [CardHoldersOrderBy.CARD_NUMBER_ASC],
        [OrderDirection.DESC]: [CardHoldersOrderBy.CARD_NUMBER_DESC],
      },
    ],
    [
      'cardExpiry',
      {
        [OrderDirection.ASC]: [CardHoldersOrderBy.CARD_EXPIRY_ASC],
        [OrderDirection.DESC]: [
          CardHoldersOrderBy.CARD_EXPIRY_ASC,
        ],
      },
    ],
    [
      'department',
      {
        [OrderDirection.ASC]: [CardHoldersOrderBy.DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC],
        [OrderDirection.DESC]: [CardHoldersOrderBy.DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC],
      },
    ],
    [
      'documentFolder',
      {
        [OrderDirection.ASC]: [CardHoldersOrderBy.DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_ASC],
        [OrderDirection.DESC]: [CardHoldersOrderBy.DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_DESC],
      },
    ],
    [
      'cardStatus',
      {
        [OrderDirection.ASC]: [CardHoldersOrderBy.CARD_STATUS_ASC],
        [OrderDirection.DESC]: [CardHoldersOrderBy.CARD_STATUS_DESC],
      },
    ]
  ]);
  const orderByArray = [
    CardHoldersOrderBy.CARD_NUMBER_ASC
  ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), ...orderByArray];
};

export const setCompanyAccountId = makeVar<string | null>(null);