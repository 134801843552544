import { FontWeights, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  cellItemInitial: {
    border: `1px solid ${theme.palette.neutralQuaternary}`,
    borderRight: 'none',
    borderBottom: 'none',
    alignItems: 'start',
    justifyContent: 'start',
    wordBreak: 'break-word',
  },
  cellEnd: {
    borderRight: `1px solid ${theme.palette.neutralQuaternary}`,
  },
  cellBottom: {
    borderBottom: `1px solid ${theme.palette.neutralQuaternary}`,
  },
  totalAmount: {
    fontWeight: FontWeights.bold,
  },
  overageAmount: {
    color: 'red',
  },
  flex3: {
    flex: 3,
  },
  flex8: {
    flex: 8,
  },
}));
