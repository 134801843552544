import * as React from 'react';
import { Shimmer, ShimmerElementType } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

const useStyles = mergeStyleSets({
  shimmerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 180px)', 
    gridGap: '10px',
    marginBottom: '20px',
  },
  shimmerRow: {
    height: '30px', 
  },
});

export const ShimmerView = () => {
  const styles = useStyles;

  
  return (
    <div>
      {Array.from({ length: 10 }).map((_, rowIndex) => (
        <div key={rowIndex} className={styles.shimmerGrid}>
          {Array.from({ length: 6 }).map((_, colIndex) => (
            <Shimmer
              key={colIndex}
              shimmerElements={[{ type: ShimmerElementType.line, height: 20 }]} // Adjust line height if needed
              className={styles.shimmerRow}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
