import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  stickyInsideStack: {
    alignItems: 'self-start',
    // marginTop: 25,
    padding: '25px 20px 0px',
  },
  container: {
    marginBottom: 30,
  },
  footer: {
    height: 80,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 1300,
    backgroundColor: theme.palette.neutralLighterAlt,
    padding: '10px 20px 10px',
  },
  greyContainer: {
    padding: '10px 20px',
    borderRadius: 5,
    height: 40,
    backgroundColor: theme.palette.neutralLight,
  },
  fontStyleTotalAmount: {
    fontSize: 18,
  },

  badge: {
    width: 13,
    height: 13,
    borderRadius: 10,
    backgroundColor: theme.palette.orangeLighter,
    cursor: 'pointer',
  },
  modalHeaderContainer: {
    zIndex: 999,
    backgroundColor: theme.palette.neutralLighterAlt,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 70,
    width: 1300,
    cursor: 'move',
  },
}));
