import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    optionsContainer: {
      padding: 20,
    },
    // sigContainer: {
    //   padding: 10,
    //   backgroundColor: theme.palette.neutralLighter,
    // },

    // sigPad: {
    //   width: 500,
    //   height: 300,
    //   backgroundColor: theme.palette.white,
    // },
  };
});
