import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  columnHeight: {
    height: 25,
  },
  depot: {
    color: theme.palette.themePrimary,
  },
  row: {
    cursor: 'pointer',
  },
  listContainer: {
    height: 300,
  },
}));
