import { useQuery } from '@apollo/client';
import { IconButton, TooltipHost } from '@fluentui/react';
import { UserDefaults } from 'Preferences/__generated__/UserDefaults';
import { AccountingAutoCompleteTextField } from 'common/components/AccountingAutoCompleteTextField';
import {
  FormHookAmount,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { ColumnData } from 'common/components/SearchBar';
import { loader } from 'graphql.macro';
import React from 'react';
import { UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';
import { BudgetItemFormValues } from '../../types';
import { BudgetListCommonData } from '../__generated__/BudgetListCommonData';

const USER_DEFAULTS = loader(
  '../../../../../../Preferences/UserDefaults.graphql'
);

export interface FormFieldProps {
  baseField: string;
  columnData: ColumnData;
  remove: UseFieldArrayRemove;
  index: number;
  commonData: BudgetListCommonData | undefined;
}

export const FormField: React.FC<FormFieldProps> = ({
  baseField,
  columnData,
  remove,
  index,
  commonData,
}) => {
  const watchBudget = useWatch<BudgetItemFormValues>();
  const { setValue } = useFormContext<BudgetItemFormValues>();

  const isEdit = !!watchBudget?.departmentBudgets?.[index]?.id;
  const isUpdatable =
    !isEdit || watchBudget?.departmentBudgets?.[index]?._isUpdatable;
  const isDeletable =
    !isEdit || watchBudget?.departmentBudgets?.[index]?._isDeletable;
  const {
    userDepartments,
    departmentBudgetTypes,
    companyBusinessUnits,
    companyCurrencies,
  } = { ...commonData };

  const departmentsOptions =
    userDepartments?.nodes.map((item) => ({
      key: item.id,
      text: item.userDepartment?.name ?? 'NA',
    })) || [];

  const businessUnitOptions =
    companyBusinessUnits?.nodes.map((item) => ({
      key: item.id,
      text: item?.name ?? 'NA',
    })) || [];

  const budgetTypeOptions =
    departmentBudgetTypes?.nodes.map((item) => ({
      key: item.id,
      text: item?.budgetType ?? 'NA',
    })) || [];

  const currencyOptions =
    companyCurrencies?.nodes.map((item) => ({
      key: item.id,
      text: item?.isoCode ?? 'NA',
    })) || [];

  const { data: userDefaultsData } = useQuery<UserDefaults>(USER_DEFAULTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const setUserDefaultValues = async () => {
    if (userDefaultsData) {
      setValue(
        `departmentBudgets.${index}.currencyId`,
        userDefaultsData?.userDefaults?.nodes[0]?.currencyId ?? null
      );
    }
  };

  switch (columnData.key) {
    case 'departmentId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={departmentsOptions}
          width={columnData.maxWidth}
          underlined
          onChange={(_event, option) => {
            setUserDefaultValues();
          }}
        />
      );
    case 'businessUnitId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={businessUnitOptions}
          width={columnData.maxWidth}
          underlined
          disabled={!isUpdatable}
        />
      );
    case 'budgetTypeId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={budgetTypeOptions}
          width={columnData.maxWidth}
          underlined
          disabled={!isUpdatable}
        />
      );
    case 'currencyId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={currencyOptions}
          width={columnData.maxWidth}
          underlined
          disabled={!isUpdatable}
        />
      );
    case 'lookupAccountId':
      return (
        <AccountingAutoCompleteTextField
          businessUnitId={
            watchBudget?.departmentBudgets?.[index]?.businessUnitId || null
          }
          id={`lookupAccountId${columnData.key}${index}`}
          name={`${baseField}.lookupAccount`}
          width={columnData.maxWidth!}
          lookUpCalloutWidth={250}
          disabled={!isUpdatable}
          onVendorSelect={(data) => {
            setValue(`departmentBudgets.${index}.lookupAccountId`, data.id);
          }}
        />
      );
    case 'delete':
      return (
        <TooltipHost content="Delete Budget" id="tooltipId">
          <IconButton
            iconProps={{ iconName: 'delete' }}
            ariaLabel="Delete"
            onClick={() => {
              remove(index);
            }}
            disabled={!isDeletable}
          />
        </TooltipHost>
      );
    case 'budgetAmount':
      return (
        <FormHookAmount
          name={`${baseField}.${columnData.key}`}
          style={{ width: columnData.maxWidth, textAlign: 'right' }}
          underlined
          disabled={!isUpdatable}
        />
      );
    default:
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          disabled={!isUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
  }
};
