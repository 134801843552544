import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  statusColumnContainer: {
    height: 25,
  },
  statusType: {
    display: 'block',
    width: 100,
  },
  checkboxContainer: {
    flex: 0.2,
    justifyContent: 'end',
  },
  rowBaseStyle: { borderLeft: `5px solid transparent` },
}));
