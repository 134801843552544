import { IToggleStyles, Stack, Toggle, useTheme } from '@fluentui/react';
import React, { useState } from 'react';

interface UrgencyToggleProps {
  onToggle: (param: boolean) => void;
}

export const UrgencyToggle: React.FC<UrgencyToggleProps> = ({ onToggle }) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(false);
  const labelName: string = isChecked
    ? 'Urgent Notifications'
    : 'Standard Notifications';
  const toggleStyles: Partial<IToggleStyles> = {
    pill: {
      backgroundColor: isChecked ? theme.palette.redDark : theme.palette.white,
      ':hover': {
        backgroundColor: isChecked
          ? theme.palette.redDark
          : theme.palette.white,
      },
    },
  };

  const _onChange = (_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    setIsChecked(!!checked);
    onToggle(!!checked);
  };
  return (
    <Stack horizontal tokens={{ childrenGap: 10 }}>
      <Toggle
        onText={labelName}
        offText={labelName}
        checked={isChecked}
        onChange={_onChange}
        styles={toggleStyles}
      />
    </Stack>
  );
};
