import { FontSizes, makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  user: {
    fontSize: FontSizes.size14, fontWeight: 'bold'
  },
  boldFont: {
    fontWeight: 'bold'
  },
  container: {
    padding: "10px 20px 10px 0px",
    height: 270,
    overflow: "auto"
  },
  loadMoreButtonContainer: {
    height: 34
  }
}));