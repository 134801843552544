import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  centerAlign: {
    alignItems: 'center',
  },
  iconButtonColor: {
    marginLeft: 30,
    color: theme.palette.neutralDark,
  },
  pivotDisplayWrapper: {
    padding: `10px 25px 10px 10px`,
    cursor: 'pointer',
  },
  width5: {
    width: 5,
  },
  width10: {
    width: 10,
  },
  pivotDisplayCount: {
    height: 25,
    alignSelf: 'center',
    borderRadius: 13,
    minWidth: 25,
    backgroundColor: theme.palette.accent,
  },
  countColor: {
    color: 'white',
  },
  disabledColor:{
    backgroundColor: theme.palette.neutralLighter
  }
}));
