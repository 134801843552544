import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
export const columns: ColumnData[] = [
  {
    key: '_documentPoolName',
    name: 'Folder',
    fieldName: '_documentPoolName',
    minWidth: 90,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'indexName',
    name: 'Name',
    fieldName: 'indexName',
    minWidth: 150,
    ...commonColumnProps,
  },
  {
    key: 'indexReferenceNumber',
    name: 'Number',
    fieldName: 'indexReferenceNumber',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'indexTransactionDate',
    name: 'Date',
    fieldName: 'indexTransactionDate',
    minWidth: 120,
    ...commonColumnProps,
  },
  {
    key: '_documentContents',
    name: 'Contents',
    fieldName: '_documentContents',
    minWidth: 150,
    ...commonColumnProps,
  },

  {
    key: 'action',
    name: '',
    fieldName: 'action',
    minWidth: 20,
    maxWidth: 20,
    ...commonColumnProps,
    isResizable: false,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'fileReference',
    name: 'Document',
    fieldName: 'fileReference',
    minWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'indexAmount',
    name: 'Total',
    fieldName: 'indexAmount',
    minWidth: 70,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: 'isoCode',
    name: 'Currency',
    fieldName: 'isoCode',
    minWidth: 70,
    ...commonColumnProps,
  },
  {
    key: 'available',
    name: 'Available',
    fieldName: 'available',
    minWidth: 70,
    ...commonColumnProps,
  },
  {
    key: 'used',
    name: 'Used',
    fieldName: 'used',
    minWidth: 70,
    ...commonColumnProps,
  },
  {
    key: 'Comment',
    name: 'Comments',
    fieldName: 'comment',
    minWidth: 70,
    ...commonColumnProps,
  },
  {
    key: '_uploadDate',
    name: 'Uploaded',
    fieldName: '_uploadDate',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
];
