import { useMutation } from '@apollo/client';
import { DefaultButton, Modal, PrimaryButton, ProgressIndicator, Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import { CompanyCorporatePeriodUpdateTypeInput, CompanyCorporateYearUpdateInput } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import { intersection, isEmpty } from 'lodash';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { ACCOUNTING_PERIOD_VALUES } from '../constants';
import { AccountingPeriodValues, PeriodRowValues } from '../types';
import { CompanyCorporateYearUpdate, CompanyCorporateYearUpdateVariables } from '../__generated__/CompanyCorporateYearUpdate';
import { validationSchema } from './companyCorporatePeriodValidation';
import { useStyles } from './index.styles';
import { PeriodBasicForm } from './PeriodBasicForm';
const COMPANY_CORPORATE_YEAR_UPDATE = loader("../CompanyCorporateYearUpdate.graphql");
const COMPANY_CORPORATE_YEAR = loader("../CompanyCorporateYear.graphql")
interface AccountingPeriodViewProps {
  visible: boolean;
  onDismiss: () => void;
  isNew: boolean;
  fiscalYearId: string;
  fiscalYearData: AccountingPeriodValues | undefined;
  index: number;
}
export const AccountingPeriodView: React.FC<AccountingPeriodViewProps> = ({
  visible,
  onDismiss,
  isNew,
  fiscalYearId,
  fiscalYearData,
  index,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  let initialValues: AccountingPeriodValues = ACCOUNTING_PERIOD_VALUES;
  const headerTitle = isNew ? 'Create New Period' : 'Update Period';
  if (fiscalYearData) {
    initialValues = {
      ...fiscalYearData,
      companyCorporatePeriodsByFiscalYearId:
        fiscalYearData.companyCorporatePeriodsByFiscalYearId?.map(
          (period) => ({
            ...period,
            startDate: period.startDate ? dateFormat(dateConvertions(period.startDate)) : null,
            endDate: period.endDate ? dateFormat(dateConvertions(period.endDate)) : null,
          })
        ) || []
    }
  }
  const [companyCorporateYearUpdate, { loading: companyCorporateYearUpdateLoading }] = useMutation<
    CompanyCorporateYearUpdate,
    CompanyCorporateYearUpdateVariables
  >(COMPANY_CORPORATE_YEAR_UPDATE, { errorPolicy: 'all' });
  const handleSubmit = async (values: AccountingPeriodValues) => {
    const { id, _rowTimestamp, companyCorporatePeriodsByFiscalYearId } = values;
    if (isNew) {
      const { errors } = await companyCorporateYearUpdate({
        variables: {
          input: {
            id: id!,
            rowTimestamp: _rowTimestamp!,
            companyCorporatePeriodsCreate: [{
              fiscalPeriod: Number(companyCorporatePeriodsByFiscalYearId![0].fiscalPeriod!),
              startDate: companyCorporatePeriodsByFiscalYearId![0].startDate ?
                dateFormat(companyCorporatePeriodsByFiscalYearId![0].startDate) :
                null,
              endDate: companyCorporatePeriodsByFiscalYearId![0].endDate ?
                dateFormat(companyCorporatePeriodsByFiscalYearId![0].endDate) :
                null,
              isPeriodOpen: companyCorporatePeriodsByFiscalYearId![0].isPeriodOpen,
              isEntryAllowed: companyCorporatePeriodsByFiscalYearId![0].isEntryAllowed,
            }]
          } as CompanyCorporateYearUpdateInput
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: COMPANY_CORPORATE_YEAR,
            variables: {
              id: fiscalYearId!,
            },
          },
        ],
      });
      if (!errors) {
        addToast('Period Created Successfully', {
          appearance: 'success',
        });
        onDismiss();
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    } else {
      const updatedPeriods =
        initialValues.companyCorporatePeriodsByFiscalYearId &&
        companyCorporatePeriodsByFiscalYearId &&
        intersection(
          initialValues.companyCorporatePeriodsByFiscalYearId.map((addr) => addr.id),
          companyCorporatePeriodsByFiscalYearId.filter((addr) => addr.id).map((addr) => addr.id)
        ).reduce((arr, targetId) => {
          const initialPeriod = initialValues.companyCorporatePeriodsByFiscalYearId!.find(
            (addr) => addr.id === targetId
          )!;
          const { id, _rowTimestamp, ...updatedAddress } = companyCorporatePeriodsByFiscalYearId!.find(
            (addr) => addr.id === targetId
          )!;
          const patch = Object.entries(updatedAddress).reduce(
            (res, [key, val]) => {
              if (val !== initialPeriod[key as keyof PeriodRowValues])
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                companyCorporatePeriodPatch: patch,
              },
            ];
          return arr;
        }, [] as CompanyCorporatePeriodUpdateTypeInput[]);
      const { errors } = await companyCorporateYearUpdate({
        variables: {
          input: {
            id: id!,
            rowTimestamp: _rowTimestamp!,
            companyCorporatePeriodsUpdate: updatedPeriods
          } as CompanyCorporateYearUpdateInput
        },
      });
      if (!errors) {
        addToast('Period Updated Successfully', {
          appearance: 'success',
        });
        onDismiss();
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  }
  const fieldName = `companyCorporatePeriodsByFiscalYearId[${index}]`;
  const loading = companyCorporateYearUpdateLoading;
  return (
    <>
      <Formik<AccountingPeriodValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, resetForm, dirty, errors }) => {
          const onClose = () => {
            resetForm();
            onDismiss();
          };
          const onSubmit = async () => {
            await submitForm();
          };
          return (
            <Form>
              <Modal isOpen={visible} isBlocking={true} onDismiss={onDismiss}>
                <Stack className={styles.container}>
                  <Stack
                    horizontal
                    horizontalAlign={'space-between'}
                    className={styles.dialogStyles}
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <Text
                        variant={'xLarge'}
                        className={commonStyles.colorThemePrimary}
                      >{headerTitle}
                      </Text>
                      <UnsavedIndicator
                        visible={!isNew && dirty && !isSubmitting}
                      />
                    </Stack>
                    <CloseButton onClick={onClose} />
                  </Stack>
                  <PeriodBasicForm
                    isNew={isNew}
                    fieldName={fieldName}
                  />
                  {loading && <ProgressIndicator />}
                  <Stack
                    horizontalAlign="space-between"
                    className={styles.buttonContainer}
                    horizontal
                    tokens={{ childrenGap: 6, padding: 25 }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <PrimaryButton
                        disabled={!dirty || Object.keys(errors).length > 0}
                        text={headerTitle}
                        onClick={onSubmit}
                      />
                      <DefaultButton onClick={onClose} text="Cancel" />
                    </Stack>
                  </Stack>
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  )
}
