import {
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';

export const ShimmerSection = (): JSX.Element => {
  return (
    <div>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, width: '5%' },
          { type: ShimmerElementType.line, height: 100, width: '32%' },
          { type: ShimmerElementType.gap, width: '5%' },
          { type: ShimmerElementType.line, height: 100, width: '32%' },
          { type: ShimmerElementType.gap, width: '5%' },
          { type: ShimmerElementType.line, height: 100, width: '32%' },
          { type: ShimmerElementType.gap, width: '5%' },
        ]}
      />
    </div>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <Stack className={styles.containerpadding} tokens={{ childrenGap: 30 }}>
      <Shimmer customElementsGroup={ShimmerSection()} />
      <Shimmer customElementsGroup={ShimmerSection()} />
      <Shimmer customElementsGroup={ShimmerSection()} />
      <Shimmer customElementsGroup={ShimmerSection()} />
    </Stack>
  );
};
