import { useQuery } from '@apollo/client';
import { Separator, Stack } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import { loader } from 'graphql.macro';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TravelAuthorizationValues } from 'travelAuthorization/TravelPlan/view/interface';
import { Trip_trip } from '../../../__generated__/Trip';
import { AttachForm } from './AttachForm';
import { columns } from './column.data';
import { UploadForm } from './UploadForm';
import { TripUploadDocument_tripUploadDocument } from './UploadForm/__generated__/TripUploadDocument';
import {
  TripAvailableDocumentTypes,
  TripAvailableDocumentTypes_tripAvailableDocumentTypes,
} from './__generated__/TripAvailableDocumentTypes';
import { TripSecureRowLevels } from './__generated__/TripSecureRowLevels';

const TRIP_ROW_LEVELS = loader('./TripSecureRowLevels.graphql');
const TRIP_AVAILABLE_DOCUMENT_TYPES = loader(
  './TripAvailableDocumentTypes.graphql'
);

interface AttachProps {
  tripData: Trip_trip | null | undefined;
  onRemove?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: TripUploadDocument_tripUploadDocument,
    toastId: string
  ) => void;
}

export const Attach: React.FC<AttachProps> = ({
  tripData,
  onUpload,
  onRemove,
}) => {
  const { entityDocumentsByEntityId } = { ...tripData };
  const {
    formState: { isDirty },
  } = useFormContext<TravelAuthorizationValues>();

  const { data: travelAuthorizationSecureRowLevels } =
    useQuery<TripSecureRowLevels>(TRIP_ROW_LEVELS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const { secureRowLevels } = { ...travelAuthorizationSecureRowLevels };

  const { data: availableDocumentTypes } = useQuery<
    TripAvailableDocumentTypes,
    TripAvailableDocumentTypes_tripAvailableDocumentTypes
  >(TRIP_AVAILABLE_DOCUMENT_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  return (
    <Stack>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{
          childrenGap: 10,
          padding: '0px 10px 0px',
        }}
      >
        <AttachForm
          availableDocumentTypes={availableDocumentTypes}
          tripData={tripData}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={availableDocumentTypes}
          tripData={tripData}
          onUpload={onUpload}
        />
      </Stack>
      {!!entityDocumentsByEntityId?.nodes.length && (
        <Stack
          tokens={{
            padding: '0px 0px 0px 10px',
          }}
        >
          <DocumentList
            isNew={false}
            data={entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevels?.nodes}
            onRemoveRow={() => {
              onRemove?.();
            }}
            columns={columns}
            disableUpdate={isDirty}
          />
        </Stack>
      )}
      <Stack tokens={{ padding: '10px 25px 0px' }}>
        <NoDataView
          show={entityDocumentsByEntityId?.totalCount === 0}
          title="No Attachments"
        />
      </Stack>
    </Stack>
  );
};
