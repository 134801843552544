import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },

  onrenderColumnStack: {
    height: 25,
  },
}));
