import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  onrenderColumnStack: {
    height: 25,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  contentColumnAlignCenter: {
    display: 'block',
    textAlign: 'center',
  },
  statusType: {
    display: 'block',
    width: 100,
  },
  rowBaseStyle: { borderLeft: `5px solid transparent` },
}));
