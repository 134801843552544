import { Stack, Text, Callout, DirectionalHint } from '@fluentui/react';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';
import { TagCategories_tagCategories_nodes } from '../__generated__/TagCategories';
import { CalloutText } from './CalloutText';
const _ = require('lodash');

interface DocumentCalloutProps {
  tagCategoryData: TagCategories_tagCategories_nodes;
}

export const CATEGORY_METRIC_VALUES = {
  isCostMetric: 'Cost Metric',
  isCustomerMetric: 'Customer Metric',
  isEmployeeMetric: 'Employee Metric',
  isPayablesMetric: 'Payables Metric',
  isEnvironmentalMetric: 'Environmental Metric',
  isPayrollMetric: 'Payroll Metric',
  isPettyCashMetric: 'PettyCash Metric',
  isPurchasingMetric: 'Purchasing Metric',
  isRedFlag: 'RedFlag',
  isReceivablesMetric: 'Receivables Metric',
  isSupplierMetric: 'Supplier Metric',
  isTravelMetric: 'Travel Metric',
  isVendorMetric: 'Vendor Metric',
};

export const TagCategoryCallout: React.FC<DocumentCalloutProps> = ({
  tagCategoryData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const calloutId = `callOutId_TagView_${tagCategoryData?.id}`;
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const omitKeys = [
    'id',
    '_rowTimestamp',
    '_isUpdatable',
    '_isDeletable',
    '_isModified',
    '_isDeprecated',
    '_isActive',
    'tagCategoryItemsByTagCategoryId',
    'description',
    'name',
    '__typename',
  ];

  const getValuesFromKeys = (array: string[]) => {
    let valuesArray = [];
    for (let [key, value] of Object.entries(CATEGORY_METRIC_VALUES)) {
      if (array.includes(key)) valuesArray.push(value);
    }
    return valuesArray;
  };

  var filterData = _.omit(tagCategoryData, omitKeys);
  var selectedMetric = _.keys(_.pickBy(filterData));
  var selectedMetricString = getValuesFromKeys(selectedMetric);

  return (
    <Stack>
      <Stack verticalAlign="center">
        <Text
          id={calloutId}
          onMouseEnter={() => setIsCalloutVisible(true)}
          onMouseLeave={() => setIsCalloutVisible(false)}
        >
          {selectedMetricString.toString()}
        </Text>

        {isCalloutVisible && selectedMetricString.length > 0 && (
          <Callout
            className={styles.callout}
            gapSpace={0}
            target={`#${calloutId}`}
            directionalHint={DirectionalHint.topCenter}
            onDismiss={() => setIsCalloutVisible(false)}
            setInitialFocus
          >
            <>
              <Stack className={styles.typeContainer}>
                <Text
                  className={clsx(commonStyles.semibold, styles.categoryType)}
                  variant="mediumPlus"
                >
                  Metrics
                </Text>
              </Stack>
              <Stack>
                {selectedMetricString.map((value, index) => {
                  return <CalloutText key={index} text={value} hasSeparator />;
                })}
              </Stack>
            </>
          </Callout>
        )}
      </Stack>
    </Stack>
  );
};
