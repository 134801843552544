import { Separator, Stack, Text } from '@fluentui/react';
import { InfoView } from 'common/components/InfoView';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { useStyles } from './index.styles';
import { PurchaseOrderDetails_purchaseOrder } from '../__generated__/PurchaseOrderDetails';
import { ActionMenu } from './ActionMenu';
import { POItems } from './POItems';

export interface PurchaseOrderDetailProps {
  purchaseOrder: PurchaseOrderDetails_purchaseOrder | null | undefined;
  loading: boolean;
}

export const PurchaseOrderDetail: React.FC<PurchaseOrderDetailProps> = ({
  purchaseOrder,
  loading,
}) => {
  const styles = useStyles();
  const {
    _purchaseOrderType,
    businessUnit,
    sortSupplierName,
    department,
    purchaseOrderDate,
    currency,
    controlTotalAmount,
    reason,
    description,
    purchaseOrderItems,
  } = { ...purchaseOrder }

  return (
    <Stack tokens={{ padding: "0px 0px 100px" }}>
      {
        loading ?
          <ShimmerView /> :
          <>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.infoViewWrapper}
            >
              <Stack className={styles.container}>
                <InfoView
                  title='Type'
                  description={`${_purchaseOrderType}` || '-'}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title={'Supplier'}
                  description={sortSupplierName || '-'}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Business Unit'
                  description={businessUnit ? `${businessUnit?.name}` : '-'}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Department'
                  description={department ? `${department?.name}` : '-'}

                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Date'
                  description={`${purchaseOrderDate ? dateFormat(dateConvertions(purchaseOrderDate || '')) : '-'}`}
                />
              </Stack>
            </Stack>
            <Separator />
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.infoViewWrapper}
            >
              <Stack
                horizontal
                className={styles.amountRow}
              >
                <Stack className={styles.container}>
                  <InfoView
                    title='Currency'
                    description={currency ? `${currency?.isoCode}` : '-'}
                  />
                </Stack>
                <Stack className={styles.container}>
                  <InfoView
                    title={'Total'}
                    currency={currency?.isoCode || ''}
                    description={controlTotalAmount || '-'}
                    isAmount
                  />
                </Stack>
              </Stack>
              <Stack
                className={styles.instructionsRow}
              >
                <InfoView
                  title='Special Instructions'
                  description={reason || '-'}
                />
              </Stack>
            </Stack>
            <Separator />
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.infoViewWrapper}
            >
              <Stack className={styles.container}>
                <InfoView
                  title='Order Description'
                  description={description || '-'}
                />
              </Stack>
            </Stack>
            <Separator />
            <ActionMenu purchaseOrder={purchaseOrder} />
            {
              !!purchaseOrderItems?.nodes.length &&
              <>
                <Separator />
                <Stack tokens={{ padding: "0px 20px", childrenGap: 10 }}>
                  <Text variant="xLarge">Order Items</Text>
                  <POItems purchaseOrderItemsData={purchaseOrderItems} />
                </Stack>
              </>
            }
          </>
      }
    </Stack>
  )
}
