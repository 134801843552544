import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
    paddingRight: 25,
  },
};

const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 100,
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
  columnActionsMode: 0,
};

export const columns: ColumnData[] = [
  {
    key: 'vendorReference',
    name: 'Name',
    fieldName: 'vendorReference',
    minWidth: 350,
    maxWidth: 400,
    styles: colHeaderLeftAlignStyle,
    ...commonColumnProps,
  },
  {
    key: 'invoiceNumber',
    name: 'Number',
    fieldName: 'invoiceNumber',
    minWidth: 200,
    maxWidth: 200,
    // styles: colHeaderLeftAlignStyle,
    ...commonColumnProps,
  },
  {
    key: 'department',
    name: 'Department',
    fieldName: 'department',
    minWidth: 200,
    maxWidth: 200,
    // styles: colHeaderLeftAlignStyle,
    ...commonColumnProps,
  },
  {
    key: 'corporatePeriodId',
    name: 'Period',
    fieldName: 'corporatePeriodId',
    minWidth: 100,
    maxWidth: 200,
    // styles: colHeaderLeftAlignStyle,
    ...commonColumnProps,
  },
  {
    key: 'controlTotalAmount',
    name: 'Amount',
    fieldName: 'controlTotalAmount',
    minWidth: 200,
    maxWidth: 200,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
];
