import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IButtonProps,
  IconButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { EntityAction, EntityType } from 'common/types/utility';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import { getConfirmButton, invalidActionMessage } from './utils';

interface ReleaseReviewModalProps {
  entityType: EntityType;
  action: EntityAction;
  onConfirm: () => Promise<void>;
  visible?: boolean;
  disabled?: boolean;
  multiple?: {
    validCount: number;
    invalidNames?: string[];
  };
  buttonProps?: Omit<IButtonProps, 'onClick' | 'disabled'>;
}
export const ReleaseReviewModal: React.FC<ReleaseReviewModalProps> = ({
  entityType,
  action = EntityAction.Release,
  onConfirm,
  visible = true,
  disabled = false,
  multiple,
  buttonProps,
}) => {
  const styles = useStyles();
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(true);
  const pastTenseAction = `${action.toLowerCase()}d`;
  const subText = multiple
    ? `The selected ${entityType.toLowerCase()}${
        multiple.validCount > 1 ? `s (${multiple.validCount})` : ''
      } will be ${pastTenseAction}.`
    : `The current ${entityType.toLowerCase()} will be ${pastTenseAction}.`;
  const ConfirmButton = getConfirmButton(action);

  return (
    <Stack horizontal>
      {visible && (
        <TooltipHost content={action} hidden={disabled}>
          {buttonProps ? (
            <PrimaryButton
              onClick={() => setDialogVisibility(!dialogVisibility)}
              disabled={disabled || (multiple && !multiple.validCount)}
              {...buttonProps}
            />
          ) : (
            <IconButton
              onClick={() => setDialogVisibility(!dialogVisibility)}
              iconProps={{ iconName: 'Delete' }}
              ariaLabel={action}
              disabled={disabled || (multiple && !multiple.validCount)}
              className={styles.iconButton}
            />
          )}
        </TooltipHost>
      )}
      {!dialogVisibility && (
        <Dialog
          hidden={false}
          onDismiss={() => setDialogVisibility(!dialogVisibility)}
          dialogContentProps={{
            title: `Are you sure?`,
            type: DialogType.largeHeader,
            subText: subText,
          }}
          minWidth={400}
        >
          {multiple &&
            multiple.invalidNames &&
            multiple.invalidNames.length > 0 && (
              <MessageBar messageBarType={MessageBarType.severeWarning}>
                {invalidActionMessage(multiple.invalidNames, entityType)}
              </MessageBar>
            )}
          <DialogFooter>
            <ConfirmButton
              onClick={async () => {
                await onConfirm();
                setDialogVisibility(!dialogVisibility);
              }}
              text={action}
            />
            <DefaultButton
              onClick={() => {
                setDialogVisibility(!dialogVisibility);
              }}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>
      )}
    </Stack>
  );
};
