import { IconButton, Stack } from '@fluentui/react';
import {
  FormikDropdown,
  AmountTextField,
  FormikCheckBox,
  FormikNumberTextField,
  FormikTextField,
} from 'common/components';
import { ApprovalState } from 'common/types/globalTypes';
import { formatDropdownOptions } from 'common/utils';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { ApprovalSetupCommonData } from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { REQ_COND_COLUMNS } from '..';
import { ApprovalValues, RequestorConditionsValues } from '../../types';
import { useStyles } from './index.styles';

interface RequestorConditionsRowsProps {
  data: RequestorConditionsValues;
  approvalSelectionConditionsName: string;
  distributionTypes: ApprovalSetupCommonData;
  index: number;
  lastRow: number;
  approvalState?: ApprovalState | null | undefined;
  isDisabled?: boolean;
  isDisabledBasedOnApprovalState?: boolean;
  insertAdditionalRow: () => void;
  deleteCurrentRow: (index: number) => void;
  isNew?: boolean;
}

export const RequestorConditionsRows: React.FC<
  RequestorConditionsRowsProps
> = ({
  data,
  approvalSelectionConditionsName,
  distributionTypes,
  index,
  lastRow,
  insertAdditionalRow,
  deleteCurrentRow,
  isDisabled,
  isDisabledBasedOnApprovalState,
  isNew,
}) => {
    const styles = useStyles();
    const [showRemoveIcon, setShowRemoveIcon] = useState<boolean>(false);
    const { setFieldValue } = useFormikContext<ApprovalValues>();

    const currencies =
      formatDropdownOptions(distributionTypes?.companyCurrencies?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.name!,
        includeAll: false,
      }) || [];

    const additionalBusUnit =
      formatDropdownOptions(distributionTypes?.businessUnits?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.name || '',
        includeAll: false,
      }) || [];

    const conversionOptions =
      formatDropdownOptions(distributionTypes?.exchangeRateTypes?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.phaseName || '',
        includeAll: false,
      }) || [];

    const occupations =
      formatDropdownOptions(distributionTypes?.userOccupationTitles?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.userOccupationTitle!,
        includeAll: false,
      }) || [];

    const ranks =
      formatDropdownOptions(distributionTypes?.userRankTypes?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.userRank!,
        includeAll: false,
      }) || [];

    const companies =
      formatDropdownOptions(distributionTypes?.companies?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.name!,
        includeAll: false,
      }) || [];

    const users =
      formatDropdownOptions(distributionTypes?.users?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.name!,
        includeAll: false,
      }) || [];

    const purchaseOrder =
      formatDropdownOptions(distributionTypes?.purchaseOrderTypes?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.purchaseOrderType!,
        includeAll: false,
      }) || [];

    const departments =
      formatDropdownOptions(distributionTypes?.departments?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.name!,
        includeAll: false,
      }) || [];

    const departmentTypes =
      formatDropdownOptions(distributionTypes?.departmentTypes?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.departmentType,
        includeAll: false,
      }) || [];

    const departmentGroups =
      formatDropdownOptions(distributionTypes?.departmentGroups?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.name,
        includeAll: false,
      }) || [];

    const tags =
      formatDropdownOptions(distributionTypes?.tags?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.badgeName,
        includeAll: false,
      }) || [];

    const rowSecurity =
      formatDropdownOptions(distributionTypes?.secureRowLevels?.nodes, {
        getKey: (item) => item.id,
        getText: (item) => item.name!,
        includeAll: false,
      }) || [];

    const handleDataUpdate = () => {
      const edited = Object.values(data).filter(
        (field) => field !== null && field !== ''
      );
      if (edited.length > 0 && lastRow === index && !isDisabled) {
        insertAdditionalRow();
      }
    };

    const handleDataUpdateMemo = useCallback(handleDataUpdate, [data]);

    useEffect(() => {
      handleDataUpdateMemo();
    }, [handleDataUpdateMemo]);

    const removeCurrentIndex = () => {
      deleteCurrentRow(index);
    };

    const handleDeleteRow = () => {
      const edited = Object.values(data).filter(
        (field) => field !== null && field !== ''
      );
      if (edited.length > 0) {
        setShowRemoveIcon(true);
      } else {
        setShowRemoveIcon(false);
      }
      if (
        data.importanceLevel === null &&
        data.description === null &&
        data.startAmountTotal === null &&
        data.endAmountTotal === null &&
        data.requesterCurrencyId === null &&
        data.requesterUserOccupationTitleId === null &&
        data.requesterUserRankId === null &&
        data.requesterCompanyId === null &&
        data.requesterBusinessUnitId === null &&
        data.startElapsedDays === null &&
        data.endElapsedDays === null &&
        data.requesterUserId === null &&
        data.requesterPurchaseOrderTypeId === null &&
        lastRow !== index
      ) {
        deleteCurrentRow(index);
      }
    };
    const handleDeleteRowMemo = useCallback(handleDeleteRow, [data]);
    useEffect(() => {
      handleDeleteRowMemo();
    }, [handleDeleteRowMemo]);

    return (
      <Stack
        className={styles.marginL5}
        key={data.id}
        horizontal
        horizontalAlign="center"
      >
        <Stack className={styles.removeIconContainer}>
          {showRemoveIcon && !isDisabled && (
            <IconButton
              onClick={removeCurrentIndex}
              iconProps={{ iconName: 'Blocked2Solid' }}
              ariaLabel="delete"
              className={styles.deleteIconColor}
            />
          )}
        </Stack>
        <FormikNumberTextField
          className={styles.marginL5}
          onChange={(e, value) => {
            const re = /^[+-]?\d*(?:[.,]\d*)?$/;
            if (re.test(value!)) {
              setFieldValue(
                `${approvalSelectionConditionsName}.importanceLevel`,
                value
              );
            }
          }}
          inputMode="decimal"
          name={`${approvalSelectionConditionsName}.importanceLevel`}
          style={{
            width: REQ_COND_COLUMNS.importanceLevel.width,
          }}
          disabled={isDisabled}
          maxLength={10}
          underlined
        />
        <Stack>
          <FormikTextField
            className={styles.marginL5}
            name={`${approvalSelectionConditionsName}.description`}
            style={{ width: REQ_COND_COLUMNS.description.width }}
            disabled={isDisabled}
            underlined
          />
        </Stack>
        <Stack>
          <AmountTextField
            className={styles.marginL5}
            name={`${approvalSelectionConditionsName}.startAmountTotal`}
            style={{
              width: REQ_COND_COLUMNS.startAmountTotal.width,
              textAlign: 'right',
            }}
            disabled={isDisabled}
            underlined
            fixedDecimalScale
            decimalScale={2}
            allowNegative={false}
          />
        </Stack>
        <Stack>
          <AmountTextField
            // className={clsx(styles.marginL5, styles.paddingRight5)}
            className={styles.marginL5}
            name={`${approvalSelectionConditionsName}.endAmountTotal`}
            style={{
              width: REQ_COND_COLUMNS.endAmountTotal.width,
              textAlign: 'right',
            }}
            disabled={isDisabled}
            underlined
            fixedDecimalScale
            decimalScale={2}
            allowNegative={false}
          />
        </Stack>

        {/* not able to add className so used inline style */}
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={currencies}
            name={`${approvalSelectionConditionsName}.requesterCurrencyId`}
            disabled={isDisabled}
            underlined
          />
        </Stack>
        {/* not able to add className so used inline style */}
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={conversionOptions}
            name={`${approvalSelectionConditionsName}.amountConversionTypeId`}
            disabled={isDisabled}
            underlined
          />
        </Stack>
        {/* not able to add className so used inline style */}
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={occupations}
            name={`${approvalSelectionConditionsName}.requesterUserOccupationTitleId`}
            disabled={isDisabled}
            underlined
          />
        </Stack>
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={departments}
            name={`${approvalSelectionConditionsName}.requesterDepartmentId`}
            disabled={isDisabled}
            underlined
          />
        </Stack>
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={departmentTypes}
            name={`${approvalSelectionConditionsName}.requesterDepartmentTypeId`}
            disabled={isDisabled}
            underlined
          />
        </Stack>
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={departmentGroups}
            name={`${approvalSelectionConditionsName}.requesterDepartmentGroupId`}
            disabled={isDisabled}
            underlined
          />
        </Stack>

        <Stack style={{ width: 130, marginLeft: 5, marginRight: 10 }}>
          <FormikNumberTextField
            allowZeroValue
            inputMode="decimal"
            name={`${approvalSelectionConditionsName}.startElapsedDays`}
            style={{
              width: REQ_COND_COLUMNS.startElapsedDays.width,
            }}
            disabled={isDisabled}
            maxLength={4}
            underlined
          />
        </Stack>
        <Stack style={{ width: 130, marginLeft: 5, marginRight: 10 }}>
          <FormikNumberTextField
            allowZeroValue
            inputMode="decimal"
            name={`${approvalSelectionConditionsName}.endElapsedDays`}
            style={{
              width: REQ_COND_COLUMNS.endElapsedDays.width,
            }}
            disabled={isDisabled}
            maxLength={4}
            underlined
          />
        </Stack>
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={tags}
            name={`${approvalSelectionConditionsName}.requesterTagId`}
            disabled={isDisabled}
            underlined
          />
        </Stack>
        {/* not able to add className so used inline style */}
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={ranks}
            name={`${approvalSelectionConditionsName}.requesterUserRankId`}
            disabled={isDisabled}
            underlined
          />
        </Stack>
        {/* not able to add className so used inline style */}
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={companies}
            name={`${approvalSelectionConditionsName}.requesterCompanyId`}
            disabled={isDisabled ? isDisabled : isDisabledBasedOnApprovalState}
            underlined
          />
        </Stack>
        {/* not able to add className so used inline style */}
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            dropdownWidth="auto"
            options={additionalBusUnit}
            name={`${approvalSelectionConditionsName}.requesterBusinessUnitId`}
            disabled={isDisabled ? isDisabled : isDisabledBasedOnApprovalState}
            underlined
          />
        </Stack>
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            underlined
            dropdownWidth="auto"
            options={users}
            name={`${approvalSelectionConditionsName}.requesterUserId`}
            disabled={isDisabled ? isDisabled : isDisabledBasedOnApprovalState}
          />
        </Stack>
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            underlined
            dropdownWidth="auto"
            options={rowSecurity}
            name={`${approvalSelectionConditionsName}.requesterSecureRowLevelId`}
            disabled={isDisabled}
          />
        </Stack>
        <Stack style={{ width: 180, marginLeft: 5 }}>
          <FormikDropdown
            styles={{
              root: {
                maxWidth: 136,
              }
            }}
            underlined
            dropdownWidth="auto"
            options={purchaseOrder}
            name={`${approvalSelectionConditionsName}.requesterPurchaseOrderTypeId`}
            disabled={isDisabled ? isDisabled : isDisabledBasedOnApprovalState}
          />
        </Stack>
        <Stack
          style={{
            width: 160,
          }}
          horizontalAlign="center"
          className={styles.marginLeft5}
        >
          <FormikCheckBox
            label=""
            disabled={isDisabled ? isDisabled : isDisabledBasedOnApprovalState}
            boxSide="end"
            name={`${approvalSelectionConditionsName}.isAutoApprovedEligible`}
            onChange={(_, value) => {
              setFieldValue(
                `${approvalSelectionConditionsName}.isAutoApprovedEligible`,
                value
              );
            }}
          />
        </Stack>
        <Stack
          style={{
            width: 160,
          }}
          horizontalAlign="center"
          className={styles.marginLeft5}
        >
          <FormikCheckBox
            label=""
            disabled={isDisabled ? isDisabled : isDisabledBasedOnApprovalState}
            boxSide="end"
            name={`${approvalSelectionConditionsName}.isAndCondition`}
            onChange={(_, value) => {
              setFieldValue(
                `${approvalSelectionConditionsName}.isAndCondition`,
                value
              );
            }}
          />
        </Stack>
      </Stack>
    );
  };
