import {
  makeStyles,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,
} from '@fluentui/react';
import React from 'react';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
    },
    fullWidthContainer: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    containerPadding: { padding: '10px 25px 20px 0px' },
    rowPadding: { paddingRight: 25 },
    messageDetailRowPadding: { paddingRight: 25, paddingTop: 60 },
  };
});

export const GroupHeader = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div
      className={styles.container}
    >
      <ShimmerElementsGroup
        width="200px"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 30, width: 200 },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          {
            type: ShimmerElementType.gap,
            height: 30,
            width: '100%',
          },
        ]}
      />
    </div>
  );
};

export const GroupRows = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
    </div>
  );
};

export const EmailContactGroup: React.FC<{ totalShimmerRows: number }> = ({
  totalShimmerRows
}) => {
  const styles = useStyles();
  const shimmerRowsArray = Array.from(Array(totalShimmerRows).keys());
  return (
    <>
      <Stack className={styles.containerPadding}>
        <Shimmer customElementsGroup={GroupHeader()} />
      </Stack>
      <Stack
        className={styles.rowPadding}
      >
        {
          shimmerRowsArray.length &&
          shimmerRowsArray.map((item) => {
            return (
              <Shimmer key={item} customElementsGroup={GroupRows()} />
            )
          })
        }
      </Stack>
    </>
  );
};

export const EmailContactGroupShimmerView: React.FC = () => {
  const contactGroupArray = Array.from(Array(3).keys());
  return (
    <>
      {
        contactGroupArray.map(item => {
          return (
            <EmailContactGroup key={item} totalShimmerRows={3} />
          )
        })
      }
    </>
  );
};
