import { Callout, DirectionalHint, Separator, Stack, Text } from '@fluentui/react'
import clsx from 'clsx'
import { useCommonStyles } from 'common/styles';
import React from 'react'
import { DetailsCallout } from '..';
import { useStyles } from './index.styles';
import { AddressRow } from './AddressRow';

type AddressDetailsCalloutType = Omit<
  DetailsCallout,
  "onSelect"
> & {
  directionalHint: DirectionalHint
}

export const AddressDetailsCallout: React.FC<AddressDetailsCalloutType> = ({
  onDismiss,
  data,
  detailsTargetId,
  directionalHint,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const {
    _fullName,
    vendorNumber,
    supplierAddressesBySupplierId,
  } = data || {};

  const {
    addressLine1,
    addressLine2,
    cityName,
    stateRegion,
    postalCode,
    telephoneNumber,
    emailAddress
  } = supplierAddressesBySupplierId?.nodes?.[0] || {};

  const addressObject = {
    cityName,
    stateRegion: stateRegion?.stateRegion,
    postalCode,
    country: stateRegion?.country?.country,
    emailAddress,
    telephoneNumber,
  }

  const addressFieldsArray = Object.values(addressObject).filter((value) => !!value);

  return (
    <Callout
      onDismiss={onDismiss}
      isBeakVisible={true}
      className={styles.detailsCallout}
      directionalHint={directionalHint}
      target={`#${detailsTargetId}`}
    >
      <Stack className={styles.calloutContainer}>
        <Stack
          horizontalAlign="space-between"
          horizontal
          tokens={{ padding: '10px' }}
        >
          <Text
            className={clsx(
              commonStyles.semibold,
              commonStyles.colorThemePrimary
            )}
            variant="mediumPlus"
          >
            {_fullName}
          </Text>
          {
            vendorNumber &&
            <Text
              className={clsx(
                commonStyles.semibold,
                commonStyles.colorThemePrimary,
                styles.lookupCode
              )}
            >
              {`#${vendorNumber}`}
            </Text>
          }
        </Stack>
        <Separator className={styles.separator} />
        <Stack horizontal>
          <Stack
            tokens={{
              childrenGap: 10,
              padding: '0px 10px 10px',
            }}
          >
            <AddressRow data={_fullName} isLastRow />
            <AddressRow data={addressLine1} isLastRow />
            <AddressRow data={addressLine2} isLastRow />
            <Stack
              horizontal
              wrap
              tokens={{ childrenGap: 5 }}
            >
              {
                addressFieldsArray.map((item, index, array) => {
                  const isLast = index === (array.length - 1);
                  return (
                    <AddressRow
                      key={index}
                      data={item}
                      isLastRow={isLast}
                    />
                  )
                })
              }
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Callout >
  )
}
