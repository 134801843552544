import { format } from 'date-fns';
import { PurchaseOrderValues } from './types';

export const PURCHASE_ORDER_INITIAL_VALUES: PurchaseOrderValues = {
  purchaseOrderTypeId: null,
  businessUnitId: null,
  departmentId: null,
  purchaseOrderDate: format(new Date(), 'yyyy/MM/dd'),
  description: null,
  currencyId: null,
  controlTotalAmount: null,
  supplierId: null,
  reason: null,
  deliveryAddressId: null,
  deliveryAddressLine1: null,
  deliveryAddressLine2: null,
  deliveryCityName: null,
  deliveryCountryId: null,
  deliveryStateRegionId: null,
  deliveryPostalCode: null,
  deliveryTelephoneNumber: null,
  deliveryEmailAddress: null,
  scheduledDeliveryDate: null,
  deliveryNotes: null,
  isDeliveryTracked: null,
  purchaseOrderItems: null,
  supplier: null,
  businessUnit: null,
};

export const fieldsToExclude = [
  'currency',
  'purchaseOrderType',
  'purchaseOrderHistoriesByEntityId',
  'notesByEntityId',
  'entityDocumentsByEntityId',
  'entityTagsByEntityId',
  'statusType',
  'entityEnvironmentalsByEntityId',
  '_isEnvironmentalsExist',
  'secureRowLevel',
  '_isPromoteAddressAllowed',
  '_isStagedApprovalRequest',
  '_isApprovalRevocable',
  'approvalHistoriesByEntityId',
  'approvalHistorySignaturesByEntityId',
  'deliveryAddressAlias',
  'purchaseOrderRevisionHistoriesByPoGroupId',
  '_isAccountingEntry',
  '_isHistory',
  '_isStampAllowed',
  '_isTransactionCancelled',
  '_isAccountingEntryStampedComplete',
  '_accountingStampDate',
  '_accountingStampUserName',
  '_accountingStampTransactionReference',
  '_isInvoiceScheduleViewable',
  '_isInvoiceScheduleUpdatable',
  'stampedEntityDocumentId',
  'entityDocumentId',
  '_emailDocument',
  'emailDocumentsByEntityId',
];
