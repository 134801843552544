import React, { ReactNode, useMemo, useState } from 'react';
import { Modal, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useStyles } from './index.styles';
import { CloseButton } from '../Buttons';
import { ApprovalFlow } from '../ApprovalFlow';

const HEADER_TITLE = 'Approval Flow Hierarchy';
interface ApprovalFlowModalProps {
  entityId: string;
  isApprovalEntry?: boolean;
  /**@param  customButton render custom button instead regular Primary Button*/
  customButton?: ReactNode;
}
export const ApprovalFlowModal: React.FC<ApprovalFlowModalProps> = ({
  entityId,
  isApprovalEntry,
  customButton,
}) => {
  const styles = useStyles();
  const [approvalType, setApprovalType] = useState<undefined | string>();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const ApprovalFlowComponent = () => {
    return (
      <Stack className={styles.hierarchyContainer}>
        <ApprovalFlow
          getApprovalType={(data) => setApprovalType(data)}
          entityId={entityId}
        />
      </Stack>
    );
  };

  const MemoizedApprovalFlowComponent = useMemo(ApprovalFlowComponent, [
    entityId,
  ]);

  return (
    <>
      {customButton ? (
        <div onClick={() => openPanel()}>{customButton}</div>
      ) : (
        <PrimaryButton text="Approval Flow" onClick={() => openPanel()} />
      )}
      <Modal
        isOpen={isOpen}
        scrollableContentClassName={styles.noScrollContainer}
        containerClassName={styles.modalContainer}
        onDismiss={() => dismissPanel()}
      >
        <Stack className={styles.container}>
          <Stack className={styles.headerContainer} tokens={{ padding: 25 }}>
            {approvalType && (
              <Stack
                horizontalAlign="space-between"
                horizontal
                className={styles.alignItemsCenter}
                tokens={{ childrenGap: 10 }}
              >
                <Stack
                  className={styles.alignItemsCenter}
                  horizontal
                  tokens={{ childrenGap: 10 }}
                >
                  <Text variant={'xLarge'}>{HEADER_TITLE}</Text>
                  <Text
                    variant={'xLarge'}
                    className={styles.approvalTypeTextStyles}
                  >
                    {approvalType}
                  </Text>
                </Stack>
                <CloseButton onClick={() => dismissPanel()} />
              </Stack>
            )}
          </Stack>
          {MemoizedApprovalFlowComponent}
        </Stack>
      </Modal>
    </>
  );
};
