import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  footer:{
    backgroundColor: theme.palette.neutralLighterAlt,
    padding: "10px 0px",
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    width: "100%"
  },
  buttonContainer:{
    padding: "10px 20px 10px",
  },
  disabledButton: {
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
}));