import { IconButton, Stack } from '@fluentui/react';
import Decimal from 'decimal.js';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { GetEntityDocument } from '../../__generated__/GetEntityDocument';
import { AccountingViewRowValues, EntityDocumentValues } from '../../types';
import { ACCOUNTING_INITIAL_VALUES } from '../constant';
import { ColumnAmountBottom } from './ColumnAmountBottom';
import { ColumnsHeader } from './ColumnHeader';
import FormField from './FormField';
import { getColumns } from './columns.data';
import { useStyles } from './index.styles';

interface PaymentAccountingViewProps {
  documentIsUpdatable: boolean;
  documentData: GetEntityDocument;
}

export const PaymentAccountingView: React.FC<PaymentAccountingViewProps> = ({
  documentData,
  documentIsUpdatable
}) => {
  const styles = useStyles();
  const isDeleting = useRef<boolean>(false);
  const { control } = useFormContext<EntityDocumentValues>();
  const { fields, remove, append } = useFieldArray({
    name: 'invoiceDistributions', // unique name for your Field Array
  });

  const watchInvoiceDistributions = useWatch({

    control,
    name: 'invoiceDistributions',
  });
  const { _entryLayout } = { ...documentData.entityDocument }
  const { columnArray } = getColumns(_entryLayout);

  const total = watchInvoiceDistributions?.reduce((
    sum: Decimal = new Decimal(0),
    current: AccountingViewRowValues
  ) => {
    const distributionAmount = current.distributionAmount || 0;
    try {
      return new Decimal(distributionAmount || 0).plus(sum);
    } catch (error) {
      return sum
    }
  }, new Decimal(0));

  const lastItem = watchInvoiceDistributions?.[fields.length - 1];
  const isValueExist = lastItem
    ? Object.entries(lastItem!).reduce((prev, current) => {
      const isValue = current?.[1] !== null;
      return isValue || prev;
    }, false)
    : true;

  useEffect(() => {
    if (isValueExist && documentIsUpdatable && !isDeleting.current) {
      append(ACCOUNTING_INITIAL_VALUES, { shouldFocus: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, isValueExist]);

  return (
    <Stack>
      <Stack className={styles.scrollContainer}>
        <Stack tokens={{ padding: '20px 0px 40px' }}>
          <Stack horizontal style={{ overflowX: 'clip' }}>
            <Stack tokens={{ padding: '52px 0px' }}>
              {fields.map((_, index) => {
                const isLastItem =
                  fields.length - 1 !== index && documentIsUpdatable;
                return (
                  <Stack key={index} style={{ height: 33, width: 40 }}>
                    {isLastItem && (
                      <>
                        <IconButton
                          disabled={!documentIsUpdatable}
                          iconProps={{ iconName: 'Blocked2Solid' }}
                          ariaLabel="delete"
                          style={{ color: 'red' }}
                          onClick={() => {
                            isDeleting.current = true;
                            remove(index);
                            setTimeout(() => {
                              isDeleting.current = false;
                            }, 1000);
                          }}
                        />
                      </>
                    )}
                  </Stack>
                );
              })}
            </Stack>
            <Stack style={{ padding: '0px 0px 40px 0px' }}>
              <ColumnsHeader columnArray={columnArray} />
              {fields.map((field, index) => {
                const baseField = `invoiceDistributions.${index}`;
                const isLastItem = fields.length - 1 === index;
                return (
                  <Stack key={index} horizontal tokens={{ childrenGap: 10 }}>
                    {columnArray.map((value, key) => {
                      return (
                        <FormField
                          baseField={baseField}
                          columnData={value}
                          disable={!documentIsUpdatable}
                          isDeletable={
                            isLastItem
                              ? false
                              : watchInvoiceDistributions?.[index]._isDeletable!
                          }
                          key={field.id + key}
                          index={index}
                        />
                      );
                    })}
                  </Stack>
                );
              })}
              {fields.length > 1 && (
                <ColumnAmountBottom
                  totalSum={total!}
                  columnArray={columnArray}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
