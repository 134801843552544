import { ColumnActionsMode } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const getColumns = () => {
  const columns: ColumnData[] = [
    {
      key: 'lookupAccount',
      name: 'Account #',
      fieldName: 'lookupAccount',
      minWidth: 140,
      maxWidth: 140,
      ...commonColumnProps,
    },
    {
      key: 'lookupName',
      name: 'Name',
      fieldName: 'lookupName',
      minWidth: 300,
      maxWidth: 300,
      ...commonColumnProps,
    },
    {
      key: 'setAccount',
      name: 'Set',
      fieldName: 'setAccount',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    // {
    //   key: 'projectAccount',
    //   name: 'Project',
    //   fieldName: 'projectAccount',
    //   minWidth: 150,
    //   maxWidth: 150,
    //   ...commonColumnProps,
    // },
    {
      key: 'department',
      name: 'Department',
      fieldName: 'department',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'travelAccount',
      name: 'Travel Section',
      fieldName: 'travelAccount',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'corporateAccountName',
      name: 'Corporate Account',
      fieldName: 'corporateAccountName',
      minWidth: 140,
      maxWidth: 140,
      ...commonColumnProps,
    },
    {
      key: 'action',
      name: '',
      fieldName: 'action',
      minWidth: 50,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
      isResizable: false,
    },
  ];
  return columns;
};
