import { IconButton, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { ChartOfAccountsModal } from '../CreateChartOfAccounts/ChartOfAccountsModal';

export const EditChartAccounts: React.FC<{
  chartOfAccountId: string | null;
}> = ({ chartOfAccountId }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isEdit = chartOfAccountId !== null ? true : false;

  if (!chartOfAccountId) return null;

  return (
    <>
      <TooltipHost content="Edit Chart of Accounts">
        <IconButton
          onClick={() => setIsOpen(true)}
          iconProps={{ iconName: 'Edit' }}
        />
      </TooltipHost>
      <ChartOfAccountsModal
        chartOfAccountId={chartOfAccountId}
        visible={isOpen}
        isEdit={isEdit}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
