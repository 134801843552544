import { DayOfWeek, Stack } from '@fluentui/react';
import { AmountTextField, FormikCheckBox } from 'common/components';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import React from 'react';
import { useStyles } from './index.styles';
interface YearBasicFormProps {
  isNew: boolean
}
export const YearBasicForm: React.FC<YearBasicFormProps> = ({
  isNew
}) => {
  const styles = useStyles();
  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20
        }}
        className={styles.formRow}
      >
        <Stack.Item
          className={styles.fieldContainer}
        >
          <FormikDatePicker
            name="startDate"
            placeholder="Select date"
            label="Start Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            showWeekNumbers
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            isRequired
            disabled={!isNew}
          />
        </Stack.Item>
        <Stack.Item
          className={styles.fieldContainer}
        >
          <FormikDatePicker
            name="endDate"
            placeholder="Select date"
            label="End Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            showWeekNumbers
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            isRequired
            disabled={!isNew}
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20
        }}
        className={styles.formRow}
      >
        <Stack className={styles.width50}>
          <AmountTextField
            name="fiscalYear"
            label="Year"
            format={"####"}
            decimalScale={0}
            allowNegative={false}
            required
            disabled={!isNew}
          />
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 20 }}
          className={styles.width50}
        >
          <FormikCheckBox
            name="isYearOpen"
            label="Year Open"
          />
          <FormikCheckBox
            name="isEntryAllowed"
            label="Entry Allowed"
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
