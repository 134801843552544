import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  iconButtonColor:{
    color: theme.palette.neutralDark
  },
  iconButtonContainer:{
    padding: "5px 0px"  
  }
}));