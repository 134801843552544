import { useApolloClient, useMutation } from '@apollo/client';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { PurchaseOrderRevisionUpdateInput } from 'common/types/globalTypes';
import { GetEntityDocument_entityDocument } from 'documents/documentAssignment/folder/view/__generated__/GetEntityDocument';
import { loader } from 'graphql.macro';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { PurchaseOrderRevisionUpdate, PurchaseOrderRevisionUpdateVariables } from './__generated__/PurchaseOrderRevisionUpdate';
const PURCHASE_ORDER_REVISION_UPDATE = loader('./PurchaseOrderRevisionUpdate.graphql')

const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to revise the purchase order?';

interface ConfirmPORevisionProps {
  hidden: boolean;
  entityDocument: GetEntityDocument_entityDocument;
  destinationEntityDocumentId: string;
  onToggle: () => void;
}

export const ConfirmPORevision: React.FC<ConfirmPORevisionProps> = ({
  hidden,
  entityDocument,
  destinationEntityDocumentId,
  onToggle,
}) => {
  const client = useApolloClient();
  const { addToast } = useToasts();
  const history = useHistory();
  const [
    purchaseOrderRevisionUpdate, { loading },
  ] = useMutation<
    PurchaseOrderRevisionUpdate,
    PurchaseOrderRevisionUpdateVariables
  >(PURCHASE_ORDER_REVISION_UPDATE, {
    onCompleted: (data) => {
      const destinationEntityDocumentId = data.purchaseOrderRevisionUpdate?.entityDocument?.id;
      if (!!destinationEntityDocumentId) {
        client.cache.modify({
          id: `DocumentPool:${entityDocument?._documentPoolId}`,
          fields: {
            _documentFileCount: (existingData) => {
              return {
                ...existingData,
                availableDocuments:
                  existingData.availableDocuments - 1,
              };
            },
          },
        });
        history.replace(`/doc/documentAssignment/document/${destinationEntityDocumentId}/${_documentPoolId}`)
        addToast('Purchase revised successfully.', {
          appearance: 'success',
        });
      }
    },
    errorPolicy: 'all'
  });

  const { id, _documentPoolId } = { ...entityDocument }

  return (
    <>
      <ConfirmDialog
        hidden={hidden}
        successBtnDisable={loading}
        title={CONFIRM_REQUEST_DIALOG_TITLE}
        onDismiss={onToggle}
        minWidth={450}
        onConfirm={async () => {
          const inputVariables: PurchaseOrderRevisionUpdateInput = {
            sourceEntityDocumentId: id,
            destinationEntityDocumentId,
          };

          const { errors } = await purchaseOrderRevisionUpdate({
            variables: {
              input: inputVariables,
            },
            update(cache, data) {
              if (!data.errors?.length) {
                const identity = cache.identify({ ...entityDocument });
                cache.evict({ id: identity });
                cache.gc();
              }
            },
          });
          if (errors?.length) {
            onToggle()
            addToast(errors[0].message, {
              appearance: 'error',
            });
          }
        }}
      />
    </>
  )
}
