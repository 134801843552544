import {
  Icon,
  ITooltipProps,
  Stack,
  Text,
  TooltipDelay,
  TooltipHost
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { DocumentCallout } from 'common/components/DocumentList/DocumentCallout';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import React from 'react';
import { useStyles } from './index.styles';
import { DocumentDataCalloutType } from './types';

interface documentDataCalloutProps {
  disabled?: boolean;
  item: DocumentDataCalloutType;
}

export const DocumentDataCallout: React.FC<documentDataCalloutProps> = ({
  disabled = false,
  item,
}) => {
  const tooltipId = useId(`callOutId${item.id}`);
  const styles = useStyles();
  const isAccountingDetailsExists =
    item.documentFileDistributionsByDocumentFileId?.nodes?.length! > 0;
  const tooltipProps: ITooltipProps = {
    styles: {
      root: {
        padding: 0,
      },
    },
    maxWidth: isAccountingDetailsExists ? '500' : '300',
  };

  return (
    <TooltipHost
      tooltipProps={tooltipProps}
      delay={TooltipDelay.long}
      id={tooltipId}
      content={<DocumentCallout calloutId={tooltipId} documentData={item} />}
      closeDelay={250}
    >
      <Stack
        id={tooltipId}
        tokens={{ childrenGap: 10 }}
        horizontal
        verticalAlign="center"
      >
        <Icon
          className={fileTypeColor(item.iconType || 'OTHER')}
          iconName={fileType(item.iconType || 'OTHER')}
        />
        <Text className={disabled ? styles.disabledStyle : ''}>
          {item.fileReference}
        </Text>
      </Stack>
    </TooltipHost>
  );
};
