import { DayOfWeek, Stack } from '@fluentui/react'
import { FormHookAmount, FormHookDatePicker, FormHookTextField } from 'common/components/FormHooksFields'
import React from 'react';
import { useStyles } from './index.styles'

interface BasicFormProps {
  disableInputs: boolean;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  disableInputs
}) => {

  const styles = useStyles();

  return (
    <Stack
      tokens={{
        padding: "10px 20px",
        childrenGap: 10,
      }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 20 }}
      >
        <Stack
          className={styles.sequenceContainer}
        >
          <FormHookAmount
            name="invoiceSequence"
            label="Sequence"
            placeholder="Sequence"
            fixedDecimalScale
            decimalScale={0}
            allowNegative={true}
            maxLength={2}
            thousandSeparator={false}
            disabled={disableInputs}
          />
        </Stack>
        <Stack
          className={styles.dateContainer}
        >
          <FormHookDatePicker
            name="scheduledDate"
            placeholder="Select a date"
            label="Scheduled Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            disabled={disableInputs}
          />
        </Stack>
        <Stack
          className={styles.noteContainer}
        >
          <FormHookTextField
            name="scheduledNote"
            label="Note"
            placeholder="Note"
            disabled={disableInputs}
          />
        </Stack>
        <Stack
          className={styles.amountContainer}
        >
          <FormHookAmount
            name="scheduledAmount"
            label="Scheduled Amount"
            placeholder="Scheduled Amount"
            fixedDecimalScale
            decimalScale={2}
            allowNegative={true}
            disabled={disableInputs}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
