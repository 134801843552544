import { ColumnActionsMode } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
};

export const columns: ColumnData[] = [
  {
    key: 'name',
    name: 'Category',
    fieldName: 'name',
    minWidth: 200,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'metrics',
    name: 'Metrics',
    fieldName: 'metrics',
    minWidth: 150,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'tagCategoryItemsByTagCategoryId',
    name: 'Tags',
    fieldName: 'tags',
    minWidth: 500,
    maxWidth: 800,
    ...commonColumnProps,
  },
  {
    key: 'action',
    name: 'Action',
    fieldName: 'action',
    minWidth: 100,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
    isResizable: false,
  },
];
