import React, { useEffect, useState } from 'react'
import { useStyles } from './index.styles';
import { ActionButton, Callout, Checkbox, DefaultButton, DirectionalHint, PrimaryButton, Stack, Text } from '@fluentui/react';
import { TextSearchFilter } from 'common/components/Filters/TextSearchFilter';
import { FilterArrayType } from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { AttachDocumentFiltersProps, filterOptionProps } from '..';

type FiltersCalloutProps = Pick<AttachDocumentFiltersProps, 'onFilterChange'> & {
  onDismiss?: () => void;
  calloutId: string;
  filterOptions: filterOptionProps;
};

export const FiltersCallout: React.FC<FiltersCalloutProps> = ({
  calloutId,
  filterOptions,
  onDismiss,
  onFilterChange,
}) => {
  const styles = useStyles();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const [indexName, setIndexName] = useState<string | undefined>();
  const [indexReferenceNumber, setIndexReferenceNumber] = useState<string | undefined>();
  const [indexDescription, setIndexDescription] = useState<string | undefined>();
  const [startsWith, setStartsWith] = useState<boolean | undefined>(true);

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    newFilters = newFilters.filter((element) => element.value);
    setFilterTypes(newFilters);
  };

  const onReset = () => {
    setIndexDescription(undefined);
    setIndexReferenceNumber(undefined);
    setIndexName(undefined);
    setStartsWith(true);
    setFilterTypes([]);
  };

  const onFilterApply = () => {
    onFilterChange({ filterTypes, startsWith: startsWith! });
    onDismiss?.();
  };

  const { filterTypes: filters, startsWith: startsWithDefault } = {
    ...filterOptions,
  };

  useEffect(() => {
    if (filters) {
      setFilterTypes(filters);
      setStartsWith(startsWithDefault);
      const indexNamePos = filters.findIndex(
        (item) => item.filterKey === 'indexName'
      );
      const indexReferenceNumberPos = filters.findIndex(
        (item) => item.filterKey === 'indexReferenceNumber'
      );
      const setIndexDescriptionPos = filters.findIndex(
        (item) => item.filterKey === 'indexDescription'
      );
      if (indexNamePos !== -1)
        setIndexName(filters[indexNamePos].value.toString());
      if (indexReferenceNumberPos !== -1)
        setIndexReferenceNumber(filters[indexReferenceNumberPos].value.toString());
      if (setIndexDescriptionPos !== -1)
        setIndexDescription(filters[setIndexDescriptionPos].value.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Callout
      className={styles.callout}
      gapSpace={0}
      target={`#${calloutId}`}
      directionalHint={DirectionalHint.topLeftEdge}
      onDismiss={onDismiss}
      setInitialFocus
    >
      <Stack className={styles.container}>
        <Text variant={'xLarge'}>Filters</Text>
        <Stack tokens={{ childrenGap: 10, padding: '20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Name"
                defaultFilter
                value={indexName}
                filterKey={'indexName'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setIndexName(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="PO#"
                defaultFilter
                value={indexReferenceNumber}
                filterKey={'indexReferenceNumber'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setIndexReferenceNumber(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack>
            <TextSearchFilter
              placeholder="Description"
              defaultFilter
              value={indexDescription}
              filterKey={'indexDescription'}
              onOptionChange={(value) => {
                onOptionChange(value);
                setIndexDescription(value?.value.toString());
              }}
            />
          </Stack>
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center">
              <ActionButton
                text="Reset"
                onClick={onReset}
                iconProps={{ iconName: 'RevToggleKey' }}
              />

              <Checkbox
                label="Starts with"
                checked={startsWith}
                onChange={(_, value) => {
                  setStartsWith(value);
                }}
              />
            </Stack>
            <Stack tokens={{ padding: '10px 0px', childrenGap: 20 }} horizontal>
              <PrimaryButton text="Apply" onClick={onFilterApply} />
              <DefaultButton text="Cancel" onClick={onDismiss} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  )
}
