import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  infoViewWrapper: {
    minHeight: 80,
  },
  reimbursedAmountContainer:{
    padding: 24,
  },
  width25:{
    width:"25%",
  }
}));
