import { IRatingStyles } from "@fluentui/react";

export const RatingStyles: Partial<IRatingStyles> = {
  ratingButton: {
    selectors: {
      ':hover:enabled .ms-RatingStar-front': {
        color: '#f57c00',
      },
      ':hover:enabled .ms-RatingStar-back': {
        color: '#ffa726',
      },
    },
  },
  ratingStarBack: {
    color: '#FFD0A2',
  },
  ratingStarFront: {
    color: '#ffa726',
  },
};