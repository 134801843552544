import {
  Calendar,
  Callout,
  DefaultButton,
  DirectionalHint,
  ICalendarDayProps,
  Label,
  Separator,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { Badge } from 'common/components/Badge';
import { CloseButton } from 'common/components/Buttons';
import { Chip } from 'common/components/Chip';
import { useCommonStyles } from 'common/styles';
import {
  dateConvertions,
  dateFormat,
  onFormatDate,
} from 'common/utils/dateFormats';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { PurchaseOrderItemFormProps } from '../interface';
import { useStyles } from './index.styles';

const calendarDayProps: Partial<ICalendarDayProps> = {
  styles: {
    dayButton: {
      color: '#323130 !important',
    },
    dayOutsideBounds: {
      backgroundColor: '#C6DEF2',
      color: 'black',
      fontWeight: 'bold',
    },
  },
};

interface CustomCalendarProps {
  label?: string;
}

export const CustomCalendar: React.FC<CustomCalendarProps> = ({ label }) => {
  const { setValue, control } = useFormContext<PurchaseOrderItemFormProps>();
  const watchItemRentalDates = useWatch({ name: 'itemRentalDates', control });
  const styles = useStyles();
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [restrictedDates, setRestrictedDates] = useState<Date[]>([]);

  const onSelectDate = useCallback(
    (date: Date, _selectedDateRangeArray?: Date[]): void => {
      setSelectedDate(date);
      setRestrictedDates((prevState) => [...prevState, date]);
      const dates = [...restrictedDates, date].map((item) =>
        onFormatDate(item)
      );
      setValue('itemRentalDates', dates, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [restrictedDates]
  );

  useEffect(() => {
    const dates = watchItemRentalDates?.map(
      (item) => new Date(dateFormat(dateConvertions(item!)))
    );
    setRestrictedDates(dates || []);
    if (dates) {
      setSelectedDate(dates[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commonStyles = useCommonStyles();
  const calloutId = useId(`balanceAmount`);
  return (
    <Stack>
      {label && <Label>{label}</Label>}

      {visible && (
        <Callout
          gapSpace={0}
          target={`#${calloutId}`}
          directionalHint={DirectionalHint.topCenter}
          onDismiss={() => setVisible(false)}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Calendar
              showMonthPickerAsOverlay
              highlightSelectedMonth
              showGoToToday={false}
              restrictedDates={restrictedDates}
              onSelectDate={onSelectDate!}
              // @ts-ignore
              // value={value ? new Date(value) : null}
              value={selectedDate}
              calendarDayProps={calendarDayProps}
            />
            {/* <DatePicker defaultValue={undefined}/> */}
            {!!restrictedDates.length && (
              <>
                <Separator vertical />

                <div className={styles.chipContainer}>
                  <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    style={{ marginBottom: 10 }}
                  >
                    <Text
                      variant="large"
                      className={clsx(
                        commonStyles.colorThemePrimary,
                        commonStyles.bold
                      )}
                    >
                      Selected Dates
                    </Text>
                    <CloseButton
                      onClick={() => {
                        setVisible(false);
                      }}
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 20 }}>
                    <Stack className={styles.wrapper}>
                      {restrictedDates?.map((item, index) => {
                        return (
                          <Stack
                            key={index}
                            style={{
                              marginRight: 10,
                              marginTop: 5,
                            }}
                          >
                            <Chip
                              onClick={() => {
                                let tempDates: Date[] = [...restrictedDates!];
                                tempDates.splice(tempDates.indexOf(item), 1);
                                setRestrictedDates(tempDates);
                                const dates = tempDates.map((item) =>
                                  onFormatDate(item)
                                );
                                setValue('itemRentalDates', dates, {
                                  shouldDirty: true,
                                });
                              }}
                              text={dateFormat(item.toISOString()).toString()}
                            />
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                </div>
              </>
            )}
          </Stack>
        </Callout>
      )}
      <TooltipHost
        content={
          !!restrictedDates.length
            ? `${restrictedDates.length} dates selected`
            : ''
        }
      >
        <Badge visible count={restrictedDates.length} primary>
          <DefaultButton
            id={calloutId}
            text="Select Dates"
            onClick={() => setVisible(() => true)}
          />
        </Badge>
      </TooltipHost>
    </Stack>
  );
};
