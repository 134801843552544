import { CommandBarButton, IDropdownOption, Stack } from '@fluentui/react';
import {
  FormHookCountryRegion,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BusinessUnitCommonData_countries } from '../../__generated__/BusinessUnitCommonData';
import { BusinessUnitValues } from '../../types';
import { useStyles } from './index.styles';
interface FormFieldsProps {
  baseField: string;
  countries: BusinessUnitCommonData_countries | null | undefined;
  toList: () => void;
  onRemove: (index: number) => void;
}

export const FormFields: React.FC<FormFieldsProps> = ({
  baseField,
  countries,
  toList,
  onRemove,
}) => {
  const styles = useStyles();
  const { control } = useFormContext<BusinessUnitValues>();
  const addressWatch = useWatch({
    control,
    name: 'companyAddressesByCompanyIdAndEntityId',
  });

  const { nodes } = { ...countries };

  const countryOptions: IDropdownOption[] =
    nodes?.map((ele) => ({
      key: ele.id,
      text: ele.country,
    })) || [];

  return (
    <Stack>
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
        <CommandBarButton
          text="Address List"
          iconProps={{ iconName: 'Back' }}
          className={styles.addressListBtn}
          onClick={() => {
            toList();
            if (addressWatch && addressWatch[0].addressLine1 === null) {
              onRemove(0);
            }
          }}
        />
      </Stack>
      <Stack className={styles.fieldsContainer} tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item grow>
            <FormHookTextField
              name={`${baseField}.addressLine1`}
              label="Street"
              placeholder="Street and Number, P.O. Box"
            />
          </Stack.Item>
          <Stack.Item grow>
            <FormHookTextField
              name={`${baseField}.addressLine2`}
              label="Apartment"
              placeholder="Apartment, Suite, Unit, Building, Floor"
            />
          </Stack.Item>
          <Stack.Item grow>
            <FormHookTextField
              name={`${baseField}.cityName`}
              label="City"
              placeholder="City, Town"
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item grow={2}>
            <FormHookCountryRegion
              countries={countryOptions}
              countryName={`${baseField}.countryId`}
              regionName={`${baseField}.stateRegionId`}
              CountryAutocompleteProps={{
                label: 'Country',
                errorMessage: '',
              }}
              StateRegionAutocompleteProps={{
                label: 'State / Region / Province',
                errorMessage: '',
              }}
              isCountryClearable
              isStateRegionClearable
            />
          </Stack.Item>
          <Stack.Item grow={1}>
            <FormHookTextField
              name={`${baseField}.postalCode`}
              label="Zip/Postal Code"
              placeholder="Zip/Postal Code"
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 20 }}>
          <Stack.Item className={styles.phone}>
            <FormHookTextField
              name={`${baseField}.mainContact`}
              label="Contact"
              placeholder="Contact"
            />
          </Stack.Item>
          <Stack.Item className={styles.phone}>
            <FormHookTextField
              name={`${baseField}.telephoneNumber`}
              label="Phone"
              placeholder="Phone"
            />
          </Stack.Item>
          <Stack.Item className={styles.email}>
            <FormHookTextField
              name={`${baseField}.emailAddress`}
              label="Email"
              placeholder="Email"
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  );
};
