import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  headerStyles: {
    alignItems: 'self-start',
    // height: 80,
    top: 0,
    position: 'absolute',
    zIndex: 999,
    width: '100%',
    padding: 25,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  container: {
    width: 600,
    overflow: 'hidden',
  },
  footer: {
    width: '100%',
    padding: 25,
    position: 'absolute',
    bottom: 0,
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  checkBoxContainer: {
    marginTop: 30,
    alignItems: 'center',
  },
}));
