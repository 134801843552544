import React from 'react'
import { getColumns } from './utils';
import { useReactiveVar } from '@apollo/client';
import { setUserDefaults } from 'utility/cache/ui';
import { Header } from './Header';
import { Stack } from '@fluentui/react';
import { AccountingViewFields } from 'common/components/AccountingView/__generated__/AccountingViewFields';
import { AccountingRow } from './AccountingRow';
import { Footer } from './Footer';

interface AccountingRowsViewProps {
  invoiceDetails: AccountingViewFields;
}

export const AccountingRowsView: React.FC<AccountingRowsViewProps> = ({
  invoiceDetails
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const { columnArray } = getColumns(userDefaults);
  const { invoiceDistributions, } = { ...invoiceDetails };
  const widthTotal =
    columnArray.reduce((prev: any, curr: any) => curr.maxWidth! + prev, 0) +
    columnArray.length * 10;

  return (
    <Stack
      tokens={{
        padding: "20px 25px"
      }}
      style={{
        width: '100%',
        overflowX: 'scroll',
        marginTop: 20,
      }}
    >
      <Stack
        style={{
          width: widthTotal
        }}
      >
        <Header columnArray={columnArray} />
        {
          invoiceDistributions.nodes.map((row) => (
            <AccountingRow
              key={row.id}
              data={row}
              columns={columnArray}
            />
          ))
        }
        <Footer
          columnArray={columnArray}
          aggregates={invoiceDistributions.aggregates}
        />
      </Stack>
    </Stack>
  )
}
