import { useQuery } from '@apollo/client';
import {
  ActionButton,
  Callout,
  Checkbox,
  DefaultButton,
  DirectionalHint,
  PrimaryButton,
  Stack,
  Text
} from '@fluentui/react';
import {
  DropdownFilter,
  FilterArrayType,
  filterCombineType,
} from 'common/components/Filters';
import { TextSearchFilter } from 'common/components/Filters/TextSearchFilter';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { ListFiltersProps } from '..';
import { FileCabinetDocumentTypes } from '../__generated__/FileCabinetDocumentTypes';
import { useStyles } from './index.styles';
const FILE_CABINET_FOLDER_DOCUMENT_TYPES = loader('../FileCabinetDocumentTypes.graphql');
type ListFilterCalloutProps = Omit<ListFiltersProps, 'documentPoolId'> & {
  onDismiss?: () => void;
  calloutId: string;
};
export const filterObject: FilterArrayType = {
  value: false,
  filterKey: '',
  label: '',
  combineType: filterCombineType.AND,
};
export const ListFilterCallout: React.FC<ListFilterCalloutProps> = ({
  onDismiss,
  calloutId,
  onFilterChange,
  filterOptions,
}) => {
  const { filterTypes: filters, startsWith: startsWithDefault } = {
    ...filterOptions,
  };
  const styles = useStyles();
  const [indexName, setIndexName] = useState<string | undefined>();
  const [documentTypeId, setDocumentTypeId] = useState<
    number | undefined | null
  >();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([
    filterObject,
  ]);

  const [comment, setComment] = useState<string | undefined>();
  const [indexRefNumber, setIndexRefNumber] = useState<string | undefined>();
  const [fileReference, setFileReference] = useState<string | undefined>();
  const [contents, setContents] = useState<string | undefined>();
  const [startsWith, setStartsWith] = useState<boolean | undefined>();

  const onFilterApply = () => {
    onFilterChange({ filterTypes, startsWith: startsWith! });
    onDismiss?.();
  };

  const onCancel = () => {
    onDismiss?.();
  };

  useEffect(() => {
    if (filters) {
      setFilterTypes(filters);
      setStartsWith(startsWithDefault);
      const indexNamePos = filters.findIndex(
        (item) => item.filterKey === 'indexName'
      );
      const documentTypeIdPos = filters.findIndex(
        (item) => item.filterKey === 'documentTypeId'
      );
      const commentPos = filters.findIndex(
        (item) => item.filterKey === 'comment'
      );
      const indexRefNumberPos = filters.findIndex(
        (item) => item.filterKey === 'indexReferenceNumber'
      );
      const fileReferencePos = filters.findIndex(
        (item) => item.filterKey === 'fileReference'
      );
      const contentsPos = filters.findIndex(
        (item) => item.filterKey === '_documentContents'
      );
      if (fileReferencePos !== -1)
        setFileReference(filters[fileReferencePos].value.toString());
      if (contentsPos !== -1)
        setContents(filters[contentsPos].value.toString());
      if (indexRefNumberPos !== -1)
        setIndexRefNumber(filters[indexRefNumberPos].value.toString());
      if (commentPos !== -1) setComment(filters[commentPos].value.toString());
      if (indexNamePos !== -1)
        setIndexName(filters[indexNamePos].value.toString());
      if (documentTypeIdPos !== -1)
        setDocumentTypeId(
          parseInt(filters[documentTypeIdPos].value.toString())
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { data } = useQuery<FileCabinetDocumentTypes>(FILE_CABINET_FOLDER_DOCUMENT_TYPES, {
    variables: {
      isDocumentUpload: true,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const documentTypeOptions = formatDropdownOptions(
    data?.fileCabinetDocumentTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.documentType!,
      includeAll: false,
    }
  );
  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    newFilters = newFilters.filter(
      (element) =>
        element.value ||
        (element.value === false)
    );
    setFilterTypes(newFilters);
  };
  const onReset = () => {
    setComment(undefined);
    setContents(undefined);
    setFileReference(undefined);
    setIndexRefNumber(undefined);
    setDocumentTypeId(null);
    setIndexName(undefined);
    setStartsWith(true);
    setFilterTypes([]);
  };
  return (
    <Callout
      className={styles.callout}
      gapSpace={0}
      target={`#${calloutId}`}
      directionalHint={DirectionalHint.leftTopEdge}
      onDismiss={onDismiss}
      setInitialFocus
    >
      <Stack className={styles.container}>
        <Text variant={'xLarge'}>Filters</Text>
        <Stack tokens={{ childrenGap: 10, padding: '20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item style={{ width: 245 }}>
              <DropdownFilter
                controlled
                placeholder={'Type'}
                options={documentTypeOptions}
                filterKey={'documentTypeId'}
                defaultFilter
                selectedKey={documentTypeId}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setDocumentTypeId(parseInt(value.value.toString()));
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Name"
                defaultFilter
                value={indexName}
                filterKey={'indexName'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setIndexName(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Number"
                defaultFilter
                value={indexRefNumber}
                filterKey={'indexReferenceNumber'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setIndexRefNumber(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Contents"
                defaultFilter
                value={contents}
                filterKey={'_documentContents'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setContents(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Comment"
                defaultFilter
                value={comment}
                filterKey={'comment'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setComment(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Document"
                defaultFilter
                value={fileReference}
                filterKey={'fileReference'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setFileReference(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center">
            <ActionButton
              text="Reset"
              onClick={onReset}
              iconProps={{ iconName: 'RevToggleKey' }}
            />
            <Checkbox
              label="Starts with"
              checked={startsWith}
              onChange={(_, value) => {
                setStartsWith(value);
              }}
            />
          </Stack>
          <Stack tokens={{ padding: '10px 0px', childrenGap: 20 }} horizontal>
            <PrimaryButton text="Apply" onClick={onFilterApply} />
            <DefaultButton text="Cancel" onClick={onCancel} />
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
