import {
  Icon,
  Link,
  Stack,
  Text,
  AnimationClassNames,
  TooltipHost,
  DirectionalHint,
  IconButton,
} from '@fluentui/react';
import clsx from 'clsx';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { DocumentViewModalState } from 'common/components/DocumentList';

import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import React, { useEffect, useState } from 'react';
import {
  GetPayCycleDetails,
  GetPayCycleDetails_payCycle_payments_nodes,
} from '../../__generated__/GetPayCycleDetails';
import { DeletePayment } from '../DeletePayment';
import { PaymentTransactions, PaymentTransactionsProps } from '../Transactions';
import { useStyles } from './index.styles';

interface PaymentDataItemProps extends PaymentTransactionsProps {
  isSelected: boolean;
  onItemClick?: () => void;
  payCycle: GetPayCycleDetails | undefined;
  payments: GetPayCycleDetails_payCycle_payments_nodes;
  onFileView?: (value: DocumentViewModalState) => void;
}
export const PaymentDataItem: React.FC<PaymentDataItemProps> = ({
  isSelected,
  onItemClick,
  onTransactionClick,
  selectedTransaction,
  payments,
  onFileView,
  payCycle,
}) => {
  const fileItem = { ...payments.entityDocumentsByEntityId.nodes[0] };
  const styles = useStyles();
  const SlideLeftIn = AnimationClassNames.slideLeftIn400;
  const SlideRightIn = AnimationClassNames.slideLeftOut400;
  const tootTipMessage = 'Approved Payment Request';

  const viewDocumentVisible =
    fileItem?._isProtected! || fileItem?._fileViewer !== 'browser';

  const [difference, setDifference] = useState<Decimal | null>(new Decimal(0));

  useEffect(() => {
    if (payments) {
      if (payments._invoiceTotal) {
        const difference = new Decimal(payments.paidAmount || 0).minus(
          payments._invoiceTotal
        );
        setDifference(difference);
      } else {
        setDifference(new Decimal(0));
      }
    }
  }, [payments]);

  return (
    <>
      <TooltipHost
        content={payments._isTransactionPayment ? tootTipMessage : ''}
        directionalHint={DirectionalHint.leftCenter}
      >
        <Stack
          horizontal
          className={clsx(
            styles.root,
            payments._isTransactionPayment
              ? styles.isTransactionPaymentRow
              : isSelected
              ? styles.groupSelected
              : null,
            styles.linkLightHover
          )}
        >
          <Link onClick={onItemClick}>
            {payments.invoicePayments.nodes.length !== 0 ? (
              <CollapseButton isOpen={isSelected} />
            ) : (
              <Stack className={styles.hideCollapseButton} />
            )}
          </Link>
          <Stack
            verticalAlign="center"
            tokens={{ padding: '0px 40px 0px 40px' }}
            className={styles.fullWidthContainer}
            horizontal
          >
            <Stack className={styles.payToColumn}>
              <ActiveLink
                key={payments?.id}
                to={`/pay/payment_tracking/payment/${payments?.id}`}
              >
                {payments.vendorReference}
              </ActiveLink>
              {payments?.entityDocumentsByEntityId?.nodes.length === 0 &&
                payments._requiredApprovalDocuments && (
                  <Text variant="xSmall" className={styles.redColor}>
                    {`Document(s) Required: ${payments._requiredApprovalDocuments}`}
                  </Text>
                )}
            </Stack>

            <Stack className={styles.listCol1}>
              {payments?.entityDocumentsByEntityId?.nodes.length > 0 && (
                <TooltipHost
                  content={viewDocumentVisible ? '' : 'View'}
                  id="tooltipId"
                >
                  <IconButton
                    disabled={viewDocumentVisible}
                    iconProps={{ iconName: 'View' }}
                    onClick={() =>
                      onFileView?.({
                        isOpen: true,
                        _fileType:
                          payments?.entityDocumentsByEntityId?.nodes[0]
                            ?._fileType!,
                        title:
                          payments?.entityDocumentsByEntityId?.nodes[0]
                            ?.fileReference,
                        entityDocumentId:
                          payments?.entityDocumentsByEntityId?.nodes[0]?.id,
                      })
                    }
                  />
                </TooltipHost>
              )}
            </Stack>

            <Stack className={styles.listCol2}>
              <Text>
                {payments.paidDate
                  ? dateFormat(dateConvertions(payments.paidDate || ''))
                  : '-'}
              </Text>
            </Stack>

            <Stack className={styles.listCol3}>
              <Text>{payments.paymentReference}</Text>
            </Stack>

            <Stack className={styles.listCol5}>
              <AmountTextView value={payments.paidAmount} />
              {difference?.toNumber() ? (
                <Stack horizontal className={styles.differenceContainer}>
                  <Text
                    className={styles.differenceText}
                  >{`Difference: `}</Text>
                  <AmountTextView
                    className={styles.differenceText}
                    value={difference?.toString() || ''}
                  />
                </Stack>
              ) : null}
            </Stack>

            <DeletePayment
              _isUpdatable={payCycle?.payCycle?._isUpdatable}
              payment={payments}
            />
          </Stack>
        </Stack>
      </TooltipHost>
      <Stack className={isSelected ? SlideLeftIn : SlideRightIn}>
        {isSelected && (
          <PaymentTransactions
            selectedTransaction={selectedTransaction}
            onTransactionClick={onTransactionClick}
            selectedTransactionData={payments.invoicePayments}
          />
        )}
      </Stack>
    </>
  );
};

interface CollapseButtonProps {
  isOpen: boolean;
}

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  isOpen = false,
}) => {
  return (
    <Icon
      iconName={isOpen ? 'ChevronDown' : 'ChevronRight'}
      ariaLabel="ExpandButton"
    />
  );
};
