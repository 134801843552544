import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  hide: {
    visibility: 'hidden',
  },
  categoryType: {
    color: theme.palette.themePrimary,
  },
  typeContainer: { alignItems: 'center', margin: '10px 20px', width: 300 },

  callout: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));
