import React, { useCallback, useEffect, useState } from 'react';
import { IPersonaProps, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { UserDistributionLists } from '../__generated__/UserDistributionLists';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { groupBy } from 'lodash';
import { ContactsPicker } from '../ContactsPicker';
import { useStyles } from './index.styles';
import { useCommonStyles } from 'common/styles';
import { ContactGroupShimmerView } from '../ShimmerView/ShimmerViews';
const GET_USER_DISTRIBUTION_LIST = loader('../UserDistributionLists.graphql');

interface ContactMember {
  text: string | null;
  id: string | null;
  groupId: string | null;
}
export interface ContactsPayload {
  name: string | null;
  members: ContactMember[];
  groupId: string | null;
}
interface ContactGroup {
  headerName: string | null;
  payLoad: ContactsPayload[];
}

export interface availableGroups {
  department?: string[];
  communicationChannel?: string[];
  user?: string[];
}
interface ContactFormProps {
  onContactsUpdated?: (data: availableGroups) => void;
}
export const ContactForm: React.FC<ContactFormProps> = ({
  onContactsUpdated,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [contactIds, setContactIds] = useState<Map<string, IPersonaProps[]>>(
    new Map<string, IPersonaProps[]>()
  );
  const [departmentIds, setDepartmentIds] = useState<Set<string>>(
    new Set<string>()
  );
  const [channelIds, setChannelIds] = useState<Set<string>>(new Set<string>());
  const [contactGroupsData, setContactGroupsData] = useState<ContactGroup[]>(
    []
  );
  const { data, loading: userDistributionListLoading } =
    useQuery<UserDistributionLists>(GET_USER_DISTRIBUTION_LIST, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  useEffect(() => {
    const groupData = groupBy(
      data?.userDistributionLists?.nodes,
      function (item) {
        return item?.sequenceName;
      }
    );

    const processedGroupData = Object.keys(groupData).map((key) => {
      const subGroupData = groupBy(groupData[key], function (item) {
        return item?.groupName;
      });
      const groupedByData = Object.keys(subGroupData).map((keys) => {
        const subGroupMembers = subGroupData[keys]
          .filter((item) => !item.isGroupHeader)
          .map((item) => ({
            text: item.userName,
            id: item.userId,
            groupId: item.groupId,
          }));

        const { groupId } = { ...subGroupMembers[0] };
        return { name: keys, members: subGroupMembers, groupId };
      });

      return { headerName: key, payLoad: groupedByData };
    });

    setContactGroupsData(processedGroupData);
  }, [data]);

  const onDepartmentSelected = (
    selected: boolean,
    contactGroup: ContactsPayload
  ) => {
    if (selected) {
      onContactSelected([], contactGroup.groupId!);
      setDepartmentIds(new Set(departmentIds.add(contactGroup.groupId!)));
    } else {
      departmentIds.delete(contactGroup.groupId!);
      setDepartmentIds(new Set(departmentIds));
    }
  };

  const onChannelSelected = (
    selected: boolean,
    contactGroup: ContactsPayload
  ) => {
    if (selected) {
      onContactSelected([], contactGroup.groupId!);
      setChannelIds(new Set(channelIds.add(contactGroup.groupId!)));
    } else {
      channelIds.delete(contactGroup.groupId!);
      setChannelIds(new Set(channelIds));
    }
  };

  const onContactSelected = (
    selectedUsers: IPersonaProps[],
    groupId: string
  ) => {
    if (selectedUsers.length > 0) contactIds.set(groupId, selectedUsers);
    else contactIds.delete(groupId);
    onReceiversListedMemo(departmentIds, channelIds, contactIds);
    setContactIds(contactIds);
  };

  const onReceiversListedMemo = useCallback(
    (
      departments: Set<string>,
      channels: Set<string>,
      individuals: Map<string, IPersonaProps[]>
    ) => {
      let individualContacts: IPersonaProps[] = [];
      individuals.forEach((item) => {
        individualContacts = [...individualContacts, ...item];
      });
      const contacts = individualContacts.map((item) => item.id as string);
      onContactsUpdated?.({
        department: Array.from(departments),
        communicationChannel: Array.from(channels),
        user: contacts,
      });
    },
    [onContactsUpdated]
  );

  useEffect(() => {
    onReceiversListedMemo(departmentIds, channelIds, contactIds);
  }, [onReceiversListedMemo, departmentIds, channelIds, contactIds]);

  return (
    <Stack className={styles.root} tokens={{ childrenGap: 20 }}>
      {userDistributionListLoading ? (
        <ContactGroupShimmerView />
      ) : (
        <Stack
          tokens={{ childrenGap: 30 }}
          className={styles.contactsGroupWrapper}
        >
          {contactGroupsData.map((header, index) => {
            return (
              <Stack key={index} tokens={{ childrenGap: 15 }}>
                <Text
                  variant="large"
                  className={clsx(
                    commonStyles.semibold,
                    commonStyles.colorThemePrimary
                  )}
                >
                  {header.headerName}
                </Text>
                {header.payLoad.map((item, index) => (
                  <ContactsPicker
                    allSelectable={header.headerName !== 'Individuals'}
                    key={index}
                    group={item}
                    onContactSelected={(users) =>
                      onContactSelected(users, item.groupId!)
                    }
                    onAllSelected={(selected) => {
                      if (header.headerName === 'Departments')
                        onDepartmentSelected(selected, item);
                      else onChannelSelected(selected, item);
                    }}
                  />
                ))}
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
