import React from 'react';
import { IPivotItemProps, Stack, Text } from '@fluentui/react';
import { useStyles } from './index.styles';

export function PivotCustomRender(
  props?: IPivotItemProps,
  defaultRenderer?: (props?: IPivotItemProps) => JSX.Element | null
): JSX.Element | null {
  const styles = useStyles();
  if (!props || !defaultRenderer) {
    return null;
  }

  return (
    <Stack horizontal>
      {defaultRenderer({ ...props, itemCount: undefined })}
      {props.itemCount !== 0 && <div className={styles.width10} />}
      {props.itemCount !== 0 && (
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          className={styles.contentWrapper}
        >
          <Text variant="mediumPlus" className={styles.itemCount}>
            {props.itemCount}
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
