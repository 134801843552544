import {
  Callout,
  DirectionalHint,
  Separator,
  Stack,
  Text,
  makeStyles,
} from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import React from 'react';
import { Trip_trip_traveler } from '../../../../__generated__/Trip';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.neutralLighterAlt,
    width: 450,
  },
}));

interface AllowancesCalloutProps {
  isOpen: boolean;
  targetID: string;
  traveler: Trip_trip_traveler | null | undefined;
}

export const AllowancesCallout: React.FC<AllowancesCalloutProps> = ({
  isOpen,
  targetID,
  traveler,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  if (!isOpen) return null;
  const {
    isEconomyClassPreferred,
    isBusinessClassPreferred,
    isFirstClassPreferred,
    _travelPolicyAllowances,
    travelPolicy,
  } = { ...traveler };
  const travelPolicyAllowances =
    _travelPolicyAllowances?.nodes.filter(
      (item) => item._travelerPolicyAllowanceId
    ) || [];
  const fareClass = isEconomyClassPreferred
    ? 'Economy'
    : isBusinessClassPreferred
    ? 'Business'
    : isFirstClassPreferred
    ? 'First'
    : null;

  return (
    <Callout
      className={styles.container}
      target={targetID}
      directionalHint={DirectionalHint.bottomCenter}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ padding: '15px 15px 5px' }}
      >
        <Text variant="mediumPlus">Travel Policy</Text>

        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {travelPolicy?.description && (
            <Text
              variant="mediumPlus"
              className={clsx(
                commonStyles.colorThemePrimary,
                commonStyles.bold
              )}
            >
              {`${travelPolicy.description}`}
            </Text>
          )}
          <Text
            variant="mediumPlus"
            className={clsx(commonStyles.colorThemePrimary, commonStyles.bold)}
          >
            {travelPolicy?.companyCurrency?.isoCode}
          </Text>
          <Text
            variant="mediumPlus"
            className={clsx(commonStyles.colorThemePrimary, commonStyles.bold)}
          >
            {travelPolicy?.isContractual ? 'Contractual' : null}
          </Text>
        </Stack>
      </Stack>
      <Separator />
      <Stack
        horizontal
        tokens={{ padding: '0px 15px', childrenGap: 10 }}
        verticalAlign="center"
      >
        <Text variant="mediumPlus">Fare Class:</Text>
        <Text>{fareClass}</Text>
      </Stack>
      <Stack tokens={{ padding: '0px 0px 15px 0px' }}>
        {travelPolicyAllowances?.length > 0 && (
          <>
            <Separator />
            <Stack tokens={{ childrenGap: 15, padding: '0px 15px' }}>
              <Text variant="mediumPlus">Travel Allowances:</Text>
              <Stack tokens={{ childrenGap: 10 }}>
                {travelPolicyAllowances.map((item) => {
                  return (
                    <Stack
                      horizontal
                      key={item.id}
                      horizontalAlign="space-between"
                    >
                      <Stack.Item>
                        <Text>{item._allowanceDescription}</Text>
                      </Stack.Item>
                      <Stack.Item>
                        <AmountTextView value={item.allowanceOverrideAmount} />
                      </Stack.Item>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Callout>
  );
};
