import { CarbonAccountingValues, EnvironmentalItemsRowValues } from './types';

export const ENVIRONMENTAL_ITEMS_VALUES: EnvironmentalItemsRowValues = {
  environmentalImpactTypeId: 0,
  feet: null,
  gallons: null,
  hours: null,
  kilogram: null,
  kilometers: null,
  kilowattHours: null,
  litres: null,
  meters: null,
  metricTonnes: null,
  miles: null,
  omissionsEquivalent: null,
  pounds: null,
  quantity: null,
  therms: null,
  tons: null,
  yards: null,
  id: null,
  carbonCreditsAmount: null,
  entityEnvironmentalId: null,
  isIntegerFormat: null,
  isFloatFormat: null,
  isAmountFormat: null,
};

export const CARBONACCOUNTING_INITIAL_VALUES: CarbonAccountingValues = {
  environmentalMetricId: null,
  startDate: null,
  endDate: null,
  description: null,
  entityEnvironmentalItems: [],
};

export const ResourceType = [
  { ENV_FUEL: ['gallons'] },
  { ENV_VEHICLES: ['quantity'] },
  { ENV_TRAVEL: ['quantity', 'miles'] },
  { ENV_UNITS: ['quantity'] },
  { ENV_TONNES: ['tons'] },
  { ENV_THERMS: ['therms'] },
  { ENV_KWH: ['kilowattHours'] },
];
