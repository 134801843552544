import { makeStyles, Stack, Text } from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import React from 'react';
interface DataViewProps {
  title?: string;
  description?: string | null;
  isAmount?: boolean;
  currency?: string;
}
const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginLeft: 14,
    padding: 10,
    flex: 1,
  },
  description: {
    flex: 1,
    marginRight: 24,
    marginLeft: 24,
    paddingBottom: 8,
  },
  container: {
    height: 60,
    width: '100%',
  },
  currency: {
    marginRight: 6,
    marginLeft: 24,
    paddingBottom: 8,
    fontWeight: 'bold',
  },
}));
export const DataView: React.FC<DataViewProps> = ({
  title,
  isAmount,
  description,
  currency,
}) => {
  const styles = useStyles();
  return (
    <Stack className={styles.container}>
      <Text variant="medium" className={styles.title}>
        {title}
      </Text>
      {isAmount && description ? (
        <Stack horizontal>
          <Text className={styles.currency}>{currency || ''}</Text>
          <AmountTextView value={description} style={{ fontWeight: 'bold' }} />
        </Stack>
      ) : (
        <Text className={styles.description}>{description ?? '-'}</Text>
      )}
    </Stack>
  );
};
