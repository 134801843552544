import { TravelPolicyAllowancesValues } from './types';
import { TravelPolicyAllowancesRows } from './types';

export const TRAVEL_POLICY_ALLOWANCES_ROWS: TravelPolicyAllowancesRows = {
  id: null,
  _rowTimestamp: null,
  expenditureTypeId: null,
  policyAllowance: null,
  policyMiles: null,
  isAllowanceCustomizable: null,
  country1Id: null,
  country2Id: null,
  stateRegion1Id: null,
  stateRegion2Id: null,
};

export const TRAVEL_POLICY_ALLOWANCES_VALUES: TravelPolicyAllowancesValues = {
  id: null,
  _rowTimestamp: null,
  travelPolicyAllowancesRow: [TRAVEL_POLICY_ALLOWANCES_ROWS],
};
