import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
    container:{

    },
    departmentHeader:{
        marginBottom:10
    },
    channelHeader:{
        marginBottom:10
    },
    individualsContainer:{
        marginBottom:10
    }
}));
