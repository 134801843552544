import {
  ComboBox,
  IComboBoxOption,
  IComboBox,
  IComboBoxProps,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import {
  TripCreateFormValues,
  TripItemFormValues,
} from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/types';
import { useFormContext } from 'react-hook-form';

type FareClassesProps = IComboBoxProps & {
  tripIndex: number;
  selectedFareClass?: string | undefined;
  onClassChange?: (key: string) => void;
};

export const FareClasses: React.FC<FareClassesProps> = ({
  tripIndex,
  selectedFareClass,
  options,
  onClassChange,
  disabled,
}) => {
  const { setValue } = useFormContext<TripCreateFormValues>();
  const [selectedOption, setSelectedOption] = useState<
    string | number | undefined
  >(undefined);
  const handleOptionChange = (
    _event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    const departureClass = option?.key as keyof TripItemFormValues;
    if (departureClass) {
      const keysToModify = options.map(
        (ele) => ele.key! as keyof TripItemFormValues
      );
      keysToModify.forEach((ele) => {
        if (ele === departureClass) {
          setValue(`tripItems.${tripIndex}.${ele}`, true, {
            shouldDirty: true,
          });
        } else {
          setValue(`tripItems.${tripIndex}.${ele}`, false, {
            shouldDirty: true,
          });
        }
      });
      setSelectedOption(option?.key);
      if (option?.text) onClassChange?.(option?.text);
    }
  };

  useEffect(() => {
    if (selectedFareClass) {
      setSelectedOption(selectedFareClass);
    } else {
      setSelectedOption(undefined)
    }
  }, [selectedFareClass]);

  return (
    <ComboBox
      label="Class"
      placeholder="Select Class"
      multiSelect
      allowFreeform={false}
      options={options}
      selectedKey={selectedOption ? selectedOption : undefined}
      onChange={handleOptionChange}
      disabled={disabled}
      styles={{
        container: {
          width: 210,
        },
        callout: {
          width: 206,
        },
      }}
    />
  );
};
