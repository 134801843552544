import { TABLE_ROWS } from 'common/constants';
import { LookupCorporateAccountsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import {
  LookupCorporateAccountSearch,
  LookupCorporateAccountSearchVariables,
} from './__generated__/LookupCorporateAccountSearch';

type OrderLookup = {
  [direction in OrderDirection]: LookupCorporateAccountsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'lookupAccount',
    {
      [OrderDirection.ASC]: [LookupCorporateAccountsOrderBy.LOOKUP_ACCOUNT_ASC],
      [OrderDirection.DESC]: [
        LookupCorporateAccountsOrderBy.LOOKUP_ACCOUNT_DESC,
      ],
    },
  ],
  [
    'lookupName',
    {
      [OrderDirection.ASC]: [LookupCorporateAccountsOrderBy.LOOKUP_NAME_ASC],
      [OrderDirection.DESC]: [LookupCorporateAccountsOrderBy.LOOKUP_NAME_DESC],
    },
  ],
  [
    'department',
    {
      [OrderDirection.ASC]: [
        LookupCorporateAccountsOrderBy.SORT_DEPT_GROUP_ASC,
      ],
      [OrderDirection.DESC]: [
        LookupCorporateAccountsOrderBy.SORT_DEPT_GROUP_DESC,
      ],
    },
  ],
  [
    'travelAccount',
    {
      [OrderDirection.ASC]: [
        LookupCorporateAccountsOrderBy.SORT_TRAVEL_ACCOUNT_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        LookupCorporateAccountsOrderBy.SORT_TRAVEL_ACCOUNT_TYPE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const defaultOrderBy = [
    LookupCorporateAccountsOrderBy.LOOKUP_ACCOUNT_ASC,
    LookupCorporateAccountsOrderBy.LOOKUP_NAME_ASC,
    LookupCorporateAccountsOrderBy.PRIMARY_KEY_ASC,
  ];
  const orderByArray = [LookupCorporateAccountsOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return defaultOrderBy;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const onHandleSearch = (
  lookupName: string | null,
  showMore: boolean,
  lookupCorporateAccount: LookupCorporateAccountSearch | undefined,
  chartOfAccountId: string | null,
  sortOrderParam: SortOrder | undefined
) => {
  const variables: LookupCorporateAccountSearchVariables = {
    lookupName: lookupName || '',
    first: TABLE_ROWS,
    chartOfAccountId: chartOfAccountId || '',
    after: showMore
      ? lookupCorporateAccount?.lookupCorporateAccountSearch?.pageInfo.endCursor
      : undefined,
    orderBy: toOrderByVariable(sortOrderParam),
  };
  return {
    queryVariables: variables,
  };
};
