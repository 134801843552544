import {
  ItemDistributionValues,
  PurchaseOrderInvoiceScheduleValues,
} from './interface';

export const DISTRIBUTION_INITIAL_VALUES: ItemDistributionValues = {
  accountReference: null,
  scheduledAmount: null,
  productionIncentiveId: null,
  projectReference: null,
  setReference: null,
  tax1099T4TypeId: null,
  referenceCode1: null,
  referenceCode2: null,
  referenceCode3: null,
  referenceCode4: null,
  referenceCode5: null,
  id: null,
  _rowTimestamp: null,
  referenceCode6: null,
  referenceCode7: null,
  _isUpdatable: null,
  _isDeletable: null,
  _accountName: null,
};

export const INVOICE_SCHEDULE_INITIAL_VALUES: PurchaseOrderInvoiceScheduleValues =
  {
    invoiceSequence: null,
    scheduledDate: null,
    scheduledNote: null,
    scheduledAmount: null,
    purchaseOrderItemDistribution: [{ ...DISTRIBUTION_INITIAL_VALUES }],
  };
