import { Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { DocumentPackage } from 'common/components/DocumentPackage';
import { ApprovalState } from 'common/types/globalTypes';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { useStyles } from './index.styles';
import { TransactionDetail } from './TransactionDetail';
import { HierarchyData } from './TransactionDetail/HierarchyData';
import {
  TransactionData,
  TransactionDataVariables,
} from './__generated__/TransactionData';
import { SignatureOptions, SignatureView } from '../Signatures';
const TRANSACTION_DATA = loader('./TransactionData.graphql');

const ActionConst = {
  viewDetails: 'viewDetails',
  viewHierarchy: 'viewHierarchy',
  viewSignature: 'viewSignature',
};

interface TransactionSectionProps {
  invoiceId?: string;
}
export const TransactionSection: React.FC<TransactionSectionProps> = ({
  invoiceId,
}) => {
  const { width } = getWindowDimensions();
  const documentViewPosition = width ? width * 0.8 - 500 : 50;
  const [selectedKey, setSelectedKey] = useState<string>(
    ActionConst.viewDetails
  );
  const [getTransactionData, { data, loading }] = useLazyQuery<
    TransactionData,
    TransactionDataVariables
  >(TRANSACTION_DATA);

  const getTransactionDataCallback = useCallback(getTransactionData, []);

  useEffect(() => {
    if (invoiceId) getTransactionDataCallback({ variables: { id: invoiceId } });
  }, [invoiceId, getTransactionDataCallback]);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const signatureOptions: SignatureOptions[] | undefined =
    data?.invoice?.approvalHistorySignaturesByEntityId.nodes.map(
      (item) =>
        ({
          actionDate: item.actionDate,
          additionalInformation: item.additionalInformation,
          digitalSignature: item.digitalSignature,
          id: item.id,
          isApproved: item.isApproved,
          isRejected: item.isRejected,
          isRequested: item.isRequested,
          userName: item.userName,
          entityDocumentId: item.entityDocumentId,
          userOccupationTitle: item.userOccupationTitle,
          userSignatureDocument: {
            _downloadLink: item.userSignatureDocument?._downloadLink,
            fileIndexInformation:
              item.userSignatureDocument?.fileIndexInformation,
            fileReference: item.userSignatureDocument?.fileReference,
            iconType: item.userSignatureDocument?.iconType,
            id: item.userSignatureDocument?.id,
          },
        } as SignatureOptions)
    );

  const getSelectedSection = (key: string) => {
    switch (key) {
      case ActionConst.viewDetails:
        return <TransactionDetail transactionData={data!} loading={loading} />;

      case ActionConst.viewHierarchy:
        return <HierarchyData entityId={invoiceId!} />;

      case ActionConst.viewSignature:
        return (
          <Stack tokens={{ padding: 20 }}>
            {data?.invoice?.approvalHistorySignaturesByEntityId.nodes.length! >
              0 && <SignatureView signatureData={signatureOptions!} />}
          </Stack>
        );
      default:
        return null;
    }
  };

  const styles = useStyles();
  const showHierarchyAndSignature =
    data?.invoice?.statusType?.approvalState !== ApprovalState.APPROVED &&
    data?.invoice?.statusType?.approvalState !== ApprovalState.PENDING;

  return (
    <Stack className={styles.container}>
      <Stack
        tokens={{ padding: '10px 10px 10px 120px' }}
        className={styles.pivotContainer}
        horizontal
      >
        <Pivot
          aria-label="Separately Rendered Content Pivot Example"
          selectedKey={selectedKey}
          onLinkClick={handleLinkClick}
          headersOnly={true}
        >
          <PivotItem headerText="Details" itemKey={ActionConst.viewDetails} />

          {!showHierarchyAndSignature && (
            <PivotItem
              headerText="Hierarchy"
              itemKey={ActionConst.viewHierarchy}
            />
          )}
          {!showHierarchyAndSignature && (
            <PivotItem
              headerText="Signature"
              itemKey={ActionConst.viewSignature}
            />
          )}
        </Pivot>
        <Stack style={{ marginTop: 8 }}>
          <DocumentPackage
            paddingLeft={documentViewPosition}
            documentPackageId={data?.invoice?._documentPackageId}
          />
        </Stack>
      </Stack>
      <Stack tokens={{ padding: '0px 10px 30px 100px' }}>
        {getSelectedSection(selectedKey)}
      </Stack>
    </Stack>
  );
};
