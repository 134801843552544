import React from 'react';
import { Document, Page, PDFViewer, View } from '@react-pdf/renderer';
import { commonPdfStyles } from 'common/components/PdfViewModal/common.stylesheet';
import { Table } from './Table';
import { Transaction } from './Transaction';
import { InvoiceDetails } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
import { InvoiceTransactionTypes } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceTransactionTypes';

export interface PdfDocumentProps {
  invoiceData?: InvoiceDetails;
  transactionTypeData?: InvoiceTransactionTypes;
}

export const TransactionPDF: React.FC<PdfDocumentProps> = ({
  invoiceData,
  transactionTypeData,
}) => {

  return (
    <PDFViewer style={{ flex: 1 }}>
      <Document>
        <Page size="A4" style={commonPdfStyles.page}>
          <View style={{ flex: 1 }}>
            <Transaction
              invoiceData={invoiceData}
              transactionTypeData={transactionTypeData}
            />
            {
              invoiceData &&
              <Table
                data={invoiceData}
                transactionTypeData={transactionTypeData}
              />
            }
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
