import React, { useEffect, useRef, useState, useCallback } from 'react';
import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
  IColumn,
  IconButton,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { ColumnData, SearchBar } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { EntityDeleteInput, TagsOrderBy } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import { columns } from './column.data';
import { InfiniteList } from 'common/components/InfiniteList';
import { badgeColor, toOrderByVariable } from './utils';
import { useStyles } from './index.styles';
import { useToasts } from 'react-toast-notifications';
import { Tags, TagsVariables, Tags_tags_nodes } from './__generated__/Tags';
import { TagDelete, TagDeleteVariables } from './__generated__/TagDelete';
import { useBoolean } from '@fluentui/react-hooks';
import { CreateTagForm } from '../view';
import { EntityType } from 'common/types/utility';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
// import { DeleteMessageModal } from 'common/components/DeleteMessageModal';
// import { EntityType } from 'common/components/DeleteMessageModal/utils';

const DELETE_TAG = loader('./TagDelete.graphql');
const TAG_LIST = loader('./Tags.graphql');

export const TagSetup: React.FC = () => {
  const commonStyles = useCommonStyles();
  const renderRef = useRef(false);
  const { addToast } = useToasts();

  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [isNew, setIsNew] = useState<boolean>(true);
  const [updateData, setUpdateData] = useState<
    Tags_tags_nodes | undefined | null
  >();

  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [selectedRows, setSelectedRows] = useState<Tags_tags_nodes[]>([]);
  const [hideCreateForm, { toggle: toggleCreateForm }] = useBoolean(false);

  const styles = useStyles();

  const [
    fetchTagList,
    {
      loading: tagListLoading,
      data: tagListData,
      networkStatus,
      fetchMore,
      variables,
    },
  ] = useLazyQuery<Tags, TagsVariables>(TAG_LIST, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [TagsOrderBy.NAME_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [deleteTag] = useMutation<TagDelete, TagDeleteVariables>(DELETE_TAG, {
    errorPolicy: 'all',
  });

  const handleSearch = (showMore: boolean) => {
    const variables: TagsVariables = {
      first: TABLE_ROWS,
      after: showMore ? tagListData?.tags?.pageInfo.endCursor : undefined,

      orderBy: toOrderByVariable(sortOrderParam),
    };

    if (showMore) fetchMore?.({ variables });
    if (!showMore) fetchTagList({ variables: variables });
    else fetchMore?.({ variables });
  };

  const handleSearchMemo = useCallback(handleSearch, [sortOrderParam]);

  useEffect(() => {
    handleSearchMemo(false);
    renderRef.current = true;
  }, [handleSearchMemo]);

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const _renderItemColumn = (
    item: Tags_tags_nodes | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item && column) {
      const key = column.key as keyof Tags_tags_nodes;
      switch (column?.key) {
        case 'badgeColor':
          return (
            <Stack
              verticalAlign="center"
              style={{
                backgroundColor: badgeColor(item.badgeColor),
              }}
              className={styles.badgeWrapper}
            />
          );
        case 'isPrivate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item.isPrivate ? 'Private' : 'Public'}</Text>
            </Stack>
          );
        case '_isActive':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text
                className={
                  item._isActive
                    ? styles.activeIndicator
                    : styles.disabledIndicator
                }
              >
                {item._isActive ? 'Active' : 'Disabled'}
              </Text>
            </Stack>
          );
        case 'isMonitored':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item.isMonitored ? 'Yes' : 'No'}</Text>
            </Stack>
          );
        case 'isAccessTag':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item.isAccessTag ? 'Yes' : 'No'}</Text>
            </Stack>
          );
        case '_isSystemGenerated':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item._isSystemGenerated ? 'Yes' : 'No'}</Text>
            </Stack>
          );

        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                onClick={() => {
                  _onUpdateModalOpen(item);
                }}
                disabled={!item._isUpdatable}
              />
            </Stack>
          );

        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item[key] ?? ''}</Text>
            </Stack>
          );
      }
    }
  };

  const _onUpdateModalOpen = (item: Tags_tags_nodes) => {
    if (item) {
      setUpdateData(item);
      setIsNew(false);
    }
    toggleCreateForm();
  };

  const _onConfirm = async () => {
    const selectedData: Tags_tags_nodes[] = selectedRows;
    const toDelete: Tags_tags_nodes[] = selectedData.map((_, index) => {
      return selectedData![index];
    });
    let deletableTagItems = toDelete?.filter((item) => item._isDeletable);
    if (deletableTagItems.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableTagItems.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      const { errors } = await deleteTag({
        variables: {
          input: {
            entityDelete: deleteInputArray,
          },
        },
        update: (cache, { data }) => {
          const deletedIds = data?.tagDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );
          if (deletedIds) {
            const filteredList = tagListData?.tags?.nodes.filter(
              (tag) => deletedIds.indexOf(tag.id) === -1
            );
            const newData: Tags = {
              tags: {
                pageInfo: tagListData?.tags?.pageInfo!,
                nodes: filteredList!,
              },
            };
            cache.writeQuery<Tags, TagsVariables>({
              query: TAG_LIST,
              variables,
              data: newData,
            });
          }
        },
      });
      if (!errors) {
        addToast('Tag deleted Successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Tags</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.TagSetup}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete.name),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              iconProps={{
                iconName: 'Add',
              }}
              onClick={() => {
                setUpdateData(null);
                setIsNew(true);
                toggleCreateForm();
              }}
              text="New Tag"
            />
          </Stack>
        </Stack>

        <SearchBar
          columns={[]}
          searchEnabled={false}
          onRefresh={() => handleSearch(false)}
        />
      </Stack>
      <InfiniteList
        loading={tagListLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={tagListData?.tags?.pageInfo.hasNextPage}
        items={refetching ? undefined : tagListData?.tags?.nodes}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onSelectionChanged={setSelectedRows}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true)}
      />
      {hideCreateForm && (
        <CreateTagForm
          visible={hideCreateForm}
          onDismiss={toggleCreateForm}
          tagData={updateData!}
          isNew={isNew}
        />
      )}
    </>
  );
};
