import { useQuery } from '@apollo/client';
import { IDropdownOption, Stack } from '@fluentui/react';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { TravelPlansFilter } from './TravelPlansFilter';
import { TripTravelersFilter } from './TripTravelersFilter';
import { TripBreakdownFilterCommonData } from './__generated__/TripBreakdownFilterCommonData';

const TRIP_BREAKDOWN_FILTER_DATA = loader(
  './TripBreakdownFilterCommonData.graphql'
);

interface SearchBarSectionProps extends SearchBarProps {
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
}

export const SearchBarSection: React.FC<SearchBarSectionProps> = ({
  columns,
  onFilterChange,
  onEnterPress,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  urlParams,
  onGlobalToggle,
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  // const TAListViewOptions = useReactiveVar(setTAListViewOptions);

  const { data: commonData } = useQuery<TripBreakdownFilterCommonData>(
    TRIP_BREAKDOWN_FILTER_DATA
  );

  // const {
  //   availableTripTravelers,
  //   tripTravelPlans,
  // } = { ...commonData }

  const policyOptions: IDropdownOption[] = [
    { text: 'Out-of-policy', key: 1 },
    { text: 'In-policy', key: 0 },
    { key: -1, text: 'All' },
  ];

  const businessUnits = formatDropdownOptions(
    commonData?.availableTripBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );

  const workgroupOptions = formatDropdownOptions(
    commonData?.availableTripWorkgroups?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );

  const originOptions: IDropdownOption[] = [
    { key: 1, text: 'Corporate' },
    { key: 0, text: 'Production' },
    { key: -1, text: 'All' },
  ];

  const currencyOptions = formatDropdownOptions(
    commonData?.availableTripCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );

  const departmentOptions = formatDropdownOptions(
    commonData?.availableTripDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: true,
    }
  );
  const tripStatusOptions = formatDropdownOptions(
    commonData?.availableTripStatuses?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType || '',
      includeAll: true,
    }
  );

  const travelPoliciesOptions = formatDropdownOptions(
    commonData?.travelPolicies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.description || '',
      includeAll: true,
    }
  );

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      urlParams={urlParams}
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange?.([]);
        setFilterTypes([]);
      }}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      onGlobalToggle={onGlobalToggle}
      onToggleVisibility={onToggleVisibility}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'_travelPolicyId'}
          options={travelPoliciesOptions || []}
          placeholder={'Travel Policy'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'businessUnitId'}
          options={businessUnits || []}
          placeholder={'Business Units'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'corporateWorkgroupId'}
          options={workgroupOptions || []}
          placeholder={'Workgroup'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_isCorporateTravelAuthorization'}
          options={originOptions || []}
          placeholder={'Origins'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'departmentId'}
          options={departmentOptions || []}
          placeholder={'Department'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_taStatusTypeId'}
          options={tripStatusOptions || []}
          placeholder={'Status'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'currencyId'}
          options={currencyOptions || []}
          placeholder={'Currency'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'_isOverBudget'}
          options={policyOptions || []}
          placeholder={'Policy'}
          onOptionChange={onOptionChange}
        />
        <TripTravelersFilter
          filterKey="travelerId"
          onOptionChange={onOptionChange}
        />
        <TravelPlansFilter
          filterKey="travelAuthorizationId"
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
