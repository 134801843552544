import * as Yup from 'yup';

export const validationSchema = () => 
  Yup.object(
      {
          environmentalMetricId: Yup.string().required('Required').nullable(),
          startDate: Yup.date().nullable(),
          endDate: Yup.date().nullable().min(
              Yup.ref('startDate'),
              "To date can't be before From date."
            ),
      }
  )