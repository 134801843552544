import React, { useEffect, useRef, useState, useCallback } from 'react';
import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
  IColumn,
  IDetailsRowProps,
  IRenderFunction,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  EntityDeleteInput,
  EntitySelectionInput,
  UserProfileFilter,
  UserProfilesOrderBy,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import { columns } from './column.data';
import { toOrderByVariable, toPaymentFilterVariable } from './utils';
import { FilterArrayType } from 'common/components/Filters';
import {
  UserProfiles,
  UserProfilesVariables,
  UserProfiles_userProfiles_nodes,
} from './__generated__/UserProfiles';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import { UserSetupSearchBar } from './UserSetupSearchBar';
import { useHistory } from 'react-router-dom';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { useStyles } from './index.styles';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import {
  UserProfileDelete,
  UserProfileDeleteVariables,
} from './__generated__/UserProfileDelete';
import { useToasts } from 'react-toast-notifications';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  TempUserProfileInvite,
  TempUserProfileInviteVariables,
} from '../__generated__/TempUserProfileInvite';
import { EntityAction, EntityType } from 'common/types/utility';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';
import clsx from 'clsx';
const USER_SETUP = loader('./UserProfiles.graphql');
const USERPROFILE_DELETE = loader('./UserProfileDelete.graphql');
const INVITE = loader('../TempUserProfileInvite.graphql');

export type UserSetupListItem = UserProfiles_userProfiles_nodes & {
  roleName?: string;
  occupation?: string;
  departmentName?: string | null;
  accessPolicy?: string;
  approvalStatus?: string | null;
  inviteStatus?: string | null;
  rank?: string | null;
  securityLevel?: number | null;
};

export const UserSetup: React.FC = () => {
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const styles = useStyles();
  const { addToast } = useToasts();
  const renderRef = useRef(false);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();
  const [selectedRows, setSelectedRows] = useState<UserSetupListItem[]>([]);
  const [
    fetchUserList,
    {
      loading: userListLoading,
      data: userListData,
      networkStatus,
      fetchMore,
      variables,
    },
  ] = useLazyQuery<UserProfiles, UserProfilesVariables>(USER_SETUP, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [UserProfilesOrderBy.NAME_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [userProfileInvite, { loading: userProfileInviteLoading }] =
    useMutation<TempUserProfileInvite, TempUserProfileInviteVariables>(INVITE, {
      errorPolicy: 'all',
    });

  const [deleteUserProfile] = useMutation<
    UserProfileDelete,
    UserProfileDeleteVariables
  >(USERPROFILE_DELETE, {
    errorPolicy: 'all',
  });
  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const transformedData: UserSetupListItem[] | undefined = refetching
    ? undefined
    : userListData?.userProfiles?.nodes.map((appr) => ({
        ...appr,
        roleName: appr.role?.name,
        occupation: appr.userOccupationTitle?.userOccupationTitle,
        departmentName: appr.department?.name,
        accessPolicy: appr.dataAccessPolicy?.name,
        inviteStatus: appr.inviteStatusType?.statusType,
        approvalStatus: appr.statusType?.statusType,
        rank: appr.userRankType?.userRank,
        securityLevel: appr.secureRowLevel?.securityLevel,
      }));

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
  props,
  defaultRender
) => {
  if (!props) {
    return null;
  }
  const item: UserSetupListItem = { ...props.item };
  const { _urgencyLevel } = { ...item };
  const newProps: IDetailsRowProps | undefined = props
    ? {
        ...props,
        className: clsx(
          styles.rowBaseStyle,
          _urgencyLevel === 0 ? commonStyles.urgentRow : styles.rowBaseStyle
        ),
      }
    : undefined;

  return <>{defaultRender?.(newProps)}</>;
};
  const handleSearch = (showMore: boolean) => {
    const variables: UserProfilesVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? userListData?.userProfiles?.pageInfo.endCursor
        : undefined,
      filter: searchFilters?.length
        ? ({
            and: toPaymentFilterVariable(searchFilters),
          } as UserProfileFilter)
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
    };

    if (showMore) fetchMore?.({ variables });
    else fetchUserList({ variables: variables });
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [searchFilters, sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    handleSearchMemo(false);
    renderRef.current = true;
  }, [handleSearchMemo]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [handleSearchMemo]);
  const _renderItemColumn = (
    item: UserSetupListItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item && column) {
      const key = column.key as keyof UserSetupListItem;
      const _isUrgentApproval = !Boolean(item._urgencyLevel);
      switch (key) {
        case '_urgencyLevel':
          return (
            <RedBoxIndicator
              _isUrgentApproval={_isUrgentApproval}
              itemId={item?.id}
            />
          );
        case 'name':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <ActiveLink to={`/account-management/users/user/${item?.id}`}>
                {item.name}
              </ActiveLink>
            </Stack>
          );
        case 'securityLevel':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignCenter}>
                {item.securityLevel}
              </Text>
            </Stack>
          );

        case 'invited':
        case '_lastUsedDate':
          const date = item[key];
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{date && getGlobalDateFormat(date)}</Text>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item[key] ?? ''}</Text>
            </Stack>
          );
      }
    }
  };
  const _onConfirm = async () => {
    const selectedData: UserSetupListItem[] = selectedRows;
    const toDelete: UserSetupListItem[] = selectedData.map((_, index) => {
      return selectedData![index];
    });
    let deletableUserProfiles = toDelete?.filter((item) => item._isDeletable);
    if (deletableUserProfiles.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableUserProfiles.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      const { errors } = await deleteUserProfile({
        variables: {
          input: {
            entityDelete: deleteInputArray,
          },
        },
        update: (cache, { data }) => {
          const deletedIds = data?.userProfileDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );
          if (deletedIds) {
            const filteredList = userListData?.userProfiles?.nodes.filter(
              (user) => deletedIds.indexOf(user.id) === -1
            );
            const newData: UserProfiles = {
              userProfiles: {
                pageInfo: userListData?.userProfiles?.pageInfo!,
                totalCount:
                  userListData?.userProfiles?.totalCount! - deletedIds.length,
                nodes: filteredList!,
              },
            };
            cache.writeQuery<UserProfiles, UserProfilesVariables>({
              query: USER_SETUP,
              variables,
              data: newData,
            });
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else
        addToast('User profiles deleted Successfully', {
          appearance: 'success',
        });
    }
  };
  const onInviteProcess = async () => {
    const toInvite: EntitySelectionInput[] = selectedRows
      .filter((item) => item._isStagedToInvite)
      .map((item) => {
        return { id: item.id, rowTimestamp: item._rowTimestamp! };
      });
    if (toInvite.length > 0) {
      const { errors } = await userProfileInvite({
        variables: {
          input: {
            entitySelection: toInvite,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: USER_SETUP,
            variables: {
              first: TABLE_ROWS,
              orderBy: [UserProfilesOrderBy.NAME_ASC],
            },
          },
        ],
      });

      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('User profile invited successfully', {
          appearance: 'success',
        });
        setSelectedRows([]);
      }
    }
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">User Setup</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.User}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete.name),
              }}
              onConfirm={_onConfirm}
            />
            <ActionMessageModal
              entityType={EntityType.User}
              action={EntityAction.Invite}
              disabled={
                !selectedRows.some((selected) => selected._isStagedToInvite)
              }
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isStagedToInvite
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isStagedToInvite)
                  .map((cannotDelete) => cannotDelete.name),
              }}
              onConfirm={onInviteProcess}
              buttonProps={{ text: 'Invite Users' }}
            />
            <PrimaryButton
              onClick={() => history.push('/account-management/users/user')}
              iconProps={{
                iconName: 'Add',
              }}
              text="New User"
            />
          </Stack>
        </Stack>
        <Stack>
          <UserSetupSearchBar
            columns={gridColumns}
            onRefresh={() => handleSearch(false)}
            onFilterChange={(filters) => {
              setSearchFilters(filters);
            }}
            onToggleVisibility={(columns) => {
              setGridColumns(columns);
            }}
          />
          {userProfileInviteLoading && <ProgressIndicator />}
        </Stack>
      </Stack>
      <InfiniteList<UserSetupListItem>
        loading={userListLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={userListData?.userProfiles?.pageInfo.hasNextPage}
        items={transformedData}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onSelectionChanged={setSelectedRows}
        onLoadMore={() => handleSearch(true)}
        onRenderRow={_onRenderRow}
      />
    </>
  );
};
