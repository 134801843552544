import { IDropdownOption, Stack, Text, makeStyles } from '@fluentui/react';
import React, { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { TravelerModalDropdownsData } from '../__generated__/TravelerModalDropdownsData';
import { DIETARY_REQUIREMENTS_INITIAL_VALUES } from '../constants';
import { TravelerValues } from '../interfaces';
import { DietaryRequirementsInputRow } from './DietaryRequirementsInputRow';

interface DietaryRequirementsProps {
  commonData: TravelerModalDropdownsData | undefined;
  inputsDisabled: boolean;
}
export interface DietaryRequirementsOptionsProps extends IDropdownOption {
  isDescriptionRequired: boolean | null;
}

const useStyles = makeStyles(() => ({
  dietaryRequirements: {
    marginBottom: 20
  }
}));


export const DietaryRequirements: React.FC<DietaryRequirementsProps> = ({
  commonData,
  inputsDisabled
}) => {
  const styles = useStyles();
  const [disabledOptions, setDisabledOptions] = useState<number[]>([]);
  const { control, watch } = useFormContext<TravelerValues>();
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    name: 'travelerDietaryRequirementsByTravelerId',
    control
  });

  const travelerDietaryRequirementsByTravelerId = useWatch({ name: 'travelerDietaryRequirementsByTravelerId', control, });
  const { dietaryRequirements } = { ...commonData }

  return (
    <Stack style={{ margin: "0px 20px" }}>
      <Text variant="xLarge" className={styles.dietaryRequirements}>Dietary Requirements</Text>
      {fields.map((_field, index) => {
        const isLastRow = !!travelerDietaryRequirementsByTravelerId && travelerDietaryRequirementsByTravelerId?.length - 1 === index;
        const isFirstRow = !!travelerDietaryRequirementsByTravelerId?.length && index === 0;
        const dietaryRequirementId = watch(`travelerDietaryRequirementsByTravelerId.${index}.dietaryRequirementId`);
        const dietaryRequirementsOptions: DietaryRequirementsOptionsProps[] = dietaryRequirements?.nodes
          .filter(
            (type) =>
            (type.id === dietaryRequirementId ||
              !travelerDietaryRequirementsByTravelerId?.some(
                (row) => row.dietaryRequirementId === type.id
              ))
          )
          .map((type) => ({
            key: type.id,
            text: type.description || '',
            isDescriptionRequired: type.isDescriptionRequired
          })) || [];

        return (
          <DietaryRequirementsInputRow
            key={index}
            fieldName={`travelerDietaryRequirementsByTravelerId.${index}`}
            dietaryRequirementsOptions={dietaryRequirementsOptions}
            onChange={(ele) => {
              if (ele)
                setDisabledOptions([...disabledOptions, parseInt(ele?.key.toString())])
              if (isLastRow && (fields.length !== dietaryRequirements?.nodes.length)) {
                append(DIETARY_REQUIREMENTS_INITIAL_VALUES);
              }
            }}
            onRemove={() => {
              remove(index)
              if (dietaryRequirementsOptions.length === 1) {
                append(DIETARY_REQUIREMENTS_INITIAL_VALUES);
              }
            }}
            isFirstRow={isFirstRow}
            rowIndex={index}
            inputsDisabled={inputsDisabled}
            commonData={commonData}
            disabledOptions={disabledOptions}
          />
        );
      })}
    </Stack>
  )
}
