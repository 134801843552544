import { useLazyQuery } from '@apollo/client';
import { ITextFieldProps, ITextFieldStyles, IconButton, Spinner, Stack, TextField, useTheme } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { LookUpCallout } from './LookupCallout';
import { LookupCarrierSearch, LookupCarrierSearchVariables, LookupCarrierSearch_lookupCarrierSearch_nodes } from './__generated__/LookupCarrierSearch';
import { useStyles } from "./index.styles";
const LOOKUP_CARRIER_SEARCH = loader("./LookupCarrierSearch.graphql");

type CarrierAutocompleteProps = RequiredNameProps<ITextFieldProps> & {
  lookUpCalloutWidth?: number;
  carrierData?: string | null | undefined
  lookupData?: LookupCarrierSearch_lookupCarrierSearch_nodes | null;
  isAirlineLookup?: boolean;
  isBusLookup?: boolean;
  isTrainLookup?: boolean;
  onCarrierSelect?: (depot: LookupCarrierSearch_lookupCarrierSearch_nodes) => void;
  onClear?: () => void
};

export const CarrierAutocomplete: React.FC<CarrierAutocompleteProps> = ({
  lookUpCalloutWidth,
  lookupData,
  carrierData,
  isAirlineLookup = false,
  isBusLookup = false,
  isTrainLookup = false,
  onCarrierSelect,
  onClear,
  ...props
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const renderRef = useRef(false);
  const [calloutVisible, setCalloutVisible] = useState(true);
  const [carrierName, setCarrierName] = useState<string | null | undefined>(null);
  const [disableTextfield, setDisableTextfield] = useState<boolean | null | undefined>(false);

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name: props.name });

  const [
    fetchLookUp,
    {
      data,
      loading,
    }
  ] = useLazyQuery<
    LookupCarrierSearch,
    LookupCarrierSearchVariables
  >(LOOKUP_CARRIER_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: props.disabled
        ? theme.palette.neutralLighter
        : theme.palette.white,
    },
  };

  const { id, disabled, required, ...textfieldProps } = props;

  const renderSpinner = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disabled ? null : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              onChange(null);
              onClear?.();
              setCarrierName(null);
              setDisableTextfield(false);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupCarrier: value,
          isAirlineLookup: !!isAirlineLookup,
          isBusLookup: !!isBusLookup,
          isTrainLookup: !!isTrainLookup,
          first: 40
        },
      });
      queryCalled();
    }, 350),
    [value]
  );

  const lookupCallBack = useCallback(
    (value) => {
      fetchLookUp({
        variables: {
          lookupCarrier: value,
          isAirlineLookup: !!isAirlineLookup,
          isBusLookup: !!isBusLookup,
          isTrainLookup: !!isTrainLookup,
          first: 40
        },
      });
    },
    [fetchLookUp, isAirlineLookup, isBusLookup, isTrainLookup]
  );

  useEffect(() => {
    if (value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(value);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack]);

  useEffect(() => {
    setCarrierName(carrierData);
    if (carrierData) {
      setDisableTextfield(true);
    } else {
      setDisableTextfield(false);
    }
  }, [carrierData]);

  // useEffect(() => {
  //   if (carrierData) {
  //     setDisableTextfield(true);
  //   }
  // }, [carrierData]);

  return (
    <Stack style={{ width: 300 }}>
      <TextField
        {...textfieldProps}
        autoComplete={'off'}
        errorMessage={error?.message}
        id={id}
        value={carrierName || ''}
        onChange={(_event, value) => {
          if (value) {
            setCarrierName(value);
            callbackLookUp(value, () => {
              if (calloutVisible === false) setCalloutVisible(true);
            });
          } else {
            onChange(null);
            setCarrierName(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textfieldStyles}
        disabled={disabled ? true : disableTextfield ? true : false}
      />
      <Stack className={styles.calloutContainer}>
        {calloutVisible && !loading && (
          <LookUpCallout
            lookUpCalloutWidth={lookUpCalloutWidth}
            onDismiss={() => {
              setCalloutVisible(false)
            }}
            onSelect={(value) => {
              if (value) {
                onChange(value.id);
                setCarrierName(value.description)
                setDisableTextfield(true);
                onCarrierSelect?.(value)
              }
            }}
            targetID={`#${id!}`}
            data={data?.lookupCarrierSearch?.nodes || []}
          />
        )}
      </Stack>
    </Stack>
  )
}
