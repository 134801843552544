import { Text, Stack, useTheme } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import React from 'react';

export const Header: React.FC<{
  title: string;
  subTitle: string;
  onDismiss: () => void;
}> = ({ title, subTitle, onDismiss }) => {
  const theme = useTheme();

  return (
    <Stack
      verticalAlign="end"
      horizontal
      tokens={{ childrenGap: 10, padding: '20px 20px 0px 20px' }}
    >
      <Text variant="xLarge" style={{ color: theme.palette.themePrimary }}>
        {title}
      </Text>
      <Text variant="xLarge">{subTitle}</Text>
      <Stack style={{ marginLeft: 'auto' }}>
        <CloseButton onClick={onDismiss} />
      </Stack>
    </Stack>
  );
};
