import {
  Separator,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.shimmerSectionConatiner}>
      <ShimmerElementsGroup
        width={'100%'}
        shimmerElements={[
          { type: ShimmerElementType.circle, height: 23 },
          { type: ShimmerElementType.gap, height: 12, width: '2%' },
          { type: ShimmerElementType.line, height: 12, width: '30%' },
          { type: ShimmerElementType.gap, height: 12, width: '52%' },
          { type: ShimmerElementType.line, height: 12, width: '16%' },
        ]}
      />
    </div>
  );
};
export const ShimmerSection1 = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.shimmerSectionConatiner}>
      <ShimmerElementsGroup
        width={'100%'}
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 12, width: '5.5%' },
          { type: ShimmerElementType.line, height: 12, width: '45%' },
          { type: ShimmerElementType.gap, height: 12, width: '50%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection2 = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.shimmerSectionConatiner}>
      <ShimmerElementsGroup
        width={'100%'}
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 12, width: '5.5%' },
          { type: ShimmerElementType.line, height: 12, width: '15%' },
          { type: ShimmerElementType.gap, height: 12, width: '80%' },
        ]}
      />
    </div>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <>
      <Stack className={styles.shimmerViewPadding}>
        <Shimmer customElementsGroup={ShimmerSection()} />
        <Shimmer
          className={styles.shimmerViewPadding}
          customElementsGroup={ShimmerSection1()}
        />
        <Shimmer
          className={styles.shimmerViewMarginSecond}
          customElementsGroup={ShimmerSection2()}
        />
      </Stack>
      <Separator />
    </>
  );
};
