import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    groupName: {
      color: theme.palette.themePrimary,
    },
    description: {
      maxWidth: 1000,
      color: theme.palette.neutralDark,
    },
    optionsSection: {
      height: 30,
    },
    groupSelected: {
      backgroundColor: theme.palette.neutralLighter,
    },
  };
});
