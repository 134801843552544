import {
  FontWeights,
  Separator,
  Stack,
  Text,
  makeStyles,
} from '@fluentui/react';
import React from 'react';
import { TravelAuthorization_travelAuthorization_approvalHistoriesByEntityId } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { ApprovalHistory } from './ApprovalHistory';

const useStyles = makeStyles((theme) => ({
  approvalHistoryContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: FontWeights.bold,
  },
}));

interface ApprovalHistoryViewProps {
  approvalHistoriesByEntityId:
    | TravelAuthorization_travelAuthorization_approvalHistoriesByEntityId
    | undefined;
}

export const ApprovalHistoryView: React.FC<ApprovalHistoryViewProps> = ({
  approvalHistoriesByEntityId,
}) => {
  const styles = useStyles();
  if (!approvalHistoriesByEntityId?.nodes.length) return null;

  return (
    <Stack>
      <Separator />
      <Stack
        className={styles.approvalHistoryContainer}
        tokens={{ childrenGap: 20 }}
      >
        <Text variant="xLarge">Approval History</Text>
        <Text variant="medium" className={styles.requestedByText}>
          Requested By:
        </Text>
        <ApprovalHistory data={approvalHistoriesByEntityId} />
      </Stack>
    </Stack>
  );
};
