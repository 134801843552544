import { ColumnActionsMode, IColumn } from '@fluentui/react';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { TransactionLayout } from 'common/types/globalTypes';

export type ColumnData = IColumn & {
  /** property to determine whether this coloumn can be toggled for visibility */
  toggleVisibility?: boolean;
  /** property to determine whether the column is visble or not*/
  isVisible?: boolean;
  isFormField?: boolean;
};

export const getColumns = (
  data: UserDefaults_userDefaults_nodes | undefined
) => {
  const columns = new Map<string, ColumnData>([
    [
      'delete',
      {
        key: 'delete',
        name: '',
        fieldName: 'delete',
        minWidth: 40,
        maxWidth: 40,
        columnActionsMode: ColumnActionsMode.disabled,
        isFormField: false,
      },
    ],

    [
      'referenceCode6',
      {
        key: 'referenceCode6',
        name: 'CO',
        fieldName: 'referenceCode6',
        minWidth: 50,
        maxWidth: 50,
      },
    ],

    [
      'referenceCode7',
      {
        key: 'referenceCode7',
        name: 'LOC',
        fieldName: 'referenceCode7',
        minWidth: 50,
        maxWidth: 50,
      },
    ],

    [
      'accountReference',
      {
        key: 'accountReference',
        name: 'Account',
        fieldName: 'accountReference',
        minWidth: 90,
        maxWidth: 90,
      },
    ],

    [
      'projectReference',
      {
        key: 'projectReference',
        name: 'Episode',
        fieldName: 'projectReference',
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [
      'setReference',
      {
        key: 'setReference',
        name: 'Set',
        fieldName: 'setReference',
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [
      'distributionAmount',
      {
        key: 'distributionAmount',
        name: 'Amount',
        fieldName: 'distributionAmount',
        minWidth: 110,
        maxWidth: 110,
      },
    ],
    [
      'allocationPercent',
      {
        key: 'allocationPercent',
        name: 'Allocation %',
        fieldName: 'allocationPercent',
        minWidth: 90,
        maxWidth: 90,
      },
    ],
    [
      'referenceCode5',
      {
        key: 'referenceCode5',
        name: 'Ins',
        fieldName: 'referenceCode5',
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [
      'referenceCode1',
      {
        key: 'referenceCode1',
        name: 'FF1',
        fieldName: 'referenceCode1',
        minWidth: 95,
        maxWidth: 95,
      },
    ],
    [
      'referenceCode2',
      {
        key: 'referenceCode2',
        name: 'FF2',
        fieldName: 'referenceCode2',
        minWidth: 95,
        maxWidth: 95,
      },
    ],
    [
      'referenceCode3',
      {
        key: 'referenceCode3',
        name: 'FF3',
        fieldName: 'referenceCode3',
        minWidth: 95,
        maxWidth: 95,
      },
    ],
    [
      'referenceCode4',
      {
        key: 'referenceCode4',
        name: 'FF4',
        fieldName: 'referenceCode4',
        minWidth: 95,
        maxWidth: 95,
      },
    ],
    [
      'productionIncentiveId',
      {
        key: 'productionIncentiveId',
        name: 'Incentives',
        fieldName: 'productionIncentiveId',
        minWidth: 140,
        maxWidth: 140,
      },
    ],
    [
      'tax1099T4TypeId',
      {
        key: 'tax1099T4TypeId',
        name: '1099/T4',
        fieldName: 'tax1099T4TypeId',
        minWidth: 160,
        maxWidth: 160,
      },
    ],
  ]);
  const columnsPSL = new Map<string, ColumnData>([
    [
      'delete',
      {
        key: 'delete',
        name: '',
        fieldName: 'delete',
        minWidth: 40,
        maxWidth: 40,
        columnActionsMode: ColumnActionsMode.disabled,
        isFormField: false,
      },
    ],
    [
      'accountReference',
      {
        key: 'accountReference',
        name: 'Account',
        fieldName: 'accountReference',
        minWidth: 90,
        maxWidth: 90,
      },
    ],
    [
      'projectReference',
      {
        key: 'projectReference',
        name: 'Episode',
        fieldName: 'projectReference',
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [
      'referenceCode7',
      {
        key: 'referenceCode7',
        name: 'LOC',
        fieldName: 'referenceCode7',
        minWidth: 60,
        maxWidth: 60,
      },
    ],
    [
      'setReference',
      {
        key: 'setReference',
        name: 'Set',
        fieldName: 'setReference',
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [
      'referenceCode5',
      {
        key: 'referenceCode5',
        name: 'Ins',
        fieldName: 'referenceCode5',
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [
      'referenceCode1',
      {
        key: 'referenceCode1',
        name: 'FF1',
        fieldName: 'referenceCode1',
        minWidth: 95,
        maxWidth: 95,
      },
    ],
    [
      'referenceCode2',
      {
        key: 'referenceCode2',
        name: 'FF2',
        fieldName: 'referenceCode2',
        minWidth: 95,
        maxWidth: 95,
      },
    ],
    [
      'referenceCode3',
      {
        key: 'referenceCode3',
        name: 'FF3',
        fieldName: 'referenceCode3',
        minWidth: 95,
        maxWidth: 95,
      },
    ],
    [
      'referenceCode4',
      {
        key: 'referenceCode4',
        name: 'FF4',
        fieldName: 'referenceCode4',
        minWidth: 95,
        maxWidth: 95,
      },
    ],
    [
      'distributionAmount',
      {
        key: 'distributionAmount',
        name: 'Amount',
        fieldName: 'distributionAmount',
        minWidth: 110,
        maxWidth: 110,
      },
    ],
    [
      'allocationPercent',
      {
        key: 'allocationPercent',
        name: 'Allocation %',
        fieldName: 'allocationPercent',
        minWidth: 90,
        maxWidth: 90,
      },
    ],
    [
      'productionIncentiveId',
      {
        key: 'productionIncentiveId',
        name: 'Incentives',
        fieldName: 'productionIncentiveId',
        minWidth: 140,
        maxWidth: 140,
      },
    ],
    [
      'tax1099T4TypeId',
      {
        key: 'tax1099T4TypeId',
        name: '1099/T4',
        fieldName: 'tax1099T4TypeId',
        minWidth: 160,
        maxWidth: 160,
      },
    ],
  ]);

  const { layoutType } = {
    ...data?.distributionLayoutType,
  };
  let columnArray = [];
  let existingData;
  switch (layoutType) {
    case TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT:
    case TransactionLayout.GL_LOC_DISTRIBUTION_LAYOUT:
      columns.delete('projectReference');
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.PSL_ENTRY_EPISODIC:
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.PSL_ENTRY_STANDARD:
      columnsPSL.delete('projectReference');
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    case TransactionLayout.PSL_ENTRY_SERIES:
      existingData = columnsPSL.get('projectReference');
      columnsPSL.set('projectReference', {
        ...existingData,
        name: 'Series',
      } as IColumn);
      columnArray = Array.from(columnsPSL, (data) => data[1]);
      return { columnArray };
    default:
      columnArray = Array.from(columns, (data) => data[1]);
      return { columnArray };
  }
};
