import React, { useEffect, useRef, useState, useCallback } from 'react';
import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
  IColumn,
  IconButton,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { ColumnData, SearchBar } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import { columns } from './column.data';
import { InfiniteList } from 'common/components/InfiniteList';
import { toOrderByVariable } from './utils';
import {
  TagCategories,
  TagCategoriesVariables,
  TagCategories_tagCategories_nodes,
} from './__generated__/TagCategories';
import {
  EntityDeleteInput,
  TagCategoriesOrderBy,
} from 'common/types/globalTypes';
import {
  TagCategoryDelete,
  TagCategoryDeleteVariables,
} from './__generated__/TagCategoryDelete';
import { useToasts } from 'react-toast-notifications';
import { CreateTagCategoryForm } from '../view';
import { TagCategoryCallout } from './TagCategoryCallout';
import { ToggleButton } from 'common/components/Buttons/ToggleButton';
import { EntityType } from 'common/types/utility';
import { ActionMessageModal } from 'common/components/ActionMessageModal';

const TAG_CATEGORIES_LIST = loader('./TagCategories.graphql');
const TAG_CATEGORY_DELETE = loader('./TagCategoryDelete.graphql');

export const TagCategorySetup: React.FC = () => {
  const commonStyles = useCommonStyles();
  const renderRef = useRef(false);
  const { addToast } = useToasts();
  const [showForm, setShowForm] = useState<boolean>(false);
  const toggelShowForm = () => setShowForm((prevState) => !prevState);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [selectedTagCategory, setTagCategoryData] = useState<
    TagCategories_tagCategories_nodes | undefined | null
  >();
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [selectedRows, setSelectedRows] = useState<
    TagCategories_tagCategories_nodes[]
  >([]);

  const [
    fetchTagCategories,
    {
      loading: tagCategorieLoading,
      data: tagCategoriesData,
      networkStatus,
      fetchMore,
      variables,
    },
  ] = useLazyQuery<TagCategories, TagCategoriesVariables>(TAG_CATEGORIES_LIST, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [TagCategoriesOrderBy.NAME_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [deleteTag] = useMutation<
    TagCategoryDelete,
    TagCategoryDeleteVariables
  >(TAG_CATEGORY_DELETE, {
    errorPolicy: 'all',
  });

  const _onConfirm = async () => {
    const selectedData: TagCategories_tagCategories_nodes[] = selectedRows;
    const toDelete: TagCategories_tagCategories_nodes[] = selectedData.map(
      (_, index) => {
        return selectedData![index];
      }
    );
    let deletableTagItems = toDelete?.filter((item) => item._isDeletable);
    if (deletableTagItems.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableTagItems.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      var { errors } = await deleteTag({
        variables: {
          input: {
            entityDelete: deleteInputArray,
          },
        },
        update: (cache, { data }) => {
          const deletedIds = data?.tagCategoryDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );
          if (deletedIds) {
            const filteredList = tagCategoriesData?.tagCategories?.nodes.filter(
              (tag) => deletedIds.indexOf(tag.id) === -1
            );
            const newData: TagCategories = {
              tagCategories: {
                ...tagCategoriesData?.tagCategories,
                pageInfo: tagCategoriesData?.tagCategories?.pageInfo!,
                nodes: filteredList!,
                totalCount:
                  tagCategoriesData?.tagCategories?.totalCount! -
                  deletedIds.length,
              },
            };
            cache.writeQuery<TagCategories, TagCategoriesVariables>({
              query: TAG_CATEGORIES_LIST,
              variables,
              data: newData,
            });
          }
        },
      });
      if (!errors) {
        addToast('Tag categories deleted Successfully', {
          appearance: 'success',
        });
      } else {
        addToast('Error in deleting category delete', {
          appearance: 'error',
        });
      }
    }
  };

  const handleSearch = (showMore: boolean) => {
    const variables: TagCategoriesVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? tagCategoriesData?.tagCategories?.pageInfo.endCursor
        : undefined,

      orderBy: toOrderByVariable(sortOrderParam),
    };

    if (showMore) fetchMore?.({ variables });
    if (!showMore) fetchTagCategories({ variables: variables });
    else fetchMore?.({ variables });
  };

  const handleSearchMemo = useCallback(handleSearch, [sortOrderParam]);

  useEffect(() => {
    handleSearchMemo(false);
    renderRef.current = true;
  }, [handleSearchMemo]);

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const _renderItemColumn = (
    item: TagCategories_tagCategories_nodes | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item && column) {
      const key = column.key as keyof TagCategories_tagCategories_nodes;
      switch (column.key) {
        case 'name':
          return (
            <Stack verticalAlign="center">
              <Text>{item.name}</Text>
            </Stack>
          );
        case 'tagCategoryItemsByTagCategoryId':
          return (
            <Stack wrap horizontal tokens={{ childrenGap: 10 }}>
              {item.tagCategoryItemsByTagCategoryId.nodes.map((tag) => {
                return (
                  <ToggleButton
                    key={tag.id}
                    iconName="Accept"
                    toggled
                    text={tag._tagBadgeName || ''}
                    onClick={() => {}}
                    color={tag.tag?.badgeColor}
                  />
                );
              })}
            </Stack>
          );
        case 'metrics':
          return <TagCategoryCallout tagCategoryData={item!} />;
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                onClick={() => {
                  openUpdateModal(item);
                }}
                disabled={!item._isUpdatable}
              />
            </Stack>
          );
        default:
          return (
            <Stack verticalAlign="center">
              <Text>{item[key] ?? ''}</Text>
            </Stack>
          );
      }
    }
  };

  const openUpdateModal = (item: TagCategories_tagCategories_nodes) => {
    if (item) {
      setTagCategoryData(item);
      setIsNew(false);
    }
    toggelShowForm();
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Tag Categories</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.TagCategory}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete.name),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              iconProps={{
                iconName: 'Add',
              }}
              text="New Tag Category"
              onClick={() => {
                setIsNew(true);
                setTagCategoryData(null);
                toggelShowForm();
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          <SearchBar
            onRefresh={() => handleSearch(false)}
            searchEnabled={false}
            columns={[]}
          />
        </Stack>
      </Stack>

      <InfiniteList
        loading={tagCategorieLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={tagCategoriesData?.tagCategories?.pageInfo.hasNextPage}
        items={refetching ? undefined : tagCategoriesData?.tagCategories?.nodes}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onSelectionChanged={setSelectedRows}
        onLoadMore={() => handleSearch(true)}
      />
      {showForm && (
        <CreateTagCategoryForm
          visible={showForm}
          onDismiss={toggelShowForm}
          tagData={!isNew ? selectedTagCategory! : null}
          isNew={isNew}
        />
      )}
    </>
  );
};
