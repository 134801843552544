import { Stack, Text, makeStyles } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    cursor: 'move',
  },
}));

type HeaderProps = {
  onClose: () => void;
  title: string;
};

export const Header: React.FC<HeaderProps> = ({ onClose, title }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20,
      }}
    >
      <Stack>
        <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
          {title}
        </Text>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <CloseButton onClick={onClose} />
      </Stack>
    </Stack>
  );
};
