import { useReactiveVar } from '@apollo/client';
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsFooterProps,
  IRenderFunction,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import React from 'react';
import { setUserDefaults } from 'utility/cache/ui';
import {
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId,
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes,
} from '../__generated__/userApproval';
import { useStyles } from './index.styles';
import { getColumns } from './utils';

type ItemRow =
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes;

interface InvoiceAccountingListProps {
  data: userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId;
}

export const InvoiceAccountingList: React.FC<InvoiceAccountingListProps> = ({
  data,
}) => {
  const styles = useStyles();
  const userDefaults = useReactiveVar(setUserDefaults);
  const { columnArray } = getColumns(userDefaults);

  const renderColumn = (item?: ItemRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;
    const fieldContent = item[column.fieldName as keyof ItemRow] as
      | string
      | null;
    switch (column?.key) {
      case 'amount':
        return (
          <AmountTextView
            className={clsx(styles.amount)}
            value={fieldContent}
          />
        );
      case 'account':
        return (
          <TooltipHost content={item._accountName || ''}>
            <Text className={clsx(styles.paddingLeft25)}>{fieldContent}</Text>
          </TooltipHost>
        );
      default:
        return <Text>{fieldContent}</Text>;
    }
  };

  const renderFooterColumn = (
    _item?: ItemRow,
    _index?: number,
    column?: IColumn
  ) => {
    if (column?.key === 'amount' && data?.aggregates?.sum)
      return (
        <Text className={clsx(styles.amount, styles.amountTotal)}>
          {getFormattedAmountValue(
            Number(data.aggregates.sum.itemAmount1).toFixed(2)
          )}
        </Text>
      );

    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !data.aggregates?.sum?.itemAmount1) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  return (
    <Stack tokens={{ padding: '0px 0px 0px 10px' }}>
      <DetailsList
        compact
        columns={columnArray}
        items={data.nodes}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        onRenderItemColumn={renderColumn}
        onRenderDetailsFooter={renderFooter}
      />
    </Stack>
  );
};
