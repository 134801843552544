import React, { useState } from 'react';
import { IconButton, Stack } from '@fluentui/react';
import { LookupAccountFields } from '../__generated__/LookupAccountFields';
import { AccountsEntryModal } from './AccountsEntryModal';
import { ChartOfAccounts_chartOfAccounts_nodes } from '../list/chartAccount/__generated__/ChartOfAccounts';

export interface AccountsEntryViewProps {
  account: LookupAccountFields;
  chartOfAccount: ChartOfAccounts_chartOfAccounts_nodes;
}

export const AccountsEntryView: React.FC<AccountsEntryViewProps> = ({
  account,
  chartOfAccount,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { _isUpdatable } = { ...account };

  return (
    <Stack>
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        onClick={() => setIsOpen(true)}
        disabled={!_isUpdatable}
      />
      {isOpen && (
        <AccountsEntryModal
          chartOfAccount={chartOfAccount}
          account={account}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Stack>
  );
};
