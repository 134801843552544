import { Callout, DirectionalHint, Stack, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useCommonStyles } from 'common/styles';
import React, { useState } from 'react';
import { ReactComponent as ApprovedIcon } from '../../../../../../assets/svgs/ApproveStatusIcons/Approved.svg';
import { ReactComponent as DraftIcon } from '../../../../../../assets/svgs/ApproveStatusIcons/Draft.svg';
import { useStyles } from './index.styles';
enum StatusTypes {
  Active = "Active",
  Reconciled = "Reconciled",
}
interface StatusIconProps {
  statusType: string;
  id: string;
}
export const StatusIcon: React.FC<StatusIconProps> = ({
  statusType,
  id,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const calloutId = useId(`callOutId_Transactions${id}`);
  return (
    <>
      <Stack
        verticalAlign="center"
        id={calloutId}
        onMouseEnter={() => setIsCalloutVisible(true)}
        onMouseLeave={() => setIsCalloutVisible(false)}
      >
        <>{getIcon(statusType)}</>
        {isCalloutVisible &&
          (
            <Callout
              className={styles.callout}
              gapSpace={0}
              target={`#${calloutId}`}
              directionalHint={DirectionalHint.topCenter}
              onDismiss={() => setIsCalloutVisible(false)}
              setInitialFocus
            >
              <Stack className={styles.typeContainer}>
                <Text className={commonStyles.semibold} variant="smallPlus">
                  {statusType ? statusType : "Missing"}
                </Text>
              </Stack>
            </Callout>
          )}
      </Stack>
    </>
  );
};

const getIcon = (status: string) => {
  if (status === StatusTypes.Reconciled)
    return <ApprovedIcon />
  else
    return <DraftIcon />
};
