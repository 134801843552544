import React, { useState } from 'react';
import { SearchBar, SearchBarProps } from 'common/components/SearchBar';
import {
  ComboboxFilter,
  DropdownFilter,
  FilterArrayType,
} from 'common/components/Filters';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { ApprovalSetupCommonData } from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { useStyles } from './index.styles';
import { Stack } from '@fluentui/react';

const COMMON_DATA = loader('../../ApprovalSetupCommonData.graphql');

type ApprovalSetupSearchBarProps = Omit<SearchBarProps, 'searchEnabled'> & {
  onFilterChange: (filterArray: FilterArrayType[]) => void;
};

export const ApprovalSetupSearchBar: React.FC<ApprovalSetupSearchBarProps> = ({
  onFilterChange,
  ...props
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: commonData } = useQuery<ApprovalSetupCommonData>(COMMON_DATA);
  const styles = useStyles();
  const statusTypeOptions = formatDropdownOptions(
    commonData?.invoiceStatusTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType!,
      includeAll: true,
    }
  );
  const approvalTypeOptions = formatDropdownOptions(
    commonData?.approvalTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.approvalType!,
      includeAll: true,
    }
  );

  const onOptionChange = (filterData: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterData, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      searchEnabled={false}
      {...props}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20 }}
        className={styles.filtersWrapperStyle}
      >
        <ComboboxFilter
          options={approvalTypeOptions!}
          placeholder={'Type'}
          filterKey={'approvalTypeId'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_statusTypeId'}
          options={statusTypeOptions}
          placeholder={'Status'}
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
