import { useQuery } from '@apollo/client';
import { CommandBarButton, IDropdownOption, Stack } from '@fluentui/react';
import {
  FormHookCountryRegion,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { Countries } from 'common/graphql/__generated__/Countries';
import { loader } from 'graphql.macro';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CompanyValues } from '../../types';
import { useStyles } from './index.styles';

const COUNTRIES = loader('../../../../../common/graphql/Countries.graphql');

interface AddressFormFieldsProps {
  toList: () => void;
  isUpdatable: boolean | null | undefined;
  addressIndex: number;
}
export const AddressFormFields: React.FC<AddressFormFieldsProps> = ({
  toList,
  isUpdatable,
  addressIndex,
}) => {
  const styles = useStyles();
  const { control } = useFormContext<CompanyValues>();
  const { fields } = useFieldArray({
    name: 'companyAddresses',
    control,
  });

  const { data: countries } = useQuery<Countries>(COUNTRIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const countryOptions: IDropdownOption[] =
    countries?.countries?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.country,
    })) || [];
  const isDisabled = !isUpdatable;
  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        className={styles.defaultButtonContainer}
      >
        <CommandBarButton
          text="Address List"
          iconProps={{ iconName: 'Back' }}
          onClick={() => {
            toList();
          }}
          style={{
            width: 120,
            height: 40,
          }}
        />
      </Stack>
      {fields.length && (
        <>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
            }}
            className={styles.formRow}
          >
            <Stack.Item className={styles.fieldContainer}>
              <FormHookTextField
                name={`companyAddresses.${addressIndex}.addressLine1`}
                label="Street"
                placeholder="Street and Number, P.O. Box"
                disabled={isDisabled}
              />
            </Stack.Item>
            <Stack.Item className={styles.fieldContainer}>
              <FormHookTextField
                name={`companyAddresses.${addressIndex}.addressLine2`}
                label="Apartment"
                placeholder="Apartment, Suite, Unit, Building, Floor"
                disabled={isDisabled}
              />
            </Stack.Item>
            <Stack.Item className={styles.fieldContainer}>
              <FormHookTextField
                name={`companyAddresses.${addressIndex}.cityName`}
                label="City"
                placeholder="City, Town"
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
            }}
            className={styles.formRow}
          >
          
              <Stack.Item grow={2}>
                <FormHookCountryRegion
                  countries={countryOptions}
                  countryName={`companyAddresses.${addressIndex}.countryId`}
                  regionName={`companyAddresses.${addressIndex}.stateRegionId`}
                  CountryAutocompleteProps={{
                    label: 'Country',
                    errorMessage: '',
                  }}
                  StateRegionAutocompleteProps={{
                    label: 'State / Region / Province',
                    errorMessage: '',
                  }}
                  disabled={isDisabled}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <FormHookTextField
                  name={`companyAddresses.${addressIndex}.postalCode`}
                  label="Zip Code"
                  placeholder="Zip Code"
                  disabled={isDisabled}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <FormHookTextField
                  name={`companyAddresses.${addressIndex}.mainContact`}
                  label="Contact"
                  placeholder="Contact"
                  disabled={isDisabled}
                />
              </Stack.Item>
            </Stack>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
            }}
            className={styles.formRow}
          >
            <Stack.Item className={styles.fieldContainer}>
              <FormHookTextField
                name={`companyAddresses.${addressIndex}.telephoneNumber`}
                label="Phone"
                placeholder="Phone"
                disabled={isDisabled}
              />
            </Stack.Item>
            <Stack.Item className={styles.fieldContainer}>
              <FormHookTextField
                name={`companyAddresses.${addressIndex}.emailAddress`}
                label="Email"
                placeholder="Email"
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
        </>
      )}
    </>
  );
};
