import {
  ComboBox,
  IComboBoxStyles,
  IMaskedTextField,
  IMaskedTextFieldProps,
  ITimePickerProps,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import { useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

const comboBoxStyles: Partial<IComboBoxStyles> = { root: { width: 65 } };
export type FormikTimePickerProps = Omit<
  ITimePickerProps,
  'value' | 'onChange'
> & {
  onTimeChange?: (timeValue: string | null) => void;
};

export const FormikTimePicker: React.FC<
  RequiredNameProps<FormikTimePickerProps & IMaskedTextFieldProps>
> = ({ onTimeChange, ...props }) => {
  const { name } = { ...props };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, helpers] = useField<string | null>(name);

  const minRef = useRef<IMaskedTextField | undefined>(null);
  const hourRef = useRef<IMaskedTextField | undefined>(null);
  const [hourState, setHourState] = useState<string | null>(null);
  const [minuteState, setMinuteState] = useState<string | null>(null);
  const [meridianState, setMeridianState] = useState<'am' | 'pm'>('am');
  const renderRef = useRef<boolean>(true);

  const onHourChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: string | undefined
  ) => {
    if (newValue) {
      if (newValue.length <= 2 && parseInt(newValue) < 12) {
        setHourState(newValue);
      }
      if (parseInt(newValue) >= 12) {
        if (parseInt(newValue) === 12) {
          setHourState(newValue);

          minRef.current?.focus();
        } else {
          minRef.current?.focus();
        }
      }
    } else {
      setHourState(null);
    }
  };

  const onMinuteChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: string | undefined
  ) => {
    if (newValue) {
      if (newValue.length <= 2 && parseInt(newValue) <= 59) {
        setMinuteState(newValue);
      }
    } else {
      setMinuteState(null);
      hourRef.current?.focus();
    }
  };

  useEffect(() => {
    if (!renderRef.current) {
      const additional = meridianState === 'am' ? 0 : 12;
      const hours = (parseInt(hourState || '0') % 12) + additional; // Use modulo operator to handle switching between AM and PM
      const mins = parseInt(minuteState || '0');
      const minutes =
        mins !== 0 ? (mins < 10 ? '0' + mins.toString() : minuteState) : '00';

      const timeValue = `${
        hours < 10 ? '0' + hours.toString() : hours
      }:${minutes}:00`;

      helpers.setValue(timeValue);
      onTimeChange?.(field.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourState, minuteState, meridianState]);

  useEffect(() => {
    if (!!field.value) {
      if (!!hourState && !!minuteState) {
        renderRef.current = false;
      } else {
        const parts = field.value ? field.value.split(':') : null;
        const hours = parts ? parseInt(parts[0]) : null;
        const hr =
          hours === 0
            ? '12'
            : hours
            ? (hours > 12 ? hours - 12 : hours).toString()
            : null;

        setHourState(hr);
        setMinuteState(!!field.value && parts ? parts[1] : null);
        setMeridianState(
          !!field.value && parts
            ? parseInt(parts[0]) > 12 ||
              (parseInt(parts[0]) === 12 && parseInt(parts[1]) > 0)
              ? 'pm'
              : 'am'
            : 'am'
        );
      }
    } else {
      renderRef.current = false;
    }
  }, [field.value]);

  return (
    <Stack horizontal verticalAlign="end">
      <TextField
        underlined
        placeholder="HH"
        value={hourState || ''}
        // @ts-ignore
        componentRef={hourRef}
        onChange={onHourChange}
        styles={{
          fieldGroup: {
            width: 40,
          },
        }}
        disabled={props.disabled}
      />
      <Text variant="xLarge" styles={{ root: { marginBottom: 5 } }}>
        :
      </Text>

      <TextField
        underlined
        onChange={onMinuteChange}
        value={minuteState || ''}
        // @ts-ignore
        componentRef={minRef}
        placeholder="MM"
        styles={{
          fieldGroup: {
            width: 50,
          },
        }}
        disabled={props.disabled}
      />
      <ComboBox
        selectedKey={meridianState}
        onChange={(_, option) => {
          // @ts-ignore
          if (option?.key) setMeridianState(option?.key || 'am');
        }}
        options={[
          { key: 'am', text: 'AM' },
          { key: 'pm', text: 'PM' },
        ]}
        styles={comboBoxStyles}
        autoComplete="on"
        disabled={props.disabled}
      />
    </Stack>
  );
};
