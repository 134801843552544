import { useQuery } from '@apollo/client';
import {
  Callout,
  CommandBarButton,
  DirectionalHint,
  Image,
  Modal,
  Stack,
  Text,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { CurrentUserProfile } from 'common/graphql/__generated__/CurrentUserProfile';
import { SignatureSection } from 'documents/personalPool/Signature';
import { loader } from 'graphql.macro';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { useStyles } from 'Preferences/index.styles';

const CURRENT_USER_PROFILE = loader(
  '../../common/graphql/CurrentUserProfile.graphql'
);

export const Signature: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const calloutId = useId('callout-signature-calloutId');
  const [signatureVisible, setSignatureVisible] = useState(false);
  const styles = useStyles();

  const { data } = useQuery<CurrentUserProfile | undefined>(
    CURRENT_USER_PROFILE,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { _downloadLink } = { ...data?.currentUserProfile?.signatureDocument };

  return (
    <Stack>
      <CommandBarButton
        text="Signature"
        iconProps={{ iconName: 'RedEye' }}
        onClick={() => setVisible(true)}
        id={calloutId}
        styles={{ root: { height: 40 } }}
        onMouseEnter={() => setSignatureVisible(true)}
        onMouseLeave={() => setSignatureVisible(false)}
      />
      {visible && <SignatureModal onDismiss={() => setVisible(false)} />}

      {signatureVisible && (
        <Callout
          className={styles.signatureCallout}
          role="dialog"
          gapSpace={0}
          target={`#${calloutId}`}
          onDismiss={() => setSignatureVisible(false)}
          setInitialFocus
          directionalHint={DirectionalHint.topAutoEdge}
        >
          <Image
            style={{ objectFit: 'contain' }}
            width={250}
            height={100}
            className={styles.ImageContainer}
            src={_downloadLink!}
          />
        </Callout>
      )}
    </Stack>
  );
};

interface SignatureModalProps {
  onDismiss?: () => void;
}

export const SignatureModal: React.FC<SignatureModalProps> = ({
  onDismiss,
}) => {
  return (
    <Modal onDismiss={() => {}} isOpen>
      <Stack style={{ height: 700, width: 1000, overflowY: 'hidden' }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ padding: 25 }}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">Signature</Text>
          </Stack>
          <CloseButton onClick={onDismiss!} />
        </Stack>

        <SignatureSection />
      </Stack>
    </Modal>
  );
};
