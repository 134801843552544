import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: 50,
    },
    groupName: {
      color: theme.palette.themePrimary,
    },
    groupSelected: {
      backgroundColor: theme.palette.neutralLighter,
    },
    linkLightHover: {
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    cardAccountName: {
      ':focus': {
        color: theme.palette.themeDark,
      },
    },
    active: {
      color: theme.palette.accent,
      ':focus': {
        color: theme.palette.accent,
      },
    },
    iconButtonColor: {
      color: theme.palette.neutralDark,
    },
    descriptionCell: {
      width: 300,
      paddingLeft: 58,
    },
    rowCell: {
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      paddingLeft: 10,
    },
    currency: {
      width: 190,
      paddingLeft: 15,
    },
    contractual: {
      width: 100,
      paddingLeft: 15,
    },
    fareClass: {
      width: 200,
      paddingLeft: 15,
    },
    action: {
      width: 360,
      paddingLeft: 15,
    },
    closingDateContainer: {
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      paddingLeft: 10,
    },
    actionContainer: {
      // flex: 1.8,
      paddingLeft: 10,
      width: 370,
      minWidth: 370,
      maxWidth: 370,
    },
  };
});
