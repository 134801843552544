import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  callout: {
    width: 320,
    padding: '20px 24px',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
}));
