import { useMutation, useQuery } from '@apollo/client';
import { ContextualMenu, IDragOptions, Modal, Stack, makeStyles } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChartOfAccountDefaultUpdateInput, CorporateChartOfAccountPatch, CorporateChartOfAccountUpdateInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { CreateCorporateChartAccountsProps } from '..';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { Header } from './Header';
import { CorporateChartOfAccount, CorporateChartOfAccountVariables } from './__generated__/CorporateChartOfAccount';
import { CorporateChartOfAccountCreate, CorporateChartOfAccountCreateVariables } from './__generated__/CorporateChartOfAccountCreate';
import { CorporateChartOfAccountUpdate, CorporateChartOfAccountUpdateVariables } from './__generated__/CorporateChartOfAccountUpdate';
import { CorporateChartOfAccountsValues } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validation';
import { ChartOfAccountDefaultUpdate, ChartOfAccountDefaultUpdateVariables } from 'common/graphql/__generated__/ChartOfAccountDefaultUpdate';
const CORPORATE_CHART_OF_ACCOUNTS_CREATE = loader('./CorporateChartOfAccountCreate.graphql')
const CORPORATE_CHART_OF_ACCOUNTS_UPDATE = loader('./CorporateChartOfAccountUpdate.graphql')
const CORPORATE_CHART_OF_ACCOUNT = loader('./CorporateChartOfAccount.graphql')
const CORPORATE_CHART_OF_ACCOUNTS = loader('../../../../../../../common/graphql/CorporateChartOfAccounts.graphql')
const CHART_OF_ACCOUNT_DEFAULT_UPDATE = loader('../../../../../../../common/graphql/ChartOfAccountDefaultUpdate.graphql')

const useStyles = makeStyles(() => ({
  container: {
    width: 600,
    maxHeight: 600,
  }
}));

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

interface CorporateChartAccountsModalProps extends CreateCorporateChartAccountsProps {
  visible: boolean;
  isEdit?: boolean;
  onClose: () => void;
}

export const CorporateChartAccountsModal: React.FC<CorporateChartAccountsModalProps> = ({
  chartOfAccountId,
  visible,
  isEdit = false,
  onClose,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const formMethods = useForm<CorporateChartOfAccountsValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema())
  });

  const [createCorporateChartOfAccount, { loading: createCorporateChartOfAccountLoading }] =
    useMutation<CorporateChartOfAccountCreate, CorporateChartOfAccountCreateVariables>(
      CORPORATE_CHART_OF_ACCOUNTS_CREATE,
      { errorPolicy: 'all' }
    );

  const [updateCorporateChartOfAccount, { loading: updateCorporateChartOfAccountLoading }] =
    useMutation<CorporateChartOfAccountUpdate, CorporateChartOfAccountUpdateVariables>(
      CORPORATE_CHART_OF_ACCOUNTS_UPDATE,
      { errorPolicy: 'all' }
    );

  const [chartOfAccountDefaultUpdate, { loading: setDefaultLoading }] = useMutation<
    ChartOfAccountDefaultUpdate,
    ChartOfAccountDefaultUpdateVariables
  >(CHART_OF_ACCOUNT_DEFAULT_UPDATE, { errorPolicy: 'all' });

  const {
    data: corporateChartOfAccountData,
    refetch,
  } = useQuery<CorporateChartOfAccount, CorporateChartOfAccountVariables>(
    CORPORATE_CHART_OF_ACCOUNT,
    {
      variables: {
        id: chartOfAccountId!,
      },
      skip: !chartOfAccountId,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const {
    handleSubmit,
    reset,
    trigger,
  } = { ...formMethods };

  const defaultValues = getDefaultValues({
    isEdit,
    data: corporateChartOfAccountData,
  });

  const onHandleSubmit = async (values: CorporateChartOfAccountsValues) => {
    const {
      id,
      _rowTimestamp
    } = { ...corporateChartOfAccountData?.corporateChartOfAccount }
    if (isEdit) {
      const corporateChartOfAccountPatch: CorporateChartOfAccountPatch =
        Object.entries(values).reduce((res, [key, val]) => {
          if (val !== defaultValues[key as keyof CorporateChartOfAccountsValues]) {
            return { ...res, [key]: val };
          }
          return res;
        }, {});
      const { errors } = await updateCorporateChartOfAccount({
        variables: {
          input: {
            id,
            rowTimestamp: _rowTimestamp,
            corporateChartOfAccountPatch: !isEmpty(corporateChartOfAccountPatch)
              ? corporateChartOfAccountPatch
              : undefined,
          } as CorporateChartOfAccountUpdateInput,
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Corporate chart of account edited successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const { errors } = await createCorporateChartOfAccount({
        variables: {
          input: {
            chartOfAccount: {
              ...values,
              name: values.name || ''
            }
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: CORPORATE_CHART_OF_ACCOUNTS,
        }]
      })
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Corporate chart of account created successfully.', {
          appearance: 'success',
        });
        reset();
        onClose();
      }
    }
  }

  const onSetDefault = async () => {
    const { _defaults } = { ...corporateChartOfAccountData?.corporateChartOfAccount };
    const { _isDefaultUpdateAvailable } = { ..._defaults }
    const input: ChartOfAccountDefaultUpdateInput = !!_isDefaultUpdateAvailable ? {
      defaultChartOfAccountId: chartOfAccountId
    } : {};
    const successMessage = !!_isDefaultUpdateAvailable ? 'Default set successfully.' : 'Default removed successfully.'
    const { errors } = await chartOfAccountDefaultUpdate({
      variables: {
        input
      }
    })
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      refetch();
      addToast(successMessage, {
        appearance: 'success',
      });
    }
  }

  useEffect(() => {
    if (corporateChartOfAccountData?.corporateChartOfAccount) {
      const defaultValues =
        getDefaultValues({ isEdit, data: corporateChartOfAccountData });
      reset(defaultValues);
    }
    trigger();
  }, [corporateChartOfAccountData, isEdit, reset, trigger]);

  const loading = createCorporateChartOfAccountLoading || updateCorporateChartOfAccountLoading || setDefaultLoading;
  if (!visible) return null;

  return (
    <Modal
      isOpen
      isBlocking
      dragOptions={DragOptions}
    >
      <Stack
        className={styles.container}
      >
        <FormProvider {...formMethods}>
          <Header
            isEdit={isEdit}
            data={corporateChartOfAccountData}
            onClose={onClose}
          />
          <BasicForm
            isEdit={isEdit}
            data={corporateChartOfAccountData}
          />
          <Footer
            isEdit={isEdit}
            data={corporateChartOfAccountData}
            loading={loading}
            onSubmit={handleSubmit(onHandleSubmit)}
            onCancel={onClose}
            onSetDefault={onSetDefault}
          />
        </FormProvider>
      </Stack>
    </Modal>
  )
}
