import {
  FontWeights,
  ILabelStyles,
  Label,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { AccountingRowsView } from 'ap/paymentCycle/view/TransactionModal/AccountingRowsView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { InfoView } from 'common/components/InfoView';
import { StampOptions, StamperView } from 'common/components/StamperView';
import { useCommonStyles } from 'common/styles';
import { TransactionLayout } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { TransactionData } from '../__generated__/TransactionData';
import { MenuActions } from './ActionMenu';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { useStyles } from './index.styles';

interface TransactionDetailsProps {
  transactionData: TransactionData;
  loading: boolean;
}

const labelStyles: Partial<ILabelStyles> = {
  root: {
    fontWeight: FontWeights.bold,
  },
};

export const TransactionDetail: React.FC<TransactionDetailsProps> = ({
  transactionData,
  loading,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const {
    _accountingStampDate,
    _isTransactionCancelled,
    _accountingStampUserName,
    _accountingStampTransactionReference,
    _isAccountingEntryStampedComplete,
  } = { ...transactionData?.invoice! };
  const stampData: StampOptions = {
    _accountingStampDate,
    _isTransactionCancelled,
    _accountingStampUserName,
    _accountingStampTransactionReference,
    _isAccountingEntryStampedComplete,
  };

  const isPettyCashLayout: boolean =
    transactionData?.invoice?.transactionType?.layoutType ===
    TransactionLayout.PETTY_CASH;

  return (
    <>
      {!loading ? (
        <>
          {transactionData && <MenuActions invoiceData={transactionData!} />}
          <Separator />
          <Stack horizontal verticalAlign="center">
            <Stack className={styles.container}>
              <InfoView
                title={'Name'}
                description={
                  `${transactionData?.invoice?.vendorReference}` || '-'
                }
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Business Unit'}
                description={`${transactionData?.invoice?.businessUnit?.name
                  ? transactionData?.invoice?.businessUnit?.name
                  : '-'
                  }`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Department'}
                description={`${transactionData?.invoice?.department?.name
                  ? transactionData?.invoice?.department?.name
                  : '-'
                  }`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Date'}
                description={`${transactionData?.invoice?.invoiceDate
                  ? dateFormat(
                    dateConvertions(
                      transactionData?.invoice?.invoiceDate || ''
                    )
                  )
                  : '-'
                  }`}
              />
            </Stack>

            {transactionData?.invoice?._isAccountingEntry &&
              transactionData?.invoice._isHistory && (
                <StamperView invoiceDetails={stampData} />
              )}
          </Stack>
          <Separator />
          <Stack
            horizontal
            verticalAlign="center"
            className={styles.infoViewWrapper}
          >
            <Stack className={styles.container}>
              <InfoView
                title={'Description'}
                description={`${transactionData?.invoice?.description
                  ? transactionData?.invoice?.description
                  : '-'
                  }`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Number'}
                description={`${transactionData?.invoice?.invoiceNumber
                  ? transactionData?.invoice?.invoiceNumber
                  : '-'
                  }`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Amount'}
                currency={transactionData?.invoice?.currency?.isoCode || ''}
                description={
                  transactionData?.invoice?.controlTotalAmount || '-'
                }
                isAmount
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Period'}
                description={`${transactionData?.invoice?.companyCorporatePeriod?._periodYear
                  ? transactionData?.invoice?.companyCorporatePeriod
                    ?._periodYear
                  : '-'
                  }`}
              />
            </Stack>
            {isPettyCashLayout && (
              <Stack className={styles.container}>
                <Label styles={labelStyles}>Reimbursed Amount</Label>
                <AmountTextView
                  value={transactionData?.invoice?.reimburseAmount || '0'}
                />
              </Stack>
            )}
          </Stack>
          {isPettyCashLayout && (
            <Stack verticalAlign="center" className={styles.infoViewWrapper}>
              <Stack className={styles.container}>
                <InfoView
                  title="Account"
                  description={
                    transactionData?.invoice?.reimburseAccountReference || '-'
                  }
                />
              </Stack>
            </Stack>
          )}
          <Separator />
          {transactionData?.invoice?.invoiceDistributions?.nodes.length !==
            0 && (
              <Stack style={{ width: '70vw', padding: '0px 0px 0px -5px' }}>
                <Text className={commonStyles.bold}>Transaction Details</Text>
                {
                  transactionData?.invoice &&
                  <AccountingRowsView invoiceDetails={transactionData?.invoice} />
                }
              </Stack>
            )}
        </>
      ) : (
        <ShimmerView />
      )}
    </>
  );
};
