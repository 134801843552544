import { InvoiceSigningSearchFilterTotalsVariables } from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';
import { FilterArrayType } from 'common/components/Filters';
import { ToNullable } from 'utility/types';

type SearchTotalsFilterType = ToNullable<
  Omit<
    InvoiceSigningSearchFilterTotalsVariables,
    | 'isSigningSearch'
    | 'isHistorySearch'
    | 'invoiceSigningSearchSearchRequest'
    | 'isLinkSearch'
    | 'isSearchAllData'
  >
>;

// function getSearchTotalFilterArray<T>(): Record<keyof T, undefined> {
//   return {} as Record<keyof T, undefined>;
// }

// function getSearchTotalFilterArray<T>(): { [K in keyof T]: undefined } {
//   const result: { [K in keyof T]: undefined } = {} as { [K in keyof T]: undefined };
//   for (const key in result) {
//     result[key] = undefined;
//   }
//   return result;
// }

export const getFilterParams = (searchFilters: FilterArrayType[]) => {
  // const searchTotalFilterArray = getSearchTotalFilterArray<SearchTotalsFilterType>();

  const searchTotalFilterArray: SearchTotalsFilterType = {
    filterCurrencyId: undefined,
    filterTransactionTypeId: undefined,
    filterBusinessUnitId: undefined,
    filterDepartmentId: undefined,
    filterAccountingPeriodId: undefined,
    filterCompanyId: undefined,
    filterStatusTypeId: undefined,
    filterCreatedById: undefined,
  };

  searchFilters.forEach((curr) => {
    switch (curr.filterKey) {
      case 'transactionTypeId':
        return (searchTotalFilterArray['filterTransactionTypeId'] =
          curr.value as number | null);
      case 'businessUnitId':
        return (searchTotalFilterArray['filterBusinessUnitId'] =
          curr.value as GqlUUID | null);
      case 'departmentId':
        return (searchTotalFilterArray['filterDepartmentId'] =
          curr.value as GqlUUID | null);
      case 'corporatePeriodId':
        return (searchTotalFilterArray['filterAccountingPeriodId'] =
          curr.value as GqlUUID | null);
      case '_companyId':
        return (searchTotalFilterArray['filterCompanyId'] =
          curr.value as GqlUUID | null);
      case '_statusTypeId':
        return (searchTotalFilterArray['filterStatusTypeId'] = curr.value as
          | number
          | null);
      case '_createdByUserid':
        return (searchTotalFilterArray['filterCreatedById'] =
          curr.value as GqlUUID | null);
      case 'currencyId':
        return (searchTotalFilterArray['filterCurrencyId'] = curr.value as
          | number
          | null);
      default:
        return searchTotalFilterArray;
    }
  });
  return searchTotalFilterArray;
};
