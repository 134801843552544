import { AnimationClassNames, ContextualMenu, IDragOptions, Stack, makeStyles } from '@fluentui/react';
import { ModalWrapper } from 'common/components/ModalWrapper';
import React from 'react';
import { DocumentPackageEmailModalProps } from '..';
import { Header } from './Header';
import { EmailList } from './list';
const slideRightIn = AnimationClassNames.slideRightIn400;
const useStyles = makeStyles(() => ({
  container: {
    width: 800,
    maxHeight: 600,
    overflowX: "hidden"
  }
}));

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

type EmailListModalProps = Pick<DocumentPackageEmailModalProps, "emailDocumentsByEntityId" | "emailDocument"> & {
  isOpen: boolean;
  onClose: () => void;
  onCreate: () => void;
}

export const EmailListModal: React.FC<EmailListModalProps> = ({
  isOpen,
  emailDocument,
  emailDocumentsByEntityId,
  onClose,
  onCreate,
}) => {
  const styles = useStyles();

  return (
    <ModalWrapper
      isOpen={isOpen}
      isBlocking
      dragOptions={DragOptions}
    >
      <Stack className={styles.container}>
        <Header
          emailDocument={emailDocument}
          onClose={onClose}
          onCreate={onCreate}
        />
        <Stack
          className={slideRightIn}
        >
          <EmailList emailDocumentsByEntityId={emailDocumentsByEntityId} />
        </Stack>
      </Stack>
    </ModalWrapper>
  )
}
