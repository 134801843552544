import { FontSizes, makeStyles } from "@fluentui/react";
export const useStyles = makeStyles((theme) => ({
  defaultButtonContainer: {
    marginBottom: 30
  },
  formRow: {
    margin: "20px 0px"
  },
  fieldContainer: {
    flex: 1
  },
  sendCheckBoxContainer: {
    marginRight: 40
  },
  root: {
    height: 60,
    justifyContent: 'space-between',
  },
  rowCell: {
    padding: "20px 0px",
  },
  addressTypeLabel: {
    color: theme.palette.neutralDark,
    fontSize: FontSizes.size14,
  },
  addressSubtitle: {
    color: theme.palette.neutralSecondary,
  },
  deleteIcon: {
    color: theme.palette.accent
  }
}));