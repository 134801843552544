import { Stack } from '@fluentui/react';
import React, { useCallback } from 'react';
import { NewMessage } from './NewMessage';
import { useStyles } from './index.styles';
import {
  MessagesListsProps,
  MessagesSections,
  PivotKeys,
} from 'common/components/MessagesSections';
import { loader } from 'graphql.macro';
import { NetworkStatus, useQuery } from '@apollo/client';
import {
  BatchTransactionMessages,
  BatchTransactionMessagesVariables,
} from './__generated__/BatchTransactionMessages';

const TRAVELER_MESSAGES = loader('./BatchTransactionMessages.graphql');
interface BatchMessagesProps
  extends Pick<MessagesListsProps, 'userMessageCounts'> {
  batchID: string;
  onCreateMessage: () => void;
}

export const BatchMessages: React.FC<BatchMessagesProps> = ({
  batchID,
  userMessageCounts,
  onCreateMessage,
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = React.useState<string>(
    PivotKeys.received
  );

  const {
    loading: loadingMessages,
    data: messagesData,
    variables: messageListVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<BatchTransactionMessages, BatchTransactionMessagesVariables>(
    TRAVELER_MESSAGES,
    {
      variables: {
        id: batchID,
        first: 10,
        filter: { _isIncomingMessage: { equalTo: true } },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const onListChange = useCallback(
    async (listName?: string) => {
      if (listName) setSelectedKey(listName);
      const _isOutgoingMessage = { equalTo: listName === PivotKeys.sent };
      await refetch({
        ...messageListVariables,
        filter: {
          _isOutgoingMessage,
        },
      });
    },
    [refetch, messageListVariables]
  );

  const loadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        ...messageListVariables,
        after:
          messagesData?.batchTransaction?.userMessagesByEntityId.pageInfo
            .endCursor,
      },
    });
  }, [fetchMore, messageListVariables, messagesData]);

  const data =
    networkStatus === NetworkStatus.setVariables
      ? undefined
      : messagesData?.batchTransaction?.userMessagesByEntityId.nodes;
  return (
    <Stack className={styles.container}>
      <MessagesSections
        userMessageCounts={userMessageCounts}
        entityId={batchID}
        data={data || []}
        loadingMessages={loadingMessages}
        onListChange={onListChange}
        onLoadMore={loadMore}
        hasNextPage={
          messagesData?.batchTransaction?.userMessagesByEntityId?.pageInfo
            .hasNextPage
        }
      >
        <NewMessage
          onCreateMessage={onCreateMessage}
          selectedKey={selectedKey}
          batchID={batchID}
        />
      </MessagesSections>
    </Stack>
  );
};
