import { useTheme } from '@fluentui/react';
import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { commonPdfStyles } from 'common/components/PdfViewModal/common.stylesheet';
import { dateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { GetEntityDocument_entityDocument } from '../../../__generated__/GetEntityDocument';
import { getTableStyle } from './index.stylesheet';
import { TransactionHeader } from './TransactionHeader';
import {
  AppliedEntityDocuments_appliedEntityDocuments_aggregates,
  AppliedEntityDocuments_appliedEntityDocuments_nodes,
} from 'common/graphql/__generated__/AppliedEntityDocuments';

export interface PdfDocumentProps {
  selectedDocument: GetEntityDocument_entityDocument;
  entityDocuments:
    | AppliedEntityDocuments_appliedEntityDocuments_nodes[]
    | undefined;
  aggregates: AppliedEntityDocuments_appliedEntityDocuments_aggregates;
}

interface TransformData {
  transactionType: string;
  accountingStampTransactionReference: string;
  retiredAmount: string;
  vendorReference: string;
  overageAmount: string;
  appliedAmount: string;
  description: string;
  remainingBalance: string;
  invoiceDate: string;
  controlTotalAmount: string;
  openingBalance: string;
}

export const PdfViewerModal: React.FC<PdfDocumentProps> = ({
  selectedDocument,
  entityDocuments,
  aggregates,
}) => {
  const { palette } = useTheme();
  const styles = getTableStyle;
  const tableHeader = [
    {
      headerTitle: 'Type',
      key: 'transactionType',
      headerStyle: styles.headerStyle,
      wrapperStyle: styles.singleFlex,
    },
    {
      headerTitle: 'Trans#',
      key: 'accountingStampTransactionReference',
      headerStyle: styles.headerStyle,
      wrapperStyle: styles.singleFlex,
    },
    {
      headerTitle: 'Name',
      key: 'vendorReference',
      headerStyle: styles.headerStyle,
      wrapperStyle: styles.mediumFlex,
    },
    {
      headerTitle: 'Description',
      key: 'description',
      headerStyle: styles.headerStyle,
      wrapperStyle: styles.mediumFlex,
    },
    {
      headerTitle: 'Date',
      key: 'invoiceDate',
      headerStyle: styles.rightAlignheaderStyle,
      wrapperStyle: styles.rightAlignSingleFlex,
    },
    {
      headerTitle: 'Amount',
      key: 'controlTotalAmount',
      headerStyle: styles.rightAlignheaderStyle,
      wrapperStyle: styles.rightAlignSingleFlex,
    },
    {
      headerTitle: 'Opening',
      key: 'openingBalance',
      headerStyle: styles.rightAlignheaderStyle,
      wrapperStyle: styles.rightAlignSingleFlex,
    },
    {
      headerTitle: 'Applied',
      key: 'appliedAmount',
      headerStyle: styles.rightAlignheaderStyle,
      wrapperStyle: styles.rightAlignSingleFlex,
    },
    {
      headerTitle: 'Retired',
      key: 'retiredAmount',
      headerStyle: styles.rightAlignheaderStyle,
      wrapperStyle: styles.rightAlignSingleFlex,
    },
    {
      headerTitle: 'Overage',
      key: 'overageAmount',
      headerStyle: styles.rightAlignheaderStyle,
      wrapperStyle: styles.rightAlignSingleFlex,
    },

    {
      headerTitle: 'Remaining',
      key: 'remainingBalance',
      headerStyle: styles.rightAlignheaderStyle,
      wrapperStyle: styles.rightAlignSingleFlex,
    },
  ];

  const transformedData = entityDocuments
    ? entityDocuments.map(
        (data) =>
          ({
            transactionType: data.transactionType,
            accountingStampTransactionReference:
              data.accountingStampTransactionReference,
            retiredAmount: data.retiredAmount,
            vendorReference: data.vendorReference,
            overageAmount: data.overageAmount,
            description: data.description,
            appliedAmount: data.appliedAmount,
            remainingBalance: data.remainingBalance,
            invoiceDate: data.invoiceDate ? dateFormat(data.invoiceDate) : '-',
            controlTotalAmount: data.controlTotalAmount,
            openingBalance: data.openingBalance,
          } as TransformData)
      )
    : [];

  const renderFooter = (key: string, index: number, item: any) => {
    switch (key) {
      case 'retiredAmount': {
        return (
          <View style={item.wrapperStyle} key={index}>
            <Text
              style={[
                item.headerStyle!,
                { color: palette.themePrimary, fontWeight: 'bold' },
              ]}
            >
              {aggregates.sum?.retiredAmount
                ? getFormattedAmountValue(aggregates.sum?.retiredAmount)
                : ''}
            </Text>
          </View>
        );
      }

      case 'overageAmount': {
        return (
          <View style={item.wrapperStyle} key={index}>
            <Text
              style={[
                item.headerStyle!,
                { color: palette.themePrimary, fontWeight: 'bold' },
              ]}
            >
              {aggregates.sum?.overageAmount
                ? getFormattedAmountValue(aggregates.sum?.overageAmount)
                : ''}
            </Text>
          </View>
        );
      }
      case 'appliedAmount': {
        return (
          <View style={item.wrapperStyle} key={index}>
            <Text
              style={[
                item.headerStyle!,
                { color: palette.themePrimary, fontWeight: 'bold' },
              ]}
            >
              {aggregates.sum?.overageAmount
                ? getFormattedAmountValue(aggregates.sum?.appliedAmount)
                : ''}
            </Text>
          </View>
        );
      }
      case 'remainingBalance': {
        return (
          <View style={item.wrapperStyle} key={index}>
            <Text
              style={[
                item.headerStyle!,
                { color: palette.themePrimary, fontWeight: 'bold' },
              ]}
            >
              {selectedDocument?.documentAppliedAmounts?.remainingTotal!
                ? getFormattedAmountValue(
                    selectedDocument?.documentAppliedAmounts?.remainingTotal!
                  )
                : ''}
            </Text>
          </View>
        );
      }
      case 'controlTotalAmount': {
        return (
          <View style={item.wrapperStyle} key={index}>
            <Text
              style={[
                item.headerStyle!,
                { color: palette.themePrimary, fontWeight: 'bold' },
              ]}
            >
              {aggregates.sum?.controlTotalAmount
                ? getFormattedAmountValue(aggregates.sum?.controlTotalAmount)
                : ''}
            </Text>
          </View>
        );
      }

      case 'openingBalance': {
        return (
          <View style={item.wrapperStyle} key={index}>
            <Text
              style={[
                item.headerStyle!,
                { color: palette.themePrimary, fontWeight: 'bold' },
              ]}
            >
              {selectedDocument?.indexAmount!
                ? getFormattedAmountValue(selectedDocument?.indexAmount)
                : ''}
            </Text>
          </View>
        );
      }

      default: {
        return (
          <View style={item.wrapperStyle} key={index}>
            <Text style={item.headerStyle!}></Text>
          </View>
        );
      }
    }
  };
  return (
    <PDFViewer style={{ flex: 1 }}>
      <Document>
        <Page size="A4" style={commonPdfStyles.page}>
          <View style={{ flex: 1 }}>
            <TransactionHeader selectedDocument={selectedDocument} />
            <View style={styles.tableHeaderWrapper}>
              {tableHeader.map((item, index) => (
                <View style={item.wrapperStyle} key={index}>
                  <Text style={item.headerStyle}>{item.headerTitle}</Text>
                </View>
              ))}
            </View>

            {transformedData.map((item, index) => {
              return (
                <View key={index} style={styles.tableRowWrapper}>
                  <View style={[styles.singleFlex]}>
                    <Text style={styles.tableValueStyle}>
                      {item?.transactionType || '-'}
                    </Text>
                  </View>
                  <View style={[styles.singleFlex]}>
                    <Text style={styles.tableValueStyle}>
                      {item?.accountingStampTransactionReference || '-'}
                    </Text>
                  </View>

                  <View style={[styles.mediumFlex]}>
                    <Text style={styles.tableValueStyle}>
                      {item?.vendorReference || '-'}
                    </Text>
                  </View>
                  <View style={styles.mediumFlex}>
                    <Text style={styles.tableValueStyle}>
                      {item.description || '-'}
                    </Text>
                  </View>
                  <View style={styles.rightAlignSingleFlex}>
                    <Text style={styles.rightAlignTablevalueStyle}>
                      {item.invoiceDate! || '-'}
                    </Text>
                  </View>
                  <View style={styles.rightAlignSingleFlex}>
                    <Text style={styles.rightAlignTablevalueStyle}>
                      {item.controlTotalAmount
                        ? getFormattedAmountValue(item?.controlTotalAmount)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.rightAlignSingleFlex}>
                    <Text style={styles.rightAlignTablevalueStyle}>
                      {item.openingBalance
                        ? getFormattedAmountValue(item?.openingBalance)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.rightAlignSingleFlex}>
                    <Text style={styles.rightAlignTablevalueStyle}>
                      {item.appliedAmount
                        ? getFormattedAmountValue(item?.appliedAmount)
                        : '-'}
                    </Text>
                  </View>
                  <View style={[styles.rightAlignSingleFlex]}>
                    <Text style={styles.rightAlignTablevalueStyle}>
                      {item?.retiredAmount
                        ? getFormattedAmountValue(item?.retiredAmount)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.rightAlignSingleFlex}>
                    <Text style={styles.rightAlignTablevalueStyle}>
                      {item?.overageAmount
                        ? getFormattedAmountValue(item?.overageAmount)
                        : '-'}
                    </Text>
                  </View>

                  <View style={styles.rightAlignSingleFlex}>
                    <Text style={styles.rightAlignTablevalueStyle}>
                      {item.remainingBalance
                        ? getFormattedAmountValue(item?.remainingBalance)
                        : '-'}
                    </Text>
                  </View>
                </View>
              );
            })}

            <View style={styles.tableBottomHeaderWrapper}>
              {tableHeader.map((item, index) => {
                return renderFooter(item.key, index, item);
              })}
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
