import { IconButton, Stack, Text } from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import React, { useState } from 'react';
import CollapsedLogo from '../../../assets/svgs/logo_collapsed.svg';
import { useStyles } from './index.styles';

export const OpenApp: React.FC = ({ children }) => {
  const styles = useStyles();
  const [sessionCheck, setSessionCheck] = useState(true);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /(iPhone|iPod|iPad|MacIntel)/i.test(navigator.platform);

  if (isMobile && sessionCheck)
    return (
      <Stack
        style={{ width: '100wh', height: '100vh', backgroundColor: 'white' }}
      >
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          tokens={{ padding: '20px 5px' }}
          className={styles.container}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
            <Stack
              tokens={{ childrenGap: 5 }}
              horizontal
              verticalAlign="center"
            >
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                onClick={() => {
                  setSessionCheck(false);
                }}
              />
              <Stack
                className={styles.logoContainer}
                verticalAlign="center"
                horizontalAlign="center"
              >
                <img
                  src={CollapsedLogo}
                  style={{ height: 50, width: 50 }}
                  alt="website logo"
                />
              </Stack>
            </Stack>

            <Stack>
              <Text variant="xLarge">CUT! Expense</Text>
              <Text>CUT! Solutions</Text>
              <Text>
                Free on {isIOS ? 'App' : isAndroid ? 'Play' : ' '} Store
              </Text>
            </Stack>
          </Stack>

          <Stack horizontal tokens={{ padding: '0px 10px 0px 0px' }}>
            <button
              className={styles.button}
              onClick={() => {
                if (isIOS) {
                  // still trying to figure out how to handle this using single link
                  const store_link =
                    'https://apps.apple.com/us/app/cut-expenses/id1602372522/';
                  // const app_link = 'cutsolutions://';

                  window.location.replace(store_link);
                  // setTimeout(() => {
                  //   window.location.replace(store_link);
                  // }, 2000);
                }

                if (isAndroid) {
                  const url =
                    'intent://cutexpense.com/#Intent;scheme=https;package=com.cutsolutions.app;end';
                  window.location.replace(url);
                }
              }}
            >
              View
            </button>
          </Stack>
        </Stack>

        <Stack
          className={styles.containerBox}
          verticalAlign="center"
          horizontalAlign="center"
        >
          <img
            src={CollapsedLogo}
            style={{ height: 150, width: 150 }}
            alt="website logo"
          />
          <Text variant="xLarge">Cut! Expense</Text>
        </Stack>
      </Stack>
    );
  else return <>{children}</>;
};
