import React, { useState } from 'react';
import {
  Callout,
  DirectionalHint,
  FontIcon,
  Link,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { LookupNameSearch_lookupNameSearch_nodes } from './__generated__/LookupNameSearch';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';

interface LookUpCalloutProps {
  data: LookupNameSearch_lookupNameSearch_nodes[];
  onDismiss?: () => void;
  targetID: string;
  onSelect: (value: LookupNameSearch_lookupNameSearch_nodes) => void;
  lookUpCalloutWidth?: number;
}

export const LookUpCallout: React.FC<LookUpCalloutProps> = ({
  onDismiss,
  data,
  targetID,
  onSelect,
  lookUpCalloutWidth,
}) => {
  const styles = useStyles();

  if (!data?.length) return null;
  return (
    <Callout
      onDismiss={onDismiss}
      gapSpace={10}
      isBeakVisible={false}
      className={styles.callout}
      directionalHint={DirectionalHint.bottomLeftEdge}
      target={targetID}
      style={{ width: lookUpCalloutWidth }}
    >
      <Stack className={styles.searchAreaContainer}>
        <Stack>
          {data?.map((item, index) => {
            const detailsTargetId = `details${item.id
              ?.replace(/-+/g, '')
              .trim()}`;
            return (
              <DetailsCallout
                onSelect={onSelect}
                onDismiss={onDismiss}
                data={item}
                key={index.toString()}
                detailsTargetId={detailsTargetId}
              />
            );
          })}
        </Stack>
      </Stack>
    </Callout>
  );
};

type DetailsCallout = Pick<LookUpCalloutProps, 'onDismiss' | 'onSelect'> & {
  data: LookupNameSearch_lookupNameSearch_nodes;
  detailsTargetId: string;
};

export const DetailsCallout: React.FC<DetailsCallout> = ({
  onSelect,
  onDismiss,
  data,
  detailsTargetId,
}) => {
  const styles = useStyles();
  const [calloutVisible, setCalloutVisible] = useState(false);
  const commonStyles = useCommonStyles();
  return (
    <>
      <Link
        onClick={() => {
          onSelect(data);
          onDismiss?.();
        }}
        id={detailsTargetId}
        onMouseEnter={() => setCalloutVisible(true)}
        onMouseLeave={() => setCalloutVisible(false)}
        className={styles.resultItem}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Text>{data.lookupName} </Text>
          {data.isApprovedVendor && <FontIcon iconName="accept" />}
        </Stack>
      </Link>

      {calloutVisible && data.isApprovedVendor && (
        <Callout
          onDismiss={onDismiss}
          isBeakVisible={true}
          className={styles.detailsCallout}
          directionalHint={DirectionalHint.rightCenter}
          target={`#${detailsTargetId}`}
        >
          <Stack className={styles.calloutContainer}>
            <Stack
              horizontalAlign="space-between"
              horizontal
              tokens={{ padding: '10px' }}
            >
              <Text
                className={clsx(
                  commonStyles.semibold,
                  commonStyles.colorThemePrimary
                )}
                variant="mediumPlus"
              >
                {data.lookupName}
              </Text>

              <Text
                className={clsx(
                  commonStyles.semibold,
                  commonStyles.colorThemePrimary,
                  styles.lookupCode
                )}
              >
                #{data.lookupCode}
              </Text>
            </Stack>
            <Separator className={styles.separator} />
            <Stack horizontal>
              <Stack
                tokens={{
                  childrenGap: 10,
                  padding: '0px 10px 10px',
                }}
              >
                {data.lookupName2 && <Text>{data.lookupName2}</Text>}
                {data.address1 && <Text>{data.address1}</Text>}
                {data.address2 && <Text>{data.address2}</Text>}
                <Stack horizontal>
                  {data.city && <Text>{`${data.city + '   '},`} </Text>}
                  {data.city && <Text>{`${data.state + '    '},`} </Text>}
                  {data.city && <Text>{data.zipCode + '     '}</Text>}
                  <Text>{data.country}</Text>
                </Stack>
                <Stack horizontal>
                  {data.emailAccount && <Text>{`${data.emailAccount} ,`}</Text>}
                  <Text>{data.phone}</Text>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Callout>
      )}
    </>
  );
};
