import { makeStyles } from "@fluentui/react";
export const useStyles = makeStyles((theme) => ({
  defaultButtonContainer: {
    marginBottom: 30
  },
  formRow: {
    margin: "20px 0px"
  },
  fieldContainer: {
    flex: 1
  },
  websiteFieldContainer: {
    flex: 0.32
  },
  sendCheckBoxContainer: {
    marginRight: 40
  },
}));