import { ActionButton, IDropdownOption, Stack, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import { ListFilterCallout } from './ListFilterCallout';
import { Badge } from './Badge';
import { filterOptionProps } from 'documents/documentAssignment/folder/list/GroupHeader';
export interface ListFiltersProps {
  filterOptions: filterOptionProps;
  documentPoolId: string;
  documentTypeOptions: IDropdownOption[];
  onFilterChange: (filterOptions: filterOptionProps) => void;
}

export const ListFilters: React.FC<ListFiltersProps> = ({
  documentPoolId,
  ...props
}) => {
  const calloutId = useId(`filter-callOutId${documentPoolId}`);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const filterToggle = () => setFiltersApplied((prevState) => !prevState);

  const filtersCount = props.filterOptions?.filterTypes?.length;

  const historyPos = props.filterOptions.filterTypes.findIndex(
    (item) => item.filterKey === '_isSelected'
  );

  const filtersNumber =
    historyPos === -1
      ? 0
      : props.filterOptions.filterTypes[historyPos].value === false
        ? filtersCount - 1
        : filtersCount;

  return (
    <Stack horizontal>
      <TooltipHost content={filtersApplied ? 'Close filters' : 'Open Filters'}>
        <Badge visible={filtersNumber > 0} filtersCount={filtersNumber}>
          <ActionButton
            onClick={filterToggle}
            id={calloutId}
            iconProps={{ iconName: filtersApplied ? 'ClearFilter' : 'Filter' }}
            text="Filters"
          />
        </Badge>
      </TooltipHost>
      {filtersApplied && (
        <ListFilterCallout
          onDismiss={filterToggle}
          calloutId={calloutId}
          {...props}
        />
      )}
    </Stack>
  );
};
