import React from 'react';
import { Stack } from '@fluentui/react';
import { useStyles } from './index.styles';
import { FormikCheckBox, FormikTextField } from 'common/components';

export const BasicForm = () => {
  const styles = useStyles();
  return (
    <Stack>
      <Stack tokens={{ childrenGap: 20, padding: 25 }}>
        <FormikTextField
          name="name"
          label="Name"
          placeholder="Enter name"
          required
        />
        <FormikTextField
          name="description"
          label="Description"
          multiline
          placeholder="Enter description"
        />

        <Stack
          horizontal
          className={styles.checkBoxContainer}
          tokens={{ childrenGap: 30 }}
        >
          <FormikCheckBox name="isReports" label="Reports" boxSide="end" />
          <FormikCheckBox name="isMessages" label="Messages" boxSide="end" />
        </Stack>
      </Stack>
    </Stack>
  );
};
