import {
  DefaultButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import React, { useState } from 'react';
import { CloseButton } from '../Buttons';
import { availableGroups, ContactForm } from './ContactsForm';
import { useStyles } from './index.styles';
import { MessageCreate } from './MessageCreate';

export interface CreateMessageData {
  receivers: availableGroups;
  messageComment: string;
  reminderDates: string[];
  isResponseRequired: boolean;
}
interface CreateNewMessageProps {
  onSubmit?: (data: CreateMessageData) => void;
  onCancel?: () => void;
  loading?: boolean;
  dirty?: boolean;
}

export const CreateNewMessage: React.FC<CreateNewMessageProps> = ({
  onSubmit,
  dirty,
  ...props
}) => {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  return (
    <>
      <DefaultButton
        styles={{ root: { width: 200 } }}
        text="Create New Message"
        disabled={dirty}
        onClick={() => setDialogVisible(true)}
      />
      {dialogVisible && (
        <NewMessageModal
          onCancel={() => setDialogVisible(false)}
          onSubmit={(data) => {
            onSubmit?.(data);
            setDialogVisible(false);
          }}
          {...props}
        />
      )}
    </>
  );
};

const NewMessageModal: React.FC<CreateNewMessageProps> = ({
  onSubmit,
  onCancel,
  loading,
}) => {
  const styles = useStyles();
  const [datesSelected, setDatesSelected] = useState<string[]>([]);
  const [message, setMessage] = useState<undefined | string>();
  const [requestCheck, setRequestCheck] = useState<undefined | boolean>();
  const [contactGroups, setContactGroups] = useState<availableGroups>();

  const onclick = () => {
    onSubmit?.({
      receivers: contactGroups!,
      reminderDates: datesSelected,
      messageComment: message!,
      isResponseRequired: requestCheck!,
    });
  };

  const { communicationChannel, department, user } = { ...contactGroups };
  const noContactsSelected =
    !communicationChannel?.length && !department?.length && !user?.length;

  const disabled = !message || loading || noContactsSelected;

  return (
    <Modal
      isOpen={true}
      isBlocking
      onDismiss={onCancel}
      containerClassName={styles.modalContainer}
    >
      <Stack className={styles.root}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          className={styles.header}
          tokens={{ padding: 25 }}
        >
          <Text variant="xLarge">Create New Message</Text>
          <CloseButton onClick={onCancel!} />
        </Stack>
        <Stack horizontal grow>
          <MessageCreate
            existingDates={datesSelected}
            onDatesUpdate={setDatesSelected}
            onMessageUpdate={setMessage}
            onRequestCheck={setRequestCheck}
          />
          <Separator vertical />
          <ContactForm onContactsUpdated={setContactGroups} />
        </Stack>

        {loading && <ProgressIndicator />}
        <Stack
          horizontal
          tokens={{ padding: 25, childrenGap: 20 }}
          horizontalAlign="end"
        >
          <PrimaryButton text="Send" disabled={disabled} onClick={onclick} />
          <DefaultButton text="Cancel" onClick={onCancel} />
        </Stack>
      </Stack>
    </Modal>
  );
};
