import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const colHeaderRightAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const useColumns = () => {
  const { isSm, isLg } = useGetScreenWidth();

  const columns: ColumnData[] = [
    {
      key: 'paymentReference',
      name: 'Payment Number',
      fieldName: 'paymentReference',
      minWidth: 150,
      maxWidth: 180,
      ...commonColumnProps,
      isSorted: true,
      isSortedDescending: false,
    },
    {
      key: 'vendorReference',
      name: 'Pay To (Name)',
      fieldName: 'vendorReference',
      minWidth: 250,
      maxWidth: 250,
      ...commonColumnProps,
    },
    {
      key: 'paidDate',
      name: 'Payment Date',
      fieldName: 'paidDate',
      minWidth: 120,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
    },
    {
      key: 'payCycle',
      name: 'Cycle',
      fieldName: 'payCycle',
      minWidth: 120,
      maxWidth: 140,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'type',
      name: 'Type',
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'account',
      name: 'Account',
      fieldName: 'account',
      minWidth: 150,
      maxWidth: 180,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'businessUnit',
      name: 'Business Unit',
      fieldName: 'businessUnit',
      minWidth: 100,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'paidAmount',
      name: 'Amount',
      fieldName: 'paidAmount',
      minWidth: 100,
      maxWidth: 120,
      ...commonColumnProps,
      styles: {
        cellTitle: {
          justifyContent: 'flex-end',
          marginRight: 30,
        },
      },
    },
    {
      key: 'currency',
      name: 'Currency',
      fieldName: 'currency',
      minWidth: 50,
      maxWidth: 60,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: '_baseCurrencyAmount',
      name: 'Base Amount',
      fieldName: '_baseCurrencyAmount',
      minWidth: 80,
      maxWidth: 100,
      ...commonColumnProps,
      styles: colHeaderRightAlignStyle,
      isVisible: false,
    },
    {
      key: '_spotCurrencyAmount',
      name: 'Spot Rate',
      fieldName: '_spotCurrencyAmount',
      minWidth: 100,
      maxWidth: 110,
      ...commonColumnProps,
      styles: colHeaderRightAlignStyle,
      isVisible: false,
    },
    {
      key: 'production',
      name: 'Production',
      fieldName: 'production',
      minWidth: 100,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 160,
      ...commonColumnProps,
    },
  ];

  return { columns };
};
export { useColumns };
