import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';

import { useStyles } from '../index.styles';
interface FooterProps {
  DisplaySaveButton: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  onClose,
  onSave,
  DisplaySaveButton,
}) => {
  const styles = useStyles();
  return (
    <Stack
      horizontal
      horizontalAlign="end"
      className={styles.footerContainer}
      tokens={{
        childrenGap: 20,
      }}
    >
      <PrimaryButton
        text="Save"
        onClick={onSave}
        disabled={!DisplaySaveButton}
      />
      <DefaultButton text="Cancel" onClick={onClose} />
    </Stack>
  );
};
