import React, { useEffect, useState } from 'react';
import {
  Target,
  Callout,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { loader } from 'graphql.macro';
import { UserRoles } from 'common/graphql/__generated__/UserRoles';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';
import { currentUserRole } from 'utility/cache';
import {
  SetPreferredRole,
  SetPreferredRoleVariables,
} from 'common/graphql/__generated__/SetPreferredRole';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useCommonStyles } from 'common/styles';

const SET_PREFERRED_ROLE = loader(
  'src/common/graphql/SetPreferredRole.graphql'
);
const ROLES = loader('src/common/graphql/UserRoles.graphql');

interface ChangeProjectRoleCalloutProps {
  target: Target;
  onDismiss: () => void;
}

export const ChangeProjectRoleCallout: React.FC<ChangeProjectRoleCalloutProps> =
  ({ target, onDismiss }) => {
    const styles = useStyles();
    const commonStyles = useCommonStyles();

    const [roles, setRoles] = useState<IDropdownOption[]>([]);
    const [selectedTenant, setSelectedTenant] = useState<string | undefined>();
    const [selectedRole, setSelectedRole] = useState<string | undefined>();

    const history = useHistory();
    const { addToast } = useToasts();
    const client = useApolloClient();

    const { data: userRolesData } = useQuery<UserRoles>(ROLES);
    const [setPreferredRole] = useMutation<
      SetPreferredRole,
      SetPreferredRoleVariables
    >(SET_PREFERRED_ROLE, { errorPolicy: 'all' });

    const availableTenants: IDropdownOption[] = uniqBy(
      userRolesData?.availableRoles.map(({ tenantId, tenantName }) => ({
        key: tenantId,
        text: tenantName,
      })),
      (tenant) => tenant.key
    ).sort((a, b) => a.text.localeCompare(b.text));

    useEffect(() => {
      setSelectedRole(currentUserRole()?.id);
      setSelectedTenant(currentUserRole()?.tenantId);

      const availableTenantRoles: IDropdownOption[] =
        userRolesData?.availableRoles
          .filter((role) => role.tenantId === currentUserRole()?.tenantId)
          .sort((a, b) => a.description.localeCompare(b.description))
          .map(({ id, description }) => ({
            key: id,
            text: description,
          })) || [];

      setRoles(availableTenantRoles);
    }, [userRolesData]);

    const handleChangeRole = async () => {
      if (
        selectedRole &&
        selectedRole !== currentUserRole()?.id &&
        userRolesData?.availableRoles
      ) {
        const newRole = userRolesData?.availableRoles.find(
          (role) => role.id === selectedRole
        );
        const isChangedProject =
          newRole?.tenantId !== currentUserRole()?.tenantId;

        currentUserRole(newRole);
        const { errors } = await setPreferredRole({
          variables: { userRoleId: selectedRole },
        });
        if (errors?.length)
          addToast(errors[0].message, {
            appearance: 'error',
          });
        else {
          history.push('/');
          client.resetStore();

          addToast(
            `Active ${isChangedProject ? 'project' : 'user'} has been changed`,
            {
              appearance: 'success',
            }
          );
        }
      }
    };

    return (
      <Callout target={target} onDismiss={onDismiss} setInitialFocus>
        <Stack tokens={{ padding: '20px 24px', childrenGap: 16 }}>
          <Text variant="medium" className={commonStyles.semibold}>
            Change Project
          </Text>

          <Dropdown
            className={styles.dropdown}
            selectedKey={selectedTenant}
            options={availableTenants}
            onChange={(_e, selectedOption) => {
              const availableTenantRoles =
                userRolesData?.availableRoles
                  .filter((role) => role.tenantId === selectedOption?.key)
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map(({ id, description }) => ({
                    key: id,
                    text: description,
                  })) || [];

              setRoles(availableTenantRoles);
              setSelectedTenant(selectedOption?.key.toString());
              setSelectedRole(availableTenantRoles[0].key);
            }}
          />

          <Dropdown
            className={styles.dropdown}
            selectedKey={selectedRole}
            options={roles}
            onChange={(_e, selectedOption) =>
              setSelectedRole(selectedOption?.key.toString())
            }
          />
          <Stack tokens={{ padding: '8px 0 0 0' }}>
            <PrimaryButton
              disabled={currentUserRole()?.id === selectedRole}
              onClick={async () => {
                await handleChangeRole();
                onDismiss();
              }}
              text="Update"
            />
          </Stack>
        </Stack>
      </Callout>
    );
  };
