import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  modalInsideStack: {
    width: 1100,
    height: 600,
  },
  stickyInsideStack: {
    alignItems: 'self-start',
    padding: 25,
    cursor: 'move',
  },
  stack: {
    padding: 25,
  },
  footerActionButtons: {
    padding: '20px 24px',
  },
  footerRow: {
    backgroundColor: theme.palette.neutralLighter,
  },
  amountStack: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  onrenderColumnStack: {
    height: 25,
  },
}));
