import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  row: {
    minWidth: '100% !important',
  },
  backgroundcolor: {
    backgroundColor: theme.palette.neutralLighter,
  },
  container: {
    position: 'relative',
  },
  header: {
    paddingTop: 0,
  },
  totalCount: {
    color: theme.palette.themePrimary,
    fontWeight: FontWeights.bold,
  },
}));
