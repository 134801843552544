import { Stack, Text } from '@fluentui/react';
import React from 'react';
import { userApproval_userApproval } from '../__generated__/userApproval';
import { useStyles } from './index.styles';
import { ApprovalHistory_approvalHistory } from '../__generated__/ApprovalHistory';

interface AdditionalInformationProps {
  data?: userApproval_userApproval | ApprovalHistory_approvalHistory | null;
}

export const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  data,
}) => {
  const styles = useStyles();

  return (
    <Stack
      tokens={{ childrenGap: 20 }}
      className={styles.additionalContainerWrapper}
    >
      <Text variant="xLarge">Additional Information</Text>
      {data?.approvalHistoryItemsByDisplayEntityApprovalId.nodes.map(
        (user, index) => (
          <Text key={index}>{user.itemDescription1}</Text>
        )
      )}
    </Stack>
  );
};
