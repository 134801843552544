import React from 'react';
import { BlockBlobClient } from '@azure/storage-blob';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { UploadFiles } from 'common/components/UploadFiles';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { useFormikContext } from 'formik';
import { GetPayCycleDetails_payCycle } from 'ap/paymentCycle/view/__generated__/GetPayCycleDetails';
import { PayCycleAvailableDocumentTypes } from 'ap/paymentCycle/__generated__/PayCycleAvailableDocumentTypes';
import { PayCycleUploadDocument, PayCycleUploadDocumentVariables, PayCycleUploadDocument_payCycleUploadDocument } from 'ap/paymentCycle/__generated__/PayCycleUploadDocument';
const PAYCYCLE_UPLOAD_DOCUMENT = loader('../../../../PayCycleUploadDocument.graphql');
interface UploadFormProps {
  availableDocumentTypes: PayCycleAvailableDocumentTypes | undefined;
  paymentCycle?: GetPayCycleDetails_payCycle;
  onUpload?: (
    fileSelected: File,
    documentData: PayCycleUploadDocument_payCycleUploadDocument,
    toastId: string
  ) => void;
}
export const UploadForm: React.FC<UploadFormProps> = ({
  availableDocumentTypes,
  paymentCycle,
  onUpload,
}) => {
  const { dirty } = useFormikContext();
  const [uploadDocument] = useMutation<
    PayCycleUploadDocument,
    PayCycleUploadDocumentVariables
  >(PAYCYCLE_UPLOAD_DOCUMENT, { errorPolicy: 'all' });
  const { updateToast, addToast } = useToasts();
  const { isDraft, id } = { ...paymentCycle };
  const documentTypeOptions =
    availableDocumentTypes?.payCycleAvailableDocumentTypes?.nodes.map(
      (doctype) =>
      ({
        key: doctype.id,
        text: doctype.documentType || '',
        isAccountingDocument: doctype.isAccountingDocument,
        extractionTypes: doctype.extractionTypes,
        title:
          isDraft && doctype.isAccountingDocument
            ? 'Upload prohibited during/after the approval process'
            : undefined,
        disabled: (!isDraft && doctype.isAccountingDocument) || undefined,
      } as DocumentTypeOption)
    ) || [];
  return (
    <UploadFiles
      documentTypes={documentTypeOptions!}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, filSelected) => {
          filSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });
            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                payCycleId: id!,
              },
            });
            if (uploadMutationResults.errors) {
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });
            }
            if (
              uploadMutationResults.data?.payCycleUploadDocument?.document &&
              uploadMutationResults.data?.payCycleUploadDocument?.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data?.payCycleUploadDocument,
                toastId!
              );
              const client = new BlockBlobClient(
                uploadMutationResults.data?.payCycleUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
      disableUpload={dirty}
    />
  );
};
