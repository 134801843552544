import { Stack, Text } from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';
import { Chart } from '../types';
import useResizeObserver from 'use-resize-observer';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { ChartEmpty, ChartEmptyProps } from './ChartEmpty';
import { ChartLoading } from './ChartLoading';

export enum ChartSize {
  Small = 500,
  Medium = 750,
}

export interface ChartContainerProps<TData> {
  title: string;
  chart: Chart<TData>;
  showOnEmpty?: ChartEmptyProps;
  size?: ChartSize;
}

const HEIGHT = 350;

export const ChartContainer = <TData,>({
  title,
  chart,
  showOnEmpty,
  size = ChartSize.Small,
}: ChartContainerProps<TData>) => {
  const styles = useStyles();
  const {
    ref,
    width = size,
    height = HEIGHT,
  } = useResizeObserver<HTMLDivElement>();

  const { Component, hasData, query } = chart;

  const { data, loading } = useQuery<TData>(query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const empty = !data || !hasData(data);

  if (!loading && empty && !showOnEmpty) return null;

  return (
    <Stack grow style={{ width: size }}>
      <Text
        className={clsx(
          loading ? styles.titleLoading : styles.titleReady,
          styles.chartTitle
        )}
      >
        {title}
      </Text>
      <div
        className={styles.chartContainer}
        style={{ height: HEIGHT, width: '100%' }}
        ref={ref}
      >
        {loading ? (
          <ChartLoading />
        ) : empty ? (
          <ChartEmpty {...showOnEmpty!} />
        ) : (
          <Component data={data!} height={height} width={width} />
        )}
      </div>
    </Stack>
  );
};
