import { IColumn, Icon, Stack, Text } from '@fluentui/react';
import React from 'react';

import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { DownloadButton } from 'common/components/DownloadButton';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { GetEntityDocument_entityDocument } from 'documents/documentAssignment/folder/view/__generated__/GetEntityDocument';
import { AvailablePurchaseOrderRevisionsItem } from '.';
import { POLink } from './POLink';
import { ViewDocument } from './ViewDocument';

export type ColumnStylesProps = {
  onRenderColumnStack: string;
};

export const onRenderItems = (
  styles: ColumnStylesProps,
  entityDocument: GetEntityDocument_entityDocument
) => {
  const renderItemColumn = (
    item: AvailablePurchaseOrderRevisionsItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof AvailablePurchaseOrderRevisionsItem
      ] as string;
      switch (column?.key) {
        case 'indexName':
          return (
            <Stack
              className={styles.onRenderColumnStack}
              verticalAlign="center"
              tokens={{ padding: "0px 0px 0px 20px" }}
            >
              <POLink
                text={fieldContent}
                entityDocument={entityDocument}
                destinationEntityDocumentId={item.id}
              />
            </Stack>
          )
        case 'indexTransactionDate':
          return (
            <Stack
              className={styles.onRenderColumnStack}
              verticalAlign="center"
            >
              <Text
              >
                {item.indexTransactionDate ?
                  dateFormat(dateConvertions(item.indexTransactionDate)) :
                  null
                }
              </Text>
            </Stack>
          );
        case 'indexAmount':
          return (
            <Stack
              verticalAlign="center"
              horizontalAlign="end"
              className={styles.onRenderColumnStack}
            >
              <AmountTextView value={fieldContent} />
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              verticalAlign="center"
              horizontal
              className={styles.onRenderColumnStack}
              tokens={{ childrenGap: 10 }}
            >
              <Icon
                className={fileTypeColor(item.iconType || 'OTHER')}
                iconName={fileType(item.iconType || 'OTHER')}
              />
              <Text>{fieldContent}</Text>
            </Stack>
          );
        case 'action':
          return (
            <Stack
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <ViewDocument item={item} />
            </Stack>
          );
        case 'download':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
              horizontalAlign={'center'}
            >
              <DownloadButton entityDocumentId={item.id} />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              {fieldContent && <Text>{fieldContent}</Text>}
            </Stack>
          );
      }
    }
  };
  return renderItemColumn;
};
