import { DefaultButton, TooltipHost } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { SignatureValues } from '../../types';

export const UploadSignature = () => {
  const { setFieldValue } = useFormikContext<SignatureValues>();
  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onDrop = async (files: File[]) => {
    if (files[0].name) {
      const base64 = await convertBase64(files[0])
      setFieldValue('imageUrl', base64);
    }
  }

  const { getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    noDragEventsBubbling: true,
    noClick: true,
  });

  return (
    <>
      <div className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <TooltipHost content={'Upload signature image'}>
            <DefaultButton
              styles={{ root: { width: 200 } }}
              iconProps={{ iconName: 'Upload' }}
              text="Upload"
              onClick={open}
            />
          </TooltipHost>
        </div>
      </div>
    </>
  )
}
