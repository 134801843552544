import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    ImageContainer: {
      width: 450,
      height: 180,
      borderRadius: 5,
      backgroundColor: 'white',
    },
    ImageWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 550,
      height: 192,
      borderRadius: 5,
      border: `2px solid ${theme.palette.neutralLight}`,
      backgroundColor: 'white',
    },

    updatePendingStyle: {
      color: theme.palette.yellowDark,
    },
    selectedStyles: {
      border: `2px solid ${theme.palette.themePrimary}`,
    },
  };
});
