import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Separator, Stack, Text } from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { CardHoldersOrderBy } from 'common/types/globalTypes';
import {
  EntityAction,
  EntityType,
  PanelCommonProps,
} from 'common/types/utility';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { setCompanyAccountId } from '../../list/CardHolders/utils';
import {
  CardHolderDelete,
  CardHolderDeleteVariables,
} from '../../list/__generated__/CardHolderDelete';
import { CardHolders_cardHolders } from '../../list/__generated__/CardHolders';
import { PurchaseCardCommonData } from '../../__generated__/PurchaseCardCommonData';
import { CARD_HOLDER_INITIAL_VALUES } from '../constants';
import { ShimmerView } from '../ShimmerView/ShimmerViews';
import { TransactionHistory } from '../TransactionHistory';
import { CardHolderValues } from '../types';
import { CardHolder, CardHolderVariables } from '../__generated__/CardHolder';
import {
  CardHolderCreate,
  CardHolderCreateVariables,
} from '../__generated__/CardHolderCreate';
import {
  CardHolderUpdate,
  CardHolderUpdateVariables,
} from '../__generated__/CardHolderUpdate';
import { ActionsMenu } from './ActionMenu';
import { BasicForm } from './BasicForm';
import { useStyles } from './index.styles';
import { IsManualBasicForm } from './IsManualBasicForm';
import { validationSchema } from './validation';
import { validationSchema2 } from './validation2';
const GET_CARD_HOLDER_DETAILS = loader('../CardHolder.graphql');
const CARD_COMMON_DATA = loader('../../PurchaseCardCommonData.graphql');
const UPDATE_CARD_HOLDER_SETUP = loader('../CardHolderUpdate.graphql');
const CARD_HOLDERS = loader('../../list/CardHolders.graphql');
const CARD_HOLDER_CREATE = loader('../CardHolderCreate.graphql');
const CARD_HOLDER_DELETE = loader('../../list/CardHolderDelete.graphql');

export interface BasicFormProps {
  data: PurchaseCardCommonData | undefined;
  isUpdatable: boolean | undefined | null;
  isEdit: boolean;
}
export interface AttachedDocumentsDataType {
  documentTypeId: number | null | undefined;
  entityDocumentId: string[] | null;
}

export const CardHolderView = () => {
  const history = useHistory();
  const styles = useStyles();
  const { addToast } = useToasts();
  const { cardHolderId } = useParams<{ cardHolderId: string | undefined }>();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const commonStyles = useCommonStyles();
  const currentCardCompanyId = useReactiveVar(setCompanyAccountId);
  const isEdit: boolean = !!cardHolderId;

  const { data: cardHolderDetailsData, loading: cardHolderDetailsLoading } =
    useQuery<CardHolder, CardHolderVariables>(GET_CARD_HOLDER_DETAILS, {
      fetchPolicy: 'network-only',
      variables: { id: cardHolderId! },
      skip: !cardHolderId,
    });

  const { data: commonData } = useQuery<PurchaseCardCommonData>(
    CARD_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [updateCardHolder, { loading: updateCardHolderLoading }] = useMutation<
    CardHolderUpdate,
    CardHolderUpdateVariables
  >(UPDATE_CARD_HOLDER_SETUP, { errorPolicy: 'all' });

  const [createCardHolder, { loading: createCardHolderLoading }] = useMutation<
    CardHolderCreate,
    CardHolderCreateVariables
  >(CARD_HOLDER_CREATE, { errorPolicy: 'all' });

  const [deleteCardHolder] = useMutation<
    CardHolderDelete,
    CardHolderDeleteVariables
  >(CARD_HOLDER_DELETE, { errorPolicy: 'all' });

  const cardFullName = cardHolderDetailsData?.cardHolder?.cardFullName || '';
  const cardNumber = cardHolderDetailsData?.cardHolder?.cardNumber || '';

  const handleSubmit = async (values: CardHolderValues) => {
    if (cardHolderId) {
      const { errors } = await updateCardHolder({
        variables: {
          input: {
            id: values.id!,
            rowTimestamp: values._rowTimestamp!,
            cardHolderPatch: {
              cardFullName: values.cardFullName,
              cardNumber: values.cardNumber,
              departmentId: values.departmentId,
              documentPoolId: values.documentPoolId,
              tenantUserId: values.tenantUserId,
              userGroupId: values.userGroupId,
            },
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CARD_HOLDERS,
            variables: {
              first: TABLE_ROWS,
              orderBy: [CardHoldersOrderBy.CARD_NUMBER_ASC],
            },
          },
        ],
      });
      if (!errors) {
        addToast('Card Holder updated successfully.', {
          appearance: 'success',
        });
      } else {
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
      }
    } else {
      const { errors, data } = await createCardHolder({
        variables: {
          input: {
            cardHolder: {
              cardAccountId: currentCardCompanyId!,
              cardFullName: values.cardFullName,
              cardNumber: values.cardNumber,
              departmentId: values.departmentId,
              documentPoolId: values.documentPoolId,
              tenantUserId: values.tenantUserId,
              userGroupId: values.userGroupId,
            },
          },
        },
        update: (cache, { data }) => {
          if (data?.cardHolderCreate?.cardHolder?.id) {
            cache.modify({
              fields: {
                cardHolders: (existingData: CardHolders_cardHolders) => {
                  return {
                    ...existingData,
                    nodes: [
                      ...existingData.nodes,
                      data.cardHolderCreate?.cardHolder,
                    ],
                    totalCount: existingData.totalCount + 1,
                  };
                },
              },
            });
          }
        },
      });
      if (!errors) {
        if (data?.cardHolderCreate?.cardHolder) {
          history.replace(
            `/project-settings/purchase-cards/card-holder/${data.cardHolderCreate.cardHolder.id}`
          );
          addToast('Card Holder created successfully.', {
            appearance: 'success',
          });
        }
      } else {
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
      }
    }
  };

  let initialValues: CardHolderValues = CARD_HOLDER_INITIAL_VALUES;
  if (cardHolderDetailsData) {
    if (cardHolderDetailsData.cardHolder) {
      initialValues = {
        ...cardHolderDetailsData.cardHolder!,
      };
    }
  }

  const onConfirmDelete = async () => {
    const { errors } = await deleteCardHolder({
      variables: {
        input: {
          entityDelete: [
            {
              id: cardHolderDetailsData?.cardHolder?.id!,
              rowTimestamp: cardHolderDetailsData?.cardHolder?._rowTimestamp!,
            },
          ],
        },
      },
      update: (cache, { data }) => {
        if (data?.cardHolderDelete?.deletedEntities?.length) {
          const identity = cache.identify({
            ...cardHolderDetailsData?.cardHolder,
          });
          cache.evict({ id: identity });
          cache.gc();
          cache.modify({
            fields: {
              cardHolders: (existingData: CardHolders_cardHolders) => {
                return {
                  nodes: existingData.nodes.filter(
                    (existingRecord) =>
                      !data.cardHolderDelete?.deletedEntities?.some(
                        (deletableRecord) =>
                          existingRecord.id === deletableRecord?.id
                      )
                  ),
                  totalCount:
                    existingData.totalCount -
                    (data?.cardHolderDelete?.deletedEntities?.length || 0),
                };
              },
            },
          });
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else {
      history.replace('/project-settings/purchase-cards');
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
    }
  };

  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    const headerText = isEdit ? 'Edit Card Holder' : 'Create Card Holder';
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/project-settings/purchase-cards');
          setIsOpen(true);
        }}
      >
        <Stack grow horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{headerText}</Text>
            <Stack horizontal>
              {cardFullName && cardHolderId && !cardHolderDetailsLoading && (
                <Text
                  variant="xLarge"
                  className={commonStyles.colorThemePrimary}
                >
                  {`${cardFullName} ${cardNumber}`}
                </Text>
              )}
            </Stack>
            {cardHolderId && (
              <UnsavedIndicator
                visible={!cardHolderDetailsData && dirty && !isSubmitting}
              />
            )}
          </Stack>
          <Stack verticalAlign="center" horizontal>
            <ActionMessageModal
              visible={isEdit}
              entityType={EntityType.CardHolder}
              action={EntityAction.Remove}
              disabled={!cardHolderDetailsData?.cardHolder?._isDeletable}
              onConfirm={onConfirmDelete}
            />
            {cardHolderDetailsData?.cardHolder?.statusType?.statusType && (
              <Stack>
                <Text
                  className={styles.status}
                >{`(${cardHolderDetailsData?.cardHolder?.statusType?.statusType})`}</Text>
              </Stack>
            )}
          </Stack>
        </Stack>
      </PanelHeader>
    );
  };

  const transformedData =
    cardHolderDetailsData?.cardHolder?.cardHolderTransactions.nodes.map(
      (data) => ({
        ...data,
        transactionType: data.cardTransactionType,
        status: data.statusType?.statusType,
      })
    );

  const isManualProcess =
    cardHolderDetailsData?.cardHolder?.cardAccounts?.companyCardCompanies
      ?._isManualProcess || !!currentCardCompanyId;

  return (
    <Formik<CardHolderValues>
      enableReinitialize
      initialValues={initialValues!}
      validateOnMount
      onSubmit={handleSubmit}
      validationSchema={isManualProcess ? validationSchema2 : validationSchema}
    >
      {({ submitForm, isSubmitting, dirty, errors }) => {
        return (
          <Form>
            <DraggablePanel
              {...PanelCommonProps}
              isOpen={isOpen}
              initialWidth={900}
              minWidth={900}
              isBlocking={false}
              onRenderHeader={() => _onRenderHeader(dirty, isSubmitting)}
              onRenderFooter={() => (
                <FooterActionBar
                  createNewText="New Card Holder"
                  addNewForm={() => {
                    history.replace(
                      `/project-settings/purchase-cards/card-holder`
                    );
                  }}
                  isLoading={updateCardHolderLoading || createCardHolderLoading}
                  hideCreateButton={!isEdit}
                  disabled={{
                    save: !dirty || Object.keys(errors).length > 0,
                    cancel: !dirty,
                  }}
                  isSubmitting={isSubmitting}
                  onCancel={() =>
                    history.replace('/project-settings/purchase-cards')
                  }
                  onSave={async () => await submitForm()}
                />
              )}
              isLightDismiss
            >
              {cardHolderDetailsLoading ? (
                <ShimmerView />
              ) : (
                <Stack>
                  {isEdit && (
                    <>
                      <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
                        <Stack
                          horizontal
                          tokens={{ childrenGap: 10, padding: '0px 25px' }}
                          horizontalAlign="space-between"
                        >
                          <Stack
                            className={styles.actionsMenuContainer}
                            horizontalAlign="space-between"
                          >
                            <ActionsMenu
                              cardHolderDetails={cardHolderDetailsData!}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                      <Separator />
                    </>
                  )}
                  {isManualProcess ? (
                    <IsManualBasicForm
                      data={commonData!}
                      isUpdatable={
                        cardHolderDetailsData?.cardHolder?._isUpdatable
                      }
                      isEdit={isEdit}
                    />
                  ) : (
                    <BasicForm
                      data={commonData!}
                      isUpdatable={
                        cardHolderDetailsData?.cardHolder?._isUpdatable
                      }
                      isEdit={isEdit}
                    />
                  )}
                  {cardHolderDetailsData?.cardHolder
                    ?.cardHolderHistoriesByEntityId &&
                    cardHolderDetailsData?.cardHolder
                      ?.cardHolderHistoriesByEntityId.nodes.length! > 0 && (
                      <Stack>
                        <Separator />
                        <Stack
                          className={styles.approvalHistoryContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text
                            variant="xLarge"
                            className={styles.transactionHistoryHeading}
                          >
                            Transaction History
                          </Text>
                          <TransactionHistory data={transformedData || []} />
                        </Stack>
                      </Stack>
                    )}
                </Stack>
              )}
            </DraggablePanel>
          </Form>
        );
      }}
    </Formik>
  );
};
