import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 24,
  },
  tagsContainer: {
    width: '96%',
  },
  emptyTagsView: {
    padding: 20,
    fontSize: FontSizes.size16,
    borderWidth: 1,
    borderColor: theme.palette.neutralLight,
    borderStyle: 'solid',
    textAlign: 'center',
    flex: 1,
  },
  closeButton: {
    color: theme.palette.neutralDark,
  },
  commandBarButton: {
    height: 40,
  },
}));
