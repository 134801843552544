import { AppRouter } from 'common/types/globalTypes';

const getRoutePath = (route: AppRouter | null | undefined) => {
  switch (route) {
    case AppRouter.APPROVALS:
      return '/approvals/';
    case AppRouter.APPROVAL_HISTORY:
      return '/approval_history/';
    case 'TRANSACTION_SIGNING':
      return '/signing/transaction/';
    case 'PAY_CYCLE':
      return '/pay/payment_cycle/cycle/';
    case 'PAYMENT_TRACKING':
      return '/pay/payment_tracking/payment/';
    case 'DOCUMENT_POOL':
      return '/doc/groups/group/';
    case 'SUPPLIER':
      return '/account-management/suppliers/supplier/';
    case AppRouter.PCARD_ACCOUNT:
      return '/project-settings/purchase-cards/card-account/';
    case AppRouter.PCARD_HOLDER:
      return '/project-settings/purchase-cards/card-holder/';
    case 'BATCH_EDIT':
      return '/postingTracker/batches/batch/';
    default:
      return null;
  }
};

export { getRoutePath };
