import { useLazyQuery } from '@apollo/client';
import {
  ITextFieldProps,
  ITextFieldStyles,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text,
  TextField,
  makeStyles,
  useTheme,
} from '@fluentui/react';
import { UserDefaults_userDefaults_nodes_lookupAccounts } from 'Preferences/__generated__/UserDefaults';
import { LookupAccountSearch_lookupAccountSearch_nodes } from 'common/graphql/__generated__/LookupAccountSearch';
import {
  LookupAccountTransactionSearch,
  LookupAccountTransactionSearchVariables,
} from 'common/graphql/__generated__/LookupAccountTransactionSearch';
import { useCommonStyles } from 'common/styles';
import { RequiredNameProps } from 'common/types/utility';
import { useField } from 'formik';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { LookUpCallout } from '../../LookUpCallout';
const LOOKUP = loader(
  '../../../graphql/LookupAccountTransactionSearch.graphql'
);

type LookUpProps = LookupAccountSearch_lookupAccountSearch_nodes;
type FormikAccountAutocompleteProps = RequiredNameProps<ITextFieldProps> & {
  width?: number;
  lookupAccounts:
    | UserDefaults_userDefaults_nodes_lookupAccounts
    | null
    | undefined;
  onAccountSelect?: (lookupData: LookUpProps) => void;
};

const useStyles = makeStyles((theme) => ({
  resultItem: {
    width: 500,
    backgroundColor: theme.palette.neutralLighter,
    textDecoration: 'none',
    margin: '0px -20px',
    padding: '6px 20px',
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
    ':active': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
  icon: {
    color: theme.palette.black,
  },
}));

export const FormikAccountAutocomplete: FC<FormikAccountAutocompleteProps> = ({
  width,
  lookupAccounts,
  onAccountSelect,
  ...props
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: theme.palette.white,
    },
  };
  const [field, meta, helpers] = useField<string | undefined | null>(
    props.name
  );

  const { value, ...rest } = field;
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState<string | null>(null);
  const [disableTextfield, setDisableTextfield] = useState<
    boolean | null | undefined
  >(false);

  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupAccountTransactionSearch,
    LookupAccountTransactionSearchVariables
  >(LOOKUP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { id, disabled } = { ...props };

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupName: value,
          businessUnitId: null,
        },
      });
      queryCalled();
    }, 350),
    [value]
  );

  const onRenderSuffix = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disabled ? null : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              helpers.setValue(null);
              setTextFieldValue(null);
              setDisableTextfield(false);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };

  useEffect(() => {
    if (lookupAccounts) {
      setTextFieldValue(
        `${lookupAccounts?.lookupAccount} ${lookupAccounts?.lookupName}`
      );
      setDisableTextfield(true);
    }
  }, [lookupAccounts]);

  return (
    <Stack>
      <TextField
        {...rest}
        {...props}
        autoComplete={'off'}
        errorMessage={meta.error}
        value={textFieldValue || ''}
        onChange={(_event, value) => {
          if (value) {
            setTextFieldValue(value);
            callbackLookUp(value, () => {
              if (!calloutVisible) setCalloutVisible(true);
            });
          } else {
            setTextFieldValue(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={onRenderSuffix}
        styles={textfieldStyles}
        disabled={disabled ? true : disableTextfield ? true : false}
      />

      {calloutVisible &&
        !loading &&
        data?.lookupAccountTransactionSearch?.nodes.length! > 0 && (
          <LookUpCallout
            lookUpCalloutWidth={500}
            onDismiss={() => setCalloutVisible(false)}
            targetID={`#${id!}`}
          >
            {data?.lookupAccountTransactionSearch?.nodes.map((data, index) => {
              return (
                <Link
                  onClick={() => {
                    helpers.setValue(data.id);
                    setCalloutVisible(false);
                    onAccountSelect?.(data);
                    setTextFieldValue(
                      `${data.lookupAccount} ${data.lookupName}`
                    );
                    setDisableTextfield(true);
                  }}
                  className={styles.resultItem}
                  key={index.toString()}
                >
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Text className={commonStyles.colorThemePrimary}>
                      {data.lookupAccount}
                    </Text>
                    <Text nowrap>{data.lookupName}</Text>
                  </Stack>
                </Link>
              );
            })}
          </LookUpCallout>
        )}
    </Stack>
  );
};
