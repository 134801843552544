import {
  TravelerValues,
  AddressRowValues,
  DietaryRequirementsRowValues,
  TravelerPolicyAllowanceRow,
} from './types';

export const ADDRESS_INITIAL_VALUES: AddressRowValues = {
  id: null,
  _rowTimestamp: null,
  addressLine1: null,
  addressLine2: null,
  cityName: null,
  countryId: null,
  stateRegionId: null,
  postalCode: null,
  telephoneNumber: null,
  emailAddress: null,
  isGroundTransferAddress: null,
  isDeliveryAddress: null,
  isPrimary: null,
};

export const DIETARY_REQUIREMENTS_INITIAL_VALUES: DietaryRequirementsRowValues =
  {
    id: null,
    _rowTimestamp: null,
    _isDeletable: true,
    description: null,
    dietaryRequirementId: null,
    isDescriptionRequired: null,
  };

export const TRAVEL_POLICY_ALLOWANCE_VALUES: TravelerPolicyAllowanceRow = {
  id: null, //id of the checkbox
  expenditureType: null,
  allowanceOverrideAmount: null,
  _allowanceDescription: null,
  isAllowanceCustomizable: null,
  _travelerPolicyAllowanceRowTimestamp: null,
  _travelerPolicyAllowanceId: null, // id of the allowance created
  isChecked: null,
};

export const TRAVELER_INITIAL_VALUES: TravelerValues = {
  firstName: null,
  companyOrLastName: null,
  vendorNumber: null,
  frequentFlyerNumber: null,
  seatPreference: null,
  travelerDepartmentId: null,
  travelPolicyId: null,
  userOccupationTitleId: null,
  travelerCompanionTickets: null,
  travelerCompanionAmount: null,
  isEconomyClassPreferred: false,
  isFirstClassPreferred: false,
  isBusinessClassPreferred: false,
  isCharterClassPreferred: false,
  isPremiumClassPreferred: false,
  travelerAddressesByTravelerId: [
    { ...ADDRESS_INITIAL_VALUES, isPrimary: true },
  ],
  travelerDietaryRequirementsByTravelerId: [
    { ...DIETARY_REQUIREMENTS_INITIAL_VALUES },
  ],
  travelerPolicyAllowancesByTravelerId: [{ ...TRAVEL_POLICY_ALLOWANCE_VALUES }],
};
