import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { RequiredNameProps } from 'common/types/utility';
import { ITextFieldStyles, TextField } from '@fluentui/react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { UseControllerProps } from 'react-hook-form';

interface FormHookAmountProps extends NumberFormatProps {
  label?: string;
  underlined?: boolean;
  rules?: UseControllerProps['rules'];
  defaultValue?: any;
  styles?: Partial<ITextFieldStyles>;
  onAmountChange?: (value: string | null) => void;
}

export const FormHookAmount: FC<RequiredNameProps<FormHookAmountProps>> = ({
  onChange,
  thousandSeparator = true,
  name,
  rules,
  onAmountChange,
  ...props
}) => {
  return (
    <Controller
      render={({
        field: { onChange, onBlur, value, name: fieldName, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        return (
          <NumberFormat
            {...props}
            value={value || ''}
            errorMessage={error?.message}
            customInput={TextField}
            thousandSeparator={thousandSeparator}
            onValueChange={(valuesObject, info) => {
              if (info.source === 'event') {
                onChange(valuesObject.value || null);
                onAmountChange?.(valuesObject.value || null);
              }
            }}
          />
        );
      }}
      name={name}
      rules={rules}
    />
  );
};
