import { ChoiceGroup, IChoiceGroupProps } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { ErrorText } from '../ErrorText';

interface FormHookChoiceGroupProps extends IChoiceGroupProps {
  rules?: UseControllerProps['rules'];
}

export const FormHookChoiceGroup: React.FC<RequiredNameProps<FormHookChoiceGroupProps>> = ({
  name,
  onChange,
  ...props
}) => {

  const {
    field: { value, onChange: onChoiceGroupChange },
    fieldState: { error, isTouched },
  } = useController({ name });

  return (
    <>
      <ChoiceGroup
        {...props}
        selectedKey={value}
        onChange={(event, option) => {
          onChoiceGroupChange(option?.key);
          onChange?.(event, option);
        }}
      />
      {isTouched && error && <ErrorText message={error.message || ""} />}
    </>
  )
}
