// import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import Decimal from 'decimal.js';
import { NumberFormatValues } from 'react-number-format';
import { DocumentMetaForm } from '.';
import {DocumentsType} from './'
interface ShowRetireCheckBoxProps {
  isNew: boolean;
  // document: EntityDocumentsFields;DocumentType
  document: DocumentsType;
  metaData: DocumentMetaForm | undefined;
}
interface GetRemainingTotal {
  isNew: boolean;
  // document: EntityDocumentsFields;
  document: DocumentsType;
}
interface GetAppliedAmount {
  isNew: boolean;
  // document: EntityDocumentsFields;
  document: DocumentsType;
}

interface OnAppliedAmountChange {
  value: NumberFormatValues;
  metaData: DocumentMetaForm | undefined;
  // onMetaDataReceived: (data: DocumentMetaForm) => void;
}

export const showRetireCheckBox = (data: ShowRetireCheckBoxProps) => {
  if (data.isNew) {
    if (data.metaData)
      return (
        parseFloat(data.metaData?.documentAppliedAmount || '0.00') <
        parseFloat(
          data.document?.documentAppliedAmounts?.remainingTotal || '0.00'
        )
      );
  } else {
    if (data.document._isDocumentAmountApplied) {
      return (
        parseFloat(data.metaData?.remainingTotal || '0.00') >
        parseFloat(data.metaData?.documentAppliedAmount || '0.00')
      );
    }
  }
};

export const showOverageCheckBox = (data: ShowRetireCheckBoxProps) => {
  if (data.isNew && data.metaData) {
    return (
      parseFloat(data.metaData?.documentAppliedAmount || '0.00') >
      parseFloat(
        data.document?.documentAppliedAmounts?.remainingTotal || '0.00'
      )
    );
  } else {
    return (
      parseFloat(data.metaData?.documentAppliedAmount || '0.00') >
      parseFloat(data?.metaData?.remainingTotal || '0.00')
    );
  }
};

export const getRemainingTotal = (data: GetRemainingTotal) => {
  if (data.isNew) {
    if (data.document._isDocumentAmountAvailable)
      return data.document?.documentAppliedAmounts?.remainingTotal || '0.00';
    else return '0.00';
  } else {
    //If partial document but not retired
    if (data.document._isDocumentAmountApplied) {
      const indexAmount = parseFloat(data.document.indexAmount || '0.00');
      const usedTotal = parseFloat(
        data.document.documentAppliedAmounts?.usedTotal || '0.00'
      );
      const documentAppliedAmount = parseFloat(
        data.document.documentAppliedAmount || '0.00'
      );
      const documentRetiredAmount = parseFloat(
        data.document.documentRetiredAmount || '0.00'
      );
      const documentOverageAmount = parseFloat(
        data.document.documentOverageAmount || '0.00'
      );
      const remainingTotal = (
        indexAmount -
        (usedTotal -
          documentAppliedAmount -
          documentRetiredAmount -
          documentOverageAmount)
      ).toString();
      return remainingTotal;
    } else {
      return '0.00';
    }
  }
};

export const GetAppliedAmount = (data: GetRemainingTotal) => {
  if (data.isNew) {
    return data.document.documentAppliedAmount || '0.00';
  } else {
    if (data.document._isDocumentAmountApplied) {
      if (data.document.isAppliedAmountOverage)
        return (
          parseFloat(data.document.documentAppliedAmount || '0.00') +
          parseFloat(data.document.documentOverageAmount || '0.00')
        ).toString();
      else return data.document.documentAppliedAmount;
    } else {
      return '0.00';
    }
  }
};

export const onAppliedAmountChange = (
  inputValue: NumberFormatValues,
  metaData?: DocumentMetaForm
): DocumentMetaForm => {
  let tempData = metaData;
  if (
    metaData?.remainingTotal &&
    metaData?.isAppliedAmountRetired &&
    metaData?.documentAppliedAmount
  ) {
    let difference = 0.0;
    difference = new Decimal(metaData?.remainingTotal)
      .minus(metaData?.documentAppliedAmount)
      .toNumber();
    tempData = {
      ...tempData,
      documentRetiredAmount: difference.toFixed(2).toString(),
    };
  }
  return {
    ...tempData,
    documentAppliedAmount: inputValue.value || null,
  };
};
