import React, { FC } from 'react';
import { useField } from 'formik';
import { RequiredNameProps } from 'common/types/utility';
import { TextField, ITextFieldProps } from '@fluentui/react';

export const FormikTextField: FC<RequiredNameProps<ITextFieldProps>> = (
  props
) => {
  const [field, meta, helpers] = useField<string | undefined | null>(
    props.name
  );
  const { value, ...rest } = field;
  return (
    <TextField
      {...rest}
      {...props}
      errorMessage={meta.error}
      value={value || ''}
      onChange={(_event, value) => {
        if (value) {
          if (value.replace(/\s/g, '').length) {
            helpers.setValue(value);
          }
        } else {
          helpers.setValue(null);
        }
      }}
    />
  );
};
