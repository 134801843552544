import React, { useEffect } from 'react';
import { useStyles } from './index.styles';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { currentUserRole } from 'utility/cache';
import { UserRoles } from 'common/graphql/__generated__/UserRoles';
import { Spinner, SpinnerSize, Text } from '@fluentui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  SetPreferredRole,
  SetPreferredRoleVariables,
} from 'common/graphql/__generated__/SetPreferredRole';
import { config } from '../config';
import { storePath } from 'utility/redirect';

const SET_PREFERRED_ROLE = loader(
  'src/common/graphql/SetPreferredRole.graphql'
);
const ROLES = loader('src/common/graphql/UserRoles.graphql');

export const LoginWrapper: React.FC = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, isLoading, logout } = useAuth0();
  const styles = useStyles();
  const currentRole = useReactiveVar(currentUserRole);
  const [targetProfileId, setTargetProfileId] = useQueryParam(
    'profile',
    StringParam
  );

  const [setPreferredRole] = useMutation<
    SetPreferredRole,
    SetPreferredRoleVariables
  >(SET_PREFERRED_ROLE, { errorPolicy: 'all' });

  const [fetchRoles, { called }] = useLazyQuery<UserRoles>(ROLES, {
    onCompleted: (roles) => {
      const foundTargetProfile = targetProfileId
        ? roles.availableRoles.find((role) => role.id === targetProfileId)
        : undefined;

      // If we expected to find a user profile passed through url params but didn't, assume it's
      // under a separate auth0 user and force logout. Or if there is no roles available... but
      // hopefully that never happens.
      if (
        (targetProfileId && !foundTargetProfile) ||
        !roles.availableRoles.length
      ) {
        logout({
          returnTo: window.location.origin,
          client_id: config!.auth0.clientId,
        });
        return;
      }

      currentUserRole(
        foundTargetProfile ||
          roles.availableRoles.find((role) => role.isPreferred) ||
          roles.availableRoles[0]
      );

      if (foundTargetProfile)
        setPreferredRole({ variables: { userRoleId: foundTargetProfile.id } });

      setTargetProfileId(undefined, 'replaceIn');
    },
  });

  useEffect(() => {
    document.title = 'Logging in...';
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const appState = storePath();
      loginWithRedirect({ appState });
    } else if (isAuthenticated && !called) {
      fetchRoles();
    }
  });

  if (!currentRole) {
    return (
      <div className={styles.wrapper}>
        <Spinner size={SpinnerSize.large} />
        <Text className={styles.loginLabel} variant="medium">
          Logging in...
        </Text>
      </div>
    );
  }

  return <>{children}</>;
};
