import { DayOfWeek, IDropdownOption, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import {
  FormHookAmount,
  FormHookAutoComplete,
  FormHookDatePicker,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { onFormatDate } from 'common/utils/dateFormats';
import { DocumentTypeOptions } from 'documents/personalPool/UploadDocumentsForm';
import { EntityDocumentValues } from 'documents/personalPool/types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GetEntityDocument_entityDocument } from '../../__generated__/GetEntityDocument';
import { TaxArea } from '../TaxArea';
import { useStyles } from './index.styles';
import { TransactionLayout } from 'common/types/globalTypes';
interface BasicFormProps {
  isEdit: boolean;
  isAccountingDocument: boolean;
  isDocumentProtected: boolean;
  isSigningDocument: boolean | null | undefined;
  showIsAccountingDocumentInputs: boolean;
  documentData?: GetEntityDocument_entityDocument | null;
  documentType?: DocumentTypeOptions;
  companyCurrencies?: IDropdownOption[];
  onInputFieldsDisabled?: (data: boolean) => void;
  dataExtractModelOptions?: IDropdownOption[];
  isDocumentTypeSelector: boolean;
  hideExtractionDropdown: boolean;
  isExtractionTypeSelected: boolean;
}
export const BasicFormView: React.FC<BasicFormProps> = ({
  isEdit,
  isAccountingDocument,
  isDocumentProtected,
  isSigningDocument,
  showIsAccountingDocumentInputs,
  documentData,
  documentType,
  companyCurrencies,
  onInputFieldsDisabled,
  dataExtractModelOptions,
  isDocumentTypeSelector,
  hideExtractionDropdown,
  isExtractionTypeSelected,
}) => {
  const styles = useStyles();
  const { setValue, getValues } = useFormContext<EntityDocumentValues>();
  const values = getValues();
  const [isDisabled, setIsDisabled] = useState(false);
  const [disabledExtractionTypeId, setDisabledExtractionTypeId] =
    useState(false);
  const [showDataExtractDropdown, setShowDataExtractDropdown] = useState(false);
  const isTimecard = documentData?.documentTypes?.documentType === 'Time Card';
  // const extractionTypeLabel = (documentType?.isSigningRequired && !documentType.isAccountingDocument) ?
  //   "Approval Flow" : "Data Extraction Template";
  // const isSigningDocument = documentType?.isSigningRequired && !documentType.isAccountingDocument;

  useEffect(() => {
    if (isEdit) {
      if (documentData?.extractionType !== null) {
        if (isDocumentProtected) {
          setIsDisabled(true);
          onInputFieldsDisabled?.(true);
        } else {
          setIsDisabled(documentData?.extractionType?.isExtractProtected!);
          onInputFieldsDisabled?.(
            documentData?.extractionType?.isExtractProtected!
          );
        }
      } else {
        setIsDisabled(isDocumentProtected);
        onInputFieldsDisabled?.(isDocumentProtected);
      }
    } else {
      if (values.extractionTypeId) {
        setIsDisabled(true);
        onInputFieldsDisabled?.(true);
      } else {
        setIsDisabled(false);
        onInputFieldsDisabled?.(false);
      }
    }
  }, [
    isEdit,
    values.extractionTypeId,
    documentData,
    isDocumentProtected,
    onInputFieldsDisabled,
  ]);
  useEffect(() => {
    if (
      isEdit &&
      (hideExtractionDropdown || values.extractionTypeId === null)
    ) {
      setShowDataExtractDropdown(false);
    } else if (dataExtractModelOptions && dataExtractModelOptions?.length > 0) {
      setShowDataExtractDropdown(true);
    } else {
      setShowDataExtractDropdown(false);
    }
  }, [
    dataExtractModelOptions,
    isEdit,
    hideExtractionDropdown,
    values.extractionTypeId,
  ]);

  useEffect(() => {
    if (isDocumentTypeSelector && isExtractionTypeSelected) {
      setDisabledExtractionTypeId(true);
    }
  }, [isDocumentTypeSelector, isExtractionTypeSelected]);

  const { _isAppliableDocumentType } = { ...documentData?.documentTypes };

  return (
    <>
      {(showDataExtractDropdown ||
        (isSigningDocument && !isAccountingDocument)) && (
        <Stack>
          <FormHookDropdown
            required
            label={
              isSigningDocument && !isAccountingDocument
                ? 'Approval flow'
                : 'Data Extraction Template'
            }
            placeholder="Select"
            name="extractionTypeId"
            options={dataExtractModelOptions || []}
            disabled={
              isDocumentProtected
                ? true
                : isSigningDocument && !isAccountingDocument
                  ? false
                  : isEdit || disabledExtractionTypeId
            }
          />
        </Stack>
      )}
      {isSigningDocument && (
        <FormHookAutoComplete
          name="indexName"
          label={isTimecard ? 'Employee' : 'Name'}
          placeholder="Enter name"
          disabled={isDisabled || isDocumentProtected}
          lookupData={documentData?.lookupName}
          documentTypeId={values.entityDocumentTypeId!}
          id={'indexName'}
          lookUpCalloutWidth={550}
        />
      )}
      {documentData?._entryLayout ===
        TransactionLayout.DOCUMENT_SIGNING_AMOUNT && (
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item className={styles.flex50}>
            <FormHookAmount
              disabled={isDisabled || isDocumentProtected}
              name="indexAmount"
              label={'Weekly Limit'}
            />
          </Stack.Item>
          <Stack.Item className={styles.flex50}>
            <FormHookDropdown
              label="Currency"
              placeholder="Select"
              name="indexCurrencyId"
              options={companyCurrencies || []}
              disabled={isDisabled || isDocumentProtected}
              required
            />
          </Stack.Item>
        </Stack>
      )}
      {!isAccountingDocument && (
        <FormHookTextField
          disabled={isDocumentProtected}
          name="indexDescription"
          label="Contents"
          multiline
          rows={3}
          placeholder="Enter contents"
        />
      )}
      {isAccountingDocument && (
        <Stack tokens={{ childrenGap: 20 }}>
          <FormHookAutoComplete
            name="indexName"
            label={isTimecard ? 'Employee' : 'Name'}
            placeholder="Enter name"
            disabled={isDisabled || isDocumentProtected}
            lookupData={documentData?.lookupName}
            documentTypeId={values.entityDocumentTypeId!}
            id={'indexName'}
            lookUpCalloutWidth={550}
          />
          {showIsAccountingDocumentInputs && (
            <>
              <FormHookTextField
                disabled={isDisabled || isDocumentProtected}
                name="indexDescription"
                label="Description"
                placeholder="Enter description"
              />
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item className={styles.flex50}>
                  <FormHookTextField
                    disabled={isDisabled || isDocumentProtected}
                    name="indexReferenceNumber"
                    label={isTimecard ? 'T/C Number' : 'Reference Number'}
                    placeholder={
                      documentType?.text
                        ? `Enter ${documentType.text.toLowerCase()} number`
                        : 'Enter reference number'
                    }
                  />
                </Stack.Item>
                <Stack.Item className={styles.flex50}>
                  <FormHookDatePicker
                    disabled={isDisabled || isDocumentProtected}
                    name="indexTransactionDate"
                    onSelectDate={(date) => {
                      if (date)
                        setValue('indexTransactionDate', onFormatDate(date));
                    }}
                    placeholder="Select date"
                    ariaLabel="Document Date"
                    label={isTimecard ? 'W/E Date' : 'Date'}
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                  />
                </Stack.Item>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item className={styles.flex50}>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <FormHookAmount
                      name="indexAmount"
                      label="Amount"
                      disabled={isDisabled || isDocumentProtected}
                      fixedDecimalScale
                      decimalScale={2}
                      placeholder="Amount"
                      allowNegative
                    />
                    {_isAppliableDocumentType && (
                      <Stack horizontal className={styles.amountRow}>
                        {documentData?.documentAppliedAmounts?.usedTotal && (
                          <Stack horizontal>
                            <Text className={styles.label}>{`Used: `}</Text>
                            <AmountTextView
                              className={clsx(styles.amountText)}
                              value={
                                documentData.documentAppliedAmounts.usedTotal
                              }
                            />
                          </Stack>
                        )}
                        {documentData?.documentAppliedAmounts
                          ?._isRemainingAmountAvailable && (
                          <Stack horizontal>
                            <Text
                              className={styles.label}
                            >{`Remaining: `}</Text>
                            <AmountTextView
                              className={clsx(styles.amountText)}
                              value={
                                documentData?.documentAppliedAmounts
                                  ?.remainingTotal
                              }
                            />
                          </Stack>
                        )}
                      </Stack>
                    )}
                    <Stack horizontal className={styles.amountRow}>
                      {documentData?.documentAppliedAmount && (
                        <Stack horizontal>
                          <Text className={styles.label}>{`Applied: `}</Text>
                          <AmountTextView
                            className={clsx(styles.amountText)}
                            value={documentData?.documentAppliedAmount}
                          />
                        </Stack>
                      )}
                      {documentData?.documentAppliedAmounts?.remainingTotal &&
                        documentData?.documentAppliedAmounts?.remainingTotal !==
                          '0.00' &&
                        documentData?.documentStatusType?.statusType &&
                        !documentData.isAppliedAmountOverage && (
                          <Text className={styles.amountText}>
                            {documentData?.documentStatusType?.statusType}
                          </Text>
                        )}
                    </Stack>
                    <Stack horizontal className={styles.amountRow}>
                      {documentData?.isAppliedAmountOverage && (
                        <Stack horizontal>
                          <Text className={styles.label}>{`Retired: `}</Text>
                          <AmountTextView
                            className={clsx(styles.amountText)}
                            value={documentData?.documentRetiredAmount}
                          />
                        </Stack>
                      )}
                      {documentData?.documentOverageAmount && (
                        <Stack horizontal>
                          <Text className={styles.label}>{`Overage: `}</Text>
                          <AmountTextView
                            className={clsx(styles.amountText)}
                            value={documentData?.documentOverageAmount}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack.Item>
                <Stack.Item className={styles.flex50}>
                  <FormHookDropdown
                    label="Currency"
                    placeholder="Select"
                    name="indexCurrencyId"
                    options={companyCurrencies || []}
                    disabled={isDisabled || isDocumentProtected}
                  />
                </Stack.Item>
              </Stack>
            </>
          )}
        </Stack>
      )}
      <TaxArea documentData={documentData} />
      {showIsAccountingDocumentInputs && (
        <FormHookTextField
          disabled={isDisabled || isDocumentProtected}
          name="comment"
          label="Comment"
          placeholder="Enter comment"
        />
      )}
    </>
  );
};
