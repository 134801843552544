import { IColumn, Stack, Text } from '@fluentui/react';
import { ActiveLink } from 'common/components/ActiveRowLink';
import React from 'react';
import { UserTemplatesRowItem } from '.';

export type ColumnStylesProps = {
  onRenderColumnStack: string;
};

export const onRenderItems = (styles: ColumnStylesProps) => {
  const renderItemColumn = (
    item: UserTemplatesRowItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof UserTemplatesRowItem
      ] as string;
      switch (column?.key) {
        case 'name':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <ActiveLink
                to={`/account-management/userTemplates/userTemplate/${item.id}`}
              >
                {fieldContent}
              </ActiveLink>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  };
  return renderItemColumn;
};
