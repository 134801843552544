import {
  Stack,
  IPersonaProps,
  IBasePickerSuggestionsProps,
  ValidationState,
  Text,
  Checkbox,
  ICheckboxStyles,
  IconButton,
  IIconProps,
  CompactPeoplePicker,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import React, { useState, useRef, FC } from 'react';

const expandIcon: IIconProps = { iconName: 'ChevronRight' };
const collapseIcon: IIconProps = { iconName: 'ChevronDown' };

interface EmployeePickerProps {
  isApproval?: boolean;
  allContacts: IPersonaProps[] | undefined;
  onItemSelected: (pickedContacts: string[], pickerTitle: string) => void;
  pickerTitle: string;
  checkboxLabel: string | undefined;
  id: string | undefined;
  onAllContactSelected: (id: string, temp: number, pickerTitle: string) => void;
}

const suggestionProps: IBasePickerSuggestionsProps = {
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

const checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: 'bold',
  },
};

export const EmployeePicker: FC<EmployeePickerProps> = ({
  isApproval = false,
  allContacts,
  pickerTitle,
  onItemSelected,
  id,
  onAllContactSelected,
}) => {
  const [delayResults] = useState(false);

  const [isPickerVisible, setPickerVisible] = useState<boolean | undefined>(
    false
  );

  const [isPickerDisabled, setisPickerDisabled] = useState<boolean>(false);

  const [selectedContacts, setSelectedContacts] = useState<
    string[] | undefined
  >([]);

  const [selectedItems, setSelectedItems] = useState<
    IPersonaProps[] | undefined
  >([]);

  const [isAllContactsSelected, setAllContactsSelected] =
    useState<boolean>(false);

  const picker = useRef(null);

  const styles = useStyles();

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

      filteredPersonas =
        removeDuplicates(filteredPersonas, currentPersonas) || [];
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return allContacts!.filter((item) =>
      doesTextStartWith(item.text as string, filterText)
    );
  };

  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (delayResults) {
      return convertResultsToPromise(personasToReturn);
    } else {
      return personasToReturn;
    }
  };

  const returnMostRecentlyUsed = (
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return filterPromise(removeDuplicates(allContacts, currentPersonas) || []);
  };

  const onItemChange = (item: IPersonaProps[]): IPersonaProps[] => {
    let pickedContacts: string[] | undefined = selectedContacts;
    pickedContacts = item?.map((item) => item!.id!);
    setSelectedContacts(pickedContacts);
    onItemSelected(pickedContacts, pickerTitle);
    setSelectedItems(item);
    return item!;
  };

  const onAllContacsSelected = () => {
    let isChecked = !isAllContactsSelected;
    setAllContactsSelected(isChecked);
    if (isChecked) {
      if (id === 'null') {
        let all_contacts: string[] | undefined =
          allContacts?.map((item: IPersonaProps) => item.id || '') || [];
        onItemSelected(all_contacts, pickerTitle);
      }
      onAllContactSelected(id!, 1, pickerTitle);
      setSelectedItems([]);
    } else {
      onItemSelected([], pickerTitle);
      onAllContactSelected(id!, 0, pickerTitle);
      setSelectedItems([]);
    }
    setisPickerDisabled(isChecked);
  };

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.container}>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        className={styles.headerContainer}
      >
        <Stack horizontal verticalAlign="center">
          <IconButton
            onClick={() =>
              setPickerVisible((isPickerVisible) => !isPickerVisible)
            }
            iconProps={isPickerVisible ? collapseIcon : expandIcon}
            ariaLabel="Back"
            className={styles.backIconStyle}
          />
          <Text className={styles.pickerTitle}>{pickerTitle}</Text>
        </Stack>
        {!isApproval && (
          <Stack horizontal tokens={{ childrenGap: 10, padding: '0px 25px' }}>
            <Checkbox
              styles={checkBoxStyle}
              label=""
              checked={isAllContactsSelected}
              onChange={onAllContacsSelected}
            />
            <Text>All</Text>
          </Stack>
        )}
      </Stack>
      {isPickerVisible && (
        <Stack className={styles.pickerContainer}>
          <CompactPeoplePicker
            onResolveSuggestions={onFilterChanged}
            disabled={isPickerDisabled}
            onEmptyResolveSuggestions={returnMostRecentlyUsed}
            pickerSuggestionsProps={suggestionProps}
            className="ms-PeoplePicker"
            selectedItems={selectedItems}
            key="normal"
            onValidateInput={validateInput}
            componentRef={picker}
            onChange={(item) => onItemChange(item!)}
            onInputChange={onInputChange}
          />
        </Stack>
      )}
    </Stack>
  );

  function doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }

  function removeDuplicates(
    personas: IPersonaProps[] | undefined,
    possibleDupes: IPersonaProps[] | undefined
  ) {
    return personas?.filter(
      (persona: IPersonaProps) => !listContainsPersona(persona, possibleDupes)
    );
  }

  function listContainsPersona(
    persona: IPersonaProps,
    personas: IPersonaProps[] | undefined
  ) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return (
      personas.filter((item: IPersonaProps) => item.text === persona.text)
        .length > 0
    );
  }

  function convertResultsToPromise(
    results: IPersonaProps[]
  ): Promise<IPersonaProps[]> {
    return new Promise<any>((resolve, reject) =>
      setTimeout(() => resolve(results), 2000)
    );
  }

  function validateInput(input: string): ValidationState {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  function onInputChange(input: string): string {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);
    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }
    return input;
  }
};
