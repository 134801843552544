import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  cellAlignRight: {
    textAlign: 'right',
    paddingRight: 10,
  },
  onrenderColumnStack: {
    height: 20,
  },
  heading: {
    color: theme.palette.themePrimary,
    fontWeight: 600,
    margin: '12px 0px 6px 10px',
  },
  detailsListContainer: {
    overflowY: 'scroll',
    maxHeight: 300,
    padding: '0px 0px 10px',
  },
  totalAmount: {
    fontWeight: 'bold',
    marginLeft: 0,
    paddingRight: 10,
    display: 'block',
    textAlign: 'right',
  },
}));
