import React from 'react';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { formatDropdownOptions } from 'common/utils';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { Stack } from '@fluentui/react';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { useStyles } from './index.styles';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { TransactionCommonData } from '../list/__generated__/TransactionCommonData';

const TRANSACTION_TYPES = loader('../list/TransactionCommonData.graphql');

interface TransactionSearchBarProps extends SearchBarProps {
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
}

export const TransactionSearchBar: React.FC<TransactionSearchBarProps> = ({
  columns,
  onFilterChange,
  onEnterPress,
  hintsAvailable,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  onHintsViewToggle,
  urlParams,
}) => {
  const styles = useStyles();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: invoiceTransactionTypesData } =
    useQuery<TransactionCommonData>(TRANSACTION_TYPES);
  const typeOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.invoiceTransactionTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.transactionType!,
      includeAll: true,
    }
  );
  const companiesOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.companies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );
  const businessUnitOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.companyBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );
  const departmentOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.companyDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );
  const statusOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.invoiceStatusTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType!,
      includeAll: true,
    }
  );
  const invoiceUsers = formatDropdownOptions(
    invoiceTransactionTypesData?.invoiceUsers?.nodes,
    {
      getKey: (item) => item.userId!,
      getText: (item) => item.userName!,
      includeAll: true,
    }
  );

  const accountingPeriodOptions =
    invoiceTransactionTypesData?.companyCorporatePeriods?.nodes.map((item) => ({
      key: item.id,
      text:
        item._periodYear +
          ' (' +
          dateFormat(dateConvertions(item.startDate)) +
          ' - ' +
          dateFormat(dateConvertions(item.endDate)) +
          ')' || '',
    })) || [];

  const currenciesOptions = formatDropdownOptions(
    invoiceTransactionTypesData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode!,
      includeAll: true,
    }
  );

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    newFilters = newFilters.filter((element) => element.value);
    onFilterChange(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      urlParams={urlParams}
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      isGlobalAvailable={false}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      hintsAvailable={hintsAvailable}
      onHintsViewToggle={onHintsViewToggle}
      onToggleVisibility={onToggleVisibility}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20 }}
        className={styles.filtersWrapperStyle}
      >
        <DropdownFilter
          filterKey="transactionTypeId"
          options={typeOptions}
          placeholder="Type"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="businessUnitId"
          options={businessUnitOptions}
          placeholder="Business Unit"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="departmentId"
          options={departmentOptions}
          placeholder="Department"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="currencyId"
          options={currenciesOptions}
          placeholder="Currency"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'_companyId'}
          options={companiesOptions || []}
          placeholder={'Productions'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="corporatePeriodId"
          options={accountingPeriodOptions}
          placeholder="Accounting Period"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="_createdByUserid"
          options={invoiceUsers}
          placeholder="Created by"
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="_statusTypeId"
          options={statusOptions}
          placeholder="Status"
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
