import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    cardCompanyId: Yup.string().required('Required').nullable(),
    // apiKey: Yup.string().required('Required').nullable(),
    apiKey: Yup.string().when("isManualProcess",{
      is: false,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    description: Yup.string().when("isManualProcess",{
      is: true,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    businessUnitId: Yup.string().when("isManualProcess",{
      is: true,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable()
    }),
  });
