import { Stack, TooltipHost, ActionButton } from '@fluentui/react';
import { PayCycleValues } from 'ap/paymentCycle/view/types';
import { GetPayCycleDetails_payCycle } from 'ap/paymentCycle/view/__generated__/GetPayCycleDetails';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { AttachFormModal } from './AttachFormModal';
import { PayCycleAvailableDocumentTypes } from 'ap/paymentCycle/__generated__/PayCycleAvailableDocumentTypes';
export interface AttachFormProps {
  paymentCycle: GetPayCycleDetails_payCycle;
  availableDocumentTypes: PayCycleAvailableDocumentTypes | undefined;
}
export const AttachForm: React.FC<AttachFormProps> = ({
  paymentCycle,
  availableDocumentTypes
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { dirty } = useFormikContext<PayCycleValues>();
  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={dirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          paymentCycle={paymentCycle}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  )
}
