import {
  ComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  TooltipHost,
} from '@fluentui/react'; 
import React from 'react';
import { FilterArrayType } from '.';

interface ComboboxFilterProps {
  placeholder?: string;
  options: IComboBoxOption[];
  filterKey: string;
  disabled?: boolean;
  onOptionChange?: (data: FilterArrayType) => void;
}

export const ComboboxFilter: React.FC<ComboboxFilterProps> = ({
  placeholder = 'Combobox',
  options = [],
  disabled = false,
  filterKey,
  onOptionChange,
}) => {
  return (
    <TooltipHost content={placeholder}>
      <ComboBox 
        disabled={disabled}
        dropdownMaxWidth={250}
        placeholder={placeholder}
        options={options}
        styles={comboBoxStyles}
        scrollSelectedToTop
        onChange={(_, option) =>
          onOptionChange?.({
            value: option?.key!,
            filterKey,
            label: option?.text!,
          })
        }
      />
    </TooltipHost>
  );
};

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    backgroundColor: 'transparent',
    ':after': {
      position: 'absolute',
      borderWidth: 0,
      outline: 'unset',
    },
  },
  optionsContainerWrapper: { height: 300 },
  input: {
    backgroundColor: 'transparent',
    alignSelf: 'center',
    width: 100,
    textAlign: 'right',
    outline: 'unset',
  },
};
