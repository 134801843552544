import { IconState } from 'common/types/globalTypes';

export const toTitleCase = (text: string) => {
  const words = text.split(' ');
  const titleCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return titleCaseWords.join(' ');
};

export const getStatusColor = (statusColor: string) => {
  switch (statusColor) {
    case IconState.PENDING:
      return '#d29200';
    case IconState.FAILED:
      return '#d14249';
    case IconState.SENT:
      return '#29a849';
    default:
      return '#0078d4';
  }
};
