import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    onrenderColumnStack: {
      height: 25,
      alignItems: 'center',
    },
    sharedByUserText: {
      width: 120,
    },
    iconSize: {
      fontSize: 14,
    },
    onrenderCreatedDateColumnStack: {
      height: 25
    },
    contentColumnAlignRight: {
      display: 'block',
      textAlign: 'right',
    },
    paddinRight25: {
      paddingRight: 25,
    },
  };
});
