import { dateConvertions } from 'common/utils/dateFormats';
import {
  ACCOUNTING_INITIAL_VALUES,
  DOCUMENT_INITIAL_VALUES,
} from './FormView/constant';
import { EntityDocumentValues } from './types';
import { GetEntityDocument } from './__generated__/GetEntityDocument';

interface DefaultValueProps {
  documentData: GetEntityDocument | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { documentData } = { ...props };
  let defaultValues: EntityDocumentValues = { ...DOCUMENT_INITIAL_VALUES };
  const { documentFileDistributionsByDocumentFileId } = {
    ...documentData?.entityDocument,
  };

  if (documentData?.entityDocument) {
    const {
      _documentContents,
      entityDocumentTypeId,
      fileIndexInformation,
      comment,
      indexName,
      indexAmount,
      indexCurrencyId,
      indexDescription,
      indexReferenceNumber,
      indexTransactionDate,
      extractionTypeId,
      indexTaxCode1,
      indexTaxAmount1,
      indexTaxAccount1,
      indexTaxCode2,
      indexTaxAmount2,
      indexTaxAccount2,
      indexTaxCode3,
      indexTaxAmount3,
      indexTaxAccount3,
      indexTaxCode4,
      indexTaxAmount4,
      indexTaxAccount4,
    } = { ...documentData.entityDocument };

    const entityDocument = {
      _documentContents,
      entityDocumentTypeId,
      fileIndexInformation,
      comment,
      indexName,
      indexAmount,
      indexCurrencyId,
      indexDescription,
      indexReferenceNumber,
      extractionTypeId,
      indexTaxCode1,
      indexTaxAmount1,
      indexTaxAccount1,
      indexTaxCode2,
      indexTaxAmount2,
      indexTaxAccount2,
      indexTaxCode3,
      indexTaxAmount3,
      indexTaxAccount3,
      indexTaxCode4,
      indexTaxAmount4,
      indexTaxAccount4,
    };

    if (
      documentData.entityDocument._isUpdatable ||
      documentData.entityDocument._protectedWithAccountingUpdate
    ) {
      defaultValues = {
        ...entityDocument,
        indexTransactionDate: indexTransactionDate
          ? dateConvertions(indexTransactionDate)
          : null,
        invoiceDistributions:
          documentFileDistributionsByDocumentFileId?.nodes?.length! > 0
            ? [
                ...(documentFileDistributionsByDocumentFileId?.nodes.map(
                  (ele) => ({
                    ...ele,
                    distributionDate: !!ele.distributionDate
                      ? dateConvertions(ele.distributionDate)
                      : null,
                  })
                ) || []),
                { ...ACCOUNTING_INITIAL_VALUES },
              ]
            : [ACCOUNTING_INITIAL_VALUES],
      };
    } else {
      defaultValues = {
        ...entityDocument,
        indexTransactionDate: indexTransactionDate
          ? dateConvertions(indexTransactionDate)
          : null,
        invoiceDistributions: !!documentFileDistributionsByDocumentFileId?.nodes
          ?.length
          ? documentFileDistributionsByDocumentFileId?.nodes.map((ele) => ({
              ...ele,
              distributionDate: !!ele.distributionDate
                ? dateConvertions(ele.distributionDate)
                : null,
            }))
          : [],
      };
    }
  }
  return defaultValues as EntityDocumentValues;
};
