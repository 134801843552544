import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  logo: {
    fill: theme.palette.black,
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  amountStack: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
    textAlign: 'right',
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  footerRow: {
    backgroundColor: theme.palette.neutralLighter,
  },
  statusColumnContainer: {
    height: 25,
  },
  statusType: {
    display: 'block',
    width: 100,
  },
  paymentCount: {
    textAlign: 'end',
  },
  checkboxContainer: {
    flex: 0.2,
    justifyContent: 'end',
  },
  rowBaseStyle: { borderLeft: `5px solid transparent` },
}));
