import {
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
} from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 38, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 38, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 38, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 38, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 38, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 38, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 38, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Shimmer customElementsGroup={ShimmerSection()} />
    </div>
  );
};
