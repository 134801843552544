import { ChartOfAccount } from './__generated__/ChartOfAccount';
import { CHART_OF_ACCOUNTS_VALUES } from './constants';
import { ChartOfAccountsValues } from './types';

interface DefaultValueProps {
  isEdit: boolean;
  data: ChartOfAccount | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isEdit, data } = { ...props };
  let defaultValues: ChartOfAccountsValues = {
    ...CHART_OF_ACCOUNTS_VALUES,
  };
  if (isEdit && data?.chartOfAccount) {
    const {
      __typename,
      id,
      _isUpdatable,
      _isDeletable,
      _rowTimestamp,
      _defaults,
      ...values
    } = {
      ...data?.chartOfAccount,
    };
    defaultValues = {
      ...values,
    };
  }
  return defaultValues as ChartOfAccountsValues;
};
