import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButton,
  IIconProps,
  Stack,
  TextField,
  IDropdownOption,
  Checkbox,
} from '@fluentui/react';
import { loader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useStyles } from './index.styles';
import { AccountingPeriod } from './__generated__/AccountingPeriod';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import {
  EntityDeleteInput,
  InvoiceSigningExportInput,
} from 'common/types/globalTypes';
import {
  InvoiceSigningExport,
  InvoiceSigningExportVariables,
} from './__generated__/InvoiceSigningExport';
import { CSVLink } from 'react-csv';
import { useToasts } from 'react-toast-notifications';
import {
  TransferCycles,
  TransferCyclesVariables,
} from 'common/graphql/__generated__/TransferCycles';
import { InvoiceRow } from 'ap/signing/types';
import { InvoiceSigningExportNonStampingStatus } from './__generated__/InvoiceSigningExportNonStampingStatus';

const INVOICE_SIGNING_EXPORT_NON_STAMPING_STATUS = loader(
  './InvoiceSigningExportNonStampingStatus.graphql'
);

const INVOICE_SIGNING_EXPORT = loader('./invoiceSigningExport.graphql');
const ACCOUNTING_PERIOD = loader('./accountingPeriod.graphql');
const TRANSFER_CYCLE = loader(
  '../../../../common/graphql/transferCycles.graphql'
);

const exportIcon: IIconProps = {
  iconName: 'Go',
  styles: {
    root: {
      fill: 'white',
      marginRight: 10,
    },
  },
};

interface CsvButtonProps {
  selectedRows: InvoiceRow[];
  refetch: () => void;
}

export const ExportCsvButton: React.FC<CsvButtonProps> = ({
  selectedRows,
  refetch,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [includeAccDistro, setIncludeAccDistro] = useState<
    boolean | undefined
  >();
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [stampDescription, setStampDescription] = React.useState<string>('');
  const [accountingPeriodOptions, setAccountingPeriodOptions] = React.useState<
    IDropdownOption[]
  >([]);

  const csvLink = useRef<HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [selectedAccountingOption, setSelectedOption] = useState<
    string | undefined
  >();
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [csvFileName, setCsvFileName] = useState<string>();

  const STAMPING_TITLE =
    'Enter the description and press confirm to download the transaction worksheet.';

  const NON_STAMPING_TITLE =
    'Enter the description and press confirm to stamp entries as entered and download export file.';

  const [fetchStampingStatus, { data: stampingStatus }] =
    useLazyQuery<InvoiceSigningExportNonStampingStatus>(
      INVOICE_SIGNING_EXPORT_NON_STAMPING_STATUS
    );

  const [invoiceExport] = useMutation<
    InvoiceSigningExport,
    InvoiceSigningExportVariables
  >(INVOICE_SIGNING_EXPORT);

  const { data: accoutingPeriod } = useQuery<AccountingPeriod>(
    ACCOUNTING_PERIOD,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [transferCycle, { data: nextCycleData }] = useLazyQuery<
    TransferCycles,
    TransferCyclesVariables
  >(TRANSFER_CYCLE, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    const accP =
      accoutingPeriod?.companyCorporatePeriods?.nodes.map((item) => ({
        disabled: item.isEntryAllowed ? false : true,
        key: item.id,
        text:
          item._periodYear +
            ' (' +
            dateFormat(dateConvertions(item.startDate)) +
            ' - ' +
            dateFormat(dateConvertions(item.endDate)) +
            ')' || '',
      })) || [];
    setAccountingPeriodOptions(accP);
    setSelectedOption(accP[0]?.key);
  }, [accoutingPeriod]);

  const handleConfirmPress = async () => {
    toggleConfirmDialog();
    const rows: EntityDeleteInput[] = [];
    for (let data of selectedRows) {
      rows.push({
        id: data?.id,
        rowTimestamp: data?._rowTimestamp!,
      });
    }
    const exportVariable: InvoiceSigningExportInput = {
      exportDescription: stampDescription,
      entityDelete: rows!,
      corporatePeriodId: selectedAccountingOption
        ? selectedAccountingOption
        : null,
      isDistributionIncluded: includeAccDistro,
    };
    const { errors, data } = await invoiceExport({
      variables: {
        input: exportVariable,
      },
    });

    if (!errors) {
      const { exportResult } = { ...data?.invoiceSigningExport };

      if (exportResult?.isRefreshListviewRequired) {
        refetch();
      }
      if (
        exportResult?.isCreateLocalFilesRequired &&
        exportResult?.exportCycles
      ) {
        const idArray = exportResult.exportCycles.map((item) => item as string);
        transferCycle({
          variables: {
            idArray,
          },
        });
      }
    }

    if (errors) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
      setStampDescription('');
    }
  };

  useEffect(() => {
    const extractData = (str: string) => {
      return str.split('"').filter((e, i) => i & 1);
    };
    nextCycleData?.transferCycles?.nodes.forEach((item, index) => {
      const csvFormatedData: string[][] = [];
      if (item) {
        if (csvLink?.current) {
          setTimeout(() => {
            setCsvFileName(item?._exportFilename!);
            csvFormatedData.push(extractData(item?.transferHeaders!));
            for (let col of item.transactionTransfers.nodes) {
              csvFormatedData.push(extractData(col?.transferValues!));
            }
            setCsvData(csvFormatedData!);
            setStampDescription('');
            csvLink.current?.link?.click();
          }, index * 500);
        }
      }
    });
  }, [nextCycleData]);

  return (
    <>
      <PrimaryButton
        text="Export"
        onClick={() => {
          fetchStampingStatus();
          toggleConfirmDialog();
        }}
        disabled={
          !selectedRows.some(
            (selected) => selected.companyTransactionType?.exportLayout
          )
        }
        iconProps={exportIcon}
      />
      {csvData ? (
        <CSVLink
          data={csvData}
          filename={csvFileName}
          target="_blank"
          ref={csvLink}
        ></CSVLink>
      ) : null}

      <>
        <ConfirmDialog
          isConfirmPrimaryButton
          hidden={hideConfirmRequestDialog}
          title={
            stampingStatus?.invoiceSigningExportNonStampingStatus
              ? STAMPING_TITLE
              : NON_STAMPING_TITLE
          }
          onDismiss={toggleConfirmDialog}
          minWidth={500}
          onConfirm={handleConfirmPress}
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <TextField
              className={styles.marginT10}
              rows={3}
              value={stampDescription}
              onChange={(_event, value) => setStampDescription(value || '')}
              placeholder="Worksheet description"
              resizable={true}
            />
            <CustomDropdown
              label="Accounting Period"
              placeholder="Select"
              selectedKey={selectedAccountingOption}
              options={accountingPeriodOptions}
              onChange={(_, option) => {
                setSelectedOption(option?.key + '');
              }}
              onClear={() => {
                setSelectedOption('');
              }}
            />
            <Checkbox
              label="Include Account distribution"
              onChange={(ev, value) => {
                if (ev) {
                  setIncludeAccDistro(value);
                }
              }}
            />
          </Stack>
        </ConfirmDialog>
      </>
    </>
  );
};
