import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: FontWeights.semibold,
    paddingRight: 10,
  },
  statusType: {
    fontWeight: FontWeights.semibold,
    paddingLeft: 10,
  },
  documentType: {
    color: theme.palette.themePrimary,
  },
  typeContainer: { margin: '10px 20px' },

  commentStyle: {
    maxWidth: 200,
  },
  separator: {
    height: 1,
  },
  container: {
    backgroundColor: theme.palette.neutralLighterAlt,
    width: 250
  },
  leftCol: {
    width: 320,
  },
  rightCol: {
    width: 220,
  },
}));
