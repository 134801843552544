import { intersection, isEmpty } from 'lodash';
import { TravelPolicyAllowanceUpdateTypeInput } from 'common/types/globalTypes';

import {
  TravelPolicyAllowancesRows,
  TravelPolicyAllowancesValues,
} from './types';
import { TRAVEL_POLICY_ALLOWANCES_VALUES } from './constants';
import { TravelPoliciesType } from 'settings/account/TravelPolicy/list';

interface AllowanceValuesProps {
  isNew: boolean;
  travelPolicyData: TravelPoliciesType | undefined;
}

export const getAllowanceValues = (data: AllowanceValuesProps) => {
  const { isNew, travelPolicyData } = { ...data };
  const { travelPolicyAllowancesByTravelPolicyId } = { ...travelPolicyData };
  let defaultValues: TravelPolicyAllowancesValues =
    TRAVEL_POLICY_ALLOWANCES_VALUES;
  if (travelPolicyData) {
    if (isNew) {
      defaultValues = {
        ...defaultValues,
        id: travelPolicyData?.id,
        _rowTimestamp: travelPolicyData?._rowTimestamp,
      };
    } else {
      defaultValues = {
        ...defaultValues,
        id: travelPolicyData?.id,
        _rowTimestamp: travelPolicyData?._rowTimestamp,
        // @ts-ignore
        travelPolicyAllowancesRow:
          travelPolicyAllowancesByTravelPolicyId?.nodes.map((ele) => {
            const result = Object.entries(ele).reduce((prev, [key, value]) => {
              if (
                key === '_isUpdatable' ||
                key === '_isDeletable' ||
                key === 'expenditureType'
              )
                return prev;
              return {
                ...prev,
                [key as keyof TravelPolicyAllowancesRows]: value,
              };
            });

            return result;
          }) || [],
      };
    }
  }
  return defaultValues as TravelPolicyAllowancesValues;
};

export const getUpdatedAllowances = (
  initialAllowances: TravelPolicyAllowancesRows[],
  currentAllowances: TravelPolicyAllowancesRows[]
) => {
  const fieldsToExclude = [
    'country1',
    'country2',
    'stateRegion1',
    'stateRegion2',
  ];
  const updatedTripItems =
    initialAllowances &&
    currentAllowances &&
    intersection(
      initialAllowances.map((item) => item.id),
      currentAllowances.filter((item) => item.id).map((item) => item.id)
    ).reduce((arr, targetId) => {
      const initialItems = initialAllowances!.find(
        (item) => item.id === targetId
      )!;
      const { id, _rowTimestamp, ...updatedItems } = currentAllowances!.find(
        (item) => item.id === targetId
      )!;
      const patch = Object.entries(updatedItems).reduce((res, [key, val]) => {
        if (
          val !== initialItems[key as keyof TravelPolicyAllowancesRows] &&
          !fieldsToExclude.includes(key)
        )
          if (key === 'policyMiles') {
            return { ...res, [key]: Number(val) };
          } else return { ...res, [key]: val };
        return res;
      }, {});
      if (!isEmpty(patch))
        return [
          ...arr,
          {
            id,
            rowTimestamp: _rowTimestamp,
            travelPolicyAllowancePatch: patch,
          },
        ];
      return arr;
    }, [] as TravelPolicyAllowanceUpdateTypeInput[]);
  return updatedTripItems;
};
