import React from 'react';
import { HierarchyComponent } from './HierarchyComponent';
import { MemoizedHierarchyComponent } from 'common/components/PreviewHierarchyComponents/MemoizedHierarchyComponent';

export interface PreHierarchyModalProps {
  entityId: string | undefined;
}
export const PreHierarchyModal: React.FC<PreHierarchyModalProps> = ({
  entityId,
}) => {
  return (
    <>
      <MemoizedHierarchyComponent
        entityId={entityId}
        HierarchyComponent={() => <HierarchyComponent entityId={entityId} />}
      />
    </>
  );
};
