import React, { useState } from 'react';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { useStyles } from './index.styles';
import { Stack, Toggle } from '@fluentui/react';
import { payCycleMode } from 'ap/paymentCycle';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { PayCycleCommonTypes } from 'ap/paymentCycle/__generated__/PayCycleCommonTypes';
import { useQuery, useReactiveVar } from '@apollo/client';
import { loader } from 'graphql.macro';
import { formatDropdownOptions } from 'common/utils';
import { globalMode } from 'utility/cache/ui';
const COMMON_DATA = loader('../../PayCycleCommonTypes.graphql');

interface PaymentCycleSearchBarProps extends SearchBarProps {
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
  toggleActions: () => void;
}
export const PaymentCycleSearchBar: React.FC<PaymentCycleSearchBarProps> = ({
  columns,
  onEnterPress,
  hintsAvailable,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  onHintsViewToggle,
  urlParams,
  onFilterChange,
  onGlobalToggle,
}) => {
  const globalState = useReactiveVar(globalMode);
  const styles = useStyles();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: commonData } = useQuery<PayCycleCommonTypes>(COMMON_DATA);

  const transactionTypeOptions = formatDropdownOptions(
    commonData?.paymentTransactionTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.transactionType!,
      includeAll: true,
    }
  );
  const accountOptions = formatDropdownOptions(
    commonData?.paymentAccounts?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );
  const statusTypeOptions = formatDropdownOptions(
    commonData?.invoiceStatusTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType!,
      includeAll: true,
    }
  );

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      onGlobalToggle={onGlobalToggle}
      urlParams={urlParams}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      hintsAvailable={hintsAvailable}
      onHintsViewToggle={onHintsViewToggle}
      onToggleVisibility={onToggleVisibility}
      renderActions={
        <Stack
          className={styles.actionsWrapperStyle}
          horizontalAlign="center"
          verticalAlign="center"
          horizontal
          tokens={{ childrenGap: 25 }}
        >
          <Toggle
            label="Pay Cycle"
            inlineLabel
            disabled={globalState}
            defaultChecked={false}
            onText="Accounting Entry"
            offText="Accounting Entry"
            onChange={() => {
              globalMode(false);
              payCycleMode(false);
            }}
            className={styles.signingAccountingToggle}
          />
        </Stack>
      }
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'transactionTypeId'}
          options={transactionTypeOptions}
          placeholder={'Transaction type'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'paymentAccountId'}
          options={accountOptions}
          placeholder={'Bank account'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'_statusTypeId'}
          options={statusTypeOptions}
          placeholder={'Status'}
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
