import {
  ActionButton,
  Callout,
  DirectionalHint,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  Checkbox,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { FilterArrayType } from 'common/components/Filters';
import { TextSearchFilter } from 'common/components/Filters/TextSearchFilter';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { ListFiltersProps } from '..';

type ListFilterCalloutProps = Omit<ListFiltersProps, 'documentPoolId'> & {
  onDismiss?: () => void;
  calloutId: string;
};
export const ListFilterCallout: React.FC<ListFilterCalloutProps> = ({
  onDismiss,
  calloutId,
  onFilterChange,
  filterOptions,
}) => {
  const { filterTypes: filters, startsWith: startsWithDefault } = {
    ...filterOptions,
  };
  const styles = useStyles();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const [name, setName] = useState<string | undefined>();
  const [startsWith, setStartsWith] = useState<boolean | undefined>();

  const onFilterApply = () => {
    onFilterChange({ filterTypes, startsWith: startsWith! });
    onDismiss?.();
  };

  const onCancel = () => {
    onDismiss?.();
  };

  useEffect(() => {
    if (filters) {
      setFilterTypes(filters);
      setStartsWith(startsWithDefault);
      const namePos = filters.findIndex((item) => item.filterKey === 'name');
      if (namePos !== -1) setName(filters[namePos].value.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    newFilters = newFilters.filter((element) => element.value);
    setFilterTypes(newFilters);
  };

  const onReset = () => {
    setName(undefined);
    setStartsWith(true);
    setFilterTypes([]);
  };

  return (
    <Callout
      className={styles.callout}
      gapSpace={0}
      target={`#${calloutId}`}
      directionalHint={DirectionalHint.leftTopEdge}
      onDismiss={onDismiss}
      setInitialFocus
    >
      <Stack className={styles.container}>
        <Text variant={'xLarge'}>Filters</Text>
        <Stack tokens={{ childrenGap: 10, padding: '20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Name"
                defaultFilter
                value={name}
                filterKey={'name'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setName(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center">
            <ActionButton
              text="Reset"
              onClick={onReset}
              iconProps={{ iconName: 'RevToggleKey' }}
            />
            <Checkbox
              label="Starts with"
              checked={startsWith}
              onChange={(_, value) => {
                setStartsWith(value);
              }}
            />
          </Stack>
          <Stack tokens={{ padding: '10px 0px', childrenGap: 20 }} horizontal>
            <PrimaryButton text="Apply" onClick={onFilterApply} />
            <DefaultButton text="Cancel" onClick={onCancel} />
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
