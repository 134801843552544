import { ActionButton, Stack } from '@fluentui/react';
import React from 'react';
import { TravelPoliciesType } from '../../list';
import { AllowanceViewModal } from './AllowanceViewModal';

interface AllowanceViewProps {
  travelPolicy: TravelPoliciesType;
  visible: boolean;
  isNew: boolean;
  index: number;
  showModal: () => void;
  hideModal: () => void;
}

export const AllowanceView: React.FC<AllowanceViewProps> = ({
  isNew,
  visible,
  showModal,
  ...props
}) => {
  return (
    <Stack>
      <ActionButton
        onClick={showModal}
        iconProps={{ iconName: 'Add' }}
        text="Policy Allowance"
      />
      {
        visible &&
        <AllowanceViewModal
          isNew={isNew}
          loading={false}
          {...props}
        />
      }
    </Stack>
  )
}
