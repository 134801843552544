import React, { FC } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Stack } from '@fluentui/react';
import { CommunicationGroupRow } from './CommunicationGroupRow';
import {
  CommunicationGroupItemValues,
  CommunicationGroupValues,
} from '../types';
export interface GroupItemSelectOption {
  id?: string | null;
  communicationChannelId: string | null;
  communicationChannelName?: string;
  isRecipientOnly: boolean | null;
  isSenderOnly: boolean | null;
  _isAlert?: boolean | null;
  _rowTimestamp?: string | null;
  isDeleted?: boolean;
}
interface FormikCommunicationGroupItemProps {
  deleteRow: (item: CommunicationGroupItemValues) => void;
}
export const FormikCommunicationGroupItem: FC<
  FormikCommunicationGroupItemProps
> = ({ deleteRow }) => {
  const { values } = useFormikContext<CommunicationGroupValues>();
  return (
    <>
      <FieldArray name="communicationGroupItems">
        {() => (
          <>
            {values?.communicationGroupItems?.map((item, index) => {
              const nameOfFormik = `communicationGroupItems[${index}]`;
              if (item.isDeleted) return null;
              else
                return (
                  <Stack
                    key={index}
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    verticalAlign="center"
                  >
                    <CommunicationGroupRow
                      data={item}
                      name={nameOfFormik}
                      index={index}
                      deleteRow={() => {
                        deleteRow(item);
                      }}
                      selectedKey={
                        item.isSenderOnly
                          ? 'isSenderOnly'
                          : item.isRecipientOnly
                            ? 'isRecipientOnly'
                            : 'Select'
                      }
                    />
                  </Stack>
                );
            })}
          </>
        )}
      </FieldArray>
    </>
  );
};
