import { useLazyQuery } from '@apollo/client';
import {
  RefCode1,
  RefCode1Variables,
} from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteRefCode1/__generated__/RefCode1';
import { loader } from 'graphql.macro';
import React, { useRef, useState } from 'react';
import {
  AutoCompleteFF,
  AutoCompleteFFTextFieldProps,
  refType,
} from '../AutoCompleteFF';
import { useController } from 'react-hook-form';
import {
  RefCode2,
  RefCode2Variables,
} from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteRefCode2/__generated__/RefCode2';
import {
  RefCode4,
  RefCode4Variables,
} from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteRefCode4/__generated__/RefCode4';
import {
  RefCode3,
  RefCode3Variables,
} from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteRefCode3/__generated__/RefCode3';

const REFERENCE_CODE1 = loader('../../../graphql/Reference1Codes.graphql');
const REFERENCE_CODE2 = loader('../../../graphql/Reference2Codes.graphql');
const REFERENCE_CODE3 = loader('../../../graphql/Reference3Codes.graphql');
const REFERENCE_CODE4 = loader('../../../graphql/Reference4Codes.graphql');

export const AutoCompleteFFn: React.FC<AutoCompleteFFTextFieldProps> = ({
  columnData,
  index,
  ffValue,
  FFn,
  ...props
}) => {
  const ref = useRef<refType>();
  const [fieldValue, setFieldValue] = useState<string | null>(null);
  const { field } = useController({
    name: props.name,
  });
  const { value, onChange } = { ...field };
  const [input, setInput] = useState<string>(value);
  const [fetchReferenceCodes1, { data: data1, loading: loading1 }] =
    useLazyQuery<RefCode1, RefCode1Variables>(REFERENCE_CODE1, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
      onCompleted: (data) => {
        if (data.reference1Codes?.nodes.length) {
          const position = data.reference1Codes.nodes.findIndex(
            (item) =>
              item.referenceCode.toUpperCase() === fieldValue?.toUpperCase()
          );
          if (position !== -1) {
            onChange(fieldValue?.toUpperCase());
            ref?.current?.onClearFieldValue();
          }
        } else {
          ref?.current?.onClearFieldValue();
        }
      },
    });
  const [fetchReferenceCodes2, { data: data2, loading: loading2 }] =
    useLazyQuery<RefCode2, RefCode2Variables>(REFERENCE_CODE2, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
      onCompleted: (data) => {
        if (data.reference2Codes?.nodes.length) {
          const position = data.reference2Codes.nodes.findIndex(
            (item) =>
              item.referenceCode.toUpperCase() === fieldValue?.toUpperCase()
          );
          if (position !== -1) {
            onChange(fieldValue?.toUpperCase());
            ref?.current?.onClearFieldValue();
          }
        } else ref?.current?.onClearFieldValue();
      },
    });
  const [fetchReferenceCodes3, { data: data3, loading: loading3 }] =
    useLazyQuery<RefCode3, RefCode3Variables>(REFERENCE_CODE3, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
      onCompleted: (data) => {
        if (data.reference3Codes?.nodes.length) {
          const position = data.reference3Codes.nodes.findIndex(
            (item) =>
              item.referenceCode.toUpperCase() === fieldValue?.toUpperCase()
          );
          if (position !== -1) {
            onChange(fieldValue?.toUpperCase());
            ref?.current?.onClearFieldValue();
          }
        } else ref?.current?.onClearFieldValue();
      },
    });
  const [fetchReferenceCodes4, { data: data4, loading: loading4 }] =
    useLazyQuery<RefCode4, RefCode4Variables>(REFERENCE_CODE4, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
      onCompleted: (data) => {
        if (data.reference4Codes?.nodes.length) {
          const position = data.reference4Codes.nodes.findIndex(
            (item) =>
              item.referenceCode.toUpperCase() === fieldValue?.toUpperCase()
          );
          if (position !== -1) {
            onChange(fieldValue?.toUpperCase());
            ref?.current?.onClearFieldValue();
          }
        } else ref?.current?.onClearFieldValue();
      },
    });

  const handleValueChange = (data: string) => {
    switch (FFn) {
      case 1:
        fetchReferenceCodes1({
          variables: {
            Ref1CodeFilter: {
              referenceCode: {
                startsWithInsensitive: data,
              },
            },
          },
        });
        break;
      case 2:
        fetchReferenceCodes2({
          variables: {
            Ref2CodeFilter: {
              referenceCode: {
                startsWithInsensitive: data,
              },
            },
          },
        });
        break;
      case 3:
        fetchReferenceCodes3({
          variables: {
            Ref3CodeFilter: {
              referenceCode: {
                startsWithInsensitive: data,
              },
            },
          },
        });
        break;
      case 4:
        fetchReferenceCodes4({
          variables: {
            Ref4CodeFilter: {
              referenceCode: {
                startsWithInsensitive: data,
              },
            },
          },
        });
        break;
      default:
        return null;
    }
  };
  const getReferenceCodeData = () => {
    switch (FFn) {
      case 1:
        return data1?.reference1Codes?.nodes;
      case 2:
        return data2?.reference2Codes?.nodes;
      case 3:
        return data3?.reference3Codes?.nodes;
      case 4:
        return data4?.reference4Codes?.nodes;
      default:
        return undefined;
    }
  };

  return (
    <>
      <AutoCompleteFF
        width={columnData?.maxWidth}
        id={`${columnData?.key}${index}`}
        onValueChange={(data) => {
          setFieldValue(data);
          handleValueChange(data);
        }}
        ffValue={ffValue}
        index={index}
        {...props}
        loading={loading1 || loading2 || loading3 || loading4}
        referenceCodeData={getReferenceCodeData()}
        ref={ref}
        input={input}
        setInput={setInput}
        FFn={FFn}
      />
    </>
  );
};
