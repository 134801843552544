import { useTheme } from '@fluentui/react';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { PdfDocumentProps } from '..';
import { BasicForm } from '../BasicForm';
import { StamperView, StampOptions } from '../StamperView';
import { getStyles } from './index.stylesheet';

export const Transaction: React.FC<PdfDocumentProps> = ({ invoiceData }) => {
  const {
    _accountingStampDate,
    _accountingStampTransactionReference,
    _accountingStampUserName,
    _isAccountingEntryStampedComplete,
    _isTransactionCancelled,
    transactionType,
  } = { ...invoiceData?.invoice };
  const stampOptions: StampOptions = {
    _accountingStampDate: _accountingStampDate,
    _accountingStampTransactionReference: _accountingStampTransactionReference,
    _accountingStampUserName: _accountingStampUserName,
    _isAccountingEntryStampedComplete: _isAccountingEntryStampedComplete,
    _isTransactionCancelled: _isTransactionCancelled,
  };

  const { palette } = useTheme();
  const styles = getStyles(palette);

  const isStampVisible =
    invoiceData?.invoice?._isHistory &&
    invoiceData?.invoice?._isAccountingEntry;

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text>Transaction</Text>
          <Text style={styles.transactionType}>
            {transactionType?.transactionType}
          </Text>
        </View>
        {isStampVisible && <StamperView invoiceDetails={stampOptions} />}
      </View>
      <View
        style={{
          width: '100%',
          height: 1,
          backgroundColor: palette.neutralLight,
        }}
      />
      <BasicForm data={invoiceData} />
    </View>
  );
};
