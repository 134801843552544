import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.neutralLight,
  },
  pivotContainer: {
    backgroundColor: theme.palette.neutralLight,
  },
}));
