import { useQuery } from '@apollo/client';
import { IDropdownOption, Link, Stack, Text } from '@fluentui/react';
import { ReactComponent as CarbonAccountingSVG } from 'assets/svgs/carbon_accounting.svg';
import { ReactComponent as CarbonAccountingFillingSVG } from 'assets/svgs/carbon_accounting_filled.svg';
import { EnvironmentalMetrics } from 'common/graphql/__generated__/EnvironmentalMetrics';
import { TransactionLayout } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { FormModal } from './FormModal';
import { useStyles } from './index.styles';
import { InvoiceDetails_invoice_entityEnvironmentalsByEntityId, InvoiceDetails_invoice_entityEnvironmentalsByEntityId_nodes_entityEnvironmentalItems_nodes } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
const GET_ENVIRONMENT_CATEGORY = loader(
  '../../../../common/graphql/EnvironmentalMetrics.graphql'
);
interface FormCarbonAccountingProps {
  entityId: string;
  invoiceDetails:
  | InvoiceDetails_invoice_entityEnvironmentalsByEntityId
  | undefined;
  environmentDeletedUpdated: (isdeleted: boolean) => void;
  isEnvironmentalsExist: boolean;
  isDirty: boolean;
}
export interface CategoryOptionsType extends IDropdownOption {
  key: string | number;
  text: string;
  layoutType: TransactionLayout | undefined | null;
}

export type EntityEnvironmentalItemsType = Omit<
  InvoiceDetails_invoice_entityEnvironmentalsByEntityId_nodes_entityEnvironmentalItems_nodes,
  '_isIntegerFormat'
  | '_isFloatFormat'
  | '_isAmountFormat'
  | "_isDeletable"
  | "_isUpdatable"
  | "__typename"
  | "entityEnvironmentalId"
  | "_rowTimestamp"
  | "environmentalImpactType"
> & {
  isIntegerFormat: boolean | null;
  isFloatFormat: boolean | null;
  isAmountFormat: boolean | null;
};

export const CarbonAccounting: React.FC<FormCarbonAccountingProps> = ({
  entityId,
  invoiceDetails,
  environmentDeletedUpdated,
  isEnvironmentalsExist,
  isDirty,
}) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: environmentCategoryData } = useQuery<EnvironmentalMetrics>(
    GET_ENVIRONMENT_CATEGORY
  );

  const categoryOptions =
    environmentCategoryData?.environmentalMetrics?.nodes.map((item) => ({
      key: item.id,
      text: item.environmentalMetric || '',
      layoutType: item.layoutType,
    })) || [];

  return (
    <Stack verticalAlign="center" className={styles.containerMargin}>
      <Link
        className={styles.textDecorationNone}
        underline={false}
        onClick={() => setIsOpen(true)}
        disabled={isDirty}
      >
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Stack horizontal verticalAlign="center">
            {isEnvironmentalsExist ? (
              <CarbonAccountingFillingSVG className={styles.svgImage} />
            ) : (
              <CarbonAccountingSVG className={styles.svgImage} />
            )}
          </Stack>
          <Text>Carbon accounting</Text>
        </Stack>
      </Link>
      {isOpen && (
        <FormModal
          entityId={entityId}
          isEnvironmentalsExist={isEnvironmentalsExist}
          categoryOptions={categoryOptions}
          invoiceDetails={invoiceDetails}
          environmentDeletedUpdated={environmentDeletedUpdated}
          setOpen={setIsOpen}
        />
      )}
    </Stack>
  );
};
