import { makeStyles, Stack } from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
import React, { useMemo } from 'react';
import { TravelAuthorization_travelAuthorization_trips_nodes } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { GridColumnProps, gridColumns } from '../columns.data';
import { getTotal } from '../utils';

interface FooterProps {
  data: TravelAuthorization_travelAuthorization_trips_nodes[] | undefined;
}

const AmountCustomView: React.FC<{ value: number | undefined }> = ({
  value,
}) => {
  const commonStyles = useCommonStyles();
  if (value)
    return (
      <AmountTextView
        className={commonStyles.bold}
        value={value.toString() || ''}
        nowrap
      />
    );
  else return null;
};
export const Footer: React.FC<FooterProps> = ({ data }) => {
  const styles = useStyles();

  const totals = useMemo(() => {
    const lengthData = data?.length || 0;
    const controlTotalAmount = getTotal(data, 'controlTotalAmount');
    const airfareAmount = getTotal(data, 'airfareAmount');
    const accommodationAmount = getTotal(data, 'accommodationAmount');
    const vehicleRentalAmount = getTotal(data, 'vehicleRentalAmount');
    const transportationAmount = getTotal(data, 'transportationAmount');
    const trainAmount = getTotal(data, 'trainAmount');
    const incidentalAmount = getTotal(data, 'incidentalAmount');
    return {
      lengthData,
      controlTotalAmount,
      airfareAmount,
      accommodationAmount,
      vehicleRentalAmount,
      transportationAmount,
      trainAmount,
      incidentalAmount,
    };
  }, [data]);

  const RenderContent: React.FC<{ item: GridColumnProps }> = ({ item }) => {
    switch (item.key) {
      case 'controlTotalAmount':
      case 'airfareAmount':
      case 'accommodationAmount':
      case 'vehicleRentalAmount':
      case 'transportationAmount':
      case 'trainAmount':
      case 'incidentalAmount':
        return <AmountCustomView value={totals[item.key]} />;
      default:
        return <Stack />;
    }
  };

  return (
    <Stack horizontal>
      {gridColumns.map((item) => {
        return (
          <Stack
            key={item.key}
            style={{
              flex: item.flexProportion,
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              alignItems: item.alignItems,
            }}
            className={styles.container}
          >
            <RenderContent item={item} />
          </Stack>
        );
      })}
      <Stack style={{ width: 30 }} />
    </Stack>
  );
};

export const useStyles = makeStyles(() => ({
  container: {
    padding: '5px 8px',
    wordBreak: 'break-word',
  },
  title: {},
}));
