import { TooltipHost, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { ExtendedMessageData } from 'common/components/MessagesSections/MessageRow/ExtendedMessage';
import { ExtendedMessageFields_extendedMessage_messageSections_nodes_messageTableColumns_nodes } from 'common/graphql/__generated__/ExtendedMessageFields';
import { AlignmentTypes } from 'common/types/globalTypes';
import React from 'react'
import { getAlignment } from '../utils';
import { useStyles } from './index.styles'

type CellData = ExtendedMessageFields_extendedMessage_messageSections_nodes_messageTableColumns_nodes;

interface TableCellProps {
    column: ExtendedMessageData | null;
    cellData: CellData;
    cellIndex: number;
    numberOfColumns: number;
    numberOfRows: number;
    rowNumber: number;
}

export const TableCell: React.FC<TableCellProps> = ({
    column,
    cellData,
    cellIndex,
    numberOfColumns,
    numberOfRows,
    rowNumber
}) => {
    const styles = useStyles();

    const getValue = (cell: CellData, index: number) => {
        switch (index) {
            case 0:
                return cell.column1Value;
            case 1:
                return cell.column2Value;
            case 2:
                return cell.column3Value;
            case 3:
                return cell.column4Value;
            case 4:
                return cell.column5Value;
            default:
                return null;
        }
    };

    return (
        <TooltipHost content={getValue(cellData, cellIndex)?.trim()}>
            <Stack
                className={clsx(
                    styles.valueBorderStyle,
                    numberOfRows - 1 !== rowNumber ? styles.bottomBorderWidth : null,
                    numberOfColumns - 1 !== cellIndex ? styles.rightBorderWidth : null
                )}
            >
                <Text
                    nowrap
                    className={
                        clsx({
                            [styles.textAlignCenter]: getAlignment(column, cellIndex) === AlignmentTypes.CENTER,
                            [styles.textAlignEnd]: getAlignment(column, cellIndex) === AlignmentTypes.RIGHT,
                            [styles.textAlignStart]: getAlignment(column, cellIndex) === AlignmentTypes.LEFT,
                        })
                    }
                >
                    {getValue(cellData, cellIndex) == null ||
                        getValue(cellData, cellIndex)?.trim().length === 0
                        ? '-'
                        : getValue(cellData, cellIndex)?.trim()}
                </Text>
            </Stack>
        </TooltipHost >
    );
};
