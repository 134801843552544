import { SuppliersOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { filterOptionProps } from '../Filters';

type OrderLookup = {
  [direction in OrderDirection]: SuppliersOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    '_sortName',
    {
      [OrderDirection.ASC]: [SuppliersOrderBy._SORT_NAME_ASC],
      [OrderDirection.DESC]: [SuppliersOrderBy._SORT_NAME_DESC],
    },
  ],
  [
    'vendorNumber',
    {
      [OrderDirection.ASC]: [SuppliersOrderBy.VENDOR_NUMBER_ASC],
      [OrderDirection.DESC]: [SuppliersOrderBy.VENDOR_NUMBER_DESC],
    },
  ],
  [
    'rating',
    {
      [OrderDirection.ASC]: [SuppliersOrderBy.RATINGS_BY_ENTITY_ID__COUNT_ASC],
      [OrderDirection.DESC]: [
        SuppliersOrderBy.RATINGS_BY_ENTITY_ID__COUNT_DESC,
      ],
    },
  ],

  [
    'status',
    {
      [OrderDirection.ASC]: [
        SuppliersOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC,
      ],
      [OrderDirection.DESC]: [
        SuppliersOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [SuppliersOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), SuppliersOrderBy.PRIMARY_KEY_ASC];
};

export const toFilterVariable = (filterOptions: filterOptionProps) =>
  filterOptions.filterTypes.map((filter) => {
    const subFilterKey = 'supplierAddressesBySupplierId';

    switch (filter.filterKey) {
      case '_sortName':
      case 'vendorNumber':
        return {
          [filter.filterKey]: {
            [filterOptions.startsWith
              ? 'startsWithInsensitive'
              : 'includesInsensitive']: filter.value,
          },
        };
      case 'statusType':
        return {
          [filter.filterKey]: {
            [filter.filterKey]: {
              [filterOptions.startsWith
                ? 'startsWithInsensitive'
                : 'includesInsensitive']: filter.value,
            },
          }
        };
      case 'country':
        return {
          [subFilterKey]: {
            some: {
              country: {
                [filter.filterKey]: {
                  [filterOptions.startsWith
                    ? 'startsWithInsensitive'
                    : 'includesInsensitive']: filter.value,
                },
              },
            },
          },
        };
      case 'stateRegion':
        return {
          [subFilterKey]: {
            some: {
              stateRegion: {
                stateRegionDescription: {
                  [filterOptions.startsWith
                    ? 'startsWithInsensitive'
                    : 'includesInsensitive']: filter.value,
                },
              },
            },
          },
        };
      case 'cityName':
      case 'addressLine1':
        return {
          [subFilterKey]: {
            some: {
              [filter.filterKey]: {
                [filterOptions.startsWith
                  ? 'startsWithInsensitive'
                  : 'includesInsensitive']: filter.value,
              },
            },
          },
        };
      case 'rating':
        return {
          supplierRatingResults: {
            starValue: {
              greaterThanOrEqualTo: filter.value,
            },
          },
        };
      default:
        return undefined;
    }
  });
