import { makeVar, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { CorporateWorkgroups_corporateWorkgroups } from '../list/__generated__/CorporateWorkgroups';
import {
  CorporateWorkgroup,
  CorporateWorkgroupVariables,
} from './__generated__/CorporateWorkgroup';
import {
  CorporateWorkgroupCreate,
  CorporateWorkgroupCreateVariables,
} from './__generated__/CorporateWorkgroupCreate';
import {
  CorporateWorkgroupUpdate,
  CorporateWorkgroupUpdateVariables,
} from './__generated__/CorporateWorkgroupUpdate';
import { FormProvider, useForm } from 'react-hook-form';
import { CorporateWorkgroupValues } from './types';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validations';
import { FormView } from './FormView';
import { getDefaultValues } from './utils';
import { CORPORATE_WORKGROUP_VALUES } from './constants';
import {
  CorporateWorkgroupInput,
  CorporateWorkgroupPatch,
} from 'common/types/globalTypes';
import { isEmpty } from 'lodash';
const CORPORATE_WORKGROUP = loader('./CorporateWorkgroup.graphql');
const CORPORATE_WORKGROUP_CREATE = loader('./CorporateWorkgroupCreate.graphql');
const CORPORATE_WORKGROUP_UPDATE = loader('./CorporateWorkgroupUpdate.graphql');
const CORPORATE_WORKGROUP_FIELDS = loader(
  '../CorporateWorkgroupsFields.graphql'
);
const CORPORATE_WORKGROUP_FRAGMENT_NAME = 'CorporateWorkgroupsFields';
export const isPOCreated = makeVar<boolean>(false);
export const CorporateWorkGroupView = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { workGroupId } = useParams<{ workGroupId: string | undefined }>();
  const saveAnotherTransaction = useRef<boolean>(false);
  const isNew = !workGroupId;

  const {
    data: corporateWorkgroupData,
    loading: corporateWorkgroupDataLoading,
    refetch,
  } = useQuery<CorporateWorkgroup, CorporateWorkgroupVariables>(
    CORPORATE_WORKGROUP,
    {
      variables: {
        id: workGroupId!,
      },
      skip: !workGroupId,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [
    createCorporateWorkgroup,
    { loading: createCorporateWorkgroupLoading },
  ] = useMutation<CorporateWorkgroupCreate, CorporateWorkgroupCreateVariables>(
    CORPORATE_WORKGROUP_CREATE,
    { errorPolicy: 'all' }
  );

  const [
    updateCorporateWorkgroup,
    { loading: updateCorporateWorkgroupLoading },
  ] = useMutation<CorporateWorkgroupUpdate, CorporateWorkgroupUpdateVariables>(
    CORPORATE_WORKGROUP_UPDATE,
    { errorPolicy: 'all' }
  );

  const formMethods = useForm<CorporateWorkgroupValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { handleSubmit, trigger, reset } = { ...formMethods };

  const { _rowTimestamp } = { ...corporateWorkgroupData?.corporateWorkgroup };

  const onHandleSubmit = async (values: CorporateWorkgroupValues) => {
    const requestPayLoad: CorporateWorkgroupInput = {
      ...values,
      workgroupNumber: values.workgroupNumber || '',
      name: values.name || '',
      prefixCode: values.prefixCode || '',
      productionPhaseId: values.productionPhaseId!,
      chartOfAccountsId: values.chartOfAccountsId || '',
      businessUnitId: values.businessUnitId || '',
      productionStatusTypeId: values.productionStatusTypeId!,
    };
    if (isNew) {
      const { errors, data } = await createCorporateWorkgroup({
        variables: {
          input: {
            corporateWorkgroup: requestPayLoad,
          },
        },
        update: (cache, { data }) => {
          if (data?.corporateWorkgroupCreate?.corporateWorkgroup) {
            cache.modify({
              fields: {
                corporateWorkgroups(
                  existing: CorporateWorkgroups_corporateWorkgroups
                ) {
                  if (data?.corporateWorkgroupCreate?.corporateWorkgroup) {
                    const newWorkGroupRef = cache.writeFragment({
                      data: data.corporateWorkgroupCreate.corporateWorkgroup,
                      fragment: CORPORATE_WORKGROUP_FIELDS,
                      fragmentName: CORPORATE_WORKGROUP_FRAGMENT_NAME,
                    });
                    return {
                      ...existing,
                      nodes: [newWorkGroupRef, ...existing.nodes],
                    };
                  }
                },
              },
            });
          }
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      }
      if (data?.corporateWorkgroupCreate?.corporateWorkgroup) {
        if (!saveAnotherTransaction.current) {
          history.replace(
            `/account-management/work-groups/work-group/${data?.corporateWorkgroupCreate?.corporateWorkgroup.id}`
          );
          isPOCreated(true);
        } else {
          reset(CORPORATE_WORKGROUP_VALUES);
          trigger();
          isPOCreated(false);
        }
        addToast('Workgroup added successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const defaultValues = getDefaultValues({
        isNew,
        corporateWorkgroupData,
      });
      const corporateWorkgroupPatch: CorporateWorkgroupPatch = Object.entries(
        requestPayLoad
      ).reduce((res, [key, val]) => {
        if (val !== defaultValues[key as keyof CorporateWorkgroupValues]) {
          return { ...res, [key]: val };
        }
        return res;
      }, {});
      if (!!workGroupId && !!_rowTimestamp) {
        const { errors } = await updateCorporateWorkgroup({
          variables: {
            input: {
              id: workGroupId,
              rowTimestamp: _rowTimestamp,
              corporateWorkgroupPatch: !isEmpty(corporateWorkgroupPatch)
                ? corporateWorkgroupPatch
                : undefined,
            },
          },
        });
        if (errors?.length) {
          addToast(errors[0].message, {
            appearance: 'error',
          });
        } else {
          addToast('Workgroup edited successfully.', {
            appearance: 'success',
          });
        }
      }
    }
  };

  useEffect(() => {
    const defaultValues = getDefaultValues({
      isNew,
      corporateWorkgroupData,
    });
    reset(defaultValues);
    trigger();
  }, [corporateWorkgroupData, isNew, reset, trigger]);

  return (
    <FormProvider {...formMethods}>
      <FormView
        isNew={isNew}
        corporateWorkgroupData={corporateWorkgroupData}
        dataLoading={corporateWorkgroupDataLoading}
        isCreating={createCorporateWorkgroupLoading}
        isUpdating={updateCorporateWorkgroupLoading}
        onSave={handleSubmit(onHandleSubmit)}
        isSaveAnother={(value) => (saveAnotherTransaction.current = value)}
        refetch={refetch}
      />
    </FormProvider>
  );
};
