import { DayOfWeek, Stack } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDatePicker,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import Decimal from 'decimal.js';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  PurchaseOrderItemFormProps,
  PurchaseOrderItemLayout,
} from '../interface';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { EndDateCalculator } from '../EndDateCalculator';
import { StandardCalculationType } from 'common/types/globalTypes';

type ContractualItemItemProps = PurchaseOrderItemLayout & {
  children?: React.ReactNode;
};

export const ContractualItem: React.FC<ContractualItemItemProps> = ({
  selectedItemType,
  commonData,
  purchaseOrderData,
  isNew,
  children,
}) => {
  const { purchaseOrder } = { ...purchaseOrderData };
  const { isTaxAllowed, isDiscountAllowed } = { ...selectedItemType };
  const expenditureTypeOption = useMemo(() => {
    if (
      selectedItemType?.isExpenditureTypeAllowed &&
      !selectedItemType.isUnitOfMeasureRequired
    )
      return (
        commonData?.expenditureTypes?.nodes.map((item) => ({
          key: item.id!,
          text: item.expenditureType! || '',
          ...item,
        })) || []
      );
    else {
      return (
        selectedItemType?.unitOfMeasureGroup?.expenditureTypes.nodes.map(
          (item) => ({
            key: item.id!,
            text: item.expenditureType! || '',
            ...item,
          })
        ) || []
      );
    }
  }, [selectedItemType, commonData]);
  const { setValue, watch } = useFormContext<PurchaseOrderItemFormProps>();
  const watchStartDate = watch('itemFromDate');

  const calculateAmount = () => {
    const watchItemQuantity = watch('itemQuantity');
    const watchItemUnitPrice = watch('itemUnitPrice');

    const extendedAmount = new Decimal(watchItemQuantity || 1)
      .times(watchItemUnitPrice || 0)
      .toNumber()
      .toFixed(2);
    setValue('itemExtendedAmount', extendedAmount);
  };

  const fieldDisabled = !purchaseOrder?._isUpdatable && !isNew;

  return (
    <Stack
      tokens={{
        childrenGap: 10,
      }}
    >
      <Stack tokens={{ childrenGap: 20, padding: '20px 20px' }}>
        <Stack horizontal grow tokens={{ childrenGap: 20 }}>
          {selectedItemType?.isExpenditureTypeAllowed && (
            <Stack.Item style={{ width: 255 }}>
              <FormHookDropdown
                label="Expenditure Type"
                placeholder="Select"
                options={expenditureTypeOption}
                name="expenditureTypeId"
                disabled={fieldDisabled}
              />
            </Stack.Item>
          )}

          <Stack.Item grow={2}>
            <FormHookTextField
              name="description"
              label="Description"
              placeholder="Description"
              disabled={fieldDisabled}
            />
          </Stack.Item>

          <Stack grow={2} />
        </Stack>

        <Stack horizontal grow tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ width: 250 }}>
            <FormHookAmount
              name="itemQuantity"
              label="Quantity"
              placeholder="Quantity"
              fixedDecimalScale
              decimalScale={0}
              allowNegative={false}
              thousandSeparator={false}
              disabled={fieldDisabled}
              onAmountChange={() => calculateAmount()}
            />
          </Stack.Item>

          <Stack.Item grow={1}>
            <FormHookAmount
              name="itemUnitPrice"
              label="Rate"
              placeholder="Rate"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={false}
              thousandSeparator
              disabled={fieldDisabled}
              onAmountChange={() => calculateAmount()}
            />
          </Stack.Item>

          <Stack.Item grow={1}>
            <FormHookAmount
              name="itemExtendedAmount"
              label="Item Total"
              placeholder="Item Total"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={false}
              thousandSeparator
              disabled={fieldDisabled}
            />
          </Stack.Item>

          <Stack grow={2} />
        </Stack>

        <Stack horizontal grow={3}>
          <Stack horizontal grow={1} tokens={{ childrenGap: 20 }}>
            <Stack grow={1}>
              <FormHookDatePicker
                name="itemFromDate"
                placeholder="Start Date"
                label="Start Date"
                firstDayOfWeek={DayOfWeek.Sunday}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay
                showGoToToday
                disabled={fieldDisabled}
                onSelectDate={(date) => {
                  if (date)
                    setValue('itemFromDate', dateFormat(date.toString()), {
                      shouldDirty: true,
                    });
                }}
              />
            </Stack>
            <Stack grow={1}>
              <FormHookDatePicker
                name="itemToDate"
                placeholder="End Date"
                label="End Date"
                firstDayOfWeek={DayOfWeek.Sunday}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay
                disabled={fieldDisabled}
                onSelectDate={(date) => {
                  if (date)
                    setValue('itemToDate', dateFormat(date.toString()), {
                      shouldDirty: true,
                    });
                }}
              />
            </Stack>
            {watchStartDate && (
              <EndDateCalculator
                calculationType={StandardCalculationType.PO_DAILY}
                startDate={watchStartDate.toString()}
                disabled={fieldDisabled}
                label={'Calculate end date'}
                abbreviation={'Enter days'}
                onEndDateReturn={(date) => {
                  setValue('itemToDate', dateFormat(dateConvertions(date)));
                }}
              />
            )}
          </Stack>
          <Stack grow={2} />
        </Stack>
      </Stack>

      {children}
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
          padding: '0px 20px 20px 20px',
        }}
      >
        {isDiscountAllowed && (
          <FormHookCheckBox
            name="isDiscounted"
            label="Discounted"
            disabled={fieldDisabled}
          />
        )}
        {isTaxAllowed && (
          <FormHookCheckBox
            name="isTaxable"
            label="Taxable"
            disabled={fieldDisabled}
          />
        )}
      </Stack>
    </Stack>
  );
};
