import { ColumnActionsMode, IColumn } from '@fluentui/react';

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

export const columnsPayCycle: IColumn[] = [
  {
    key: 'itemDescription1',
    name: 'Payee',
    fieldName: 'itemDescription1',
    minWidth: 150,
    ...commonProps,
  },
  {
    key: 'itemDescription3',
    name: 'Payment #',
    fieldName: 'itemDescription3',
    minWidth: 150,
    ...commonProps,
  },
  {
    key: 'itemDate1',
    name: 'Date',
    fieldName: 'itemDate1',
    minWidth: 100,
    isPadded: true,
    styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
    ...commonProps,
  },

  {
    key: 'amount',
    name: 'Paid Amount',
    fieldName: 'itemAmount1',
    minWidth: 100,
    isPadded: true,
    styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
    ...commonProps,
  },
  {
    key: 'itemDescription2',
    name: 'Memo',
    fieldName: 'itemDescription2',
    minWidth: 140,
    ...commonProps,
  },
];
