import { useQuery } from '@apollo/client';
import { DetailsListLayoutMode, DetailsRow, IColumn, IDetailsFooterProps, IRenderFunction, IconButton, Selection, SelectionMode, ShimmeredDetailsList, Stack, Text } from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { NoDataView } from 'common/components/DataPlaceholders';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { useRef } from 'react';
import { PurchaseOrderInvoiceSchedules, PurchaseOrderInvoiceSchedulesVariables, PurchaseOrderInvoiceSchedules_purchaseOrderInvoiceSchedules_nodes } from './__generated__/PurchaseOrderInvoiceSchedules';
import { columns } from './column.data';
import { useStyles } from './index.styles';

const PURCHASE_ORDER_INVOICE_SCHEDULES = loader("./PurchaseOrderInvoiceSchedules.graphql");

export type InvoiceSchedulesItem = PurchaseOrderInvoiceSchedules_purchaseOrderInvoiceSchedules_nodes & {
  invoiceNumber?: string | null;
};

interface ScheduleListProps {
  purchaseOrderData: PurchaseOrder | undefined;
  onEditClick: (data: InvoiceSchedulesItem) => void;
  onRowSelection: (data: InvoiceSchedulesItem[]) => void;
}

export const ScheduleList: React.FC<ScheduleListProps> = ({
  purchaseOrderData,
  onEditClick,
  onRowSelection,
}) => {
  const styles = useStyles();

  const {
    loading,
    data,
  } = useQuery<PurchaseOrderInvoiceSchedules, PurchaseOrderInvoiceSchedulesVariables>(
    PURCHASE_ORDER_INVOICE_SCHEDULES,
    {
      variables: {
        purchaseOrderId: purchaseOrderData?.purchaseOrder?.id!
      },
      skip: !purchaseOrderData?.purchaseOrder?.id,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  )

  const {
    nodes
  } = { ...data?.purchaseOrderInvoiceSchedules }

  const transformedData = nodes?.map((ele) => ({
    ...ele,
    scheduledDate: ele?.scheduledDate ? dateFormat(dateConvertions(ele?.scheduledDate)) : null,
    invoiceNumber: ele.invoice?.invoiceNumber
  } as InvoiceSchedulesItem)) || [];

  const _renderItemColumn = (
    item: InvoiceSchedulesItem,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof InvoiceSchedulesItem
      ] as string;

      switch (column?.key) {
        case 'scheduledAmount':
          return (
            <Stack
              verticalAlign="center"
              horizontalAlign="end"
              className={styles.columnCell}
            >
              <AmountTextView
                value={fieldContent}
              />
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                onClick={() => {
                  onEditClick(item)
                }}
              />
            </Stack>
          )
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.columnCell}
            >
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const renderFooterColumn = (
    _item?: InvoiceSchedulesItem,
    _index?: number,
    column?: IColumn
  ) => {
    if (
      column?.key === 'scheduledAmount' &&
      Number(data?.purchaseOrderInvoiceSchedules?.aggregates?.sum?.scheduledAmount) > 0
    ) {
      const totalAmount = data?.purchaseOrderInvoiceSchedules?.aggregates?.sum
        ?.scheduledAmount
        ? Number(
          data?.purchaseOrderInvoiceSchedules?.aggregates?.sum?.scheduledAmount
        ).toFixed(2)
        : '-';
      return (
        <Stack>
          <AmountTextView
            value={totalAmount}
            className={styles.scheduleAmountTotal}
          />
        </Stack>
      );
    }
    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (
      !props ||
      !(Number(data?.purchaseOrderInvoiceSchedules?.aggregates?.sum?.scheduledAmount) > 0)
    ) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
        className={styles.listFooter}
      />
    );
  };

  const selection: React.MutableRefObject<Selection<InvoiceSchedulesItem>> = useRef(
    // @ts-ignore
    new Selection<InvoiceSchedulesItem>({
      getKey: (item: InvoiceSchedulesItem) => item.id,
      onSelectionChanged: () =>
        onRowSelection(selection.current.getSelection().filter((item) => !!item))
    })
  );

  return (
    <Stack
      className={styles.container}
    >
      <ShimmeredDetailsList
        compact
        setKey="set"
        enableShimmer={loading}
        shimmerLines={7}
        columns={columns}
        items={transformedData || []}
        selectionMode={SelectionMode.multiple}
        layoutMode={DetailsListLayoutMode.justified}
        onRenderItemColumn={_renderItemColumn}
        onRenderDetailsFooter={renderFooter}
        // @ts-ignore
        selection={selection.current}
      />
      {
        !loading && transformedData.length === 0 &&
        <Stack>
          <NoDataView
            show
            title='No schedule available.'
          />
        </Stack>
      }
    </Stack>
  )
}
