import { makeVar, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserDefaults } from 'Preferences/__generated__/UserDefaults';
import { PurchaseOrderPatch } from 'common/types/globalTypes';
import { dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import { PurchaseOrderSearch_purchaseOrderSearch } from 'purchaseOrder/list/__generated__/PurchaseOrderSearch';
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { FormView } from './FormView';
import {
  PurchaseOrder,
  PurchaseOrderVariables,
} from './__generated__/PurchaseOrder';
import {
  PurchaseOrderCreate,
  PurchaseOrderCreateVariables,
} from './__generated__/PurchaseOrderCreate';
import {
  PurchaseOrderUpdate,
  PurchaseOrderUpdateVariables,
} from './__generated__/PurchaseOrderUpdate';
import { PurchaseOrderValues } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validations';
import { fieldsToExclude } from './constant';
import { PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals } from 'purchaseOrder/list/__generated__/PurchaseOrderSearchFilterTotals';
const PURCHASE_ORDER_CREATE = loader('./PurchaseOrderCreate.graphql');
const PURCHASE_ORDER = loader('./PurchaseOrder.graphql');
const PURCHASE_UPDATE = loader('./PurchaseOrderUpdate.graphql');
const USER_DEFAULTS = loader('../../Preferences/UserDefaults.graphql');
const PURCHASE_ORDER_LIST_FIELDS = loader('./PurchaseOrderListFields.graphql');
const PURCHASE_ORDER_FRAGMENT_NAME = 'PurchaseOrderListFields';

export const isPOCreated = makeVar<boolean>(false);

export const PurchaseOrderView = () => {
  const { addToast } = useToasts();
  const { purchaseOrderId } = useParams<{
    purchaseOrderId: string | undefined;
  }>();
  const isNew = !purchaseOrderId;
  const history = useHistory();
  const saveAnotherTransaction = useRef<boolean>(false);
  const fetched = useRef<boolean>(false);

  const { data: userDefaultsData } = useQuery<UserDefaults>(USER_DEFAULTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const {
    data: purchaseOrderData,
    loading: purchaseOrderDataLoading,
    refetch,
  } = useQuery<PurchaseOrder, PurchaseOrderVariables>(PURCHASE_ORDER, {
    variables: {
      id: purchaseOrderId!,
    },
    skip: !purchaseOrderId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [createPurchaseOrder, { loading: createPurchaseOrderLoading }] =
    useMutation<PurchaseOrderCreate, PurchaseOrderCreateVariables>(
      PURCHASE_ORDER_CREATE,
      { errorPolicy: 'all' }
    );

  const [updatePurchaseOrder, { loading: updatePurchaseOrderLoading }] =
    useMutation<PurchaseOrderUpdate, PurchaseOrderUpdateVariables>(
      PURCHASE_UPDATE,
      { errorPolicy: 'all' }
    );

  const formMethods = useForm<PurchaseOrderValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { handleSubmit, trigger, reset } = { ...formMethods };

  const defaultValues = getDefaultValues({
    isNew,
    purchaseOrderData,
    userDefaultsData: userDefaultsData?.userDefaults?.nodes || [],
  });

  const onHandleSubmit = async (values: PurchaseOrderValues) => {
    const {
      purchaseOrderDate,
      supplierId,
      purchaseOrderTypeId,
      currencyId,
      departmentId,
      businessUnitId,
      controlTotalAmount,
      scheduledDeliveryDate,
      purchaseOrderItems,
      supplier,
      businessUnit,
      ...rest
    } = { ...values };

    const requestPayLoad = {
      ...rest,
      purchaseOrderTypeId: Number(purchaseOrderTypeId!),
      supplierId: supplierId!,
      currencyId: Number(currencyId!),
      departmentId: departmentId!,
      businessUnitId: businessUnitId!,
      purchaseOrderDate: purchaseOrderDate ? dateFormat(purchaseOrderDate) : '',
      scheduledDeliveryDate: scheduledDeliveryDate
        ? dateFormat(scheduledDeliveryDate)
        : null,
    };

    if (isNew) {
      const { data, errors } = await createPurchaseOrder({
        variables: {
          input: {
            purchaseOrder: requestPayLoad,
          },
        },
        update: (cache, { data }) => {
          try {
            cache.modify({
              fields: {
                purchaseOrderSearchFilterTotals(
                  existing: PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    const {
                      controlTotalAmount,
                      _baseCurrencyAmount,
                      _spotCurrencyAmount,
                    } = {
                      ...data?.purchaseOrderCreate?.purchaseOrder,
                    };
                    const controlTotalAmountSum =
                      Number(existing?.totalAmount1) +
                        Number(controlTotalAmount) ?? 0;
                    const baseCurrencyAmountSum =
                      Number(existing?.totalAmount2) +
                        Number(_baseCurrencyAmount) ?? 0;
                    const spotCurrencyAmountSum =
                      Number(existing?.totalAmount3) +
                        Number(_spotCurrencyAmount) ?? 0;
                    return {
                      ...existing,
                      totalAmount1: controlTotalAmountSum?.toString(),
                      totalAmount2: baseCurrencyAmountSum?.toString(),
                      totalAmount3: spotCurrencyAmountSum?.toString(),
                      totalCount1: existing?.totalCount1 || 0 + 1,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
                purchaseOrderSearch(
                  existing: PurchaseOrderSearch_purchaseOrderSearch
                ) {
                  if (data?.purchaseOrderCreate?.purchaseOrder) {
                    const newPORef = cache.writeFragment({
                      data: data.purchaseOrderCreate.purchaseOrder,
                      fragment: PURCHASE_ORDER_LIST_FIELDS,
                      fragmentName: PURCHASE_ORDER_FRAGMENT_NAME,
                    });
                    return {
                      ...existing,
                      nodes: [newPORef, ...existing.nodes],
                    };
                  }
                },
              },
            });
          } catch (error) {}
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      }
      if (data?.purchaseOrderCreate?.purchaseOrder) {
        if (!saveAnotherTransaction.current) {
          isPOCreated(true);
        } else {
          isPOCreated(false);
        }
        history.replace(
          `/purchase-orders/purchase-order/${data?.purchaseOrderCreate?.purchaseOrder?.id}`
        );
        addToast('Purchase order added successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const purchaseOrderPatch: PurchaseOrderPatch = Object.entries(
        requestPayLoad
      ).reduce((res, [key, val]) => {
        if (
          val !== defaultValues[key as keyof PurchaseOrderValues] &&
          !fieldsToExclude.includes(key)
        ) {
          return { ...res, [key]: val };
        }
        return res;
      }, {});

      const { errors } = await updatePurchaseOrder({
        variables: {
          input: {
            id: purchaseOrderId!,
            rowTimestamp: purchaseOrderData?.purchaseOrder?._rowTimestamp!,
            purchaseOrderPatch: !isEmpty(purchaseOrderPatch)
              ? purchaseOrderPatch
              : undefined,
          },
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Purchase Order edited successfully', {
          appearance: 'success',
        });
      }
    }
  };

  useEffect(() => {
    if (purchaseOrderData && !fetched.current) {
      const defaultValues = getDefaultValues({
        isNew,
        purchaseOrderData,
        userDefaultsData: userDefaultsData?.userDefaults?.nodes || [],
      });
      reset(defaultValues);
      trigger();
      fetched.current = true;
    } else {
      const defaultValues = getDefaultValues({
        isNew,
        purchaseOrderData,
        userDefaultsData: userDefaultsData?.userDefaults?.nodes || [],
      });
      reset(defaultValues);
      trigger();
    }
  }, [purchaseOrderData, isNew, reset, userDefaultsData, trigger]);

  return (
    <>
      <FormProvider {...formMethods}>
        <FormView
          onSave={handleSubmit(onHandleSubmit)}
          isSaveAnother={(value) => (saveAnotherTransaction.current = value)}
          isNew={isNew}
          purchaseOrderData={purchaseOrderData}
          isCreating={createPurchaseOrderLoading}
          isUpdating={updatePurchaseOrderLoading}
          dataLoading={purchaseOrderDataLoading}
          userDefaultsData={userDefaultsData?.userDefaults?.nodes || []}
          refetch={refetch}
        />
      </FormProvider>
    </>
  );
};
