import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  makeStyles,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { DangerButton } from 'common/components/DangerButton';
import { loader } from 'graphql.macro';
import { useApolloClient, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import {
  PaymentDelete,
  PaymentDeleteVariables,
} from 'ap/paymentTracking/__generated__/PaymentDelete';
import { GetPayCycleDetails_payCycle_payments_nodes } from '../../__generated__/GetPayCycleDetails';
const DELETE_PAYMENT = loader(
  '../../../../paymentTracking/PaymentDelete.graphql'
);

interface DeletePaymentProps {
  _isUpdatable: boolean | null | undefined;
  payment?: GetPayCycleDetails_payCycle_payments_nodes;
  _onConfirm?: () => void;
}
const useStyles = makeStyles((theme) => ({
  container: {
    height: 40,
  },
}));

export const DeletePayment: React.FC<DeletePaymentProps> = ({
  payment,
  _isUpdatable,
}) => {
  const { cache } = useApolloClient();
  const { addToast } = useToasts();
  const styles = useStyles();
  const [visible, setVisible] = useState(false);
  const { _isDeletable, id, _rowTimestamp } = {
    ...payment,
  };
  const [DeletePayment] = useMutation<PaymentDelete, PaymentDeleteVariables>(
    DELETE_PAYMENT
  );

  const onDelete = async () => {
    const { errors } = await DeletePayment({
      variables: {
        input: { entityDelete: [{ id: id!, rowTimestamp: _rowTimestamp! }] },
      },
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      const identify = cache.identify({
        ...payment,
      });
      cache.evict({ id: identify });
      cache.gc();
      addToast('Payment deleted successfully', { appearance: 'success' });
    }
  };
  if (_isUpdatable && payment?._isDeletable)
    return (
      <Stack>
        <IconButton
          disabled={!_isDeletable}
          iconProps={{ iconName: 'Delete' }}
          onClick={() => setVisible(true)}
        />

        {visible && (
          <Dialog
            hidden={false}
            dialogContentProps={{
              title: `Delete Document`,
              type: DialogType.largeHeader,
              subText: 'Are you sure you want to delete this document?',
            }}
            minWidth={350}
          >
            <Stack className={styles.container}>
              <DialogFooter>
                <DangerButton
                  onClick={() => {
                    onDelete();
                    setVisible(false);
                  }}
                  text={'Delete'}
                />
                <DefaultButton
                  onClick={() => setVisible(false)}
                  text="Cancel"
                />
              </DialogFooter>
            </Stack>
          </Dialog>
        )}
      </Stack>
    );
  else return null;
};
