import { DefaultButton, PrimaryButton, ProgressIndicator, Stack, makeStyles } from '@fluentui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TravelPolicyValues } from '../types';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "10px 20px 20px",
    backgroundColor: theme.palette.neutralLighterAlt,
    marginTop: "10px !important",
  },
  loaderContainer: {
    height: 24
  }
}));

interface FooterProps {
  headerTitle: string;
  loading: boolean;
  onSave: () => void;
  onDismiss: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  headerTitle,
  loading,
  onSave,
  onDismiss,
}) => {
  const styles = useStyles();
  const {
    formState: {
      isDirty,
      isValid,
      isSubmitting,
    }
  } = useFormContext<TravelPolicyValues>();

  return (
    <Stack
      className={styles.footer}
    >
      <Stack className={styles.loaderContainer}>
        {loading && <ProgressIndicator />}
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20
        }}
      >
        <PrimaryButton
          disabled={!isDirty || !isValid || isSubmitting}
          text={headerTitle}
          onClick={onSave}
        />
        <DefaultButton
          onClick={onDismiss}
          text="Cancel"
        />
      </Stack>
    </Stack>
  )
}
