import { TABLE_ROWS } from 'common/constants';
import { CompanyBusinessUnitsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { CompanyBusinessUnits, CompanyBusinessUnitsVariables } from './__generated__/CompanyBusinessUnits';

type OrderLookup = {
  [direction in OrderDirection]: CompanyBusinessUnitsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [CompanyBusinessUnitsOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [CompanyBusinessUnitsOrderBy.NAME_DESC],
    },
  ],
  [
    'emailProductionOfficeDescription',
    {
      [OrderDirection.ASC]: [
        CompanyBusinessUnitsOrderBy.EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC,
      ],
      [OrderDirection.DESC]: [
        CompanyBusinessUnitsOrderBy.EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC,
      ],
    },
  ],
  [
    'emailReplyName',
    {
      [OrderDirection.ASC]: [CompanyBusinessUnitsOrderBy.EMAIL_REPLY_TO_ASC],
      [OrderDirection.DESC]: [CompanyBusinessUnitsOrderBy.EMAIL_REPLY_TO_DESC],
    },
  ],
  [
    'emailReplyTo',
    {
      [OrderDirection.ASC]: [CompanyBusinessUnitsOrderBy.EMAIL_REPLY_TO_ASC],
      [OrderDirection.DESC]: [CompanyBusinessUnitsOrderBy.EMAIL_REPLY_TO_DESC],
    },
  ],
  [
    'chartOfAccount',
    {
      [OrderDirection.ASC]: [CompanyBusinessUnitsOrderBy.COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_ASC],
      [OrderDirection.DESC]: [CompanyBusinessUnitsOrderBy.COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_ASC],
    },
  ]
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [CompanyBusinessUnitsOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const onHandleSearch = (
  showMore: boolean,
  businessUnits: CompanyBusinessUnits | undefined,
  sortOrderParam: SortOrder | undefined,
) => {
  const variables: CompanyBusinessUnitsVariables = {
    first: TABLE_ROWS,
    after: showMore
      ? businessUnits?.companyBusinessUnits?.pageInfo.endCursor
      : undefined,
    orderBy: toOrderByVariable(sortOrderParam),
  };
  return {
    queryVariables: variables
  };
};
