import { useLazyQuery } from '@apollo/client';
import {
  Icon,
  Spinner,
  Stack,
  Text,
  TooltipHost,
  makeStyles,
} from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import React from 'react';
import {
  EntityApprovalTopic,
  EntityApprovalTopicVariables,
} from './__generated__/EntityApprovalTopic';

const ENTITY_APPROVAL_TOPIC = loader('./EntityApprovalTopic.graphql');

export const useStyles = makeStyles((theme) => ({
  container: { width: 300 },
  containerMain: { padding: 0, marginLeft: -30 },
  title: { color: theme.palette.white },
  urgentHeader: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '5px 10px',
    backgroundColor: `${theme.palette.redDark}`,
  },
}));

export const RedBoxIndicator: React.FC<{
  _isUrgentApproval: boolean;
  itemId?: string;
}> = ({ _isUrgentApproval, itemId }) => {
  const commonStyles = useCommonStyles();
  const [getTopic, { data, loading }] = useLazyQuery<
    EntityApprovalTopic,
    EntityApprovalTopicVariables
  >(ENTITY_APPROVAL_TOPIC, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
  });

  const styles = useStyles();

  const Content: React.FC = () => {
    return (
      <Stack className={styles.container}>
        <Stack className={styles.urgentHeader}>
          <Text className={styles.title}>Urgent</Text>
        </Stack>
        <Stack tokens={{ padding: 20 }}>
          {loading ? <Spinner /> : <Text>{data?.entityApprovalTopic}</Text>}
        </Stack>
      </Stack>
    );
  };

  return (
    <TooltipHost
      onTooltipToggle={(data) => {
        if (data) getTopic({ variables: { entityId: itemId! } });
      }}
      className={styles.containerMain}
      content={<Content />}
    >
      <Stack tokens={{ padding: '0px 0px 0px 0px' }} verticalAlign="center">
        {!!_isUrgentApproval && (
          <Icon
            iconName="FabricFolderFill"
            className={commonStyles.urgentBox}
          />
        )}
      </Stack>
    </TooltipHost>
  );
};
