import { Image, Text, View } from '@react-pdf/renderer';
import Approved from 'assets/images/Approved.png';
import Declined from 'assets/images/Declined.png';
import SplitObject from 'assets/images/SplitObject.png';
import { dateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { styles } from './index.stylesheet';
import { AccountingEntryInvoice_invoice_approvalHistorySignaturesByEntityId_nodes } from 'ap/signing/accountingEntry/view/__generated__/AccountingEntryInvoice';

type SignatureEntityViewProps = {
  signatureEntity: AccountingEntryInvoice_invoice_approvalHistorySignaturesByEntityId_nodes;
};

export const SignatureEntityView: React.FC<SignatureEntityViewProps> = ({
  signatureEntity,
}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        marginBottom: 10,
        justifyContent: 'space-between',
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        <View style={{ marginRight: 10 }}>
          {signatureEntity.isApproved &&
          !signatureEntity.isRejected &&
          !signatureEntity.isRequested ? (
            <Image src={Approved} style={styles.icon} />
          ) : !signatureEntity.isApproved &&
            signatureEntity.isRejected &&
            !signatureEntity.isRequested ? (
            <Image src={Declined} style={styles.icon} />
          ) : !signatureEntity.isApproved &&
            !signatureEntity.isRejected &&
            signatureEntity.isRequested ? (
            <Image src={SplitObject} style={styles.icon} />
          ) : null}
        </View>
        <View>
          <View>
            <Text style={styles.username}>{signatureEntity.userName!}</Text>
            <Text
              style={styles.occupation}
            >{`${signatureEntity.userOccupationTitle}`}</Text>
          </View>
          <Text style={styles.digitalSign}>
            {signatureEntity.digitalSignature}
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.date}>
          {dateFormat(signatureEntity.actionDate!)}
        </Text>
        {signatureEntity.userSignatureDocument && (
          <View>
            <Image
              style={{
                objectFit: 'contain',
                backgroundColor: 'white',
                width: 80,
                height: 30,
                marginLeft: 5,
              }}
              src={signatureEntity.userSignatureDocument?._downloadLink!}
            />
          </View>
        )}
      </View>
    </View>
  );
};
