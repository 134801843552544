import { DefaultButton, Dialog, DialogFooter, ProgressIndicator, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { AvailableApproverListType, modalProps } from '..';
import { useStyles } from './index.styles'
import clsx from 'clsx';
import { ActiveApproverListItem } from '../..';
import { Header } from './Header';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import { ReplaceApprover, ReplaceApproverVariables } from '../../__generated__/ReplaceApprover';
const REPLACE_APPROVER = loader('../../ReplaceApprover.graphql');
const ACTIVE_APPROVERS = loader('../../ActiveApproverList.graphql');

interface ConfirmDialogProps {
  hidden: boolean;
  selectedUser: ActiveApproverListItem | undefined;
  selectedAvailableUser: AvailableApproverListType | undefined;
  onDismiss: () => void;
  onSubmit: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  hidden,
  selectedUser,
  selectedAvailableUser,
  onDismiss,
  onSubmit,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts()
  const [reason, setReason] = useState<string>("");

  const [
    replaceApprover,
    {
      loading: replaceApproverLoading
    }
  ] = useMutation<ReplaceApprover, ReplaceApproverVariables>(
    REPLACE_APPROVER,
    { errorPolicy: 'all' }
  );

  const disabledConfirmButton = reason.length === 0 || replaceApproverLoading;
  const confirmButtonStyle = clsx(disabledConfirmButton ? styles.acceptButtonDisabled : styles.acceptButton);

  const onDismissDialog = () => {
    setReason("");
    onDismiss();
  }

  const handleSubmit = async () => {
    if (selectedAvailableUser) {
      const { errors } = await replaceApprover({
        variables: {
          input: {
            isBypassOnly: false,
            newUserProfileId: selectedAvailableUser.id,
            originalUserProfileId: selectedUser?.id || "",
            reason: reason
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ACTIVE_APPROVERS,
          },
        ]
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Approver replaced successfully.', {
          appearance: 'success',
        });
        setReason("");
        onSubmit();
      }
    }
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismissDialog}
      minWidth={400}
      modalProps={modalProps}
    >
      <Header
        selectedUsername={selectedUser?.user?.name || ""}
        availableUsername={selectedAvailableUser?.user?.name}
      />
      <TextField
        multiline
        rows={3}
        value={reason}
        placeholder="Please write your reason here."
        resizable={false}
        onChange={(_event, value) => setReason(value || '')}
      />
      <DialogFooter>
        {
          replaceApproverLoading &&
          <ProgressIndicator className={styles.progress} />
        }
        <DefaultButton
          toggle
          primary={true}
          className={confirmButtonStyle}
          selected={true}
          onClick={handleSubmit}
          text="Confirm"
          disabled={disabledConfirmButton}
        />
        <DefaultButton
          onClick={onDismissDialog}
          text="Cancel"
        />
      </DialogFooter>
    </Dialog>
  )
}
