import { makeStyles, Text } from '@fluentui/react';
import React from 'react';

interface ErrorTextProps {
  message: string;
}

export const ErrorText: React.FC<ErrorTextProps> = ({ message }) => {
  const styles = makeStyles((theme) => ({
    errorMessage: { paddingTop: '5px', color: theme.semanticColors.errorText },
  }))();

  return (
    <Text variant="smallPlus" className={styles.errorMessage}>
      {message}
    </Text>
  );
};
