import { IDropdownOption, Pivot, PivotItem, Stack } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react'
import { SupplierValues } from '../types';
import { SupplierCommonData_availableSupplierDocumentPools_nodes, SupplierCommonData_environmentalMetrics_nodes, SupplierCommonData_paymentTermTypes_nodes, SupplierCommonData_vendorServiceTypes_nodes } from '../__generated__/SupplierCommonData';
import { BasicFormFields } from './BasicFormFields';
import { useStyles } from './index.styles';
import { Supplier_supplier } from '../__generated__/Supplier';
enum PivotKeys {
  company = "Company",
  individual = "Individual"
}
interface BasicFormProps {
  supplierAvailableDocumentTypes: SupplierCommonData_availableSupplierDocumentPools_nodes[];
  paymentTermTypes: SupplierCommonData_paymentTermTypes_nodes[];
  environmentalMetrics: SupplierCommonData_environmentalMetrics_nodes[];
  serviceTypes: SupplierCommonData_vendorServiceTypes_nodes[];
  isUpdatable: boolean | null | undefined;
  supplier: Supplier_supplier | null | undefined;
  isNew: boolean;

}
export interface ServiceTypeOptions extends IDropdownOption {
  isRateRequired: boolean | null;
  isServiceRateAllowed: boolean | null;
}
export const BasicForm: React.FC<BasicFormProps> = ({
  supplierAvailableDocumentTypes,
  paymentTermTypes,
  environmentalMetrics,
  serviceTypes,
  isUpdatable,
  supplier,
  isNew
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = useState<string>(PivotKeys.company);
  const { setFieldValue } = useFormikContext<SupplierValues>();
  const isDisabled = isUpdatable === false ? true : false;

  const supplierAvailableDocumentTypesOptions: IDropdownOption[] = supplierAvailableDocumentTypes
    .map((ele) => ({
      key: ele.id,
      text: ele.name!
    })) || [];

  const paymentTermTypesOptions: IDropdownOption[] = paymentTermTypes
    .map((ele) => ({
      key: ele.id,
      text: ele.paymentTermType
    })) || [];

  const environmentalMetricsOptions: IDropdownOption[] = environmentalMetrics
    .map((ele) => ({
      key: ele.id,
      text: ele.environmentalMetric!
    })) || [];

  const serviceTypeOptions: ServiceTypeOptions[] = serviceTypes
    .map((ele) => ({
      key: ele.id,
      text: ele.vendorType!,
      isRateRequired: ele.isRateRequired,
      isServiceRateAllowed: ele.isServiceRateAllowed
    })) || [];

  const onPivotChange = (item: PivotItem | undefined) => {
    setSelectedKey(item?.props.itemKey || PivotKeys.individual);
    if (!isDisabled) {
      if (item?.props.itemKey === PivotKeys.individual) {
        setFieldValue("isIndividual", true);
        setFieldValue("isCorporation", false);
      }
      else {
        setFieldValue("isCorporation", true);
        setFieldValue("isIndividual", false);
      }
    }
  }

  useEffect(() => {
    if (!isNew) {
      if (supplier?.isIndividual)
        setSelectedKey(PivotKeys.individual)
    } else
      setSelectedKey(PivotKeys.company)
  }, [isNew, supplier])

  return (
    <Stack
      className={styles.mainContainer}
    >
      <Pivot
        onLinkClick={onPivotChange}
        selectedKey={selectedKey}
      >
        <PivotItem
          itemKey={PivotKeys.company}
          headerText={PivotKeys.company}
        />
        <PivotItem
          itemKey={PivotKeys.individual}
          headerText={PivotKeys.individual}
        />
      </Pivot>
      <BasicFormFields
        isCompany={selectedKey === PivotKeys.company}
        supplierAvailableDocumentTypesOptions={supplierAvailableDocumentTypesOptions}
        paymentTermTypesOptions={paymentTermTypesOptions}
        environmentalMetricsOptions={environmentalMetricsOptions}
        serviceTypeOptions={serviceTypeOptions}
        supplier={supplier}
        isNew={isNew}
      />
    </Stack>
  )
}
