import {
  makeStyles,
  Stack
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
import Decimal from 'decimal.js';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { ColumnData } from '../columns.data';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '25px !important',
  },
  amountText: {
    textAlign: 'right',
  },
}));

interface DistributionRowTotalProps {
  columnsArray: ColumnData[];
}

export const DistributionRowTotal: React.FC<DistributionRowTotalProps> = ({
  columnsArray,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const watchDistribution = useWatch({ name: 'purchaseOrderItemDistribution' });

  const total = watchDistribution?.reduce((sum: Decimal, current: any) => {
    return new Decimal(sum || 0.0).plus(current.scheduledAmount || '0.0');
  }, new Decimal(0)) as Decimal;

  if (watchDistribution?.length <= 1) return null;
  else
    return (
      <>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
          className={styles.container}
        >
          {columnsArray?.map((ele, index) => {
            switch (ele.key) {
              case 'delete':
                return null;
              case 'scheduledAmount':
                return (
                  <Stack
                    key={index}
                    style={{
                      minWidth: ele.maxWidth,
                    }}
                  >
                    <AmountTextView
                      className={clsx(
                        styles.amountText,
                        commonStyles.semibold,
                        commonStyles.colorThemePrimary
                      )}
                      value={total?.toNumber().toFixed(2)}
                    />
                  </Stack>
                );
              default:
                return (
                  <Stack
                    key={index}
                    style={{
                      minWidth: ele.minWidth,
                    }}
                  />
                );
            }
          })}
        </Stack>
      </>
    );
};
