import { NetworkStatus, useQuery, useReactiveVar } from '@apollo/client';
import {
  IColumn,
  IDetailsRowBaseProps,
  Pivot,
  PivotItem,
  Stack,
  Text,
} from '@fluentui/react';
import { FilterArrayType } from 'common/components/Filters';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { setGlobalSearchText } from 'utility/cache/ui';
import { useStyles } from './index.styles';

import clsx from 'clsx';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import {
  BatchTransactionFilter,
  BatchTransactionsOrderBy,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { BatchSearchBar } from 'postingTracker/batchEdit/batch/list/BatchSearchBar';
import {
  BatchTransactions,
  BatchTransactionsVariables,
  BatchTransactions_batchTransactions_nodes,
} from 'postingTracker/batchEdit/batch/list/__generated__/BatchTransactions';
import {
  transactionHistoryPivotTabs,
  transactionHistoryTabMode,
} from 'postingTracker/transactionHistory';
import { columns } from './column.data';
import { toBatchTransactionsFilterVariable, toOrderByVariable } from './utils';
const TRANSACTION_HISTORY = loader(
  '../../../batchEdit/batch/list/BatchTransactions.graphql'
);
export type BatchListItem = BatchTransactions_batchTransactions_nodes & {
  currencyData: string;
};

export const BatchList = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  let batchListList: BatchListItem[] = [];
  const postTrackTabState = useReactiveVar(transactionHistoryTabMode);
  const currentURL = useLocation();
  const isEditPanelActive = !!matchPath(
    currentURL.pathname,
    '/ap/posting_tracking/posted_transaction/:id'
  );
  const renderRef = useRef(false);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);

  const {
    loading,
    data,
    networkStatus,
    refetch,
    fetchMore,
    variables: BatchListVariables,
  } = useQuery<BatchTransactions, BatchTransactionsVariables>(
    TRANSACTION_HISTORY,
    {
      variables: {
        first: TABLE_ROWS,
        filter: {
          _accountingStampDate: { isNull: false },
        },
        orderBy: [
          BatchTransactionsOrderBy.REFERENCE_NUMBER_ASC,
          BatchTransactionsOrderBy.PRIMARY_KEY_ASC,
        ],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { _batchTransactionCount, _batchTransactionTotal, controlTotalAmount } =
    { ...data?.batchTransactions?.aggregates?.sum };

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const transformedData = refetching
    ? undefined
    : data?.batchTransactions?.nodes.map(
        (batchItem) =>
          ({
            ...batchItem,
            currencyData: batchItem.currency?.isoCode,
            periodYear: batchItem.companyCorporatePeriod?._periodYear,
            status: batchItem.statusType?.statusType,
            postingDate: batchItem.postingDate
              ? dateFormat(dateConvertions(batchItem.postingDate))
              : '',
          } as BatchListItem)
      );
  if (transformedData) batchListList = transformedData;

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({
        ...BatchListVariables,
      }),
    [refetch, BatchListVariables]
  );

  const _renderItemColumn = (
    item: BatchListItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof BatchListItem
      ] as string;
      switch (column?.key) {
        case 'description':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <ActiveLink to={`/postingTracker/history/batch/${item.id}`}>
                {fieldContent}
              </ActiveLink>
            </Stack>
          );

        case 'controlTotalAmount':
        case '_batchTransactionTotal':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <Text className={commonStyles.contentColumnAlignRight}>
                <AmountTextView value={fieldContent} />
              </Text>
            </Stack>
          );
        case '_batchTransactionCount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <Text className={commonStyles.contentColumnAlignRight}>
                {fieldContent}
              </Text>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        setSortOrderParam({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
        await refetch({
          after: null,
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  const handleSearch = (showMore?: boolean) => {
    const variables: BatchTransactionsVariables = {
      ...BatchListVariables,
      first: TABLE_ROWS,
      after: showMore ? data?.batchTransactions?.pageInfo.endCursor : undefined,
      filter: searchFilters?.length
        ? ({
            and: toBatchTransactionsFilterVariable(searchFilters),
            _accountingStampDate: { isNull: false },
          } as BatchTransactionFilter)
        : { _accountingStampDate: { isNull: false } },
      orderBy: toOrderByVariable(sortOrderParam!),
    };

    if (showMore) fetchMore?.({ variables });
    else refetch(variables);
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo();
    else {
      renderRef.current = true;
    }
  }, [sortOrderParam, handleSearchMemo, searchFilters]);

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const {
        _batchTransactionCount,
        _batchTransactionTotal,
        controlTotalAmount,
      } = {
        ...data?.batchTransactions?.aggregates?.sum,
      };
      const fieldContent =
        column?.key === 'controlTotalAmount'
          ? controlTotalAmount
          : column?.key === '_batchTransactionTotal'
          ? _batchTransactionTotal
          : column?.key === '_batchTransactionCount'
          ? _batchTransactionCount
          : '';
      const styleCommon = clsx(
        commonStyles.bold,
        commonStyles.contentColumnAlignRight
      );
      if (column?.key === '_batchTransactionCount') {
        return (
          <Stack
            verticalAlign="center"
            className={styles.statusColumnContainer}
          >
            <Text className={styleCommon}>{fieldContent}</Text>
          </Stack>
        );
      } else
        return (
          <AmountTextView
            variant="medium"
            className={styleCommon}
            value={fieldContent!}
          />
        );
    };

  const showFooter =
    refetching ||
    (!_batchTransactionCount && !_batchTransactionTotal && !controlTotalAmount);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Transaction History</Text>
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal verticalAlign="end">
            <Pivot
              selectedKey={postTrackTabState}
              onLinkClick={(item: PivotItem | undefined) => {
                setGlobalSearchText('');
                if (!isEditPanelActive) {
                  transactionHistoryTabMode(
                    item?.props.itemKey || transactionHistoryPivotTabs.batch
                  );
                }
              }}
            >
              <PivotItem
                itemKey={transactionHistoryPivotTabs.batch}
                headerText="Batches"
              />
              <PivotItem
                itemKey={transactionHistoryPivotTabs.postedTransaction}
                headerText="Posted Transactions"
              />
            </Pivot>
          </Stack>
          <Stack>
            <BatchSearchBar
              transactionHistory
              columns={gridColumns}
              onRefresh={() => reload()}
              onFilterChange={(filters) => {
                setSearchFilters(filters);
              }}
              onToggleVisibility={(columns) => {
                setGridColumns(columns);
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <InfiniteList
        items={batchListList}
        loading={loading}
        hasNextPage={data?.batchTransactions?.pageInfo.hasNextPage}
        showFooter={!showFooter}
        columns={gridColumns.filter((column) => column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={_onColumnClick}
        onLoadMore={() => handleSearch(true)}
        // onSelectionChanged={setSelectedList}
      />
    </>
  );
};
