import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    height: 800,
  },
}));
