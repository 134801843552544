import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
    },
    fullWidthContainer: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    containerpadding: { padding: '0px 20px 20px' },
    startcontainerpadding: { padding: '15px 20px 20px' },

    containerMargin: { padding: 20 },
  };
});
