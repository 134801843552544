import { useApolloClient, useMutation } from '@apollo/client';
import {
  DatePicker,
  DayOfWeek,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DocumentList } from 'common/components/DocumentList';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import {
  ApprovalRequestInput,
  BatchEditApprovalRevokeInput,
  UploadStatusType,
} from 'common/types/globalTypes';
import { EntityType, PanelCommonProps } from 'common/types/utility';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import { setBatchSelected } from 'postingTracker/batchEdit';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { BatchTransactions_batchTransactions } from '../../list/__generated__/BatchTransactions';
import {
  BatchEditApprovalCreate,
  BatchEditApprovalCreateVariables,
} from '../../__generated__/BatchEditApprovalCreate';
import { ActionsMenu } from '../ActionMenu';
import { BatchValues } from '../types';
import { BatchTransaction } from '../__generated__/BatchTransaction';
import {
  BatchTransactionDelete,
  BatchTransactionDeleteVariables,
} from '../__generated__/BatchTransactionDelete';
import { ApprovalHistory } from './ApprovalHistory';
import {
  AttachableBatchDocumentsType,
  AttachBatchDocuments,
} from './AttachBatchDocuments';
import { BasicForm } from './BasicForm';
import { columns } from './coloumn.data';
import { useStyles } from './index.styles';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { Transactions } from './Transactions';
import {
  BatchEditApprovalRevoke,
  BatchEditApprovalRevokeVariables,
} from './__generated__/BatchEditApprovalRevoke';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import { UrgencyFolder } from './UrgencyFolder';
const DELETE_BATCH_TRANSACTION = loader('../BatchTransactionDelete.graphql');
const BATCH_EDIT_APPROVAL_CREATE = loader(
  '../../BatchEditApprovalCreate.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const BATCH_TRANSACTION = loader('../BatchTransaction.graphql');
const BATCH_EDIT_APPROVAL_REVOKE = loader('./BatchEditApprovalRevoke.graphql');
const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this batch for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your batch will be requested for approval.';
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the Batch/edit from the approval cycle and require re-approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';
export interface FormViewProps {
  data: BatchTransaction | undefined;
  onSave?: (createNew: boolean) => void;
  editInProgress?: boolean;
  createInProgress?: boolean;
  dataLoading?: boolean;
  refetchBatch?: () => void;
  onDocumentsAttached?: (
    attachedDocumentsData: AttachableBatchDocumentsType[]
  ) => void;
}
export interface AttachedDocuments {
  attachedDocuments: AttachableBatchDocumentsType[];
}

export const FormView: React.FC<FormViewProps> = ({
  createInProgress,
  editInProgress,
  dataLoading,
  data,
  onSave,
  onDocumentsAttached,
  refetchBatch,
  ...props
}) => {
  const batchData: BatchTransaction | undefined = { ...data! };
  const { dirty, errors, isSubmitting, submitForm, resetForm } =
    useFormikContext<BatchValues>();
  const history = useHistory();
  const { batchId } = useParams<{ batchId: string | undefined }>();
  const isNew = !batchId;
  const panelHeading = isNew ? 'Create Batch' : 'Edit batch';
  const styles = useStyles();
  const { addToast, updateToast } = useToasts();
  const client = useApolloClient();

  const [attachedDocuments, setAttachedDocuments] =
    useState<AttachableBatchDocumentsType[]>();
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [requestComment, setRequestComment] = React.useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);

  const [deleteBatchTransaction] = useMutation<
    BatchTransactionDelete,
    BatchTransactionDeleteVariables
  >(DELETE_BATCH_TRANSACTION, {
    errorPolicy: 'all',
  });

  const [batchEditApprovalCreate, { loading: batchEditApprovalCreateLoading }] =
    useMutation<BatchEditApprovalCreate, BatchEditApprovalCreateVariables>(
      BATCH_EDIT_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );

  const [batchEditApprovalRevoke, { loading: batchEditApprovalRevokeLoading }] =
    useMutation<BatchEditApprovalRevoke, BatchEditApprovalRevokeVariables>(
      BATCH_EDIT_APPROVAL_REVOKE,
      { errorPolicy: 'all' }
    );

  const deleteBatch = async () => {
    const { errors } = await deleteBatchTransaction({
      variables: {
        input: {
          entityDelete: [
            {
              id: batchData?.batchTransaction?.id!,
              rowTimestamp: batchData?.batchTransaction?._rowTimestamp!,
            },
          ],
        },
      },
      update: (cache, { data }) => {
        if (data?.batchTransactionDelete?.deletedEntities?.length) {
          const identity = cache.identify({
            ...batchData?.batchTransaction,
          });
          cache.evict({ id: identity });
          cache.gc();
          cache.modify({
            fields: {
              batchTransactions: (
                existingData: BatchTransactions_batchTransactions
              ) => {
                const filteredNodes = existingData.nodes.filter(
                  (item) => item.id !== batchData?.batchTransaction?.id
                );
                const {
                  controlTotalAmount,
                  _batchTransactionTotal,
                  _batchTransactionCount,
                } = { ...batchData.batchTransaction };
                const { sum } = {
                  ...existingData?.aggregates,
                };

                const controlTotalAmountNew =
                  Number(sum?.controlTotalAmount) - Number(controlTotalAmount);

                const _batchTransactionTotalNew =
                  Number(sum?._batchTransactionTotal) -
                  Number(_batchTransactionTotal);

                const _batchTransactionCountNew =
                  Number(sum?._batchTransactionCount) -
                  Number(_batchTransactionCount);

                return {
                  ...existingData,
                  nodes: filteredNodes,
                  aggregates: {
                    ...existingData?.aggregates,
                    sum: {
                      ...existingData?.aggregates?.sum,
                      controlTotalAmount: controlTotalAmountNew.toString(),
                      _batchTransactionTotal:
                        _batchTransactionTotalNew.toString(),
                      _batchTransactionCount:
                        _batchTransactionCountNew.toString(),
                    },
                  },
                };
              },
            },
          });
        }
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/postingTracker/batches');
    }
  };

  const panelStatus: string = isNew
    ? '(Draft)'
    : dataLoading
      ? ''
      : `(${data?.batchTransaction?.statusType?.statusType!})`;

  const isLoading =
    createInProgress ||
    editInProgress ||
    batchEditApprovalCreateLoading ||
    batchEditApprovalRevokeLoading;

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        title={panelHeading}
        onClose={() => {
          history.replace('/postingTracker/batches');
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            <UnsavedIndicator
              visible={!isNew && !dataLoading && dirty && !isSubmitting}
            />
          </Stack>

          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            {data?.batchTransaction?.statusType?.statusType === 'Pending' && (
              <UrgencyFolder batchTransactionData={data} />
            )}
            <Text className={styles.status}>{panelStatus}</Text>
            <ActionMessageModal
              visible={!isNew}
              entityType={EntityType.Batch}
              disabled={!data?.batchTransaction?._isDeletable}
              onConfirm={deleteBatch}
            />
          </Stack>
        </Stack>
      </PanelHeader>
    );
  };

  const _onRenderFooter = () => {
    return (
      <FooterActionBar
        onCancel={() => history.replace('/postingTracker/batches')}
        isCreate={isNew}
        createNewText={'New Batch'}
        showButtons={{
          showCreateNewButton: isNew,
          showOnSaveButton: !isNew,
        }}
        addNewForm={() => {
          history.replace('/postingTracker/batches/batch');
        }}
        disabled={
          createInProgress || {
            save: !dirty || Object.keys(errors).length > 0,
            cancel: !dirty,
          }
        }
        isSubmitting={isSubmitting}
        onSave={async () => {
          onSave?.(false);
          await submitForm();
        }}
        onSaveAnother={async () => {
          onSave?.(true);
          await submitForm();
          resetForm();
        }}
        isLoading={isLoading}
        children={
          batchData && (
            <Stack>
              {!isNew &&
                batchData?.batchTransaction?._isStagedApprovalRequest && (
                  <PrimaryButton
                    disabled={dirty}
                    className={styles.disabledButton}
                    text="Request Approval"
                    onClick={() => toggleConfirmDialog()}
                  />
                )}
              {!isNew && batchData.batchTransaction?._isApprovalRevocable && (
                <PrimaryButton
                  disabled={dirty}
                  className={styles.disabledButton}
                  text="Amend Batch"
                  onClick={() => toggleConfirmAmendDialog()}
                />
              )}
            </Stack>
          )
        }
      ></FooterActionBar>
    );
  };

  const removeDocument = (documentID: string) => {
    const filteredArray = attachedDocuments?.filter(
      (item) => item.id !== documentID
    );
    setAttachedDocuments(filteredArray || []);
    onDocumentsAttached?.(filteredArray || []);
  };

  return (
    <DraggablePanel
      {...PanelCommonProps}
      isBlocking={isNew === true ? true : false}
      initialWidth={900}
      minWidth={900}
      isOpen
      onRenderHeader={_onRenderHeader}
      onRenderFooter={_onRenderFooter}
      onDismiss={() => {
        setBatchSelected(undefined);
        history.replace('/postingTracker/batches');
      }}
      isLightDismiss
    >
      {dataLoading ? (
        <ShimmerView />
      ) : (
        <>
          {!isNew && (
            <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
              <Stack tokens={{ padding: '5px 20px' }}>
                {batchData?.batchTransaction?._isApprovalDocumentsRequired &&
                  batchData?.batchTransaction?._requiredApprovalDocuments && (
                    <MessageBar messageBarType={MessageBarType.error}>
                      {batchData?.batchTransaction?._requiredApprovalDocuments}
                    </MessageBar>
                  )}
              </Stack>
              {batchData.batchTransaction && (
                <ActionsMenu
                  onRemove={refetchBatch}
                  batchTransaction={batchData.batchTransaction}
                  onUpload={async (fileSelected, document, toastId) => {
                    const observer = client.subscribe({
                      query: DOCUMENT_UPLOAD_STATUS,
                      variables: {
                        documentId: document.document._documentFileId!,
                      },
                    });
                    const subscription = observer.subscribe((response) => {
                      const subscribedData =
                        response.data as OnDocumentUploadStatus;

                      const { status, document } = {
                        ...subscribedData.documentUploadStatus,
                      };

                      if (!document) {
                        if (status.type === UploadStatusType.VALIDATING) {
                          updateToast(toastId!, {
                            content: status.message
                              ? `Validating files ${fileSelected.name} - ${status.message}`
                              : `Validating files ${fileSelected.name}`,
                            appearance: 'info',
                            autoDismiss: false,
                          });
                        } else if (
                          status.type === UploadStatusType.EXTRACTING
                        ) {
                          updateToast(toastId!, {
                            content: status.message
                              ? `Extracting data from ${fileSelected.name} - ${status.message}`
                              : `Extracting data from ${fileSelected.name}`,
                            appearance: 'info',
                            autoDismiss: false,
                          });
                        } else if (status.type === UploadStatusType.FAILURE) {
                          subscription.unsubscribe();
                          updateToast(toastId!, {
                            content: status.message
                              ? `Upload of ${fileSelected.name} failed - ${status.message}`
                              : `Upload of ${fileSelected.name} failed`,
                            appearance: 'error',
                            autoDismiss: true,
                          });
                        } else if (status.type === UploadStatusType.WARNING) {
                          subscription.unsubscribe();
                          updateToast(toastId!, {
                            content: status.message
                              ? `Warning for file ${fileSelected.name}: ${status.message}`
                              : `Warning for file ${fileSelected.name}`,
                            appearance: 'warning',
                            autoDismiss: true,
                          });
                        }
                      } else {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                            : `Successfully uploaded ${fileSelected.name}`,
                          appearance: 'success',
                          autoDismiss: true,
                        });
                        refetchBatch?.();
                      }
                    });
                  }}
                />
              )}
              <Separator />
            </Stack>
          )}

          {isNew && (
            <AttachBatchDocuments
              onDocumentsAttached={(documentsData) => {
                if (attachedDocuments && attachedDocuments?.length > 0) {
                  setAttachedDocuments([
                    ...attachedDocuments,
                    ...documentsData,
                  ]);
                  onDocumentsAttached?.([
                    ...attachedDocuments,
                    ...documentsData,
                  ]);
                } else {
                  setAttachedDocuments(documentsData);
                  onDocumentsAttached?.(documentsData);
                }
              }}
              prevSelectedDocuments={attachedDocuments}
            />
          )}

          <BasicForm data={data} />

          {isNew && attachedDocuments?.length && (
            <Stack className={styles.fileListContainer}>
              <DocumentList
                isNew={isNew}
                columns={columns}
                data={attachedDocuments || []}
                isDeleteButtonVisible
                onRemoveRow={(data) => {
                  removeDocument(data.id);
                }}
              />
            </Stack>
          )}

          {!isNew && !!data?.batchTransaction?.invoiceBatches?.nodes && (
            <Transactions {...props} data={data} />
          )}

          {data?.batchTransaction?.approvalHistoriesByEntityId &&
            data?.batchTransaction?.approvalHistoriesByEntityId.nodes.length! >
            0 && (
              <Stack>
                <Stack
                  className={styles.approvalHistoryContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Text variant="xLarge">Approval History</Text>
                  <Text variant="medium" className={styles.requestedByText}>
                    Requested By:
                  </Text>
                  <ApprovalHistory
                    data={data.batchTransaction.approvalHistoriesByEntityId}
                  />
                </Stack>
              </Stack>
            )}
          <ConfirmDialog
            hidden={hideConfirmRequestDialog}
            title={CONFIRM_REQUEST_DIALOG_TITLE}
            subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
            onDismiss={toggleConfirmDialog}
            minWidth={500}
            onConfirm={async () => {
              toggleConfirmDialog();
              const inputVariables: ApprovalRequestInput = {
                entityId: batchId!,
                rowTimestamp: data?.batchTransaction?._rowTimestamp!,
                comments: requestComment,
              };
              if (requiredDate) inputVariables.requiredDate = requiredDate;
              const { errors } = await batchEditApprovalCreate({
                variables: {
                  input: {
                    entityApproval: [inputVariables],
                    urgencyLevel: urgencyLevel,
                  },
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: BATCH_TRANSACTION,
                    variables: {
                      id: batchId!,
                    },
                  },
                ],
              });

              if (errors?.length)
                addToast(errors[0].message, {
                  appearance: 'error',
                });
              else {
                setRequestComment('');
                setRequiredDate('');
                addToast('Request sent for approval', {
                  appearance: 'success',
                });
              }
            }}
          >
            <Stack tokens={{ childrenGap: 10 }}>
              <TextField
                className={styles.marginT10}
                multiline
                rows={3}
                value={requestComment}
                placeholder="Please write your comment here (optional)"
                resizable={false}
                onChange={(_event, value) => setRequestComment(value || '')}
              />
              <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                <DatePicker
                  minDate={new Date()}
                  firstDayOfWeek={DayOfWeek.Sunday}
                  placeholder="Required date (optional)"
                  ariaLabel="Date"
                  formatDate={onFormatDate}
                  showWeekNumbers
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday={false}
                  onSelectDate={(date) =>
                    setRequiredDate(dateFormat(date!.toString()))
                  }
                />
              </TooltipHost>

              <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                <UrgencyToggle
                  onToggle={(data) => {
                    setUrgencyLevel(data ? 0 : 1);
                  }}
                />
              </Stack>
            </Stack>
          </ConfirmDialog>
          <ConfirmDialog
            isAmendButton
            hidden={hideConfirmAmendDialog}
            title={CONFIRM_AMEND_DIALOG_TITLE}
            subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
            onDismiss={toggleConfirmAmendDialog}
            onConfirm={async () => {
              toggleConfirmAmendDialog();
              const inputVariables: BatchEditApprovalRevokeInput = {
                entityId: batchId!,
                rowTimestamp: data?.batchTransaction?._rowTimestamp!,
              };
              const { errors } = await batchEditApprovalRevoke({
                variables: {
                  input: inputVariables,
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: BATCH_TRANSACTION,
                    variables: {
                      id: batchId!,
                    },
                  },
                ],
              });

              if (errors?.length)
                addToast(errors[0].message, {
                  appearance: 'error',
                });
              else
                addToast('Approval amended successfully', {
                  appearance: 'success',
                });
            }}
          />
        </>
      )}
    </DraggablePanel>
  );
};
