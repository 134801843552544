import { DayOfWeek, IDropdownOption, Stack } from '@fluentui/react';
import { FormikDropdown, AmountTextField, FormikCheckBox } from 'common/components';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { CompanyPeriodFrequencies_companyPeriodFrequencies_nodes } from '../../__generated__/CompanyPeriodFrequencies';
import { PeriodRangeValues } from '../types';
import { useStyles } from './index.styles';
interface CorporateRangeFormProps {
  fiscalYearId: string;
  companyPeriodFrequencies: CompanyPeriodFrequencies_companyPeriodFrequencies_nodes[]
}
export const CorporateRangeForm: React.FC<CorporateRangeFormProps> = ({
  fiscalYearId,
  companyPeriodFrequencies
}) => {
  const styles = useStyles();
  const { setFieldValue } = useFormikContext<PeriodRangeValues>()
  const companyPeriodFrequenciesOptions: IDropdownOption[] = companyPeriodFrequencies.map(frequency => ({
    key: frequency.id,
    text: frequency.frequencyName || ""
  }));

  useEffect(() => {
    if (fiscalYearId)
      setFieldValue("companyCorporateYearId", fiscalYearId)
  }, [fiscalYearId, setFieldValue])

  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20
        }}
        className={styles.formRow}
      >

        <Stack.Item
          className={styles.fieldContainer}
        >
          <FormikDatePicker
            name={"startDate"}
            placeholder="Select date"
            label="Start Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            showWeekNumbers
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            isRequired
          />
        </Stack.Item>
        <Stack.Item
          className={styles.fieldContainer}
        >
          <FormikDropdown
            label="Frequency"
            placeholder="Select"
            options={companyPeriodFrequenciesOptions}
            name="frequencyId"
            required
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20
        }}
        className={styles.formRow}
      >
        <Stack.Item
          className={styles.fieldContainer}
        >
          <AmountTextField
            name={"intervals"}
            label="Intervals"
            format={"###"}
            decimalScale={0}
            allowNegative={false}
            required
          />
        </Stack.Item>
        <Stack.Item
          className={styles.fieldContainer}
        >
          <AmountTextField
            name={"startPeriodNumber"}
            label="Start Period Number"
            format={"###"}
            decimalScale={0}
            allowNegative={false}
            required
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20
        }}
        className={styles.checkboxWrapper}
      >
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 20 }}
          className={styles.width50}
        >
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 7 }}>
            <FormikCheckBox
              label="Period Open"
              name={"isPeriodOpen"}
            />
          </Stack>
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 7 }}>
            <FormikCheckBox
              label="Entry Allowed"
              name={"isEntryAllowed"}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack >
  )
}
