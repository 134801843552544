import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isSortedDescending: false,
  isCollapsible: true,
  toggleVisibility: true,
  isVisible: true,
};
export const columns: ColumnData[] = [
  {
    key: 'expand',
    name: '',
    fieldName: 'expand',
    minWidth: 20,
    maxWidth: 20,
    ...commonColumnProps,
  },
  {
    key: 'transactionType',
    name: 'Type',
    fieldName: 'transactionType',
    minWidth: 60,
    maxWidth: 80,
    ...commonColumnProps,
  },
  {
    key: 'accountingStampTransactionReference',
    name: 'Transaction No.',
    fieldName: 'accountingStampTransactionReference',
    minWidth: 100,
    maxWidth: 120,
    ...commonColumnProps,
    isSorted: true,
  },
  {
    key: 'accountingStampDate',
    name: 'Stamped',
    fieldName: 'accountingStampDate',
    minWidth: 70,
    maxWidth: 70,
    ...commonColumnProps,
  },
  {
    key: 'vendorReference',
    name: 'Name',
    fieldName: 'vendorReference',
    minWidth: 140,
    maxWidth: 160,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 160,
    maxWidth: 180,
    ...commonColumnProps,
  },

  {
    key: 'invoiceNumber',
    name: 'Ref. Number',
    fieldName: 'invoiceNumber',
    minWidth: 80,
    maxWidth: 80,
    ...commonColumnProps,
  },
  {
    key: 'invoiceDate',
    name: 'Date',
    fieldName: 'invoiceDate',
    minWidth: 70,
    maxWidth: 70,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: 'controlTotalAmount',
    name: 'Amount',
    fieldName: 'controlTotalAmount',
    minWidth: 84,
    maxWidth: 84,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'openingBalance',
    name: 'Opening',
    fieldName: 'openingBalance',
    minWidth: 84,
    maxWidth: 84,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'appliedAmount',
    name: 'Applied',
    fieldName: 'appliedAmount',
    minWidth: 84,
    maxWidth: 84,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'retiredAmount',
    name: 'Retired',
    fieldName: 'retiredAmount',
    minWidth: 84,
    maxWidth: 84,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'overageAmount',
    name: 'Overage',
    fieldName: 'overageAmount',
    minWidth: 84,
    maxWidth: 84,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'remainingBalance',
    name: 'Remaining',
    fieldName: 'remainingBalance',
    minWidth: 84,
    maxWidth: 84,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
];
