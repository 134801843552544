import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isSortedDescending: false,
  isCollapsible: true,
  toggleVisibility: true,
  isVisible: true,
};
export const columns: ColumnData[] = [
  {
    key: 'transactionType',
    name: 'Type',
    fieldName: 'transactionType',
    minWidth: 120,
    maxWidth: 130,
    ...commonColumnProps,
  },
  {
    key: 'accountStampReference',
    name: 'Transaction No.',
    fieldName: 'accountStampReference',
    minWidth: 140,
    maxWidth: 140,
    ...commonColumnProps,
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 100,
    maxWidth: 200,
    ...commonColumnProps,
    isSorted: true,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 220,
    maxWidth: 220,
    ...commonColumnProps,
  },

  {
    key: 'invoiceNumber',
    name: 'Ref. Number',
    fieldName: 'invoiceNumber',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'date',
    name: 'Date',
    fieldName: 'date',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },

  {
    key: 'amount',
    name: 'Amount',
    fieldName: 'amount',
    minWidth: 100,
    maxWidth: 150,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
];
