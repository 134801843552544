import { IDropdownOption, IStackStyles, TooltipHost } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import { FormHookTextField } from 'common/components/FormHooksFields/FormHookTextField';
import { ColumnData } from 'common/components/SearchBar';
import React, { useEffect, useState } from 'react';
import { PurchaseOrderItem_purchaseOrderItem } from 'purchaseOrder/view/FormView/POItems/__generated__/PurchaseOrderItem';
import {
  AutoCompleteTextField,
  LookUpProps,
} from 'common/components/FormHooksFields/AutoCompleteTextField';

import {
  ItemDistributionValues,
  PurchaseOrderItemFormProps,
} from '../../interface';
import { PurchaseOrderDataType } from 'purchaseOrder/view/FormView/POItems';
import { useFormContext } from 'react-hook-form';
import { AutoCompleteFFn } from 'common/components/AutoCompleteFFn/FFn';

const lookUpCalloutStyles: Partial<IStackStyles> = {
  root: {
    maxHeight: 400,
    padding: 10,
  },
};

export interface FormFieldProps {
  baseField: string;
  columnData: ColumnData;
  index: number;
  item: ItemDistributionValues | undefined;
  _isUpdatable?: boolean | null | undefined;
  _isDistributionUpdateAllowed?: boolean | null | undefined;
  rowLength: number;
  purchaseOrderItem: PurchaseOrderItem_purchaseOrderItem | null | undefined;
  dropdownValues: {
    productionIncentivesOptions: IDropdownOption[];
    tax1099T4Options: IDropdownOption[];
  };
  refCodesOptions: {
    refCode1Options: IDropdownOption[];
    refCode2Options: IDropdownOption[];
    refCode3Options: IDropdownOption[];
    refCode4Options: IDropdownOption[];
  };
  purchaseOrder: PurchaseOrderDataType | undefined;

  onSelectAccount: (lookupData: LookUpProps) => void;
  onSetAllocationDefault?: () => void;
  businessUnitId: string | null;
}

export const FormField: React.FC<FormFieldProps> = ({
  baseField,
  columnData,
  dropdownValues,
  index,
  onSelectAccount,
  refCodesOptions,
  _isDistributionUpdateAllowed,
  _isUpdatable,
  item,
  rowLength,
  onSetAllocationDefault,
  businessUnitId,
}) => {
  const { watch } = useFormContext<PurchaseOrderItemFormProps>();
  const watchInvoiceDistributions = watch('purchaseOrderItemDistribution');
  const [referenceCode, setReferenceCode] = useState<string | null>();

  const isRowUpdatable = _isUpdatable ? true : _isDistributionUpdateAllowed;
  const isAllocationEntered = item ? !!item.allocationPercent : false;
  const isAmountEntered = item ? !!item.distributionAmount : false;

  useEffect(() => {
    if (
      item?.allocationPercent === '100' &&
      item.distributionAmount !== null &&
      index === 0
    ) {
      onSetAllocationDefault?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.distributionAmount]);

  switch (columnData.key) {
    case 'delete':
      return null;
    case 'accountReference':
      return (
        <TooltipHost content={item?._accountName ?? ''}>
          <AutoCompleteTextField
            businessUnitId={businessUnitId}
            id={`accountReference${columnData.key}${index}`}
            name={`${baseField}.${columnData.key}`}
            width={columnData.maxWidth!}
            lookUpCalloutWidth={500}
            onVendorSelect={onSelectAccount}
            underlined
            disabled={!isRowUpdatable}
            lookupCalloutStyles={lookUpCalloutStyles}
          />
        </TooltipHost>
      );
    case 'projectReference':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          max={4}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'setReference':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          max={4}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode1':
      return (
        <AutoCompleteFFn
          index={index}
          name={`${baseField}.${columnData.key}`}
          columnData={columnData}
          FFn={1}
          ffValue={watchInvoiceDistributions?.[index].referenceCode1 ?? ''}
          referenceCode={referenceCode}
          setReferenceCode={setReferenceCode}
          disabled={!isRowUpdatable}
        />
      );
    case 'referenceCode2':
      return (
        <AutoCompleteFFn
          index={index}
          name={`${baseField}.${columnData.key}`}
          columnData={columnData}
          FFn={2}
          ffValue={watchInvoiceDistributions?.[index]?.referenceCode2}
          referenceCode={referenceCode}
          setReferenceCode={setReferenceCode}
          disabled={!isRowUpdatable}
        />
      );
    case 'referenceCode3':
      return (
        <AutoCompleteFFn
          index={index}
          name={`${baseField}.${columnData.key}`}
          columnData={columnData}
          FFn={3}
          ffValue={watchInvoiceDistributions?.[index].referenceCode3 ?? ''}
          referenceCode={referenceCode}
          setReferenceCode={setReferenceCode}
          disabled={!isRowUpdatable}
        />
      );
    case 'referenceCode4':
      return (
        <AutoCompleteFFn
          index={index}
          name={`${baseField}.${columnData.key}`}
          columnData={columnData}
          FFn={4}
          ffValue={watchInvoiceDistributions?.[index].referenceCode4 ?? ''}
          referenceCode={referenceCode}
          setReferenceCode={setReferenceCode}
          disabled={!isRowUpdatable}
        />
      );
    case 'referenceCode5':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          maxLength={3}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode6':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          maxLength={3}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode7':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'productionIncentiveId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={dropdownValues.productionIncentivesOptions}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 200 }}
        />
      );
    case 'tax1099T4TypeId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={dropdownValues.tax1099T4Options}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 200 }}
        />
      );

    case 'distributionAmount':
      return (
        <FormHookAmount
          name={`${baseField}.${columnData.key}`}
          style={{ width: columnData.maxWidth, textAlign: 'right' }}
          fixedDecimalScale
          decimalScale={2}
          maxLength={15}
          allowNegative
          underlined
          disabled={
            !isRowUpdatable
              ? true
              : index === 0 &&
                item?.allocationPercent === '100' &&
                rowLength === 1
              ? false
              : isAllocationEntered && !_isDistributionUpdateAllowed
            // ? isAllocationEntered || !!_isDistributionUpdateAllowed
            // : true
          }
        />
      );
    case 'allocationPercent':
      return (
        <FormHookAmount
          name={`${baseField}.${columnData.key}`}
          style={{ width: columnData.maxWidth, textAlign: 'right' }}
          maxLength={3}
          underlined
          allowNegative={false}
          disabled={
            !_isUpdatable
              ? true
              : isAmountEntered && !_isDistributionUpdateAllowed
            // (!!_isDistributionUpdateAllowed && !_isUpdatable) || isAmountEntered
          }
        />
      );
    default:
      return null;
  }
};
