import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  disableButton: {
    backgroundColor: theme.palette.neutralLight,
  },
  primaryButton: {
    backgroundColor: theme.palette.accent,
  },
  iconButton: {
    color: 'white',
    ':hover': {
      color: 'white',
      backgroundColor: theme.palette.themeDarker,
    },
  },
  chipText: {
    color: 'white',
    fontWeight: FontWeights.semibold,
  },
}));
