import { useMutation } from '@apollo/client';
import {
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Stack,
  Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import DraggablePanel from 'common/components/DraggablePanel';
import { PanelHeader } from 'common/components/PanelHeader';
import { useCommonStyles } from 'common/styles';
import { BatchEditApprovalRevokeInput } from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import {
  BatchEditApprovalRevoke,
  BatchEditApprovalRevokeVariables
} from 'postingTracker/batchEdit/batch/view/Form/__generated__/BatchEditApprovalRevoke';
import { BatchTransaction } from 'postingTracker/batchEdit/batch/view/__generated__/BatchTransaction';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Actions } from './Actions';
import { ApprovalHistory } from './ApprovalHistory';
import { BasicForm } from './BasicForm';
import { useStyles } from './index.styles';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { SignatureView } from './Signatures';
import { Transactions } from './Transactions';

const BATCH_EDIT_APPROVAL_REVOKE = loader(
  '../../../../batchEdit/batch/view/Form/BatchEditApprovalRevoke.graphql'
);
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the Transaction history from the approval cycle and require re-approval.';

export interface FormViewProps {
  batchTransactionData: BatchTransaction | undefined;
  dataLoading?: boolean;
}
export const FormView: React.FC<FormViewProps> = ({
  dataLoading,
  batchTransactionData,
  ...props
}) => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const history = useHistory();
  const commonStyles = useCommonStyles();
  const [visibility, { toggle: toggleVisibility }] = useBoolean(true);
  const panelStatus: string = `(${batchTransactionData?.batchTransaction
    ?.statusType?.statusType!})`;

  const [batchRevoke, { loading }] = useMutation<
    BatchEditApprovalRevoke,
    BatchEditApprovalRevokeVariables
  >(BATCH_EDIT_APPROVAL_REVOKE, { errorPolicy: 'all' });

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        title="Transaction History"
        onClose={() => {
          history.replace('/postingTracker/history');
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">Transaction History</Text>
          </Stack>
          <Text className={commonStyles.colorThemePrimary} variant="xLarge">
            {panelStatus}
          </Text>
        </Stack>
      </PanelHeader>
    );
  };

  const _onRenderFooter = () => {
    return (
      <Stack className={styles.footerStyle}>
        {loading && <ProgressIndicator />}
        <Stack
          horizontal
          tokens={{ childrenGap: 10, padding: '25px 25px 25px 25px' }}
        >
          <PrimaryButton
            text="Amend"
            onClick={toggleVisibility}
            disabled={
              !batchTransactionData?.batchTransaction?._isApprovalRevocable!
            }
          />
        </Stack>
      </Stack>
    );
  };
  return (
    <DraggablePanel
      {...PanelCommonProps}
      isBlocking={false}
      initialWidth={900}
      minWidth={900}
      isOpen
      onRenderHeader={_onRenderHeader}
      onRenderFooter={_onRenderFooter}
      onDismiss={() => {
        history.replace('/postingTracker/history');
      }}
      isLightDismiss
    >
      {dataLoading ? (
        <ShimmerView />
      ) : (
        <>
          <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
            {batchTransactionData?.batchTransaction?.id && (
              <Actions batchTransaction={batchTransactionData.batchTransaction} />
            )}
            <Separator />
          </Stack>

          <BasicForm data={batchTransactionData} />

          <Transactions
            {...props}
            batchTransactionData={batchTransactionData}
          />
          {batchTransactionData?.batchTransaction
            ?.approvalHistorySignaturesByEntityId?.nodes &&
            batchTransactionData?.batchTransaction
              ?.approvalHistorySignaturesByEntityId?.nodes?.length > 0 && (
              <Stack
                className={styles.tagsContainer}
                tokens={{ childrenGap: 20 }}
              >
                <Text variant="xLarge">Signatures</Text>
                <SignatureView
                  signatureData={
                    batchTransactionData?.batchTransaction
                      ?.approvalHistorySignaturesByEntityId?.nodes
                  }
                />
              </Stack>
            )}
          {batchTransactionData?.batchTransaction
            ?.approvalHistoriesByEntityId &&
            batchTransactionData?.batchTransaction?.approvalHistoriesByEntityId
              ?.nodes?.length! > 0 && (
              <Stack>
                <Separator />
                <Stack
                  className={styles.approvalHistoryContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Text variant="xLarge">Approval History</Text>
                  <Text variant="medium" className={styles.requestedByText}>
                    Requested By:
                  </Text>
                  <ApprovalHistory
                    data={
                      batchTransactionData?.batchTransaction
                        ?.approvalHistoriesByEntityId
                    }
                  />
                </Stack>
              </Stack>
            )}
        </>
      )}

      <ConfirmDialog
        isAmendButton
        onDismiss={toggleVisibility}
        hidden={visibility}
        title={CONFIRM_AMEND_DIALOG_TITLE}
        subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
        onConfirm={async () => {
          toggleVisibility();
          const inputVariables: BatchEditApprovalRevokeInput = {
            entityId: batchTransactionData?.batchTransaction?.id!,
            rowTimestamp:
              batchTransactionData?.batchTransaction?._rowTimestamp!,
          };
          const { errors } = await batchRevoke({
            variables: {
              input: inputVariables,
            },
            update(cache, { data }) {
              if (data?.batchEditApprovalRevoke?.revokedApprovalEntity) {
                const identity = cache.identify({
                  ...batchTransactionData?.batchTransaction,
                });
                cache.evict({ id: identity });
                cache.gc();
              }
            },
          });
          if (errors?.length)
            addToast(errors[0].message, {
              appearance: 'error',
            });
          else {
            history.replace('/postingTracker/history');
            addToast('Approval amended successfully', {
              appearance: 'success',
            });
          }
        }}
      />
    </DraggablePanel>
  );
};
