import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  fixedWidth: {
    width: 250,
  },
  red: {
    borderColor: theme.palette.red,
    color: theme.palette.red,
  },
  grey: {
    borderColor: theme.palette.neutralSecondaryAlt,
    color: theme.palette.neutralSecondaryAlt,
  },
  border: {
    borderStyle: 'double',
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  noBottomBorder: {
    borderBottom: 'none',
    // offset to prevent StamperView container shifting because bottom border adds height
    paddingBottom: 3,
  },
  noTopBorder: {
    borderTop: 'none',
  },
  separatorBorder: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
  },
  noBoxShadow: {
    boxShadow: 'none',
  },
  value: {
    fontWeight: FontWeights.semibold,
    color: theme.palette.neutralPrimaryAlt,
  },
}));
