import { FontIcon, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';

interface PivotDisplayProps {
  name: string;
  iconName: string;
  number?: number;
  onPivotClick: () => void;
}
export const PivotDisplay: React.FC<PivotDisplayProps> = ({
  name = 'Attach',
  iconName = 'Attach',
  number = 0,
  onPivotClick = () => { },
}) => {
  const styles = useStyles();
  return (
    <Stack onClick={onPivotClick} className={styles.container}>
      <Stack horizontal verticalAlign="center">
        <FontIcon iconName={iconName} />
        <Stack className={styles.width5} />
        <Text>{name}</Text>
        {number !== 0 && (
          <Stack horizontal>
            <div className={styles.width10} />
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              className={styles.pivotDisplayCount}
            >
              <Text className={styles.countColor} variant="mediumPlus">
                {number}
              </Text>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
