import { IDropdownOption, Stack } from '@fluentui/react';
import {
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { UserTemplateCommonData } from './__generated__/UserTemplateCommonData';

const COMMON_DATA = loader('./UserTemplateCommonData.graphql');
interface BasicFormProps {
  isUpdatable?: boolean;
}
export const BasicForm: React.FC<BasicFormProps> = ({ isUpdatable }) => {
  const { data: commonData } = useQuery<UserTemplateCommonData>(COMMON_DATA);
  const roleOptions: IDropdownOption[] =
    commonData?.roles?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.name,
    })) || [];
  const occupationOptions: IDropdownOption[] =
    commonData?.userOccupationTitles?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.userOccupationTitle,
    })) || [];
  const departmentOptions: IDropdownOption[] =
    commonData?.companyDepartments?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.name || '',
    })) || [];
  const departmentGroupOptions: IDropdownOption[] =
    commonData?.departmentGroups?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.name || '',
    })) || [];
  const accessPolicyOptions: IDropdownOption[] =
    commonData?.dataAccessPolicies?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.name || '',
    })) || [];
  const userGroupOptions: IDropdownOption[] =
    commonData?.userGroups?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.name || '',
    })) || [];
  const userGroupAccessOptions: IDropdownOption[] =
    commonData?.accessGroups?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.name || '',
    })) || [];
  const tagGroupOptions: IDropdownOption[] =
    commonData?.tagGroups?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.name || '',
    })) || [];
  const communicationGroupOptions: IDropdownOption[] =
    commonData?.communicationGroups?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.name || '',
    })) || [];
  const rankOptions: IDropdownOption[] =
    commonData?.userRankTypes?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.userRank || '',
    })) || [];
  const securityLevelOptions: IDropdownOption[] =
    commonData?.secureRowLevels?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.name || '',
    })) || [];

  return (
    <Stack
      tokens={{
        padding: 30,
        childrenGap: 10,
      }}
    >
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="name"
            label="Name"
            required
            disabled={!isUpdatable}
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="description"
            label="Description"
            disabled={!isUpdatable}
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Role"
            name="roleId"
            options={roleOptions}
            disabled={!isUpdatable}
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Occupation"
            name="userOccupationTitleId"
            options={occupationOptions}
            disabled={!isUpdatable}
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Department"
            name="departmentId"
            options={departmentOptions}
            disabled={!isUpdatable}
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Department Access"
            name="departmentGroupId"
            options={departmentGroupOptions}
            disabled={!isUpdatable}
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Access Policy"
            name="dataAccessPolicyId"
            options={accessPolicyOptions}
            disabled={!isUpdatable}
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="User Group"
            name="userGroupId"
            options={userGroupOptions}
            disabled={!isUpdatable}
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="User Group Access"
            name="accessGroupId"
            options={userGroupAccessOptions}
            disabled={!isUpdatable}
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Tagging Group"
            name="tagGroupId"
            options={tagGroupOptions}
            disabled={!isUpdatable}
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Communication Group"
            name="communicationGroupId"
            options={communicationGroupOptions}
            disabled={!isUpdatable}
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Rank"
            name="userRankTypeId"
            options={rankOptions}
            disabled={!isUpdatable}
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 0.5 }}>
          <FormHookDropdown
            label="Security Level"
            name="secureRowLevelId"
            options={securityLevelOptions}
            disabled={!isUpdatable}
          />
        </Stack>
        <Stack
          horizontal
          style={{ flex: 0.5 }}
          tokens={{
            childrenGap: 40,
          }}
        >
          <FormHookTextField
            name="userDefaultCompany"
            label="Default Company"
            disabled={!isUpdatable}
          />
          <FormHookTextField
            name="userDefaultLocation"
            label="Default Location"
            disabled={!isUpdatable}
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 30,
          padding: '20px 0px 0px 0px',
        }}
      >
        <FormHookCheckBox
          name="isUserPrimaryCurrencyDefault"
          label="Add Default Currency"
          disabled={!isUpdatable}
        />

        <FormHookCheckBox
          name="isUserDepartmentDefault"
          label="Add Default Department"
          disabled={!isUpdatable}
        />
        <FormHookCheckBox
          name="isUserPrimaryBusinessUnitDefault"
          label="Add Default Business Unit"
          disabled={!isUpdatable}
        />

        <FormHookCheckBox
          name="isTemplateControlled"
          label="Template Controlled"
          disabled={!isUpdatable}
        />
        <FormHookCheckBox
          name="isDepartmentUserGroupRequired"
          label="User Group Required"
          disabled={!isUpdatable}
        />
      </Stack>
    </Stack>
  );
};
