import {
  CommandBarButton,
  DefaultButton,
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { ADDRESS_INITIAL_VALUES } from '../constants';
import { BusinessUnitValues } from '../types';
import { FormFields } from './FormFields';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { BusinessUnitCommonData_countries } from '../__generated__/BusinessUnitCommonData';
import { NoDataView } from 'common/components/DataPlaceholders';
interface AddressesFormProps {
  isUpdatable: boolean;
  countries: BusinessUnitCommonData_countries | null | undefined;
}

export const Addresses: React.FC<AddressesFormProps> = ({
  isUpdatable,
  countries,
}) => {
  const styles = useStyles();
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false);
  const [addressIndex, setAddressIndex] = useState<number>(-1);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<BusinessUnitValues>();
  const { fields, remove, insert } = useFieldArray({
    name: 'companyAddressesByCompanyIdAndEntityId',
    control,
  });
  const baseField = `companyAddressesByCompanyIdAndEntityId.${addressIndex}`;
  const watchCompanyAddresses = useWatch({
    name: 'companyAddressesByCompanyIdAndEntityId',
    control,
  });

  return (
    <Stack className={styles.container}>
      {showAddressForm && addressIndex !== -1 ? (
        <FormFields
          baseField={baseField}
          countries={countries}
          toList={() => {
            setAddressIndex(-1);
            setShowAddressForm(false);
          }}
          onRemove={remove}
        />
      ) : (
        <>
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Text variant="xLarge">Addresses</Text>
            <DefaultButton
              text="Add Address"
              iconProps={{ iconName: 'Add' }}
              onClick={() => {
                setAddressIndex(0);
                setShowAddressForm(true);
                if (!fields.length)
                  insert(0, {
                    ...ADDRESS_INITIAL_VALUES,
                    isPrimaryAddress: true,
                  });
                else insert(0, { ...ADDRESS_INITIAL_VALUES });
              }}
              disabled={!isUpdatable}
            />
          </Stack>
          {/* ERROR MESSAGE */}
          {errors.companyAddressesByCompanyIdAndEntityId && (
            <>
              <MessageBar messageBarType={MessageBarType.error}>
                At least one address is currently invalid
              </MessageBar>
            </>
          )}
          {/* ADDRESS LIST */}
          {fields.map((field, index) => {
            return (
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
                className={styles.AddressListItem}
                key={index}
              >
                <Stack tokens={{ padding: '20px 0px' }}>
                  <Link
                    className={styles.addressTypeLabel}
                    onClick={(event) => {
                      event.preventDefault();
                      setAddressIndex(index);
                      setShowAddressForm(true);
                    }}
                  >
                    {/* To remove 'null' from address string. */}
                    {`${watchCompanyAddresses?.[index]?.addressLine1}, ${watchCompanyAddresses?.[index]?.addressLine2}`.replace(
                      ', null',
                      ''
                    )}
                  </Link>
                  <Text className={styles.addressSubtitle} variant="small">
                    {/* To remove 'null' from address string. */}
                    {field.cityName ? `${field.cityName}, ` : ''}
                    {field.postalCode ? `${field.postalCode}, ` : ''}
                    {field.telephoneNumber ? `${field.telephoneNumber}, ` : ''}
                    {field.mainContact ? `${field.mainContact}, ` : ''}
                    {field.emailAddress ? `${field.emailAddress} ` : ''}
                  </Text>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 50 }}>
                  {watchCompanyAddresses?.[index]?.isPrimaryAddress ? (
                    <Text className={styles.primaryLabel}>Primary</Text>
                  ) : watchCompanyAddresses?.[index]?.isDeliveryAddress ? (
                    <Text className={styles.secondaryLabel}>
                      Delivery Address
                    </Text>
                  ) : null}
                  {!watchCompanyAddresses?.[index]?.isDeliveryAddress &&
                    !watchCompanyAddresses?.[index]?.isPrimaryAddress &&
                    isUpdatable && (
                      <CommandBarButton
                        iconProps={{ iconName: 'HomeVerify' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          fields?.forEach((_addr, i) => {
                            setValue(
                              `companyAddressesByCompanyIdAndEntityId.${i}.isDeliveryAddress`,
                              i === index,
                              { shouldDirty: true }
                            );
                          });
                        }}
                        text="Set delivery address"
                        className={clsx(
                          styles.setPrimaryButton,
                          'setPrimaryButtonGlobal'
                        )}
                      />
                    )}
                  {!watchCompanyAddresses?.[index]?.isDeliveryAddress &&
                    !watchCompanyAddresses?.[index]?.isPrimaryAddress &&
                    isUpdatable && (
                      <CommandBarButton
                        iconProps={{ iconName: 'HomeVerify' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          fields?.forEach((_addr, i) => {
                            setValue(
                              `companyAddressesByCompanyIdAndEntityId.${i}.isPrimaryAddress`,
                              i === index,
                              { shouldDirty: true }
                            );
                          });
                        }}
                        text="Set primary"
                        className={clsx(
                          styles.setPrimaryButton,
                          'setPrimaryButtonGlobal'
                        )}
                      />
                    )}
                  {!watchCompanyAddresses?.[index]?.isPrimaryAddress &&
                  isUpdatable ? (
                    <TooltipHost content="Delete">
                      <IconButton
                        iconProps={{ iconName: 'delete' }}
                        className={styles.deleteIcon}
                        ariaLabel="Delete"
                        onClick={() => {
                          remove(index);
                          //if only one address left, set it to Primary
                          if (fields?.length === 2)
                            setValue(
                              `companyAddressesByCompanyIdAndEntityId.0.isPrimaryAddress`,
                              true
                            );
                        }}
                      />
                    </TooltipHost>
                  ) : (
                    <Stack style={{ width: 80 }}></Stack>
                  )}
                </Stack>
              </Stack>
            );
          })}
          <Stack tokens={{ padding: '20px 0px' }}>
            <NoDataView show={fields.length === 0} title="Address not found" />
          </Stack>
        </>
      )}
    </Stack>
  );
};
