import React, { useEffect, useState } from 'react';
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { CloseButton } from '../Buttons/CloseButton';
import { ToggleButton } from '../Buttons/ToggleButton';
import { ApolloError } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';

export interface UpdateProtectionMutationProps {
  loading: boolean;
  updateSecurity: (
    securityData: string | null
  ) => Promise<ApolloError | undefined>;
}

export interface secureRowLevelNode {
  id: GqlUUID;
  description: string | null;
  name: string | null;
  securityLevel: number | null;
}

interface SecureRowLevelProps {
  data: secureRowLevelNode[];
  isUpdatable: boolean;
  visibility: boolean;
  secureRowLevelsId: string;
  onDismiss: () => void;
  updateProtection: UpdateProtectionMutationProps;
}

export const SecureRowLevel: React.FC<SecureRowLevelProps> = ({
  isUpdatable = true,
  secureRowLevelsId,
  visibility = false,
  onDismiss,
  data,
  updateProtection,
}) => {
  const { addToast } = useToasts();
  const [selectedLevel, setselectedLevel] = useState<string | null>(
    secureRowLevelsId
  );

  useEffect(() => {
    setselectedLevel(secureRowLevelsId);
  }, [visibility, secureRowLevelsId]);

  const { loading, updateSecurity } = updateProtection;

  const onClick = async () => {
    const errors = await updateSecurity(selectedLevel);
    if (!errors) {
      addToast('Security Level Changed', { appearance: 'success' });
      onDismiss?.();
    }
  };

  const onTagPressed = (securityRoleID: string) => {
    setselectedLevel(selectedLevel === securityRoleID ? null : securityRoleID);
  };
  const isSelected = (securityRoleID: string) =>
    selectedLevel === securityRoleID;
  const classStyles = useStyles();
  const disabled = secureRowLevelsId !== selectedLevel;
  const disabledString = secureRowLevelsId === '' && selectedLevel === null;

  return (
    <Stack>
      <Modal isOpen={visibility} isBlocking={true} onDismiss={onDismiss}>
        <Stack className={classStyles.container}>
          <Stack horizontal horizontalAlign="space-between">
            <Stack tokens={{ childrenGap: 10 }}>
              <Text variant="xLarge">Protect Document</Text>
              <Text variant="medium">
                Change security level for this document
              </Text>
            </Stack>
            <CloseButton onClick={onDismiss} />
          </Stack>
          <Stack
            className={classStyles.container2}
            horizontal
            wrap
            tokens={{ childrenGap: 20 }}
          >
            {data?.map((securityRole, index) => {
              return (
                <ToggleButton
                  onClick={() => onTagPressed(securityRole.id)}
                  toggled={isSelected(securityRole.id)}
                  key={index.toString()}
                  text={securityRole.name || ''}
                  iconName={isSelected(securityRole.id) ? 'Accept' : ''}
                />
              );
            })}
          </Stack>
          <Stack>{loading && <ProgressIndicator />}</Stack>
          <Stack horizontal tokens={{ childrenGap: 6 }}>
            <PrimaryButton
              disabled={!disabled || disabledString}
              text="Save changes"
              onClick={onClick}
            />
            <DefaultButton onClick={onDismiss} text="Cancel" />
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
};
