import { DangerButton } from 'common/components/DangerButton';
import { SuccessButton } from 'common/components/SuccessButton';
import { EntityAction, EntityType } from 'common/types/utility';

export const getConfirmButton = (action: EntityAction) => {
  switch (action) {
    case EntityAction.Invite:
    case EntityAction.Request:
    case EntityAction.Release:
      return SuccessButton;
    default:
      return DangerButton;
  }
};

export const invalidActionMessage = (
  names: string[],
  entityType: EntityType
) => {
  const prefix = `The following ${entityType.toLowerCase()}${
    names.length > 1 ? 's are' : ' is'
  } invalid:`;
  switch (names.length) {
    case 1:
      return `${prefix} ${names[0]}`;
    case 2:
      return `${prefix} ${names[0]} and ${names[1]}`;
    default:
      return `${prefix} ${names[0]}, ${names[1]} and ${
        names.length - 2
      } others`;
  }
};
