import React from 'react';
import { Icon, IconButton, Stack, Text } from '@fluentui/react';
import { useStyles } from './index.styles';
import { fileType, fileTypeColor } from 'common/utils/fileType';
interface DocumentEntityProps {
  filesList: File[];
  onRemoveFile: (fileIndex: number) => void;
}

export const DocumentEntityList: React.FC<DocumentEntityProps> = ({
  filesList,
  onRemoveFile,
}) => {
  const styles = useStyles();
  return (
    <Stack horizontal wrap>
      {filesList?.map((fileEntity, fileIndex) => {
        return (
          <Stack
            key={fileIndex}
            className={styles.fileContainerStyles}
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 20 }}
          >
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              <Icon
                iconName={fileType('OTHER')}
                className={fileTypeColor('OTHER')}
              />
              <Text>{fileEntity?.name}</Text>
            </Stack>
            <IconButton
              onClick={() => onRemoveFile(fileIndex)}
              iconProps={{ iconName: 'cancel' }}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};
