import {DocumentPoolFormValues} from './types'

export const DOCUMENT_POOL_INITIAL_VALUES: DocumentPoolFormValues = {
    name: null,
    description: null,
    defaultDirectoryTypeId: null,
    userGroupId: null,
    defaultDocumentTypeId: null,
    defaultBusinessUnitId: null,
    defaultDepartmentId: null,
    isShareableDefault: false,
    isCreateCopyWithSelection: false,
    isRetainedWithSelection: false,
    isDocumentTypeSelector: false,
    isBusinessUnitSelector: false,
    isDepartmentSelector: false,
    extractionTypeId: null
}
