import { useLazyQuery } from '@apollo/client';
import { ITextFieldProps } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import {
  RefCode3,
  RefCode3Variables,
} from 'documents/documentAssignment/folder/view/FormView/AccountingView/CustomFields/AutoCompleteRefCode3/__generated__/RefCode3';
import { DocumentNode } from 'graphql';
import { loader } from 'graphql.macro';
import React, { useRef, useState } from 'react';
import { ColumnData } from '../../columns.data';
import { AutoCompleteFF, refType } from '../AutoCompleteFF';
import { InvoiceDetails_invoice_invoiceDistributions_nodes } from '../../../graphql/__generated__/InvoiceDetails';
const REFERENCE_CODE3 = loader(
  '../../../../../../graphql/Reference3Codes.graphql'
);
export type AutoCompleteFFTextFieldProps =
  RequiredNameProps<ITextFieldProps> & {
    index: number;
    invoiceDistributions:
      | InvoiceDetails_invoice_invoiceDistributions_nodes[]
      | undefined;
    query?: DocumentNode;
    columnData?: ColumnData;
  };

export const AutoCompleteFF3: React.FC<AutoCompleteFFTextFieldProps> = ({
  columnData,
  index,
  ...props
}) => {
  const ref = useRef<refType>();
  const [fieldValue, setFieldValue] = useState<string | null>(null);
  const [fetchReferenceCodes, { data, loading }] = useLazyQuery<
    RefCode3,
    RefCode3Variables
  >(REFERENCE_CODE3, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    onCompleted: (data) => {
      if (data.reference3Codes?.nodes.length) {
        const position = data.reference3Codes.nodes.findIndex(
          (item) =>
            item.referenceCode.toUpperCase() === fieldValue?.toUpperCase()
        );
        if (position === -1) {
        } else ref?.current?.onClearFieldValue();
      } else ref?.current?.onClearFieldValue();
    },
  });
  return (
    <>
      <AutoCompleteFF
        width={columnData?.maxWidth}
        id={`${columnData?.key}${index}`}
        onValueChange={(data) => {
          setFieldValue(data);
          fetchReferenceCodes({
            variables: {
              Ref3CodeFilter: {
                referenceCode: {
                  startsWithInsensitive: data,
                },
              },
            },
          });
        }}
        ffValue={props.invoiceDistributions?.[index]?._ff3Name}
        index={index}
        {...props}
        loading={loading}
        referenceCodeData={data?.reference3Codes?.nodes}
        ref={ref}
      />
    </>
  );
};
