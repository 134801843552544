import { Stack } from '@fluentui/react';
import clsx from 'clsx';
import { RemoveRowButton } from 'common/components/Buttons';
import { FormHookDropdown, FormHookTextField } from 'common/components/FormHooksFields';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DietaryRequirementsOptionsProps } from '..';
import { TravelerModalDropdownsData } from '../../__generated__/TravelerModalDropdownsData';
import { TravelerValues } from '../../interfaces';
import { useStyles } from './index.styles';

interface DietaryRequirementsInputRowProps {
  fieldName: string;
  dietaryRequirementsOptions: DietaryRequirementsOptionsProps[];
  onChange: (option?: DietaryRequirementsOptionsProps) => void;
  onRemove: () => void;
  isFirstRow: boolean;
  rowIndex: number;
  inputsDisabled: boolean;
  commonData: TravelerModalDropdownsData | undefined;
  disabledOptions: (number | null)[];
}

export const DietaryRequirementsInputRow: React.FC<DietaryRequirementsInputRowProps> = ({
  fieldName,
  dietaryRequirementsOptions,
  onChange,
  onRemove,
  isFirstRow,
  rowIndex,
  inputsDisabled,
  commonData,
  disabledOptions
}) => {
  const styles = useStyles();
  const { control, setValue, getValues } = useFormContext<TravelerValues>();
  const value = getValues(`travelerDietaryRequirementsByTravelerId.${rowIndex}.dietaryRequirementId`)
  const travelerDietaryRequirementsByTravelerId = useWatch({ name: "travelerDietaryRequirementsByTravelerId", control });
  const showDeleteButton = !!travelerDietaryRequirementsByTravelerId?.[rowIndex].dietaryRequirementId
  const showDescription = !!travelerDietaryRequirementsByTravelerId?.[rowIndex].isDescriptionRequired

  const onDietaryRequirementsChange = async (selectedOption: DietaryRequirementsOptionsProps) => {
    if (selectedOption.isDescriptionRequired) {
      await setValue(`travelerDietaryRequirementsByTravelerId.${rowIndex}.isDescriptionRequired`, true);
    }
    else {
      await setValue(`travelerDietaryRequirementsByTravelerId.${rowIndex}.isDescriptionRequired`, false);
    }
    await setValue(`travelerDietaryRequirementsByTravelerId.${rowIndex}.description`, null);
    onChange(selectedOption);
  }

  return (
    <Stack
      horizontal
      tokens={{
        childrenGap: 20,
        padding: "10px 0px"
      }}
    >
      <Stack.Item className={styles.inputsContainer}>
        {
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            {
              !inputsDisabled &&
              <Stack.Item className={clsx(!isFirstRow && styles.removeButtonContainer)}>
                {
                  showDeleteButton &&
                  <RemoveRowButton
                    onClick={() => {
                      onRemove();
                    }}
                  />
                }
              </Stack.Item>
            }
            <FormHookDropdown
              placeholder="Select Dietary Requirements"
              options={dietaryRequirementsOptions || []}
              selectedKey={value}
              name={`${fieldName}.dietaryRequirementId`}
              onChange={(_e, option) => {
                const selectedOption = option as DietaryRequirementsOptionsProps;
                onDietaryRequirementsChange(selectedOption)
              }}
              onClear={() => {
                onRemove()
              }}
              styles={{
                dropdown: {
                  width: 547
                }
              }}
              disabled={inputsDisabled}
            />
          </Stack>
        }
      </Stack.Item>
      <Stack.Item className={styles.inputsContainer}>
        {
          showDescription &&
          <FormHookTextField
            placeholder="Description"
            name={`${fieldName}.description`}
            disabled={inputsDisabled}
          />
        }
      </Stack.Item>
    </Stack>
  )
}
