import { useMutation } from '@apollo/client';
import {
  ContextualMenu,
  IDragOptions,
  Modal,
  Stack,
  makeStyles,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DepartmentCreateInput,
  DepartmentUpdateInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { DepartmentProps } from '..';
import { Departments_departments } from '../../list/__generated__/Departments';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { Header } from './Header';
import {
  DepartmentCreate,
  DepartmentCreateVariables,
} from './__generated__/DepartmentCreate';
import {
  DepartmentUpdate,
  DepartmentUpdateVariables,
} from './__generated__/DepartmentUpdate';
import { DepartmentValues } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validation';
const DEPARTMENT_CREATE = loader('./DepartmentCreate.graphql');
const DEPARTMENT_UPDATE = loader('./DepartmentUpdate.graphql');
const DEPARTMENT_FIELDS = loader('../../DepartmentFields.graphql');
const DEPARTMENT_FRAGMENT_NAME = 'DepartmentFields';

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

const useStyles = makeStyles(() => ({
  container: {
    width: 800,
    maxHeight: 600,
  },
}));

type DepartmentModalProps = Partial<DepartmentProps> & {
  onClose: () => void;
};

export const DepartmentModal: React.FC<DepartmentModalProps> = ({
  department,
  onClose,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const [createDepartment, { loading: createDepartmentLoading }] = useMutation<
    DepartmentCreate,
    DepartmentCreateVariables
  >(DEPARTMENT_CREATE, { errorPolicy: 'all' });
  const [updateDepartment, { loading: updateDepartmentLoading }] = useMutation<
    DepartmentUpdate,
    DepartmentUpdateVariables
  >(DEPARTMENT_UPDATE, { errorPolicy: 'all' });

  const formMethods = useForm<DepartmentValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const { handleSubmit, reset, trigger } = { ...formMethods };
  const { id, _rowTimestamp } = { ...department };
  const isEdit = !!id;
  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack]);

  const onHandleSubmit = async (values: DepartmentValues) => {
    const { name, description } = { ...values };
    if (!!id && !!_rowTimestamp) {
      const { errors } = await updateDepartment({
        variables: {
          input: {
            id,
            rowTimestamp: _rowTimestamp,
            departmentPatch: {
              name,
              description,
            },
          } as DepartmentUpdateInput,
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Department updated successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const { errors } = await createDepartment({
        variables: {
          input: {
            department: {
              name,
              description,
            },
          } as DepartmentCreateInput,
        },
        update: (cache, { data }) => {
          if (!!data?.departmentCreate?.department?.id) {
            cache.modify({
              fields: {
                departments(existing: Departments_departments) {
                  if (data?.departmentCreate?.department) {
                    const newDepartmentRef = cache.writeFragment({
                      data: data.departmentCreate.department,
                      fragment: DEPARTMENT_FIELDS,
                      fragmentName: DEPARTMENT_FRAGMENT_NAME,
                    });
                    return {
                      ...existing,
                      nodes: [newDepartmentRef, ...existing.nodes],
                    };
                  }
                },
              },
            });
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Department created successfully.', {
          appearance: 'success',
        });
        onClose();
      }
    }
  };
  useEffect(() => {
    if (department) {
      const defaultValues = getDefaultValues({
        department,
      });
      reset(defaultValues);
    }
    trigger();
  }, [department, reset]);

  return (
    <Modal isOpen isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        <FormProvider {...formMethods}>
          <Header
            onClose={onClose}
            title={department ? 'Update Department' : 'Create Department'}
            isEdit={isEdit}
          />
          <BasicForm />
          <Footer
            loading={updateDepartmentLoading || createDepartmentLoading}
            onSubmit={handleSubmit(onHandleSubmit)}
            onCancel={onClose}
          />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
