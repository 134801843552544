import { useLazyQuery } from '@apollo/client';
import {
  Callout,
  DirectionalHint,
  ITextFieldStyles,
  Label,
  Spinner,
  Stack,
  Text,
  TextField,
  useTheme,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useCommonStyles } from 'common/styles';
import { LookupNamesOrderBy } from 'common/types/globalTypes';
import { RequiredNameProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TripCreateFormValues } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/types';
import {
  LookupSectionNameSearch,
  LookupSectionNameSearchVariables,
  LookupSectionNameSearch_lookupSectionNameSearch_nodes,
} from './__generated__/LookupSectionNameSearch';
import { useStyles } from './index.styles';
const LOOKUP_NAME = loader('./LookupSectionNameSearch.graphql');

interface ProviderSearchProps {
  tripSectionId: string | null | undefined;
  placeholder: string;
  tripIndex: number;
  label: string;
  disabled: boolean;
}

const useTextFieldStyles = (disabled: boolean) => {
  const theme = useTheme();
  const textfieldStyles = useMemo(() => {
    const styles: Partial<ITextFieldStyles> = {
      field: { height: 50 },
      fieldGroup: { height: 50 },
      suffix: {
        backgroundColor: disabled
          ? theme.palette.neutralLighter
          : theme.palette.white,
      },
    };
    return styles;
  }, [disabled, theme]);

  return textfieldStyles;
};

export const ProviderSearch: React.FC<
  RequiredNameProps<ProviderSearchProps>
> = ({ tripSectionId, name, placeholder, label, tripIndex, disabled }) => {
  const { field } = useController({ name });
  const { setValue } = useFormContext<TripCreateFormValues>();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const textFieldStyles = useTextFieldStyles(false);
  const calloutId = useId('providerSearchCallout');
  const [callOutVisible, setCallOutVisible] = useState<boolean>(false);
  const [getLookUpdata, { data, loading }] = useLazyQuery<
    LookupSectionNameSearch,
    LookupSectionNameSearchVariables
  >(LOOKUP_NAME);

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      getLookUpdata({
        variables: {
          travelSectionId: tripSectionId!,
          lookupName: value!,
          orderBy: [LookupNamesOrderBy.LOOKUP_NAME_ASC],
        },
      });
      queryCalled();
    }, 350),
    [field.value]
  );

  const onSearch = (_: any, value?: string) => {
    if (value) {
      field.onChange(value);
      callbackLookUp(value, () => {
        setCallOutVisible(true);
      });
    } else {
      field.onChange(null);
      setCallOutVisible(false);
    }
  };

  const renderSpinner = () => {
    return <>{loading ? <Spinner /> : null}</>;
  };

  const onSelect = (item: LookupSectionNameSearch_lookupSectionNameSearch_nodes) => {
    field.onChange(item.lookupName);
    if (item.countryId) {
      setValue(
        `tripItems.${tripIndex}.rentalCountryId`,
        item.countryId
      );
    }
    if (item.stateRegionId) {
      setValue(
        `tripItems.${tripIndex}.rentalStateRegionId`,
        item.stateRegionId
      );
    }
    if (item.zipCode) {
      setValue(
        `tripItems.${tripIndex}.rentalPostalCode`,
        item.zipCode
      );
    }
    if (item.city) {
      setValue(
        `tripItems.${tripIndex}.rentalCityName`,
        item.city
      );
    }
    if (item.address1) {
      setValue(
        `tripItems.${tripIndex}.rentalAddress1`,
        item.address1
      );
    }
    if (item.address2) {
      setValue(
        `tripItems.${tripIndex}.rentalAddress2`,
        item.address2
      );
    }
    if (item.phone) {
      setValue(
        `tripItems.${tripIndex}.rentalTelephoneNumber`,
        item.phone
      );
    }
    setCallOutVisible(false);
  }

  return (
    <Stack>
      <Label className={commonStyles.colorThemePrimary}>{label}</Label>
      <TextField
        id={calloutId}
        styles={textFieldStyles}
        placeholder={placeholder}
        onChange={onSearch}
        value={field.value || ''}
        onRenderSuffix={renderSpinner}
        disabled={disabled}
      />

      {callOutVisible && data?.lookupSectionNameSearch?.nodes?.length! > 0 && (
        <Callout
          gapSpace={10}
          onDismiss={() => setCallOutVisible(false)}
          isBeakVisible={false}
          className={styles.callout}
          directionalHint={DirectionalHint.bottomLeftEdge}
          target={`#${calloutId}`}
          calloutWidth={500}
        >
          <Stack className={styles.itemContainer}>
            {data?.lookupSectionNameSearch?.nodes.map((item, index) => {
              return (
                <Text
                  onClick={() => onSelect(item)}
                  className={styles.resultItem}
                  key={index.toString()}
                >
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Text>{item.lookupDescription}</Text>
                  </Stack>
                </Text>
              );
            })}
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};
