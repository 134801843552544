import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  tagsContainer: {
    paddingTop: 24,
  },
  tagsContent: {
    margin: '0px -20px',
    padding: 20,
  },
  emptyTagsView: {
    padding: 20,
    width: 200,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: FontSizes.size16,
    borderWidth: 1,
    borderColor: theme.palette.neutralLight,
    borderStyle: 'solid',
  },
  closeButton: {
    color: theme.palette.neutralDark,
  },
  commandBarButton: {
    height: 40,
  },
  customWidth: {
    width: '900px',
  },
}));
