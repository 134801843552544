import { useMutation } from '@apollo/client';
import { Stack, Text } from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { VIEW_DOC_MODAL_WIDTH } from 'common/components/DocumentList/DocumentViewModal';
import { PdfViewModal } from 'common/components/PdfViewModal';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import { ApprovalState } from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import { loader } from 'graphql.macro';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { DocumentPackage } from '../FormView/DocumentPackage';
import { LinkedTransaction } from '../FormView/LinkedTransaction';
import { OverrideApprovalHint } from './OverrideApprovalHint';
import { PreHierarchyModal } from './PreHierarchyModal';
import { UrgencyFolder } from './UrgencyFolder';
import { useStyles } from './index.styles';
import { InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals } from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';
import { InvoiceDetails } from '../graphql/__generated__/InvoiceDetails';
import { InvoiceTransactionTypes } from '../graphql/__generated__/InvoiceTransactionTypes';
import { TransactionPDF } from '../FormView/TransactionPDF';
import {
  InvoiceSigningDelete,
  InvoiceSigningDeleteVariables,
} from 'common/graphql/__generated__/InvoiceSigningDelete';

const DELETE_TRANSACTION = loader(
  '../../../../graphql/InvoiceSigningDelete.graphql'
);

interface HeaderProps {
  invoiceDetailsData?: InvoiceDetails;
  isNew: boolean;
  invoiceDetailsLoading: boolean;
  dirty: boolean;
  isSubmitting: boolean;
  transactionTypeData: InvoiceTransactionTypes | undefined;
}

export const Header: React.FC<HeaderProps> = ({
  isNew,
  invoiceDetailsData,
  invoiceDetailsLoading,
  dirty,
  isSubmitting,
  transactionTypeData,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { width } = getWindowDimensions();
  const documentViewPosition = width
    ? width - (1200 + VIEW_DOC_MODAL_WIDTH)
    : 50;
  const { transactionType, statusType } = {
    ...invoiceDetailsData?.invoice,
  };
  const commonStyles = useCommonStyles();
  const [deleteTransaction] = useMutation<
    InvoiceSigningDelete,
    InvoiceSigningDeleteVariables
  >(DELETE_TRANSACTION, { errorPolicy: 'all' });

  const panelHeading = `${isNew ? 'Create New' : 'Edit'} Transaction`;
  const styles = useStyles();
  let panelInvoiceStatus: string;
  if (isNew) panelInvoiceStatus = '(Draft)';
  else if (
    !invoiceDetailsLoading &&
    statusType?.approvalState !== ApprovalState.APPROVED_NOT_REQUIRED
  )
    panelInvoiceStatus = `(${statusType?.statusType!})`;
  else panelInvoiceStatus = '';
  const onConfirmDelete = async () => {
    const { errors } = await deleteTransaction({
      variables: {
        input: {
          entityDelete: [
            {
              id: invoiceDetailsData?.invoice?.id!,
              rowTimestamp: invoiceDetailsData?.invoice?._rowTimestamp!,
            },
          ],
        },
      },
      update(cache, data) {
        if (!data.errors?.length) {
          setTimeout(() => {
            const identity = cache.identify({ ...invoiceDetailsData?.invoice });
            cache.evict({ id: identity });
            cache.gc();
          }, 200);
          try {
            cache.modify({
              fields: {
                invoiceSigningSearchFilterTotals(
                  existing: InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    const {
                      _spotCurrencyAmount,
                      _baseCurrencyAmount,
                      controlTotalAmount,
                    } = {
                      ...invoiceDetailsData?.invoice,
                    };

                    const totalAmount1 = (
                      parseFloat(existing.totalAmount1 || '0.00') -
                      parseFloat(controlTotalAmount || '0.00')
                    ).toString();
                    return {
                      ...existing,
                      totalAmount1,
                      totalAmount2: (
                        parseFloat(existing?.totalAmount2 || '0.00') -
                        parseFloat(_baseCurrencyAmount || '0.0')
                      ).toString(),
                      totalAmount3: (
                        parseFloat(existing.totalAmount3 || '0.00') -
                        parseFloat(_spotCurrencyAmount || '0.0')
                      ).toString(),
                      totalCount1: existing.totalCount1
                        ? existing.totalCount1 - 1
                        : 0,
                    } as InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals;
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
              },
            });
          } catch (error) {}
        }
      },
    });

    if (errors?.length) {
      addToast(errors[0].message, { appearance: 'error' });
    } else {
      history.replace('/signing');
      addToast('Deleted transaction successfully', { appearance: 'success' });
    }
  };
  return (
    <Stack className={styles.container}>
      <Stack grow horizontal horizontalAlign="space-between">
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {transactionType?.transactionType && !isNew && (
              <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
                {transactionType?.transactionType}
              </Text>
            )}
            <DocumentPackage
              paddingLeft={documentViewPosition}
              documentPackageId={
                invoiceDetailsData?.invoice?._documentPackageId
              }
            />
            <UnsavedIndicator
              visible={
                !isNew && !invoiceDetailsLoading && dirty && !isSubmitting
              }
            />
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            {!isNew && (
              <>
                {!invoiceDetailsLoading && (
                  <OverrideApprovalHint
                    invoiceDetailsData={invoiceDetailsData}
                    transactionTypeData={transactionTypeData}
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
        <Stack verticalAlign="start" horizontal tokens={{ childrenGap: 10 }}>
          {invoiceDetailsData?.invoice?.statusType?.statusType ===
            'Pending' && (
            <UrgencyFolder invoiceDetailsData={invoiceDetailsData} />
          )}
          {invoiceDetailsData?.invoice?._isStagedApprovalRequest && !isNew && (
            <PreHierarchyModal entityId={invoiceDetailsData?.invoice?.id} />
          )}
          <PdfViewModal visible={!!invoiceDetailsData}>
            <TransactionPDF
              invoiceData={invoiceDetailsData}
              transactionTypeData={transactionTypeData!}
            />
          </PdfViewModal>
          <LinkedTransaction
            _linkedInvoiceId={invoiceDetailsData?.invoice?._linkedInvoiceId!}
          />
          <ActionMessageModal
            visible={!isNew}
            entityType={EntityType.Transaction}
            action={EntityAction.Remove}
            disabled={!invoiceDetailsData?.invoice?._isDeletable}
            onConfirm={onConfirmDelete}
          />
          <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center">
            <Text className={styles.approvalStatus}>{panelInvoiceStatus}</Text>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
