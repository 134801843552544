import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  username: {
    color: theme.palette.themePrimary,
    margin: "0px 5px"
  },
  container:{
    marginBottom: 14
  }
}));
