import React, { useState } from 'react';
import { PrimaryButton, Stack } from '@fluentui/react';
import { CardCompaniesModal } from './view';
export const CardCompanies = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const closeModal = () => setModalVisible(false);
  const showModal = () => setModalVisible(true);

  return (
    <Stack>
      <PrimaryButton
        onClick={showModal}
        iconProps={{
          iconName: 'Add',
        }}
        text="Card Companies"
      />
      {modalVisible && <CardCompaniesModal onClose={closeModal} />}
    </Stack>
  );
};
