import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutralLighter,
    position: 'absolute',
    background: 'lightgreen',
    padding: 8,
    bottom: 0,
    right: 0,
    content: 'in portal',
  },
}));
