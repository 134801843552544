import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '70px 0px 100px 0px',
  },
  columnCell: {
    paddingLeft: 20,
    height: 25,
  },
}));
