import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  listLoaderContainer: {
    marginLeft: 30,
    marginRight: 30,
  },
  respondMessage: {
    width: '920px',
  },
  respondSendCancelContainer: {
    marginLeft: 10,
  },
  respondViewContainer: {
    padding: '10px 14px 0px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  }));
