import { useQuery } from '@apollo/client';
import {
  ActionButton,
  IDropdownOption,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { loader } from 'graphql.macro';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CHART_TYPE_INITIAL_VALUES } from '../constants';
import { UserTemplatevalues } from '../types';
import { ChartTypes } from './__generated__/ChartTypes';
import { useStyles } from './index.styles';

const CHART_TYPES = loader('./ChartTypes.graphql');
interface DashboardChartsProps {
  isUpdatable?: boolean;
  existingChartTypeIds: Set<number>;
}
export const DashboardCharts: React.FC<DashboardChartsProps> = ({
  isUpdatable,
  existingChartTypeIds,
}) => {
  const styles = useStyles();

  const { control } = useFormContext<UserTemplatevalues>();

  const { fields, remove, append } = useFieldArray({
    name: 'userTemplateCharts',
    control,
  });

  const { data: chartTypeData } = useQuery<ChartTypes>(CHART_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  return (
    <Stack grow tokens={{ childrenGap: 25, padding: '20px 0px 50px 20px' }}>
      <Stack
        horizontal
        tokens={{
          childrenGap: 60,
        }}
        verticalAlign="start"
        horizontalAlign="start"
      >
        <Stack
          horizontal
          horizontalAlign="start"
          className={styles.width250}
          verticalAlign="center"
        >
          <Text variant="xLarge">DashBoard Charts</Text>
        </Stack>
        <Stack horizontal horizontalAlign="start" className={styles.width200}>
          <Text variant="xLarge">Position</Text>
        </Stack>
        <Stack horizontal horizontalAlign="start" className={styles.width200}>
          <Text variant="xLarge">Default</Text>
        </Stack>
      </Stack>
      {fields.map((field, index) => {
        const chartTypeExists =
          !!field.chartTypeId && existingChartTypeIds.has(field.chartTypeId);
        const chartTypeOptions: IDropdownOption[] =
          chartTypeData?.chartTypes?.nodes
            .filter(
              (type) =>
                type.id === field.chartTypeId ||
                !fields?.some((row) => row.chartTypeId === type.id)
            )
            .map((ele) => ({
              key: ele.id,
              text: ele.caption,
            })) || [];
        return (
          <Stack
            horizontal
            tokens={{
              childrenGap: 60,
            }}
            key={field.id}
          >
            <FormHookDropdown
              name={`userTemplateCharts.${index}.chartTypeId`}
              options={chartTypeOptions}
              width={250}
              disabled={!isUpdatable || chartTypeExists}
            />
            <Stack className={styles.width200} verticalAlign="end">
              <FormHookAmount
                name={`userTemplateCharts.${index}.chartPosition`}
                placeholder="enter position"
                decimalScale={0}
                allowNegative={false}
                disabled={!isUpdatable}
              />
            </Stack>
            <Stack verticalAlign="center">
              <FormHookCheckBox name={`userTemplateCharts.${index}.isActive`} />
            </Stack>
            <Stack verticalAlign="start">
              <TooltipHost content="Delete">
                <IconButton
                  iconProps={{ iconName: 'delete' }}
                  ariaLabel="Delete"
                  onClick={() => {
                    remove(index);
                  }}
                />
              </TooltipHost>
            </Stack>
          </Stack>
        );
      })}
      <Stack horizontal verticalAlign="center">
        <ActionButton
          iconProps={{ iconName: 'add' }}
          text="Add"
          onClick={() => {
            append(CHART_TYPE_INITIAL_VALUES);
          }}
          disabled={!isUpdatable}
        />
      </Stack>
    </Stack>
  );
};
