import React, { FC, useCallback, useEffect, useState } from 'react';
import { RequiredNameProps } from 'common/types/utility';
import {
  TextField,
  ITextFieldProps,
  Stack,
  Spinner,
  useTheme,
  Link,
  Text,
  TooltipHost,
  Icon,
  IRenderFunction,
} from '@fluentui/react';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import { LookUpCallout } from './Lookup';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import { useController, useFormContext } from 'react-hook-form';
import { GetEntityDocument_entityDocument_documentFileDistributionsByDocumentFileId_nodes } from 'documents/documentAssignment/folder/view/__generated__/GetEntityDocument';
import { ReferenceCodeOptionsType } from '../interface';
import { RefCode4, RefCode4Variables } from './__generated__/RefCode4';
const REFERENCECODES = loader('./RefCode4.graphql');
type AutoCompleteFFTextFieldProps = RequiredNameProps<ITextFieldProps> & {
  width: number;
  index: number;
  invoiceDistributions:
    | GetEntityDocument_entityDocument_documentFileDistributionsByDocumentFileId_nodes[]
    | undefined;
};

export const AutoCompleteFF4: FC<AutoCompleteFFTextFieldProps> = ({
  width,
  index,
  invoiceDistributions,
  ...props
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { clearErrors, setFocus } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
  });
  const { value, onChange } = field;
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [referenceCode, setReferenceCode] = useState<string | null>();
  const [referenceCodeOptions, setReferenceCodeOptions] = useState<
    ReferenceCodeOptionsType[]
  >([]);

  const [fetchReferenceCodes, { data, loading }] = useLazyQuery<
    RefCode4,
    RefCode4Variables
  >(REFERENCECODES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    onCompleted: (data) => {
      if (data.reference4Codes?.nodes.length) {
        const position = data.reference4Codes.nodes.findIndex(
          (item) => item.referenceCode.toUpperCase() === value?.toUpperCase()
        );
        if (position === -1) {
          setFocus(props.name);
        } else clearFormikFields();
      } else clearFormikFields();
    },
  });
  const { id } = { ...props };

  useEffect(() => {
    if (invoiceDistributions && invoiceDistributions.length > 0) {
      setReferenceCode(invoiceDistributions[index]?._ff4Name!);
    }
  }, [invoiceDistributions, index]);

  useEffect(() => {
    if (data) {
      const ref4Options: ReferenceCodeOptionsType[] =
        data?.reference4Codes?.nodes.map((item, index) => ({
          key: index,
          text: item.name,
          referenceCode: item.referenceCode,
        })) || [];
      setReferenceCodeOptions(ref4Options);
    }
  }, [invoiceDistributions, data]);

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchReferenceCodes({
        variables: {
          Ref4CodeFilter: {
            referenceCode: {
              startsWithInsensitive: value,
            },
          },
        },
      });
      queryCalled();
    }, 350),
    [value]
  );

  const onRenderSuffix: IRenderFunction<ITextFieldProps> = (props) => {
    if (loading) return <Spinner />;
    else
      return (
        <button
          className={styles.customDropdownIcon}
          onClick={() => {
            callbackLookUp('', () => {
              if (!calloutVisible) setCalloutVisible(true);
            });
          }}
          tabIndex={-1}
        >
          <Icon iconName="ChevronDown" />
        </button>
      );
  };

  const textfieldProps: ITextFieldProps | undefined = { onRenderSuffix };

  const clearFormikFields = () => {
    // helpers.setTouched(false);
    clearErrors(props.name);
    setReferenceCode('');
  };

  const commonStyles = useCommonStyles();
  return (
    <Stack style={{ marginTop: 3 }}>
      <TooltipHost content={referenceCode!}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={
            {
              // borderWidth: 0,
              // borderBottomColor: error?.message
              //   ? theme.semanticColors.errorText
              //   : theme.palette.black,
              // borderBottomWidth: 1,
              // borderStyle: 'solid',
            }
          }
        >
          <TextField
            {...props}
            // borderless
            autoComplete={'off'}
            errorMessage={error?.message}
            value={value || ''}
            onChange={(_event, value) => {
              if (value) {
                onChange(value);
                callbackLookUp(value, () => {
                  if (!calloutVisible) setCalloutVisible(true);
                });
              } else {
                onChange(null);
                setCalloutVisible(false);
              }
            }}
            {...textfieldProps}
            underlined
            styles={{
              suffix: { backgroundColor: theme.palette.white },
              fieldGroup: { width: width },
            }}
          />
        </Stack>
        <Text style={{ color: theme.palette.white }}>{error?.message}</Text>
      </TooltipHost>

      {calloutVisible && !loading && referenceCodeOptions.length! > 0 && (
        <LookUpCallout
          lookUpCalloutWidth={300}
          onDismiss={() => setCalloutVisible(false)}
          targetID={`#${id!}`}
        >
          {referenceCodeOptions.map((data, index) => {
            return (
              <Link
                onClick={() => {
                  onChange(data.referenceCode);
                  setReferenceCode(data.text);
                  setCalloutVisible(false);
                }}
                className={styles.resultItem}
                key={index.toString()}
              >
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Text className={commonStyles.colorThemePrimary}>
                    {data.referenceCode}
                  </Text>
                  <Text style={{ width: 220 }} nowrap>
                    {data.text}
                  </Text>
                </Stack>
              </Link>
            );
          })}
        </LookUpCallout>
      )}
    </Stack>
  );
};
