import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  fixedWidth: {
    width: 150,
    height: 80,
    borderColor: 'red',
    borderWidth: 1.5,
    backgroundColor: '#FFFFFF33',
    flexDirection: 'column',
  },
  titleContainer: {
    borderBottomColor: 'red',
    borderBottomWidth: 0.5,

    alignItems: 'center',
    padding: 5,
  },
  red: {
    fontSize: 10,
    fontWeight: 'bold',
    color: 'red',
  },
  grey: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#605e5c',
  },
  rowContainer: {
    borderBottomColor: 'red',
    borderBottomWidth: 0.5,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
    flexDirection: 'row',
  },
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
    flexDirection: 'row',
  },
});
