import React from 'react';
import { IPivotItemProps, Stack, Text } from '@fluentui/react';
import { useStyles } from './index.styles';


export function PivotCustomRender(
  link?: IPivotItemProps,
  defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
): JSX.Element | null {
  const styles = useStyles();
  if (!link || !defaultRenderer) {
    return null;
  }
  return (
    <Stack horizontal>
      {defaultRenderer({ ...link, itemCount: undefined })}
      {link.itemCount !== 0 && <div className={styles.width10} />}
      {link.itemCount !== 0 && (
        <Stack
          horizontalAlign='center'
          verticalAlign='center'
          className={styles.contentWrapper}
        >
          <Text className={styles.itemCount} variant='mediumPlus'>
            {link.itemCount}
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
