import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Pivot, PivotItem } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { FileCabinetTabs, setTabMode } from '..';

export const Tabs: React.FC = () => {
  const tabMode = useReactiveVar(setTabMode);
  const history = useHistory();

  return (
    <Pivot
      selectedKey={tabMode}
      onLinkClick={(item: PivotItem | undefined) => {
        setTabMode(item?.props.itemKey);
        history.replace('/doc/fileCabinet');
      }}
    >
      <PivotItem itemKey={FileCabinetTabs.folder} headerText="Folder" />
      <PivotItem itemKey={FileCabinetTabs.documents} headerText="Documents" />
    </Pivot>
  );
};
