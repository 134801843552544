import { ColumnActionsMode, IColumn } from '@fluentui/react';

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.clickable,
};

export const columns: IColumn[] = [
  {
    key: 'purchaseOrderNumber',
    name: 'PO Number',
    fieldName: 'purchaseOrderNumber',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: 'supplierReferenceName',
    name: 'Supplier',
    fieldName: 'supplierReferenceName',
    minWidth: 250,
    ...commonProps,
  },
  {
    key: 'requesterName',
    name: 'Request name',
    fieldName: 'requesterName',
    minWidth: 150,
    ...commonProps,
  },
  {
    key: 'dateRequested',
    name: 'Request date',
    fieldName: 'dateRequested',
    minWidth: 130,
    ...commonProps,
  },
  {
    key: 'poTotal',
    name: 'Amount',
    fieldName: 'poTotal',
    minWidth: 100,
    isPadded: true,
    styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
    ...commonProps,
  },
];
