import {
  DefaultButton,
  IContextualMenuProps,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { customStyles } from './index.styles';
interface BottomActionBarProps {
  createNewText?: string;
  showButtons?: {
    showCreateNewButton?: boolean;
    showOnSaveButton?: boolean;
  };
  disabled?: boolean | { save?: boolean; cancel?: boolean; remove?: boolean };
  isCreate?: boolean;
  isSubmitting?: boolean;
  onSave?: () => void;
  onSaveAnother?: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  addNewForm?: () => void;
  children?: ReactNode;
  hideCreateButton?: boolean;
  saveButtonText?: string;
  saveNewButtonText?: string;
}
export const FooterActionBar: React.FC<BottomActionBarProps> = ({
  createNewText,
  showButtons = {
    showCreateNewButton: true,
    showOnSaveButton: true,
  },
  disabled,
  isCreate,
  isSubmitting,
  onSave,
  onSaveAnother,
  onCancel,
  isLoading = false,
  addNewForm,
  children,
  hideCreateButton = false,
  saveButtonText = 'Save',
  saveNewButtonText = 'Save and Create new'
}) => {
  const styles = customStyles();
  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'saveAndCreateNew',
        text: saveNewButtonText,
        disabled:
          disabled === true || (disabled && disabled.save) || isSubmitting,
        onClick: (event) => {
          if (event) event.stopPropagation();
          if (onSaveAnother) {
            onSaveAnother();
          }
        },
      },
    ],
  };

  const disable =
    disabled === true || (disabled && disabled.save) || isSubmitting;
  return (
    <Stack className={styles.footerStyle}>
      {isLoading && <ProgressIndicator />}
      <Stack
        horizontal
        tokens={{ childrenGap: 10, padding: '25px 25px 25px 25px' }}
      >
        <Stack horizontal grow={4}>
          <Stack.Item grow={2}>
            {isCreate ? (
              <DefaultButton
                text={saveButtonText}
                primary
                split
                menuProps={menuProps}
                onClick={onSave}
                disabled={disable}
              />
            ) : (
              showButtons.showOnSaveButton && (
                <PrimaryButton
                  className={styles.diabledButton}
                  text="Save"
                  disabled={disable}
                  onClick={onSave}
                />
              )
            )}
            <DefaultButton
              className={clsx(styles.marginLeft15, styles.diabledButton)}
              text="Cancel"
              onClick={onCancel}
            />
          </Stack.Item>

          <Stack.Item grow={2}>
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              {children}
              {!isCreate && showButtons?.showOnSaveButton && !hideCreateButton && (
                <PrimaryButton
                  onClick={addNewForm}
                  iconProps={{
                    iconName: 'Add',
                  }}
                  text={createNewText}
                />
              )}
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  );
};
