import {
  ContextualMenu,
  IDragOptions,
  Modal,
  Stack,
  makeStyles,
} from '@fluentui/react';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BasicForm } from './BasicForm';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import {
  OccupationUpdateInput,
  OccupationCreateInput,
} from 'common/types/globalTypes';
import { OccupationProps } from '..';
import { OccupationValues } from './types';
import { getDefaultValues } from './utils';
import { Header } from './Header';
import {
  OccupationCreate,
  OccupationCreateVariables,
} from './__generated__/OccupationCreate';
import {
  OccupationUpdate,
  OccupationUpdateVariables,
} from './__generated__/OccupationUpdate';
import { Footer } from './Footer';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation';
import { Occupations_userOccupationTitles } from '../../list/__generated__/Occupations';

const OCCUPATION_CREATE = loader('./OccupationCreate.graphql');
const OCCUPATION_UPDATE = loader('./OccupationUpdate.graphql');
const OCCUPATION_FIELDS = loader('../../OccupationFields.graphql');
const OCCUPATION_FRAGMENT_NAME = 'OccupationFields';

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

const useStyles = makeStyles(() => ({
  container: {
    width: 800,
    maxHeight: 600,
  },
}));

type FormModalProps = Partial<OccupationProps> & {
  onClose: () => void;
};

export const OccupationViewModal: React.FC<FormModalProps> = ({
  occupation,
  onClose,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const [createOccupation, { loading: createOccupationLoading }] = useMutation<
    OccupationCreate,
    OccupationCreateVariables
  >(OCCUPATION_CREATE, { errorPolicy: 'all' });
  const [updateOccupation, { loading: updateOccupationLoading }] = useMutation<
    OccupationUpdate,
    OccupationUpdateVariables
  >(OCCUPATION_UPDATE, { errorPolicy: 'all' });

  const formMethods = useForm<OccupationValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const { handleSubmit, reset, trigger } = { ...formMethods };
  const { id, _rowTimestamp } = { ...occupation };
  const isEdit = !!id;
  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack]);

  const onHandleSubmit = async (values: OccupationValues) => {
    const { userOccupationTitle } = { ...values };
    if (!!id && !!_rowTimestamp) {
      const { errors } = await updateOccupation({
        variables: {
          input: {
            id,
            rowTimestamp: _rowTimestamp,
            occupationPatch: {
              userOccupationTitle,
            },
          } as OccupationUpdateInput,
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Occupation updated successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const { errors } = await createOccupation({
        variables: {
          input: {
            occupation: {
              userOccupationTitle: userOccupationTitle,
            },
          } as OccupationCreateInput,
        },
        update: (cache, { data }) => {
          if (!!data?.occupationCreate?.userOccupationTitle?.id) {
            cache.modify({
              fields: {
                userOccupationTitles(
                  existing: Occupations_userOccupationTitles
                ) {
                  if (data?.occupationCreate?.userOccupationTitle) {
                    const newOccupationRef = cache.writeFragment({
                      data: data.occupationCreate.userOccupationTitle,
                      fragment: OCCUPATION_FIELDS,
                      fragmentName: OCCUPATION_FRAGMENT_NAME,
                    });
                    return {
                      ...existing,
                      nodes: [newOccupationRef, ...existing.nodes],
                    };
                  }
                },
              },
            });
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Occupation created successfully.', {
          appearance: 'success',
        });
        onClose();
      }
    }
  };
  useEffect(() => {
    if (occupation) {
      const defaultValues = getDefaultValues({
        occupation,
      });
      reset(defaultValues);
    }
    trigger();
  }, [occupation, reset, trigger]);

  return (
    <Modal isOpen isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        <FormProvider {...formMethods}>
          <Header
            onClose={onClose}
            title={occupation ? 'Update Occupation' : 'Create Occupation'}
            isEdit={isEdit}
          />
          <BasicForm />
          <Footer
            loading={updateOccupationLoading || createOccupationLoading}
            onSubmit={handleSubmit(onHandleSubmit)}
            onCancel={onClose}
          />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
