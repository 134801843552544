import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SupplierAvailableDocumentTypes } from 'settings/account/supplier/view/ActionMenu/SupplierAttach/__generated__/SupplierAvailableDocumentTypes';
import { TravelAuthorizationValues } from 'travelAuthorization/TravelPlan/view/interface';
import { TASupplierDetails_supplier } from '../../../../__generated__/TASupplierDetails';
import { AttachFormModal } from './AttachFormModal';
export interface AttachFormProps {
  availableDocumentTypes: SupplierAvailableDocumentTypes | undefined;
  supplier: TASupplierDetails_supplier;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  supplier,
  availableDocumentTypes,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const {
    formState: { isDirty },
  } = useFormContext<TravelAuthorizationValues>();

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={isDirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          supplier={supplier}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  );
};
