import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 20px 10px 20px',
  },
  tabContainer: {
    transition: 'all 100ms ease-in-out',
    cursor: 'pointer',
    ':hover': {
      transform: 'scale(1.2)',
    },
  },
  toggleButton: {
    cursor: 'pointer',
  },
}));
