import { Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useStyles } from './index.styles';

interface BadgeProps {
  visible?: boolean;
  filtersCount: number;
}

export const Badge: React.FC<BadgeProps> = ({
  visible,
  filtersCount,
  children,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  if (!visible) {
    if (!children) return null;
    return <>{children}</>;
  }

  return (
    <span className={styles.root}>
      {children}
      <Stack className={styles.badge}>
        <Text className={clsx(styles.textStyles, commonStyles.bold)}>
          {filtersCount}
        </Text>
      </Stack>
    </span>
  );
};
