import { IDropdownOption, IRenderFunction, ISelectableOption, MessageBar, MessageBarType, Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import { UserDefaultDropdownData } from 'Preferences/__generated__/UserDefaultDropdownData';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { UserDefaultFormValues } from 'Preferences/types';
import { AdvancedFormikDropdown } from 'common/components/FormikFields/AdvancedFormikDropdown';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import { FormikTimePicker } from 'common/components/FormikFields/FormikTimePicker';
import { useCommonStyles } from 'common/styles';
import { useFormikContext } from 'formik';
import React from 'react';

interface DelegateApproverProps {
  dropdownData: UserDefaultDropdownData | undefined;
  formikNameInitial: string;
  userDefaults: UserDefaults_userDefaults_nodes | undefined;
}
export const DelegateApprover: React.FC<DelegateApproverProps> = ({
  dropdownData,
  formikNameInitial,
  userDefaults,
}) => {
  const commonStyles = useCommonStyles();
  const { values } = useFormikContext<UserDefaultFormValues>()

  const options: IDropdownOption[] =
    dropdownData?.delegateApproverList?.nodes.map(
      item => ({
        key: item.id,
        text: item.user?.name,
        data: {
          personaProps: {
            text: item.user?.name,
            size: PersonaSize.size24, // You can customize the size
          },
        }
      }) as IDropdownOption
    ) || [];

  const renderOption: IRenderFunction<ISelectableOption> = (
    props,
  ): JSX.Element => {
    const { personaProps } = props?.data;
    return <Persona {...personaProps} />;
  };

  const showApproverDateTime = !!values.userDefaultPatch?.delegateUserProfileId;

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      {userDefaults?._isApproverProxyAllowed && (
        <>
          <Stack
            horizontal
            tokens={{ padding: '10px 0px 10px 0px', childrenGap: 20 }}
          >
            <Text variant="xLarge" className={commonStyles.bold}>
              Delegated Approver
            </Text>

            {userDefaults._isDelegateActive && (
              <MessageBar
                styles={{ root: { height: 30, width: 90, borderRadius: 5 } }}
                messageBarType={MessageBarType.success}
              >
                Active
              </MessageBar>
            )}
          </Stack>

          <Stack>
            <AdvancedFormikDropdown
              placeholder="Select approver"
              options={options}
              name={`${formikNameInitial}.delegateUserProfileId`}
              onRenderOption={renderOption}
            />
          </Stack>
          {
            showApproverDateTime &&
            <Stack tokens={{ childrenGap: 20 }}>
              <Stack tokens={{ childrenGap: 5 }}>
                <Text className={commonStyles.bold}>From</Text>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Stack.Item grow={1}>
                    <FormikDatePicker
                      name={`${formikNameInitial}.delegateStartDate`}
                    />
                  </Stack.Item>
                  <FormikTimePicker
                    name={`${formikNameInitial}.delegateStartTime`}
                  />
                </Stack>
              </Stack>

              <Stack tokens={{ childrenGap: 5 }}>
                <Text className={commonStyles.bold}>To</Text>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Stack.Item grow={1}>
                    <FormikDatePicker
                      name={`${formikNameInitial}.delegateEndDate`}
                    />
                  </Stack.Item>

                  <FormikTimePicker
                    name={`${formikNameInitial}.delegateEndTime`}
                  />
                </Stack>
              </Stack>
            </Stack>
          }
        </>
      )}
    </Stack>
  );
};
