import {
  ComboBox,
  IComboBoxStyles,
  IMaskedTextField,
  IMaskedTextFieldProps,
  ITimePickerProps,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import React, { useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
const comboBoxStyles: Partial<IComboBoxStyles> = { root: { width: 65 } };
export type FormHookTimePickerProps = Omit<
  ITimePickerProps,
  'value' | 'onChange'
> & {
  onTimeChange?: (timeValue: string | null) => void;
};

export const FormHookTimePicker: React.FC<
  RequiredNameProps<FormHookTimePickerProps & IMaskedTextFieldProps>
> = ({ onTimeChange, ...props }) => {
  const { name } = { ...props };
  const {
    field: { value, onChange },
  } = useController({ name });
  const minRef = useRef<IMaskedTextField | undefined>(null);
  const hourRef = useRef<IMaskedTextField | undefined>(null);
  const [hourState, setHourState] = useState<string | null>(null);
  const [minuteState, setMinuteState] = useState<string | null>(null);
  const [meridianState, setMeridianState] = useState<'am' | 'pm'>('am');
  const renderRef = useRef<boolean>(true);

  const onHourChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: string | undefined
  ) => {
    const re = /^([1-9]|1[012])$/;
    if (!!newValue) {
      if (re.test(newValue)) {
        setHourState(newValue);
        if (newValue.length === 2) minRef.current?.focus();
      } else {
        setHourState(null);
      }
    } else {
      setHourState(null);
    }
  };

  const onMinuteChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: string | undefined
  ) => {
    if (newValue) {
      if (newValue.length <= 2 && parseInt(newValue) <= 59) {
        setMinuteState(newValue);
      }
    } else {
      setMinuteState(null);
      hourRef.current?.focus();
    }
  };

  useEffect(() => {
    if (!renderRef.current) {
      const additional = meridianState === 'am' ? 0 : 12;
      const hours = (parseInt(hourState || '0') % 12) + additional; // Use modulo operator to handle switching between AM and PM
      const mins = parseInt(minuteState || '0');
      const minutes =
        mins !== 0 ? (mins < 10 ? '0' + mins.toString() : minuteState) : '00';
      const timeValue = `${
        hours < 10 ? '0' + hours.toString() : hours
      }:${minutes}:00`;

      onChange(timeValue);
      onTimeChange?.(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourState, minuteState, meridianState, onChange]);

  useEffect(() => {
    if (!!value) {
      const [hourString, minute] = value.split(':');
      const hour = +hourString % 24;
      // return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "am" : "pm");
      setHourState((hour % 12 || 12).toString());
      setMinuteState(minute);
      setMeridianState(hour < 12 ? 'am' : 'pm');
      renderRef.current = false;
    } else {
      renderRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hours = value ? hourState || '' : '';
  const minutes = value ? minuteState || '' : '';
  const meridian = value ? meridianState || '' : '';

  return (
    <Stack horizontal verticalAlign="end">
      <TextField
        underlined
        placeholder="HH"
        value={hours}
        // @ts-ignore
        componentRef={hourRef}
        onChange={onHourChange}
        styles={{
          fieldGroup: {
            width: 40,
          },
        }}
        disabled={props.disabled}
      />
      <Text variant="xLarge" styles={{ root: { marginBottom: 5 } }}>
        :
      </Text>

      <TextField
        underlined
        onChange={onMinuteChange}
        value={minutes}
        // @ts-ignore
        componentRef={minRef}
        placeholder="MM"
        styles={{
          fieldGroup: {
            width: 50,
          },
        }}
        disabled={props.disabled}
      />
      <ComboBox
        selectedKey={meridian}
        onChange={(_, option) => {
          // @ts-ignore
          if (option?.key) setMeridianState(option?.key || 'am');
        }}
        options={[
          { key: 'am', text: 'AM' },
          { key: 'pm', text: 'PM' },
        ]}
        styles={comboBoxStyles}
        autoComplete="on"
        disabled={props.disabled}
      />
    </Stack>
  );
};
