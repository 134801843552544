import {
  DialogFooter,
  DialogType,
  IDialogProps,
  PrimaryButton
} from '@fluentui/react';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import React, { ReactNode } from 'react';
import { DialogWrapper } from '../DialogWrapper';

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};

interface AlertDialogProps extends IDialogProps {
  hidden: boolean;

  onConfirm: () => void;
  children?: ReactNode;
  title: string;
  type?: DialogType;
  subText?: string;
  minWidth?: number;
  isDraggable?: boolean;
  confirmText?: string | undefined;
}
export const AlertDialog: React.FC<AlertDialogProps> = ({
  onConfirm,
  children,
  title,
  type = DialogType.largeHeader,
  subText,
  minWidth,
  
  isDraggable,
  confirmText,
  ...props
}) => {

  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable]
  );

  return (
    <DialogWrapper
      dialogContentProps={{
        title: title,
        type: type,
        subText: subText,
      }}
      minWidth={minWidth}
      modalProps={modalProps}
      {...props}
    >
      {children}
      <DialogFooter>
        <PrimaryButton text="OK" onClick={onConfirm} />
      </DialogFooter>
    </DialogWrapper>
  );
};
