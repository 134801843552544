import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: { height: '100%' },
  root: {
    border: `1px solid ${theme.palette.neutralQuaternary}`,
    borderRadius: 4,
    padding: 24,
    margin: 16,
    maxWidth: 600,
  },
}));
