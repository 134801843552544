export const columnHeadings = [
  {
    name: "",
    width: 50,
  },
  {
    name: "Sequence",
    width: 90,
  },
  {
    name: "Date",
    width: 150,
  },
  {
    name: "Note",
    width: 340,
  },
  {
    name: "Applied Invoice #",
    width: 172
  },
  {
    name: "Amount",
    width: 168,
  },
];