import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from 'app';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { BrowserRouter, Route, useHistory } from 'react-router-dom';
import { LoginWrapper } from 'login';
import { ApolloWrapper } from 'utility/ApolloWrapper';
import FluentUITheme from 'theme';
import { initializeIcons } from '@fluentui/react';
import {
  DefaultToastContainer,
  ToastProvider,
} from 'react-toast-notifications';
import { QueryParamProvider } from 'use-query-params';
import { registerIcons } from '@fluentui/react/lib/Styling';
import { ReactComponent as StampIcon } from './assets/svgs/ApproveStatusIcons/Stamp.svg';
import { ReactComponent as IncognitoIcon } from 'assets/svgs/incognito_filled.svg';
import { loadConfig } from './config';
import { OpenApp } from 'common/components/OpenApp';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SentryBoundary } from 'error/SentryBoundary';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { restorePath } from 'utility/redirect';

initializeIcons();
registerIcons({
  icons: {
    Stamp: <StampIcon style={{ width: 25, height: 30 }} />,
    StampSmall: <StampIcon style={{ width: 20, height: 18 }} />,
  },
});
registerIcons({
  icons: {
    Incognito: <IncognitoIcon style={{ width: 25, height: 30 }} />,
    IncognitoSmall: <IncognitoIcon style={{ width: 20, height: 18 }} />,
  },
});

loadConfig().then((config) => {
  if (!config) throw new Error('Configuration file not found');

  if (config.sentry) {
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.sentry.env,
      integrations: [new BrowserTracing()],
      tracesSampleRate: config.sentry.sampleRate ?? 0,
      beforeBreadcrumb: excludeGraphQLFetch,
      release: process.env.REACT_APP_BUILD_VERSION,
    });
  }

  const Auth0ProviderWithRedirect: React.FC<Auth0ProviderOptions> = (props) => {
    const history = useHistory();
    return (
      <Auth0Provider
        {...props}
        onRedirectCallback={(appState) => {
          const path = restorePath(appState);
          if (path) history.replace(path);
        }}
      />
    );
  };

  ReactDOM.render(
    <OpenApp>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Auth0ProviderWithRedirect
            domain={config.auth0.domain}
            clientId={config.auth0.clientId}
            redirectUri={window.location.origin}
            audience={config.auth0.audience}
          >
            <ApolloWrapper>
              <FluentUITheme>
                <ToastProvider
                  placement="bottom-left"
                  autoDismiss
                  components={{
                    ToastContainer: (props) => (
                      <DefaultToastContainer
                        {...props}
                        className="top-z-index"
                      />
                    ),
                  }}
                >
                  <SentryBoundary>
                    <LoginWrapper>
                      <App />
                    </LoginWrapper>
                  </SentryBoundary>
                </ToastProvider>
              </FluentUITheme>
            </ApolloWrapper>
          </Auth0ProviderWithRedirect>
        </QueryParamProvider>
      </BrowserRouter>
    </OpenApp>,
    document.getElementById('root')
  );
});
