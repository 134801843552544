import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => {
  return {
    textFieldStack: {
      marginTop: 15,
      marginBottom: 5,
    },
    textFieldUpperStack: {
      width: 500,
    },
    progressIndicatorStack: {
      width: '100%',
      height: 10,
    },
    footerStack: {
      alignItems: 'self-start',
      paddingTop: 40,
    },
    columnStack: {
      height: '100%',
      width: '100%',
    },
    fileReferenceCell: {
      columnGap: '15px',
    },
    columnHeight: {
      height: 25,
    },
    
  };
});
