import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    flex: 1,
    flexDirection: 'row',
  },
  onrenderColumnStack: {
    height: 25,
  },
  marginB200: {
    marginBottom: 200,
  },
  columnHeight: {
    height: 25,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  firstColumn: {
    display: 'block',
    paddingLeft: 15,
  },
  amount: {
    textAlign: 'right',
    display: 'block',
  },
  amountTotal: {
    fontWeight: 'bold',
    color: theme.palette.themePrimary,
  },
  iconBlueStyles: {
    selectors: {
      ':hover': {
        borderRadius: 2,
        backgroundColor: theme.palette.neutralLight,
      },
    },
  },
}));
