import { Stack, makeStyles } from '@fluentui/react';
import {
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import React from 'react';
import { CompanyChartOfAccounts } from '../__generated__/CompanyChartOfAccounts';
import { formatDropdownOptions } from 'common/utils';
const useStyles = makeStyles(() => ({
  container: {
    marginTop: 80,
  },
  fieldContainer: {
    flex: 1,
  },
  chartOfAccount: {
    width: '50%',
  },
}));

interface BasicFormProps {
  companyChartOfAccounts: CompanyChartOfAccounts | undefined;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  companyChartOfAccounts,
}) => {
  const styles = useStyles();

  const companyChartOfAccountsOptions = formatDropdownOptions(
    companyChartOfAccounts?.companyChartOfAccounts?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
    }
  );

  return (
    <Stack
      tokens={{
        padding: 20,
        childrenGap: 10,
      }}
      className={styles.container}
    >
      <Stack
        horizontal
        tokens={{
          childrenGap: 15,
        }}
      >
        <Stack className={styles.fieldContainer}>
          <FormHookTextField label="Business Unit" name="name" disabled />
        </Stack>
        <Stack className={styles.fieldContainer}>
          <FormHookTextField
            label="Production Office"
            name="emailProductionOfficeDescription"
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 15,
        }}
      >
        <Stack className={styles.fieldContainer}>
          <FormHookTextField label="Reply To Name" name="emailReplyName" />
        </Stack>
        <Stack className={styles.fieldContainer}>
          <FormHookTextField label="Email" name="emailReplyTo" />
        </Stack>
      </Stack>
      <Stack className={styles.chartOfAccount}>
        <FormHookDropdown
          label="Chart Of Accounts"
          placeholder="Select"
          options={companyChartOfAccountsOptions}
          name="chartOfAccountId"
        />
      </Stack>
    </Stack>
  );
};
