import React from 'react';
import {
  DefaultButton,
  IButtonStyles,
  IIconProps,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { getToggleStyles } from './utils';

interface ButtonVariantProps {
  toggled?: boolean;
  text?: string;
  iconName?: string;
  color?: string;
  onClick: () => void;
  disabled?: boolean;
}

// Chip Button
// This is a button that can be used as tags ( clickable / unclickable )
export const ToggleButton: React.FC<ButtonVariantProps> = ({
  toggled = true,
  text = 'Button',
  iconName = 'Attach',
  color,
  onClick,
  disabled,
}) => {
  const ICON: IIconProps = { iconName };
  const styles: IButtonStyles = getToggleStyles(color);
  return (
    <Stack>
      {toggled ? (
        <PrimaryButton
          onClick={onClick}
          text={text}
          iconProps={ICON}
          styles={styles}
        />
      ) : (
        <DefaultButton
          onClick={onClick}
          disabled={disabled}
          text={text}
          iconProps={ICON}
        />
      )}
    </Stack>
  );
};
