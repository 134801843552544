import React, { useState } from 'react';
import {
  DatePicker,
  DayOfWeek,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IButtonProps,
  IconButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { getConfirmButton, invalidActionMessage } from './utils';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { useStyles } from './index.styles';
import { EntityAction, EntityType } from 'common/types/utility';

interface RequestMessageModalProps {
  entityType: EntityType;
  action?: EntityAction;
  onConfirm: (date: string | null, comment: string | null) => Promise<void>;
  visible?: boolean;
  disabled?: boolean;
  multiple?: {
    validCount: number;
    invalidNames?: string[];
  };
  isLoading: boolean;
  buttonProps?: Omit<IButtonProps, 'onClick' | 'disabled'>;
}
export const RequestMessageModal: React.FC<RequestMessageModalProps> = ({
  entityType,
  action = EntityAction.Request,
  onConfirm,
  visible = true,
  disabled = false,
  multiple,
  isLoading,
  buttonProps,
}) => {
  const styles = useStyles();
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(true);
  const [requestComment, setRequestComment] = React.useState<
    string | undefined
  >();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const pastTenseAction = `${action.toLowerCase()}ed`;
  const subText = multiple
    ? `The selected ${entityType.toLowerCase()}${
        multiple.validCount > 1 ? `s (${multiple.validCount})` : ''
      } will be ${pastTenseAction}.`
    : `The current ${entityType.toLowerCase()} will be ${pastTenseAction}.`;
  const ConfirmButton = getConfirmButton(action);
  if (visible)
    return (
      <Stack horizontal>
        {visible && (
          <TooltipHost content={action} hidden={disabled}>
            {buttonProps ? (
              <PrimaryButton
                onClick={() => setDialogVisibility(!dialogVisibility)}
                disabled={disabled || (multiple && !multiple.validCount)}
                {...buttonProps}
              />
            ) : (
              <IconButton
                onClick={() => setDialogVisibility(!dialogVisibility)}
                iconProps={{ iconName: 'Delete' }}
                ariaLabel={action}
                disabled={disabled || (multiple && !multiple.validCount)}
                className={styles.iconButton}
              />
            )}
          </TooltipHost>
        )}
        {!dialogVisibility && (
          <Dialog
            hidden={false}
            onDismiss={() => setDialogVisibility(!dialogVisibility)}
            dialogContentProps={{
              title: `Are you sure?`,
              type: DialogType.largeHeader,
              subText: subText,
            }}
            minWidth={400}
          >
            <Stack tokens={{ childrenGap: 10, padding: '0px 0px 10px 0px' }}>
              <TextField
                multiline
                rows={3}
                value={requestComment}
                placeholder="Please write your comment here (optional)"
                resizable={false}
                onChange={(_event, value) => setRequestComment(value || '')}
              />
              <DatePicker
                minDate={new Date()}
                firstDayOfWeek={DayOfWeek.Sunday}
                placeholder="Required date (optional)"
                ariaLabel="Date"
                formatDate={onFormatDate}
                showWeekNumbers
                firstWeekOfYear={1}
                showMonthPickerAsOverlay
                showGoToToday={false}
                onSelectDate={(date) =>
                  setRequiredDate(dateFormat(date!.toString()))
                }
              />

              <Stack className={styles.progressContainer}>
                {isLoading && <ProgressIndicator />}
              </Stack>
            </Stack>

            {multiple &&
              multiple.invalidNames &&
              multiple.invalidNames.length > 0 && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                  {invalidActionMessage(multiple.invalidNames, entityType)}
                </MessageBar>
              )}
            <DialogFooter>
              <ConfirmButton
                onClick={async () => {
                  await onConfirm(requestComment!, requiredDate!);
                  setDialogVisibility(!dialogVisibility);
                  setRequestComment('');
                  setRequiredDate('');
                }}
                text={action}
              />
              <DefaultButton
                onClick={() => {
                  setRequestComment('');
                  setRequiredDate('');
                  setDialogVisibility(!dialogVisibility);
                }}
                text="Cancel"
              />
            </DialogFooter>
          </Dialog>
        )}
      </Stack>
    );
  else return null;
};
