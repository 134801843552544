import { TransactionLayout } from 'common/types/globalTypes';

export interface LabelParams {
  vendor: string;
  vendorPlaceHolder?: string | undefined;
  invoiceNumber?: string | undefined;
  invoiceDate?: string | undefined;
  invoiceTotal?: string | undefined;
  description?: string | undefined;
}

export const getLabelsAndPlaceholder = (
  transactionLayoutType: TransactionLayout | string | null | undefined,
  setIsPCardTransaction: (value: boolean) => void,
  setShowBankDetails: (value: boolean) => void
) => {
  switch (transactionLayoutType) {
    case TransactionLayout.AP_INVOICE:
      return {
        vendor: 'Vendor',
        invoiceNumber: 'Invoice Number',
        invoiceDate: 'Invoice Date',
        invoiceTotal: 'Invoice Total',
      };
    case TransactionLayout.PETTY_CASH:
      return {
        vendor: 'Employee',
        invoiceNumber: 'Envelope Number',
        invoiceDate: 'Envelope Date',
        invoiceTotal: 'Envelope Amount',
      };
    case TransactionLayout.PER_DIEM:
      return {
        vendor: 'Employee',
        invoiceNumber: 'Envelope Number',
        invoiceDate: 'Envelope Date',
        invoiceTotal: 'Envelope Amount',
      };
    case TransactionLayout.PR_INVOICE:
      return {
        vendor: 'Payroll Company',
        invoiceNumber: 'PR Invoice Number',
        invoiceDate: 'Invoice Date',
        invoiceTotal: 'Invoice Total',
      };
    case TransactionLayout.CREDIT_CARD_INVOICE:
      return {
        vendor: 'Card holder',
        vendorPlaceHolder: 'Name',
        invoiceNumber: 'Statement Number',
        invoiceDate: 'Date',
        invoiceTotal: 'Card Total',
      };
    case TransactionLayout.PURCHASE_ORDER:
      return {
        vendor: 'Supplier',
        invoiceNumber: 'PO Number',
        invoiceDate: 'Order Date',
        invoiceTotal: 'Order Total',
      };
    case TransactionLayout.JOURNAL_VOUCHER:
      return {
        vendor: 'Journal Type',
        invoiceNumber: 'Voucher Number',
        invoiceDate: 'Journal Date',
        invoiceTotal: 'Control Total (Debits)',
      };
    case TransactionLayout.TRAVEL_AUTHORIZATION:
      return {
        vendor: 'Passenger(s)',
        invoiceNumber: 'TA Number',
        invoiceDate: 'Transaction Date',
        invoiceTotal: 'Trip Total',
      };
    case TransactionLayout.WIRE_TRANSFER:
      return {
        vendor: 'Wire To (Name)',
        invoiceNumber: 'Wire Transfer Number',
        invoiceDate: 'Required By',
        invoiceTotal: 'Wire Total',
      };
    case TransactionLayout.PAYMENT_REQUEST:
      return {
        vendor: 'Pay To (Name)',
        invoiceNumber: 'Reference Number',
        invoiceDate: 'Payment Date',
        invoiceTotal: 'Payment Total',
      };
    case TransactionLayout.PAYMENT:
      return {
        vendor: 'Pay To (Name)',
        invoiceNumber: 'Reference/Cheque Number',
        invoiceDate: 'Payment Date',
        invoiceTotal: 'Payment Total',
      };
    case TransactionLayout.WIRE_TRANSFER_BANK_ACCOUNT:
      setShowBankDetails(true);
      return {
        vendor: 'Wire To (Name)',
        invoiceNumber: 'Bank Details',
        invoiceDate: 'Required By',
        invoiceTotal: 'Wire Total',
      };
    case TransactionLayout.PAY_CARD:
      setIsPCardTransaction(true);
      return {
        vendor: 'Vendor/Merchant',
        invoiceNumber: 'Reference Number',
        invoiceDate: 'Transaction Date',
        invoiceTotal: 'Card Total',
      };
    case TransactionLayout.PETTY_CASH_FUND:
      return {
        vendor: 'Employee',
        invoiceNumber: 'Envelope Number',
        invoiceDate: 'Envelope Date',
        invoiceTotal: 'Envelope Amount',
      };
    case TransactionLayout.SIGNING_DOCUMENT:
    case TransactionLayout.SIGNING_AMOUNT_DOCUMENT:
      return {
        vendor: 'Name',
        description: 'Contents',
      };
    case TransactionLayout.TIME_CARD:
      return {
        vendor: 'Employee',
        invoiceNumber: 'T/C Number',
        invoiceDate: 'W/E Date',
        invoiceTotal: 'Total',
      };
    default:
      return {
        vendor: 'Vendor',
        invoiceNumber: 'Invoice Number',
        invoiceDate: 'Invoice Date',
        invoiceTotal: 'Invoice Total',
      };
  }
};
