import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { AppliedEntityDocumentsOrderBy } from 'common/types/globalTypes';

type OrderLookup = {
  [direction in OrderDirection]: AppliedEntityDocumentsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'transactionType',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.TRANSACTION_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.TRANSACTION_TYPE_DESC,
      ],
    },
  ],
  [
    'accountingStampTransactionReference',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC,
      ],
    },
  ],

  [
    '_createdDate',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy._CREATED_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy._CREATED_DATE_DESC,
      ],
    },
  ],
  [
    'vendorReference',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.VENDOR_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.VENDOR_REFERENCE_DESC,
      ],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.DESCRIPTION_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.DESCRIPTION_DESC,
      ],
    },
  ],
  [
    'invoiceNumber',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.INVOICE_NUMBER_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.INVOICE_NUMBER_DESC,
      ],
    },
  ],
  [
    'invoiceDate',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.INVOICE_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.INVOICE_DATE_DESC,
      ],
    },
  ],
  [
    'controlTotalAmount',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.CONTROL_TOTAL_AMOUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.CONTROL_TOTAL_AMOUNT_DESC,
      ],
    },
  ],
  [
    'openingBalance',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.OPENING_BALANCE_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.OPENING_BALANCE_DESC,
      ],
    },
  ],
  [
    'appliedAmount',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.APPLIED_AMOUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.APPLIED_AMOUNT_DESC,
      ],
    },
  ],
  [
    'distributedAmount',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.DISTRIBUTED_AMOUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.DISTRIBUTED_AMOUNT_DESC,
      ],
    },
  ],
  [
    'differenceAmount',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.DIFFERENCE_AMOUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.DIFFERENCE_AMOUNT_DESC,
      ],
    },
  ],
  [
    'retiredAmount',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.RETIRED_AMOUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.RETIRED_AMOUNT_DESC,
      ],
    },
  ],
  [
    'remainingBalance',
    {
      [OrderDirection.ASC]: [
        AppliedEntityDocumentsOrderBy.REMAINING_BALANCE_ASC,
      ],
      [OrderDirection.DESC]: [
        AppliedEntityDocumentsOrderBy.REMAINING_BALANCE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    AppliedEntityDocumentsOrderBy.ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
    AppliedEntityDocumentsOrderBy.ACCOUNTING_STAMP_DATE_ASC,
  ];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), ...orderByArray];
};
