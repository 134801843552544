import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  flexHalf: {
    flex: 0.5,
  },
  status: {
    color: theme.palette.themePrimary,
    fontSize: FontSizes.size18,
  },
  fileListContainer: {
    margin: 20,
  },
  marginT10: {
    marginTop: 10,
  },
  disabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  approvalHistoryContainer: {
    margin: '20px 0',
    paddingLeft: 25,
    paddingRight: 25,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
  
}));
