import { EditCorporateAccountProps } from '..';
import { CORPORATE_ACCOUNT_VALUES } from './constants';
import { CorporateAccountValues } from './types';

type DefaultValueProps = Pick<EditCorporateAccountProps, 'corporateAccount'>;

export const getDefaultValues = (props: DefaultValueProps) => {
  const { corporateAccount } = { ...props };
  const {
    __typename,
    id,
    _isUpdatable,
    _isDeletable,
    _rowTimestamp,
    departmentGroup,
    travelAccountType,
    ...corporateAccountFields
  } = {
    ...corporateAccount,
  };
  let defaultValues: CorporateAccountValues = CORPORATE_ACCOUNT_VALUES;
  if (!!id) defaultValues = { ...corporateAccountFields };
  return defaultValues;
};
