import { Stack, Text } from '@fluentui/react';
import { PurchaseOrder_purchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { PurchaseOrderCommonData } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import React from 'react';
import { PurchaseOrderItemProps } from '../BasicForm';
import { ItemsList } from './ItemsList';
import { POItemsModal } from './POItemsModal';
import { useStyles } from './index.styles';

export type PurchaseOrderType = Pick<
  PurchaseOrder_purchaseOrder,
  | '_isUpdatable'
  | 'id'
  | '_rowTimestamp'
  | '_subjectToTax'
  | '_subjectToDiscount'
  | 'purchaseOrderNumber'
  | 'purchaseOrderTypeId'
  | '_isDistributionUpdateAllowed'
  | 'purchaseOrderItems'
  | 'currency'
  | 'businessUnit'
>;

export interface PurchaseOrderDataType {
  purchaseOrder: PurchaseOrderType | undefined | null;
}

interface POItemsProps {
  inputsDisabled: boolean;
  commonData: PurchaseOrderCommonData | undefined;
  purchaseOrderItemAvailable: PurchaseOrderItemProps[] | undefined;
  purchaseOrderData: PurchaseOrderDataType | undefined;
  _isProductionBusinessUnit: boolean | null | undefined;
}
export const POItems: React.FC<POItemsProps> = ({
  commonData,
  inputsDisabled,
  purchaseOrderItemAvailable,
  purchaseOrderData,
}) => {
  const styles = useStyles();
  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Stack
        className={styles.container}
        horizontal
        tokens={{ childrenGap: 20 }}
        // horizontalAlign="space-between"
      >
        <Text variant="xLarge">Order Items</Text>
        <POItemsModal
          purchaseOrderData={purchaseOrderData}
          commonData={commonData}
          inputsDisabled={inputsDisabled}
          purchaseOrderItemAvailable={purchaseOrderItemAvailable}
        />
      </Stack>
      <ItemsList
        inputsDisabled={inputsDisabled}
        purchaseOrderData={purchaseOrderData}
        commonData={commonData}
        purchaseOrderItemAvailable={purchaseOrderItemAvailable}
      />
    </Stack>
  );
};
