import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  Link,
  Modal,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { columns } from './column.data';
import { useStyles } from './index.styles';
interface BalancesProps {
  data: BalanceProps[];
}
interface BalanceProps {
  currency: string | undefined;
  balance: string | undefined;
}
export const BalancesListView: React.FC<BalancesProps> = ({ data }) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
  const renderColumn = (
    item?: BalanceProps,
    _index?: number,
    column?: IColumn
  ) => {
    if (!item || !column) return undefined;
    switch (column.key) {
      case 'currency':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text className={styles.currency}>{item.currency}</Text>
          </Stack>
        );
      case 'balance':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text className={styles.amount}>{item.balance}</Text>
          </Stack>
        );
    }
  };
  return (
    <Stack>
      <Link onClick={toggleHideDialog}>
        <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
          Balances
        </Text>
      </Link>
      <Modal isOpen={hideDialog} onDismiss={toggleHideDialog}>
        <Stack className={styles.container}>
          <Stack horizontal className={styles.header}>
            <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
              Balances
            </Text>
            <IconButton
              onClick={toggleHideDialog}
              iconProps={{ iconName: 'Cancel' }}
              className={styles.iconButtonColor}
              ariaLabel="Expand"
            />
          </Stack>
          <DetailsList
            compact
            columns={columns}
            items={data!}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            onRenderItemColumn={renderColumn}
          />
        </Stack>
      </Modal>
    </Stack>
  );
};
