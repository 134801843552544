import {
  ContextualMenu,
  DefaultButton,
  IDragOptions,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  makeStyles,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Header } from './Header';
import { useMutation } from '@apollo/client';
import { AssignCorporateAccountsInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { CorporateAccountAutoComplete } from 'settings/account/accountsEntry/components/CorporateAccountAutoComplete';
import { AccountAutoComplete } from '../../../components/AccountAutoComplete';
import { ChartOfAccounts_chartOfAccounts_nodes } from '../../chartAccount/__generated__/ChartOfAccounts';
import {
  AssignCorporateAccounts,
  AssignCorporateAccountsVariables,
} from './__generated__/AssignCorporateAccounts';
const ASSIGN_CORPORATE_ACCOUNTS = loader('./AssignCorporateAccounts.graphql');
const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: 600,
    maxHeight: 600,
  },
  formContainer: {
    marginTop: 80,
    marginBottom: 100,
  },
  fieldContainer: {
    flex: 1,
  },
  footerContainer: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
    padding: '10px 20px',
    height: 84,
  },
  progressContainer: {
    height: 12,
  },
}));

interface AssignmentModalProps {
  onClose: () => void;
  chartOfAccount: ChartOfAccounts_chartOfAccounts_nodes | null;
  onRefetch: () => void;
}

export const AssignmentModal: React.FC<AssignmentModalProps> = ({
  onClose,
  onRefetch,
  chartOfAccount,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const accountCalloutId = useId(`cropAccountId`);
  const startAccountCalloutId = useId(`startAccountId`);
  const endAccountCalloutId = useId(`endAccountId`);
  const [corporateAccountId, setCorporateAccountId] = useState<string>();
  const [startingAccountId, setStartingAccountId] = useState<string>();
  const [endingAccountId, setEndingAccountId] = useState<string>();

  const [assignCorporateAccounts, { loading }] = useMutation<
    AssignCorporateAccounts,
    AssignCorporateAccountsVariables
  >(ASSIGN_CORPORATE_ACCOUNTS, { errorPolicy: 'all' });

  const onSubmit = async () => {
    if (!!corporateAccountId && !!startingAccountId && !!endingAccountId) {
      const { data, errors } = await assignCorporateAccounts({
        variables: {
          input: {
            corporateAccountId,
            startingAccountId,
            endingAccountId,
          } as AssignCorporateAccountsInput,
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        onRefetch();
        addToast(
          `${data?.assignCorporateAccounts?.assignedCorporateAccounts} corporate accounts assigned.`,
          {
            appearance: 'success',
          }
        );
        setCorporateAccountId(undefined);
        setStartingAccountId(undefined);
        setEndingAccountId(undefined);
        onClose();
      }
    }
  };

  const isDisabled = !(
    !!corporateAccountId &&
    !!startingAccountId &&
    !!endingAccountId
  );

  return (
    <Modal isOpen isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        <Header onClose={onClose} />
        <Stack
          tokens={{
            padding: 20,
            childrenGap: 20,
          }}
          className={styles.formContainer}
        >
          <CorporateAccountAutoComplete
            chartOfAccountId={chartOfAccount?.corporateChartOfAccountId!}
            label="Corporate Account"
            id={accountCalloutId}
            onAccountSelect={(account) => {
              if (account) {
                const { id } = { ...account };
                setCorporateAccountId(id);
              } else setCorporateAccountId(undefined);
            }}
            required
          />
          <Stack tokens={{ childrenGap: 10 }}>
            <Text variant="large">Select the range of project accounts:</Text>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <Stack className={styles.fieldContainer}>
                <AccountAutoComplete
                  label="Starting Account"
                  id={startAccountCalloutId}
                  chartOfAccount={chartOfAccount}
                  onAccountSelect={(account) => {
                    if (account) {
                      const { id } = { ...account };
                      setStartingAccountId(id);
                    } else setStartingAccountId(undefined);
                  }}
                  required
                />
              </Stack>
              <Stack className={styles.fieldContainer}>
                <AccountAutoComplete
                  label="Ending Account"
                  id={endAccountCalloutId}
                  chartOfAccount={chartOfAccount}
                  onAccountSelect={(account) => {
                    if (account) {
                      const { id } = { ...account };
                      setEndingAccountId(id);
                    } else setEndingAccountId(undefined);
                  }}
                  required
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack className={styles.footerContainer}>
          <Stack tokens={{ childrenGap: 10 }}>
            <Stack className={styles.progressContainer}>
              {loading && <ProgressIndicator />}
            </Stack>
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{
                childrenGap: 20,
              }}
            >
              <PrimaryButton
                text="Assign"
                onClick={onSubmit}
                disabled={isDisabled}
              />
              <DefaultButton text="Cancel" onClick={onClose} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
