import { useMutation } from '@apollo/client';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { HeaderProps } from '..';
import { ChartOfAccountDelete, ChartOfAccountDeleteVariables } from './__generated__/ChartOfAccountDelete';
const CHART_OF_ACCOUNT_DELETE = loader('./ChartOfAccountDelete.graphql');
const CHART_ACCOUNTING = loader('../../../../../list/chartAccount/ChartOfAccounts.graphql');

type DeleteChartsOfAccountsProps = HeaderProps

export const DeleteChartsOfAccounts: React.FC<DeleteChartsOfAccountsProps> = ({
  isEdit,
  data,
  onClose,
}) => {
  const { addToast } = useToasts()
  const {
    id,
    _rowTimestamp,
    _isDeletable,
  } = { ...data?.chartOfAccount }

  const [deleteChartOfAccount] = useMutation<
    ChartOfAccountDelete,
    ChartOfAccountDeleteVariables
  >(CHART_OF_ACCOUNT_DELETE);

  const onConfirm = async () => {
    if (!!id && !!_rowTimestamp) {
      const { errors } = await deleteChartOfAccount({
        variables: {
          input: {
            entityDelete: [
              {
                id,
                rowTimestamp: _rowTimestamp,
              },
            ],
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: CHART_ACCOUNTING
        }]
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Record Deleted Successfully', {
          appearance: 'success',
        });
        onClose()
      }
    }
  };

  if (!isEdit && !_isDeletable) return null;

  return (
    <>
      <ActionMessageModal
        visible={isEdit}
        entityType={EntityType.ChartOfAccount}
        disabled={!_isDeletable}
        onConfirm={onConfirm}
      />
    </>
  )
}
