import React from 'react';
import { Link, ILinkProps } from '@fluentui/react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './index.styles';
import { setUserDefaults } from 'utility/cache/ui';
import { useReactiveVar } from '@apollo/client';

interface ActiveLinkProps {
  to: string;
  state?: any;
  onLinkClick?: () => void;
}

export const ActiveLink: React.FC<ILinkProps & ActiveLinkProps> = ({
  children,
  to,
  className,
  state,
  onLinkClick,
  ...props
}) => {
  const history = useHistory();
  const match = useRouteMatch(to);
  const styles = useStyles();
const userDefaults = useReactiveVar(setUserDefaults);
const isSelectedRowBold = !userDefaults?.isRowSelectorBold;
  return (
    <Link
      className={clsx(
        styles.root,
        isSelectedRowBold
          ? !!match
            ? styles.linkBold
            : styles.link
          : !!match
          ? styles.link
          : styles.linkBold,
        className
      )}
      onClick={() => {
        onLinkClick?.();
        history.push(to, state);
      }}
      {...props}
    >
      {children}
    </Link>
  );
};
