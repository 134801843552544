import {
  NetworkStatus,
  useLazyQuery,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { IColumn, IDetailsRowBaseProps, Stack, Text } from '@fluentui/react';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { StatusIcon } from 'common/components/StatusIcon';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  TripBreakdownFilter,
  TripBreakdownsOrderBy,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  globalMode,
  setCurrentProfile,
  setGlobalSearchText,
  setUserDefaults,
} from 'utility/cache/ui';
import { TripBreakdownView } from '../view';
import { ExportTrips } from './ExportTrips';
import OverBudget from './OverBudget';
import { SearchBarSection } from './SearchBarSection';
import {
  TripBreakdown,
  TripBreakdownVariables,
} from './__generated__/TripBreakdown';
import {
  TripBreakdownSearch,
  TripBreakdownSearchVariables,
  TripBreakdownSearch_tripBreakdownSearch_nodes,
} from './__generated__/TripBreakdownSearch';
import { getColumns } from './columns.data';
import { useStyles } from './index.styles';
import { toOrderByVariable, toTripBreakdownFilterVariable } from './utils';
const TRIP_BREAKDOWN_SEARCH = loader('./TripBreakdownSearch.graphql');
const TRIP_BREAKDOWN = loader('./TripBreakdown.graphql');

export type TripBreakdownItem = TripBreakdownSearch_tripBreakdownSearch_nodes;

export const TripBreakdownList: React.FC = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const globalState = useReactiveVar(globalMode);
  const globalSearchText = useReactiveVar(setGlobalSearchText);

  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });
  const currentProfileData = useReactiveVar(setCurrentProfile);
  const useDefaultsData = useReactiveVar(setUserDefaults);

  const { t: searchParam } = urlParameters;
  const columns = getColumns(currentProfileData, useDefaultsData);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [searchTextValue, setSearchTextValue] = useState<string | null>(
    searchParam! || null
  );
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [selectedRows, setSelectedRows] = useState<TripBreakdownItem[]>([]);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();

  const { data, networkStatus, loading, fetchMore, refetch } = useQuery<
    TripBreakdownSearch,
    TripBreakdownSearchVariables
  >(TRIP_BREAKDOWN_SEARCH, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [
        TripBreakdownsOrderBy.TRAVEL_AUTHORIZATION_NUMBER_DESC,
        TripBreakdownsOrderBy.PRIMARY_KEY_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [getTrip] = useLazyQuery<TripBreakdown, TripBreakdownVariables>(
    TRIP_BREAKDOWN,
    { fetchPolicy: 'network-only' }
  );
  const { nodes, pageInfo } = { ...data?.tripBreakdownSearch };

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const transformedData = refetching
    ? undefined
    : nodes?.map(
        (item) =>
          ({
            ...item,
            travelAuthorizationDate: item?.travelAuthorizationDate
              ? dateFormat(dateConvertions(item?.travelAuthorizationDate))
              : null,
          } as TripBreakdownItem)
      );

  const handleSearchGlobal = (
    showMore: boolean,
    newHints: boolean,
    globalValue: boolean
  ) => {
    const filter = searchFilters?.length
      ? ({
          and: toTripBreakdownFilterVariable(searchFilters),
        } as TripBreakdownFilter)
      : undefined;
    const searchRequest = searchTextValue
      ? {
          searchText: [searchTextValue ? searchTextValue : ''],
        }
      : undefined;
    const searchQueryParam =
      searchTextValue && searchTextValue.length > 0
        ? searchTextValue
        : undefined;
    const variables: TripBreakdownSearchVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? data?.tripBreakdownSearch?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
      filter: filter,
      searchRequest: searchRequest,
      isLinkSearch: globalValue,
    };

    setUrlParameters({ t: searchQueryParam }, 'replace');
    if (showMore) fetchMore?.({ variables });
    else refetch(variables);
  };

  const handleSearch = (showMore: boolean, newHints: boolean) =>
    handleSearchGlobal(showMore, newHints, globalState);

  const handleSearchMemo = useCallback(handleSearch, [
    searchTextValue,
    sortOrderParam,
    searchFilters,
  ]);

  useEffect(() => {
    handleSearchMemo(false, true);
  }, [handleSearchMemo, searchTextValue, sortOrderParam, searchFilters]);

  const _renderItemColumn = (
    item: TripBreakdownItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof TripBreakdownItem
      ] as string;

      switch (column?.key) {
        case 'tripLocator':
          return (
            <TripBreakdownView
              fieldContent={fieldContent}
              globalSearchText={globalSearchText}
              item={item}
              onTripUpdate={() => {
                getTrip({ variables: { id: item.id } });
              }}
            />
          );

        case 'travelAuthorizationNumber':
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              <HighLightActiveLink
                key={item?.id}
                to={`/ta/travel-plan/travel/${item?.travelAuthorization?.id}`}
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );
        case '_spotCurrencyAmount':
        case '_baseCurrencyAmount':
        case '_companionAmountUsed':
          return (
            <AmountColumnTextView
              value={fieldContent}
              searchText={globalSearchText}
            />
          );

        case '_isCorporateTravelAuthorization':
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={fieldContent ? 'Corporate' : 'Production'}
              />
            </Stack>
          );
        case '_companionTicketUsed':
          return (
            <AmountColumnTextView
              value={fieldContent?.toString()}
              searchText={globalSearchText}
              isDecimal={false}
            />
          );
        case 'incidentalAmount':
          return (
            <OverBudget
              isAmountExist={item._isIncidentalExist}
              amount={item.incidentalAmount}
              budgetAmount={item.incidentalBudgetAmount}
              globalSearchText={globalSearchText!}
              isOverBudget={item._isIncidentalOverBudget}
            />
          );
        case 'trainAmount':
          return (
            <OverBudget
              isAmountExist={item._isTrainExist}
              amount={item.trainAmount}
              budgetAmount={item.trainBudgetAmount}
              globalSearchText={globalSearchText!}
              isOverBudget={item._isTrainOverBudget}
            />
          );
        case 'transportationAmount':
          return (
            <OverBudget
              isAmountExist={item._isTransportationExist}
              amount={item.transportationAmount}
              budgetAmount={item.transportationBudgetAmount}
              globalSearchText={globalSearchText!}
              isOverBudget={item._isTransportationOverBudget}
            />
          );

        case 'vehicleRentalAmount':
          return (
            <OverBudget
              isAmountExist={item._isVehicleRentalExist}
              amount={item.vehicleRentalAmount}
              budgetAmount={item.vehicleRentalBudgetAmount}
              globalSearchText={globalSearchText!}
              isOverBudget={item._isVehicleRentalOverBudget}
            />
          );
        case 'accommodationAmount':
          return (
            <OverBudget
              isAmountExist={item._isAccommodationExist}
              amount={item.accommodationAmount}
              budgetAmount={item.accommodationBudgetAmount}
              globalSearchText={globalSearchText!}
              isOverBudget={item._isAccommodationOverBudget}
            />
          );

        case 'airfareAmount':
          return (
            <OverBudget
              isAmountExist={item._isAirfareExist}
              amount={item.airfareAmount}
              budgetAmount={item.airfareBudgetAmount}
              globalSearchText={globalSearchText!}
              isOverBudget={item._isAirfareOverBudget}
            />
          );

        case 'controlTotalAmount':
          return (
            <OverBudget
              isAmountExist={
                !(
                  item.controlTotalAmount === null ||
                  item.controlTotalAmount === '0.00'
                )
              }
              amount={item.controlTotalAmount}
              budgetAmount={item.budgetAmount}
              globalSearchText={globalSearchText!}
              isOverBudget={item._isOverBudget}
            />
          );
        case '_taStatusType':
          return (
            <Stack horizontal verticalAlign="center">
              <HighlightTextView
                className={styles.statusType}
                highlightText={globalSearchText}
                text={convertToTitleCase(item?._taStatusType!)!}
              />
              <Stack>
                <StatusIcon
                  approval={false}
                  iconType={{ ...item.statusType }}
                />
              </Stack>
            </Stack>
          );
        default:
          return (
            <Stack verticalAlign="center" className={styles.rowContainer}>
              {!!fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);

        setSortOrderParam({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
      }
    },
    [gridColumns]
  );

  const { sum } = { ...data?.tripBreakdownSearch?.aggregates };
  const showFooter =
    !refetching &&
    sum &&
    Object.entries(sum).reduce((prev, [_, value]) => {
      return parseFloat(value || '0.0') > 0 || prev;
    }, false);

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const { aggregates } = {
        ...data?.tripBreakdownSearch,
      };

      let fieldContent;

      switch (column?.key) {
        case 'airfareAmount':
          fieldContent = aggregates?.sum?.airfareAmount;
          break;
        case 'accommodationAmount':
          fieldContent = aggregates?.sum?.accommodationAmount;
          break;
        case 'vehicleRentalAmount':
          fieldContent = aggregates?.sum?.vehicleRentalAmount;
          break;
        case 'transportationAmount':
          fieldContent = aggregates?.sum?.transportationAmount;
          break;
        case 'trainAmount':
          fieldContent = aggregates?.sum?.trainAmount;
          break;
        case 'incidentalAmount':
          fieldContent = aggregates?.sum?.incidentalAmount;
          break;
        case 'controlTotalAmount':
          fieldContent = aggregates?.sum?.controlTotalAmount;
          break;
        case '_companionAmountUsed':
          fieldContent = aggregates?.sum?._companionAmountUsed;
          break;
        case '_companionTicketUsed':
          fieldContent = aggregates?.sum?._companionTicketUsed;
          break;
        default:
          fieldContent = '';
      }

      return (
        <AmountTextView
          variant="medium"
          className={styles.footerTotal}
          value={fieldContent || ''}
        />
      );
    };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Trip Breakdown </Text>
          <Stack>
            <ExportTrips selectedRows={selectedRows} />
          </Stack>
        </Stack>

        <SearchBarSection
          urlParams={{
            searchParam: searchParam!,
          }}
          columns={gridColumns}
          onToggleVisibility={setGridColumns}
          onRefresh={() => {
            handleSearch(false, false);
          }}
          onFilterChange={setSearchFilters}
          onEnterPress={(value) => {
            setSearchTextValue(value);
            setGlobalSearchText(value);
          }}
          onGlobalToggle={(value) => {
            handleSearchGlobal(false, true, value);
          }}
        />
      </Stack>

      <InfiniteList
        items={transformedData || []}
        loading={loading}
        hasNextPage={pageInfo?.hasNextPage}
        showFooter={!!showFooter}
        columns={gridColumns.filter((column) => column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={_onColumnClick}
        // onLoadMore={async () => await handleSearch(true, false)}
        onSelectionChanged={setSelectedRows}
        // onColumnHeaderClick={_onColumnClick}
        onLoadMore={() => handleSearch(true, false)}
        // onSelectionChanged={setSelectedRows}
        // requestedIndices={requestedIndices}
      />
    </>
  );
};
