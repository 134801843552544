import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const leftAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    paddingLeft: 30,
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const useColumns = () => {
  const { isSm, isLg } = useGetScreenWidth();
  const columns: ColumnData[] = [
    {
      key: 'description',
      name: 'Cycle',
      fieldName: 'description',
      minWidth: 300,
      styles: leftAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: '_paymentType',
      name: 'Type',
      fieldName: '_paymentType',
      minWidth: 150,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: '_paymentAccount',
      name: 'Bank account',
      fieldName: '_paymentAccount',
      minWidth: 200,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'defaultPaymentDate',
      name: 'Payment Date',
      fieldName: 'defaultPaymentDate',
      minWidth: 200,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: 'currencyIsoCode',
      name: 'Currency',
      fieldName: 'currencyIsoCode',
      minWidth: 100,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: 'controlTotalAmount',
      name: 'Cycle Total',
      fieldName: 'controlTotalAmount',
      minWidth: 120,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },

    {
      key: '_totalPayments',
      name: 'Total Payments',
      fieldName: '_totalPayments',
      minWidth: 120,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: '_paymentCount',
      name: 'Payments',
      fieldName: '_paymentCount',
      minWidth: 70,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: '_accountingStampUserName',
      name: 'Enter By',
      fieldName: '_accountingStampUserName',
      minWidth: 50,
      maxWidth: 180,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_accountingStampDate',
      name: 'Stamped',
      fieldName: '_accountingStampDate',
      minWidth: 40,
      maxWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_accountingStampTransactionReference',
      name: 'Transaction #',
      fieldName: '_accountingStampTransactionReference',
      minWidth: 50,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 120,
      maxWidth: 120,
      ...commonColumnProps,
    },
  ];
  return { columns };
};
export { useColumns };
