import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  Stack,
} from '@fluentui/react';
import { DangerButton } from 'common/components/DangerButton';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TripItemProps } from '..';
import {
  TripDelete,
  TripDeleteVariables,
} from '../../__generated__/TripDelete';
import { TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals } from 'travelAuthorization/TravelPlan/list/__generated__/TravelAuthorizationSearchFilterTotals';

const DELETE = loader('../../TripDelete.graphql');
const dialogStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
  type: DialogType.normal,
  title: 'Warning',
  closeButtonAriaLabel: 'Close',
  subText: 'Are you sure you want to delete this trip?',
};

interface DeleteItemProps {
  inputsDisabled: boolean;
  item: TripItemProps;
  onSuccess?: () => void;
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
}
export const DeleteItem: React.FC<DeleteItemProps> = ({
  item,
  travelAuthorizationData,
  onSuccess,
  inputsDisabled,
}) => {
  const [visible, setVisible] = useState(false);
  const { addToast } = useToasts();
  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: dialogStyles,
    }),
    []
  );

  const [deleteItem] = useMutation<TripDelete, TripDeleteVariables>(DELETE, {
    errorPolicy: 'all',
  });

  const onDeleteClick = async () => {
    const { errors } = await deleteItem({
      variables: {
        input: {
          entityDelete: [
            {
              id: item.id,
              rowTimestamp: item._rowTimestamp!,
            },
          ],
        },
        id: travelAuthorizationData?.id!,
      },
      update: (cache, { data }) => {
        try {
          cache.modify({
            fields: {
              travelAuthorizationSearchFilterTotals(
                existing: TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals
              ) {
                if (existing?.totalCount1 !== 0) {
                  const controlTotalAmount =
                    Number(existing?.totalAmount1) -
                      Number(item?.controlTotalAmount) ?? 0;
                  const amount4 =
                    Number(existing?.totalAmount4) -
                      Number(item?.budgetAmount) ?? 0;
                  return {
                    ...existing,
                    totalAmount1: controlTotalAmount.toString(),
                    totalAmount4: amount4.toString(),
                    totalCount1: existing.totalCount1
                      ? existing.totalCount1 - 1
                      : 0,
                  };
                } else {
                  return {
                    ...existing,
                  };
                }
              },
            },
          });
        } catch (error) {}
      },
    });

    if (errors) addToast(errors?.[0].message, { appearance: 'error' });
    else {
      setVisible(false);
      addToast('Trip Item - Deleted Successfully', {
        appearance: 'success',
      });
      onSuccess?.();
    }
  };

  return (
    <Stack style={{ width: 34 }}>
      <IconButton
        disabled={inputsDisabled}
        iconProps={{ iconName: 'delete' }}
        onClick={() => setVisible(true)}
      />
      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <DangerButton onClick={onDeleteClick} text="Delete" />
            <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}
    </Stack>
  );
};
