import { FilterArrayType } from 'common/components/Filters';
import { UserProfilesOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: UserProfilesOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [UserProfilesOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [UserProfilesOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [UserProfilesOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [UserProfilesOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'emailAccount',
    {
      [OrderDirection.ASC]: [UserProfilesOrderBy.EMAIL_ACCOUNT_ASC],
      [OrderDirection.DESC]: [UserProfilesOrderBy.EMAIL_ACCOUNT_DESC],
    },
  ],
  [
    'roleName',
    {
      [OrderDirection.ASC]: [UserProfilesOrderBy.ROLE_BY_ROLE_ID__NAME_ASC],
      [OrderDirection.DESC]: [UserProfilesOrderBy.ROLE_BY_ROLE_ID__NAME_DESC],
    },
  ],
  [
    'occupation',
    {
      [OrderDirection.ASC]: [
        UserProfilesOrderBy.USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_ASC,
      ],
      [OrderDirection.DESC]: [
        UserProfilesOrderBy.USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_DESC,
      ],
    },
  ],
  [
    'departmentName',
    {
      [OrderDirection.ASC]: [
        UserProfilesOrderBy.DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        UserProfilesOrderBy.DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'accessPolicy',
    {
      [OrderDirection.ASC]: [
        UserProfilesOrderBy.DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        UserProfilesOrderBy.DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_DESC,
      ],
    },
  ],
  [
    'invited',
    {
      [OrderDirection.ASC]: [UserProfilesOrderBy.INVITED_ASC],
      [OrderDirection.DESC]: [UserProfilesOrderBy.INVITED_DESC],
    },
  ],
  [
    '_lastUsedDate',
    {
      [OrderDirection.ASC]: [UserProfilesOrderBy._LAST_USED_DATE_ASC],
      [OrderDirection.DESC]: [UserProfilesOrderBy._LAST_USED_DATE_DESC],
    },
  ],
  [
    'rank',
    {
      [OrderDirection.ASC]: [
        UserProfilesOrderBy.USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_ASC,
      ],
      [OrderDirection.DESC]: [
        UserProfilesOrderBy.USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_DESC,
      ],
    },
  ],
  [
    'securityLevel',
    {
      [OrderDirection.ASC]: [
        UserProfilesOrderBy.SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_ASC,
      ],
      [OrderDirection.DESC]: [
        UserProfilesOrderBy.SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_DESC,
      ],
    },
  ],
  [
    'inviteStatus',
    {
      [OrderDirection.ASC]: [
        UserProfilesOrderBy.USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__STATUS_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        UserProfilesOrderBy.USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__STATUS_TYPE_DESC,
      ],
    },
  ],
  [
    'approvalStatus',
    {
      [OrderDirection.ASC]: [
        UserProfilesOrderBy.USER_STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC,
      ],
      [OrderDirection.DESC]: [
        UserProfilesOrderBy.USER_STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [UserProfilesOrderBy.NAME_ASC];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const toPaymentFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'roleId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      case 'userOccupationTitleId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      case 'departmentId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      case 'dataAccessPolicyId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };

      case '_invitationStatusTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };

      case '_statusTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };

      default:
        return undefined;
    }
  });
