import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentStyles,
  Stack,
  makeStyles,
} from '@fluentui/react';
import React, { useState } from 'react';
import { MovementOrders } from '../../../__generated__/MovementOrders';
import { MovementOrderTraveler } from '../../Sidebar';
import { SuccessButton } from 'common/components/SuccessButton';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GenerateMovementOrders,
  GenerateMovementOrdersVariables,
} from './__generated__/GenerateMovementOrders';
import { useToasts } from 'react-toast-notifications';
const GENERATE_MOVEMENT_ORDERS = loader('./GenerateMovementOrders.graphql');

interface RebuildProps {
  data: MovementOrders | undefined;
  selectedTraveler: MovementOrderTraveler | undefined;
  travelerSet: Set<string>;
}
const dialogStyles: Partial<IDialogContentStyles> = {
  subText: {
    margin: 0
  }
}
export const Rebuild: React.FC<RebuildProps> = ({ ...props }) => {
  const [visible, setVisible] = useState(false);
  const { addToast } = useToasts();
  const [onGenerate] = useMutation<
    GenerateMovementOrders,
    GenerateMovementOrdersVariables
  >(GENERATE_MOVEMENT_ORDERS, { errorPolicy: 'all' });

  const onProceed = async () => {
    setVisible(false);
    const errors = await onGenerate({
      variables: {
        id: props.data?.travelAuthorization?.id!,
        input: {
          travelAuthorizationId: props.data?.travelAuthorization?.id!,
          movementTravelers: Array.from(props.travelerSet),
        },
      },
    });

    if (!!errors.errors?.length)
      addToast('Error while rebuilding movement orders', {
        appearance: 'error',
      });
    else
      addToast('Successfully Rebuilt movement orders', {
        appearance: 'success',
      });
  };
  if (!!Array.from(props.travelerSet).length)
    return (
      <Stack>
        <DefaultButton
          iconProps={{ iconName: 'Refresh' }}
          text="Rebuild"
          onClick={() => setVisible(true)}
        />
        <RebuildModal
          visible={visible}
          onCancel={() => setVisible(false)}
          onProceed={onProceed}
          {...props}
        />
      </Stack>
    );
  else return null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: 40,
  },
}));

const RebuildModal: React.FC<
  RebuildProps & {
    visible: boolean;
    onCancel: () => void;
    onProceed: () => void;
  }
> = ({ visible, onCancel, onProceed }) => {
  const styles = useStyles();
  if (visible)
    return (
      <Dialog
        hidden={false}
        dialogContentProps={{
          title: `Rebuild Movement Orders`,
          type: DialogType.largeHeader,
          subText: 'This will replace existing movement details with current trip information, for all selected travelers.',
          styles: dialogStyles
        }}
        minWidth={350}
      >
        <Stack className={styles.container}>
          <DialogFooter>
            <SuccessButton onClick={onProceed} text={'Rebuild'} />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </DialogFooter>
        </Stack>
      </Dialog>
    );
  else return null;
};
