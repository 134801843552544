import React, { useEffect, useState } from 'react';
import { Separator, Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import { TransactionLayout } from 'common/types/globalTypes';
import { DataView } from '../DataView';
import { AccountingEntryInvoice } from '../__generated__/AccountingEntryInvoice';
import { ResourceType } from './constants';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import clsx from 'clsx';

interface FormCarbonAccountingProps {
  invoiceDetails: AccountingEntryInvoice;
}

interface ResourceTypeIC {
  id: string;
  layoutType: TransactionLayout;
  environmentalMetricItems: any[];
}

export const FormCarbonAccount: React.FC<FormCarbonAccountingProps> = ({
  invoiceDetails,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [categoryName, setCategoryName] = React.useState<string | undefined>();
  const [carbonDescription, setCarbondescription] = useState<
    string | undefined
  >();
  const [selectedData, setSelectedData] = useState<ResourceTypeIC | null>();
  const [resourceColumn, setResourceColumn] = useState<any[]>([]);
  const [startdate, setStartdate] = useState<string | undefined>();
  const [enddate, setEndDate] = useState<string | undefined>();

  useEffect(() => {
    if (
      invoiceDetails &&
      invoiceDetails.invoice?.entityEnvironmentalsByEntityId.nodes.length
    ) {
      const data =
        invoiceDetails.invoice.entityEnvironmentalsByEntityId.nodes[0];
      setCategoryName(data.environmentalMetric?.environmentalMetric!);
      setCarbondescription(data.description || '');
      setStartdate(data.startDate!);
      setEndDate(data.endDate!);

      let tempArr = [];
      for (let j = 0; j < data.entityEnvironmentalItems.nodes.length; j++) {
        let invDetails = data.entityEnvironmentalItems.nodes[j];
        let obj = {
          environmentalImpact:
            invDetails.environmentalImpactType?.environmentalImpact,
          quantity: invDetails.quantity,
          gallons: invDetails.gallons,
          litres: invDetails.litres,
          feet: invDetails.feet,
          meters: invDetails.meters,
          yards: invDetails.yards,
          miles: invDetails.miles,
          kilometers: invDetails.kilometers,
          tons: invDetails.tons,
          metricTonnes: invDetails.metricTonnes,
          hours: invDetails.hours,
          kilowattHours: invDetails.kilowattHours,
          pounds: invDetails.pounds,
          kilogram: invDetails.kilogram,
          therms: invDetails.therms,
          omissionsEquivalent: invDetails.omissionsEquivalent,
          carbonCreditsAmount: invDetails.carbonCreditsAmount,
        };
        tempArr.push(obj);
      }

      let tempObj: ResourceTypeIC = {
        id: data.id,
        layoutType: data.environmentalMetric?.layoutType!,
        environmentalMetricItems: tempArr,
      };
      setSelectedData(tempObj);
    }
  }, [invoiceDetails]);

  useEffect(() => {
    ResourceType.map((type) => {
      if (Object.keys(type).toString() === selectedData?.layoutType) {
        let temp = Object.values(type);
        let tempArr: string[] = [];
        temp.map((item) => {
          if (item) {
            for (let i = 0; i < item.length; i++) {
              tempArr.push(item[i]);
            }
          }
          return true;
        });
        setResourceColumn(tempArr);
      }
      return true;
    });
  }, [selectedData]);

  return (
    <>
      <Stack className={styles.mainContainer} tokens={{ childrenGap: 20 }}>
        <Text variant="xLarge">Carbon Accounting</Text>
        <DataView title="Category" description={categoryName} />
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item grow={1}>
            <DataView
              title="From date"
              description={
                startdate ? dateFormat(dateConvertions(startdate)) : '-'
              }
            />
          </Stack.Item>
          <Separator vertical />
          <Stack.Item grow={1}>
            <DataView
              title="To date"
              description={enddate ? dateFormat(dateConvertions(enddate)) : '-'}
            />
          </Stack.Item>
        </Stack>
        <DataView title="Comment" description={carbonDescription} />
        {selectedData && selectedData.environmentalMetricItems.length > 0 && (
          <Stack horizontal>
            <Stack className={styles.resourceMainContainer}>
              <Text className={commonStyles.semibold}>Resource</Text>
              <Separator />
              <Stack className={styles.resourceColumnContainer}>
                {selectedData?.environmentalMetricItems.map((item, index) => {
                  return (
                    <Stack
                      key={index}
                      className={clsx(
                        styles.environmentalImpact,
                        index !== 0 && styles.marginTop5
                      )}
                    >
                      <Text className={commonStyles.semibold}>
                        {item.environmentalImpact}
                      </Text>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
            <Stack horizontal className={styles.resourceColumnMainContainer}>
              {resourceColumn.map((item, index) => {
                return (
                  <Stack
                    className={clsx(
                      index !== 0 && styles.marginLeft10,
                      styles.resouce
                    )}
                    key={index}
                  >
                    <Text className={styles.resourceType}>{item}</Text>
                    <Separator />
                    <Stack className={styles.resourceTypeDataMainContainer}>
                      {selectedData?.environmentalMetricItems.map(
                        (data, index) => {
                          return (
                            <Stack
                              key={index}
                              className={clsx(index !== 0 && styles.marginTop5)}
                            >
                              <DataView description={data[item]} />
                            </Stack>
                          );
                        }
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};
