import {
  ConstrainMode,
  IColumn,
  IconButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { InfiniteList } from 'common/components/InfiniteList';
import React, { useState } from 'react';
import { AllowanceViewModal } from '../../view/AllowanceView/AllowanceViewModal';
import {
  TravelPolicyAllowances_travelPolicy,
  TravelPolicyAllowances_travelPolicy_travelPolicyAllowancesByTravelPolicyId_nodes,
} from '../__generated__/TravelPolicyAllowances';
import { columns } from './column.data';
import { useStyles } from './index.styles'

export type TravelPolicyAllowance = Omit<
  TravelPolicyAllowances_travelPolicy_travelPolicyAllowancesByTravelPolicyId_nodes,
  'expenditureType' |
  'country1' |
  'country2' |
  'stateRegion1' |
  'stateRegion2'
> & {
  expenditureType: string | null | undefined;
  country1: string;
  country2: string;
  stateRegion1: string | null;
  stateRegion2: string | null;
};

interface AllowanceListProps {
  data: TravelPolicyAllowances_travelPolicy | null | undefined;
  loading: boolean;
  onAllowanceSelection: (selectedAllowances: TravelPolicyAllowance[]) => void;
}

export const AllowanceList: React.FC<AllowanceListProps> = ({
  data,
  loading,
  onAllowanceSelection,
}) => {
  const styles = useStyles();
  const [index, setIndex] = useState<number>(0);
  const [visible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const { nodes, totalCount } = {
    ...data?.travelPolicyAllowancesByTravelPolicyId,
  };
  const transformedData: TravelPolicyAllowance[] =
    nodes?.map((item) => ({
      ...item,
      country1: item.country1?.country || '',
      stateRegion1: item.stateRegion1?.stateRegion || null,
      country2: item.country2?.country || '',
      stateRegion2: item.stateRegion2?.stateRegion || null,
      expenditureType: item.expenditureType?.expenditureType,
    })) || [];

  const listHeight = (totalCount || 0) * 52 + 70;
  const sectionHeight =
    listHeight > 500 ? 500 : totalCount === 0 ? 150 : listHeight;

  const onEditClick = (index: number) => {
    setIndex(index);
    showModal();
  };

  const _renderItemColumn = (
    item: TravelPolicyAllowance | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof TravelPolicyAllowance
      ] as string;

      switch (column?.key) {
        case 'expenditureType':
          return (
            <Stack
              className={clsx(styles.row, styles.expenditureType)}
              tokens={{ padding: '0px 0px 0px 8px' }}
              verticalAlign="center"
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
        case 'policyAllowance':
          return (
            <Stack
              className={clsx(styles.row, styles.rightAlign)}
              tokens={{ padding: '0px 0px 0px 8px' }}
              verticalAlign="center"
            >
              <AmountTextView value={fieldContent} />
            </Stack>
          );
        case 'policyMiles':
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.row, styles.rightAlign)}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          )
        case 'isAllowanceCustomizable':
          const value = item.isAllowanceCustomizable ? 'Yes' : 'No';
          return (
            <Stack
              className={clsx(styles.row, styles.centerAlign)}
              verticalAlign="center"
            >
              <Text>{value}</Text>
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                disabled={!item._isUpdatable}
                onClick={() => onEditClick(_index!)}
              />
            </Stack>
          );
        default:
          return (
            <Stack verticalAlign="center" className={styles.row}>
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  return (
    <Stack>
      <Stack style={{ height: sectionHeight }}>
        {loading ? (
          <ProgressIndicator />
        ) : (
          <InfiniteList
            items={transformedData}
            loading={loading}
            hasNextPage={false}
            columns={columns}
            onRenderItemColumn={_renderItemColumn}
            onSelectionChanged={onAllowanceSelection}
            constrainMode={ConstrainMode.unconstrained}
          />
        )}
      </Stack>
      {visible && (
        <AllowanceViewModal
          travelPolicy={data!}
          isNew={false}
          index={index}
          loading={loading}
          hideModal={hideModal}
        />
      )}
    </Stack>
  );
};
