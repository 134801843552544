import {
  ActionButton, Stack, TooltipHost
} from '@fluentui/react';
import { SupplierDetails_supplier } from 'purchaseOrder/view/FormView/__generated__/SupplierDetails';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import React, {
  useState
} from 'react';
import { useFormContext } from 'react-hook-form';
import { SupplierAvailableDocumentTypes } from 'settings/account/supplier/view/ActionMenu/SupplierAttach/__generated__/SupplierAvailableDocumentTypes';
import { AttachFormModal } from './AttachFormModal';
export interface AttachFormProps {
  availableDocumentTypes: SupplierAvailableDocumentTypes | undefined;
  supplier: SupplierDetails_supplier;
}

export const AttachForm: React.FC<AttachFormProps> = ({ supplier, availableDocumentTypes }) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const {
    formState: {
      isDirty
    }
  } = useFormContext<PurchaseOrderValues>();

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={isDirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          supplier={supplier}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  );
};

