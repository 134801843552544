import { Stack } from '@fluentui/react';
import React from 'react';
import { HighlightTextView } from '../HighLight';
import { useStyles } from './index.styles';
import { getFormattedAmountValue } from './utils';

interface AmountColumnTextViewProps {
  searchText?: string;
  value: string | null;
  textColor?: string;
  isDecimal?: boolean;
}
export const AmountColumnTextView: React.FC<AmountColumnTextViewProps> = ({
  searchText,
  value,
  textColor,
  isDecimal = true,
}) => {
  const styles = useStyles();

  const formattedValue = isDecimal ? getFormattedAmountValue(value) : value;

  return (
    <Stack verticalAlign="center" className={styles.onrenderColumnStack}>
      <HighlightTextView
        className={styles.contentColumnAlignRight}
        highlightText={searchText!}
        text={formattedValue || ''}
        textColor={textColor}
      />
    </Stack>
  );
};
