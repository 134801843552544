import { IconButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { FormikTextField } from 'common/components';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
interface BankingProps {
  isEdit: boolean;
  isBankAccountVerified?: boolean | null;
  isUpdatable: boolean | null | undefined;
}
export const Banking: React.FC<BankingProps> = ({
  isEdit,
  isBankAccountVerified,
  isUpdatable
}) => {
  const styles = useStyles();
  const isDisabled = isUpdatable === false ? true : false;
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  return (
    <Stack
      className={styles.mainContainer}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        className={styles.heading}
      >
        <Text variant="xLarge">Banking</Text>
        <Stack className={styles.iconButtonContainer}>
          {
            viewDetails ?
              <IconButton
                iconProps={{ iconName: 'ChevronDown' }}
                className={styles.iconButtonColor}
                ariaLabel="Expand"
                onClick={() => {
                  setViewDetails(prevState => !prevState)
                }}
              /> :
              <IconButton
                iconProps={{ iconName: 'ChevronRight' }}
                className={styles.iconButtonColor}
                ariaLabel="Collapse"
                onClick={() => {
                  setViewDetails(prevState => !prevState);
                }}
              />
          }
        </Stack>
      </Stack>
      {
        viewDetails &&
        <>
          {
            isEdit && !isBankAccountVerified &&
            <Stack
              horizontalAlign="end"
              className={styles.verifyButtonContainer}
            >
              <PrimaryButton
                iconProps={{
                  iconName: 'CheckMark',
                  styles: {
                    root: {
                      fill: 'white',
                    },
                  },
                }}
                text="Account Verification"
                disabled={isDisabled}
              />
            </Stack>
          }
          <Stack
            horizontal
            tokens={{
              childrenGap: 20
            }}
            className={styles.formRow}
          >
            <Stack.Item
              className={styles.fieldContainer}
            >
              <FormikTextField
                name="bankAccountName"
                label="Account Name"
                placeholder="Account Name"
                disabled={isDisabled}
              />
            </Stack.Item>
            <Stack.Item
              className={styles.fieldContainer}
            >
              <FormikTextField
                name="bankRoutingNumber"
                label="Routing Number"
                placeholder="Routing Number"
                disabled={isDisabled}
              />
            </Stack.Item>
            <Stack.Item
              className={styles.fieldContainer}
            >
              <FormikTextField
                name="bankName"
                label="Bank Name"
                placeholder="Bank Name"
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20
            }}
            className={styles.formRow}
          >
            <Stack.Item
              className={styles.fieldContainer}
            >
              <FormikTextField
                name="bankSortCode"
                label="Sort Code"
                placeholder="Sort Code"
                disabled={isDisabled}
              />
            </Stack.Item>
            <Stack.Item
              className={styles.fieldContainer}
            >
              <FormikTextField
                name="bankIbanCode"
                label="IBAN (International)"
                placeholder="IBAN (International)"
                disabled={isDisabled}
              />
            </Stack.Item>
            <Stack.Item
              className={styles.fieldContainer}
            >
              <FormikTextField
                name="bankAccountNumber"
                label="Account Number"
                placeholder="Account Number"
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20
            }}
            className={styles.formRow}
          >
            <Stack.Item
              className={styles.swiftCodeContainer}
            >
              <FormikTextField
                name="bankSwiftCode"
                label="BIC / SWIFT Code"
                placeholder="BIC / SWIFT Code"
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
        </>
      }
    </Stack>
  )
}
