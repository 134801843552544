import { Stack, Text, makeStyles } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    cursor: 'move',
  },
}));

interface HeaderProps {
  onClose: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  onClose,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20
      }}
    >
      <Stack>
        <Text variant='xLarge' className={commonStyles.colorThemePrimary}>
          New Email
        </Text>
      </Stack>
      <CloseButton onClick={onClose} />
    </Stack>
  )
}
