import { useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import {
  DocumentDownloadLink,
  DocumentDownloadLinkVariables,
} from './__generated__/DocumentDownloadLink';

const DOCUMENT_DOWNLOAD_LINK = loader('./DocumentDownloadLink.graphql');

export default function useDownloadLink() {
  const client = useApolloClient();

  const getLink = useCallback(
    async (entityDocumentId: string) => {
      const res = await client.query<
        DocumentDownloadLink,
        DocumentDownloadLinkVariables
      >({
        query: DOCUMENT_DOWNLOAD_LINK,
        variables: { documentId: entityDocumentId },
        fetchPolicy: 'network-only',
      });

      return res.data.entityDocument?._downloadLink;
    },
    [client]
  );
  return getLink;
}
