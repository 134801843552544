import {
  DetailsListLayoutMode,
  SelectionMode,
  IRenderFunction,
  IDetailsFooterProps,
  DetailsRow,
  DetailsList,
  IColumn,
  Text,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React, { useState } from 'react';
import {
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId,
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes,
} from '../__generated__/userApproval';
import { columnsPayCycle } from './column.paycycles.data';
import { useStyles } from './index.styles';
import { StyledLink } from 'common/components/StyledLink';
import { PaymentModal } from './PaymentModal';

type ItemRow =
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes;

interface InvoiceAccountingListProps {
  data: userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId;
  isPaymentLink: boolean;
}

export const InvoicePaymentsList: React.FC<InvoiceAccountingListProps> = ({
  data,
  isPaymentLink,
}) => {
  const styles = useStyles();
  const [paymentId, setPaymentId] = useState<string | null>(null);

  const renderColumn = (item?: ItemRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;

    const fieldContent = item[column.fieldName as keyof ItemRow] as
      | string
      | null;

    switch (column?.key) {
      case 'itemDate1':
        return (
          <Text className={clsx(styles.amount)}>
            {fieldContent && dateFormat(dateConvertions(fieldContent))}
          </Text>
        );
      case 'amount':
        return (
          <AmountTextView
            className={clsx(styles.amount)}
            value={fieldContent}
          />
        );
      case 'itemDescription1':
        if (isPaymentLink)
          return (
            <StyledLink
              onLinkClick={() => setPaymentId(item.id1)}
              linkText={fieldContent}
            />
          );
        return <Text>{fieldContent}</Text>;

      default:
        return <Text>{fieldContent}</Text>;
    }
  };

  const renderFooterColumn = (
    _item?: ItemRow,
    _index?: number,
    column?: IColumn
  ) => {
    if (column?.key === 'amount' && data?.aggregates?.sum)
      return (
        <AmountTextView
          value={Number(data.aggregates.sum.itemAmount1).toFixed(2)}
          className={clsx(styles.amount, styles.amountTotal)}
        />
      );

    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !data.aggregates?.sum?.itemAmount1) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  return (
    <>
      <DetailsList
        compact
        columns={columnsPayCycle}
        items={data.nodes}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        onRenderItemColumn={renderColumn}
        onRenderDetailsFooter={renderFooter}
      />
      {paymentId && (
        <PaymentModal
          paymentId={paymentId}
          onClose={() => setPaymentId(null)}
        />
      )}
    </>
  );
};
