import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 600,
    paddingBottom: 100,
    paddingTop: 20,
  },
  columnCell:{
    height: 25
  },
  iconButtonColor: {
    color: theme.palette.neutralDark,
  },
  scheduleAmountTotal: {
    textAlign: "right",
    fontWeight: FontWeights.bold,
    color: theme.palette.themePrimary,
  },
  listFooter:{
    backgroundColor: theme.palette.neutralLighter
  }
}));
