import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  inputRow: {
    height: 86,
  },
  expenditureType: {
    height: '100%',
    width: '40%',
  },
  policyAllowance: {
    height: '100%',
    width: '33%',
  },
}));
