import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultButton,
  IDropdownOption,
  PrimaryButton,
  Separator,
  Spinner,
  Stack,
  Text,
} from '@fluentui/react';
import {
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { Countries } from 'common/graphql/__generated__/Countries';
import {
  CompanyAddressInput,
  CompanyAddressUpdateTypeInput,
  CompanyValidCurrencyInput,
  CompanyValidCurrencyUpdateTypeInput,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { CurrencyFormFields } from './CurrencyFormFields';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { ApprovalPhases } from './__generated__/ApprovalPhases';
import { Companies } from './__generated__/Companies';
import {
  CompanyUpdate,
  CompanyUpdateVariables,
} from './__generated__/CompanyUpdate';
import { CorporateWorkgroupsData } from './__generated__/CorporateWorkgroupsData';
import { DistributionLayoutTypes } from './__generated__/DistributionLayoutTypes';
import { TimeZones, TimeZonesVariables } from './__generated__/TimeZones';
import { useStyles } from './index.styles';
import { AddressRowValues, CompanyValues, CurrencyRowValues } from './types';
import { getDefaultValues } from './utils';
import { AddressForm } from './AddressForm';
import { Currencies } from './__generated__/Currencies';

const COMPANY_UPDATE = loader('./CompanyUpdate.graphql');
const COMPANY = loader('./Companies.graphql');
const COUNTRIES = loader('../../../common/graphql/Countries.graphql');
const TIME_ZONES = loader('./TimeZones.graphql');
const CORPORATE_GROUPS_DATA = loader('./CorporateGroupsData.graphql');
const DISTRIBUTION_LAYOUT_TYPES = loader('./DistributionLayoutTypes.graphql');
const APPROVAL_PHASE = loader('./ApprovalPhases.graphql');
const CURRENCY = loader('./Currencies.graphql');

export const CompanyMaintenance: React.FC = () => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const { data, loading: loadingCompanyData } = useQuery<Companies>(COMPANY);
  const [selectedCountryId, setSelectedCountryId] = useState<
    number | undefined
  >();
  const { id, _rowTimestamp, _isUpdatable } = { ...data?.companies?.nodes[0] };
  const formMethods = useForm<CompanyValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = { ...formMethods };

  // retrieve all hook methods
  interface OptionsType extends IDropdownOption {
    key: string | number;
    text: string;
  }
  const saveDisabled = !isDirty || !isValid;

  const onHandleSubmit = async (values: CompanyValues) => {
    //Array for new Addresses
    const newAddresses =
      values?.companyAddresses &&
      (values?.companyAddresses
        ?.filter((addr) => !addr.id && addr.addressLine1 !== null)
        .map(
          ({ id, _rowTimestamp, ...addr }) => addr
        ) as CompanyAddressInput[]);

    const newValidCurrencies =
      values?.companyValidCurrencies &&
      (values?.companyValidCurrencies
        ?.filter((curr) => !curr.id && curr.currencyId !== null)
        .map(
          ({
            id,
            _rowTimestamp,
            _isHomeCurrency,
            _isUpdatable,
            _isDeletable,
            ...curr
          }) => curr
        ) as CompanyValidCurrencyInput[]);

    const deletedAddressRows = differenceBy(
      data?.companies?.nodes[0]?.companyAddressesByEntityId?.nodes,
      values?.companyAddresses || [],
      'id'
    ).map((addr) => ({
      id: addr.id!,
      rowTimestamp: addr._rowTimestamp!,
    })) as EntityDeleteInput[];

    const deletedValidCurrencyRows = differenceBy(
      data?.companies?.nodes[0]?.companyValidCurrencies?.nodes,
      values?.companyValidCurrencies || [],
      'id'
    ).map((addr) => ({
      id: addr.id!,
      rowTimestamp: addr._rowTimestamp!,
    })) as EntityDeleteInput[];

    const updatedAddresses =
      data?.companies?.nodes[0]?.companyAddressesByEntityId?.nodes &&
      values?.companyAddresses &&
      intersection(
        data?.companies?.nodes[0]?.companyAddressesByEntityId?.nodes.map(
          (addr) => addr.id
        ),
        values?.companyAddresses
          .filter((addr) => addr.id)
          .map((addr) => addr.id)
      ).reduce((arr, targetId) => {
        const initialAddress =
          data?.companies?.nodes[0]?.companyAddressesByEntityId?.nodes!.find(
            (addr) => addr.id === targetId
          )!;
        const { id, _rowTimestamp, ...updatedAddress } =
          values?.companyAddresses!.find((addr) => addr.id === targetId)!;
        const patch = Object.entries(updatedAddress).reduce(
          (res, [key, val]) => {
            if (val !== initialAddress[key as keyof AddressRowValues])
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              companyAddressPatch: patch,
            },
          ];
        return arr;
      }, [] as CompanyAddressUpdateTypeInput[]);

    const updatedValidCurrencies =
      data?.companies?.nodes[0]?.companyValidCurrencies?.nodes &&
      values?.companyValidCurrencies &&
      intersection(
        data?.companies?.nodes[0]?.companyValidCurrencies?.nodes.map(
          (curr) => curr.id
        ),
        values?.companyValidCurrencies
          .filter((curr) => curr.id)
          .map((curr) => curr.id)
      ).reduce((arr, targetId) => {
        const initialValidCurrencies =
          data?.companies?.nodes[0]?.companyValidCurrencies?.nodes!.find(
            (curr) => curr.id === targetId
          )!;
        const { id, _rowTimestamp, ...updatedCurrencies } =
          values?.companyValidCurrencies!.find((curr) => curr.id === targetId)!;
        const patch = Object.entries(updatedCurrencies).reduce(
          (res, [key, val]) => {
            if (val !== initialValidCurrencies[key as keyof CurrencyRowValues])
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              companyValidCurrencyPatch: patch,
            },
          ];
        return arr;
      }, [] as CompanyValidCurrencyUpdateTypeInput[]);

    const { errors } = await updateCompany({
      variables: {
        input: {
          id: id || '',
          rowTimestamp: _rowTimestamp!,
          companyPatch: {
            name: values.name,
            homeCountryId: values.homeCountryId,
            currencyId: values.currencyId,
            approvalPhaseTypeId: values.approvalPhaseTypeId,
            distributionLayoutType: values.distributionLayoutType,
            timeZoneId: values.timeZoneId,
            corporationName: values.corporationName,
            corporateWorkgroupId: values.corporateWorkgroupId,
          },
          companyAddressCreate:
            newAddresses && newAddresses.length > 0 ? newAddresses : undefined,
          companyAddressUpdate:
            updatedAddresses && updatedAddresses.length > 0
              ? updatedAddresses
              : undefined,
          companyAddressDelete: deletedAddressRows,
          companyCurrencyDelete: deletedValidCurrencyRows,
          companyCurrencyCreate:
            newValidCurrencies && newValidCurrencies.length > 0
              ? newValidCurrencies
              : undefined,
          companyCurrencyUpdate:
            updatedValidCurrencies && updatedValidCurrencies.length > 0
              ? updatedValidCurrencies
              : undefined,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: COMPANY,
        },
      ],
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Company updated successfully', {
        appearance: 'success',
      });
    }
  };
  const [updateCompany] = useMutation<CompanyUpdate, CompanyUpdateVariables>(
    COMPANY_UPDATE,
    { errorPolicy: 'all' }
  );
  const { data: countries, loading: loadingCountries } = useQuery<Countries>(
    COUNTRIES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { data: timeZones, loading: loadingTimeZones } = useQuery<
    TimeZones,
    TimeZonesVariables
  >(TIME_ZONES, {
    variables: {
      countryId: selectedCountryId,
    },
    skip: !selectedCountryId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const { data: corporateGroupsData, loading: loadingCorporateGroups } =
    useQuery<CorporateWorkgroupsData>(CORPORATE_GROUPS_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const { data: distributionLayoutTypes, loading: loadingLayoutTypes } =
    useQuery<DistributionLayoutTypes>(DISTRIBUTION_LAYOUT_TYPES, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const { data: approvalPhaseData, loading: loadingApprovalPhaseData } =
    useQuery<ApprovalPhases>(APPROVAL_PHASE, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  const { data: currencyList } = useQuery<Currencies>(CURRENCY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const countryOptions: IDropdownOption[] =
    countries?.countries?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.country,
    })) || [];

  const timeZonesOptions: IDropdownOption[] =
    timeZones?.timeZones?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.description || '',
    })) || [];

  const corporateGroupsOptions: IDropdownOption[] =
    corporateGroupsData?.corporateWorkgroups?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.name,
    })) || [];

  const layoutOptions: IDropdownOption[] =
    distributionLayoutTypes?.distributionLayoutTypes?.nodes.map((ele) => ({
      key: ele.layoutType,
      text: ele.description || '',
    })) || [];

  const approvalPhaseOptions: IDropdownOption[] =
    approvalPhaseData?.approvalPhases?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.phaseName || '',
    })) || [];

  const currencyOptions: IDropdownOption[] =
    currencyList?.currencies?.nodes.map((ele) => ({
      key: ele.id || ele.id,
      text: `${ele.isoCode}-${ele.name}`,
    })) || [];

  useEffect(() => {
    if (data) {
      setSelectedCountryId(
        parseInt(data.companies?.nodes[0]?.homeCountryId.toString() || '')
      );
      const defaultValues = getDefaultValues(data);
      reset(defaultValues);
    }
  }, [data, reset]);

  return (
    <Stack tokens={{ childrenGap: 20 }} className={styles.listHeaderContainer}>
      <Stack verticalAlign="center" className={styles.listTitleContainer}>
        <Text variant="xLarge" style={{ paddingLeft: 25 }}>
          Company
        </Text>
        <Separator />
      </Stack>

      {loadingCompanyData ||
      loadingApprovalPhaseData ||
      loadingCorporateGroups ||
      loadingCountries ||
      loadingLayoutTypes ? (
        <ShimmerView />
      ) : (
        <FormProvider {...formMethods}>
          <Stack
            grow
            tokens={{
              childrenGap: 20,
            }}
            className={styles.container}
          >
            <FormHookTextField
              name="name"
              label="Name"
              placeholder="Name"
              disabled={!_isUpdatable}
            />
            <Stack
              horizontal
              style={{ flex: 1 }}
              tokens={{
                childrenGap: 20,
              }}
            >
              <Stack style={{ flex: 1 }}>
                <FormHookDropdown
                  name={'homeCountryId'}
                  label="Country/Locale"
                  options={countryOptions}
                  onChange={(_event, option) => {
                    const selectedOption = option as OptionsType;
                    setSelectedCountryId(
                      parseInt(selectedOption.key.toString())
                    );
                  }}
                  disabled={!_isUpdatable}
                />
              </Stack>
              <Stack style={{ flex: 1, verticalAlign: 'end' }}>
                {loadingTimeZones ? (
                  <Spinner label="loading..." />
                ) : (
                  <FormHookDropdown
                    name={'timeZoneId'}
                    label="Time Zone"
                    options={timeZonesOptions}
                    disabled={!_isUpdatable}
                  />
                )}
              </Stack>
            </Stack>
            <Stack
              horizontal
              style={{ flex: 1 }}
              tokens={{
                childrenGap: 20,
              }}
            >
              <Stack style={{ flex: 1 }}>
                <FormHookDropdown
                  name={'distributionLayoutType'}
                  label="Accounting Layout"
                  options={layoutOptions}
                  disabled={!_isUpdatable}
                />
              </Stack>
              <Stack style={{ flex: 1 }}>
                <FormHookDropdown
                  name={'approvalPhaseTypeId'}
                  label="Approval Phase"
                  options={approvalPhaseOptions}
                  disabled={!_isUpdatable}
                />
              </Stack>
            </Stack>
            <FormHookTextField
              name="corporationName"
              label="Corporate Entity"
              placeholder="Name"
              disabled={!_isUpdatable}
            />
            <Stack
              horizontal
              style={{ flex: 1 }}
              tokens={{
                childrenGap: 20,
              }}
            >
              <Stack style={{ flex: 0.5 }}>
                <FormHookDropdown
                  name={'currencyId'}
                  label="Home Currency"
                  options={currencyOptions}
                  disabled={!_isUpdatable}
                />
              </Stack>
              <Stack style={{ flex: 0.5 }}>
                <FormHookDropdown
                  name={'corporateWorkgroupId'}
                  label="Corporate Workgroup"
                  options={corporateGroupsOptions}
                  disabled={!_isUpdatable}
                />
              </Stack>
            </Stack>
          </Stack>
          <Separator />
          <Stack
            grow
            tokens={{
              childrenGap: 20,
            }}
            className={styles.subContainer}
          >
            <AddressForm isUpdatable={_isUpdatable} />
          </Stack>
          <Separator />
          <Stack
            grow
            tokens={{
              childrenGap: 20,
            }}
            className={styles.subContainer}
          >
            <CurrencyFormFields
              isUpdatable={_isUpdatable}
              currencyOptions={currencyOptions}
            />
          </Stack>
          <Stack className={styles.stickyBorder}></Stack>

          <Stack
            horizontal
            horizontalAlign="end"
            tokens={{ childrenGap: 20 }}
            className={styles.stickyFooter}
          >
            <PrimaryButton
              text="Save"
              onClick={handleSubmit(onHandleSubmit)}
              disabled={saveDisabled}
            />
            <DefaultButton
              text="Cancel"
              onClick={() => {
                if (data) {
                  const defaultValues = getDefaultValues(data);
                  reset(defaultValues);
                }
              }}
            />
          </Stack>
        </FormProvider>
      )}
    </Stack>
  );
};
