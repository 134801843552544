import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  DatePicker,
  DayOfWeek,
  PrimaryButton,
  Stack,
  TextField,
  TooltipHost,
  makeStyles,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import {
  TravelAuthorizationStatementReportStatus,
  TravelAuthorizationStatementReportStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/ReportStatus/__generated__/TravelAuthorizationStatementReportStatus';
import {
  ApprovalRequestInput,
  ReportStatusType,
} from 'common/types/globalTypes';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  TravelAuthorizationApprovalCreate,
  TravelAuthorizationApprovalCreateVariables,
} from 'travelAuthorization/TravelPlan/__generated__/TravelAuthorizationApprovalCreate';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  TravelAuthorizationRefresh,
  TravelAuthorizationRefreshVariables,
} from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorizationRefresh';
const TRAVEL_AUTH_REPORT_STATUS = loader(
  '../../../../../../common/graphql/DocumentPackageSubscription/ReportStatus/TravelAuthorizationStatementReportStatus.graphql'
);
const TRAVELER_AUTHORIZATION_APPROVAL_CREATE = loader(
  '../../../../TravelAuthorizationApprovalCreate.graphql'
);
const REFRESH_TRAVEL_AUTH = loader(
  '../../../TravelAuthorizationRefresh.graphql'
);

const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this travel plan for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your travel plan will be requested for approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';
const useStyles = makeStyles((theme) => ({
  disabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  marginT10: {
    marginTop: 10,
  },
}));

interface RequestApprovalProps {
  isNew: boolean;
  isDirty: boolean;
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
}

export const RequestApproval: React.FC<RequestApprovalProps> = ({
  isNew,
  isDirty,
  travelAuthorizationData,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const client = useApolloClient();
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [requestComment, setRequestComment] = useState<string>();
  const [requiredDate, setRequiredDate] = useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [
    travelerAuthorizationApprovalCreate,
    { loading: approvalCreateLoading },
  ] = useMutation<
    TravelAuthorizationApprovalCreate,
    TravelAuthorizationApprovalCreateVariables
  >(TRAVELER_AUTHORIZATION_APPROVAL_CREATE, {
    errorPolicy: 'all',
    onCompleted: () => {
      setUrgencyLevel(1);
    },
  });
  const [refreshTravelAuth] = useLazyQuery<
    TravelAuthorizationRefresh,
    TravelAuthorizationRefreshVariables
  >(REFRESH_TRAVEL_AUTH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { id, _rowTimestamp, _isStagedApprovalRequest } = {
    ...travelAuthorizationData,
  };

  if (!(!isNew && !!_isStagedApprovalRequest)) return null;

  return (
    <Stack>
      <PrimaryButton
        disabled={isDirty}
        className={styles.disabledButton}
        text="Request Approval"
        onClick={() => toggleConfirmDialog()}
      />
      <ConfirmDialog
        hidden={hideConfirmRequestDialog}
        successBtnDisable={approvalCreateLoading}
        title={CONFIRM_REQUEST_DIALOG_TITLE}
        subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
        onDismiss={toggleConfirmDialog}
        minWidth={500}
        onConfirm={async () => {
          const inputVariables: ApprovalRequestInput = {
            entityId: id!,
            rowTimestamp: _rowTimestamp!,
            comments: requestComment,
          };
          if (requiredDate) inputVariables.requiredDate = requiredDate;
          const { errors } = await travelerAuthorizationApprovalCreate({
            variables: {
              input: {
                entityApproval: [inputVariables],
                urgencyLevel: urgencyLevel,
              },
            },
          });
          if (errors?.length)
            addToast(errors[0].message, {
              appearance: 'error',
            });
          else {
            if (id)
              refreshTravelAuth({
                variables: {
                  id,
                },
              });
            setRequestComment('');
            setRequiredDate('');
            toggleConfirmDialog();
            addToast('Request sent for approval', {
              appearance: 'success',
            });
            const observer = client.subscribe<
              TravelAuthorizationStatementReportStatus,
              TravelAuthorizationStatementReportStatusVariables
            >({
              query: TRAVEL_AUTH_REPORT_STATUS,
              variables: {
                id: id!,
              },
            });
            const subscription = observer.subscribe(({ data, errors }) => {
              if (errors)
                addToast(
                  'Errors received while Subscribing to document package',
                  { appearance: 'error' }
                );
              else {
                const { document, status } = {
                  ...data?.travelAuthorizationStatementReportStatus,
                };
                if (status === ReportStatusType.FAILURE) {
                  addToast('Report generation failed', {
                    appearance: 'success',
                  });
                }
                if (document) {
                  addToast('Report document created', {
                    appearance: 'success',
                  });
                }
              }
              subscription.unsubscribe();
            });
          }
        }}
      >
        <Stack
          tokens={{
            childrenGap: 10,
          }}
        >
          <TextField
            className={styles.marginT10}
            multiline
            rows={3}
            value={requestComment}
            placeholder="Please write your comment here (optional)"
            resizable={false}
            onChange={(_event, value) => setRequestComment(value || '')}
          />
          <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
            <DatePicker
              minDate={new Date()}
              firstDayOfWeek={DayOfWeek.Sunday}
              placeholder="Finalized by date (optional)"
              ariaLabel="Date"
              formatDate={onFormatDate}
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday={false}
              onSelectDate={(date) =>
                setRequiredDate(dateFormat(date!.toString()))
              }
            />
          </TooltipHost>
          <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
            <UrgencyToggle
              onToggle={(data) => {
                setUrgencyLevel(data ? 0 : 1);
              }}
            />
          </Stack>
        </Stack>
      </ConfirmDialog>
    </Stack>
  );
};
