import React, { useState } from 'react';
import {
    Stack,
    useTheme,
    TooltipHost,
    Label,
    Icon,
    IIconStyles,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { LookupNameSearch_lookupNameSearch_nodes } from 'common/components/LookUp/__generated__/LookupNameSearch';
import { VendorDetailsCallout } from './VendorDetailsCallout';

interface TextFieldLabelProps {
    data?: LookupNameSearch_lookupNameSearch_nodes | null;
    disabled: boolean | undefined;
    showIcon: boolean | undefined | null;
    label: string | undefined;
    required?: boolean | undefined;
}

export const TextFieldLabel: React.FC<TextFieldLabelProps> = ({
    disabled,
    showIcon,
    label,
    data,
    required = false,
}) => {
    const theme = useTheme();
    const [calloutVisible, setCalloutVisible] = useState(false);
    const calloutId = useId(`callOutId${data?.id}`);
    const iconStyles: Partial<IIconStyles> = {
        root: {
            color: theme.palette.themePrimary,
            margin: "6px 0px 0px 6px"
        }
    };
    return (
        <Stack horizontal>
            <Label
                disabled={disabled ? true : showIcon ? true : false}
                onMouseEnter={() => setCalloutVisible(true)}
                onMouseLeave={() => setCalloutVisible(false)}
                id={calloutId}
                required={required}
            >
                {label}
            </Label>
            {
                calloutVisible &&
                data?.isApprovedVendor &&
                <VendorDetailsCallout
                    data={data}
                    detailsTargetId={calloutId}
                    onChangeCalloutVisibility={setCalloutVisible}
                />
            }
            {
                showIcon &&
                <TooltipHost content={'Verified'}>
                    <Icon iconName="CheckMark" styles={iconStyles} />
                </TooltipHost>
            }
        </Stack>
    )
}