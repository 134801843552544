import {
  DocumentPackageEmailModal,
  DocumentPackageEmailModalProps,
} from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal';
import { CreateNewEmailModal } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/CreateNewEmailModal';
import React from 'react';

type DocumentPackageEmailProps = DocumentPackageEmailModalProps & {
  dataLoading: boolean;
};

export const DocumentPackageEmail: React.FC<DocumentPackageEmailProps> = ({
  dataLoading,
  ...props
}) => {
  const { _isEmailExists, _isEmailCreateAllowed } = { ...props.emailDocument };

  if (dataLoading) return null;

  return (
    <>
      {!!_isEmailExists && (
        <DocumentPackageEmailModal
          {...props}
        />
      )}
      {!_isEmailExists && !!_isEmailCreateAllowed && (
        <CreateNewEmailModal  {...props} />
      )}
    </>
  );
};
