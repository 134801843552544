import {
  Callout,
  DirectionalHint,
  IconButton,
  Separator,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { FormHookAmount } from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { TravelerValues } from '../../interfaces';
import { useStyles } from './index.styles';
import { TravelPoliciesOptions } from '../types';
import clsx from 'clsx';

export type SelectedTypePolicy = Pick<
  TravelPoliciesOptions,
  | 'text'
  | 'isCompanion'
  | 'isContractual'
  | 'isDefaultCustomizable'
  | 'currency'
>;

interface TravelAllowancesCalloutProps {
  calloutId: string;
  dismissCallout: (param: boolean) => void;
  closeCallout: () => void;
  isCalloutVisible: boolean;
  inputsDisabled: boolean;
  selectedTypePolicy?: SelectedTypePolicy;
}

export const TravelAllowancesCallout: React.FC<
  TravelAllowancesCalloutProps
> = ({
  calloutId,
  dismissCallout,
  closeCallout,
  isCalloutVisible,
  inputsDisabled,
  selectedTypePolicy,
}) => {
    const styles = useStyles();
    const commonStyles = useCommonStyles();
    const { control, setValue } = useFormContext<TravelerValues>();

    const { fields } = useFieldArray({
      name: 'travelerPolicyAllowancesByTravelerId',
      control,
    });
    const fareClassKeys = [
      { key: 'isEconomyClassPreferred', value: 'Economy' },
      { key: 'isFirstClassPreferred', value: 'First' },
      { key: 'isBusinessClassPreferred', value: 'Business' },
      { key: 'isCharterClassPreferred', value: 'Charter' },
      { key: 'isPremiumClassPreferred', value: 'Premium' },
    ];
    const values = useWatch();
    const selectedFareClass = fareClassKeys.find(
      (key) => values[key.key] === true
    )?.value;

    const travelerCompanionTickets = useWatch({
      name: 'travelerCompanionTickets',
      control,
    });
    const travelerCompanionAmount = useWatch({
      name: 'travelerCompanionAmount',
      control,
    });
    const travelerPolicyAllowancesByTravelerId = useWatch({
      name: 'travelerPolicyAllowancesByTravelerId',
      control,
    });
    const {
      text,
      currency,
      isContractual,
      isDefaultCustomizable,
    } = { ...selectedTypePolicy }

    return (
      <>
        {isCalloutVisible && (
          <Stack className={styles.container}>
            <Callout
              onDismiss={() => dismissCallout(false)}
              gapSpace={10}
              isBeakVisible={false}
              directionalHint={DirectionalHint.bottomRightEdge}
              target={`#${calloutId!}`}
            >
              <Stack verticalAlign="center" className={styles.background}>
                <Stack
                  verticalAlign="center"
                  horizontal
                  className={styles.header}
                >
                  <Text variant="mediumPlus">Travel Policy</Text>

                  <Stack tokens={{ childrenGap: 20 }} horizontal>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      {text && (
                        <Text
                          variant="mediumPlus"
                          className={clsx(
                            commonStyles.colorThemePrimary,
                            commonStyles.bold
                          )}
                        >
                          {`${text}`}
                        </Text>
                      )}
                      <Text
                        variant="mediumPlus"
                        className={clsx(
                          commonStyles.colorThemePrimary,
                          commonStyles.bold
                        )}
                      >
                        {currency}
                      </Text>
                      <Text
                        variant="mediumPlus"
                        className={clsx(
                          commonStyles.colorThemePrimary,
                          commonStyles.bold
                        )}
                      >
                        {isContractual ? 'Contractual' : null}
                      </Text>
                    </Stack>
                    <TooltipHost content="Close">
                      <IconButton
                        onClick={closeCallout}
                        iconProps={{ iconName: 'Cancel' }}
                        className={styles.iconButtonColor}
                        ariaLabel="Expand"
                      />
                    </TooltipHost>
                  </Stack>
                </Stack>
                <Separator />
                <Stack horizontal tokens={{ padding: '10px 15px' }}>
                  {isContractual && (
                    <>
                      <Stack.Item className={styles.labelContainer}>
                        <Text className={styles.label}>Granted Tickets:</Text>
                        {travelerCompanionTickets && (
                          <Text>{travelerCompanionTickets}</Text>
                        )}
                      </Stack.Item>
                      <Stack.Item className={styles.labelContainer}>
                        <Text className={styles.label}>Amount:</Text>
                        {
                          <Text>
                            {travelerCompanionAmount
                              ? travelerCompanionAmount
                              : '0.00'}
                          </Text>
                        }
                      </Stack.Item>
                    </>
                  )}
                  <Stack.Item className={styles.labelContainer}>
                    <Text className={styles.label}>Fare Class:</Text>
                    {selectedFareClass && <Text>{selectedFareClass}</Text>}
                  </Stack.Item>
                </Stack>
                {travelerPolicyAllowancesByTravelerId &&
                  travelerPolicyAllowancesByTravelerId.length > 0 && (
                    <>
                      <Separator />
                      <Stack horizontal>
                        <Text
                          variant="mediumPlus"
                          className={styles.travelAllowancesHeading}
                        >
                          Travel Allowances
                        </Text>
                      </Stack>
                      <Separator />
                      <Stack className={styles.policyAllowanceContainer}>
                        {fields.map((field, index) => {
                          const isChecked =
                            travelerPolicyAllowancesByTravelerId[index].isChecked;
                          const isAllowanceCustomizable =
                            travelerPolicyAllowancesByTravelerId[index]
                              .isAllowanceCustomizable;

                          return (
                            <Stack
                              key={index}
                              horizontal
                              verticalAlign="baseline"
                              tokens={{
                                childrenGap: 10,
                                padding: '10px 15px',
                              }}
                            >
                              <Stack.Item className={styles.labelContainer}>
                                {
                                  <FormHookCheckBox
                                    name={`travelerPolicyAllowancesByTravelerId.${index}.isChecked`}
                                    label={field._allowanceDescription!}
                                    onChange={(_e, isChecked) => {
                                      if (!isChecked) {
                                        setValue(
                                          `travelerPolicyAllowancesByTravelerId.${index}.allowanceOverrideAmount`,
                                          null
                                        );
                                      }
                                    }}
                                    disabled={
                                      inputsDisabled || !isDefaultCustomizable
                                    }
                                  />
                                }
                              </Stack.Item>
                              <Stack.Item className={styles.labelContainer}>
                                {isChecked &&
                                  isAllowanceCustomizable && (
                                    <TooltipHost content="Override">
                                      <FormHookAmount
                                        name={`travelerPolicyAllowancesByTravelerId.${index}.allowanceOverrideAmount`}
                                        placeholder="Override"
                                        fixedDecimalScale
                                        decimalScale={2}
                                        allowNegative={false}
                                        disabled={inputsDisabled}
                                      />
                                    </TooltipHost>
                                  )}
                              </Stack.Item>
                            </Stack>
                          );
                        })}

                      </Stack>
                    </>
                  )}
              </Stack>
            </Callout>
          </Stack>
        )}
      </>
    );
  };
