import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { InvoiceBatchesOrderBy } from 'common/types/globalTypes';

type OrderLookup = {
  [direction in OrderDirection]: InvoiceBatchesOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'transactionType',
    {
      [OrderDirection.ASC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__TRANSACTION_TYPE_ID_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__TRANSACTION_TYPE_ID_DESC,
      ],
    },
  ],
  [
    'accountStampReference',
    {
      [OrderDirection.ASC]: [
        InvoiceBatchesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoiceBatchesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC,
      ],
    },
  ],

  [
    'name',
    {
      [OrderDirection.ASC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__VENDOR_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__VENDOR_REFERENCE_DESC,
      ],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__DESCRIPTION_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__DESCRIPTION_DESC,
      ],
    },
  ],
  [
    'invoiceNumber',
    {
      [OrderDirection.ASC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__INVOICE_NUMBER_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__INVOICE_NUMBER_DESC,
      ],
    },
  ],
  [
    'date',
    {
      [OrderDirection.ASC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__INVOICE_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_DATE_DESC,
      ],
    },
  ],
  [
    'amount',
    {
      [OrderDirection.ASC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__CONTROL_TOTAL_AMOUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoiceBatchesOrderBy.INVOICE_BY_INVOICE_ID__CONTROL_TOTAL_AMOUNT_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    InvoiceBatchesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
    InvoiceBatchesOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), ...orderByArray];
};
