import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'self-start',
    padding: 20,
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99
  },
}));
