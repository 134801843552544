import { Stack, Text } from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';
import { Trip_trip } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/__generated__/Trip';
interface WarningValuesProps {
  tripData: Trip_trip | null | undefined;
}
export const WarningValues: React.FC<WarningValuesProps> = ({ tripData }) => {
  const styles = useStyles();
  if (!tripData) return null;
  const { warningValue1, warningValue2, warningValue3 } = { ...tripData };
  const warningValuesArray = [warningValue1, warningValue2, warningValue3];
  return (
    <Stack className={styles.container} tokens={{ childrenGap: 14 }}>
      {warningValuesArray.map((item, index) => (
        <Text key={index}>{item}</Text>
      ))}
    </Stack>
  );
};
