import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  createNewMessageContainer: {
    padding: 15,
    alignItems: 'flex-end',
  },
  createNewMessageButton: {
    width: '200px',
  },
  pivotContainer: {
    marginLeft: 20,
  },
  selectedIndexContaianer: {
    marginTop: '20px',
  },
  mainContainer: {
    marginTop: 80,
  },
  messageReadBgColor: {
    backgroundColor: theme.palette.white,
  },
  messageUnreadBgColor: {
    backgroundColor: theme.palette.neutralLight,
  },
}));
