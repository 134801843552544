import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  leftPanelContainer: {
    flex: 1,
    borderColor: 'red',
    borderWidth: 19,
    '::-webkit-scrollbar': {
      width: 5,
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.neutralLight,
      borderRadius: '6px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.neutralLighter,
    },

    // /* Scrollbar styles for Firefox */
    // scrollbarWidth: 'thin',
    // scrollbarColor: '#888 #f1f1f1',
  },
  lightBackground: {
    backgroundColor: `${theme.palette.neutralLighterAlt}99`,
  },
  groupSelected: {
    backgroundColor: theme.palette.neutralLighter,
  },
}));
