import { useMutation } from '@apollo/client';
import {
  Icon,
  IPivotItemProps,
  Label,
  Pivot,
  PivotItem,
  Stack,
} from '@fluentui/react';
import { Badge } from 'common/components/Badge';
import { EntityUserMessageFields } from 'common/graphql/__generated__/EntityUserMessageFields';
import { ExtendedMessageFields } from 'common/graphql/__generated__/ExtendedMessageFields';
import {
  ResetUserNewMessageCount,
  ResetUserNewMessageCountVariables,
} from 'common/graphql/__generated__/ResetUserNewMessageCount';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { ReceivedMessageList } from './ReceivedList';
import { SentMessageList } from './SentList';
const GET_USER_MESSAGE_RESET_COUNT = loader(
  '../../../common/graphql/ResetUserNewMessageCount.graphql'
);
export type MessageListItem = EntityUserMessageFields &
  Partial<ExtendedMessageFields>;

interface MessagesListsProps {
  repliesReceived: number | null;
  messagesReceived: number | null;
  data: MessageListItem[];
  loadingMessages: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  onListChange: (listName?: string) => void;
  defaultList: string;
}
export enum PivotKeys {
  received = 'RECEIVED',
  sent = 'SENT',
}

export const MessagesSections: React.FC<MessagesListsProps> = ({
  onListChange,
  defaultList,
  children,
  ...props
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = React.useState<string>(defaultList);
  const [repliesReceivedCount, setRepliesReceivedCount] = useState<number>(0);

  useEffect(() => {
    setSelectedKey(defaultList);
  }, [defaultList]);

  const [messageResetUser] = useMutation<
    ResetUserNewMessageCount,
    ResetUserNewMessageCountVariables
  >(GET_USER_MESSAGE_RESET_COUNT, {
    onCompleted: (data) => {
      setRepliesReceivedCount(0);
    },
  });

  const resetMessageCountMemo = useCallback(messageResetUser, []);

  useEffect(() => {
    if (selectedKey === PivotKeys.sent && repliesReceivedCount > 0) {
      resetMessageCountMemo({
        variables: {
          input: {},
        },
      });
    }
  }, [selectedKey, repliesReceivedCount, resetMessageCountMemo]);

  useEffect(() => {
    setRepliesReceivedCount(props.repliesReceived!);
  }, [props.repliesReceived]);

  const _customRenderer = (
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ) => {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <span style={{ flex: '0 1 100%' }}>
        <Icon iconName={link.itemIcon} />
        {defaultRenderer({ ...link, itemIcon: undefined })}
        <Badge useMargin visible={repliesReceivedCount > 0} />
      </span>
    );
  };

  return (
    <>
      <Stack horizontal horizontalAlign="space-between">
        <Pivot
          selectedKey={selectedKey}
          onLinkClick={(item: PivotItem | undefined) => {
            setSelectedKey(item!.props!.itemKey!);
            onListChange(item!.props!.itemKey!);
          }}
          className={styles.pivotContainer}
        >
          <PivotItem
            itemKey={PivotKeys.received}
            headerText="Received"
            itemIcon="SortDown"
          />
          <PivotItem
            headerText="Sent"
            itemIcon="SortUp"
            itemKey={PivotKeys.sent}
            onRenderItemLink={_customRenderer}
          >
            <Label>Sent</Label>
          </PivotItem>
        </Pivot>
        {children}
      </Stack>
      <Stack tokens={{ padding: '10px 0px 0px 0px' }}>
        {selectedKey === PivotKeys.received ? (
          <ReceivedMessageList {...props} refetchList={onListChange} />
        ) : (
          <SentMessageList {...props} refetchList={onListChange} />
        )}
      </Stack>
    </>
  );
};
