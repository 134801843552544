import { Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import React from 'react';
import { useStyles } from './index.styles';

interface ModalHeaderProps {
  onClose: () => void;
}
export const ModalHeader: React.FC<ModalHeaderProps> = ({ onClose }) => {
  const styles = useStyles();
  return (
    <Stack
      horizontal
      horizontalAlign={'space-between'}
      className={styles.container}
    >
      <Text variant={'xLarge'}>Add Review</Text>
      <CloseButton onClick={onClose} />
    </Stack>
  );
};
