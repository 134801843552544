import { useLazyQuery } from '@apollo/client';
import {
  IRenderFunction,
  ITextFieldProps,
  ITextFieldStyles,
  Link,
  Spinner,
  Stack,
  Text,
  TextField,
  makeStyles,
  useTheme,
} from '@fluentui/react';
import {
  LookupAccountTransactionSearch,
  LookupAccountTransactionSearchVariables,
} from 'common/graphql/__generated__/LookupAccountTransactionSearch';
import { useCommonStyles } from 'common/styles';
import { RequiredNameProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import { LookUpCallout } from './Lookup';
import { LookupSearch_lookupAccountSearch_nodes } from './__generated__/LookupSearch';
const LOOKUP = loader(
  '../../../../../../../../common/graphql/LookupAccountTransactionSearch.graphql'
);

export type LookUpProps = LookupSearch_lookupAccountSearch_nodes;
type AutoCompleteTextFieldProps = RequiredNameProps<ITextFieldProps> & {
  width: number;
  lookUpCalloutWidth?: number;
  onVendorSelect?: (lookupData: LookUpProps) => void;
  businessUnitId: string | null;
};

const useStyles = makeStyles((theme) => ({
  resultItem: {
    textDecoration: 'none',
    margin: '0px -20px',
    padding: '6px 20px',
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
    ':active': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
}));

export const AutoCompleteTextField: FC<AutoCompleteTextFieldProps> = ({
  width,
  lookUpCalloutWidth = 300,
  onVendorSelect,
  businessUnitId,
  ...props
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: theme.palette.white,
    },
    fieldGroup: {
      maxWidth: width,
    },
  };

  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
  });

  const { value, ...rest } = field;
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupAccountTransactionSearch,
    LookupAccountTransactionSearchVariables
  >(LOOKUP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const { id } = { ...props };

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupName: value,
          businessUnitId,
        },
      });
      queryCalled();
    }, 350),
    [value]
  );

  const commonStyles = useCommonStyles();

  const onRenderSuffix: IRenderFunction<ITextFieldProps> = (props) => {
    if (loading) return <Spinner />;
    else return null;
  };

  const TextfieldProps: ITextFieldProps | undefined = loading
    ? {
        onRenderSuffix,
      }
    : undefined;

  return (
    <Stack style={{ width }}>
      <TextField
        {...rest}
        {...props}
        autoComplete={'off'}
        errorMessage={error?.message}
        value={value || ''}
        onChange={(_event, value) => {
          if (value) {
            field.onChange(value);
            callbackLookUp(value, () => {
              if (!calloutVisible) setCalloutVisible(true);
            });
          } else {
            field.onChange(null);
            setCalloutVisible(false);
          }
        }}
        {...TextfieldProps}
        underlined
        style={{ width }}
        styles={textfieldStyles}
      />

      {calloutVisible &&
        !loading &&
        data?.lookupAccountTransactionSearch?.nodes.length! > 0 && (
          <LookUpCallout
            lookUpCalloutWidth={lookUpCalloutWidth}
            onDismiss={() => setCalloutVisible(false)}
            targetID={`#${id!}`}
          >
            {data?.lookupAccountTransactionSearch?.nodes.map((data, index) => {
              return (
                <Link
                  onClick={() => {
                    field.onChange(data.lookupAccount);
                    setCalloutVisible(false);
                    onVendorSelect?.(data);
                  }}
                  className={styles.resultItem}
                  key={index.toString()}
                >
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Text className={commonStyles.colorThemePrimary}>
                      {data.lookupAccount}
                    </Text>
                    <Text nowrap>{data.lookupName}</Text>
                  </Stack>
                </Link>
              );
            })}
          </LookUpCallout>
        )}
    </Stack>
  );
};
