import { makeStyles } from '@fluentui/react';

const NODE_LINK = '#9c9c9c';
export const useStyles = makeStyles((theme) => ({
  headerPending: {
    color: theme.palette.themePrimary,
  },
  treeHeadingStyle: {
    backgroundColor: theme.palette.white,
    width: 200,
    padding: '7px 10px',
    borderRadius: 5,
    border: `1px dashed ${theme.palette.neutralTertiaryAlt}`,
  },
  backgroundDefault: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  nodeContainer: {
    boxSizing: 'border-box',
    width: 220,
    height: 170,
    borderRadius: 10,
    padding: 10,
    boxShadow: '1px 10px 40px -12px rgba(0, 0, 0, 0.48)',
    MozWindowShadow: '1px 10px 40px -12px rgba(0, 0, 0, 0.48)',
    WebkitBoxShadow: '1px 10px 40px -12px rgba(0, 0, 0, 0.48)',
  },
  treeContainer: {
    width: 0,
    height: 170,
    justifyContent: 'flex-end',
    alignItems: 'center',
    border: `1px solid ${NODE_LINK}`,
    margin: '-2px 0',
  },
  textviewStyles: {
    textAlign: 'left',
  },
  innerBox: {
    backgroundColor: theme.palette.neutralLight,
    width: '100%',
    borderRadius: 5,
    padding: 10,
    marginTop: 10,
  },
}));
