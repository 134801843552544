import {
  ColumnActionsMode,
  IColumn,
  IDetailsColumnStyles,
} from '@fluentui/react';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
    paddingRight: 30,
  },
};
const leftAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    paddingLeft: 20,
  },
};
// const alignHeaderStyle: Partial<IDetailsColumnStyles> = {
//   cellTitle: {
//     paddingRight: 20,
//   },
// };
const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};
export const columns: IColumn[] = [
  {
    key: 'currency',
    name: 'Currency',
    fieldName: 'currency',
    minWidth: 80,
    maxWidth: 100,
    styles: leftAlignHeaderStyle,
    ...commonProps,
  },
  {
    key: 'balance',
    name: 'Balance',
    fieldName: 'balance',
    minWidth: 100,
    maxWidth: 120,
    styles: rightAlignHeaderStyle,
    ...commonProps,
  },
];
