import { TagCategoryValues, TagCategoryItems } from './types';

export const TAG_GROUP_ITEM: TagCategoryItems = {
  tagId: null,
};
export const CATEGORY_METRIC = {
  isCostMetric: false,
  isCustomerMetric: false,
  isEmployeeMetric: false,
  isPayablesMetric: false,
  isEnvironmentalMetric: false,
  isPayrollMetric: false,
  isPettyCashMetric: false,
  isPurchasingMetric: false,
  isRedFlag: false,
  isReceivablesMetric: false,
  isSupplierMetric: false,
  isTravelMetric: false,
  isVendorMetric: false,
};

export const TAG_CATEGORY_INITIAL_VALUES: TagCategoryValues = {
  name: null,
  description: null,
  metrics: CATEGORY_METRIC,
  tagCategoryItems: [],
};
