import { makeStyles, FontWeights } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 800,
  },
  tagsContent: {
    margin: '0px -20px',
    padding: 20,
  },
  tagsSection: {
    paddingTop: 10
  },
  iconButtonColor: {
    color: theme.palette.neutralDark,
  },
  selectTagsLabel: {
    marginTop: 6,
    marginLeft: '6px !important',
    fontWeight: FontWeights.bold
  },
}));
