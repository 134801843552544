import { IPalette } from '@fluentui/react';
import { StyleSheet } from '@react-pdf/renderer';

export const getStyles = (palette: IPalette) => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      paddingHorizontal: 20,
      paddingTop: 20,
      paddingBottom: 10,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    header: {
      flexDirection: 'row',
    },
    transactionType: {
      fontSize: 16,
      paddingLeft: 10,
      color: palette.themePrimary,
      paddingHorizontal: 10,
    },
    colorThemeRed: {
      color: palette.red,
      paddingHorizontal: 20,
      paddingBottom: 20,
      fontSize: 12,
    },
  });
};
