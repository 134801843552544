import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  status: {
    marginLeft: 10,
  },
}));
