import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    fiscalYear: Yup.number().required('Required').nullable(),
    startDate: Yup.date().required('Required').nullable(),
    endDate: Yup.date()
    .required('Required')
    .nullable()
    .min(
      Yup.ref('startDate'), 
      "End Date cannot be greater than Start Date."
      ),
    companyCorporatePeriodsByFiscalYearId: Yup.array().of(
      Yup.object().shape({
        fiscalPeriod: Yup.number().required('Required').nullable(),
        startDate: Yup.date().required('Required').nullable(),
        endDate: Yup.date()
        .required('Required')
        .nullable()
        .min(
          Yup.ref('startDate'), 
          "End Date cannot be greater than Start Date."
          )
      }),      
    )
  });
