import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import {
  ActionButton,
  DayOfWeek,
  IButtonProps,
  Icon,
  IconButton,
  Label,
  Pivot,
  PivotItem,
  Stack,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import {
  FormHookAmount,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { FormHookDateSelect } from 'common/components/FormHooksFields/FormHookDateSelect';
import { FormHookTimePicker } from 'common/components/FormHooksFields/FormHookTimePicker';
import { useCommonStyles } from 'common/styles';
import { TransactionLayout } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { Trip_trip } from '../../../../__generated__/Trip';
import { TripCreateFormValues, TripItemFormValues } from '../../../../types';
import { CarrierAutocomplete } from '../CarrierAutocomplete';
import { DepotAutoComplete } from '../DepotAutoComplete';
import { ArrivalPrice } from '../components/ArrivalPrice';
import { ConfirmationCode } from '../components/ConfirmationCode/ConfirmationCode';
import { FareClasses } from '../components/FareClasses';
import {
  departureFlightClassOptions,
  returnFlightClassOptions,
} from '../components/FareClasses/constants';
import { ETicketField } from '../components/ETicketField';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { StopsModal } from './StopsModal';
import { useStyles } from './index.styles';
import {
  TravelerFirstFlight,
  TravelerFirstFlightVariables,
} from './__generated__/TravelerFirstFlight';
import { setSelectedTraveler } from '../../../BasicForm/TravelerAutoComplete';
const TRAVELER_AUTHORIZATION_COMMON_DATA = loader(
  '../../../../../../TravelerAuthorizationCommonData.graphql'
);
const TRAVELER_FIRST_FLIGHT = loader('./TravelerFirstFlight.graphql');

const iconButtonProps: IButtonProps = {
  text: 'Add Stops',
  iconProps: {
    iconName: 'Add',
  },
  styles: {
    icon: {
      width: 1,
      height: 1,
      paddingBottom: 10,
      alignContent: 'center',
      alignItems: 'center',
    },
  },
};

interface FlightProps {
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
}

interface DepartureLayoutProps {
  baseField: string;
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
  isNew: boolean;
  tripType: string;
  onFareClassSelect: (depot: string | null) => void;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
}

interface ReturnLayoutProps {
  baseField: string;
  tripIndex: number;
  tripData: Trip_trip | null | undefined;
  fareClass: string | null | undefined;
  selectedKey: string;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
}

export const Flight: React.FC<FlightProps> = ({
  tripIndex,
  tripData,
  travelAuthorizationData,
}) => {
  const { _isUpdatable } = { ...travelAuthorizationData };
  const baseField = `tripItems.${tripIndex}.`;
  const { control, setValue } = useFormContext<TripCreateFormValues>();
  const tripItems = useWatch({ name: 'tripItems', control });
  const [selectedKey, setSelectedKey] = useState<string>(
    TransactionLayout.TRAVEL_BOOKING_ROUND_TRIP
  );
  const commonStyles = useCommonStyles();
  const [selectedFareClass, setSelectedFareClass] = useState<
    string | null | undefined
  >(undefined);

  const { data: commonData } = useQuery<TravelerAuthorizationCommonData>(
    TRAVELER_AUTHORIZATION_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { tripSectionId, tripSectionItemId, id } = { ...tripItems[tripIndex] };
  const isNew = !id;
  const departureDepotId = useWatch({
    name: `tripItems.${tripIndex}.departureDepotId`,
    control,
  });
  const departureArrivalDepotId = useWatch({
    name: `tripItems.${tripIndex}.departureArrivalDepotId`,
    control,
  });
  const showReturnFields = !!departureDepotId && !!departureArrivalDepotId;
  const { nodes } = { ...commonData?.tripSections };
  const airTravelSectionItems = nodes?.find((ele) => ele.id === tripSectionId);
  const { tripSectionItems } = { ...airTravelSectionItems };
  const watchDepartureAirfare = useWatch({
    name: `tripItems.${tripIndex}.departureAirfare`,
  });
  const watchReturnAirfare = useWatch({
    name: `tripItems.${tripIndex}.returnAirfare`,
  });

  const totalAmount =
    parseFloat(watchDepartureAirfare || '0.0') +
    parseFloat(watchReturnAirfare || '0.0');

  const onLinkClick = (item: PivotItem | undefined) => {
    const tripSectionItemId = tripSectionItems?.nodes.find(
      (ele) => ele.layoutType === item?.props?.itemKey
    )?.id;
    if (tripSectionItemId) {
      setValue(`tripItems.${tripIndex}.tripSectionItemId`, tripSectionItemId, {
        shouldDirty: true,
      });
      if (item?.props.itemKey === TransactionLayout.TRAVEL_BOOKING_ONE_WAY) {
        setValue(`tripItems.${tripIndex}.returnArrivalDepotId`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalDepotName`, null);
        setValue(`tripItems.${tripIndex}.returnDepotId`, null);
        setValue(`tripItems.${tripIndex}.returnDepotName`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierId`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierName`, null);
        setValue(`tripItems.${tripIndex}.isReturnBusinessClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnCharterClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnEconomyClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnFirstClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnPremiumClass`, false);
        setValue(`tripItems.${tripIndex}.returnDate`, null);
        setValue(`tripItems.${tripIndex}.returnTime`, null);
        setValue(`tripItems.${tripIndex}.returnAirfare`, null);
        setValue(`tripItems.${tripIndex}.isReturnRefundable`, false);
        setValue(`tripItems.${tripIndex}.returnStops`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalDate`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalTime`, null);
        setValue(`tripItems.${tripIndex}.returnTerminal`, null);
        setValue(`tripItems.${tripIndex}.returnTerminalArrival`, null);
        setValue(`tripItems.${tripIndex}.returnMiles`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierTripNumber`, null);
        setValue(`tripItems.${tripIndex}.returnSeat`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierConfirmationCode`, null);
        setValue(`tripItems.${tripIndex}.returnEticketNumber`, null);
        setValue(`tripItems.${tripIndex}.stopModalReturnItems`, null);
        setSelectedFareClass(null);
      }
      if (item?.props.itemKey === TransactionLayout.TRAVEL_BOOKING_MULTI_TRIP) {
        setValue(`tripItems.${tripIndex}.returnArrivalDepotId`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalDepotName`, null);
        setValue(`tripItems.${tripIndex}.returnDepotId`, null);
        setValue(`tripItems.${tripIndex}.returnDepotName`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierId`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierName`, null);
        setValue(`tripItems.${tripIndex}.isReturnBusinessClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnCharterClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnEconomyClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnFirstClass`, false);
        setValue(`tripItems.${tripIndex}.isReturnPremiumClass`, false);
        setValue(`tripItems.${tripIndex}.returnDate`, null);
        setValue(`tripItems.${tripIndex}.returnTime`, null);
        setValue(`tripItems.${tripIndex}.returnAirfare`, null);
        setValue(`tripItems.${tripIndex}.isReturnRefundable`, false);
        setValue(`tripItems.${tripIndex}.returnStops`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalDate`, null);
        setValue(`tripItems.${tripIndex}.returnArrivalTime`, null);
        setValue(`tripItems.${tripIndex}.returnTerminal`, null);
        setValue(`tripItems.${tripIndex}.returnTerminalArrival`, null);
        setValue(`tripItems.${tripIndex}.returnMiles`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierTripNumber`, null);
        setValue(`tripItems.${tripIndex}.returnSeat`, null);
        setValue(`tripItems.${tripIndex}.returnCarrierConfirmationCode`, null);
        setValue(`tripItems.${tripIndex}.returnEticketNumber`, null);
        setValue(`tripItems.${tripIndex}.stopModalReturnItems`, null);
      }
    }
    setSelectedKey(
      item?.props.itemKey || TransactionLayout.TRAVEL_BOOKING_ROUND_TRIP
    );
  };

  const disablePivot = !_isUpdatable
    ? {
        disabled: true,
        style: { color: 'grey' },
      }
    : undefined;

  useEffect(() => {
    if (airTravelSectionItems) {
      const layoutType = airTravelSectionItems.tripSectionItems.nodes.find(
        (item) => item.id === tripSectionItemId
      )?.layoutType;
      setSelectedKey(layoutType!);
    }
  }, [airTravelSectionItems, tripSectionItemId]);

  return (
    <Stack>
      <Stack horizontal horizontalAlign="end">
        <Stack
          horizontal
          tokens={{ childrenGap: 20, padding: '0px 20px 0px 0px' }}
        >
          <Text variant="xLarge">Total </Text>
          <AmountTextView
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
            value={totalAmount?.toString() || '0.00'}
            variant="xLarge"
          />
        </Stack>
      </Stack>
      <Stack tokens={{ childrenGap: 30 }}>
        <Pivot selectedKey={selectedKey} onLinkClick={onLinkClick}>
          {tripSectionItems?.nodes.map((item) => {
            return (
              <PivotItem
                headerText={item.name}
                itemKey={item.layoutType!}
                key={item.id}
                headerButtonProps={disablePivot}
              />
            );
          })}
        </Pivot>
        <Stack tokens={{ childrenGap: 60 }}>
          <DepartureLayout
            baseField={baseField}
            tripIndex={tripIndex}
            tripData={tripData}
            isNew={isNew}
            onFareClassSelect={setSelectedFareClass}
            tripType={selectedKey}
            travelAuthorizationData={travelAuthorizationData}
          />
          {isNew ? (
            showReturnFields && (
              <ReturnLayout
                baseField={baseField}
                tripIndex={tripIndex}
                tripData={tripData}
                selectedKey={selectedKey}
                fareClass={selectedFareClass}
                travelAuthorizationData={travelAuthorizationData}
              />
            )
          ) : (
            <ReturnLayout
              baseField={baseField}
              tripIndex={tripIndex}
              tripData={tripData}
              selectedKey={selectedKey}
              fareClass={selectedFareClass}
              travelAuthorizationData={travelAuthorizationData}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const DepartureLayout: React.FC<DepartureLayoutProps> = ({
  baseField,
  tripIndex,
  tripData,
  isNew,
  tripType,
  onFareClassSelect,
  travelAuthorizationData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { watch, setValue } = useFormContext<TripCreateFormValues>();
  const carrierCalloutId = useId(`departureCarrier`);
  const departureFromDepot = useId(`departureFromDepot`);
  const departureToDepot = useId(`departureToDepot`);
  const watchStopModalItem = watch(`tripItems.${tripIndex}.stopModalItems`);

  const departureDepotName = useWatch({
    name: `tripItems.${tripIndex}.departureDepotName`,
  });
  const arrivalDepotName = useWatch({
    name: `tripItems.${tripIndex}.arrivalDepotName`,
  });
  const carrierName = useWatch({ name: `tripItems.${tripIndex}.carrierName` });

  const { _isUpdatable, _isNonAmountUpdatableAfterApproval } = {
    ...travelAuthorizationData,
  };

  const isDisabled = !!_isNonAmountUpdatableAfterApproval
    ? false
    : !_isUpdatable;

  const {
    isDepartureEconomyClass,
    isDeparturePremiumClass,
    isDepartureBusinessClass,
    isDepartureFirstClass,
    isDepartureCharterClass,
  } = {
    ...tripData?.tripItems.nodes[tripIndex],
  };

  const fareClassObject = {
    isDepartureEconomyClass,
    isDeparturePremiumClass,
    isDepartureBusinessClass,
    isDepartureFirstClass,
    isDepartureCharterClass,
  };
  const [selectedFareClass] =
    Object.entries(fareClassObject).find(([_key, value]) => value === true) ||
    [];

  const onFareClassChange = (selectedClass: string) => {
    if (tripType !== TransactionLayout.TRAVEL_BOOKING_ONE_WAY) {
      const returnKey = returnFlightClassOptions.find(
        (ele) => ele.text === selectedClass
      )?.key as keyof TripItemFormValues;
      if (returnKey) {
        const departureClass = returnKey as keyof TripItemFormValues;
        if (departureClass) {
          const keysToModify = returnFlightClassOptions.map(
            (ele) => ele.key! as keyof TripItemFormValues
          );
          keysToModify.forEach((ele) => {
            if (ele === departureClass) {
              setValue(`tripItems.${tripIndex}.${ele}`, true, {
                shouldDirty: true,
              });
            } else {
              setValue(`tripItems.${tripIndex}.${ele}`, false, {
                shouldDirty: true,
              });
            }
          });
        }
        onFareClassSelect(returnKey);
      }
    }
  };
  const isStopsIndicatorVisible = watchStopModalItem?.some(
    (item) => item.depotName !== null
  );

  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Text
          variant="large"
          className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
        >
          Departure
        </Text>
        <Icon iconName="ArrowTallUpRight" />
      </Stack>
      <Stack tokens={{ childrenGap: 20 }} grow>
        {/* FROM */}
        <Stack
          horizontal
          verticalAlign="end"
          horizontalAlign="space-between"
          tokens={{ childrenGap: 97 }}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DepotAutoComplete
              name={`${baseField}departureDepotId`}
              placeholder="Enter Location"
              label="From"
              labelId="departureFrom"
              lookUpCalloutWidth={300}
              disabled={isDisabled}
              id={departureFromDepot}
              depotData={departureDepotName}
              isAirport
              onDepotSelect={(data) => {
                if (data) {
                  setValue(
                    `tripItems.${tripIndex}.departureDepotName`,
                    data.description || null
                  );
                  if (
                    isNew &&
                    tripType === TransactionLayout.TRAVEL_BOOKING_ROUND_TRIP
                  ) {
                    setValue(
                      `tripItems.${tripIndex}.returnArrivalDepotId`,
                      data.id || null
                    );
                    setValue(
                      `tripItems.${tripIndex}.returnArrivalDepotName`,
                      data.description || null
                    );
                  }
                }
              }}
              onClear={() => {
                setValue(`tripItems.${tripIndex}.departureDepotName`, null);
              }}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack
                style={{
                  width: 160,
                }}
              >
                <FormHookDateSelect
                  label="Departure Date"
                  name={`${baseField}departureDate`}
                  placeholder="Select Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  disabled={isDisabled}
                  showMonthPickerAsOverlay
                  showGoToToday
                  onSelectDateCallback={(date) => {
                    if (date) {
                      setValue(
                        `tripItems.${tripIndex}.departureArrivalDate`,
                        date.toString() || null
                      );
                      if (
                        watchStopModalItem?.length === 1 &&
                        !watchStopModalItem?.[0].depotId
                      ) {
                        setValue(
                          `tripItems.${tripIndex}.stopModalItems.0.arriveDate`,
                          date.toString()
                        );
                        setValue(
                          `tripItems.${tripIndex}.stopModalItems.0.departDate`,
                          date.toString()
                        );
                      }
                    }
                  }}
                />
              </Stack>
              <Stack>
                <Label disabled={isDisabled}>Time</Label>
                <FormHookTimePicker
                  name={`${baseField}departureTime`}
                  disabled={isDisabled}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            grow={2}
            horizontal
            verticalAlign="end"
            tokens={{ childrenGap: 20 }}
          >
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="end">
              <FormHookAmount
                name={`${baseField}departureAirfare`}
                placeholder="Enter Air Fare"
                label="Air fare"
                fixedDecimalScale
                allowNegative={false}
                decimalScale={2}
                style={{ width: 160 }}
                disabled={!_isUpdatable}
              />
              <FormHookCheckBox
                label="Refundable"
                disabled={isDisabled}
                styles={{ root: { marginBottom: 10 } }}
                name={`${baseField}isDepartureRefundable`}
              />
            </Stack>
            <Stack grow={1}>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
              >
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 10 }}
                  className={styles.labelStop}
                  onClick={() => {
                    if (isStopsIndicatorVisible) {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  {isStopsIndicatorVisible && (
                    <Stack className={styles.badge} />
                  )}

                  <Label>Stops</Label>
                </Stack>

                {!isStopsIndicatorVisible && (
                  <IconButton
                    {...iconButtonProps}
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  />
                )}
                <StopsModal
                  isOpen={isModalOpen}
                  isDepart
                  onDismiss={() => {
                    setIsModalOpen(false);
                  }}
                  tripIndex={tripIndex}
                  disabled={isDisabled}
                  tripData={tripData}
                />
              </Stack>
              <Stack>
                <FormHookAmount
                  disabled={isDisabled}
                  name={`${baseField}departureStops`}
                  placeholder="Enter Stops"
                  style={{ width: 160 }}
                  fixedDecimalScale
                  allowNegative={false}
                  decimalScale={0}
                  maxLength={3}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* TO */}
        <Stack
          horizontal
          verticalAlign="end"
          horizontalAlign="space-between"
          tokens={{ childrenGap: 97 }}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack>
              <DepotAutoComplete
                name={`${baseField}departureArrivalDepotId`}
                placeholder="Enter Location"
                label="To"
                labelId="departureTo"
                lookUpCalloutWidth={300}
                disabled={isDisabled}
                id={departureToDepot}
                depotData={arrivalDepotName}
                isAirport
                onDepotSelect={(data) => {
                  if (data) {
                    setValue(
                      `tripItems.${tripIndex}.arrivalDepotName`,
                      data.description || null
                    );
                    if (
                      isNew &&
                      tripType === TransactionLayout.TRAVEL_BOOKING_ROUND_TRIP
                    ) {
                      setValue(
                        `tripItems.${tripIndex}.returnDepotId`,
                        data.id || null
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnDepotName`,
                        data.description || null
                      );
                    }
                  }
                }}
                onClear={() => {
                  setValue(`tripItems.${tripIndex}.arrivalDepotName`, null);
                }}
              />
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack style={{ width: 160 }}>
                <FormHookDateSelect
                  label="Arrival Date"
                  name={`${baseField}departureArrivalDate`}
                  placeholder="Select Arrival date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                  disabled={isDisabled}
                />
              </Stack>
              <Stack>
                <Label disabled={isDisabled}>Time</Label>
                <FormHookTimePicker
                  name={`${baseField}departureArrivalTime`}
                  disabled={isDisabled}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
            <FareClasses
              tripIndex={tripIndex}
              options={departureFlightClassOptions}
              selectedFareClass={selectedFareClass}
              onClassChange={onFareClassChange}
              disabled={isDisabled}
            />
            <Stack>
              <Stack horizontalAlign="center">
                <Label disabled={isDisabled}>Terminal No</Label>
              </Stack>
              <Stack horizontal verticalAlign="center">
                <FormHookTextField
                  name={`${baseField}departureTerminal`}
                  placeholder="Depart"
                  disabled={isDisabled}
                  underlined
                  style={{ width: 90 }}
                />
                <Text>-</Text>
                <FormHookTextField
                  name={`${baseField}departureTerminalArrival`}
                  placeholder="Arrive"
                  disabled={isDisabled}
                  underlined
                  style={{ width: 90 }}
                />
              </Stack>
            </Stack>

            <FormHookAmount
              name={`${baseField}departureMiles`}
              placeholder="Enter Miles"
              label="Miles"
              fixedDecimalScale
              allowNegative={false}
              decimalScale={0}
              disabled={isDisabled}
            />
          </Stack>
        </Stack>

        {/* CARRIER */}
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 117 }}>
          <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end">
            <Stack>
              <CarrierAutocomplete
                name={`${baseField}departureCarrierId`}
                placeholder="Enter Carrier"
                label="Carrier"
                lookUpCalloutWidth={300}
                disabled={isDisabled}
                isAirlineLookup
                id={carrierCalloutId}
                carrierData={carrierName}
                onCarrierSelect={(data) => {
                  if (data) {
                    setValue(
                      `tripItems.${tripIndex}.carrierName`,
                      data.description || null
                    );
                    if (tripType !== TransactionLayout.TRAVEL_BOOKING_ONE_WAY) {
                      setValue(
                        `tripItems.${tripIndex}.returnCarrierId`,
                        data.id || null
                      );
                      setValue(
                        `tripItems.${tripIndex}.returnCarrierName`,
                        data.description || null
                      );
                    }
                  }
                }}
                onClear={() => {
                  setValue(`tripItems.${tripIndex}.carrierName`, null);
                }}
              />
            </Stack>
            <Stack>
              <FormHookTextField
                label="Flight Number"
                name={`${baseField}departureCarrierTripNumber`}
                placeholder="Enter Flight Number"
                styles={{ root: { width: 150 } }}
                disabled={isDisabled}
              />
            </Stack>
            <Stack>
              <FormHookTextField
                disabled={isDisabled}
                label="Seat Number"
                name={`${baseField}departureSeat`}
                placeholder="Enter Seat Number"
                styles={{ root: { width: 150 } }}
              />
            </Stack>
          </Stack>
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <FormHookTextField
              disabled={isDisabled}
              label="Confirmation Code"
              name={`${baseField}departureCarrierConfirmationCode`}
              placeholder="Enter Confirmation Code"
              styles={{ root: { width: 180 } }}
            />
            <FormHookTextField
              disabled={isDisabled}
              label="E-Ticket #"
              name={`${baseField}departureEticketNumber`}
              placeholder="Enter E-Ticket"
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ReturnLayout: React.FC<ReturnLayoutProps> = ({
  baseField,
  tripData,
  tripIndex,
  selectedKey,
  fareClass,
  travelAuthorizationData,
}) => {
  const selectedTraveler = useReactiveVar(setSelectedTraveler);

  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setValue, watch } = useFormContext<TripCreateFormValues>();
  const calloutId = useId(`returnCarrier`);
  const returnFromDepot = useId(`returnFromDepot`);
  const returnToDepot = useId(`returnToDepot`);
  const arrivalDepotName = useWatch({
    name: `tripItems.${tripIndex}.arrivalDepotName`,
  });
  const departureArrivalDepotId = useWatch({
    name: `tripItems.${tripIndex}.departureArrivalDepotId`,
  });
  const returnDepotName = useWatch({
    name: `tripItems.${tripIndex}.returnDepotName`,
  });

  const returnArrivalDepotName = useWatch({
    name: `tripItems.${tripIndex}.returnArrivalDepotName`,
  });
  const watchStopModalReturnItems = watch(
    `tripItems.${tripIndex}.stopModalReturnItems`
  );
  const airTravelDetails = tripData?.tripItems?.nodes?.find(
    (item) =>
      item._tripSectionLayout === TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL
  );
  const [isReturnHome, setIsReturnHome] = useState(
    !!(
      airTravelDetails?.returnDepotId || airTravelDetails?.returnArrivalDepotId
    )
  );
  const [getFirstFlight, { data: firstFlightData }] = useLazyQuery<
    TravelerFirstFlight,
    TravelerFirstFlightVariables
  >(TRAVELER_FIRST_FLIGHT, {
    onCompleted(data) {
      setValue(
        `tripItems.${tripIndex}.returnArrivalDepotId`,
        data.travelerFirstFlight?._depotId || null
      );
      setValue(
        `tripItems.${tripIndex}.returnArrivalDepotName`,
        data.travelerFirstFlight?._depotDescription || null,
        { shouldDirty: true }
      );
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (!returnDepotName && !returnArrivalDepotName) setIsReturnHome(false);
  }, [returnDepotName, returnArrivalDepotName]);
  const departureCarrierId = useWatch({
    name: `tripItems.${tripIndex}.departureCarrierId`,
  });
  const carrierName = useWatch({
    name: `tripItems.${tripIndex}.carrierName`,
  });
  const returnCarrierName = useWatch({
    name: `tripItems.${tripIndex}.returnCarrierName`,
  });

  if (selectedKey === TransactionLayout.TRAVEL_BOOKING_ONE_WAY) return null;
  const {
    isReturnEconomyClass,
    isReturnPremiumClass,
    isReturnBusinessClass,
    isReturnFirstClass,
    isReturnCharterClass,
  } = {
    ...tripData?.tripItems.nodes[tripIndex],
  };

  const fareClassObject = {
    isReturnEconomyClass,
    isReturnPremiumClass,
    isReturnBusinessClass,
    isReturnFirstClass,
    isReturnCharterClass,
  };
  const [selectedFareClass] =
    Object.entries(fareClassObject).find(([key, value]) => value === true) ||
    [];
  const optionalLabel =
    selectedKey === TransactionLayout.TRAVEL_BOOKING_MULTI_TRIP
      ? '(Optional)'
      : '';
  const { _isUpdatable, _isNonAmountUpdatableAfterApproval } = {
    ...travelAuthorizationData,
  };
  const isDisabled = !!_isNonAmountUpdatableAfterApproval
    ? false
    : !_isUpdatable;
  const isStopsIndicatorVisible = watchStopModalReturnItems?.some(
    (item) => item.depotName !== null
  );
  const isReturnHomeClosed =
    selectedKey === TransactionLayout.TRAVEL_BOOKING_MULTI_TRIP &&
    !isReturnHome;
  const returnHomeSection =
    selectedKey === TransactionLayout.TRAVEL_BOOKING_MULTI_TRIP &&
    isReturnHome &&
    firstFlightData?.travelerFirstFlight?._depotId;

  return (
    <Stack>
      {selectedKey !== TransactionLayout.TRAVEL_BOOKING_MULTI_TRIP ? (
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
          <Text
            variant="large"
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
          >
            {`Return ${optionalLabel}`}
          </Text>
          <Icon iconName="ArrowTallDownLeft" />
        </Stack>
      ) : (
        <Stack horizontal tokens={{ childrenGap: 0 }} verticalAlign="center">
          {isReturnHome ? (
            <ActionButton
              iconProps={{ iconName: 'CalculatorSubtract' }}
              disabled={!_isUpdatable}
              onClick={() => {
                setIsReturnHome(false);
                setValue(`tripItems.${tripIndex}.returnArrivalDepotId`, null, {
                  shouldDirty: true,
                });
                setValue(
                  `tripItems.${tripIndex}.returnArrivalDepotName`,
                  null,
                  {
                    shouldDirty: true,
                  }
                );
                setValue(`tripItems.${tripIndex}.returnDepotId`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnDepotName`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnCarrierId`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnCarrierName`, null, {
                  shouldDirty: true,
                });
                setValue(
                  `tripItems.${tripIndex}.isReturnBusinessClass`,
                  false,
                  {
                    shouldDirty: true,
                  }
                );
                setValue(`tripItems.${tripIndex}.isReturnCharterClass`, false, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.isReturnEconomyClass`, false, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.isReturnFirstClass`, false, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.isReturnPremiumClass`, false, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnDate`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnTime`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnAirfare`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.isReturnRefundable`, false, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnStops`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnArrivalDate`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnArrivalTime`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnTerminal`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnTerminalArrival`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.returnMiles`, null, {
                  shouldDirty: true,
                });
                setValue(
                  `tripItems.${tripIndex}.returnCarrierTripNumber`,
                  null,
                  {
                    shouldDirty: true,
                  }
                );
                setValue(`tripItems.${tripIndex}.returnSeat`, null, {
                  shouldDirty: true,
                });
                setValue(
                  `tripItems.${tripIndex}.returnCarrierConfirmationCode`,
                  null,
                  {
                    shouldDirty: true,
                  }
                );
                setValue(`tripItems.${tripIndex}.returnEticketNumber`, null, {
                  shouldDirty: true,
                });
                setValue(`tripItems.${tripIndex}.stopModalReturnItems`, null, {
                  shouldDirty: true,
                });
              }}
            />
          ) : (
            <ActionButton
              iconProps={{ iconName: 'add' }}
              disabled={!_isUpdatable}
              onClick={() => {
                setIsReturnHome(true);
                setValue(
                  `tripItems.${tripIndex}.returnDepotId`,
                  departureArrivalDepotId || null
                );
                setValue(
                  `tripItems.${tripIndex}.returnDepotName`,
                  arrivalDepotName || null,
                  { shouldDirty: true }
                );
                setValue(
                  `tripItems.${tripIndex}.returnCarrierId`,
                  departureCarrierId || null
                );
                setValue(
                  `tripItems.${tripIndex}.returnCarrierName`,
                  carrierName || null
                );
                if (travelAuthorizationData?.id && selectedTraveler?.id) {
                  getFirstFlight({
                    variables: {
                      travelAuthorizationId: travelAuthorizationData?.id,
                      travelerId: selectedTraveler?.id,
                    },
                  });
                }
              }}
            />
          )}
          <Text
            variant="large"
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
          >
            {isReturnHome ? 'Return Home' : 'Returning home?'}
          </Text>
        </Stack>
      )}
      {(selectedKey !== TransactionLayout.TRAVEL_BOOKING_MULTI_TRIP ||
        isReturnHome) && (
        <Stack tokens={{ childrenGap: 20 }} grow>
          {/* FROM */}
          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 97 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DepotAutoComplete
                name={`${baseField}returnDepotId`}
                placeholder="Enter Location"
                label="From"
                labelId="returnDepotFrom"
                lookUpCalloutWidth={300}
                disabled={isDisabled}
                id={returnFromDepot}
                depotData={
                  returnHomeSection ? arrivalDepotName : returnDepotName
                }
                isAirport
                onDepotSelect={(data) => {
                  if (data) {
                    setValue(
                      `tripItems.${tripIndex}.returnDepotName`,
                      data.description || null
                    );
                  }
                }}
                onClear={() => {
                  setValue(`tripItems.${tripIndex}.returnDepotName`, null);
                }}
              />

              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack style={{ width: 160 }}>
                  <FormHookDateSelect
                    label="Return Date"
                    name={`${baseField}returnDate`}
                    placeholder="Select Date"
                    firstDayOfWeek={DayOfWeek.Sunday}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                    disabled={isDisabled}
                    onSelectDateCallback={(date) => {
                      if (date) {
                        setValue(
                          `tripItems.${tripIndex}.returnArrivalDate`,
                          date.toString() || null
                        );
                        if (
                          watchStopModalReturnItems?.length === 1 &&
                          !watchStopModalReturnItems?.[0].depotId
                        ) {
                          setValue(
                            `tripItems.${tripIndex}.stopModalReturnItems.0.arriveDate`,
                            date.toString()
                          );
                          setValue(
                            `tripItems.${tripIndex}.stopModalReturnItems.0.departDate`,
                            date.toString()
                          );
                        }
                      }
                    }}
                  />
                </Stack>

                <Stack>
                  <Label disabled={isDisabled}>Time</Label>
                  <FormHookTimePicker
                    name={`${baseField}returnTime`}
                    disabled={isDisabled}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              grow={2}
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 20 }}
            >
              <Stack
                horizontal
                tokens={{ childrenGap: 20 }}
                verticalAlign="end"
              >
                <ArrivalPrice
                  label="Air fare"
                  placeholder="Enter Air Fare"
                  name={`${baseField}returnAirfare`}
                  disabled={!_isUpdatable}
                  style={{ width: 160 }}
                />
                <FormHookCheckBox
                  name={`${baseField}isReturnRefundable`}
                  label="Refundable"
                  disabled={isDisabled}
                  styles={{ root: { marginBottom: 10 } }}
                />
              </Stack>
              <Stack grow={1}>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 10 }}
                >
                  <Stack
                    horizontal
                    verticalAlign="center"
                    tokens={{ childrenGap: 10 }}
                    className={styles.labelStop}
                    onClick={() => {
                      if (isStopsIndicatorVisible) {
                        setIsModalOpen(true);
                      }
                    }}
                  >
                    {isStopsIndicatorVisible && (
                      <Stack className={styles.badge} />
                    )}

                    <Label>Stops</Label>
                  </Stack>
                  {!isStopsIndicatorVisible && (
                    <IconButton
                      {...iconButtonProps}
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    />
                  )}
                  <StopsModal
                    isOpen={isModalOpen}
                    disabled={isDisabled}
                    onDismiss={() => {
                      setIsModalOpen(false);
                    }}
                    tripIndex={tripIndex}
                    tripData={tripData}
                  />
                </Stack>
                <Stack>
                  <FormHookAmount
                    name={`${baseField}returnStops`}
                    placeholder="Enter Stops"
                    style={{ width: 160 }}
                    fixedDecimalScale
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={3}
                    disabled={isDisabled}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          {/* TO */}
          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 97 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack>
                <DepotAutoComplete
                  name={`${baseField}returnArrivalDepotId`}
                  placeholder="Enter Location"
                  label="To"
                  labelId="returnArrivalDepot"
                  lookUpCalloutWidth={300}
                  disabled={isDisabled}
                  id={returnToDepot}
                  depotData={
                    isReturnHome &&
                    firstFlightData?.travelerFirstFlight?._depotDescription
                      ? firstFlightData?.travelerFirstFlight?._depotDescription
                      : returnArrivalDepotName
                  }
                  isAirport
                  onDepotSelect={(data) => {
                    if (data) {
                      setValue(
                        `tripItems.${tripIndex}.returnArrivalDepotName`,
                        data.description || null
                      );
                    }
                  }}
                  onClear={() => {
                    setValue(
                      `tripItems.${tripIndex}.returnArrivalDepotName`,
                      null
                    );
                  }}
                />
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack style={{ width: 160 }}>
                  <FormHookDateSelect
                    label="Arrival Date"
                    name={`${baseField}returnArrivalDate`}
                    placeholder="Select Date"
                    firstDayOfWeek={DayOfWeek.Sunday}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                    disabled={isDisabled}
                  />
                </Stack>
                <Stack>
                  <Label disabled={isDisabled}>Time</Label>
                  <FormHookTimePicker
                    name={`${baseField}returnArrivalTime`}
                    disabled={isDisabled}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
              <FareClasses
                tripIndex={tripIndex}
                options={returnFlightClassOptions}
                selectedFareClass={fareClass || selectedFareClass}
                disabled={isDisabled}
              />
              <Stack>
                <Stack horizontalAlign="center">
                  <Label disabled={isDisabled}>Terminal No</Label>
                </Stack>
                <Stack horizontal verticalAlign="center">
                  <FormHookTextField
                    name={`${baseField}returnTerminal`}
                    placeholder="Depart"
                    disabled={isDisabled}
                    underlined
                    style={{ width: 90 }}
                  />
                  <Text>-</Text>
                  <FormHookTextField
                    name={`${baseField}returnTerminalArrival`}
                    placeholder="Arrive"
                    disabled={isDisabled}
                    underlined
                    style={{ width: 90 }}
                  />
                </Stack>
              </Stack>
              <FormHookAmount
                name={`${baseField}returnMiles`}
                placeholder="Enter Miles"
                label="Miles"
                fixedDecimalScale
                allowNegative={false}
                decimalScale={0}
                disabled={isDisabled}
              />
            </Stack>
          </Stack>

          {/* CARRIER */}
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 117 }}
          >
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end">
              <Stack>
                <CarrierAutocomplete
                  name={`${baseField}returnCarrierId`}
                  placeholder="Enter Carrier"
                  label="Carrier"
                  lookUpCalloutWidth={300}
                  disabled={isDisabled}
                  isAirlineLookup
                  id={calloutId}
                  carrierData={isReturnHomeClosed ? null : returnCarrierName}
                  onCarrierSelect={(data) => {
                    if (data) {
                      setValue(
                        `tripItems.${tripIndex}.returnCarrierName`,
                        data.description || null
                      );
                    }
                  }}
                  onClear={() => {
                    setValue(`tripItems.${tripIndex}.returnCarrierName`, null);
                  }}
                />
              </Stack>

              <Stack>
                <FormHookTextField
                  label="Flight Number"
                  name={`${baseField}returnCarrierTripNumber`}
                  placeholder="Enter Flight Number"
                  styles={{ root: { width: 150 } }}
                  disabled={isDisabled}
                />
              </Stack>
              <Stack>
                <FormHookTextField
                  label="Seat Number"
                  name={`${baseField}returnSeat`}
                  placeholder="Enter Seat Number"
                  styles={{ root: { width: 150 } }}
                  disabled={isDisabled}
                />
              </Stack>
            </Stack>

            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <ConfirmationCode
                label="Confirmation Code"
                disabled={isDisabled}
                name={`${baseField}returnCarrierConfirmationCode`}
                placeholder="Enter Confirmation Code"
                styles={{ root: { width: 180 } }}
              />

              <ETicketField
                disabled={isDisabled}
                label="E-Ticket #"
                name={`${baseField}returnEticketNumber`}
                placeholder="Enter E-Ticket"
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
