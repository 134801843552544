import {
  Dropdown,
  IconButton,
  IDropdown,
  IDropdownOption,
  IDropdownProps,
  IRenderFunction,
  IStyle,
  makeStyles,
  Stack,
  Text,
  useTheme,
} from '@fluentui/react';
import React from 'react';

interface CustomDropdownProps extends IDropdownProps {
  onClear: () => void;
  underlined?: boolean;
  width?: number;

  isCustomTitle?: boolean;
}
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: '0px 4px',
  },
  iconContainer: {
    paddingTop: 30,
  },
}));

export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  onClear,
  underlined,
  isCustomTitle = false,
  ...props
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const dropdownRef = React.createRef<IDropdown>();
  const onRemoveFocus = () => dropdownRef.current!.focus(false);

  const disabledLabelStyles: IStyle = {
    title: {
      border: 0,
      outline: 0,
      backgroundColor: '#e9e9e900',
      borderBottom: `1px solid ${theme.semanticColors.inputBorder}`,
    },
  };

  const { selectedKey, required, disabled } = { ...props };
  const isRemoveIconVisible = !!selectedKey && !required && !disabled;

  const noLabelProvided = props.label === undefined;

  const onRenderTitle: IRenderFunction<IDropdownOption[]> = (innerProps) => {
    const title = innerProps?.[0].key ? innerProps?.[0].key.toString() : null;

    return (
      <Stack>
        <Text>{title}</Text>
      </Stack>
    );
  };

  const isSelectedOption = props.options.find(
    (item) => item.key === props.selectedKey
  );

  return (
    <Stack
      horizontal
      style={noLabelProvided ? { height: 35 } : undefined}
      verticalAlign="end"
      tokens={{ childrenGap: 4 }}
    >
      <Stack
        grow
        style={{ maxWidth: isRemoveIconVisible ? 'calc(100% - 40px)' : '100%' }}
      >
        <Dropdown
          title={isSelectedOption ? isSelectedOption.text : undefined}
          componentRef={dropdownRef}
          {...props}
          onRenderTitle={isCustomTitle ? onRenderTitle : undefined}
          styles={{
            ...props.styles,
            title: underlined
              ? props.disabled
                ? null
                : disabledLabelStyles.title
              : null,

            dropdown: props.width
              ? { width: isRemoveIconVisible ? props.width - 40 : props.width }
              : undefined,
          }}
        />
      </Stack>
      <Stack className={styles.iconContainer}>
        {isRemoveIconVisible && (
          <IconButton
            type="button"
            onClick={() => {
              onRemoveFocus();
              onClear();
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        )}
      </Stack>
    </Stack>
  );
};
