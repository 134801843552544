import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    trip: Yup.object({
      travelerId: Yup.string().required('Required').nullable(),
    }),
    tripItems: Yup.array().of(
      Yup.object().shape({
        departureDate: Yup.date().nullable(),
        departureArrivalDate: Yup.date()
          .min(
            Yup.ref('departureDate'),
            'Arrival date must be greater than Pickup date'
          )
          .nullable(),
        returnDate: Yup.date()
          .min(
            Yup.ref('departureDate'),
            'Arrival date must be greater than Pickup date'
          )
          .nullable(),
        returnArrivalDate: Yup.date()
          .min(
            Yup.ref('returnDate'),
            'Arrival date must be greater than Pickup date'
          )
          .nullable(),
        departureTime: Yup.string().nullable(),
        departureArrivalTime: Yup.string().nullable(),
        checkInDate: Yup.date().nullable(),
        checkOutDate: Yup.date()
          .min(
            Yup.ref('checkInDate'),
            'Check-Out date must be greater than Check-In date'
          )
          .nullable(),
      })
    ),
  });
