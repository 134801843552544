import { useQuery } from '@apollo/client';
import { Checkbox, ContextualMenu, ICheckboxStyles, IconButton, Modal, Stack, Text, TooltipHost } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import { useCommonStyles } from 'common/styles';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import { Badge } from 'documents/Filters/Badge';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { Header } from './Header';
import { GetPOInvoiceSchedules, GetPOInvoiceSchedulesVariables } from './__generated__/GetPOInvoiceSchedules';
import { columnHeadings } from './constants';
import { useStyles } from './index.styles';
const PO_INVOICE_SCHEDULES = loader("./GetPOInvoiceSchedules.graphql");

const checkboxStyles: Partial<ICheckboxStyles> = {
  checkbox: {
    borderRadius: "50%",
    height: 18,
    width: 18,
  },
  checkmark: {
    fontWeight: 900,
    fontSize: 10
  }
}

interface SelectedRecords {
  totalAmount: string;
  scheduleIds: string[];
}

interface SelectedRowsType {
  scheduleId: string;
  amount: string;
}
interface InvoiceSchedulesProps {
  document: EntityDocumentsFields;
  onRowSelect: (scheduleIds: SelectedRecords) => void;
}

export const InvoiceSchedules: React.FC<InvoiceSchedulesProps> = ({
  document,
  onRowSelect,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const calloutId = useId(`address${document.id}`);
  const [isOpenModal, { toggle: toggleIsOpenModal }] = useBoolean(false);
  const [hideDialog, setHideDialog] = useState(true);
  const [selectedRows, setSelectedRows] = useState<SelectedRowsType[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<SelectedRowsType>();

  const {
    data,
  } = useQuery<GetPOInvoiceSchedules, GetPOInvoiceSchedulesVariables>(
    PO_INVOICE_SCHEDULES,
    {
      variables: {
        purchaseOrderId: document.id
      },
      skip: !document.id,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  )

  const handleRowSelection = () => {
    const totalAmount = selectedRows.reduce(
      (n, { amount }) => new Decimal(amount || 0).plus(n),
      new Decimal(0)
    ).toNumber().toFixed(2).toString();
    const scheduleIdsArray = selectedRows.map(ele => ele.scheduleId)
    onRowSelect({
      totalAmount: totalAmount,
      scheduleIds: scheduleIdsArray
    })
  }

  const handleRowSelectionMemo = useCallback(handleRowSelection, [selectedRows])

  useEffect(() => {
    handleRowSelectionMemo()
  }, [handleRowSelectionMemo])

  const onConfirm = () => {
    setSelectedRows([...selectedRows, selectedSchedule!]);
    setHideDialog(true);
    setSelectedSchedule(undefined);
  }

  const onDismiss = () => {
    setHideDialog(true);
    setSelectedSchedule(undefined);
  }

  const handleSelectAll = (_ev?: React.FormEvent | undefined, checked?: boolean | undefined) => {
    if (checked) {
      const allSelectedRows: SelectedRowsType[] =
        data?.entityDocument?._poInvoiceSchedules.nodes
          .filter(ele => !ele.isPreviouslySelected)
          .map((ele) => ({ scheduleId: ele.scheduleId!, amount: ele.scheduleAmount! })) || [];
      setSelectedRows(allSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const selectableRows = data?.entityDocument?._poInvoiceSchedules?.nodes.filter(ele => !ele.isPreviouslySelected) || [];
  const isSelectAllChecked = selectedRows.length === selectableRows.length

  return (
    <Stack style={{ marginRight: 6 }}>
      <TooltipHost content={"Select Invoice Schedules"}>
        <Badge visible={selectedRows.length > 0} filtersCount={selectedRows.length}>
          <IconButton
            iconProps={{ iconName: 'TimeEntry' }}
            onClick={toggleIsOpenModal}
            id={calloutId}
            className={clsx({ [styles.iconButton]: selectedRows.length > 0 })}
          />
        </Badge>
      </TooltipHost>
      {
        isOpenModal &&
        <Modal
          isOpen={isOpenModal}
          isBlocking
          onDismiss={toggleIsOpenModal}
          dragOptions={{
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
            dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
          }}
        >
          <Header
            purchaseOrderNumber={document.indexReferenceNumber}
            onDismiss={toggleIsOpenModal}
          />
          <Stack className={styles.container}>
            <Stack horizontal className={styles.headerContainer} >
              {
                columnHeadings.map(
                  (heading, index) => {
                    return (
                      <Stack
                        key={index}
                      >
                        <Stack
                          verticalAlign="center"
                          className={clsx({
                            [styles.root]: true,
                            [styles.amountColumn]: heading.name === "Amount"
                          })}
                          horizontal
                          tokens={{ childrenGap: 20 }}
                          style={{
                            width: heading.width,
                          }}
                        >
                          {
                            index === 0 ?
                              <Checkbox
                                checked={isSelectAllChecked}
                                onChange={handleSelectAll}
                                styles={checkboxStyles}
                              />
                              :
                              <Text
                                nowrap
                                block
                                className={commonStyles.semibold}
                                variant="medium"
                              >
                                {heading.name}
                              </Text>
                          }
                        </Stack>
                      </Stack>
                    )
                  }
                )
              }
            </Stack>
            <Stack className={styles.content}>
              {
                data?.entityDocument?._poInvoiceSchedules?.nodes.map((item, index) => {
                  const { scheduleSequence, scheduleDate, scheduleNote, scheduleAmount,
                    invoiceNumber, isPreviouslySelected,
                  } = { ...item };
                  const isRowSelected = selectedRows.some(ele => ele.scheduleId === item.scheduleId)
                  return (
                    <Stack
                      horizontal
                      verticalAlign="center"
                      className={clsx({
                        [styles.item]: true,
                        [styles.itemHover]: true,
                        [styles.itemSelected]: isRowSelected
                      })}
                      key={index}
                    >
                      <Stack
                        horizontal
                        className={clsx(styles.selection, styles.rowCell)}
                      >
                        <Checkbox
                          checked={isRowSelected}
                          disabled={!!isPreviouslySelected}
                          onChange={(_e, checked) => {
                            if (checked) {
                              if (!isPreviouslySelected) {
                                setSelectedRows([...selectedRows, {
                                  scheduleId: item.scheduleId!,
                                  amount: item.scheduleAmount!
                                }])
                              }
                            } else {
                              const tempSelectedRows = selectedRows.filter(ele => ele.scheduleId !== item.scheduleId)
                              setSelectedRows(tempSelectedRows)
                            }
                          }}
                          styles={checkboxStyles}
                        />
                      </Stack>
                      <Stack
                        horizontal
                        className={clsx(styles.sequence, styles.rowCell)}
                      >
                        <Text>{scheduleSequence}</Text>
                      </Stack>
                      <Stack
                        className={clsx(styles.scheduleDate, styles.rowCell)}
                      >
                        <Text>{scheduleDate ? dateFormat(dateConvertions(scheduleDate)) : null}</Text>
                      </Stack>
                      <Stack
                        className={clsx(styles.scheduleNote, styles.rowCell)}
                      >
                        <Text>{scheduleNote}</Text>
                      </Stack>
                      <Stack
                        className={clsx(styles.invoiceNumber, styles.rowCell)}
                      >
                        <Text>{invoiceNumber}</Text>
                      </Stack>
                      <Stack
                        horizontalAlign="end"
                        className={clsx(styles.scheduleAmount, styles.rowCell)}
                      >
                        <AmountTextView value={scheduleAmount} />
                      </Stack>
                    </Stack>
                  );
                })
              }
              <ConfirmDialog
                hidden={hideDialog}
                title="Are you sure?"
                subText="This schedule has been applied to other transactions."
                onDismiss={onDismiss}
                minWidth={300}
                modalProps={{
                  isBlocking: true
                }}
                onConfirm={onConfirm}
              />
            </Stack>
          </Stack>
        </Modal>
      }
    </Stack >
  )
}
