import { makeStyles } from '@fluentui/react';
import { TRIP_MODAL_HEADER_HEIGHT } from '.';

export const useStyles = makeStyles((theme) => ({
  container: {
    // zIndex: 999,
    backgroundColor: theme.palette.neutralLighterAlt,
    // position: 'absolute',
    top: 0,
    left: 0,
    padding: '0px 20px',
    height: TRIP_MODAL_HEADER_HEIGHT,
    width: 1350,
    cursor: 'move',
  },
  innerContainer: {
    width: '100%',
  },
}));
