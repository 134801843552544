import { makeStyles, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { GridColumnProps } from '../coloumn.data';

interface HeaderProps {
  item: GridColumnProps;
}
export const Header: React.FC<HeaderProps> = ({ item }) => {
  const { flexProportion, headerName } = { ...item };

  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack style={{ flex: flexProportion }} className={styles.container}>
      <Text className={clsx(commonStyles.bold, styles.title)}>
        {headerName.toUpperCase()}
      </Text>
    </Stack>
  );
};

export const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    padding: '10px 10px',
  },
  title: {
    fontSize: 12,
  },
}));
