import {
  DirectionalHint,
  Icon,
  IconButton,
  IIconStyles,
  Label,
  Stack,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { AddressDetailsCallout } from '../AddressDetailsCallout';
import { TravelerModal } from './TravelerModal';
import { TravelerCreate_travelerCreate_traveler } from 'common/graphql/__generated__/TravelerCreate';
import { LookupTravelerSearch_lookupTravelerSearch_nodes } from '../__generated__/LookupTravelerSearch';
import { Trip_trip } from '../../../../__generated__/Trip';

interface TextFieldLabelProps {
  data?: any | null;
  disabled: boolean | undefined;
  showAddIcon?: boolean;
  label: string | undefined;
  required?: boolean | undefined;
  traveler: LookupTravelerSearch_lookupTravelerSearch_nodes | null;
  tripData: Trip_trip | null | undefined;
  onTravelerCreate: (param: TravelerCreate_travelerCreate_traveler) => void;
  onTravelerDelete: () => void;
}

export const TextFieldLabel: React.FC<TextFieldLabelProps> = ({
  disabled,
  showAddIcon,
  label,
  data,
  required = false,
  traveler,
  tripData,
  onTravelerCreate,
  onTravelerDelete,
}) => {
  const theme = useTheme();
  const iconButtonId: string = useId('iconButton');
  const calloutId = useId(`callOutId${data?.id}`);
  const iconStyles: Partial<IIconStyles> = {
    root: {
      color: theme.palette.themePrimary,
      margin: '6px 0px 0px 6px',
    },
  };
  const [travelerDialogVisible, setTravelerDialogVisible] =
    useState<boolean>(false);
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [isNew, setIsNew] = useState<boolean>(false);

  const { id, _isApproved } = { ...traveler };

  return (
    <Stack horizontal verticalAlign="baseline">
      {travelerDialogVisible && (
        <>
          <TravelerModal
            setOpen={setTravelerDialogVisible}
            onTravelerCreate={onTravelerCreate}
            selectedTravelerId={id}
            isNew={isNew}
            onTravelerDelete={onTravelerDelete}
            tripData={tripData}
          />
        </>
      )}
      <Label
        disabled={disabled ? true : false}
        id={calloutId}
        required={required}
        onMouseEnter={() => setCalloutVisible(true)}
        onMouseLeave={() => setCalloutVisible(false)}
      >
        {label}
      </Label>
      {calloutVisible && traveler && (
        <AddressDetailsCallout
          onDismiss={() => setCalloutVisible(false)}
          data={traveler!}
          detailsTargetId={calloutId}
          directionalHint={DirectionalHint.leftCenter}
        />
      )}
      {_isApproved && (
        <TooltipHost content={'Verified'}>
          <Icon iconName="CheckMark" styles={iconStyles} />
        </TooltipHost>
      )}

      {!id && (
        <>
          {!disabled && showAddIcon && (
            <TooltipHost content={'Add Traveler'}>
              <IconButton
                id={iconButtonId}
                iconProps={{ iconName: 'AddTo' }}
                styles={iconStyles}
                onClick={() => {
                  setIsNew(true);
                  setTravelerDialogVisible(true);
                }}
              />
            </TooltipHost>
          )}
        </>
      )}
      {!disabled && id && (
        <TooltipHost content={'Edit Traveler'}>
          <IconButton
            id={iconButtonId}
            iconProps={{ iconName: 'Edit' }}
            styles={iconStyles}
            onClick={() => {
              setIsNew(false);
              setTravelerDialogVisible(true);
            }}
          />
        </TooltipHost>
      )}
    </Stack>
  );
};
