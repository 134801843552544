import { IColumn, IDetailsColumnStyles } from "@fluentui/react";

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
export const columns: IColumn[] = [
  {
    key: 'fileReference',
    name: 'File',
    fieldName: 'fileReference',
    minWidth: 100,
    maxWidth: 160,
    isResizable: true
  },
  {
    key: 'indexName',
    name: 'Name',
    fieldName: 'indexName',
    minWidth: 100,
    maxWidth: 160,
    isResizable: true
  },
  {
    key: '_documentContents',
    name: 'Description',
    fieldName: '_documentContents',
    minWidth: 120,
    maxWidth: 180,
    isResizable: true
  },
  {
    key: 'indexReferenceNumber',
    name: 'Number',
    fieldName: 'indexReferenceNumber',
    minWidth: 160,
    maxWidth: 240,
    isResizable: true
  },
  {
    key: 'amount',
    name: 'Amount',
    fieldName: 'amount',
    minWidth: 110,
    maxWidth: 160,
    isResizable: true,
    styles: rightAlignHeaderStyle,
  },
];