import { useMutation } from '@apollo/client';
import { Stack } from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
import Decimal from 'decimal.js';
import { loader } from 'graphql.macro';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  TravelAuthorizationDelete,
  TravelAuthorizationDeleteVariables,
} from 'travelAuthorization/TravelPlan/__generated__/TravelAuthorizationDelete';
import { TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals } from 'travelAuthorization/TravelPlan/list/__generated__/TravelAuthorizationSearchFilterTotals';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
const DELETE_AUTH = loader('../../../../TravelAuthorizationDelete.graphql');

interface DeleteTraveAuthProps {
  isNew: boolean;
  disabled?: boolean;
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
}
export const DeleteTraveAuth: React.FC<DeleteTraveAuthProps> = ({
  travelAuthorizationData,
  isNew,
  disabled = false,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [deleteAuth] = useMutation<
    TravelAuthorizationDelete,
    TravelAuthorizationDeleteVariables
  >(DELETE_AUTH);

  const onConfirm = async () => {
    const { errors } = await deleteAuth({
      variables: {
        input: {
          entityDelete: [
            {
              id: travelAuthorizationData?.id!,
              rowTimestamp: travelAuthorizationData?._rowTimestamp!,
            },
          ],
        },
      },
      update: (cache, { data }) => {
        if (data?.travelAuthorizationDelete?.deletedEntities?.length) {
          const identity = cache.identify({
            ...travelAuthorizationData,
          });
          cache.evict({ id: identity });
          cache.gc();
          cache.modify({
            fields: {
              travelAuthorizationSearchFilterTotals: (
                existingData: TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals
              ) => {
                return {
                  ...existingData,
                  totalCount1: Number(existingData.totalCount1) - 1,
                  totalAmount1: new Decimal(existingData.totalAmount1 || '0')
                    .minus(travelAuthorizationData?.controlTotalAmount || '0')
                    .toNumber(),
                };
              },
            },
          });
        }
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record Deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/ta/travel-plan');
    }
  };

  return (
    <Stack>
      {!isNew && (
        <ActionMessageModal
          visible={!isNew}
          entityType={EntityType.TravelPlan}
          disabled={disabled}
          onConfirm={onConfirm}
        />
      )}
    </Stack>
  );
};
