import {
  AccountingViewFields_invoiceDistributions_nodes,
  AccountingViewFields_invoiceDistributions_nodes_businessUnit,
  AccountingViewFields_invoiceDistributions_nodes_department,
  AccountingViewFields_invoiceDistributions_nodes_productionIncentive,
  AccountingViewFields_invoiceDistributions_nodes_tax1099t4Type,
} from 'common/components/AccountingView/__generated__/AccountingViewFields';
import React from 'react';
import { ColumnData } from '../utils';
import { Stack, Text } from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';

interface AccountingRowProps {
  data: AccountingViewFields_invoiceDistributions_nodes;
  columns: ColumnData[];
}
type ProductionIncentiveType =
  AccountingViewFields_invoiceDistributions_nodes_productionIncentive;
type BusinessUnitType =
  AccountingViewFields_invoiceDistributions_nodes_businessUnit;
type DepartmentType =
  AccountingViewFields_invoiceDistributions_nodes_department;
type Tax1099t4Type =
  AccountingViewFields_invoiceDistributions_nodes_tax1099t4Type;

export const AccountingRow: React.FC<AccountingRowProps> = ({
  data,
  columns,
}) => {
  return (
    <Stack horizontal>
      {columns.map((item, index) => {
        const columnKey =
          item.key as keyof AccountingViewFields_invoiceDistributions_nodes;
        const columnData = !!data[columnKey] ? data[columnKey] : '-';
        switch (item.key) {
          case 'distributionAmount':
            return (
              <Stack
                key={index}
                style={{
                  height: 40,
                  width: item.maxWidth,
                  padding: '0px 10px',
                }}
                horizontalAlign="end"
                verticalAlign="center"
              >
                <AmountTextView
                  value={
                    isNaN(parseFloat(columnData?.toString() || ''))
                      ? '0.00'
                      : columnData?.toString() || '0.00'
                  }
                />
              </Stack>
            );
          case 'productionIncentive':
            const productionIncentive = data[
              'productionIncentive'
            ] as ProductionIncentiveType;
            const incentiveType =
              productionIncentive?.incentiveType?.incentiveType || '-';
            return (
              <Stack
                key={index}
                style={{
                  height: 40,
                  width: item.maxWidth,
                  padding: '0px 10px',
                }}
                horizontalAlign="center"
                verticalAlign="center"
              >
                <Text>{incentiveType}</Text>
              </Stack>
            );
          case 'businessUnit':
            const businessUnit = data['businessUnit'] as BusinessUnitType;
            const businessUnitName = businessUnit?.name || '-';
            return (
              <Stack
                key={index}
                style={{
                  height: 40,
                  width: item.maxWidth,
                  padding: '0px 10px',
                }}
                horizontalAlign="center"
                verticalAlign="center"
              >
                <Text>{businessUnitName}</Text>
              </Stack>
            );
          case 'department':
            const department = data['department'] as DepartmentType;
            const departmentName = department?.name || '-';
            return (
              <Stack
                key={index}
                style={{
                  height: 40,
                  width: item.maxWidth,
                  padding: '0px 10px',
                }}
                horizontalAlign="center"
                verticalAlign="center"
              >
                <Text>{departmentName}</Text>
              </Stack>
            );
          case 'tax1099t4Type':
            const tax1099t4Type = data['tax1099t4Type'] as Tax1099t4Type;
            const tax1099t4TypeName = tax1099t4Type?.tax1099T4Type || '-';
            return (
              <Stack
                key={index}
                style={{
                  height: 40,
                  width: item.maxWidth,
                  padding: '0px 10px',
                }}
                horizontalAlign="center"
                verticalAlign="center"
              >
                <Text>{tax1099t4TypeName}</Text>
              </Stack>
            );
          default:
            return (
              <Stack
                key={index}
                style={{
                  height: 40,
                  width: item.maxWidth,
                  padding: '0px 10px',
                }}
                horizontalAlign="center"
                verticalAlign="center"
              >
                <Text>{columnData}</Text>
              </Stack>
            );
        }
      })}
    </Stack>
  );
};
