import { ColumnActionsMode, IColumn } from '@fluentui/react';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';

export type ColumnData = IColumn & {
  /** property to determine whether this coloumn can be toggled for visibility */
  toggleVisibility?: boolean;
  /** property to determine whether the column is visble or not*/
  isVisible?: boolean;
  isFormField?: boolean;
};

export const getColumns = (
  data: UserDefaults_userDefaults_nodes | undefined
) => {
  const columns = new Map<string, ColumnData>([
    [
      'action',
      {
        key: 'action',
        name: '',
        fieldName: 'action',
        minWidth: 40,
        maxWidth: 40,
        columnActionsMode: ColumnActionsMode.disabled,
        isFormField: false,
      },
    ],

    [
      'depotId',
      {
        key: 'depotId',
        name: 'depotId',
        fieldName: 'depotId',
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [
      'arriveDate',
      {
        key: 'arriveDate',
        name: 'arriveDate',
        fieldName: 'arriveDate',
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [
      'arriveTime',
      {
        key: 'arriveTime',
        name: 'arriveTime',
        fieldName: 'arriveTime',
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [
      'arriveTerminal',
      {
        key: 'arriveTerminal',
        name: 'terminal',
        fieldName: 'arriveTerminal',
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [
      'departDate',
      {
        key: 'departDate',
        name: 'departDate',
        fieldName: 'departDate',
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    [
      'departTime',
      {
        key: 'departTime',
        name: 'departTime',
        fieldName: 'departTime',
        minWidth: 50,
        maxWidth: 50,
      },
    ],
  ]);

  let columnArray = [];
  columnArray = Array.from(columns, (data) => data[1]);
  return { columnArray };
};
