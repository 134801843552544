import React, { useState } from 'react';
import { SearchBar, SearchBarProps } from 'common/components/SearchBar';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { useStyles } from './index.styles';
import { Stack } from '@fluentui/react';
import { BatchCommonData } from '../../__generated__/BatchCommonData';

const COMMON_DATA = loader('../../BatchCommonData.graphql');

type BatchSearchBarProps = Omit<SearchBarProps, 'searchEnabled'> & {
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  transactionHistory?: boolean;
};

export const BatchSearchBar: React.FC<BatchSearchBarProps> = ({
  onFilterChange,
  transactionHistory = false,
  ...props
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: commonData } = useQuery<BatchCommonData>(COMMON_DATA);

  const styles = useStyles();
  const periodOptions = formatDropdownOptions(
    commonData?.companyCorporatePeriods?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item._periodYear!,
      includeAll: true,
    }
  );
  const currencyOptions = formatDropdownOptions(
    commonData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode!,
      includeAll: true,
    }
  );
  const batchUserOptions = formatDropdownOptions(
    commonData?.batchUsers?.nodes,
    {
      getKey: (item) => item.userId!,
      getText: (item) => item.userName!,
      includeAll: true,
    }
  );

  const batchTransactionWeeksOptions = formatDropdownOptions(
    commonData?.batchTransactionWeeks?.nodes.filter((item) =>
      transactionHistory ? item._isHistory : !item._isHistory
    ),
    {
      getKey: (item) => item.weekNumber,
      getText: (item) => item.weekNumber.toString(),
      includeAll: true,
    }
  );

  const statusOptions = formatDropdownOptions(
    commonData?.invoiceStatusTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType!,
      includeAll: true,
    }
  );

  const onOptionChange = (filterData: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterData, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      searchEnabled={false}
      {...props}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20 }}
        className={styles.filtersWrapperStyle}
      >
        <DropdownFilter
          options={batchTransactionWeeksOptions}
          placeholder={'Week#'}
          filterKey={'weekNumber'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          options={currencyOptions}
          placeholder={'Currency'}
          filterKey={'currencyId'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'corporatePeriodId'}
          options={periodOptions}
          placeholder={'Period'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'_createdByUserid'}
          options={batchUserOptions}
          placeholder={'Created by'}
          onOptionChange={onOptionChange}
        />
        {!transactionHistory && (
          <DropdownFilter
            filterKey="_statusTypeId"
            options={statusOptions}
            placeholder="Status"
            onOptionChange={onOptionChange}
          />
        )}
      </Stack>
    </SearchBar>
  );
};
