import { ColumnActionsMode } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'badgeName',
    name: 'Badge Name',
    fieldName: 'badgeName',
    minWidth: 180,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'badgeColor',
    name: 'Badge Color',
    fieldName: 'badgeColor',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },

  {
    key: 'isPrivate',
    name: 'Access Level ',
    fieldName: 'isPrivate',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: 'isMonitored',
    name: 'Monitored',
    fieldName: 'isMonitored',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: 'isAccessTag',
    name: 'Data Access',
    fieldName: 'isAccessTag',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: '_isSystemGenerated',
    name: 'System Generated',
    fieldName: '_isSystemGenerated',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: '_isActive',
    name: 'Status',
    fieldName: '_isActive',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'action',
    name: '',
    fieldName: 'action',
    minWidth: 100,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
    isResizable: false,
  },
];
