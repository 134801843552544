import React, { useState } from 'react';
import { Callout, SwatchColorPicker, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useStyles } from './index.styles';
import { colorCells } from './colors';
import { useField } from 'formik';
import { badgeColor } from 'settings/project/tagSetup/list/utils';

interface ColorPickerCalloutProps {
  name: string;
}
export const ColorPickerCallout: React.FC<ColorPickerCalloutProps> = ({
  name,
}) => {
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const buttonId = useId('callout-button');
  const labelId = useId('callout-label');
  const styles = useStyles();
  const [field, meta, helpers] = useField<string | undefined | null>(name);

  const colorPickerButton = meta.error
    ? styles.errorButtonContainer
    : styles.buttonContainer;

  return (
    <>
      <Text block id={labelId}>
        Badge Color
        <Text className={styles.requireTick}>*</Text>
      </Text>
      <DefaultButton
        id={buttonId}
        onClick={() => setIsCalloutVisible(true)}
        style={{
          backgroundColor: badgeColor(field.value!),
          marginLeft: 8,
        }}
        className={colorPickerButton}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={() => setIsCalloutVisible(false)}
        >
          <Text block variant="xLarge" className={styles.title} id={labelId}>
            Colors
          </Text>
          <SwatchColorPicker
            columnCount={6}
            cellShape={'square'}
            cellHeight={35}
            cellWidth={35}
            cellBorderWidth={2}
            colorCells={colorCells}
            selectedId={field.value || undefined}
            onChange={(_, id) => {
              helpers.setValue(id);
              setIsCalloutVisible(false);
            }}
          />
        </Callout>
      )}
    </>
  );
};
