import { TABLE_ROWS } from 'common/constants';
import { LookupAccountsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { LookupAccountSearchFields } from '.';
import { LookupAccountFields } from '../__generated__/LookupAccountFields';
import {
  LookupAccountCoaSearch,
  LookupAccountCoaSearchVariables,
} from './__generated__/LookupAccountCoaSearch';

type OrderLookup = {
  [direction in OrderDirection]: LookupAccountsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'lookupAccount',
    {
      [OrderDirection.ASC]: [LookupAccountsOrderBy.LOOKUP_ACCOUNT_ASC],
      [OrderDirection.DESC]: [LookupAccountsOrderBy.LOOKUP_ACCOUNT_DESC],
    },
  ],
  [
    'lookupName',
    {
      [OrderDirection.ASC]: [LookupAccountsOrderBy.LOOKUP_NAME_ASC],
      [OrderDirection.DESC]: [LookupAccountsOrderBy.LOOKUP_NAME_DESC],
    },
  ],
  [
    'setAccount',
    {
      [OrderDirection.ASC]: [LookupAccountsOrderBy.SORT_SET_ACCOUNT_ASC],
      [OrderDirection.DESC]: [LookupAccountsOrderBy.SORT_SET_ACCOUNT_DESC],
    },
  ],
  [
    'departmentGroupId',
    {
      [OrderDirection.ASC]: [
        LookupAccountsOrderBy.DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        LookupAccountsOrderBy.DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC,
      ],
    },
  ],
  [
    'travelAccountTypeId',
    {
      [OrderDirection.ASC]: [LookupAccountsOrderBy.TRAVEL_ACCOUNT_TYPE_ID_ASC],
      [OrderDirection.DESC]: [
        LookupAccountsOrderBy.TRAVEL_ACCOUNT_TYPE_ID_DESC,
      ],
    },
  ],
  [
    'projectAccount',
    {
      [OrderDirection.ASC]: [LookupAccountsOrderBy.SORT_PROJECT_ACCOUNT_ASC],
      [OrderDirection.DESC]: [LookupAccountsOrderBy.SORT_PROJECT_ACCOUNT_DESC],
    },
  ],
  [
    'corporateAccountName',
    {
      [OrderDirection.ASC]: [LookupAccountsOrderBy.SORT_CORPORATE_ACCOUNT_ASC],
      [OrderDirection.DESC]: [
        LookupAccountsOrderBy.SORT_CORPORATE_ACCOUNT_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const defaultOrderBy = [
    LookupAccountsOrderBy.LOOKUP_ACCOUNT_ASC,
    LookupAccountsOrderBy.LOOKUP_NAME_ASC,
    LookupAccountsOrderBy.PROJECT_ACCOUNT_ASC,
    LookupAccountsOrderBy.PRIMARY_KEY_ASC,
  ];
  const orderByArray = [LookupAccountsOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return defaultOrderBy;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const onHandleSearch = (
  lookupName: string | null,
  showMore: boolean,
  lookupAccountSearch: LookupAccountCoaSearch | undefined,
  chartOfAccountId: string,
  sortOrderParam: SortOrder | undefined
) => {
  const variables: LookupAccountCoaSearchVariables = {
    chartOfAccountId: chartOfAccountId!,
    first: TABLE_ROWS,
    after: showMore
      ? lookupAccountSearch?.lookupAccountCoaSearch?.pageInfo.endCursor
      : undefined,
    orderBy: toOrderByVariable(sortOrderParam),

    lookupName: lookupName || '',
  };
  return {
    queryVariables: variables,
  };
};

export const getTransformedData = (
  refetching: boolean,
  nodes: LookupAccountFields[] | undefined
) => {
  const result = refetching
    ? undefined
    : nodes?.map(
        (account) =>
          ({
            ...account,
            corporateAccountName: account.corporateAccount?.lookupAccount,
            department: account?.departmentGroup?.name || null,
            travelAccount: account.travelAccountType?.accountType || null,
          } as LookupAccountSearchFields)
      ) || [];

  return result;
};
