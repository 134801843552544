import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: `20px 20px 0px`,
  },
  centerAlign: {
    alignItems: 'center',
  },
  iconButtonColor: {
    color: theme.palette.neutralDark,
  },
  pivotDisplayWrapper: {
    padding: `10px 10px 10px 10px`,
    cursor: 'pointer',
  },
  width5: {
    width: 5,
  },
  width10: {
    width: 10,
  },
  pivotDisplayCount: {
    height: 25,
    alignSelf: 'center',
    borderRadius: 13,
    minWidth: 25,
    backgroundColor: theme.palette.themePrimary,
  },
  countColor: {
    color: 'white',
  },
}));
