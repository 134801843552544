import { DepartmentGroupItemsValues, DepartmentGroupValues } from './types';

export const DEPARTMENT_GROUP_ITEMS_INITIAL_VALUES: DepartmentGroupItemsValues =
  {
    departmentId: null,
  };

export const DEPARTMENT_GROUP_INITIAL_VALUES: DepartmentGroupValues = {
  name: null,
  description: null,
  isTravelDepartmentGroup: null,
  departmentGroupItems: [],
};
