import { makeVar, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  CommandBarButton,
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IContextualMenuProps,
  IDropdownOption,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  useTheme,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHookDropdown } from 'common/components/FormHooksFields';
import {
  PurchaseOrderItemInput,
  TransactionLayout,
} from 'common/types/globalTypes';
import Decimal from 'decimal.js';
import { loader } from 'graphql.macro';
import { PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals } from 'purchaseOrder/list/__generated__/PurchaseOrderSearchFilterTotals';
import { isPOCreated } from 'purchaseOrder/view';
import { PurchaseOrderItemProps } from 'purchaseOrder/view/FormView/BasicForm';
import { PurchaseOrder_purchaseOrder_purchaseOrderItems_nodes } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { PurchaseOrderCommonData } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { setUserDefaults } from 'utility/cache/ui';
import { showPurchaseOrderModal } from '..';
import { PurchaseOrderDataType } from '../..';
import {
  PurchaseOrderItem,
  PurchaseOrderItemVariables,
} from '../../__generated__/PurchaseOrderItem';
import {
  PurchaseOrderItemCreate,
  PurchaseOrderItemCreateVariables,
} from '../../__generated__/PurchaseOrderItemCreate';
import {
  PurchaseOrderItemUpdate,
  PurchaseOrderItemUpdateVariables,
} from '../../__generated__/PurchaseOrderItemUpdate';
import { Accounting } from './Accounting';
import { Additional } from './Additional';
import { DiscountItem } from './Discount';
import { Header } from './Header';
import { PurchaseTypeItem } from './PurchaseTypeItem';
import { RentalTypeItem } from './RentalTypeItem';
import { ShippingItem } from './Shipping';
import { TaxItem } from './TaxItem';
import { PURCHASE_ORDER_ITEM_INITIAL_VALUES } from './constant';
import { useStyles } from './index.styles';
import { PurchaseOrderItemFormProps } from './interface';
import {
  getDefaultValues,
  getNewDistributions,
  getPatchData,
  getRemovedDistributions,
  getUpdatedDistributions,
} from './utils';
import { validationSchema } from './validation';
import { ContractualItem } from './Contractual';
import { ModalWrapper } from 'common/components/ModalWrapper';

const PURCHASE_ORDER_ITEM = loader('../../PurchaseOrderItem.graphql');
const CREATE_PURCHASE_ORDER_ITEM = loader(
  '../../PurchaseOrderItemCreate.graphql'
);
const UPDATE_PURCHASE_ORDER_ITEM = loader(
  '../../PurchaseOrderItemUpdate.graphql'
);

interface POItemsFormProps {
  purchaseOrderData: PurchaseOrderDataType | undefined;
  selectedItem?:
    | PurchaseOrder_purchaseOrder_purchaseOrderItems_nodes
    | undefined;
  inputsDisabled: boolean;
  commonData: PurchaseOrderCommonData | undefined;
  purchaseOrderItemAvailable: PurchaseOrderItemProps[] | undefined;
  onUpdate?: () => void;
  businessUnitId: string | null;
  isOpen: boolean;
}

export const setRateCalculate = makeVar<boolean>(true);

export const POItemsForm: React.FC<POItemsFormProps> = ({
  commonData,
  purchaseOrderItemAvailable,
  purchaseOrderData,
  inputsDisabled,
  onUpdate,
  businessUnitId,
  isOpen,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const poModaReactiveProps = useReactiveVar(showPurchaseOrderModal);
  const { isNew, poItem } = { ...poModaReactiveProps };
  const { addToast } = useToasts();
  const styles = useStyles();
  const theme = useTheme();
  const [accountingVisibility, setAccountingVisibility] = useState<boolean>(
    userDefaults?.isAccountingAreaExpanded || false
  );
  const [selectedItemType, setSelectedItemType] = useState<
    PurchaseOrderItemProps | undefined
  >();
  const [createPurchaseOrderItem, { loading: isCreatingItem }] = useMutation<
    PurchaseOrderItemCreate,
    PurchaseOrderItemCreateVariables
  >(CREATE_PURCHASE_ORDER_ITEM);

  const [updatePurchaseOrderItem, { loading: isUpdatingItem }] = useMutation<
    PurchaseOrderItemUpdate,
    PurchaseOrderItemUpdateVariables
  >(UPDATE_PURCHASE_ORDER_ITEM);
  const { data, loading } = useQuery<
    PurchaseOrderItem,
    PurchaseOrderItemVariables
  >(PURCHASE_ORDER_ITEM, {
    skip: isNew,
    variables: {
      id: poItem?.id!,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const formMethods = useForm<PurchaseOrderItemFormProps>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const {
    reset,
    handleSubmit,
    trigger,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = { ...formMethods };

  const watchPurchaseOrderItemTypeId = useWatch({
    name: 'purchaseOrderItemTypeId',
    control,
  });

  const onHandleSubmit = async (
    values: PurchaseOrderItemFormProps,
    addMore: boolean,
    closeAfterComplete: boolean
  ) => {
    const { id, _rowTimestamp } = { ...purchaseOrderData?.purchaseOrder };
    if (!isNew) {
      if (
        data?.purchaseOrderItem?.id &&
        data?.purchaseOrderItem?._rowTimestamp
      ) {
        const { purchaseOrderItem } = { ...data };
        const defaultValues = getDefaultValues({ purchaseOrderItem });
        const itemPatch = getPatchData(values, defaultValues);
        const deletedDistributions = getRemovedDistributions(data, values);
        const newDistributions = getNewDistributions(values, userDefaults);
        const updatedDistributions = getUpdatedDistributions(
          values,
          defaultValues
        );

        const { errors } = await updatePurchaseOrderItem({
          variables: {
            input: {
              purchaseOrderItemId: data?.purchaseOrderItem?.id!,
              rowTimestamp: data?.purchaseOrderItem?._rowTimestamp!,
              purchaseOrderItemPatch: itemPatch,
              purchaseOrderItemDistributionDelete: deletedDistributions,
              purchaseOrderItemDistributionCreate: newDistributions,
              purchaseOrderItemDistributionUpdate: updatedDistributions,
            },
          },
        });
        if (errors?.length)
          addToast(errors?.[0].message, { appearance: 'error' });
        else {
          setRateCalculate(!addMore);
          addToast('Updated successfully', { appearance: 'success' });
          showPurchaseOrderModal({
            ...poModaReactiveProps,
            isModalOpen: !closeAfterComplete,
          });
          onUpdate?.();
        }
      } else {
        addToast('Purchase Order item not found', { appearance: 'error' });
      }
    } 
    
    else {
      let initialValues: PurchaseOrderItemFormProps =
        PURCHASE_ORDER_ITEM_INITIAL_VALUES;
      const purchaseOrderItemInput: PurchaseOrderItemInput = Object.entries(
        values
      ).reduce(
        (res, [key, val]) => {
          if (key === 'purchaseOrderItemDistribution') return res;
          if (key === 'itemDays') return { ...res, [key]: Number(val) };
          if (key === 'itemWeeks') return { ...res, [key]: Number(val) };
          if (val !== initialValues[key as keyof PurchaseOrderItemInput]) {
            return { ...res, [key]: val };
          }
          if (key === 'itemQuantity' && val === null)
            return { ...res, itemQuantity: '1' };
          return res;
        },
        {
          purchaseOrderItemTypeId: values.purchaseOrderItemTypeId!,
          itemExtendedAmount: values.itemExtendedAmount!,
        }
      );

      const fieldsToInclude = [
        'projectId',
        'referenceCode7',
        'accountCode',
        'referenceCode6',
        'projectReference',
        'setReference',
      ];
      const userDefaultsExits =
        userDefaults &&
        Object.entries(userDefaults).some(([key, val]) => {
          return fieldsToInclude.includes(key) && !!val;
        });

      const { errors, data } = await createPurchaseOrderItem({
        variables: {
          input: {
            purchaseOrderId: id!,
            rowTimestamp: _rowTimestamp!,
            purchaseOrderItem: purchaseOrderItemInput,
            purchaseOrderItemDistribution:
              values.purchaseOrderItemDistribution?.length === 1 &&
              userDefaultsExits
                ? null
                : getNewDistributions(values, userDefaults),
          },
        },
        update: (cache, { data }) => {
          try {
            cache.modify({
              fields: {
                purchaseOrderSearchFilterTotals(
                  existing: PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    const controlTotalAmount =
                      Number(existing?.totalAmount1) +
                      (Number(purchaseOrderItemInput?.itemExtendedAmount) ?? 0);
                    return {
                      ...existing,
                      totalAmount1: controlTotalAmount?.toString(),
                      totalCount1: existing?.totalCount1 || 0 + 1,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
              },
            });
          } catch (error) {}
        },
      });
      if (errors?.length)
        addToast(errors?.[0].message, { appearance: 'error' });
      else {
        setRateCalculate(!addMore);
        addToast('Added successfully', { appearance: 'success' });
      }
      if (data?.purchaseOrderItemCreate?.purchaseOrderItem) {
        showPurchaseOrderModal({
          isModalOpen: !closeAfterComplete,
          isNew: addMore,
          poItem: addMore
            ? undefined
            : data?.purchaseOrderItemCreate?.purchaseOrderItem,
        });
        if (addMore) {
          setAccountingVisibility(false);
          const defaultValues = getDefaultValues(
            {
              purchaseOrderItem: undefined,
            },
            userDefaults
          );
          reset({
            ...defaultValues,
            purchaseOrderItemTypeId: values.purchaseOrderItemTypeId,
          } as PurchaseOrderItemFormProps);
        }
      }
    }
  };

  const purchaseOrderItemsOptions: (IDropdownOption &
    PurchaseOrderItemProps)[] =
    purchaseOrderItemAvailable?.map((item) => ({
      key: item.purchaseOrderItemTypeId!,
      text: item.description! || '',
      disabled: !item.isOrderItemSelectable,
      ...item,
    })) || [];

  const itemTypeSelected = purchaseOrderItemsOptions.find(
    (item) =>
      item.purchaseOrderItemTypeId === selectedItemType?.purchaseOrderItemTypeId
  );

  const getLayout = () => {
    switch (selectedItemType?.layoutType) {
      case TransactionLayout.PO_ITEM_RENTAL:
        return (
          <RentalTypeItem
            isNew={isNew}
            item={data?.purchaseOrderItem}
            selectedItemType={selectedItemType}
            purchaseOrderData={purchaseOrderData}
            commonData={commonData}
          />
        );
      case TransactionLayout.PO_ITEM_PURCHASE:
        return (
          <PurchaseTypeItem
            isNew={isNew}
            item={data?.purchaseOrderItem}
            purchaseOrderData={purchaseOrderData}
            selectedItemType={selectedItemType}
            commonData={commonData}
          />
        );
      case TransactionLayout.PO_ITEM_CONTRACT:
        return (
          <ContractualItem
            isNew={isNew}
            item={data?.purchaseOrderItem}
            purchaseOrderData={purchaseOrderData}
            selectedItemType={selectedItemType}
            commonData={commonData}
          />
        );
      case TransactionLayout.PO_ITEM_TAX:
        return (
          <TaxItem
            isNew={isNew}
            item={data?.purchaseOrderItem}
            purchaseOrderData={purchaseOrderData}
            selectedItemType={selectedItemType}
            commonData={commonData}
          />
        );
      case TransactionLayout.PO_ITEM_DISCOUNT:
        return (
          <DiscountItem
            isNew={isNew}
            item={data?.purchaseOrderItem}
            purchaseOrderData={purchaseOrderData}
            selectedItemType={selectedItemType}
            commonData={commonData}
          />
        );
      case TransactionLayout.PO_ITEM_DELIVERY:
        return (
          <ShippingItem
            isNew={isNew}
            item={data?.purchaseOrderItem}
            purchaseOrderData={purchaseOrderData}
            selectedItemType={selectedItemType}
            commonData={commonData}
          />
        );
      default:
        return null;
    }
  };

  const { purchaseOrderItemTypeId } = { ...data?.purchaseOrderItem };
  const selectedType = purchaseOrderItemsOptions.find(
    (item) => item.purchaseOrderItemTypeId === purchaseOrderItemTypeId
  );

  const settingDefaultsCallback = useCallback(() => {
    const defaultValues = getDefaultValues(
      {
        purchaseOrderItem: data?.purchaseOrderItem,
      },
      userDefaults
    );
    reset(defaultValues);
    setSelectedItemType(selectedType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    settingDefaultsCallback();
  }, [data, settingDefaultsCallback]);

  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    isPOCreated(false);
    triggerCallBack();
  }, [triggerCallBack]);

  const watchDistribution = useWatch({
    name: 'purchaseOrderItemDistribution',
    control,
  });

  const totalAllocation = watchDistribution
    ? (watchDistribution?.reduce((sum: Decimal, current: any) => {
        return new Decimal(sum || 0.0).plus(current.allocationPercent || '0.0');
      }, new Decimal(0)) as Decimal)
    : new Decimal(0);
  const isAllocationError =
    watchDistribution?.length! > 1
      ? watchDistribution?.every((item: any) => {
          return (
            item.allocationPercent === null || item.allocationPercent === 'NaN'
          );
        })
        ? false
        : totalAllocation?.toNumber() !== 100
      : false;
  const fieldDisabled =
    !purchaseOrderData?.purchaseOrder?._isUpdatable && !isNew;
  const { _isUpdatable, _isDistributionUpdateAllowed } = {
    ...purchaseOrderData?.purchaseOrder,
  };
  const isSaveButtonEnabled = _isUpdatable
    ? true
    : _isDistributionUpdateAllowed;

  const isErrorsExists = Object.keys(errors).length > 0;

  const getMenuProps = () => {
    const newMenuProps: IContextualMenuProps = {
      items: [
        {
          key: 'saveAddNew',
          text: 'Save + Add New',
          onClick: () => {
            const func = handleSubmit((values) =>
              onHandleSubmit(values, isNew, false)
            );
            func();
          },
        },

        {
          key: 'save',
          text: 'Save',
          onClick: () => {
            const func = handleSubmit((values) =>
              onHandleSubmit(values, false, false)
            );
            func();
          },
        },
      ],
    };

    const editMenuProps: IContextualMenuProps = {
      items: [
        {
          key: 'saveClose',
          text: 'Save and close',
          onClick: () => {
            const func = handleSubmit((values) =>
              onHandleSubmit(values, false, true)
            );
            func();
          },
        },
      ],
    };

    return isNew ? newMenuProps : (editMenuProps as IContextualMenuProps);
  };

  const menuProps = getMenuProps();

  const disableNewButton = isErrorsExists || fieldDisabled || isDirty;
  const disableSaveButton =
    isErrorsExists ||
    !(isDirty && Object.keys(dirtyFields).length > 0) ||
    !isSaveButtonEnabled ||
    isAllocationError;

  return (
    <FormProvider {...formMethods}>
      <ModalWrapper
        isOpen={isOpen}
        isBlocking
        focusTrapZoneProps={{
          style: {
            overflowY: 'hidden',
          },
        }}
        dragOptions={{
          moveMenuItemText: 'Move',
          closeMenuItemText: 'Close',
          menu: ContextualMenu,
          dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
        }}
      >
        <Stack
          className={styles.modalHeaderContainer}
          style={{
            width: accountingVisibility ? 1500 : 1300,
          }}
        >
          <Header
            isNew={isNew}
            inputsDisabled={inputsDisabled}
            selectedItem={data?.purchaseOrderItem!}
            purchaseOrderData={purchaseOrderData}
          />
        </Stack>
        <Stack
          styles={{
            root: {
              minHeight: 650,
              maxHeight: accountingVisibility ? 1500 : 650,
              width: accountingVisibility ? 1500 : 1300,
              padding: '90px 0px 10px 0px',
            },
          }}
          tokens={{
            childrenGap: 10,
          }}
        >
          <Stack style={{ minHeight: 500 }}>
            {!loading && (
              <Stack
                verticalAlign="center"
                horizontalAlign="space-around"
                horizontal
                tokens={{
                  padding: '0px 20px 20px 20px',
                }}
              >
                <Stack grow={1}>
                  <FormHookDropdown
                    style={{
                      width: 250,
                    }}
                    disabled={
                      isNew
                        ? false
                        : fieldDisabled
                        ? true
                        : itemTypeSelected
                        ? !itemTypeSelected?.isOrderItemSelectable
                        : false
                    }
                    label="Item Type"
                    placeholder="Select"
                    options={purchaseOrderItemsOptions}
                    name="purchaseOrderItemTypeId"
                    onChange={(_, option) => {
                      const newOption = { ...option } as IDropdownOption &
                        PurchaseOrderItemProps;
                      setSelectedItemType(newOption || null);
                    }}
                    required
                  />
                </Stack>

                <Stack
                  tokens={{ childrenGap: 20 }}
                  horizontal
                  horizontalAlign="end"
                  verticalAlign="center"
                >
                  {watchPurchaseOrderItemTypeId && (
                    <Stack horizontal verticalAlign={'center'}>
                      {!!data?.purchaseOrderItem?.purchaseOrderItemDistributions
                        ?.nodes?.length && <Stack className={styles.badge} />}
                      <CommandBarButton
                        styles={{
                          root: {
                            height: 40,
                            backgroundColor: accountingVisibility
                              ? theme.palette.neutralLighterAlt
                              : theme.palette.white,
                          },
                        }}
                        iconProps={{ iconName: 'AddToShoppingList' }}
                        text="Accounting"
                        onClick={() =>
                          setAccountingVisibility((value) => !value)
                        }
                      />
                    </Stack>
                  )}
                  {!isNew && (
                    <Stack grow={1} tokens={{ childrenGap: 20 }}>
                      <Additional disabled={fieldDisabled} />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            )}

            {loading && (
              <Stack style={{ padding: 40 }}>
                <ProgressIndicator label="Loading Item" />
              </Stack>
            )}
            {getLayout()}
            <Stack>
              {accountingVisibility && (
                <Accounting
                  purchaseOrderItem={data?.purchaseOrderItem}
                  purchaseOrder={purchaseOrderData}
                  businessUnitId={businessUnitId}
                />
              )}
            </Stack>
          </Stack>
        </Stack>

        {/* Footer */}
        <Stack
          className={styles.footer}
          style={{
            width: accountingVisibility ? 1500 : 1300,
          }}
        >
          {(isCreatingItem || isUpdatingItem) && (
            <Stack tokens={{ padding: '0px 10px' }}>
              <ProgressIndicator />
            </Stack>
          )}

          <Stack
            verticalAlign="center"
            horizontalAlign="end"
            horizontal
            tokens={{
              padding:
                isCreatingItem || isUpdatingItem
                  ? undefined
                  : '10px 0px 0px 0px',
              childrenGap: 20,
            }}
          >
            {!isNew && (
              <NewButton
                isDirty={isDirty}
                disabled={disableNewButton}
                onConfirm={() => {
                  showPurchaseOrderModal({
                    isModalOpen: true,
                    isNew: true,
                    poItem: undefined,
                  });
                  reset();
                }}
              />
            )}

            <PrimaryButton
              text={isNew ? 'Save and close' : 'Save'}
              menuProps={menuProps}
              split
              disabled={disableSaveButton}
              onClick={handleSubmit((values) => {
                const func = handleSubmit((values) =>
                  onHandleSubmit(values, false, isNew)
                );
                func();
              })}
            />

            <CancelButton
              onConfirm={() => {
                showPurchaseOrderModal({
                  isModalOpen: false,
                  isNew: true,
                  poItem: undefined,
                });
              }}
              isDirty={isDirty && Object.keys(dirtyFields).length > 0}
            />
          </Stack>
        </Stack>
      </ModalWrapper>
    </FormProvider>
  );
};

const dialogStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
  type: DialogType.normal,
  title: 'Warning',
  closeButtonAriaLabel: 'Close',
  subText: 'Are you sure you want to leave without changes unsaved?',
};

export const NewButton: React.FC<{
  isDirty: boolean;
  disabled: boolean;
  onConfirm: () => void;
}> = ({ disabled, isDirty, onConfirm }) => {
  const [visible, setVisible] = useState(false);

  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: dialogStyles,
    }),
    []
  );

  return (
    <Stack>
      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                onConfirm();
                setVisible(false);
              }}
              text="Leave"
            />
            <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}
      <DefaultButton
        text="+ New"
        disabled={disabled}
        onClick={() => {
          if (isDirty) {
            setVisible(true);
          } else onConfirm();
        }}
      />
    </Stack>
  );
};

const cancelDialogContentProps = {
  type: DialogType.normal,
  title: 'Warning',
  closeButtonAriaLabel: 'Close',
  subText: 'Are you sure you want to leave without saving changes?',
};

export const CancelButton: React.FC<{
  isDirty: boolean;
  onConfirm: () => void;
}> = ({ isDirty, onConfirm }) => {
  const [visible, setVisible] = useState(false);

  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: dialogStyles,
    }),
    []
  );

  return (
    <Stack>
      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={cancelDialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                onConfirm();
                setVisible(false);
              }}
              text="Leave"
            />
            <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}

      <DefaultButton
        onClick={() => {
          if (isDirty) {
            setVisible(true);
          } else onConfirm();
        }}
        text="Cancel"
      />
    </Stack>
  );
};
