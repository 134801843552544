import { Stack, Text, Separator } from '@fluentui/react';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { useStyles } from './index.styles';
import { ApprovalState } from 'common/types/globalTypes';
import clsx from 'clsx';
import { ApprovalValues } from '../types';
import { REQUESTOR_INITIAL_VALUES } from '../constants';
import { ApprovalSetupCommonData } from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { RequestorConditionsColumns } from './RequestorConditionsColumns';
import { RequestorConditionsRows } from './RequestorConditionsRows';

export const REQ_COND_COLUMNS = {
  importanceLevel: { width: 110, title: 'Sequence' },
  description: { width: 130, title: 'Comment' },
  startAmountTotal: { width: 110, title: 'Amount From' },
  endAmountTotal: { width: 120, title: 'Amount To' },
  requesterCurrencyId: { width: 180, title: 'Currency' },
  amountConversionTypeId: { width: 180, title: 'Conversion' },
  requesterUserOccupationTitleId: { width: 180, title: 'Occupation' },

  requesterDepartmentId: { width: 180, title: 'Department' },
  requesterDepartmentTypeId: { width: 180, title: 'Department Types' },
  requesterDepartmentGroupId: { width: 180, title: 'Department Group' },
  startElapsedDays: { width: 150, title: 'Elapsed Days From' },
  endElapsedDays: { width: 150, title: 'Elapsed Days To' },
  requesterTagId: { width: 180, title: 'Tag' },
  requesterUserRankId: { width: 180, title: 'Rank' },
  requesterCompanyId: { width: 180, title: 'Company' },
  requesterBusinessUnitId: { width: 180, title: 'Business Unit' },
  requesterUserId: { width: 180, title: 'User' },

  requesterSecureRowLevelId: { width: 180, title: 'Row Security' },

  requesterPurchaseOrderTypeId: { width: 180, title: 'PO Type' },
  isAutoApprovedEligible: { width: 160, title: 'Auto Approved' },

  isAndCondition: { width: 160, title: 'AND' },
};

interface DisableConditionType {
  statusType: string | null | undefined;
}
interface RequestorConditionsProps {
  approvalTypeData: ApprovalSetupCommonData | undefined;
  disableCondition?: DisableConditionType;
  deleteRow: (id: string, _rowTimestamp: string | null) => void;
  aggregate: number | null;
  isNew?: boolean;
  isUpdateble: boolean;
}
export const RequestorConditionsView: React.FC<RequestorConditionsProps> = ({
  approvalTypeData,
  disableCondition,
  deleteRow,
  isUpdateble,
  isNew,
}) => {
  const { values } = useFormikContext<ApprovalValues>();
  const styles = useStyles();

  const disableBasedOnApprovalState =
    disableCondition?.statusType === ApprovalState.APPROVED ||
    disableCondition?.statusType === ApprovalState.PENDING;

  return (
    <Stack className={styles.mainContainer} tokens={{ childrenGap: 20 }}>
      <Text variant="xLarge">Requestor Conditions</Text>
      <Stack className={styles.scrollboxWrapper}>
        <Stack horizontal>
          <RequestorConditionsColumns />
        </Stack>
        <Separator className={styles.separator} />
        <Stack>
          <FieldArray name="approvalSelectionConditions">
            {({ remove, insert }) => (
              <>
                {values.approvalSelectionConditions?.map((item, index) => {
                  const approvalSelectionConditionsName = `approvalSelectionConditions[${index}]`;
                  return (
                    <Stack
                      horizontal
                      key={index}
                      className={clsx(
                        index !== 0 && styles.accountingRowMargin
                      )}
                    >
                      <RequestorConditionsRows
                        deleteCurrentRow={(removeIndex: number) => {
                          remove(removeIndex);
                          if (item.id) {
                            deleteRow(item.id, item._rowTimestamp);
                          }
                        }}
                        isDisabled={isUpdateble}
                        isDisabledBasedOnApprovalState={
                          disableBasedOnApprovalState
                        }
                        insertAdditionalRow={() =>
                          insert(
                            values.approvalSelectionConditions?.length!,
                            REQUESTOR_INITIAL_VALUES
                          )
                        }
                        index={index}
                        lastRow={
                          values.approvalSelectionConditions?.length! - 1
                        }
                        distributionTypes={approvalTypeData!}
                        data={item}
                        approvalSelectionConditionsName={
                          approvalSelectionConditionsName
                        }
                        isNew={isNew}
                      />
                    </Stack>
                  );
                })}
              </>
            )}
          </FieldArray>
        </Stack>
      </Stack>
    </Stack>
  );
};
