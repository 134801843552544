import React, { useEffect, useState } from 'react';
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { Form, Formik } from 'formik';
import { validationSchema } from './validations';
import { FormikTextField } from 'common/components';
import { loader } from 'graphql.macro';
import { intersection, isEmpty } from 'lodash';

import { useMutation, useQuery } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import {
  CommunicationGroupValues,
  CommunicationGroupItemValues,
} from './types';
import { COMMUNICATION_GROUP_INITIAL_VALUES } from './constant';
import { TABLE_ROWS } from 'common/constants';
import {
  CommunicationGroups,
  CommunicationGroupsVariables,
  CommunicationGroups_communicationGroups_nodes,
} from '../list/__generated__/CommunicationGroups';
import {
  CommunicationGroupCreate,
  CommunicationGroupCreateVariables,
} from './__generated__/CommunicationGroupCreate';
import {
  CommunicationChannelsOrderBy,
  CommunicationGroupInput,
  CommunicationGroupItemInput,
  CommunicationGroupItemsOrderBy,
  CommunicationGroupItemUpdateTypeInput,
  CommunicationGroupsOrderBy,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import {
  CommunicationGroupUpdate,
  CommunicationGroupUpdateVariables,
} from './__generated__/CommunicationGroupUpdate';
import { ToggleButton } from 'common/components/Buttons';
import {
  FormikCommunicationGroupItem,
  GroupItemSelectOption,
} from './FormikCommunicationGroupItem';
import {
  CommunicationChannels_communicationChannels_nodes,
  CommunicationChannels,
} from 'settings/project/communicationChannels/list/__generated__/CommunicationChannels';

const COMMUNICATION_GROUPS = loader('../list/CommunicationGroups.graphql');
const CREATE_COMMUNICATION_GROUP = loader('./CommunicationGroupCreate.graphql');
const UPDATE_COMMUNICATION_GROUP = loader('./CommunicationGroupUpdate.graphql');
const COMMUNICATION_CHANNEL = loader(
  '../../communicationChannels/list/CommunicationChannels.graphql'
);
interface CommunicationGroupFormProps {
  channelData: CommunicationGroups_communicationGroups_nodes | null;
  visible: boolean;
  onDismiss: () => void;
  isNew: boolean;
}
export const CreateCommunicationGroupForm: React.FC<
  CommunicationGroupFormProps
> = ({ visible, onDismiss, channelData, isNew }) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const [
    tempSelectedCommunicationGroupItem,
    setTempSelectedCommunicationGroupItem,
  ] = useState<GroupItemSelectOption[]>([]);
  const [communicationChannel, setCommunicationChannel] = useState<
    CommunicationChannels_communicationChannels_nodes[]
  >([]);

  const {
    loading: communicationChannelListLoading,
    data: communicationChannelListData,
  } = useQuery<CommunicationChannels>(COMMUNICATION_CHANNEL, {
    variables: {
      orderBy: [CommunicationChannelsOrderBy.NAME_ASC],
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  let initialValues: CommunicationGroupValues =
    COMMUNICATION_GROUP_INITIAL_VALUES;
  if (channelData) {
    initialValues = {
      name: channelData.name,
      description: channelData.description,
      communicationGroupItems:
        channelData.communicationGroupItemsByCommunicationGroupId.nodes.map(
          (ele) => ({
            id: ele.id,
            communicationChannelId: ele.communicationChannelId,
            _communicationChannelName: ele._communicationChannelName,
            isSenderOnly: ele.isSenderOnly,
            isRecipientOnly: ele.isRecipientOnly,
            _rowTimestamp: ele._rowTimestamp,
            isDeleted: false,
            communicationChannel: ele.communicationChannel,
          })
        ),
    };
  }

  useEffect(() => {
    if (channelData) {
      setTempSelectedCommunicationGroupItem(
        channelData.communicationGroupItemsByCommunicationGroupId.nodes.map(
          (ele) => ({
            id: ele.id,
            communicationChannelId: ele.communicationChannelId,
            communicationChannelName: ele._communicationChannelName!,
            isSenderOnly: ele.isSenderOnly!,
            isRecipientOnly: ele.isRecipientOnly!,
            _isAlert: ele.communicationChannel?._isAlerts!,
            _rowTimestamp: ele._rowTimestamp!,
            isDeleted: false,
          })
        )
      );
    }
  }, [channelData]);

  useEffect(() => {
    if (communicationChannelListData?.communicationChannels?.nodes) {
      if (tempSelectedCommunicationGroupItem.length > 0) {
        const filteredArray =
          communicationChannelListData.communicationChannels.nodes.filter(
            (obj) =>
              !tempSelectedCommunicationGroupItem.some(
                (ele) => ele.communicationChannelId === obj.id
              )
          );
        setCommunicationChannel(filteredArray);
      } else {
        setCommunicationChannel(
          communicationChannelListData.communicationChannels.nodes
        );
      }
    }
  }, [communicationChannelListData, tempSelectedCommunicationGroupItem]);

  const [
    createCommunicationGroup,
    { loading: createCommunicationChannelLoading },
  ] = useMutation<CommunicationGroupCreate, CommunicationGroupCreateVariables>(
    CREATE_COMMUNICATION_GROUP,
    {
      errorPolicy: 'all',
    }
  );

  const [
    updateCommunicationGroup,
    { loading: updateCommunicationGrouplLoading },
  ] = useMutation<CommunicationGroupUpdate, CommunicationGroupUpdateVariables>(
    UPDATE_COMMUNICATION_GROUP,
    {
      errorPolicy: 'all',
    }
  );

  const handleSubmit = async (values: CommunicationGroupValues) => {
    const { communicationGroupItems, ...communicationGroup } = values;
    if (isNew) {
      const { errors } = await createCommunicationGroup({
        variables: {
          input: {
            communicationGroup: communicationGroup as CommunicationGroupInput,
            communicationGroupItems: communicationGroupItems
              ?.filter((ele) => !ele.isDeleted)
              .map(
                ({ ...group }) =>
                ({
                  communicationChannelId: group.communicationChannelId,
                  isSenderOnly: group.isSenderOnly,
                  isRecipientOnly: group.isRecipientOnly,
                } as CommunicationGroupItemInput)
              ),
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<
            CommunicationGroups,
            CommunicationGroupsVariables
          >({
            query: COMMUNICATION_GROUPS,
            variables: {
              first: TABLE_ROWS,
              orderBy: [CommunicationGroupsOrderBy.NAME_ASC],
              communicationGroupItems: [
                CommunicationGroupItemsOrderBy.COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NAME_ASC,
              ],
            },
          });
          if (
            cacheData?.communicationGroups &&
            data?.communicationGroupCreate?.communicationGroup
          ) {
            const updatedChannelsData: CommunicationGroups = {
              communicationGroups: {
                ...cacheData?.communicationGroups!,
                pageInfo: cacheData?.communicationGroups?.pageInfo!,
                totalCount: cacheData?.communicationGroups?.totalCount! + 1,
                nodes: [
                  data?.communicationGroupCreate?.communicationGroup!,
                  ...cacheData?.communicationGroups?.nodes!,
                ],
              },
            };
            cache.writeQuery<CommunicationGroups, CommunicationGroupsVariables>(
              {
                query: COMMUNICATION_GROUPS,
                data: updatedChannelsData,
                variables: {
                  first: TABLE_ROWS,
                  orderBy: [CommunicationGroupsOrderBy.NAME_ASC],
                  communicationGroupItems: [
                    CommunicationGroupItemsOrderBy.COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NAME_ASC,
                  ],
                },
              }
            );
          }
        },
      });
      if (!errors) {
        addToast('Communication group created successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    } else {
      const newGroupItems =
        communicationGroupItems &&
        communicationGroupItems
          .filter((item) => !item.id && !item.isDeleted)
          .map(
            (ele) =>
            ({
              communicationChannelId: ele.communicationChannelId,
              isSenderOnly: ele.isSenderOnly,
              isRecipientOnly: ele.isRecipientOnly,
            } as CommunicationGroupItemInput)
          );
      const updatedGroupItems =
        initialValues.communicationGroupItems &&
        communicationGroupItems &&
        intersection(
          initialValues.communicationGroupItems.map((group) => group.id),
          communicationGroupItems
            .filter((group) => group.id && !group.isDeleted)
            .map((group) => group.id)
        ).reduce((groupArray, targetId) => {
          const initialGroupItems = initialValues.communicationGroupItems!.find(
            (group) => group.id === targetId
          )!;
          const {
            id,
            _rowTimestamp,
            communicationChannelId,
            _communicationChannelName,
            ...updatedGroup
          } = communicationGroupItems!.find((group) => group.id === targetId)!;
          const patch = Object.entries(updatedGroup).reduce(
            (res, [key, val]) => {
              if (
                val !==
                initialGroupItems[key as keyof CommunicationGroupItemValues]
              )
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...groupArray,
              {
                id,
                rowTimestamp: _rowTimestamp,
                communicationGroupItemPatch: patch,
              },
            ];
          return groupArray;
        }, [] as CommunicationGroupItemUpdateTypeInput[]);

      // const deletedGroupItems =
      //   initialValues.communicationGroupItems &&
      //   (differenceBy(initialValues.communicationGroupItems, communicationGroupItems || [], 'id').map(
      //     (group) => ({
      //       id: group.id!,
      //       rowTimestamp: group._rowTimestamp!,
      //     })
      //   ) as EntityDeleteInput[]);

      const deletedGroupItems = communicationGroupItems
        ?.filter(({ id: id1, isDeleted: isDeleted1 }) =>
          initialValues.communicationGroupItems?.some(
            ({ id: id2 }) => id2 === id1 && isDeleted1
          )
        )
        .map((ele) => ({
          id: ele.id,
          rowTimestamp: ele._rowTimestamp,
        })) as EntityDeleteInput[];

      const { errors } = await updateCommunicationGroup({
        variables: {
          input: {
            id: channelData?.id!,
            rowTimestamp: channelData?._rowTimestamp!,
            communicationGroupPatch:
              communicationGroup as CommunicationGroupInput,
            communicationGroupItemsCreate:
              newGroupItems && newGroupItems.length > 0
                ? newGroupItems
                : undefined,
            communicationGroupItemsUpdate:
              updatedGroupItems && updatedGroupItems.length > 0
                ? updatedGroupItems
                : undefined,
            communicationGroupItemsDelete:
              deletedGroupItems && deletedGroupItems.length > 0
                ? deletedGroupItems
                : undefined,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: COMMUNICATION_GROUPS,
            variables: {
              first: TABLE_ROWS,
              orderBy: [CommunicationGroupsOrderBy.NAME_ASC],
              communicationGroupItems: [
                CommunicationGroupItemsOrderBy.COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NAME_ASC,
              ],
            },
          },
        ],
      });
      if (!errors) {
        addToast('Communication group updated successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };

  const headerTitle = isNew ? 'Create Group' : 'Update Group';

  return (
    <Stack>
      <Formik<CommunicationGroupValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          submitForm,
          dirty,
          resetForm,
          errors,
          isSubmitting,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Modal
                isOpen={visible}
                isBlocking
                onDismiss={onDismiss}
                focusTrapZoneProps={{ style: { overflow: 'hidden' } }}
                styles={{
                  main: { height: 800, width: 800 },
                  // scrollableContent: { overflow: 'hidden' },
                }}
              >
                <Stack>
                  <Stack
                    horizontal
                    horizontalAlign={'space-between'}
                    className={styles.headerStyles}
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <Text variant={'xLarge'}>{headerTitle}</Text>
                      <UnsavedIndicator
                        visible={!isNew && dirty && !isSubmitting}
                      />
                    </Stack>
                    <CloseButton
                      onClick={() => {
                        resetForm();
                        onDismiss();
                      }}
                    />
                  </Stack>

                  {/* MAIN */}
                  <Stack style={{ padding: '100px 0px', height: 700 }}>
                    <Stack tokens={{ padding: 25 }}>
                      <Stack tokens={{ childrenGap: 20 }}>
                        <FormikTextField
                          name="name"
                          label="Name"
                          placeholder="Enter name"
                          required
                        />

                        <FormikTextField
                          name="description"
                          label="Description"
                          multiline
                          placeholder="Enter description"
                        />
                      </Stack>

                      <Stack
                        wrap
                        horizontal
                        tokens={{
                          childrenGap: 10,
                          padding: '25px 0px 0px 0px',
                        }}
                      >
                        {communicationChannel?.map((item, index) => {
                          return (
                            <Stack key={index}>
                              <ToggleButton
                                onClick={() => {
                                  const tempObj: GroupItemSelectOption = {
                                    communicationChannelId: item.id,
                                    communicationChannelName: item.name,
                                    _isAlert: item._isAlerts!,
                                    isRecipientOnly: false,
                                    isSenderOnly: false,
                                    isDeleted: false,
                                  };
                                  const toAdd = [
                                    ...tempSelectedCommunicationGroupItem,
                                    tempObj,
                                  ];

                                  //Check if channel communicationGroupItem in the communicationGroupItems array
                                  const isExists =
                                    values.communicationGroupItems?.some(
                                      (group) =>
                                        group.communicationChannelId === item.id
                                    );
                                  if (isExists) {
                                    const communicationGroupValuesArray =
                                      values.communicationGroupItems?.map(
                                        (group) =>
                                          group.communicationChannelId ===
                                            item.id
                                            ? { ...group, isDeleted: false }
                                            : group
                                      );
                                    setFieldValue(
                                      'communicationGroupItems',
                                      communicationGroupValuesArray
                                    );
                                  } else {
                                    const communicationGroupValuesArray = [
                                      ...values.communicationGroupItems!,
                                      {
                                        communicationChannelId: item.id,
                                        _communicationChannelName: item.name,
                                        isSenderOnly: false,
                                        isRecipientOnly: false,
                                        isDeleted: false,
                                        communicationChannel: {
                                          _isAlerts: item._isAlerts,
                                        },
                                      },
                                      // tempObj
                                    ];
                                    setFieldValue(
                                      'communicationGroupItems',
                                      communicationGroupValuesArray
                                    );
                                  }
                                  setTempSelectedCommunicationGroupItem(toAdd);
                                }}
                                key={item.id}
                                text={item.name}
                                iconName={'Add'}
                              />
                            </Stack>
                          );
                        })}
                      </Stack>
                      <Separator />
                      <Stack
                        tokens={{
                          childrenGap: 10,
                          padding: '25px 0px 0px 0px',
                        }}
                      >
                        {!!tempSelectedCommunicationGroupItem.length && (
                          <Text>Selected Group Items</Text>
                        )}
                        <FormikCommunicationGroupItem
                          deleteRow={(data) => {
                            if (data) {
                              const filteredTempSelectedCommunicationGroupItem =
                                tempSelectedCommunicationGroupItem.filter(
                                  (group) =>
                                    group.communicationChannelId !==
                                    data.communicationChannelId
                                );
                              setTempSelectedCommunicationGroupItem(
                                filteredTempSelectedCommunicationGroupItem
                              );
                            }
                          }}
                        />
                      </Stack>

                      {(createCommunicationChannelLoading ||
                        updateCommunicationGrouplLoading ||
                        communicationChannelListLoading) && (
                          <ProgressIndicator label={'Loading groups'} />
                        )}
                    </Stack>
                  </Stack>

                  {/* FOOTER */}
                  <Stack
                    horizontalAlign="space-between"
                    className={styles.footer}
                    horizontal
                    tokens={{ childrenGap: 6 }}
                    style={{}}
                  >
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <PrimaryButton
                        disabled={!dirty || Object.keys(errors).length > 0}
                        text={headerTitle}
                        onClick={async () => {
                          await submitForm();
                          resetForm();
                          onDismiss();
                        }}
                      />
                      <DefaultButton
                        onClick={() => {
                          resetForm();
                          onDismiss();
                        }}
                        text="Cancel"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

