import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const commonColumnProps: Partial<ColumnData> = {
  isResizable: false,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
  columnActionsMode: ColumnActionsMode.disabled,
};
const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 8,
  },
};

export const columns: ColumnData[] = [
  {
    key: 'fiscalPeriod',
    name: 'Period',
    fieldName: 'fiscalPeriod',
    minWidth: 50,
    maxWidth: 120,
    styles: colHeaderLeftAlignStyle,
    ...commonColumnProps,
  },
  {
    key: 'startDate',
    name: '',
    fieldName: 'startDate',
    minWidth: 50,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'endDate',
    name: '',
    fieldName: 'endDate',
    minWidth: 50,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'isPeriodOpen',
    name: '',
    fieldName: 'isPeriodOpen',
    minWidth: 50,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'isEntryAllowed',
    name: '',
    fieldName: 'isEntryAllowed',
    minWidth: 50,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'action',
    name: '',
    fieldName: 'action',
    minWidth: 352,
    // maxWidth: 352,
    isResizable: false,
    ...commonColumnProps,
  },
];
