import { AppState } from '@auth0/auth0-react';
import { randomBytes } from 'crypto';

const getNonce = () => randomBytes(16).toString('base64').slice(0, -2);

export type Auth0AppState = AppState & {
  redirectNonce?: string;
};

export const storePath = (state?: AppState): Auth0AppState => {
  const nonce = getNonce();

  window.sessionStorage.setItem(nonce, window.location.pathname);
  return {
    ...state,
    redirectNonce: nonce,
  };
};

export const restorePath = (state: Auth0AppState) => {
  const nonce = state.redirectNonce;
  if (nonce) {
    const path = window.sessionStorage.getItem(nonce);
    window.sessionStorage.removeItem(nonce);
    return path;
  }

  return undefined;
};
