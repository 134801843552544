import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isSortedDescending: false,
  isCollapsible: true,
  toggleVisibility: true,
  isVisible: true,
};
const useColumns = () => {
  const { isSm, isLg } = useGetScreenWidth();

  const columns: ColumnData[] = [
    {
      key: 'transactionType',
      name: 'Type',
      fieldName: 'transactionType',
      minWidth: 80,
      maxWidth: 110,
      ...commonColumnProps,
    },
    {
      key: '_accountingStampTransactionReference',
      name: 'Transaction No.',
      fieldName: '_accountingStampTransactionReference',
      minWidth: 140,
      maxWidth: 140,
      ...commonColumnProps,
    },

    {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 50,
      maxWidth: 200,
      ...commonColumnProps,
      isSorted: true,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 80,
      maxWidth: 300,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: 'production',
      name: 'Production',
      fieldName: 'production',
      minWidth: 100,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'invoiceNumber',
      name: 'Ref. Number',
      fieldName: 'invoiceNumber',
      minWidth: 80,
      maxWidth: 150,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'date',
      name: 'Date',
      fieldName: 'date',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'corporatePeriod',
      name: 'Period',
      fieldName: 'corporatePeriod',
      minWidth: 40,
      maxWidth: 50,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'department',
      name: 'Department',
      fieldName: 'department',
      minWidth: 80,
      maxWidth: 110,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'businessUnit',
      name: 'Business Unit',
      fieldName: 'businessUnit',
      minWidth: 80,
      maxWidth: 110,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'amount',
      name: 'Amount',
      fieldName: 'amount',
      minWidth: 60,
      maxWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: 'currency',
      name: 'Currency',
      fieldName: 'currency',
      minWidth: 50,
      maxWidth: 60,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: '_createdByUserName',
      name: 'Created by',
      fieldName: '_createdByUserName',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_baseCurrencyAmount',
      name: 'Base Amount',
      fieldName: '_baseCurrencyAmount',
      minWidth: 80,
      maxWidth: 100,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
      isVisible: false,
    },
    {
      key: '_spotCurrencyAmount',
      name: 'Spot Rate',
      fieldName: '_spotCurrencyAmount',
      minWidth: 100,
      maxWidth: 110,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
      isVisible: false,
    },
    {
      key: 'requesterName',
      name: 'Requested By',
      fieldName: 'requesterName',
      minWidth: 90,
      maxWidth: 130,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'requesterDate',
      name: 'Requested on',
      fieldName: 'requesterDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'approvers',
      name: 'Approved By',
      fieldName: 'approvers',
      minWidth: 90,
      maxWidth: 130,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'approvedDate',
      name: 'Approved on',
      fieldName: 'approvedDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'rejectors',
      name: 'Denied By',
      fieldName: 'rejectors',
      minWidth: 90,
      maxWidth: 130,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'rejectedDate',
      name: 'Denied on',
      fieldName: 'rejectedDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'amendedByName',
      name: 'Amended By',
      fieldName: 'amendedByName',
      minWidth: 90,
      maxWidth: 130,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'amendedDate',
      name: 'Amended on',
      fieldName: 'amendedDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 120,
      maxWidth: 150,
      ...commonColumnProps,
    },
  ];
  return { columns };
};
export { useColumns };
