import { useMutation, useQuery } from '@apollo/client';
import { Stack } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DocumentFileDistributionInput,
  DocumentFileDistributionUpdateTypeInput,
  EntityDeleteInput,
  EntityDocumentPatch,
} from 'common/types/globalTypes';
import {
  DocumentAssignmentTabs,
  setTabMode,
} from 'documents/documentAssignment';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { FormView } from './FormView';
import { validationSchema } from './FormView/documentValidations';
import {
  EntityDocumentDistributionUpdate,
  EntityDocumentDistributionUpdateVariables,
} from './FormView/__generated__/EntityDocumentDistributionUpdate';

import { AccountingViewRowValues, EntityDocumentValues } from './types';
import { getDefaultValues } from './utils';
import {
  GetEntityDocument,
  GetEntityDocumentVariables,
} from './__generated__/GetEntityDocument';
import { dateFormat } from 'common/utils/dateFormats';
const ENTITY_DOCUMENT = loader('./GetEntityDocument.graphql');
const UPDATE_DOC = loader(
  '../view/FormView/EntityDocumentDistributionUpdate.graphql'
);

export const DocumentAssignmentView = () => {
  const { addToast } = useToasts();
  const { documentId, selectedPoolId } = useParams<{
    documentId: string;
    selectedPoolId: string;
  }>();

  const [UpdateDocument, { loading: updateDocLoading }] = useMutation<
    EntityDocumentDistributionUpdate,
    EntityDocumentDistributionUpdateVariables
  >(UPDATE_DOC, { errorPolicy: 'all' });

  const {
    data: documentData,
    loading: entityDocumentDataLoading,
    refetch,
  } = useQuery<GetEntityDocument, GetEntityDocumentVariables>(ENTITY_DOCUMENT, {
    variables: {
      documentId: documentId!,
    },
    skip: !documentId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  useEffect(() => {
    setTabMode(
      selectedPoolId
        ? DocumentAssignmentTabs.folder
        : DocumentAssignmentTabs.documents
    );
  }, [selectedPoolId]);

  const onHandleSubmit = async (values: EntityDocumentValues) => {
    const initialValues = getDefaultValues({ documentData });
    const { invoiceDistributions } = values;
    if (documentId) {
      const { ...item } = values;
      const entityDocumentPatch: EntityDocumentPatch = Object.entries(
        item
      ).reduce(
        (res, [key, val]) => {
          if (
            val?.toString().toUpperCase() !==
              initialValues[key as keyof EntityDocumentValues]
                ?.toString()
                .toUpperCase() &&
            key !== 'invoiceDistributions'
          ) {
            if (
              key === 'indexCurrencyId' ||
              key === 'extractionTypeId' ||
              key === 'entityDocumentTypeId'
            )
              return { ...res, [key]: Number(val) };
            else return { ...res, [key]: val };
          }
          return res;
        },
        {
          indexTransactionDate: values.indexTransactionDate,
        }
      );

      const newInvoiceDistributionRows =
        invoiceDistributions &&
        (invoiceDistributions
          ?.filter(
            (addr) => !addr.id && !Object.values(addr).every((x) => x === null)
          )
          .map(
            ({
              id,
              _rowTimestamp,
              _isDeletable,
              _isUpdatable,
              _accountName,
              ...addr
            }) => addr
          ) as DocumentFileDistributionInput[]);

      const deletedInvoiceDistributionRows =
        initialValues.invoiceDistributions &&
        (differenceBy(
          initialValues.invoiceDistributions,
          invoiceDistributions || [],
          'id'
        ).map((addr) => ({
          id: addr.id!,
          rowTimestamp: addr._rowTimestamp!,
        })) as EntityDeleteInput[]);

      const updatedInvoiceDistributions =
        initialValues.invoiceDistributions &&
        invoiceDistributions &&
        intersection(
          initialValues.invoiceDistributions.map((addr) => addr.id),
          invoiceDistributions.filter((addr) => addr.id).map((addr) => addr.id)
        ).reduce((arr, targetId) => {
          const initialInvoiceDistributions =
            initialValues.invoiceDistributions!.find(
              (addr) => addr.id === targetId
            )!;
          const { id, _rowTimestamp, _accountName, ...updatedAddress } =
            invoiceDistributions!.find((addr) => addr.id === targetId)!;
          const patch = Object.entries(updatedAddress).reduce(
            (res, [key, val]) => {
              if (
                val !==
                initialInvoiceDistributions[
                  key as keyof AccountingViewRowValues
                ]
              )
                if (key === 'distributionDate') {
                  return {
                    ...res,
                    [key]: !!val ? dateFormat(val.toString()) : null,
                  };
                } else {
                  return { ...res, [key]: val };
                }
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                documentFileDistributionPatch: patch,
              },
            ];
          return arr;
        }, [] as DocumentFileDistributionUpdateTypeInput[]);

      const { id, _rowTimestamp } = { ...documentData?.entityDocument };

      const { errors } = await UpdateDocument({
        variables: {
          input: {
            id: id!,
            rowTimestamp: _rowTimestamp!,
            entityDocumentPatch: entityDocumentPatch,
            documentDistributionsUpdate:
              updatedInvoiceDistributions &&
              updatedInvoiceDistributions.length > 0
                ? updatedInvoiceDistributions
                : undefined,
            documentDistributionsCreate:
              newInvoiceDistributionRows &&
              newInvoiceDistributionRows.length > 0
                ? newInvoiceDistributionRows
                : undefined,
            documentDistributionsDelete:
              deletedInvoiceDistributionRows &&
              deletedInvoiceDistributionRows.length > 0
                ? deletedInvoiceDistributionRows
                : undefined,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: documentData?.entityDocument?.id
          ? [
              {
                query: ENTITY_DOCUMENT,
                variables: {
                  documentId: documentData?.entityDocument?.id,
                },
              },
            ]
          : [],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Document updated', { appearance: 'success' });
      }
    }
  };

  const formMethods = useForm<EntityDocumentValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { handleSubmit, trigger, reset } = { ...formMethods };

  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack]);

  useEffect(() => {
    const defaultValues = getDefaultValues({ documentData });
    reset(defaultValues);
  }, [documentData, reset]);

  return (
    <Stack>
      <FormProvider {...formMethods}>
        <FormView
          selectedDocumentId={documentId}
          selectedPoolId={selectedPoolId}
          onSave={handleSubmit(onHandleSubmit)}
          isNew={false}
          documentData={documentData}
          dataLoading={entityDocumentDataLoading}
          updateDocLoading={updateDocLoading}
          refetch={refetch!}
        />
      </FormProvider>
    </Stack>
  );
};
