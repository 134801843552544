import { ITextFieldProps, Stack, TextField } from '@fluentui/react';
import { LookupSupplierSearch_lookupSupplierSearch_nodes } from 'common/graphql/__generated__/LookupSupplierSearch';
import { RequiredNameProps } from 'common/types/utility';
import React, { FC, useEffect, useState } from 'react';
import { Supplier_supplier } from 'settings/account/supplier/view/__generated__/Supplier';
import { TextFieldLabel } from './TextFieldLabel';

type SupplierAutoCompleteProps = RequiredNameProps<ITextFieldProps> & {
  lookUpCalloutWidth?: number;
  lookupData?: LookupSupplierSearch_lookupSupplierSearch_nodes | null;
  clearTextField?: boolean | null;
  showAddIcon?: boolean;
  supplier: Supplier_supplier | undefined | null;
};

export const SupplierAutoComplete: FC<SupplierAutoCompleteProps> = ({
  lookUpCalloutWidth,
  lookupData,
  clearTextField,
  showAddIcon,
  supplier,
  ...props
}) => {
  const [fullName, setFullName] = useState<string | null | undefined>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<
    Supplier_supplier | null | undefined
  >(null);
  const { id, label, disabled, required, ...textfieldProps } = props;
  const { _fullName } = { ...supplier };
  useEffect(() => {
    setFullName(_fullName);
  }, [_fullName]);

  useEffect(() => {
    if (supplier) setSelectedSupplier(supplier);
  }, [supplier]);

  return (
    <Stack>
      <TextFieldLabel
        label={label}
        required={required}
        supplier={selectedSupplier}
      />
      <TextField disabled {...textfieldProps} id={id} value={fullName || ''} />
    </Stack>
  );
};
