import { FontSizes, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  messageResponseContainer: {
    marginBottom: 15,
    alignItems: 'center',
  },
  messageRowContainer: {
    paddingTop: 8,
  },
  messageResponseSeparatorContainer: {
    width: '50px',
    marginRight: '5px',
  },
  messageResponsePersonaContainer: {
    marginLeft: 15,
    marginRight: 5,
    width: '50px',
  },
  messageRowPersonaContainer: {
    marginRight: 5,
    marginBottom: 15,
    width: 50,
  },
  messageResponseMessageMainContainer: {
    width: '500px',
  },
  messageRowMessageMainContainer: {
    maxWidth: '500px',
  },
  messageResponseMessageContainer: {
    justifyContent: 'center',
    width: '500px',
  },
  messageRowMessageContainer: {
    justifyContent: 'center',
    maxWidth: '500px',
  },
  sendingUser: {
    fontSize: FontSizes.size14,
    fontWeight: 'bold',
  },
  messageTitle: {
    paddingTop: '8px',
    fontSize: FontSizes.size14,
  },
}));
