import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  categoryType: {
    color: theme.palette.themePrimary,
    maxWidth:200,
    textAlign:'center',
    padding:"5px 0px 0px 0px"
  },
  typeContainer: { alignItems: 'center', margin: '10px 10px' },

  callout: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));
