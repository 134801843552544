import React from 'react';
import { Stack, Text, TooltipHost } from '@fluentui/react';
import {
  GetNotification_userNotifications_nodes_extendedMessage,
  GetNotification_userNotifications_nodes_extendedMessage_messageSections_nodes,
  GetNotification_userNotifications_nodes_extendedMessage_messageSections_nodes_messageTableColumns_nodes,
} from '../__generated__/GetNotification';
import { useStyles } from './index.styles';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';
import { AlignmentTypes } from 'common/types/globalTypes';

type MessageData =
  GetNotification_userNotifications_nodes_extendedMessage_messageSections_nodes;
type CellData =
  GetNotification_userNotifications_nodes_extendedMessage_messageSections_nodes_messageTableColumns_nodes;

interface ExtendedNotificationViewProps {
  extendedData: GetNotification_userNotifications_nodes_extendedMessage;
}

const getAlignment = (column: MessageData | null, index: number) => {
  if (!column) return null;
  switch (index) {
    case 0:
      return column.columnAlignment1;
    case 1:
      return column.columnAlignment2;
    case 2:
      return column.columnAlignment3;
    case 3:
      return column.columnAlignment4;
    case 4:
      return column.columnAlignment5;
    default:
      return null;
  }
};

const getHeading = (column: MessageData | null, index: number) => {
  if (!column) return null;

  switch (index) {
    case 0:
      return column.columnHeading1;
    case 1:
      return column.columnHeading2;
    case 2:
      return column.columnHeading3;
    case 3:
      return column.columnHeading4;
    case 4:
      return column.columnHeading5;
    default:
      return null;
  }
};

const getValue = (cell: CellData, index: number) => {
  switch (index) {
    case 0:
      return cell.column1Value;
    case 1:
      return cell.column2Value;
    case 2:
      return cell.column3Value;
    case 3:
      return cell.column4Value;
    case 4:
      return cell.column5Value;
    default:
      return null;
  }
};

interface TableCellProps {
  column: MessageData | null;
  cellData: CellData;
  cellIndex: number;
  numColumns: number;
  numRows: number;
  rowNumber: number;
}

const TableCell: React.FC<TableCellProps> = ({
  column,
  cellData,
  cellIndex,
  numColumns,
  numRows,
  rowNumber,
}) => {
  const styles = useStyles();
  const getAlignmentStyle = () => {
    switch (getAlignment(column, cellIndex)) {
      case AlignmentTypes.CENTER:
        return styles.textAlignCenter;
      case AlignmentTypes.RIGHT:
        return styles.textAlignEnd;
      case AlignmentTypes.LEFT:
      default:
        return styles.textAlignStart;
    }
  };
  return (
    <TooltipHost content={getValue(cellData, cellIndex)?.trim()}>
      <Stack
        className={clsx(
          styles.valueBorderStyle,
          numRows - 1 !== rowNumber ? styles.bottomBorderWidth : null,
          numColumns - 1 !== cellIndex ? styles.rightBorderWidth : null
        )}
      >
        {/* Content is must otherwise the Cell UI distorting after adding tooltip */}
        <Text nowrap className={getAlignmentStyle()}>
          {getValue(cellData, cellIndex) == null ||
            getValue(cellData, cellIndex)?.trim().length === 0
            ? '-'
            : getValue(cellData, cellIndex)?.trim()}
        </Text>
      </Stack>
    </TooltipHost>
  );
};
interface TableColumnHeaderProps {
  column: MessageData | null;
  index: number;
  numColumns: number;
}
const TableColumnHeader: React.FC<TableColumnHeaderProps> = ({
  column,
  index,
  numColumns,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const getAlignmentStyle = () => {
    switch (getAlignment(column, index)) {
      case AlignmentTypes.CENTER:
        return styles.textAlignCenter;
      case AlignmentTypes.RIGHT:
        return styles.textAlignEnd;
      case AlignmentTypes.LEFT:
      default:
        return styles.textAlignStart;
    }
  };

  return (
    <TooltipHost>
      <Stack
        className={clsx(
          styles.borderStyle,
          numColumns - 1 !== index ? styles.rightBorderWidth : null
        )}
      >
        <Text
          className={clsx(
            commonStyles.bold,
            commonStyles.colorThemePrimary,
            getAlignmentStyle()
          )}
        >
          {getHeading(column, index)}
        </Text>
      </Stack>
    </TooltipHost>
  );
};

export const ExtendedNotificationView: React.FC<ExtendedNotificationViewProps> =
  ({ extendedData }) => {
    const styles = useStyles();
    const commonStyles = useCommonStyles();

    return (
      <Stack
        className={styles.sectionMainContainer}
        tokens={{ childrenGap: 20 }}
      >
        {extendedData.messageSections.nodes.map((item, index) => {
          const columns = new Array(item.tableColumns).fill(null);
          return (
            <Stack key={index}>
              <Text variant={'medium'} className={commonStyles.bold}>
                {item.sectionHeader}
              </Text>
              {item.sectionBody && <Text>{item.sectionBody}</Text>}
              <Stack className={styles.marginTop10} horizontal>
                {columns.map((_, index) => (
                  <TableColumnHeader
                    column={item}
                    index={index}
                    key={index}
                    numColumns={item.tableColumns!}
                  />
                ))}
              </Stack>
              <Stack>
                {item.messageTableColumns.nodes.map((tableItem, tableIndex) => {
                  const tableRows = item.messageTableColumns.nodes.length;
                  return (
                    <Stack horizontal key={tableIndex}>
                      {columns.map((_, rowIndex: number) => (
                        <TableCell
                          column={item}
                          cellData={tableItem}
                          cellIndex={rowIndex}
                          numColumns={item.tableColumns!}
                          numRows={tableRows}
                          rowNumber={tableIndex}
                          key={tableIndex + rowIndex}
                        />
                      ))}
                    </Stack>
                  );
                })}
              </Stack>
              <Stack className={styles.marginTop10}>
                {item.sectionFooter && <Text>{item.sectionFooter}</Text>}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    );
  };
