import React, { useState } from 'react';
import {
  Callout,
  IColorCellProps,
  IRenderFunction,
  SwatchColorPicker,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useStyles } from './index.styles';
import { colorCells } from './colors';

interface ColorPickerCalloutProps {
  onColorChange: (color: string) => void;
}
export const ColorPickerCallout: React.FC<ColorPickerCalloutProps> = ({
  onColorChange,
}) => {
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const buttonId = useId('callout-button');
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');
  const styles = useStyles();

  const onRenderColorCell: IRenderFunction<IColorCellProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    return <>{defaultRender!(props)}</>;
  };

  return (
    <>
      <DefaultButton
        id={buttonId}
        onClick={() => setIsCalloutVisible(true)}
        text={'Choose Pen Color'}
      />
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={() => setIsCalloutVisible(false)}
          setInitialFocus
        >
          <Text block variant="xLarge" className={styles.title} id={labelId}>
            Colors
          </Text>

          <SwatchColorPicker
            columnCount={6}
            cellShape={'square'}
            cellHeight={35}
            cellWidth={35}
            cellBorderWidth={3}
            colorCells={colorCells}
            onChange={(_, id, color) => {
              onColorChange(color!);
              setIsCalloutVisible(false);
            }}
            onRenderColorCell={onRenderColorCell}
          />
        </Callout>
      )}
    </>
  );
};
