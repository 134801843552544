import { TagGroupValues,  TagGroupItems } from './types';

export const TAG_GROUP_ITEM: TagGroupItems = {
  tagId: null,
}

export const TAG_GROUP_INITIAL_VALUES: TagGroupValues = {
  name: null,
  description: null,
  tagGroupItems: []
};
