import {
  Icon,
  IPivotItemProps,
  Pivot,
  PivotItem,
  Stack,
} from '@fluentui/react';
import { EntityUserMessageFields } from 'common/graphql/__generated__/EntityUserMessageFields';
import { ExtendedMessageFields } from 'common/graphql/__generated__/ExtendedMessageFields';
import { UserEntityMessageCounts } from 'common/graphql/__generated__/UserEntityMessageCounts';
import React from 'react';
import { Badge } from '../Badge';
import { useStyles } from './index.styles';
import { ReceivedMessageList } from './ReceivedList';
import { SentMessageList } from './SentList';

export type MessageListItem = EntityUserMessageFields &
  Partial<ExtendedMessageFields>;

export interface MessagesListsProps {
  data: MessageListItem[];
  loadingMessages: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  onListChange: (listName?: string) => void;
  showNavigation?: boolean;
  entityId: string;
  userMessageCounts: UserEntityMessageCounts | undefined;
}
export enum PivotKeys {
  received = 'RECEIVED',
  sent = 'SENT',
}

export const MessagesSections: React.FC<MessagesListsProps> = ({
  onListChange,
  children,
  entityId,
  userMessageCounts,
  ...props
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = React.useState<string>(
    PivotKeys.received
  );

  const { messagesSent } = { ...userMessageCounts?.userEntityMessageCounts };

  const _customRenderer = (
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ) => {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <span style={{ flex: '0 1 100%' }}>
        <Icon iconName={link.itemIcon} />
        {defaultRenderer({ ...link, itemIcon: undefined })}
        <Badge useMargin visible={messagesSent! > 0} />
      </span>
    );
  };

  return (
    <>
      <Stack horizontal horizontalAlign="space-between">
        <Pivot
          selectedKey={selectedKey}
          onLinkClick={(item: PivotItem | undefined) => {
            setSelectedKey(item!.props!.itemKey!);
            onListChange(item!.props!.itemKey!);
          }}
          className={styles.pivotContainer}
        >
          <PivotItem
            itemKey={PivotKeys.received}
            headerText="Received"
            itemIcon="SortDown"
          />
          <PivotItem
            itemKey={PivotKeys.sent}
            headerText="Sent"
            itemIcon="SortUp"
            onRenderItemLink={_customRenderer}
          />
        </Pivot>
        {children}
      </Stack>
      <Stack tokens={{ padding: '10px 0px 0px 0px' }}>
        {selectedKey === PivotKeys.received ? (
          <ReceivedMessageList {...props} refetchList={onListChange} />
        ) : (
          <SentMessageList {...props} refetchList={onListChange} />
        )}
      </Stack>
    </>
  );
};
