import { Stack, Text, mergeStyles } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './index.styles';

interface BadgeProps {
  visible?: boolean;
  useMargin?: boolean;
  primary?: boolean;
  count?: number;
  size?: number;
}

export const Badge: React.FC<BadgeProps> = ({
  visible,
  children,
  useMargin,
  primary,
  count,
  size = 20,
}) => {
  const badgeSizeClass = mergeStyles({
    width: size,
    height: size,
  });

  const styles = useStyles();
  if (!visible) {
    if (!children) return null;
    return <>{children}</>;
  }

  return (
    <span
      className={clsx(
        styles.root,
        useMargin && styles.customBadgeStyle,
        badgeSizeClass
      )}
    >
      {children}
      {!primary ? (
        <span className={styles.badge} />
      ) : (
        <>
          {!!count && (
            <Stack className={clsx(styles.primaryBadge)}>
              <Text className={styles.countText}>{count}</Text>
            </Stack>
          )}
        </>
      )}
    </span>
  );
};
