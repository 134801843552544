import { Stack } from '@fluentui/react';
import clsx from 'clsx';
import { AccountingViewFields_invoiceDistributions_aggregates } from 'common/components/AccountingView/__generated__/AccountingViewFields';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { ColumnData } from '../utils';

export const Footer: React.FC<{
  columnArray: ColumnData[];
  aggregates: AccountingViewFields_invoiceDistributions_aggregates | null;
}> = ({ columnArray, aggregates }) => {
  const commonStyles = useCommonStyles();
  const sum = aggregates?.sum?.distributionAmount || "0"
  const totalDistributionAmount = parseFloat(sum!.toString()).toFixed(2);

  return (
    <Stack
      horizontal
    >
      {columnArray.map((value, key) => {
        return (
          <Stack
            style={{
              height: 40,
              width: value.maxWidth,
              padding: "0px 10px",
            }}
            key={key}
            horizontalAlign="end"
            verticalAlign="center"
          >
            {
              value.key === 'distributionAmount' ?
                <AmountTextView
                  className={clsx(
                    commonStyles.semibold,
                    commonStyles.colorThemePrimary
                  )}
                  value={totalDistributionAmount}
                />
                : null
            }
          </Stack>
        )
      })}
    </Stack>
  );
};
