import {
  IDropdownOption,
  ITextFieldProps,
  Pivot,
  PivotItem,
  Stack,
  Label,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { CompanyAutoComplete } from '../CompanyAutoComplete';
import {
  FormHookCountryRegion,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { useQuery } from '@apollo/client';
import { Countries } from 'common/graphql/__generated__/Countries';
import { loader } from 'graphql.macro';
import { SupplierValues } from '../types';
import { useFormContext } from 'react-hook-form';
import { SupplierDetails_supplier } from '../../__generated__/SupplierDetails';
import { useStyles } from './index.styles';
import { SupplierAlias } from 'settings/account/supplier/view/BasicForm/SupplierAlias';
import { SupplierCreate_supplierCreate_supplier } from 'settings/account/supplier/view/__generated__/SupplierCreate';
const COUNTRIES = loader('../../../../../common/graphql/Countries.graphql');
enum PivotKeys {
  company = 'Company',
  individual = 'Individual',
}

interface BasicFormProps {
  isNew: boolean;
  isUpdatable: boolean;
  supplier: SupplierDetails_supplier | null | undefined;
  onSupplierCreate: (param: SupplierCreate_supplierCreate_supplier) => void;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  isNew,
  isUpdatable,
  supplier,
  onSupplierCreate,
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = useState<string>(PivotKeys.company);

  const { setValue } = useFormContext<SupplierValues>();

  const { data: countries } = useQuery<Countries>(COUNTRIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const onPivotChange = (item: PivotItem | undefined) => {
    setSelectedKey(item?.props.itemKey || PivotKeys.individual);
    if (!!isUpdatable) {
      if (item?.props.itemKey === PivotKeys.individual) {
        setValue('isIndividual', true);
        setValue('isCorporation', false);
      } else {
        setValue('isIndividual', false);
        setValue('isCorporation', true);
      }
    }
  };

  const countryOptions: IDropdownOption[] =
    countries?.countries?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.country,
    })) || [];

  useEffect(() => {
    if (!isNew) {
      if (supplier?.isIndividual) setSelectedKey(PivotKeys.individual);
    } else setSelectedKey(PivotKeys.company);
  }, [isNew, supplier]);

  const { _isPurchaseOrderSettingsUpdatable, _isUpdatable, _isAliasInvoked } = {
    ...supplier,
  };

  //Refer ticket 1396 and 1397 for more details.
  const isCreate = isNew ? true : false;
  const isPurchaseOrderSettingsUpdatable = !!_isPurchaseOrderSettingsUpdatable;
  const isUpdatableAndIsPOUpdatable = !(
    _isUpdatable && isPurchaseOrderSettingsUpdatable
  );

  const disableCheckboxes =
    isCreate ||
    !isPurchaseOrderSettingsUpdatable ||
    isUpdatableAndIsPOUpdatable;

  const disableCompetitiveBids = isCreate || !isPurchaseOrderSettingsUpdatable;
  const onRenderSupplierLabel = (
    props: ITextFieldProps | undefined
  ): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="end" horizontalAlign="space-between">
        <Label disabled={props?.disabled}>{props?.label}</Label>
        <SupplierAlias
          isPurchaseOrderMutation
          supplier={supplier}
          onAliasUpdate={onSupplierCreate}
        />
      </Stack>
    );
  };

  return (
    <>
      <Stack
        tokens={{
          childrenGap: 15,
          padding: '0px 0px 20px 0px',
        }}
      >
        <Pivot
          onLinkClick={onPivotChange}
          selectedKey={selectedKey}
          className={styles.pivot}
        >
          <PivotItem
            itemKey={PivotKeys.company}
            headerText={PivotKeys.company}
          />
          <PivotItem
            itemKey={PivotKeys.individual}
            headerText={PivotKeys.individual}
          />
        </Pivot>
        {selectedKey === PivotKeys.company ? (
          <Stack
            horizontal
            verticalAlign="baseline"
            tokens={{
              childrenGap: 20,
              padding: '0px 20px',
            }}
          >
            <Stack className={styles.inputContainer}>
              <CompanyAutoComplete
                name="companyOrLastName"
                label="Company Name"
                placeholder="Company Name"
                id={'companyNameField'}
                disabled={!isUpdatable || !!_isAliasInvoked}
                required
              />
            </Stack>
          </Stack>
        ) : (
          <>
            <Stack
              horizontal
              verticalAlign="baseline"
              tokens={{
                childrenGap: 20,
                padding: '0px 20px',
              }}
            >
              <Stack className={styles.inputContainer}>
                <FormHookTextField
                  name="firstName"
                  label="Given Name"
                  placeholder="Given Name"
                  disabled={!isUpdatable || !!_isAliasInvoked}
                  onRenderLabel={onRenderSupplierLabel}
                />
              </Stack>
              <Stack className={styles.inputContainer}>
                <CompanyAutoComplete
                  name="companyOrLastName"
                  label="Family Name"
                  placeholder="Family Name"
                  disabled={!isUpdatable || !!_isAliasInvoked}
                  id={'companyNameField'}
                  required
                />
              </Stack>
            </Stack>
          </>
        )}
        <Stack
          verticalAlign="center"
          tokens={{
            padding: '0px 20px',
          }}
        >
          <FormHookTextField
            name="supplierAddressesBySupplierId[0].addressLine1"
            label="Street"
            placeholder="Street Number and PO Box"
            disabled={!isUpdatable}
            required
          />
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{
            childrenGap: 20,
            padding: '0px 20px',
          }}
        >
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="supplierAddressesBySupplierId[0].addressLine2"
              label="Apartment"
              placeholder="Apartment, Suite, Unit, Building, Floor"
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="supplierAddressesBySupplierId[0].cityName"
              label="City"
              placeholder="City/Town"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{
            childrenGap: 20,
            padding: '0px 20px',
          }}
        >
          <Stack.Item grow={2}>
            <FormHookCountryRegion
              countries={countryOptions}
              countryName={`supplierAddressesBySupplierId[0].countryId`}
              regionName={`supplierAddressesBySupplierId[0].stateRegionId`}
              CountryAutocompleteProps={{
                label: 'Country',
                errorMessage: '',
              }}
              StateRegionAutocompleteProps={{
                label: 'State / Region / Province',
                errorMessage: '',
              }}
              disabled={!isUpdatable}
            />
          </Stack.Item>
          <Stack.Item grow={1}>
            <FormHookTextField
              name="supplierAddressesBySupplierId[0].postalCode"
              label="Zip/Postal Code"
              placeholder="Zip/Postal Code"
              disabled={!isUpdatable}
            />
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          verticalAlign="baseline"
          tokens={{
            childrenGap: 20,
            padding: '0px 20px',
          }}
          className={styles.mT20}
        >
          <Stack.Item grow={1}>
            <FormHookTextField
              name="supplierAddressesBySupplierId[0].telephoneNumber"
              label="Phone"
              placeholder="Phone"
              disabled={!isUpdatable}
            />
          </Stack.Item>
          <Stack.Item grow={1}>
            <FormHookTextField
              name="supplierAddressesBySupplierId[0].emailAddress"
              label="Email"
              placeholder="Email"
              onTextChange={(value) => {
                if (value)
                  setValue(
                    'supplierAddressesBySupplierId.0.isSendEmailAccount',
                    true
                  );
              }}
              disabled={!isUpdatable}
            />
          </Stack.Item>
          <Stack.Item grow={1}>
            <FormHookTextField
              name="supplierAddressesBySupplierId[0].webAddress"
              label="Website"
              placeholder="Website"
              disabled={!isUpdatable}
            />
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          verticalAlign="baseline"
          tokens={{
            childrenGap: 20,
            padding: '0px 20px',
          }}
          className={styles.mT20}
        >
          <Stack.Item grow={1}>
            <FormHookTextField
              name="federalTaxReference"
              label="Tax Reference#"
              placeholder="Tax Reference#"
              disabled={!isUpdatable}
            />
          </Stack.Item>
          <Stack.Item grow={1}>
            <FormHookTextField
              name="vatNumber"
              label="VAT ID"
              placeholder="VAT ID"
              disabled={!isUpdatable}
            />
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
            padding: '0px 20px',
          }}
          className={styles.mT30}
        >
          <Stack.Item className={styles.checkboxContainer}>
            <FormHookCheckBox
              name="supplierAddressesBySupplierId.0.isSendEmailAccount"
              label="Send Purchase Orders"
              disabled={disableCheckboxes}
            />
          </Stack.Item>
          <Stack.Item className={styles.checkboxContainer}>
            <FormHookCheckBox
              name="isRelatedPartySupplier"
              label="Related Party"
              disabled={disableCheckboxes}
            />
          </Stack.Item>
          <Stack.Item className={styles.checkboxContainer}>
            <FormHookCheckBox
              name="isCompetitiveBidsRequired"
              label="Competitive Bids"
              // disabled={!isUpdatable}
              disabled={disableCompetitiveBids}
            />
          </Stack.Item>
          <Stack.Item className={styles.checkboxContainer}>
            <FormHookCheckBox
              name="isContractualPurchaseOrderRequired"
              label="Contractual"
              // disabled={!isUpdatable}
              disabled={disableCheckboxes}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
};
