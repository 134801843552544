import { TripBreakdownsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { FilterArrayType } from 'common/components/Filters';

type OrderLookup = {
  [direction in OrderDirection]: TripBreakdownsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'tripLocator',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.TRIP_LOCATOR_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.TRIP_LOCATOR_DESC],
    },
  ],
  [
    '_travelTags',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._TRAVEL_TAGS_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._TRAVEL_TAGS_DESC],
    },
  ],
  [
    'travelerReferenceName',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.TRAVELER_REFERENCE_NAME_ASC],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy.TRAVELER_REFERENCE_NAME_DESC,
      ],
    },
  ],
  [
    'travelAuthorizationNumber',
    {
      [OrderDirection.ASC]: [
        TripBreakdownsOrderBy.TRAVEL_AUTHORIZATION_NUMBER_ASC,
      ],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy.TRAVEL_AUTHORIZATION_NUMBER_DESC,
      ],
    },
  ],
  [
    '_isCorporateTravelAuthorization',
    {
      [OrderDirection.ASC]: [
        TripBreakdownsOrderBy._IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC,
      ],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy._IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC,
      ],
    },
  ],
  [
    'comment',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.COMMENT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.COMMENT_DESC],
    },
  ],
  [
    'travelAuthorizationDate',
    {
      [OrderDirection.ASC]: [
        TripBreakdownsOrderBy.TRAVEL_AUTHORIZATION_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy.TRAVEL_AUTHORIZATION_DATE_DESC,
      ],
    },
  ],
  [
    '_departmentName',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._DEPARTMENT_NAME_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._DEPARTMENT_NAME_DESC],
    },
  ],
  [
    '_businessUnitName',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._BUSINESS_UNIT_NAME_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._BUSINESS_UNIT_NAME_DESC],
    },
  ],
  [
    'corporateWorkgroupName',
    {
      [OrderDirection.ASC]: [
        TripBreakdownsOrderBy.CORPORATE_WORKGROUP_NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy.CORPORATE_WORKGROUP_NAME_DESC,
      ],
    },
  ],
  [
    'tripPurpose',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.TRIP_PURPOSE_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.TRIP_PURPOSE_DESC],
    },
  ],
  [
    '_travelPolicyDescription',
    {
      [OrderDirection.ASC]: [
        TripBreakdownsOrderBy._TRAVEL_POLICY_DESCRIPTION_ASC,
      ],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy._TRAVEL_POLICY_DESCRIPTION_DESC,
      ],
    },
  ],
  [
    '_companionTicketUsed',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._COMPANION_TICKET_USED_ASC],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy._COMPANION_TICKET_USED_DESC,
      ],
    },
  ],
  [
    '_isoCode',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._ISO_CODE_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._ISO_CODE_DESC],
    },
  ],
  [
    'airfareAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.AIRFARE_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.AIRFARE_AMOUNT_DESC],
    },
  ],
  [
    'accommodationAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.ACCOMMODATION_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.ACCOMMODATION_AMOUNT_DESC],
    },
  ],
  [
    'vehicleRentalAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.VEHICLE_RENTAL_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.VEHICLE_RENTAL_AMOUNT_DESC],
    },
  ],
  [
    'transportationAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.TRANSPORTATION_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.TRANSPORTATION_AMOUNT_DESC],
    },
  ],
  [
    'trainAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.TRAIN_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.TRAIN_AMOUNT_DESC],
    },
  ],
  [
    'incidentalAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.INCIDENTAL_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.INCIDENTAL_AMOUNT_DESC],
    },
  ],
  [
    'controlTotalAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy.CONTROL_TOTAL_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy.CONTROL_TOTAL_AMOUNT_DESC],
    },
  ],
  [
    '_baseCurrencyAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._BASE_CURRENCY_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._BASE_CURRENCY_AMOUNT_DESC],
    },
  ],
  [
    '_spotCurrencyAmount',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._SPOT_CURRENCY_AMOUNT_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._SPOT_CURRENCY_AMOUNT_DESC],
    },
  ],
  [
    '_taStatusType',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._TA_STATUS_TYPE_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._TA_STATUS_TYPE_DESC],
    },
  ],
  [
    '_createdDate',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._CREATED_DATE_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._CREATED_DATE_DESC],
    },
  ],
  [
    '_createdByUserName',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._CREATED_BY_USER_NAME_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._CREATED_BY_USER_NAME_DESC],
    },
  ],
  [
    '_companionAmountUsed',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._COMPANION_AMOUNT_USED_ASC],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy._COMPANION_AMOUNT_USED_DESC,
      ],
    },
  ],
  [
    '_tripWarningInfo',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._TRIP_WARNING_INFO_ASC],
      [OrderDirection.DESC]: [TripBreakdownsOrderBy._TRIP_WARNING_INFO_DESC],
    },
  ],
  [
    '_primaryTravelerName',
    {
      [OrderDirection.ASC]: [TripBreakdownsOrderBy._PRIMARY_TRAVELER_NAME_ASC],
      [OrderDirection.DESC]: [
        TripBreakdownsOrderBy._PRIMARY_TRAVELER_NAME_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    TripBreakdownsOrderBy.TRAVEL_AUTHORIZATION_NUMBER_DESC,
    TripBreakdownsOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];

  return [...(orderBy ? orderBy : []), TripBreakdownsOrderBy.PRIMARY_KEY_ASC];
};

export const toTripBreakdownFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'departmentId':
      case 'currencyId':
      case 'businessUnitId':
      case '_taStatusTypeId':
      case 'travelerId':
      case 'travelAuthorizationId':
      case 'corporateWorkgroupId':
      case '_travelPolicyId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      case '_isOverBudget':
        return {
          _isOverBudget: {
            equalTo: filter.value === 1,
          },
        };

      case '_isCorporateTravelAuthorization':
        return {
          _isCorporateTravelAuthorization: {
            equalTo: Boolean(filter.value),
          },
        };
      default:
        return undefined;
    }
  });
