import * as React from 'react';
import { Stack, List, DefaultButton, ProgressIndicator } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { useStyles } from './index.styles';
import { MessageListItem } from '..';
import { MessageRow } from '../MessageRow';
import { PivotKeys } from '../index';
interface SentMessageListProps {
  data: MessageListItem[];
  loadingMessages: boolean;
  refetchList: (listName?: string) => void;
  onLoadMore?: () => void;
  hasNextPage?: boolean;
  showNavigation?: boolean;
}

export const SentMessageList: React.FunctionComponent<SentMessageListProps> = ({
  data = [],
  loadingMessages,
  refetchList,
  onLoadMore,
  hasNextPage,
  showNavigation,
}) => {
  const styles = useStyles();
  const isFetchingData = loadingMessages && !data?.length;
  return (
    <Stack tokens={{ padding: '10px 0px 0px 0px' }}>
      {isFetchingData && (
        <Stack tokens={{ padding: '10px 0px 0px 0px' }} data-is-scrollable>
          <ProgressIndicator barHeight={4} />
        </Stack>
      )}
      <List
        items={data}
        onRenderCell={(item, index) => (
          <MessageRow
            refetchList={refetchList}
            item={item}
            key={item?.id}
            listType={PivotKeys.sent}
            isLastRow={!(index! < data.length - 1)}
            showNavigation={showNavigation}
          />
        )}
      />
      {hasNextPage && data?.length && (
        <Stack className={styles.loadMoreContainer} onClick={onLoadMore}>
          <DefaultButton text="Load more" />
        </Stack>
      )}
      <NoDataView
        title="No messages found"
        show={!data.length && !loadingMessages}
      />
    </Stack>
  );
};
