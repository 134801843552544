import { useReactiveVar } from '@apollo/client';
import { Link } from '@fluentui/react';
import React from 'react';
import { setUserDefaults } from 'utility/cache/ui';
import { useStyles } from './index.styles';

interface StyledLinkProps {
  onLinkClick?: () => void;
  linkText: string | null;
}

export const StyledLink: React.FC<StyledLinkProps> = ({
  onLinkClick,
  linkText,
}) => {
  const styles = useStyles();

  const userDefaults = useReactiveVar(setUserDefaults);
  const isRowSelectorBold = userDefaults?.isRowSelectorBold;
  return (
    <Link
      className={isRowSelectorBold ? styles.linkBold : styles.link}
      onClick={() => {
        onLinkClick?.();
      }}
    >
      {linkText}
    </Link>
  );
};
