import { TransactionLayout } from 'common/types/globalTypes';

export const getPreferredSupplierLabel = (selectedLayout: string | null) => {
  switch (selectedLayout) {
    case TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL:
      return 'Airline';
    case TransactionLayout.TRAVEL_BOOKING_HOTEL:
      return 'Hotel/Accommodation';
    case TransactionLayout.TRAVEL_BOOKING_VEHICLE_RENTAL:
      return 'Vehicle Rental';
    case TransactionLayout.TRAVEL_BOOKING_GROUND_TRANS:
      return 'Ground Transportation';
    case TransactionLayout.TRAVEL_BOOKING_TRAIN_TRAVEL:
      return 'Train Travel';
    default:
      return 'Agent/Incidental';
  }
};
