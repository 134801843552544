import { ContextualMenu, IDragOptions, Modal, Stack } from '@fluentui/react';
import React from 'react';
import { GetEntityDocument_entityDocument } from '../../../__generated__/GetEntityDocument';
import { PORevisionsList } from './PORevisionsList';

interface RevisionModalProps {
  isOpen: boolean;
  entityDocument: GetEntityDocument_entityDocument;
  onClose: () => void;
}

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

export const RevisionModal: React.FC<RevisionModalProps> = ({
  isOpen,
  entityDocument,
  onClose
}) => {
  return (
    <Modal isOpen={isOpen} isBlocking dragOptions={DragOptions}>
      <Stack
        style={{ height: 600, width: 1200 }}
      >
        <PORevisionsList
          entityDocument={entityDocument}
          onClose={onClose}
        />
      </Stack>
    </Modal>
  )
}
