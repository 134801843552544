import {
  ComboBox,
  IComboBoxProps,
  SelectableOptionMenuItemType,
} from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import React, { FC } from 'react';
import { useController } from 'react-hook-form';

export const FormHookComboBox: FC<RequiredNameProps<IComboBoxProps>> = ({
  name,
  onChange,
  ...props
}) => {
  const { field } = useController({
    name,
  });

  const { value: fieldValue } = field;

  if (fieldValue && typeof fieldValue === 'object') {
    const selectAllOption = props.options.find(
      (opt) => opt.itemType === SelectableOptionMenuItemType.SelectAll
    );
    const numOptions = selectAllOption
      ? props.options.length - 1
      : props.options.length;
    const selectedKeys = props.options
      .map((opt) => opt.key as string)
      .filter((key) => fieldValue[key]);
    const allSelected = selectedKeys.length === numOptions;

    return (
      <ComboBox
        {...props}
        selectedKey={[
          ...(allSelected && selectAllOption
            ? [selectAllOption.key as string]
            : []),
          ...selectedKeys,
        ]}
        onChange={(event, option, index, value) => {
          if (option) {
            const newValueObject =
              option.itemType === SelectableOptionMenuItemType.SelectAll
                ? Object.fromEntries(
                    Object.keys(fieldValue).map((key) => [key, !allSelected])
                  )
                : { ...fieldValue, [option.key]: option.selected };

            field.onChange(newValueObject);
          }

          onChange?.(event, option, index, value);
        }}
      />
    );
  } else
    return (
      <ComboBox
        {...props}
        onChange={(event, option, index) => {
          field.onChange(option?.key);
          onChange?.(event, option, index);
        }}
      />
    );
};
