import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import { DangerButton } from 'common/components/DangerButton';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AccountingEntryPayCycle } from '../__generated__/AccountingEntryPayCycle';
import {
  PayCycleAccountingUnstamper,
  PayCycleAccountingUnstamperVariables,
} from '../__generated__/PayCycleAccountingUnstamper';
import { useStyles } from './index.styles';
const UNSTAMP = loader('../PayCycleAccountingUnstamper.graphql');
const CONFIRM_STAMP_REMOVE_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT =
  'This will remove the stamp from the batch.';

interface UnStampProps {
  payCycle: AccountingEntryPayCycle | undefined;
}

export const UnStamp: React.FC<UnStampProps> = ({ payCycle }) => {
  const [visible, setVisible] = useState(false);
  const styles = useStyles();
  const { addToast } = useToasts();

  const [unStampPayCycle, { loading }] = useMutation<
    PayCycleAccountingUnstamper,
    PayCycleAccountingUnstamperVariables
  >(UNSTAMP, { errorPolicy: 'all' });

  return (
    <Stack>
      {payCycle?.payCycle?._isAccountingEntryStampedComplete && (
        <PrimaryButton text="Remove Stamp" onClick={() => setVisible(true)} />
      )}
      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={{
            title: CONFIRM_STAMP_REMOVE_DIALOG_TITLE,
            type: DialogType.largeHeader,
            subText: CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT,
          }}
        >
          {loading && <ProgressIndicator />}
          <DialogFooter>
            <DangerButton
              text="Unstamp"
              className={styles.unStampButton}
              onClick={async () => {
                const { id, _rowTimestamp } = { ...payCycle?.payCycle };
                const { errors } = await unStampPayCycle({
                  variables: {
                    input: {
                      rowTimestamp: _rowTimestamp!,
                      id: id!,
                    },
                  },
                });
                setVisible(false);
                if (errors?.length)
                  addToast(errors[0].message, {
                    appearance: 'error',
                  });
                else {
                  addToast('Unstamp successful', {
                    appearance: 'success',
                  });
                }
              }}
            />

            <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}
    </Stack>
  );
};
