import { useQuery } from '@apollo/client';
import {
  Modal,
  PivotItem,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import { StampOptions } from 'common/components/StamperView';
import { ApprovalState } from 'common/types/globalTypes';
import { GetDocumentDetails_fileCabinetHistoryTransaction_nodes } from 'documents/fileCabinet/folder/view/__generated__/GetDocumentDetails';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { HeaderComponent } from '../components/HeaderComponent';
import { PivotComponent, PivotItemsArray } from '../components/PivotComponent';
import { PaymentDetail } from './PaymentDetail';
import {
  PaymentDetails,
  PaymentDetailsVariables,
} from './__generated__/PaymentDetails';
import { useStyles } from './index.styles';
import { HierarchyData } from 'ap/paymentCycle/view/TransactionModal/HierarchyData';
import { SignatureView } from 'common/components/Signatures';
const PAYMENT_DETAILS = loader('./PaymentDetails.graphql');
enum HeaderText {
  DETAILS = 'viewDetails',
  HIERARCHY = 'viewHierarchy',
  SIGNATURE = 'viewSignature',
}
const pivotItemsArray: PivotItemsArray[] = [
  {
    itemKey: HeaderText.DETAILS,
    headerText: 'Details',
  },
  {
    itemKey: HeaderText.HIERARCHY,
    headerText: 'Hierarchy',
  },
  {
    itemKey: HeaderText.SIGNATURE,
    headerText: 'Signature',
  },
];

interface PaymentsModalProps {
  data: GetDocumentDetails_fileCabinetHistoryTransaction_nodes;
  onDismiss: () => void;
}
export const PaymentsModal: React.FC<PaymentsModalProps> = ({
  data,
  onDismiss,
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = useState<string>(HeaderText.DETAILS);
  const { entityId, linkType } = { ...data };

  const { data: paymentDetailsData, loading } = useQuery<
    PaymentDetails,
    PaymentDetailsVariables
  >(PAYMENT_DETAILS, {
    variables: {
      id: entityId!,
    },
    skip: !entityId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const {
    payCycleId,
    payCycle,
    transactionType,
    statusType,
    _documentPackageId,
    _accountingStampDate,
    _accountingStampTransactionReference,
    _accountingStampUserName,
    _isAccountingEntryStampedComplete,
    _isTransactionCancelled,
  } = { ...paymentDetailsData?.payment };

  const { approvalHistorySignaturesByEntityId } = { ...payCycle };

  const stampData: StampOptions = {
    _accountingStampDate,
    _accountingStampTransactionReference,
    _accountingStampUserName,
    _isAccountingEntryStampedComplete,
    _isTransactionCancelled,
  };

  const isApproved = statusType?.approvalState === ApprovalState.APPROVED;

  const onLinkClick = (item?: PivotItem) => {
    if (item?.props.itemKey) setSelectedKey(item.props.itemKey);
  };

  const getSelectedSection = (key: string) => {
    switch (key) {
      case HeaderText.DETAILS:
        return (
          <Stack>
            <PaymentDetail
              payment={paymentDetailsData?.payment}
              loading={loading}
            />
          </Stack>
        );
      case HeaderText.HIERARCHY:
        return <HierarchyData entityId={payCycleId!} />;
      case HeaderText.SIGNATURE:
        return (
          <Stack tokens={{ padding: 20 }}>
            {!!approvalHistorySignaturesByEntityId?.nodes.length && (
              <SignatureView
                signatureData={approvalHistorySignaturesByEntityId?.nodes}
              />
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen isBlocking onDismiss={onDismiss}>
      <Stack className={styles.container}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <Stack
              className={styles.stickyInsideStack}
              tokens={{ childrenGap: 10 }}
            >
              <HeaderComponent
                entityId={entityId!}
                entityType={linkType!}
                heading={transactionType?.transactionType || ''}
                isApproved={isApproved}
                documentPackageId={_documentPackageId}
                stampData={stampData}
                onDismiss={onDismiss}
              />
              <PivotComponent
                pivotItems={pivotItemsArray}
                selectedKey={selectedKey}
                onLinkClick={onLinkClick}
              />
            </Stack>
          </Sticky>
          {getSelectedSection(selectedKey)}
        </ScrollablePane>
      </Stack>
    </Modal>
  );
};
