import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import {
  NotesSection,
  NotesSectionProps,
} from 'common/components/NotesSection';
import {
  NotePayCycleCreate,
  NotePayCycleCreateVariables,
} from 'ap/paymentCycle/view/ActionMenu/PayCycleNotes/__generated__/NotePayCycleCreate';

const ADD_NEW_NOTE = loader(
  '../../../../../view/ActionMenu/PayCycleNotes/NotePayCycleCreate.graphql'
);

type PayCycleAccountingNotesProps = Pick<
  NotesSectionProps,
  'listRefreshRequest'
> & {
  payCycleId: string;
  data: NotesEntityFields[];
  loading?: boolean;
};

export const PayCycleAccountingNotesSection: React.FC<PayCycleAccountingNotesProps> =
  ({ payCycleId, data, loading, listRefreshRequest }) => {
    const [createNote, { loading: createNoteInProgress, error }] = useMutation<
      NotePayCycleCreate,
      NotePayCycleCreateVariables
    >(ADD_NEW_NOTE);

    const noteAddMutation: NoteAddMutationProps = {
      loading: createNoteInProgress,
      createNote: async (formData) => {
        const { noteComment, isShared } = { ...formData };
        await createNote({
          variables: {
            input: {
              note: {
                entityId: payCycleId,
                noteComment: noteComment!,
                isShared: isShared!,
              },
            },
          },
        });
        if (!error) listRefreshRequest?.();
        return error;
      },
    };

    return (
      <NotesSection
        dataLoading={loading!}
        noteAddMutation={noteAddMutation}
        addingNoteInProgress={createNoteInProgress}
        listRefreshRequest={listRefreshRequest}
        data={data}
      />
    );
  };
