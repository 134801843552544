import {
  ContextualMenu,
  IContextualMenuItem,
  Link,
  Stack,
} from '@fluentui/react';
import React, { ReactNode, useState } from 'react';
import { nodeMapType } from '../../../utils';
import { setActivePoolDocument } from 'utility/cache/ui';

type RightClickMenuProps = {
  children: ReactNode;
  id: string;
  selectedPool: nodeMapType;
  onClick: (value: 'CREATE' | 'UPDATE' | 'DELETE') => void;
};
export const RightClickMenu: React.FC<RightClickMenuProps> = ({
  id,
  selectedPool,
  onClick,
  children,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const linkRef = React.useRef(null);

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (menuPosition.x !== event.clientX || menuPosition.y !== event.clientY) {
      setActivePoolDocument(selectedPool);
      setMenuPosition({ x: event.clientX, y: event.clientY });
      setMenuVisible(true);
    }
  };

  const handleClick = () => {
    setMenuVisible(false);
  };

  const menuItems: IContextualMenuItem[] = [
    {
      key: 'add',
      text: 'Add Folder',
      iconProps: { iconName: 'FabricNewFolder' },
      disabled: !selectedPool._isUpdatable,
      onClick: () => {
        onClick('CREATE');
      },
    },

    {
      key: 'edit',
      text: 'Update Folder',
      iconProps: { iconName: 'Edit' },
      disabled: !selectedPool._isUpdatable,
      onClick: () => {
        onClick('UPDATE');
      },
    },
    {
      key: 'delete',
      text: 'Delete folder',
      iconProps: { iconName: 'Delete' },
      onClick: () => {
        onClick('DELETE');
      },
      disabled: !selectedPool._isDeletable,
    },
  ];

  const onHideContextualMenu = React.useCallback(
    () => setMenuVisible(false),
    []
  );

  return (
    <Stack
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      style={{ cursor: 'pointer' }}
    >
      {menuVisible && <Stack />}
      <Stack>
        <Link
          ref={linkRef}
          href=""
          style={{
            position: 'absolute',
            top: `${menuPosition.y - 50}px`,
            left: `${menuPosition.x - 220}px`,
          }}
        />
        <ContextualMenu
          items={menuItems}
          hidden={!menuVisible}
          target={linkRef}
          onItemClick={onHideContextualMenu}
          onDismiss={onHideContextualMenu}
        />
      </Stack>

      {children}
    </Stack>
  );
};
