import { CorporateChartOfAccount } from './__generated__/CorporateChartOfAccount';
import { CORPORATE_CHART_OF_ACCOUNTS_VALUES } from './constants';
import { CorporateChartOfAccountsValues } from './types';

interface DefaultValueProps {
  isEdit: boolean;
  data: CorporateChartOfAccount | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isEdit, data } = { ...props };
  let defaultValues: CorporateChartOfAccountsValues = {
    ...CORPORATE_CHART_OF_ACCOUNTS_VALUES,
  };
  if (isEdit && data?.corporateChartOfAccount) {
    const {
      __typename,
      id,
      _isUpdatable,
      _isDeletable,
      _rowTimestamp,
      _defaults,
      ...values
    } = {
      ...data?.corporateChartOfAccount,
    };
    defaultValues = {
      ...values,
    };
  }
  return defaultValues as CorporateChartOfAccountsValues;
};
