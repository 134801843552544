import { DocumentPoolsOrderBy } from '../../common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
    [direction in OrderDirection]: DocumentPoolsOrderBy[];
  };

  const orderByMap = new Map<string, OrderLookup>([
    [
      'name',
      {
        [OrderDirection.ASC]: [
          DocumentPoolsOrderBy.NAME_ASC,
        ],
        [OrderDirection.DESC]: [
          DocumentPoolsOrderBy.NAME_DESC
        ],
      },
    ],
    [
      'description',
      {
        [OrderDirection.ASC]: [
          DocumentPoolsOrderBy.DESCRIPTION_ASC,
        ],
        [OrderDirection.DESC]: [
          DocumentPoolsOrderBy.DESCRIPTION_DESC,
        ],
      },
    ],
    [
      'defaultDirectoryTypes.description',
      {
        [OrderDirection.ASC]: [
          DocumentPoolsOrderBy.DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DESCRIPTION_ASC,
        ],
        [OrderDirection.DESC]: [
          DocumentPoolsOrderBy.DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DESCRIPTION_DESC,
        ],
      },
    ],
    [
        'defaultDocumentTypes.documentType',
        {
          [OrderDirection.ASC]: [
            DocumentPoolsOrderBy.DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC,
          ],
          [OrderDirection.DESC]: [
            DocumentPoolsOrderBy.DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC,
          ],
        },
    ],
    [
      'documentPoolTotals.availableDocuments',
      {
        [OrderDirection.ASC]: [
          DocumentPoolsOrderBy.DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC,
        ],
        [OrderDirection.DESC]: [
          DocumentPoolsOrderBy.DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC,
        ],
      },
    ],
  ]);

  export const toOrderByVariable = (sort?: SortOrder) => {
    const orderByArray = [DocumentPoolsOrderBy.NAME_ASC];
    if (!sort) return orderByArray;
  
    const orderBy = orderByMap.get(sort.column)?.[sort.direction];
    return [...(orderBy ? orderBy : []), ...orderByArray];
  };