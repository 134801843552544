import { Pivot, PivotItem } from '@fluentui/react';
import React from 'react';

export interface PivotItemsArray {
  itemKey: string;
  headerText: string;
}

interface PivotComponentProps {
  pivotItems: PivotItemsArray[];
  selectedKey: string;
  onLinkClick: (item?: PivotItem) => void;
}

export const PivotComponent: React.FC<PivotComponentProps> = ({
  pivotItems,
  selectedKey,
  onLinkClick,
}) => {
  return (
    <Pivot
      selectedKey={selectedKey}
      onLinkClick={onLinkClick}
      headersOnly
    >
      {
        pivotItems.map(ele => {
          return (
            <PivotItem
              key={ele.itemKey}
              itemKey={ele.itemKey}
              headerText={ele.headerText}
            />
          )
        })
      }
    </Pivot>
  )
}
