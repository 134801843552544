import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  footerStyle: {
    backgroundColor: theme.palette.neutralLighterAlt,
    width: '100%',
    boxShadow: `-1px -4px 13px rgba(0, 0, 0, 0.15)`,
    position: 'sticky',
    bottom: 0,
  },
  diabledButton: {
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  marginLeft15: {
    marginLeft: 15,
  },
  tagsContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  approvalHistoryContainer: {
    margin: '20px 0',
    paddingLeft: 25,
    paddingRight: 25,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
}));
