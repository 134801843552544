import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexDirection: 'row',
      height: 50,
      alignItems: 'center',
      justifyContent: 'space-between',
      width: "100%"
    },
    linkLightHover: {
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    headerCell: {
      flex: 1, 
      paddingLeft: 15,
      height: 50,
    },
    accountHeaderCell: {
      flex: 1, 
      paddingLeft: 25,
      height: 50,
    },
  };
});
