import { useQuery } from '@apollo/client';
import {
  Callout,
  DirectionalHint,
  IconButton,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { FormHookDropdown } from 'common/components/FormHooksFields';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { PurchaseCardCommonData } from 'settings/project/purchaseCards/__generated__/PurchaseCardCommonData';
import { useStyles } from './index.styles';

const COMMON_DATA = loader('../../../../../PurchaseOrderCommonData.graphql');

interface AdditionalProps {
  disabled: boolean;
}
export const Additional: React.FC<AdditionalProps> = ({ disabled }) => {
  const [visible, setVisible] = useState(false);
  const { data: commonData } = useQuery<PurchaseCardCommonData>(COMMON_DATA, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });
  const styles = useStyles();
  const calloutId = useId('additionalBusinessDepartment');

  const businessUnitOptions =
    commonData?.companyBusinessUnits?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
    })) || [];

  const departmentOptions =
    commonData?.companyDepartments?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
      ...item,
    })) || [];

  const watchData = useWatch({
    name: ['additionalBusinessUnitId', 'additionalDepartmentId'],
  });

  const watchBusinesId = watchData[0];
  const watchDepId = watchData[1];
  return (
    <Stack>
      <TooltipHost content={'Add additional Business/Department'}>
        <Stack horizontal verticalAlign="center">
          {(!!watchBusinesId || !!watchDepId) && (
            <Stack className={styles.badge} />
          )}
          <IconButton
            id={calloutId}
            iconProps={{ iconName: 'AddHome' }}
            onClick={() => setVisible(true)}
          />
        </Stack>
      </TooltipHost>

      {visible && (
        <Callout
          className={styles.callout}
          gapSpace={0}
          onDismiss={() => setVisible(false)}
          target={`#${calloutId}`}
          directionalHint={DirectionalHint.leftTopEdge}
          setInitialFocus
        >
          <Stack tokens={{ childrenGap: 20 }} className={styles.container}>
            <FormHookDropdown
              label="Business Unit"
              placeholder="Select"
              options={businessUnitOptions}
              name="additionalBusinessUnitId"
              disabled={disabled}
            />

            <FormHookDropdown
              label="Department"
              placeholder="Select"
              options={departmentOptions}
              name="additionalDepartmentId"
              calloutProps={{ calloutWidth: 200 }}
              disabled={disabled}
            />
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};
