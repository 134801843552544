import React, { useState } from 'react';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { CommonData } from '../Documents/__generated__/CommonData';
import { formatDropdownOptions } from 'common/utils';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { IDropdownOption, Stack } from '@fluentui/react';

interface DocumentsSearchBar extends SearchBarProps {
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
  toggleActions?: () => void;
  commonData: CommonData | undefined;
}
export const DocumentsSearchBar: React.FC<DocumentsSearchBar> = ({
  columns,
  onEnterPress,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  urlParams,
  onFilterChange,
  onGlobalToggle,
  commonData,
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);

  const documentTypeOptions = formatDropdownOptions(
    commonData?.documentTypeUploads?.nodes,
    {
      getKey: (item) => item.id!,
      getText: (item) => item.documentType!,
      includeAll: true,
    }
  );

  const folderOptions = formatDropdownOptions(
    commonData?.entityDocumentPools?.nodes,
    {
      getKey: (item) => item.id!,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );
  const createdByOptions = formatDropdownOptions(
    commonData?.entityDocumentUsers?.nodes,
    {
      getKey: (item) => item.userId!,
      getText: (item) => item.userName!,
      includeAll: true,
    }
  );

  const verifyOptions: IDropdownOption[] = [
    { key: 'true', text: 'Verified' },
    { key: 'false', text: 'Unverified' },
    { key: 'all', text: 'All' },
  ];
  const historyOptions: IDropdownOption[] = [
    { key: 'true', text: 'History' },
    { key: 'false', text: 'Non-history' },
    { key: 'all', text: 'All' },
  ];
  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      onGlobalToggle={onGlobalToggle}
      isGlobalAvailable={false}
      urlParams={urlParams}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      hintsAvailable={false}
      onToggleVisibility={onToggleVisibility}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'_isSelected'}
          options={historyOptions}
          placeholder={'History'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_isVerified'}
          options={verifyOptions}
          placeholder={'Verified'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_createdByUserid'}
          options={createdByOptions}
          placeholder={'Created By'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'documentTypeId'}
          options={documentTypeOptions}
          placeholder={'Document type'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'_documentPoolId'}
          options={folderOptions}
          placeholder={'Folder'}
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
