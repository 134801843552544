import React, { useEffect, useRef, useState, useCallback } from 'react';
import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
  IColumn,
  IconButton,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { ColumnData, SearchBar } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import { useStyles } from './index.styles';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  CommunicationGroups,
  CommunicationGroupsVariables,
  CommunicationGroups_communicationGroups_nodes,
} from './__generated__/CommunicationGroups';
import {
  CommunicationGroupItemsOrderBy,
  CommunicationGroupsOrderBy,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { toOrderByVariable } from './utils';
import { columns } from './column.data';
import { CommunicationGroupItemCallout } from './CommunicationGroupItemCallout';
import { CreateCommunicationGroupForm } from '../view';
import {
  CommunicationGroupDelete,
  CommunicationGroupDeleteVariables,
} from './__generated__/CommunicationGroupDelete';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
import { useToasts } from 'react-toast-notifications';
const COMMUNICATION_GROUP = loader('./CommunicationGroups.graphql');
const DELETE_COMMUNICATION_GROUP = loader('./CommunicationGroupDelete.graphql');

export type CommunicationGroupListItem =
  CommunicationGroups_communicationGroups_nodes;

export const CommunicationGroup: React.FC = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const renderRef = useRef(false);
  const { addToast } = useToasts();
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [updateData, setUpdateData] = useState<
    CommunicationGroups_communicationGroups_nodes | undefined | null
  >();
  const [hideCreateDialog, setHideCreateDialog] = useState<boolean>(false);
  const toggleConfirmCreateDialog = () =>
    setHideCreateDialog((prevState) => !prevState);
  const [selectedRows, setSelectedRows] = useState<
    CommunicationGroupListItem[]
  >([]);

  const [
    fetchCommunicationGroupList,
    {
      loading: communicationGroupListLoading,
      data: communicationGroupListData,
      networkStatus,
      fetchMore,
      variables,
    },
  ] = useLazyQuery<CommunicationGroups, CommunicationGroupsVariables>(
    COMMUNICATION_GROUP,
    {
      variables: {
        first: TABLE_ROWS,
        orderBy: [CommunicationGroupsOrderBy.NAME_ASC],
        communicationGroupItems: [
          CommunicationGroupItemsOrderBy.COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NAME_ASC,
        ],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const [deleteGroup] = useMutation<
    CommunicationGroupDelete,
    CommunicationGroupDeleteVariables
  >(DELETE_COMMUNICATION_GROUP, {
    errorPolicy: 'all',
  });

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const transformedData: CommunicationGroupListItem[] | undefined = refetching
    ? undefined
    : communicationGroupListData?.communicationGroups?.nodes.map((appr) => ({
        ...appr,
      }));

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const handleSearch = (showMore: boolean) => {
    const variables: CommunicationGroupsVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? communicationGroupListData?.communicationGroups?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
    };

    if (showMore) fetchMore?.({ variables });
    else fetchCommunicationGroupList({ variables: variables });
  };

  const handleSearchMemo = useCallback(handleSearch, [sortOrderParam]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    handleSearchMemo(false);
    renderRef.current = true;
  }, [handleSearchMemo]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [handleSearchMemo]);

  const _onUpdateModalOpen = (
    item: CommunicationGroups_communicationGroups_nodes
  ) => {
    if (item) {
      setUpdateData(item);
      setIsNew(false);
    }
    toggleConfirmCreateDialog();
  };

  const _renderItemColumn = (
    item: CommunicationGroupListItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item?.[
        column?.fieldName as keyof CommunicationGroupListItem
      ] as string;
      switch (column?.key) {
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
              className={styles.contentColumnCenter}
            >
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                // disabled={!item._isUpdatable}
                onClick={() => {
                  _onUpdateModalOpen(item);
                }}
              />
            </Stack>
          );

        case 'communicationGroupItemsByCommunicationGroupId':
          return (
            <Stack wrap horizontal tokens={{ childrenGap: 10 }}>
              {item.communicationGroupItemsByCommunicationGroupId.nodes.map(
                (groupItems, index) => (
                  <Stack key={index}>
                    <CommunicationGroupItemCallout groupItemdata={groupItems} />
                  </Stack>
                )
              )}
            </Stack>
          );

        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{fieldContent ?? ''}</Text>
            </Stack>
          );
      }
    }
  };
  const _onConfirm = async () => {
    const deletableGroupItems = selectedRows.filter(
      (item) => item._isDeletable
    );
    if (deletableGroupItems.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableGroupItems.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      const { errors } = await deleteGroup({
        variables: {
          input: {
            entityDelete: deleteInputArray,
          },
        },
        update: (cache, { data }) => {
          const deletedIds =
            data?.communicationGroupDelete?.deletedEntities?.map(
              (entity) => entity?.id
            );
          if (deletedIds) {
            const filteredList =
              communicationGroupListData?.communicationGroups?.nodes.filter(
                (channel) => deletedIds.indexOf(channel.id) === -1
              );
            const newData: CommunicationGroups = {
              communicationGroups: {
                pageInfo:
                  communicationGroupListData?.communicationGroups?.pageInfo!,
                nodes: filteredList!,
                totalCount:
                  communicationGroupListData?.communicationGroups?.totalCount! -
                  deletedIds.length,
              },
            };
            cache.writeQuery<CommunicationGroups, CommunicationGroupsVariables>(
              {
                query: COMMUNICATION_GROUP,
                variables,
                data: newData,
              }
            );
          }
        },
      });
      if (!errors) {
        addToast('Communication group deleted Successfully', {
          appearance: 'success',
        });
        setSelectedRows([]);
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };
  const disabled = !selectedRows.some((selected) => selected._isDeletable);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Communication Groups</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.CommunicationGroup}
              disabled={disabled}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete.name),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              iconProps={{
                iconName: 'Add',
              }}
              onClick={() => {
                setUpdateData(null);
                setIsNew(true);
                toggleConfirmCreateDialog();
              }}
              text="New Group"
            />
          </Stack>
        </Stack>
        <SearchBar
          searchEnabled={false}
          columns={[]}
          onRefresh={() => handleSearch(false)}
        />
      </Stack>
      <InfiniteList<CommunicationGroupListItem>
        compact={false}
        loading={communicationGroupListLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={
          communicationGroupListData?.communicationGroups?.pageInfo.hasNextPage
        }
        items={transformedData}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={() => handleSearch(true)}
        onSelectionChanged={setSelectedRows}
      />
      {hideCreateDialog && (
        <CreateCommunicationGroupForm
          visible={hideCreateDialog}
          onDismiss={toggleConfirmCreateDialog}
          channelData={updateData!}
          isNew={isNew}
        />
      )}
    </>
  );
};
