import { CorporateWorkgroup } from './__generated__/CorporateWorkgroup';
import { CORPORATE_WORKGROUP_VALUES } from './constants';
import { CorporateWorkgroupValues } from './types';

interface DefaultValueProps {
  isNew: boolean;
  corporateWorkgroupData: CorporateWorkgroup | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, corporateWorkgroupData } = { ...props };
  let defaultValues: CorporateWorkgroupValues = CORPORATE_WORKGROUP_VALUES;
  if (corporateWorkgroupData?.corporateWorkgroup && !isNew) {
    const {
      id,
      _rowTimestamp,
      _isUpdatable,
      _isDeletable,
      corporateChartOfAccount,
      statusType,
      ...workgroupValues
    } = { ...corporateWorkgroupData.corporateWorkgroup };
    defaultValues = {
      ...workgroupValues,
      chartOfAccountsId: corporateChartOfAccount?.id!,
    };
  }
  return defaultValues;
};
