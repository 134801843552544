import { Callout, DirectionalHint, Link, Stack, Text } from '@fluentui/react';
import React from 'react';
import { LookupDepotSearch_lookupDepotSearch_nodes } from '../__generated__/LookupDepotSearch';
import { useStyles } from './index.styles';

export interface LookUpCalloutProps {
  data: LookupDepotSearch_lookupDepotSearch_nodes[];
  onDismiss?: () => void;
  targetID: string;
  onSelect: (value: LookupDepotSearch_lookupDepotSearch_nodes) => void;
  lookUpCalloutWidth?: number;
}

export const LookUpCallout: React.FC<LookUpCalloutProps> = ({
  onDismiss,
  data,
  targetID,
  onSelect,
  lookUpCalloutWidth,
}) => {
  const styles = useStyles();
  if (!data?.length) return null;
  return (
    <Callout
      onDismiss={onDismiss}
      gapSpace={10}
      isBeakVisible={false}
      className={styles.callout}
      directionalHint={DirectionalHint.bottomCenter}
      target={targetID}
      style={{ width: lookUpCalloutWidth }}
    >
      <Stack className={styles.searchAreaContainer}>
        <Stack>
          {data?.map((item, index) => {
            return (
              <Link
                onClick={() => {
                  onSelect(item);
                  onDismiss?.();
                }}
                className={styles.resultItem}
                key={item.id}
              >
                <Stack>
                  <Text>{item.description} </Text>
                </Stack>
              </Link>
            );
          })}
        </Stack>
      </Stack>
    </Callout>
  );
};
