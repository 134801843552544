import * as Yup from 'yup';
export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required('Required').nullable(),
    emailReplyTo: Yup.string().email('Enter a valid email address').nullable(),
    companyAddressesByCompanyIdAndEntityId: Yup.array().of(
      Yup.object().shape({
        addressLine1: Yup.string().required('Required').nullable(),
        emailAddress: Yup.string()
          .email('Enter a valid email address')
          .nullable(),
      })
    ),
  });
