import { ApolloError } from '@apollo/client';
import {
  DefaultButton,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField,
  Toggle,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';
export interface NoteFormFields {
  noteComment: string | undefined;
  isShared: boolean | undefined;
}

export interface NoteAddMutationProps {
  loading: boolean;
  createNote: (formData: NoteFormFields) => Promise<ApolloError | undefined>;
}

export interface NotesFormProps {
  addingNoteInProgress?: boolean;
  noteAddMutation: NoteAddMutationProps;
  isDirty?: boolean;
}

export const NotesForm: React.FC<NotesFormProps> = ({
  addingNoteInProgress,
  noteAddMutation,
  isDirty,
}) => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const [noteComment, setNoteComment] = useState<string | undefined>();
  const [notesToggle, setNotesToggle] = useState(false);
  const [isShared, setNoteIsShared] = React.useState<boolean>(true);

  const resetNoteValue = () => {
    setNoteComment('');
    setNoteIsShared(true);
    setNotesToggle(!notesToggle);
  };

  const { createNote } = noteAddMutation;

  const onClick = async () => {
    const errors = await createNote({ noteComment, isShared });

    if (!errors) {
      resetNoteValue();
      addToast('Note added successfully.', {
        appearance: 'success',
      });
    } else
      addToast(`Error while adding Notes`, {
        appearance: 'error',
      });
  };

  return (
    <>
      {notesToggle ? (
        <Stack
          className={styles.addNotesSection}
          tokens={{ childrenGap: 10, padding: '20px 30px' }}
        >
          <Stack horizontal horizontalAlign="space-between" verticalAlign="end">
            <Text className={styles.boldFont}>Add Note:</Text>
            <div className={styles.fixedToggleContainer}>
              <Toggle
                checked={!isShared}
                onText="Private"
                offText="Public"
                disabled={addingNoteInProgress}
                className={styles.publicPrivateToggle}
                onChange={(_event, checked) => setNoteIsShared(!checked)}
              />
            </div>
          </Stack>
          <TextField
            multiline
            rows={4}
            value={noteComment}
            placeholder="Write a new note..."
            resizable={false}
            onChange={(_event, value) => setNoteComment(value || '')}
          />
          {addingNoteInProgress && <ProgressIndicator label="Creating note" />}
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              disabled={!noteComment}
              text="Save"
              onClick={onClick}
            />
            <DefaultButton
              text="Cancel"
              onClick={() => setNotesToggle(false)}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="end">
          <DefaultButton
            iconProps={{ iconName: 'Add' }}
            text="Create Note"
            onClick={() => {
              resetNoteValue();
            }}
            disabled={isDirty}
          />
        </Stack>
      )}
    </>
  );
};
