import { ColumnData } from 'common/components/SearchBar';

export const getColumn = (columnKey: string, existingColumns: ColumnData[]) => {
  const filterColumn = existingColumns.filter(
    (column) => column.key === columnKey
  );
  return filterColumn[0];
};

export const getSortedColumns = (
  clickedColumn: ColumnData,
  existingColumns: ColumnData[]
) => {
  const desc =
    clickedColumn?.isSortedDescending === undefined
      ? false
      : !clickedColumn?.isSortedDescending;
  const newColumns = existingColumns.map((col) => ({
    ...col,
    isSorted: clickedColumn?.key === col.key,
    isSortedDescending: clickedColumn?.key === col.key ? desc : undefined,
  }));

  return { newColumns, desc };
};

const hasWindow = typeof window !== 'undefined';

export const getWindowDimensions = () => {
  const width = hasWindow ? window.innerWidth : null;
  const height = hasWindow ? window.innerHeight : null;
  return {
    width,
    height,
  };
};
export const getSubtractedValue = (
  sum: string | null,
  amountArray: (string | null)[] | undefined
) => {
  return (
    Number(sum) -
    (amountArray?.reduce((total, item) => total + Number(item), 0) ?? 0)
  );
};
