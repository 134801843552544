import { useQuery } from '@apollo/client';
import { Stack, Sticky, StickyPositionType, Text } from '@fluentui/react';
import {
  DocumentPoolFiles,
  DocumentPoolFilesVariables,
} from 'common/graphql/__generated__/DocumentPoolFiles';
import { useCommonStyles } from 'common/styles';
import { DocumentPoolsOrderBy } from 'common/types/globalTypes';
import { Tabs } from 'documents/documentAssignment/Tabs';
import { loader } from 'graphql.macro';
import React, { useRef } from 'react';
import FolderView from './FolderView';
import { DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes } from './__generated__/DocumentPoolDocuments';
import { useStyles } from './index.styles';
import { buildTree, nodeMapType } from './utils';

const DOCUMENT_POOL_DATA = loader(
  '../../../../common/graphql/DocumentPool.graphql'
);

export type DocumentEntity =
  DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes & {
    documentType: string;
    usedTotal: string;
    remainingTotal: string;
    isoCode: string;
    _uploadDate: string;
  };

// DOCUMENT LOADER
export const FolderList: React.FC = () => {
  const styles = useStyles();
  const stickyHeaderRef = useRef<Sticky>(null);
  const commonStyles = useCommonStyles();
  const { data: DocumentFilesData } = useQuery<
    DocumentPoolFiles,
    DocumentPoolFilesVariables
  >(DOCUMENT_POOL_DATA, {
    variables: {
      orderBy: [DocumentPoolsOrderBy.NAME_ASC],
      filter: {
        defaultDocumentTypes: {
          isReportingDocument: {
            equalTo: false,
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const rootNodes: nodeMapType[] = buildTree(
    DocumentFilesData?.documentPools?.nodes ?? null
  );

  return (
    <Stack className={styles.container}>
      <Sticky stickyPosition={StickyPositionType.Header} ref={stickyHeaderRef}>
        <Stack
          tokens={{ childrenGap: 20 }}
          className={commonStyles.listHeaderContainer}
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={commonStyles.listTitleContainer}
          >
            <Text variant="xLarge">Folders</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <Stack
              horizontal
              verticalAlign="end"
              horizontalAlign="space-between"
            >
              <Tabs />
            </Stack>
          </Stack>
        </Stack>
      </Sticky>

      {rootNodes[0] && (
        <FolderView rootNodes={rootNodes} documentPool={rootNodes[0]} />
      )}
    </Stack>
  );
};
