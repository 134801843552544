import { TagGroupsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: TagGroupsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [TagGroupsOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [TagGroupsOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [
        TagGroupsOrderBy.DESCRIPTION_ASC,
      ],
      [OrderDirection.DESC]: [
        TagGroupsOrderBy.DESCRIPTION_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    TagGroupsOrderBy.NAME_ASC,
  ];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};
