import { Separator, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { AccountingEntryPayCycle } from '../__generated__/AccountingEntryPayCycle';
import { DataView } from 'common/components/DataView';

interface BasicFormProps {
  payCycleDetails?: AccountingEntryPayCycle;
}

export const BasicForm: React.FC<BasicFormProps> = ({ payCycleDetails }) => {
  const style = useStyles();
  const [data, setData] = useState<AccountingEntryPayCycle>();
  useEffect(() => {
    setData(payCycleDetails);
  }, [payCycleDetails]);
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 50 }}
      className={style.mainContainer}
    >
      <Stack
        grow={5}
        style={{ maxWidth: '500px' }}
        tokens={{ childrenGap: 20 }}
      >
        <DataView
          title="Transaction Type"
          description={data?.payCycle?.transactionType?.transactionType || '-'}
        />
        <DataView
          title={'Payment Date'}
          description={
            (data?.payCycle?.defaultPaymentDate &&
              dateFormat(
                dateConvertions(data?.payCycle?.defaultPaymentDate)
              )) ||
            '-'
          }
        />
        <DataView
          title="Accounting Period"
          description={
            data?.payCycle?.companyCorporatePeriod
              ? `${
                  data?.payCycle?.companyCorporatePeriod._periodYear
                } (${dateFormat(
                  dateConvertions(
                    data.payCycle.companyCorporatePeriod.startDate
                  )
                )} - ${dateFormat(
                  dateConvertions(
                    data?.payCycle?.companyCorporatePeriod.endDate
                  )
                )})`
              : '-'
          }
        />
        <DataView
          title={'Description'}
          description={data?.payCycle?.description || '-'}
        />
      </Stack>
      <Separator vertical />
      <Stack
        grow={5}
        style={{ maxWidth: '300px' }}
        tokens={{ childrenGap: 20 }}
      >
        <DataView
          title="Bank Account"
          description={
            data?.payCycle?.paymentAccount?.name +
              ' - ' +
              data?.payCycle?.paymentAccount?.bankAccountNumber || '-'
          }
        />
        <DataView
          title="Currency"
          description={data?.payCycle?.paymentAccount?.currency?.isoCode || '-'}
        />
        <DataView
          title={'Cycle Total'}
          description={data?.payCycle?.controlTotalAmount || '-'}
          isAmount
        />
      </Stack>
    </Stack>
  );
};
