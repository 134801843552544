import { IconButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import { ReviewCompact } from './ReviewCompact';
import { ReviewDetails } from './ReviewDetails';
import { ReviewForm } from './ReviewForm';
import { TASupplierDetails_supplier } from '../../__generated__/TASupplierDetails';
interface ReviewsRatingsProps {
  supplier: TASupplierDetails_supplier,
  countTotal?: number | null;
}
export const ReviewsRatings: React.FC<ReviewsRatingsProps> = ({
  supplier,
  countTotal,
}) => {
  const styles = useStyles();
  const [viewReviewCompact, setViewReviewCompact] = useState<boolean>(true);
  const [viewDetails, setViewDetails] = useState<boolean>(false);

  return (
    <Stack
      className={styles.mainContainer}
    >
      <Stack horizontal horizontalAlign="space-between">
        <Stack>
          {
            viewReviewCompact &&
            <ReviewCompact
              ratings={supplier?.supplierRatingResults}
              onToggleRatings={() => {
                setViewDetails(true)
                setViewReviewCompact(false);
              }}
            />
          }
        </Stack>
        {
          countTotal ?
            <Stack className={styles.iconButtonContainer}>
              {
                viewDetails ?
                  <IconButton
                    iconProps={{ iconName: 'ChevronDown' }}
                    className={styles.iconButtonColor}
                    ariaLabel="Expand"
                    onClick={() => {
                      setViewDetails(prevState => !prevState)
                      setViewReviewCompact(true);
                    }}
                  /> :
                  <IconButton
                    iconProps={{ iconName: 'ChevronRight' }}
                    className={styles.iconButtonColor}
                    ariaLabel="Collapse"
                    onClick={() => {
                      setViewDetails(prevState => !prevState);
                      setViewReviewCompact(false);
                    }}
                  />
              }
            </Stack> :
            <ReviewForm
              supplierId={supplier.id}
              refreshList={() => {
                setViewDetails(prevState => !prevState);
                setViewReviewCompact(false);
              }}
            />
        }
      </Stack>
      {
        viewDetails &&
        <ReviewDetails supplier={supplier} />
      }
    </Stack>
  )
}
