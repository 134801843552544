import {
  CommandBarButton,
  PrimaryButton,
  Stack,
  Text,
  makeStyles,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React from 'react';
import { HeaderInfo } from '../__generated__/HeaderInfo';
import { InvoiceSchedulesItem } from '../ScheduleList';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  PurchaseOrderInvoiceScheduleDelete,
  PurchaseOrderInvoiceScheduleDeleteVariables,
} from './__generated__/PurchaseOrderInvoiceScheduleDelete';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { useToasts } from 'react-toast-notifications';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
import {
  PurchaseOrderInvoiceSchedules,
  PurchaseOrderInvoiceSchedulesVariables,
} from '../ScheduleList/__generated__/PurchaseOrderInvoiceSchedules';
const PURCHASE_ORDER_INVOICE_SCHEDULE_DELETE = loader(
  './PurchaseOrderInvoiceScheduleDelete.graphql'
);
const PURCHASE_ORDER_INVOICE_SCHEDULES = loader(
  '../ScheduleList/PurchaseOrderInvoiceSchedules.graphql'
);

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.neutralLight,
    position: 'sticky',
    width: '100%',
    zIndex: 99,
    top: 0,
  },
}));

interface HeaderProps {
  purchaseOrderData: PurchaseOrder | undefined;
  selectedRows: InvoiceSchedulesItem[];
  showList: boolean;
  headerInfo: HeaderInfo | undefined;
  onCreate: () => void;
  onBack: () => void;
  onDismiss: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  purchaseOrderData,
  selectedRows,
  headerInfo,
  showList,
  onCreate,
  onBack,
  onDismiss,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const { purchaseOrderNumber, supplier, description } = {
    ...purchaseOrderData?.purchaseOrder,
  };
  const { _fullName } = { ...supplier };
  const { documentAppliedAmounts, indexAmount, currency } = {
    ...headerInfo?.entityDocument,
  };
  const { remainingTotal } = { ...documentAppliedAmounts };
  const { isoCode } = { ...currency };

  const [deletePurchaseOrder] = useMutation<
    PurchaseOrderInvoiceScheduleDelete,
    PurchaseOrderInvoiceScheduleDeleteVariables
  >(PURCHASE_ORDER_INVOICE_SCHEDULE_DELETE, {
    errorPolicy: 'all',
  });

  const _onConfirm = async () => {
    const selectedData: InvoiceSchedulesItem[] = selectedRows;
    const entityDelete: EntityDeleteInput[] = selectedData
      .filter((item) => item._isDeletable)
      .map((item) => {
        return { id: item.id, rowTimestamp: item._rowTimestamp! };
      });
    const { errors } = await deletePurchaseOrder({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache, { data }) {
        const deletedIds =
          data?.purchaseOrderInvoiceScheduleDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );

        const cacheData = cache.readQuery<
          PurchaseOrderInvoiceSchedules,
          PurchaseOrderInvoiceSchedulesVariables
        >({
          query: PURCHASE_ORDER_INVOICE_SCHEDULES,
          variables: {
            purchaseOrderId: purchaseOrderData?.purchaseOrder?.id!,
          },
        });

        if (deletedIds?.length && cacheData) {
          const filteredList =
            cacheData?.purchaseOrderInvoiceSchedules?.nodes.filter(
              (emp) => deletedIds.indexOf(emp.id) === -1
            );
          const deletedFilteredList =
            cacheData?.purchaseOrderInvoiceSchedules?.nodes.filter(
              (emp) => deletedIds.indexOf(emp.id) !== -1
            );

          const { scheduledAmount } = {
            ...cacheData.purchaseOrderInvoiceSchedules?.aggregates?.sum,
          };

          const updatedScheduledAmount =
            Number(scheduledAmount) -
            (deletedFilteredList?.reduce(
              (total, item) => total + Number(item.scheduledAmount),
              0
            ) ?? 0);

          const newData: PurchaseOrderInvoiceSchedules = {
            purchaseOrderInvoiceSchedules: {
              nodes: filteredList!,
              aggregates: {
                sum: {
                  scheduledAmount: updatedScheduledAmount.toString(),
                },
              },
            },
          };
          cache.writeQuery<
            PurchaseOrderInvoiceSchedules,
            PurchaseOrderInvoiceSchedulesVariables
          >({
            query: PURCHASE_ORDER_INVOICE_SCHEDULES,
            variables: {
              purchaseOrderId: purchaseOrderData?.purchaseOrder?.id!,
            },
            data: newData,
          });
        }
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Records deleted successfully.', {
        appearance: 'success',
      });
    }
  };

  const { _isInvoiceScheduleUpdatable } = {
    ...purchaseOrderData?.purchaseOrder,
  };

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      className={styles.header}
      tokens={{
        padding: 20,
      }}
    >
      <Stack
        horizontal
        verticalAlign="baseline"
        tokens={{ childrenGap: 10 }}
        wrap
      >
        <Text variant="xLarge">Invoice Schedule</Text>
        <Text variant="large" className={commonStyles.colorThemePrimary}>
          {purchaseOrderNumber}
        </Text>
        <Text variant="large" className={commonStyles.colorThemePrimary}>
          {_fullName}
        </Text>
        <Text variant="large">{description}</Text>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="baseline">
        {indexAmount && (
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <Text variant="large" className={commonStyles.colorThemePrimary}>
              Total:
            </Text>
            <Text variant="large">{indexAmount}</Text>
          </Stack>
        )}
        {remainingTotal && (
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <Text variant="large" className={commonStyles.colorThemePrimary}>
              Remaining:
            </Text>
            <Text variant="large">{remainingTotal}</Text>
          </Stack>
        )}
        {isoCode && (
          <Text variant="large" className={commonStyles.colorThemePrimary}>
            {isoCode}
          </Text>
        )}
        <ActionMessageModal
          entityType={EntityType.InvoiceSchedule}
          disabled={
            !selectedRows.some((selected) => selected._isDeletable) ||
            !_isInvoiceScheduleUpdatable
          }
          visible={selectedRows.length > 0}
          multiple={{
            validCount: selectedRows.filter((selected) => selected._isDeletable)
              .length,
            invalidNames: selectedRows
              .filter((selected) => !selected._isDeletable)
              .map((selected) => selected.scheduledNote || ''),
          }}
          onConfirm={_onConfirm}
        />
        {showList ? (
          <PrimaryButton
            text="Add"
            onClick={() => {
              onCreate();
            }}
            iconProps={{
              iconName: 'Add',
            }}
            disabled={!_isInvoiceScheduleUpdatable}
          />
        ) : (
          <CommandBarButton
            iconProps={{ iconName: 'ChevronLeft' }}
            text={'Back to List'}
            onClick={onBack}
            styles={{
              root: {
                height: 35,
              },
            }}
          />
        )}
        <CloseButton onClick={() => onDismiss?.()} />
      </Stack>
    </Stack>
  );
};
