import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Stack,
  Text,
} from '@fluentui/react';
import { SelectionMode } from '@fluentui/utilities';
import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';
import clsx from 'clsx';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DownloadButton } from 'common/components/DownloadButton';
import React, { useState } from 'react';
import { CardAccount_cardAccount_cardAccountTransactions_nodes } from '../__generated__/CardAccount';
import { CardHolder_cardHolder_cardHolderTransactions_nodes } from '../__generated__/CardHolder';
import { StatusIcon } from './StatusIcon';
import { columns } from './column.data';
import { useStyles } from './index.styles';
type Transactions_nodes = Partial<
  CardAccount_cardAccount_cardAccountTransactions_nodes &
  Pick<
    CardHolder_cardHolder_cardHolderTransactions_nodes,
    'cardTransactionType' | 'linkInvoiceId'
  >
>;
type ItemRow = Transactions_nodes;
interface ApprovalHistoryProps {
  data: Transactions_nodes[];
}
export const TransactionHistory: React.FC<ApprovalHistoryProps> = ({
  data,
}) => {
  const styles = useStyles();
  const [selectedInvoice, setSelectedInvoice] = useState<string>();
  const renderColumn = (item?: ItemRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;
    const fieldContent = item[column.fieldName as keyof ItemRow] as
      | string
      | null;
    switch (column.key) {
      case 'isoCode':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text className={styles.currency}>{item.currency?.isoCode}</Text>
          </Stack>
        );
      case 'amount':
        return (
          <Stack className={clsx(styles.columnHeight)} verticalAlign="center">
            <AmountTextView
              value={item.amount?.toString()!}
              className={clsx(styles.amount)}
            />
          </Stack>
        );
      case 'entryDate':
        return (
          <Stack className={styles.marginEnd} verticalAlign="center">
            <ActiveLink
              to=""
              text={fieldContent}
              className={clsx(styles.columnHeight)}
              onClick={() => {
                setSelectedInvoice(item.linkInvoiceId || '');
              }}
            >
              <Text>{fieldContent}</Text>
            </ActiveLink>
          </Stack>
        );
      case 'download':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            {item._entityDocumentId && (
              <DownloadButton entityDocumentId={item._entityDocumentId!} />
            )}
          </Stack>
        );
      case 'status':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <StatusIcon
              statusType={item.statusType?.statusType!}
              id={item.id!}
            />
          </Stack>
        );
      default:
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text>{fieldContent}</Text>
          </Stack>
        );
    }
  };
  const transformedData =
    data.map(
      (ele) =>
      ({
        ...ele,
        status: ele.statusType?.statusType,
      } as Transactions_nodes)
    ) || [];
  return (
    <>
      <Stack tokens={{ padding: '0px 0px 0px 10px' }}>
        <DetailsList
          compact
          columns={columns}
          items={transformedData}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          onRenderItemColumn={renderColumn}
        />
      </Stack>
      {!transformedData?.length && (
        <Stack tokens={{ padding: '0px 30px' }}>
          <NoDataView show />
        </Stack>
      )}
      {!!selectedInvoice && (
        <TransactionModal
          entityId={selectedInvoice}
          isOpen={!!selectedInvoice}
          onDismiss={() => setSelectedInvoice(undefined)}
        />
      )}
    </>
  );
};
