import { useQuery } from '@apollo/client';
import { IDropdownOption, Stack } from '@fluentui/react';
import { FormHookDropdown, FormHookTextField } from 'common/components/FormHooksFields';
import { CorporateChartOfAccounts } from 'common/graphql/__generated__/CorporateChartOfAccounts';
import { loader } from 'graphql.macro';
import React from 'react';
import { ChartOfAccount } from '../__generated__/ChartOfAccount';
import { useStyles } from './index.styles';
const CORPORATE_CHART_OF_ACCOUNTS = loader('../../../../../../../common/graphql/CorporateChartOfAccounts.graphql');

interface BasicFormProps {
  isEdit: boolean;
  data: ChartOfAccount | undefined
}

export const BasicForm: React.FC<BasicFormProps> = ({
  isEdit,
  data,
}) => {
  const styles = useStyles();

  const {
    data: corporateChartOfAccountsData,
  } = useQuery<CorporateChartOfAccounts>(CORPORATE_CHART_OF_ACCOUNTS);

  const {
    corporateChartOfAccounts,
  } = { ...corporateChartOfAccountsData }

  const corporateChartOfAccountsOptions: IDropdownOption[]
    = corporateChartOfAccounts?.nodes.map((item) => ({ key: item.id, text: item.name || '' })) || [];

  const { _isUpdatable } = { ...data?.chartOfAccount }
  const isDisabled = isEdit ? !_isUpdatable : false;

  return (
    <Stack
      tokens={{
        padding: 20,
        childrenGap: 13
      }}
      className={styles.container}
    >
      <Stack
        className={styles.fieldContainer}
      >
        <FormHookTextField label="Name" name="name" disabled={isDisabled} required />
      </Stack>
      <Stack
        className={styles.fieldContainer}
      >
        <FormHookTextField label="Description" name="description" disabled={isDisabled} />
      </Stack>
      <Stack
        className={styles.fieldContainer}
      >
        <FormHookDropdown
          label='Corporate Chart'
          placeholder="Select"
          options={corporateChartOfAccountsOptions}
          name="corporateChartOfAccountId"
          disabled={isDisabled}
        />
      </Stack>
    </Stack>
  )
}
