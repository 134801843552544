import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  callout: {
    width: 400,
  },
  idCallStylesBox: {
    padding: 5,
    borderRadius: 5,
    border: `solid 1px ${theme.palette.themePrimary}`,
  },
}));
