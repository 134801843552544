import { Stack, Text, makeStyles } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useFormikContext } from 'formik';
import React from 'react';
interface ModalHeaderProps {
    headerTitle: string;
    isNew: boolean;
    onDismiss: () => void;
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '25px 25px 15px',
        backgroundColor: theme.palette.neutralLighterAlt,
    },
}))

export const ModalHeader: React.FC<ModalHeaderProps> = ({ headerTitle, onDismiss, isNew }) => {
    const styles = useStyles()
    const { resetForm, dirty, isSubmitting } = useFormikContext();
    return (
        <Stack
            horizontal
            horizontalAlign={'space-between'}
            className={styles.container}
        >
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Text variant={'xLarge'}>{headerTitle}</Text>
                <UnsavedIndicator
                    visible={!isNew && dirty && !isSubmitting}
                />
            </Stack>
            <CloseButton
                onClick={() => {
                    resetForm();
                    onDismiss();
                }}
            />
        </Stack>
    )
};
