import { makeStyles } from '@fluentui/react';

export const SIGNATURE_PAD_BG_COLOR = '#faf9f8';
export const SIGNATURE_PAD_BORDER_COLOR = '#a19f9d';
export const useStyles = makeStyles((theme) => {
  return {
    sigPad: {
      width: 650,
      height: 250,
      borderRadius: 3,
      border: `1px dashed ${SIGNATURE_PAD_BORDER_COLOR}`,
      backgroundColor: 'transparent',
    },
    signatureContainer: {
      width: 700,
    },
    container: {
      height: 470,
      width: 700,
    },
  };
});
