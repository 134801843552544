import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 20,
  },
};

export const getColumns = () => {
  const columns: ColumnData[] = [
    {
      key: 'indexName',
      name: 'Name',
      fieldName: 'indexName',
      minWidth: 200,
      maxWidth: 200,
      styles: colHeaderLeftAlignStyle,
      ...commonColumnProps,
    },
    {
      key: 'indexReferenceNumber',
      name: 'PO#',
      fieldName: 'indexReferenceNumber',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
    },
    {
      key: 'indexTransactionDate',
      name: 'Date',
      fieldName: 'indexTransactionDate',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
    },
    {
      key: 'indexDescription',
      name: 'Description',
      fieldName: 'indexDescription',
      minWidth: 250,
      maxWidth: 250,
      ...commonColumnProps,
    },
    {
      key: 'currency',
      name: 'Currency',
      fieldName: 'currency',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
    },
    {
      key: 'indexAmount',
      name: 'Amount',
      fieldName: 'indexAmount',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
    },
    {
      key: 'action',
      name: '',
      fieldName: 'action',
      minWidth: 20,
      maxWidth: 20,
      ...commonColumnProps,
      isResizable: false,
    },
    {
      key: 'fileReference',
      name: 'Document',
      fieldName: 'fileReference',
      minWidth: 200,
      maxWidth: 200,
      ...commonColumnProps,
    },
  ];
  return columns;
};
