import { useReactiveVar } from '@apollo/client';
import { Pivot, PivotItem } from '@fluentui/react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { setGlobalSearchText } from 'utility/cache/ui';
import { DocumentAssignmentTabs, setTabMode } from '..';

export const Tabs: React.FC = () => {
  const tabMode = useReactiveVar(setTabMode);
  const history = useHistory();
  const [urlParameters] = useQueryParams({
    t: StringParam,
  });
  const { t: searchParam } = { ...urlParameters };
  React.useEffect(() => {
    if (urlParameters.t) {
      setTabMode(DocumentAssignmentTabs.documents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  return (
    <Pivot
      selectedKey={tabMode}
      onLinkClick={(item: PivotItem | undefined) => {
        setTabMode(item?.props.itemKey);
        setGlobalSearchText('');
        history.replace('/doc/documentAssignment');
      }}
    >
      <PivotItem itemKey={DocumentAssignmentTabs.folder} headerText="Folder" />
      <PivotItem
        itemKey={DocumentAssignmentTabs.documents}
        headerText="Documents"
      />
    </Pivot>
  );
};
