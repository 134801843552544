import { IconButton } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { EmailDocumentFields } from '../../__generated__/EmailDocumentFields';
import { EntityEmailDocumentsFields } from '../../__generated__/EntityEmailDocumentsFields';
import { EmailCallout } from './EmailCallout';
import { EmailListModal } from './ListModal';
import { FormModal } from './FormModal';
import { EmailCreateValues } from './FormModal/types';
import { Badge } from 'common/components/Badge';

export interface DocumentPackageEmailModalProps {
  emailDocument: EmailDocumentFields | null | undefined;
  emailDocumentsByEntityId: EntityEmailDocumentsFields[];
  loading: boolean;
  showEmailStatus?: boolean;
  setShowEmailStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccessful: boolean;
  onSubmitValues: (values: EmailCreateValues) => void;
}

export const DocumentPackageEmailModal: React.FC<
  DocumentPackageEmailModalProps
> = ({
  emailDocument,
  emailDocumentsByEntityId,
  loading,
  isSuccessful,
  onSubmitValues,
  showEmailStatus,
  setShowEmailStatus,
}) => {
  const calloutId = useId(`emailCalloutId`);
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [isListModalVisible, setIsListModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  useEffect(() => {
    if (isSuccessful) setIsCreateModalVisible(false);
  }, [isSuccessful]);

  return (
    <>
      <Badge visible={showEmailStatus}>
        <IconButton
          onClick={() => setIsListModalVisible(true)}
          onMouseEnter={() => {
            setCalloutVisible(true);
            setShowEmailStatus?.(false);
          }}
          onMouseLeave={() => setCalloutVisible(false)}
          iconProps={{ iconName: 'MailCheck' }}
          id={calloutId}
        />
      </Badge>
      <EmailCallout
        isOpen={calloutVisible}
        targetId={`#${calloutId}`}
        emailDocument={emailDocument}
        onDismiss={() => setCalloutVisible(false)}
        onViewList={() => setIsListModalVisible(true)}
      />
      <EmailListModal
        isOpen={isListModalVisible}
        emailDocument={emailDocument}
        emailDocumentsByEntityId={emailDocumentsByEntityId}
        onClose={() => setIsListModalVisible(false)}
        onCreate={() => {
          setIsListModalVisible(false);
          setIsCreateModalVisible(true);
        }}
      />
      <FormModal
        isOpen={isCreateModalVisible}
        emailDocument={emailDocument}
        loading={loading}
        isSuccessful={isSuccessful}
        onSubmitValues={onSubmitValues}
        onClose={() => setIsCreateModalVisible(false)}
      />
    </>
  );
};
