import { Label, Stack } from '@fluentui/react';
import React from 'react';
import { ColumnData } from './columns.data';

interface ColumnsHeaderProps {
  columnsArray: ColumnData[];
}
export const ColumnsHeader: React.FC<ColumnsHeaderProps> = ({
  columnsArray,
}) => {
  const widthTotal =
    columnsArray.reduce((prev, curr) => curr.maxWidth! + prev, 0) +
    columnsArray.length * 10;
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 10 }}
      style={{ width: widthTotal }}
    >
      {columnsArray.map((value, key) => {
        switch (value.key) {
          case 'delete':
            return null;
          default:
            return (
              <Stack
                style={{
                  height: 50,
                  width: value.maxWidth,
                }}
                key={key}
                horizontalAlign="center"
                verticalAlign="center"
              >
                <Label key={key}>{value.name}</Label>
              </Stack>
            );
        }
      })}
    </Stack>
  );
};
