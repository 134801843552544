import { EntityDocumentValues } from './types';

export const DOCUMENT_INITIAL_VALUES: EntityDocumentValues = {
  fileIndexInformation: null,
  _documentContents: null,
  comment: null,
  entityDocumentTypeId: null,
  indexTransactionDate: null,
  indexName: null,
  indexDescription: null,
  indexReferenceNumber: null,
  indexAmount: null,
  indexCurrencyId: null,
  extractionTypeId: null,
};
