import React from 'react';
import {
  DefaultButton,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { SecureRowLevelsEntityFields } from 'common/graphql/__generated__/SecureRowLevelsEntityFields';

interface ProtectedSectionIC {
  secureRowLevelId: string | undefined;
  secureRowLevels: SecureRowLevelsEntityFields[];
  updateRowLevel: (selectedLevel: string | null) => void;
  updateLoading: boolean;
  isUpdatable?: boolean;
}
export const ProtectSection: React.FC<ProtectedSectionIC> = ({
  secureRowLevelId,
  secureRowLevels,
  updateRowLevel,
  updateLoading,
  isUpdatable,
}) => {
  const styles = useStyles();
  React.useEffect(() => {
    selectedLevel.current = secureRowLevelId || null;
  }, [secureRowLevelId]);
  const selectedLevel = React.useRef<string | null>(secureRowLevelId || null);

  return (
    <Stack>
      <Stack className={styles.height10} />
      {updateLoading && <ProgressIndicator label="Updating security level" />}
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        className={styles.contentWrapper}
      >
        {secureRowLevels &&
          secureRowLevels.map((data) => {
            return (
              <Stack key={data.id?.toString()}>
                {selectedLevel.current === data.id ? (
                  <PrimaryButton
                    disabled={updateLoading || !isUpdatable}
                    text={data.name?.toString()}
                    onClick={(event) => {
                      selectedLevel.current = null;
                      event.stopPropagation();
                      updateRowLevel(selectedLevel.current);
                    }}
                  />
                ) : (
                  <DefaultButton
                    disabled={updateLoading || !isUpdatable}
                    text={data.name?.toString()}
                    onClick={(event) => {
                      selectedLevel.current = data.id;
                      event.stopPropagation();
                      updateRowLevel(selectedLevel.current);
                    }}
                  />
                )}
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
};
