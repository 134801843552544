import React from 'react';
import { Icon, makeStyles, Stack, Text } from '@fluentui/react'; 
import clsx from 'clsx';

interface NavButtonProps {
  title: string;
  direction?: 'Next' | 'Previous';
  onClick?: () => void;
}
export const NavButton: React.FC<NavButtonProps> = ({
  title = 'Next',
  direction = 'Next',
  onClick,
}) => {
  const styles = useStyles();
  return (
    <button
      className={clsx(styles.button, styles.container)}
      onClick={()=>onClick?.()}
    >
      <Stack
        className={styles.iconStack}
        verticalAlign='center'
        horizontalAlign='center'
      >
        <Icon
          iconName={direction === 'Next' ? 'ChevronRightMed' : 'ChevronLeftMed'}
          className={styles.icon}
        />
      </Stack>
      <Text className={styles.text}>{title}</Text>
    </button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    border: 'none',
    textDecoration: 'none',
    display: 'inline',
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
  },
  container: { marginTop: 20 },
  iconStack: {
    backgroundColor: theme.palette.themePrimary,
    width: 40,
    height: 40,
    borderRadius: 20,
    selectors: {
      ':hover': {
        backgroundColor: theme.palette.themeDarker,
      },
    },
  },
  icon: {
    color: 'white',
  },
  text: {
    color: theme.palette.black,
  },
}));
