import {
  AnimationClassNames,
  ContextualMenu,
  IDragOptions,
  Stack,
  makeStyles,
} from '@fluentui/react';
import { ModalWrapper } from 'common/components/ModalWrapper';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DocumentPackageEmailModalProps } from '..';
import { CreateNewEmail } from './CreateNewEmail';
import { Footer } from './Footer';
import { Header } from './Header';
import { EMAIL_CREATE_VALUES } from './constants';
import { EmailCreateValues } from './types';
const SlideLeftIn = AnimationClassNames.slideLeftIn400;

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

const useStyles = makeStyles(() => ({
  container: {
    width: 800,
    maxHeight: 600,
    overflowX: 'hidden',
  },
}));

type FormModalProps = Omit<
  DocumentPackageEmailModalProps,
  'emailDocumentsByEntityId'
> & {
  isOpen: boolean;
  onClose: () => void;
};

export const FormModal: React.FC<FormModalProps> = ({
  isOpen,
  emailDocument,
  isSuccessful,
  loading,
  onSubmitValues,
  onClose,
}) => {
  const styles = useStyles();

  const formMethods = useForm<EmailCreateValues>({
    defaultValues: EMAIL_CREATE_VALUES,
  });

  const { handleSubmit, reset } = { ...formMethods };

  const onHandleSubmit = async (values: EmailCreateValues) => {
    onSubmitValues(values);
  };

  const _onModalClose = () => {
    reset();
    onClose();
  };

  const onResetFormMemo = useCallback(
    (success: boolean) => {
      if (success) {
        reset();
      }
    },
    [reset]
  );

  useEffect(() => {
    onResetFormMemo(isSuccessful);
  }, [onResetFormMemo, isSuccessful]);

  return (
    <ModalWrapper isOpen={isOpen} isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        <FormProvider {...formMethods}>
          <Header onClose={_onModalClose} />
          <Stack className={SlideLeftIn}>
            <CreateNewEmail emailDocument={emailDocument} />
          </Stack>
          <Footer
            loading={loading}
            onSubmit={handleSubmit(onHandleSubmit)}
            onCancel={_onModalClose}
          />
        </FormProvider>
      </Stack>
    </ModalWrapper>
  );
};
