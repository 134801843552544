import { useMutation } from '@apollo/client';
import {
  DefaultButton, Dialog,
  DialogFooter, DialogType, IconButton, IProgressIndicatorStyles, makeStyles, PrimaryButton, ProgressIndicator, Stack, TooltipHost
} from '@fluentui/react';
import {
  FormikDropdown, FormikNumberTextField,
  FormikTextField
} from 'common/components';
import { ApprovalCopyInput } from 'common/types/globalTypes';
import { Form, Formik, useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import {
  Approvals,
  ApprovalsVariables
} from '../../list/__generated__/Approvals';
import { ApprovalSetupCommonData_approvalTypes } from '../../__generated__/ApprovalSetupCommonData';
import { ApprovalValues } from '../types';
import { validationSchema } from './approvalCopyValidation';
import { APPROVAL_COPY_INITIAL_VALUES } from './constants';
import { ApprovalCopyValues } from './types';
import { generateApprovalName } from './utils';
import {
  ApprovalCopy,
  ApprovalCopyVariables
} from './__generated__/ApprovalCopy';
const CREATE_APPROVAL_COPY = loader('./ApprovalCopy.graphql');
const APPROVALS = loader('../../list/Approvals.graphql');

const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
  root: { marginBottom: 10 },
};
interface CopyApprovalsProps {
  id: string;
  data: ApprovalSetupCommonData_approvalTypes;
}
const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginRight: 10,
  },
}));
export const CopyApproval: React.FC<CopyApprovalsProps> = ({ id, data }) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const history = useHistory();
  const { values } = useFormikContext<ApprovalValues>();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const approvalTypeOptions =
    data?.nodes.map((item) => ({
      key: item.id,
      text: item.approvalType || '',
    })) || [];

  let initialValues: ApprovalCopyValues = APPROVAL_COPY_INITIAL_VALUES;
  if (id)
    initialValues = {
      ...initialValues,
      approvalId: id,
      newApprovalTypeId: values.approvalTypeId,
      newName: generateApprovalName(values?.name!),
    };

  const [createApprovalCopy, { loading: createApprovalCopyLoading }] =
    useMutation<ApprovalCopy, ApprovalCopyVariables>(CREATE_APPROVAL_COPY, {
      errorPolicy: 'all',
    });

  const handleSubmit = async (values: ApprovalCopyValues) => {
    const { errors, data } = await createApprovalCopy({
      variables: {
        input: {
          ...(values as ApprovalCopyInput),
          newPriorityNumber: Number(values.newPriorityNumber),
        },
      },
      update: (cache, { data }) => {
        const cacheData = cache.readQuery<Approvals, ApprovalsVariables>({
          query: APPROVALS,
        });
        if (
          cacheData?.approvals?.pageInfo &&
          cacheData?.approvals?.totalCount &&
          data?.approvalCopy?.approval
        ) {
          const newData: Approvals = {
            approvals: {
              ...cacheData.approvals,
              pageInfo: cacheData.approvals.pageInfo,
              totalCount: cacheData.approvals.totalCount,
              nodes: [
                data?.approvalCopy.approval,
                ...cacheData.approvals.nodes,
              ],
            },
          };
          cache.writeQuery<Approvals, ApprovalsVariables>({
            query: APPROVALS,
            data: newData,
          });
        }
      },
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    }
    if (data?.approvalCopy?.approval) {
      setShowDialog(false);
      history.replace(
        `/account-management/approvals/approval/${data.approvalCopy.approval.id}`
      );
      addToast('Approval copy added successfully', {
        appearance: 'success',
      });
    }
  };

  return (
    <>
      <TooltipHost content="Clone">
        <IconButton
          iconProps={{ iconName: 'Copy' }}
          ariaLabel="Copy"
          onClick={() => setShowDialog(true)}
          className={styles.iconButton}
        />
      </TooltipHost>
      {showDialog && (
        <Dialog
          hidden={false}
          onDismiss={() => setShowDialog(false)}
          dialogContentProps={{
            title: 'Create Approval Clone',
            type: DialogType.largeHeader,
          }}
          minWidth={500}
        >
          <Formik<ApprovalCopyValues>
            enableReinitialize
            initialValues={initialValues}
            validateOnMount
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ submitForm, dirty, errors, isSubmitting }) => {
              return (
                <Form>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <FormikDropdown
                      label="Approval Type"
                      placeholder="Select"
                      options={approvalTypeOptions}
                      name="newApprovalTypeId"
                      required
                    />
                    <FormikTextField
                      name="newName"
                      label="Name"
                      placeholder="Enter name"
                      required
                    />
                    <FormikNumberTextField
                      inputMode="decimal"
                      name="newPriorityNumber"
                      label="Priority Number"
                      placeholder="Priority number"
                      required
                    />
                  </Stack>
                  <DialogFooter>
                    {createApprovalCopyLoading && (
                      <ProgressIndicator styles={progressIndicatorStyles} />
                    )}
                    <PrimaryButton
                      disabled={
                        !dirty || Object.keys(errors).length > 0 || isSubmitting
                      }
                      text="Confirm"
                      onClick={async () => {
                        await submitForm();
                      }}
                    />
                    <DefaultButton
                      onClick={() => setShowDialog(false)}
                      text="Cancel"
                    />
                  </DialogFooter>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      )}
    </>
  );
};
