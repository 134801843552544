import React from 'react';
import { ITextProps, Text, Link, ILinkProps } from '@fluentui/react';
import { useReactiveVar } from '@apollo/client';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { highlightTextMode, setUserDefaults } from 'utility/cache/ui';
import { useStyles } from './index.styles';
import clsx from 'clsx';

type HighlightTextViewProps = ITextProps &
  ILinkProps & {
    preventDefault?: boolean;
    isHighlightable?: boolean;
    highlightText: string;
    text: string;
    to?: string;
    navigationState?: any;
    onLinkClick?: () => void;
  };

export const HighLightActiveLink: React.FC<HighlightTextViewProps> = ({
  isHighlightable,
  highlightText,
  text,
  to,
  preventDefault,
  navigationState,
  onLinkClick,

  ...props
}) => {
  const escapedHighlightText = highlightText.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&'
  );
  const parts = text?.split(new RegExp(`(${escapedHighlightText})`, 'gi'));
  const userDefaults = useReactiveVar(setUserDefaults);
  const isSelectedRowBold = !userDefaults?.isRowSelectorBold;
  const highlightTextState = useReactiveVar(highlightTextMode);
  const history = useHistory();
  const match = useRouteMatch(to!);
  const styles = useStyles();

  return (
    <Link
      className={styles.root}
      onClick={() => {
        if (preventDefault) {
          onLinkClick?.();
        } else {
          history.push(to!, {
            ...navigationState,
          });
        }
      }}
      {...props}
    >
      <Text variant="xLarge">
        {highlightTextState ? (
          <>
            {parts?.map((part, index) => {
              const isHighLighted =
                part.toLowerCase() === highlightText.toLowerCase() &&
                highlightText !== '';
              return (
                <Text
                  key={index}
                  style={
                    isHighLighted ? { backgroundColor: 'rgb(235,157,56)' } : {}
                  }
                  className={clsx(
                    isSelectedRowBold
                      ? !!match
                        ? styles.linkBold
                        : styles.link
                      : !!match
                      ? styles.link
                      : styles.linkBold
                  )}
                >
                  {part}
                </Text>
              );
            })}
          </>
        ) : (
          <Text>{text}</Text>
        )}
      </Text>
    </Link>
  );
};
