import { DefaultButton, PrimaryButton, ProgressIndicator, Stack, makeStyles } from '@fluentui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ChartOfAccountsValues } from '../types';
import { ChartOfAccount } from '../__generated__/ChartOfAccount';
import { UpdateChartOfAccountDefault } from '../../../UpdateChartOfAccountDefault';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
    padding: "10px 20px",
    height: 84,
  },
  progressContainer: {
    height: 12
  }
}));

interface FooterProps {
  isEdit: boolean;
  data: ChartOfAccount | undefined;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onSetDefault: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  isEdit,
  data,
  loading,
  onSubmit,
  onCancel,
  onSetDefault,
}) => {
  const styles = useStyles();
  const { formState: { isDirty, errors, isSubmitting } } = useFormContext<ChartOfAccountsValues>();
  const isDisabled = !isDirty || Object.keys(errors).length > 0 || isSubmitting;
  const { id, _defaults } = { ...data?.chartOfAccount };
  return (
    <Stack
      className={styles.container}
    >
      <Stack
        tokens={{ childrenGap: 10 }}
      >
        <Stack className={styles.progressContainer}>
          {loading && <ProgressIndicator />}
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
        >
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
            }}
          >
            <PrimaryButton
              text="Save"
              onClick={onSubmit}
              disabled={isDisabled}
            />
            <DefaultButton
              text="Cancel"
              onClick={onCancel}
            />
          </Stack>
          {
            isEdit && !!id && !!_defaults &&
            <UpdateChartOfAccountDefault
              data={{ id, _defaults }}
              onConfirm={onSetDefault}
            />
          }
        </Stack>
      </Stack>
    </Stack>
  )
}
