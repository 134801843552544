import {
  Companies,
  Companies_companies_nodes_companyAddressesByEntityId_nodes,
  Companies_companies_nodes_companyValidCurrencies_nodes,
} from './__generated__/Companies';
import { COMPANY_INITIAL_VALUES } from './constants';
import { AddressRowValues, CompanyValues, CurrencyRowValues } from './types';

export const getDefaultValues = (props: Companies) => {
  let defaultValues: CompanyValues = {
    ...COMPANY_INITIAL_VALUES,
  };
  const addressInitialArray = getAddressValues(
    props?.companies?.nodes[0]?.companyAddressesByEntityId?.nodes ?? []
  );
  const validCurrencyInitialArray = getCurrencyValues(
    props?.companies?.nodes[0]?.companyValidCurrencies?.nodes ?? []
  );

  if (props)
    defaultValues = {
      ...defaultValues,
      name: props.companies?.nodes[0]?.name || null,
      corporationName: props.companies?.nodes[0]?.corporationName || null,
      homeCountryId: props.companies?.nodes[0]?.homeCountryId || null,
      currencyId: props.companies?.nodes[0]?.currencyId || null,
      timeZoneId: props.companies?.nodes[0]?.timeZoneId || null,
      distributionLayoutType:
        props.companies?.nodes[0]?.distributionLayoutType || null,
      approvalPhaseTypeId:
        props.companies?.nodes[0]?.approvalPhaseTypeId || null,
      corporateWorkgroupId:
        props.companies?.nodes[0]?.corporateWorkgroupId || null,
      companyAddresses: [...addressInitialArray],
      companyValidCurrencies: [...validCurrencyInitialArray],
    };

  return defaultValues as CompanyValues;
};

export const getAddressValues = (
  values: Companies_companies_nodes_companyAddressesByEntityId_nodes[]
) => {
  if (values.length > 0) {
    const itemsValues = values.map((item) => {
      const updatedObject = Object.entries(item)?.reduce((res, [key, val]) => {
        if (key === '_isUpdatable' || key === '__typename') return res;
        else return { ...res, [key]: val };
      }, {});

      return { ...updatedObject };
    });
    return itemsValues as AddressRowValues[];
  } else return [];
};
export const getCurrencyValues = (
  values: Companies_companies_nodes_companyValidCurrencies_nodes[]
) => {
  if (values.length > 0) {
    const itemsValues = values.map((item) => {
      const updatedObject = Object.entries(item)?.reduce((res, [key, val]) => {
        if (key === '_isUpdatable' || key === '__typename') return res;
        else return { ...res, [key]: val };
      }, {});

      return { ...updatedObject };
    });
    return itemsValues as CurrencyRowValues[];
  } else return [];
};
