import {
  Callout,
  CommandBarButton,
  DirectionalHint,
  Image,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useState } from 'react';
import { useStyles } from './index.styles';

interface UserSignatureProps {
  _downloadLink: string;
}
export const UserSignature: React.FC<UserSignatureProps> = ({
  _downloadLink,
}) => {
  const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
  const targetId = 'signatureIdUser';
  const styles = useStyles();

  return (
    <Stack>
      <CommandBarButton
        styles={{ root: { height: 40 } }}
        text="Signature"
        iconProps={{ iconName: 'RedEye' }}
        id={targetId}
        onMouseEnter={() => setCalloutVisible(true)}
        onMouseLeave={() => setCalloutVisible(false)}
      />

      {calloutVisible && (
        <Callout
          gapSpace={10}
          isBeakVisible={true}
          directionalHint={DirectionalHint.topAutoEdge}
          target={`#${targetId}`}
        >
          <Stack tokens={{ padding: '10px 20px' }}>
            <Image
              style={{ objectFit: 'contain', backgroundColor: 'white' }}
              className={styles.signatureImage}
              src={_downloadLink}
              width={250}
              height={100}
            />
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};
