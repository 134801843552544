import React from 'react';
import {
    Callout,
    DirectionalHint,
    makeStyles,
    Separator,
    Stack,
    Text
} from '@fluentui/react';
import clsx from 'clsx';
import { LookupNameSearch_lookupNameSearch_nodes } from 'common/components/LookUp/__generated__/LookupNameSearch';
import { useCommonStyles } from 'common/styles';

interface VendorDetailsCalloutProps {
    data: LookupNameSearch_lookupNameSearch_nodes;
    detailsTargetId: string;
    onChangeCalloutVisibility: (param: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
    detailsCallout: {
        width: 400,
        flexGrow: 1,
    },
    separator: {
        height: 1,
        color: theme.palette.neutralLight
    },
    lookupCode: {
        border: `1px solid ${theme.palette.themePrimary}`,
        borderRadius: 5,
        padding: '0px 5px',
    },
    calloutContainer: {
        backgroundColor: theme.palette.neutralLighterAlt,
    }
}));

export const VendorDetailsCallout: React.FC<VendorDetailsCalloutProps> = ({
    data,
    detailsTargetId,
    onChangeCalloutVisibility,
}) => {
    const styles = useStyles();
    const commonStyles = useCommonStyles();
    return (
        <Callout
            onDismiss={() => onChangeCalloutVisibility(false)}
            isBeakVisible={true}
            className={styles.detailsCallout}
            directionalHint={DirectionalHint.leftTopEdge}
            target={`#${detailsTargetId}`}
        >
            <Stack className={styles.calloutContainer}>
                <Stack
                    horizontalAlign="space-between"
                    horizontal
                    tokens={{ padding: '10px' }}
                >
                    <Text
                        className={clsx(
                            commonStyles.semibold,
                            commonStyles.colorThemePrimary
                        )}
                        variant="mediumPlus"
                    >
                        {data.lookupName}
                    </Text>

                    <Text
                        className={clsx(
                            commonStyles.semibold,
                            commonStyles.colorThemePrimary,
                            styles.lookupCode
                        )}
                    >
                        #{data.lookupCode}
                    </Text>
                </Stack>
                <Separator className={styles.separator} />
                <Stack horizontal>
                    <Stack
                        tokens={{
                            childrenGap: 10,
                            padding: '0px 10px 10px',
                        }}
                    >
                        {data.lookupName2 && <Text>{data.lookupName2}</Text>}
                        {data.address1 && <Text>{data.address1}</Text>}
                        {data.address2 && <Text>{data.address2}</Text>}
                        <Stack horizontal>
                            {data.city && <Text>{`${data.city + '   '},`} </Text>}
                            {data.city && <Text>{`${data.state + '    '},`} </Text>}
                            {data.city && <Text>{data.zipCode + '     '}</Text>}
                            <Text>{data.country}</Text>
                        </Stack>
                        <Stack horizontal>
                            {data.emailAccount && <Text>{`${data.emailAccount} ,`}</Text>}
                            <Text>{data.phone}</Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Callout>
    );
};