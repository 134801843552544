import { useApolloClient, useQuery } from '@apollo/client';
import {
  IPanelHeaderRenderer,
  IPanelProps,
  IRenderFunction,
  MessageBar,
  MessageBarType,
  Separator,
  Stack,
} from '@fluentui/react';
import { UserDefaults } from 'Preferences/__generated__/UserDefaults';

import { ApprovalHistory } from 'common/components/ApprovalHistory';
import DraggablePanel from 'common/components/DraggablePanel';
import { Accounting } from 'common/components/Modules/TransactionEdit/Accounting';
import { Footer } from 'common/components/Modules/TransactionEdit/Footer';
import {
  AvailablePurchaseOrders,
  AvailablePurchaseOrdersVariables,
} from 'common/components/Modules/TransactionEdit/graphql/__generated__/AvailablePurchaseOrders';
import { InvoiceDetails } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
import { InvoiceTransactionTypes } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceTransactionTypes';
import {
  attachedDocumentsData,
  FileListArray,
  TransactionSigningValues,
} from 'common/components/Modules/TransactionEdit/types';
import { PanelHeader } from 'common/components/PanelHeader';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { UploadStatusType } from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { BasicForm } from '../../../../../common/components/Modules/TransactionEdit/BasicForm';
import { Header } from '../../../../../common/components/Modules/TransactionEdit/Header';
import { TemporaryDocumentList } from '../../../../../common/components/Modules/TransactionEdit/TempDocumentList';
import {
  formatEntityDocuments,
  getPOInvoiceSchedules,
} from '../../../../../common/components/Modules/TransactionEdit/utils';
import { ActionsMenu } from './ActionMenu';
import { AttachDocumentsForm } from './AttachDocumentsForm';
import { useStyles } from './index.styles';
import { ShimmerView } from './ShimmerView/ShimmerViews';
const TRANSACTION_DATA = loader(
  '../../../../../common/components/Modules/TransactionEdit/graphql/InvoiceTransactionTypes.graphql'
);
const USER_DEFAULTS = loader('../../../../../Preferences/UserDefaults.graphql');
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const AVAILABLE_PURCHASE_ORDER = loader(
  '../../../../../common/components/Modules/TransactionEdit/graphql/AvailablePurchaseOrders.graphql'
);

interface FormViewProps {
  onSave: () => void;
  isSaveAnother: (saveAnother: boolean) => void;
  isNew: boolean;
  invoiceDetailsData: InvoiceDetails | undefined;
  dataLoading: boolean;
  refetch: () => void;
  isCreating: boolean;
  isUpdating: boolean;
  onFileListArrayChange: (files: FileListArray[]) => void;
}

export interface InitialDocumentMetaData {
  id: string;
  indexName: string | null;
  comment: string | null;
}

export const FormView: React.FC<FormViewProps> = ({
  onSave,
  isNew,
  invoiceDetailsData,
  dataLoading,
  isSaveAnother,
  refetch,
  isCreating,
  isUpdating,
  onFileListArrayChange,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const { updateToast } = useToasts();
  const [receiptTotal, setReceiptTotal] = useState<string | null>(null);
  const [fileListArray, setFileListArray] = useState<FileListArray[]>([]);
  const [attachedDocuments, setAttachedDocuments] = useState<
    attachedDocumentsData | undefined
  >();

  const [previouslyAppliedAmount, setPreviouslyAppliedAmount] =
    useState<number>(0);
  const [initialDocumentMetaData, setInitialDocumentMetaData] =
    useState<InitialDocumentMetaData>();
  const { _isApprovalDocumentsRequired, _requiredApprovalDocuments } = {
    ...invoiceDetailsData?.invoice,
  };
  const { data: userDefaultsData } = useQuery<UserDefaults>(USER_DEFAULTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: transactionTypeData, loading: transactionTypeLoading } =
    useQuery<InvoiceTransactionTypes>(TRANSACTION_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  const {
    trigger,
    control,
    formState: { isSubmitting, isDirty },
    setValue,
  } = useFormContext<TransactionSigningValues>();
  const watchTransactionTypeId = useWatch({
    name: 'transactionTypeId',
    control,
  });
  const watchControlTotalAmount = useWatch({
    name: 'controlTotalAmount',
    control,
  });

  const watchBusinessUnitId = useWatch({
    name: 'businessUnitId',
    control,
  });
  const watchCurrencyId = useWatch({
    name: 'currencyId',
    control,
  });
  const watchDepartmentId = useWatch({
    name: 'departmentId',
    control,
  });
  const watchCardHolderId = useWatch({
    name: 'cardHolderId',
    control,
  });

  const { data: availablePurchaseOrders } = useQuery<
    AvailablePurchaseOrders,
    AvailablePurchaseOrdersVariables
  >(AVAILABLE_PURCHASE_ORDER, {
    skip: !invoiceDetailsData?.invoice?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    variables: { invoiceId: invoiceDetailsData?.invoice?.id! },
  });

  const watchLayoutType = useWatch({ name: 'layoutType', control });
  useEffect(() => {
    if (fileListArray) {
      const totalPOAmount = fileListArray.reduce(
        (n, { documentAppliedAmount }) =>
          n + (documentAppliedAmount ? parseFloat(documentAppliedAmount) : 0),
        0
      );
      setPreviouslyAppliedAmount(totalPOAmount);
    }
  }, [fileListArray]);

  useEffect(() => {
    onFileListArrayChange(fileListArray);
  }, [fileListArray, onFileListArrayChange]);

  // CUSTOM RENDER HEADER
  const _onRenderHeader: IPanelHeaderRenderer = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/signing');
        }}
      >
        <Header
          isNew={isNew}
          transactionTypeData={transactionTypeData}
          invoiceDetailsLoading={dataLoading}
          isSubmitting={isSubmitting}
          invoiceDetailsData={invoiceDetailsData}
          dirty={isDirty}
        />
      </PanelHeader>
    );
  };

  // CUSTOM RENDER FOOTER
  const _onRenderFooter: IRenderFunction<IPanelProps> = () => {
    return (
      <Footer
        invoiceDetailsData={invoiceDetailsData}
        isNew={isNew}
        isCreating={isCreating}
        isUpdating={isUpdating}
        transactionTypeLoading={transactionTypeLoading}
        onSave={(saveAnother) => {
          isSaveAnother(saveAnother);
          onSave();
        }}
        onDismiss={() => {
          history.replace('/signing');
        }}
      />
    );
  };

  const onDocumentsAttached = (documentsData: attachedDocumentsData) => {
    if (!!attachedDocuments?.attachedDocuments.length) {
      const selectedDocuments = [
        ...attachedDocuments?.attachedDocuments,
        ...documentsData.attachedDocuments,
      ];
      setAttachedDocuments({
        attachedDocuments: selectedDocuments,
      });
    } else {
      setAttachedDocuments(documentsData);
      const { documentPosition, documentTypes, comment } = {
        ...documentsData?.attachedDocuments?.[0],
      };
      const { isAccountingDocument } = { ...documentTypes };
      const documentComment =
        isAccountingDocument && documentPosition === 1 ? comment : null;
      setInitialDocumentMetaData({
        id: documentsData.attachedDocuments[0].id,
        indexName: documentsData.attachedDocuments[0].indexName,
        comment: documentComment,
      });
    }
    trigger();
  };

  const disableCardHolder = isNew
    ? fileListArray.length > 0
    : invoiceDetailsData?.invoice?._isDocumentsExist!;

  useEffect(() => {
    if (!!attachedDocuments?.attachedDocuments.length)
      setFileListArray(attachedDocuments?.attachedDocuments);
    else setFileListArray([]);
  }, [attachedDocuments]);

  const setDocumentValues = () => {
    const formattedEntityDocuments = formatEntityDocuments(
      attachedDocuments?.attachedDocuments || []
    );
    const pOInvoiceSchedulesDocuments = getPOInvoiceSchedules(
      attachedDocuments?.attachedDocuments || []
    );
    setValue('entityDocuments', formattedEntityDocuments);
    setValue('poInvoiceSchedules', pOInvoiceSchedulesDocuments);
  };

  const setDocumentValuesMemo = useCallback(setDocumentValues, [
    attachedDocuments,
    fileListArray,
  ]);

  useEffect(() => {
    setDocumentValuesMemo();
  }, [setDocumentValuesMemo]);

  const currencyId = watchCurrencyId ? Number(watchCurrencyId) : null;

  return (
    <DraggablePanel
      {...PanelCommonProps}
      initialWidth={1200}
      minWidth={1200}
      isBlocking={false}
      onRenderHeader={_onRenderHeader}
      onRenderFooter={_onRenderFooter}
      isOpen
      onDismiss={() => {
        history.replace('/signing');
      }}
      isLightDismiss
    >
      <>
        {!isNew && dataLoading ? (
          <ShimmerView />
        ) : (
          <Stack tokens={{ padding: '20px 0px' }}>
            {!isNew && (
              <Stack>
                <Stack
                  className={styles.headerContainer}
                  tokens={{ childrenGap: 10 }}
                >
                  {_isApprovalDocumentsRequired &&
                    _requiredApprovalDocuments && (
                      <MessageBar messageBarType={MessageBarType.error}>
                        {`${_requiredApprovalDocuments}`}
                      </MessageBar>
                    )}
                  {invoiceDetailsData?.invoice && (
                    <ActionsMenu
                      carbonDeletedUpdated={async (status) => {
                        if (status) await refetch();
                      }}
                      onUpload={async (fileSelected, document, toastId) => {
                        const observer = client.subscribe({
                          query: DOCUMENT_UPLOAD_STATUS,
                          variables: {
                            documentId: document.document._documentFileId!,
                          },
                        });

                        const subscription = observer.subscribe((data) => {
                          const subscribedData =
                            data.data as OnDocumentUploadStatus;

                          const { status, document } = {
                            ...subscribedData.documentUploadStatus,
                          };

                          if (status.type === UploadStatusType.VALIDATING) {
                            updateToast(toastId!, {
                              content: status.message
                                ? `Validating files ${fileSelected.name} - ${status.message}`
                                : `Validating files ${fileSelected.name}`,
                              appearance: 'info',
                              autoDismiss: false,
                            });
                          } else if (
                            status.type === UploadStatusType.EXTRACTING
                          ) {
                            updateToast(toastId!, {
                              content: status.message
                                ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                : `Extracting data from ${fileSelected.name}`,
                              appearance: 'info',
                              autoDismiss: false,
                            });
                          } else if (status.type === UploadStatusType.FAILURE) {
                            subscription.unsubscribe();
                            updateToast(toastId!, {
                              content: status.message
                                ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                : `Upload of ${fileSelected.name} failed`,
                              appearance: 'error',
                              autoDismiss: true,
                            });
                          } else if (status.type === UploadStatusType.WARNING) {
                            updateToast(toastId!, {
                              content: status.message
                                ? `Warning for file ${fileSelected.name}: ${status.message}`
                                : `Warning for file ${fileSelected.name}`,
                              appearance: 'warning',
                              autoDismiss: true,
                            });
                          } else {
                            subscription.unsubscribe();
                            updateToast(toastId!, {
                              content: status.message
                                ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                : `Successfully uploaded ${fileSelected.name}`,
                              appearance: 'success',
                              autoDismiss: true,
                            });

                            if (document) {
                              client.cache.modify({
                                id: client.cache.identify({
                                  ...invoiceDetailsData?.invoice,
                                }),
                                fields: {
                                  entityDocumentsByEntityId(existing) {
                                    return {
                                      ...existing,
                                      nodes: [...existing?.nodes, document],
                                    };
                                  },
                                },
                              });
                            }
                          }
                        });
                      }}
                      invoiceDetails={invoiceDetailsData.invoice}
                      secureRowLevels={transactionTypeData?.secureRowLevels}
                    />
                  )}
                </Stack>
                <Separator />
              </Stack>
            )}

            {isNew && watchTransactionTypeId && watchBusinessUnitId ? (
              watchLayoutType === 'PAY_CARD' ? (
                watchCardHolderId && (
                  <AttachDocumentsForm
                    transactionTypeId={watchTransactionTypeId}
                    businessUnitId={watchBusinessUnitId}
                    currencyId={currencyId}
                    departmentId={watchDepartmentId}
                    cardHolderId={watchCardHolderId}
                    onDocumentsAttached={onDocumentsAttached}
                    previousReceiptTotal={receiptTotal}
                    onReceiptTotal={setReceiptTotal}
                    prevSelectedDocuments={attachedDocuments?.attachedDocuments}
                    previouslyAppliedAmount={previouslyAppliedAmount}
                    initialDocumentMetaData={initialDocumentMetaData}
                  />
                )
              ) : (
                <AttachDocumentsForm
                  transactionTypeId={watchTransactionTypeId!}
                  businessUnitId={watchBusinessUnitId!}
                  currencyId={currencyId}
                  departmentId={watchDepartmentId!}
                  onDocumentsAttached={onDocumentsAttached}
                  prevSelectedDocuments={attachedDocuments?.attachedDocuments}
                  previouslyAppliedAmount={previouslyAppliedAmount}
                  initialDocumentMetaData={initialDocumentMetaData}
                />
              )
            ) : null}

            <TemporaryDocumentList
              isNew={isNew}
              fileListArray={fileListArray}
              setFileListArray={setFileListArray}
              attachedDocuments={attachedDocuments}
              controlTotalAmount={watchControlTotalAmount}
              receiptTotal={receiptTotal}
              onUpdatedDataReceived={setAttachedDocuments}
              onReceiptTotalChange={(_, documentID) => {
                if (documentID === initialDocumentMetaData?.id) {
                  setInitialDocumentMetaData(undefined);
                }
              }}
            />
            <BasicForm
              invoiceDetails={!isNew ? invoiceDetailsData : undefined}
              transactionTypeData={transactionTypeData}
              submitBasicForm={onSave}
              disableCardHolder={disableCardHolder}
              receiptTotal={receiptTotal}
              userDefaultsData={userDefaultsData?.userDefaults?.nodes || []}
            />

            <Accounting
              invoiceData={invoiceDetailsData?.invoice!}
              invoiceDistributionStatusTypes={transactionTypeData}
              availablePurchaseOrders={availablePurchaseOrders}
            />

            <ApprovalHistory
              data={
                invoiceDetailsData?.invoice?.approvalHistoriesByEntityId?.nodes
              }
              // isNew={isNew}
            />

            <Prompt
              when={isDirty && !isSubmitting}
              message="Are you sure you want to leave your changes unsaved?"
            />
          </Stack>
        )}
      </>
    </DraggablePanel>
  );
};
