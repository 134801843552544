import { useQuery } from '@apollo/client';
import { IPersonaProps, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { ContactsPicker } from 'common/components/CreateNewMessage/ContactsPicker';
import { UserDistributionLists } from 'common/components/CreateNewMessage/__generated__/UserDistributionLists';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import { groupBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { EmailContactGroupShimmerView } from './ShimmerViews';
const USER_DISTRIBUTION_LIST = loader('../../../../../../CreateNewMessage/UserDistributionLists.graphql');

interface ContactMember {
  text: string | null;
  id: string | null;
  groupId: string | null;
}

export interface ContactsPayload {
  name: string | null;
  members: ContactMember[];
  groupId: string | null;
}

interface ContactGroup {
  headerName: string | null;
  payLoad: ContactsPayload[];
}

export interface AvailableGroups {
  userIds?: string[];
}

interface EmailContactsFormProps {
  onContactsUpdated?: (data: AvailableGroups) => void;
}

export const EmailContactsForm: React.FC<EmailContactsFormProps> = ({
  onContactsUpdated,
}) => {
  const commonStyles = useCommonStyles();
  const [contactGroupsData, setContactGroupsData] = useState<ContactGroup[]>([]);
  const [contactIds, setContactIds] = useState<Map<string, IPersonaProps[]>>(new Map<string, IPersonaProps[]>());
  const { data, loading } = useQuery<UserDistributionLists>(USER_DISTRIBUTION_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const onContactSelected = (
    selectedUsers: IPersonaProps[],
    groupId: string
  ) => {
    if (selectedUsers.length > 0) contactIds.set(groupId, selectedUsers);
    else contactIds.delete(groupId);
    onReceiversListedMemo(contactIds);
    setContactIds(contactIds);
  };

  const onReceiversListedMemo = useCallback(
    (
      individuals: Map<string, IPersonaProps[]>
    ) => {
      let individualContacts: IPersonaProps[] = [];
      individuals.forEach((item) => {
        individualContacts = [...individualContacts, ...item];
      });
      const contacts = individualContacts.map((item) => item.id as string);
      onContactsUpdated?.({
        userIds: contacts,
      });
    },
    [onContactsUpdated]
  );

  useEffect(() => {
    const groupData = groupBy(
      data?.userDistributionLists?.nodes,
      function (item) {
        return item?.sequenceName;
      }
    );
    const processedGroupData = Object.keys(groupData).map((key) => {
      const subGroupData = groupBy(groupData[key], function (item) {
        return item?.groupName;
      });
      const groupedByData = Object.keys(subGroupData).map((keys) => {
        const subGroupMembers = subGroupData[keys]
          .filter((item) => !item.isGroupHeader)
          .map((item) => ({
            text: item.userName,
            id: item.userId,
            groupId: item.groupId,
          }));
        const { groupId } = { ...subGroupMembers[0] };
        return { name: keys, members: subGroupMembers, groupId };
      });
      return { headerName: key, payLoad: groupedByData };
    });
    setContactGroupsData(processedGroupData);
  }, [data]);

  return (
    <Stack tokens={{ childrenGap: 20, padding: 20 }}>
      {
        loading ?
          <EmailContactGroupShimmerView /> :
          contactGroupsData.map((header, index) => {
            return (
              <Stack key={index} tokens={{ childrenGap: 15 }}>
                <Text
                  variant="large"
                  className={clsx(
                    commonStyles.semibold,
                    commonStyles.colorThemePrimary
                  )}
                >
                  {header.headerName}
                </Text>
                {header.payLoad.map((item, index) => (
                  <ContactsPicker
                    allSelectable={false}
                    key={index}
                    group={item}
                    onContactSelected={(users) =>
                      onContactSelected(users, item.groupId!)
                    }
                  />
                ))}
              </Stack>
            );
          })
      }
    </Stack>
  )
}
