import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  marginL5: {
    marginLeft: 5,
  },
  removeIconContainer: {
    alignItems: 'center',
    width: 20,
   marginRight: 25,
  },
  marginLeft5: {
    marginLeft: 5,
  },
  // marginLeft15: {
  //   marginLeft: 15,
  // },
  deleteIconColor: {
    color: 'red',
  },
  paddingRight5: {
    paddingRight: 5,
  },
  checkboxContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: 70,
    paddingTop: 10,
  },
}));
