import {
  DayOfWeek,
  IDropdownOption,
  ITextFieldProps,
  Label,
  Stack,
} from '@fluentui/react';
import {
  AmountTextField,
  FormikCheckBox,
  FormikDropdown,
  FormikTextField,
} from 'common/components';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { ServiceTypeOptions } from '..';
import { SupplierValues } from '../../types';
import { useStyles } from './index.styles';
import { CompanyNameAutoComplete } from './CompanyNameAutoComplete';
import { SupplierAlias } from '../SupplierAlias';
import { Supplier_supplier } from '../../__generated__/Supplier';
interface BasicFormFieldsProps {
  isCompany: boolean;
  supplierAvailableDocumentTypesOptions: IDropdownOption[];
  paymentTermTypesOptions: IDropdownOption[];
  environmentalMetricsOptions: IDropdownOption[];
  serviceTypeOptions: ServiceTypeOptions[];
  supplier: Supplier_supplier | null | undefined;
  isNew: boolean;
}
export const BasicFormFields: React.FC<BasicFormFieldsProps> = ({
  isCompany,
  supplierAvailableDocumentTypesOptions,
  paymentTermTypesOptions,
  environmentalMetricsOptions,
  serviceTypeOptions,
  supplier,
  isNew,
}) => {
  const { values, setFieldValue } = useFormikContext<SupplierValues>();
  const styles = useStyles();
  const [showVendorServiceRate, setShowVendorServiceRate] =
    useState<boolean>(false);
  const { _isUpdatable, _isUpdateSupplierOverride, _isAliasInvoked } = {
    ...supplier,
  };

  // const isDisabled = isUpdatable === false ? true : false;
  const isEnabled = !isNew
    ? _isUpdateSupplierOverride
      ? true
      : _isUpdatable
      ? true
      : false
    : true;

  const onServiceTypeChange = (selectedOption: ServiceTypeOptions) => {
    setFieldValue('vendorServiceRate', null);
    if (selectedOption.isRateRequired) setFieldValue('isRateRequired', true);
    else setFieldValue('isRateRequired', false);
    if (selectedOption.isServiceRateAllowed) {
      setFieldValue('isServiceRateAllowed', true);
      setShowVendorServiceRate(true);
    } else {
      setFieldValue('isServiceRateAllowed', false);
      setShowVendorServiceRate(false);
    }
  };

  const onClearServiceType = async () => {
    await setFieldValue('vendorServiceRate', null);
    await setFieldValue('isRateRequired', null);
    await setFieldValue('isServiceRateAllowed', null);
    setShowVendorServiceRate(false);
  };

  useEffect(() => {
    if (values.isServiceRateAllowed) setShowVendorServiceRate(true);
    else setShowVendorServiceRate(false);
  }, [values.isServiceRateAllowed]);

  const onRenderLabel = (props: ITextFieldProps | undefined): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="end" horizontalAlign="space-between">
        <Label disabled={props?.disabled}>{props?.label}</Label>
        {!isCompany && !isNew && <SupplierAlias supplier={supplier} />}
      </Stack>
    );
  };
  return (
    <Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        verticalAlign="baseline"
        className={styles.formRow}
      >
        {isCompany ? (
          <Stack.Item className={styles.companyNameContainer}>
            <CompanyNameAutoComplete
              name="companyOrLastName"
              label="Company Name"
              placeholder="Company Name"
              disabled={_isAliasInvoked || (!isNew && !_isUpdatable)}
              required
              id={'companyOrLastName'}
              lookUpCalloutWidth={550}
            />
          </Stack.Item>
        ) : (
          <>
            <Stack.Item className={styles.fieldContainer}>
              <FormikTextField
                name="firstName"
                label="Given Name"
                placeholder="Given Name"
                disabled={_isAliasInvoked || (!isNew && !_isUpdatable)}
                onRenderLabel={onRenderLabel}
              />
            </Stack.Item>
            <Stack.Item className={styles.fieldContainer}>
              <CompanyNameAutoComplete
                name="companyOrLastName"
                label="Family Name"
                placeholder="Family Name"
                disabled={_isAliasInvoked || (!isNew && !_isUpdatable)}
                required
                id={'familyName'}
                lookUpCalloutWidth={550}
              />
            </Stack.Item>
          </>
        )}
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name="vendorNumber"
            label="Supplier Code"
            placeholder="Supplier Code"
            disabled={!isEnabled}
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        className={styles.formRow}
      >
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name="customerAccount"
            label="Customer Account"
            placeholder="Customer Account"
            disabled={!isEnabled || !_isUpdatable}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikDropdown
            label="Payment Terms"
            placeholder="Select"
            options={paymentTermTypesOptions}
            name="paymentTermTypeId"
            disabled={!isEnabled}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikDropdown
            label="Documents"
            placeholder="Select"
            options={supplierAvailableDocumentTypesOptions}
            name="purchaseDocumentsPoolId"
            disabled={!isEnabled}
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        className={styles.formRow}
      >
        <Stack.Item className={styles.fieldContainer}>
          <FormikDropdown
            label="Service Type"
            placeholder="Select"
            options={serviceTypeOptions}
            name="vendorServiceTypeId"
            disabled={!isEnabled}
            onChange={(_e, option) => {
              const selectedOption = option as ServiceTypeOptions;
              onServiceTypeChange(selectedOption);
            }}
            onClear={onClearServiceType}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name="federalTaxReference"
            label="Tax Reference#"
            placeholder="Tax Reference#"
            disabled={!isEnabled}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikTextField
            name="vatNumber"
            label="VAT ID"
            placeholder="VAT ID"
            disabled={!isEnabled}
          />
        </Stack.Item>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        className={styles.rateTypeRow}
      >
        {showVendorServiceRate && (
          <Stack.Item className={styles.fieldContainer}>
            <AmountTextField
              name="vendorServiceRate"
              label="Rate"
              placeholder="Rate"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={false}
              disabled={!isEnabled}
            />
          </Stack.Item>
        )}
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
        }}
        style={{
          margin: '0px 0px 20px', //Inline styles added because style class was not supporting.
        }}
        verticalAlign="end"
      >
        <Stack.Item className={styles.fieldContainer}>
          <FormikCheckBox
            name="isRelatedPartySupplier"
            label="Related Party"
            disabled={!isEnabled}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikCheckBox
            name="isCompetitiveBidsRequired"
            label="Competitive Bids"
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikCheckBox
            name="isContractualPurchaseOrderRequired"
            label="Contractual"
            disabled={!isEnabled}
          />
        </Stack.Item>
        <Stack.Item className={styles.fieldContainer}>
          <FormikCheckBox
            name="isEnvironmentalImpactTracked"
            label="Environmental Tracking"
            disabled={!isEnabled}
          />
        </Stack.Item>
        {values.isEnvironmentalImpactTracked && (
          <Stack.Item
            style={{
              flex: 2,
            }}
          >
            <FormikDropdown
              label="Environmental Metric"
              placeholder="Select"
              options={environmentalMetricsOptions}
              name="environmentalMetricId"
              disabled={!isEnabled}
            />
          </Stack.Item>
        )}
      </Stack>
      <Stack
        horizontal
        style={{
          margin: '0px 0px 20px', //Inline styles added because style class was not supporting.
          width: '50%',
          height: 64,
        }}
        verticalAlign="center"
      >
        <Stack.Item className={styles.fieldContainer}>
          <FormikCheckBox
            name="isW9OnFile"
            label="W9 on file"
            disabled={!isEnabled}
            onChange={(_e, checked) => {
              if (!checked) {
                setFieldValue('w9ExpirationDate', null);
              }
            }}
          />
        </Stack.Item>
        {values.isW9OnFile && (
          <Stack.Item className={styles.fieldContainer}>
            <FormikDatePicker
              disabled={!isEnabled}
              name="w9ExpirationDate"
              placeholder="Select date"
              label="Expires"
              firstDayOfWeek={DayOfWeek.Sunday}
              showWeekNumbers
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday
            />
          </Stack.Item>
        )}
      </Stack>
    </Stack>
  );
};
