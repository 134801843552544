import React, { FC } from 'react';
import { useField } from 'formik';
import { RequiredNameProps } from 'common/types/utility';
import { IDatePickerProps } from '@fluentui/react';
import { onFormatDate } from 'common/utils/dateFormats';
import { CustomDatePicker } from '../CustomDatePicker';
type FormikDatePickerProps = IDatePickerProps & {
  onClear?: () => void;
};
export const FormikDatePicker: FC<RequiredNameProps<FormikDatePickerProps>> = ({
  name,
  onClear,
  ...props
}) => {
  const [field, meta, helpers] = useField<Date | null | undefined>(name);
  const { value, onChange, onBlur, ...rest } = field;
  return (
    <>
      <CustomDatePicker
        styles={{
          statusMessage: {
            marginTop: 0,
          },
        }}
        formatDate={onFormatDate}
        //@ts-ignore
        value={value ? new Date(value) : null}
        onSelectDate={(date) => {
          helpers.setValue(date);
        }}
        textField={{ errorMessage: meta.error }}
        onClear={() => {
          helpers.setValue(null);
          onClear?.();
        }}
        {...rest}
        {...props}
      />
    </>
  );
};
