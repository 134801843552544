import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  iconSize: {
    fontSize: 14,
  },
  contactListUpperStack: {
    height: '560px',
    overflow: 'scroll',
    overflowX: 'clip',
  },
  footerStack: {
    alignItems: 'self-start',
    paddingTop: 40,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  onrenderColumnStack: {
    height: 25,
  },
  columnHeight: {
    height: 25,
  },
  paddingRight10: {
    color: theme.palette.themePrimary,
    paddingRight: 10,
  },
  fileReferenceColumn: {
    marginLeft: 5
  }
}));
