import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  textColor: {
    color: theme.palette.themePrimary,
  },
  amount: {
    marginLeft: '6px !important',
  },
  iconColor: {
    color: theme.palette.orange,
  },
}));
