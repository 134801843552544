import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  disabledButton: {
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
  },
  marginB20: {
    marginBottom: 20,
  },
  documentsContainer: {
    margin: '0px 0px',
  },
  headerContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  marginT10: {
    marginTop: 10,
  },
  status: {
    fontSize: FontSizes.size18,
  },
  paddingRight10: {
    paddingRight: 10,
  },
  tagsContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  approvalHistoryContainer: {
    margin: '20px 0',
    paddingLeft: 25,
    paddingRight: 25,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
}));
