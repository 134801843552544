import { DirectionalHint, Stack, Text, TooltipHost } from '@fluentui/react';
import { RoundCheck } from 'common/components/RoundCheck';
import React, { useCallback, useEffect, useState } from 'react';
import {
  MovementOrders,
  MovementOrders_travelAuthorization_movementOrder_movementTravelers_nodes,
} from '../../__generated__/MovementOrders';
import { useStyles } from '../index.styles';
import { DocumentPackageEmail } from 'common/components/DocumentPackageEmail/DocumentPackageEmail';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import { loader } from 'graphql.macro';
import {
  MovementTravelerEmailCreate,
  MovementTravelerEmailCreateVariables,
} from './__generated__/MovementTravelerEmailCreate';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { DocumentPackage } from 'common/components/DocumentPackage';
import {
  MovementTravelerEmailStatus,
  MovementTravelerEmailStatusVariables,
} from './__generated__/MovementTravelerEmailStatus';
import { IconState } from 'common/types/globalTypes';
const MOVEMENT_TRAVELER_EMAIL_CREATE = loader(
  './MovementTravelerEmailCreate.graphql'
);
const MOVEMENT_ORDER = loader('../../MovementOrders.graphql');
const FETCH_EMAIL_STATUS = loader('./MovementTravelerEmailStatus.graphql');

export type MovementOrderTraveler =
  MovementOrders_travelAuthorization_movementOrder_movementTravelers_nodes;
interface TravelersListProps {
  data: MovementOrders | undefined;
  onClick: (item: MovementOrderTraveler) => void;
  selectedTraveler: MovementOrderTraveler | undefined;
  dataLoading: boolean;
  onTravelerChecked: (item: MovementOrderTraveler) => void;
}

export const TravelersList: React.FC<TravelersListProps> = ({
  data,
  onClick,
  selectedTraveler,
  dataLoading,
  onTravelerChecked,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const [movementTravelerEmail, { loading, data: movementTravelerEmailData }] =
    useMutation<
      MovementTravelerEmailCreate,
      MovementTravelerEmailCreateVariables
    >(MOVEMENT_TRAVELER_EMAIL_CREATE, { errorPolicy: 'all' });

  const { id } = { ...data?.travelAuthorization };
  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    MovementTravelerEmailStatus,
    MovementTravelerEmailStatusVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      data?.travelAuthorization?.movementOrder?.movementTravelers.nodes.map(
        (item) => {
          if (
            selectedTraveler?.id === item.id &&
            item._emailDocument?._sendIconState === IconState.SENT
          ) {
            stopPolling?.();
            setShowEmailStatus(true);
          }
        }
      );
    },
  });
  const _onSubmitValues = async (
    travelerId: string,
    values: EmailCreateValues
  ) => {
    if (travelerId) {
      const { errors } = await movementTravelerEmail({
        variables: {
          input: {
            entityId: travelerId,
            ...values,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: MOVEMENT_ORDER,
            variables: {
              id,
            },
          },
        ],
      });
      if (!!errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: id!,
          },
        });
      }
    }
  };

  const isSuccessful = !!movementTravelerEmailData?.movementTravelerEmailCreate;
  const fetchEmailStatusMemo = useCallback(() => {
    fetchEmailStatus({
      variables: {
        id: id!,
      },
    });
  }, [fetchEmailStatus, id]);

  useEffect(() => {
    data?.travelAuthorization?.movementOrder?.movementTravelers.nodes.map(
      (item) => {
        if (
          selectedTraveler?.id === item.id &&
          item._emailDocument?._sendIconState === IconState.PENDING
        ) {
          fetchEmailStatusMemo();
        }
      }
    );
  }, [data, selectedTraveler, fetchEmailStatusMemo]);

  return (
    <Stack className={styles.sidebarContainer}>
      <Stack tokens={{ padding: '10px 0px 0px 0px' }}>
        {data?.travelAuthorization?.movementOrder?.movementTravelers.nodes.map(
          (item, index) => {
            const isSelected = selectedTraveler?.id === item.id;
            return (
              <Stack
                className={
                  isSelected ? styles.sidebarItemSelected : styles.sidebarItem
                }
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
                horizontal
                key={index}
              >
                <RoundCheck onCheckChange={() => onTravelerChecked(item)} />
                <Stack
                  verticalAlign="center"
                  horizontal
                  horizontalAlign="space-between"
                  grow
                  tokens={{ childrenGap: 10 }}
                  onClick={() => onClick(item)}
                >
                  <TooltipHost
                    tooltipProps={{
                      directionalHint: DirectionalHint.rightTopEdge,
                    }}
                    content={item.revisionReference || ''}
                  >
                    <Stack
                      tokens={{ childrenGap: 10 }}
                      verticalAlign="center"
                      horizontal
                    >
                      <Stack
                        tokens={{ childrenGap: 10 }}
                        verticalAlign="center"
                        style={{ height: 40 }}
                        horizontal
                      >
                        <Text>{item.travelerName}</Text>
                      </Stack>
                      {!!item.revisionReference && (
                        <Stack className={styles.indicator} />
                      )}
                    </Stack>
                  </TooltipHost>

                  <Stack
                    verticalAlign="center"
                    horizontal
                    tokens={{ childrenGap: 5 }}
                  >
                    <DocumentPackage
                      documentPackageId={item.entityDocumentId}
                    />
                    {item.entityDocumentId && (
                      <DocumentPackageEmail
                        emailDocument={item._emailDocument}
                        emailDocumentsByEntityId={
                          item.emailDocumentsByEntityId?.nodes || []
                        }
                        isSuccessful={isSuccessful}
                        loading={loading}
                        onSubmitValues={(values) => {
                          _onSubmitValues(item.id, values);
                        }}
                        showEmailStatus={showEmailStatus}
                        setShowEmailStatus={setShowEmailStatus}
                        dataLoading={dataLoading}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>
            );
          }
        )}
      </Stack>
    </Stack>
  );
};
