import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  row: {
    height: 26,
  },
  listContainer: {},
  footer: {
    position: 'fixed',
    zIndex: 100,
    right: 0,
    bottom: 0,
  },
}));
