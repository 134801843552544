import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  dialogStyles: {
    alignItems: 'self-start',
    padding: 20,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  container: {
    width: 700
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.neutralLighterAlt
  },
}));
