import { Link, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { FilterArrayType } from 'common/components/Filters';
import { useCommonStyles } from 'common/styles';
import { FolderExpand } from 'documents/documentAssignment/folder/list/FolderExpand';
import React from 'react';
import { ListFilters } from '../Filters';
import { FileCabinetPools_fileCabinetPools_nodes } from '../__generated__/FileCabinetPools';
import { useStyles } from './index.styles';

type DocumentPoolItemType = FileCabinetPools_fileCabinetPools_nodes;

export type filterOptionProps = {
  filterTypes: FilterArrayType[];
  startsWith: boolean;
};
interface GroupHeaderProps {
  isOpen: boolean;
  documentPool: DocumentPoolItemType;
  availableCount: number;
  filterOptions: filterOptionProps;
  onFilterChange: (filterOptions: filterOptionProps) => void;
  onHeaderClick: (documentPool: DocumentPoolItemType) => void;
}

export const GroupHeader: React.FC<GroupHeaderProps> = ({
  isOpen,
  documentPool,
  availableCount,
  onHeaderClick,
  onFilterChange,
  filterOptions,
}) => {
  const [isHovered, { setFalse: onMouseLeave, setTrue: onMouseEnter }] =
    useBoolean(false);
  const commonStyles = useCommonStyles();
  const styles = useStyles();

  return (
    <Stack
      horizontal
      className={clsx(
        styles.root,
        isOpen && styles.groupSelected,
        isHovered ? styles.linkLightHover : styles.linkHover
      )}
      tokens={{ childrenGap: 50 }}
    >
      <Link
        style={{ textDecoration: 'none' }}
        onClick={() => onHeaderClick(documentPool)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 20 }}>
          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
            style={{ width: 300 }}
            verticalAlign="center"
          >
            <FolderExpand isOpen={isOpen} />
            <Text
              nowrap
              block
              className={commonStyles.semibold}
              variant="medium"
            >
              {documentPool.name}
            </Text>
            <Text variant="medium" className={styles.groupName}>
              {`(${availableCount})`}
            </Text>
          </Stack>

          <Stack horizontal grow={2}>
            <Text block nowrap variant="medium" className={styles.description}>
              {documentPool.description}
            </Text>
          </Stack>
        </Stack>
      </Link>
      {isOpen && (
        <Stack
          className={styles.optionsSection}
          verticalAlign="center"
          horizontalAlign="end"
        >
          <ListFilters
            filterOptions={filterOptions}
            documentPoolId={documentPool.id}
            onFilterChange={onFilterChange}
          />
        </Stack>
      )}
    </Stack>
  );
};
