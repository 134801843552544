import { Stack, Text } from '@fluentui/react';

import clsx from 'clsx';
import React from 'react';
import { useStyles } from './index.styles';
import { InitialDocumentMetaData } from '..';
interface InitialDocumentDataProps {
  initialDocumentMetaData: InitialDocumentMetaData;
}
export const InitialDocumentData: React.FC<InitialDocumentDataProps> = ({
  initialDocumentMetaData,
}) => {
  const styles = useStyles();
  return (
    <Stack className={styles.container}>
      {initialDocumentMetaData?.indexName && (
        <Stack>
          <Text className={clsx(styles.text, styles.nameText)}>
            {initialDocumentMetaData.indexName}
          </Text>
        </Stack>
      )}
      {initialDocumentMetaData?.comment && (
        <Stack>
          <Text
            nowrap
            className={styles.text}
            title={initialDocumentMetaData.comment}
          >
            {initialDocumentMetaData.comment}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};
