import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { RequiredNameProps } from 'common/types/utility';
import { IDatePickerProps } from '@fluentui/react';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { CustomDatePicker } from '../CustomDatePicker';
import { UseControllerProps } from 'react-hook-form';

type FormHookDateSelectProps = IDatePickerProps & {
  onClear?: () => void;
  rules?: UseControllerProps['rules'];
  defaultValue?: any;
  onSelectDateCallback?: (date: Date | null | undefined) => void;
};
export const FormHookDateSelect: FC<
  RequiredNameProps<FormHookDateSelectProps>
> = ({ name, onClear, rules, onSelectDateCallback, ...props }) => {
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const newValue = value ? new Date(value) : null;
        return (
          <CustomDatePicker
            styles={{
              statusMessage: {
                marginTop: 0,
              },
            }}
            formatDate={onFormatDate}
            // @ts-ignore
            value={newValue}
            onSelectDate={(date) => {
              if (date) {
                onChange(dateFormat(date.toString()));
                onSelectDateCallback?.(date);
              }
            }}
            textField={{ errorMessage: error?.message }}
            onClear={() => {
              onChange(null);
              onClear?.();
            }}
            {...props}
          />
        );
      }}
      name={name}
      rules={rules}
    />
  );
};
