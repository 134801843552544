import {
  StopModalItemValues,
  TripDistributionFormValues,
  TripItemFormValues,
} from './types';

export const TRIP_DISTRIBUTION_INITIAL_VALUES: TripDistributionFormValues = {
  referenceCode6: null,
  referenceCode7: null,
  accountReference: null,
  projectReference: null,
  setReference: null,
  distributionAmount: null,
  allocationPercent: null,
  referenceCode5: null,
  referenceCode1: null,
  referenceCode2: null,
  referenceCode3: null,
  referenceCode4: null,
  productionIncentiveId: null,
  tax1099T4TypeId: null,
  tripItemId: null,
  projectId: null,
  accountId: null,
  setAccountId: null,
  referenceCode1Id: null,
  referenceCode2Id: null,
  referenceCode3Id: null,
  referenceCode4Id: null,
  referenceCode5Id: null,
  referenceCode6Id: null,
  referenceCode7Id: null,
  additionalDescription: null,
  currencyId: null,
  tripSectionId: null,
  id: null,
  _rowTimestamp: null,
  _accountName: null,
};

export const TRIP_INITIAL_VALUES: TripItemFormValues = {
  id: null,
  _rowTimestamp: null,
  tripSectionItemId: null,
  departureStops: null,
  returnStops: null,
  departureMiles: null,
  returnMiles: null,
  departureTerminal: null,
  departureTerminalArrival: null,
  returnTerminal: null,
  returnTerminalArrival: null,
  departureArrivalDate: null,
  departureArrivalTime: null,
  pickupArrivalName: null,
  pickupArrivalDropName: null,
  layout: null,
  pickupArrivalPrice: null,
  departureDate: null,
  departureTime: null,
  pickupName: null,
  pickupDropName: null,
  pickupPrice: null,
  returnDate: null,
  returnTime: null,
  rentalName: null,
  returnDropName: null,
  returnPrice: null,
  returnName: null,
  rentalPrice: null,
  returnArrivalDate: null,
  returnArrivalTime: null,
  returnArrivalName: null,
  returnArrivalDropName: null,
  returnArrivalPrice: null,
  bookingFeeQuantity: null,
  bookingFeeRate: null,
  bookingFeeTotal: null,
  perDiemQuantity: null,
  perDiemRate: null,
  perDiemTotal: null,
  incidentalQuantity: null,
  incidentalRate: null,
  incidentalTotal: null,
  miscellaneousQuantity: null,
  miscellaneousRate: null,
  miscellaneousTotal: null,
  itemTotal: null,
  isDepartureEconomyClass: null,
  isDeparturePremiumClass: null,
  isDepartureBusinessClass: null,
  isDepartureFirstClass: null,
  isDepartureCharterClass: null,
  isDepartureRefundable: null,
  departureDepotId: null,
  departureArrivalDepotId: null,
  departureCarrierId: null,
  returnDepotId: null,
  returnArrivalDepotId: null,
  returnCarrierId: null,
  tripSectionId: null,
  departureAirfare: null,
  returnAirfare: null,
  departureCarrierTripNumber: null,
  returnCarrierTripNumber: null,
  departureArrivalConfirmationCode: null,
  departureCarrierConfirmationCode: null,
  returnCarrierConfirmationCode: null,
  returnArrivalConfirmationCode: null,
  isReturnRefundable: null,
  departureSeat: null,
  returnSeat: null,
  isReturnEconomyClass: null,
  isReturnPremiumClass: null,
  isReturnBusinessClass: null,
  isReturnFirstClass: null,
  isReturnCharterClass: null,
  rentalCityName: null,
  rentalCountryId: null,
  rentalAddress2: null,
  rentalStateRegionId: null,
  rentalAddress1: null,
  rentalTerm: null,
  checkInDate: null,
  checkInTime: null,
  checkOutDate: null,
  checkOutTime: null,
  returnAddress1: null,
  returnAddress2: null,
  comments: null,
  rentalEmailAddress: null,
  rentalTelephoneNumber: null,
  rentalPostalCode: null,
  tripDistributions: [],
  stopModalItems: [],
  stopModalReturnItems: [],
  supplierId: null,
  departureDepotName: null,
  arrivalDepotName: null,
  carrierName: null,
  returnDepotName: null,
  returnArrivalDepotName: null,
  returnCarrierName: null,
  pickupSupplierId: null,
  pickupDropSupplierId: null,
  returnDropSupplierId: null,
  returnSupplierId: null,
  currencyId: null,
  pickupDropCurrencyId: null,
  pickupCurrencyId: null,
  returnCurrencyId: null,
  returnDropCurrencyId: null,
  returnEticketNumber: null,
  departureEticketNumber: null,
  isPreferredSupplier: null,
  preferredRateTypeId: '0',
  isExceptionRequired: null,
  exceptionComments: null,
  isSupplierBreakdownRequired: null,
};
export const STOP_MODAL_INITIAL_VALUES: StopModalItemValues = {
  depotId: null,
  arriveDate: null,
  arriveTime: null,
  departTerminal: null,
  arriveTerminal: null,
  departDate: null,
  departTime: null,
  depotName: null,
};
