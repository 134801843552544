import { IModalProps, Modal } from '@fluentui/react';
import React, { useEffect } from 'react';
import { closeOpenedDocument, setActiveOrderIndex } from 'utility/cache/ui';

export const ModalWrapper: React.FunctionComponent<IModalProps> = (props) => {
  useEffect(() => {
    if (props.isOpen) {
      const current = setActiveOrderIndex();
      current.clear();
      setActiveOrderIndex(current);
      closeOpenedDocument(true);
    }
  }, [props.isOpen]);

  return <Modal {...props} />;
};
