import {
  ContextualMenu,
  DefaultButton,
  Dropdown,
  IContextualMenuProps,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  Toggle,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import React, { useEffect, useState } from 'react';
import { CloseButton } from 'common/components/Buttons';
import { CommonData_companyBusinessUnits } from '../Documents/__generated__/CommonData';
import { formatDropdownOptions } from 'common/utils';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  DocumentTransactionBuild,
  DocumentTransactionBuildVariables,
} from './__generated__/DocumentTransactionBuild';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import { GetDocumentPoolCommonData_companyDepartments } from 'documents/__generated__/GetDocumentPoolCommonData';
import { setUserDefaults } from 'utility/cache/ui';
import { useCommonStyles } from 'common/styles';
import { EntityDocumentSearch_entityDocumentSearch_nodes } from '../Documents/__generated__/EntityDocumentSearch';
import { useHistory } from 'react-router-dom';
const BUILD_TRANSACTION = loader('./DocumentTransactionBuild.graphql');

type SelectedListProps = Pick<
  EntityDocumentSearch_entityDocumentSearch_nodes,
  '_isSelected' | 'id' | 'documentTypes'
>;
interface BuildTransactionProps {
  allowMultiFileBundleToggle: boolean;
  companyBusinessUnits: CommonData_companyBusinessUnits | null | undefined;
  companyDepartments:
    | GetDocumentPoolCommonData_companyDepartments
    | null
    | undefined;
  selectedList: SelectedListProps[];
  onBuildComplete?: () => void;
  onCloseDocumentView?: () => void;
}

export const BuildTransaction: React.FC<BuildTransactionProps> = ({
  companyBusinessUnits,
  companyDepartments,
  selectedList,
  onBuildComplete,
  onCloseDocumentView,
  allowMultiFileBundleToggle,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const [buildTransaction, { loading }] = useMutation<
    DocumentTransactionBuild,
    DocumentTransactionBuildVariables
  >(BUILD_TRANSACTION, {
    errorPolicy: 'all',
  });
  const { addToast } = useToasts();
  const [isMultiPageBundle, setIsMultiPageBundle] = useState(false);
  const [businessUnitId, setBusinessUnit] = useState<string | undefined>();
  const [departmentId, setDepartmentId] = useState<string | undefined>();
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);

  const styles = useStyles();
  const closeModal = () => {
    setVisible(false);
    setEdit(false);
  };
  const openModal = () => {
    setVisible(true);
    onCloseDocumentView?.();
  };
  const BUILD_AND_TRANSACT_TEXT = 'Build Transactions + Edit';
  const BUILD_TEXT = 'Build Transactions';
  const businessUnitOptions = formatDropdownOptions(
    companyBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
    }
  );

  const checkDocType = selectedList[0]?.documentTypes?.documentType;

  const allEqual = (arr: SelectedListProps[]) =>
    arr.every((val) => {
      return (
        val?.documentTypes?.documentType ===
          arr[0]?.documentTypes?.documentType && checkDocType !== undefined
      );
    });

  const allMultiBundleAllowed =
    selectedList.length > 0 &&
    allEqual(selectedList) &&
    selectedList.findIndex((item) => {
      return item.documentTypes?._isMultiDocumentBundleAllowed;
    }) !== -1;

  const departmentOptions = formatDropdownOptions(companyDepartments?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.name!,
  });

  const selectedListNonHistory = selectedList?.[0]
    ? selectedList.filter(
        (item) =>
          !item._isSelected &&
          !!item.documentTypes?.transactionTypeId &&
          !item.documentTypes._isAppliableDocumentType
      )
    : [];

  const disabled = !businessUnitId || !departmentId;

  useEffect(() => {
    const { businessUnitId, departmentId } = {
      ...userDefaults,
    };
    if (businessUnitId) setBusinessUnit(businessUnitId);
    if (departmentId) setDepartmentId(departmentId);
  }, [userDefaults]);

  const onBuildProcess = async () => {
    const entityDocumentId = selectedListNonHistory.map((item) => item.id);
    if (businessUnitId && departmentId) {
      const { errors, data } = await buildTransaction({
        variables: {
          input: {
            departmentId,
            businessUnitId,
            entityDocumentId,
            isMultiPageBundle: allowMultiFileBundleToggle
              ? isMultiPageBundle
              : false,
          },
        },
      });

      if (!errors?.length) {
        closeModal();
        onBuildComplete?.();
        addToast('Transaction Built successfully', { appearance: 'success' });

        const { _transactionHistoryId } = {
          ...data?.documentTransactionBuild?.entityDocuments?.[0],
        };
        if (edit && _transactionHistoryId)
          history.push(`/signing/transaction/${_transactionHistoryId}`);
      } else addToast(errors[0].message, { appearance: 'error' });
    }
  };
  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'buildAndEdit',
        text: BUILD_AND_TRANSACT_TEXT,
        onClick: (event) => {
          if (event) event.stopPropagation();
          setEdit(true);
          openModal();
        },
      },
    ],
  };
  return (
    <Stack>
      {!!selectedListNonHistory.length && (
        <Stack.Item grow={2}>
          <DefaultButton
            text={edit ? BUILD_AND_TRANSACT_TEXT : BUILD_TEXT}
            iconProps={{ iconName: 'AddToShoppingList' }}
            split
            menuProps={menuProps}
            onClick={openModal}
          />
        </Stack.Item>
      )}
      {visible && (
        <Modal
          isOpen
          isBlocking
          dragOptions={{
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
            dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
          }}
        >
          <Stack
            horizontal
            style={{ cursor: 'move' }}
            tokens={{ padding: '20px 25px' }}
            horizontalAlign="space-between"
          >
            <Text variant={'xLarge'}>Build Transactions</Text>
            <CloseButton onClick={closeModal} />
          </Stack>

          <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
            {/* Header */}

            {/* Body */}
            <Stack tokens={{ padding: '10px 25px', childrenGap: 25 }}>
              <Dropdown
                options={businessUnitOptions!}
                placeholder={'Business Unit'}
                selectedKey={businessUnitId}
                onChange={(_, option) => {
                  setBusinessUnit(option?.key.toString());
                }}
              />

              <Dropdown
                options={departmentOptions!}
                placeholder={'Departments'}
                selectedKey={departmentId}
                onChange={(_, option) => {
                  setDepartmentId(option?.key.toString());
                }}
              />
            </Stack>

            {loading && <ProgressIndicator />}
            <Stack
              verticalAlign="center"
              horizontal
              horizontalAlign="space-between"
              tokens={{ padding: '10px 25px' }}
            >
              {allowMultiFileBundleToggle && allMultiBundleAllowed && (
                <Toggle
                  label={
                    <Text className={commonStyles.colorThemePrimary}>
                      Separate
                    </Text>
                  }
                  inlineLabel
                  onText="Batch"
                  offText="Batch"
                  onChange={(_, checked) => {
                    setIsMultiPageBundle(checked || false);
                  }}
                />
              )}
              <Stack
                horizontal
                horizontalAlign="end"
                tokens={{ childrenGap: 25 }}
              >
                <PrimaryButton
                  text="Build"
                  disabled={disabled}
                  onClick={onBuildProcess}
                />
                <DefaultButton text="Cancel" onClick={closeModal} />
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      )}
    </Stack>
  );
};
