import { useLazyQuery } from '@apollo/client';
import OrganizationChart from '@dabeng/react-orgchart';
import { Stack, Text, makeStyles } from '@fluentui/react';
import { getTreeGraph } from 'common/components/Hierarchy/utils';
import { LoadingHierarchy } from 'common/components/PreviewHierarchyComponents/LoadingHierarchy';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { NodeView } from './NodeView';
import { TravelAuthorizationPreviewApprovalMap, TravelAuthorizationPreviewApprovalMapVariables, TravelAuthorizationPreviewApprovalMap_travelAuthorizationPreviewApprovalMap_nodes } from './__generated__/TravelAuthorizationPreviewApprovalMap';
const TRAVEL_AUTHORIZATION_PREVIEW_APPROVAL_MAP = loader("./TravelAuthorizationPreviewApprovalMap.graphql")

export interface HierarchyNode extends TravelAuthorizationPreviewApprovalMap_travelAuthorizationPreviewApprovalMap_nodes {
  children?: HierarchyNode[];
}

interface HierarchyComponentProps {
  entityId: string | undefined
}

const useStyles = makeStyles((theme) => ({
  hierarchyContainer: {
    backgroundColor: theme.palette.white,
  },
}));

export const HierarchyComponent: React.FC<HierarchyComponentProps> = ({
  entityId,
}) => {
  const styles = useStyles();
  const [hierarchyData, setHierarchyData] = useState<HierarchyNode[]>([]);

  const [getApprovalMap, { loading, data, error }] = useLazyQuery<
    TravelAuthorizationPreviewApprovalMap,
    TravelAuthorizationPreviewApprovalMapVariables
  >(TRAVEL_AUTHORIZATION_PREVIEW_APPROVAL_MAP, { fetchPolicy: 'network-only' });

  const getApprovalDetails = () => {
    getApprovalMap({
      variables: {
        entityId: entityId!,
      },
    });
  };

  const getApproval = useCallback(getApprovalDetails, [entityId]);

  useEffect(() => {
    getApproval();
  }, [getApproval]);

  const memoizedChildrenData = useCallback(getTreeGraph, []);

  const { nodes } = { ...data?.travelAuthorizationPreviewApprovalMap };

  useEffect(() => {
    if (!loading && nodes?.length !== 0) {
      // Implicitly adding children property to avoid an error caused
      const childrenAdded = nodes?.map((item) => ({
        ...item,
        children: [] as HierarchyNode[],
      }));
      const newData = memoizedChildrenData(childrenAdded) as HierarchyNode[];
      setHierarchyData(newData);
    }
  }, [nodes, loading, memoizedChildrenData]);

  return (
    <Stack className={styles.hierarchyContainer}>
      {hierarchyData.length > 0 ? (
        <>
          <OrganizationChart
            datasource={hierarchyData[0]}
            chartClass="myChart"
            NodeTemplate={(node: any) => <NodeView nodeData={node.nodeData} />}
            pan={true}
            containerClass={'orgchartcontainer'}
            zoom={true}
            zoominLimit={1.2}
            zoomoutLimit={0.5}
            draggable={false}
            collapsible={false}
          />
        </>
      ) : (
        <>
          {error ? (
            <>
              <Stack
                horizontalAlign="center"
                verticalAlign="center"
                style={{ height: 600 }}
              >
                <Stack style={{ width: 800 }}>
                  <Text variant="xLarge">{error.message}</Text>
                </Stack>
              </Stack>
            </>
          ) : (
            <LoadingHierarchy />
          )}
        </>
      )}
    </Stack>
  );
}
