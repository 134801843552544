import { useMutation } from '@apollo/client';
import { IconButton, Modal, Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { MovementOrderJourney } from '..';
import { MovementOrders } from '../../../__generated__/MovementOrders';
import { MovementOrderTraveler } from '../../Sidebar';
import { FormView } from './Form';
import {
  MovementTravelerUpdate,
  MovementTravelerUpdateVariables,
} from './__generated__/MovementTravelerUpdate';
import { useStyles } from './index.styles';
import { JourneyFormValues } from './types';
const MOVEMENT_ORDER_JOURNEY_UPDATE = loader(
  './MovementTravelerUpdate.graphql'
);

interface JourneyProps {
  journey: MovementOrderJourney;
  data: MovementOrders | undefined;
  selectedTraveler: MovementOrderTraveler | undefined;
}
export const Journey: React.FC<JourneyProps> = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <Stack>
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        disabled={!props.journey._isUpdatable}
        onClick={() => setVisible(true)}
      />
      {visible && <JourneyModal onClose={() => setVisible(false)} {...props} />}
    </Stack>
  );
};

const JourneyModal: React.FC<{
  onClose: () => void;
  journey: MovementOrderJourney;
  data: MovementOrders | undefined;
  selectedTraveler: MovementOrderTraveler | undefined;
}> = ({ onClose, journey, selectedTraveler }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const { headerTitle, phraseType, bodyArea1, headerCityFromTo } = {
    ...journey,
  };
  const formMethods = useForm<any>({
    defaultValues: {
      headerTitle,
      phraseType,
      bodyArea1,
      headerCityFromTo,
    } as JourneyFormValues,
  });

  const { handleSubmit } = { ...formMethods };
  const [updateJourney] = useMutation<
    MovementTravelerUpdate,
    MovementTravelerUpdateVariables
  >(MOVEMENT_ORDER_JOURNEY_UPDATE, { errorPolicy: 'all' });

  const onHandleSubmit = async (values: JourneyFormValues) => {
    const errors = await updateJourney({
      variables: {
        id: journey.id!,
        input: {
          movementTravelerId: selectedTraveler?.id!,
          movementTravelerJourneyUpdate: [
            {
              movementTravelerJourneyUpdatePatch: { ...values },
              id: journey.id,
              rowTimestamp: journey._rowTimestamp,
            },
          ],
        },
      },
    });
    if (!!errors.errors?.length) {
      addToast('Error while updating Journey', { appearance: 'error' });
    } else {
      addToast('Journey updated', { appearance: 'success' });
      onClose();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Modal
        isOpen
        containerClassName={styles.container}
        styles={{ scrollableContent: { overflow: 'hidden' } }}
      >
        {/* Header */}
        <Stack
          style={{ padding: '20px' }}
          horizontal
          horizontalAlign="space-between"
        >
          <Text className={commonStyles.colorThemePrimary} variant="xLarge">
            Trip Detail
          </Text>

          <CloseButton onClick={onClose} />
        </Stack>

        <FormView onSave={handleSubmit(onHandleSubmit)} />
      </Modal>
    </FormProvider>
  );
};
