import { NetworkStatus, useQuery } from '@apollo/client';
import { IconButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { useCommonStyles } from 'common/styles';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteTemplate } from './DeleteTemplate';
import {
  UserTemplates,
  UserTemplates_departmentOccupationTemplates_nodes,
} from './__generated__/UserTemplates';
import { getColumns } from './column.data';
import { ColumnStylesProps, onRenderItems } from './columns.render';
import { useStyles } from './index.styles';
import { onHandleSearch } from './utils';
import { DepartmentOccupationTemplatesOrderBy } from 'common/types/globalTypes';

const USER_TEMPLATES = loader('./UserTemplates.graphql');

export type UserTemplatesRowItem =
  UserTemplates_departmentOccupationTemplates_nodes;

export const UserTemplatesList: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const commonStyles = useCommonStyles();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const columnStyles: ColumnStylesProps = {
    onRenderColumnStack: styles.onRenderColumnStack,
  };
  const [selectedRows, setSelectedRows] = useState<UserTemplatesRowItem[]>([]);
  const renderRef = useRef(false);

  const {
    data: userTemplatesData,
    loading: UserTemplatesLoading,
    networkStatus,
    refetch,
  } = useQuery<UserTemplates>(USER_TEMPLATES, {
    variables: {
      orderBy: DepartmentOccupationTemplatesOrderBy.NAME_ASC,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;
  const { nodes } = {
    ...userTemplatesData?.departmentOccupationTemplates,
  };
  const transformedData: UserTemplatesRowItem[] = refetching
    ? []
    : nodes?.map((item) => ({
        ...item,
      })) || [];
  const handleSearch = async () => {
    setSelectedRows([]);
    const { queryVariables } = onHandleSearch(sortOrderParam);
    refetch(queryVariables);
  };

  const handleSearchMemo = useCallback(handleSearch, [sortOrderParam]);
  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);
  const onRefresh = () => {
    refetch();
  };

  useEffect(() => {
    setCustomColumns();
  }, [setCustomColumns]);
  const _renderItemColumn = useCallback(onRenderItems(columnStyles), [
    columnStyles,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo();
    else renderRef.current = true;
  }, [handleSearchMemo]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">User Templates</Text>
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            <IconButton
              iconProps={{ iconName: 'refresh' }}
              onClick={onRefresh}
            />
            <DeleteTemplate selectedRows={selectedRows} />
            <PrimaryButton
              iconProps={{
                iconName: 'Add',
              }}
              text="New User Template"
              onClick={() =>
                history.replace(
                  `/account-management/userTemplates/userTemplate`
                )
              }
            />
          </Stack>
        </Stack>
      </Stack>
      <InfiniteList
        loading={UserTemplatesLoading}
        items={transformedData}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onSelectionChanged={setSelectedRows}
      />
    </>
  );
};
