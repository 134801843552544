import { useReactiveVar } from '@apollo/client';
import { PurchaseOrderItemProps } from 'purchaseOrder/view/FormView/BasicForm';
import { PurchaseOrderDataType } from 'purchaseOrder/view/FormView/POItems';
import { showPurchaseOrderModal } from 'purchaseOrder/view/FormView/POItems/POItemsModal';
import { POItemsForm } from 'purchaseOrder/view/FormView/POItems/POItemsModal/POItemsForm';
import { PurchaseOrderCommonData } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import React from 'react';

interface POItemsViewProps {
  purchaseOrderData: PurchaseOrderDataType | undefined;
  commonData: PurchaseOrderCommonData | undefined;
  purchaseOrderItemAvailable: PurchaseOrderItemProps[] | undefined;
  onRefetch: () => void;
}

export const POItemsView: React.FC<POItemsViewProps> = ({
  purchaseOrderData,
  commonData,
  purchaseOrderItemAvailable,
  onRefetch,
}) => {
  const poModaReactiveProps = useReactiveVar(showPurchaseOrderModal);
  const { isModalOpen, poItem } = { ...poModaReactiveProps };

  const visible =
    !!commonData &&
    !!purchaseOrderData &&
    !!purchaseOrderItemAvailable &&
    isModalOpen &&
    !!poItem;

  if (visible) {
    return (
      <POItemsForm
        isOpen={visible}
        businessUnitId={null}
        purchaseOrderData={{
          purchaseOrder: purchaseOrderData.purchaseOrder,
        }}
        commonData={commonData}
        inputsDisabled
        purchaseOrderItemAvailable={purchaseOrderItemAvailable}
        onUpdate={onRefetch}
      />
    );
  }
  return null;
};
