import { IDropdownOption, Label, Separator, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  FormikCheckBox,
  FormikDropdown,
  FormikTextField,
  FormikToggle,
} from 'common/components';
import {
  GetDocumentPoolCommonData,
  GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes,
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentPoolFormValues } from '../types';
import { GetDocumentPoolDetails_documentPool_defaultDocumentTypes_extractionTypes_nodes } from '../__generated__/GetDocumentPoolDetails';
import { useStyles } from './index.styles';
interface DocumentPoolFormProps {
  commonData: GetDocumentPoolCommonData | undefined;
  setComponentFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  isAdministrator: boolean;
  extractionTypes: GetDocumentPoolDetails_documentPool_defaultDocumentTypes_extractionTypes_nodes[];
}
type ExtractionTypes =
  GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes;
interface DocumentTypeOptions extends IDropdownOption {
  extractionTypes: ExtractionTypes;
}

export const DocumentPoolForm: React.FC<DocumentPoolFormProps> = ({
  commonData,
  setComponentFieldValue,
  isAdministrator = false,
  extractionTypes,
}) => {
  const styles = useStyles();
  const { values, setFieldValue } = useFormikContext<DocumentPoolFormValues>();
  const [disableShareable, { setTrue: makeDisabled, setFalse: removeDisable }] =
    useBoolean(true);
  const [dataExtractModelOptions, setDataExtractModelOptions] = useState<
    IDropdownOption[]
  >([]);

  //updated for useEffect Warning
  const handleFormicComponent = () => {
    if (values.defaultDirectoryTypeId === 4) {
      setComponentFieldValue('isShareableDefault', false);
      makeDisabled();
    } else removeDisable();
  };

  const handleFormicComponentMemo = useCallback(handleFormicComponent, [
    values.defaultDirectoryTypeId,
  ]);

  useEffect(() => {
    handleFormicComponentMemo();
  }, [handleFormicComponentMemo]);

  let storageTypeOptions: IDropdownOption[] = [];
  let documentTypeOptions: DocumentTypeOptions[] = [];
  let businessUnitOptions: IDropdownOption[] = [];
  let departmentOptions: IDropdownOption[] = [];
  let userGroupOptions: IDropdownOption[] = [];

  const formatDropDownOptions = (dropArr: any[], textName: string) => {
    return (
      dropArr.map((item) => ({
        key: item['id'],
        text: item[textName] ? item[textName] : 'NA',
        disabled: !item['_isDocSelectable'],
      })) || []
    );
  };
  if (commonData) {
    storageTypeOptions =
      commonData.directoryTypes?.nodes.map((storage) => ({
        key: storage.id,
        text: storage['description'] ? storage.description : 'NA',
        directory: storage['directory'],
      })) || [];
    documentTypeOptions =
      commonData.documentPoolAvailableDocumentTypes?.nodes.map((type) => ({
        key: type.id,
        text: type.documentType!,
        extractionTypes: type.extractionTypes,
      })) || [];
    businessUnitOptions =
      commonData.companyBusinessUnits?.nodes.map((item) => ({
        key: item.id,
        text: item.name ? item.name : 'NA',
      })) || [];

    departmentOptions = formatDropDownOptions(
      commonData.companyDepartments?.nodes || [],
      'name'
    );
    if (
      commonData.companyUserGroups?.nodes &&
      commonData.companyUserGroups.nodes.length > 0
    ) {
      userGroupOptions = commonData.companyUserGroups.nodes.map((group) => ({
        key: group.userGroupId,
        text: group.userGroup?.name ? group.userGroup?.name : 'NA',
      }));
    }
  }

  useEffect(() => {
    if (extractionTypes.length > 0) {
      const extractionTypesArray =
        extractionTypes.map((ele) => ({
          key: ele.id,
          text: ele.extractionType,
        })) || [];
      setDataExtractModelOptions(extractionTypesArray);
    }
  }, [extractionTypes]);

  const onDocumentTypeChange = async (documentTypeId: number | undefined) => {
    setFieldValue('extractionTypeId', null);
    if (documentTypeId) {
      const documentTypes =
        commonData?.documentPoolAvailableDocumentTypes?.nodes.find(
          (o) => o.id === documentTypeId
        );
      const extractionTypesArray =
        documentTypes?.extractionTypes.nodes.map((ele) => ({
          key: ele.id,
          text: ele.extractionType,
        })) || [];
      if (extractionTypesArray.length > 0) {
        setDataExtractModelOptions(extractionTypesArray);
      } else {
        setDataExtractModelOptions([]);
      }
    }
  };

  const onDocumentTypeClear = async () => {
    await setFieldValue('extractionTypeId', null);
    setDataExtractModelOptions([]);
  };

  return (
    <Stack>
      <Stack className={styles.inputsContainer}>
        <Stack tokens={{ childrenGap: 20 }} className={styles.paddingRL10}>
          <Stack.Item grow>
            <FormikTextField
              name="name"
              label="Name"
              placeholder="Enter Your Name"
              required
            />
          </Stack.Item>
          <Stack.Item grow>
            <FormikTextField
              name="description"
              label="Description"
              placeholder="Enter Your Description Here"
              rows={5}
              multiline
              resizable={false}
            />
          </Stack.Item>
        </Stack>
      </Stack>
      <Separator />
      <Stack className={styles.inputsContainer}>
        <Stack tokens={{ childrenGap: 40 }} className={styles.paddingRL10}>
          <Stack
            horizontal
            tokens={{ childrenGap: 20 }}
            horizontalAlign="space-evenly"
          >
            <Stack.Item grow className={styles.flex50}>
              <FormikCheckBox
                name="isShareableDefault"
                label="Shareable"
                disabled={!isAdministrator || disableShareable}
              />
            </Stack.Item>
            {/* Create Copy On Use is commented for now as directed in the User Story 228 */}
            {/* <Stack.Item grow className={styles.flex33}>
              <FormikCheckBox
                name="isCreateCopyWithSelection"
                label="Create Copy On Use"
              />
            </Stack.Item> */}
            <Stack.Item grow className={styles.flex50}>
              <FormikCheckBox
                name="isRetainedWithSelection"
                label="Retain On Use"
                disabled={!isAdministrator}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack>
      <Separator />
      <Stack className={styles.inputsContainer}>
        <Stack tokens={{ childrenGap: 40 }} className={styles.paddingRL10}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow className={styles.flex50}>
              <FormikDropdown
                label="Storage Type"
                placeholder="Select"
                options={storageTypeOptions}
                name="defaultDirectoryTypeId"
                onChange={(_event, SelectedOption) => {
                  setComponentFieldValue(
                    'defaultDirectoryTypeId',
                    SelectedOption?.key
                  );
                }}
              />
            </Stack.Item>
            <Stack.Item grow className={styles.flex50}>
              <Label>User Group</Label>
              <FormikDropdown
                placeholder="Select"
                name="userGroupId"
                options={userGroupOptions}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack className={styles.flex50}>
              <Stack.Item grow className={styles.flex70}>
                <Stack
                  horizontal
                  verticalAlign="center"
                  horizontalAlign="space-between"
                  style={{ width: 330 }}
                >
                  <Label>Document Type</Label>
                  <FormikToggle
                    name="isDocumentTypeSelector"
                    label="Exclusive"
                    inlineLabel={true}
                  />
                </Stack>
                <FormikDropdown
                  placeholder="Select"
                  options={documentTypeOptions}
                  name="defaultDocumentTypeId"
                  onChange={(_event, SelectedOption) => {
                    onDocumentTypeChange(Number(SelectedOption?.key) || 0);
                  }}
                  onClear={onDocumentTypeClear}
                />
              </Stack.Item>
            </Stack>
            <Stack className={styles.flex50}>
              <Stack>
                <Stack
                  horizontal
                  verticalAlign="center"
                  horizontalAlign="space-between"
                  style={{ width: 330 }}
                >
                  <Label>Business Unit</Label>
                  <FormikToggle
                    name="isBusinessUnitSelector"
                    label="Exclusive"
                    inlineLabel={true}
                  />
                </Stack>
                <FormikDropdown
                  placeholder="Select"
                  options={businessUnitOptions}
                  name="defaultBusinessUnitId"
                  onChange={(_event, SelectedOption) => {
                    setComponentFieldValue(
                      'defaultBusinessUnitId',
                      SelectedOption?.key
                    );
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack className={styles.width50}>
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
                style={{ width: 330 }}
              >
                <Label>Department</Label>
                <FormikToggle
                  name="isDepartmentSelector"
                  label="Exclusive"
                  inlineLabel={true}
                />
              </Stack>
              <FormikDropdown
                placeholder="Select"
                options={departmentOptions}
                name="defaultDepartmentId"
                onChange={(_event, SelectedOption) => {
                  setComponentFieldValue(
                    'defaultDepartmentId',
                    SelectedOption?.key
                  );
                }}
              />
            </Stack>
            {dataExtractModelOptions.length > 0 && (
              <Stack className={styles.width50} tokens={{ childrenGap: 5 }}>
                <Label>Data Extraction Template</Label>
                <FormikDropdown
                  placeholder="Select"
                  options={dataExtractModelOptions}
                  name="extractionTypeId"
                  onChange={(_event, SelectedOption) => {
                    setFieldValue('extractionTypeId', SelectedOption?.key);
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
