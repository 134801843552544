import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  paymentListsContainer: {
    margin: '20px -20px 0px -20px',
    overflowX: 'hidden',
  },
  downloadSection: {
    minWidth: 30,
  },
  amountTotal: {
    fontWeight: 'bold',
    color: theme.palette.themePrimary,
  },
  footerwrapper: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  column:{
    fontWeight: FontWeights.bold,
    flex: 1, 
    textAlignLast: 'end'
  },
  wideColoumn:{
    fontWeight: FontWeights.bold,
    flex: 1, 
  }
}));
