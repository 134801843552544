import {
  AddressRowValues,
  BusinessUnitValues,
  CurrencyRowValues,
} from './types';

export const ADDRESS_INITIAL_VALUES: AddressRowValues = {
  id: null,
  _rowTimestamp: null,
  addressLine1: null,
  addressLine2: null,
  cityName: null,
  countryId: null,
  stateRegionId: null,
  postalCode: null,
  telephoneNumber: null,
  mainContact:null,
  emailAddress: null,
  isDeliveryAddress: null,
  isPrimaryAddress: null,
};

export const CURRENCY_INITIAL_VALUES: CurrencyRowValues = {
  id: null,
  _rowTimestamp: null,
  currencyId: null,
  isPrimary: false,
  _isUpdatable: true,
  _isDeletable: true,
};

export const BUSINESS_UNIT_VALUES: BusinessUnitValues = {
  name: null,
  emailProductionOfficeDescription: null,
  emailReplyName: null,
  emailReplyTo: null,
  chartOfAccountId: null,
  _isProductionBusinessUnit: null,
  companyAddressesByCompanyIdAndEntityId: [
    { ...ADDRESS_INITIAL_VALUES, isPrimaryAddress: true },
  ],
  companyBusinessUnitCurrencies: [{ ...CURRENCY_INITIAL_VALUES }],
};
