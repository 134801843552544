import { IconButton, Stack, Text, TooltipHost } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React, { ReactNode, useState } from 'react';
import { ModalWrapper } from '../ModalWrapper';

/**
 * This component has been developed to Allow a common Modal to administer the PDF Viewer.
 * While passing renderPDF make sure to wrap the PDFViewer with flex:1
 */
interface PdfViewModalProps {
  visible: boolean;
  children: ReactNode;
  toolTipContent?: string;
}

export const PdfViewModal: React.FC<PdfViewModalProps> = ({
  visible,
  children,
  toolTipContent = 'Print Transaction',
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const closeModal = () => setModalVisible(false);
  const openModal = () => setModalVisible(true);
  const commonStyles = useCommonStyles();
  if (visible)
    return (
      <Stack>
        {visible && (
          <TooltipHost content={toolTipContent}>
            <IconButton iconProps={{ iconName: 'print' }} onClick={openModal} />
          </TooltipHost>
        )}

        {modalVisible && (
          <ModalWrapper isOpen={modalVisible} isBlocking>
            <Stack
              tokens={{ padding: 10 }}
              horizontal
              horizontalAlign="space-between"
            >
              <Text
                className={clsx(
                  commonStyles.bold,
                  commonStyles.colorThemePrimary
                )}
              >
                PDF Export
              </Text>
              <IconButton
                iconProps={{ iconName: 'cancel' }}
                onClick={closeModal}
              />
            </Stack>
            <Stack styles={{ root: { width: 1200, height: '90vh' } }}>
              {children}
            </Stack>
          </ModalWrapper>
        )}
      </Stack>
    );
  else return null;
};
