import { FontSizes, makeStyles } from "@fluentui/react";
export const useStyles = makeStyles((theme) => ({
  defaultButtonContainer: {
    marginBottom: 10
  },
  root: {
    height: 60,
    justifyContent: 'space-between',
  },
  addressTypeLabel: {
    color: theme.palette.neutralDark,
    fontSize: FontSizes.size14,
  },
  rowCell: {
    padding: "20px 0px",
  },
  addressSubtitle: {
    color: theme.palette.neutralSecondary,
  },
  setPrimaryButton: {
    visibility: 'hidden',
  },
  deleteIcon: {
    color: theme.palette.accent
  },
  primaryLabel: {
    fontWeight: 'bold',
    color: theme.palette.accent
  },
  AddressListItem: {
    ':hover .setPrimaryButtonGlobal': {
      visibility: 'visible',
    },
  }
}));