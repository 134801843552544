import { makeStyles } from '@fluentui/react';
const GREEN_600 = '#b6e3c8';
const GREEN_800 = '#1ea866';
const GREEN_900 = '#228f5b';
const GREEN_600_DARK = "#506157";

export const useStyles = makeStyles((theme) => ({
  acceptButton: {
    color: 'white',
    backgroundColor: GREEN_800,
    borderColor: GREEN_800,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: GREEN_900,
      borderColor: GREEN_900,
    },
    ':active': {
      color: 'white',
      backgroundColor: GREEN_800,
      borderColor: GREEN_800,
    },
  },  
  acceptButtonDisabled: {
    backgroundColor:theme.isInverted?GREEN_600_DARK: GREEN_600,
    borderColor: theme.isInverted?GREEN_600_DARK: GREEN_600,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: GREEN_600,
      borderColor: GREEN_600,
    },
    ':active': {
      color: 'white', 
      backgroundColor: GREEN_600,
      borderColor: GREEN_600,
    },
  },
  progress:{
    marginBottom: 10
  }
}));