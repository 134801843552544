import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.neutralQuaternaryAlt,
    position: 'relative',
    '::before': {
      backgroundColor: theme.palette.accent,
      content: '""',
      width: 3,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    flexGrow: 1,
    display: 'flex',
    alignSelf: 'stretch',
  },
  linkHover: {
    ':hover': {
      backgroundColor: theme.palette.neutralQuaternaryAlt,
    },
  },
  linkLightHover: {
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
  iconContainer: {
    padding: '12px 0',
    width: 48,
  },
  compactIconContainer: {
    padding: '8px 0',
    width: 44,
  },
  largeIcon: {
    height: 22,
    width: 22,
    fontSize: 22,
  },
  smallIcon: {
    height: 16,
    width: 16,
    fontSize: 16,
    fill: theme.palette.neutralDark,
  },
  root: {
    display: 'inline-flex',
    position: 'relative',
    flexShrink: 0,
    verticalAlign: 'middle',
    height: 22,
    padding: '12px 0',
  },
  textStyles: {
    color: 'white',
  },
  badge: {
    zIndex: 1,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 10,
    height: 22,
    right: 0,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    backgroundColor: theme.palette.orangeLighter,
  },
}));
