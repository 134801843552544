import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  innerContainer: {
    marginBottom: 100,
  },
  container: {
    position: "relative",
  },
}));
