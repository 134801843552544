import { IconButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { DepartmentRowItem } from '../list';
import { DepartmentModal } from './DepartmentModal';

export interface DepartmentProps {
  department: DepartmentRowItem;
}
type DepartmentModalViewProps = Partial<DepartmentProps>;
export const DepartmentModalView: React.FC<DepartmentModalViewProps> = ({
  department,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { _isUpdatable } = { ...department };

  return (
    <Stack horizontal verticalAlign="center">
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        onClick={() => setIsOpen(true)}
        disabled={!_isUpdatable}
      />
      {isOpen && (
        <DepartmentModal department={department} onClose={() => setIsOpen(false)} />
      )}
    </Stack>
  );
};
