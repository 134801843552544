import { useMutation, useQuery } from '@apollo/client';
import { PrimaryButton, Stack, Text } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import {
  DepartmentOccupationApprovalInput,
  DepartmentOccupationChartInput,
  DepartmentOccupationTemplatesOrderBy,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { EntityType, PanelCommonProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import { differenceBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  UserTemplateDelete,
  UserTemplateDeleteVariables,
} from '../list/__generated__/UserTemplateDelete';
import { ActionsMenu } from './ActionsMenu';
import { ApprovalTypeView } from './ApprovalTypeView';
import { BasicForm } from './BasicForm';
import { DashboardCharts } from './DashboardCharts';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { CopyUserTemplate } from './UserTemplateCopy';
import { ApprovalTypes } from './__generated__/ApprovalTypes';
import {
  UserTemplate,
  UserTemplateVariables,
} from './__generated__/UserTemplate';
import {
  UserTemplateCreate,
  UserTemplateCreateVariables,
} from './__generated__/UserTemplateCreate';
import {
  UserTemplateUpdate,
  UserTemplateUpdateVariables,
} from './__generated__/UserTemplateUpdate';
import { UserTemplatevalues } from './types';
import {
  getDefaultValues,
  getUpdateUserTemplateApprovals,
  getUpdatedChart,
  getUserTemplatePatch,
} from './utils';
import { validationSchema } from './validation';
import {
  UserTemplateUserSync,
  UserTemplateUserSyncVariables,
} from './__generated__/UserTemplateUserSync';

const USER_TEMPLATE = loader('./userTemplate.graphql');
const UPDATE_USER = loader('./UserTemplateUserSync.graphql');
const USER_TEMPLATES = loader('../list/UserTemplates.graphql');
const APPROVAL_TYPES = loader('./ApprovalTypes.graphql');
const USER_TEMPLATE_CREATE = loader('./UserTemplateCreate.graphql');
const USER_TEMPLATE_UPDATE = loader('./UserTemplateUpdate.graphql');
const USER_TEMPLATE_DELETE = loader('../list/UserTemplateDelete.graphql');

export const UserTemplateViewModal: React.FC = () => {
  const { addToast } = useToasts();
  const { userId } = useParams<{ userId: string | undefined }>();
  const history = useHistory();
  const [updateUserDialogHidden, setupdateUserDialogHidden] = useState(true);
  const DIALOG_TITLE = 'Are you sure ?';
  const DIALOG_SUB_TITLE =
    'This will update all users controlled by this template with the current values listed.';
  const [deleteUserTemplate] = useMutation<
    UserTemplateDelete,
    UserTemplateDeleteVariables
  >(USER_TEMPLATE_DELETE, {
    errorPolicy: 'all',
  });
  const formMethods = useForm<UserTemplatevalues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    trigger,
  } = { ...formMethods };
  const saveDisabled = !isDirty || !isValid;
  const { data: userTemplatedata, loading: userTemplateLoading } = useQuery<
    UserTemplate,
    UserTemplateVariables
  >(USER_TEMPLATE, {
    variables: {
      id: userId!,
    },
    skip: !userId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [updateUsers, { loading: updateUsersLoading }] = useMutation<
    UserTemplateUserSync,
    UserTemplateUserSyncVariables
  >(UPDATE_USER);
  const existingApprovalTypeId = new Set(
    userTemplatedata?.departmentOccupationTemplate?.departmentOccupationApprovals?.nodes?.map(
      (item) => item.approvalTypeId
    )
  );
  const existingChartTypeIds = new Set(
    userTemplatedata?.departmentOccupationTemplate?.departmentOccupationCharts?.nodes?.map(
      (item) => item.chartTypeId
    )
  );

  const _deleteUserTemplate = async () => {
    const { errors } = await deleteUserTemplate({
      variables: {
        input: {
          entityDelete: [
            {
              id: userTemplatedata?.departmentOccupationTemplate?.id!,
              rowTimestamp:
                userTemplatedata?.departmentOccupationTemplate?._rowTimestamp!,
            },
          ],
        },
      },
      update(cache, { data: response }) {
        response?.userTemplateDelete?.entityDeletes?.forEach((item) => {
          const identity = `DepartmentOccupationTemplate:${userTemplatedata?.departmentOccupationTemplate?.id}`;
          cache.evict({ id: identity });
          cache.gc();
        });
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('User template deleted successfully', {
        appearance: 'success',
      });
      history.replace('/account-management/userTemplates');
    }
  };
  const _updateUserOnConfirm = async () => {
    setupdateUserDialogHidden(true);
    const { errors, data } = await updateUsers({
      variables: {
        input: {
          departmentOccupationTemplateId:
            userTemplatedata?.departmentOccupationTemplate?.id!,
          rowTimestamp:
            userTemplatedata?.departmentOccupationTemplate?._rowTimestamp!,
        },
      },
      awaitRefetchQueries: true,
    });
    const _totalUpdatedUsers = data?.userTemplateUserSync?._totalUpdatedUsers;
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      const userText = _totalUpdatedUsers
        ? `${_totalUpdatedUsers} users`
        : `No user`;
      addToast(`Update completed. (${userText} affected)`, {
        appearance: 'success',
      });
    }
  };
  const { _isUpdatable } = {
    ...userTemplatedata?.departmentOccupationTemplate,
  };
  const { data: approvalTypesData } = useQuery<ApprovalTypes>(APPROVAL_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [userTemplateCreate] = useMutation<
    UserTemplateCreate,
    UserTemplateCreateVariables
  >(USER_TEMPLATE_CREATE, { errorPolicy: 'all' });

  const [userTemplateUpdate] = useMutation<
    UserTemplateUpdate,
    UserTemplateUpdateVariables
  >(USER_TEMPLATE_UPDATE, { errorPolicy: 'all' });

  const isNew = !userId;
  const inputEnabled = isNew || _isUpdatable;

  const panelHeading = isNew ? 'Create User Template' : 'Edit User Template';
  useEffect(() => {
    const defaultValues = getDefaultValues(userTemplatedata);
    reset(defaultValues);
  }, [userTemplatedata, reset, trigger]);
  const { _rowTimestamp, name } = {
    ...userTemplatedata?.departmentOccupationTemplate,
  };
  const onHandleSubmit = async (values: UserTemplatevalues) => {
    let createUserTemplateApprovals: DepartmentOccupationApprovalInput[] = [];
    if (isNew) {
      if (values && values.userTemplateApprovals) {
        createUserTemplateApprovals = values?.userTemplateApprovals
          ?.filter((ele) => ele.approvalTypeId)
          .map((item) => {
            return {
              approvalTypeId: Number(item.approvalTypeId),
              isApprover: item.grants.isApprover,
              isRequester: item.grants.isRequester,
              isObserver: item.grants.isObserver,
              isEnMasseApprovalsEnabled: item.grants.isEnMasseApprovalsEnabled,
              isAutoApproveEnabled: item.grants.isAutoApproveEnabled,
              isSelfApproveEnabled: item.grants.isSelfApproveEnabled,
              isApprovalTreeViewerAllowed:
                item.grants.isApprovalTreeViewerAllowed,
              isAddingNonApproversAllowed:
                item.grants.isAddingNonApproversAllowed,
              isApprovalRevokingEnabled: item.grants.isApprovalRevokingEnabled,
              isLowerTreeLevelBypassEnabled:
                item.grants.isLowerTreeLevelBypassEnabled,
              isExtendedApprover: item.grants.isExtendedApprover,
            };
          });
        const createUserTemplateCharts =
          values?.userTemplateCharts &&
          (values?.userTemplateCharts
            ?.filter((chart) => chart.chartTypeId)
            .map(({ chartPosition, id, _rowTimestamp, ...chart }) => {
              return {
                chartPosition: Number(chartPosition),
                ...chart,
              };
            }) as DepartmentOccupationChartInput[]);
        const inputVariables = {
          userTemplate: {
            description: values.description,
            departmentId: values.departmentId,
            userOccupationTitleId: values.userOccupationTitleId,
            roleId: values.roleId,
            userRankTypeId: values.userRankTypeId,
            dataAccessPolicyId: values.dataAccessPolicyId,
            tagGroupId: values.tagGroupId,
            communicationGroupId: values.communicationGroupId,
            userGroupId: values.userGroupId,
            accessGroupId: values.accessGroupId,
            departmentGroupId: values.departmentGroupId,
            name: values.name!,
            isTemplateControlled: values.isTemplateControlled,
            secureRowLevelId: values.secureRowLevelId,
            isDepartmentUserGroupRequired: values.isDepartmentUserGroupRequired,
            isUserDepartmentDefault: values.isUserDepartmentDefault,
            isUserPrimaryCurrencyDefault: values.isUserPrimaryCurrencyDefault,
            isUserPrimaryBusinessUnitDefault:
              values.isUserPrimaryBusinessUnitDefault,
            userDefaultCompany: values.userDefaultCompany,
            userDefaultLocation: values.userDefaultLocation,
          },
          userTemplateApprovals: createUserTemplateApprovals,
          userTemplateCharts: createUserTemplateCharts,
        };
        const { data, errors } = await userTemplateCreate({
          variables: {
            input: inputVariables,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: USER_TEMPLATES,
              variables: {
                orderBy: DepartmentOccupationTemplatesOrderBy.NAME_ASC,
              },
            },
          ],
        });
        if (errors?.length) {
          addToast(errors[0].message, {
            appearance: 'error',
          });
        } else {
          addToast('User template created successfully', {
            appearance: 'success',
          });
          if (data?.userTemplateCreate?.departmentOccupationTemplate?.id) {
            history.replace(
              `/account-management/userTemplates/userTemplate/${data?.userTemplateCreate?.departmentOccupationTemplate?.id}`
            );
          }
        }
      }
    } else {
      values.userTemplateApprovals?.map((item, index) => {
        if (index !== values.userTemplateApprovals?.length! - 1) {
          if (!item.id) {
            const approvalItem = {
              approvalTypeId: Number(item.approvalTypeId),
              isAddingNonApproversAllowed:
                item.grants.isAddingNonApproversAllowed,
              isApprovalRevokingEnabled: item.grants.isApprovalRevokingEnabled,
              isApprovalTreeViewerAllowed:
                item.grants.isApprovalTreeViewerAllowed,
              isApprover: item.grants.isApprover,
              isAutoApproveEnabled: item.grants.isAutoApproveEnabled,
              isEnMasseApprovalsEnabled: item.grants.isEnMasseApprovalsEnabled,
              isExtendedApprover: item.grants.isExtendedApprover,
              isLowerTreeLevelBypassEnabled:
                item.grants.isLowerTreeLevelBypassEnabled,
              isObserver: item.grants.isObserver,
              isRequester: item.grants.isRequester,
              isSelfApproveEnabled: item.grants.isSelfApproveEnabled,
            };
            createUserTemplateApprovals.push(approvalItem);
          }
        }
      });
      const updatedUserTemplateApprovals = getUpdateUserTemplateApprovals(
        values?.userTemplateApprovals,
        userTemplatedata?.departmentOccupationTemplate
          ?.departmentOccupationApprovals?.nodes
      );

      const newCharts =
        values?.userTemplateCharts &&
        (values?.userTemplateCharts
          ?.filter((chart) => !chart.id && chart.chartTypeId !== null)
          .map(({ id, _rowTimestamp, chartPosition, ...chart }) => {
            return {
              chartPosition: Number(chartPosition),
              ...chart,
            };
          }) as DepartmentOccupationChartInput[]);

      const updatedChart = getUpdatedChart(
        values?.userTemplateCharts,
        userTemplatedata?.departmentOccupationTemplate
          ?.departmentOccupationCharts?.nodes
      );

      const deletedChartRows = differenceBy(
        userTemplatedata?.departmentOccupationTemplate
          ?.departmentOccupationCharts?.nodes,
        values?.userTemplateCharts || [],
        'id'
      ).map((chart) => ({
        id: chart.id!,
        rowTimestamp: chart._rowTimestamp!,
      })) as EntityDeleteInput[];

      const deletedApprovalRows = differenceBy(
        userTemplatedata?.departmentOccupationTemplate
          ?.departmentOccupationApprovals?.nodes,
        values?.userTemplateApprovals || [],
        'id'
      ).map((approval) => ({
        id: approval.id!,
        rowTimestamp: approval._rowTimestamp!,
      })) as EntityDeleteInput[];

      const updatedUserTemplatePatch = getUserTemplatePatch(
        values,
        userTemplatedata?.departmentOccupationTemplate
      );

      const { errors } = await userTemplateUpdate({
        variables: {
          input: {
            id: userTemplatedata?.departmentOccupationTemplate?.id!,
            rowTimestamp:
              userTemplatedata?.departmentOccupationTemplate?._rowTimestamp!,
            userTemplatePatch: updatedUserTemplatePatch,
            userTemplateApprovalCreate: createUserTemplateApprovals,
            userTemplateApprovalUpdate: updatedUserTemplateApprovals,
            userTemplateApprovalDelete: deletedApprovalRows,
            userTemplateChartCreate: newCharts,
            userTemplateChartUpdate: updatedChart,
            userTemplateChartDelete: deletedChartRows,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: USER_TEMPLATE,
            variables: {
              id: userId!,
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('User template edited successfully', {
          appearance: 'success',
        });
      }
    }
  };
  const _onRenderHeader = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/account-management/userTemplates');
        }}
      >
        <Stack
          horizontal
          grow
          verticalAlign="center"
          tokens={{ childrenGap: 20 }}
        >
          <Text variant="xLarge">{panelHeading}</Text>
          <UnsavedIndicator visible={!isNew && isDirty} />
        </Stack>
        {!isNew && (
          <Stack horizontal verticalAlign="center">
            <ActionMessageModal
              entityType={EntityType.UserTemplate}
              disabled={
                !userTemplatedata?.departmentOccupationTemplate?._isDeletable
              }
              onConfirm={() => {
                _deleteUserTemplate();
              }}
            />
            <CopyUserTemplate
              id={userId!}
              rowTimestamp={_rowTimestamp}
              name={name!}
            />
          </Stack>
        )}
      </PanelHeader>
    );
  };
  const _onRenderFooter = () => {
    return (
      <FooterActionBar
        disabled={
          userTemplateLoading || {
            save: saveDisabled,
          }
        }
        children={
          !isNew &&
          userTemplatedata?.departmentOccupationTemplate?._isSyncAllowed && (
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                text="Update Users"
                disabled={updateUsersLoading}
                onClick={() => setupdateUserDialogHidden(false)}
              />
            </Stack>
          )
        }
        onCancel={() => {
          history.replace('/account-management/userTemplates');
        }}
        onSave={handleSubmit(onHandleSubmit)}
        hideCreateButton={true}
      />
    );
  };

  return (
    <DraggablePanel
      {...PanelCommonProps}
      initialWidth={1200}
      minWidth={1200}
      isBlocking={false}
      onRenderHeader={_onRenderHeader}
      onRenderFooter={_onRenderFooter}
      isOpen={true}
      isLightDismiss
    >
      {userTemplateLoading ? (
        <ShimmerView />
      ) : (
        <Stack>
          <FormProvider {...formMethods}>
            {!isNew && (
              <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
                <Stack tokens={{ childrenGap: 10, padding: '0 25px' }}>
                  <Stack>
                    <ActionsMenu userTemplatedata={userTemplatedata} />
                  </Stack>
                </Stack>
              </Stack>
            )}
            <BasicForm isUpdatable={!!inputEnabled} />
            <ApprovalTypeView
              approvalTypes={approvalTypesData?.approvalTypes?.nodes || []}
              isUpdatable={!!inputEnabled}
              existingApprovalTypeId={existingApprovalTypeId}
            />
            <DashboardCharts
              isUpdatable={!!inputEnabled}
              existingChartTypeIds={existingChartTypeIds}
            />
            <ConfirmDialog
              hidden={updateUserDialogHidden}
              title={DIALOG_TITLE}
              subText={DIALOG_SUB_TITLE}
              onDismiss={() => setupdateUserDialogHidden(true)}
              onConfirm={_updateUserOnConfirm}
            />
          </FormProvider>
        </Stack>
      )}
    </DraggablePanel>
  );
};
