import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: 80,
    padding: '0px 20px',
  },
  fieldContainer: {
    flex: 1,
  },
}));
