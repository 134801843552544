import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  hoverLinkStyle: {
    color: '#157ADD',
    fontWeight: 'bold',
    fontSize: FontSizes.size14,
  },
  plainCardContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '300px',
  },
  progressContainer: {
    width: '100%',
    height: 10,
    backgroundColor: theme.palette.white,
  },

  personaContainer: {
    width: '40px',
    marginLeft: 10,
  },
  recipient: { width: 100, fontWeight: 'bold', fontSize: FontSizes.size14 },
  recievedDate: { fontSize: FontSizes.size12 },
  colorPrimary: {
    color: theme.palette.themePrimary,
  },
  messageResponseContainer: {
    width: '300px',
    padding: '0px 10px',
  },
  separatorContainer: {
    justifyContent: 'center',
    width: '25px',
    marginLeft: 20,
    marginRight: '5px',
  },
  justifyHoverCard: {
    justifyContent: 'center',
    width: '600px',
  },
}));
