import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  rowLink: {
    fontSize: theme.fonts.medium.fontSize,
    color: theme.semanticColors.bodyText,
    ':focus': {
      color: theme.semanticColors.bodyText,
    },
  },
  statusText: {
    color: theme.palette.accent,
  },
  filterListChipsContainer: {
    padding: 10,
    marginRight: 15,
    marginLeft: 15,
  },
  searchFilterContainer: {
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  activeRowLink: {
    color: theme.palette.accent,
    ':focus': {
      color: theme.palette.accent,
    },
  },
  stackStyle: { paddingTop: 0, paddingBottom: 0 },
  callout: {
    minWidth: 150,
    maxWidth: 150,
  },
  statusIconStyle: {
    fontSize: FontSizes.size14,
    color: theme.palette.themePrimary,
    marginRight: 10,
  },
  iconStyle: {
    fontSize: FontSizes.size18,
    color: theme.palette.themePrimary,
    marginLeft: 10,
  },

  searchContainer: {
    width: '100%',
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  footerRow: {
    backgroundColor: theme.palette.neutralLighter,
  },
  amountStack: {
    fontWeight: 'bold',
  },
  onrenderColumnStack: {
    height: 25,
  },
  rowBaseStyle: { borderLeft: `5px solid transparent` },
  container: { maxWidth: 300, minWidth: 200 },
  containerMain: { padding: 0, margin: 0 },
  title: { color: theme.palette.white },
  urgentHeader: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '5px 10px',
    backgroundColor: `${theme.palette.redDark}`,
  },
}));
