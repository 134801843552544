import { NetworkStatus, useQuery } from '@apollo/client';
import {
  SelectionMode,
  Stack,
  Text
} from '@fluentui/react';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData, SearchBar } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { CompanyBusinessUnitsOrderBy } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CompanyBusinessUnits,
  CompanyBusinessUnitsVariables,
  CompanyBusinessUnits_companyBusinessUnits_nodes,
} from './__generated__/CompanyBusinessUnits';
import { getColumns } from './column.data';
import { ColumnStylesProps, onRenderItems } from './columns.render';
import { useStyles } from './index.styles';
import { onHandleSearch } from './utils';

const BUSINESS_UNIT_LIST = loader('./CompanyBusinessUnits.graphql');

export type BusinessUnitRow = CompanyBusinessUnits_companyBusinessUnits_nodes & {
  chartOfAccount: string;
};

export const BusinessUnitsList: React.FC = () => {
  const commonStyles = useCommonStyles();
  const renderRef = useRef(false);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const styles = useStyles();
  const columnStyles: ColumnStylesProps = {
    onRenderColumnStack: styles.onRenderColumnStack,
    businessUnitCol: styles.businessUnitCol,
  };

  const {
    loading: companyBusinessUnitsLoading,
    data: companyBusinessUnitsListData,
    fetchMore,
    networkStatus,
    refetch,
  } = useQuery<CompanyBusinessUnits, CompanyBusinessUnitsVariables>(
    BUSINESS_UNIT_LIST,
    {
      variables: {
        first: TABLE_ROWS,
        orderBy: [CompanyBusinessUnitsOrderBy.NAME_ASC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const handleSearch = async (showMore: boolean) => {
    const { queryVariables } = onHandleSearch(showMore, companyBusinessUnitsListData, sortOrderParam);
    if (showMore) await fetchMore?.({ variables: queryVariables });
    else refetch(queryVariables);
  };

  const handleSearchMemo = useCallback(handleSearch, [sortOrderParam]);

  useEffect(() => {
    if (renderRef.current) {
      handleSearchMemo(false);
    } else renderRef.current = true;
  }, [handleSearchMemo,]);

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const {
    nodes
  } = { ...companyBusinessUnitsListData?.companyBusinessUnits }

  const transformedData =
    refetching ? undefined : nodes?.map((row) => ({
      ...row,
      chartOfAccount: row.companyChartOfAccount?.name || []
    } as BusinessUnitRow)) || []

  const _renderItemColumn = useCallback(
    onRenderItems(
      columnStyles
    ),
    [columnStyles]
  );

  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);

  useEffect(() => {
    setCustomColumns()
  }, [setCustomColumns])

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Business Units</Text>
        </Stack>

        <SearchBar
          columns={[]}
          searchEnabled={false}
          onRefresh={() => handleSearch(false)}
        />
      </Stack>
      <InfiniteList
        loading={companyBusinessUnitsLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={
          companyBusinessUnitsListData?.companyBusinessUnits?.pageInfo
            .hasNextPage
        }
        items={transformedData}
        selectionMode={SelectionMode.none}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true)}
      />
    </>
  );
};
