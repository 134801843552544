import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  loginLabel: {
    paddingTop: 8,
  },
}));
