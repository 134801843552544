import { FontWeights, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  rowContainer: {
    height: 25,
  },
  statusType: {
    display: 'block',
    width: 110,
  },
  footerTotal: {
    display: 'block',
    textAlign: 'right',
    fontWeight: FontWeights.bold,
  },
}));
