import { Label, Stack } from '@fluentui/react';
import React from 'react';
import { ColumnData } from './columns.data';

export const ColumnsHeader: React.FC<{
  columnArray: ColumnData[];
}> = ({ columnArray }) => {
  const widthTotal =
    columnArray.reduce((prev: any, curr: any) => curr.maxWidth! + prev, 0) +
    columnArray.length * 10;

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 10 }}
      style={{ width: widthTotal }}
    >
      {columnArray.map((value, key) => {
        switch (value.key) {
          case 'delete':
            return null;
          default:
            return (
              <Stack
                style={{
                  height: 50,
                  width: value.maxWidth,
                }}
                key={key}
                horizontalAlign="center"
                verticalAlign="center"
              >
                <Label key={key}>{value.name}</Label>
              </Stack>
            );
        }
      })}
    </Stack>
  );
};
