import {
  ActionButton, Stack, TooltipHost
} from '@fluentui/react';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import React, {
  useState
} from 'react';
import { useFormContext } from 'react-hook-form';
import { AttachFormModal } from './AttachFormModal';
import { PurchaseOrder_purchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { PurchaseOrderAvailableDocumentTypes } from '../__generated__/PurchaseOrderAvailableDocumentTypes';
export interface AttachFormProps {
  availableDocumentTypes: PurchaseOrderAvailableDocumentTypes | undefined;
  purchaseOrder: PurchaseOrder_purchaseOrder;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  purchaseOrder,
  availableDocumentTypes
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const {
    formState: {
      isDirty
    }
  } = useFormContext<PurchaseOrderValues>();

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={isDirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          purchaseOrder={purchaseOrder}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  );
};

