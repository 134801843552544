import { IColumn } from '@fluentui/react';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { TransactionLayout } from 'common/types/globalTypes';

export type ColumnData = IColumn & {
  /** property to determine whether this coloumn can be toggled for visibility */
  toggleVisibility?: boolean;
  /** property to determine whether the column is visble or not*/
  isVisible?: boolean;
  isFormField?: boolean;
  viewWidth?: number;
  isTransactionOnly?: boolean;
};

const columns = new Map<string, ColumnData>([
  [
    'referenceCode6',
    {
      key: 'referenceCode6',
      name: 'CO',
      fieldName: 'referenceCode6',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],

  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],
  [
    'accountReference',
    {
      key: 'accountReference',
      name: 'Account',
      fieldName: 'accountReference',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 100,
    },
  ],

  [
    'projectReference',
    {
      key: 'projectReference',
      name: 'Project',
      fieldName: 'projectReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'additionalDescription',
    {
      key: 'additionalDescription',
      name: 'Item Description',
      fieldName: 'additionalDescription',
      minWidth: 230,
      maxWidth: 230,
      viewWidth: 230,
    },
  ],
  [
    'distributionAmount',
    {
      key: 'distributionAmount',
      name: 'Amount',
      fieldName: 'distributionAmount',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 110,
    },
  ],
  [
    'purchaseOrderReference',
    {
      key: 'purchaseOrderReference',
      name: 'PO Reference',
      fieldName: 'purchaseOrderReference',
      minWidth: 130,
      maxWidth: 130,
      viewWidth: 110,
      isTransactionOnly: true,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'productionIncentive',
    {
      key: 'productionIncentive',
      name: 'Production Incentives',
      fieldName: 'productionIncentive',
      minWidth: 180,
      maxWidth: 180,
      viewWidth: 200,
    },
  ],
  [
    'tax1099t4Type',
    {
      key: 'tax1099t4Type',
      name: '1099/T4',
      fieldName: 'tax1099t4Type',
      minWidth: 160,
      maxWidth: 160,
      viewWidth: 190,
    },
  ],
  [
    'department',
    {
      key: 'department',
      name: 'Additional Department',
      fieldName: 'department',
      minWidth: 190,
      maxWidth: 200,
      viewWidth: 210,
    },
  ],
  [
    'businessUnit',
    {
      key: 'businessUnit',
      name: 'Additional Business Unit',
      fieldName: 'businessUnit',
      minWidth: 220,
      maxWidth: 220,
      viewWidth: 240,
    },
  ],
]);

const columnsGL = new Map<string, ColumnData>([
  [
    'referenceCode6',
    {
      key: 'referenceCode6',
      name: 'CO',
      fieldName: 'referenceCode6',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],

  [
    'referenceCode7',
    {
      key: 'referenceCode7',
      name: 'LOC',
      fieldName: 'referenceCode7',
      minWidth: 50,
      maxWidth: 50,
      viewWidth: 100,
    },
  ],

  [
    'projectReference',
    {
      key: 'projectReference',
      name: 'Project',
      fieldName: 'projectReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'accountReference',
    {
      key: 'accountReference',
      name: 'Account',
      fieldName: 'accountReference',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 100,
    },
  ],

  [
    'setReference',
    {
      key: 'setReference',
      name: 'Set',
      fieldName: 'setReference',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 80,
    },
  ],
  [
    'referenceCode1',
    {
      key: 'referenceCode1',
      name: 'FF1',
      fieldName: 'referenceCode1',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode2',
    {
      key: 'referenceCode2',
      name: 'FF2',
      fieldName: 'referenceCode2',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode3',
    {
      key: 'referenceCode3',
      name: 'FF3',
      fieldName: 'referenceCode3',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode4',
    {
      key: 'referenceCode4',
      name: 'FF4',
      fieldName: 'referenceCode4',
      minWidth: 90,
      maxWidth: 90,
      viewWidth: 100,
    },
  ],
  [
    'referenceCode5',
    {
      key: 'referenceCode5',
      name: 'Ins',
      fieldName: 'referenceCode5',
      minWidth: 70,
      maxWidth: 70,
      viewWidth: 100,
    },
  ],
  [
    'distributionAmount',
    {
      key: 'distributionAmount',
      name: 'Amount',
      fieldName: 'distributionAmount',
      minWidth: 110,
      maxWidth: 110,
      viewWidth: 110,
    },
  ],
  [
    'purchaseOrderReference',
    {
      key: 'purchaseOrderReference',
      name: 'PO Reference',
      fieldName: 'purchaseOrderReference',
      minWidth: 130,
      maxWidth: 130,
      viewWidth: 110,
      isTransactionOnly: true,
    },
  ],
  [
    'additionalDescription',
    {
      key: 'additionalDescription',
      name: 'Item Description',
      fieldName: 'additionalDescription',
      minWidth: 230,
      maxWidth: 230,
      viewWidth: 230,
    },
  ],
  [
    'productionIncentive',
    {
      key: 'productionIncentive',
      name: 'Production Incentives',
      fieldName: 'productionIncentive',
      minWidth: 180,
      maxWidth: 180,
      viewWidth: 200,
    },
  ],
  [
    'tax1099t4Type',
    {
      key: 'tax1099t4Type',
      name: '1099/T4',
      fieldName: 'tax1099t4Type',
      minWidth: 160,
      maxWidth: 160,
      viewWidth: 190,
    },
  ],
  [
    'department',
    {
      key: 'department',
      name: 'Additional Department',
      fieldName: 'department',
      minWidth: 190,
      maxWidth: 200,
      viewWidth: 210,
    },
  ],
  [
    'businessUnit',
    {
      key: 'businessUnit',
      name: 'Additional Business Unit',
      fieldName: 'businessUnit',
      minWidth: 220,
      maxWidth: 220,
      viewWidth: 240,
    },
  ],
]);

export const getColumns = (
  data: UserDefaults_userDefaults_nodes | undefined
) => {
  const { layoutType } = {
    ...data?.distributionLayoutType,
  };
  if (layoutType === TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT) {
    columns.delete('projectReference');
    const columnArray = Array.from(columns, (data) => data[1]);
    return { columnArray };
  } else if (
    layoutType === TransactionLayout.GL_LOC_EPISODE_DISTRIBUTION_LAYOUT
  ) {
    columnsGL.delete('referenceCode6');
    const existingData = columnsGL.get('projectReference');
    columnsGL.set('projectReference', {
      ...existingData,
      name: 'Episode',
    } as ColumnData);
    const columnArray = Array.from(columnsGL, (data) => data[1]);
    return { columnArray };
  } else if (layoutType === TransactionLayout.GL_LOC_DISTRIBUTION_LAYOUT) {
    columnsGL.delete('referenceCode6');
    columnsGL.delete('projectReference');
    const columnArray = Array.from(columnsGL, (data) => data[1]);
    return { columnArray };
  } else if (layoutType === TransactionLayout.EPISODIC_DISTRIBUTION_LAYOUT) {
    const existingData = columns.get('projectReference');
    columns.set('projectReference', {
      ...existingData,
      name: 'Episode',
    } as ColumnData);
    const columnArray = Array.from(columns, (data) => data[1]);
    return { columnArray };
  } else if (
    layoutType === TransactionLayout.GL_CO_LOC_EPISODE_DISTRIBUTION_LAYOUT
  ) {
    const existingData = columnsGL.get('projectReference');
    columnsGL.set('projectReference', {
      ...existingData,
      name: 'Episode',
    } as ColumnData);
    const columnArray = Array.from(columnsGL, (data) => data[1]);
    return { columnArray };
  } else if (layoutType === TransactionLayout.GL_CO_LOC_DISTRIBUTION_LAYOUT) {
    columnsGL.delete('projectReference');
    const columnArray = Array.from(columnsGL, (data) => data[1]);
    return { columnArray };
  } else {
    columns.delete('referenceCode6');
    columns.delete('referenceCode7');
    const columnArray = Array.from(columns, (data) => data[1]);
    return { columnArray };
  }
};
