import { useMutation } from '@apollo/client';
import {
  NotesSection,
  NotesSectionProps
} from 'common/components/NotesSection';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import { loader } from 'graphql.macro';
import React from 'react';
import { NoteCardAccountCreate, NoteCardAccountCreateVariables } from './__generated__/NoteCardAccountCreate';



const NOTE_CARD_ACCOUNT_CREATE = loader('./NoteCardAccountCreate.graphql');

type CardAccountNotesSecionProps = Pick<
  NotesSectionProps,
  'listRefreshRequest'
> & {
  id: string;
  data: NotesEntityFields[];
  loading?: boolean;
};
export const CardAccountNotesSection: React.FC<CardAccountNotesSecionProps> = ({
  id,
  listRefreshRequest,
  data,
  loading,
}) => {
  const [createNote, { loading: createNoteInProgress, error }] = useMutation<
    NoteCardAccountCreate,
    NoteCardAccountCreateVariables
  >(NOTE_CARD_ACCOUNT_CREATE);

  const noteAddMutation: NoteAddMutationProps = {
    loading: createNoteInProgress,
    createNote: async (formData) => {
      const { noteComment, isShared } = { ...formData };
      await createNote({
        variables: {
          input: {
            note: {
              entityId: id,
              noteComment: noteComment!,
              isShared: isShared!,
            },
          },
        },
      });
      if (!error) listRefreshRequest?.();
      return error;
    },
  };
  return (
    <NotesSection
      dataLoading={loading!}
      noteAddMutation={noteAddMutation}
      addingNoteInProgress={createNoteInProgress}
      listRefreshRequest={listRefreshRequest}
      data={data}
    />
  );
};
