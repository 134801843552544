import React from 'react';
import { ProgressIndicator, Stack, Text } from "@fluentui/react";
import { useCommonStyles } from "common/styles";
import clsx from 'clsx';
import { useStyles } from './index.styles';

const LOADING_TEXT = 'Loading hierarchy';
export const LoadingHierarchy = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      className={styles.loaderContainer}
    >
      <Stack className={styles.loadingInnerContainer}>
        <Text className={clsx(commonStyles.semibold, styles.loadingText)}>
          {LOADING_TEXT}
        </Text>
        <ProgressIndicator />
      </Stack>
    </Stack>
  );
};