import { CardAccountValues, CardHolderValues } from './types';
export const CARD_ACCOUNT_INITIAL_VALUES: CardAccountValues = {
  id: null,
  _rowTimestamp: null,
  name: null,
  businessUnitId: null,
  documentPoolId: null,
  description: null,
  entityTagsByEntityId: null,
  cardAccountHistoriesByEntityId: null,
  cardAccountTransactions: null,
  notesByEntityId: null,
};
export const CARD_HOLDER_INITIAL_VALUES: CardHolderValues = {
  id: null,
  cardAccountId: null,
  cardFullName: null,
  cardNumber: null,
  departmentId: null,
  documentPoolId: null,
  tenantUserId: null,
  userGroupId: null,
  _rowTimestamp: null,
  cardStatus: null,
  cardFirstName: null,
  cardLastName: null,
  cardExpiry: null,
  departments: null,
  documentPools: null,
  entityTagsByEntityId: null,
  cardHolderHistoriesByEntityId: null,
  cardHolderTransactions: null,
  notesByEntityId: null,
};
