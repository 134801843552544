import { makeStyles } from '@fluentui/react';

const RED_600 = '#E8C5C5';
const RED_700 = '#D32F2F';
const RED_800 = '#C62828';
const RED_900 = '#B71C1C';
const GREEN_600 = '#b6e3c8';
const GREEN_800 = '#1ea866';
const GREEN_900 = '#228f5b';
const GREEN_600_DARK = "#506157";
const RED_600_DARK = '#635151';

export const useStyles = makeStyles((theme) => ({
  acceptButton: {
    color: 'white',
    backgroundColor: GREEN_800,
    borderColor: GREEN_800,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: GREEN_900,
      borderColor: GREEN_900,
    },
    ':active': {
      color: 'white',
      backgroundColor: GREEN_800,
      borderColor: GREEN_800,
    },
  },  
  acceptButtonDisabled: {
    backgroundColor:theme.isInverted?GREEN_600_DARK: GREEN_600,
    borderColor: theme.isInverted?GREEN_600_DARK: GREEN_600,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: GREEN_600,
      borderColor: GREEN_600,
    },
    ':active': {
      color: 'white', 
      backgroundColor: GREEN_600,
      borderColor: GREEN_600,
    },
  },
  declineButton: {
    color: 'white',
    backgroundColor: RED_700,
    borderColor: RED_700,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: RED_800,
      borderColor: RED_800,
    },
    ':active': {
      color: 'white',
      backgroundColor: RED_900,
      borderColor: RED_900,
    },
  },
  declineButtonDisabled: {
    backgroundColor:theme.isInverted?RED_600_DARK: RED_600,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.isInverted?RED_600_DARK: RED_600,
    ':hover': {
      color: 'white',
      backgroundColor: RED_600,
      borderColor: RED_600,
    },
    ':active': {
      color: 'white',
      backgroundColor: RED_600,
      borderColor: RED_600,
    },
  },
  progress:{
    marginBottom: 10
  }
}));