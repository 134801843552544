import { DepotsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: DepotsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'description',
    {
      [OrderDirection.ASC]: [DepotsOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [DepotsOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'stateRegion',
    {
      [OrderDirection.ASC]: [DepotsOrderBy.STATE_REGION_ASC],
      [OrderDirection.DESC]: [DepotsOrderBy.STATE_REGION_DESC],
    },
  ],
  [
    'cityArea',
    {
      [OrderDirection.ASC]: [DepotsOrderBy.CITY_AREA_ASC],
      [OrderDirection.DESC]: [DepotsOrderBy.CITY_AREA_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    DepotsOrderBy.STATE_REGION_ASC,
    DepotsOrderBy.CITY_AREA_ASC,
    DepotsOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];

  return [...(orderBy ? orderBy : []), DepotsOrderBy.PRIMARY_KEY_ASC];
};
