import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { Traveler_traveler } from '../../__generated__/Traveler';
import { AliasModal } from './AliasModal';
import { TravelerCreate_travelerCreate_traveler } from 'common/graphql/__generated__/TravelerCreate';

type TravelerType = Pick<
  Traveler_traveler,
  'id' | '_isAliasOriginalNameViewable' | '_isAliasInvoked' | '_isUpdatable'
>;

interface TravelerAliasProps {
  traveler: TravelerType | null | undefined;
  isTravelAuthMutation?: boolean | null;
  isProtected?: boolean;
  onAliasUpdate?: (param: TravelerCreate_travelerCreate_traveler) => void;
}
export const TravelerAlias: React.FC<TravelerAliasProps> = ({
  traveler,
  isTravelAuthMutation,
  isProtected = false,
  onAliasUpdate,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { _isAliasOriginalNameViewable, _isAliasInvoked, id, _isUpdatable } = {
    ...traveler,
  };
  const isAliasDisabled = !_isAliasOriginalNameViewable;
  const showAddButton =
    !!_isUpdatable &&
    !isProtected &&
    !!_isAliasOriginalNameViewable &&
    !_isAliasInvoked;

  return (
    <Stack>
      <TooltipHost
        content="Alias"
        tooltipProps={{ styles: { root: { marginBottom: -10 } } }}
      >
        {!!_isAliasInvoked && (
          <ActionButton
            onClick={() => setIsVisible(true)}
            iconProps={{
              iconName: 'Incognito',
            }}
            disabled={isAliasDisabled}
          />
        )}
        {showAddButton && (
          <ActionButton
            onClick={() => setIsVisible(true)}
            iconProps={{
              iconName: 'Add',
            }}
            text="Alias"
          />
        )}
      </TooltipHost>
      {isVisible && (
        <AliasModal
          id={id}
          onClose={() => setIsVisible(false)}
          isTravelAuthMutation={isTravelAuthMutation}
          onAliasUpdate={onAliasUpdate}
          isProtected={isProtected}
        />
      )}
    </Stack>
  );
};
