import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  container: {
    width: '1000px',
    paddingLeft: 25,
    paddingTop: 50,
  },
  subContainer: {
    width: '1000px',
    paddingLeft: 25,
  },
  listHeaderContainer: {
    padding: '25px 25px 25px 0px',
    overflowY: 'scroll',
  },
  listTitleContainer: {
    position: 'absolute',
    top: 0,
    paddingTop: 20,
    backgroundColor: theme.palette.white,
    zIndex: 10,
    width: '100%',
  },
  stickyFooter: {
    bottom: 0,
    paddingBottom: 20,
    paddingTop: 20,
    position: 'absolute',
    backgroundColor: theme.palette.white,
    zIndex: 20,
    width: '1000px',
  },
  stickyBorder: {
    borderTop: `1px solid ${theme.palette.neutralLight}`,
    position: 'absolute',
    bottom: 75,
    zIndex: -1,
    width: '100%',
    backgroundColor: theme.palette.white,
  },
}));
