import { Stack, TooltipHost } from '@fluentui/react';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { getOverageInfo } from 'common/utils';
import React from 'react';
interface OverBudgetProps {
  isAmountExist: boolean | null;
  amount: string | null;
  budgetAmount: string | null;
  globalSearchText: string;
  isOverBudget: boolean | null;
}
const OverBudget: React.FC<OverBudgetProps> = ({
  amount,
  budgetAmount,
  globalSearchText,
  isOverBudget,
  isAmountExist,
}) => {
  const toolTipContent = getOverageInfo(amount, budgetAmount);
  if (isAmountExist)
    return (
      <TooltipHost content={toolTipContent}>
        <Stack
          tokens={{ childrenGap: 10 }}
          horizontal
          horizontalAlign="end"
          verticalAlign="center"
        >
          <AmountColumnTextView
            value={amount}
            searchText={globalSearchText}
            textColor={isOverBudget ? 'red' : undefined}
          />
        </Stack>
      </TooltipHost>
    );
  else return null;
};
export default OverBudget;
