import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  historyWrapper: {
    paddingTop: 24,
    padding: "25px 25px 0px"
  },
  marginContainer: {
    marginTop: -180,
  },
  historyContent: {
    height: 200,
    overflow: 'hidden',
  },
  timeLineWrapper: {
    width: '100%',
  },
  timelineScrub: {
    width: '80%',
    paddingRight: 20,
    paddingLeft: 20,
    zIndex: 10,
  },
  timeLine: {
    width: '100%',
    height: 1,
    marginTop: -180,
    backgroundColor: theme.palette.neutralTertiaryAlt,
  },
  activityBoxWrapper: {
    width: '80%',
    flex: 1,
    paddingRight: 20,
    paddingLeft: 20,
  },
  activityBox: {
    width: '25%',
  },
  maxWidth150: {
    maxWidth: 150,
  },
  activityDate: {
    textAlign: 'center',
    color: theme.palette.themePrimary,
  },
  activityTime: {
    textAlign: 'center',
    color: theme.palette.neutralSecondary,
  },
  activityIconWrapper: {
    marginTop: '20px !important',
    width: 50,
    height: 50,
    backgroundColor: theme.palette.neutralLight,
  },
  activityIcon: {
    color: theme.palette.black,
  },
  height20: {
    height: 20,
  },
  textAlignCenter: {
    marginTop: '20px !important',
    textAlign: 'center',
  },
  stopPoint: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.themePrimary,
  },
}));
