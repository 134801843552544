import {
  Callout,
  Checkbox,
  DefaultButton,
  Icon,
  IconButton,
  ITooltipHostStyles,
  Label,
  PrimaryButton,
  Stack,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
  UserApprovalSearch_userApprovalSearch_nodes,
  UserApprovalSearch_userApprovalSearch_nodes_branchStatusType,
  UserApprovalSearch_userApprovalSearch_nodes_iconType,
} from 'approvals/MyApprovals/__generated__/UserApprovalSearch';
import {
  ApprovalActionTypeInput,
  TransactionLayout,
} from 'common/types/globalTypes';
import { dateFormat } from 'common/utils/dateFormats';
import React, { useState } from 'react';
import { InvoiceBatchForm } from '../InvoiceBatchModal';
import { StatusIcon } from 'common/components/StatusIcon';
import { ApproveButton, DeclineButton } from './buttons';
import { useStyles } from './index.styles';
import { DocumentContainer } from 'common/components/DocumentContainer';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { getWindowDimensions } from 'common/utils/columnUtilities';

export enum ApprovalStatus {
  Approved,
  Declined,
}

export type IconStatusData =
  UserApprovalSearch_userApprovalSearch_nodes_iconType &
    UserApprovalSearch_userApprovalSearch_nodes_branchStatusType;

interface ApprovalInputGroupProps {
  approvalData?: Partial<UserApprovalSearch_userApprovalSearch_nodes>;
  onChange: (value: ApprovalActionTypeInput, remove: boolean) => void;
  selectedApproval?: ApprovalActionTypeInput;
  hideActionButtons?: boolean;
  refetchAllApproval?: boolean;
}

export const ApprovalInputGroup: React.FC<ApprovalInputGroupProps> = ({
  approvalData,
  onChange,
  selectedApproval,
  refetchAllApproval,
  hideActionButtons = false,
}) => {
  const styles = useStyles();
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block' },
  };

  const { iconType, _isUserEnMasseApprovalAllowed, _isValidating } = {
    ...approvalData,
  };
  const showApproveButtons =
    iconType?.isApprovalActionAvailable &&
    _isUserEnMasseApprovalAllowed &&
    !_isValidating;

  return (
    <Stack horizontal tokens={{ childrenGap: 4 }}>
      {showApproveButtons ? (
        <>
          {!hideActionButtons ? (
            <ApprovalButtons
              approvalData={approvalData}
              onChange={onChange}
              selectedApproval={selectedApproval}
              refetchAllApproval={refetchAllApproval}
            />
          ) : (
            <Stack style={{ width: 30 }} />
          )}
        </>
      ) : (
        <Stack horizontal>
          <StatusIcon
            approval
            iconType={approvalData?.iconType!}
            approvalData={{
              id: approvalData?.id!,
              _pendingApprovers: approvalData?._pendingApprovers,
            }}
          />
          {(approvalData?._isAccountingEntryStampedCompleted ||
            approvalData?._isTransactionCancelled) && (
            <TooltipHost
              content={
                approvalData._isAccountingEntryStampedCompleted
                  ? `Entered by ${
                      approvalData._accountingStampUserName
                    } on ${dateFormat(
                      approvalData._accountingStampDate!
                    )} Ref:${approvalData._accountingStampTransactionReference}`
                  : `Voided by ${
                      approvalData._accountingStampUserName
                    } on ${dateFormat(approvalData._accountingStampDate!)}`
              }
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <Icon iconName="More" className={styles.iconStyles} />
            </TooltipHost>
          )}
        </Stack>
      )}
    </Stack>
  );
};

interface ApprovalButtonsProps {
  approvalData?: Partial<UserApprovalSearch_userApprovalSearch_nodes>;
  onChange: (data: ApprovalActionTypeInput, remove: boolean) => void;
  selectedApproval?: ApprovalActionTypeInput;
  refetchAllApproval?: boolean;
}
export const ApprovalButtons: React.FC<ApprovalButtonsProps> = ({
  onChange,
  approvalData,
  selectedApproval,

  refetchAllApproval,
}) => {
  const [approveStatus, setApproveStatus] = useState<
    ApprovalStatus | undefined
  >();
  const [openValidationModal, setOpenValidationModal] =
    useState<boolean>(false);
  const [approvalInput, setapprovalInput] = useState<ApprovalActionTypeInput>({
    entityApprovalBranchId: approvalData?.entityApprovalBranchId!,
    entityApprovalBranchRowTimestamp:
      approvalData?.entityApprovalBranchRowTimestamp!,
    isDecline: false,
    isApproval: false,
    comments: null,
    isApproveBypassLowerLevels: null,
  });
  const { width } = getWindowDimensions();
  const paddingLeft = width ? width - 850 : 50;
  React.useEffect(() => {
    if (selectedApproval?.entityApprovalBranchId) {
      setapprovalInput(selectedApproval);
    } else {
      setapprovalInput({
        entityApprovalBranchId: approvalData?.entityApprovalBranchId!,
        entityApprovalBranchRowTimestamp:
          approvalData?.entityApprovalBranchRowTimestamp!,
        isDecline: false,
        isApproval: false,
        comments: null,
        isApproveBypassLowerLevels: null,
      });
    }
  }, [approvalData, selectedApproval]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const onApproveClick = () => {
    let tempapprovalInput = { ...approvalInput };

    tempapprovalInput.isApproval = !tempapprovalInput.isApproval;
    tempapprovalInput.isDecline = false;
    setApproveStatus(
      tempapprovalInput.isApproval ? undefined : ApprovalStatus.Approved
    );

    onChange(tempapprovalInput, !tempapprovalInput.isApproval);
    setapprovalInput(tempapprovalInput);
  };
  const document = approvalData?.entityDocumentsByEntityId?.nodes[0];

  const onValidateClick = () => {
    setOpenValidationModal(true);
    if (document)
      setTimeout(() => {
        setDocViewState({
          isOpen: true,
          title: document?.fileReference,
          entityDocumentId: document?.id,
          _fileType: document?._fileType!,
        });
      }, 500);
  };

  const onDeclineClick = () => {
    let tempapprovalInput = { ...approvalInput };
    tempapprovalInput.isApproval = false;
    tempapprovalInput.isDecline = !tempapprovalInput.isDecline;
    onChange(tempapprovalInput, !tempapprovalInput.isDecline);
    setapprovalInput(tempapprovalInput);
    setApproveStatus(
      tempapprovalInput.isDecline ? undefined : ApprovalStatus.Declined
    );
  };

  const isBatchValidation =
    TransactionLayout.BATCH_TRANSACTION_APPROVAL && approvalData?._isValidating;

  return (
    <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 4 }}>
      {document && (
        <DocumentContainer
          paddingLeft={paddingLeft}
          paddingTop={0}
          onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
          {...docViewState}
        />
      )}
      {!isBatchValidation && (
        <ApproveButton
          isApproval={!!approvalInput.isApproval}
          onClick={onApproveClick}
        />
      )}
      {isBatchValidation && (
        <PrimaryButton
          styles={{ root: { width: 120 } }}
          text="Validate"
          onClick={onValidateClick}
        />
      )}
      <DeclineButton
        isDeclined={!!approvalInput.isDecline}
        onClick={onDeclineClick}
      />
      {approvalInput.isDecline || approvalInput.isApproval ? (
        <AdditionalInput
          onInputChange={(comment, bypass) => {
            let tempapprovalInput = { ...approvalInput };
            tempapprovalInput.comments = comment;
            tempapprovalInput.isApproveBypassLowerLevels = bypass;
            onChange(tempapprovalInput, false);
            setapprovalInput(tempapprovalInput);
          }}
          approvalData={approvalData}
          status={approveStatus!}
        />
      ) : null}
      {openValidationModal && (
        <InvoiceBatchForm
          onClose={() => {
            setOpenValidationModal(false);
            setDocViewState({ isOpen: false, _fileType: 'pdf' });
          }}
          approvalData={approvalData}
          batchTransactionId={approvalData?.entityId?.toString()!}
          refetchAllApproval={refetchAllApproval}
        />
      )}
    </Stack>
  );
};

interface AdditionalInputProps {
  status: ApprovalStatus;
  approvalData?: Partial<UserApprovalSearch_userApprovalSearch_nodes>;
  onInputChange: (comment: string | null, bypass: boolean | null) => void;
}
export const AdditionalInput: React.FC<AdditionalInputProps> = ({
  status,
  approvalData,
  onInputChange,
}) => {
  const [comment, setcomment] = useState<string | undefined>('');
  const [bypass, setbypass] = useState<boolean | null>(false);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId('additional-input-callout');
  const styles = useStyles();

  return (
    <>
      <TooltipHost content="Comment">
        <IconButton
          id={buttonId}
          onClick={() => {
            toggleIsCalloutVisible();
          }}
          iconProps={{ iconName: 'MoreVertical' }}
          ariaLabel="Comment"
        />
      </TooltipHost>

      {isCalloutVisible && (
        <Callout
          className={styles.additionInputCallout}
          role="alertdialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <Stack>
              <Stack horizontal>
                <Label>Comment (optional)</Label>
              </Stack>
              <TextField
                value={comment}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    onInputChange(comment!, bypass);
                    toggleIsCalloutVisible();
                  }
                }}
                onChange={(_, newValue) => {
                  setcomment(newValue);
                }}
              />
            </Stack>

            {approvalData?.isLevelBypassingAllowed && (
              <Checkbox
                label="Bypass lower levels"
                onChange={(_, checked) => {
                  setbypass(checked!);
                  onInputChange(comment!, checked!);
                }}
              />
            )}

            {/* OPerational Button for callout management */}
            <Stack tokens={{ childrenGap: 10 }} horizontal>
              <PrimaryButton
                disabled={comment?.length === 0 || !comment}
                text={'Save'}
                onClick={() => {
                  toggleIsCalloutVisible();
                  onInputChange(comment!, bypass);
                }}
              />
              <DefaultButton
                text={'Cancel'}
                onClick={() => {
                  toggleIsCalloutVisible();
                  setcomment('');
                  setbypass(false);
                }}
              />
            </Stack>
          </Stack>
        </Callout>
      )}
    </>
  );
};
