import React from 'react';
import { Separator, Stack } from '@fluentui/react';
import { useStyles } from './index.styles';
import { NoDataView } from 'common/components/DataPlaceholders';
import { AttachForm } from './AttachForm';
import { UploadForm } from './UploadForm';
import { loader } from 'graphql.macro';
import { useApolloClient, useQuery } from '@apollo/client';
import { DocumentList } from 'common/components/DocumentList';
import {
  Traveler,
  TravelerVariables,
  Traveler_traveler,
} from '../../__generated__/Traveler';
import { secureRowLevelNode } from 'common/components/SecureRowLevel';
import { columns } from '../column.data';
import { TravelerAvailableDocumentTypes } from 'common/graphql/__generated__/TravelerAvailableDocumentTypes';
import { TravelerUploadDocument_travelerUploadDocument } from 'common/graphql/__generated__/TravelerUploadDocument';
const TRAVELER = loader('../../Traveler.graphql');
const TRAVELER_AVAILABLE_DOCUMENT_TYPES = loader(
  '../../../../../../common/graphql/TravelerAvailableDocumentTypes.graphql'
);

interface AttachProps {
  traveler: Traveler_traveler;
  onUpload?: (
    fileSelected: File,
    documentData: TravelerUploadDocument_travelerUploadDocument,
    toastId: string
  ) => void;
  secureRowLevel: secureRowLevelNode[];
}

export const Attach: React.FC<AttachProps> = ({
  traveler,
  onUpload,
  secureRowLevel,
}) => {
  const styles = useStyles();
  const client = useApolloClient();

  const { data: availableDocumentTypes } =
    useQuery<TravelerAvailableDocumentTypes>(
      TRAVELER_AVAILABLE_DOCUMENT_TYPES,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10, padding: '0px 25px 0px 0px' }}
      >
        <AttachForm
          availableDocumentTypes={availableDocumentTypes}
          traveler={traveler}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={availableDocumentTypes}
          traveler={traveler}
          onUpload={onUpload}
        />
      </Stack>
      {traveler?.entityDocumentsByEntityId?.nodes.length! > 0 && (
        <Stack
          tokens={{
            childrenGap: 10,
          }}
        >
          <DocumentList
            isNew={false}
            data={traveler?.entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevel}
            onRemoveRow={(data) => {
              const cacheData = client.readQuery<Traveler, TravelerVariables>({
                query: TRAVELER,
                variables: {
                  id: traveler?.id!,
                },
              });
              if (cacheData) {
                const filteredList =
                  cacheData?.traveler?.entityDocumentsByEntityId.nodes.filter(
                    (ele) => ele.id !== data.id
                  );
                const updatedData: Traveler = {
                  traveler: {
                    ...cacheData?.traveler!,
                    entityDocumentsByEntityId: {
                      ...cacheData?.traveler?.entityDocumentsByEntityId,
                      nodes: filteredList!,
                    },
                  },
                };
                client.writeQuery<Traveler, TravelerVariables>({
                  query: TRAVELER,
                  data: updatedData,
                  variables: {
                    id: traveler?.id!,
                  },
                });
              }
            }}
            columns={columns}
          />
        </Stack>
      )}
      <Stack tokens={{ padding: '0px 25px' }}>
        <NoDataView
          show={traveler?.entityDocumentsByEntityId.nodes.length === 0}
          title="No Attachments"
        />
      </Stack>
    </Stack>
  );
};
