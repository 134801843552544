import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isSortedDescending: false,
  isCollapsible: true,
  toggleVisibility: true,
  isVisible: true,
};
export const columns: ColumnData[] = [
  {
    key: 'expand',
    name: '',
    fieldName: 'expand',
    minWidth: 20,
    maxWidth: 20,
    ...commonColumnProps,
  },
  {
    key: '_documentPackageId',
    name: '',
    fieldName: '_documentPackageId',
    minWidth: 20,
    maxWidth: 20,
    ...commonColumnProps,
  },
  {
    key: 'transactionType',
    name: 'Type',
    fieldName: 'transactionType',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'accountingNo',
    name: 'Transaction No.',
    fieldName: 'accountingNo',
    minWidth: 140,
    maxWidth: 140,
    ...commonColumnProps,
    isSorted: true,
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 100,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 100,
    maxWidth: 200,
    ...commonColumnProps,
  },

  {
    key: 'invoiceNumber',
    name: 'Ref. Number',
    fieldName: 'invoiceNumber',
    minWidth: 100,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: 'date',
    name: 'Date',
    fieldName: 'date',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },

  {
    key: 'amount',
    name: 'Amount',
    fieldName: 'amount',
    minWidth: 100,
    maxWidth: 150,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },

  {
    key: 'comment',
    name: 'Comment',
    fieldName: 'comment',
    minWidth: 370,
    maxWidth: 370,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
];
