import {
  NetworkStatus,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  Checkbox,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  IColumn,
  IDetailsRowBaseProps,
  IDetailsRowProps,
  IRenderFunction,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import clsx from 'clsx';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FilterArrayType } from 'common/components/Filters';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  BatchTransactionFilter,
  BatchTransactionsOrderBy,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import {
  BatchEditTabs,
  batchEditTabMode,
  BatchListOption,
  setBatchSelected,
} from 'postingTracker/batchEdit';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BatchSearchBar } from './BatchSearchBar';
import { useStyles } from './index.styles';
import { toFilterVariable, toOrderByVariable } from './utils';
import {
  BatchTransactions,
  BatchTransactionsVariables,
  BatchTransactions_batchTransactions_nodes,
} from './__generated__/BatchTransactions';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import {
  BatchTransactionDelete,
  BatchTransactionDeleteVariables,
} from '../view/__generated__/BatchTransactionDelete';
import { useToasts } from 'react-toast-notifications';
import { RequestMessageModal } from 'common/components/RequestMessageModal';
import {
  BatchEditApprovalCreate,
  BatchEditApprovalCreateVariables,
} from '../__generated__/BatchEditApprovalCreate';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { EntityAction, EntityType } from 'common/types/utility';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';
import { useColumns } from './column.data';
import { StatusIcon } from 'common/components/StatusIcon';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
const BATCH_TRANSACTIONS = loader('./BatchTransactions.graphql');
const DELETE_BATCH_TRANSACTION = loader(
  '../view/BatchTransactionDelete.graphql'
);
const BATCH_EDIT_APPROVAL_CREATE = loader('../BatchEditApprovalCreate.graphql');

const checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};

export type BatchListItem = BatchTransactions_batchTransactions_nodes & {
  currencyData: string;
  periodYear: string;
};

export const BatchList: React.FC = () => {
  let batchDataList: BatchListItem[] = [];
  const result: number[] = [];
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const history = useHistory();
  const renderRef = useRef(false);
  const { addToast } = useToasts();
  const { columns } = useColumns();
  const batchEditTabModeState = useReactiveVar(batchEditTabMode);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [selectedRows, setSelectedRows] = useState<BatchListItem[]>([]);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();
  const [isAllRequestChecked, setIsAllRequestlChecked] = useState(false);
  const [requestedIndices, setRequestedIndices] = useState<number[]>([]);

  const {
    loading,
    data,
    networkStatus,
    refetch,
    variables: batchTransactionVariables,
    fetchMore,
  } = useQuery<BatchTransactions, BatchTransactionsVariables>(
    BATCH_TRANSACTIONS,
    {
      variables: {
        first: TABLE_ROWS,
        filter: {
          _accountingStampDate: { isNull: true },
          _isHistory: { equalTo: false },
        },
        orderBy: [BatchTransactionsOrderBy.PRIMARY_KEY_ASC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [deleteBatchTransaction] = useMutation<
    BatchTransactionDelete,
    BatchTransactionDeleteVariables
  >(DELETE_BATCH_TRANSACTION, {
    errorPolicy: 'all',
  });

  const [batchEditApprovalCreate, { loading: batchEditApprovalCreateLoading }] =
    useMutation<BatchEditApprovalCreate, BatchEditApprovalCreateVariables>(
      BATCH_EDIT_APPROVAL_CREATE,
      { errorPolicy: 'all' }
    );

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const transformedData = refetching
    ? undefined
    : data?.batchTransactions?.nodes.map(
        (batchItem) =>
          ({
            ...batchItem,
            currencyData: batchItem.currency?.isoCode,
            periodYear: batchItem.companyCorporatePeriod?._periodYear,
            status: batchItem.statusType?.statusType,
            postingDate: batchItem.postingDate
              ? dateFormat(dateConvertions(batchItem.postingDate))
              : '',
          } as BatchListItem)
      );
  if (transformedData) batchDataList = transformedData;

  const _renderItemColumn = (
    item: BatchListItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof BatchListItem
      ] as string;
      const _isUrgentApproval = !Boolean(item._urgencyLevel);
      switch (column?.key) {
        case '_urgencyLevel':
          return (
            <RedBoxIndicator
              _isUrgentApproval={_isUrgentApproval}
              itemId={item?.id}
            />
          );
        case 'description':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <ActiveLink
                onClick={() => {
                  if (item._isUpdatable) {
                    const option: BatchListOption = {
                      key: item.id,
                      text:
                        `${item.description}  ${
                          item.currency?.isoCode
                            ? `(${item.currency?.isoCode})`
                            : ''
                        } ` || '',
                      description: item.description,
                      isoCode: item.currency?.isoCode!,
                      count: item._batchTransactionCount,
                      total: item?._batchTransactionTotal!,
                    };
                    setBatchSelected(option);
                  }

                  history.replace(`/postingTracker/batches/batch/${item.id}`);
                }}
                to={`/postingTracker/batches/batch/${item.id}`}
              >
                {fieldContent}
              </ActiveLink>
            </Stack>
          );

        case 'controlTotalAmount':
        case '_batchTransactionTotal':
          return (
            <AmountTextView
              variant="medium"
              className={commonStyles.contentColumnAlignRight}
              value={fieldContent!}
            />
          );
        case '_batchTransactionCount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <Text className={commonStyles.contentColumnAlignRight}>
                {fieldContent}
              </Text>
            </Stack>
          );

        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
              horizontal
              horizontalAlign="space-between"
            >
              <Text className={styles.statusType}>
                {convertToTitleCase(fieldContent)}
              </Text>
              <Stack verticalAlign="center" className={styles.flexEnd}>
                <StatusIcon
                  approval={false}
                  iconType={item.statusType!}
                  approvalData={item!}
                />
              </Stack>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const item: BatchListItem = { ...props.item };
    const { _urgencyLevel } = { ...item };
    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          className: clsx(
            styles.rowBaseStyle,
            _urgencyLevel === 0 ? commonStyles.urgentRow : styles.rowBaseStyle
          ),
        }
      : undefined;
    return <>{defaultRender?.(newProps)}</>;
  };
  // Handle Search query with globalSearch param
  const handleSearch = (showMore?: boolean) => {
    const variables: BatchTransactionsVariables = {
      ...batchTransactionVariables,
      first: TABLE_ROWS,
      after: showMore ? data?.batchTransactions?.pageInfo.endCursor : undefined,
      filter: searchFilters?.length
        ? ({
            and: [
              ...toFilterVariable(searchFilters),
              { _accountingStampDate: { isNull: true } },
            ],
          } as BatchTransactionFilter)
        : { _accountingStampDate: { isNull: true } },
      orderBy: toOrderByVariable(sortOrderParam!),
    };

    if (showMore) fetchMore?.({ variables });
    else refetch(variables);
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo();
    else {
      renderRef.current = true;
    }
  }, [sortOrderParam, handleSearchMemo, searchFilters]);

  const { _batchTransactionCount, _batchTransactionTotal, controlTotalAmount } =
    { ...data?.batchTransactions?.aggregates?.sum };

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({
        ...batchTransactionVariables,
      }),
    [refetch, batchTransactionVariables]
  );

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        setSortOrderParam({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
        await refetch({
          after: null,
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  const _onConfirm = async () => {
    const selectedData: BatchListItem[] = selectedRows;
    const deletedRows: BatchListItem[] = selectedData.filter(
      (item) => item._isDeletable
    );

    const entityDelete: EntityDeleteInput[] = selectedData
      .filter((item) => item._isDeletable)
      .map((item) => {
        return { id: item.id, rowTimestamp: item._rowTimestamp! };
      });

    const { errors } = await deleteBatchTransaction({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache) {
        deletedRows.forEach((item) => {
          const identity = cache.identify({
            ...item,
          });

          cache.evict({ id: identity });
          cache.gc();
        });
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
    }
  };

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const {
        _batchTransactionCount,
        _batchTransactionTotal,
        controlTotalAmount,
      } = {
        ...data?.batchTransactions?.aggregates?.sum,
      };
      const fieldContent =
        column?.key === 'controlTotalAmount'
          ? controlTotalAmount
          : column?.key === '_batchTransactionTotal'
          ? _batchTransactionTotal
          : column?.key === '_batchTransactionCount'
          ? _batchTransactionCount
          : '';
      const styleCommon = clsx(
        commonStyles.bold,
        commonStyles.contentColumnAlignRight
      );
      if (column?.key === '_batchTransactionCount') {
        return (
          <Stack
            verticalAlign="center"
            className={styles.statusColumnContainer}
          >
            <Text className={styleCommon}>{fieldContent}</Text>
          </Stack>
        );
      } else
        return (
          <AmountTextView
            variant="medium"
            className={styleCommon}
            value={fieldContent!}
          />
        );
    };

  useEffect(() => {
    if (requestedIndices.length > 0) {
      const stagedRowsLength = selectedRows.filter(
        (item) => item._isStagedApprovalRequest
      ).length;
      if (stagedRowsLength !== requestedIndices.length && isAllRequestChecked) {
        setIsAllRequestlChecked(false);
      } else {
        if (requestedIndices.length === selectedRows.length) {
          setIsAllRequestlChecked(true);
        } else {
          if (isAllRequestChecked) setIsAllRequestlChecked(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const showFooter =
    refetching ||
    (!_batchTransactionCount && !_batchTransactionTotal && !controlTotalAmount);

  const showRequestAll = transformedData?.some(
    (batch) => batch._isStagedApprovalRequest
  );

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Batch/Edit</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            {showRequestAll && (
              <RequestMessageModal
                entityType={EntityType.Batch}
                action={EntityAction.Request}
                disabled={
                  !selectedRows.some(
                    (selected) => selected._isStagedApprovalRequest
                  )
                }
                visible={selectedRows.length > 0}
                multiple={{
                  validCount: selectedRows.filter(
                    (selected) => selected._isStagedApprovalRequest
                  ).length,
                  invalidNames: selectedRows
                    .filter((selected) => !selected._isStagedApprovalRequest!)
                    .map((cannotDelete) => cannotDelete.description!),
                }}
                buttonProps={{ text: 'Request Approval' }}
                onConfirm={async (
                  requestComment: string | null,
                  requiredDate: string | null
                ) => {
                  const inputVariables: ApprovalRequestInput[] = selectedRows
                    .filter((item) => item._isStagedApprovalRequest)
                    .map((requestInput) => ({
                      entityId: requestInput.id!,
                      rowTimestamp: requestInput._rowTimestamp!,
                      comments: requestComment,
                      requiredDate: requiredDate ? requiredDate : undefined,
                    }));
                  if (inputVariables.length > 0) {
                    const { errors } = await batchEditApprovalCreate({
                      variables: {
                        input: {
                          entityApproval: inputVariables,
                        },
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: BATCH_TRANSACTIONS,
                          variables: batchTransactionVariables,
                        },
                      ],
                    });
                    if (errors?.length)
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    else {
                      addToast('Request sent for approvals', {
                        appearance: 'success',
                      });
                      setIsAllRequestlChecked(false);
                      setRequestedIndices([]);
                    }
                  }
                }}
                isLoading={batchEditApprovalCreateLoading}
              />
            )}
            <ActionMessageModal
              entityType={EntityType.Batch}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((selected) => selected.description),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              onClick={() => history.push('/postingTracker/batches/batch')}
              iconProps={{
                iconName: 'Add',
              }}
              text="New Batch"
            />
          </Stack>
        </Stack>

        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal verticalAlign="end" horizontalAlign="space-between">
            <Pivot
              selectedKey={batchEditTabModeState}
              onLinkClick={(item: PivotItem | undefined) => {
                if (item?.props.itemKey === BatchEditTabs.transactions)
                  history.replace('/postingTracker/batches');
                batchEditTabMode(item?.props.itemKey || BatchEditTabs.batch);
              }}
            >
              <PivotItem itemKey={BatchEditTabs.batch} headerText="Batches" />
              <PivotItem
                itemKey={BatchEditTabs.transactions}
                headerText="Available Transactions"
              />
            </Pivot>

            <Stack style={{ marginBottom: 10 }}>
              {showRequestAll && (
                <Checkbox
                  boxSide="end"
                  styles={checkBoxStyle}
                  checked={isAllRequestChecked}
                  label="Request All"
                  onChange={(_, value) => {
                    setIsAllRequestlChecked(!isAllRequestChecked!);
                    if (value) {
                      transformedData?.forEach((data, index) =>
                        data._isStagedApprovalRequest
                          ? result.push(index)
                          : null
                      );
                      setRequestedIndices(result);
                    } else {
                      setRequestedIndices([]);
                    }
                  }}
                />
              )}
            </Stack>
          </Stack>

          <Stack>
            <BatchSearchBar
              columns={gridColumns}
              onRefresh={() => reload()}
              onFilterChange={(filters) => {
                setSearchFilters(filters);
              }}
              onToggleVisibility={(columns) => {
                setGridColumns(columns);
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <InfiniteList
        items={batchDataList}
        loading={loading}
        hasNextPage={data?.batchTransactions?.pageInfo.hasNextPage}
        showFooter={!showFooter}
        columns={gridColumns.filter((column) => column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={_onColumnClick}
        onLoadMore={() => handleSearch(true)}
        onRenderRow={_onRenderRow}
        onSelectionChanged={setSelectedRows}
        requestedIndices={requestedIndices}
      />
    </>
  );
};
