import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  IconButton,
  ScrollablePane,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { useCommonStyles } from 'common/styles';
import {
  EntityDeleteInput,
  TravelPolicyUpdateInput,
} from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { TravelPolicyView } from '../view/TravelPolicyView';
import {
  TravelPolicyUpdate,
  TravelPolicyUpdateVariables,
} from '../view/TravelPolicyView/__generated__/TravelPolicyUpdate';
import { AllowanceList, TravelPolicyAllowance } from './AllowanceList';
import { PolicyList } from './PolicyList';
import { Header } from './PolicyList/Header';
import { TravelPoliciesShimmer } from './Shimmer';
import {
  TravelPolicies,
  TravelPolicies_travelPolicies_nodes,
} from './__generated__/TravelPolicies';
import {
  TravelPolicyAllowances,
  TravelPolicyAllowancesVariables,
} from './__generated__/TravelPolicyAllowances';
import { useStyles } from './index.styles';
const TRAVEL_POLICIES = loader('./TravelPolicies.graphql');
const TRAVEL_POLICIES_ALLOWANCES = loader('./TravelPolicyAllowances.graphql');
const TRAVEL_POLICY_UPDATE = loader(
  '../view/TravelPolicyView/TravelPolicyUpdate.graphql'
);
export type TravelPoliciesType = TravelPolicies_travelPolicies_nodes;

interface AllowanceSelectionProps {
  rows: TravelPolicyAllowance[];
  policyId: string;
  policyRowTimestamp: string;
}

export const TravelPolicyList = () => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const commonStyles = useCommonStyles();
  const [selectedGroup, setSelectedGroup] =
    useState<TravelPoliciesType | null>();
  const [isNew, setIsNew] = useState<boolean>(true);
  const [travelPolicyData, setTravelPolicyData] =
    useState<TravelPoliciesType>();
  const [visible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [selectedRows, setSelectedRows] = useState<TravelPolicyAllowance[]>([]);
  const [policyId, setPolicyId] = useState<string>();
  const [policyRowTimestamp, setPolicyRowTimestamp] = useState<string>();

  const {
    data: travelPoliciesData,
    loading: travelPoliciesLoading,
    refetch,
  } = useQuery<TravelPolicies>(TRAVEL_POLICIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [travelPolicyUpdate] = useMutation<
    TravelPolicyUpdate,
    TravelPolicyUpdateVariables
  >(TRAVEL_POLICY_UPDATE, { errorPolicy: 'all' });

  const [
    getTravelPolicyAllowances,
    {
      data: travelPolicyAllowancesData,
      loading: travelPolicyAllowancesLoading,
      variables: travelPolicyAllowancesVariables,
    },
  ] = useLazyQuery<TravelPolicyAllowances, TravelPolicyAllowancesVariables>(
    TRAVEL_POLICIES_ALLOWANCES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { nodes } = { ...travelPoliciesData?.travelPolicies };

  const onEditClick = async (travelPolicy: TravelPoliciesType) => {
    setIsNew(false);
    setTravelPolicyData(travelPolicy);
    showModal();
  };

  const _onCollapseClick = (travelPolicy: TravelPoliciesType) => {
    if (selectedGroup?.id !== travelPolicy.id) {
      getTravelPolicyAllowances({
        variables: {
          ...travelPolicyAllowancesVariables,
          id: travelPolicy.id,
        },
      });
    }
    setSelectedGroup((prevState) => {
      return prevState?.id === travelPolicy.id ? null : travelPolicy;
    });
  };

  const _onAllowanceSelection = (data: AllowanceSelectionProps) => {
    if (!!data.rows.length) {
      const { rows, policyId, policyRowTimestamp } = { ...data };
      setSelectedRows(rows);
      setPolicyId(policyId);
      setPolicyRowTimestamp(policyRowTimestamp);
    } else {
      setSelectedRows([]);
      setPolicyId(undefined);
      setPolicyRowTimestamp(undefined);
    }
  };

  const _onConfirm = async () => {
    const deletableRows: EntityDeleteInput[] = selectedRows
      ?.filter((entity) => entity._isDeletable)
      .map((obj) => {
        return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
      });
    const { errors } = await travelPolicyUpdate({
      variables: {
        input: {
          id: policyId,
          rowTimestamp: policyRowTimestamp,
          travelPolicyAllowancesDelete: deletableRows,
        } as TravelPolicyUpdateInput,
      },
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      setSelectedRows([]);
      setPolicyId(undefined);
      setPolicyRowTimestamp(undefined);
      addToast('Allowances deleted successfully.', {
        appearance: 'success',
      });
    }
  };

  return (
    <Stack grow className={styles.container}>
      <ScrollablePane>
        <Stack className={styles.innerContainer}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <Stack className={commonStyles.listHeaderContainer}>
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                className={commonStyles.listTitleContainer}
              >
                <Text variant="xLarge">Travel Policies</Text>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <ActionMessageModal
                    entityType={EntityType.TravelPolicyAllowance}
                    action={EntityAction.Delete}
                    onConfirm={_onConfirm}
                    disabled={
                      !selectedRows.some((selected) => selected._isDeletable)
                    }
                    visible={selectedRows.length > 0}
                    multiple={{
                      validCount: selectedRows.filter(
                        (selected) => selected._isDeletable
                      ).length,
                      invalidNames: selectedRows
                        .filter((selected) => !selected._isDeletable)
                        .map(
                          (cannotDelete) =>
                            cannotDelete?.expenditureType?.toString()!
                        ),
                    }}
                  />
                  <TooltipHost content={'Refresh'}>
                    <IconButton
                      onClick={() => {
                        refetch();
                        setSelectedGroup(null);
                      }}
                      iconProps={{ iconName: 'refresh' }}
                    />
                  </TooltipHost>
                  <TravelPolicyView
                    visible={visible}
                    isNew={isNew}
                    travelPolicyData={travelPolicyData}
                    showModal={showModal}
                    hideModal={() => {
                      hideModal();
                      setIsNew(true);
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Header />
          </Sticky>
          {travelPoliciesLoading ? (
            <TravelPoliciesShimmer />
          ) : (
            <>
              {!!nodes?.length &&
                nodes.map((travelPolicy, index) => {
                  const isOpen = selectedGroup?.id === travelPolicy.id;
                  return (
                    <Stack key={index.toString()}>
                      <PolicyList
                        isOpen={isOpen}
                        travelPolicy={travelPolicy}
                        onCollapseClick={() => {
                          _onCollapseClick(travelPolicy);
                        }}
                        onEditClick={(data) => onEditClick(data)}
                      />
                      {isOpen && (
                        <AllowanceList
                          data={travelPolicyAllowancesData?.travelPolicy}
                          loading={travelPolicyAllowancesLoading}
                          onAllowanceSelection={(rows) => {
                            _onAllowanceSelection({
                              rows: rows,
                              policyId: travelPolicy.id!,
                              policyRowTimestamp: travelPolicy._rowTimestamp!,
                            });
                          }}
                        />
                      )}
                    </Stack>
                  );
                })}
            </>
          )}
        </Stack>
      </ScrollablePane>
    </Stack>
  );
};
