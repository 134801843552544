import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    paddingLeft: 25,
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const getColumns = () => {
  const columns: ColumnData[] = [
    {
      key: 'name',
      name: 'Business Unit',
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 150,
      styles: colHeaderLeftAlignStyle,
      ...commonColumnProps,
    },
    {
      key: 'emailProductionOfficeDescription',
      name: 'Production Office',
      fieldName: 'emailProductionOfficeDescription',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'emailReplyName',
      name: 'Reply To Name',
      fieldName: 'emailReplyName',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'emailReplyTo',
      name: 'Email',
      fieldName: 'emailReplyTo',
      minWidth: 170,
      maxWidth: 170,
      ...commonColumnProps,
    },
    {
      key: 'chartOfAccount',
      name: 'Chart of Accounts',
      fieldName: 'chartOfAccount',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'action',
      name: '',
      fieldName: 'action',
      minWidth: 50,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
      isResizable: false,
    },
  ];
  return columns;
};
