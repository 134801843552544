import { AppRouter } from 'common/types/globalTypes';

interface Message {
  linkId: GqlUUID | null;
  linkType: AppRouter | null;
  invoiceMessage?: {
    _isAccountingEntry: boolean | null;
  } | null;
}

const getBasePath = (message: Message) => {
  switch (message.linkType) {
    case 'APPROVALS':
      return '/approvals/approval/';
    case 'APPROVAL_HISTORY':
      return '/approval_history/approval/';
    case 'TRANSACTION_SIGNING':
      if (message.invoiceMessage?._isAccountingEntry)
        return '/signing/accounting/';
      return '/signing/transaction/';
    case 'PAY_CYCLE':
      return '/pay/payment_cycle/cycle/';
    case 'PAYMENT_TRACKING':
      return '/pay/payment_tracking/payment/';
    case 'DOCUMENT_POOL':
      return '/doc/groups/group/';
    case 'SUPPLIER':
      return '/account-management/suppliers/supplier/';
    case AppRouter.PCARD_ACCOUNT:
      return '/project-settings/purchase-cards/card-account/';
    case AppRouter.PCARD_HOLDER:
      return '/project-settings/purchase-cards/card-holder/';
    case AppRouter.BATCH_EDIT:
      return '/postingTracker/batches/batch/';
    case 'PURCHASE_ORDER':
      return '/purchase-orders/purchase-order/';
    case 'TRAVELER':
      return '/account-management/travelers/traveler/';
    case AppRouter.TRAVEL_AUTHORIZATION:
      return '/ta/travel-plan/travel/';
  }
};
export const getMessageUrl = (message: Message) => {
  const base = getBasePath(message);
  return base ? `${base}${message.linkId}` : '/404';
};
