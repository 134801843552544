import {
  CommandBarButton,
  IContextualMenuProps,
  PrimaryButton,
} from '@fluentui/react';
import { GetEntityDocument_entityDocument } from 'documents/documentAssignment/folder/view/__generated__/GetEntityDocument';
import React from 'react';

interface RetireSplitButtonProps {
  entityDocument: GetEntityDocument_entityDocument | null | undefined;
  onRevisionPO: () => void;
  onClick: (retire: boolean) => void;
}

export const RetireSplitButton: React.FC<RetireSplitButtonProps> = ({
  onRevisionPO,
  entityDocument,
  onClick,
}) => {
  const { _retirePrivilege, _unretirePrivilege, _revisePrivilege } = {
    ...entityDocument,
  };

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'revise',
        text: 'Revise PO',
        onClick: (event) => {
          if (event) event.stopPropagation();
          onRevisionPO();
        },
      },
    ],
  };

  /**
   * If retire previlege is there
   */
  if (!_retirePrivilege && !_unretirePrivilege && _revisePrivilege)
    return (
      <CommandBarButton
        split
        text="Revise PO"
        menuProps={menuProps}
        onClick={() => onRevisionPO()}
      />
    );
  else {
    if (_retirePrivilege)
      return (
        <PrimaryButton
          split
          text={'Retire Balance'}
          menuProps={_revisePrivilege ? menuProps : undefined}
          onClick={() => onClick(true)}
        />
      );
    if (_unretirePrivilege)
      return (
        <PrimaryButton
          split
          text={'Unretire Balance'}
          menuProps={_revisePrivilege ? menuProps : undefined}
          onClick={() => onClick(false)}
        />
      );
    else return null;
  }
};
