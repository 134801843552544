import { Stack, Separator } from '@fluentui/react';
import React from 'react';

import { useStyles } from './index.styles';
import { SignaturePad } from './SignaturePad';
import { TextToSignature } from './TextToSignature';
import { UploadSignature } from './UploadSignature';

export interface CreateSignatureProps {
  name: string;
  onSignatureCreate: (imageUrl: string) => void;
}
export const CreateSignature: React.FC<CreateSignatureProps> = ({
  name,
  ...props
}) => {
  const styles = useStyles();

  return (
    <Stack tokens={{ childrenGap: 30, padding: '20px 0px' }} horizontal>
      <Stack tokens={{ childrenGap: 20 }} className={styles.optionsContainer}>
        <UploadSignature />
      </Stack>
      <Stack tokens={{ childrenGap: 20 }} className={styles.optionsContainer}>
        <SignaturePad {...props} />
      </Stack>
      <Separator vertical />
      <Stack tokens={{ childrenGap: 20 }} className={styles.optionsContainer}>
        <TextToSignature name={name} {...props} />
      </Stack>
    </Stack>
  );
};
