import React from 'react';
import {
  DefaultButton,
  Image,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import { useField } from 'formik';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useFormikContext } from 'formik';

interface ViewSignatureProps {
  loading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}
export const ViewSignature: React.FC<ViewSignatureProps> = ({
  loading,
  onCancel,
  onSubmit,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { dirty } = useFormikContext();
  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="baseline"
      >
        <Text
          className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
        >
          Your Signature
        </Text>
        <UnsavedIndicator visible={dirty} />
      </Stack>

      <Stack
        className={clsx(
          styles.ImageWrapper,
          dirty ? styles.selectedStyles : ''
        )}
      >
        <SignatureImage name="imageUrl" />
      </Stack>
      {dirty && (
        <Stack
          tokens={{ padding: 5, childrenGap: 20 }}
          horizontal
          horizontalAlign="end"
        >
          <DefaultButton text="Cancel" onClick={onCancel} />
          <PrimaryButton
            text="Update"
            disabled={loading}
            onClick={onSubmit}
          />
        </Stack>
      )}
    </>
  );
};

interface SignatureImageProps {
  name: string;
}
const SignatureImage: React.FC<SignatureImageProps> = ({ name }) => {
  const [field] = useField<string | undefined>(name);
  const styles = useStyles();

  const { value } = field;

  return (
    <Image
      style={{ objectFit: 'contain' }}
      width={450}
      height={180}
      className={styles.ImageContainer}
      src={value}
    />
  );
};
