import { IDropdownProps, TooltipHost } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import { useController } from 'react-hook-form';
import React, { FC } from 'react';
import { CustomDropdown } from '../CustomDropdown';

type FormikDropdownProps<T> = T & {
  underlined?: boolean;
  onClear?: () => void;
  width?: number;
  isCustomTitle?: boolean;
  showText?: boolean;
};

export const FormHookDropdown: FC<
  FormikDropdownProps<RequiredNameProps<IDropdownProps>>
> = ({ name, showText, onChange, onClear, ...props }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    // rules: { required: true },
  });

  const selectedItem =
    !!props.options && !!field.value
      ? props.options.find((item) => item.key === field.value)
      : null;

  return (
    <TooltipHost content={showText ? selectedItem?.text : undefined}>
      <CustomDropdown
        errorMessage={error?.message}
        selectedKey={field.value}
        onChange={(event, option, index) => {
          field.onChange(option?.key);
          onChange?.(event, option, index);
        }}
        onClear={() => {
          field.onChange(null);
          onClear?.();
        }}
        {...props}
      />
    </TooltipHost>
  );
};
