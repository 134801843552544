import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: `2px dashed ${theme.palette.neutralQuaternary}`,
    padding: 10,
    height: 100,
  },
  uploadIconStyle: { color: 'white', fontSize: FontSizes.size24 },
  dropZoneHover: {
    backgroundColor: theme.palette.themePrimary,
    borderColor: theme.palette.white,
  },
}));
