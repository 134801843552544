import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    callout: {
      width: 350,
      padding: '20px 24px',
    },
    title: {
      marginBottom: 12,
    },
    link: {
      display: 'block',
      marginTop: 20,
    },
  };
});
