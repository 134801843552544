import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    position: 'relative',
    flexShrink: 0,
    verticalAlign: 'middle',
  },
  textStyles: {
    color: 'white',
  },
  badge: {
    height: 20,
    zIndex: 1,
    position: 'absolute',
    width: 20,
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 10,
    top: 10,
    right: 0,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    backgroundColor: theme.palette.themePrimary,
  },
}));
