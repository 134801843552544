import { useReactiveVar } from '@apollo/client';
import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';
import { setCurrentProfile } from 'utility/cache/ui';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isSortedDescending: false,
  isCollapsible: true,
  toggleVisibility: true,
  isVisible: true,
};
const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 0,
  },
};
const useColumns = () => {
  const currentProfileData = useReactiveVar(setCurrentProfile);
  const { company } = { ...currentProfileData?.currentUserProfile };
  const { isSm, isLg } = useGetScreenWidth();
  const columns: ColumnData[] = [
    {
      key: '_urgencyLevel',
      name: '',
      showColumnTitle: 'Urgent',
      fieldName: '_urgencyLevel',
      minWidth: 10,
      maxWidth: 10,
      styles: colHeaderLeftAlignStyle,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
    {
      key: 'transactionType',
      name: 'Type',
      fieldName: 'transactionType',
      minWidth: 110,
      maxWidth: 110,
      ...commonColumnProps,
    },
    {
      key: 'vendorReference',
      name: 'Name',
      fieldName: 'vendorReference',
      minWidth: 50,
      maxWidth: 200,
      ...commonColumnProps,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 80,
      maxWidth: 300,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: 'invoiceNumber',
      name: 'Ref. Number',
      fieldName: 'invoiceNumber',
      minWidth: 80,
      maxWidth: 150,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'date',
      name: 'Date',
      fieldName: 'date',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
      isSorted: true,
    },
    {
      key: 'corporatePeriod',
      name: 'Period',
      fieldName: 'corporatePeriod',
      minWidth: 40,
      maxWidth: 50,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'department',
      name: 'Department',
      fieldName: 'department',
      minWidth: 80,
      maxWidth: 110,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'businessUnit',
      name: 'Business Unit',
      fieldName: 'businessUnit',
      minWidth: 80,
      maxWidth: 110,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'controlTotalAmount',
      name: 'Amount',
      fieldName: 'controlTotalAmount',
      minWidth: 60,
      maxWidth: 120,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: 'currency',
      name: 'Currency',
      fieldName: 'currency',
      minWidth: 50,
      maxWidth: 60,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: '_accountingStampTransactionReference',
      name: 'Transaction #',
      fieldName: '_accountingStampTransactionReference',
      minWidth: 50,
      maxWidth: 150,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'transactionStamp',
      name: 'Accounting Trans #',
      fieldName: 'transactionStamp',
      minWidth: 50,
      maxWidth: 150,
      ...commonColumnProps,
      isVisible: false,
      toggleVisibility: false,
    },
    {
      key: '_createdByUserName',
      name: 'Created by',
      fieldName: '_createdByUserName',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_createdDate',
      name: 'Created On',
      fieldName: '_createdDate',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_baseCurrencyAmount',
      name: `${company?.currency?.isoCode} Amount`,
      fieldName: '_baseCurrencyAmount',
      minWidth: 80,
      maxWidth: 100,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
      isVisible: false,
    },
    {
      key: '_spotCurrencyAmount',
      name: `${company?.currency?.isoCode} Spot Rate`,
      fieldName: '_spotCurrencyAmount',
      minWidth: 100,
      maxWidth: 110,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
      isVisible: false,
    },
    {
      key: 'production',
      name: 'Production',
      fieldName: 'production',
      minWidth: 100,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'requesterName',
      name: 'Requested By',
      fieldName: 'requesterName',
      minWidth: 90,
      maxWidth: 130,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'requesterDate',
      name: 'Requested on',
      fieldName: 'requesterDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'approvers',
      name: 'Approved By',
      fieldName: 'approvers',
      minWidth: 90,
      maxWidth: 130,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'approvedDate',
      name: 'Approved on',
      fieldName: 'approvedDate',
      minWidth: 120,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 160,
      ...commonColumnProps,
    },
  ];

  return { columns };
};
export { useColumns };
