import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
    paddingRight: 30,
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isCollapsible: true,
  toggleVisibility: true,
  isVisible: true,
};
export const reportsColumns: ColumnData[] = [
  {
    key: 'documentTypes',
    name: 'Type',
    fieldName: 'documentTypes',
    minWidth: 100,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'fileReference',
    name: 'Documents',
    fieldName: 'fileReference',
    minWidth: 200,
    maxWidth: 300,
    ...commonColumnProps,
  },
  {
    key: '_documentContents',
    name: 'Contents',
    fieldName: '_documentContents',
    minWidth: 200,
    maxWidth: 300,
    ...commonColumnProps,
  },
  {
    key: 'comment',
    name: 'Comments',
    fieldName: 'comment',
    minWidth: 200,
    maxWidth: 300,
    ...commonColumnProps,
  },
  {
    key: '_sharedByUser',
    name: 'Shared By',
    fieldName: '_sharedByUser',
    minWidth: 150,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: '_createdDate',
    name: 'Created',
    fieldName: '_createdDate',
    minWidth: 200,
    maxWidth: 300,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
];
