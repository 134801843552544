import { useQuery } from '@apollo/client';
import { ContextualMenu, Spinner, Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  MovementOrders,
  MovementOrdersVariables,
} from '../__generated__/MovementOrders';
import { JourneysList } from './JourneyList';
import { MovementOrderTraveler, TravelersList } from './Sidebar';
import { useStyles } from './index.styles';
const MOVEMENT_ORDERS = loader('../MovementOrders.graphql');

interface MovementDetailModelProps {
  onClose: () => void;
  travelAuthorizationData: TravelAuthorization_travelAuthorization;
  isOpen: boolean;
}
export const MovementDetailModel: React.FC<MovementDetailModelProps> = ({
  travelAuthorizationData,
  onClose,
  isOpen,
}) => {
  // const [contactIds, setContactIds] = useState<Map<string, IPersonaProps[]>>(
  //   new Map<string, IPersonaProps[]>()
  // );

  const [travelersSet, setTravelersSet] = useState<Set<string>>(
    new Set<string>()
  );

  const [selectedTraveler, setSelectedTraveler] =
    useState<MovementOrderTraveler>();
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { data, loading, refetch } = useQuery<
    MovementOrders,
    MovementOrdersVariables
  >(MOVEMENT_ORDERS, { variables: { id: travelAuthorizationData?.id } });

  const { movementOrderNumber } = {
    ...data?.travelAuthorization?.movementOrder,
  };
  useEffect(() => {
    if (
      !!data?.travelAuthorization?.movementOrder?.movementTravelers.nodes.length
    ) {
      setSelectedTraveler(
        data.travelAuthorization.movementOrder?.movementTravelers.nodes[0]
      );
    }
  }, [data]);

  const onTravelerChecked = (traveler: MovementOrderTraveler) => {
    if (travelersSet.has(traveler.id)) {
      travelersSet.delete(traveler.id);
      setTravelersSet(new Set(travelersSet));
    } else setTravelersSet(new Set(travelersSet.add(traveler.id)));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      containerClassName={styles.container}
      styles={{ scrollableContent: { overflow: 'hidden' } }}
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
    >
      <Stack style={{ height: 700 }}>
        {loading ? (
          <Stack style={{ height: 550 }} verticalAlign="center">
            <Spinner />
          </Stack>
        ) : (
          <Stack>
            <Stack
              style={{ padding: '20px' }}
              horizontal
              horizontalAlign="space-between"
            >
              <Text variant="xLarge">
                {`Movement Orders `}
                <Text
                  className={commonStyles.colorThemePrimary}
                  variant="xLarge"
                >
                  {movementOrderNumber}
                </Text>
              </Text>

              <CloseButton onClick={onClose} />
            </Stack>
            <Stack
              horizontal
              grow
              style={{ height: 700 }}
              className={commonStyles.noScroll}
              disableShrink
            >
              <TravelersList
                data={data}
                onClick={setSelectedTraveler}
                selectedTraveler={selectedTraveler}
                dataLoading={loading}
                onTravelerChecked={onTravelerChecked}
              />

              <Stack
                style={{
                  position: 'relative',
                  flexBasis: 0,
                }}
                grow
              >
                <JourneysList
                  travelerSet={travelersSet}
                  data={data}
                  selectedTraveler={selectedTraveler}
                  onRefetch={refetch}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </ModalWrapper>
  );
};
