import { useTheme } from '@fluentui/react';
import { Text, View } from '@react-pdf/renderer';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { GetEntityDocument_entityDocument } from 'documents/documentAssignment/folder/view/__generated__/GetEntityDocument';
import React from 'react';
import { getStyles } from './index.stylesheet';

export interface TransactionHeaderProps {
  selectedDocument: GetEntityDocument_entityDocument;
}

export const TransactionHeader: React.FC<TransactionHeaderProps> = ({
  selectedDocument,
}) => {
  const { palette } = useTheme();
  const styles = getStyles(palette);

  const convertValues = (amount: string) => {
    const formattedValue = getFormattedAmountValue(amount);
    const textValue = formattedValue === '' ? '0.00' : formattedValue;
    return textValue;
  };

  return (
    <View
      style={{
        backgroundColor: '#edebe9',
        paddingHorizontal: 15,
        paddingVertical: 20,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 15,
        }}
      >
        <Text style={[styles.keyHeader, { marginRight: 10 }]}>
          {selectedDocument?.documentTypes?.documentType}:
        </Text>
        <Text style={[styles.valueHeader, { marginRight: 10 }]}>
          {selectedDocument?.indexReferenceNumber}
        </Text>
        <Text style={[styles.valueHeader, { marginRight: 10 }]}>
          {selectedDocument?.indexName}
        </Text>
        <Text style={[styles.keyHeader]}>
          {selectedDocument?.indexDescription}
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <Text style={styles.valueHeader}>Total : </Text>
        <Text style={styles.keyHeader}>
          {convertValues(selectedDocument?.indexAmount!)}
        </Text>
        <Text
          style={[
            styles.valueHeader,
            {
              paddingLeft: 10,
            },
          ]}
        >
          {`Remaining : `}
        </Text>
        <Text style={styles.keyHeader}>
          {convertValues(
            selectedDocument?.documentAppliedAmounts?.remainingTotal!
          )}
        </Text>
        <Text
          style={[
            styles.valueHeader,
            {
              paddingLeft: 10,
            },
          ]}
        >
          {selectedDocument?.currency?.isoCode}
        </Text>
      </View>
    </View>
  );
};
