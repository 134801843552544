import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  panelHeaderContainer: {
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.33)`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  listContainer: {
    marginTop: '70px',
  },
  noDataViewContainer: {
    padding: 20,
  },
  notificationContainer: {
    padding: '15px 15px 5px 15px',
    height: "auto",
  },
  briefcaseContainer: {
    justifyContent: 'center',
    width: '50px',
    marginTop: 10,
  },
  briefcaseIcon: {
    marginLeft: 10,
    fontSize: FontSizes.size24,
    color: '#929292',
  },
  jumpToDetailContainer: {
    color: '#157ADD',
    fontSize: FontSizes.size14,
  },
  shimmerViewMargin: {
    marginTop: '0.5%',
  },
  shimmerViewMarginSecond: {
    marginTop: '1%',
  },
  shimmerViewPadding: {
    padding: 15,
  },
  messageReadBgColor: {
    backgroundColor: theme.palette.white,
  },
  messageUnreadBgColor: {
    backgroundColor: theme.palette.neutralLight,
  },
  shimmerSectionConatiner: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  marginTop10: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  customWidth500: {
    maxWidth: '500px',
  },
  customWidth110: {
    maxWidth: '110px',
    marginTop: '5px !important'
  },
  wordWrap: {
    wordWrap: "break-word"
  }
}));
