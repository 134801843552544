import { PivotMenuItemProps } from '../PivotDisplay';

export interface MenuPropertiesCount {
  name: string;
  count: number;
  iconName: string;
}

export const getUpdatedPivotActionItems = (
  pivotItems: MenuPropertiesCount[],
  pivotActionItems: PivotMenuItemProps[]
) => {
  return pivotActionItems.map((item) => ({
    ...item,
    count: pivotItems.find((item2) => item2.name === item.name)?.count,
    iconName: pivotItems.find((item2) => item2.name === item.name)?.iconName!,
  }));
};
