import { FilterArrayType } from 'common/components/Filters';
import { InvoicesOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: InvoicesOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'transactionType',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC,
      ],
    },
  ],
  [
    '_accountingStampTransactionReference',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC,
      ],
    },
  ],
  [
    'businessUnit',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'name',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.VENDOR_REFERENCE_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.VENDOR_REFERENCE_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'department',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'invoiceNumber',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.INVOICE_NUMBER_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.INVOICE_NUMBER_DESC],
    },
  ],
  [
    'date',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.INVOICE_DATE_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.INVOICE_DATE_DESC],
    },
  ],
  [
    'amount',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.CONTROL_TOTAL_AMOUNT_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.CONTROL_TOTAL_AMOUNT_DESC],
    },
  ],
  [
    '_baseCurrencyAmount',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy._BASE_CURRENCY_AMOUNT_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy._BASE_CURRENCY_AMOUNT_DESC],
    },
  ],
  [
    '_spotCurrencyAmount',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy._SPOT_CURRENCY_AMOUNT_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy._SPOT_CURRENCY_AMOUNT_DESC],
    },
  ],
  [
    'currency',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC,
      ],
    },
  ],
  [
    '_createdByUserName',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy._CREATED_BY_USER_NAME_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy._CREATED_BY_USER_NAME_DESC],
    },
  ],
  [
    'status',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC,
      ],
    },
  ],
  [
    '_accountingStampDate',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy._ACCOUNTING_STAMP_DATE_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy._ACCOUNTING_STAMP_DATE_DESC],
    },
  ],
  [
    '_accountingStampTransactionReference',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC,
      ],
    },
  ],
  [
    '_accountingStampUserName',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy._ACCOUNTING_STAMP_USER_NAME_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy._ACCOUNTING_STAMP_USER_NAME_DESC],
    },
  ],
  [
    '_accountingStampDate',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy._ACCOUNTING_STAMP_DATE_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy._ACCOUNTING_STAMP_DATE_DESC],
    },
  ],
  [
    'requesterName',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC,
      ],
    },
  ],
  [
    'requesterDate',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC,
      ],
    },
  ],
  [
    'approvers',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC,
      ],
    },
  ],
  [
    'approvedDate',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC,
      ],
    },
  ],
  [
    'rejectors',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC,
      ],
    },
  ],
  [
    'rejectedDate',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC,
      ],
    },
  ],
  [
    'amendedByName',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC,
      ],
    },
  ],
  [
    'amendedDate',
    {
      [OrderDirection.ASC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        InvoicesOrderBy.APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC,
      ],
    },
  ],
  [
    'production',
    {
      [OrderDirection.ASC]: [InvoicesOrderBy.COMPANY_BY_COMPANY_ID__NAME_ASC],
      [OrderDirection.DESC]: [InvoicesOrderBy.COMPANY_BY_COMPANY_ID__NAME_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    InvoicesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
    InvoicesOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), ...orderByArray];
};

export const toFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'transactionTypeId':
      case 'businessUnitId':
      case 'departmentId':
      case 'corporatePeriodId':
      case '_statusTypeId':
      case '_companyId':
      case '_isHistory':
      case 'currencyId':
      case '_createdByUserid':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      default:
        return undefined;
    }
  });
