import { Stack, Text } from '@fluentui/react';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import React from 'react';
import { useStyles } from './index.styles';

interface DataViewProps {
  title?: string | undefined;
  description?: string | undefined;
  isAmount?: boolean;
}

export const DataView: React.FC<DataViewProps> = ({ title, description, isAmount }) => {
  const classStyles = useStyles();
  let descriptionText = description;
  if (isAmount) {
    descriptionText = getFormattedAmountValue(description!);
  }
  return (
    <Stack>
      {title && (
        <Text variant="medium" className={classStyles.inputContainerLabel}>
          {title}
        </Text>
      )}
      <Text className={classStyles.textInputConatiner}>{descriptionText}</Text>
    </Stack>
  );
};
