import { FilterArrayType } from 'common/components/Filters';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { PayCyclesOrderBy } from 'common/types/globalTypes';

type OrderLookup = {
  [direction in OrderDirection]: PayCyclesOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'description',
    {
      [OrderDirection.ASC]: [PayCyclesOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [PayCyclesOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    '_paymentType',
    {
      [OrderDirection.ASC]: [
        PayCyclesOrderBy.PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        PayCyclesOrderBy.PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC,
      ],
    },
  ],
  [
    '_paymentAccount',
    {
      [OrderDirection.ASC]: [
        PayCyclesOrderBy.PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_ASC,
      ],
      [OrderDirection.DESC]: [
        PayCyclesOrderBy.PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_DESC,
      ],
    },
  ],
  [
    'defaultPaymentDate',
    {
      [OrderDirection.ASC]: [PayCyclesOrderBy.DEFAULT_PAYMENT_DATE_ASC],
      [OrderDirection.DESC]: [PayCyclesOrderBy.DEFAULT_PAYMENT_DATE_DESC],
    },
  ],
  [
    'currencyIsoCode',
    {
      [OrderDirection.ASC]: [PayCyclesOrderBy.CURRENCY_ID_ASC],
      [OrderDirection.DESC]: [PayCyclesOrderBy.CURRENCY_ID_DESC],
    },
  ],
  [
    'controlTotalAmount',
    {
      [OrderDirection.ASC]: [PayCyclesOrderBy.CONTROL_TOTAL_AMOUNT_ASC],
      [OrderDirection.DESC]: [PayCyclesOrderBy.CONTROL_TOTAL_AMOUNT_DESC],
    },
  ],
  [
    '_totalPayments',
    {
      [OrderDirection.ASC]: [PayCyclesOrderBy._TOTAL_PAYMENTS_ASC],
      [OrderDirection.DESC]: [PayCyclesOrderBy._TOTAL_PAYMENTS_DESC],
    },
  ],
  [
    '_paymentCount',
    {
      [OrderDirection.ASC]: [PayCyclesOrderBy._PAYMENT_COUNT_ASC],
      [OrderDirection.DESC]: [PayCyclesOrderBy._PAYMENT_COUNT_DESC],
    },
  ],
  [
    'status',
    {
      [OrderDirection.ASC]: [
        PayCyclesOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC,
      ],
      [OrderDirection.DESC]: [
        PayCyclesOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [PayCyclesOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), PayCyclesOrderBy.PRIMARY_KEY_ASC];
};

export const toPayCycleFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'transactionTypeId':
      case 'paymentAccountId':
      case '_statusTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      default:
        return undefined;
    }
  });
