import { Stack } from '@fluentui/react';
import { FormHookAmount } from 'common/components/FormHooksFields';
import React from 'react';
import { useStyles } from './index.styles';
import { EnvironmentalItemsRowValues } from '../../../types';

interface EntityEnvironmentalItemsRowProps {
  fieldName: string;
  data: EnvironmentalItemsRowValues;
  environmentalItemsName: string;
  isIntegerFormat?: boolean | null;
  isFloatFormat?: boolean | null;
  isAmountFormat?: boolean | null;
}

export const EntityEnvironmentalItemsRow: React.FC<
  EntityEnvironmentalItemsRowProps
> = ({
  fieldName,
  data,
  environmentalItemsName,
  isIntegerFormat,
  isFloatFormat,
  isAmountFormat,
}) => {
    const styles = useStyles();
    const decimalScale = isFloatFormat ? 5 : isIntegerFormat ? 0 : 2;

    return (
      <Stack key={data.environmentalImpactTypeId}>
        <FormHookAmount
          name={`${environmentalItemsName}.${fieldName}`}
          className={styles.marginL5}
          fixedDecimalScale
          decimalScale={decimalScale}
          allowNegative={true}
          thousandSeparator={!!isAmountFormat}
        />
      </Stack>
    );
  };
