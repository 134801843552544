import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    extractionTypeId: Yup.string().when('entityDocumentTypeId', {
      is: (entityDocumentTypeId: string) => {
        return entityDocumentTypeId === '55';
      },
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),

    entityDocumentTypeId: Yup.string().required('Required').nullable(),

    indexCurrencyId: Yup.string().when('indexAmount', {
      is: (indexAmount: number) => indexAmount != null,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
  });
