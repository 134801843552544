import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import {
  PurchaseOrder,
  PurchaseOrder_purchaseOrder_purchaseOrderRevisionHistoriesByPoGroupId_nodes,
} from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React from 'react';
import { columns } from './column.data';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import { dateFormat } from 'common/utils/dateFormats';
import { ActiveLink } from 'common/components/ActiveRowLink';
type ItemRow =
  PurchaseOrder_purchaseOrder_purchaseOrderRevisionHistoriesByPoGroupId_nodes;
interface RevisionHistoryProps {
  purchaseOrder: PurchaseOrder | undefined;
}

export const RevisionHistory: React.FC<RevisionHistoryProps> = ({
  purchaseOrder,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const data =
    purchaseOrder?.purchaseOrder?.purchaseOrderRevisionHistoriesByPoGroupId?.nodes?.map(
      (item) => ({
        ...item,
      })
    ) || [];

  const renderColumn = (item?: ItemRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;
    const fieldContent = item[column.fieldName as keyof ItemRow] as
      | string
      | null;

    switch (column.key) {
      case 'purchaseOrderNumber':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <ActiveLink
              key={item?.id}
              to={`${item.id}`}
              className={commonStyles.colorThemePrimary}
            >
              {fieldContent ? fieldContent : ''}
            </ActiveLink>
          </Stack>
        );

      case 'poTotal':
        return (
          <Stack
            horizontal
            className={clsx(styles.columnHeight, styles.rightAlignContainer)}
            verticalAlign="center"
          >
            <AmountTextView
              className={clsx(styles.amount)}
              value={fieldContent}
            />
          </Stack>
        );
      case 'dateRequested':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text>{fieldContent ? dateFormat(fieldContent) : ''}</Text>
          </Stack>
        );
      default:
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text>{fieldContent ? fieldContent : ''}</Text>
          </Stack>
        );
    }
  };

  if (purchaseOrder && !!data?.length)
    return (
      <Stack tokens={{ padding: '10px 0px 10px 20px' }}>
        <Text variant="xLarge">Revision history</Text>
        <DetailsList
          compact
          columns={columns}
          items={data ? data : []}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          onRenderItemColumn={renderColumn}
        //   onRenderDetailsFooter={renderFooter}
        />
      </Stack>
    );
  else return null;
};
