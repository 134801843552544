import { useQuery } from '@apollo/client';
import { IDropdownOption, Stack } from '@fluentui/react';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { CompanyCurrencies } from 'common/graphql/__generated__/CompanyCurrencies';
import { ExtractionTypes_extractionTypes } from 'common/graphql/__generated__/ExtractionTypes';
import { invoiceDateFormat } from 'common/utils/dateFormats';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { FileSelect } from './FileSelect';
import { ModalFooter } from './Footer';
import { FormView } from './Form';
import { ModalHeader } from './Header';
import { DOCUMENT_INITIAL_VALUES } from './constant';
import { validationSchema } from './documentValidations';
import { useStyles } from './index.styles';
import { EntityDocumentValues } from './types';
const CURRENCY_DATA = loader(
  '../../../../common/graphql/CompanyCurrencies.graphql'
);

type requestData = {
  description?: string | null;
  comment?: string | null;
  indexName?: string | null;
  indexDescription?: string | null;
  indexReferenceNumber?: string | null;
  indexTransactionDate?: string | null;
  indexAmount?: number | null;
  indexCurrencyId?: number | null;
  extractionTypeId?: number | null;
};
export interface UploadDocumentMutationProps {
  uploadDocumentData: (
    documentType: DocumentTypeOption,
    data: requestData,
    fileSelected: File[]
  ) => void;
}

export interface DocumentTypeOption extends IDropdownOption {
  key: string | number;
  text: string;
  isAccountingDocument: boolean | undefined;
  extractionTypes: ExtractionTypes_extractionTypes;
}

export interface UploadFileModalProps {
  onClose: () => void;
  documentTypes: DocumentTypeOption[];
  uploadDocument: UploadDocumentMutationProps;
  isOpen: boolean;
}

export const UploadFileModal: React.FC<UploadFileModalProps> = ({
  documentTypes,
  onClose,
  uploadDocument,
  isOpen,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [fileSelected, setFileSelected] = useState<File[]>([]);
  const [fileTypesAllowed, setFileTypesAllowed] = useState<string[]>([]);

  const [documentType, setDocumentType] = useState<
    DocumentTypeOption | undefined
  >();
  const [isDisabled, setIsDisabled] = useState(false);

  let initialValues: EntityDocumentValues = DOCUMENT_INITIAL_VALUES;
  const { data: currencyData } = useQuery<CompanyCurrencies>(CURRENCY_DATA);
  const { isAccountingDocument } = { ...documentType };
  const { uploadDocumentData } = uploadDocument;

  const handleSubmit = (values: EntityDocumentValues) => {
    const {
      indexReferenceNumber,
      indexTransactionDate,
      indexAmount,
      _documentContents,
      indexName,
      comment,
      indexCurrencyId,
      indexDescription,
      extractionTypeId,
    } = { ...values };
    const newValues = {
      indexAmount: indexAmount ? parseFloat(indexAmount!) : null,
      comment,
      indexName,
      description: _documentContents,
      indexCurrencyId,
      indexDescription,
      indexReferenceNumber,
      indexTransactionDate: indexTransactionDate
        ? invoiceDateFormat(indexTransactionDate)
        : null,
      extractionTypeId,
    } as requestData;

    if (extractionTypeId) {
      const filteredFilesList: File[] = fileSelected.filter((file) => {
        return fileTypesAllowed.includes(file.type);
      });
      uploadDocumentData(documentType!, newValues, filteredFilesList);
      if (fileSelected.length - filteredFilesList.length > 0)
        addToast('Files with invalid data types are not uploaded', {
          appearance: 'error',
        });
    } else {
      uploadDocumentData(documentType!, newValues, fileSelected);
    }
    onClose();
  };

  const onDrop = (files: File[]) => {
    if (isAccountingDocument) {
      if (fileSelected.length === 0) {
        setFileSelected([...fileSelected, ...files]);
      } else {
        addToast('You can only add single file', { appearance: 'error' });
      }
    } else setFileSelected([...fileSelected, ...files]);
  };

  const onRemoveFile = (fileIndex: number) => {
    let newFiles = [...fileSelected!].filter((_, index) => fileIndex !== index);
    setFileSelected(newFiles);
  };

  return (
    <Formik<EntityDocumentValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, dirty, errors, setFieldValue }) => {
        const disableSubmit =
          !dirty ||
          Object.keys(errors).length > 0 ||
          isSubmitting ||
          (!documentType?.id && fileSelected.length === 0);
        return (
          <Form>
            <ModalWrapper isOpen={isOpen} isBlocking={true}>
              <Stack className={styles.container}>
                <ModalHeader onClose={onClose} />
                <Stack
                  tokens={{ childrenGap: 20 }}
                  style={{ padding: '100px 25px 150px 25px' }}
                >
                  <CustomDropdown
                    label="Document Type"
                    placeholder="Select"
                    options={documentTypes!}
                    onChange={(_event, option) => {
                      const selectedOptions = option as DocumentTypeOption;
                      setFileSelected([]);
                      setFieldValue('extractionTypeId', null);
                      setFieldValue(
                        'entityDocumentTypeId',
                        selectedOptions.key
                      );
                      setDocumentType(selectedOptions);
                    }}
                    disabled={isDisabled}
                    onClear={() => {
                      setFieldValue('entityDocumentTypeId', null);
                      setDocumentType(undefined);
                    }}
                    required
                  />
                  {documentType && (
                    <FileSelect
                      isMultiple={!isAccountingDocument}
                      onDrop={onDrop}
                      fileSelected={fileSelected}
                      onRemoveFile={onRemoveFile}
                    />
                  )}
                  <FormView
                    currencyData={currencyData!}
                    documentType={documentType!}
                    onInputFieldsDisabled={setIsDisabled}
                    setFileTypesAllowed={setFileTypesAllowed}
                  />
                </Stack>
                <ModalFooter
                  disableSubmit={disableSubmit}
                  onCancelPress={onClose}
                  onUploadPress={() => {
                    submitForm();
                  }}
                />
              </Stack>
            </ModalWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
