import { useMutation } from '@apollo/client';
import { IconButton, Stack, Text, TooltipHost } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { TravelPoliciesType } from '..';
import { AllowanceView } from '../../view/AllowanceView';
import { TravelPolicyDelete, TravelPolicyDeleteVariables } from './__generated__/TravelPolicyDelete';
import { useStyles } from './index.styles';
const TRAVEL_POLICy_DELETE = loader("./TravelPolicyDelete.graphql")

interface PolicyListProps {
  isOpen: boolean;
  travelPolicy: TravelPoliciesType;
  onCollapseClick?: (travelPolicy: TravelPoliciesType) => void;
  onEditClick: (travelPolicy: TravelPoliciesType) => void;
}

export const PolicyList: React.FC<PolicyListProps> = ({
  isOpen,
  travelPolicy,
  onCollapseClick,
  onEditClick,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [visible, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [travelPolicyDelete] = useMutation<
    TravelPolicyDelete,
    TravelPolicyDeleteVariables
  >(TRAVEL_POLICy_DELETE, { errorPolicy: 'all' });

  const {
    id,
    _rowTimestamp,
    _isDeletable,
    description,
    companyCurrency,
    isContractual,
    travelPolicyAllowancesByTravelPolicyId,
  } = { ...travelPolicy }

  const { totalCount } = { ...travelPolicyAllowancesByTravelPolicyId, }

  const getFareClass = (policy: TravelPoliciesType) => {
    switch (true) {
      case policy.isBusinessClassAllowed:
        return "Business";
      case policy.isCharterClassAllowed:
        return "Charter";
      case policy.isEconomyClassAllowed:
        return "Economy";
      case policy.isFirstClassAllowed:
        return "First";
      case policy.isPremiumClassAllowed:
        return "Premium";
    }
  }
  const fareClass = getFareClass(travelPolicy)

  const onTravelPolicyDelete = async (corporateYear: EntityDeleteInput) => {
    const { errors } = await travelPolicyDelete({
      variables: { input: { entityDelete: [corporateYear] } },
      update: (cache, { data }) => {
        if (data?.travelPolicyDelete?.deletedEntities?.length) { }
        const identity = cache.identify({ ...travelPolicy })
        cache.evict({ id: identity });
        cache.gc();
      }
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
  };

  const currency = !!companyCurrency?.name ? `${companyCurrency.isoCode} - ${companyCurrency.name}` : companyCurrency?.isoCode

  return (
    <Stack
      horizontal
      verticalAlign="center"
      className={
        clsx({
          [styles.root]: true,
          [styles.linkLightHover]: true,
          [styles.groupSelected]: isOpen,
        })
      }
    >
      <Stack
        horizontal
        verticalAlign="center"
        className={styles.descriptionCell}
        tokens={{ childrenGap: 10 }}
      >
        {isOpen ? (
          <IconButton
            iconProps={{ iconName: 'ChevronDown' }}
            className={styles.iconButtonColor}
            ariaLabel="Expand"
            onClick={() => onCollapseClick?.(travelPolicy)}
          />
        ) : (
          <IconButton
            iconProps={{ iconName: 'ChevronRight' }}
            className={styles.iconButtonColor}
            ariaLabel="Collapse"
            onClick={() => onCollapseClick?.(travelPolicy)}
          />
        )}
        <Text nowrap variant="medium">
          {description}
        </Text>
        <Text variant="medium" className={styles.groupName}>
          {`(${totalCount})`}
        </Text>
      </Stack>
      <Stack className={styles.currency}>
        <Text nowrap block variant="medium">
          {currency}
        </Text>
      </Stack>
      <Stack className={styles.contractual}>
        <Text nowrap block variant="medium">
          {isContractual ? "Yes" : "No"}
        </Text>
      </Stack>
      <Stack className={styles.fareClass}>
        <Text nowrap block variant="medium">
          {fareClass}
        </Text>
      </Stack>
      <Stack
        horizontal
        className={styles.action}
        tokens={{ childrenGap: 8 }}
        verticalAlign="center"
      >
        <TooltipHost content="Edit">
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            onClick={() => onEditClick(travelPolicy)}
          />
        </TooltipHost>
        <ActionMessageModal
          entityType={EntityType.TravelPolicy}
          action={EntityAction.Delete}
          disabled={!_isDeletable}
          visible={true}
          onConfirm={() =>
            onTravelPolicyDelete({
              id: id!,
              rowTimestamp: _rowTimestamp!,
            })
          }
        />
        {
          isOpen &&
          <AllowanceView
            travelPolicy={travelPolicy}
            visible={visible}
            isNew={true}
            index={0}
            showModal={showModal}
            hideModal={hideModal}
          />
        }
      </Stack>
    </Stack>
  )
}
