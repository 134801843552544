import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  container: {
    // height: '90vh',
    width: 1350,
    overflow: 'hidden',
  },
  loadingFill: {
    height: '90vh',
    width: 1350,
    overflow: 'hidden',
  },
  loaderContainer: {
    width: 300,
  },
  formContainer: {
    margin: '10px 0px 0px 0px',
    width: 1350,
    height: '80vh',
    overflow: 'auto',
  },
}));
