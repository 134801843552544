import { View } from '@react-pdf/renderer';
import { PdfDocumentProps } from '..';
import { SignatureEntityView } from '../Signature';
import React from 'react';

type SignatureSectionProps = PdfDocumentProps & {};
export const SignatureSection: React.FC<SignatureSectionProps> = ({
  invoiceData,
}) => {
  const { nodes } = {
    ...invoiceData?.invoice?.approvalHistorySignaturesByEntityId,
  };
  const signatureNodes = [...nodes!];
  const halfNode = parseInt((signatureNodes.length / 2).toPrecision(1));

  const firstHalf =
    signatureNodes.length > 1
      ? [...signatureNodes.slice(0, halfNode)]
      : [...signatureNodes];

  const secondHalf =
    signatureNodes.length > 1
      ? [...signatureNodes.slice(halfNode, signatureNodes.length)]
      : [];

  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: '#ffffff66',
        padding: 10,

        borderRadius: 5,
      }}
    >
      <View style={{ marginRight: 20 }}>
        {firstHalf.map((item) => {
          return <SignatureEntityView signatureEntity={item} key={item.id} />;
        })}
      </View>
      <View>
        {secondHalf.map((item) => {
          return <SignatureEntityView signatureEntity={item} key={item.id} />;
        })}
      </View>
    </View>
  );
};
