import React from 'react';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';

import { useMutation } from '@apollo/client';
import {
  CreateMessageData,
  CreateNewMessage,
} from 'common/components/CreateNewMessage';
import {
  MessagePaymentCreate,
  MessagePaymentCreateVariables,
} from './__generated__/MessagePaymentCreate';
import { PivotKeys } from 'common/components/MessagesSections';
const ADD_NEW_MESSAGE = loader('./MessagePaymentCreate.graphql');
const GET_PAYMENT_MESSAGE_LIST = loader('../GetMessageList.graphql');
interface CreateNewMessageViewProps {
  selectedKey: string;
  paymentID: string;
  onCreateMessage: () => void;
}

export const NewMessage: React.FC<CreateNewMessageViewProps> = ({
  selectedKey,
  paymentID,
  onCreateMessage,
}) => {
  const { addToast } = useToasts();
  const [createMessage, { loading }] = useMutation<
    MessagePaymentCreate,
    MessagePaymentCreateVariables
  >(ADD_NEW_MESSAGE);

  const messageFilter =
    selectedKey === PivotKeys.sent
      ? { _isOutgoingMessage: { equalTo: true } }
      : { _isIncomingMessage: { equalTo: true } };

  const onSubmit = async (formData: CreateMessageData) => {
    const { messageComment, reminderDates, isResponseRequired, receivers } = {
      ...formData,
    };
    const { communicationChannel, department, user } = { ...receivers };

    const { errors } = await createMessage({
      variables: {
        input: {
          entityId: paymentID!,
          message: { messageComment, reminderDates, isResponseRequired },
          communicationChannel,
          department,
          user,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PAYMENT_MESSAGE_LIST,
          variables: {
            id: paymentID,
            first: 10,
            filter: messageFilter,
          },
        },
      ],
    });
    if (!errors) {
      onCreateMessage();
      addToast('Message sent successfully.', {
        appearance: 'success',
      });
    } else
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
  };

  return <CreateNewMessage onSubmit={onSubmit} loading={loading} />;
};
