import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  resultItem: {
    textDecoration: 'none',
    margin: '0px -20px',
    padding: '6px 20px',
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
    ':active': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },

  customDropdownIcon: {
    background: 'none',
    color: 'inherit',
    padding: 0,
    cursor: 'pointer',
    border: 'none',
    // borderBottom: `1px solid ${theme.palette.neutralPrimary}`,
    height: 33,
  },
}));
