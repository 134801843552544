import { NetworkStatus, useQuery, useReactiveVar } from '@apollo/client';
import {
  IColumn,
  IDetailsRowBaseProps,
  Pivot,
  PivotItem,
  Stack,
  Text,
} from '@fluentui/react';
import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';
import {
  InvoiceSigningSearchFilterTotals,
  InvoiceSigningSearchFilterTotalsVariables,
} from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';
import clsx from 'clsx';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { StatusIcon } from 'common/components/StatusIcon';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { InvoiceFilter, InvoicesOrderBy } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { TransactionSearchBar } from 'postingTracker/batchEdit/unpostedTransactions/TransactionSearchBar';
import {
  Transactions,
  TransactionsVariables,
} from 'postingTracker/batchEdit/unpostedTransactions/list/__generated__/Transactions';
import {
  transactionHistoryPivotTabs,
  transactionHistoryTabMode,
} from 'postingTracker/transactionHistory';
import { getFilterParams } from 'postingTracker/utils.common';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  globalMode,
  setCurrentProfile,
  setGlobalSearchText,
} from 'utility/cache/ui';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { toFilterVariable, toOrderByVariable } from './utils';
const INVOICES = loader(
  '../../../batchEdit/unpostedTransactions/list/Transactions.graphql'
);
const INVOICES_FILTER_TOTAL = loader(
  '../../../../ap/signing/InvoiceSigningSearchFilterTotals.graphql'
);

// export type InvoiceRow = InvoicesSigningSearch_invoiceSigningSearch_nodes & {
export type InvoiceRow = any & {
  name: string | null | undefined;
  date: string | null;
  purchaseOrder: string | null | undefined;
  corporatePeriod: string | null | undefined;
  amount: string;
  status: string | null | undefined;
  requesterDate: string;
  approvers: string;
  approvedDate: string;
  amendedByName: string;
  rejectors: string;
  rejectedDate: string;
  amendedDate: string;
  requesterName: string;
  transactionType: string;
  isDocumentSigningPayment: boolean | undefined;
  production: string | null;
  currencyName: string | null;
};

export interface paymentTrackingNavProps {
  clearSelection?: boolean | undefined;
  reloadList?: boolean | undefined;
  isSigningSearch?: boolean;
}

interface PostedTransactionProps {}

export const PostedTransaction: React.FC<PostedTransactionProps> = () => {
  const commonStyles = useCommonStyles();
  const { state } = useLocation<paymentTrackingNavProps | undefined>();
  const styles = useStyles();
  const renderRef = useRef(false);
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>();
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const [selectedRows, setSelectedRows] = useState<InvoiceRow[]>([]);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>({
    column: 'name',
    direction: 'asc' as OrderDirection,
  });
  const globalState = useReactiveVar(globalMode);

  const currentProfileData = useReactiveVar(setCurrentProfile);
  const batchesTabState = useReactiveVar(transactionHistoryTabMode);
  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });
  const { t: searchParam } = urlParameters;
  const [searchTextValue, setSearchTextValue] = useState<string | null>(
    searchParam! || null
  );

  const {
    loading: invoicesLoading,
    data: invoicesData,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery<Transactions, TransactionsVariables>(INVOICES, {
    variables: {
      isSigningSearch: false,
      isHistorySearch: true,
      first: TABLE_ROWS,
      isLinkSearch: globalState,
      invoiceSigningSearchSearchRequest: {
        searchText: [searchTextValue ? searchTextValue : ''],
        searchHintId: 102,
      },
      orderBy: [
        InvoicesOrderBy.VENDOR_REFERENCE_ASC,
        InvoicesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
        InvoicesOrderBy.PRIMARY_KEY_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: invoicesFilterTotal, refetch: refetchFilterTotal } = useQuery<
    InvoiceSigningSearchFilterTotals,
    InvoiceSigningSearchFilterTotalsVariables
  >(INVOICES_FILTER_TOTAL, {
    variables: {
      isSigningSearch: false,
      isHistorySearch: true,
      isLinkSearch: globalState,
      invoiceSigningSearchSearchRequest: {
        searchText: [searchTextValue ? searchTextValue : ''],
        searchHintId: 102,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (state?.clearSelection) {
      setSelectedRows([]);
    }
    if (state?.reloadList) {
      refetch();
    }

    window.history.replaceState({}, document.title);
  }, [state, refetch]);

  const refetching =
    invoicesLoading && networkStatus !== NetworkStatus.fetchMore;

  let transformedData = refetching
    ? undefined
    : invoicesData?.invoiceSigningSearch?.nodes.map(
        (inv) =>
          ({
            ...inv,
            ...inv.invoiceHistoryApprover,
            id: inv.id,
            transactionType: inv.transactionType?.transactionType,
            businessUnit: inv.businessUnit?.name,
            name: inv.vendorReference,
            description: inv.description,
            department: inv.department?.name,
            invoiceNumber: inv.invoiceNumber,
            date: inv.invoiceDate
              ? dateFormat(dateConvertions(inv.invoiceDate))
              : '',
            corporatePeriod: inv.companyCorporatePeriod?._periodYear,
            amount: inv.controlTotalAmount,
            _baseCurrencyAmount: inv._baseCurrencyAmount,
            _spotCurrencyAmount: inv._spotCurrencyAmount,
            currency: inv.currency?.isoCode,
            status: inv.statusType?.statusType,
            production: inv.company?.name,
            isDocumentSigningPayment:
              inv.transactionType?.isDocumentSigningPayment,
          } as InvoiceRow)
      );

  const _renderItemColumn = (
    item: InvoiceRow | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof InvoiceRow
      ] as string;

      const approverData = item.invoiceHistoryApprover?.approvers
        ? item.invoiceHistoryApprover?.approvers.split(',').join(', ')
        : '';
      switch (column?.key) {
        case 'transactionType':
          return (
            <HighLightActiveLink
              highlightText={globalSearchText}
              text={item?.transactionType}
              preventDefault
              onClick={() => {
                setSelectedInvoice(item.id);
              }}
            />
          );
        case '_baseCurrencyAmount':
        case '_spotCurrencyAmount':
        case 'amount':
          return (
            <AmountColumnTextView
              value={fieldContent}
              searchText={globalSearchText}
            />
          );
        case 'currency':
          return (
            <Stack verticalAlign="center" className={styles.currencyCell}>
              <HighlightTextView
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );

        case 'requesterDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.requesterDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.requesterDate!
                  )}
              </Text>
            </Stack>
          );
        case 'approvedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.approvedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.approvedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'rejectedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.rejectedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.rejectedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'amendedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {item.invoiceHistoryApprover?.amendedDate &&
                  getGlobalDateFormat(
                    item.invoiceHistoryApprover?.amendedDate!
                  )}
              </Text>
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 10 }}
            >
              <HighlightTextView
                className={styles.statusType}
                highlightText={globalSearchText}
                text={convertToTitleCase(item?.status!)!}
              />
              <Stack>
                <StatusIcon
                  approval={false}
                  iconType={item.statusType!}
                  approvalData={item}
                />
              </Stack>
            </Stack>
          );

        case 'approvers':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={approverData!}
              />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const handleSearch = async (showMore: boolean) => {
    if (!showMore) setSelectedRows([]);
    const searchRequest = {
      searchText: [searchTextValue ? searchTextValue : ''],
      searchHintId: 102,
    };

    let filter = searchFilters?.length
      ? ({ and: toFilterVariable(searchFilters) } as InvoiceFilter)
      : undefined;
    const filterParams = getFilterParams(searchFilters || []);
    const searchQueryParam =
      searchTextValue && searchTextValue.length > 0
        ? searchTextValue
        : undefined;
    setUrlParameters({ t: searchQueryParam }, 'replace');

    const variables: TransactionsVariables = {
      isSigningSearch: false,
      isHistorySearch: true,
      first: TABLE_ROWS,
      after: showMore
        ? invoicesData?.invoiceSigningSearch?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
      filter: filter,
      invoiceSigningSearchSearchRequest: searchRequest,
      isLinkSearch: globalState,
    };

    const totalFilterVariables: InvoiceSigningSearchFilterTotalsVariables = {
      isSigningSearch: false,
      isHistorySearch: true,
      invoiceSigningSearchSearchRequest: searchRequest,
      isLinkSearch: globalState,
      ...filterParams,
    };

    if (showMore) await fetchMore?.({ variables });
    else {
      refetch(variables);
      refetchFilterTotal({
        ...totalFilterVariables,
      });
    }
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [searchFilters, searchTextValue, sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    if (!renderRef.current) renderRef.current = true;
    else {
      handleSearchMemo(false);
    }
  }, [searchTextValue, handleSearchMemo]);

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const fieldContent =
        column?.key === 'amount'
          ? totalAmount1
          : column?.key === '_baseCurrencyAmount'
          ? totalAmount2
          : column?.key === '_spotCurrencyAmount'
          ? totalAmount3
          : '';

      return (
        <AmountTextView
          variant="medium"
          className={clsx(styles.amountStack, styles.contentColumnAlignRight)}
          value={fieldContent!}
        />
      );
    };

  const { totalAmount1, totalCount1, totalAmount2, totalAmount3 } = {
    ...invoicesFilterTotal?.invoiceSigningSearchFilterTotals,
  };

  const showFooter =
    !refetching &&
    parseFloat(totalAmount1 || '0.0') > 0 &&
    parseInt(totalCount1?.toString() || '0') > 0;

  const setBaseAmountHeading = () => {
    if (currentProfileData) {
      const updatedColumns: ColumnData[] = gridColumns.map((col) => {
        switch (col.fieldName) {
          case '_baseCurrencyAmount':
            return {
              ...col,
              name: `${currentProfileData.currentUserProfile?.company?.currency?.isoCode} Amount`,
            };
          case '_spotCurrencyAmount':
            return {
              ...col,
              name: `${currentProfileData.currentUserProfile?.company?.currency?.isoCode} Spot Rate`,
            };
          default:
            return { ...col };
        }
      });
      setGridColumns(updatedColumns);
    }
  };

  const setBaseAmountHeadingMemo = useCallback(setBaseAmountHeading, [
    currentProfileData,
  ]);

  useEffect(() => {
    setBaseAmountHeadingMemo();
  }, [setBaseAmountHeadingMemo]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Transaction History</Text>
        </Stack>
      </Stack>

      <Stack tokens={{ padding: '0px 0px 10px 20px', childrenGap: 10 }}>
        <Stack horizontal horizontalAlign="space-between">
          <Stack className={styles.pivotContainer}>
            <Pivot
              selectedKey={batchesTabState}
              onLinkClick={(item: PivotItem | undefined) => {
                transactionHistoryTabMode(
                  item?.props.itemKey || transactionHistoryPivotTabs.batch
                );
              }}
              aria-label="Menu_Actions"
            >
              <PivotItem
                itemKey={transactionHistoryPivotTabs.batch}
                headerText="Batches"
              />
              <PivotItem
                itemKey={transactionHistoryPivotTabs.postedTransaction}
                headerText="Posted Transactions"
              />
            </Pivot>
          </Stack>
        </Stack>
        <Stack style={{ marginRight: 20 }}>
          <TransactionSearchBar
            urlParams={{
              searchParam: searchParam!,
            }}
            columns={gridColumns}
            onRefresh={() => handleSearch(false)}
            onEnterPress={(value) => {
              setSearchTextValue(value);
              setGlobalSearchText(value);
            }}
            onFilterChange={setSearchFilters}
            onToggleVisibility={(columns) => {
              setGridColumns(columns);
            }}
          />
        </Stack>
      </Stack>

      <InfiniteList
        loading={invoicesLoading}
        items={transformedData}
        hasNextPage={invoicesData?.invoiceSigningSearch?.pageInfo.hasNextPage}
        showFooter={showFooter}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true)}
        onSelectionChanged={setSelectedRows}
        selectedRows={selectedRows}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        totalRowsCount={totalCount1}
      />

      {selectedInvoice !== undefined && (
        <TransactionModal
          entityId={selectedInvoice}
          isOpen={selectedInvoice !== undefined}
          onDismiss={() => setSelectedInvoice(undefined)}
        />
      )}
    </>
  );
};
