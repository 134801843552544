import { CommandBarButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import { useFormikContext } from 'formik';
import React from 'react';
import { useStyles } from './index.styles';

interface ModalHeaderProps {
  isEdit: boolean;
  listMode: boolean;
  onClose: () => void;
  onCreate: () => void;
  onBack: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  isEdit,
  listMode,
  onClose,
  onCreate,
  onBack,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { dirty } = useFormikContext();

  const isUnsaved = isEdit && dirty;

  return (
    <Stack
      horizontal
      horizontalAlign={'space-between'}
      className={styles.container}
    >
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Text variant={'xLarge'} className={commonStyles.colorThemePrimary}>
          Card Companies
        </Text>
        <UnsavedIndicator visible={isUnsaved} />
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {listMode ? (
          <PrimaryButton
            iconProps={{ iconName: 'Add' }}
            text={'New'}
            onClick={onCreate}
          />
        ) : (
          <CommandBarButton
            iconProps={{ iconName: 'ChevronLeft' }}
            text={'Back to List'}
            onClick={onBack}
          />
        )}
        <CloseButton onClick={onClose} />
      </Stack>
    </Stack>
  );
};
