import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
    },
    fullWidthContainer: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    containerpadding: { marginTop: 40, padding: 20 },
}
});


