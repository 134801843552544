import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogType,
  IColumn,
  IDetailsList,
  ITooltipHostStyles,
  IconButton,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import clsx from 'clsx';

import {
  DocumentShareCreate,
  DocumentShareCreateVariables,
} from 'common/graphql/__generated__/DocumentShareCreate';
import { DocumentShareLists } from 'common/graphql/__generated__/DocumentShareLists';
import {
  EntityDocumentRowProtection,
  EntityDocumentRowProtectionVariables,
} from 'common/graphql/__generated__/EntityDocumentRowProtection';
import {
  EntityDocumentSingleDelete,
  EntityDocumentSingleDeleteVariables,
  EntityDocumentSingleDelete_entityDocumentSingleDelete_batchTransactionDocument,
  EntityDocumentSingleDelete_entityDocumentSingleDelete_invoiceDocument,
  EntityDocumentSingleDelete_entityDocumentSingleDelete_paymentDocument,
} from 'common/graphql/__generated__/EntityDocumentSingleDelete';
import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import { EntityDocumentSingleDeleteInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AmountTextView } from '../AmountView/AmountTextView';
import { DocumentDataCallout } from '../AttachDocumentModal/DocumentDataCallout';
import { ProtectButton } from '../Buttons';
import { ContactListPicker } from '../ContactsListPicker';
import { DeleteModal } from '../DeleteModal';
import { DownloadButton } from '../DownloadButton';
import { SecureRowLevel, secureRowLevelNode } from '../SecureRowLevel';
import { DocumentMetaForm, EditDocumentModal } from './EditDocumentModal';
import { ViewDocument } from './ViewDocument';
import { useStyles } from './index.styles';

const ENTITY_DOCUMENT_DELETE = loader(
  '../../graphql/EntityDocumentSingleDelete.graphql'
);
const ENTITY_DOCUMENT_SECURITY = loader(
  '../../graphql/EntityDocumentRowProtection.graphql'
);
const DOCUMENT_POOL_DATA = loader('../../graphql/DocumentPool.graphql');
const DOCUMENT_SHARE_LIST = loader('../../graphql/DocumentShareLists.graphql');
const SHARE_DOCUMENTS = loader('../../graphql/DocumentShareCreate.graphql');

const DIALOG_TITLE = 'Are you sure you want to delete this document?';
const DIALOG_SUBTEXT = 'Your document will be removed from this record.';
const dialogShareProps = {
  type: DialogType.close,
  title: 'Share documents',
  closeButtonAriaLabel: 'Close',
  subText: 'Share your documents with other users',
};
const dialogStyles = { main: { maxWidth: 500 } };
export type EntityDocumentType = Omit<
  EntityDocumentsFields,
  'paymentDocument' | 'invoiceDocument'
>;
interface DeletedDocumentType {
  id: string;
  invoiceDocument?: EntityDocumentSingleDelete_entityDocumentSingleDelete_invoiceDocument | null;
  paymentDocument?: EntityDocumentSingleDelete_entityDocumentSingleDelete_paymentDocument | null;
  batchTransactionDocument?: EntityDocumentSingleDelete_entityDocumentSingleDelete_batchTransactionDocument | null;
}
interface DocumentListProps {
  isNew: boolean;
  isShareAndProtectButtonsVisible?: boolean;
  data: EntityDocumentsFields[];
  isDeleteButtonVisible?: boolean;
  secureRowLevel?: secureRowLevelNode[];
  onRemoveRow?: (data: DeletedDocumentType) => void;
  columns?: IColumn[];
  readOnly?: boolean;
  paddingLeft?: number;
  focusTrap?: boolean;
  onDataReceive?: (item: DocumentMetaForm) => void;
  disableUpdate?: boolean;
  autoOpenFirstFile?: boolean;
  onUpdateDocument?: () => void;
}

export interface DocumentViewModalState {
  isOpen: boolean;
  entityDocumentId?: string;
  title?: string;
  _fileType: string;
}

export const DocumentList: React.FC<DocumentListProps> = ({
  data,
  isNew,
  isShareAndProtectButtonsVisible,
  isDeleteButtonVisible,
  secureRowLevel,
  onRemoveRow,
  columns,
  readOnly,
  paddingLeft,
  focusTrap = true,
  onDataReceive,
  disableUpdate,
  autoOpenFirstFile,
  onUpdateDocument,
}) => {
  const _listView = useRef<IDetailsList | null>(null);
  const [showEditModal, { setTrue: openEditModal, setFalse: closeEditModal }] =
    useBoolean(false);
  const styles = useStyles();
  const { addToast } = useToasts();
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block' },
  };

  const [isDeleteDialogHidden, setIsDeleteDialogHidden] = useState(true);
  const [isProtectDialogVisible, setIsProtectDialogVisible] = useState(false);
  const [isShareDialogVisible, setIsShareDialogVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<EntityDocumentsFields | null>(null);

  const [selectedUserID, setSelectedUserID] = React.useState<string[]>([]);
  const [selectedDepartMentID, setSelectedDepartmentID] = React.useState<
    string[]
  >([]);
  const [selectedChannelID, setSelectedChannelID] = React.useState<string[]>(
    []
  );
  const [documentId, setDocumentId] = useState<string | undefined>();

  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      containerClassName: 'ms-dialogMainOverride',
    }),
    [labelId, subTextId]
  );
  const disableShareDocument =
    selectedChannelID.length === 0 &&
    selectedDepartMentID.length === 0 &&
    selectedUserID.length === 0;
  const { data: documentShareListData } = useQuery<DocumentShareLists>(
    DOCUMENT_SHARE_LIST,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [entityDelete, { loading: DeleteEntityLoading }] = useMutation<
    EntityDocumentSingleDelete,
    EntityDocumentSingleDeleteVariables
  >(ENTITY_DOCUMENT_DELETE, { errorPolicy: 'all' });

  const [
    updateDocumentSecurity,
    { loading: updateSecurityLoading, error: updateSecurityError },
  ] = useMutation<
    EntityDocumentRowProtection,
    EntityDocumentRowProtectionVariables
  >(ENTITY_DOCUMENT_SECURITY, { errorPolicy: 'all' });

  const [shareDocuments] = useMutation<
    DocumentShareCreate,
    DocumentShareCreateVariables
  >(SHARE_DOCUMENTS, { errorPolicy: 'all' });

  const _onShareDocument = async () => {
    const entityDocumentArray: string[] = [documentId!];
    const { errors } = await shareDocuments({
      variables: {
        input: {
          entityDocument: entityDocumentArray,
          department: selectedDepartMentID,
          communicationChannel: selectedChannelID,
          user: selectedUserID,
        },
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      setIsShareDialogVisible(false);
      addToast('File shared successfully', { appearance: 'success' });
    }
  };

  const getAppliedAmount = (document: EntityDocumentsFields | undefined) => {
    const result =
      parseFloat(document?.documentAppliedAmount || '0.00') +
      parseFloat(document?.documentOverageAmount || '0.00');
    return result ? result.toString() : null;
  };

  const onRenderItemColumn = (
    item: EntityDocumentsFields | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof EntityDocumentsFields
      ] as string;
      const showEditButton =
        item._isDocumentAmountApplied ||
        item?.documentAppliedAmount ||
        item?.documentRetiredAmount ||
        item?.documentOverageAmount ||
        item?.documentTypes?._isAppliableDocumentType;

      switch (column?.key) {
        case 'fileReference':
          const viewDocumentVisible =
            item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              horizontal
              className={clsx(styles.columnHeight, styles.fileReferenceColumn)}
              verticalAlign="center"
              tokens={{ childrenGap: 6 }}
            >
              <Stack horizontal>
                <ViewDocument
                  defaultOpen={_index === 0 && !!autoOpenFirstFile}
                  document={item}
                  disabled={viewDocumentVisible}
                  focusTrap={focusTrap}
                  paddingLeft={paddingLeft}
                  // onOpen={(currentIndex) => {
                  //   if (currentIndex !== undefined) return currentIndex;
                  //   const sorted = Array.from(
                  //     openDocuments.current.values()
                  //   ).sort();
                  //   const nextIndex = sorted.findIndex((v, i) => v !== i);
                  //   const targetIndex =
                  //     nextIndex !== -1 ? nextIndex : sorted.length;
                  //   openDocuments.current.add(targetIndex);
                  //   return targetIndex;
                  // }}
                  // onDismiss={(index) => openDocuments.current.delete(index)}
                />
                <TooltipHost content="Download" id="tooltipId">
                  <DownloadButton
                    isDisable={item._isProtected!}
                    entityDocumentId={item.id}
                  />
                </TooltipHost>
              </Stack>
              <DocumentDataCallout item={item} />
            </Stack>
          );
        case 'amount':
          const amount = item.documentTypes?._isAppliableDocumentType
            ? getAppliedAmount(item)
            : item?.indexAmount;
          if (item.documentAppliedAmount || item.indexAmount)
            return (
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="end"
                className={styles.onrenderColumnStack}
              >
                <AmountTextView
                  className={clsx(styles.paddingRight10)}
                  value={amount}
                  allowRenderZero
                />
                <Text>{item.currency?.isoCode}</Text>
              </Stack>
            );
          else return null;
        case 'action':
          if (readOnly) return null;

          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontal
              tokens={{ childrenGap: 4 }}
            >
              {isShareAndProtectButtonsVisible && (
                <>
                  <TooltipHost
                    content="Share"
                    id="tooltipId"
                    calloutProps={calloutProps}
                    styles={hostStyles}
                  >
                    <IconButton
                      disabled={disableUpdate || !item.isShareable}
                      onClick={() => {
                        setSelectedDocument(item);
                        setDocumentId(item.id);
                        setIsShareDialogVisible(true);
                      }}
                      iconProps={{ iconName: 'Share' }}
                      ariaLabel="Share"
                    />
                  </TooltipHost>
                  {secureRowLevel && (
                    <ProtectButton
                      isProtected={disableUpdate || !!item._isProtected}
                      onPress={() => {
                        setSelectedDocument(item);
                        setIsProtectDialogVisible(true);
                      }}
                    />
                  )}
                </>
              )}
              {isDeleteButtonVisible && (
                <TooltipHost
                  content="Remove"
                  id="tooltipId"
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <IconButton
                    disabled={disableUpdate ? true : !item._isDeletable}
                    onClick={() => {
                      if (isNew) {
                        onRemoveRow?.({ id: item.id });
                      } else {
                        setSelectedDocument(item);
                        setIsDeleteDialogHidden(false);
                      }
                    }}
                    iconProps={{ iconName: 'Delete' }}
                    ariaLabel="Delete"
                  />
                </TooltipHost>
              )}
              {showEditButton && (
                <TooltipHost content="Edit">
                  <IconButton
                    disabled={
                      !isNew
                        ? disableUpdate
                          ? true
                          : !item._isUpdatable
                        : false
                    }
                    onClick={() => {
                      setSelectedDocument(item);
                      openEditModal();
                    }}
                    iconProps={{ iconName: 'Edit' }}
                  />
                </TooltipHost>
              )}
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  useEffect(() => {
    _listView.current?.forceUpdate();
  }, [disableUpdate]);

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      {DeleteEntityLoading && <ProgressIndicator label="Deleting Document" />}
      <DetailsList
        componentRef={_listView}
        items={data}
        compact
        columns={columns}
        selectionMode={SelectionMode.none}
        onRenderItemColumn={onRenderItemColumn}
        layoutMode={DetailsListLayoutMode.justified}
      />
      <Dialog
        hidden={!isShareDialogVisible}
        onDismiss={() => setIsShareDialogVisible(false)}
        dialogContentProps={dialogShareProps}
        modalProps={modalProps}
        minWidth="600px"
      >
        <Stack className={styles.contactListUpperStack}>
          <ContactListPicker
            data={documentShareListData?.userDocumentShareLists?.nodes!}
            selectedContacts={(items) => {
              setSelectedDepartmentID(items.department);
              setSelectedChannelID(items.communicationChannel);
              setSelectedUserID(items.user);
            }}
          />
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 6 }}
          className={styles.footerStack}
        >
          <PrimaryButton
            onClick={_onShareDocument}
            text="Share Document"
            disabled={disableShareDocument}
          />
          <DefaultButton
            onClick={() => setIsShareDialogVisible(false)}
            text="Cancel"
          />
        </Stack>
      </Dialog>

      <DeleteModal
        isDeletable
        title={DIALOG_TITLE}
        subText={DIALOG_SUBTEXT}
        isOpen={isDeleteDialogHidden}
        onDismiss={() => setIsDeleteDialogHidden(true)}
        onConfirm={async () => {
          setIsDeleteDialogHidden(true);
          const deleteDocument: EntityDocumentSingleDeleteInput = {
            id: selectedDocument?.id || '',
            rowTimestamp: selectedDocument?._rowTimestamp || '',
          };
          const { data, errors } = await entityDelete({
            variables: {
              input: deleteDocument,
            },
          });

          if (errors?.length) {
            setSelectedDocument(null);
            addToast(errors[0].message, {
              appearance: 'error',
            });
          } else {
            setSelectedDocument(null);
            // addToast('Record deleted successfully', { appearance: 'success' });
            onRemoveRow?.({
              id: data?.entityDocumentSingleDelete?.entityDocument?.id!,
              invoiceDocument:
                data?.entityDocumentSingleDelete?.invoiceDocument &&
                data.entityDocumentSingleDelete.invoiceDocument!,
              paymentDocument:
                data?.entityDocumentSingleDelete?.paymentDocument &&
                data.entityDocumentSingleDelete.paymentDocument!,
              batchTransactionDocument:
                data?.entityDocumentSingleDelete?.batchTransactionDocument,
            });
          }
        }}
      />
      {secureRowLevel && (
        <SecureRowLevel
          data={secureRowLevel}
          isUpdatable={selectedDocument?._isUpdatable!}
          secureRowLevelsId={selectedDocument?.rowSecurityId! || ''}
          visibility={isProtectDialogVisible}
          onDismiss={() => {
            setIsProtectDialogVisible(false);
          }}
          updateProtection={{
            loading: updateSecurityLoading,
            updateSecurity: async (selectedLevel) => {
              const inputData =
                selectedLevel !== null
                  ? {
                      entityId: selectedDocument?.id!,
                      rowSecurityId: selectedLevel!,
                    }
                  : {
                      entityId: selectedDocument?.id!,
                      isProtectionRemoval: true,
                    };

              await updateDocumentSecurity({
                variables: {
                  input: inputData,
                },
                update(cache, { data: dataSet }) {
                  cache.modify({
                    id: cache.identify({}),
                    fields: {
                      documentPools(existinPools = []) {
                        const newPoolData = cache.writeFragment({
                          data: dataSet,
                          fragment: DOCUMENT_POOL_DATA,
                        });
                        return [...existinPools, newPoolData];
                      },
                    },
                  });
                },
              });
              return updateSecurityError;
            },
          }}
        />
      )}
      {selectedDocument && (
        <EditDocumentModal
          isOpen={showEditModal}
          isNew={isNew}
          onDismiss={() => {
            setSelectedDocument(null);
            closeEditModal();
          }}
          onUpdate={onUpdateDocument}
          document={selectedDocument}
          onChange={(data) => onDataReceive?.(data)}
        />
      )}
    </Stack>
  );
};
