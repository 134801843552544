import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 20,
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: false,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: '_cardCompanyName',
    name: 'Card Company Name',
    fieldName: '_cardCompanyName',
    minWidth: 200,
    maxWidth: 250,
    ...commonColumnProps,
    styles: colHeaderLeftAlignStyle,
  },
  {
    key: 'apiKey',
    name: 'Api Token',
    fieldName: 'apiKey',
    minWidth: 400,
    maxWidth: 400,
    ...commonColumnProps,
  },
  {
    key: 'edit',
    name: '',
    fieldName: 'edit',
    minWidth: 80,
    maxWidth: 80,
    ...commonColumnProps,
  },
];
