import { useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  currencyFormatter,
  CustomizedAxisTick,
  useColors,
  useStyles,
  ChartProps,
  Chart,
} from '../common';
import { loader } from 'graphql.macro';
import { ApprovalsChartData } from './__generated__/ApprovalsChartData';

const query = loader('./ApprovalsChartData.graphql');
const Component: React.FC<ChartProps<ApprovalsChartData>> = ({
  data,
  height,
  width,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const colors = useColors();
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [animation, setAnimation] = useState(true);
  const history = useHistory();

  return (
    <BarChart
      height={height}
      width={width}
      data={data?.kpiApprovalTypes?.nodes}
      margin={{
        top: 20,
        right: 20,
        left: 10,
        bottom: 20,
      }}
      className={styles.pointer}
      barGap={20}
      maxBarSize={80}
      onClick={() => history.push('/approvals')}
    >
      <CartesianGrid
        strokeDasharray="3 3"
        stroke={theme.palette.neutralQuaternary}
      />
      <XAxis
        dataKey="title"
        interval={0}
        tick={<CustomizedAxisTick fill={theme.palette.neutralPrimary} />}
      />
      <YAxis dataKey="amount" tick={{ fill: theme.palette.neutralPrimary }} />
      <Tooltip
        formatter={(value: number) => [currencyFormatter.format(value), '']}
        separator=""
        contentStyle={{
          backgroundColor: theme.palette.white,
          borderColor: theme.palette.neutralLighter,
        }}
        itemStyle={{
          color: theme.palette.neutralPrimary,
        }}
        cursor={{
          fill: theme.palette.neutralQuaternaryAlt,
          opacity: 0.4,
        }}
        isAnimationActive={false}
        labelStyle={{ fontWeight: 600 }}
      />
      <Bar
        dataKey="amount"
        onMouseEnter={(_, index) => setActiveIndex(index)}
        onMouseLeave={(_, index) => {
          if (activeIndex === index) setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiApprovalTypes?.nodes.map((_entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={colors[index % colors.length][index === activeIndex ? 1 : 0]}
          />
        ))}
      </Bar>
    </BarChart>
  );
};

export const ApprovalsChart: Chart<ApprovalsChartData> = {
  Component,
  query,
  hasData: (data) => !!data?.kpiApprovalTypes?.nodes.length,
};
