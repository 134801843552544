import { Stack } from '@fluentui/react';
import { useStyles } from './index.styles';
import React from 'react';
import { userApproval_userApproval } from '../__generated__/userApproval';
import { DataView } from '../InvoiceDetailedData';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';

interface InvoiceHeaderProps {
  data: Omit<userApproval_userApproval, '__typename'> | undefined | null;
}

export const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({ data }) => {
  const styles = useStyles();
  return (
    <Stack
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
      className={styles.detailsContainer}
    >
      <DataView title={'Requester'} description={data?.requesterName!} />
      <DataView
        title={'Department'}
        description={data?.department?.name ? data?.department?.name : '-'}
      />
      <DataView
        title={'Date'}
        description={
          data?.date1 ? dateFormat(dateConvertions(data?.date1)) : '-'
        }
      />
    </Stack>
  );
};
