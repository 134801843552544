import { useLazyQuery } from '@apollo/client';
import {
  ITextFieldProps,
  ITextFieldStyles,
  IconButton,
  Spinner,
  Stack,
  TextField,
  makeStyles,
  useTheme,
} from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { SupplierCreate_supplierCreate_supplier } from 'settings/account/supplier/view/__generated__/SupplierCreate';
import { TravelAuthorization_travelAuthorization_supplier } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  lookupTravelAgencySearch,
  lookupTravelAgencySearchVariables,
  lookupTravelAgencySearch_lookupTravelAgencySearch_nodes,
} from './__generated__/lookupTravelAgencySearch';
import { TextFieldLabel } from './TextFieldLabel';
import { SupplierLookUpCallout } from 'common/components/SupplierLookUpCallout';
import { LookupSupplierSearch_lookupSupplierSearch_nodes } from 'common/graphql/__generated__/LookupSupplierSearch';
const LOOKUP_TRAVEL_AGENCY_SEARCH = loader(
  './lookupTravelAgencySearch.graphql'
);

const useStyles = makeStyles((theme) => ({
  calloutContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  icon: {
    color: theme.palette.black,
  },
}));

type TravelAgencyAutoCompleteProps = RequiredNameProps<ITextFieldProps> & {
  lookUpCalloutWidth?: number;
  lookupData?: lookupTravelAgencySearch_lookupTravelAgencySearch_nodes | null;
  clearTextField?: boolean | null;
  showAddIcon?: boolean;
  supplierName: string | null;
  supplier: TravelAuthorization_travelAuthorization_supplier | null;
  isNew: boolean;
  onTravelAgencySelect: (
    travelAgency: LookupSupplierSearch_lookupSupplierSearch_nodes
  ) => void;
};

export const TravelAgencyAutoComplete: React.FC<
  TravelAgencyAutoCompleteProps
> = ({
  lookUpCalloutWidth,
  lookupData,
  clearTextField,
  showAddIcon,
  supplierName,
  supplier,
  isNew,
  onTravelAgencySelect,
  ...props
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: props.disabled
        ? theme.palette.neutralLighter
        : theme.palette.white,
    },
  };
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: props.name,
  });

  const renderRef = useRef(false);
  const [calloutVisible, setCalloutVisible] = useState(true);
  const [fullName, setFullName] = useState<string | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<
    lookupTravelAgencySearch_lookupTravelAgencySearch_nodes | undefined | null
  >(lookupData);
  const [selectedSupplier, setSelectedSupplier] =
    useState<TravelAuthorization_travelAuthorization_supplier | null>(null);
  const [disableTextfield, setDisableTextfield] = useState<
    boolean | null | undefined
  >(false);

  const { id, label, disabled, required, ...textfieldProps } = props;

  const [fetchLookUp, { data, loading }] = useLazyQuery<
    lookupTravelAgencySearch,
    lookupTravelAgencySearchVariables
  >(LOOKUP_TRAVEL_AGENCY_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const renderSpinner = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disabled ? null : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              onChange(null);
              setFullName(null);
              setSelectedRecord(null);
              setSelectedSupplier(null);
              setDisableTextfield(false);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };

  const onSupplierCreate = (
    supplier: SupplierCreate_supplierCreate_supplier
  ) => {
    if (supplier) {
      onChange(supplier.id);
      setFullName(supplier._fullName);
      setSelectedSupplier(supplier);
      setDisableTextfield(true);
    }
  };
  const onSupplierDelete = () => {
    onChange(null);
    setFullName(null);
    setSelectedSupplier(null);
    setDisableTextfield(false);
  };

  const lookupCallBack = useCallback(
    (value) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
    },
    [fetchLookUp]
  );

  useEffect(() => {
    if (value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(undefined);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack]);

  useEffect(() => {
    if (value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(value);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack]);

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
      queryCalled();
    }, 350),
    []
  );

  useEffect(() => {
    setFullName(supplierName);
  }, [supplierName]);

  useEffect(() => {
    setSelectedSupplier(supplier);
    if (supplier) {
      setDisableTextfield(true);
    }
  }, [supplier]);

  useEffect(() => {
    if (isNew) setDisableTextfield(false);
  }, [isNew]);

  return (
    <Stack>
      <TextFieldLabel
        disabled={disabled}
        label={label}
        data={selectedRecord}
        required={required}
        showAddIcon={showAddIcon}
        supplier={selectedSupplier}
        onSupplierCreate={onSupplierCreate}
        onSupplierDelete={onSupplierDelete}
      />
      <TextField
        {...textfieldProps}
        id={id}
        autoComplete={'off'}
        errorMessage={error?.message}
        value={fullName || ''}
        onChange={(_event, value) => {
          if (value) {
            setFullName(value);
            callbackLookUp(value, () => {
              if (calloutVisible === false) setCalloutVisible(true);
            });
          } else {
            onChange(null);
            setFullName(null);
            setSelectedSupplier(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textfieldStyles}
        disabled={disabled ? true : disableTextfield ? true : false}
      />
      <Stack className={styles.calloutContainer}>
        {calloutVisible && !loading && (
          <SupplierLookUpCallout
            lookUpCalloutWidth={lookUpCalloutWidth}
            onDismiss={() => {
              setCalloutVisible(false);
            }}
            onSelect={(value) => {
              if (value) {
                onChange(value.id);
                setFullName(value._fullName);
                setSelectedRecord(value);
                setSelectedSupplier(value);
                setDisableTextfield(true);
                onTravelAgencySelect(value);
              }
            }}
            targetID={`#${id!}`}
            data={data?.lookupTravelAgencySearch?.nodes!}
          />
        )}
      </Stack>
    </Stack>
  );
};
