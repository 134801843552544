import { useApolloClient, useQuery } from '@apollo/client';
import { Separator, Stack } from '@fluentui/react';
import { DocumentList } from 'common/components/DocumentList';
import { secureRowLevelNode } from 'common/components/SecureRowLevel';
import { loader } from 'graphql.macro';
import React from 'react';
import {
  GetPayCycleDetails,
  GetPayCycleDetailsVariables,
  GetPayCycleDetails_payCycle,
} from '../../__generated__/GetPayCycleDetails';
import { AttachForm } from './AttachForm';
import { useStyles } from './index.styles';
import { UploadForm } from './UploadForm';
import { columns } from './column.data';
import { PayCycleAvailableDocumentTypes } from 'ap/paymentCycle/__generated__/PayCycleAvailableDocumentTypes';
import { PayCycleUploadDocument_payCycleUploadDocument } from 'ap/paymentCycle/__generated__/PayCycleUploadDocument';
const PAYCYCLE_DETAILS = loader('../../GetPayCycleDetails.graphql');
const PAY_CYCLE_AVAILABLE_DOCUMENT_TYPE = loader(
  '../../../PayCycleAvailableDocumentTypes.graphql'
);
interface AttachSectionProps {
  secureRowLevel: secureRowLevelNode[];
  paymentCycle: GetPayCycleDetails_payCycle;
  onUpload?: (
    fileSelected: File,
    documentData: PayCycleUploadDocument_payCycleUploadDocument,
    toastId: string
  ) => void;
}
export const AttachSection: React.FC<AttachSectionProps> = ({
  secureRowLevel,
  paymentCycle,
  onUpload,
}) => {
  const styles = useStyles();
  const client = useApolloClient();

  const { data: availableDocumentTypes } =
    useQuery<PayCycleAvailableDocumentTypes>(PAY_CYCLE_AVAILABLE_DOCUMENT_TYPE);
  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        <AttachForm
          paymentCycle={paymentCycle}
          availableDocumentTypes={availableDocumentTypes}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={availableDocumentTypes}
          paymentCycle={paymentCycle}
          onUpload={onUpload}
        />
      </Stack>
      {paymentCycle?.entityDocumentsByEntityId?.nodes?.length > 0 && (
        <Stack tokens={{ childrenGap: 10, padding: 10 }}>
          <DocumentList
            isNew={false}
            columns={columns}
            data={paymentCycle?.entityDocumentsByEntityId?.nodes! || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevel}
            onRemoveRow={(data) => {
              const cacheData = client.readQuery<
                GetPayCycleDetails,
                GetPayCycleDetailsVariables
              >({
                query: PAYCYCLE_DETAILS,
                variables: {
                  id: paymentCycle?.id!,
                },
              });
              if (cacheData) {
                const filteredList =
                  cacheData?.payCycle?.entityDocumentsByEntityId.nodes.filter(
                    (ele) => ele.id !== data.id
                  );
                const updatedData: GetPayCycleDetails = {
                  payCycle: {
                    ...cacheData?.payCycle!,
                    entityDocumentsByEntityId: {
                      ...cacheData?.payCycle?.entityDocumentsByEntityId,
                      nodes: filteredList!,
                    },
                  },
                };
                client.writeQuery<
                  GetPayCycleDetails,
                  GetPayCycleDetailsVariables
                >({
                  query: PAYCYCLE_DETAILS,
                  data: updatedData,
                  variables: {
                    id: paymentCycle?.id!,
                  },
                });
              }
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
