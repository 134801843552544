import { IconButton, TooltipHost } from '@fluentui/react'
import React, { useState } from 'react'
import { AvailablePurchaseOrderRevisionsItem } from '..'
import { DocumentViewModalState } from 'common/components/DocumentList'
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal'


interface ViewDocumentProps {
  item: AvailablePurchaseOrderRevisionsItem
}


export const ViewDocument: React.FC<ViewDocumentProps> = ({
  item
}) => {
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  return (
    <>
      <TooltipHost content="View" id="tooltipId">
        <IconButton
          iconProps={{ iconName: 'View' }}
          onClick={
            () => {
              setDocViewState({
                isOpen: true,
                title: item.fileReference,
                entityDocumentId: item.id,
                _fileType: item._fileType!,
              })
            }
          }
        />
      </TooltipHost>
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  )
}
