import * as Yup from 'yup';
export const validationSchema = () =>
  Yup.object({
    companyOrLastName: Yup.string().required('Required').nullable(),
    travelerDepartmentId: Yup.string().required('Required').nullable(),
    travelerAddressesByTravelerId: Yup.array().of(
      Yup.object().shape({
        addressLine1: Yup.string().required('Required').nullable(),
        emailAddress: Yup.string()
          .email('Enter a valid email address')
          .nullable(),
      })
    ),
    travelerDietaryRequirementsByTravelerId: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().when('isDescriptionRequired', {
          is: true,
          then: Yup.string().required('Required').nullable(),
          otherwise: Yup.string().nullable(),
        }),
      })
    ),
  });
