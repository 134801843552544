import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { setTripsModalState } from 'travelAuthorization/TravelPlan/view/FormView/Trips';
import { TripsModal } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';

interface TripModalViewProps {
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
  onRefetch: () => void;
}

export const TripModalView: React.FC<TripModalViewProps> = ({
  travelAuthorizationData,
  onRefetch,
}) => {
  // const watch = useWatch<TravelAuthorizationValues>();
  const tripModalReactiveProps = useReactiveVar(setTripsModalState);
  const { isModalOpen, trip } = { ...tripModalReactiveProps };

  if (travelAuthorizationData && trip && isModalOpen) {
    return (
      <TripsModal
        isOpen={travelAuthorizationData && trip && isModalOpen}
        businessUnitId={null}
        travelAuthorizationData={travelAuthorizationData}
        inputsDisabled
        onSave={onRefetch}
      />
    );
  }
  return null;
};
