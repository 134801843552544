import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  unsavedMessage: {
    border: `solid 1px ${theme.palette.accent}`,
    borderRadius: 3,
    height: 30,
    padding: 5,
  },
  textStyles: {
    color: theme.palette.themePrimary,
  },
}));
