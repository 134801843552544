import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  iconButtonColor: {
    marginLeft: 30,
    color: theme.palette.neutralDark,
  },
  pivotContainer: {
    padding: '0px 25px'
  }
}));
