import {
  ColumnActionsMode,
  IColumn,
  IDetailsColumnStyles,
} from '@fluentui/react';

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

export const columns: IColumn[] = [
  {
    key: 'itemDescription1',
    name: 'Locator',
    fieldName: 'itemDescription1',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: 'itemDescription2',
    name: 'Traveler',
    fieldName: 'itemDescription2',
    minWidth: 200,
    ...commonProps,
  },
  {
    key: 'itemDescription3',
    name: 'Account',
    fieldName: 'itemDescription3',
    minWidth: 120,
    ...commonProps,
  },
  {
    key: 'itemDescription4',
    name: 'Sections',
    fieldName: 'itemDescription4',
    minWidth: 340,
    ...commonProps,
  },
  {
    key: 'itemAmount1',
    name: 'Total',
    fieldName: 'itemAmount1',
    minWidth: 100,
    styles: rightAlignHeaderStyle,
    ...commonProps,
  },
];
