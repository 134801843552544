export const storedDarkMode = (enabled?: boolean) => {
  if (enabled !== undefined) {
    localStorage.setItem('darkMode', enabled.toString());
  }

  const storedValue = localStorage.getItem('darkMode');
  return storedValue !== null
    ? storedValue === 'true'
    : window.matchMedia('(prefers-color-scheme: dark)').matches;
};
