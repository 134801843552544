import { Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import {
  PurchaseOrder_purchaseOrder_purchaseOrderItems_nodes
} from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { showPurchaseOrderModal } from '../..';
import { PurchaseOrderDataType } from '../../..';
import { DeleteItem } from '../../../ItemsList/DeleteItem';
import { useStyles } from '../index.styles';

export const Header: React.FC<{
  isNew: boolean;
  inputsDisabled: boolean;
  purchaseOrderData: PurchaseOrderDataType | undefined;
  selectedItem:
  | PurchaseOrder_purchaseOrder_purchaseOrderItems_nodes
  | undefined;
}> = ({ isNew, purchaseOrderData, selectedItem, inputsDisabled }) => {
  // Styles for design
  const styles = useStyles();

  const {
    formState: { dirtyFields, isDirty },
  } = useFormContext();
  const commonStyles = useCommonStyles();
  const styleText = clsx(commonStyles.colorThemePrimary);

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      className={styles.stickyInsideStack}
    >
      <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
        <Text variant="xLarge">{`${isNew ? 'Order Item' : 'Order Item'}`}</Text>

        <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
          <Text variant="large" className={styleText}>
            {selectedItem?.itemNumber ? `- ${selectedItem?.itemNumber}  ` : ' '}
            ({purchaseOrderData?.purchaseOrder?.purchaseOrderNumber})
          </Text>
        </Stack>
      </Stack>

      {/* Right Side Header */}
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        {selectedItem && (
          <DeleteItem
            inputsDisabled={inputsDisabled}
            item={selectedItem}
            purchaseOrder={purchaseOrderData?.purchaseOrder}
            onSuccess={() =>
              showPurchaseOrderModal({
                poItem: undefined,
                isModalOpen: true,
                isNew: true,
              })
            }
          />
        )}

        <CloseButton
          disabled={isDirty && Object.keys(dirtyFields).length > 0}
          onClick={() => {
            showPurchaseOrderModal({
              poItem: undefined,
              isModalOpen: false,
              isNew: false,
            });
          }}
        />
      </Stack>
    </Stack>
  );
};
