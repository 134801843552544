import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  Stack,
  Text,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { SelectionMode } from '@fluentui/utilities';
import React from 'react';
import { useStyles } from './index.styles';
import { HierarchyModal } from 'common/components/HierarchyModal';

import { getGlobalDateFormat } from 'common/utils/dateFormats';
// import { StatusIcon } from 'approvals/ApprovalInputGroup/StatusIcon';
import { columns } from './column.data';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import clsx from 'clsx';

import {
  BatchTransaction_batchTransaction_approvalHistoriesByEntityId,
  BatchTransaction_batchTransaction_approvalHistoriesByEntityId_nodes,
} from 'postingTracker/batchEdit/batch/view/__generated__/BatchTransaction';
import { StatusIcon } from 'common/components/StatusIcon';

type ItemRow =
  BatchTransaction_batchTransaction_approvalHistoriesByEntityId_nodes;
interface ApprovalHistoryProps {
  data: BatchTransaction_batchTransaction_approvalHistoriesByEntityId;
}
export const ApprovalHistory: React.FC<ApprovalHistoryProps> = ({ data }) => {
  const styles = useStyles();
  const theme = useTheme();

  const renderColumn = (item?: ItemRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;
    const fieldContent = item[column.fieldName as keyof ItemRow] as
      | string
      | null;
    switch (column.key) {
      case 'requestedDate':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text className={styles.amount}>
              {fieldContent ? getGlobalDateFormat(fieldContent) : null}
            </Text>
          </Stack>
        );
      case 'amount1':
        return (
          <Stack
            horizontal
            className={clsx(styles.columnHeight, styles.rightAlignContainer)}
            verticalAlign="center"
          >
            <AmountTextView
              className={clsx(styles.amount)}
              value={item.amount1}
            />
          </Stack>
        );
      case 'statusType':
        return (
          <Stack
            horizontal
            className={styles.columnHeight}
            verticalAlign="center"
            tokens={{ childrenGap: 10 }}
          >
            <StatusIcon approval iconType={item.iconType!} approvalData={item} />
          </Stack>
        );
      case 'viewHierarchy':
        return item.isApprovalTreeViewerAllowed ? (
          <Stack horizontalAlign="end">
            <HierarchyModal
              isApprovalEntry
              entityId={item.id}
              customButton={
                <TooltipHost
                  content="View Hierarchy"
                  calloutProps={{
                    target: `#hierarchy-button-${item.id}`,
                  }}
                >
                  <IconButton
                    iconProps={{ iconName: 'Org' }}
                    ariaLabel="Org"
                    id={`hierarchy-button-${item.id}`}
                    styles={{
                      root: {
                        color: theme.palette.neutralPrimary,
                      },
                    }}
                  />
                </TooltipHost>
              }
            />
          </Stack>
        ) : undefined;
      default:
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text>{fieldContent}</Text>
          </Stack>
        );
    }
  };
  return (
    <DetailsList
      compact
      columns={columns}
      items={data.nodes}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
      onRenderItemColumn={renderColumn}
    />
  );
};
