import {
  Callout,
  DirectionalHint,
  IconButton,
  Stack,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
import Decimal from 'decimal.js';
import React, { useState } from 'react';
import { PaymentsItem } from '..';

export const PaidAmount: React.FC<{
  item: PaymentsItem | undefined;
  globalSearchText: string | undefined;
}> = ({ item, globalSearchText }) => {
  const difference = new Decimal(item?.paidAmount || 0).minus(
    parseFloat(item?._invoiceTotal || '0')
  );

  const isBalanced = difference.toNumber() === 0.0;
  const calloutId = useId(`balanceAmount`);
  const [visible, setVisible] = useState(false);

  const commonStyles = useCommonStyles();
  return (
    <Stack horizontal>
      <AmountColumnTextView
        value={item?.paidAmount || '0.0'}
        searchText={globalSearchText}
      />
      {!isBalanced && (
        <Stack
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        >
          {visible && (
            <Callout
              gapSpace={0}
              target={`#${calloutId}`}
              directionalHint={DirectionalHint.rightTopEdge}
              setInitialFocus
            >
              <Stack tokens={{ padding: 10 }}>
                <Text>Difference</Text>

                <AmountTextView
                  value={difference?.toString() || ''}
                  className={clsx(
                    commonStyles.colorThemePrimary,
                    commonStyles.bold
                  )}
                />
              </Stack>
            </Callout>
          )}

          <IconButton
            id={calloutId}
            iconProps={{ iconName: 'Warning' }}
            className={commonStyles.warning}
          />
        </Stack>
      )}
      {isBalanced && <Stack style={{ width: 30 }} />}
    </Stack>
  );
};
