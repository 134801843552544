import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useStyles } from './index.styles';

interface RoundCheckProps {
  defaultChecked?: boolean;
  onCheckChange: (value: boolean) => void;
}
export const RoundCheck: React.FC<RoundCheckProps> = ({
  defaultChecked = false,
  onCheckChange,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const styles = useStyles();
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className={clsx(styles.container, isChecked && styles.checked)}
      onClick={() => {
        setIsChecked(!isChecked);
        onCheckChange(!isChecked);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        style={{
          position: 'relative',
          opacity: hovered || isChecked ? 1 : 0.3,
          // visibility: hovered || isChecked ? 'visible' : 'hidden',
        }}
      >
        <Icon
          iconName="CircleRing"
          // style={{ fontSize: 20, position: 'absolute', zIndex: 10 }}
          className={clsx(styles.ringIcon, isChecked && styles.iconChecked)}
        />
        <Icon
          iconName="StatusCircleCheckmark"
          // style={{ fontSize: 18, position: 'absolute' }}
          className={clsx(styles.checkIcon, isChecked && styles.iconChecked)}
        />
      </div>
    </div>
  );
};
