import React from 'react';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import { PrimaryButton, Stack, Text } from '@fluentui/react';

const SentryFallback = () => {
  return (
    <Stack
      verticalFill
      verticalAlign="center"
      horizontalAlign="center"
      style={{ height: '75vh' }}
      tokens={{ childrenGap: 20 }}
    >
      <Text variant="xxLargePlus">Sorry about that...</Text>
      <PrimaryButton onClick={() => window.location.reload()}>
        Reload Page
      </PrimaryButton>
    </Stack>
  );
};

export const SentryBoundary: React.FC = ({ children }) => {
  const { user } = useAuth0();

  return (
    <Sentry.ErrorBoundary
      showDialog
      fallback={SentryFallback}
      dialogOptions={{ user: { name: user?.name, email: user?.email } }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
