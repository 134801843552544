import { Stack, Text, makeStyles } from '@fluentui/react'
import { CloseButton } from 'common/components/Buttons'
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TravelPolicyValues } from '../types';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 20,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));

interface HeaderProps {
  headerTitle: string;
  isNew: boolean;
  loading: boolean;
  onDismiss: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  headerTitle,
  isNew,
  loading,
  onDismiss
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { formState: { isDirty, isSubmitting } } = useFormContext<TravelPolicyValues>();
  const showUnsavedIndicator: boolean = !isNew && isDirty && !loading && !isSubmitting;
  return (
    <Stack
      horizontal
      horizontalAlign={'space-between'}
      className={styles.header}
    >
      <Stack
        horizontal
      >
        <Stack
          horizontal
          tokens={{ childrenGap: 10 }}
        >
          <Text
            variant="xLarge"
            className={commonStyles.colorThemePrimary}
          >
            {`${headerTitle} Travel Policy`}
          </Text>
          <UnsavedIndicator
            visible={showUnsavedIndicator}
          />
        </Stack>

      </Stack>
      <CloseButton
        onClick={onDismiss}
      />
    </Stack>
  )
}
