import { useQuery } from '@apollo/client';
import {
  IconButton,
  Pivot,
  PivotItem,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { DocumentPackage } from 'common/components/DocumentPackage';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { SignatureOptions, SignatureView } from 'common/components/Signatures';
import { StampOptions, StamperView } from 'common/components/StamperView';
import { useCommonStyles } from 'common/styles';
import { ApprovalState } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HierarchyData } from './HierarchyData';
import { TransactionDetail } from './TransactionDetail';
import { Invoice, InvoiceVariables } from './__generated__/Invoice';
import { useStyles } from './index.styles';

const INVOICE_DETAILS = loader('./Invoice.graphql');

interface TransactionModalProps {
  isOpen: boolean;
  onDismiss?: () => void;
  entityId: string;
}
export const TransactionModal: React.FC<TransactionModalProps> = ({
  isOpen,
  onDismiss,
  entityId,
}) => {
  const styles = useStyles();
  const ActionConsts = {
    viewDetails: 'viewDetails',
    viewHierarchy: 'viewHierarchy',
    viewSignature: 'viewSignature',
  };

  const { data: invoiceDetailsData, loading } = useQuery<
    Invoice,
    InvoiceVariables
  >(INVOICE_DETAILS, {
    variables: {
      id: entityId,
    },
    skip: !entityId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [selectedKey, setSelectedKey] = useState<string>(
    ActionConsts.viewDetails
  );

  const [signatureData, setSignatureData] = useState<SignatureOptions[]>();
  const [stampData, setStampData] = useState<StampOptions>();

  useEffect(() => {
    if (
      invoiceDetailsData?.invoice &&
      invoiceDetailsData?.invoice.approvalHistorySignaturesByEntityId.nodes
        .length > 0
    ) {
      const signatureOptions: SignatureOptions[] =
        invoiceDetailsData?.invoice.approvalHistorySignaturesByEntityId.nodes.map(
          (item) =>
            ({
              actionDate: item.actionDate,
              additionalInformation: item.additionalInformation,
              digitalSignature: item.digitalSignature,
              id: item.id,
              isApproved: item.isApproved,
              isRejected: item.isRejected,
              isRequested: item.isRequested,
              userName: item.userName,
              entityDocumentId: item.entityDocumentId,
              userOccupationTitle: item.userOccupationTitle,
              userSignatureDocument: {
                _downloadLink: item.userSignatureDocument?._downloadLink,
                fileIndexInformation:
                  item.userSignatureDocument?.fileIndexInformation,
                fileReference: item.userSignatureDocument?.fileReference,
                iconType: item.userSignatureDocument?.iconType,
                id: item.userSignatureDocument?.id,
              },
            } as SignatureOptions)
        );
      setSignatureData(signatureOptions);
    }
  }, [invoiceDetailsData]);

  useEffect(() => {
    if (invoiceDetailsData) {
      const stampOptions: StampOptions = {
        _accountingStampDate: invoiceDetailsData.invoice?._accountingStampDate,
        _accountingStampTransactionReference:
          invoiceDetailsData.invoice?._accountingStampTransactionReference,
        _accountingStampUserName:
          invoiceDetailsData.invoice?._accountingStampUserName,
        _isAccountingEntryStampedComplete:
          invoiceDetailsData.invoice?._isAccountingEntryStampedComplete,
        _isTransactionCancelled:
          invoiceDetailsData.invoice?._isTransactionCancelled,
      };
      setStampData(stampOptions);
    }
  }, [invoiceDetailsData]);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const getTabId = (itemKey: string) => {
    return `ShapeColorPivot_${itemKey}`;
  };

  const getSelectedSection = (key: string) => {
    switch (key) {
      case ActionConsts.viewDetails:
        return (
          <Stack>
            <TransactionDetail
              transactionData={invoiceDetailsData!}
              loading={loading}
            />
          </Stack>
        );

      case ActionConsts.viewHierarchy:
        return <HierarchyData entityId={entityId} />;

      case ActionConsts.viewSignature:
        return (
          <Stack tokens={{ padding: 20 }}>
            {invoiceDetailsData?.invoice?.approvalHistorySignaturesByEntityId
              .nodes.length! > 0 && (
              <SignatureView signatureData={signatureData!} />
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      containerClassName={styles.modalContainer}
      onDismiss={onDismiss}
    >
      <Stack className={styles.container}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <Stack
              className={styles.stickyInsideStack}
              tokens={{ childrenGap: 10 }}
            >
              <TransactionModalHeader
                stampData={stampData!}
                invoiceDetailsData={invoiceDetailsData!}
                onDismiss={onDismiss}
                entityId={entityId}
              />

              <Pivot
                aria-label="Separately Rendered Content Pivot Example"
                selectedKey={selectedKey}
                onLinkClick={handleLinkClick}
                headersOnly={true}
                getTabId={getTabId}
              >
                <PivotItem
                  headerText="Details"
                  itemKey={ActionConsts.viewDetails}
                />
                <PivotItem
                  headerText="Hierarchy"
                  itemKey={ActionConsts.viewHierarchy}
                />
                <PivotItem
                  headerText="Signature"
                  itemKey={ActionConsts.viewSignature}
                />
              </Pivot>
            </Stack>
          </Sticky>

          <Stack>{getSelectedSection(selectedKey)}</Stack>
        </ScrollablePane>
      </Stack>
    </ModalWrapper>
  );
};

interface TransactionModalHeaderProps {
  onDismiss?: () => void;
  entityId: string | undefined;
  stampData: StampOptions;
  invoiceDetailsData: Invoice;
}
const TransactionModalHeader: React.FC<TransactionModalHeaderProps> = ({
  onDismiss,
  entityId,
  stampData,
  invoiceDetailsData,
}) => {
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const styles = useStyles();

  const isApproved =
    invoiceDetailsData?.invoice?.statusType?.approvalState ===
    ApprovalState.APPROVED;

  return (
    <Stack horizontalAlign="space-between" horizontal>
      <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
        <Text variant={'xLarge'}>Transaction</Text>
        <Text variant={'xLarge'} className={commonStyles.colorThemePrimary}>
          {invoiceDetailsData?.invoice?.transactionType?.transactionType
            ? invoiceDetailsData?.invoice?.transactionType?.transactionType
            : ''}
        </Text>
        <TooltipHost content={'Details'}>
          <IconButton
            onClick={() =>
              history.push(
                isApproved
                  ? `/signing/accounting/${entityId}`
                  : `/signing/transaction/${entityId}`
              )
            }
            iconProps={{ iconName: 'ArrowUpRight' }}
            styles={{
              icon: styles.shareIcon,
            }}
          />
        </TooltipHost>
        <DocumentPackage
          documentPackageId={invoiceDetailsData?.invoice?._documentPackageId}
        />
      </Stack>

      <Stack verticalAlign="start" horizontal tokens={{ childrenGap: 10 }}>
        {stampData &&
          invoiceDetailsData?.invoice?._isAccountingEntry &&
          invoiceDetailsData.invoice._isHistory && (
            <StamperView invoiceDetails={stampData} />
          )}
        <CloseButton onClick={() => onDismiss?.()} />
      </Stack>
    </Stack>
  );
};
