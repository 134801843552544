import { Label, Stack } from '@fluentui/react';
import React from 'react';
import { ColumnData } from '../utils';

export const Header: React.FC<{
  columnArray: ColumnData[];
}> = ({ columnArray }) => {

  return (
    <Stack
      horizontal
    >
      {columnArray.map((col, key) => {
        return (
          <Stack
            style={{
              height: 50,
              width: col.maxWidth,
              padding: "0px 10px",
            }}
            key={key}
            horizontalAlign={
              col.key === "distributionAmount" ?
                "end" : "center"
            }
            verticalAlign="center"
          >
            <Label key={key}>{col.name}</Label>
          </Stack>
        );
      })}
    </Stack>
  );
};
