import React, { FC } from 'react';
import { useField } from 'formik';
import { RequiredNameProps } from 'common/types/utility';
import { TextField } from '@fluentui/react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
interface AmountTextFieldProps extends NumberFormatProps {
  label?: string;
  underlined?: boolean;
}

export const AmountTextField: FC<RequiredNameProps<AmountTextFieldProps>> = ({
  onChange,
  thousandSeparator = true,
  ...props
}) => {
  const [field, meta, helpers] = useField<string | undefined | null>(
    props.name
  );
  const { value, onChange: onChangeFormik, ...rest } = field;

  return (
    <NumberFormat
      {...props}
      {...rest}
      value={value || ''}
      errorMessage={meta.error}
      customInput={TextField}
      thousandSeparator={thousandSeparator}
      onValueChange={(valuesObject, info) => {
        if (info.source === 'event')
          helpers.setValue(valuesObject.value || null);
      }}
    />
  );
};
