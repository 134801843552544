import { useQuery } from '@apollo/client';
import { IDropdownOption, Separator, Stack } from '@fluentui/react';
import { FormikDropdown, FormikTextField } from 'common/components';
import { formatDropdownOptions } from 'common/utils';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  UserSetupCommonData,
  UserSetupCommonData_companyDepartmentOccupationTemplates_nodes_departmentOccupationTemplate,
} from 'settings/account/userSetup/__generated__/UserSetupCommonData';
import { ApprovalTypeView } from '../ApprovalTypeView';
import { UserSetupValues } from '../types';
import { AttachableUserProfileDocuments_attachableUserProfileDocuments_nodes } from '../__generated__/AttachableUserProfileDocuments';
import { UserSetupCommon } from '../__generated__/UserSetupCommon';
import { useStyles } from './index.styles';

export type AttachableDataType =
  AttachableUserProfileDocuments_attachableUserProfileDocuments_nodes;
export interface AttachedDocumentsDataType {
  documentTypeId: number | null | undefined;
  entityDocumentId: string[] | null;
  attachedDocuments: AttachableDataType[];
}

const GET_USERPROFILE_COMMON_TYPES = loader(
  '../../UserSetupCommonData.graphql'
);
const COMMON_DATA = loader('../../view/UserSetupCommon.graphql');

interface BasicFormProps {
  toDeleteRows: (id: string, _rowTimestamp: string) => void;
  inputsDisabled: boolean;
  isTemplateControlled: boolean | null | undefined;
}

interface UserTemplateOptions extends IDropdownOption {
  key: string | number;
  text: string;
  departmentOccupationTemplate: UserSetupCommonData_companyDepartmentOccupationTemplates_nodes_departmentOccupationTemplate | null;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  toDeleteRows,
  inputsDisabled,
  isTemplateControlled,
}) => {
  const styles = useStyles();

  const { data: commonData } = useQuery<UserSetupCommonData>(
    GET_USERPROFILE_COMMON_TYPES
  );
  const { data: commonTypes } = useQuery<UserSetupCommon>(COMMON_DATA);

  const [disableAllUserTemplateSelection, setDisableAllUserTemplateSelection] =
    useState<boolean>(false);

  const [isDisableDepartmentId, setIsDisableDepartmentId] =
    useState<boolean>(false);

  const [isDisableUserOccupationTitleId, setIsDisableUserOccupationTitleId] =
    useState<boolean>(false);

  const occTemplateOptions: UserTemplateOptions[] =
    commonData?.companyDepartmentOccupationTemplates?.nodes.map((item) => ({
      key: item.departmentOccupationTemplateId,
      text: item.departmentOccupationTemplate?.name || '',
      departmentOccupationTemplate: item.departmentOccupationTemplate,
    })) || [];

  const { values, setFieldValue } = useFormikContext<UserSetupValues>();

  const roleOptions = formatDropdownOptions(commonData?.roles?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.name!,
  });
  const occTitleOptions = formatDropdownOptions(
    commonData?.companyUserOccupationTitles?.nodes,
    {
      getKey: (item) => item.userOccupationTitleId,
      getText: (item) => item.userOccupationTitles?.userOccupationTitle!,
    }
  );
  const departmentOptions = formatDropdownOptions(
    commonData?.companyDepartments?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
    }
  );
  const departmentGroupOptions = formatDropdownOptions(
    commonData?.companyDepartmentGroups?.nodes,
    {
      getKey: (item) => item.departmentGroupId,
      getText: (item) => item.departmentGroup?.name!,
    }
  );
  const accessPolicyOptions = formatDropdownOptions(
    commonData?.companyDataAccessPolicies?.nodes,
    {
      getKey: (item) => item.dataAccessPolicyId,
      getText: (item) => item.dataAccessPolicy?.name!,
    }
  );
  const userGroupOptions = formatDropdownOptions(
    commonData?.companyUserGroups?.nodes,
    {
      getKey: (item) => item.userGroupId,
      getText: (item) => item.userGroup?.name!,
    }
  );
  const accessGroupOptions = formatDropdownOptions(
    commonData?.companyAccessGroups?.nodes,
    {
      getKey: (item) => item.accessGroupId,
      getText: (item) => item.accessGroup?.name!,
    }
  );
  const tagGroupOptions = formatDropdownOptions(
    commonData?.companyTagGroups?.nodes,
    {
      getKey: (item) => item.tagGroupId,
      getText: (item) => item.tagGroup?.name!,
    }
  );
  const communicationGroupOptions = formatDropdownOptions(
    commonData?.companyCommunicationGroups?.nodes,
    {
      getKey: (item) => item.communicationGroupId,
      getText: (item) => item.communicationGroup?.name!,
    }
  );
  const rankOptions = formatDropdownOptions(commonTypes?.userRankTypes?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.userRank!,
  });
  const securityLevelOptions = formatDropdownOptions(
    commonTypes?.secureRowLevels?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
    }
  );

  useEffect(() => {
    if (!values.departmentOccupationTemplateId) {
      setDisableAllUserTemplateSelection(false);
      setIsDisableDepartmentId(false);
      setIsDisableUserOccupationTitleId(false);
    }
  }, [
    setFieldValue,
    values.departmentId,
    values.departmentOccupationTemplateId,
  ]);

  useEffect(() => {
    if (isTemplateControlled) {
      setDisableAllUserTemplateSelection(true);
      setIsDisableDepartmentId(true);
      setIsDisableUserOccupationTitleId(true);
    }
  }, [isTemplateControlled]);

  const onTransactionTypeChange = async (
    selectedOption: UserTemplateOptions
  ) => {
    if (selectedOption.departmentOccupationTemplate?.departmentId) {
      await setFieldValue(
        'departmentId',
        selectedOption.departmentOccupationTemplate?.departmentId
      );
      setIsDisableDepartmentId(true);
    }

    if (selectedOption.departmentOccupationTemplate?.userOccupationTitleId) {
      await setFieldValue(
        'userOccupationTitleId',
        selectedOption.departmentOccupationTemplate?.userOccupationTitleId
      );
      setIsDisableUserOccupationTitleId(true);
    }
    if (
      selectedOption.departmentOccupationTemplate?.departmentId &&
      selectedOption.departmentOccupationTemplate?.userOccupationTitleId
    ) {
      await setFieldValue(
        'departmentId',
        selectedOption.departmentOccupationTemplate?.departmentId
      );
      await setFieldValue(
        'userOccupationTitleId',
        selectedOption.departmentOccupationTemplate?.userOccupationTitleId
      );
      setIsDisableUserOccupationTitleId(true);
      setIsDisableDepartmentId(true);
    }
    if (
      !selectedOption.departmentOccupationTemplate?.departmentId &&
      selectedOption.departmentOccupationTemplate?.userOccupationTitleId
    ) {
      setIsDisableDepartmentId(false);
      await setFieldValue(
        'userOccupationTitleId',
        selectedOption.departmentOccupationTemplate?.userOccupationTitleId
      );
      setIsDisableUserOccupationTitleId(true);
    }
    if (
      selectedOption.departmentOccupationTemplate?.departmentId &&
      !selectedOption.departmentOccupationTemplate?.userOccupationTitleId
    ) {
      await setFieldValue(
        'departmentId',
        selectedOption.departmentOccupationTemplate?.departmentId
      );
      setIsDisableDepartmentId(true);
      setIsDisableUserOccupationTitleId(false);
    }
    if (
      !selectedOption.departmentOccupationTemplate?.departmentId &&
      !selectedOption.departmentOccupationTemplate?.userOccupationTitleId
    ) {
      setIsDisableDepartmentId(false);
      setIsDisableUserOccupationTitleId(false);
    }
    await setFieldValue('roleId', null);
    await setFieldValue('dataAccessPolicyId', null);
    setDisableAllUserTemplateSelection(true);
  };

  return (
    <Stack grow tokens={{ childrenGap: 20, padding: '20px 35px 30px' }}>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikTextField
            name="name"
            label="Name"
            placeholder="Enter name"
            disabled={inputsDisabled}
            required
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikTextField
            name="description"
            label="Description"
            placeholder="Enter description"
            disabled={inputsDisabled}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikTextField
            name="emailAccount"
            label="Email"
            placeholder="Enter email"
            disabled={inputsDisabled}
            required
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            placeholder="Select"
            label="User Template"
            name="departmentOccupationTemplateId"
            options={occTemplateOptions}
            disabled={inputsDisabled}
            onChange={(event, option) => {
              onTransactionTypeChange(option as UserTemplateOptions);
            }}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Role"
            placeholder="Select"
            options={roleOptions}
            name="roleId"
            disabled={inputsDisabled || disableAllUserTemplateSelection}
            required={!disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Occupation"
            placeholder="Select"
            options={occTitleOptions}
            name="userOccupationTitleId"
            disabled={inputsDisabled || isDisableUserOccupationTitleId}
            required
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Department"
            placeholder="Select"
            options={departmentOptions}
            name="departmentId"
            disabled={inputsDisabled || isDisableDepartmentId}
            required
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Department Access"
            placeholder="Select"
            name="departmentGroupId"
            options={departmentGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Access Policy"
            placeholder="Select"
            options={accessPolicyOptions}
            name="dataAccessPolicyId"
            disabled={inputsDisabled || disableAllUserTemplateSelection}
            required={!disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="User Group"
            placeholder="Select"
            name="userGroupId"
            options={userGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="User Group Access"
            placeholder="Select"
            name="accessGroupId"
            options={accessGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Tagging Group"
            placeholder="Select"
            name="tagGroupId"
            options={tagGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal grow verticalAlign="end" tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Communication Group"
            placeholder="Select"
            name="communicationGroupId"
            options={communicationGroupOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
        <Stack.Item className={styles.flex50}>
          <FormikDropdown
            label="Rank"
            placeholder="Select"
            name="userRankTypeId"
            options={rankOptions}
            disabled={inputsDisabled || disableAllUserTemplateSelection}
          />
        </Stack.Item>
      </Stack>
      <Stack verticalAlign="end" className={styles.secureLevelContainer}>
        <FormikDropdown
          label="Security Level"
          placeholder="Select"
          name="secureRowLevelId"
          options={securityLevelOptions}
          disabled={inputsDisabled || disableAllUserTemplateSelection}
        />
      </Stack>
      <Separator />
      <ApprovalTypeView
        approvalTypes={commonData?.approvalTypes?.nodes || []}
        deleteRow={toDeleteRows}
        inputsDisabled={
          inputsDisabled ||
          values.departmentOccupationTemplateId !== null ||
          isTemplateControlled!
        }
      />
    </Stack>
  );
};
