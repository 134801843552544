import { useReactiveVar } from '@apollo/client';
import {
  ContextualMenu,
  IStyle,
  IconButton,
  Modal,
  Spinner,
  useTheme,
} from '@fluentui/react';
import { setPanelWidth } from 'common/components/DraggablePanel';
import useDownloadLink from 'common/hooks/useDownloadLink';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import React, { useEffect, useState } from 'react';
import { closeOpenedDocument } from 'utility/cache/ui';
import { useStyles } from './index.styles';

export type DocumentViewModalProps = {
  entityDocumentId?: string;
  _fileType: string;
  title?: string;
  isOpen: boolean;
  onDismiss: () => void;
  paddingLeft?: number;
  paddingTop?: number;
  centerAlign?: boolean;
  focusTrap?: boolean;
  existingDownloadLink?: string | null;
  orderIndex?: number;
  layerIndex?: number;
};

export const VIEW_DOC_MODAL_WIDTH = 800;
export const DocumentViewModal: React.FC<DocumentViewModalProps> = ({
  entityDocumentId,
  title,
  isOpen,
  onDismiss,
  paddingLeft,
  paddingTop = 20,
  _fileType,
  centerAlign = false,
  focusTrap = true,
  existingDownloadLink,
  orderIndex = 0,
  layerIndex = 0,
}) => {
  const { width } = getWindowDimensions();
  const panelWidth = useReactiveVar(setPanelWidth);
  const closeFile = useReactiveVar(closeOpenedDocument);

  const panelPaddingLeft =
    panelWidth && width
      ? width - (panelWidth + VIEW_DOC_MODAL_WIDTH) - orderIndex * 20
      : 20;

  const styles = useStyles();
  const theme = useTheme();
  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralSecondaryAlt,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  const getLink = useDownloadLink();
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setUrl(undefined);
    setLoading(false);
    onDismiss();
    closeOpenedDocument(false);
  };

  useEffect(() => {
    setUrl(undefined);
    setLoading(false);
  }, [entityDocumentId]);

  useEffect(() => {
    if (entityDocumentId && !loading && !url) {
      setLoading(true);
      if (existingDownloadLink) {
        setUrl(existingDownloadLink);
      } else {
        (async () => {
          const url = (await getLink(entityDocumentId)) || undefined;
          setUrl(url);
        })();
      }
    }
  }, [entityDocumentId, getLink, loading, url, existingDownloadLink]);

  const centerAligned: IStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const padLeftValue = paddingLeft ? paddingLeft : panelPaddingLeft;

  const startAligned: IStyle = {
    overflow: 'hidden',
    justifyContent: 'flex-start',
    paddingLeft: padLeftValue,
    paddingTop,
  };

  // const zIndex = 10000001 + (activeOrderIndex === orderIndex ? 10 : 0);
  const zIndex = 10000001 + layerIndex;

  useEffect(() => {
    if (closeFile) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeFile]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      isModeless
      forceFocusInsideTrap
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
      styles={{
        root: centerAlign ? centerAligned : startAligned,
        main: {
          zIndex: zIndex + 1,
        },
      }}
      layerProps={{
        styles: {
          root: {
            zIndex: zIndex,
          },
        },
      }}
      containerClassName={styles.container}
      scrollableContentClassName={styles.scrollableContent}
    >
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          styles={iconButtonStyles}
          onClick={onClose}
        />
      </div>

      <div className={styles.content}>
        {loading && (
          <Spinner label="Loading document..." labelPosition="right" />
        )}
        {_fileType === 'pdf' ? (
          <object
            data={url}
            className={loading ? styles.hidden : styles.viewer}
            onLoad={() => {
              setLoading(false);
            }}
            aria-label={title}
          ></object>
        ) : (
          <img
            alt=""
            src={url}
            className={loading ? styles.hidden : styles.viewer}
            onLoad={() => {
              setLoading(false);
            }}
            aria-label={title}
          ></img>
        )}
      </div>
    </Modal>
  );
};
