import * as React from 'react';
import { Stack, List, DefaultButton, ProgressIndicator } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { useStyles } from './index.styles';
import { MessageListItem } from '..';
import { MessageRow } from '../MessageRow';
import { PivotKeys } from '../index';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  ResetUserNewMessageCount,
  ResetUserNewMessageCountVariables,
} from 'common/graphql/__generated__/ResetUserNewMessageCount';
import { useCallback, useEffect } from 'react';
const GET_USER_MESSAGE_RESET_COUNT = loader(
  '../../../graphql/ResetUserNewMessageCount.graphql'
);

interface ReceivedMessageListProps {
  data: MessageListItem[];
  loadingMessages: boolean;
  refetchList: (listName?: string) => void;
  onLoadMore?: () => void;
  hasNextPage?: boolean;
  showNavigation?: boolean;
}

export const ReceivedMessageList: React.FunctionComponent<
  ReceivedMessageListProps
> = ({
  data = [],
  loadingMessages,
  onLoadMore,
  hasNextPage,
  refetchList,
  showNavigation,
}) => {
  const styles = useStyles();
  const isFetchingData = loadingMessages && !data?.length;

  const [messageResetUser] = useMutation<
    ResetUserNewMessageCount,
    ResetUserNewMessageCountVariables
  >(GET_USER_MESSAGE_RESET_COUNT);

  const resetMessageCountMemo = useCallback(messageResetUser, [data]);

  useEffect(() => {
    const isNewMessage = data.filter((item) => item._isNewMessage === true);
    if (isNewMessage.length)
      resetMessageCountMemo({
        variables: {
          input: {},
        },
      });
  }, [data, resetMessageCountMemo]);

  return (
    <Stack tokens={{ padding: '10px 0px 0px 0px' }}>
      {isFetchingData && (
        <Stack className={styles.listLoaderMainContainer} data-is-scrollable>
          <ProgressIndicator barHeight={4} />
        </Stack>
      )}
      <List
        items={data}
        onRenderCell={(item, index) => (
          <MessageRow
            refetchList={refetchList}
            item={item}
            key={item?.id}
            listType={PivotKeys.received}
            isLastRow={!(index! < data.length - 1)}
            showNavigation={showNavigation}
          />
        )}
      />
      {hasNextPage && data?.length && (
        <Stack className={styles.loadMoreContainer} onClick={onLoadMore}>
          <DefaultButton text="Load more" />
        </Stack>
      )}
      <NoDataView
        title="No messages found"
        show={!data.length && !loadingMessages}
      />
    </Stack>
  );
};
