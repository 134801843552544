import { CommandButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { TravelAuthorization_travelAuthorization } from '../../__generated__/TravelAuthorization';
import { MovementDetailModel } from './DetailModal';

interface MovementOrders {
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
}
export const MovementOrders: React.FC<MovementOrders> = ({
  travelAuthorizationData,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <Stack>
      {travelAuthorizationData?._isTripMovementAllowed && (
        <CommandButton
          iconProps={{ iconName: 'FollowUser' }}
          text="Movement Orders"
          onClick={() => setVisible(true)}
        />
      )}

      {visible && travelAuthorizationData && (
        <MovementDetailModel
          isOpen={visible && !!travelAuthorizationData}
          onClose={() => setVisible(false)}
          travelAuthorizationData={travelAuthorizationData}
        />
      )}
    </Stack>
  );
};
