import React, { useEffect, useState } from 'react'
import { DocumentPackageEmailModalProps } from '..';
import { IconButton, TooltipHost } from '@fluentui/react';
import { FormModal } from '../FormModal';

type CreateNewEmailModalProps = Omit<DocumentPackageEmailModalProps, "emailDocumentsByEntityId">

export const CreateNewEmailModal: React.FC<CreateNewEmailModalProps> = ({
  emailDocument,
  loading,
  isSuccessful,
  onSubmitValues,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (isSuccessful)
      setModalVisible(false)
  }, [isSuccessful])

  return (
    <>
      <TooltipHost content="Create New Mail">
        <IconButton
          onClick={() => setModalVisible(true)}
          iconProps={{ iconName: 'Read' }}
        />
      </TooltipHost>
      <FormModal
        isOpen={modalVisible}
        emailDocument={emailDocument}
        loading={loading}
        isSuccessful={isSuccessful}
        onSubmitValues={onSubmitValues}
        onClose={() => setModalVisible(false)}
      />
    </>
  )
}
