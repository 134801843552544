import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    firstColumnStyle: {
      paddingLeft: 100,
    },
    onrenderColumnStack: {
      height: 25,
    },
    contentColumnAlignRight: {
      display: 'block',
      textAlign: 'right',
      paddingRight: 25,
    },
    amount: {
      textAlign: 'right',
      paddingRight: 25,

      display: 'block',
    },
    amountTotal: {
      fontWeight: 'bold',
      color: theme.palette.themePrimary,
    },
  };
});
