import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  NoteApprovalCreate,
  NoteApprovalCreateVariables,
} from './__generated__/NoteApprovalCreate';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import {
  NotesSection,
  NotesSectionProps,
} from 'common/components/NotesSection';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';

const NOTE_APPROVAL_SETUP_CREATE = loader('./NoteApprovalCreate.graphql');

type ApprovalSetupNotesSectionProps = Pick<
  NotesSectionProps,
  'listRefreshRequest'
> & {
  id: string;
  data: NotesEntityFields[];
  loading?: boolean;
};

export const ApprovalSetupNotesSection: React.FC<ApprovalSetupNotesSectionProps> =
  ({ id, data, loading, listRefreshRequest }) => {
    const [createNote, { loading: createNoteInProgress, error }] = useMutation<
      NoteApprovalCreate,
      NoteApprovalCreateVariables
    >(NOTE_APPROVAL_SETUP_CREATE);

    const noteAddMutation: NoteAddMutationProps = {
      loading: createNoteInProgress,
      createNote: async (formData) => {
        const { noteComment, isShared } = { ...formData };
        await createNote({
          variables: {
            input: {
              note: {
                entityId: id,
                noteComment: noteComment!,
                isShared: isShared!,
              },
            },
          },
        });
        if (!error) listRefreshRequest?.();
        return error;
      },
    };

    return (
      <NotesSection
        dataLoading={loading!}
        noteAddMutation={noteAddMutation}
        addingNoteInProgress={createNoteInProgress}
        listRefreshRequest={listRefreshRequest}
        data={data}
      />
    );
  };
