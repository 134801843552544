import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { AccountingEntryInvoice } from 'ap/signing/accountingEntry/view/__generated__/AccountingEntryInvoice';
import {
  InvoiceSigningAccountingUnStamper,
  InvoiceSigningAccountingUnStamperVariables,
} from 'ap/signing/accountingEntry/view/__generated__/InvoiceSigningAccountingUnStamper';
import { AccountingEntryValues } from 'ap/signing/accountingEntry/view/types';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DialogWrapper } from 'common/components/DialogWrapper';
import { TABLE_ROWS } from 'common/constants';
import {
  InvoiceBatchesOrderBy,
  InvoiceSigningAccountingUnstamperInput,
  InvoiceSigningApprovalRevokeInput,
  InvoicesOrderBy,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';
import { InvoiceSigningApprovalRevoke, InvoiceSigningApprovalRevokeVariables } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceSigningApprovalRevoke';

interface FooterActionsProps {
  invoiceDetailsData: AccountingEntryInvoice | undefined;
  batchTransactionId: string | undefined;
  handleSubmit: () => void;
  onDismiss: () => void;
  refetch: () => void;
}
const UNSTAMP_TRANSACTION = loader(
  '../../../../../../../../ap/signing/accountingEntry/view/InvoiceSigningAccountingUnStamper.graphql'
);
const INVOICE_SIGNING_APPROVAL_REVOKE = loader(
  '../../../../../../../../common/components/Modules/TransactionEdit/graphql/InvoiceSigningApprovalRevoke.graphql'
);
const INVOICES = loader(
  '../../../../../../../../common/components/Modules/TransactionEdit/graphql/InvoicesSigningSearch.graphql'
);
const INVOICE_BATCHES = loader(
  '../../../../../../../batchEdit/unpostedTransactions/InvoiceBatchModal/InvoiceBatches.graphql'
);
const BATCH_TRANSACTION = loader('../../../../BatchTransaction.graphql');

export const FooterActions: React.FC<FooterActionsProps> = ({
  handleSubmit,
  invoiceDetailsData,
  refetch,
  batchTransactionId,
  onDismiss,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [hideConfirmRemoveStampdDialog, { toggle: toggleRemoveStampdDialog }] =
    useBoolean(true);
  const {
    formState: { isDirty },
  } = useFormContext<AccountingEntryValues>();
  const [unStampTransaction] = useMutation<
    InvoiceSigningAccountingUnStamper,
    InvoiceSigningAccountingUnStamperVariables
  >(UNSTAMP_TRANSACTION, { errorPolicy: 'all' });
  const [invoiceSigningApprovalRevoke] = useMutation<
    InvoiceSigningApprovalRevoke,
    InvoiceSigningApprovalRevokeVariables
  >(INVOICE_SIGNING_APPROVAL_REVOKE, { errorPolicy: 'all' });

  const CONFIRM_STAMP_REMOVE_DIALOG_TITLE = 'Are you sure?';
  const CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT =
    'This will remove the stamp and remove the transaction from the batch.';
  const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
  const CONFIRM_AMEND_DIALOG_SUBTEXT =
    'This will remove the Transaction signing from the approval cycle and require re-approval.';
  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: 20, padding: 20 }}
      >
        <PrimaryButton
          className={styles.diabledButton}
          text="Save"
          onClick={handleSubmit}
          disabled={!isDirty}
        />
        <PrimaryButton
          className={styles.diabledButton}
          text="Cancel"
          onClick={onDismiss}
        />
        {invoiceDetailsData?.invoice?._isApprovalRevocable && (
          <PrimaryButton
            className={styles.diabledButton}
            text="Amend Transaction"
            onClick={() => toggleConfirmAmendDialog()}
          />
        )}
        {invoiceDetailsData?.invoice?._isAccountingEntryStampedComplete && (
          <PrimaryButton
            className={styles.diabledButton}
            text="Remove Stamp"
            onClick={() => toggleRemoveStampdDialog()}
          />
        )}
        <DialogWrapper
          hidden={hideConfirmRemoveStampdDialog}
          onDismiss={toggleRemoveStampdDialog}
          dialogContentProps={{
            title: CONFIRM_STAMP_REMOVE_DIALOG_TITLE,
            type: DialogType.largeHeader,
            subText: CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT,
          }}
        >
          <DialogFooter>
            <PrimaryButton
              text="Unstamp"
              className={styles.unstampButton}
              onClick={async () => {
                toggleRemoveStampdDialog();
                const inputVariables: InvoiceSigningAccountingUnstamperInput = {
                  batchTransactionId: batchTransactionId!,
                  id: invoiceDetailsData?.invoice?.id!,
                  rowTimestamp: invoiceDetailsData?.invoice?._rowTimestamp!,
                };

                const { errors } = await unStampTransaction({
                  variables: {
                    input: inputVariables,
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: [
                    {
                      query: INVOICE_BATCHES,
                      variables: {
                        id: batchTransactionId,
                        orderBy: [
                          InvoiceBatchesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
                          InvoiceBatchesOrderBy.PRIMARY_KEY_ASC,
                        ],
                      },
                    },
                    {
                      query: BATCH_TRANSACTION,
                      variables: {
                        id: batchTransactionId,
                      },
                    },
                  ],
                });
                if (errors?.length)
                  addToast(errors[0].message, {
                    appearance: 'error',
                  });
                else {
                  refetch?.();
                  addToast('Unstamp successful', {
                    appearance: 'success',
                  });
                }
              }}
            />
            <DefaultButton onClick={toggleRemoveStampdDialog} text="Cancel" />
          </DialogFooter>
        </DialogWrapper>
        <ConfirmDialog
          isAmendButton
          hidden={hideConfirmAmendDialog}
          title={CONFIRM_AMEND_DIALOG_TITLE}
          subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
          onDismiss={toggleConfirmAmendDialog}
          onConfirm={async () => {
            toggleConfirmAmendDialog();
            const inputVariables: InvoiceSigningApprovalRevokeInput = {
              entityId: invoiceDetailsData?.invoice?.id!,
              rowTimestamp: invoiceDetailsData?.invoice?._rowTimestamp!,
            };
            const { errors } = await invoiceSigningApprovalRevoke({
              variables: {
                input: inputVariables,
              },
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: INVOICES,
                  variables: {
                    isSigningSearch: false,
                    isHistorySearch: false,
                    first: TABLE_ROWS,
                    orderBy: [
                      InvoicesOrderBy._CREATED_DATE_DESC,
                      InvoicesOrderBy.PRIMARY_KEY_ASC,
                    ],
                  },
                },
              ],
            });
            if (errors?.length)
              addToast(errors[0].message, {
                appearance: 'error',
              });
            else {
              onDismiss();
              addToast('Approval amended successfully', {
                appearance: 'success',
              });
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
