import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { RequiredNameProps } from 'common/types/utility';
import { IDatePickerProps } from '@fluentui/react';
import { onFormatDate } from 'common/utils/dateFormats';
import { CustomDatePicker } from '../CustomDatePicker';
import { UseControllerProps } from 'react-hook-form';
type FormikDatePickerProps = IDatePickerProps & {
  onClear?: () => void;
  rules?: UseControllerProps['rules'];
  defaultValue?: any;
};
export const FormHookDatePicker: FC<
  RequiredNameProps<FormikDatePickerProps>
> = ({ name, onClear, rules, ...props }) => {
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <CustomDatePicker
            styles={{
              statusMessage: {
                marginTop: 0,
              },
            }}
            formatDate={onFormatDate}
            // @ts-ignore
            value={value ? new Date(value) : null}
            onSelectDate={(date) => {
              onChange(date);
            }}
            textField={{ errorMessage: error?.message }}
            onClear={() => {
              onChange(null);
              onClear?.();
            }}
            {...props}
          />
        );
      }}
      name={name}
      rules={rules}
    />
  );
};
