import React from 'react';
import {
  Stack,
  Persona,
  Icon,
  IIconStyles,
} from '@fluentui/react';
import { useStyles } from './index.styles';

interface GroupedPersonaProps {
  isMultiplePeople: boolean;
  name: string;
}
const GROUP_LIST_ICON: IIconStyles = {
  root: {
    fontSize: 22,
    color: 'white',
    borderWidth: 0.08,
  },
};
export const GroupedPersona: React.FC<GroupedPersonaProps> = ({ isMultiplePeople, name }) => {
  const styles = useStyles();
  return (
    <>
      {isMultiplePeople ? (
        <Stack className={styles.groupIconContainer}>
          <Icon iconName={'Group'} styles={GROUP_LIST_ICON} />
        </Stack>
      ) : (
        <Persona
          onRenderPrimaryText={() => {
            return null;
          }}
          text={name}
          coinSize={42}
        />
      )}
    </>
  );
};
