import { IDropdownOption } from '@fluentui/react';
import { DocumentTypeOptions } from 'documents/personalPool/UploadDocumentsForm';
import { EntityDocumentValues } from '../../types';
import { GetEntityDocument_entityDocument } from '../../__generated__/GetEntityDocument';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes_nodes } from 'documents/__generated__/GetDocumentPoolCommonData';
import { FormHookDropdown } from 'common/components/FormHooksFields';
import React from 'react';
interface CustomDropDown {
  isEdit: boolean;
  defaultDocumentTypeId: number | undefined;
  documentData: GetEntityDocument_entityDocument | null;
  isDocumentProtected: boolean;
  documentOptions?: DocumentTypeOptions[];
  setDocumentType: (data: DocumentTypeOptions) => void;
  setIsAccountingDocument: (isAccounting: boolean | undefined) => void;
  setFileSelected: () => void;
  disabled?: boolean;
  onDocumentTypeChange?: (options: IDropdownOption[]) => void;
  hideExtractionDropdown: () => void;
  setIsSigningDocument: (param: boolean) => void;
}
export const CustomDropDown: React.FC<CustomDropDown> = ({
  isEdit,
  defaultDocumentTypeId,
  documentData,
  isDocumentProtected,
  documentOptions,
  setDocumentType,
  setIsAccountingDocument,
  setFileSelected,
  disabled,
  onDocumentTypeChange,
  hideExtractionDropdown,
  setIsSigningDocument,
}) => {
  const { setValue, getValues } = useFormContext<EntityDocumentValues>();
  const values = getValues();
  useEffect(() => {
    if (defaultDocumentTypeId && !documentData?.id) {
      setValue(
        'entityDocumentTypeId',
        defaultDocumentTypeId ? defaultDocumentTypeId : null
      );
    }
  }, [defaultDocumentTypeId, documentData, documentOptions, setValue]);
  useEffect(() => {
    let extractionTypesArray: GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes_nodes[] =
      [];

    extractionTypesArray =
      documentOptions?.find((x) => x.key === values.entityDocumentTypeId)
        ?.extractionTypes.nodes || [];

    onDocumentTypeChange?.(
      extractionTypesArray.map((ele) => ({
        key: ele.id,
        text: ele.extractionType,
      }))
    );
  }, [values.entityDocumentTypeId, documentOptions, onDocumentTypeChange]);

  const isSigningDocument =
    documentData?.documentTypes?.isSigningRequired &&
    !documentData?.documentTypes?.isAccountingDocument;

  return (
    <>
      <FormHookDropdown
        label="Document Type"
        placeholder="Select"
        options={documentOptions || []}
        name="entityDocumentTypeId"
        disabled={
          disabled ||
          (isSigningDocument
            ? false
            : !!documentData &&
              (isDocumentProtected || !documentData._isDocumentTypeUpdatable))
        }
        onChange={(_event, option) => {
          const selectedOptions = option as DocumentTypeOptions;
          setDocumentType(selectedOptions);
          setIsAccountingDocument(selectedOptions.isAccountingDocument!);
          setIsSigningDocument(
            !!(
              selectedOptions.isSigningRequired &&
              !selectedOptions.isAccountingDocument
            )
          );
          if (selectedOptions.isAccountingDocument) setFileSelected();
          if (isEdit) hideExtractionDropdown();
        }}
        required
      />
    </>
  );
};
