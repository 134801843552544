import { Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OccupationValues } from '../types';
import { useStyles } from './index.styles';

interface HeaderProps {
  title: string;
  isEdit: boolean;
  onClose: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onClose, title, isEdit }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const {
    formState: { isDirty },
  } = useFormContext<OccupationValues>();

  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20,
      }}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.row}>
        <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
          {title}
        </Text>
        <UnsavedIndicator visible={isEdit && isDirty} />
      </Stack>
      <CloseButton onClick={onClose} />
    </Stack>
  );
};
