import { ColumnActionsMode } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
};

export const columns: ColumnData[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'tagGroupItemsByTagGroupId',
    name: 'Tags',
    fieldName: 'tags',
    minWidth: 500,
    maxWidth: 1100,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'action',
    name: '',
    fieldName: 'action',
    minWidth: 100,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
    isResizable: false,
  },
];
