import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 20,
  },
  groupHeading: {
    fontWeight: FontWeights.semibold,
    marginLeft: 20,
  },
  preferredRate: {
    width: 220,
  },
  notesContainer: {
    width: '100%',
    marginTop: 20,
  },
  notes: {
    width: '100%',
  },
}));
