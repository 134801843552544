import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  title: {
    fontWeight:'bold',
    fontSize: 12,
    paddingBottom: 8,
    color:'#3b3a39'
  },
  value: {
    fontSize: 11, 
    color: '#909090',
    paddingRight:5

  },
  formContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
});
