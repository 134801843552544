import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    // flex: 1,
    width: 250,
  },
  infoViewWrapper: {
    minHeight: 80,
  },
}));
