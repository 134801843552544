import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  dialogStyles: {
    alignItems: 'self-start',
    padding: 25,
    paddingBottom: 0,
  },
  container: {
    width: 600,
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
}));
