import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  chartContainer: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.neutralLight,
    borderRadius: '2px',
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  chartTitle: {
    paddingBottom: 6,
    fontWeight: FontWeights.semibold,
  },
  titleLoading: {
    color: theme.palette.neutralQuaternary,
  },
  titleReady: {
    color: theme.palette.neutralSecondary,
  },
}));
