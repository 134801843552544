import { PrimaryButton, Separator, Stack } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  PurchaseOrderItemFormProps,
  PurchaseOrderItemLayout,
} from '../interface';

type DiscountItemProps = PurchaseOrderItemLayout & {
  children?: React.ReactNode;
};

export const DiscountItem: React.FC<DiscountItemProps> = ({
  selectedItemType,
  purchaseOrderData,
  commonData,
  children,
}) => {
  const { setValue } = useFormContext<PurchaseOrderItemFormProps>();

  const watchValues = useWatch<PurchaseOrderItemFormProps>({
    name: ['subjectToAmount', 'itemUnitPrice', 'itemExtendedAmount'],
  });

  const watchSubjectToAmount: string | undefined = watchValues?.[0]?.toString();
  const watchItemUnitPrice: string | undefined = watchValues?.[1]?.toString();

  const fieldDisabled = !purchaseOrderData?.purchaseOrder?._isUpdatable;
  const expenditureTypeOption = useMemo(() => {
    if (
      selectedItemType?.isExpenditureTypeAllowed &&
      !selectedItemType.isUnitOfMeasureRequired
    )
      return (
        commonData?.expenditureTypes?.nodes.map((item) => ({
          key: item.id!,
          text: item.expenditureType! || '',
          ...item,
        })) || []
      );
    else {
      return (
        selectedItemType?.unitOfMeasureGroup?.expenditureTypes.nodes.map(
          (item) => ({
            key: item.id!,
            text: item.expenditureType! || '',
            ...item,
          })
        ) || []
      );
    }
  }, [selectedItemType, commonData]);

  const getCalculate = useCallback(() => {
    setValue(
      'subjectToAmount',
      purchaseOrderData?.purchaseOrder?._subjectToDiscount || null
    );
  }, [purchaseOrderData, setValue]);

  useEffect(() => {
    const discount = (
      (parseFloat(watchItemUnitPrice || '0.00') / 100) *
      parseFloat(watchSubjectToAmount || '0.00')
    ).toFixed(2);

    setValue('itemExtendedAmount', discount, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchItemUnitPrice, watchSubjectToAmount]);

  useEffect(() => {
    if (selectedItemType?.isAutoCalculated) {
      getCalculate();
    }
  }, [selectedItemType, getCalculate]);

  return (
    <Stack>
      <Stack
        tokens={{
          padding: '20px 20px',
          childrenGap: 10,
        }}
      >
        <Stack horizontal>
          <Stack
            horizontal
            grow
            horizontalAlign="space-between"
            tokens={{ childrenGap: 20 }}
          >
            {selectedItemType?.isExpenditureTypeAllowed && (
              <Stack.Item style={{ width: 255 }}>
                <FormHookDropdown
                  label="Expenditure Type"
                  placeholder="Select"
                  options={expenditureTypeOption}
                  name="expenditureTypeId"
                  required
                  disabled={fieldDisabled}
                />
              </Stack.Item>
            )}

            <Stack.Item grow={2}>
              <FormHookTextField
                name="description"
                label="Description"
                placeholder="Description"
                disabled={fieldDisabled}
                required
              />
            </Stack.Item>
          </Stack>
          <Stack grow={1} horizontalAlign="end"></Stack>
        </Stack>
        <Separator />
        <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 20 }}>
          <Stack style={{ width: 250 }}>
            <FormHookAmount
              name="subjectToAmount"
              label="Subject to Discount"
              placeholder="Subject To Discount"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={false}
              disabled={fieldDisabled || !!selectedItemType?.isAutoCalculated}
            />
          </Stack>

          <FormHookAmount
            name="itemUnitPrice"
            label="Rate"
            decimalScale={2}
            fixedDecimalScale
            placeholder="Enter rate"
            allowNegative={false}
            suffix="%"
            disabled={fieldDisabled}
          />

          <FormHookAmount
            name="itemExtendedAmount"
            label="Discount"
            placeholder="Enter Discount"
            fixedDecimalScale
            decimalScale={2}
            allowNegative={false}
            style={{ width: 200 }}
            disabled={fieldDisabled || !!selectedItemType?.isAutoCalculated}
          />
        </Stack>
        {!selectedItemType?.isAutoCalculated && (
          <Stack horizontal>
            <PrimaryButton
              text="Get Discounted Items"
              onClick={getCalculate}
              disabled={fieldDisabled}
            />
          </Stack>
        )}
      </Stack>

      {children}
    </Stack>
  );
};
