import { Stack, Text } from '@fluentui/react'
import React from 'react';
import { useStyles } from './index.styles'

interface HeaderProps {
  selectedUsername: string;
  availableUsername?: string;
}

export const Header: React.FC<HeaderProps> = ({
  selectedUsername,
  availableUsername,
}) => {
  const styles = useStyles();

  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        verticalAlign="baseline"
        wrap
      >
        <Text variant="xLarge">
          Are you sure you want to replace existing pending approvals for
        </Text>
        <Text
          variant="xLarge"
          className={styles.username}
        >
          {selectedUsername}
        </Text>
        <Text variant="xLarge">
          sent to
        </Text>
        <Text
          variant="xLarge"
          className={styles.username}
        >
          {availableUsername}?
        </Text>
      </Stack>
    </Stack>
  )
}
