import { makeStyles } from '@fluentui/react';

const RED_900 = '#B71C1C';

export const useStyles = makeStyles((theme) => ({
  appliedTotalStyle: {
    backgroundColor: theme.palette.neutralLighter,
    padding: '10px 25px 10px 25px',
  },
  colorThemePrimaryDark: { color: theme.palette.themeDark },
  textAlignRight: { textAlign: 'right' },
  errorRedColor: { color: RED_900 },
  amount: {
    marginLeft: "6px !important"
  }
}));
