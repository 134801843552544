import { CommandBarButton, DefaultButton, IconButton, Link, MessageBar, MessageBarType, Stack, Text, TooltipHost } from '@fluentui/react'
import clsx from 'clsx';
import { FormikCheckBox, FormikTextField } from 'common/components';
import { FieldArray, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { CONTACT_INITIAL_VALUES } from '../constants';
import { SupplierValues } from '../types';
import { useStyles } from './index.styles'
interface ContactsProps {
  isEdit: boolean;
  isUpdatable: boolean | null | undefined;
}
export const Contacts: React.FC<ContactsProps> = ({
  isEdit,
  isUpdatable
}) => {
  const styles = useStyles();
  const { values } = useFormikContext<SupplierValues>();
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [contactIndex, setContactIndex] = useState<number>(
    isEdit ? -1 : 0
  );
  const contactFieldName = `supplierContactsBySupplierId[${contactIndex}]`;
  const isContactBlank = values.supplierContactsBySupplierId &&
    values.supplierContactsBySupplierId[0]?.contactName === null;
  const isDisabled = isUpdatable === false ? true : false;
  return (
    <Stack tokens={{ padding: "10px 25px" }}>
      <FieldArray name="supplierContactsBySupplierId">
        {({ remove, insert }) => (
          <>
            {
              showContactForm && contactIndex !== -1 ?
                <>
                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    className={styles.defaultButtonContainer}
                  >
                    <CommandBarButton
                      iconProps={{ iconName: "Back" }}
                      text="Contact List"
                      onClick={() => {
                        if (isContactBlank) {
                          remove(0);
                        }
                        setContactIndex(-1);
                        setShowContactForm(false);
                      }}
                      style={{
                        width: 120,
                        height: 40
                      }}
                    />

                  </Stack>
                  {
                    isContactBlank &&
                    <Stack style={{ marginBottom: 20 }}>
                      <MessageBar messageBarType={MessageBarType.warning}>Please Enter Name</MessageBar>
                    </Stack>
                  }
                  <Stack
                    horizontal
                    tokens={{
                      childrenGap: 20
                    }}
                    className={styles.formRow}
                  >

                    <Stack.Item className={styles.fieldContainer}>
                      <FormikTextField
                        name={`${contactFieldName}.contactName`}
                        label="Name"
                        placeholder="Name"
                        disabled={isDisabled}
                      />
                    </Stack.Item>
                    <Stack.Item className={styles.fieldContainer}>
                      <FormikTextField
                        name={`${contactFieldName}.telephoneNumber`}
                        label="Phone"
                        placeholder="Phone"
                        disabled={isDisabled}
                      />
                    </Stack.Item>
                    <Stack.Item className={styles.fieldContainer}>
                      <FormikTextField
                        name={`${contactFieldName}.emailAddress`}
                        label="Email"
                        placeholder="Email"
                        disabled={isDisabled}
                      />
                    </Stack.Item>
                    <Stack
                      horizontal
                      verticalAlign="end"
                      horizontalAlign="space-around"
                      className={styles.fieldContainer}
                    >
                      <Stack.Item className={styles.sendCheckBoxContainer}>
                        <FormikCheckBox
                          name={`${contactFieldName}.isSendEmailAccount`}
                          label="Send"
                          disabled={isDisabled}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <FormikCheckBox
                          name={`${contactFieldName}.isReceiveEmailAccount`}
                          label="Receive"
                          disabled={isDisabled}
                        />
                      </Stack.Item>
                    </Stack>
                  </Stack>
                </>
                :
                <>
                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    verticalAlign="center"
                    className={styles.defaultButtonContainer}
                  >
                    <Text variant="xLarge">Contacts</Text>
                    <DefaultButton
                      text="Add Contact"
                      iconProps={{ iconName: 'Add' }}
                      onClick={() => {
                        setContactIndex(0);
                        setShowContactForm(true);
                        insert(
                          0, { ...CONTACT_INITIAL_VALUES }
                        )
                      }}
                      disabled={isDisabled}
                    />
                  </Stack>
                  {
                    values.supplierContactsBySupplierId?.map((ele, index) => {
                      return (
                        <Stack
                          horizontal
                          verticalAlign="center"
                          className={clsx(styles.root)}
                          key={index}
                        >
                          <Stack className={styles.rowCell}>
                            <Link
                              className={styles.addressTypeLabel}
                              onClick={(event) => {
                                event.preventDefault();
                                setContactIndex(index);
                                setShowContactForm(true);
                              }}
                            >
                              {ele.contactName}
                            </Link>
                            <Text className={styles.addressSubtitle} variant="small">
                              {/* To remove 'null' from address string. */}
                              {ele.telephoneNumber ? `${ele.telephoneNumber}, ` : ""}
                              {ele.emailAddress ? `${ele.emailAddress}` : ""}
                            </Text>
                          </Stack>
                          {
                            !isDisabled &&
                            <Stack
                              horizontal
                              tokens={{ childrenGap: 50 }}
                            >
                              <TooltipHost content="Delete">
                                <IconButton
                                  iconProps={{ iconName: 'delete' }}
                                  className={styles.deleteIcon}
                                  ariaLabel="Delete"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                />
                              </TooltipHost>
                            </Stack>
                          }
                        </Stack>
                      )
                    })
                  }
                </>
            }
          </>
        )}
      </FieldArray>
    </Stack>
  )
}
