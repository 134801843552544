import { useApolloClient, useMutation } from '@apollo/client';
import { Stack } from '@fluentui/react';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { loader } from 'graphql.macro';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  PurchaseOrderApprovalRevision,
  PurchaseOrderApprovalRevisionVariables,
} from './__generated__/PurchaseOrderApprovalRevision';
import { useHistory } from 'react-router-dom';
import {
  PurchaseOrderDocumentPackageStatus,
  PurchaseOrderDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/PurchaseOrderDocumentPackageStatus';
import { DocumentPackageStatusType } from 'common/types/globalTypes';
const APPROVAL_REVISION = loader('./PurchaseOrderApprovalRevision.graphql');
const PO_DOCUMENT_PACKAGE_STATUS = loader(
  '../../../../../../common/graphql/DocumentPackageSubscription/PurchaseOrderDocumentPackageStatus.graphql'
);
interface ReviseModalProps {
  purchaseOrder: PurchaseOrder | undefined;
  onClose?: () => void;
  isOpen: boolean;
}

const SUBTEXT =
  'This will cancel the approved purchase order and create a revised purchase order that can be resubmitted for approval';
const TITLE = 'Are you sure ?';

export const ReviseModal: React.FC<ReviseModalProps> = ({
  onClose,
  purchaseOrder,
  isOpen,
}) => {
  const client = useApolloClient();
  const history = useHistory();
  const { addToast } = useToasts();
  const [createRevision] = useMutation<
    PurchaseOrderApprovalRevision,
    PurchaseOrderApprovalRevisionVariables
  >(APPROVAL_REVISION, { errorPolicy: 'all' });
  // On dismiss
  const onDismiss = () => {
    onClose?.();
  };

  const onConfirm = async () => {
    const { _rowTimestamp, id } = { ...purchaseOrder?.purchaseOrder };
    const { errors, data } = await createRevision({
      variables: {
        input: {
          rowTimestamp: _rowTimestamp!,
          entityId: id!,
        },
      },
    });

    if (errors?.length) {
      addToast(errors?.[0].message, { appearance: 'error' });
    } else {
      onClose?.();
      addToast('Successfully created a Revision', { appearance: 'success' });
      history.replace(
        `/purchase-orders/purchase-order/${data?.purchaseOrderApprovalRevision?.revisedApprovalEntity}`
      );

      const observer = client.subscribe<
        PurchaseOrderDocumentPackageStatus,
        PurchaseOrderDocumentPackageStatusVariables
      >({
        query: PO_DOCUMENT_PACKAGE_STATUS,
        variables: {
          id: purchaseOrder?.purchaseOrder?.id!,
        },
      });

      const subscription = observer.subscribe(({ data, errors }) => {
        if (errors)
          addToast('Errors received while Subscribing to document package', {
            appearance: 'error',
          });
        else {
          const { document, status } = {
            ...data?.purchaseOrderDocumentPackageStatus,
          };

          if (status === DocumentPackageStatusType.REGENERATION_FAILURE) {
            addToast(
              'Report generation failed. Document package was not created',
              { appearance: 'error' }
            );
          }
          if (status === DocumentPackageStatusType.FAILURE) {
            addToast('Error while creating Document package ', {
              appearance: 'error',
            });
          }
          if (document) {
            addToast('Document package created', {
              appearance: 'success',
            });
            client.cache.modify({
              id: client.cache.identify({
                ...purchaseOrder?.purchaseOrder,
              }),
              fields: {
                stampedEntityDocumentId: () => {
                  return document.id;
                },
              },
            });
          }
        }
        subscription.unsubscribe();
      });
    }
  };

  return (
    <Stack>
      <ConfirmDialog
        hidden={!isOpen}
        title={TITLE}
        subText={SUBTEXT}
        onDismiss={onDismiss}
        maxWidth={400}
        onConfirm={onConfirm}
        confirmText={'Revise'}
      />
    </Stack>
  );
};
