import { INavLinkGroup, Nav, Stack, Text, useTheme } from '@fluentui/react';
import { AppRouter } from 'common/types/globalTypes';
import React from 'react';
import { useEffect } from 'react';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { expandedNav } from 'utility/cache/ui';
import { useStyles } from './index.styles';

export interface SettingsOption {
  name: string;
  icon?: string;
  path: string;
  component: React.FC;
  routeName?: AppRouter | undefined;
}

interface SettingsBaseProps {
  sections: SettingsOption[];
  basePath: string;
  title: string;
}

export const SettingsBase: React.FC<SettingsBaseProps> = ({
  sections,
  basePath,
  title,
}) => {
  const history = useHistory();
  const styles = useStyles();
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    expandedNav(false);
  }, []);

  const links: INavLinkGroup[] = [
    {
      links: sections.map((s) => ({
        name: s.name,
        url: s.path,
        key: s.path,
        iconProps: {
          iconName: s.icon,
          styles: {
            root: {
              paddingLeft: 8,
              color: theme.palette.neutralDark,
            },
          },
        },
      })),
    },
  ];

  const selectedLinkKey = sections
    .map((section) => section.path)
    .find((path) => matchPath(location.pathname, path));

  return (
    <Stack horizontal disableShrink className={styles.container}>
      <Stack className={styles.nav}>
        <Text variant="mediumPlus" className={styles.navHeader}>
          {title}
        </Text>
        <Nav
          onLinkClick={(event, element) => {
            event?.preventDefault();
            if (element) history.push(element.url);
          }}
          groups={links}
          selectedKey={selectedLinkKey}
        />
      </Stack>
      <Stack grow>
        <Switch>
          <Route
            path={basePath}
            exact
            render={() => <Redirect to={sections[0].path} />}
          />
          {sections.map((section) => (
            <Route
              key={section.path}
              path={section.path}
              component={section.component}
            />
          ))}
        </Switch>
      </Stack>
    </Stack>
  );
};
