import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles(() => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  detailsContainer: {
    marginTop: '10px !important',
    padding: '0px 10px',
  },
  flex50: {
    flex: 1,
  },
}));
