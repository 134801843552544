import { useQuery } from '@apollo/client';
import { ProgressIndicator, Stack, Text, makeStyles } from '@fluentui/react';
import { ToggleButton } from 'common/components/Buttons';
import { Chip } from 'common/components/Chip';
import { NoDataView } from 'common/components/DataPlaceholders';
import { Colors } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DepartmentGroupRow } from 'settings/account/departmentGroups/list';
import { DepartmentGroupValues } from '../types';
import {
  GroupDepartments,
  GroupDepartments_departments_nodes,
} from './__generated__/GroupDepartments';

type GroupDepartmentsItems = GroupDepartments_departments_nodes;

const GROUP_DEPARTMENTS = loader('./GroupDepartments.graphql');

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 200,
  },
  groupItems: {
    maxHeight: 250,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '0px 20px',
  },
  heading: {
    marginLeft: 20,
  },
  progressContainer: {
    padding: '0px 20px',
  },
}));

interface GroupItemsProps {
  departmentGroup?: DepartmentGroupRow;
}

export const GroupItems: React.FC<GroupItemsProps> = ({ departmentGroup }) => {
  const styles = useStyles();
  const [groupItems, setGroupItems] = useState<GroupDepartmentsItems[]>([]);
  const [selectedGroupItems, setSelectedGroupItems] = useState<
    GroupDepartmentsItems[]
  >([]);

  const { id, departmentGroupItems } = { ...departmentGroup };

  const isEdit = !!id;

  const { control, setValue } = useFormContext<DepartmentGroupValues>();

  const departmentGroups = useWatch({
    name: 'departmentGroupItems',
    control,
  });

  const { loading, data } = useQuery<GroupDepartments>(GROUP_DEPARTMENTS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const onToggleClick = (department: GroupDepartmentsItems) => {
    if (!!department.id) {
      const isExists = departmentGroups?.some(
        (item) => item.departmentId === department.id
      );
      if (!isExists) {
        setSelectedGroupItems([...selectedGroupItems, department]);
        setValue(
          'departmentGroupItems',
          [...(departmentGroups || []), { departmentId: department.id }],
          { shouldDirty: true }
        );
        const filteredArray = groupItems.filter(
          (item) => item.id !== department.id
        );
        setGroupItems(filteredArray);
      }
    }
  };

  const onRemoveClick = (department: GroupDepartmentsItems) => {
    if (!!department.id) {
      const isExists = selectedGroupItems?.some(
        (item) => item.id === department.id
      );
      if (isExists) {
        const filteredArray = selectedGroupItems.filter(
          (item) => item.id !== department.id
        );
        setSelectedGroupItems(filteredArray);
        setGroupItems([department, ...groupItems]);
        setValue(
          'departmentGroupItems',
          [
            ...filteredArray.map((ele) => ({
              departmentId: ele.id,
            })),
          ],
          { shouldDirty: true }
        );
      }
    }
  };

  useEffect(() => {
    const { nodes } = { ...data?.departments };
    const departmentGroupArray: GroupDepartmentsItems[] =
      departmentGroupItems?.nodes.map((item) => ({
        id: item.departmentId,
        name: item.department?.name || '',
      })) || [];
    if (!!departmentGroupArray.length) {
      setSelectedGroupItems(departmentGroupArray);
      const filteredArray =
        nodes?.filter((item) =>
          departmentGroupArray.every((ele) => ele.id !== item.id)
        ) || [];
      if (!!nodes?.length) {
        setGroupItems(filteredArray);
      }
    } else {
      if (!!nodes?.length) {
        setGroupItems(nodes);
      }
    }
  }, [departmentGroupItems, data, isEdit]);

  return (
    <Stack
      className={styles.container}
      tokens={{
        childrenGap: 20,
      }}
    >
      <Text variant="large" className={styles.heading}>
        Available Departments
      </Text>
      {loading ? (
        <Stack className={styles.progressContainer}>
          <ProgressIndicator />
        </Stack>
      ) : (
        <Stack
          wrap
          horizontal
          tokens={{
            childrenGap: 10,
          }}
          className={styles.groupItems}
        >
          {groupItems?.map((item, index) => {
            return (
              <Stack key={index}>
                <ToggleButton
                  onClick={() => onToggleClick(item)}
                  key={item.id}
                  text={item.name}
                  iconName={'Add'}
                />
              </Stack>
            );
          })}
          <Stack grow>
            <NoDataView
              show={!groupItems?.length}
              title="No records available."
            />
          </Stack>
        </Stack>
      )}
      <Text variant="large" className={styles.heading}>
        Selected Departments
      </Text>
      <Stack
        wrap
        horizontal
        tokens={{
          childrenGap: 10,
        }}
        className={styles.groupItems}
      >
        {selectedGroupItems?.map((item, index) => {
          return (
            <Stack key={index}>
              <Chip
                // disabled={loadingRemoveTag || isDirty}
                text={item.name}
                onClick={() => onRemoveClick(item)}
                color={Colors.RED}
              />
            </Stack>
          );
        })}
        <Stack grow>
          <NoDataView
            show={!selectedGroupItems?.length}
            title="No records available"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
