import {
  IChoiceGroupOption,
  IDropdownOption,
  Label,
  Stack,
} from '@fluentui/react';
import {
  FormikChoiceGroup,
  FormikTextField,
  FormikToggle,
} from 'common/components';
import { AdvancedFormikDropdown } from 'common/components/FormikFields/AdvancedFormikDropdown';
import { FormikAccountAutocomplete } from 'common/components/FormikFields/FormikAccountAutocomplete';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { UserDefaultDropdownData } from 'Preferences/__generated__/UserDefaultDropdownData';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import React from 'react';
import { DelegateApprover } from './DelegateApprover';
import { TransactionLayout } from 'common/types/globalTypes';
import { choiceGroupStyles, rowSelectorGroupStyles } from './index.styles';
import { useFormikContext } from 'formik';
interface DefaultsViewProps {
  dropdownData: UserDefaultDropdownData;
  userDefaults: UserDefaults_userDefaults_nodes | undefined;
}

export const DefaultsView: React.FC<DefaultsViewProps> = ({
  dropdownData,
  userDefaults,
}) => {
  const formikNameInitial: string = 'userDefaultPatch';

  const { setFieldValue } = useFormikContext();
  const {
    companyDepartments,
    companyCurrencies,
    projects,
    companyCorporatePeriods,
    companyBusinessUnits,
  } = { ...dropdownData };
  const options: IChoiceGroupOption[] = [
    { key: TransactionLayout.LISTVIEW_SIZE_LARGE, text: 'Large' },
    { key: TransactionLayout.LISTVIEW_SIZE_MEDIUM, text: 'Medium' },
    { key: TransactionLayout.LISTVIEW_SIZE_SMALL, text: 'Small' },
  ];
  const rowSelectorOptions: IChoiceGroupOption[] = [
    { key: 'Normal', text: 'Normal' },
    { key: 'Bold', text: 'Bold' },
  ];

  const businessUnitOptions: IDropdownOption[] =
    companyBusinessUnits?.nodes.map((item) => ({
      key: item.id!,
      text: item.name!,
    })) || [];

  const projectsOptions: IDropdownOption[] =
    projects?.nodes.map((item) => ({
      key: item.id!,
      text: item.name!,
    })) || [];

  const corporatePeriodOptions: IDropdownOption[] =
    companyCorporatePeriods?.nodes?.map((item) => ({
      key: item.id!,
      text:
        item._periodYear +
          ' (' +
          dateFormat(dateConvertions(item.startDate)) +
          ' - ' +
          dateFormat(dateConvertions(item.endDate)) +
          ')' || '',
    })) || [];

  const currencyOptions: IDropdownOption[] =
    companyCurrencies?.nodes?.map((item) => ({
      key: item.id!,
      text: item.name!,
    })) || [];

  const companyDepartmentOptions: IDropdownOption[] =
    companyDepartments?.nodes?.map((item) => ({
      key: item.id!,
      text: item.name!,
    })) || [];

  return (
    <Stack tokens={{ padding: 20, childrenGap: 20 }}>
      <AdvancedFormikDropdown
        label="Currency"
        options={currencyOptions}
        placeholder="Select Currency"
        name={`${formikNameInitial}.currencyId`}
      />

      <AdvancedFormikDropdown
        label="Business Unit"
        placeholder="Select Business Unit"
        options={businessUnitOptions}
        name={`${formikNameInitial}.businessUnitId`}
      />
      <AdvancedFormikDropdown
        label="Department"
        placeholder="Select Department"
        options={companyDepartmentOptions}
        name={`${formikNameInitial}.departmentId`}
      />

      <Stack horizontal tokens={{ childrenGap: 30 }}>
        <FormikTextField
          name={`${formikNameInitial}.referenceCode6`}
          label="Company (CO)"
          placeholder="Enter company"
        />
        <FormikTextField
          name={`${formikNameInitial}.referenceCode7`}
          label="Location (LO)"
          placeholder="Enter location"
        />
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 30 }}>
        <FormikTextField
          name={`${formikNameInitial}.projectReference`}
          label="Project (Series/Episode)"
          placeholder="Enter project"
        />
        <FormikTextField
          name={`${formikNameInitial}.setReference`}
          label="Set"
          placeholder="Enter set"
        />
      </Stack>
      <FormikAccountAutocomplete
        lookupAccounts={userDefaults?.lookupAccounts}
        label="Account"
        placeholder="Enter Account"
        name={`${formikNameInitial}.lookupAccountId`}
        id="lookupAccountId"
      />
      {userDefaults?._isProjectsAvailable && (
        <AdvancedFormikDropdown
          label="Project/Episode"
          placeholder="Select Project"
          options={projectsOptions}
          name={`${formikNameInitial}.projectId`}
        />
      )}
      <AdvancedFormikDropdown
        label="Accounting Period"
        placeholder="Select Accounting Period"
        options={corporatePeriodOptions}
        name={`${formikNameInitial}.corporatePeriodId`}
      />
      <Stack horizontal horizontalAlign="space-between">
        <Label>Expand all accounting sections</Label>
        <FormikToggle name={`${formikNameInitial}.isAccountingAreaExpanded`} />
      </Stack>
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: 20 }}
      >
        <Stack>
          <Label>List view width</Label>
        </Stack>
        <Stack horizontal>
          <FormikChoiceGroup
            name={`${formikNameInitial}.listviewSize`}
            options={options}
            styles={choiceGroupStyles}
          />
        </Stack>
      </Stack>

      <Stack horizontal horizontalAlign="space-between">
        <Stack>
          <Label>Row selector (unselected)</Label>
        </Stack>
        <FormikChoiceGroup
          name="rowSelector"
          options={rowSelectorOptions}
          defaultSelectedKey={
            userDefaults?.isRowSelectorBold === true ? 'Bold' : 'Normal'
          }
          styles={rowSelectorGroupStyles}
          onChange={(_event, option) => {
            setFieldValue(
              `${formikNameInitial}.isRowSelectorBold`,
              option?.key === 'Bold'
            );
          }}
        />
      </Stack>

      <Stack horizontal horizontalAlign="space-between">
        <Label>Auto-view approval documents</Label>
        <FormikToggle name={`${formikNameInitial}.isDocumentAutoviewEnabled`} />
      </Stack>
      {userDefaults?._isRequestingFinalApprovalAllowed && (
        <Stack horizontal horizontalAlign="space-between">
          <Label>Receive document email after fully approved</Label>
          <FormikToggle
            name={`${formikNameInitial}.isEmailSentOnFinalApproval`}
          />
        </Stack>
      )}
      {userDefaults?._isRequesterEmailAllowed && (
        <Stack horizontal horizontalAlign="space-between">
          <Label>
            As Requester, receive email notification after each approval step
          </Label>
          <FormikToggle
            name={`${formikNameInitial}.isRequesterEmailSentEachApprovalCycle`}
          />
        </Stack>
      )}
      <DelegateApprover
        dropdownData={dropdownData}
        formikNameInitial={formikNameInitial}
        userDefaults={userDefaults}
      />
    </Stack>
  );
};
