import React from 'react';
import { IColumn, Stack, Text } from '@fluentui/react';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { StatusIcon } from 'approvals/ApprovalInputGroup/StatusIcon';
import { TravelerRowItem } from '.';
import { useStyles } from './index.styles';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';

export const _renderItemColumn = (
  item: TravelerRowItem | undefined,
  _index: number | undefined,
  column: IColumn | undefined
) => {
  const styles = useStyles();
  if (item) {
    const fieldContent = item[
      column?.fieldName as keyof TravelerRowItem
    ] as string;

    const { iconType } = { ...item.approvalHistoriesByEntityId.nodes[0] };
    const _isUrgentApproval = !Boolean(item._urgencyLevel);
    switch (column?.key) {
      case '_urgencyLevel':
        return (
          <RedBoxIndicator
            _isUrgentApproval={_isUrgentApproval}
            itemId={item?.id}
          />
        );
      case '_sortName':
        return (
          <Stack
            className={styles.statusColumnContainer}
            verticalAlign="center"
          >
            <ActiveLink
              to={`/account-management/travelers/traveler/${item.id}`}
            >
              {fieldContent}
            </ActiveLink>
          </Stack>
        );
      case 'status':
        return (
          <Stack
            verticalAlign="center"
            horizontal
            horizontalAlign="space-between"
          >
            <StatusIcon approvalData={item} approval iconType={iconType!} />
          </Stack>
        );
      default:
        return (
          <Stack
            verticalAlign="center"
            className={styles.statusColumnContainer}
          >
            <Text>{fieldContent && fieldContent}</Text>
          </Stack>
        );
    }
  }
};
