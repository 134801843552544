import { IColumn, Stack, Text } from "@fluentui/react";
import clsx from "clsx";
import React from "react";
import { LookupCorporateAccountFields } from "../__generated__/LookupCorporateAccountFields";
import { EditCorporateAccount } from "../view";

export type ColumnStylesProps = {
  onRenderColumnStack: string;
};

export const onRenderItems = (styles: ColumnStylesProps) => {
  const renderItemColumn = (
    item: LookupCorporateAccountFields | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof LookupCorporateAccountFields
      ] as string;
      switch (column?.key) {
        case 'isTravelAccountForAllDepartments':
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.onRenderColumnStack)}
            >
              <Text>{!!fieldContent ? "Yes" : "No"}</Text>
            </Stack>
          );
        case 'department':
          const allDepartment = item[
            'isTravelAccountForAllDepartments' as keyof LookupCorporateAccountFields
          ];
          const departmentContent = fieldContent ? fieldContent : allDepartment ? "(All)" : null;
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.onRenderColumnStack)}
            >
              <Text>{departmentContent}</Text>
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <EditCorporateAccount
                corporateAccount={item}
              />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  }
  return renderItemColumn
}