import { TABLE_ROWS } from 'common/constants';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import {
  AvailablePurchaseOrderRevisionsVariables,
  AvailablePurchaseOrderRevisions_availablePurchaseOrderRevisions,
} from './__generated__/AvailablePurchaseOrderRevisions';
import {
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
} from 'common/types/globalTypes';
import { filterOptionProps } from './Filters';

type OrderLookup = {
  [direction in OrderDirection]: EntityDocumentsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'indexName',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_NAME_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_NAME_DESC],
    },
  ],
  [
    'indexReferenceNumber',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_REFERENCE_NUMBER_ASC],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.INDEX_REFERENCE_NUMBER_DESC,
      ],
    },
  ],
  [
    'indexTransactionDate',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_TRANSACTION_DATE_ASC],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.INDEX_TRANSACTION_DATE_DESC,
      ],
    },
  ],
  [
    'indexDescription',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_DESCRIPTION_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_DESCRIPTION_DESC],
    },
  ],
  [
    'currency',
    {
      [OrderDirection.ASC]: [
        EntityDocumentsOrderBy.COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_ASC,
      ],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_DESC,
      ],
    },
  ],
  [
    'indexAmount',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_DESC],
    },
  ],
  [
    'fileReference',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.FILE_REFERENCE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.FILE_REFERENCE_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    EntityDocumentsOrderBy.INDEX_NAME_ASC,
    EntityDocumentsOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];

  return [...(orderBy ? orderBy : []), EntityDocumentsOrderBy.PRIMARY_KEY_ASC];
};

export const onHandleSearch = (
  entityDocumentId: string,
  showMore: boolean,
  sortOrderParam: SortOrder | undefined,
  availablePurchaseOrderRevisions:
    | AvailablePurchaseOrderRevisions_availablePurchaseOrderRevisions
    | null
    | undefined,
  filter: EntityDocumentFilter | undefined
) => {
  const { pageInfo } = { ...availablePurchaseOrderRevisions };
  const { endCursor } = { ...pageInfo };
  const variables: AvailablePurchaseOrderRevisionsVariables = {
    entityDocumentId,
    first: TABLE_ROWS,
    orderBy: toOrderByVariable(sortOrderParam),
    after: showMore ? endCursor : undefined,
    filter,
  };
  return {
    queryVariables: variables,
  };
};

export const toFilterVariable = (filterOptions: filterOptionProps) =>
  filterOptions.filterTypes.map((filter) => {
    switch (filter.filterKey) {
      case 'indexReferenceNumber':
      case 'indexDescription':
      case 'indexName':
        return {
          [filter.filterKey]: {
            [filterOptions.startsWith
              ? 'startsWithInsensitive'
              : 'includesInsensitive']: filter.value,
          },
        };
      default:
        return undefined;
    }
  });
