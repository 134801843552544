import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    fontStylesContainer: {
      width: 1050,
      overflowY: 'scroll',
    },
    container: {
      display: 'flex',
    },
    fullWidthContainer: { display: 'flex', flexWrap: 'wrap', width: '100%' },
    containerpadding: { padding: '0px 20px 50px' },
    containerMargin: { padding: 20 },
  };
});
