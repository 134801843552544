import { Separator, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { FormHookTextField } from 'common/components/FormHooksFields';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { CorporateChartOfAccount } from '../__generated__/CorporateChartOfAccount';
import { useStyles } from './index.styles';

interface BasicFormProps {
  isEdit: boolean;
  data: CorporateChartOfAccount | undefined
}

export const BasicForm: React.FC<BasicFormProps> = ({
  isEdit,
  data
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { _isUpdatable } = { ...data?.corporateChartOfAccount }
  const isDisabled = isEdit ? !_isUpdatable : false;

  return (
    <Stack
      tokens={{
        padding: 20,
        childrenGap: 13
      }}
      className={styles.container}
    >
      <Stack
        className={styles.fieldContainer}
      >
        <FormHookTextField label="Name" name="name" disabled={isDisabled} required />
      </Stack>
      <Stack
        className={styles.fieldContainer}
      >
        <FormHookTextField label="Description" name="description" disabled={isDisabled} />
      </Stack>
      <Stack>
        <Text
          variant="xLarge"
          className={clsx(commonStyles.colorThemePrimary, styles.subTitle)}
        >
          Integration
        </Text>
        <Separator />
        <Stack
          tokens={{ childrenGap: 13 }}
        >
          <Stack
            horizontal
            tokens={{
              childrenGap: 20
            }}
          >
            <Stack className={styles.flex50}>
              <FormHookTextField label="Company Code" name="companyCode" disabled={isDisabled} />
            </Stack>
            <Stack className={styles.flex50}>
              <FormHookTextField label="Business Area" name="businessArea" disabled={isDisabled} />
            </Stack>
          </Stack>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20
            }}
          >
            <Stack className={styles.flex50}>
              <FormHookTextField label="Cost Center" name="costCenter" disabled={isDisabled} />
            </Stack>
            <Stack className={styles.flex50}>
              <FormHookTextField label="G/L Account" name="glAccount" disabled={isDisabled} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
