import { Checkbox, Icon, Stack, TextField, TooltipHost } from '@fluentui/react';
import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import Decimal from 'decimal.js';
import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { InvoiceSchedules } from '../InvoiceSchedules';
import { useStyles } from './index.styles';
import { GetPOInvoiceSchedules_entityDocument__poInvoiceSchedules_nodes } from '../InvoiceSchedules/__generated__/GetPOInvoiceSchedules';

export type InvoiceSchedulesItem = Omit<
  GetPOInvoiceSchedules_entityDocument__poInvoiceSchedules_nodes,
  'purchaseOrderId'
> & {
  id: string;
};

export interface DocumentMetaForm {
  comment?: string;
  remainingTotal?: string;
  documentAppliedAmount?: string | null;
  isAppliedAmountRetired?: boolean;
  documentStatusExplanation?: string;
  isAppliedAmountOverage?: boolean;
  canBeOverage?: false;
  documentRetiredAmount?: string | null;
  poInvoiceSchedules?: string[];
}

interface DocumentMetaFormProps {
  onChange: (item: DocumentMetaForm) => void;
  documentItem: EntityDocumentsFields;
}
export const DocumentMetaForm: React.FC<DocumentMetaFormProps> = ({
  documentItem,
  onChange,
}) => {
  const remainingTotal = documentItem._isDocumentAmountAvailable
    ? documentItem.documentAppliedAmounts?.remainingTotal!
    : undefined;
  const styles = useStyles();
  const [metaData, setMetaData] = useState<DocumentMetaForm | undefined>({
    remainingTotal,
    documentAppliedAmount: remainingTotal,
  });
  const [showRetireAmountFields, setShowRetireAmountFields] =
    useState<boolean>(false);

  const onChangeMemo = useCallback(onChange, [metaData]);
  useEffect(() => {
    onChangeMemo(metaData!);
  }, [metaData, onChangeMemo]);

  const retireCheckBoxVisible =
    parseFloat(metaData?.documentAppliedAmount!) <
    parseFloat(documentItem.documentAppliedAmounts?.remainingTotal!);

  const overageCheckBoxVisible =
    parseFloat(metaData?.documentAppliedAmount!) >
    parseFloat(documentItem.documentAppliedAmounts?.remainingTotal!);

  useEffect(() => {
    if (!overageCheckBoxVisible)
      setMetaData((prevState) => {
        return { ...prevState, isAppliedAmountOverage: false };
      });
  }, [overageCheckBoxVisible]);

  useEffect(() => {
    if (!retireCheckBoxVisible)
      setMetaData((prevState) => {
        return { ...prevState, isAppliedAmountRetired: false };
      });
  }, [retireCheckBoxVisible]);

  const explanationErrorhandler = metaData?.isAppliedAmountOverage
    ? metaData?.documentStatusExplanation?.length! > 0
      ? ''
      : 'Explanation required'
    : '';
  const documentAppliedAmount =
    !!metaData?.documentAppliedAmount &&
    !isNaN(parseFloat(metaData?.documentAppliedAmount))
      ? metaData?.documentAppliedAmount
      : 0;
  const inValidRetireAmount = new Decimal(
    metaData?.documentRetiredAmount || 0
  ).greaterThan(
    new Decimal(metaData?.remainingTotal || 0).minus(documentAppliedAmount)
  );

  const inValidRetireAmountError = inValidRetireAmount
    ? 'Retire amount cannot be greater than applied amount.'
    : '';

  const nullRetireAmountValueError =
    metaData?.isAppliedAmountRetired && !Number(metaData?.documentRetiredAmount)
      ? 'Please enter Retire Amount.'
      : '';

  useEffect(() => {
    if (
      metaData?.remainingTotal &&
      metaData?.isAppliedAmountRetired &&
      documentAppliedAmount
    ) {
      let difference = 0.0;
      difference = new Decimal(metaData?.remainingTotal)
        .minus(documentAppliedAmount)
        .toNumber();
      setMetaData((prevState) => {
        return {
          ...prevState,
          documentRetiredAmount: difference.toFixed(2).toString(),
        };
      });
    } else {
      setMetaData((prevState) => {
        return { ...prevState, documentRetiredAmount: null };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metaData?.remainingTotal,
    metaData?.isAppliedAmountRetired,
    documentAppliedAmount,
  ]);

  return (
    <Stack
      className={styles.container}
      horizontal
      verticalAlign="start"
      horizontalAlign="space-between"
      tokens={{ padding: '20px 20px 20px 60px' }}
    >
      {documentItem._isDocumentAmountAvailable && (
        <Stack
          horizontal
          verticalAlign="start"
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          {documentItem.documentTypes?._isPoSystemDocument && (
            <InvoiceSchedules
              document={documentItem}
              onRowSelect={(data) => {
                if (data.scheduleIds.length > 0) {
                  setMetaData((prevState) => ({
                    ...prevState,
                    poInvoiceSchedules: data.scheduleIds,
                    documentAppliedAmount: data.totalAmount
                      ? data.totalAmount
                      : '100000',
                  }));
                } else {
                  setMetaData((prevState) => ({
                    ...prevState,
                    poInvoiceSchedules: [],
                    documentAppliedAmount: remainingTotal,
                  }));
                }
              }}
            />
          )}
          <NumberFormat
            className={styles.textFieldStyles}
            value={metaData?.documentAppliedAmount || ''}
            placeholder="Apply Amount"
            customInput={TextField}
            thousandSeparator
            allowNegative={true}
            fixedDecimalScale
            decimalScale={2}
            onBlur={() =>
              setMetaData((prevState) => ({
                ...prevState,
                documentAppliedAmount: (
                  Number(metaData?.documentAppliedAmount) || 0
                ).toFixed(2),
              }))
            }
            onValueChange={(valuesObject, info) => {
              if (info.source === 'event') {
                if (valuesObject.value) {
                  setMetaData((prevState) => ({
                    ...prevState,
                    documentAppliedAmount: valuesObject.value,
                  }));
                } else {
                  setMetaData((prevState) => ({
                    ...prevState,
                    documentAppliedAmount: null,
                  }));
                }
              }
            }}
            autoFocus
          />
          {overageCheckBoxVisible && (
            <Stack
              horizontalAlign="center"
              horizontal
              styles={{ root: { marginTop: 5 } }}
              tokens={{ childrenGap: 10 }}
            >
              <Checkbox
                required
                checked={metaData?.isAppliedAmountOverage}
                label="Overages"
                onChange={(_, checked) => {
                  setMetaData((prevState) => ({
                    ...prevState,
                    isAppliedAmountOverage: checked,
                  }));
                }}
              />
              {!metaData?.isAppliedAmountOverage && (
                <TooltipHost content="Acknowledge the overage and provide an explanation.">
                  <Icon
                    iconName="Info"
                    styles={{ root: { color: 'red', fontSize: 20 } }}
                  />
                </TooltipHost>
              )}
            </Stack>
          )}
          {retireCheckBoxVisible && (
            <>
              <Stack
                horizontalAlign="center"
                horizontal
                styles={{ root: { marginTop: 5 } }}
              >
                <Checkbox
                  checked={metaData?.isAppliedAmountRetired}
                  label="Retire"
                  onChange={(_, checked) => {
                    setMetaData((prevState) => ({
                      ...prevState,
                      isAppliedAmountRetired: checked,
                    }));
                    setShowRetireAmountFields(!!checked);
                  }}
                />
              </Stack>
              {showRetireAmountFields && metaData?.isAppliedAmountRetired && (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <NumberFormat
                    className={styles.textFieldStyles}
                    value={metaData?.documentRetiredAmount || ''}
                    placeholder="Retire Amount"
                    customInput={TextField}
                    thousandSeparator
                    allowNegative={true}
                    fixedDecimalScale
                    decimalScale={2}
                    errorMessage={
                      inValidRetireAmountError || nullRetireAmountValueError
                    }
                    onBlur={() =>
                      setMetaData((prevState) => ({
                        ...prevState,
                        documentRetiredAmount: (
                          Number(metaData?.documentRetiredAmount) || 0
                        ).toFixed(2),
                      }))
                    }
                    onValueChange={(valuesObject, info) => {
                      if (info.source === 'event') {
                        if (valuesObject.value) {
                          setMetaData((prevState) => ({
                            ...prevState,
                            documentRetiredAmount: valuesObject.value,
                          }));
                        } else {
                          setMetaData((prevState) => ({
                            ...prevState,
                            documentRetiredAmount: null,
                          }));
                        }
                      }
                    }}
                  />
                </Stack>
              )}
            </>
          )}
          <Stack className={styles.explanationStyles}>
            {(metaData?.isAppliedAmountOverage ||
              metaData?.isAppliedAmountRetired) && (
              <TextField
                className={styles.textFieldStyles}
                placeholder="Explanation"
                errorMessage={explanationErrorhandler}
                onChange={(_, newValue) => {
                  setMetaData((prevState) => ({
                    ...prevState,
                    documentStatusExplanation: newValue,
                  }));
                }}
              />
            )}
          </Stack>
        </Stack>
      )}
      <Stack style={{ maxWidth: 450 }} grow={1}>
        <TextField
          placeholder="Comments"
          onChange={(_, newValue) => {
            setMetaData((prevState) => ({
              ...prevState,
              comment: newValue,
            }));
          }}
        />
      </Stack>
    </Stack>
  );
};
