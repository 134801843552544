import { useMutation } from '@apollo/client';
import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import { ConfirmDialog } from 'common/components/UrgencySelector/ConfirmDialog';
import { useCommonStyles } from 'common/styles';
import { BatchEditUrgentUpdateInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { BatchTransaction } from '../../__generated__/BatchTransaction';
import {
  BatchEditUrgentUpdate,
  BatchEditUrgentUpdateVariables,
} from './__generated__/BatchEditUrgentUpdate';
import { useStyles } from './index.styles';

const BATCH_TRANSACTION_URGENCY_UPDATE = loader(
  './BatchEditUrgentUpdate.graphql'
);
interface UrgencyFolderProps {
  batchTransactionData?: BatchTransaction | undefined;
}

export const UrgencyFolder: React.FC<UrgencyFolderProps> = ({
  batchTransactionData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const [isVisible, setIsVisible] = useState(false);
  const isUrgent = !Boolean(
    batchTransactionData?.batchTransaction?._urgencyLevel
  );
  const urgencyLevelToolTip = isUrgent ? 'Remove Urgency' : 'Elevate to urgent';
  const iconName = isUrgent ? 'FabricFolderFill' : 'FabricFolder';
  const iconStyle = isUrgent ? commonStyles.urgentBox : styles.standardBox;
  const isChecked = !isUrgent;
  const [batchTransactionUrgencyUpdate] = useMutation<
    BatchEditUrgentUpdate,
    BatchEditUrgentUpdateVariables
  >(BATCH_TRANSACTION_URGENCY_UPDATE, { errorPolicy: 'all' });

  const onSubmit = async (requestComment: string | undefined | null) => {
    const updatedUrgencyLevel =
      batchTransactionData?.batchTransaction?._urgencyLevel === 0 ? 1 : 0;

    const { errors } = await batchTransactionUrgencyUpdate({
      variables: {
        input: {
          entityId: batchTransactionData?.batchTransaction?.id!,
          rowTimestamp: batchTransactionData?.batchTransaction?._rowTimestamp,
          urgencyLevel: updatedUrgencyLevel,
          explanationComment: requestComment,
        } as BatchEditUrgentUpdateInput,
      },
      update(cache, { data }) {
        if (!!batchTransactionData?.batchTransaction?.id) {
          cache.modify({
            id: cache.identify({ ...batchTransactionData.batchTransaction }),
            fields: {
              _urgencyLevel: () => {
                return data?.batchEditUrgentUpdate?._newUrgentLevel;
              },
            },
          });
        }
        setIsVisible(false);
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Urgency status updated successfully.', {
        appearance: 'success',
      });
    }
  };

  return (
    <Stack>
      <TooltipHost content={urgencyLevelToolTip}>
        <IconButton
          iconProps={{
            iconName: iconName,
          }}
          className={iconStyle}
          onClick={() => setIsVisible(true)}
          styles={{
            rootHovered: { color: 'redDark' },
            rootPressed: { color: 'redDark' },
          }}
        />
      </TooltipHost>
      <ConfirmDialog
        hidden={!isVisible}
        minWidth={500}
        checked={isChecked}
        onConfirm={(requestComment: string | null | undefined) =>
          onSubmit(requestComment)
        }
        onDismiss={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
      />
    </Stack>
  );
};
