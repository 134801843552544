import { IconButton, Stack } from '@fluentui/react';
import { DocumentContainer } from 'common/components/DocumentContainer';
import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import React, { useEffect, useState } from 'react';
import { DocumentViewModalState } from '..';

export type DocumentsType = Pick<
  EntityDocumentsFields,
  'id' | 'fileReference' | '_fileType'
>;

interface ViewDocumentProps {
  // document: EntityDocumentsFields;
  document: DocumentsType;
  disabled: boolean;
  focusTrap?: boolean;
  paddingLeft?: number;
  onDismiss?: (index: number) => void;
  defaultOpen?: boolean;
}

export const ViewDocument: React.FC<ViewDocumentProps> = ({
  document,
  disabled,
  focusTrap,
  defaultOpen = false,
}) => {
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  useEffect(() => {
    if (defaultOpen === true) {
      setTimeout(() => {
        setDocViewState({
          isOpen: true,
          title: document.fileReference,
          entityDocumentId: document.id,
          _fileType: document._fileType!,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOpen]);

  return (
    <Stack>
      <IconButton
        disabled={disabled}
        iconProps={{ iconName: 'View' }}
        onClick={() => {
          setDocViewState({
            isOpen: true,
            title: document.fileReference,
            entityDocumentId: document.id,
            _fileType: document._fileType!,
          });
        }}
      />
      <DocumentContainer
        focusTrap={focusTrap}
        onDismiss={() => {
          setDocViewState({ isOpen: false, _fileType: 'pdf' });
        }}
        {...docViewState}
      />
    </Stack>
  );
};
