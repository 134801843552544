import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '20px !important',
  },
  businessPurposeContainer: {
    marginTop: '30px !important',
  },
  leftInputsContainer: {
    flexBasis: '60%',
  },
  rightInputsContainer: {
    flexBasis: '40%',
  },
  totalAmount: {
    fontSize: FontSizes.size24,
  },
  workgroupContainer: {
    width: '50%',
    marginTop: 10,
  },
}));
