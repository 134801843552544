import { useLazyQuery, useMutation } from '@apollo/client';
import { Stack, Text } from '@fluentui/react';
import { DocumentPackage } from 'common/components/DocumentPackage';
import { DocumentPackageEmail } from 'common/components/DocumentPackageEmail/DocumentPackageEmail';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import { PanelHeader } from 'common/components/PanelHeader';
import { StampOptions, StamperView } from 'common/components/StamperView';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import { IconState } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TravelerAuthorizationCommonData_companyCorporatePeriods } from '../__generated__/TravelerAuthorizationCommonData';
import { CopyTravelPlan } from './CopyTravelPlan';
import { DeleteTraveAuth } from './Delete';
import { PreHierarchyModal } from './PreHierarchyModal';
import { StampEntry } from './StampEntry';
import { UrgencyFolder } from './UrgencyFolder';
import {
  TravelAuthorizationEmailCreate,
  TravelAuthorizationEmailCreateVariables,
} from './__generated__/TravelAuthorizationEmailCreate';
import {
  TravelAuthorizationEmailStatus,
  TravelAuthorizationEmailStatusVariables,
} from './__generated__/TravelAuthorizationEmailStatus';
import { useStyles } from './index.styles';
const TRAVEL_AUTHORIZATION_EMAIL_CREATE = loader(
  './TravelAuthorizationEmailCreate.graphql'
);
const TRAVEL_AUTHORIZATION = loader('../../TravelAuthorization.graphql');
const FETCH_EMAIL_STATUS = loader('./TravelAuthorizationEmailStatus.graphql');

interface HeaderProps {
  isNew: boolean;
  dirty: boolean;
  loading: boolean;
  isSubmitting: boolean;
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  companyCorporatePeriods:
    | TravelerAuthorizationCommonData_companyCorporatePeriods
    | null
    | undefined;
  onRefetch: () => void;
}
export const Header: React.FC<HeaderProps> = ({
  isNew,
  dirty,
  loading,
  isSubmitting,
  travelAuthorizationData,
  companyCorporatePeriods,
  onRefetch,
}) => {
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const { travelId } = useParams<{ travelId: string | undefined }>();
  const styles = useStyles();
  const { addToast } = useToasts();

  const panelHeading = isNew ? 'Create Travel Plan' : 'Edit Travel Plan';
  const showUnsavedIndicator = !isNew && !loading && dirty && !isSubmitting;

  const [
    travelAuthorizationEmailCreate,
    { loading: emailCreateLoading, data },
  ] = useMutation<
    TravelAuthorizationEmailCreate,
    TravelAuthorizationEmailCreateVariables
  >(TRAVEL_AUTHORIZATION_EMAIL_CREATE, { errorPolicy: 'all' });

  const {
    id,
    travelAuthorizationNumber,
    _isStagedApprovalRequest,
    statusType,
    _isAccountingEntry,
    _isHistory,
    _isStampAllowed,
    _isAccountingEntryStampedComplete,
    _isTransactionCancelled,
    _accountingStampDate,
    _accountingStampUserName,
    _accountingStampTransactionReference,
    _isDeletable,
    stampedEntityDocumentId,
    _emailDocument,
    travelAuthorizationEmailDocumentsByEntityId,
  } = { ...travelAuthorizationData };

  const travelAuthorizationStatus =
    !isNew && !_isAccountingEntryStampedComplete && travelAuthorizationData
      ? `(${statusType?.statusType})`
      : null;

  const showStampEntry = _isAccountingEntry && !_isHistory && _isStampAllowed;
  const showDelete = !_isAccountingEntryStampedComplete && !showStampEntry;

  const stampData: StampOptions | undefined =
    !isNew && _isAccountingEntryStampedComplete
      ? {
          _isTransactionCancelled,
          _isAccountingEntryStampedComplete,
          _accountingStampDate,
          _accountingStampUserName,
          _accountingStampTransactionReference,
        }
      : undefined;

  const _onSubmitValues = async (values: EmailCreateValues) => {
    if (id) {
      const { errors } = await travelAuthorizationEmailCreate({
        variables: {
          input: {
            entityId: id!,
            ...values,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: TRAVEL_AUTHORIZATION,
            variables: {
              id,
            },
          },
        ],
      });
      if (!!errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: travelId!,
          },
        });
      }
    }
  };

  const isSuccessful = !!data?.travelAuthorizationEmailCreate;
  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    TravelAuthorizationEmailStatus,
    TravelAuthorizationEmailStatusVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      if (
        data.travelAuthorization?._emailDocument?._sendIconState ===
        IconState.SENT
      ) {
        stopPolling?.();
        setShowEmailStatus(true);
      }
    },
  });

  const fetchEmailStatusMemo = useCallback(() => {
    fetchEmailStatus({
      variables: {
        id: travelId!,
      },
    });
  }, [fetchEmailStatus, travelId]);

  useEffect(() => {
    if (_emailDocument?._sendIconState === IconState.PENDING) {
      fetchEmailStatusMemo();
    }
  }, [_emailDocument, fetchEmailStatusMemo]);

  return (
    <PanelHeader
      hasHeaderText={false}
      onClose={() => {
        history.replace('/ta/travel-plan');
      }}
    >
      <Stack className={styles.container}>
        <Stack grow horizontal horizontalAlign="space-between">
          <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Text variant="xLarge">{panelHeading}</Text>
              {!isNew && (
                <Text
                  variant="xLarge"
                  className={commonStyles.colorThemePrimary}
                >
                  {travelAuthorizationNumber}
                </Text>
              )}
              {stampedEntityDocumentId && (
                <DocumentPackage documentPackageId={stampedEntityDocumentId} />
              )}
              <UnsavedIndicator visible={showUnsavedIndicator} />
              <DocumentPackageEmail
                emailDocument={_emailDocument}
                emailDocumentsByEntityId={
                  travelAuthorizationEmailDocumentsByEntityId?.nodes || []
                }
                isSuccessful={isSuccessful}
                loading={emailCreateLoading}
                onSubmitValues={_onSubmitValues}
                showEmailStatus={showEmailStatus}
                setShowEmailStatus={setShowEmailStatus}
                dataLoading={loading}
              />
            </Stack>
          </Stack>
          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
            className={styles.iconsContainer}
          >
            {travelAuthorizationData?.statusType?.statusType === 'Pending' && (
              <UrgencyFolder
                travelAuthorizationData={travelAuthorizationData}
              />
            )}
            <CopyTravelPlan travelAuthorizationData={travelAuthorizationData} />
            {stampData && _isAccountingEntryStampedComplete && (
              <StamperView invoiceDetails={stampData} />
            )}
            <StampEntry
              travelAuthorizationData={travelAuthorizationData}
              companyCorporatePeriods={companyCorporatePeriods}
              onRefetch={onRefetch}
            />
            {_isStagedApprovalRequest && !isNew && (
              <PreHierarchyModal entityId={id} />
            )}
            {showDelete && (
              <DeleteTraveAuth
                travelAuthorizationData={travelAuthorizationData}
                isNew={isNew}
                disabled={!_isDeletable}
              />
            )}
            <Text
              variant="mediumPlus"
              className={commonStyles.colorThemePrimary}
            >
              {travelAuthorizationStatus}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </PanelHeader>
  );
};
