import React, { useState } from 'react';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import {
  Checkbox,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  Stack,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { PaymentTrackingTypes } from './__generated__/PaymentTrackingTypes';
import { loader } from 'graphql.macro';
import { useQuery, useReactiveVar } from '@apollo/client';
import { formatDropdownOptions } from 'common/utils';
import { globalMode } from 'utility/cache/ui';
const PAYMENTTYPESDATA = loader('./PaymentTrackingTypes.graphql');

interface PaymentSearchBarProps extends SearchBarProps, MenuActionBarProps {
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
  onHistoryCheckbox?: () => void;
}

export const PaymentTrackingSearchBar: React.FC<PaymentSearchBarProps> = ({
  columns,
  onFilterChange,
  onEnterPress,
  hintsAvailable,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  onHintsViewToggle,
  onHistoryToogle,
  urlParams,
  onGlobalToggle,
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const { data: paymentTypesData } =
    useQuery<PaymentTrackingTypes>(PAYMENTTYPESDATA);
  const typeOptions = formatDropdownOptions(
    paymentTypesData?.paymentTransactionTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.transactionType!,
      includeAll: true,
    }
  );
  const businessUnitOptions = formatDropdownOptions(
    paymentTypesData?.companyBusinessUnits?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );
  const currencyOptions = formatDropdownOptions(
    paymentTypesData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );

  const companiesOptions = formatDropdownOptions(
    paymentTypesData?.companies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
      includeAll: true,
    }
  );

  const statusOptions = formatDropdownOptions(
    paymentTypesData?.invoiceStatusTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType!,
      includeAll: true,
    }
  );

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };

  return (
    <SearchBar
      urlParams={urlParams}
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange?.([]);
        setFilterTypes([]);
      }}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      onGlobalToggle={onGlobalToggle}
      hintsAvailable={hintsAvailable}
      onHintsViewToggle={onHintsViewToggle}
      onToggleVisibility={onToggleVisibility}
      renderActions={<MenuActionBar onHistoryToogle={onHistoryToogle} />}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'transactionTypeId'}
          options={typeOptions || []}
          placeholder={'Types'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'businessUnitId'}
          options={businessUnitOptions || []}
          placeholder={'Business Unit'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'currencyId'}
          options={currencyOptions || []}
          placeholder={'Currency'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'_companyId'}
          options={companiesOptions || []}
          placeholder={'Productions'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey="_statusTypeId"
          options={statusOptions}
          placeholder="Status"
          onOptionChange={onOptionChange}
        />
      </Stack>
    </SearchBar>
  );
};
interface MenuActionBarProps {
  onHistoryToogle?: (value: boolean) => void;
}
export const MenuActionBar: React.FC<MenuActionBarProps> = ({
  onHistoryToogle,
}) => {
  const styles = useStyles();
  const globalState = useReactiveVar(globalMode);

  return (
    <Stack
      className={styles.actionsWrapperStyle}
      horizontalAlign="center"
      verticalAlign="center"
      horizontal
      tokens={{ childrenGap: 25 }}
    >
      <Checkbox
        disabled={globalState}
        styles={checkBoxStyle}
        label="History"
        onChange={(_, checked) => onHistoryToogle?.(checked!)}
      />
    </Stack>
  );
};
let checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};
