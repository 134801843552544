import { Stack, Text, TooltipHost, Image, Icon } from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';
import { ReactComponent as ApprovedIcon } from '../../../assets/svgs/ApproveStatusIcons/Approved.svg';
import { ReactComponent as DeclinedIcon } from '../../../assets/svgs/ApproveStatusIcons/Declined.svg';
import { dateFormat } from 'common/utils/dateFormats';
import { FileIconType } from 'common/types/globalTypes';
import { ApprovalHistorySignaturesFields } from 'common/graphql/__generated__/ApprovalHistorySignaturesFields';

export interface SignatureOptions extends ApprovalHistorySignaturesFields {}

export interface UserSignatureDocument {
  id: GqlUUID;
  _downloadLink: GqlSASBlobLink | null;
  fileIndexInformation: string | null;
  fileReference: string;
  iconType: FileIconType | null;
}

interface SignatureViewProps {
  signatureData: ApprovalHistorySignaturesFields[];
}
export const SignatureView: React.FC<SignatureViewProps> = ({
  signatureData,
}) => {
  const styles = useStyles();

  return (
    <Stack tokens={{ childrenGap: 20, padding: '0px 0px 25px 0px' }}>
      {signatureData.map((item) => {
        return (
          <Stack
            key={item.id}
            horizontal
            tokens={{ childrenGap: 10 }}
            verticalAlign="center"
          >
            <Stack horizontalAlign="center" style={{ width: 40 }}>
              {item.isApproved && !item.isRejected && !item.isRequested ? (
                <ApprovedIcon />
              ) : !item.isApproved && item.isRejected && !item.isRequested ? (
                <DeclinedIcon />
              ) : !item.isApproved && !item.isRejected && item.isRequested ? (
                <Icon
                  styles={{
                    root: {
                      fontSize: 20,
                    },
                  }}
                  iconName={'SplitObject'}
                  className={styles.logo}
                />
              ) : null}
            </Stack>
            <Stack className={styles.customWidth} tokens={{ childrenGap: 5 }}>
              <Stack horizontal horizontalAlign="space-between">
                {item.digitalSignature && (
                  <Stack>
                    <TooltipHost content={item.additionalInformation!}>
                      <Text variant="mediumPlus">{item.userName}</Text>
                      <Text variant="small" className={styles.gray}>
                        {` - ${item.userOccupationTitle}`}
                      </Text>
                    </TooltipHost>
                    <TooltipHost content="Digitally Signed">
                      <Text variant="xSmall">{item.digitalSignature}</Text>
                    </TooltipHost>
                  </Stack>
                )}
                <Text variant="mediumPlus">{dateFormat(item.actionDate!)}</Text>
              </Stack>
            </Stack>

            {item.userSignatureDocument && (
              <Stack
                className={styles.paddingLeft20}
                horizontal
                verticalAlign="center"
              >
                <Image
                  style={{ objectFit: 'contain', backgroundColor: 'white' }}
                  className={styles.signatureImage}
                  src={item.userSignatureDocument?._downloadLink!}
                  width={120}
                  height={50}
                />
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
