import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
 
 container: {
    marginBottom: 100,
    display:'flex',
    flexDirection:'column',
    height:'100%',
  },
  
}));
