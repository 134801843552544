import { format } from 'date-fns';
import { sub } from 'date-fns/esm';

const yesterdaydate = sub(new Date(), {
  days: 1,
});
export const getPrettierdate = (dateTime: string) => {
  const serverDate = format(new Date(dateTime), 'M/d/yyyy');
  if (serverDate === format(new Date(), 'M/d/yyyy')) return 'Today';
  else if (serverDate === format(new Date(yesterdaydate), 'M/d/yyyy'))
    return 'Yesterday';
  else return serverDate;
};

export const getTimeFormat = (dateTime: string) =>
  format(new Date(dateTime), 'h:mm:ss a');

export const getGlobalDateFormat = (dateTime: string) =>
  format(new Date(dateTime), 'M/d/yyyy h:mm:ss a');

export const dateFormat = (dateTime: string) =>
  format(new Date(dateTime), 'M/d/yyyy');

export const onFormatDate = (date?: Date): string => {
  return !date ? '' : dateFormat(date.toString());
};

export const dateConvertions = (date: string) => date.replaceAll('-', '/');

export const invoiceDateFormat = (dateTime: string) =>
  format(new Date(dateTime), 'MM/dd/yyyy');

export const formatDateTime = (dateTime: string) => {
  const isoCreatedDate = new Date(dateTime).toISOString().split('T')[0];
  return dateFormat(dateConvertions(isoCreatedDate));
};

export const getTimeFromDate = (dataTime: string) => {
  var d = new Date(dataTime);
  return d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};
