import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    payCycleId: Yup.string().required('Required').nullable(),
    vendorReference: Yup.string().required('Required').nullable(),
    paymentReference: Yup.string().required('Required').nullable(),
    paidDate: Yup.string().required('Required').nullable(),
    paidAmount: Yup.number().required('Required').nullable()
  });
