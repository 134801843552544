import { makeStyles } from '@fluentui/react';

const GREEN_700 = '#28a745';
const GREEN_800 = '#24963e';
const GREEN_900 = '#208537';

export const useStyles = makeStyles({
  root: {
    backgroundColor: GREEN_700,
    borderColor: GREEN_700,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      backgroundColor: GREEN_800,
      borderColor: GREEN_800,
    },
    ':active': {
      backgroundColor: GREEN_900,
      borderColor: GREEN_900,
    },
  },
});
