import {
  FontIcon,
  Separator,
  Stack,
  Text,
  Theme,
  Toggle,
  useTheme,
} from '@fluentui/react';
import { FieldArray, useFormikContext } from 'formik';
import { UserChartValues, UserDefaultFormValues } from 'Preferences/types';
import { UserCharts } from 'Preferences/__generated__/UserCharts';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { useStyles } from './index.styles';
interface ChartsViewProps {
  chartsData?: UserCharts;
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any,
  index: number,
  theme: Theme
) => ({
  ...draggableStyle,
  left: isDragging ? 0 : undefined,
  background: !isDragging ? theme.palette.white : theme.palette.neutralLighter,
  top: isDragging ? draggableStyle.top - 30 : undefined,
});

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const ChartsView: React.FC<ChartsViewProps> = () => {
  const { setFieldValue, values } = useFormikContext<UserDefaultFormValues>();
  const styles = useStyles();
  const theme = useTheme();
  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { destination, source } = { ...result };
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const chartNodesNew: UserChartValues[] = reorder(
      [...(values.userCharts || [])],
      source.index,
      destination?.index!
    ) as UserChartValues[];

    const newOrderedCharts = chartNodesNew.map(
      (item, index) =>
        ({ ...item, chartPosition: index + 1 } as UserChartValues)
    );
    setFieldValue('userCharts', newOrderedCharts);
  };

  return (
    <Stack tokens={{ padding: '20px 0px' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <FieldArray name="userCharts">
                {() => (
                  <>
                    {values.userCharts?.map((item, index) => {
                      const userCharts = `userCharts[${index}]`;
                      const _onChange = (
                        event: React.MouseEvent<HTMLElement, MouseEvent>,
                        checked?: boolean | undefined
                      ) => {
                        if (event) {
                          setFieldValue(`${userCharts}.isActive`, checked);
                        }
                      };

                      return (
                        <Draggable
                          key={index.toString()}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            const { isDragging } = { ...snapshot };
                            return (
                              <div
                                className={isDragging ? styles.root : ''}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  isDragging,
                                  provided.draggableProps.style,
                                  index,
                                  theme
                                )}
                              >
                                <Stack key={index.toFixed()}>
                                  <Stack
                                    horizontal
                                    horizontalAlign="space-between"
                                    verticalAlign="center"
                                    tokens={{ padding: '0px 20px' }}
                                  >
                                    <Stack
                                      horizontal
                                      verticalAlign="center"
                                      tokens={{ childrenGap: 20 }}
                                    >
                                      <FontIcon iconName="GripperDotsVertical" />
                                      <Stack styles={{ root: { width: 200 } }}>
                                        <Text>{item.chartCaption}</Text>
                                      </Stack>
                                    </Stack>

                                    <Toggle
                                      checked={item.isActive!}
                                      onChange={_onChange}
                                    />
                                  </Stack>
                                  <Separator />
                                </Stack>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                  </>
                )}
              </FieldArray>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
};
