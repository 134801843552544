import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      cursor: 'pointer',
      height: 20,
      width: 20,
      borderRadius: 10,
    },
    checked: {
      backgroundColor: theme.palette.themePrimary,
    },
    iconChecked: {
      color: theme.palette.white,
      ':hover': {
        color: theme.palette.white,
      },
    },
    checkIcon: {
      left: 1,
      position: 'absolute',
      fontSize: 18,
      color: theme.palette.neutralSecondary,
    },
    ringIcon: {
      fontSize: 20,
      position: 'absolute',
      color: theme.palette.neutralSecondary,
    },
  };
});
