import { Stack } from '@fluentui/react'
import { FormHookTextField } from 'common/components/FormHooksFields'
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox'
import React from 'react'
import { useStyles } from './index.styles'

export const BasicForm = () => {
  const styles = useStyles()

  return (
    <Stack
      className={styles.container}
      tokens={{ childrenGap: 10 }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 20 }}
        verticalAlign="center"
      >
        <Stack className={styles.fieldContainer}>
          <FormHookTextField
            label="Name"
            name="name"
            required
          />
        </Stack>
        <Stack className={styles.fieldContainer}>
          <FormHookCheckBox
            label="Travel Group"
            name="isTravelDepartmentGroup"
          />
        </Stack>
      </Stack>
      <Stack>
        <Stack className={styles.fieldContainer}>
          <FormHookTextField
            label="Description"
            name="description"
            multiline
            rows={3}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
