import { APPROVAL_TYPE_VALUES } from 'settings/account/userSetup/view/constant';
import {
  UserTemplate,
  UserTemplate_departmentOccupationTemplate,
  UserTemplate_departmentOccupationTemplate_departmentOccupationApprovals_nodes,
  UserTemplate_departmentOccupationTemplate_departmentOccupationCharts_nodes,
} from './__generated__/UserTemplate';
import { USER_TEMPLATE_INITIAL_VALUES } from './constants';
import { ChartTypeRowValues, UserTemplatevalues } from './types';
import {
  ApprovalTypeRowValues,
  UserGrants,
} from 'settings/account/userSetup/view/types';
import { intersection, isEmpty } from 'lodash';
import {
  DepartmentOccupationApprovalUpdateTypeInput,
  DepartmentOccupationChartInput,
  DepartmentOccupationChartUpdateTypeInput,
} from 'common/types/globalTypes';

export const getDefaultValues = (props?: UserTemplate) => {
  let defaultValues: UserTemplatevalues = USER_TEMPLATE_INITIAL_VALUES;
  if (props?.departmentOccupationTemplate) {
    const { nodes } = {
      ...props.departmentOccupationTemplate?.departmentOccupationApprovals,
    };
    const grantNodes = nodes?.map(
      ({ id, _rowTimestamp, approvalTypeId, ...item }) => {
        return {
          id,
          _rowTimestamp,
          approvalTypeId,
          grants: {
            ...item,
          } as UserGrants,
        } as ApprovalTypeRowValues;
      }
    );

    const { __typename, id, _isUpdatable, _rowTimestamp, ...values } = {
      ...props.departmentOccupationTemplate,
    };
    const { nodes: chartNodes } = {
      ...props.departmentOccupationTemplate?.departmentOccupationCharts,
    };
    const templateChartNodes = chartNodes?.map(({ chartType, ...item }) => {
      return {
        ...item,
      } as ChartTypeRowValues;
    });
    defaultValues = {
      ...values,
      userTemplateApprovals:
        nodes?.length! > 0
          ? [...grantNodes!, ...[APPROVAL_TYPE_VALUES]]
          : [APPROVAL_TYPE_VALUES],
      userTemplateCharts:
        chartNodes?.length! > 0 ? [...templateChartNodes!] : null,
    };
  }
  return defaultValues;
};
export const getUpdatedChart = (
  formValues: ChartTypeRowValues[] | null,
  chartData:
    | UserTemplate_departmentOccupationTemplate_departmentOccupationCharts_nodes[]
    | undefined
) => {
  const updatedChart =
    chartData &&
    formValues &&
    intersection(
      chartData.map((chart) => chart.chartTypeId),
      formValues
        .filter((chart) => chart.chartTypeId)
        .map((chart) => chart.chartTypeId)
    ).reduce((arr, targetId) => {
      const initialCharts = chartData.find(
        (chart) => chart.chartTypeId === targetId
      )!;
      const { ...updatedChart } = formValues!.find(
        (chart) => chart.chartTypeId === targetId
      )!;
      const patch = Object.entries(updatedChart).reduce((res, [key, val]) => {
        if (
          val !== initialCharts[key as keyof DepartmentOccupationChartInput]
        ) {
          if (key === 'chartPosition') return { ...res, [key]: Number(val) };
          return { ...res, [key]: val };
        }
        return res;
      }, {});
      if (!isEmpty(patch))
        return [
          ...arr,
          {
            id: updatedChart?.id,
            rowTimestamp: updatedChart?._rowTimestamp,
            departmentOccupationChartPatch: patch,
          },
        ];
      else return [...arr];
    }, [] as DepartmentOccupationChartUpdateTypeInput[]);
  return updatedChart;
};

export const getUpdateUserTemplateApprovals = (
  formValues: ApprovalTypeRowValues[] | null,
  approvalData:
    | UserTemplate_departmentOccupationTemplate_departmentOccupationApprovals_nodes[]
    | undefined
) => {
  const updatedUserTemplateApprovals =
    approvalData &&
    formValues &&
    intersection(
      approvalData?.map((approval) => approval.id),
      formValues
        .filter((approval) => approval.id)
        .map((approval) => approval.id)
    ).reduce((arr, targetId) => {
      const initialApprovals = approvalData!.find(
        (approval) => approval.id === targetId
      )!;
      const { ...updatedApproval } = formValues!?.find(
        (approval) => approval.id === targetId
      )!;
      const { grants } = updatedApproval;
      const approvalItems = {
        ...grants,
      };
      const { id, _rowTimestamp, approvalTypeId, ...initialApprovalItems } =
        initialApprovals;

      const patch = Object.entries(approvalItems).reduce((res, [key, val]) => {
        if (val !== initialApprovalItems[key as keyof UserGrants])
          return { ...res, [key]: val };
        return res;
      }, {});
      if (!isEmpty(patch))
        return [
          ...arr,
          {
            id: updatedApproval?.id,
            rowTimestamp: updatedApproval?._rowTimestamp,
            departmentOccupationApprovalPatch: patch,
          },
        ];
      else return [...arr];
    }, [] as DepartmentOccupationApprovalUpdateTypeInput[]);
  return updatedUserTemplateApprovals;
};

export const getUserTemplatePatch = (
  formValues: UserTemplatevalues,
  initialData: UserTemplate_departmentOccupationTemplate | null | undefined
) => {
  const patch = Object.entries(formValues).reduce((res, [key, val]) => {
    if (
      key === 'id' ||
      key === '_isUpdatable' ||
      key === '_isDeletable' ||
      key === '__typename' ||
      key === '_rowTimestamp' ||
      key === 'departmentOccupationTemplatesHistoriesByEntityId' ||
      key === 'userTemplateApprovals' ||
      key === 'userTemplateCharts' ||
      key === 'departmentOccupationApprovals' ||
      key === 'departmentOccupationCharts' ||
      key === 'userOccupationTitle' ||
      key === 'role' ||
      key === 'department' ||
      key === 'departmentGroup' ||
      key === 'dataAccessPolicy' ||
      key === 'userGroup' ||
      key === 'accessGroup' ||
      key === 'tagGroup' ||
      key === 'communicationGroup' ||
      key === 'userRank' ||
      key === 'secureRowLevel'
    ) {
      return res;
    }
    if (
      val !==
      initialData?.[key as keyof UserTemplate_departmentOccupationTemplate]
    )
      return { ...res, [key]: val };
    else return res;
  }, {});
  return patch;
};
