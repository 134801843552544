import React from 'react';
import { IColumn, Stack, Text } from '@fluentui/react';
import { OccupationRowItem } from '.';
import { OccupationView } from '../view';

export type ColumnStylesProps = {
  onRenderColumnStack: string;
};

export const onRenderItems = (styles: ColumnStylesProps) => {
  const renderItemColumn = (
    item: OccupationRowItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof OccupationRowItem
      ] as string;
      switch (column?.key) {
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <OccupationView occupation={item} />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  };
  return renderItemColumn;
};
