import { Text, Stack } from '@fluentui/react';
import { SVGComponent } from 'common/types/utility';
import React from 'react';
import { useStyles } from './index.styles';

export interface ChartEmptyProps {
  Icon: SVGComponent;
  text: string;
}

export const ChartEmpty: React.FC<ChartEmptyProps> = ({ Icon, text }) => {
  const styles = useStyles();
  return (
    <Stack
      grow
      verticalFill
      verticalAlign="center"
      horizontalAlign="center"
      tokens={{ childrenGap: 15 }}
    >
      <Icon className={styles.icon} />
      <Text variant="medium" className={styles.text}>
        {text}
      </Text>
    </Stack>
  );
};
