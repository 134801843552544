import { useMutation, useQuery } from '@apollo/client';
import { Modal, PrimaryButton, Stack } from '@fluentui/react';
import { CompanyCurrencies } from 'common/graphql/__generated__/CompanyCurrencies';
import { TravelPolicyCreateInput, TravelPolicyPatch } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { Header } from './Header';
import { TravelPolicyCreate, TravelPolicyCreateVariables } from './__generated__/TravelPolicyCreate';
import { TravelPolicyUpdate, TravelPolicyUpdateVariables } from './__generated__/TravelPolicyUpdate';
import { useStyles } from './index.styles';
import { TravelPolicyValues } from './types';
import { getDefaultValues } from './utils';
import { TravelPoliciesType } from '../../list';
import { TravelPolicies } from '../../list/__generated__/TravelPolicies';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validations'
import { TravelPolicy, TravelPolicyVariables } from './__generated__/TravelPolicy';
const COMPANY_CURRENCIES = loader("../../../../../common/graphql/CompanyCurrencies.graphql")
const TRAVEL_POLICY_CREATE = loader("./TravelPolicyCreate.graphql")
const TRAVEL_POLICY_UPDATE = loader("./TravelPolicyUpdate.graphql")
const TRAVEL_POLICIES = loader("../../list/TravelPolicies.graphql");
const TRAVEL_POLICY = loader("./TravelPolicy.graphql");

interface TravelPolicyViewProps {
  visible: boolean;
  isNew: boolean;
  travelPolicyData: TravelPoliciesType | undefined;
  showModal: () => void;
  hideModal: () => void;
}

export const TravelPolicyView: React.FC<TravelPolicyViewProps> = ({
  visible,
  isNew,
  travelPolicyData,
  showModal,
  hideModal,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const formMethods = useForm<TravelPolicyValues>({
    mode: "all",
    resolver: yupResolver(validationSchema())
  })
  const { data: currenciesData } = useQuery<CompanyCurrencies>(COMPANY_CURRENCIES);
  const {
    id,
  } = { ...travelPolicyData }

  const {
    data: travelPolicyDetails,
    loading: travelPolicyDetailsLoading,
  } = useQuery<TravelPolicy, TravelPolicyVariables>(
    TRAVEL_POLICY,
    {
      variables: {
        id: id!,
      },
      skip: !id,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const {
    _rowTimestamp
  } = { ...travelPolicyDetails?.travelPolicy }

  const [
    travelPolicyCreate,
    {
      loading: travelPolicyCreateLoading
    }
  ] = useMutation<TravelPolicyCreate, TravelPolicyCreateVariables>(
    TRAVEL_POLICY_CREATE,
    { errorPolicy: "all" }
  )

  const [
    travelPolicyUpdate,
    {
      loading: travelPolicyUpdateLoading
    }
  ] = useMutation<TravelPolicyUpdate, TravelPolicyUpdateVariables>(
    TRAVEL_POLICY_UPDATE,
    { errorPolicy: "all" }
  )

  const { nodes } = { ...currenciesData?.companyCurrencies }
  const headerTitle = isNew ? "Create" : "Update";

  const { handleSubmit, reset, trigger } = { ...formMethods }

  const defaultValues = getDefaultValues({
    isNew,
    travelPolicyData: travelPolicyDetails?.travelPolicy!,
  });

  const onHandleSubmit = async (values: TravelPolicyValues) => {
    if (isNew) {
      const { errors, } = await travelPolicyCreate({
        variables: {
          input: {
            travelPolicy: {
              ...values,
              description: values.description!,
              currencyId: values.currencyId!,
              companionTickets: Number(values.companionTickets),
            }
          } as TravelPolicyCreateInput
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<TravelPolicies>({
            query: TRAVEL_POLICIES
          });
          if (cacheData && data?.travelPolicyCreate?.travelPolicy) {
            const updatedData: TravelPolicies = {
              travelPolicies: {
                ...cacheData.travelPolicies!,
                nodes: [
                  data?.travelPolicyCreate.travelPolicy,
                  ...cacheData.travelPolicies?.nodes!
                ]
              }
            }
            cache.writeQuery<TravelPolicies>({
              query: TRAVEL_POLICIES,
              data: updatedData,
            });
          }
        }
      })
      if (!errors) {
        addToast('Travel policy created successfully.', {
          appearance: 'success',
        });
        hideModal();
        reset();
      } else addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    } else {
      const fieldsToInclude = Object.keys(values);
      const travelPolicyPatch: TravelPolicyPatch = Object.entries(
        values
      ).reduce((res, [key, val]) => {
        if (
          val !== defaultValues[key as keyof TravelPolicyValues] &&
          fieldsToInclude.includes(key)
        ) {
          if (key === 'companionTickets')
            return { ...res, [key]: Number(val) }
          else
            return { ...res, [key]: val };
        }
        return res;
      }, {});
      const { errors } = await travelPolicyUpdate({
        variables: {
          input: {
            id: id!,
            rowTimestamp: _rowTimestamp || '',
            travelPolicyPatch: !isEmpty(travelPolicyPatch)
              ? travelPolicyPatch
              : undefined,
          },
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Travel policy updated successfully.', {
          appearance: 'success',
        });
      }
    }
  }

  const loading = travelPolicyCreateLoading || travelPolicyUpdateLoading;

  useEffect(() => {
    if (travelPolicyDetails?.travelPolicy) {
      const defaultValues = getDefaultValues({
        isNew,
        travelPolicyData: travelPolicyDetails?.travelPolicy,
      });
      reset(defaultValues);
      trigger();
    }
  }, [travelPolicyDetails, isNew, reset, trigger, visible]);

  return (
    <Stack>
      <PrimaryButton
        onClick={showModal}
        iconProps={{
          iconName: 'Add',
        }}
        text="Travel Policy"
      />
      <Modal
        isOpen={visible}
        isBlocking={true}
        onDismiss={hideModal}
      >
        <FormProvider {...formMethods}>
          <Stack
            className={styles.container}
          >
            <Header
              headerTitle={headerTitle}
              isNew={isNew}
              loading={travelPolicyDetailsLoading}
              onDismiss={hideModal}
            />
            <BasicForm
              currencies={nodes}
            />
            <Footer
              headerTitle={headerTitle}
              loading={loading}
              onSave={handleSubmit(onHandleSubmit)}
              onDismiss={hideModal}
            />
          </Stack>
        </FormProvider>
      </Modal>
    </Stack>
  )
}
