import {
  FontSizes,
  IconButton,
  ITooltipHostStyles,
  makeStyles,
  TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';

interface ProtectButtonProps {
  isProtected?: boolean;
  onPress?: () => void;
}
export const ProtectButton: React.FC<ProtectButtonProps> = ({
  onPress,
  isProtected = false,
}) => {
  const tooltipId = useId('DeleteButton');
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block' },
  };
  const styles = useStyles();
  return (
    <TooltipHost
      content="Change security level"
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
    >
      <IconButton
        onClick={() => onPress?.()}
        disabled={isProtected}
        iconProps={{ iconName: isProtected ? 'LockSolid' : 'Unlock' }}
        ariaLabel="Refresh"
        className={clsx(
          styles.ButtonStyle,
          isProtected ? styles.protected : styles.unProtected
        )}
      />
    </TooltipHost>
  );
};

const useStyles = makeStyles((theme) => ({
  ButtonStyle: {
    fontSize: FontSizes.size18,
  },
  protected: {
    color: theme.palette.green,
  },
  unProtected: {
    color: theme.palette.neutralSecondary,
  },
}));
