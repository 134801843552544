import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useStyles } from './index.styles';
import { AccountingEntryPayCycle } from 'ap/paymentCycle/AccountingEntry/view/__generated__/AccountingEntryPayCycle';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';
import { PayCycleCommonTypes_secureRowLevels } from 'ap/paymentCycle/__generated__/PayCycleCommonTypes';

import {
  ListPayCycleNotes,
  ListPayCycleNotesVariables,
} from 'ap/paymentCycle/view/ActionMenu/PayCycleNotes/__generated__/ListPayCycleNotes';
import { HistoryActionMenuView } from 'common/components/History';
import { ProtectSection } from 'common/components/Protect';
import {
  PayCycleRowProtection,
  PayCycleRowProtectionVariables,
} from 'ap/paymentCycle/view/ActionMenu/__generated__/PayCycleRowProtection';
import { useToasts } from 'react-toast-notifications';
import { PayCycleAccountingNotesSection } from './ActionMenuContents/AccountingEntryNotes';
import { AttachSection } from './ActionMenuContents/Files';
import { PayCycleUploadDocument_payCycleUploadDocument } from 'ap/paymentCycle/__generated__/PayCycleUploadDocument';
const ENTITY_NOTES_LIST = loader(
  '../../../view/ActionMenu/PayCycleNotes/ListPayCycleNotes.graphql'
);
const PAYCYCLE_ROW_PROTECTION = loader(
  '../../../../paymentCycle/view/ActionMenu/PayCycleRowProtection.graphql'
);
const PAYCYCLE_DETAILS = loader('../AccountingEntryPayCycle.graphql');

const ActionConsts = {
  protect: 'Protect_Action',
  attach: 'Attach_Action',
  notes: 'Notes_Action',
  history: 'History_Action',
};
interface ActionMenuProps {
  payCycleDetails: AccountingEntryPayCycle | undefined;
  secureRowLevels: PayCycleCommonTypes_secureRowLevels | undefined | null;
  onUpload?: (
    fileSelected: File,
    documentData: PayCycleUploadDocument_payCycleUploadDocument,
    toastId: string
  ) => void;
}
export const ActionsMenu: React.FC<ActionMenuProps> = ({
  payCycleDetails,
  secureRowLevels,
  onUpload,
}) => {
  const [selectedKey, setSelectedKey] = React.useState(ActionConsts.protect);
  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const { addToast } = useToasts();
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListPayCycleNotes,
    ListPayCycleNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id: payCycleDetails?.payCycle?.id!,
    },
  });
  const getEntityNotes = () => {
    const variables: ListPayCycleNotesVariables = {
      id: payCycleDetails?.payCycle?.id!,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    payCycleDetails?.payCycle?.id,
  ]);

  const [updateRowProtection, { loading: updateLoading, error: updateError }] =
    useMutation<PayCycleRowProtection, PayCycleRowProtectionVariables>(
      PAYCYCLE_ROW_PROTECTION
    );

  const updateRowLevel = async (selectedLevel: string | null) => {
    const inputData =
      selectedLevel !== null
        ? {
          entityId: payCycleDetails?.payCycle?.id!,
          rowSecurityId: selectedLevel,
        }
        : {
          entityId: payCycleDetails?.payCycle?.id!,
          isProtectionRemoval: true,
        };
    await updateRowProtection({
      variables: {
        input: inputData,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAYCYCLE_DETAILS,
          variables: {
            id: payCycleDetails?.payCycle?.id,
          },
        },
      ],
    });
    if (!updateError) {
      addToast('Security/Protection level upgraded', {
        appearance: 'success',
      });
    } else
      addToast('Failed to upgrade protection level', {
        appearance: 'error',
      });
  };
  const getSelectedSection = (key: string) => {
    switch (key) {
      case ActionConsts.protect:
        return (
          <ProtectSection
            secureRowLevels={secureRowLevels?.nodes!}
            secureRowLevelId={payCycleDetails?.payCycle?.secureRowLevel?.id}
            updateLoading={updateLoading}
            updateRowLevel={(selectedLevel) => {
              updateRowLevel(selectedLevel);
            }}
          />
        );
      case ActionConsts.notes:
        return (
          <PayCycleAccountingNotesSection
            payCycleId={payCycleDetails?.payCycle?.id!}
            data={entityNotesData?.payCycle?.notesByEntityId.nodes!}
            listRefreshRequest={getEntityNotesMemo}
          />
        );
      case ActionConsts.history:
        return (
          <HistoryActionMenuView
            moduleName="Payment Cycle"
            numberOfItems={4}
            historyData={
              payCycleDetails?.payCycle?.payCycleHistoriesByEntityId.nodes || []
            }
          />
        );
      case ActionConsts.attach:
        return (
          <AttachSection
            secureRowLevel={secureRowLevels?.nodes!}
            paymentCycle={payCycleDetails}
            onUpload={onUpload}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey || ActionConsts.protect);
                }}
                aria-label="Menu_Actions"
              >
                <PivotItem
                  itemKey={ActionConsts.protect}
                  itemIcon={
                    payCycleDetails?.payCycle?._isProtected ? 'Lock' : 'Unlock'
                  }
                  headerText="Protect"
                  itemCount={0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.attach}
                  itemIcon="Attach"
                  headerText="Files"
                  itemCount={
                    payCycleDetails?.payCycle?.entityDocumentsByEntityId?.nodes
                      ?.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    entityNotesData?.payCycle?.notesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={
                    payCycleDetails?.payCycle?.payCycleHistoriesByEntityId.nodes
                      .length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
                ariaLabel="Collapse"
              />
            </Stack>
          </Stack>
          <Stack>{getSelectedSection(selectedKey)}</Stack>
        </Stack>
      ) : (
        <Stack
          verticalAlign="center"
          horizontal
          horizontalAlign="space-between"
        >
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Protect"
                iconName={
                  payCycleDetails?.payCycle?._isProtected ? 'Lock' : 'Unlock'
                }
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.protect);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Files"
                iconName="Attach"
                count={
                  payCycleDetails?.payCycle?.entityDocumentsByEntityId?.nodes
                    ?.length
                }
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.attach);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Notes"
                iconName="Page"
                count={entityNotesData?.payCycle?.notesByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.notes);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                count={
                  payCycleDetails?.payCycle?.payCycleHistoriesByEntityId.nodes
                    .length
                }
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.history);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
              ariaLabel="Expand"
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
