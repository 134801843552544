import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  row: {
    minWidth: '100% !important',
  },
  disabledText: {
    color: `${theme.palette.neutralTertiary}${theme.isInverted ? '55' : ''}`,
  },
  columnHeight: {
    height: 25,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  onrenderColumnStack: {
    height: 25,
  },
}));
