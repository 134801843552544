import { AppRouter } from 'common/types/globalTypes';

interface RedirectionLinkData {
  isApproved: boolean;
  entityId: string;
  entityType: string;
}

const getRedirectionLink = (data: RedirectionLinkData) => {
  const { isApproved, entityId, entityType } = { ...data };
  switch (entityType) {
    case AppRouter.TRANSACTION_SIGNING:
      return isApproved
        ? `/signing/accounting/${entityId}`
        : `/signing/transaction/${entityId}`;
    case AppRouter.PAYMENT_TRACKING:
      return `/pay/payment_tracking/payment/${entityId}`;
    case AppRouter.TRAVEL_AUTHORIZATION:
      return `/ta/travel-plan/travel/${entityId}`;
    case AppRouter.PURCHASE_ORDER:
      return `/purchase-orders/purchase-order/${entityId}`;
    default:
      return null;
  }
};

export { getRedirectionLink };
