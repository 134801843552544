import { PrimaryButton, Stack } from '@fluentui/react';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import React, { useState } from 'react';
import { ChartOfAccountFields } from '../CreateChartOfAccounts/__generated__/ChartOfAccountFields';
const DIALOG_TITLE = 'Are you sure?';


type UpdateChartOfAccountDefaultProps = {
  data: Pick<ChartOfAccountFields, "id" | "_defaults">;
  onConfirm: () => void;
}

export const UpdateChartOfAccountDefault: React.FC<UpdateChartOfAccountDefaultProps> = ({
  data,
  onConfirm,
}) => {
  const [hidden, setHidden] = useState(true);
  const { _defaults } = { ...data }
  const { _isDefaultUpdateAvailable, _isRemoveDefaultAvailable } = { ..._defaults }
  const buttonText = !!_isDefaultUpdateAvailable ? "Set Default" : "Remove Default";
  const DIALOG_SUB_TITLE = !!_isDefaultUpdateAvailable ?
    'This will set the default for the selected Chart of Account.' :
    'This will remove the default of the selected Chart of Account.';

  const hideButton = (!!_isDefaultUpdateAvailable && !!_isRemoveDefaultAvailable) || (!_isDefaultUpdateAvailable && !_isRemoveDefaultAvailable);

  const _onConfirm = async () => {
    setHidden(true);
    onConfirm();
  }

  return (
    <Stack horizontal>
      {
        !hideButton &&
        <PrimaryButton
          text={buttonText}
          onClick={() => setHidden(false)}
        />
      }
      <ConfirmDialog
        hidden={hidden}
        title={DIALOG_TITLE}
        subText={DIALOG_SUB_TITLE}
        onDismiss={() => setHidden(true)}
        onConfirm={_onConfirm}
      />
    </Stack>
  )
}
