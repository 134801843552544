import { FontWeights, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  rowContainer: {
    height: 25,
  },
  statusType: {
    display: 'block',
    width: 110,
  },
  footerTotal: {
    display: 'block',
    textAlign: 'right',
    fontWeight: FontWeights.bold,
  },
  checkboxContainer: {
    flex: 0.2,
    justifyContent: 'end',
  },

  overageAmount: {
    color: 'red',
  },
  logo: {
    fill: theme.palette.neutralPrimary,
  },
  sectionIcons: {
    height: 30,
    widows: 30,
  },
  rowBaseStyle: { borderLeft: `5px solid transparent` },
}));
