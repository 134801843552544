import React from 'react';
import { Separator, Stack } from '@fluentui/react';
import { useStyles } from './index.styles';
import { NoDataView } from 'common/components/DataPlaceholders';
import { AttachFormModal } from './AttachForm';
import { UploadForm } from './UploadForm';
import { loader } from 'graphql.macro';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  UserSetupCommonData,
  UserSetupCommonData_secureRowLevels,
} from 'settings/account/userSetup/__generated__/UserSetupCommonData';
import {
  UserProfile,
  UserProfileVariables,
} from '../../__generated__/UserProfile';
import { UserSetupUploadDocument_userSetupUploadDocument_document } from './UploadForm/__generated__/UserSetupUploadDocument';
import { DocumentList } from 'common/components/DocumentList';
import { columns } from '../../column.data';

const USER_DETAILS = loader('../../../view/UserProfile.graphql');
const AVAILABLE_DOCUMENT_TYPES = loader('../../../UserSetupCommonData.graphql');
interface AttachSectionProps {
  userDetails: UserProfile | undefined;
  onUpload?: (
    fileSelected: File,
    documentData: UserSetupUploadDocument_userSetupUploadDocument_document,
    toastId: string
  ) => void;
  secureRowLevels: UserSetupCommonData_secureRowLevels | undefined | null;
}
export const AttachSection: React.FC<AttachSectionProps> = ({
  userDetails,
  onUpload,
  secureRowLevels,
}) => {
  const styles = useStyles();
  const client = useApolloClient();

  const { data: AvailableDocumentTypes } = useQuery<UserSetupCommonData>(
    AVAILABLE_DOCUMENT_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        <AttachFormModal
          AvailableDocumentTypes={AvailableDocumentTypes}
          userDetails={userDetails}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={AvailableDocumentTypes}
          userDetails={userDetails}
          onUpload={onUpload}
        />
      </Stack>
      {userDetails?.userProfile?.entityDocumentsByEntityId.nodes?.length! >
        0 && (
        <Stack tokens={{ childrenGap: 10, padding: 10 }}>
          <DocumentList
            isNew={false}
            columns={columns}
            data={
              userDetails?.userProfile?.entityDocumentsByEntityId?.nodes! || []
            }
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevels?.nodes!}
            onRemoveRow={(data) => {
              const cacheData = client.readQuery<
                UserProfile,
                UserProfileVariables
              >({
                query: USER_DETAILS,
                variables: {
                  id: userDetails?.userProfile?.id!,
                },
              });
              const filteredList =
                cacheData?.userProfile?.entityDocumentsByEntityId.nodes.filter(
                  (ele) => ele.id !== data.id
                );
              const updatedData: UserProfile = {
                userProfile: {
                  ...cacheData?.userProfile!,
                  entityDocumentsByEntityId: {
                    ...cacheData?.userProfile?.entityDocumentsByEntityId,
                    nodes: filteredList!,
                  },
                },
              };
              client.writeQuery<UserProfile, UserProfileVariables>({
                query: USER_DETAILS,
                data: updatedData,
                variables: {
                  id: userDetails?.userProfile?.id!,
                },
              });
            }}
          />
        </Stack>
      )}
      <NoDataView
        show={
          userDetails?.userProfile?.entityDocumentsByEntityId.nodes.length === 0
        }
        title="No Attachments"
      />
    </Stack>
  );
};
