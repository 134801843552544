import React, { useState } from 'react';
import {
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { ViewSignature } from './ViewSignature';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useStyles } from './index.styles';
import { useCommonStyles } from 'common/styles';
import { useToasts } from 'react-toast-notifications';
import { CreateSignature } from './CreateSignature';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { BlockBlobClient } from '@azure/storage-blob';
import { SignatureValues, validationSchema } from './types';
import { Form, Formik } from 'formik';
import { UploadStatusType } from 'common/types/globalTypes';
import { UserSignatureUploadDocument } from 'common/graphql/__generated__/UserSignatureUploadDocument';
import { CurrentUserProfile } from 'common/graphql/__generated__/CurrentUserProfile';

const CURRENT_USER_PROFILE = loader(
  '../../../common/graphql/CurrentUserProfile.graphql'
);
const UPLOAD_SIGNATURE = loader(
  '../../../common/graphql/UserSignatureUploadDocument.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);

export const SignatureSection = () => {
  const { data, loading } = useQuery<CurrentUserProfile | undefined>(
    CURRENT_USER_PROFILE,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const styles = useStyles();
  const client = useApolloClient();
  const commonStyles = useCommonStyles();
  const { addToast, updateToast } = useToasts();
  const toastId = `file.name.signature`;
  const [isLoading, setIsLoading] = useState(false)

  const [uploadSignature] = useMutation<UserSignatureUploadDocument>(UPLOAD_SIGNATURE);

  const callNewToast = () => {
    addToast(`Uploading Signature...`, {
      appearance: 'info',
      id: toastId,
      autoDismiss: false,
    });
  };

  let initialValues: SignatureValues = { imageUrl: null };
  if (data?.currentUserProfile?.signatureDocument?._downloadLink !== null) {
    initialValues = {
      imageUrl: data?.currentUserProfile?.signatureDocument?._downloadLink!,
    };
  } else initialValues = { imageUrl: null };

  const handleSubmit = async (values: SignatureValues) => {
    setIsLoading(true)
    callNewToast();
    const { data: uploadSignatureData, errors } = await uploadSignature({});
    const { uploadLink, document } = {
      ...uploadSignatureData?.userSignatureUploadDocument,
    };
    if (errors) {
      updateToast(toastId!, {
        content: `Signature Upload failed`,
        appearance: 'error',
        autoDismiss: true,
      });
    }

    if (uploadSignatureData) {
      const observer = client.subscribe({
        query: DOCUMENT_UPLOAD_STATUS,
        variables: {
          documentId: document?._documentFileId!,
        },
      });

      const subscription = observer.subscribe((data) => {
        setIsLoading(false)
        const subscribedData = data.data as OnDocumentUploadStatus;

        const { status, document } = {
          ...subscribedData.documentUploadStatus,
        };

        if (status.type === UploadStatusType.VALIDATING) {
          updateToast(toastId!, {
            content: ` Validating File`,
            appearance: 'info',
            autoDismiss: false,
          });
        } else if (status.type === UploadStatusType.FAILURE) {
          subscription.unsubscribe();
          updateToast(toastId!, {
            content: ` Filed uploading Signature - ${status.message!}`,
            appearance: 'error',
            autoDismiss: true,
          });
        } else {
          const cacheData = client.readQuery<CurrentUserProfile>({
            query: CURRENT_USER_PROFILE,
          });

          const updatedData: CurrentUserProfile = {
            currentUserProfile: {
              ...cacheData?.currentUserProfile!,
              signatureDocument: {
                ...cacheData?.currentUserProfile?.signatureDocument!,
                id: document?.id!,
                _downloadLink: document?._downloadLink!,
              },
            },
          };
          client.writeQuery<CurrentUserProfile>({
            query: CURRENT_USER_PROFILE,
            data: updatedData,
          });

          updateToast(toastId!, {
            content: 'Signature Successfully uploaded',
            appearance: 'success',
            autoDismiss: true,
          });
        }
      });

      const clientBlob = new BlockBlobClient(uploadLink!);
      const response = await fetch(values.imageUrl!);
      const blob = await response.blob();

      await clientBlob.uploadData(blob);
    }
  };

  return (
    <Formik<SignatureValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, dirty, resetForm, errors }) => {
        return (
          <Form>
            <Stack tokens={{ padding: 25 }}>
              {loading || !data?.currentUserProfile ? (
                <ProgressIndicator label={'Loading signature'} barHeight={2} />
              ) : (
                <Stack className={styles.container} horizontalAlign="center">
                  <Stack tokens={{ padding: '25px 0px 80px', childrenGap: 10 }}>
                    <ViewSignature
                      loading={isLoading}
                      onCancel={resetForm}
                      onSubmit={submitForm}
                    />
                  </Stack>
                  <Stack className={styles.labelContainer}>
                    {!data.currentUserProfile._isUserSignatureUpdatable && (
                      <MessageBar
                        messageBarType={MessageBarType.info}
                        className={commonStyles.colorThemePrimary}
                      >
                        Your signature is currently included in the digital
                        signature of active approvals and cannot be changed. You
                        will be able to update your signature once the approvals
                        you are participating in have been finalized.
                      </MessageBar>
                    )}
                  </Stack>

                  {data.currentUserProfile._isUserSignatureUpdatable && (
                    <Stack horizontalAlign="center">
                      <Text className={commonStyles.bold} variant="xLarge">
                        Create New Signature
                      </Text>

                      <CreateSignature
                        name={data.currentUserProfile.name}
                        onSignatureCreate={() => { }}
                      />
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
