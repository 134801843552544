import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  IconButton,
  PrimaryButton,
  ScrollablePane,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityAction, EntityType } from 'common/types/utility';
import { NoDataView } from 'common/components/DataPlaceholders';
import { useCommonStyles } from 'common/styles';
import {
  CompanyCorporateYearsOrderBy,
  CompanyCorporateYearUpdateInput,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AccountingYearView } from '../view/AccountingYearView';
import {
  CompanyCorporateYear,
  CompanyCorporateYearVariables,
} from '../view/__generated__/CompanyCorporateYear';
import {
  CompanyCorporateYearUpdate,
  CompanyCorporateYearUpdateVariables,
} from '../view/__generated__/CompanyCorporateYearUpdate';
import { Header } from './Header';
import { useStyles } from './index.styles';
import { CompanyCorporatePeriod, PeriodList } from './PeriodList';
import { AccountingPeriodShimmer } from './Shimmer';
import { YearList } from './YearList';
import {
  CompanyCorporateYears,
  CompanyCorporateYearsVariables,
  CompanyCorporateYears_companyCorporateYears_nodes,
} from './__generated__/CompanyCorporateYears';
const COMPANY_CORPORATE_YEARS = loader('./CompanyCorporateYears.graphql');
const COMPANY_CORPORATE_YEAR = loader('../view/CompanyCorporateYear.graphql');
const COMPANY_CORPORATE_YEAR_UPDATE = loader(
  '../view/CompanyCorporateYearUpdate.graphql'
);
export type CompanyCorporateYearType =
  CompanyCorporateYears_companyCorporateYears_nodes;
interface PeriodSelectionData {
  data: CompanyCorporatePeriod[];
  yearId: string;
  yearRowTimestamp: string;
}

export const AccountingPeriodEntry = () => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const commonStyles = useCommonStyles();
  const [selectedGroup, setSelectedGroup] =
    useState<CompanyCorporateYearType | null>();
  const [corporateYearData, setCorporateYearData] =
    useState<CompanyCorporateYearType>();
  const [selectedList, setSelectedList] = useState<CompanyCorporatePeriod[]>(
    []
  );
  const [selectedYearId, setSelectedYearId] = useState<string>();
  const [selectedYearRowTimestamp, setSelectedYearRowTimestamp] =
    useState<string>();
  const [hideCreateForm, { toggle: toggleCreateForm }] = useBoolean(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const columnHeadings: string[] = [
    'Year',
    'Start Date',
    'End Date',
    'Open',
    'Entry Allowed',
    'Action',
  ];
  const yearIdRef = useRef('');
  const updateYearIdRef = (newItems: string) => {
    yearIdRef.current = newItems;
  };

  const {
    data: companyCorporateYearsData,
    loading: companyCorporateYearsLoading,
    refetch,
  } = useQuery<CompanyCorporateYears, CompanyCorporateYearsVariables>(
    COMPANY_CORPORATE_YEARS,
    {
      variables: {
        orderBy: [CompanyCorporateYearsOrderBy.FISCAL_YEAR_ASC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [
    getCompanyCorporatePeriods,
    {
      data: companyCorporatePeriodsData,
      loading: companyCorporatePeriodsLoading,
      variables: companyCorporatePeriodsVariables,
      networkStatus,
    },
  ] = useLazyQuery<CompanyCorporateYear, CompanyCorporateYearVariables>(
    COMPANY_CORPORATE_YEAR,
    {
      variables: {
        id: yearIdRef.current,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [companyCorporateYearUpdate] = useMutation<
    CompanyCorporateYearUpdate,
    CompanyCorporateYearUpdateVariables
  >(COMPANY_CORPORATE_YEAR_UPDATE, { errorPolicy: 'all' });

  const refetching =
    companyCorporateYearsLoading && networkStatus !== NetworkStatus.fetchMore;

  const _headerOnClick = (corporateYear: CompanyCorporateYearType) => {
    if (selectedGroup?.id !== corporateYear.id) {
      getCompanyCorporatePeriods({
        variables: {
          ...companyCorporatePeriodsVariables,
          id: corporateYear.id,
        },
      });
    }
    setSelectedGroup((prevState) => {
      return prevState?.id === corporateYear.id ? null : corporateYear;
    });
  };

  const onCreateCreateYear = () => {
    setIsNew(true);
    setCorporateYearData(undefined);
    toggleCreateForm();
  };

  const onEditClick = async (corporateYear: CompanyCorporateYearType) => {
    setIsNew(false);
    setCorporateYearData(corporateYear);
    toggleCreateForm();
  };

  const _onConfirm = async () => {
    let selectedData: EntityDeleteInput[] = selectedList
      ?.filter((entity) => entity._isDeletable)
      .map((obj) => {
        return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
      });
    const { errors } = await companyCorporateYearUpdate({
      variables: {
        input: {
          id: selectedYearId,
          rowTimestamp: selectedYearRowTimestamp,
          companyCorporatePeriodsDelete: selectedData,
        } as CompanyCorporateYearUpdateInput,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: COMPANY_CORPORATE_YEAR,
          variables: {
            id: selectedYearId,
          },
        },
      ],
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      setSelectedList([]);
      setSelectedYearId(undefined);
      setSelectedYearRowTimestamp(undefined);
      addToast('Period Deleted Successfully', {
        appearance: 'success',
      });
    }
  };

  const onPeriodSelection = (selectedData: PeriodSelectionData) => {
    setSelectedList(selectedData.data);
    setSelectedYearId(selectedData.yearId);
    setSelectedYearRowTimestamp(selectedData.yearRowTimestamp);
  };

  return (
    <Stack grow className={styles.container}>
      <ScrollablePane>
        <Stack className={styles.innerContainer}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <Stack
              tokens={{ childrenGap: 20 }}
              className={commonStyles.listHeaderContainer}
            >
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                className={commonStyles.listTitleContainer}
              >
                <Text variant="xLarge">Accounting Periods</Text>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <ActionMessageModal
                    entityType={EntityType.AccountingPeriod}
                    action={EntityAction.Delete}
                    onConfirm={_onConfirm}
                    disabled={
                      !selectedList.some((selected) => selected._isDeletable)
                    }
                    visible={selectedList.length > 0}
                    multiple={{
                      validCount: selectedList.filter(
                        (selected) => selected._isDeletable
                      ).length,
                      invalidNames: selectedList
                        .filter((selected) => !selected._isDeletable)
                        .map((cannotDelete) =>
                          cannotDelete.fiscalPeriod.toString()
                        ),
                    }}
                  />
                  <TooltipHost content={'Refresh'}>
                    <IconButton
                      onClick={() => {
                        refetch();
                        setSelectedGroup(null);
                      }}
                      iconProps={{ iconName: 'refresh' }}
                    />
                  </TooltipHost>
                  <PrimaryButton
                    onClick={onCreateCreateYear}
                    iconProps={{
                      iconName: 'Add',
                    }}
                    text="Add New Year"
                  />
                </Stack>
              </Stack>
            </Stack>
            <Header columnHeadings={columnHeadings} />
          </Sticky>
          {companyCorporateYearsLoading ? (
            <AccountingPeriodShimmer />
          ) : (
            <>
              {companyCorporateYearsData?.companyCorporateYears?.nodes
                .length ? (
                companyCorporateYearsData?.companyCorporateYears?.nodes.map(
                  (corporateYear, index) => {
                    const isOpen = selectedGroup?.id === corporateYear.id;
                    return (
                      <Stack key={index.toString()}>
                        <YearList
                          isOpen={isOpen}
                          corporateYear={corporateYear}
                          availableCount={
                            corporateYear.companyCorporatePeriodsByFiscalYearId
                              .totalCount
                          }
                          onHeaderClick={() => {
                            _headerOnClick(corporateYear);
                            updateYearIdRef(corporateYear.id);
                          }}
                          onEditClick={() => onEditClick(corporateYear)}
                          refetching={refetching}
                        />
                        {isOpen && (
                          <PeriodList
                            corporateYear={
                              companyCorporatePeriodsData?.companyCorporateYear
                            }
                            loading={companyCorporatePeriodsLoading}
                            onPeriodSelection={(data) => {
                              onPeriodSelection({
                                data: data,
                                yearId: data[0]?.corporateYear?.id!,
                                yearRowTimestamp:
                                  data[0]?.corporateYear?._rowTimestamp!,
                              });
                            }}
                          />
                        )}
                      </Stack>
                    );
                  }
                )
              ) : (
                <NoDataView show title="No Years Available" />
              )}
            </>
          )}
          {hideCreateForm && (
            <AccountingYearView
              visible={hideCreateForm}
              onDismiss={toggleCreateForm}
              corporateYear={corporateYearData}
              isNew={isNew}
            />
          )}
        </Stack>
      </ScrollablePane>
    </Stack>
  );
};
