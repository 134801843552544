import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stack, IconButton, TooltipHost } from '@fluentui/react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { useBoolean } from '@fluentui/react-hooks';
import { Text, Icon } from '@fluentui/react';
import {
  NetworkStatus,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  PersoanlDocuments,
  PersoanlDocumentsVariables,
  PersoanlDocuments_entityDocumentPersonalPools_nodes,
} from './__generated__/PersoanlDocuments';
import { loader } from 'graphql.macro';
import {
  EntityDeleteInput,
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
  UploadStatusType,
} from 'common/types/globalTypes';
import { TABLE_ROWS } from 'common/constants';
import { useToasts } from 'react-toast-notifications';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import {
  toFilterOrVariable,
  toFilterVariable,
  toOrderByVariable,
} from './utils';
import { useStyles } from './index.styles';
import { DownloadButton } from 'common/components/DownloadButton';
import {
  UserUploadDocument,
  UserUploadDocumentVariables,
} from '../__generated__/UserUploadDocument';
import { BlockBlobClient } from '@azure/storage-blob';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { documentColumns } from './columns.data';
import {
  getGlobalDateFormat,
  invoiceDateFormat,
} from 'common/utils/dateFormats';
import clsx from 'clsx';
import {
  EntityDocumentDelete,
  EntityDocumentDeleteVariables,
} from 'common/graphql/__generated__/EntityDocumentDelete';
import {
  GetDocumentPoolCommonData,
  GetDocumentPoolCommonDataVariables,
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { CompanyCurrencies } from 'common/graphql/__generated__/CompanyCurrencies';
import { formatDropdownOptions } from 'common/utils';
import { UploadDocumentForm } from '../UploadDocumentsForm';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { filterOptionProps } from 'documents/documentAssignment/folder/list/GroupHeader';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { EntityDocuments_entityDocuments_nodes } from 'documents/documentAssignment/folder/list/__generated__/EntityDocuments';
import { ExtractionTypes } from 'documents/documentAssignment/folder/list/FolderView/FolderViewHeader/UploadForm/__generated__/ExtractionTypes';
import { getFileTypesList } from 'documents/documentAssignment/folder/list/FolderView/FolderViewHeader/UploadForm/utils';

const CURRENCY_DATA = loader(
  '../../../common/graphql/CompanyCurrencies.graphql'
);
const GENERAL_DATA = loader(
  '../../../documents/GetDocumentPoolCommonData.graphql'
);
const DOCUMENTSLIST = loader('./PersonalDocuments.graphql');
const UPLOAD_DOCUMENT = loader('../UserUploadDocument.graphql');
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const DELETE_DOCUMENTS = loader(
  '../../../common/graphql/EntityDocumentDelete.graphql'
);
const EXTRACTION_TYPES = loader(
  '../../documentAssignment/folder/list/FolderView/FolderViewHeader/UploadForm/ExtractionTypes.graphql'
);
type DocumentListItemType = EntityDocuments_entityDocuments_nodes;
type DocumentProps = PersoanlDocuments_entityDocumentPersonalPools_nodes;
interface MyDocumentListProps {
  selectedItems: (dataList: DocumentProps[]) => void;
  openDeleteDialog: boolean;
  onDismiss: () => void;
  toggleHideUploadDialog: () => void;
  uploadFormVisibility: boolean;
  filterOptions: filterOptionProps;
  callDeleteFunction?: boolean;
  resetFunctionCall: (value: boolean) => void;
}

export const MyDocumentList: React.FC<MyDocumentListProps> = ({
  selectedItems,
  onDismiss,
  toggleHideUploadDialog,
  uploadFormVisibility,
  callDeleteFunction,
  resetFunctionCall,
  filterOptions,
}) => {
  const styles = useStyles();
  const { updateToast, addToast } = useToasts();
  const [hideUpdateDialog, { toggle: toggleUpdateDialog }] = useBoolean(false);

  const [selectedDocument, setSelectedDocument] =
    useState<DocumentListItemType | null>(null);
  const [defaultDocumentTypeId, setDefaultDocumentTypeId] = useState<number>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(documentColumns);
  const [selectedList, setselectedList] = useState<DocumentProps[]>([]);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>({
    column: 'date',
    direction: 'desc' as OrderDirection,
  });
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const client = useApolloClient();
  const { data: GeneralData } = useQuery<
    GetDocumentPoolCommonData,
    GetDocumentPoolCommonDataVariables
  >(GENERAL_DATA, {
    variables: {
      isDocumentUpload: true,
    },
  });
  const { data: extractionTypesData } = useQuery<ExtractionTypes>(
    EXTRACTION_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { data: currencyData } = useQuery<CompanyCurrencies>(CURRENCY_DATA);
  const {
    loading: personalDocumentLoading,
    data: personalDocumentListData,
    fetchMore,
    refetch,
    variables,
    networkStatus,
  } = useQuery<PersoanlDocuments, PersoanlDocumentsVariables>(DOCUMENTSLIST, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [
        EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
        EntityDocumentsOrderBy.PRIMARY_KEY_ASC,
      ],
      filter: {
        _isReportingDocument: { equalTo: false },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [uploadDocument] = useMutation<
    UserUploadDocument,
    UserUploadDocumentVariables
  >(UPLOAD_DOCUMENT);
  const documentOptions =
    GeneralData?.personalPoolAvailableDocumentTypes?.nodes.map((item) => ({
      key: item.id,
      text: item?.documentType! || '',
      isAccountingDocument: item?.isAccountingDocument || undefined,
      isSigningRequired: item?.isSigningRequired,
      extractionTypes: item.extractionTypes,
    }));

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const _renderItemColumn = (
    item: DocumentListItemType | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    const fieldContent = item?.[
      column?.fieldName as keyof DocumentListItemType
    ] as string;
    if (item) {
      switch (column?.key) {
        case 'documentType':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.columnStack}
            >
              <Text variant="medium">{item.documentTypes?.documentType}</Text>
            </Stack>
          );
        case '_documentContents':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.columnStack}
            >
              {item._documentContents && (
                <Text variant="medium">{item._documentContents}</Text>
              )}
            </Stack>
          );
        case 'isoCode':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.columnStack}
            >
              {item.currency?.isoCode && (
                <Text variant="medium">{item.currency?.isoCode}</Text>
              )}
            </Stack>
          );
        case 'indexAmount':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="end"
              className={styles.columnStack}
            >
              <AmountTextView value={item.indexAmount} />
            </Stack>
          );
        case 'usedTotal':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="end"
              className={styles.columnStack}
            >
              <AmountTextView value={item.documentAppliedAmounts?.usedTotal!} />
            </Stack>
          );
        case 'remainingTotal':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="end"
              className={styles.columnStack}
            >
              <AmountTextView
                value={item.documentAppliedAmounts?.remainingTotal!}
              />
            </Stack>
          );

        case 'comment':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.columnStack}
            >
              {item.comment && <Text variant="medium">{item.comment}</Text>}
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              className={clsx(styles.columnStack, styles.fileReferenceCell)}
            >
              <Icon
                iconName={fileType(item.iconType || 'OTHER')}
                className={fileTypeColor(item.iconType || 'OTHER')}
              />
              <Text variant="medium">{item.fileReference}</Text>
            </Stack>
          );
        case '_sharedByUser':
          return (
            <Stack verticalAlign="center" className={styles.columnStack}>
              {item._sharedByUser && (
                <Text variant="medium">{item._sharedByUser}</Text>
              )}
            </Stack>
          );
        case 'action':
          return (
            <Stack verticalAlign="center" tokens={{ childrenGap: 3 }}>
              <DownloadButton entityDocumentId={item.id} />
            </Stack>
          );
        case 'view':
          const viewDocumentVisible =
            item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );

        case 'edit':
          return (
            <Stack verticalAlign="center" tokens={{ childrenGap: 3 }}>
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                onClick={() => {
                  setDefaultDocumentTypeId(item.documentTypes?.id);
                  setSelectedDocument(item);
                  toggleUpdateDialog();
                }}
              />
            </Stack>
          );
        case '_uploadDate':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="end"
              className={styles.columnStack}
            >
              <Text variant="medium">
                {getGlobalDateFormat(item._uploadDate || '')}
              </Text>
            </Stack>
          );
        default:
          return (
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.columnStack}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const [deleteDocuments] = useMutation<
    EntityDocumentDelete,
    EntityDocumentDeleteVariables
  >(DELETE_DOCUMENTS, { errorPolicy: 'all' });

  const currencyTypes = formatDropdownOptions(
    currencyData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode + '-' + item.name!,
      includeAll: false,
    }
  );
  const handleDelete = async () => {
    const selectionArray: DocumentProps[] = selectedList;
    const selectedData = selectionArray;
    const toDelete: DocumentProps[] = selectedData.map((_, index) => {
      return selectedData![index];
    });
    let deletableDocs = toDelete?.filter((item) => item._isDeletable !== false);
    if (deletableDocs.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableDocs.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      const { errors } = await deleteDocuments({
        variables: { input: { entityDelete: deleteInputArray } },
        optimisticResponse: {
          entityDocumentDelete: {
            deletedEntities: deleteInputArray,
          },
        },
        update: (cache, { data }) => {
          const deletedIds = data?.entityDocumentDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );
          if (deletedIds) {
            const filteredList =
              personalDocumentListData?.entityDocumentPersonalPools?.nodes.filter(
                (emp) => deletedIds.indexOf(emp.id) === -1
              );
            const newData: PersoanlDocuments = {
              entityDocumentPersonalPools: {
                nodes: filteredList!,
                pageInfo:
                  personalDocumentListData?.entityDocumentPersonalPools
                    ?.pageInfo!,
                totalCount:
                  personalDocumentListData?.entityDocumentPersonalPools
                    ?.totalCount! - deletedIds.length,
              },
            };
            cache.writeQuery<PersoanlDocuments, PersoanlDocumentsVariables>({
              query: DOCUMENTSLIST,
              variables,
              data: newData,
            });
          }
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        resetFunctionCall(false);
        addToast('Record deleted Successfully', {
          appearance: 'success',
        });
        onDismiss();
      }
    }
  };

  const handleDeleteMemo = useCallback(handleDelete, [callDeleteFunction]);

  useEffect(() => {
    if (callDeleteFunction) {
      handleDeleteMemo();
    }
  }, [callDeleteFunction, handleDeleteMemo]);

  const { filterTypes, startsWith } = { ...filterOptions };

  const handleSearch = async (showMore?: boolean) => {
    const searchOption = startsWith
      ? 'startsWithInsensitive'
      : 'includesInsensitive';

    const filtersApplied: EntityDocumentFilter | undefined = filterTypes?.length
      ? ({
          and: toFilterVariable(filterTypes, searchOption),
          or: toFilterOrVariable(filterTypes),
        } as EntityDocumentFilter)
      : undefined;
    const variables: PersoanlDocumentsVariables = {
      first: TABLE_ROWS,
      orderBy: toOrderByVariable(sortOrderParam),
      filter: {
        ...filtersApplied,
        _isReportingDocument: { equalTo: false },
      },
      after: showMore
        ? personalDocumentListData?.entityDocumentPersonalPools?.pageInfo
            ?.endCursor
        : undefined,
    };
    if (showMore) fetchMore({ variables });
    else refetch(variables);
  };

  const handleSearchMemo = useCallback(handleSearch, [
    filterTypes,
    sortOrderParam,
    startsWith,
  ]);

  const renderRef = useRef(false);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [filterTypes, sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    if (!renderRef.current) renderRef.current = true;
    else {
      handleSearchMemo(false);
    }
  }, [startsWith, handleSearchMemo]);

  return (
    <>
      <InfiniteList
        loading={personalDocumentLoading}
        items={
          !refetching
            ? personalDocumentListData?.entityDocumentPersonalPools?.nodes || []
            : undefined
        }
        hasNextPage={
          personalDocumentListData?.entityDocumentPersonalPools?.pageInfo
            .hasNextPage
        }
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          // if (column) _onColumnClick(column);
          if (column) onColumnClick(column);
        }}
        // onLoadMore={() => handleSortAndPagination(sortOrderParam, true)}
        onLoadMore={async () => await handleSearch(true)}
        onSelectionChanged={(arr) => {
          setselectedList(arr);
          selectedItems(arr);
        }}
        isSelectedOnFocus={false}
      />
      <Stack style={{ alignSelf: 'center' }}>
        <DocumentViewModal
          centerAlign
          onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
          {...docViewState}
        />
      </Stack>
      {/* for Upload Modal */}

      {
        <UploadDocumentForm
          documentOptions={documentOptions!}
          companyCurrencies={currencyTypes}
          visible={uploadFormVisibility}
          onDismiss={toggleHideUploadDialog}
          uploadDocument={{
            uploadDocumentData: (
              documentType,
              fileSelected,
              content,
              indexName,
              indexDescription,
              indexReferenceNumber,
              indexTransactionDate,
              indexAmount,
              indexCurrencyId,
              comment,
              extractionTypeId
            ) => {
              let filteredFilesList: File[] = fileSelected;
              if (extractionTypeId) {
                const selectedExtractionType =
                  extractionTypesData?.extractionTypes?.nodes.find(
                    (node) => node.id === extractionTypeId
                  );
                const fileTypesAllowed = getFileTypesList(
                  selectedExtractionType
                );
                filteredFilesList = fileSelected.filter((file) => {
                  return fileTypesAllowed?.includes(file.type);
                });
                if (fileSelected.length > filteredFilesList.length)
                  addToast('Files with invalid data types are not uploaded', {
                    appearance: 'error',
                  });
              }
              filteredFilesList.map(async (fileEntity, fileIndex) => {
                const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
                addToast(`Uploading ${fileEntity?.name}...`, {
                  appearance: 'info',
                  id: toastId,
                  autoDismiss: false,
                });
                const uploadMutationResults = await uploadDocument({
                  variables: {
                    document: {
                      documentTypeId: parseInt(documentType.key.toString()),
                      description: content ? content : '',
                      comment: comment,
                      filename: fileEntity.name,
                      indexName: indexName ? indexName : '',
                      indexDescription: indexDescription
                        ? indexDescription
                        : '',
                      indexReferenceNumber: indexReferenceNumber
                        ? indexReferenceNumber
                        : '',
                      indexTransactionDate: indexTransactionDate
                        ? invoiceDateFormat(indexTransactionDate)
                        : null,
                      indexAmount: indexAmount ? indexAmount : null,
                      indexCurrencyId: indexCurrencyId ? indexCurrencyId : null,
                      extractionTypeId: extractionTypeId,
                    },
                  },
                });

                if (uploadMutationResults.errors)
                  updateToast(toastId!, {
                    content: `Upload of ${fileEntity.name} failed`,
                    appearance: 'error',
                    autoDismiss: true,
                  });

                if (
                  uploadMutationResults.data?.userUploadDocument?.document &&
                  uploadMutationResults.data.userUploadDocument.document
                    ._documentFileId
                ) {
                  const observer = client.subscribe({
                    query: DOCUMENT_UPLOAD_STATUS,
                    variables: {
                      documentId:
                        uploadMutationResults.data.userUploadDocument.document
                          ._documentFileId!,
                    },
                  });

                  const subscription = observer.subscribe((data) => {
                    const subscribedData = data.data as OnDocumentUploadStatus;

                    const { status, document } = {
                      ...subscribedData.documentUploadStatus,
                    };

                    if (status.type === UploadStatusType.VALIDATING) {
                      updateToast(toastId!, {
                        content: status.message
                          ? `Validating files ${fileEntity.name} - ${status.message}`
                          : `Validating files ${fileEntity.name}`,
                        appearance: 'info',
                        autoDismiss: false,
                      });
                    } else if (status.type === UploadStatusType.EXTRACTING) {
                      updateToast(toastId!, {
                        content: status.message
                          ? `Extracting data from ${fileEntity.name} - ${status.message}`
                          : `Extracting data from ${fileEntity.name}`,
                        appearance: 'info',
                        autoDismiss: false,
                      });
                    } else if (status.type === UploadStatusType.FAILURE) {
                      subscription.unsubscribe();
                      updateToast(toastId!, {
                        content: status.message
                          ? `Upload of ${fileEntity.name} failed - ${status.message}`
                          : `Upload of ${fileEntity.name} failed`,
                        appearance: 'error',
                        autoDismiss: true,
                      });
                    } else if (status.type === UploadStatusType.WARNING) {
                      subscription.unsubscribe();
                      updateToast(toastId!, {
                        content: status.message
                          ? `Warning for file ${fileEntity.name}: ${status.message}`
                          : `Warning for file ${fileEntity.name}`,
                        appearance: 'warning',
                        autoDismiss: true,
                      });
                    } else {
                      subscription.unsubscribe();
                      updateToast(toastId!, {
                        content: status.message
                          ? `Successfully uploaded ${fileEntity.name}: ${status.message}`
                          : `Successfully uploaded ${fileEntity.name}`,
                        appearance: 'success',
                        autoDismiss: true,
                      });

                      const cacheData = client.readQuery<
                        PersoanlDocuments,
                        PersoanlDocumentsVariables
                      >({
                        query: DOCUMENTSLIST,
                      });

                      client.writeQuery<
                        PersoanlDocuments,
                        PersoanlDocumentsVariables
                      >({
                        query: DOCUMENTSLIST,
                        data: {
                          entityDocumentPersonalPools: {
                            ...cacheData?.entityDocumentPersonalPools!,
                            nodes: [
                              { ...document! },
                              ...cacheData?.entityDocumentPersonalPools?.nodes!,
                            ],
                          },
                        },
                      });
                    }
                  });

                  const clientBlob = new BlockBlobClient(
                    uploadMutationResults.data.userUploadDocument.uploadLink
                  );
                  await clientBlob.uploadData(fileEntity);
                  updateToast(toastId!, {
                    content: `Validating ${fileEntity.name}...`,
                  });
                }
              });
            },
          }}
        />
      }

      {/* for Update Modal */}
      {hideUpdateDialog && (
        <UploadDocumentForm
          documentOptions={documentOptions!}
          companyCurrencies={currencyTypes}
          documentData={selectedDocument || null}
          defaultDocumentTypeId={defaultDocumentTypeId}
          visible={hideUpdateDialog}
          onUpdate={(updatedDocuments) => {
            const position = selectedList.findIndex(
              (item) => updatedDocuments[0].id === item.id
            );
            let newSelectedList = [...selectedList];
            newSelectedList[position] = updatedDocuments[0];
            setselectedList(newSelectedList);
            selectedItems(newSelectedList);
          }}
          onDismiss={toggleUpdateDialog}
        />
      )}
    </>
  );
};
