import { TravelerAvailableDocumentTypes } from 'common/graphql/__generated__/TravelerAvailableDocumentTypes';
import React from 'react';
import { TravelerDetails_traveler } from '../../../__generated__/TravelerDetails';
import { loader } from 'graphql.macro';
import { TravelerUploadDocument, TravelerUploadDocumentVariables, TravelerUploadDocument_travelerUploadDocument } from 'common/graphql/__generated__/TravelerUploadDocument';
import { TravelerValues } from '../../../interfaces';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { UploadFiles } from 'common/components/UploadFiles';
import { BlockBlobClient } from '@azure/storage-blob';
const TRAVELER_UPLOAD_DOCUMENT = loader('../../../../../../../../../../../../../../common/graphql/TravelerUploadDocument.graphql');

interface UploadFormProps {
  availableDocumentTypes: TravelerAvailableDocumentTypes | undefined;
  traveler: TravelerDetails_traveler;
  onUpload?: (
    fileSelected: File,
    documentData: TravelerUploadDocument_travelerUploadDocument,
    toastId: string
  ) => void;
}

export const UploadForm: React.FC<UploadFormProps> = ({
  traveler,
  availableDocumentTypes,
  onUpload
}) => {
  const { updateToast, addToast } = useToasts();
  const { formState: { isDirty } } = useFormContext<TravelerValues>();

  const [uploadDocument] = useMutation<
    TravelerUploadDocument,
    TravelerUploadDocumentVariables
  >(TRAVELER_UPLOAD_DOCUMENT, { errorPolicy: 'all' });

  const { isDraft, id } = { ...traveler };
  const documentTypeOptions =
    availableDocumentTypes?.travelerAvailableDocumentTypes?.nodes.map(
      (doctype) =>
      ({
        key: doctype.id,
        text: doctype.documentType || '',
        isAccountingDocument: doctype.isAccountingDocument,
        title:
          isDraft && doctype.isAccountingDocument
            ? 'Upload prohibited during/after the approval process'
            : undefined,
        disabled: (!isDraft && doctype.isAccountingDocument) || undefined,
      } as DocumentTypeOption)
    ) || [];

  return (
    <UploadFiles
      documentTypes={documentTypeOptions!}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, filSelected) => {
          filSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });
            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                travelerId: id!,
              },
            });
            if (uploadMutationResults.errors) {
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });
            }
            if (
              uploadMutationResults.data?.travelerUploadDocument?.document &&
              uploadMutationResults.data?.travelerUploadDocument?.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data?.travelerUploadDocument,
                toastId!
              );
              const client = new BlockBlobClient(
                uploadMutationResults.data?.travelerUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
      disableUpload={isDirty}
    />
  )
}
