import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  scrollboxWrapper: {
    width: '100%',
    overflowX: 'scroll',
    paddingBottom: 20,
  },
  mainContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
  accountingRowMargin: {
    marginTop: '5px !important',
  },
  separator: {
    left: 0,
    position: 'sticky',
  },
  renderAccountingAmount: {
    paddingTop: 10,
  },
}));
