import React, { useCallback, useState } from 'react';
import { AttachFormProps } from '..';
import { useStyles } from './index.styles';
import { useToasts } from 'react-toast-notifications';
import {
  AttachableTravelAuthorizationDocuments,
  AttachableTravelAuthorizationDocumentsVariables,
  AttachableTravelAuthorizationDocuments_attachableTravelAuthorizationDocuments_nodes,
} from './__generated__/AttachableTravelAuthorizationDocuments';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { TABLE_ROWS } from 'common/constants';
import {
  AttachTravelAuthorizationDocument,
  AttachTravelAuthorizationDocumentVariables,
} from './__generated__/AttachTravelAuthorizationDocument';
import {
  DirectionalHint,
  IColumn,
  IDetailsRowProps,
  IRenderFunction,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { EntityDocumentFilter } from 'common/types/globalTypes';
import { SortOrder } from '../types';
import { toOrderByVariable } from '../utils';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { _columns } from './columns.data';
import {
  TravelAuthorizationAvailableDocumentTypes,
  TravelAuthorizationAvailableDocumentTypesVariables,
} from '../../__generated__/TravelAuthorizationAvailableDocumentTypes';

const ATTACHABLE_TRAVEL_AUTHORIZATION_DOCUMENTS = loader(
  './AttachableTravelAuthorizationDocuments.graphql'
);
const ATTACH_TRAVEL_AUTHORIZATION_DOCUMENT = loader(
  './AttachTravelAuthorizationDocument.graphql'
);
const TRAVEL_AUTHORIZATION = loader(
  '../../../../../TravelAuthorization.graphql'
);
const TRAVEL_AUTHORIZATION_AVAILABLE_DOCUMENT_TYPES = loader(
  '../../TravelAuthorizationAvailableDocumentTypes.graphql'
);

type AttachableDataType =
  AttachableTravelAuthorizationDocuments_attachableTravelAuthorizationDocuments_nodes;

type AttachFormModalProps = AttachFormProps & {
  setOpen: (open: boolean) => void;
};

export const AttachFormModal: React.FC<AttachFormModalProps> = ({
  travelAuthorizationData,
  setOpen,
}) => {
  const { id, isDraft } = { ...travelAuthorizationData };

  const { data: availableDocumentTypes } = useQuery<
    TravelAuthorizationAvailableDocumentTypes,
    TravelAuthorizationAvailableDocumentTypesVariables
  >(TRAVEL_AUTHORIZATION_AVAILABLE_DOCUMENT_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { travelAuthorizationAvailableDocumentTypes } = {
    ...availableDocumentTypes,
  };
  const styles = useStyles();
  const { addToast } = useToasts();
  const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState<
    number | null
  >(null);

  const {
    data: documentsList,
    loading: documentsLoading,
    variables: documentsVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<
    AttachableTravelAuthorizationDocuments,
    AttachableTravelAuthorizationDocumentsVariables
  >(ATTACHABLE_TRAVEL_AUTHORIZATION_DOCUMENTS, {
    variables: { travelAuthorizationId: id!, first: TABLE_ROWS },
    skip: !id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [attachDocuments, { loading: attachLoading }] = useMutation<
    AttachTravelAuthorizationDocument,
    AttachTravelAuthorizationDocumentVariables
  >(ATTACH_TRAVEL_AUTHORIZATION_DOCUMENT, { errorPolicy: 'all' });

  const canSelectItem = useCallback(
    (item: AttachableDataType) =>
      travelAuthorizationData?.isDraft ? true : !item._isAccountingDocument!,
    [travelAuthorizationData]
  );

  const documentTypeOptions =
    travelAuthorizationAvailableDocumentTypes?.nodes.map((doctype) => ({
      key: doctype.id,
      text: doctype.documentType || '',
      title:
        !isDraft && doctype.isAccountingDocument
          ? 'Upload prohibited during/after the approval process' || undefined
          : doctype.documentType || '',

      disabled: (!isDraft && doctype.isAccountingDocument) || undefined,
    })) || [];

  const _onAttach = async () => {
    const entityDocIds = selectedList.map((item) => item.id);
    const { errors } = await attachDocuments({
      variables: {
        input: {
          travelAuthorizationId: id!,
          entityDocumentId: entityDocIds,
          documentTypeId: selectedDocumentTypeId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: TRAVEL_AUTHORIZATION,
          variables: {
            id: id,
          },
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else addToast('Document attached successfully', { appearance: 'success' });
    setOpen(false);
  };

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;

      const disableLogic = travelAuthorizationData?.isDraft
        ? false
        : item._isAccountingDocument;

      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;
      switch (column.key) {
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout disabled={disableLogic!} item={item} />
            </Stack>
          );
        case 'documentType':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {item.documentTypes?.documentType}
              </Text>
            </Stack>
          );
        case 'indexAmount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignRight}>
                {fieldContent}
              </Text>
            </Stack>
          );
        case 'isoCode':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {item.currency?.isoCode}
              </Text>
            </Stack>
          );

        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {item._uploadDate ? getGlobalDateFormat(item._uploadDate) : ''}
              </Text>
            </Stack>
          );
        case 'indexTransactionDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              {item.indexTransactionDate && (
                <Text className={disableLogic ? styles.disabledText : ''}>
                  {dateFormat(dateConvertions(item.indexTransactionDate))}
                </Text>
              )}
            </Stack>
          );
        case 'view':
           const viewDocumentVisible =
             item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {fieldContent}
              </Text>
            </Stack>
          );
      }
    },
    [styles, travelAuthorizationData]
  );

  const { attachableTravelAuthorizationDocuments } = { ...documentsList };
  const { pageInfo, nodes } = {
    ...attachableTravelAuthorizationDocuments,
  };

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const { item } = { ...props };
    const newProps: IDetailsRowProps | undefined = props
      ? { ...props, className: styles.row }
      : undefined;

    const tootTipMessage =
      'Upload prohibited during/after the approval process';
    const tooltipVisible = !isDraft && item._isAccountingDocument;
    return (
      <>
        <TooltipHost
          content={tooltipVisible ? tootTipMessage : ''}
          directionalHint={DirectionalHint.rightCenter}
        >
          {defaultRender!(newProps)}
        </TooltipHost>
      </>
    );
  };

  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...documentsVariables,
          after: attachableTravelAuthorizationDocuments?.pageInfo.endCursor,
        },
      }),
    [fetchMore, documentsVariables, attachableTravelAuthorizationDocuments]
  );

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null) =>
      await refetch({ ...documentsVariables, documentTypeId }),
    [refetch, documentsVariables]
  );

  const attachDisabled = selectedList.length === 0;

  return (
    <>
      <AttachDocumentModal
        columns={_columns}
        loading={documentsLoading}
        modalWidth={1350}
        items={
          networkStatus === NetworkStatus.refetch ||
          networkStatus === NetworkStatus.setVariables
            ? undefined
            : nodes
        }
        hasNextPage={pageInfo?.hasNextPage}
        attachLoading={attachLoading}
        availableDocumentTypes={documentTypeOptions}
        setOpen={setOpen}
        onSortReload={reload}
        onLoadMore={loadMore}
        onRenderRow={onRenderRow}
        onFiltersReload={onFiltersReload}
        onDocumentTypeChange={(documentTypeId) => {
          onDocumentTypeReload(documentTypeId);
          setSelectedDocumentTypeId(documentTypeId);
        }}
        attachDisabled={attachDisabled}
        onSelectionChanged={setSelectedList}
        dropdownDisabled={selectedList.length > 0}
        onRenderItemColumn={onRenderItemColumn}
        onAttachDocuments={_onAttach}
        canSelectItem={canSelectItem}
      />
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
