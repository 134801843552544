import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  fileContainerStyles: {
    margin: '0px 20px 10px 0px',
    paddingLeft: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.neutralLighter,
  },
}));
