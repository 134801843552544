import React from 'react';
import { ThemeProvider } from '@fluentui/react';
import { lightTheme } from 'theme/light';
import { darkTheme } from 'theme/dark';
import { darkMode } from 'utility/cache/ui';
import { useReactiveVar } from '@apollo/client';

const FluentUITheme: React.FC = ({ children }) => {
  const useDark = useReactiveVar(darkMode);

  return (
    <ThemeProvider applyTo="body" theme={useDark ? darkTheme : lightTheme}>
      {children}
    </ThemeProvider>
  );
};

export default FluentUITheme;
