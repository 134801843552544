import {
  DetailsListLayoutMode,
  IColumn,
  Selection, ShimmeredDetailsList,
  Stack,
  Text
} from '@fluentui/react';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { ColumnData } from 'common/components/SearchBar';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection } from 'common/utils/commonTypes';
import React, { useState } from 'react';
import { CardAccounts_cardAccounts_nodes } from '../__generated__/CardAccounts';
import { CardHolders_cardHolders_nodes } from '../__generated__/CardHolders';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { setCompanyAccountId } from './utils';
export interface SortColumnType {
  column: string;
  direction: OrderDirection;
}
interface CardHoldersProps {
  data: CardHolders_cardHolders_nodes[];
  cardAccount: CardAccounts_cardAccounts_nodes;
  cardHoldersLoading: boolean;
  getSortColumn: (columnToSort: SortColumnType) => void;
  onSelectionChanged: (items: CardHolders_cardHolders_nodes[]) => void;
}
export interface cardAccountStateProps {
  _isManualProcess?: boolean | null;
}

export const CardHoldersList: React.FC<CardHoldersProps> = ({
  data,
  cardHoldersLoading,
  getSortColumn,
  cardAccount,
  onSelectionChanged
}) => {
  const styles = useStyles();
  const [gridColumns, setGridColumns] = useState<IColumn[]>(columns);
  const _onColumnClick = (
    ev?: React.MouseEvent<HTMLElement>,
    clickedColumn?: ColumnData
  ): void => {
    if (ev) {
      const { newColumns, desc } = getSortedColumns(
        clickedColumn!,
        gridColumns
      );
      setGridColumns(newColumns);
      getSortColumn({
        column: clickedColumn?.key!,
        direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
      });
    }
  };


  const _renderItemColumn = (
    item: CardHolders_cardHolders_nodes | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof CardHolders_cardHolders_nodes
      ] as string;

      switch (column?.key) {
        case 'cardHolder':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              <ActiveLink
                to={`/project-settings/purchase-cards/card-holder/${item.id}`}
                onLinkClick={() => setCompanyAccountId(item.cardAccountId)}
              >
                {fieldContent}
              </ActiveLink>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && <Text>{fieldContent}</Text>}
            </Stack>
          );
      }
    }
  };

  const transformedData: CardHolders_cardHolders_nodes[] = data.map((item) => ({
    ...item,
    cardHolder: `${item.cardFullName}`,
    department: item.departments?.name,
    documentFolder: item.documentPools?.name,
  }));

  const selection = new Selection({
    onSelectionChanged: () => {
      onSelectionChanged(selection.getSelection() as CardHolders_cardHolders_nodes[])
    }
  })

  return (
    <ShimmeredDetailsList
      compact
      items={transformedData || []}
      columns={gridColumns}
      enableShimmer={cardHoldersLoading}
      onColumnHeaderClick={_onColumnClick}
      setKey="set"
      onRenderItemColumn={_renderItemColumn}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      selection={selection}
    />
  );
};
