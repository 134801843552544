import React, { useState } from 'react'
import { Trip_trip } from '../../../__generated__/Trip';
import { useQuery } from '@apollo/client';
import { EnvironmentalMetrics } from 'common/graphql/__generated__/EnvironmentalMetrics';
import { loader } from 'graphql.macro';
import { useStyles } from './index.styles';
import { IDropdownOption, Link, Stack, Text } from '@fluentui/react';
import { TransactionLayout } from 'common/types/globalTypes';
import { ReactComponent as CarbonAccountingSVG } from 'assets/svgs/carbon_accounting.svg';
import { ReactComponent as CarbonAccountingFillingSVG } from 'assets/svgs/carbon_accounting_filled.svg';
import { useFormContext } from 'react-hook-form';
import { TripCreateFormValues } from '../../../types';
import { FormModal } from './FormModal';
const GET_ENVIRONMENT_CATEGORY = loader('../../../../../../../../../common/graphql/EnvironmentalMetrics.graphql');

export interface CategoryOptionsType extends IDropdownOption {
  key: string | number;
  text: string;
  layoutType: TransactionLayout | undefined | null;
}

export interface CarbonAccountingProps {
  trip: Trip_trip | null | undefined;
  refetch: () => void;
}

export const CarbonAccounting: React.FC<CarbonAccountingProps> = ({
  trip,
  refetch,
}) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    _isEnvironmentalsExist
  } = { ...trip }

  const { data: environmentCategoryData } = useQuery<EnvironmentalMetrics>(
    GET_ENVIRONMENT_CATEGORY
  );

  const categoryOptions: CategoryOptionsType[] =
    environmentCategoryData?.environmentalMetrics?.nodes.map((item) => ({
      key: item.id,
      text: item.environmentalMetric || '',
      layoutType: item.layoutType,
    })) || [];

  const { formState: { isDirty } } = useFormContext<TripCreateFormValues>();

  return (
    <Stack>
      <Link
        className={styles.textDecorationNone}
        underline={false}
        onClick={() => setIsOpen(true)}
        disabled={isDirty}
      >
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Stack horizontal verticalAlign="center">
            {!!_isEnvironmentalsExist ? (
              <CarbonAccountingFillingSVG className={styles.svgImage} />
            ) : (
              <CarbonAccountingSVG className={styles.svgImage} />
            )}
          </Stack>
          <Text>Carbon accounting</Text>
        </Stack>
      </Link>
      {isOpen && (
        <>
          <FormModal
            trip={trip}
            categoryOptions={categoryOptions}
            setOpen={setIsOpen}
            refetch={refetch}
          />
        </>
      )}
    </Stack>
  )
}
