import {
  DefaultButton,
  ProgressIndicator,
  RatingSize,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { StarRatings } from '../../../../../../../common/components/StarRatings';
import { SupplierRatings_supplier_ratingsByEntityId_nodes } from '../../__generated__/SupplierRatings';
import { useStyles } from './index.styles';
interface ReviewsListProps {
  supplierRatingsData: SupplierRatings_supplier_ratingsByEntityId_nodes[];
  hasNextPage?: boolean;
  loadingReviews: boolean;
  loadMore: () => void;
}
export const ReviewsList: React.FC<ReviewsListProps> = ({
  hasNextPage,
  loadingReviews,
  supplierRatingsData,
  loadMore,
}) => {
  const styles = useStyles();

  return (
    <Stack tokens={{ childrenGap: 15 }} className={styles.container}>
      {supplierRatingsData.map((review, index) => {
        return (
          <Stack key={index.toString()} tokens={{ childrenGap: 15 }}>
            <Stack horizontal horizontalAlign="space-between">
              <Stack
                tokens={{ childrenGap: 10 }}
              >
                <Text className={styles.user}>{review._createdByUserName}</Text>
                <Text>{review.ratingComment}</Text>
              </Stack>
              <Stack tokens={{ childrenGap: 5 }} horizontalAlign="end">
                <Text className={styles.boldFont}>
                  {getGlobalDateFormat(review._createdDate!)}
                </Text>
                <StarRatings
                  min={1}
                  max={5}
                  size={RatingSize.Small}
                  rating={Number(review.ratingValue)}
                  readOnly
                />
              </Stack>
            </Stack>
            {supplierRatingsData?.length! - 1 !== index && <Separator />}
          </Stack>
        );
      })}
      {loadingReviews && <ProgressIndicator />}
      {hasNextPage && (
        <Stack className={styles.loadMoreButtonContainer}>
          <DefaultButton text="Load More" onClick={loadMore} />
        </Stack>
      )}
    </Stack>
  );
};
