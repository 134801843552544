import { Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useStyles } from './index.styles';

interface HeaderProps {
  title: string;
  isEdit: boolean;
  folderName?: string;
  onClose: () => void;
  isRootFolder: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  onClose,
  title,
  folderName,
  isEdit,
  isRootFolder,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20,
      }}
    >
      <Stack tokens={{ childrenGap: 10 }}>
        <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
          {title}
        </Text>
        {folderName && !isEdit ? (
          isRootFolder ? (
            <Text variant="medium" className={commonStyles.colorThemePrimary}>
              {folderName}
            </Text>
          ) : (
            <Stack horizontal>
              <Text variant="medium" style={{ paddingRight: 4 }}>
                {'... /'}
              </Text>
              <Text variant="medium" className={commonStyles.colorThemePrimary}>
                {folderName}
              </Text>
            </Stack>
          )
        ) : null}
      </Stack>
      <CloseButton onClick={onClose} />
    </Stack>
  );
};
