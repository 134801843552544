import React from 'react';
import { PaymentDetails_payment } from '../__generated__/PaymentDetails';
import { Separator, Stack, Text } from '@fluentui/react';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { InfoView } from 'common/components/InfoView';
import { useStyles } from './index.styles'
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { ActionMenu } from './ActionMenu';
import { PaymentInvoices } from './PaymentInvoices';

export interface PaymentDetailProps {
  payment: PaymentDetails_payment | null | undefined;
  loading: boolean;
}

export const PaymentDetail: React.FC<PaymentDetailProps> = ({
  payment,
  loading,
}) => {
  const styles = useStyles();
  const {
    _payCycleDescription,
    paidDate,
    paymentReference,
    paidAmount,
    vendorReference,
    currency,
    invoicePayments,
  } = { ...payment }


  return (
    <Stack tokens={{ padding: "0px 0px 100px" }}>
      {
        loading ?
          <ShimmerView /> :
          <>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.infoViewWrapper}
            >
              <Stack className={styles.container}>
                <InfoView
                  title='Pay Cycle'
                  description={`${_payCycleDescription}` || '-'}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Date'
                  description={`${paidDate ? dateFormat(dateConvertions(paidDate || '')) : '-'}`}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Payment Number'
                  description={`${paymentReference ? paymentReference : '-'}`}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title={'Amount'}
                  currency={currency?.isoCode || ''}
                  description={paidAmount || '-'}
                  isAmount
                />
              </Stack>
            </Stack>
            <Separator />
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.infoViewWrapper}
            >
              <Stack className={styles.container}>
                <InfoView
                  title='Pay To (Name)'
                  description={`${vendorReference}` || '-'}
                />
              </Stack>
            </Stack>
            <Separator />
            <ActionMenu payment={payment} />
            <Separator />
            {
              !!invoicePayments?.nodes.length &&
              <Stack tokens={{ padding: "0px 20px", childrenGap: 10 }}>
                <Text variant="xLarge">Accounting - Invoice</Text>
                <PaymentInvoices invoicePayments={invoicePayments} />
              </Stack>
            }
          </>
      }
    </Stack>
  )
}
