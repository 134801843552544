import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    height: 400,
    width: 800,
  },
  dropdownStyles: {
    border: 0,
    width: 350,
  },
  textInputStyle: {
    width: 350,
  },
  width100: {
    width: 100,
  },
}));
