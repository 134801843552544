import { ITextFieldProps } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import React from 'react';
import { useController } from 'react-hook-form';
import { CorporateAccountAutoComplete } from '../CorporateAccountAutoComplete';

type FormHookCorporateAccountAutoCompleteProps =
  RequiredNameProps<ITextFieldProps> & { chartOfAccountId: string };

export const FormHookCorporateAccountAutoComplete: React.FC<
  FormHookCorporateAccountAutoCompleteProps
> = ({ name, chartOfAccountId, ...props }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name });

  return (
    <div>
      <CorporateAccountAutoComplete
        chartOfAccountId={chartOfAccountId}
        {...props}
        corporateAccount={value}
        onAccountSelect={(account) => {
          if (account) {
            const { id, lookupAccount } = { ...account };
            onChange({ id, lookupAccount });
          } else onChange(null);
        }}
        errorMessage={error?.message}
      />
    </div>
  );
};
