import { Separator, Stack } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { formatDropdownOptions } from 'common/utils';
import React, { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  PurchaseOrderItemFormProps,
  PurchaseOrderItemLayout,
} from '../interface';

type ShippingItemProps = PurchaseOrderItemLayout;

export const ShippingItem: React.FC<ShippingItemProps> = ({
  selectedItemType,
  purchaseOrderData,
  commonData,
}) => {
  const fieldDisabled = !purchaseOrderData?.purchaseOrder?._isUpdatable;
  const expenditureTypeOption = useMemo(() => {
    if (
      selectedItemType?.isExpenditureTypeAllowed &&
      !selectedItemType.isUnitOfMeasureRequired
    )
      return (
        commonData?.expenditureTypes?.nodes.map((item) => ({
          key: item.id!,
          text: item.expenditureType! || '',
          ...item,
        })) || []
      );
    else {
      const expenditureTypes = formatDropdownOptions(
        selectedItemType?.unitOfMeasureGroup?.expenditureTypes.nodes,
        {
          getKey: (item) => item.id,
          getText: (item) => item.expenditureType!,
        }
      );

      return expenditureTypes;
    }
  }, [selectedItemType, commonData]);

  const { setValue } = useFormContext<PurchaseOrderItemFormProps>();
  const watchItemQuantity = useWatch({ name: 'itemQuantity' });
  const watchItemUnitPrice = useWatch({ name: 'itemUnitPrice' });

  useEffect(() => {
    setValue(
      'itemExtendedAmount',
      ((watchItemQuantity || 1) * watchItemUnitPrice).toString()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchItemQuantity, watchItemUnitPrice]);

  return (
    <Stack
      tokens={{
        padding: '20px 20px',
        childrenGap: 10,
      }}
    >
      <Stack horizontal>
        <Stack
          grow={2}
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 20 }}
        >
          {selectedItemType?.isExpenditureTypeAllowed && (
            <Stack.Item>
              <FormHookDropdown
                label="Expenditure Type"
                placeholder="Select"
                options={expenditureTypeOption}
                name="expenditureTypeId"
                required
                disabled={fieldDisabled}
                styles={{ root: { width: 200 } }}
              />
            </Stack.Item>
          )}

          <Stack.Item grow={2}>
            <FormHookTextField
              name="description"
              label="Description"
              placeholder="Description"
              disabled={fieldDisabled}
              required
            />
          </Stack.Item>
        </Stack>
        <Stack grow={1} horizontalAlign="end"></Stack>
      </Stack>
      <Separator />
      <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 20 }}>
        <Stack style={{ width: 250 }}>
          <FormHookAmount
            name="itemQuantity"
            label="Quantity"
            placeholder="Enter quantity"
            disabled={fieldDisabled}
            allowNegative={false}
          />
        </Stack>

        <Stack style={{ width: 250 }}>
          <FormHookAmount
            name="itemUnitPrice"
            label="Price"
            placeholder="Enter Price"
            disabled={fieldDisabled}
            fixedDecimalScale
            decimalScale={2}
            allowNegative={false}
          />
        </Stack>

        <Stack style={{ width: 250 }}>
          <FormHookAmount
            name="itemExtendedAmount"
            label="Delivery charge"
            placeholder="Enter delivery charges"
            disabled={fieldDisabled}
            fixedDecimalScale
            decimalScale={2}
            allowNegative={false}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
