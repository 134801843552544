import { useQuery } from '@apollo/client';
import { RatingSize, Separator, Stack, Text } from '@fluentui/react';
import { loader } from 'graphql.macro';
import React, { useCallback } from 'react';
import { ReviewForm } from '../ReviewForm';
import { useStyles } from './index.styles';
import { RatingsPercentage } from './RatingsPercentage';
import { ReviewsList } from './ReviewsList';
import {
  SupplierRatings,
  SupplierRatingsVariables,
} from 'settings/account/supplier/view/ReviewsRatings/__generated__/SupplierRatings';
import { StarRatings } from 'common/components/StarRatings';
import { TASupplierDetails_supplier } from '../../../__generated__/TASupplierDetails';
interface ReviewDetailsProps {
  supplier: TASupplierDetails_supplier;
}
const GET_SUPPLIER_RATINGS = loader(
  '../../../../../../../../../../settings/account/supplier/view/ReviewsRatings/SupplierRatings.graphql'
);
export const ReviewDetails: React.FC<ReviewDetailsProps> = ({ supplier }) => {
  const styles = useStyles();

  const {
    loading: supplierRatingsLoading,
    data: supplierRatingsData,
    variables: supplierRatingsVariables,
    fetchMore,
    refetch,
  } = useQuery<SupplierRatings, SupplierRatingsVariables>(
    GET_SUPPLIER_RATINGS,
    {
      variables: {
        id: supplier.id,
        first: 10,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const refetchList = () => {
    refetch({
      ...supplierRatingsVariables,
    });
  };

  const loadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        ...supplierRatingsVariables,
        after:
          supplierRatingsData?.supplier?.ratingsByEntityId.pageInfo.endCursor,
      },
    });
  }, [fetchMore, supplierRatingsVariables, supplierRatingsData]);

  return (
    <Stack horizontal tokens={{ childrenGap: 26 }} className={styles.container}>
      <Stack.Item className={styles.stackColumn}>
        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="baseline">
          <Stack
            horizontal
            verticalAlign="baseline"
            className={styles.ratingsValueContainer}
          >
            <Text className={styles.ratingsValue}>
              {supplier?.supplierRatingResults?.starValue || 0}
            </Text>
            <Text className={styles.totalRating}>/5</Text>
          </Stack>
          <StarRatings
            max={5}
            rating={Number(supplier?.supplierRatingResults?.starValue || '0')}
            size={RatingSize.Large}
            readOnly
          />
        </Stack>
        {supplier.supplierRatingResults && (
          <RatingsPercentage ratings={supplier.supplierRatingResults} />
        )}
      </Stack.Item>
      <Separator vertical />
      <Stack.Item className={styles.stackColumn}>
        <ReviewForm supplierId={supplier.id} refreshList={refetchList} />
        <ReviewsList
          supplierRatingsData={
            supplierRatingsData?.supplier?.ratingsByEntityId.nodes || []
          }
          hasNextPage={
            supplierRatingsData?.supplier?.ratingsByEntityId.pageInfo
              .hasNextPage
          }
          loadingReviews={supplierRatingsLoading}
          loadMore={loadMore}
        />
      </Stack.Item>
    </Stack>
  );
};
