import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';
import { loader } from 'graphql.macro';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import {
  CompanyCardCompanies,
  CompanyCardCompaniesVariables,
  CompanyCardCompanies_companyCardCompanies_nodes,
} from './__generated__/CompanyCardCompanies';
import { DeleteCardCompany } from '../view/DeleteCardCompany';
import { CardAccountsOrderBy } from 'common/types/globalTypes';
import {
  CardAccounts,
  CardAccountsVariables,
} from '../../list/__generated__/CardAccounts';
const CARD_ACCOUNTS = loader('../../list/CardAccounts.graphql');
const CARD_COMPANIES = loader('./CompanyCardCompanies.graphql');
export type CardCompanyRowItem =
  CompanyCardCompanies_companyCardCompanies_nodes;

interface CardCompaniesListProps {
  onEdit: (selectedItem: CardCompanyRowItem) => void;
}
export const CardCompaniesList: React.FC<CardCompaniesListProps> = ({
  onEdit,
}) => {
  const styles = useStyles();
  const { data, loading } = useQuery<
    CompanyCardCompanies,
    CompanyCardCompaniesVariables
  >(CARD_COMPANIES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [getCardAccounts] = useLazyQuery<CardAccounts, CardAccountsVariables>(
    CARD_ACCOUNTS,
    {
      variables: {
        orderBy: [CardAccountsOrderBy.NAME_ASC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const _renderItemColumn = (
    item: CardCompanyRowItem,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof CardCompanyRowItem
      ] as string;

      switch (column?.key) {
        case 'edit':
          return (
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
              className={styles.columnCell}
            >
              <IconButton
                disabled={!item._isUpdatable!}
                iconProps={{ iconName: 'Edit' }}
                onClick={() => onEdit(item)}
              />
              <DeleteCardCompany
                item={item}
                onDelete={() => getCardAccounts()}
              />
            </Stack>
          );

        default:
          return (
            <Stack verticalAlign="center" className={styles.columnCell}>
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  return (
    <Stack className={styles.container}>
      <ShimmeredDetailsList
        enableShimmer={loading}
        shimmerLines={5}
        items={data?.companyCardCompanies?.nodes || []}
        compact={true}
        columns={columns}
        selectionMode={SelectionMode.none}
        getKey={(_, index) => index?.toString()!}
        setKey="multiple"
        onRenderItemColumn={_renderItemColumn}
        layoutMode={DetailsListLayoutMode.justified}
        selectionPreservedOnEmptyClick={true}
      />
    </Stack>
  );
};
