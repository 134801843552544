import { Stack } from '@fluentui/react';
import { ToggleButton } from 'common/components/Buttons/ToggleButton';
import { TagSelection_tags_nodes } from 'common/graphql/__generated__/TagSelection';
import React, { useState } from 'react';
import { TagSelectOption } from '..';
interface TagsProps {
  /** @param options Collection of tags */
  options: TagSelection_tags_nodes[];
  /** @param Previously selected tags from server */
  selectedTags?: TagSelectOption[];
  /** @param onSelectionChange callback to sent current tag selection */
  onSelectionChange: (selectedTags: TagSelectOption[]) => void;
}

export const Tags: React.FC<TagsProps> = ({
  options,
  selectedTags = [],
  onSelectionChange,
}) => {
  const [selectedOptions, setSelectedOptions] =
    useState<TagSelectOption[]>(selectedTags);

  const isSelected = (option: TagSelectOption) =>
    selectedOptions.some((item) => item.tagId === option.tagId);

  const onTagPressed = (option: TagSelectOption) => {
    let levelsSelected = [...selectedOptions];
    let position = levelsSelected.findIndex(
      (item) => item.tagId === option.tagId
    );
    if (position === -1) {
      levelsSelected.push(option);
    } else levelsSelected.splice(position, 1);
    onSelectionChange?.(levelsSelected);
    setSelectedOptions(levelsSelected);
  };

  return (
    <Stack wrap horizontal tokens={{ childrenGap: 10 }}>
      {options?.map((item) => {
        const tagId = { tagId: item.id };
        return (
          <ToggleButton
            onClick={() => onTagPressed(tagId)}
            toggled={isSelected(tagId)}
            key={item.id}
            text={item.badgeName}
            iconName={isSelected(tagId) ? 'Accept' : 'Add'}
            color={
              isSelected(tagId) && item.badgeColor ? item.badgeColor : undefined
            }
          />
        );
      })}
    </Stack>
  );
};
