import { useQuery } from '@apollo/client';
import {
  CommandBarButton,
  DefaultButton,
  IDropdownOption,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import {
  GetDocumentPoolCommonData,
  GetDocumentPoolCommonDataVariables,
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { nodeMapType } from '../../../utils';
import { FolderUpdateValues } from '../types';
import { validationSchema } from './folderUpdateValidations';
import { Header } from './Header';

const GENERAL_DATA = loader(
  '../../../../../../../documents/GetDocumentPoolCommonData.graphql'
);

interface FolderUpdateModalProps {
  selectedPool: nodeMapType;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: (values: FolderUpdateValues) => void;
  title: string;
  isEdit: boolean;
  isLoading: boolean;
}

export const FolderUpdateModal: React.FC<FolderUpdateModalProps> = ({
  selectedPool,
  setShowModal,
  onClick,
  title,
  isEdit,
  isLoading,
}) => {
  const isRootFolder = selectedPool.parentDocumentPoolId === null;
  const [advanceVisible, setAdvanceVisible] = useState<boolean>(false);
  const [name, setName] = useState<any | undefined>(
    isEdit ? selectedPool.name : null
  );
  const [extractionTypeId, setExtractionTypeId] = useState<any | undefined>(
    selectedPool.extractionTypeId
  );
  const [userGroupId, setUserGroupId] = useState<any | undefined>(
    selectedPool.userGroupId
  );
  const [defaultBusinessUnitId, setDefaultBusinessUnitId] = useState<
    any | undefined
  >(selectedPool.defaultBusinessUnitId);
  const [defaultDepartmentId, setDefaultDepartmentId] = useState<
    any | undefined
  >(selectedPool.defaultDepartmentId);
  const formMethods = useForm<FolderUpdateValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const {
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = { ...formMethods };
  const saveDisabled = !isDirty || !isValid;

  const onHandleSubmit = async (values: FolderUpdateValues) => {
    onClick(values);
  };
  const { data: GeneralData } = useQuery<
    GetDocumentPoolCommonData,
    GetDocumentPoolCommonDataVariables
  >(GENERAL_DATA, {
    variables: {
      isDocumentUpload: true,
    },
  });
  const documentTypes =
    GeneralData?.documentPoolAvailableDocumentTypes?.nodes.find(
      (o) => o.id === selectedPool.defaultDocumentTypeId
    );
  const extractionTypesOptions: IDropdownOption[] =
    documentTypes?.extractionTypes?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.extractionType,
    })) || [];
  const formatDropDownOptions = (dropArr: any[], textName: string) => {
    return (
      dropArr.map((item) => ({
        key: item['id'],
        text: item[textName] ? item[textName] : 'NA',
        disabled: !item['_isDocSelectable'],
      })) || []
    );
  };
  let userGroupOptions: IDropdownOption[] = [];
  let businessUnitOptions: IDropdownOption[] = [];
  let departmentOptions: IDropdownOption[] = [];

  if (
    GeneralData?.companyUserGroups?.nodes &&
    GeneralData?.companyUserGroups.nodes.length > 0
  ) {
    userGroupOptions = GeneralData?.companyUserGroups.nodes.map((group) => ({
      key: group.userGroupId,
      text: group.userGroup?.name ? group.userGroup?.name : 'NA',
    }));
  }
  businessUnitOptions =
    GeneralData?.companyBusinessUnits?.nodes.map((item) => ({
      key: item.id,
      text: item.name ? item.name : 'NA',
    })) || [];

  departmentOptions = formatDropDownOptions(
    GeneralData?.companyDepartments?.nodes || [],
    'name'
  );
  useEffect(() => {
    setValue('name', selectedPool.name);
    setValue('defaultBusinessUnitId', selectedPool.defaultBusinessUnitId);
    setValue('defaultDepartmentId', selectedPool.defaultDepartmentId);
    setValue('userGroupId', selectedPool.userGroupId);
    setValue('extractionTypeId', selectedPool.extractionTypeId);
  }, [selectedPool, setValue]);

  return (
    <Modal isOpen isBlocking>
      <FormProvider {...formMethods}>
        <Stack style={{ width: 600 }}>
          <Header
            isEdit={isEdit}
            isRootFolder={isRootFolder}
            title={title}
            folderName={selectedPool.name}
            onClose={() => setShowModal(false)}
          />
          <Stack
            tokens={{
              padding: 20,
            }}
          >
            <FormHookTextField
              name={'name'}
              label="Name"
              placeholder={'Enter name'}
              value={name}
              onChange={(_event, value) => {
                setName(value);
                setValue('name', value ?? null);
              }}
            />
            <Stack horizontalAlign="end">
              <CommandBarButton
                styles={{
                  root: {
                    height: 50,
                    padding: 15,
                    marginTop: 10,
                  },
                }}
                iconProps={{
                  iconName: advanceVisible ? 'ChevronUp' : 'ChevronDown',
                }}
                text="Options"
                onClick={() => setAdvanceVisible((value) => !value)}
              />
            </Stack>
            {advanceVisible && (
              <Stack
                tokens={{
                  childrenGap: 15,
                }}
              >
                <FormHookDropdown
                  name={'extractionTypeId'}
                  label="Data Extraction Template"
                  options={extractionTypesOptions}
                  selectedKey={extractionTypeId}
                  disabled={
                    !!(
                      selectedPool.isDocumentTypeSelector &&
                      selectedPool.extractionTypeId
                    )
                  }
                  onChange={(_event, option) => {
                    setExtractionTypeId(option?.key);
                  }}
                />
                <FormHookDropdown
                  name={'userGroupId'}
                  label="User Group"
                  options={userGroupOptions}
                  selectedKey={userGroupId}
                  onChange={(_event, option) => {
                    setUserGroupId(option?.key);
                  }}
                />
                <FormHookDropdown
                  name={'defaultBusinessUnitId'}
                  label="Business Unit"
                  options={businessUnitOptions}
                  selectedKey={defaultBusinessUnitId}
                  onChange={(_event, option) => {
                    setDefaultBusinessUnitId(option?.key);
                  }}
                />
                <FormHookDropdown
                  name={'defaultDepartmentId'}
                  label="Department"
                  options={departmentOptions}
                  selectedKey={defaultDepartmentId}
                  onChange={(_event, option) => {
                    setDefaultDepartmentId(option?.key);
                  }}
                />
              </Stack>
            )}
          </Stack>
          <Stack
            horizontal
            horizontalAlign="end"
            tokens={{
              childrenGap: 15,
              padding: 30,
            }}
          >
            <PrimaryButton
              text="Save"
              onClick={handleSubmit(onHandleSubmit)}
              disabled={saveDisabled}
            />
            <DefaultButton text="Cancel" onClick={() => setShowModal(false)} />
          </Stack>
          {isLoading && <ProgressIndicator />}
        </Stack>
      </FormProvider>
    </Modal>
  );
};
