import { useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { HistoryActionMenuView } from 'common/components/History';
import { GlobalActions } from 'common/constants';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { BalancesListView } from '../../Balances';
import { CardAccount } from '../../__generated__/CardAccount';
import { CardAccountNotesSection } from './CardAccountNotes';
import { TagsSection } from './CardAccountTags';
import { useStyles } from './index.styles';
import {
  ListCardAccountNotes,
  ListCardAccountNotesVariables,
} from './__generated__/ListCardAccountNotes';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';

const ENTITY_NOTES_LIST = loader('./ListCardAccountNotes.graphql');
interface ApprovalDetailsProps {
  cardAccountDetails: CardAccount | undefined;
}
const balances = [
  {
    currency: 'USD',
    balance: '479.50',
  },
  {
    currency: 'GBP',
    balance: '1,300.00',
  },
  {
    currency: 'EUR',
    balance: '700.00',
  },
];

export const ActionsMenu: React.FC<ApprovalDetailsProps> = ({
  cardAccountDetails,
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    GlobalActions.attach
  );
  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListCardAccountNotes,
    ListCardAccountNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id: cardAccountDetails?.cardAccount?.id!,
    },
  });
  const getEntityNotes = () => {
    const variables: ListCardAccountNotesVariables = {
      id: cardAccountDetails?.cardAccount?.id!,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    cardAccountDetails?.cardAccount?.id,
  ]);
  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.notes:
        return (
          <CardAccountNotesSection
            listRefreshRequest={getEntityNotesMemo}
            id={cardAccountDetails?.cardAccount?.id!}
            data={entityNotesData?.cardAccount?.notesByEntityId.nodes!}
          />
        );
      case GlobalActions.history:
        return (
          <HistoryActionMenuView
            moduleName="Purchase order"
            historyData={
              cardAccountDetails?.cardAccount?.cardAccountHistoriesByEntityId
                .nodes!
            }
          />
        );
      case GlobalActions.tags:
        return (
          <TagsSection cardAccountID={cardAccountDetails?.cardAccount?.id} />
        );
      default:
        return null;
    }
  };
  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                <PivotItem
                  itemKey={GlobalActions.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    entityNotesData?.cardAccount?.notesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={GlobalActions.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={
                    cardAccountDetails?.cardAccount
                      ?.cardAccountHistoriesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={GlobalActions.tags}
                  itemIcon="Tag"
                  headerText="Tags"
                  itemCount={
                    cardAccountDetails?.cardAccount?.entityTagsByEntityId.nodes
                      .length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
            {!cardAccountDetails?.cardAccount?.companyCardCompanies
              ?._isManualProcess && <BalancesListView data={balances} />}
          </Stack>

          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack horizontalAlign="space-between" horizontal>
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Notes"
                iconName="Page"
                count={
                  entityNotesData?.cardAccount?.notesByEntityId.nodes.length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.notes);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                count={
                  cardAccountDetails?.cardAccount
                    ?.cardAccountHistoriesByEntityId.nodes.length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.history);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Tags"
                iconName="Tag"
                count={
                  cardAccountDetails?.cardAccount?.entityTagsByEntityId.nodes
                    .length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.tags);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
          {!cardAccountDetails?.cardAccount?.companyCardCompanies
            ?._isManualProcess && <BalancesListView data={balances} />}
        </Stack>
      )}
    </Stack>
  );
};
