import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const centerAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'center',
  },
};

export const getColumns = () => {
  const columns: ColumnData[] = [
    {
      key: 'name',
      name: 'Group',
      fieldName: 'name',
      minWidth: 180,
      maxWidth: 180,
      ...commonColumnProps,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 280,
      maxWidth: 280,
      ...commonColumnProps,
    },
    {
      key: 'isTravel',
      name: 'Travel Group',
      fieldName: 'isTravel',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
    },
    {
      key: 'departmentGroupItems',
      name: 'Department',
      fieldName: 'departmentGroupItems',
      minWidth: 750,
      maxWidth: 750,
      ...commonColumnProps,
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: 'action',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      styles: centerAlignHeaderStyle,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
  ];
  return columns;
};
