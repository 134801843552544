import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    minWidth: 300,
  },
  callout: {
    flexGrow: 1,
  },
  badge: {
    width: 13,
    height: 13,
    borderRadius: 10,
    backgroundColor: theme.palette.orangeLighter,
    cursor: 'pointer',
  },
}));
