import { useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Chart,
  ChartProps,
  CustomizedAxisTick,
  currencyFormatter,
  getSecondaryShade,
  useStyles,
} from '../common';
import { loader } from 'graphql.macro';
import { TravelAuthorizationChartData } from './__generated__/TravelAuthorizationChartData';
import { getHoverColor } from 'common/utils/color';
import { useHistory } from 'react-router-dom';

type ActiveIndex = {
  index: number;
  set: 'pending' | 'active' | 'inPolicy' | 'outPolicy';
};

const query = loader('./TravelAuthorizationChartData.graphql');
const Component: React.FC<ChartProps<TravelAuthorizationChartData>> = ({
  data,
  height,
  width,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState<ActiveIndex | undefined>();
  const [animation, setAnimation] = useState(true);
  const history = useHistory();

  const onCellClick = (search: string | null) =>
    history.push({
      pathname: '/ta/travel-plan',
      search: search ? `?t=${search}` : undefined,
    });
  return (
    <BarChart
      height={height}
      width={width}
      data={data?.kpiTravelAuthorizationPolicyBreakdowns?.nodes}
      margin={{
        top: 20,
        right: 20,
        left: 10,
        bottom: 20,
      }}
    >
      <CartesianGrid
        strokeDasharray="3 3"
        stroke={theme.palette.neutralQuaternary}
      />
      <XAxis
        dataKey="name"
        interval={0}
        tick={
          <CustomizedAxisTick
            fill={theme.palette.neutralPrimary}
            width={140}
            maxLines={2}
          />
        }
      />
      <YAxis
        tick={{ fill: theme.palette.neutralPrimary }}
        tickFormatter={(value, index) => {
          return value;
        }}
      />
      <Tooltip
        separator=": "
        formatter={(value: number, name: string) => [
          currencyFormatter.format(value),
          name,
        ]}
        contentStyle={{
          backgroundColor: theme.palette.white,
          borderColor: theme.palette.neutralLighter,
        }}
        cursor={{
          fill: theme.palette.neutralQuaternaryAlt,
          opacity: 0.4,
        }}
        offset={60}
        labelStyle={{ fontWeight: 600 }}
        isAnimationActive={false}
      />
      <Bar
        dataKey="activeAmount"
        stackId="activePending"
        name="Active"
        className={styles.pointer}
        fill={theme.palette.blue}
        onMouseEnter={(_, index) => setActiveIndex({ index, set: 'active' })}
        onMouseLeave={(_, index) => {
          if (activeIndex?.set === 'active' && activeIndex?.index === index)
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiTravelAuthorizationPolicyBreakdowns?.nodes.map(
          (entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                activeIndex?.set === 'active' && activeIndex?.index === index
                  ? getHoverColor(theme.palette.blue, theme)
                  : theme.palette.blue
              }
              onClick={() => onCellClick(entry.activeSearchParam)}
            />
          )
        )}
      </Bar>
      <Bar
        dataKey="pendingAmount"
        stackId="activePending"
        name="Pending"
        className={styles.pointer}
        fill={getSecondaryShade(theme.palette.blue)}
        onMouseEnter={(_, index) => setActiveIndex({ index, set: 'pending' })}
        onMouseLeave={(_, index) => {
          if (activeIndex?.set === 'pending' && activeIndex?.index === index)
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data.kpiTravelAuthorizationPolicyBreakdowns?.nodes.map(
          (entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                activeIndex?.set === 'pending' && activeIndex?.index === index
                  ? getHoverColor(getSecondaryShade(theme.palette.blue), theme)
                  : getSecondaryShade(theme.palette.blue)
              }
              onClick={() => onCellClick(entry.pendingSearchParam)}
            />
          )
        )}
      </Bar>
      <Bar
        dataKey="inPolicyAmount"
        stackId="policy"
        name="In-Policy"
        className={styles.pointer}
        fill={theme.palette.green}
        onMouseEnter={(_, index) => setActiveIndex({ index, set: 'inPolicy' })}
        onMouseLeave={(_, index) => {
          if (activeIndex?.set === 'inPolicy' && activeIndex?.index === index)
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data.kpiTravelAuthorizationPolicyBreakdowns?.nodes.map(
          (entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                activeIndex?.set === 'inPolicy' && activeIndex?.index === index
                  ? getHoverColor(theme.palette.green, theme)
                  : theme.palette.green
              }
              onClick={() => onCellClick(entry.inPolicySearchParam)}
            />
          )
        )}
      </Bar>
      <Bar
        dataKey="outOfPolicyAmount"
        stackId="policy"
        name="Out-of-Policy"
        className={styles.pointer}
        fill={theme.palette.red}
        onMouseEnter={(_, index) => setActiveIndex({ index, set: 'outPolicy' })}
        onMouseLeave={(_, index) => {
          if (activeIndex?.set === 'outPolicy' && activeIndex?.index === index)
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiTravelAuthorizationPolicyBreakdowns?.nodes.map(
          (entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                activeIndex?.set === 'outPolicy' && activeIndex?.index === index
                  ? getHoverColor(theme.palette.red, theme)
                  : theme.palette.red
              }
              onClick={() => onCellClick(entry.outOfPolicySearchParam)}
            />
          )
        )}
      </Bar>
    </BarChart>
  );
};

export const TravelAuthorizationChart: Chart<TravelAuthorizationChartData> = {
  query,
  Component,
  hasData: (data) =>
    !!data.kpiTravelAuthorizationPolicyBreakdowns?.nodes.length,
};
