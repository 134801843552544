import { useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { HistoryActionMenuView } from 'common/components/History';
import { PivotCustomRender } from 'common/components/PivotComponent';
import {
  PivotDisplay,
  PivotMenuItemProps,
} from 'common/components/PivotDisplay';
import { GlobalActions } from 'common/constants';
import { TravelerNotes, TravelerNotesVariables } from 'common/graphql/__generated__/TravelerNotes';
import { TravelerUploadDocument_travelerUploadDocument } from 'common/graphql/__generated__/TravelerUploadDocument';
import {
  UserEntityMessageCounts,
  UserEntityMessageCountsVariables,
} from 'common/graphql/__generated__/UserEntityMessageCounts';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { Traveler_traveler } from '../__generated__/Traveler';
import { TravelerCommonData_secureRowLevels } from '../__generated__/TravelerCommonData';
import { Attach } from './Attach';
import { Notes } from './Notes';
import { Tags } from './Tags';
import { TravelerMessage } from './TravelerMessage';
import {
  TravelerPropertiesCount,
  getUpdatedPivotActionItems,
  pivotActionItems,
} from './constants';
import { useStyles } from './index.styles';
const TRAVELER_NOTES_LIST = loader('../../../../../common/graphql/TravelerNotes.graphql');

const ENTITY_MESSAGE_COUNTS = loader(
  '../../../../../common/graphql/UserEntityMessageCounts.graphql'
);

interface ActionsMenuProps {
  traveler: Traveler_traveler;
  onUpload?: (
    fileSelected: File,
    documentData: TravelerUploadDocument_travelerUploadDocument,
    toastId: string
  ) => void;
  secureRowLevels: TravelerCommonData_secureRowLevels | undefined | null;
}

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  traveler,
  secureRowLevels,
  onUpload,
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    GlobalActions.attach
  );

  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const {
    id,
    travelerHistoriesByEntityId,
  } = { ...traveler }

  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    TravelerNotes,
    TravelerNotesVariables
  >(TRAVELER_NOTES_LIST, {
    variables: {
      id
    },
    skip: !id,
  });

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          entityId: id,
        },
        skip: !id,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );
  const getEntityNotes = () => {
    const variables: TravelerNotesVariables = {
      id,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    id,
  ]);

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.notes:
        return (
          <Stack tokens={{ padding: '0px 15px' }}>
            <Notes
              listRefreshRequest={getEntityNotesMemo}
              id={id}
              data={entityNotesData?.traveler?.notesByEntityId.nodes || []}
            />
          </Stack>
        );
      case GlobalActions.history:
        return (
          <Stack tokens={{ padding: '0px 15px' }}>
            <HistoryActionMenuView
              moduleName="Traveler"
              numberOfItems={4}
              historyData={travelerHistoriesByEntityId.nodes || []}
            />
          </Stack>
        );
      case GlobalActions.tags:
        return (
          <Stack tokens={{ padding: '0px 15px' }}>
            <Tags travelerId={id} />
          </Stack>
        );
      case GlobalActions.attach:
        return (
          <Attach
            traveler={traveler}
            secureRowLevel={secureRowLevels?.nodes || []}
            onUpload={onUpload}
          />
        );
      case GlobalActions.messages:
        return (
          <Stack tokens={{ padding: '0px 15px' }}>
            <TravelerMessage
              onCreateMessage={userMessageCountsRefetch}
              userMessageCounts={userMessageCounts}
              travelerID={id}
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  //Update the values of the count property in the pivotActionItems array

  let updatedPivotActionItems: PivotMenuItemProps[] = [];
  if (traveler) {
    const {
      entityDocumentsByEntityId,
      notesByEntityId,
      travelerHistoriesByEntityId,
      entityTagsByEntityId,
    } = traveler;

    const travelerPropertiesCountArray: TravelerPropertiesCount[] = [
      {
        name: 'Files',
        count: entityDocumentsByEntityId?.nodes?.length || 0,
      },
      {
        name: 'Notes',
        count: notesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'History',
        count: travelerHistoriesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'Tags',
        count: entityTagsByEntityId?.nodes?.length || 0,
      },
      {
        name: 'Messages',
        count: userMessageCounts?.userEntityMessageCounts?.messagesSent || 0,
      },
    ];
    updatedPivotActionItems = getUpdatedPivotActionItems(
      travelerPropertiesCountArray
    );
  } else updatedPivotActionItems = pivotActionItems;

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.pivotContainer}
            >
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                {updatedPivotActionItems.map((item, index) => {
                  return (
                    <PivotItem
                      key={index}
                      itemKey={item.itemKey}
                      itemIcon={item.iconName}
                      headerText={item.name}
                      itemCount={item.count}
                      onRenderItemLink={PivotCustomRender}
                    />
                  );
                })}
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
          </Stack>
          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack
          horizontalAlign="space-between"
          horizontal
          className={styles.pivotContainer}
        >
          <Stack horizontal verticalAlign="center">
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              {updatedPivotActionItems.map((item, index) => {
                return (
                  <PivotDisplay
                    key={index}
                    {...item}
                    onPivotClick={() => {
                      setSelectedKey(item.itemKey);
                      setExpandMenu(true);
                    }}
                  />
                );
              })}
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
