import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  callout: {
    minWidth: 500,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  container: {
    padding: '20px',
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));
