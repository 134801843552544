import {
  FontWeights,
  ILabelStyles,
  Label,
  Separator,
  Stack,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { InfoView } from 'common/components/InfoView';
import { TransactionLayout } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { AccountingRowsView } from '../AccountingRowsView';
import { Invoice } from '../__generated__/Invoice';
import { MenuActions } from './ActionMenu';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { useStyles } from './index.styles';
const labelStyles: Partial<ILabelStyles> = {
  root: {
    fontWeight: FontWeights.bold,
  },
};
interface TransactionDetailsProps {
  transactionData: Invoice;
  loading: boolean;
}

export const TransactionDetail: React.FC<TransactionDetailsProps> = ({
  transactionData,
  loading,
}) => {
  const styles = useStyles();
  const {
    transactionType,
    vendorReference,
    businessUnit,
    department,
    invoiceDate,
    description,
    invoiceNumber,
    currency,
    controlTotalAmount,
    companyCorporatePeriod,
    reimburseAmount,
    reimburseAccountReference,
    invoiceDistributions,
  } = { ...transactionData?.invoice }

  const isPettyCashLayout: boolean = transactionType?.layoutType === TransactionLayout.PETTY_CASH;

  return (
    <>
      {!loading ? (
        <>
          <Stack horizontal verticalAlign="center">
            <Stack className={styles.container}>
              <InfoView
                title={'Name'}
                description={`${vendorReference}` || '-'}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Business Unit'}
                description={`${businessUnit?.name
                  ? businessUnit?.name
                  : '-'
                  }`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Department'}
                description={`${department?.name
                  ? department?.name
                  : '-'
                  }`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Date'}
                description={`${invoiceDate
                  ? dateFormat(
                    dateConvertions(invoiceDate || '')
                  )
                  : '-'
                  }`}
              />
            </Stack>
          </Stack>

          <Separator />

          <Stack
            horizontal
            verticalAlign="center"
            className={styles.infoViewWrapper}
          >
            <Stack className={styles.container}>
              <InfoView
                title={'Description'}
                description={`${description
                  ? description
                  : '-'
                  }`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Number'}
                description={`${invoiceNumber
                  ? invoiceNumber
                  : '-'
                  }`}
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Amount'}
                currency={currency?.isoCode || ''}
                description={controlTotalAmount || '-'}
                isAmount
              />
            </Stack>
            <Stack className={styles.container}>
              <InfoView
                title={'Period'}
                description={`${companyCorporatePeriod?._periodYear
                  ? companyCorporatePeriod?._periodYear
                  : '-'
                  }`}
              />
            </Stack>
          </Stack>
          <Separator />
          {isPettyCashLayout && (
            <>
              <Stack
                horizontal
                verticalAlign="center"
                className={styles.infoViewWrapper}
              >
                <Stack
                  className={clsx(
                    styles.reimbursedAmountContainer,
                    styles.width25
                  )}
                >
                  <Label styles={labelStyles}>Reimbursed Amount</Label>
                  <AmountTextView
                    value={reimburseAmount || '0'}
                  />
                </Stack>
                <Stack className={styles.width25}>
                  <InfoView
                    title="Account"
                    description={
                      reimburseAccountReference || '-'
                    }
                  />
                </Stack>
              </Stack>
              <Separator />
            </>
          )}
          <MenuActions invoiceData={transactionData!} />
          <Separator />
          {
            !!invoiceDistributions?.nodes?.length && transactionData?.invoice &&
            <Stack tokens={{ padding: "0px 20px" }}>
              <AccountingRowsView invoiceDetails={transactionData?.invoice} />
            </Stack>
          }
        </>
      ) : (
        <ShimmerView />
      )}
    </>
  );
};
