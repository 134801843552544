import { EntityDocumentsOrderBy } from '../../../common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: EntityDocumentsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'documentTypes',
    {
      [OrderDirection.ASC]: [
        EntityDocumentsOrderBy.DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC,
      ],
    },
  ],
  [
    '_documentContents',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._DOCUMENT_CONTENTS_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._DOCUMENT_CONTENTS_DESC],
    },
  ],
  [
    'comment',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.COMMENT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.COMMENT_DESC],
    },
  ],
  [
    'fileReference',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.FILE_REFERENCE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.FILE_REFERENCE_DESC],
    },
  ],
  [
    '_createdDate',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._CREATED_DATE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._CREATED_DATE_DESC],
    },
  ],
  [
    '_sharedByUser',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._SHARED_BY_USER_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._SHARED_BY_USER_DESC],
    },
  ],
]);

export const toReportOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    EntityDocumentsOrderBy._CREATED_DATE_DESC,
    EntityDocumentsOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), ...orderByArray];
};
