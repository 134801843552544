import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  username: {
    fontSize: 10,
  },
  occupation: {
    fontSize: 7,
  },
  digitalSign: {
    marginTop: 4,
    fontSize: 6,
    
  },
  date: {
    fontSize: 8,
  },
  icon: {
    width: 15,
    height: 15,
    objectFit: 'contain',
  },
});
