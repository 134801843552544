import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  addNotesSection: {
    backgroundColor: theme.palette.neutralLighterAlt,
    margin: `0px -20px`,
  },
  boldFont: {
    fontWeight: 'bold',
  },
  publicPrivateToggle: {
    marginBottom: 0,
  },
  fixedToggleContainer: {
    width: '100px',
  },
}));
