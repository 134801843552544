import { IconButton, Stack } from '@fluentui/react'
import React, { useState } from 'react'
import { DepartmentGroupsViewModal } from '../DepartmentGroupsViewModal';
import { DepartmentGroupRow } from '../../list';

interface EditDepartmentGroupProps {
  departmentGroup: DepartmentGroupRow;
}

export const EditDepartmentGroup: React.FC<EditDepartmentGroupProps> = ({
  departmentGroup
}) => {
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const {
    _isUpdatable
  } = { ...departmentGroup }

  const _onClick = () => {
    setVisible(true)
    setIsEdit(true)
  }

  return (
    <Stack>
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        onClick={_onClick}
        disabled={!_isUpdatable}
      />
      <DepartmentGroupsViewModal
        isOpen={visible}
        isEdit={isEdit}
        departmentGroup={departmentGroup}
        onClose={() => setVisible(false)}
      />
    </Stack>
  )
}
