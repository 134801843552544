import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 800,
    padding: 25,
  },
  formContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  accountingType: {
    color: theme.palette.themePrimary,
  },
  header: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));
