import { Form, Formik } from 'formik';
import {
  IconButton,
  Modal,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { TAG_GROUP_INITIAL_VALUES } from './constant';
import { validationSchema } from './tagGroupValidation';
import { TagGroupValues } from './types';
import { useStyles } from './index.styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  TagGroupCreate,
  TagGroupCreateVariables,
} from './__generated__/TagGroupCreate';
import { TagSelection } from 'common/graphql/__generated__/TagSelection';
import {
  EntityDeleteInput,
  TagGroupInput,
  TagGroupItemInput,
  TagGroupPatch,
} from 'common/types/globalTypes';
import { useToasts } from 'react-toast-notifications';
import { TagGroups, TagGroupsVariables } from '../__generated__/TagGroups';
import {
  TagGroupDetails_tagGroup,
  TagGroupDetails_tagGroup_tagGroupItemsByTagGroupId_nodes,
} from '../__generated__/TagGroupDetails';
import {
  TagGroupUpdate,
  TagGroupUpdateVariables,
} from './__generated__/TagGroupUpdate';
import { FormikMultiSelectTags } from 'common/components/FormikMultiSelectTags';
import { NoDataView } from 'common/components/DataPlaceholders';
import { BasicForm } from './BasicForm';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
const TAG_GROUPS = loader('../TagGroups.graphql');
const TAG_GROUP_CREATE = loader('./TagGroupCreate.graphql');
const TAG_SELECTION = loader('../../../../common/graphql/TagSelection.graphql');
const TAG_GROUP_UPDATE = loader('./TagGroupUpdate.graphql');
const TAG_GROUP_DETAILS = loader('../TagGroupDetails.graphql');
type TagGroupItemsDetailsType =
  TagGroupDetails_tagGroup_tagGroupItemsByTagGroupId_nodes;
interface TagGroupFormProps {
  visible: boolean;
  onDismiss: () => void;
  isNew: boolean;
  tagGroupDetails: TagGroupDetails_tagGroup | undefined | null;
}

export const TagGroupForm: React.FC<TagGroupFormProps> = ({
  visible,
  onDismiss,
  isNew,
  tagGroupDetails,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [showTags, setShowTags] = useState(false);
  const headerTitle = isNew ? 'Create Tag Group' : 'Update Tag Group';
  let initialValues: TagGroupValues = TAG_GROUP_INITIAL_VALUES;
  if (!isNew && tagGroupDetails)
    initialValues = {
      name: tagGroupDetails.name,
      description: tagGroupDetails.description,
      tagGroupItems: tagGroupDetails.tagGroupItemsByTagGroupId.nodes.map(
        (tag) => ({ tagId: tag.tagId })
      ),
    };

  const [
    getTagSelection,
    { data: tagSelectionData, loading: tagSelectionLoading },
  ] = useLazyQuery<TagSelection>(TAG_SELECTION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [createTagGroup, { loading: tagGroupCreateLoading }] = useMutation<
    TagGroupCreate,
    TagGroupCreateVariables
  >(TAG_GROUP_CREATE, {
    errorPolicy: 'all',
  });

  const [updateTagGroup, { loading: tagGroupUpdateLoading }] = useMutation<
    TagGroupUpdate,
    TagGroupUpdateVariables
  >(TAG_GROUP_UPDATE, {
    errorPolicy: 'all',
  });

  const getSelectionMemo = useCallback(getTagSelection, [showTags]);

  useEffect(() => getSelectionMemo(), [getSelectionMemo]);

  const toggleShowTags = () => setShowTags((prevState) => !prevState);

  const handleSubmit = async (values: TagGroupValues) => {
    const { tagGroupItems, ...tagGroup } = values;
    if (isNew) {
      const { errors } = await createTagGroup({
        variables: {
          input: {
            tagGroup: tagGroup as TagGroupInput,
            tagGroupItems: tagGroupItems?.map(
              ({ ...tag }) => tag as TagGroupItemInput
            ),
          },
        },
        update: (cache, { data }) => {
          if (data?.tagGroupCreate?.tagGroup) {
            const cacheData = cache.readQuery<TagGroups, TagGroupsVariables>({
              query: TAG_GROUPS,
            });
            if (cacheData) {
              const updatedData: TagGroups = {
                tagGroups: {
                  ...cacheData?.tagGroups!,
                  nodes: [
                    data?.tagGroupCreate?.tagGroup!,
                    ...cacheData?.tagGroups?.nodes!,
                  ],
                  pageInfo: cacheData?.tagGroups?.pageInfo!,
                  totalCount: cacheData?.tagGroups?.totalCount! + 1,
                },
              };
              cache.writeQuery<TagGroups, TagGroupsVariables>({
                query: TAG_GROUPS,
                data: updatedData,
              });
            }
          }
        },
      });
      if (!errors)
        addToast('Tag group created successfully.', {
          appearance: 'success',
        });
      else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    } else {
      const tagGroupPatch: TagGroupPatch = Object.entries(tagGroup).reduce(
        (res, [key, val]) => {
          if (val !== initialValues[key as keyof TagGroupValues]) {
            return { ...res, [key]: val };
          }
          return res;
        },
        {}
      );
      const tagGroupItemsDetails: TagGroupItemsDetailsType[] =
        tagGroupDetails?.tagGroupItemsByTagGroupId.nodes || [];
      let tagGroupItemsCreate: TagGroupItemInput[] = [];
      let tagGroupItemsDelete: EntityDeleteInput[] = [];
      if (tagGroupItems?.length) {
        //To create input array for tag items.
        tagGroupItemsCreate = tagGroupItems.filter(
          (tagGroup) =>
            !tagGroupItemsDetails.some(
              (tagItem) => tagGroup.tagId === tagItem.tagId
            )
        ) as TagGroupItemInput[];
        /* To create delete array for tag items.
          1. Make an array of common tags between previously attached tags and tags coming from inputs.
          2. Make and array of removed tags by comparing the common tags array with the previously attached tags. 
        */
        const commonTags = tagGroupItems.filter(({ tagId: tagId1 }) =>
          tagGroupItemsDetails.some(({ tagId: tagId2 }) => tagId2 === tagId1)
        );
        tagGroupItemsDelete = tagGroupItemsDetails
          .filter(
            ({ tagId: id1 }) =>
              !commonTags.some(({ tagId: id2 }) => id2 === id1)
          )
          .map((tag) => ({
            id: tag.id,
            rowTimestamp: tag._rowTimestamp,
          })) as EntityDeleteInput[];
      }

      const { errors } = await updateTagGroup({
        variables: {
          input: {
            id: tagGroupDetails?.id!,
            rowTimestamp: tagGroupDetails?._rowTimestamp!,
            tagGroupPatch,
            tagGroupItemsCreate,
            tagGroupItemsDelete,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: TAG_GROUP_DETAILS,
            variables: {
              id: tagGroupDetails?.id!,
            },
          },
        ],
      });
      if (!errors)
        addToast('Tag group updated successfully.', {
          appearance: 'success',
        });
      else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };

  return (
    <Formik<TagGroupValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form>
            <Modal isOpen={visible} isBlocking={true} onDismiss={onDismiss}>
              <Stack className={styles.container}>
                <ModalHeader
                  headerTitle={headerTitle}
                  isNew={isNew}
                  onDismiss={onDismiss}
                />
                <Stack tokens={{ childrenGap: 20, padding: 25 }}>
                  <BasicForm />
                  {showTags ? (
                    <Stack
                      tokens={{ childrenGap: 30 }}
                      className={styles.tagsContent}
                    >
                      <Stack>
                        <Stack horizontal>
                          <IconButton
                            onClick={toggleShowTags}
                            iconProps={{ iconName: 'ChevronUp' }}
                            className={styles.iconButtonColor}
                            ariaLabel="Expand"
                          />
                          <Text className={styles.selectTagsLabel}>
                            Select Tags
                          </Text>
                        </Stack>
                        <Stack className={styles.tagsSection}>
                          <FormikMultiSelectTags
                            name="tagGroupItems"
                            tagsData={tagSelectionData?.tags?.nodes || []}
                          />
                          <NoDataView
                            title={'No tags available!'}
                            show={
                              !tagSelectionData?.tags?.nodes.length &&
                              !tagSelectionLoading
                            }
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  ) : (
                    <Stack horizontal>
                      <IconButton
                        onClick={toggleShowTags}
                        iconProps={{ iconName: 'ChevronDown' }}
                        className={styles.iconButtonColor}
                        ariaLabel="Expand"
                      />
                      <Text className={styles.selectTagsLabel}>
                        Select Tags
                      </Text>
                    </Stack>
                  )}
                </Stack>
                {(tagGroupCreateLoading || tagGroupUpdateLoading) && (
                  <ProgressIndicator />
                )}
                <ModalFooter headerTitle={headerTitle} onDismiss={onDismiss} />
              </Stack>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
