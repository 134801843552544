import React, { useState } from 'react';
import {
  Checkbox,
  CompactPeoplePicker,
  IBasePickerSuggestionsProps,
  IPersonaProps,
  Stack,
  Text,
} from '@fluentui/react';
import { ContactsPayload } from '../ContactsForm';
import { useCommonStyles } from 'common/styles';

interface ContactsPickerProps {
  group: ContactsPayload;
  allSelectable: boolean;
  onContactSelected?: (selectedUser: IPersonaProps[]) => void;
  onAllSelected?: (selected: boolean) => void;
}
const suggestionProps: IBasePickerSuggestionsProps = {
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

export const ContactsPicker: React.FC<ContactsPickerProps> = ({
  group,
  allSelectable,
  onAllSelected,
  onContactSelected,
}) => {
  const commonStyles = useCommonStyles();

  const [Picker, setPicker] = useState(false);
  const [selectedItems, setSelectedItems] = React.useState<
    IPersonaProps[] | undefined
  >([]);

  const contacts = group.members.map(
    (member) => ({ text: member.text, id: member.id } as IPersonaProps)
  );

  const removeDuplicates = (
    personas: IPersonaProps[] | undefined,
    possibleDupes: IPersonaProps[] | undefined
  ) => {
    return personas?.filter(
      (persona: IPersonaProps) => !listContainsPersona(persona, possibleDupes)
    );
  };

  const listContainsPersona = (
    persona: IPersonaProps,
    personas: IPersonaProps[] | undefined
  ) => {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return (
      personas.filter((item: IPersonaProps) => item.text === persona.text)
        .length > 0
    );
  };

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      filteredPersonas =
        removeDuplicates(filteredPersonas, currentPersonas) || [];
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return contacts!.filter((item) =>
      doesTextStartWith(item.text as string, filterText)
    );
  };

  const doesTextStartWith = (text: string, filterText: string): boolean => {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  };

  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };

  const returnMostRecentlyUsed = (
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return filterPromise(removeDuplicates(contacts, currentPersonas) || []);
  };

  const onItemChange = (item: IPersonaProps[]): IPersonaProps[] => {
    setSelectedItems(item);
    onContactSelected?.(item);
    return item!;
  };

  return (
    <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
        <Text className={commonStyles.semibold}>{group.name}</Text>
        {allSelectable && (
          <Checkbox
            label={'All'}
            onChange={(_, checked) => {
              setPicker(checked!);
              setSelectedItems([]);
              onAllSelected?.(checked!);
            }}
          />
        )}
      </Stack>

      <CompactPeoplePicker
        styles={{ root: { flex: '1' } }}
        disabled={Picker}
        onResolveSuggestions={onFilterChanged}
        onEmptyResolveSuggestions={returnMostRecentlyUsed}
        pickerSuggestionsProps={suggestionProps}
        className={'ms-PeoplePicker'}
        selectedItems={selectedItems}
        onChange={(item) => onItemChange(item!)}
        key={'normal'}
      />
    </Stack>
  );
};
