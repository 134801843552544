import { IColumn, Stack, Text } from "@fluentui/react";
import React from "react";
import { DepartmentGroupRow } from ".";
import { EditDepartmentGroup } from "../view/EditDepartmentGroup";
import { DepartmentGroupItem } from "./DepartmentGroupItem";

export type ColumnStylesProps = {
  onRenderColumnStack: string;
};

export const onRenderItems = (styles: ColumnStylesProps) => {
  const renderItemColumn = (
    item: DepartmentGroupRow,
    _index: number | undefined,
    column: IColumn | undefined,
  ) => {
    if (item) {
      const fieldContent = item[column?.fieldName as keyof DepartmentGroupRow] as string;
      switch (column?.key) {
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <EditDepartmentGroup
                departmentGroup={item}
              />
            </Stack>
          );
        case 'departmentGroupItems':
          return (
            <Stack wrap horizontal tokens={{ childrenGap: 10 }}>
              {
                item.departmentGroupItems.nodes.map((groupItem, index) => (
                  <Stack key={index}>
                    <DepartmentGroupItem item={groupItem} isTravelDepartmentGroup={!!item.isTravelDepartmentGroup} />
                  </Stack>
                ))
              }
            </Stack>
          )
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  }
  return renderItemColumn
}