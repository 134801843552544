import { ProgressIndicator, Stack } from '@fluentui/react';
import React from 'react';
import { Supplier_supplier_supplierRatingResults } from '../../../__generated__/Supplier';
import { useStyles } from './index.styles';
interface RatingsPercentageProps {
  ratings: Supplier_supplier_supplierRatingResults
}
export const RatingsPercentage: React.FC<RatingsPercentageProps> = ({
  ratings
}) => {
  const styles = useStyles();
  return (
    <Stack
      className={styles.mainContainer}
    >
      <ProgressIndicator
        label={`${ratings?.star1Percent} %`}
        percentComplete={
          ratings?.star1Percent || 0 * 0.01
        }
        barHeight={4}
      />
      <ProgressIndicator
        label={`${ratings?.star2Percent} %`}
        percentComplete={
          ratings?.star2Percent || 0 * 0.01
        }
        barHeight={4}
      />
      <ProgressIndicator
        label={`${ratings?.star3Percent} %`}
        percentComplete={
          ratings?.star3Percent || 0 * 0.01
        }
        barHeight={4}
      />
      <ProgressIndicator
        label={`${ratings?.star4Percent} %`}
        percentComplete={
          ratings?.star4Percent || 0 * 0.01
        }
        barHeight={4}
      />
      <ProgressIndicator
        label={`${ratings?.star5Percent} %`}
        percentComplete={
          ratings?.star5Percent || 0 * 0.01
        }
        barHeight={4}
      />
    </Stack>
  )
}
