import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
  },
  calloutContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));