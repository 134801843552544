import { Stack } from '@fluentui/react';
import { FormHookTextField } from 'common/components/FormHooksFields';
import React from 'react';

export const ContactSectionInput: React.FC<{
  baseField: string;
  disabled: boolean;
}> = ({ baseField, disabled }) => {
  return (
    <Stack grow={3} tokens={{ childrenGap: 10 }}>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack>
          <FormHookTextField
            name={`${baseField}rentalTelephoneNumber`}
            placeholder="Enter phone"
            label="Phone"
            style={{ width: 150 }}
            disabled={disabled}
          />
        </Stack>
        <Stack grow={8}>
          <FormHookTextField
            name={`${baseField}rentalEmailAddress`}
            placeholder="Enter email"
            label="Email"
            disabled={disabled}
          />
        </Stack>
      </Stack>
      <FormHookTextField
        name={`${baseField}comments`}
        multiline
        rows={3}
        resizable={false}
        placeholder="Comment"
        disabled={disabled}
      />
    </Stack>
  );
};
