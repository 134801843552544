import {
  DefaultButton,
  IContextualMenuProps,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  makeStyles,
} from '@fluentui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { AccountEntryValues } from '../types';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
    padding: '10px 20px',
    height: 84,
  },
  progressContainer: {
    height: 12,
  },
}));

interface FooterProps {
  isEdit: boolean;
  loading: boolean;
  onSubmit: (addMore: boolean, closeAfterComplete: boolean) => void;
  onCancel: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  loading,
  onSubmit,
  onCancel,
  isEdit,
}) => {
  const styles = useStyles();
  const {
    formState: { isDirty, errors, isSubmitting },
  } = useFormContext<AccountEntryValues>();
  const isDisabled = !isDirty || Object.keys(errors).length > 0 || isSubmitting;

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'saveAddNew',
        text: 'Save + Add New',
        onClick: () => {
          onSubmit(true, false);
        },
      },

      {
        key: 'save',
        text: 'Save',
        onClick: () => {
          onSubmit(false, false);
        },
      },
    ],
  };

  return (
    <Stack className={styles.container}>
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack className={styles.progressContainer}>
          {loading && <ProgressIndicator />}
        </Stack>

        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
          }}
        >
          {isEdit && (
            <PrimaryButton
              text="Save"
              disabled={isDisabled}
              onClick={() => {
                onSubmit(true, false);
              }}
            />
          )}

          {!isEdit && (
            <DefaultButton
              text={'Save and close'}
              primary
              split
              menuProps={menuProps}
              onClick={() => onSubmit(false, true)}
              disabled={isDisabled}
            />
          )}
          <DefaultButton text="Cancel" onClick={onCancel} />
        </Stack>
      </Stack>
    </Stack>
  );
};
