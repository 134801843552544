import { IColumn } from '@fluentui/react';
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export type ColumnData = IColumn & {
  /** property to determine whether this coloumn can be toggled for visibility */
  toggleVisibility?: boolean;
  /** property to determine whether the column is visble or not*/
  isVisible?: boolean;
  isFormField?: boolean;
};

export const useColumns = () => {
  const columns = new Map<string, ColumnData>([
    [
      'departmentId',
      {
        key: 'departmentId',
        name: 'Department',
        fieldName: 'departmentId',
        minWidth: 250,
        maxWidth: 250,
        ...commonColumnProps,
      },
    ],
    [
      'businessUnitId',
      {
        key: 'businessUnitId',
        name: 'Business Unit',
        fieldName: 'businessUnitId',
        minWidth: 250,
        maxWidth: 250,
        ...commonColumnProps,
      },
    ],
    [
      'budgetTypeId',
      {
        key: 'budgetTypeId',
        name: 'Budget Type',
        fieldName: 'budgetTypeId',
        minWidth: 150,
        maxWidth: 150,
        ...commonColumnProps,
      },
    ],
    [
      'lookupAccountId',
      {
        key: 'lookupAccountId',
        name: 'Account',
        fieldName: 'lookupAccountId',
        minWidth: 80,
        maxWidth: 80,
        ...commonColumnProps,
      },
    ],
    [
      'currencyId',
      {
        key: 'currencyId',
        name: 'Currency',
        fieldName: 'currencyId',
        minWidth: 120,
        maxWidth: 120,
        ...commonColumnProps,
      },
    ],
    [
      'budgetAmount',
      {
        key: 'budgetAmount',
        name: 'Amount',
        fieldName: 'budgetAmount',
        minWidth: 120,
        maxWidth: 120,
        ...commonColumnProps,
      },
    ],
    [
      'delete',
      {
        key: 'delete',
        name: '',
        fieldName: 'delete',
        minWidth: 80,
        maxWidth: 80,
        ...commonColumnProps,
        toggleVisibility: false,
      },
    ],
  ]);

  let columnArray = Array.from(columns, (data) => data[1]);
  return { columns, columnArray };
};
