import { Stack, Text } from '@fluentui/react'
import React from 'react'
interface AddressTextProps {
  text: string | null | undefined;
}
export const AddressText: React.FC<AddressTextProps> = ({ text }) => {
  return (
    <>
      {
        text &&
        <Stack>
          <Text>{text}</Text>
        </Stack>
      }
    </>
  )
}
