import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  categoryType: {
    color: theme.palette.themePrimary,
  },
  typeContainer: { alignItems: 'center', margin: '10px 20px', width: 150 },

  callout: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  onRenderColumnStack: {
    height: 25,
  },
  groupItemHeight: {
    height: 30,
    padding: 10,
  },
  isTravelDepartmentGroup: {
    color: 'white',
    backgroundColor: theme.palette.green,
    borderRadius: 5,
  },
  isNonTravelDepartmentGroup: {
    // color: 'white',
    backgroundColor: theme.palette.themePrimary,
    borderRadius: 5,
  },
  backgroundWithoutIsAlert: {
    borderRadius: 5,
    backgroundColor: theme.palette.themePrimary,
  },
  fontColor: {
    color: 'white',
  },
}));
