import {
  ActionButton,
  IStackStyles,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { UploadFileModal, UploadFileModalProps } from './view';

type UploadFilesProps = Omit<UploadFileModalProps, 'onClose' | 'isOpen'> & {
  renderButton?: React.ReactNode;
  disableUpload?: boolean;
  onOpen?: () => void;
  onVisibilityChange?: (value: boolean) => void;
};

export const UploadFiles: React.FC<UploadFilesProps> = (props) => {
  const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };
  const [visible, setVisible] = useState(false);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  const onVisibilityChangeMemo = useCallback((isVisible: boolean) => {
    props.onVisibilityChange?.(isVisible);
  }, [props.onVisibilityChange])

  useEffect(() => {
    onVisibilityChangeMemo(visible)
  }, [onVisibilityChangeMemo, visible])

  return (
    <>
      <Stack horizontal styles={stackStyles}>
        <TooltipHost content="Upload new files">
          <ActionButton
            onClick={showDialog}
            iconProps={{ iconName: 'ShareiOS' }}
            text="Upload"
            checked
            disabled={props.disableUpload}
          />
        </TooltipHost>

        {visible && (
          <UploadFileModal onClose={hideDialog} {...props} isOpen={visible} />
        )}
      </Stack>
    </>
  );
};
