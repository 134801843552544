import {
  ActionButton,
  Callout,
  DirectionalHint,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  Checkbox,
  IDropdownOption,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { TextSearchFilter } from 'common/components/Filters/TextSearchFilter';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { ListFiltersProps } from '..';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { TravelerCommonData } from '../../view/__generated__/TravelerCommonData';
const TRAVELER_COMMON_DATA = loader('../../view/TravelerCommonData.graphql');

type ListFilterCalloutProps = Omit<ListFiltersProps, 'documentPoolId'> & {
  onDismiss?: () => void;
  calloutId: string;
};
export const ListFilterCallout: React.FC<ListFilterCalloutProps> = ({
  onDismiss,
  calloutId,
  onFilterChange,
  filterOptions,
}) => {
  const { filterTypes: filters, startsWith: startsWithDefault } = {
    ...filterOptions,
  };
  const styles = useStyles();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const [name, setName] = useState<string | undefined>();
  const [userOccupation, setUserOccupation] = useState<string | undefined>();
  const [travelPolicy, setTravelPolicy] = useState<string | undefined>();
  const [vendorName, setVendorName] = useState<string | undefined>();

  const [addressLine, setAddressLine] = useState<string | undefined>();
  const [country, setCountry] = useState<string | undefined>();
  const [city, setCity] = useState<undefined | string>();
  const [stateRegion, setStateRegion] = useState<undefined | string>();

  const [rating, setRating] = useState<undefined | string>();
  const [statusType, setStatusType] = useState<undefined | string>();
  const [startsWith, setStartsWith] = useState<boolean | undefined>();
  const [department, setDepartment] = useState<string | undefined | null>();

  const { data: travelerCommonData } = useQuery<TravelerCommonData>(
    TRAVELER_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const departmentOptions: IDropdownOption[] =
    travelerCommonData?.companyDepartments?.nodes.map((type) => ({
      key: type.id,
      text: type.name || '',
    })) || [];

  const onFilterApply = () => {
    onFilterChange({ filterTypes, startsWith: startsWith! });
    onDismiss?.();
  };

  const onCancel = () => {
    onDismiss?.();
  };

  useEffect(() => {
    if (filters) {
      setFilterTypes(filters);
      setStartsWith(startsWithDefault);
      const namePos = filters.findIndex(
        (item) => item.filterKey === '_sortName'
      );

      const ratingPos = filters.findIndex(
        (item) => item.filterKey === 'rating'
      );

      const addressPos = filters.findIndex(
        (item) => item.filterKey === 'addressLine1'
      );

      const departmentPos = filters.findIndex(
        (item) => item.filterKey === 'department'
      );

      const userOccupationPos = filters.findIndex(
        (item) => item.filterKey === 'userOccupation'
      );
      const travelPolicyPos = filters.findIndex(
        (item) => item.filterKey === 'travelPolicy'
      );
      const vendorNamePos = filters.findIndex(
        (item) => item.filterKey === 'vendorNumber'
      );
      const cityNamePos = filters.findIndex(
        (item) => item.filterKey === 'cityName'
      );

      const countryPos = filters.findIndex(
        (item) => item.filterKey === 'country'
      );

      if (travelPolicyPos !== -1)
        setTravelPolicy(filters[travelPolicyPos].value.toString());

      if (departmentPos !== -1)
        setDepartment(filters[departmentPos].value.toString());
      if (userOccupationPos !== -1)
        setUserOccupation(filters[userOccupationPos].value.toString());
      if (countryPos !== -1) setCountry(filters[countryPos].value.toString());
      if (ratingPos !== -1) setRating(filters[ratingPos].value.toString());
      if (cityNamePos !== -1) setCity(filters[cityNamePos].value.toString());
      if (vendorNamePos !== -1)
        setVendorName(filters[vendorNamePos].value.toString());
      if (addressPos !== -1)
        setAddressLine(filters[addressPos].value.toString());
      if (namePos !== -1) setName(filters[namePos].value.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    newFilters = newFilters.filter((element) => element.value);
    setFilterTypes(newFilters);
  };

  const onReset = () => {
    setCountry(undefined);
    setCity(undefined);
    setStateRegion(undefined);
    setAddressLine(undefined);

    setRating(undefined);
    setStatusType(undefined);

    setUserOccupation(undefined);
    setTravelPolicy(undefined);
    setVendorName(undefined);
    setName(undefined);
    setDepartment(null);

    setStartsWith(true);
    setFilterTypes([]);
  };

  return (
    <Callout
      className={styles.callout}
      gapSpace={0}
      target={`#${calloutId}`}
      directionalHint={DirectionalHint.leftTopEdge}
      onDismiss={onDismiss}
      setInitialFocus
    >
      <Stack className={styles.container}>
        <Text variant={'xLarge'}>Filters</Text>

        <Stack tokens={{ childrenGap: 10, padding: '20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Name"
                defaultFilter
                value={name}
                filterKey={'_sortName'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setName(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Traveler Number"
                defaultFilter
                value={vendorName}
                filterKey={'vendorNumber'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setVendorName(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Travel Policy"
                defaultFilter
                value={travelPolicy}
                filterKey={'travelPolicy'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setTravelPolicy(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1} className={styles.width50}>
              <DropdownFilter
                controlled
                placeholder={'Department'}
                options={departmentOptions}
                filterKey={'department'}
                defaultFilter
                selectedKey={department}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setDepartment(value.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1} className={styles.width50}>
              <TextSearchFilter
                placeholder="User Occupation"
                defaultFilter
                value={userOccupation}
                filterKey={'userOccupation'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setUserOccupation(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Country"
                defaultFilter
                value={country}
                filterKey={'country'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setCountry(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="State"
                defaultFilter
                value={stateRegion}
                filterKey={'stateRegion'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setStateRegion(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="City"
                defaultFilter
                value={city}
                filterKey={'cityName'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setCity(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack.Item grow={1}>
            <TextSearchFilter
              placeholder="Address Line"
              defaultFilter
              value={addressLine}
              filterKey={'addressLine1'}
              onOptionChange={(value) => {
                onOptionChange(value);
                setAddressLine(value?.value.toString());
              }}
            />
          </Stack.Item>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Rating"
                defaultFilter
                value={rating}
                filterKey={'rating'}
                isNumeric
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setRating(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Status"
                defaultFilter
                value={statusType}
                filterKey={'status'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setStatusType(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center">
            <ActionButton
              text="Reset"
              onClick={onReset}
              iconProps={{ iconName: 'RevToggleKey' }}
            />
            <Checkbox
              label="Starts with"
              checked={startsWith}
              onChange={(_, value) => {
                setStartsWith(value);
              }}
            />
          </Stack>

          <Stack tokens={{ padding: '10px 0px', childrenGap: 20 }} horizontal>
            <PrimaryButton text="Apply" onClick={onFilterApply} />
            <DefaultButton text="Cancel" onClick={onCancel} />
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
