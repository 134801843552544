import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  IComboBoxStyles,
  IconButton,
  Stack,
  makeStyles,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TravelPolicyValues } from '../../types';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: '0px 4px',
  },
}));

const comboBoxStyles: Partial<IComboBoxStyles> = {
  container: {
    width: 210,
  },
  callout: {
    width: 206,
  }
}

type FareClassesProps = IComboBoxProps & {
  selectedFareClass?: string | null;
  onClassChange?: (key: string | number) => void;
};

export const FareClasses: React.FC<FareClassesProps> = ({
  selectedFareClass,
  options,
  onClassChange,
  ...props
}) => {
  const styles = useStyles();
  const { setValue } = useFormContext<TravelPolicyValues>();
  const [selectedOption, setSelectedOption] = useState<
    string | number | null | undefined
  >(undefined);

  const handleOptionChange = (
    _event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    const departureClass = option?.key as keyof TravelPolicyValues;
    if (departureClass) {
      setValue(departureClass, true, { shouldDirty: true });
      options.forEach((ele) => {
        if (ele.key !== departureClass) {
          const option = ele.key as keyof TravelPolicyValues
          setValue(option, false);
        }
      });
      setSelectedOption(option?.key);
      if (option?.key) onClassChange?.(option?.key);
    }
  };

  const onClear = () => {
    if (selectedOption) {
      setValue(
        selectedOption as keyof TravelPolicyValues,
        false,
        { shouldDirty: true }
      );
      setSelectedOption(null)
    }
  }

  useEffect(() => {
    setSelectedOption(selectedFareClass);
  }, [selectedFareClass]);

  return (
    <Stack
      horizontal
      verticalAlign="end"
      tokens={{
        childrenGap: 6
      }}
    >
      <ComboBox
        label="Class"
        placeholder="Select Class"
        multiSelect
        allowFreeform={false}
        options={options}
        selectedKey={selectedOption || null}
        onChange={handleOptionChange}
        styles={comboBoxStyles}
        {...props}
      />
      {
        selectedOption &&
        <IconButton
          type="button"
          onClick={onClear}
          iconProps={{ iconName: 'cancel' }}
          className={styles.icon}
          ariaLabel="Close"
        />
      }
    </Stack>
  );
};
