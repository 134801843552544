import { useMutation, useQuery } from '@apollo/client';
import {
  ContextualMenu,
  IconButton,
  ProgressIndicator,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { ModalWrapper } from 'common/components/ModalWrapper';
import { TravelerCreate_travelerCreate_traveler } from 'common/graphql/__generated__/TravelerCreate';
import {
  TravelerAlias,
  TravelerAliasVariables,
} from './__generated__/TravelerAlias';
import {
  TravelerAliasNameUpdate,
  TravelerAliasNameUpdateVariables,
} from './__generated__/TravelerAliasNameUpdate';
import { Footer } from './Footer';
import { Header } from './Header';
import { useStyles } from './index.styles';

const TRAVELER_ALIAS = loader('./TravelerAlias.graphql');
const TRAVELER_ALIAS_NAME_UPDATE = loader('./TravelerAliasNameUpdate.graphql');

interface AliasModalProps {
  id: string | undefined;
  onClose: () => void;
  isTravelAuthMutation?: boolean | null;
  isProtected?: boolean;
  onAliasUpdate?: (param: TravelerCreate_travelerCreate_traveler) => void;
}
const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
});
export const AliasModal: React.FC<AliasModalProps> = ({
  onClose,
  id,
  isTravelAuthMutation,
  isProtected,
  onAliasUpdate,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [aliasValue, setAliasValue] = useState<string>('');
  const [nameValue, setNameValue] = useState<string>('');
  const [familyNameValue, setFamilyNameValue] = useState<string>('');
  const [isEdited, setisEdited] = useState<boolean>(false);
  const [toggleEdit, setToggleEdit] = useState<boolean>(false);

  const { loading, data: travelerAliasData } = useQuery<
    TravelerAlias,
    TravelerAliasVariables
  >(TRAVELER_ALIAS, {
    variables: { id: id! },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [updateAlias, { loading: updateLoading }] = useMutation<
    TravelerAliasNameUpdate,
    TravelerAliasNameUpdateVariables
  >(TRAVELER_ALIAS_NAME_UPDATE, { errorPolicy: 'all' });

  const onSubmit = async () => {
    const TravelerAliasNameUpdateInput = {
      id: id!,
      nameAlias: aliasValue,
      firstName: nameValue,
      rowTimestamp: travelerAliasData?.travelerAlias?._rowTimestamp!,
      companyOrLastName: familyNameValue,
      isTravelAuthMutation: !!isTravelAuthMutation,
    };
    const { errors, data } = await updateAlias({
      variables: {
        input: TravelerAliasNameUpdateInput,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: TRAVELER_ALIAS,
          variables: {
            id: id,
          },
        },
      ],
    });
    if (!!errors?.length) {
      addToast('invalid data !', {
        appearance: 'error',
      });
    } else {
      onAliasUpdate?.(data?.travelerAliasNameUpdate?.traveler!);
      onClose();
    }
  };
  useEffect(() => {
    if (travelerAliasData) {
      setAliasValue(travelerAliasData?.travelerAlias?.nameAlias!);
      setFamilyNameValue(travelerAliasData?.travelerAlias?.companyOrLastName!);
      setNameValue(travelerAliasData?.travelerAlias?.firstName!);
    }
  }, [travelerAliasData]);

  const isDisabled =
    !travelerAliasData?.travelerAlias?._isUpdatable || !!isProtected;

  return (
    <>
      <ModalWrapper
        isOpen
        dragOptions={{
          moveMenuItemText: 'Move',
          closeMenuItemText: 'Close',
          menu: ContextualMenu,
          dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
        }}
      >
        {loading || updateLoading ? (
          <ProgressIndicator label="Loading" className={styles.loadingScreen} />
        ) : (
          <Stack className={styles.modelContainer}>
            (
            <>
              <Header />
              <Stack
                tokens={{ childrenGap: 10 }}
                className={styles.formContainer}
              >
                <Stack horizontal style={{ width: '100%' }}>
                  <Stack.Item className={styles.flexContainer}>
                    <TextField
                      label="Alias"
                      name="alias"
                      value={aliasValue}
                      onChange={(e) => {
                        e.currentTarget.value || nameValue || familyNameValue
                          ? setisEdited(true)
                          : setisEdited(false);
                        setAliasValue(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                      styles={{
                        root: {
                          flex: '1',
                        },
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item className={styles.cancelIconContainer}>
                    {!isDisabled && aliasValue?.length > 0 && (
                      <IconButton
                        aria-label="Cancel"
                        iconProps={{ iconName: 'Cancel' }}
                        className={iconClass}
                        onClick={() => {
                          setAliasValue('');
                          setisEdited(true);
                        }}
                      />
                    )}
                  </Stack.Item>
                </Stack>
                <Stack className={styles.inlineLabelStyle}>
                  <Toggle
                    inlineLabel
                    label="View Name"
                    onChange={() => setToggleEdit(!toggleEdit)}
                  />
                </Stack>

                {toggleEdit && (
                  <TextField
                    label="Given Name"
                    value={nameValue}
                    onChange={(e) => {
                      e.currentTarget.value || aliasValue || familyNameValue
                        ? setisEdited(true)
                        : setisEdited(false);
                      setNameValue(e.currentTarget.value);
                    }}
                    disabled={isDisabled}
                  />
                )}
                {toggleEdit && (
                  <TextField
                    label="Family Name"
                    value={familyNameValue}
                    onChange={(e) => {
                      e.currentTarget.value || aliasValue || nameValue
                        ? setisEdited(true)
                        : setisEdited(false);
                      setFamilyNameValue(e.currentTarget.value);
                    }}
                    disabled={isDisabled}
                  />
                )}
              </Stack>
              <Footer
                DisplaySaveButton={!isDisabled && isEdited}
                onClose={onClose}
                onSave={onSubmit}
              />
            </>
          </Stack>
        )}
      </ModalWrapper>
    </>
  );
};
