import React from 'react';
import { Icon, Stack, Text } from '@fluentui/react';
import { useDropzone } from 'react-dropzone';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { DocumentEntityList } from 'common/components/DocumentEntityList';

interface FileSelectProps {
  isMultiple?: boolean;
  fileSelected: File[];
  onRemoveFile?: (fileIndex: number) => void;
  onDrop: (files: File[]) => void;
}

export const FileSelect: React.FC<FileSelectProps> = ({
  isMultiple,
  onDrop,
  fileSelected,
  onRemoveFile,
}) => {
  let { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    multiple: true,
  });

  const styles = useStyles();

  return (
    <Stack>
      <div
        {...getRootProps()}
        className={clsx(styles.dropZone, isDragActive && styles.dropZoneHover)}
      >
        <Stack
          grow
          horizontalAlign="center"
          verticalAlign="center"
          verticalFill
        >
          <input
            {...getInputProps({
              multiple: isMultiple,
            })}
          />

          {isDragActive ? (
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <Icon
                iconName={'CloudUpload'}
                className={styles.uploadIconStyle}
              />
              <Text className={styles.uploadIconStyle}>
                Drop the files here...
              </Text>
            </Stack>
          ) : (
            <Text>Drag 'n' drop some files here, or click to select files</Text>
          )}
        </Stack>
      </div>

      <Stack style={{ padding: '10px 0px 0px 0px' }}>
        {fileSelected.length > 0 && (
          <DocumentEntityList
            onRemoveFile={onRemoveFile!}
            filesList={fileSelected!}
          />
        )}
      </Stack>
    </Stack>
  );
};
