import { DetailsList, DetailsRow, FontWeights, IColumn, IDetailsFooterProps, IRenderFunction, SelectionMode, Stack, Text, makeStyles } from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import React from 'react';
import { PaymentDetails_payment_invoicePayments, PaymentDetails_payment_invoicePayments_nodes_invoice } from '../../__generated__/PaymentDetails';
import { columns } from './columns';

const useStyles = makeStyles(() => ({
  amount: {
    display: 'block',
    textAlign: 'right',
  },
  cell: {
    height: 25,
  },
  footerLabel: {
    fontWeight: FontWeights.bold,
    textAlign: "right"
  }
}));

interface PaymentInvoicesProps {
  invoicePayments: PaymentDetails_payment_invoicePayments | undefined;
}
type PaymentInvoiceRowItem = PaymentDetails_payment_invoicePayments_nodes_invoice;

export const PaymentInvoices: React.FC<PaymentInvoicesProps> = ({
  invoicePayments
}) => {
  const styles = useStyles();
  const {
    nodes
  } = { ...invoicePayments }

  const transformedData: PaymentInvoiceRowItem[] =
    nodes?.map((ele) => ({
      ...ele.invoice!,
      invoiceDate: ele?.invoice?.invoiceDate
        ? dateFormat(dateConvertions(ele?.invoice.invoiceDate))
        : null,
    })) || [];

  const totalAmount = transformedData
    .filter((tran) => !tran.transactionType?.isDocumentSigningPayment)
    .map((item) => item.controlTotalAmount)
    .reduce((sum, curr) => {
      return new Decimal(curr || 0).plus(sum);
    }, new Decimal(0))
    .toNumber();

  const onRenderItemColumn = (
    item: PaymentInvoiceRowItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof PaymentInvoiceRowItem
      ] as string;
      switch (column?.key) {
        case 'controlTotalAmount':
          return (
            <AmountTextView
              className={styles.amount}
              value={item.controlTotalAmount}
            />
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.cell}
            >
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !totalAmount) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  const renderFooterColumn = (
    _item?: PaymentInvoiceRowItem,
    _index?: number,
    column?: IColumn
  ) => {
    if (column?.key === 'invoiceDate')
      return (
        <Stack>
          <Text className={styles.footerLabel}>Total</Text>
        </Stack>
      );
    if (column?.key === 'controlTotalAmount')
      return (
        <AmountTextView
          value={totalAmount.toString()}
          className={clsx(styles.amount, styles.footerLabel)}
        />
      );
    return null;
  };

  return (
    <DetailsList
      compact
      selectionMode={SelectionMode.none}
      items={transformedData}
      columns={columns}
      onRenderItemColumn={onRenderItemColumn}
      onRenderDetailsFooter={renderFooter}
    />
  )
}
