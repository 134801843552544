import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField,
  Toggle,
} from '@fluentui/react';

import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import {
  UpdateNote,
  UpdateNoteVariables,
} from 'common/graphql/__generated__/UpdateNote';

import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';
const UPDATE_NOTE = loader('../../../../common/graphql/UpdateNote.graphql');

interface NoteFormUpdateProps {
  item: NotesEntityFields;
  onCancel: () => void;
  listRefreshRequest?: () => void;
}

export const NoteUpdateForm: React.FC<NoteFormUpdateProps> = ({
  item,
  onCancel,
  listRefreshRequest,
}) => {
  const styles = useStyles();
  const [noteComment, setNoteComment] = useState<string | undefined>();
  const [isShared, setNoteIsShared] = React.useState<boolean>(true);
  const { addToast } = useToasts();

  const [noteUpdate, { loading: updateInProgress }] = useMutation<
    UpdateNote,
    UpdateNoteVariables
  >(UPDATE_NOTE, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (item) {
      setNoteIsShared(item.isShared);
      setNoteComment(item.noteComment);
    }
  }, [item]);

  const onNoteUpdate = async () => {
    if (!updateInProgress) {
      const { errors } = await noteUpdate({
        variables: {
          input: {
            id: item.id,
            rowTimestamp: item._rowTimestamp || '',
            notePatch: { isShared: isShared, noteComment: noteComment },
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        listRefreshRequest?.();
        onCancel();
        addToast('Note has been updated', { appearance: 'success' });
      }
    }
  };

  const disableSave =
    item.isShared !== isShared || item.noteComment !== noteComment;

  return (
    <Stack
      className={styles.addNotesSection}
      tokens={{ childrenGap: 10, padding: '20px 30px' }}
    >
      <Stack horizontal horizontalAlign="space-between" verticalAlign="end">
        <Text className={styles.boldFont}>Update Note</Text>
        <div className={styles.fixedToggleContainer}>
          <Toggle
            checked={!isShared}
            onText="Private"
            offText="Public"
            disabled={updateInProgress}
            className={styles.publicPrivateToggle}
            onChange={(_event, checked) => setNoteIsShared(!checked)}
          />
        </div>
      </Stack>
      <TextField
        multiline
        rows={4}
        value={noteComment || ''}
        placeholder="Write a new note..."
        resizable={false}
        onChange={(_event, value) => setNoteComment(value || '')}
      />
      {updateInProgress && <ProgressIndicator label="Update in progress" />}
      <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          disabled={!disableSave}
          text="Save"
          onClick={onNoteUpdate}
        />
        <DefaultButton text="Cancel" onClick={onCancel} />
      </Stack>
    </Stack>
  );
};
