import {
  IColumn,
  IDetailsRowBaseProps,
  IDetailsRowProps,
  IRenderFunction,
  IconButton,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { useStyles } from './index.styles';

import { NetworkStatus, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { InfiniteList } from 'common/components/InfiniteList';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { PdfViewModal } from 'common/components/PdfViewModal';
import { ColumnData } from 'common/components/SearchBar';
import { TransactionSection } from 'common/components/TransactionSection';
import {
  AppliedEntityDocuments,
  AppliedEntityDocumentsVariables,
  AppliedEntityDocuments_appliedEntityDocuments_nodes,
} from 'common/graphql/__generated__/AppliedEntityDocuments';
import { AppliedEntityDocumentsOrderBy } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection } from 'common/utils/commonTypes';
import { dateFormat } from 'common/utils/dateFormats';
import {
  GetEntityDocument,
  GetEntityDocument_entityDocument,
} from '../../__generated__/GetEntityDocument';
import { PdfViewerModal } from './PdfViewerModal';
import { RetireBalance } from './RetireBalance';
import { columns } from './columns.data';
import { toOrderByVariable } from './utils';

interface TrackingModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onRetireCallback?: () => void;
  documentData: GetEntityDocument | undefined;
  entityDocument: GetEntityDocument_entityDocument;
  documentFileId: string;
}

export type InvoiceBatchRow =
  AppliedEntityDocuments_appliedEntityDocuments_nodes;

type TrackingTransactionModal = TrackingModalProps;
const APPLIED_ENTITY_DOCUMENTS = loader(
  '../../../../../../common/graphql/AppliedEntityDocuments.graphql'
);
export const TrackingTransactionModal: React.FC<TrackingTransactionModal> = ({
  onClose,
  documentData,
  entityDocument,
  isOpen,
  documentFileId,
  onRetireCallback,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [activeRow, setActiveRow] = useState<string | undefined>();
  const selectedDocument = entityDocument;
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);

  const {
    loading: entityDocumentLoading,
    data: entityDocuments,
    networkStatus,
    refetch,
  } = useQuery<AppliedEntityDocuments, AppliedEntityDocumentsVariables>(
    APPLIED_ENTITY_DOCUMENTS,
    {
      variables: {
        id: documentFileId,
        orderBy: [
          AppliedEntityDocumentsOrderBy._DOCUMENT_FILE_ID_ASC,
          AppliedEntityDocumentsOrderBy._CREATED_DATE_ASC,
        ],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const refetching =
    entityDocumentLoading && networkStatus !== NetworkStatus.fetchMore;

  let transformedData = refetching
    ? undefined
    : entityDocuments?.appliedEntityDocuments?.nodes;

  const _renderItemColumn = (
    item: InvoiceBatchRow | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (!item || !column) return undefined;

    const fieldContent = item[column.fieldName as keyof InvoiceBatchRow] as
      | string
      | null;
    const validate = activeRow === item.id && activeRow !== undefined;

    switch (column.key) {
      case 'controlTotalAmount':
      case 'openingBalance':
      case 'appliedAmount':
      case 'distributedAmount':
      case 'differenceAmount':
      case 'retiredAmount':
      case 'overageAmount':
      case 'remainingBalance':
        return <AmountViewField value={fieldContent!} />;
      case '_createdDate':
        return (
          <Stack verticalAlign="center">
            <Text>{dateFormat(item._createdDate!)}</Text>
          </Stack>
        );
      case 'accountingStampDate':
      case 'invoiceDate':
        if (fieldContent)
          return (
            <Stack verticalAlign="center">
              <Text className={styles.contentColumnAlignRight}>
                {dateFormat(fieldContent!)}
              </Text>
            </Stack>
          );
        else return null;
      case 'expand':
        return (
          <IconButton
            iconProps={{
              iconName: !validate ? 'ChevronRightMed' : 'ChevronDownMed',
            }}
            onClick={() => {
              if (validate) {
                setActiveRow(undefined);
              } else setActiveRow(item.id);
            }}
          />
        );
      default:
        return (
          <Stack verticalAlign="center">
            <Text>{fieldContent}</Text>
          </Stack>
        );
    }
  };
  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const value = {
        ...entityDocuments?.appliedEntityDocuments?.aggregates?.sum,
      };

      if (entityDocumentLoading) {
        return null;
      } else {
        switch (column?.key) {
          case 'controlTotalAmount':
            return <AmountView value={value.controlTotalAmount!} />;
          case 'openingBalance':
            return (
              !entityDocumentLoading && (
                <AmountView value={selectedDocument?.indexAmount!} />
              )
            );
          case 'appliedAmount':
            return <AmountView value={value.appliedAmount!} />;
          case 'retiredAmount':
            return <AmountView value={value.retiredAmount!} />;
          case 'overageAmount':
            return <AmountView value={value.overageAmount!} />;
          case 'remainingBalance':
            return (
              !entityDocumentLoading && (
                <AmountView
                  value={
                    selectedDocument?.documentAppliedAmounts?.remainingTotal!
                  }
                />
              )
            );
        }
      }
    };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        await refetch({
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const item: InvoiceBatchRow = { ...props.item };
    const newProps: IDetailsRowProps | undefined = props
      ? { ...props, className: styles.row }
      : undefined;

    return (
      <>
        {defaultRender!(newProps)}
        {activeRow === item.id && activeRow !== undefined && (
          <TransactionSection invoiceId={item.entityId!} />
        )}
      </>
    );
  };

  return (
    <ModalWrapper isOpen={isOpen} isBlocking>
      <Stack
        className={
          activeRow !== undefined ? styles.containerExpanded : styles.container
        }
      >
        <Sticky stickyPosition={StickyPositionType.Header}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={styles.stickyInsideStack}
          >
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
              style={{ flex: 1.5 }}
            >
              <Text variant="large">
                {selectedDocument?.documentTypes?.documentType}:
              </Text>
              <Text variant="large" className={commonStyles.colorThemePrimary}>
                {selectedDocument?.indexReferenceNumber}
              </Text>
              <Text variant="large" className={commonStyles.colorThemePrimary}>
                {selectedDocument?.indexName}
              </Text>
              <Text variant="large">{selectedDocument?.indexDescription}</Text>
            </Stack>

            <Stack
              horizontal
              tokens={{ childrenGap: 20 }}
              verticalAlign="center"
              style={{
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 10 }}
                >
                  <Text
                    variant="large"
                    className={commonStyles.colorThemePrimary}
                  >
                    Total :
                  </Text>
                  <AmountTextView
                    className={styles.contentColumnAlignRight}
                    variant="large"
                    value={selectedDocument?.indexAmount!}
                  />
                </Stack>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 10 }}
                >
                  <Text
                    variant="large"
                    className={commonStyles.colorThemePrimary}
                  >
                    Remaining :
                  </Text>
                  <AmountTextView
                    className={styles.contentColumnAlignRight}
                    variant="large"
                    value={
                      selectedDocument?.documentAppliedAmounts?.remainingTotal!
                    }
                  />
                </Stack>
                <Text
                  variant="xLarge"
                  className={commonStyles.colorThemePrimary}
                >
                  {selectedDocument?.currency?.isoCode}
                </Text>
              </Stack>
              <Stack
                verticalAlign="start"
                horizontal
                tokens={{ childrenGap: 10 }}
              >
                <PdfViewModal
                  visible={
                    transformedData ? transformedData?.length > 0 : false
                  }
                  toolTipContent={'Print Tracking'}
                >
                  <PdfViewerModal
                    selectedDocument={selectedDocument!}
                    entityDocuments={
                      entityDocuments?.appliedEntityDocuments?.nodes
                    }
                    aggregates={
                      entityDocuments?.appliedEntityDocuments?.aggregates!
                    }
                  />
                </PdfViewModal>
              </Stack>
              <CloseButton onClick={onClose!} />
            </Stack>
          </Stack>
        </Sticky>
        <InfiniteList
          loading={entityDocumentLoading}
          items={transformedData}
          compact
          hasNextPage={
            entityDocuments?.appliedEntityDocuments?.pageInfo.hasNextPage
          }
          onColumnHeaderClick={_onColumnClick}
          selectionMode={SelectionMode.none}
          showFooter={true}
          onRenderRow={onRenderRow}
          columns={gridColumns}
          onRenderItemColumn={_renderItemColumn}
          onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
          isSelectedOnFocus={false}
          disableFullRowSelect={true}
        />
      </Stack>

      <Stack horizontalAlign="end" tokens={{ padding: '20px 20px' }}>
        <RetireBalance
          documentData={documentData}
          onMutationSuccess={() => {
            onRetireCallback?.();
          }}
        />
      </Stack>
    </ModalWrapper>
  );
};

interface AmountViewProps {
  value: string;
}

const AmountView: React.FC<AmountViewProps> = ({ value }) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <AmountTextView
      variant="medium"
      className={clsx(
        styles.amountStack,
        styles.contentColumnAlignRight,
        commonStyles.colorThemePrimary
      )}
      value={value!}
    />
  );
};

const AmountViewField: React.FC<AmountViewProps> = ({ value }) => {
  const styles = useStyles();
  return (
    <Stack verticalAlign="center" className={styles.onrenderColumnStack}>
      <AmountTextView
        className={styles.contentColumnAlignRight}
        variant="medium"
        value={value!}
      />
    </Stack>
  );
};
