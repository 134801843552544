import {
  Callout,
  DirectionalHint,
  IconButton,
  Stack,
  Text,
  makeStyles,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  calloutContainer: {
    backgroundColor: theme.palette.neutralLighterAlt,
    width: 350,
  },
  iconButton: {
    paddingLeft: 0,
  },
}));

export const NotesHint = () => {
  const styles = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  const buttonId = useId('note-hint');

  return (
    <>
      <IconButton
        id={buttonId}
        iconProps={{ iconName: 'Info' }}
        onMouseEnter={() => setIsHidden(false)}
        onMouseLeave={() => setIsHidden(true)}
        className={styles.iconButton}
      />
      <Callout
        hidden={isHidden}
        target={`#${buttonId}`}
        onDismiss={() => setIsHidden(true)}
        directionalHint={DirectionalHint.topRightEdge}
      >
        <Stack className={styles.calloutContainer} tokens={{ padding: 10 }}>
          <Text>
            Please provide an explanation if an exception is needed or
            information on price between refundable and non-refundable.
          </Text>
        </Stack>
      </Callout>
    </>
  );
};
