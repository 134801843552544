import {
  Modal,
  PivotItem,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { StampOptions } from 'common/components/StamperView';
import { HeaderComponent } from '../components/HeaderComponent';
import { ApprovalState } from 'common/types/globalTypes';
import { InvoiceDetail } from './InvoiceDetail';
import { HierarchyData } from 'common/components/TransactionSection/TransactionDetail/HierarchyData';
import { SignatureView } from 'common/components/Signatures';
import { PivotComponent, PivotItemsArray } from '../components/PivotComponent';
import { GetDocumentDetails_fileCabinetHistoryTransaction_nodes } from 'documents/fileCabinet/folder/view/__generated__/GetDocumentDetails';
import {
  InvoiceModalDetails,
  InvoiceModalDetailsVariables,
} from './__generated__/InvoiceModalDetails';

const INVOICE_DETAILS = loader('./InvoiceModalDetails.graphql');
interface InvoiceModalProps {
  data: GetDocumentDetails_fileCabinetHistoryTransaction_nodes;
  onDismiss: () => void;
}

enum HeaderText {
  DETAILS = 'viewDetails',
  HIERARCHY = 'viewHierarchy',
  SIGNATURE = 'viewSignature',
}

const pivotItemsArray: PivotItemsArray[] = [
  {
    itemKey: HeaderText.DETAILS,
    headerText: 'Details',
  },
  {
    itemKey: HeaderText.HIERARCHY,
    headerText: 'Hierarchy',
  },
  {
    itemKey: HeaderText.SIGNATURE,
    headerText: 'Signature',
  },
];

export const InvoiceModal: React.FC<InvoiceModalProps> = ({
  data,
  onDismiss,
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = useState<string>(HeaderText.DETAILS);

  const { entityId, linkType } = { ...data };

  const { data: invoiceDetailsData, loading } = useQuery<
    InvoiceModalDetails,
    InvoiceModalDetailsVariables
  >(INVOICE_DETAILS, {
    variables: {
      id: entityId!,
    },
    skip: !entityId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const onLinkClick = (item?: PivotItem) => {
    if (item?.props.itemKey) setSelectedKey(item.props.itemKey);
  };

  const {
    transactionType,
    statusType,
    _documentPackageId,
    _accountingStampDate,
    _accountingStampTransactionReference,
    _accountingStampUserName,
    _isAccountingEntryStampedComplete,
    _isTransactionCancelled,
    approvalHistorySignaturesByEntityId,
  } = { ...invoiceDetailsData?.invoice };

  const stampData: StampOptions = {
    _accountingStampDate,
    _accountingStampTransactionReference,
    _accountingStampUserName,
    _isAccountingEntryStampedComplete,
    _isTransactionCancelled,
  };

  const isApproved = statusType?.approvalState === ApprovalState.APPROVED;

  const getSelectedSection = (key: string) => {
    switch (key) {
      case HeaderText.DETAILS:
        return (
          <Stack>
            {
              invoiceDetailsData?.invoice &&
              <InvoiceDetail
                invoice={invoiceDetailsData.invoice}
                loading={loading}
              />
            }
          </Stack>
        );
      case HeaderText.HIERARCHY:
        return <HierarchyData entityId={entityId!} />;
      case HeaderText.SIGNATURE:
        return (
          <Stack tokens={{ padding: 20 }}>
            {!!approvalHistorySignaturesByEntityId?.nodes.length && (
              <SignatureView
                signatureData={approvalHistorySignaturesByEntityId?.nodes}
              />
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen
      containerClassName={styles.modalContainer}
      onDismiss={onDismiss}
      isBlocking
    >
      <Stack className={styles.container}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <Stack
              className={styles.stickyInsideStack}
              tokens={{ childrenGap: 10 }}
            >
              <HeaderComponent
                entityId={entityId!}
                entityType={linkType!}
                heading={transactionType?.transactionType || ''}
                isApproved={isApproved}
                documentPackageId={_documentPackageId}
                stampData={stampData}
                onDismiss={onDismiss}
              />
              <PivotComponent
                pivotItems={pivotItemsArray}
                selectedKey={selectedKey}
                onLinkClick={onLinkClick}
              />
            </Stack>
          </Sticky>
          {getSelectedSection(selectedKey)}
        </ScrollablePane>
      </Stack>
    </Modal>
  );
};
