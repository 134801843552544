import { Stack, makeStyles } from '@fluentui/react';
import React from 'react';
import { Hierarchy } from './Hierarchy';

interface HierarchyComponentProps {
  entityId: string | undefined
}

export const useStyles = makeStyles((theme) => ({
  hierarchyContainer: {
    backgroundColor: theme.palette.white,
  },
}));

export const HierarchyComponent: React.FC<HierarchyComponentProps> = ({
  entityId
}) => {
  const styles = useStyles();
  return (
    <Stack className={styles.hierarchyContainer}>
      <Hierarchy
        entityId={entityId!}
      />
    </Stack>
  )
}
