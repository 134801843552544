import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  headerStyle2: {
    zIndex: 10,
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.33)`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  formMainContainer: {
    margin: '0px 0px 30px 0px',
  },
  inputsContainer: {
    padding: `20px 20px 30px`,
  },
  paddingRL10: {
    padding: `0px 10px`,
  },
  HW100: {
    height: 100,
    width: 100,
  },
}));
