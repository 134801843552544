import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  row: {
    height: 25,
  },
  expenditureType: {
    marginLeft: 0,
  },
  rightAlign: {
    textAlign: 'right',
  },
  centerAlign: {
    textAlign: 'center',
  },
}));
