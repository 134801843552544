import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
  },
  content: {
    position: 'relative',
    borderTop: `1px solid ${theme.palette.neutralLight}`,
    flexBasis: 0,
  },
}));
