import { dataExtractModelOptionsType } from '.';

export const getFileTypesList = (
  selectedOption: dataExtractModelOptionsType
) => {
  const value = Object.entries(selectedOption)?.reduce((prev, [key, val]) => {
    if (val) {
      if (key === 'pdfAllowed') return [...prev, 'application/pdf'];
      if (key === 'imageAllowed')
        return [
          ...prev,
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/heif',
          'image/webp',
          'image/tiff',
        ];
      if (key === 'textAllowed') return [...prev, 'text/plain'];
      return [...prev];
    }
    return [...prev];
  }, [] as string[]);
  return value;
};
