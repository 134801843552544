import { makeStyles, FontWeights } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  dialogStyles: {
    alignItems: 'self-start',
    padding: 25,
    paddingBottom: 0,
  },
  container: {
    width: 600,
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
  checkBoxContainer: {
    marginTop: 30,
    alignItems: 'center',
  },
  tagsContent: {
    margin: '0px -20px',
    padding: 20,
  },
  tagsSection: {
    paddingTop: 10,
  },
  iconButtonColor: {
    color: theme.palette.neutralDark,
  },
  selectTagsLabel: {
    marginTop: 6,
    marginLeft: '6px !important',
    fontWeight: FontWeights.bold,
  },
}));
