import {
  IconButton,
  Stack,
  Text,
  TooltipHost,
  makeStyles,
} from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import { EntityType } from 'common/types/utility';
import React, { useState } from 'react';
import { TravelerDetails_traveler } from '../__generated__/TravelerDetails';
import { PreviewHierarchyModal } from './PreviewHierarchyModal';
import { useToasts } from 'react-toast-notifications';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  TravelerDelete,
  TravelerDeleteVariables,
} from 'common/graphql/__generated__/TravelerDelete';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
const TRAVELER_DELETE = loader(
  './../../../../../../../../../../../../common/graphql/TravelerDelete.graphql'
);

const useStyles = makeStyles((theme) => ({
  stickyInsideStack: {
    alignItems: 'self-start',
    padding: '25px 20px 20px',
    cursor: 'move',
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));

interface HeaderProps {
  isEdit: boolean;
  loading: boolean;
  traveler: TravelerDetails_traveler | null | undefined;
  isDirty: boolean;
  isSubmitting: boolean;
  setOpen: (open: boolean) => void;
  onTravelerDelete: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  isEdit,
  loading,
  traveler,
  isDirty,
  isSubmitting,
  setOpen,
  onTravelerDelete,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const [showHierarchyModal, setShowHierarchyModal] = useState(false);

  const { id, _isApprovalRevocable, statusType, _isDeletable, _rowTimestamp } =
    { ...traveler };

  const heading: string = !isEdit ? 'Create Traveler' : 'Edit Traveler';
  const supplierStatus =
    isEdit && traveler ? `(${statusType?.statusType})` : '(Draft)';

  const [deleteSupplier] = useMutation<TravelerDelete, TravelerDeleteVariables>(
    TRAVELER_DELETE,
    {
      errorPolicy: 'all',
    }
  );

  const deleteRecord = async () => {
    const { errors } = await deleteSupplier({
      variables: {
        input: {
          entityDelete: [
            {
              id: id!,
              rowTimestamp: _rowTimestamp!,
            },
          ],
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      onTravelerDelete();
      addToast('Record deleted successfully', {
        appearance: 'success',
      });
      setOpen(false);
    }
  };

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      className={styles.stickyInsideStack}
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      <Stack
        horizontal
        tokens={{
          childrenGap: 10,
        }}
      >
        <Text variant="xLarge">{heading}</Text>
        <UnsavedIndicator
          visible={isEdit && !loading && isDirty && !isSubmitting}
        />
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 10,
        }}
        verticalAlign="center"
      >
        {isEdit && id && _isApprovalRevocable && (
          <>
            <TooltipHost content={'Preview Approval Hierarchy'}>
              <IconButton
                iconProps={{ iconName: 'Org' }}
                ariaLabel="Org"
                onClick={() => setShowHierarchyModal(true)}
              />
            </TooltipHost>
            {showHierarchyModal && (
              <PreviewHierarchyModal
                entityId={id}
                onDismiss={() => setShowHierarchyModal(false)}
              />
            )}
          </>
        )}
        <Text variant="mediumPlus" className={commonStyles.colorThemePrimary}>
          {supplierStatus}
        </Text>
        <ActionMessageModal
          visible={isEdit}
          entityType={EntityType.Traveler}
          disabled={!_isDeletable}
          onConfirm={deleteRecord}
        />
        <CloseButton onClick={() => setOpen(false)} />
      </Stack>
    </Stack>
  );
};
