import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles(() => ({
  addressListBtn: {
    width: 120,
    height: 40,
  },
  fieldsContainer: {
    marginTop: 20,
  },
  phone: {
    width: 275,
  },
  email: {
    width: 275,
  },
}));
