import { makeStyles } from '@fluentui/react';
import { DRAWER_CLOSE_WIDTH, DRAWER_OPEN_WIDTH } from 'common/constants';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 13px',
  },
  logoContainer: {
    height: 24,
    marginBottom: 6,
  },
  logoContainerExpanded: {
    width: DRAWER_OPEN_WIDTH,
  },
  logoContainerCollapsed: {
    width: DRAWER_CLOSE_WIDTH,
  },
  logo: {
    color: theme.palette.neutralPrimary,
    lineHeight: 0,
    textDecoration: 'none',
  },
  logoText: {
    color: theme.palette.neutralPrimary,
    paddingLeft: 6,
  },
  search: {
    width: 200,
  },
  toggle: {
    margin: 0,
    paddingLeft: 24,
  },
  icon: {
    fontSize: 18,
    color: theme.palette.neutralSecondaryAlt,
  },
  iconButton: {
    minWidth: 48,
    ':hover': { backgroundColor: theme.palette.neutralLighterAlt },
  },
}));
