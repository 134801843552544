import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 270,
    ...commonColumnProps,
  },
  {
    key: 'referenceNumber',
    name: 'Batch ID',
    fieldName: 'referenceNumber',
    minWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'postingDate',
    name: 'Posting Date',
    fieldName: 'postingDate',
    minWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'periodYear',
    name: 'Period',
    fieldName: 'periodYear',
    minWidth: 100,
    ...commonColumnProps,
  },
  {
    key: 'weekNumber',
    name: 'Week#',
    fieldName: 'weekNumber',
    minWidth: 70,
    ...commonColumnProps,
  },
  {
    key: 'currencyData',
    name: 'Currency',
    fieldName: 'currencyData',
    minWidth: 70,
    ...commonColumnProps,
  },
  {
    key: 'controlTotalAmount',
    name: 'Batch Total',
    fieldName: 'controlTotalAmount',
    minWidth: 120,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: '_batchTransactionTotal',
    name: 'Transaction Total',
    fieldName: '_batchTransactionTotal',
    minWidth: 120,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: '_batchTransactionCount',
    name: 'Transactions',
    fieldName: '_batchTransactionCount',
    minWidth: 120,
    ...commonColumnProps,
    styles: rightAlignHeaderStyle,
  },
  {
    key: '_createdByUserName',
    name: 'Created by',
    fieldName: '_createdByUserName',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
    isVisible: false,
  },
];
