import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
  IColumn,
  IDetailsRowProps,
  IRenderFunction,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { FilterArrayType } from 'common/components/Filters';
import { InfiniteList } from 'common/components/InfiniteList';
import { RequestMessageModal } from 'common/components/RequestMessageModal';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalFilter,
  ApprovalRequestInput,
  ApprovalsOrderBy,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  ApprovalSetupApprovalCreate,
  ApprovalSetupApprovalCreateVariables,
} from '../view/__generated__/ApprovalSetupApprovalCreate';
import { ApprovalSetupSearchBar } from './ApprovalSetupSearchBar';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { toOrderByVariable, toPaymentFilterVariable } from './utils';
import {
  ApprovalDelete,
  ApprovalDeleteVariables,
} from './__generated__/ApprovalDelete';
import {
  Approvals,
  ApprovalsVariables,
  Approvals_approvals_nodes,
} from './__generated__/Approvals';
import { StatusIcon } from 'common/components/StatusIcon';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';
import clsx from 'clsx';
const APPROVAL_SETUP = loader('./Approvals.graphql');
const DELETE_APPROVAL = loader('./ApprovalDelete.graphql');
const APPROVAL_SETUP_APPROVAL_CREATE = loader(
  '../view/ApprovalSetupApprovalCreate.graphql'
);

type ApprovalSetupItems = Approvals_approvals_nodes;
export const ApprovalSetup: React.FC = () => {
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const styles = useStyles();
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();
  const [selectedList, setSelectedList] = useState<ApprovalSetupItems[]>([]);
  const [requestedIndices, setRequestedIndices] = useState<number[]>([]);

  const [
    fetchApprovalSetup,
    {
      loading: approvalsLoading,
      data: approvalSetupData,
      networkStatus,
      fetchMore,
      variables,
    },
  ] = useLazyQuery<Approvals, ApprovalsVariables>(APPROVAL_SETUP, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [
        ApprovalsOrderBy.APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC,
        ApprovalsOrderBy.PRIORITY_NUMBER_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [deleteApproval] = useMutation<ApprovalDelete, ApprovalDeleteVariables>(
    DELETE_APPROVAL,
    { errorPolicy: 'all' }
  );

  const [
    approvalSetupApprovalCreate,
    { loading: approvalSetupApprovalCreateLoading },
  ] = useMutation<
    ApprovalSetupApprovalCreate,
    ApprovalSetupApprovalCreateVariables
  >(APPROVAL_SETUP_APPROVAL_CREATE, { errorPolicy: 'all' });

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const _renderItemColumn = (
    item: ApprovalSetupItems | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item?.[
        column?.fieldName as keyof ApprovalSetupItems
      ] as string;
      const _isUrgentApproval = !Boolean(item._urgencyLevel);
      switch (column?.key) {
        case '_urgencyLevel':
          return (
            <RedBoxIndicator
              _isUrgentApproval={_isUrgentApproval}
              itemId={item?.id}
            />
          );
        case 'approvalType':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <ActiveLink
                to={`/account-management/approvals/approval/${item?.id}`}
              >
                {item?.approvalType?.approvalType}
              </ActiveLink>
            </Stack>
          );
        case 'priorityNumber':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignRight}>
                {item.priorityNumber}
              </Text>
            </Stack>
          );
        case 'isAddApproversEligible':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignCenter}>
                {item.isAddApproversEligible ? 'Yes' : 'No'}
              </Text>
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontal
              horizontalAlign="space-between"
            >
              <Stack>
                <Text className={styles.statusType}>
                  {item?.statusType?.statusType!}
                </Text>
              </Stack>

              <StatusIcon
                approvalData={item}
                approval={false}
                iconType={item?.statusType!}
              />
            </Stack>
          );
        case 'phaseName':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item?.approvalPhaseType?.phaseName}</Text>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>
                {fieldContent === null || !fieldContent ? '' : fieldContent}
              </Text>
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const item: ApprovalSetupItems = { ...props.item };
    const { _urgencyLevel } = { ...item };
    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          className: clsx(
            styles.rowBaseStyle,
            _urgencyLevel === 0 ? commonStyles.urgentRow : styles.rowBaseStyle
          ),
        }
      : undefined;
    return <>{defaultRender?.(newProps)}</>;
  };
  // Handle Search query
  const handleSearch = (showMore: boolean) => {
    if (!showMore) {
      setSelectedList([]);
    }
    const variables: ApprovalsVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? approvalSetupData?.approvals?.pageInfo.endCursor
        : undefined,
      filter: searchFilters?.length
        ? ({
            and: toPaymentFilterVariable(searchFilters),
          } as ApprovalFilter)
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
    };

    if (showMore) fetchMore?.({ variables });
    else fetchApprovalSetup({ variables: variables });
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
  ]);

  useEffect(() => {
    handleSearchMemo(false);
  }, [handleSearchMemo]);

  const _onConfirm = async () => {
    const selectedData: ApprovalSetupItems[] = selectedList;
    const toDelete: ApprovalSetupItems[] = selectedData.map((_, index) => {
      return selectedData![index];
    });
    let deletableDocs = toDelete?.filter((item) => item._isDeletable !== false);
    if (deletableDocs.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableDocs.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      const { errors } = await deleteApproval({
        variables: {
          input: {
            entityDelete: deleteInputArray,
          },
        },
        update: (cache, { data }) => {
          const { nodes, pageInfo, totalCount } = {
            ...approvalSetupData?.approvals,
          };

          const deletedIds = data?.approvalDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );
          if (deletedIds) {
            const filteredList = nodes?.filter(
              (appr) => deletedIds.indexOf(appr.id) === -1
            );
            const newData: Approvals = {
              approvals: {
                pageInfo: pageInfo!,
                totalCount: totalCount! - deletedIds.length,
                nodes: filteredList!,
              },
            };
            cache.writeQuery<Approvals, ApprovalsVariables>({
              query: APPROVAL_SETUP,
              variables,
              data: newData,
            });
          }
        },
      });

      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Record deleted Successfully', {
          appearance: 'success',
        });
      }
    }
  };

  const showRequestApproval = approvalSetupData?.approvals?.nodes?.some(
    (approval) => approval._isStagedApprovalRequest
  );

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Approval Setup</Text>
          <Stack horizontal>
            {showRequestApproval && (
              <RequestMessageModal
                entityType={EntityType.ApprovalSetup}
                action={EntityAction.Request}
                disabled={
                  !selectedList.some(
                    (selected) => selected._isStagedApprovalRequest
                  )
                }
                visible={selectedList.length > 0}
                multiple={{
                  validCount: selectedList.filter(
                    (selected) => selected._isStagedApprovalRequest
                  ).length,
                  invalidNames: selectedList
                    .filter((selected) => !selected._isStagedApprovalRequest!)
                    .map((cannotDelete) => cannotDelete.name!),
                }}
                buttonProps={{ text: 'Request Approval' }}
                onConfirm={async (
                  requestComment: string | null,
                  requiredDate: string | null
                ) => {
                  const inputVariables: ApprovalRequestInput[] = selectedList
                    .filter((item) => item._isStagedApprovalRequest)
                    .map((requestInput) => ({
                      entityId: requestInput.id!,
                      rowTimestamp: requestInput._rowTimestamp!,
                      comments: requestComment,
                      requiredDate: requiredDate ? requiredDate : undefined,
                    }));
                  if (inputVariables.length > 0) {
                    const { errors } = await approvalSetupApprovalCreate({
                      variables: {
                        input: {
                          entityApproval: inputVariables,
                        },
                      },
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: APPROVAL_SETUP,
                          variables: variables,
                        },
                      ],
                    });
                    if (errors?.length)
                      addToast(errors[0].message, {
                        appearance: 'error',
                      });
                    else {
                      addToast('Request sent for approvals', {
                        appearance: 'success',
                      });
                      setRequestedIndices([]);
                    }
                  }
                }}
                isLoading={approvalSetupApprovalCreateLoading}
              />
            )}
            <ActionMessageModal
              entityType={EntityType.ApprovalConfig}
              disabled={
                !selectedList.some(
                  (selected) => selected._isDeletable || selected.isDraft
                )
              }
              visible={selectedList.length > 0}
              multiple={{
                validCount: selectedList.filter(
                  (selected) => selected._isDeletable || selected.isDraft
                ).length,
                invalidNames: selectedList
                  .filter(
                    (selected) => !selected._isDeletable || !selected.isDraft
                  )
                  .map((cannotDelete) => cannotDelete.name),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              onClick={() =>
                history.push('/account-management/approvals/approval')
              }
              iconProps={{
                iconName: 'Add',
              }}
              text="New Approval Setup"
            />
          </Stack>
        </Stack>
        <Stack>
          <ApprovalSetupSearchBar
            columns={gridColumns}
            onRefresh={() => handleSearch(false)}
            onFilterChange={(filters) => {
              setSearchFilters(filters);
            }}
            onToggleVisibility={(columns) => {
              setGridColumns(columns);
            }}
          />
        </Stack>
      </Stack>
      <InfiniteList<ApprovalSetupItems>
        loading={approvalsLoading}
        hasNextPage={approvalSetupData?.approvals?.pageInfo?.hasNextPage}
        items={refetching ? undefined : approvalSetupData?.approvals?.nodes!}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onSelectionChanged={setSelectedList}
        requestedIndices={requestedIndices}
        onLoadMore={() => handleSearch(true)}
        onRenderRow={_onRenderRow}
      />
    </>
  );
};
