import { TravelPoliciesType } from '../../list';
import { TRAVEL_POLICY_VALUES } from './constants';
import { TravelPolicyValues } from './types';

interface DefaultValueProps {
  isNew: boolean;
  travelPolicyData: TravelPoliciesType | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, travelPolicyData } = { ...props };
  let defaultValues: TravelPolicyValues = TRAVEL_POLICY_VALUES;
  if (isNew) return defaultValues;
  if (travelPolicyData) {
    const {
      id,
      _rowTimestamp,
      _isDeletable,
      _isUpdatable,
      companyCurrency,
      travelPolicyAllowancesByTravelPolicyId,
      ...travelPolicyValues
    } = { ...travelPolicyData };
    defaultValues = {
      ...travelPolicyValues,
    };
  }
  return defaultValues as TravelPolicyValues;
};
