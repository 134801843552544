import { TABLE_ROWS } from 'common/constants';
import { DepartmentsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { filterOptionProps } from './Filters';
import { Departments, DepartmentsVariables } from './__generated__/Departments';

type OrderLookup = {
  [direction in OrderDirection]: DepartmentsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [DepartmentsOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [DepartmentsOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [DepartmentsOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [DepartmentsOrderBy.DESCRIPTION_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const defaultOrderBy = [
    DepartmentsOrderBy.NAME_ASC,
    DepartmentsOrderBy.PRIMARY_KEY_ASC,
  ];
  const orderByArray = [DepartmentsOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return defaultOrderBy;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const onHandleSearch = (
  showMore: boolean,
  sortOrderParam: SortOrder | undefined,
  departments: Departments | undefined
) => {
  const variables: DepartmentsVariables = {
    first: TABLE_ROWS,
    orderBy: toOrderByVariable(sortOrderParam),
    after: showMore ? departments?.departments?.pageInfo.endCursor : undefined,
  };
  return {
    queryVariables: variables,
  };
};

export const toFilterVariable = (filterOptions: filterOptionProps) =>
  filterOptions.filterTypes.map((filter) => {
    switch (filter.filterKey) {
      case 'name':
        return {
          [filter.filterKey]: {
            [filterOptions.startsWith
              ? 'startsWithInsensitive'
              : 'includesInsensitive']: filter.value,
          },
        };
      case 'description':
        return {
          [filter.filterKey]: {
            [filterOptions.startsWith
              ? 'startsWithInsensitive'
              : 'includesInsensitive']: filter.value,
          },
        };

      default:
        return undefined;
    }
  });
