import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px 25px 10px 10px',
    cursor: 'pointer',
  },
  countColor: {
    color: 'white',
  },
  pivotDisplayCount: {
    height: 25,
    alignSelf: 'center',
    borderRadius: 13,
    minWidth: 25,
    backgroundColor: theme.palette.themePrimary,
  },
  width10: {
    width: 10,
  },
  width5: {
    width: 5,
  },
}));
