import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  labelStop: {
    cursor: 'pointer',
  },
  badge: {
    width: 10,
    height: 10,
    borderRadius: 10,
    top: 0,
    right: 10,
    backgroundColor: theme.palette.orangeLighter,
    cursor: 'pointer',
  },
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: '0px 4px',
  },
  button: {
    width: 100,
    textDecoration: 'none',
    display: 'inline',
    backgroundColor: 'theme.palette.themePrimary',
    outline: 'none',
    cursor: 'pointer',
  },
  container: { marginTop: 80, minHeight: 400, minWidth: 1300 },
  text: {
    color: theme.palette.black,
    fontWeight: FontWeights.semibold,
  },
}));
