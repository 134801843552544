import { useQuery } from '@apollo/client';
import {
  Callout,
  ContextualMenu,
  DayOfWeek,
  DefaultButton,
  DirectionalHint,
  Icon,
  IconButton,
  IDragOptions,
  IDropdownOption,
  ITextFieldProps,
  Label,
  Modal,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { CloseButton } from 'common/components/Buttons';
import {
  FormHookCountryRegion,
  FormHookDatePicker,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { Countries } from 'common/graphql/__generated__/Countries';
import { loader } from 'graphql.macro';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  AvailableDeliveryAddresses,
  AvailableDeliveryAddresses_availableDeliveryAddresses_nodes,
} from './__generated__/AvailableDeliveryAddresses';
import { useStyles } from './index.styles';
import { PromoteDeliveryAddress } from './PromoteDeliveryAddress';
interface ShippingAddressModalProps {
  purchaseOrder: PurchaseOrder | undefined;
  setOpen: (open: boolean) => void;
}
const COUNTRIES = loader('../../../../../common/graphql/Countries.graphql');
const AVAILABLE_DELIVERY_ADDRESS = loader(
  './AvailableDeliveryAddresses.graphql'
);

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

export type SelectedDeliveryAddress =
  AvailableDeliveryAddresses_availableDeliveryAddresses_nodes;

export const ShippingAddressModal: React.FC<ShippingAddressModalProps> = ({
  purchaseOrder,
  setOpen,
}) => {
  const styles = useStyles();
  const { setValue, control } = useFormContext<PurchaseOrderValues>();
  const [disableInputs, setDisableInputs] = useState(false);

  const { _isPromoteAddressAllowed } = { ...purchaseOrder?.purchaseOrder };

  const watchDeliveryAddressId = useWatch({
    name: 'deliveryAddressId',
    control,
  });

  const calloutId = useId(`address`);
  const [isVisible, setIsVisible] = useState(false);
  const [addressId, setAddressId] = useState<string | null>(
    watchDeliveryAddressId
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { data: countries } = useQuery<Countries>(COUNTRIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const {
    data: availableDeliveryAddressesData,
    refetch: refetchAvailableDeliveryAddresses,
  } = useQuery<AvailableDeliveryAddresses>(AVAILABLE_DELIVERY_ADDRESS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const countryOptions: IDropdownOption[] =
    countries?.countries?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.country,
    })) || [];

  const { availableDeliveryAddresses } = { ...availableDeliveryAddressesData };

  useEffect(() => {
    if (watchDeliveryAddressId) setDisableInputs(true);
  }, [watchDeliveryAddressId]);

  const onClearInputs = (clearAll: boolean) => {
    setDisableInputs(false);
    setAddressId(null);
    setValue('deliveryAddressId', null, { shouldDirty: true });
    if (clearAll) {
      setValue('scheduledDeliveryDate', null);
      setValue('deliveryNotes', null);
      setValue('isDeliveryTracked', null);
    }
    setValue('deliveryAddressLine1', null, { shouldDirty: true });
    setValue('deliveryAddressLine2', null);
    setValue('deliveryCityName', null);
    setValue('deliveryCountryId', null);
    setValue('deliveryStateRegionId', null);
    setValue('deliveryPostalCode', null);
    setValue('deliveryTelephoneNumber', null);
    setValue('deliveryEmailAddress', null);
  };

  const onSelect = (param: SelectedDeliveryAddress | null) => {
    if (param) {
      setDisableInputs(true);
      setValue('deliveryAddressId', param.id, { shouldDirty: true });
      setValue('deliveryAddressLine1', param.addressLine1 || null);
      setValue('deliveryAddressLine2', param.addressLine2 || null);
      setValue('deliveryCityName', param.cityName || null);
      setValue('deliveryCountryId', param.countryId || null);
      setValue('deliveryStateRegionId', param.stateRegionId || null);
      setValue('deliveryPostalCode', param.postalCode || null);
      setValue('deliveryTelephoneNumber', param.telephoneNumber || null);
      setValue('deliveryEmailAddress', param.emailAddress || null);
    } else {
      onClearInputs(false);
    }
  };

  const onCancel = () => {
    setIsVisible(false);
  };

  const addressSelected = availableDeliveryAddresses?.nodes.find(
    (data) => data?.id === addressId
  );

  const onRenderLabel = (props: ITextFieldProps | undefined): JSX.Element => {
    return (
      <>
        {isVisible && (
          <Callout
            gapSpace={0}
            target={`#${calloutId}`}
            directionalHint={DirectionalHint.leftTopEdge}
            setInitialFocus
            onDismiss={() => setIsVisible(false)}
          >
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{ padding: 20 }}
              className={styles.addressContainer}
            >
              <Text variant={'xLarge'}>Available Addresses</Text>
              <CloseButton onClick={onCancel} />
            </Stack>
            <Stack tokens={{ padding: '0px 0px 15px 0px' }}>
              {availableDeliveryAddresses?.nodes.map((item, index) => {
                const { addressAlias, addressLine1 } = { ...item };
                const addressLine = addressLine1 ? ` / ${addressLine1}` : null;
                const addressText = `${addressAlias}${addressLine}`;
                const isSelected = addressSelected?.id === item?.id;

                return (
                  <Stack
                    horizontal
                    verticalAlign="center"
                    onClick={() => {
                      setAddressId(isSelected ? null : item?.id!);
                      onSelect(isSelected ? null : item);
                    }}
                    tokens={{ childrenGap: 10 }}
                    className={clsx({
                      [styles.item]: true,
                      [styles.itemHover]: true,
                      [styles.itemSelected]: isSelected,
                    })}
                    key={index}
                  >
                    {isSelected ? (
                      <Icon iconName="accept" />
                    ) : (
                      <Stack className={styles.addressStack} />
                    )}
                    <Text>{addressText}</Text>
                  </Stack>
                );
              })}
            </Stack>
          </Callout>
        )}
        <Stack horizontal verticalAlign="center">
          <IconButton
            iconProps={{ iconName: 'Info' }}
            onClick={() => setIsVisible(true)}
            id={calloutId}
          />
          <Label>{props?.label}</Label>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Modal
        isOpen
        isBlocking
        onDismiss={() => setOpen(false)}
        dragOptions={dragOptions}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          className={styles.headerContainer}
        >
          <Text variant="xLarge">Delivery</Text>
          <CloseButton onClick={() => setOpen(false)} />
        </Stack>
        <Separator />
        <Stack
          tokens={{
            childrenGap: 10,
          }}
          className={styles.container}
        >
          <Stack
            tokens={{
              childrenGap: 10,
              padding: '0px 0px 20px 0px',
            }}
          >
            <Stack
              verticalAlign="center"
              tokens={{
                padding: '0px 20px',
              }}
            >
              <FormHookTextField
                name="deliveryAddressLine1"
                label="Delivery Address"
                placeholder="Ship Address"
                disabled={disableInputs}
                onRenderLabel={onRenderLabel}
              />
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{
                childrenGap: 20,
                padding: '0px 20px',
              }}
            >
              <Stack.Item grow={1}>
                <FormHookTextField
                  name="deliveryAddressLine2"
                  label="Suit, Building, Unit"
                  placeholder="Suit, Building, Unit"
                  disabled={disableInputs}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <FormHookTextField
                  name="deliveryCityName"
                  label="City"
                  placeholder="City"
                  disabled={disableInputs}
                />
              </Stack.Item>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{
                childrenGap: 20,
                padding: '0px 20px',
              }}
            >
              <Stack.Item grow={2}>
                <FormHookCountryRegion
                  countries={countryOptions}
                  countryName={`deliveryCountryId`}
                  regionName={`deliveryStateRegionId`}
                  CountryAutocompleteProps={{
                    label: 'Country',
                    errorMessage: '',
                  }}
                  StateRegionAutocompleteProps={{
                    label: 'State / Region / Province',
                    errorMessage: '',
                  }}
                  disabled={disableInputs}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <FormHookTextField
                  name="deliveryPostalCode"
                  label="Zip Code"
                  placeholder="Zip Code"
                  disabled={disableInputs}
                />
              </Stack.Item>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{
                childrenGap: 20,
                padding: '0px 20px',
              }}
              className={styles.mT20}
            >
              <Stack.Item grow={1}>
                <FormHookTextField
                  name="deliveryTelephoneNumber"
                  label="Phone"
                  placeholder="Phone"
                  disabled={disableInputs}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <FormHookTextField
                  name="deliveryEmailAddress"
                  label="Email"
                  placeholder="Email"
                  disabled={disableInputs}
                />
              </Stack.Item>
            </Stack>
          </Stack>
          <Separator />
          <Stack
            tokens={{
              childrenGap: 10,
              padding: '0px 0px 20px 0px',
            }}
          >
            <Text variant="xLarge" className={styles.detailsLabel}>
              Details
            </Text>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{
                padding: '0px 20px',
              }}
              className={styles.mB20}
            >
              <Stack
                tokens={{ padding: '0px 10px 0px 0px' }}
                className={styles.inputContainer}
              >
                <FormHookDatePicker
                  name="scheduledDeliveryDate"
                  placeholder="Select date"
                  label="Delivery Date"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay
                  showGoToToday
                />
              </Stack>
              <Stack
                tokens={{ padding: '0px 10px 0px 0px' }}
                className={styles.inputContainer}
              >
                <FormHookTextField
                  name="deliveryNotes"
                  label="Delivery Notes"
                  placeholder="Delivery Notes"
                />
              </Stack>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{
                padding: '0px 20px',
              }}
              className={styles.mB20}
            >
              <Stack
                tokens={{ padding: '0px 10px 0px 0px' }}
                className={styles.inputContainer}
              >
                <FormHookCheckBox
                  name="isDeliveryTracked"
                  label="Delivery Tracking"
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            className={styles.footer}
            verticalAlign="center"
            horizontalAlign="space-between"
            horizontal
          >
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              {!!_isPromoteAddressAllowed && (
                <DefaultButton
                  text="Available Address"
                  iconProps={{
                    iconName: 'Add',
                  }}
                  onClick={() => setOpenDialog(true)}
                />
              )}
              <DefaultButton onClick={() => onClearInputs(true)} text="Clear" />
            </Stack>
            <Stack>
              <DefaultButton onClick={() => setOpen(false)} text="Close" />
            </Stack>
          </Stack>
        </Stack>
        <PromoteDeliveryAddress
          purchaseOrder={purchaseOrder}
          hidden={!openDialog}
          onDismiss={() => setOpenDialog(false)}
          onConfirm={(data) => {
            refetchAvailableDeliveryAddresses();
            onSelect(data);
            setAddressId(data.id);
            setOpenDialog(false);
          }}
        />
      </Modal>
    </>
  );
};
