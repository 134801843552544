import { IconButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { LookupCorporateAccountFields } from '../__generated__/LookupCorporateAccountFields';
import { CorporateAccountModal } from './CorporateAccountModal';

export interface EditCorporateAccountProps {
  corporateAccount: LookupCorporateAccountFields;
}

export const EditCorporateAccount: React.FC<EditCorporateAccountProps> = ({
  corporateAccount
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    chartOfAccountId,
    _isUpdatable
  } = { ...corporateAccount }

  return (
    <Stack>
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        onClick={() => setIsOpen(true)}
        disabled={!_isUpdatable}
      />
      {
        isOpen &&
        <CorporateAccountModal
          chartOfAccountId={chartOfAccountId}
          corporateAccount={corporateAccount}
          onClose={() => setIsOpen(false)}
        />
      }
    </Stack>
  )
}
