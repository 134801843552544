import { useApolloClient, useQuery } from '@apollo/client';
import {
  ContextualMenu,
  MessageBar,
  MessageBarType,
  Separator,
  Stack,
} from '@fluentui/react';
import { UserDefaults } from 'Preferences/__generated__/UserDefaults';
import { ActionsMenu } from 'ap/signing/transactionSigning/view/FormView/ActionMenu';
import { ShimmerView } from 'ap/signing/transactionSigning/view/FormView/ShimmerView/ShimmerViews';
import { ApprovalHistory } from 'common/components/ApprovalHistory';
import { CloseButton } from 'common/components/Buttons';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { Accounting } from 'common/components/Modules/TransactionEdit/Accounting';
import { BasicForm } from 'common/components/Modules/TransactionEdit/BasicForm';
import { Footer } from 'common/components/Modules/TransactionEdit/Footer';
import { Header } from 'common/components/Modules/TransactionEdit/Header';
import { TemporaryDocumentList } from 'common/components/Modules/TransactionEdit/TempDocumentList';
import {
  AvailablePurchaseOrders,
  AvailablePurchaseOrdersVariables,
} from 'common/components/Modules/TransactionEdit/graphql/__generated__/AvailablePurchaseOrders';
import { InvoiceDetails } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
import { InvoiceTransactionTypes } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceTransactionTypes';
import {
  attachedDocumentsData,
  FileListArray,
  TransactionSigningValues,
} from 'common/components/Modules/TransactionEdit/types';
import {
  formatEntityDocuments,
  getPOInvoiceSchedules,
} from 'common/components/Modules/TransactionEdit/utils';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { UploadStatusType } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';

const TRANSACTION_DATA = loader(
  '../../../../../common/components/Modules/TransactionEdit/graphql/InvoiceTransactionTypes.graphql'
);
const USER_DEFAULTS = loader(
  '../../../../../../src/Preferences/UserDefaults.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const AVAILABLE_PURCHASE_ORDER = loader(
  '../../../../../common/components/Modules/TransactionEdit/graphql/AvailablePurchaseOrders.graphql'
);

interface FormViewProps {
  onSave: () => void;
  isSaveAnother: (saveAnother: boolean) => void;
  isNew: boolean;
  invoiceDetailsData: InvoiceDetails | undefined;
  dataLoading: boolean;
  refetch: () => void;
  isCreating: boolean;
  isUpdating: boolean;
  onFileListArrayChange: (files: FileListArray[]) => void;
  onDismiss: () => void;
}

export interface InitialDocumentMetaData {
  id: string;
  indexName: string | null;
  comment: string | null;
}

export const FormView: React.FC<FormViewProps> = ({
  onSave,
  isNew,
  invoiceDetailsData,
  dataLoading,
  isSaveAnother,
  refetch,
  isCreating,
  isUpdating,
  onFileListArrayChange,
  onDismiss,
}) => {
  const styles = useStyles();
  const client = useApolloClient();
  const { updateToast } = useToasts();
  const [receiptTotal] = useState<string | null>(null);
  const [fileListArray, setFileListArray] = useState<FileListArray[]>([]);
  const [attachedDocuments, setAttachedDocuments] = useState<
    attachedDocumentsData | undefined
  >();

  const [initialDocumentMetaData, setInitialDocumentMetaData] =
    useState<InitialDocumentMetaData>();
  const { _isApprovalDocumentsRequired, _requiredApprovalDocuments } = {
    ...invoiceDetailsData?.invoice,
  };
  const { data: userDefaultsData } = useQuery<UserDefaults>(USER_DEFAULTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: transactionTypeData, loading: transactionTypeLoading } =
    useQuery<InvoiceTransactionTypes>(TRANSACTION_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  const {
    control,
    formState: { isSubmitting, isDirty },
    setValue,
  } = useFormContext<TransactionSigningValues>();

  const watchControlTotalAmount = useWatch({
    name: 'controlTotalAmount',
    control,
  });

  const { data: availablePurchaseOrders } = useQuery<
    AvailablePurchaseOrders,
    AvailablePurchaseOrdersVariables
  >(AVAILABLE_PURCHASE_ORDER, {
    skip: !invoiceDetailsData?.invoice?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    variables: { invoiceId: invoiceDetailsData?.invoice?.id! },
  });

  useEffect(() => {
    onFileListArrayChange(fileListArray);
  }, [fileListArray, onFileListArrayChange]);

  const disableCardHolder = invoiceDetailsData?.invoice?._isDocumentsExist!;

  useEffect(() => {
    if (!!attachedDocuments?.attachedDocuments.length)
      setFileListArray(attachedDocuments?.attachedDocuments);
    else setFileListArray([]);
  }, [attachedDocuments]);

  const setDocumentValues = () => {
    const formattedEntityDocuments = formatEntityDocuments(
      attachedDocuments?.attachedDocuments || []
    );
    const pOInvoiceSchedulesDocuments = getPOInvoiceSchedules(
      attachedDocuments?.attachedDocuments || []
    );
    setValue('entityDocuments', formattedEntityDocuments);
    setValue('poInvoiceSchedules', pOInvoiceSchedulesDocuments);
  };

  const setDocumentValuesMemo = useCallback(setDocumentValues, [
    attachedDocuments,
    fileListArray,
  ]);

  useEffect(() => {
    setDocumentValuesMemo();
  }, [setDocumentValuesMemo]);

  return (
    <ModalWrapper
      isOpen
      containerClassName={styles.modalContainer}
      isBlocking
      onDismiss={() => {}}
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
    >
      {dataLoading ? (
        <ShimmerView />
      ) : (
        <>
          <Stack>
            <Stack
              horizontal
              tokens={{ childrenGap: 5 }}
              className={styles.headerContainer}
            >
              <Header
                isNew={isNew}
                transactionTypeData={transactionTypeData}
                invoiceDetailsLoading={dataLoading}
                isSubmitting={isSubmitting}
                invoiceDetailsData={invoiceDetailsData}
                dirty={isDirty}
              />
              <CloseButton onClick={onDismiss} />
            </Stack>
            <Stack
              className={styles.actionContainer}
              tokens={{ childrenGap: 10 }}
            >
              {_isApprovalDocumentsRequired && _requiredApprovalDocuments && (
                <MessageBar messageBarType={MessageBarType.error}>
                  {`${_requiredApprovalDocuments}`}
                </MessageBar>
              )}
              {invoiceDetailsData?.invoice && (
                <ActionsMenu
                  carbonDeletedUpdated={async (status) => {
                    if (status) await refetch();
                  }}
                  onUpload={async (fileSelected, document, toastId) => {
                    const observer = client.subscribe({
                      query: DOCUMENT_UPLOAD_STATUS,
                      variables: {
                        documentId: document.document._documentFileId!,
                      },
                    });

                    const subscription = observer.subscribe((data) => {
                      const subscribedData =
                        data.data as OnDocumentUploadStatus;

                      const { status, document } = {
                        ...subscribedData.documentUploadStatus,
                      };

                      if (status.type === UploadStatusType.VALIDATING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Validating files ${fileSelected.name} - ${status.message}`
                            : `Validating files ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.EXTRACTING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Extracting data from ${fileSelected.name} - ${status.message}`
                            : `Extracting data from ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.FAILURE) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Upload of ${fileSelected.name} failed - ${status.message}`
                            : `Upload of ${fileSelected.name} failed`,
                          appearance: 'error',
                          autoDismiss: true,
                        });
                      } else if (status.type === UploadStatusType.WARNING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Warning for file ${fileSelected.name}: ${status.message}`
                            : `Warning for file ${fileSelected.name}`,
                          appearance: 'warning',
                          autoDismiss: true,
                        });
                      } else {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                            : `Successfully uploaded ${fileSelected.name}`,
                          appearance: 'success',
                          autoDismiss: true,
                        });

                        if (document) {
                          client.cache.modify({
                            id: client.cache.identify({
                              ...invoiceDetailsData?.invoice,
                            }),
                            fields: {
                              entityDocumentsByEntityId(existing) {
                                return {
                                  ...existing,
                                  nodes: [...existing?.nodes, document],
                                };
                              },
                            },
                          });
                        }
                      }
                    });
                  }}
                  invoiceDetails={invoiceDetailsData.invoice}
                  secureRowLevels={transactionTypeData?.secureRowLevels}
                />
              )}
            </Stack>
            <Separator />
          </Stack>

          <Stack>
            <TemporaryDocumentList
              isNew={isNew}
              fileListArray={fileListArray}
              setFileListArray={setFileListArray}
              attachedDocuments={attachedDocuments}
              controlTotalAmount={watchControlTotalAmount}
              receiptTotal={receiptTotal}
              onUpdatedDataReceived={setAttachedDocuments}
              onReceiptTotalChange={(_, documentID) => {
                if (documentID === initialDocumentMetaData?.id) {
                  setInitialDocumentMetaData(undefined);
                }
              }}
            />
            <BasicForm
              invoiceDetails={invoiceDetailsData}
              transactionTypeData={transactionTypeData}
              submitBasicForm={onSave}
              disableCardHolder={disableCardHolder}
              receiptTotal={receiptTotal}
              userDefaultsData={userDefaultsData?.userDefaults?.nodes || []}
            />

            <Accounting
              invoiceData={invoiceDetailsData?.invoice!}
              invoiceDistributionStatusTypes={transactionTypeData}
              availablePurchaseOrders={availablePurchaseOrders}
            />
            <Stack style={{ margin: '-20px 20px 50px 20px' }}>
              <ApprovalHistory
                data={
                  invoiceDetailsData?.invoice?.approvalHistoriesByEntityId
                    ?.nodes
                }
              />
            </Stack>
          </Stack>
          <Prompt
            when={isDirty && !isSubmitting}
            message="Are you sure you want to leave your changes unsaved?"
          />
          <Footer
            invoiceDetailsData={invoiceDetailsData}
            isNew={isNew}
            isCreating={isCreating}
            isUpdating={isUpdating}
            transactionTypeLoading={transactionTypeLoading}
            onSave={(saveAnother) => {
              isSaveAnother(saveAnother);
              onSave();
            }}
            showOtherButtons={false}
            hideCreateButton
            onDismiss={onDismiss}
          />
        </>
      )}
    </ModalWrapper>
  );
};
