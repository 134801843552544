import { TransactionLayout } from 'common/types/globalTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { useCommonStyles } from 'common/styles';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CarbonAccountingValues, EnvironmentalItemsRowValues, EnvironmentalMetricDataType } from '../types';
import { EnvironmentalMetrics, EnvironmentalMetricsVariables, EnvironmentalMetrics_environmentalMetrics_nodes } from 'common/graphql/__generated__/EnvironmentalMetrics';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { ProgressIndicator, Separator, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { EntityEnvironmentalItemsRow } from './EntityEnvironmentalItemsRow';
import { ResourceType } from '../constants';
const GET_ENVIRONMENT_CATEGORY = loader('../../../../../../../../../../../common/graphql/EnvironmentalMetrics.graphql');

interface EnvironmentalItemsViewProps {
  categoryId: string;
  layoutType: TransactionLayout | undefined;
  isEnvironmentalsExist: boolean;
}

export const EnvironmentalItemsView: React.FC<EnvironmentalItemsViewProps> = ({
  categoryId,
  layoutType,
  isEnvironmentalsExist,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { control, setValue } = useFormContext<CarbonAccountingValues>();
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'entityEnvironmentalItems', // unique name for your Field Array
  });
  const [resourceColumn, setResourceColumn] = useState<string[]>([]);
  const [environmentalMetricData, setEnvironmentalMetricData] = useState<EnvironmentalMetricDataType>();

  const [
    getEnvironmentalMetrics,
    { data: environmentCategoryData, loading: environmentCategoryDataLoading },
  ] = useLazyQuery<EnvironmentalMetrics, EnvironmentalMetricsVariables>(
    GET_ENVIRONMENT_CATEGORY,
    {
      variables: {
        filter: {
          id: {
            equalTo: categoryId,
          },
        },
      },
    }
  );

  const transformMetricData = (
    data: EnvironmentalMetrics_environmentalMetrics_nodes[]
  ) => {
    let environmentalMetricArray: EnvironmentalItemsRowValues[] = [];
    const transformedArray: EnvironmentalMetricDataType[] = data.map((ele) => {
      return {
        environmentalMetricItems: ele.environmentalMetricItems.nodes.map(
          (item) => {
            const environmentalImpactTypeId = item.environmentalImpact?.id;
            const isIntegerFormat = item.environmentalImpact?.isIntegerFormat;
            const isFloatFormat = item.environmentalImpact?.isFloatFormat;
            const isAmountFormat = item.environmentalImpact?.isAmountFormat;
            if (!isEnvironmentalsExist) {
              environmentalMetricArray.push({
                environmentalImpactTypeId: environmentalImpactTypeId!,
                feet: null,
                gallons: null,
                hours: null,
                kilogram: null,
                kilometers: null,
                kilowattHours: null,
                litres: null,
                meters: null,
                metricTonnes: null,
                miles: null,
                omissionsEquivalent: null,
                pounds: null,
                quantity: null,
                therms: null,
                tons: null,
                yards: null,
                carbonCreditsAmount: null,
                isIntegerFormat,
                isAmountFormat,
                isFloatFormat,
              });
            }
            return {
              environmentalImpact: item.environmentalImpact?.environmentalImpact,
              environmentalImpactTypeId,
              isIntegerFormat,
              isFloatFormat,
              isAmountFormat,
            };
          }
        ),
      };
    });
    if (transformedArray)
      setEnvironmentalMetricData(transformedArray[0]);
    if (!isEnvironmentalsExist)
      setValue('entityEnvironmentalItems', environmentalMetricArray);
  };

  const handleEnvironmentalMetrics = () => {
    if (categoryId) {
      //create an array of resources from ResourceType array.
      getEnvironmentalMetrics({
        variables: { filter: { id: { equalTo: categoryId } } },
      });
      ResourceType.map((type) => {
        if (Object.keys(type).toString() === layoutType) {
          let nestedResourceColumnsArray = Object.values(type);
          let resourceColumnsArray: string[] = [];
          nestedResourceColumnsArray.map((item) => {
            if (item) {
              for (let i = 0; i < item.length; i++) {
                resourceColumnsArray.push(item[i]);
              }
            }
            return true;
          });
          setResourceColumn(resourceColumnsArray);
        }
        return true;
      });
    }
  };

  const handleEnvironmentalMetricsMemo = useCallback(handleEnvironmentalMetrics, [categoryId]);

  const handleEnvironmentalCategoryData = () => {
    if (environmentCategoryData?.environmentalMetrics) {
      transformMetricData(
        environmentCategoryData.environmentalMetrics.nodes
      );
    }
  };

  const environmentCategoryDataMemo = useCallback(
    handleEnvironmentalCategoryData,
    [environmentCategoryData]
  );

  useEffect(() => {
    handleEnvironmentalMetricsMemo();
  }, [handleEnvironmentalMetricsMemo]);

  useEffect(() => {
    environmentCategoryDataMemo();
  }, [environmentCategoryDataMemo]);

  return (
    <>
      {environmentCategoryDataLoading && <ProgressIndicator />}
      {!!environmentalMetricData?.environmentalMetricItems.length && (
        <Stack horizontal>
          <Stack className={styles.resourceMainContainer}>
            <Text className={commonStyles.semibold}>Resource</Text>
            <Separator />
            <Stack className={styles.resourceColumnContainer}>
              {environmentalMetricData?.environmentalMetricItems.map(
                (item, index) => {
                  return (
                    <Stack
                      key={item.environmentalImpactTypeId}
                      className={clsx(
                        styles.environmentalImpact,
                        index !== 0 && styles.marginTop5
                      )}
                    >
                      <Text className={commonStyles.semibold}>
                        {item.environmentalImpact}
                      </Text>
                    </Stack>
                  );
                }
              )}
            </Stack>
          </Stack>
          <Stack horizontal className={styles.resourceColumnMainContainer}>
            {resourceColumn.map((item, index) => {
              const capitalize = (word: string) => {
                const lower = word.toLowerCase();
                return word.charAt(0).toUpperCase() + lower.slice(1);
              };
              return (
                <Stack
                  className={clsx(
                    index !== 0 && styles.marginLeft10,
                    styles.resouce
                  )}
                  key={index}
                >
                  <Text className={styles.resourceType}>
                    {capitalize(item)}
                  </Text>
                  <Separator />
                  <Stack className={styles.resourceTypeDataMainContainer}>
                    <>
                      {fields &&
                        fields?.map((ele, index) => {
                          const environmentalItemsName = `entityEnvironmentalItems[${index}]`;

                          return (
                            <Stack
                              horizontal
                              key={index}
                              className={clsx(index !== 0 && styles.marginTop5)}
                            >
                              <EntityEnvironmentalItemsRow
                                fieldName={item}
                                data={ele}
                                environmentalItemsName={environmentalItemsName}
                                isIntegerFormat={ele.isIntegerFormat}
                                isFloatFormat={ele.isFloatFormat}
                                isAmountFormat={ele.isAmountFormat}
                              />
                            </Stack>
                          );
                        })}
                    </>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
    </>
  )
}
