import { DirectionalHint, TooltipHost } from '@fluentui/react';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import React from 'react';
import TimeAgo from 'timeago-react';
import { useId } from '@fluentui/react-hooks';

interface NotificationDateTimeProps {
  dateTime: string;
}
export const NotificationDateTime: React.FC<NotificationDateTimeProps> = ({
  dateTime,
}) => {
  const timeAgoId = useId('timeAgo');
  return (
    <TooltipHost
      directionalHint={DirectionalHint.rightCenter}
      content={getGlobalDateFormat(dateTime)}
      calloutProps={{ target: `#${timeAgoId}` }}
    >
      <TimeAgo datetime={new Date(dateTime)} locale="en" id={timeAgoId} />
    </TooltipHost>
  );
};
