import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    padding: '10px 10px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  selected: {
    backgroundColor: theme.palette.white,
    border: `1px Solid ${theme.palette.neutralDark}66`,
    borderBottom: `1px Solid ${theme.palette.white}`,
  },
  unSelected: {
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
    // backgroundColor: theme.palette.neutralLighter,
  },
}));
