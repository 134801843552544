import { PivotMenuItemProps } from 'common/components/PivotDisplay';
import { GlobalActions } from 'common/constants';
export interface TravelerPropertiesCount {
  name: string;
  count: number;
}
export const pivotActionItems: PivotMenuItemProps[] = [
  {
    name: 'Files',
    iconName: 'Attach',
    itemKey: GlobalActions.attach,
    count: 0,
  },
  {
    name: 'Notes',
    iconName: 'Page',
    itemKey: GlobalActions.notes,
    count: 0,
  },
  {
    name: 'History',
    iconName: 'History',
    itemKey: GlobalActions.history,
    count: 0,
  },
  {
    name: 'Tags',
    iconName: 'Tag',
    itemKey: GlobalActions.tags,
    count: 0,
  },
  {
    name: 'Messages',
    iconName: 'Message',
    itemKey: GlobalActions.messages,
    count: 0,
  },
];
export const getUpdatedPivotActionItems = (pivotItems: TravelerPropertiesCount[]) => {
  return pivotActionItems.map(
    item => (
      {
        ...item,
        count: pivotItems.find(item2 => item2.name === item.name)?.count
      }
    )
  )
}
