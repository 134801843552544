import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultButton,
  Stack,
  IconButton,
  Text,
  Shimmer,
  ShimmerElementType,
  CommandBarButton,
  TooltipHost,
  ITooltipHostStyles,
} from '@fluentui/react';
import { loader } from 'graphql.macro';
import { NoDataView } from 'common/components/DataPlaceholders';
import { EntityTagInput } from 'common/types/globalTypes';
import {
  SupplierTags,
  SupplierTagsVariables,
} from './__generated__/SupplierTags';
import {
  EntityTagSupplierCreate,
  EntityTagSupplierCreateVariables,
} from './__generated__/EntityTagSupplierCreate';
import {
  SupplierAvailableTags,
  SupplierAvailableTagsVariables,
} from './__generated__/SupplierAvailableTags';
import { useStyles } from './index.styles';
import { dateFormat } from 'common/utils/dateFormats';
import {
  EntityTagDelete,
  EntityTagDeleteVariables,
} from 'common/graphql/__generated__/EntityTagDelete';
import { Chip } from 'common/components/Chip';
import { ToggleButton } from 'common/components/Buttons/ToggleButton';
const SUPPLIER_TAGS_LIST = loader('./SupplierTags.graphql');
const AVAILABLE_TAGS_LIST = loader('./SupplierAvailableTags.graphql');
const ADD_NEW_TAG = loader('./EntityTagSupplierCreate.graphql');
const DELETE_TAG = loader(
  '../../../../../../common/graphql/EntityTagDelete.graphql'
);
interface TagsSectionProps {
  supplierID?: string;
}
interface Tags {
  id: string;
  _rowTimestamp: string;
}
export const TagsSection: React.FC<TagsSectionProps> = ({ supplierID }) => {
  const styles = useStyles();
  const [toggleView, setToggleView] = useState(false);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [addNewTag, { loading: loadingAddNewTag }] = useMutation<
    EntityTagSupplierCreate,
    EntityTagSupplierCreateVariables
  >(ADD_NEW_TAG);
  const [deleteTag, { loading: loadingRemoveTag }] = useMutation<
    EntityTagDelete,
    EntityTagDeleteVariables
  >(DELETE_TAG);
  const {
    data: tagsData,
    refetch: tagsListRefetch,
    loading: tagsLoading,
  } = useQuery<
    SupplierAvailableTags,
    SupplierAvailableTagsVariables
  >(AVAILABLE_TAGS_LIST, {
    variables: {
      id: supplierID!,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const getAvailableTags = () => {
    const variables: SupplierAvailableTagsVariables = {
      id: supplierID!,
    };
    tagsListRefetch(variables);
  };
  const getAvailableTagsMemo = useCallback(getAvailableTags, []);
  const {
    data: entityTagsData,
    refetch,
    loading: entityTagsLoading,
  } = useQuery<SupplierTags, SupplierTagsVariables>(SUPPLIER_TAGS_LIST, {
    variables: {
      id: supplierID!,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const getEntityTags = () => {
    const variables: SupplierTagsVariables = {
      id: supplierID!,
    };
    refetch(variables);
  };
  const getEntityTagsMemo = useCallback(getEntityTags, []);
  const removeTag = async (tag: Tags) => {
    await deleteTag({
      variables: {
        entityTagDeleteInput: {
          entityDelete: [
            {
              id: tag!.id,
              rowTimestamp: tag!._rowTimestamp!,
            },
          ],
        },
      },
    });
    setSelectedTags([]);
    if (!loadingRemoveTag) {
      getAvailableTagsMemo();
      getEntityTagsMemo();
    }
  };
  const AddTag = async () => {
    const tagsInputData = selectedTags.map((tag: string) => ({
      tagId: tag,
      entityId: supplierID,
    }));
    await addNewTag({
      variables: {
        input: {
          entityTag: tagsInputData?.map(
            ({ ...tags }) => tags as EntityTagInput
          ),
        },
      },
    });
    setSelectedTags([]);
    if (!loadingAddNewTag) {
      getAvailableTagsMemo();
      getEntityTagsMemo();
    }
  };

  const disableAddTags = selectedTags.length === 0 || loadingAddNewTag;
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block' },
  };

  const isSelected = (tagId: string) =>
    selectedTags.some((item) => item === tagId);

  const onTagPressed = (tagId: string) => {
    let levelsSelected = [...selectedTags];
    let position = levelsSelected.findIndex(
      (item) => item === tagId
    );
    if (position === -1) {
      levelsSelected.push(tagId);
    } else levelsSelected.splice(position, 1);
    setSelectedTags(levelsSelected)
  }

  return (
    <Stack tokens={{ childrenGap: 15, padding: "0px 25px" }} className={styles.tagsContainer}>
      {toggleView ? (
        <Stack tokens={{ childrenGap: 30 }} className={styles.tagsContent}>
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              {tagsData?.supplierAvailableTags?.nodes.length === 0 &&
                !tagsLoading && (
                  <Stack.Item className={styles.emptyTagsView}>
                    <Text>No available tags to add!</Text>
                  </Stack.Item>
                )}
              <Stack
                wrap
                horizontal
                tokens={{ childrenGap: 10 }}
                className={styles.customWidth}
              >
                {tagsData?.supplierAvailableTags?.nodes &&
                  tagsData.supplierAvailableTags?.nodes.map((tag) => {
                    return (
                      <Stack key={tag.id}>
                        <ToggleButton
                          onClick={() => onTagPressed(tag.id)}
                          toggled={isSelected(tag.id)}
                          key={tag.id}
                          text={tag.badgeName}
                          iconName={isSelected(tag.id) ? 'Accept' : 'Add'}
                          color={
                            isSelected(tag.id) && tag.badgeColor ? tag.badgeColor : undefined
                          }
                        />
                      </Stack>
                    );
                  })}
              </Stack>
            </Stack>
            <IconButton
              onClick={() => setToggleView(false)}
              iconProps={{ iconName: 'Cancel' }}
              className={styles.closeButton}
              ariaLabel="Close"
            />
          </Stack>
          <Stack tokens={{ childrenGap: 20 }} horizontal>
            <CommandBarButton
              className={styles.commandBarButton}
              iconProps={{ iconName: 'Accept' }}
              disabled={disableAddTags}
              onClick={() => {
                AddTag();
              }}
              text={'Add tags'}
            />
            <CommandBarButton
              className={styles.commandBarButton}
              iconProps={{ iconName: 'Cancel' }}
              disabled={selectedTags.length === 0}
              onClick={() => {
                setSelectedTags([]);
              }}
              text="Cancel"
            />
          </Stack>
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="end">
          <DefaultButton
            onClick={() => setToggleView(true)}
            iconProps={{ iconName: 'Add' }}
            text={'Add Tags'}
          />
        </Stack>
      )}
      <>
        {entityTagsLoading && (
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, width: 100, height: 40 },
            ]}
          />
        )}
        {!entityTagsLoading && (
          <Stack
            wrap
            horizontal
            tokens={{ childrenGap: 10 }}
            className={styles.customWidth}
          >
            {entityTagsData?.supplier?.entityTagsByEntityId.nodes.map(
              (entityTag) => {
                const createdDate = dateFormat(entityTag._createdDate!);
                const createdByUser = entityTag.createdByUser;
                const tooltipContent = `${createdByUser} / ${createdDate}`;
                return (
                  <TooltipHost
                    content={tooltipContent}
                    id="tooltipId"
                    calloutProps={calloutProps}
                    styles={hostStyles}
                    key={entityTag.id}
                  >
                    <Chip
                      disabled={loadingRemoveTag}
                      text={entityTag._tagBadgeName!}
                      onClick={() => {
                        removeTag({
                          id: entityTag.id || '',
                          _rowTimestamp: entityTag._rowTimestamp || '',
                        });
                      }}
                      color={entityTag._tagBadgeColor!}
                    />
                  </TooltipHost>
                );
              }
            )}
          </Stack>
        )}
        <NoDataView
          title={'No tags available!'}
          show={
            entityTagsData?.supplier?.entityTagsByEntityId.nodes.length === 0 &&
            !entityTagsLoading
          }
        />
      </>
    </Stack>
  );
};
