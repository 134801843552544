import { Dropdown, IDropdownOption, IDropdownStyles, PrimaryButton, Stack, IButtonStyles, getTheme } from '@fluentui/react';
import { RemoveRowButton } from 'common/components/Buttons';
import { useField } from 'formik';
import React from 'react';
import { GroupItemSelectOption } from '..';
import { CommunicationGroupItemValues } from '../../types';
interface CommunicationGroupRowProps {
    data: CommunicationGroupItemValues,
    name: string;
    index: number;
    deleteRow: (index: number) => void;
    selectedKey: string;
}
export const CommunicationGroupRow: React.FC<CommunicationGroupRowProps> = ({
    data,
    name,
    index,
    deleteRow,
    selectedKey
}) => {

    const theme = getTheme();
    const [field, meta, helpers] = useField<GroupItemSelectOption | undefined>(name);
    const dropdownOptions: IDropdownOption[] = [
        { key: "Select", text: 'Select' },
        { key: "isRecipientOnly", text: 'Receive Only' },
        { key: "isSenderOnly", text: 'Send Only' },
    ];
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 200 },
    };
    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            width: 250,
            backgroundColor: data.communicationChannel?._isAlerts ? '#D32F2F' : theme.palette.themePrimary,
            borderColor: data.communicationChannel?._isAlerts ? '#D32F2F' : theme.palette.themePrimary,
        },
        rootHovered: {
            backgroundColor: data.communicationChannel?._isAlerts ? '#DA4F4F' : undefined,
            borderColor: data.communicationChannel?._isAlerts ? '#DA4F4F' : undefined,
        }
    }

    const removeCurrentRow = () => {
        let value = { ...field.value!, isDeleted: true, isSenderOnly: false, isRecipientOnly: false };
        helpers.setValue(value)
        deleteRow(index);
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <PrimaryButton
                text={data._communicationChannelName!}
                styles={buttonStyles}
            />
            <Dropdown
                placeholder="Select an option"
                options={dropdownOptions}
                errorMessage={meta.error}
                selectedKey={selectedKey}
                onChange={(_, option) => {
                    let value = {
                        ...field.value!,
                        isRecipientOnly: option?.key === "isRecipientOnly" ? true : false,
                        isSenderOnly: option?.key === "isSenderOnly" ? true : false,
                    };
                    helpers.setValue(value)
                }}
                styles={dropdownStyles}
            />
            <RemoveRowButton
                onClick={removeCurrentRow}
            />
        </Stack>
    )
};
