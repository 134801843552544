import {
  NetworkStatus,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  IColumn,
  Icon,
  IconButton,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentsSearchBar } from '../DocumentsSearchBar';
import { Tabs } from '../Tabs';
import { DocumentListItem } from './interface';
import { ReactComponent as ApprovedIcon } from '../../../assets/svgs/ApproveStatusIcons/Approved.svg';
import { ReactComponent as PendingIcon } from '../../../assets/svgs/ApproveStatusIcons/Pending.svg';
import clsx from 'clsx';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { setGlobalSearchText } from 'utility/cache/ui';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { DownloadButton } from 'common/components/DownloadButton';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { toFilterVariable, toOrderByVariable } from './utils';
import {
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
  EntitySelectionInput,
} from 'common/types/globalTypes';
import { FilterArrayType } from 'common/components/Filters';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  EntityDocumentSearch,
  EntityDocumentSearchVariables,
} from './__generated__/EntityDocumentSearch';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { CommonData } from './__generated__/CommonData';
import { VerifyDocuments } from '../VerifyDocuments';
import {
  EntityDocumentVerify,
  EntityDocumentVerifyVariables,
} from '../VerifyDocuments/__generated__/EntityDocumentVerify';
import { useToasts } from 'react-toast-notifications';
import { DeleteModal } from './DeleteModal';
import { BuildTransaction } from '../BuildTransactions';
import { useColumns } from './columns.data';

const ENTITY_DOCUMENT_SEARCH = loader('./EntityDocumentSearch.graphql');
const COMMON_DATA = loader('../Documents/CommonData.graphql');
const VERIFY_DOCUMENTS = loader(
  '../VerifyDocuments/EntityDocumentVerify.graphql'
);
interface DocumentsProps {}

export const Documents: React.FC<DocumentsProps> = () => {
  let listData: DocumentListItem[] = [];
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { columns } = useColumns();

  const { addToast } = useToasts();
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();
  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });
  const { t: searchParam } = urlParameters;
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const [selectedList, setSelectedList] = useState<DocumentListItem[]>([]);
  // const [secureDialogVisibility, { toggle: toggleSecure }] = useBoolean(false);

  const [searchValue, setSearchValue] = useState<string | null>(
    searchParam! || null
  );
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const [verifyDocuments] = useMutation<
    EntityDocumentVerify,
    EntityDocumentVerifyVariables
  >(VERIFY_DOCUMENTS);
  const { data: commonData } = useQuery<CommonData>(COMMON_DATA, {
    fetchPolicy: 'network-only',
  });
  const { data, loading, networkStatus, refetch, fetchMore, variables } =
    useQuery<EntityDocumentSearch, EntityDocumentSearchVariables>(
      ENTITY_DOCUMENT_SEARCH,
      {
        variables: {
          isSigningSearch: false,
          isHistorySearch: false,
          isLinkSearch: false,
          first: TABLE_ROWS,
          orderBy: [EntityDocumentsOrderBy.PRIMARY_KEY_ASC],
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );
  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const transformedData = refetching
    ? undefined
    : data?.entityDocumentSearch?.nodes.map(
        (ele) =>
          ({
            ...ele,
            key: ele.id,
            isoCode: ele.currency?.isoCode,
            indexTransactionDate: ele.indexTransactionDate
              ? dateFormat(dateConvertions(ele.indexTransactionDate))
              : null,
          } as DocumentListItem)
      );

  if (transformedData) listData = transformedData;

  useEffect(() => {
    if (searchParam) setGlobalSearchText(searchParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _renderItemColumn = (
    item: DocumentListItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof DocumentListItem
      ] as string;

    
      switch (column?.key) {
        case 'documentType':
          return (
            <HighLightActiveLink
              highlightText={globalSearchText}
              text={item?._documentType!}
              to={`/doc/documentAssignment/document/${item.id}`}
            />
          );
        case '_isSelected':
          if (item._isSelected)
            return (
              <Stack
                className={clsx(
                  styles.onrenderColumnStack,
                  styles.contentColumnAlignCenter
                )}
                verticalAlign="center"
              >
                <ApprovedIcon />
              </Stack>
            );
          else return null;
        case '_isVerified':
          // History
          return (
            <Stack
              className={clsx(
                styles.onrenderColumnStack,
                styles.contentColumnAlignCenter
              )}
              verticalAlign="center"
            >
              <TooltipHost
                content={item._isVerified ? 'Verified' : 'Pending verification'}
              >
                {item._isVerified ? <ApprovedIcon /> : <PendingIcon />}
              </TooltipHost>
            </Stack>
          );
        case '_uploadDate':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              <HighlightTextView
                className={styles.contentColumnAlignRight}
                highlightText={globalSearchText}
                text={getGlobalDateFormat(item._uploadDate || '')}
              />
            </Stack>
          );

        case 'indexAmount':
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.textAlignEnd, styles.onrenderColumnStack)}
            >
              <AmountColumnTextView
                value={fieldContent}
                searchText={globalSearchText}
              />
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              verticalAlign={'center'}
              horizontal
              className={styles.onrenderColumnStack}
              tokens={{ childrenGap: 10 }}
            >
              <Icon
                className={fileTypeColor(item.iconType || 'OTHER')}
                iconName={fileType(item.iconType || 'OTHER')}
              />
              <Text>{fieldContent}</Text>
            </Stack>
          );
        case 'view':
            const viewDocumentVisible =
              item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case 'download':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontalAlign={'center'}
            >
              <DownloadButton entityDocumentId={item.id} />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        setSortOrderParam({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
        await refetch({
          after: null,
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  const handleSearch = (showMore: boolean) => handleSearchGlobal(showMore);

  // Handle Search query with globalSearch param
  const handleSearchGlobal = (showMore: boolean) => {
    const variables: EntityDocumentSearchVariables = {
      invoiceSigningSearchSearchRequest: searchValue
        ? { searchText: [searchValue] }
        : null,
      first: TABLE_ROWS,
      after: showMore
        ? data?.entityDocumentSearch?.pageInfo.endCursor
        : undefined,

      filter: searchFilters?.length
        ? ({
            and: toFilterVariable(searchFilters),
          } as EntityDocumentFilter)
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam!),
    };

    const searchQueryParam =
      searchValue && searchValue.length > 0 ? searchValue : undefined;

    setUrlParameters({ t: searchQueryParam }, 'replace');

    if (showMore) fetchMore?.({ variables });
    else refetch(variables);
  };

  useEffect(() => {
    handleSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters, sortOrderParam, searchValue]);

  const onVerifyClick: any = async () => {
    const entitySelection = selectedList
      .filter((item) => !item._isVerified && item._isUpdatable)
      .map(
        (item) =>
          ({
            rowTimestamp: item._rowTimestamp,
            id: item.id,
          } as EntitySelectionInput)
      );

    if (entitySelection.length > 0) {
      const { errors } = await verifyDocuments({
        variables: {
          input: {
            entitySelection,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ENTITY_DOCUMENT_SEARCH,
            variables: variables,
          },
        ],
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        setSelectedList([]);
        addToast('Documents verified successfully', {
          appearance: 'success',
        });
      }
    } else addToast('Documents already verified', { appearance: 'error' });
  };

  return (
    <>
      <Sticky stickyPosition={StickyPositionType.Header}>
        <Stack
          tokens={{ childrenGap: 20 }}
          className={commonStyles.listHeaderContainer}
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={commonStyles.listTitleContainer}
          >
            <Text variant="xLarge">Documents</Text>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <DeleteModal
                onDeletion={() => {}}
                selectedDocuments={selectedList}
              />
              <VerifyDocuments
                _onConfirm={onVerifyClick}
                selectedDocuments={selectedList}
              />
              <BuildTransaction
                allowMultiFileBundleToggle
                companyBusinessUnits={commonData?.companyBusinessUnits}
                companyDepartments={commonData?.companyDepartments}
                selectedList={selectedList}
              />
            </Stack>
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <Stack
              horizontal
              verticalAlign="end"
              horizontalAlign="space-between"
            >
              <Tabs />
            </Stack>
          </Stack>
        </Stack>

        <Stack tokens={{ padding: '0px 25px', childrenGap: 20 }}>
          <DocumentsSearchBar
            urlParams={{
              searchParam: searchParam!,
            }}
            commonData={commonData}
            columns={gridColumns}
            onEnterPress={(value) => {
              setSearchValue(value);
              setGlobalSearchText(value);
            }}
            onFilterChange={setSearchFilters}
            onToggleVisibility={(columns) => {
              setGridColumns(columns);
            }}
            onRefresh={refetch}
          />
        </Stack>
      </Sticky>

      <InfiniteList<DocumentListItem>
        loading={loading}
        items={listData}
        hasNextPage={data?.entityDocumentSearch?.pageInfo.hasNextPage}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={_onColumnClick}
        onLoadMore={() => handleSearch(true)}
        onSelectionChanged={setSelectedList}
        isSelectedOnFocus={false}
      />

      <DocumentViewModal
        paddingLeft={500}
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
