import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AnimationClassNames, Icon, Stack, Text } from '@fluentui/react';
import { CrudOperations } from 'common/types/globalTypes';
import { useStyles } from './index.styles';

import { NavButton } from 'common/components/Buttons';
import {
  getGlobalDateFormat,
  getPrettierdate,
  getTimeFormat,
} from 'common/utils/dateFormats';
import { NoDataView } from '../DataPlaceholders';

export interface HistoryListOptions {
  crudOperation: string | null;
  pointInTimeDate: string | null;
  tenantUserName: string | null;
}
interface HistoryListQueryProps {
  historyData: HistoryListOptions[];
  numberOfItems?: number;
  moduleName?: string;
}

export const HistoryActionMenuView: React.FC<HistoryListQueryProps> = ({
  historyData,
  numberOfItems = 3,
  moduleName,
}) => {
  const styles = useStyles();

  const [scrubNext, setScrubNext] = useState(false);
  const [scrubPrevious, setScrubPrevious] = useState(false);
  const [timeline, setTimeline] = useState<HistoryListOptions[]>([]);
  const [page, setPage] = useState(0);
  const SlideLeftIn = AnimationClassNames.slideLeftIn400;
  const SlideRightIn = AnimationClassNames.slideRightIn400;

  useEffect(() => {
    if (historyData.length > 0) {
      const hData = [...historyData].reverse();
      setTimeline(
        hData.slice(page * numberOfItems, page * numberOfItems + numberOfItems)
      );
    }
  }, [page, historyData, numberOfItems]);

  useEffect(() => {
    setPage(0);
  }, [historyData]);

  const timelineLength = historyData?.length || 0;

  return (
    <Stack className={styles.historyWrapper} >
      {timeline.length! ? (
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          className={styles.historyContent}
        >
          <Stack
            className={clsx(
              styles.timelineScrub,
              scrubNext ? SlideRightIn : '',
              scrubPrevious ? SlideLeftIn : ''
            )}
            horizontal
            tokens={{ childrenGap: 30 }}
            horizontalAlign="space-evenly"
          >
            {timeline.map((item, index) => (
              <Stack key={index.toString()} className={styles.activityBox}>
                <ActivityBoxView
                  key={index}
                  activityData={item}
                  moduleName={moduleName}
                />
              </Stack>
            ))}
          </Stack>

          <Stack
            horizontal
            verticalAlign="center"
            className={styles.timeLineWrapper}
          >
            <Stack className={styles.marginContainer}>
              {page === 0 ? (
                <StopPoint />
              ) : (
                <NavButton
                  title="Recent"
                  direction="Previous"
                  onClick={() => {
                    setScrubNext(true);
                    setPage((prevstate) => prevstate - 1);
                    setTimeout(() => {
                      setScrubNext(false);
                    }, 300);
                  }}
                />
              )}
            </Stack>

            <Stack className={styles.timeLine} />

            <Stack className={styles.marginContainer}>
              {Math.ceil(timelineLength / numberOfItems) - 1 === page ? (
                <StopPoint />
              ) : (
                <NavButton
                  title={'Earlier'}
                  onClick={() => {
                    setScrubPrevious(true);
                    setPage((prevstate) => prevstate + 1);
                    setTimeout(() => {
                      setScrubPrevious(false);
                    }, 300);
                  }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <NoDataView show />
      )}
    </Stack>
  );
};

interface ActivityBoxViewProps {
  activityData: HistoryListOptions;
  moduleName: string | undefined;
}

export const ActivityBoxView: React.FC<ActivityBoxViewProps> = ({
  activityData,
  moduleName,
}) => {
  const styles = useStyles();
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      className={styles.maxWidth150}
    >
      <Text variant="mediumPlus" className={styles.activityDate}>
        {getPrettierdate(getGlobalDateFormat(activityData?.pointInTimeDate!))}
      </Text>
      <Text variant="medium" className={styles.activityTime}>
        {getTimeFormat(activityData?.pointInTimeDate!)}
      </Text>
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        className={styles.activityIconWrapper}
      >
        <Icon
          iconName={getActivityIcon(activityData.crudOperation || 'PageEdit')}
          className={styles.activityIcon}
        />
      </Stack>
      <Text variant="medium" className={styles.textAlignCenter}>
        {activityData.tenantUserName}
        {activityData.crudOperation === 'CREATE'
          ? ` created new ${moduleName}`
          : ` updated ${moduleName}`}
      </Text>
    </Stack>
  );
};
const getActivityIcon = (crudType: string = 'PageEdit') => {
  if (crudType === CrudOperations.CREATE) return 'PencilReply';
  else if (crudType === CrudOperations.DELETE) return 'Delete';
  else if (crudType === CrudOperations.UPDATE) return 'PageEdit';
  else return 'PageEdit';
};

export const StopPoint = () => {
  const styles = useStyles();
  return <Stack className={styles.stopPoint}></Stack>;
};
