import { useQuery } from '@apollo/client';
import { Separator, Stack, makeStyles } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import { TravelerAvailableDocumentTypes } from 'common/graphql/__generated__/TravelerAvailableDocumentTypes';
import { TravelerUploadDocument_travelerUploadDocument } from 'common/graphql/__generated__/TravelerUploadDocument';
import { loader } from 'graphql.macro';
import React from 'react';
import { TravelerDetails_traveler } from '../../__generated__/TravelerDetails';
import { AttachForm } from './AttachForm';
import { UploadForm } from './UploadForm';
import { TravelerSecureRowLevels } from './__generated__/TravelerSecureRowLevels';
import { columns } from './coloumn.data';
const TRAVELER_AVAILABLE_DOCUMENT_TYPES = loader(
  '../../../../../../../../../../../../../common/graphql/TravelerAvailableDocumentTypes.graphql'
);
const TRAVELER_SECURE_ROW_LEVELS = loader('./TravelerSecureRowLevels.graphql')

const useStyles = makeStyles(() => ({
  mainStack: {
    paddingTop: 24,
  },
}));

interface AttachProps {
  traveler: TravelerDetails_traveler;
  onRemove?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: TravelerUploadDocument_travelerUploadDocument,
    toastId: string
  ) => void;
}

export const Attach: React.FC<AttachProps> = ({
  traveler,
  onRemove,
  onUpload,
}) => {
  const styles = useStyles();

  const { data: secureRowLevelsData } = useQuery<TravelerSecureRowLevels>(
    TRAVELER_SECURE_ROW_LEVELS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { data: availableDocumentTypes } =
    useQuery<TravelerAvailableDocumentTypes>(
      TRAVELER_AVAILABLE_DOCUMENT_TYPES,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );

  const {
    entityDocumentsByEntityId
  } = { ...traveler }

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{
          childrenGap: 10,
        }}
      >
        <AttachForm
          availableDocumentTypes={availableDocumentTypes}
          traveler={traveler}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={availableDocumentTypes}
          traveler={traveler}
          onUpload={onUpload}
        />
      </Stack>
      {!!entityDocumentsByEntityId?.nodes?.length && (
        <Stack
          tokens={{
            childrenGap: 10,
          }}
        >
          <DocumentList
            isNew={false}
            data={entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevelsData?.secureRowLevels?.nodes}
            onRemoveRow={() => onRemove?.()}
            columns={columns}
          />
        </Stack>
      )}
      <Stack tokens={{ padding: '0px 25px' }}>
        <NoDataView
          show={!entityDocumentsByEntityId?.totalCount}
          title="No Attachments"
        />
      </Stack>
    </Stack>
  )
}
