import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    travelPolicyAllowancesRow: Yup.array().of(
      Yup.object().shape({
        expenditureTypeId: Yup.string().required('Required').nullable(),
        policyAllowance: Yup.string().required('Required').nullable(),
      })
    ),
  });
