import Decimal from 'decimal.js';
import { TripItemFormValues } from '../../../../types';

export const calculateCharge = (
  quantity: number | null | undefined,
  Rate: string | null | undefined
) => {
  if (!!quantity && !!Rate) {
    const product = new Decimal(quantity || 0).times(Rate || 0);
    if (!product.greaterThan(0)) return null;
    return product.toNumber().toFixed(2);
  }
};

export const calcuLateTotalAmount = (tripItemsValues: TripItemFormValues[]) => {
  if (!tripItemsValues?.length) return null;
  return (
    tripItemsValues
      ?.reduce(
        (
          sum,
          { bookingFeeTotal, perDiemTotal, incidentalTotal, miscellaneousTotal }
        ) => {
          const decimalBookingFeeTotal = new Decimal(bookingFeeTotal || 0);
          const decimalPerDiemTotal = new Decimal(perDiemTotal || 0);
          const decimalIncidentalTotal = new Decimal(incidentalTotal || 0);
          const decimalMiscellaneousTotal = new Decimal(
            miscellaneousTotal || 0
          );
          return decimalBookingFeeTotal
            .plus(decimalPerDiemTotal)
            .plus(decimalIncidentalTotal)
            .plus(decimalMiscellaneousTotal)
            .plus(sum);
        },
        new Decimal(0)
      )
      .toNumber()
      .toFixed(2) || null
  );
};
