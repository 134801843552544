import { useMutation, useQuery } from "@apollo/client";
import { CommandBarButton, DefaultButton, IconButton, ITooltipHostStyles, Shimmer, ShimmerElementType, Stack, Text, TooltipHost } from "@fluentui/react";
import { ToggleButton } from "common/components/Buttons";
import { Chip } from "common/components/Chip";
import { NoDataView } from "common/components/DataPlaceholders";
import { EntityTagDelete, EntityTagDeleteVariables } from "common/graphql/__generated__/EntityTagDelete";
import { EntityTagInput } from "common/types/globalTypes";
import { dateFormat } from "common/utils/dateFormats";
import { loader } from "graphql.macro";
import React, { useCallback, useState } from "react";
import { useStyles } from './index.styles';
import { EntityTagTravelerCreate, EntityTagTravelerCreateVariables } from "common/graphql/__generated__/EntityTagTravelerCreate";
import { TravelerAvailableTags, TravelerAvailableTagsVariables } from "common/graphql/__generated__/TravelerAvailableTags";
import { TravelerTags, TravelerTagsVariables } from "common/graphql/__generated__/TravelerTags";

const ENTITY_TAG_TRAVELER_CREATE = loader("../../../../../../common/graphql/EntityTagTravelerCreate.graphql");
const TRAVELER_AVAILABLE_TAGS = loader("../../../../../../common/graphql/TravelerAvailableTags.graphql");
const TRAVELER_TAGS = loader("../../../../../../common/graphql/TravelerTags.graphql");
const ENTITY_TAG_DELETE = loader(
  '../../../../../../common/graphql/EntityTagDelete.graphql'
);

interface TagsProps {
  travelerId: string;
}
interface Tags {
  id: string;
  _rowTimestamp: string;
}

export const Tags: React.FC<TagsProps> = ({ travelerId }) => {
  const styles = useStyles();
  const [toggleView, setToggleView] = useState(false);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);

  const [addNewTag, { loading: loadingAddNewTag }] = useMutation<
    EntityTagTravelerCreate,
    EntityTagTravelerCreateVariables
  >(ENTITY_TAG_TRAVELER_CREATE);

  const [deleteTag, { loading: loadingRemoveTag }] = useMutation<
    EntityTagDelete,
    EntityTagDeleteVariables
  >(ENTITY_TAG_DELETE);

  const {
    data: tagsData,
    refetch: tagsListRefetch,
    loading: tagsLoading,
  } = useQuery<
    TravelerAvailableTags,
    TravelerAvailableTagsVariables
  >(TRAVELER_AVAILABLE_TAGS, {
    variables: {
      id: travelerId!,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const getAvailableTags = () => {
    const variables: TravelerAvailableTagsVariables = {
      id: travelerId!,
    };
    tagsListRefetch(variables);
  };

  const getAvailableTagsMemo = useCallback(getAvailableTags, []);

  const {
    data: entityTagsData,
    refetch,
    loading: entityTagsLoading,
  } = useQuery<TravelerTags, TravelerTagsVariables>(TRAVELER_TAGS, {
    variables: {
      id: travelerId!,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const getEntityTags = () => {
    const variables: TravelerTagsVariables = {
      id: travelerId!,
    };
    refetch(variables);
  };

  const getEntityTagsMemo = useCallback(getEntityTags, []);

  const removeTag = async (tag: Tags) => {
    await deleteTag({
      variables: {
        entityTagDeleteInput: {
          entityDelete: [
            {
              id: tag!.id,
              rowTimestamp: tag!._rowTimestamp!,
            },
          ],
        },
      },
    });
    setSelectedTags([]);
    if (!loadingRemoveTag) {
      getAvailableTagsMemo();
      getEntityTagsMemo();
    }
  };

  const AddTag = async () => {
    const tagsInputData = selectedTags.map((tag: string) => ({
      tagId: tag,
      entityId: travelerId,
    }));
    await addNewTag({
      variables: {
        input: {
          entityTag: tagsInputData?.map(
            ({ ...tags }) => tags as EntityTagInput
          ),
        },
      },
    });
    setSelectedTags([]);
    if (!loadingAddNewTag) {
      getAvailableTagsMemo();
      getEntityTagsMemo();
    }
  };

  const disableAddTags = selectedTags.length === 0 || loadingAddNewTag;
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block' },
  };

  const isSelected = (tagId: string) =>
    selectedTags.some((item) => item === tagId);

  const onTagPressed = (tagId: string) => {
    let levelsSelected = [...selectedTags];
    let position = levelsSelected.findIndex(
      (item) => item === tagId
    );
    if (position === -1) {
      levelsSelected.push(tagId);
    } else levelsSelected.splice(position, 1);
    setSelectedTags(levelsSelected)
  }

  return (
    <Stack tokens={{ childrenGap: 15 }} className={styles.tagsContainer}>
      {toggleView ? (
        <Stack tokens={{ childrenGap: 30 }} className={styles.tagsContent}>
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              {tagsData?.travelerAvailableTags?.nodes.length === 0 &&
                !tagsLoading && (
                  <Stack.Item className={styles.emptyTagsView}>
                    <Text>No available tags to add!</Text>
                  </Stack.Item>
                )}
              <Stack
                wrap
                horizontal
                tokens={{ childrenGap: 10 }}
                className={styles.customWidth}
              >
                {tagsData?.travelerAvailableTags?.nodes &&
                  tagsData.travelerAvailableTags?.nodes.map((tag) => {
                    return (
                      <Stack key={tag.id}>
                        <ToggleButton
                          onClick={() => onTagPressed(tag.id)}
                          toggled={isSelected(tag.id)}
                          key={tag.id}
                          text={tag.badgeName}
                          iconName={isSelected(tag.id) ? 'Accept' : 'Add'}
                          color={
                            isSelected(tag.id) && tag.badgeColor ? tag.badgeColor : undefined
                          }
                        />
                      </Stack>
                    );
                  })}
              </Stack>
            </Stack>
            <IconButton
              onClick={() => setToggleView(false)}
              iconProps={{ iconName: 'Cancel' }}
              className={styles.closeButton}
              ariaLabel="Close"
            />
          </Stack>
          <Stack tokens={{ childrenGap: 20 }} horizontal>
            <CommandBarButton
              className={styles.commandBarButton}
              iconProps={{ iconName: 'Accept' }}
              disabled={disableAddTags}
              onClick={() => {
                AddTag();
              }}
              text={'Add tags'}
            />
            <CommandBarButton
              className={styles.commandBarButton}
              iconProps={{ iconName: 'Cancel' }}
              disabled={selectedTags.length === 0}
              onClick={() => {
                setSelectedTags([]);
              }}
              text="Cancel"
            />
          </Stack>
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="end">
          <DefaultButton
            onClick={() => setToggleView(true)}
            iconProps={{ iconName: 'Add' }}
            text={'Add Tags'}
          />
        </Stack>
      )}
      <>
        {entityTagsLoading && (
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, width: 100, height: 40 },
            ]}
          />
        )}
        {!entityTagsLoading && (
          <Stack
            wrap
            horizontal
            tokens={{ childrenGap: 10 }}
            className={styles.customWidth}
          >
            {entityTagsData?.traveler?.entityTagsByEntityId.nodes.map(
              (entityTag) => {
                const createdDate = dateFormat(entityTag._createdDate!);
                const createdByUser = entityTag.createdByUser;
                const tooltipContent = `${createdByUser} / ${createdDate}`;
                return (
                  <TooltipHost
                    content={tooltipContent}
                    id="tooltipId"
                    calloutProps={calloutProps}
                    styles={hostStyles}
                    key={entityTag.id}
                  >
                    <Chip
                      disabled={loadingRemoveTag}
                      text={entityTag._tagBadgeName!}
                      onClick={() => {
                        removeTag({
                          id: entityTag.id || '',
                          _rowTimestamp: entityTag._rowTimestamp || '',
                        });
                      }}
                      color={entityTag._tagBadgeColor!}
                    />
                  </TooltipHost>
                );
              }
            )}
          </Stack>
        )}
        <NoDataView
          title={'No tags available!'}
          show={
            entityTagsData?.traveler?.entityTagsByEntityId.nodes.length === 0 &&
            !entityTagsLoading
          }
        />
      </>
    </Stack>
  );
};