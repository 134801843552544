import { useQuery } from '@apollo/client';
import { Separator, Stack } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import { loader } from 'graphql.macro';
import { BatchCommonData } from 'postingTracker/batchEdit/batch/__generated__/BatchCommonData';
import React from 'react';
import { BatchTransaction_batchTransaction } from '../../__generated__/BatchTransaction';
import { BatchTransactionAvailableDocumentTypes } from '../../__generated__/BatchTransactionAvailableDocumentTypes';
import { AttachForm } from './AttachForm';
import { columns } from './coloumn.data';
import { useStyles } from './index.styles';
import { UploadForm } from './UploadForm';
import { BatchTransactionUploadDocument_batchTransactionUploadDocument } from './UploadForm/__generated__/BatchTransactionUploadDocument';
const BATCH_TRANSACTION_AVAILABLE_DOCUMENT_TYPES = loader(
  '../../BatchTransactionAvailableDocumentTypes.graphql'
);
const BATCH_TRANSACTION_COMMON_DATA = loader(
  '../../../BatchCommonData.graphql'
);

interface AttachProps {
  batchTransaction: BatchTransaction_batchTransaction;
  onRemove?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: BatchTransactionUploadDocument_batchTransactionUploadDocument,
    toastId: string
  ) => void;
}

export const Attach: React.FC<AttachProps> = ({
  batchTransaction,
  onUpload,
  onRemove,
}) => {
  const styles = useStyles();
  const { data: batchCommonData } = useQuery<BatchCommonData>(
    BATCH_TRANSACTION_COMMON_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { data: availableDocumentTypes } =
    useQuery<BatchTransactionAvailableDocumentTypes>(
      BATCH_TRANSACTION_AVAILABLE_DOCUMENT_TYPES,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10, padding: '0px 25px 0px 0px' }}
      >
        <AttachForm
          availableDocumentTypes={availableDocumentTypes}
          batchTransaction={batchTransaction}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={availableDocumentTypes}
          batchTransaction={batchTransaction}
          onUpload={onUpload}
        />
      </Stack>
      {batchTransaction?.entityDocumentsByEntityId?.nodes.length! > 0 && (
        <Stack
          tokens={{
            childrenGap: 10,
          }}
        >
          <DocumentList
            isNew={false}
            data={batchTransaction?.entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={batchCommonData?.secureRowLevels?.nodes}
            onRemoveRow={(data) => {
              onRemove?.();
            }}
            columns={columns}
          />
        </Stack>
      )}
      <Stack tokens={{ padding: '0px 25px' }}>
        <NoDataView
          show={!batchTransaction?.entityDocumentsByEntityId?.totalCount}
          title="No Attachments"
        />
      </Stack>
    </Stack>
  );
};
