import { useQuery } from '@apollo/client';
import {
  IColumn,
  Selection,
  SelectionMode,
  Stack,
  Text,
  makeStyles,
} from '@fluentui/react';
import { InfiniteList } from 'common/components/InfiniteList';
import { loader } from 'graphql.macro';
import React, { useRef } from 'react';
import {
  AvailableApproverList,
  AvailableApproverList_availableApproverList_nodes,
} from './__generated__/AvailableApproverList';
import { columns } from './column.data';
const AVAILABLE_APPROVER_LIST = loader('./AvailableApproverList.graphql');
export type AvailableApproverListType =
  AvailableApproverList_availableApproverList_nodes;
interface AvailableApproversListProps {
  onRowSelection: (data: AvailableApproverListType[]) => void;
}
const useStyles = makeStyles(() => ({
  container: {
    height: 500,
  },
  row: {
    height: 25,
  },
}));

export const AvailableApproversList: React.FC<AvailableApproversListProps> = ({
  onRowSelection,
}) => {
  const styles = useStyles();

  const { loading, data } = useQuery<AvailableApproverList>(
    AVAILABLE_APPROVER_LIST,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const transformedData = data?.availableApproverList?.nodes?.map(
    (item) =>
      ({
        ...item,
        key: item.id,
        name: item.user?.name,
        _activeApprovalCount: item._activeApprovalCount,
      } as AvailableApproverListType)
  );

  const _renderItemColumn = (
    item: AvailableApproverListType | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof AvailableApproverListType
      ] as string;
      return (
        <Stack verticalAlign="center" className={styles.row}>
          {fieldContent && <Text>{fieldContent}</Text>}
        </Stack>
      );
    }
  };

  const selection: React.MutableRefObject<
    Selection<AvailableApproverListType>
  > = useRef(
    // @ts-ignore
    new Selection<InvoiceSchedulesItem>({
      getKey: (item: AvailableApproverListType) => item.id,
      onSelectionChanged: () =>
        onRowSelection(
          selection.current.getSelection().filter((item) => !!item)
        ),
    })
  );

  return (
    <Stack className={styles.container}>
      <InfiniteList
        items={transformedData || []}
        loading={loading}
        hasNextPage={false}
        columns={columns}
        selectionMode={SelectionMode.single}
        onRenderItemColumn={_renderItemColumn}
        onSelectionChanged={onRowSelection}
      />
    </Stack>
  );
};
