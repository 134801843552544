import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Text, Icon } from '@fluentui/react';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  EntityDeleteInput,
  EntityDocumentsOrderBy,
} from 'common/types/globalTypes';
import { TABLE_ROWS } from 'common/constants';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { toReportOrderByVariable } from './reportUtils';
import {
  PersoanlReports,
  PersoanlReportsVariables,
  PersoanlReports_entityDocumentPersonalPools_nodes,
} from './__generated__/PersoanlReports';
import { useStyles } from './index.styles';
import { useToasts } from 'react-toast-notifications';
import { reportsColumns } from './columns.data';
import { ColumnData } from 'common/components/SearchBar';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import clsx from 'clsx';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  EntityDocumentDelete,
  EntityDocumentDeleteVariables,
} from 'common/graphql/__generated__/EntityDocumentDelete';

const REPORTSLIST = loader('./PersoanlReports.graphql');
const DELETE_DOCUMENTS = loader(
  '../../../common/graphql/EntityDocumentDelete.graphql'
);
type DocumentProps = PersoanlReports_entityDocumentPersonalPools_nodes;

interface ReportListProps {
  selectedItems: (dataList: DocumentProps[]) => void;
  callDeleteFunction?: boolean;
  resetFunctionCall: (value: boolean) => void;
}
export let listVariables: PersoanlReportsVariables | undefined;
export const ReportList: React.FC<ReportListProps> = ({
  selectedItems,
  callDeleteFunction,
  resetFunctionCall,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(reportsColumns);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>({
    column: 'date',
    direction: 'desc' as OrderDirection,
  });
  const [selectedList, setselectedList] = useState<DocumentProps[]>([]);
  const {
    loading: personalReportLoading,
    data: personalReportListData,
    fetchMore,
    refetch,
    variables,
    networkStatus,
  } = useQuery<PersoanlReports, PersoanlReportsVariables>(REPORTSLIST, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [
        EntityDocumentsOrderBy._CREATED_DATE_DESC,
        EntityDocumentsOrderBy.PRIMARY_KEY_ASC,
      ],
      filter: {
        _isReportingDocument: { equalTo: true },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const handleSortAndPagination = (
    sortOrder?: SortOrder,
    showMore?: boolean
  ) => {
    const variables: PersoanlReportsVariables = {
      first: TABLE_ROWS,
      orderBy: toReportOrderByVariable(sortOrder),
      filter: {
        _isReportingDocument: { equalTo: true },
      },
      after: showMore
        ? personalReportListData?.entityDocumentPersonalPools?.pageInfo
            ?.endCursor
        : null,
    };
    if (showMore) fetchMore({ variables });
    else refetch(variables);
  };
  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const sortColumns = (columnName: string, direction: string) => {
    let orderBy = {
      column: columnName,
      direction: direction as OrderDirection,
    };
    setSortOrderParam(orderBy);
    handleSortAndPagination(orderBy);
  };

  const _onColumnClick = (column: ColumnData) => {
    const newColumns: ColumnData[] = gridColumns.slice();
    const currColumn: ColumnData = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: ColumnData) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
      }
    });
    setGridColumns(newColumns);
    let direction = 'asc';
    if (column.isSortedDescending === true) {
      direction = 'asc';
      sortColumns(column.key, direction);
    } else {
      direction = 'desc';
      sortColumns(column.key, direction);
    }
  };

  const [deleteDocuments] = useMutation<
    EntityDocumentDelete,
    EntityDocumentDeleteVariables
  >(DELETE_DOCUMENTS, { errorPolicy: 'all' });

  const _renderItemColumn = (
    item: DocumentProps | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      switch (column?.key) {
        case 'documentTypes':
          return (
            <Stack
              horizontal
              tokens={{ childrenGap: 15 }}
              className={styles.onrenderColumnStack}
            >
              {item?.documentTypes && (
                <Text variant="medium">
                  {item.documentTypes.documentType
                    ? item.documentTypes.documentType
                    : '-'}
                </Text>
              )}
            </Stack>
          );
        case '_documentContents':
          return (
            <Stack
              horizontal
              tokens={{ childrenGap: 15 }}
              className={styles.onrenderColumnStack}
            >
              {item._documentContents && (
                <Text variant="medium">{item._documentContents}</Text>
              )}
            </Stack>
          );
        case 'comment':
          return (
            <Stack
              horizontal
              tokens={{ childrenGap: 15 }}
              className={styles.onrenderColumnStack}
            >
              {item.comment && <Text variant="medium">{item.comment}</Text>}
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              horizontal
              tokens={{ childrenGap: 15 }}
              className={styles.onrenderColumnStack}
            >
              <Icon
                iconName={fileType(item.iconType || 'OTHER')}
                className={fileTypeColor(item.iconType || 'OTHER')}
              />
              <Text variant="medium">{item.fileReference}</Text>
            </Stack>
          );
        case '_createdDate':
          return (
            <Stack tokens={{ childrenGap: 15 }} verticalAlign="center">
              <Text
                variant="medium"
                className={clsx(
                  styles.contentColumnAlignRight,
                  styles.paddinRight25
                )}
              >
                {getGlobalDateFormat(item._createdDate || '')}
              </Text>
            </Stack>
          );
        case '_sharedByUser':
          return (
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 15 }}
              className={styles.onrenderColumnStack}
            >
              {item._sharedByUser && (
                <Text variant="medium">{item._sharedByUser}</Text>
              )}
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
              ></Stack>
            </Stack>
          );
      }
    }
  };

  const handleDelete = async () => {
    const selectionArray: DocumentProps[] = selectedList;
    const selectedData = selectionArray;
    const toDelete: DocumentProps[] = selectedData.map((_, index) => {
      return selectedData![index];
    });
    let deletableDocs = toDelete?.filter((item) => item._isDeletable !== false);
    if (deletableDocs.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableDocs.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      const { errors } = await deleteDocuments({
        variables: { input: { entityDelete: deleteInputArray } },
        optimisticResponse: {
          entityDocumentDelete: {
            deletedEntities: deleteInputArray,
          },
        },
        update: (cache, { data }) => {
          const deletedIds = data?.entityDocumentDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );
          if (deletedIds) {
            const filteredList =
              personalReportListData?.entityDocumentPersonalPools?.nodes.filter(
                (emp) => deletedIds.indexOf(emp.id) === -1
              );
            const newData: PersoanlReports = {
              entityDocumentPersonalPools: {
                nodes: filteredList!,
                pageInfo:
                  personalReportListData?.entityDocumentPersonalPools
                    ?.pageInfo!,
                totalCount:
                  personalReportListData?.entityDocumentPersonalPools
                    ?.totalCount! - deletedIds.length,
              },
            };
            cache.writeQuery<PersoanlReports, PersoanlReportsVariables>({
              query: REPORTSLIST,
              variables,
              data: newData,
            });
          }
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        resetFunctionCall(false);
        addToast('Record deleted Successfully', {
          appearance: 'success',
        });
      }
    }
  };
  const handleDeleteMemo = useCallback(handleDelete, [callDeleteFunction]);

  useEffect(() => {
    if (callDeleteFunction) {
      handleDeleteMemo();
    }
  }, [callDeleteFunction, handleDeleteMemo]);

  return (
    <>
      <InfiniteList
        loading={personalReportLoading}
        items={
          !refetching
            ? personalReportListData?.entityDocumentPersonalPools?.nodes || []
            : undefined
        }
        hasNextPage={
          personalReportListData?.entityDocumentPersonalPools?.pageInfo
            .hasNextPage
        }
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={() => handleSortAndPagination(sortOrderParam, true)}
        onSelectionChanged={(arr) => {
          setselectedList(arr);
          selectedItems(arr);
        }}
      />
    </>
  );
};
