import { Stack } from '@fluentui/react';
import React, { useCallback } from 'react';
import { useStyles } from './index.styles';
import { NewMessage } from './NewMessage';
import { NetworkStatus, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  MessagesListsProps,
  MessagesSections,
  PivotKeys,
} from 'common/components/MessagesSections';
import {
  GetMessageList,
  GetMessageListVariables,
} from './__generated__/GetMessageList';
interface PaymentTrackingMessageProps
  extends Pick<MessagesListsProps, 'userMessageCounts'> {
  paymentID: string;
  onCreateMessage: () => void;
  isViewOnly?: boolean;
}
const GET_PAYMENT_MESSAGE_LIST = loader('./GetMessageList.graphql');

export const PaymentTrackingMessage: React.FC<PaymentTrackingMessageProps> = ({
  paymentID,
  userMessageCounts,
  onCreateMessage,
  isViewOnly = false,
}) => {
  const styles = useStyles();

  const [selectedKey, setSelectedKey] = React.useState<string>(
    PivotKeys.received
  );

  const {
    loading: loadingMessageList,
    data: messageListData,
    variables: messageListVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<GetMessageList, GetMessageListVariables>(
    GET_PAYMENT_MESSAGE_LIST,
    {
      variables: {
        id: paymentID,
        first: 10,
        filter: { _isIncomingMessage: { equalTo: true } },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const onListChange = useCallback(
    async (listName?: string) => {
      if (listName) setSelectedKey(listName);
      const _isOutgoingMessage = { equalTo: listName === PivotKeys.sent };
      await refetch({
        ...messageListVariables,
        filter: {
          _isOutgoingMessage,
        },
      });
    },
    [refetch, messageListVariables]
  );

  const loadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        ...messageListVariables,
        after:
          messageListData?.payment?.userMessagesByEntityId.pageInfo.endCursor,
      },
    });
  }, [fetchMore, messageListVariables, messageListData]);

  const data =
    networkStatus === NetworkStatus.setVariables
      ? undefined
      : messageListData?.payment?.userMessagesByEntityId.nodes;
  return (
    <Stack className={styles.container}>
      <Stack className={styles.marginT10}>
        <MessagesSections
          userMessageCounts={userMessageCounts}
          entityId={paymentID}
          data={data || []}
          loadingMessages={!data?.length && loadingMessageList}
          onListChange={onListChange}
          onLoadMore={loadMore}
          hasNextPage={
            messageListData?.payment?.userMessagesByEntityId?.pageInfo
              ?.hasNextPage
          }
        >
          {!isViewOnly && (
            <NewMessage
              onCreateMessage={onCreateMessage}
              selectedKey={selectedKey}
              paymentID={paymentID}
            />
          )}
        </MessagesSections>
      </Stack>
    </Stack>
  );
};
