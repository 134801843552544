import { Stack, Text, makeStyles } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    cursor: 'move',
  },
  row: {
    height: 30
  }
}));

interface HeaderProps {
  onClose: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  onClose,
  children
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const _onClose = () => {
    onClose()
  }

  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20
      }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 20 }}
        className={styles.row}
        verticalAlign="center"
      >
        <Text variant='xLarge' className={commonStyles.colorThemePrimary}>
          Select Revised PO
        </Text>
        {children}
        {/* <Filters
          onFilterChange={onFilterChange}
          filterOptions={filtersArray}
        /> */}
      </Stack>
      <CloseButton onClick={_onClose} />
    </Stack>
  )
}
