import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { PrimaryButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData, SearchBar } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { EntityDeleteInput, LookupCorporateAccountsOrderBy } from 'common/types/globalTypes';
import { EntityType } from 'common/types/utility';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { LookupCorporateAccountFields } from '../__generated__/LookupCorporateAccountFields';
import { CorporateAccountModal } from '../view/CorporateAccountModal';
import { CorporateChartAccountsView } from './CorporateChartAccountsView';
import { LookupCorporateAccountDelete, LookupCorporateAccountDeleteVariables } from './__generated__/LookupCorporateAccountDelete';
import { LookupCorporateAccountSearch, LookupCorporateAccountSearchVariables } from './__generated__/LookupCorporateAccountSearch';
import { getColumns } from './columns.data';
import { ColumnStylesProps, onRenderItems } from './columns.render';
import { useStyles } from "./index.styles";
import { onHandleSearch } from './utils';
const LOOKUP_CORPORATE_ACCOUNT_SEARCH = loader("./LookupCorporateAccountSearch.graphql");
const LOOKUP_CORPORATE_ACCOUNT_DELETE = loader('./LookupCorporateAccountDelete.graphql');

export type LookupCorporateAccount = Omit<
  LookupCorporateAccountFields,
  'departmentGroupId' |
  'departmentGroup' |
  'travelAccountTypeId' |
  'travelAccountType'
> & {
  department: string | null;
  travelAccount: string | null;
};

export const CorporateAccountsList = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const columnStyles: ColumnStylesProps = {
    onRenderColumnStack: styles.onRenderColumnStack,
  };
  const renderRef = useRef(false);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const [lookupName, setLookupName] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<LookupCorporateAccount[]>([]);
  const [chartOfAccountId, setChartOfAccountId] = useState<string | null>(null);

  const [
    getCorporateAccounts,
    {
      loading: lookupCorporateAccountLoading,
      data: lookupCorporateAccountData,
      fetchMore,
      networkStatus,
    }
  ] = useLazyQuery<LookupCorporateAccountSearch, LookupCorporateAccountSearchVariables>(
    LOOKUP_CORPORATE_ACCOUNT_SEARCH,
    {
      variables: {
        chartOfAccountId: chartOfAccountId || '',
        first: TABLE_ROWS,
        orderBy: [
          LookupCorporateAccountsOrderBy.LOOKUP_ACCOUNT_ASC,
          LookupCorporateAccountsOrderBy.LOOKUP_NAME_ASC,
          LookupCorporateAccountsOrderBy.PRIMARY_KEY_ASC
        ],
        lookupName: "",
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-only"
    }
  )

  const [deleteCorporateAccounts] =
    useMutation<
      LookupCorporateAccountDelete,
      LookupCorporateAccountDeleteVariables
    >(LOOKUP_CORPORATE_ACCOUNT_DELETE, { errorPolicy: "all" })

  const handleSearch = async (showMore: boolean) => {
    setSelectedRows([])
    const { queryVariables } =
      onHandleSearch(
        lookupName,
        showMore,
        lookupCorporateAccountData,
        chartOfAccountId,
        sortOrderParam,
      )
    if (showMore) fetchMore?.({ variables: queryVariables })
    else getCorporateAccounts({ variables: queryVariables })
  }

  const handleSearchMemo = useCallback(handleSearch, [
    sortOrderParam,
    lookupName,
    chartOfAccountId,
  ])

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const { nodes, pageInfo } = { ...lookupCorporateAccountData?.lookupCorporateAccountSearch }

  const transformedData: LookupCorporateAccount[] = nodes?.map((item) => ({
    ...item,
    department: item?.departmentGroup?.name || null,
    travelAccount: item.travelAccountType?.accountType || null,
  })) || []

  const _renderItemColumn = useCallback(
    onRenderItems(
      columnStyles
    ),
    [columnStyles]
  );

  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);

  const _onConfirm = async () => {
    const selectedData: LookupCorporateAccount[] = selectedRows;
    const deletedRows: LookupCorporateAccount[] = selectedData.filter(
      (item) => item._isDeletable
    );
    const entityDelete: EntityDeleteInput[] = selectedData
      .filter((item) => item._isDeletable)
      .map((item) => {
        return { id: item.id, rowTimestamp: item._rowTimestamp! };
      });
    const { errors } = await deleteCorporateAccounts({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache) {
        deletedRows.forEach((item) => {
          const identity = cache.identify({
            ...item,
          });
          cache.evict({ id: identity });
          cache.gc();
        });
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted successfully.', {
        appearance: 'success',
      });
    }
  };

  useEffect(() => {
    setCustomColumns()
  }, [setCustomColumns])

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
    else renderRef.current = true;
  }, [handleSearchMemo])

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          className={commonStyles.listTitleContainer}
        >
          <Stack
            horizontal
            tokens={{ childrenGap: 20 }}
          >
            <Text variant="xLarge">Corporate Accounts</Text>
            <CorporateChartAccountsView
              chartOfAccountId={chartOfAccountId}
              onChartOfAccountChange={setChartOfAccountId}
            />
          </Stack>
          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
          >
            <ActionMessageModal
              entityType={EntityType.CorporateAccount}
              disabled={!selectedRows.some((selected) => selected._isDeletable)}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((selected) => selected.lookupName || ''),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              text='New Corporate Account'
              iconProps={{ iconName: "Add" }}
              onClick={() => setIsOpen(true)}
            />
          </Stack>
        </Stack>
        <SearchBar
          columns={[]}
          searchEnabled
          isGlobalAvailable={false}
          onEnterPress={(value) => setLookupName(value)}
          onRefresh={() => handleSearch(false)}
        />
      </Stack>
      <InfiniteList
        loading={lookupCorporateAccountLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={pageInfo?.hasNextPage}
        items={
          refetching
            ? undefined
            : transformedData
        }
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={setSelectedRows}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true)}
      />
      {
        isOpen &&
        <CorporateAccountModal
          chartOfAccountId={chartOfAccountId}
          onClose={() => setIsOpen(false)}
        />
      }
    </>
  )
}
