import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  DatePicker,
  DayOfWeek,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DocumentList } from 'common/components/DocumentList';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { TABLE_ROWS } from 'common/constants';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  ApprovalState,
  EntityDocumentInput,
  PayCycleApprovalRevokeInput,
  PayCycleInput,
  PayCyclePatch,
  PayCyclesOrderBy,
  UploadStatusType,
} from 'common/types/globalTypes';
import { EntityType, PanelCommonProps } from 'common/types/utility';
import {
  dateConvertions,
  dateFormat,
  invoiceDateFormat,
  onFormatDate,
} from 'common/utils/dateFormats';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Prompt } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  payCycleSearch,
  payCycleSearchVariables,
} from '../list/__generated__/payCycleSearch';
import { PayCycleCommonTypes } from '../__generated__/PayCycleCommonTypes';
import {
  PayCycleDelete,
  PayCycleDeleteVariables,
} from '../__generated__/PayCycleDelete';
import { ActionsMenu } from './ActionMenu';
import {
  AttachDocumentsForm,
  attachedPaymentDocumentsData,
} from './AttachDocumentsForm';
import { BasicForm } from './BasicForm';
import { columns } from './coloumn.data';
import { PAYCYCLE_INITIAL_VALUES } from './constant';
import { useStyles } from './index.styles';
import { validationSchema } from './payCycleValidation';
import { Payments } from './Payments';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { PayCycleValues } from './types';
import {
  GetPayCycleDetails,
  GetPayCycleDetailsVariables,
} from './__generated__/GetPayCycleDetails';
import {
  PayCycleApprovalCreate,
  PayCycleApprovalCreateVariables,
} from './__generated__/PayCycleApprovalCreate';
import {
  PayCycleApprovalRevoke,
  PayCycleApprovalRevokeVariables,
} from './__generated__/PayCycleApprovalRevoke';
import {
  PayCycleCreate,
  PayCycleCreateVariables,
} from './__generated__/PayCycleCreate';
import {
  PayCycleUpdate,
  PayCycleUpdateVariables,
} from './__generated__/PayCycleUpdate';
import { ApprovalHistory } from 'common/components/ApprovalHistory';
import { UrgencyFolder } from './UrgencyFolder';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
const PAYCYCLE_DATA = loader('../PayCycleCommonTypes.graphql');
const PAY_CYCLE_CREATE = loader('./PayCycleCreate.graphql');
const GET_PAYCYCLE_DETAILS = loader('./GetPayCycleDetails.graphql');
const UPDATE_PAYCYCLE = loader('./PayCycleUpdate.graphql');
const PAY_CYCLE_APPROVAL_REVOKE = loader('./PayCycleApprovalRevoke.graphql');
const PAY_CYCLE_APPROVAL_CREATE = loader('./PayCycleApprovalCreate.graphql');

const DELETE_PAY_CYCLES = loader('../PayCycleDelete.graphql');
const PAY_CYCLES = loader('../list/PayCycleSearch.graphql');
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
export const PayCycleDetails = () => {
  const { addToast, updateToast } = useToasts();
  const styles = useStyles();
  const history = useHistory();
  const commonStyles = useCommonStyles();
  const { cycleId } = useParams<{ cycleId: string | undefined }>();

  const isNew = !cycleId;
  const panelHeading = isNew ? 'Create Pay Cycle' : 'Edit Pay Cycle';
  const [panelVisibility, setPanelVisibility] = useState<boolean>(true);
  const [isSaveAnother, setIsSaveAnother] = useState<boolean>(false);

  const [requestComment, setRequestComment] = React.useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [attachedDocuments, setAttachedDocuments] =
    useState<attachedPaymentDocumentsData>();
  const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
  const CONFIRM_AMEND_DIALOG_SUBTEXT =
    'This will remove the Pay cycle from the approval cycle and require re-approval.';
  const CONFIRM_REQUEST_DIALOG_TITLE =
    'Are you sure you want to request this pay cycle for approval?';
  const CONFIRM_REQUEST_DIALOG_SUBTEXT =
    'Your pay cycle will be requested for approval.';
  const FINALIZED_BY_DATE_TOOLTIP =
    'Automatically promotes to urgent notifications when not approved by this date';

  const [payCycleApprovalCreate, { loading: payCycleApprovalLoading }] =
    useMutation<PayCycleApprovalCreate, PayCycleApprovalCreateVariables>(
      PAY_CYCLE_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );
  const [payCycleApprovalRevoke, { loading: payCycleApprovalRevokeLoading }] =
    useMutation<PayCycleApprovalRevoke, PayCycleApprovalRevokeVariables>(
      PAY_CYCLE_APPROVAL_REVOKE,
      { errorPolicy: 'all' }
    );

  const [
    getPayCycleDetails,
    { data: payCycleDetails, loading: payCycleDetailsLoading },
  ] = useLazyQuery<GetPayCycleDetails, GetPayCycleDetailsVariables>(
    GET_PAYCYCLE_DETAILS,
    {
      fetchPolicy: 'network-only',
    }
  );

  let panelInvoiceStatus: string;
  if (isNew) panelInvoiceStatus = '(Draft)';
  else if (
    !payCycleDetailsLoading &&
    payCycleDetails?.payCycle?.statusType?.approvalState !==
      ApprovalState.APPROVED_NOT_REQUIRED
  )
    panelInvoiceStatus = `(${payCycleDetails?.payCycle?.statusType
      ?.statusType!})`;
  else panelInvoiceStatus = '';

  let initialValues: PayCycleValues = PAYCYCLE_INITIAL_VALUES;
  if (payCycleDetails?.payCycle && !isNew) {
    initialValues = {
      ...payCycleDetails.payCycle,
      defaultPaymentDate: payCycleDetails.payCycle.defaultPaymentDate
        ? dateConvertions(payCycleDetails.payCycle.defaultPaymentDate!)
        : null,
    };
  } else initialValues = PAYCYCLE_INITIAL_VALUES;

  const { data: payCycleTypeData, loading: payCycleTypeLoading } =
    useQuery<PayCycleCommonTypes>(PAYCYCLE_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const getPayCycleDetailsMemo = useCallback(getPayCycleDetails, [cycleId]);
  useEffect(() => {
    if (cycleId && cycleId !== '') {
      getPayCycleDetailsMemo({
        variables: {
          id: cycleId,
        },
      });
    }
  }, [cycleId, getPayCycleDetailsMemo]);

  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/pay/payment_cycle');
          setPanelVisibility(false);
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {payCycleDetails?.payCycle?.transactionType?.transactionType &&
              !isNew && (
                <Text
                  variant="xLarge"
                  className={commonStyles.colorThemePrimary}
                >
                  {payCycleDetails?.payCycle?.transactionType?.transactionType}
                </Text>
              )}
            <UnsavedIndicator
              visible={
                !isNew && !payCycleDetailsLoading && dirty && !isSubmitting
              }
            />
          </Stack>
          <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            {payCycleDetails?.payCycle?.statusType?.statusType ===
              'Pending' && <UrgencyFolder payCycleDetails={payCycleDetails} />}
            <ActionMessageModal
              visible={!isNew}
              entityType={EntityType.PayCycle}
              disabled={!payCycleDetails?.payCycle?._isDeletable}
              onConfirm={deletepayCycle}
            />
            <Text className={styles.payCycleStatus}>{panelInvoiceStatus}</Text>
          </Stack>
        </Stack>
      </PanelHeader>
    );
  };
  const [createpayCycle, { loading: createPayCycleLoading }] = useMutation<
    PayCycleCreate,
    PayCycleCreateVariables
  >(PAY_CYCLE_CREATE, {
    errorPolicy: 'all',
  });

  const [updatePayCycle, { loading: updatePayCycleLoading }] = useMutation<
    PayCycleUpdate,
    PayCycleUpdateVariables
  >(UPDATE_PAYCYCLE, {
    errorPolicy: 'all',
  });

  const [deletePayCycle, { loading: deletePayCycleLoading }] = useMutation<
    PayCycleDelete,
    PayCycleDeleteVariables
  >(DELETE_PAY_CYCLES, {
    errorPolicy: 'all',
  });

  const handleSubmit = async (values: PayCycleValues) => {
    if (isNew) {
      const entityDocuments = attachedDocuments?.attachedDocuments?.map(
        (item) =>
          ({
            id: item.id,
            comment: item.comment,
            entityId: item.entityId,
            fileReference: item.fileReference,
            fileIndexInformation: item.fileIndexInformation,
            entityDocumentTypeId: item.entityDocumentTypeId,
            indexName: item.indexName,
            indexDescription: item.indexDescription,
            indexTransactionDate: item.indexTransactionDate,
            indexAmount: item.indexAmount,
            indexCurrencyId: item.indexCurrencyId,
            documentAppliedAmount: item.documentAppliedAmount,
            documentStatusExplanation: item.documentStatusExplanation,
            isAppliedAmountRetired: item.isAppliedAmountRetired,
            isAppliedAmountOverage: item.isAppliedAmountOverage,
            documentRetiredAmount: item.documentRetiredAmount,
          } as EntityDocumentInput)
      );
      let newID = '';
      const { errors } = await createpayCycle({
        variables: {
          input: {
            entityDocuments,
            payCycle: {
              ...values,

              defaultPaymentDate: values.defaultPaymentDate
                ? invoiceDateFormat(values.defaultPaymentDate)
                : null,
            } as PayCycleInput,
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<payCycleSearch>({
            query: PAY_CYCLES,
          });
          newID = data?.payCycleCreate?.payCycle?.id!;
          if (cacheData?.payCycleSearch && data?.payCycleCreate?.payCycle?.id) {
            const new_paymentCount =
              Number(
                cacheData?.payCycleSearch?.aggregates?.sum?._paymentCount
              ) + Number(data.payCycleCreate.payCycle._paymentCount);
            const new_totalPayments =
              Number(
                cacheData?.payCycleSearch?.aggregates?.sum?._totalPayments
              ) + Number(data.payCycleCreate.payCycle._totalPayments);
            const newcontrolTotalAmount =
              Number(
                cacheData?.payCycleSearch?.aggregates?.sum?.controlTotalAmount
              ) + Number(data.payCycleCreate.payCycle.controlTotalAmount);
            const newData: payCycleSearch = {
              payCycleSearch: {
                ...cacheData?.payCycleSearch!,
                pageInfo: cacheData?.payCycleSearch?.pageInfo!,
                totalCount: cacheData?.payCycleSearch?.totalCount! + 1,
                nodes: [
                  data?.payCycleCreate?.payCycle!,
                  ...cacheData?.payCycleSearch?.nodes!,
                ],
                aggregates: {
                  ...cacheData?.payCycleSearch?.aggregates,
                  sum: {
                    ...cacheData?.payCycleSearch?.aggregates?.sum,
                    _paymentCount: new_paymentCount.toString(),
                    _totalPayments: new_totalPayments.toString(),
                    controlTotalAmount: newcontrolTotalAmount.toString(),
                  },
                },
              },
            };
            cache.writeQuery<payCycleSearch, payCycleSearchVariables>({
              query: PAY_CYCLES,
              data: newData,
            });
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        if (!isSaveAnother) {
          history.push(`/pay/payment_cycle/cycle/${newID}`);
        }
        addToast('Pay cycle created successfully', {
          appearance: 'success',
        });
      }
    } else {
      const { ...paycycle } = values;
      const payCyclePatch: PayCyclePatch = Object.entries(paycycle).reduce(
        (res, [key, val]) => {
          if (val !== initialValues[key as keyof PayCycleValues]) {
            return { ...res, [key]: val };
          }
          return res;
        },
        {
          defaultPaymentDate: values.defaultPaymentDate
            ? invoiceDateFormat(values.defaultPaymentDate)
            : null,
        }
      );
      const { errors } = await updatePayCycle({
        variables: {
          input: {
            id: payCycleDetails?.payCycle?.id!,
            rowTimestamp: payCycleDetails?.payCycle?._rowTimestamp!,
            payCyclePatch: !isEmpty(payCyclePatch) ? payCyclePatch : undefined,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_PAYCYCLE_DETAILS,
            variables: {
              id: cycleId,
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else
        addToast('Pay cycle edited successfully', {
          appearance: 'success',
        });
    }
  };

  const removeDocument = (documentID: string) => {
    const filteredArray = attachedDocuments?.attachedDocuments.filter(
      (item) => item.id !== documentID
    );
    if (attachedDocuments?.attachedDocuments) {
      attachedDocuments.attachedDocuments =
        attachedDocuments?.attachedDocuments.filter(
          (item) => item.id !== documentID
        );
    }
    setAttachedDocuments({ attachedDocuments: filteredArray || [] });
  };

  const deletepayCycle = async () => {
    const { errors } = await deletePayCycle({
      variables: {
        input: {
          entityDelete: [
            {
              id: payCycleDetails?.payCycle?.id!,
              rowTimestamp: payCycleDetails?.payCycle?._rowTimestamp!,
            },
          ],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAY_CYCLES,
          variables: {
            first: TABLE_ROWS,
            orderBy: [PayCyclesOrderBy.PRIMARY_KEY_ASC],
          },
        },
      ],
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/pay/payment_cycle');
    }
  };

  const isLoading =
    createPayCycleLoading ||
    updatePayCycleLoading ||
    deletePayCycleLoading ||
    payCycleApprovalLoading ||
    payCycleApprovalRevokeLoading;
  const client = useApolloClient();
  return (
    <Formik<PayCycleValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, dirty, resetForm, errors, values }) => {
        const { paymentAccountId } = { ...values };
        return (
          <Form>
            <DraggablePanel
              {...PanelCommonProps}
              isBlocking={isNew === true ? true : false}
              initialWidth={1200}
              minWidth={1200}
              onRenderHeader={() => _onRenderHeader(dirty, isSubmitting)}
              onRenderFooter={() => (
                <FooterActionBar
                  createNewText="New Pay Cycle"
                  addNewForm={() => {
                    history.replace('/pay/payment_cycle/cycle');
                  }}
                  disabled={
                    payCycleTypeLoading || {
                      save: !dirty || Object.keys(errors).length > 0,
                      cancel: !dirty,
                    }
                  }
                  isSubmitting={isSubmitting}
                  isCreate={!cycleId ? true : false}
                  onCancel={() => history.replace('/pay/payment_cycle')}
                  onSave={async () => {
                    setIsSaveAnother(false);
                    await submitForm();
                  }}
                  onSaveAnother={async () => {
                    setIsSaveAnother(true);
                    await submitForm();
                    resetForm();
                  }}
                  isLoading={isLoading}
                  children={
                    payCycleDetails && (
                      <Stack>
                        {payCycleDetails?.payCycle
                          ?._isStagedApprovalRequest && (
                          <PrimaryButton
                            disabled={dirty}
                            className={styles.diabledButton}
                            text="Request Approval"
                            onClick={() => toggleConfirmDialog()}
                          />
                        )}
                        {payCycleDetails?.payCycle?._isApprovalRevocable && (
                          <PrimaryButton
                            disabled={dirty}
                            className={styles.diabledButton}
                            text="Amend Pay Cycle"
                            onClick={() => toggleConfirmAmendDialog()}
                          />
                        )}
                        <ConfirmDialog
                          isAmendButton
                          hidden={hideConfirmAmendDialog}
                          title={CONFIRM_AMEND_DIALOG_TITLE}
                          subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
                          onDismiss={toggleConfirmAmendDialog}
                          onConfirm={async () => {
                            toggleConfirmAmendDialog();
                            const inputVariables: PayCycleApprovalRevokeInput =
                              {
                                entityId: payCycleDetails?.payCycle?.id!,
                                rowTimestamp:
                                  payCycleDetails?.payCycle?._rowTimestamp!,
                              };
                            const { errors } = await payCycleApprovalRevoke({
                              variables: {
                                input: inputVariables,
                              },
                              awaitRefetchQueries: true,
                              refetchQueries: [
                                {
                                  query: GET_PAYCYCLE_DETAILS,
                                  variables: {
                                    id: payCycleDetails?.payCycle?.id,
                                  },
                                },
                              ],
                            });
                            if (errors?.length) {
                              addToast(errors[0].message, {
                                appearance: 'error',
                              });
                            } else {
                              addToast('Approval amended successfully', {
                                appearance: 'success',
                              });
                            }
                          }}
                        />
                        <ConfirmDialog
                          hidden={hideConfirmRequestDialog}
                          title={CONFIRM_REQUEST_DIALOG_TITLE}
                          subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
                          onDismiss={toggleConfirmDialog}
                          minWidth={500}
                          onConfirm={async () => {
                            toggleConfirmDialog();
                            const inputVariables: ApprovalRequestInput = {
                              entityId: payCycleDetails?.payCycle?.id!,
                              rowTimestamp:
                                payCycleDetails?.payCycle?._rowTimestamp!,
                              comments: requestComment,
                            };
                            if (requiredDate)
                              inputVariables.requiredDate = requiredDate;
                            const { errors } = await payCycleApprovalCreate({
                              variables: {
                                input: {
                                  entityApproval: [inputVariables],
                                  urgencyLevel: urgencyLevel,
                                },
                              },
                              awaitRefetchQueries: true,
                              refetchQueries: [
                                {
                                  query: GET_PAYCYCLE_DETAILS,
                                  variables: {
                                    id: payCycleDetails?.payCycle?.id!,
                                  },
                                },
                              ],
                            });
                            if (errors?.length) {
                              addToast(errors[0].message, {
                                appearance: 'error',
                              });
                            } else {
                              setRequestComment('');
                              setRequiredDate('');
                              addToast('Request sent for approval', {
                                appearance: 'success',
                              });
                            }
                          }}
                        >
                          <Stack tokens={{ childrenGap: 10 }}>
                            <TextField
                              className={styles.marginT10}
                              multiline
                              rows={3}
                              value={requestComment}
                              placeholder="Please write your comment here (optional)"
                              resizable={false}
                              onChange={(_event, value) =>
                                setRequestComment(value || '')
                              }
                            />
                            <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                              <DatePicker
                                minDate={new Date()}
                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder="Finalized by date (optional)"
                                ariaLabel="Date"
                                formatDate={onFormatDate}
                                showWeekNumbers
                                firstWeekOfYear={1}
                                showMonthPickerAsOverlay
                                showGoToToday={false}
                                onSelectDate={(date) =>
                                  setRequiredDate(dateFormat(date!.toString()))
                                }
                              />
                            </TooltipHost>
                            <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                              <UrgencyToggle
                                onToggle={(data) => {
                                  setUrgencyLevel(data ? 0 : 1);
                                }}
                              />
                            </Stack>
                          </Stack>
                        </ConfirmDialog>
                      </Stack>
                    )
                  }
                />
              )}
              isOpen={panelVisibility}
              onDismiss={() => {
                history.replace('/pay/payment_cycle');
              }}
              isLightDismiss
            >
              {!isNew && payCycleDetailsLoading ? (
                <ShimmerView />
              ) : (
                <Stack>
                  {!isNew && (
                    <Stack>
                      <Stack
                        className={styles.headerContainer}
                        tokens={{ childrenGap: 10 }}
                      >
                        {payCycleDetails?.payCycle
                          ?._isApprovalDocumentsRequired &&
                          payCycleDetails?.payCycle
                            ?._requiredApprovalDocuments && (
                            <MessageBar messageBarType={MessageBarType.error}>
                              {`${payCycleDetails?.payCycle?._requiredApprovalDocuments}`}
                            </MessageBar>
                          )}
                        <ActionsMenu
                          secureRowLevels={payCycleTypeData?.secureRowLevels}
                          payCycleId={cycleId || ''}
                          payCycleDetails={payCycleDetails}
                          onUpload={async (fileSelected, document, toastId) => {
                            const observer = client.subscribe({
                              query: DOCUMENT_UPLOAD_STATUS,
                              variables: {
                                documentId: document.document._documentFileId!,
                              },
                            });

                            const subscription = observer.subscribe((data) => {
                              const subscribedData =
                                data.data as OnDocumentUploadStatus;

                              const { status, document } = {
                                ...subscribedData.documentUploadStatus,
                              };

                              if (!document) {
                                if (
                                  status.type === UploadStatusType.VALIDATING
                                ) {
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Validating files ${fileSelected.name} - ${status.message}`
                                      : `Validating files ${fileSelected.name}`,
                                    appearance: 'info',
                                    autoDismiss: false,
                                  });
                                } else if (
                                  status.type === UploadStatusType.EXTRACTING
                                ) {
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                      : `Extracting data from ${fileSelected.name}`,
                                    appearance: 'info',
                                    autoDismiss: false,
                                  });
                                } else if (
                                  status.type === UploadStatusType.FAILURE
                                ) {
                                  subscription.unsubscribe();
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                      : `Upload of ${fileSelected.name} failed`,
                                    appearance: 'error',
                                    autoDismiss: true,
                                  });
                                } else if (
                                  status.type === UploadStatusType.WARNING
                                ) {
                                  subscription.unsubscribe();
                                  updateToast(toastId!, {
                                    content: status.message
                                      ? `Warning for file ${fileSelected.name}: ${status.message}`
                                      : `Warning for file ${fileSelected.name}`,
                                    appearance: 'warning',
                                    autoDismiss: true,
                                  });
                                }
                              } else {
                                subscription.unsubscribe();
                                updateToast(toastId!, {
                                  content: status.message
                                    ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                    : `Successfully uploaded ${fileSelected.name}`,
                                  appearance: 'success',
                                  autoDismiss: true,
                                });

                                const cacheData = client.readQuery<
                                  GetPayCycleDetails,
                                  GetPayCycleDetailsVariables
                                >({
                                  query: GET_PAYCYCLE_DETAILS,
                                  variables: {
                                    id: payCycleDetails?.payCycle?.id!,
                                  },
                                });
                                if (cacheData) {
                                  const updatedData: GetPayCycleDetails = {
                                    payCycle: {
                                      ...cacheData?.payCycle!,
                                      _isApprovalDocumentsRequired:
                                        document?.payCycleDocument
                                          ?._isApprovalDocumentsRequired!,
                                      _requiredApprovalDocuments:
                                        document?.payCycleDocument
                                          ?._requiredApprovalDocuments!,
                                      entityDocumentsByEntityId: {
                                        ...cacheData?.payCycle
                                          ?.entityDocumentsByEntityId,
                                        nodes: [
                                          document!,
                                          ...cacheData?.payCycle
                                            ?.entityDocumentsByEntityId.nodes!,
                                        ],
                                      },
                                    },
                                  };
                                  client.writeQuery<
                                    GetPayCycleDetails,
                                    GetPayCycleDetailsVariables
                                  >({
                                    query: GET_PAYCYCLE_DETAILS,
                                    data: updatedData,
                                    variables: {
                                      id: payCycleDetails?.payCycle?.id!,
                                    },
                                  });
                                }
                              }
                            });
                          }}
                        />
                      </Stack>
                      <Separator />
                    </Stack>
                  )}
                  {isNew && paymentAccountId && (
                    <AttachDocumentsForm
                      paymentAccountId={paymentAccountId}
                      onDocumentsAttached={(documentsData) => {
                        if (attachedDocuments?.attachedDocuments.length) {
                          setAttachedDocuments({
                            attachedDocuments: [
                              ...attachedDocuments.attachedDocuments,
                              ...documentsData.attachedDocuments,
                            ],
                          });
                        } else setAttachedDocuments(documentsData);
                      }}
                      prevSelectedDocuments={
                        attachedDocuments?.attachedDocuments
                      }
                    />
                  )}
                  <BasicForm
                    payCycleTypeData={payCycleTypeData}
                    disableConditions={{
                      isNew: isNew,
                      isUpdatable: payCycleDetails?.payCycle?._isUpdatable,
                    }}
                    totalPaymentAmount={
                      payCycleDetails?.payCycle?._totalPayments
                    }
                    payCycleId={payCycleDetails?.payCycle?.id}
                  />
                  {!isNew &&
                    payCycleDetails?.payCycle?.payments.nodes.length !== 0 && (
                      <Stack>
                        <Separator />
                        <Stack tokens={{ childrenGap: 20 }}>
                          <Payments
                            payCycle={payCycleDetails}
                            paymentListData={
                              payCycleDetails?.payCycle?.payments!
                            }
                          />
                        </Stack>
                      </Stack>
                    )}

                  {isNew && attachedDocuments?.attachedDocuments.length && (
                    <Stack className={styles.fileListContainer}>
                      <DocumentList
                        isNew={isNew}
                        columns={columns}
                        data={attachedDocuments?.attachedDocuments || []}
                        isDeleteButtonVisible
                        onRemoveRow={(data) => {
                          removeDocument(data.id);
                        }}
                      />
                    </Stack>
                  )}

                  {payCycleDetails?.payCycle?.approvalHistoriesByEntityId &&
                    payCycleDetails?.payCycle?.approvalHistoriesByEntityId.nodes
                      .length! > 0 &&
                    !isNew && (
                      <Stack>
                        <Separator />
                        <Stack
                          className={styles.approvalHistoryContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text variant="xLarge">Approval History</Text>
                          <Text
                            variant="medium"
                            className={styles.requestedByText}
                          >
                            Requested By:
                          </Text>
                          <ApprovalHistory
                            data={
                              payCycleDetails.payCycle
                                .approvalHistoriesByEntityId.nodes
                            }
                          />
                        </Stack>
                      </Stack>
                    )}
                </Stack>
              )}
              <Prompt
                when={dirty && !isSubmitting}
                message="Are you sure you want to leave your changes unsaved?"
              />
            </DraggablePanel>
          </Form>
        );
      }}
    </Formik>
  );
};
