import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  mainStack: {
    paddingTop: 10,
  },
  contactListUpperStack: {
    height: '560px',
    overflow: 'scroll',
    overflowX: 'clip',
  },
}));
