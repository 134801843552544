import {
  Separator,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection2 = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <ShimmerElementsGroup
        width="150px"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 30, width: 150 },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          {
            type: ShimmerElementType.gap,
            height: 30,
            width: '100%',
          },
        ]}
      />
    </div>
  );
};

export const ShimmerSection3 = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '80%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 20, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '80%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 20, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '80%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 20, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '80%' },
        ]}
      />
    </div>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
        <Stack className={styles.containerpadding}>
          <Shimmer customElementsGroup={ShimmerSection()} />
        </Stack>
        <Stack className={styles.containerpadding}>
          <Shimmer customElementsGroup={ShimmerSection()} />
        </Stack>
        <Stack className={styles.containerpadding}>
          <Shimmer customElementsGroup={ShimmerSection()} />
        </Stack>
      </Stack>
      <Separator />

      <Stack className={styles.containerpadding}>
        <Shimmer customElementsGroup={ShimmerSection2()} />
      </Stack>
      <Separator />

      <Stack className={styles.containerpadding}>
        <Shimmer customElementsGroup={ShimmerSection3()} />
      </Stack>
    </React.Fragment>
  );
};
