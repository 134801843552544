export interface GridColumnProps {
  key: string;
  headerName: string;
  minWidth?: number;
  maxWidth?: number;
  fixedWidth: number | boolean;
  flexProportion?: number;
  alignItems:
    | 'center'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'self-end'
    | 'self-start'
    | 'start';
}

export const gridColumns: GridColumnProps[] = [
  {
    key: 'tripLocator',
    headerName: 'Locator',
    fixedWidth: false,
    flexProportion: 2,
    alignItems: 'start',
  },
  {
    key: 'travelerReferenceName',
    headerName: 'Traveler',
    fixedWidth: false,
    flexProportion: 3,
    alignItems: 'start',
  },
  {
    key: 'accountReference',
    headerName: 'Account#',
    fixedWidth: false,
    flexProportion: 2,
    alignItems: 'center',
  },
  {
    key: 'airfareAmount',
    headerName: 'Airfare',
    fixedWidth: false,
    flexProportion: 1,
    alignItems: 'end',
    minWidth: 80,
    maxWidth: 120,
  },
  {
    key: 'accommodationAmount',
    headerName: 'Hotel/Acc',
    fixedWidth: false,
    flexProportion: 1,
    alignItems: 'end',
    minWidth: 100,
    maxWidth: 120,
  },
  {
    key: 'vehicleRentalAmount',
    headerName: 'Veh Rental',
    fixedWidth: false,
    flexProportion: 1,
    alignItems: 'end',
    minWidth: 100,
    maxWidth: 120,
  },
  {
    key: 'transportationAmount',
    headerName: 'Grnd Trans',
    fixedWidth: false,
    flexProportion: 1,
    alignItems: 'end',
    minWidth: 100,
    maxWidth: 120,
  },
  {
    key: 'trainAmount',
    headerName: 'Train',
    fixedWidth: false,
    flexProportion: 1,
    alignItems: 'end',
    minWidth: 70,
    maxWidth: 120,
  },
  {
    key: 'incidentalAmount',
    headerName: 'Other',
    fixedWidth: false,
    flexProportion: 1,
    alignItems: 'end',
    minWidth: 80,
    maxWidth: 120,
  },
  {
    key: 'controlTotalAmount',
    headerName: 'Total',
    fixedWidth: false,
    minWidth: 70,
    maxWidth: 120,
    flexProportion: 1,
    alignItems: 'end',
  },
];
