import React from 'react';
import { useStyles } from './index.styles';
import { Icon, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';

export type PivotMenuItemProps = {
  itemKey?: string;
  name: string;
  count?: number;
  iconName: string;
};
interface PivotDisplayIC {
  name: string;
  iconName: string;
  count?: number;
  onPivotClick: () => void;
}
export const PivotDisplay: React.FC<PivotDisplayIC> = ({
  name = 'Attach',
  iconName = 'Attach',
  count = 0,
  onPivotClick,
}) => {
  const commonstyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack
      onClick={() => onPivotClick?.()}
      className={styles.pivotDisplayWrapper}
    >
      <Stack horizontal verticalAlign="center">
        <Icon iconName={iconName} />
        <Stack className={styles.width5} />
        <Text>{name}</Text>
        {count !== 0 && (
          <Stack horizontal>
            <div className={styles.width10} />
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              className={styles.pivotDisplayCount}
            >
              <Text className={clsx(styles.countColor, commonstyles.semibold)}>
                {count}
              </Text>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
