import { IButtonProps, IconButton, IIconProps } from '@fluentui/react';
import useDownloadLink from 'common/hooks/useDownloadLink';
import React from 'react';
type DownloadButtonProps = Omit<IButtonProps, 'onClick' | 'iconProps'> & {
  iconProps?: Omit<IIconProps, 'iconName'>;
} & { entityDocumentId: GqlUUID; isDisable?: boolean };

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  entityDocumentId,
  isDisable,
  iconProps,
  ...props
}) => {
  const getLink = useDownloadLink();

  return (
    <IconButton
      disabled={isDisable}
      iconProps={{ ...iconProps, iconName: 'Download' }}
      onClick={async () => {
        const url = await getLink(entityDocumentId);
        if (url) window.open(url);
      }}
      {...props}
    />
  );
};
