import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexDirection: 'row',
      height: 50,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    linkLightHover: {
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    headerCell: {
      // flex: 1, 
      width: "100%", 
      paddingLeft: 15,
      height: 50,
    },
    yearHeaderCell: {
      // flex: 1,
      width: 190,
      minWidth: 190,
      maxWidth: 190,
      // width: "100%", 
      paddingLeft: 68,
      height: 50,
      // border: "1px solid blue"
    },
    actionHeaderCell: {
      flex: 1, 
      paddingLeft: 25,
      height: 50,
    },
    actionColumnWidth: {
      paddingLeft: 10,
      width: 370,
      minWidth: 370,
      maxWidth: 370,
      // border: "1px solid yellow",
    },
    regularColumnWidth: {
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      // border: "1px solid green",
      paddingLeft: 10,
    },
  };
});
