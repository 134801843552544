import {
  ColumnActionsMode,
  IColumn,
  IDetailsColumnStyles,
} from '@fluentui/react';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

export const columns: IColumn[] = [
  {
    key: 'entryDate',
    name: 'Entry Date',
    fieldName: 'entryDate',
    minWidth: 120,
    maxWidth: 180,
    ...commonProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 150,
    maxWidth: 170,
    ...commonProps,
  },
  {
    key: 'transactionType',
    name: 'Action',
    fieldName: 'transactionType',
    minWidth: 80,
    maxWidth: 90,
    ...commonProps,
  },
  {
    key: 'amount',
    name: 'Amount',
    fieldName: 'amount',
    minWidth: 140,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonProps,
  },
  {
    key: 'isoCode',
    name: 'Currency',
    fieldName: 'isoCode',
    minWidth: 150,
    maxWidth: 170,
    ...commonProps,
  },
  {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 60,
    maxWidth: 60,
    ...commonProps,
  },
  {
    key: 'download',
    name: '',
    fieldName: 'download',
    minWidth: 60,
    maxWidth: 60,
    ...commonProps,
  },
];
