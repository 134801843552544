import { CommunicationChannelsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: CommunicationChannelsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [CommunicationChannelsOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [CommunicationChannelsOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [CommunicationChannelsOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [CommunicationChannelsOrderBy.DESCRIPTION_DESC],
    },
  ],

  [
    '_createdDate',
    {
      [OrderDirection.ASC]: [CommunicationChannelsOrderBy._CREATED_DATE_ASC],
      [OrderDirection.DESC]: [CommunicationChannelsOrderBy._CREATED_DATE_DESC],
    },
  ],
  [
    'isReports',
    {
      [OrderDirection.ASC]: [CommunicationChannelsOrderBy.IS_REPORTS_ASC],
      [OrderDirection.DESC]: [CommunicationChannelsOrderBy.IS_REPORTS_DESC],
    },
  ],
  [
    'isMessages',
    {
      [OrderDirection.ASC]: [CommunicationChannelsOrderBy.IS_MESSAGES_ASC],
      [OrderDirection.DESC]: [CommunicationChannelsOrderBy.IS_MESSAGES_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [CommunicationChannelsOrderBy.NAME_ASC];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};
