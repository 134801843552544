import { useMutation } from '@apollo/client';
import { BlockBlobClient } from '@azure/storage-blob';
import { UploadFiles } from 'common/components/UploadFiles';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { loader } from 'graphql.macro';
import { SupplierDetails_supplier } from 'purchaseOrder/view/FormView/__generated__/SupplierDetails';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { SupplierUploadDocument, SupplierUploadDocumentVariables, SupplierUploadDocument_supplierUploadDocument } from 'settings/account/supplier/view/ActionMenu/SupplierAttach/UploadForm/__generated__/SupplierUploadDocument';
import { SupplierAvailableDocumentTypes } from 'settings/account/supplier/view/ActionMenu/SupplierAttach/__generated__/SupplierAvailableDocumentTypes';
const SUPPLIER_UPLOAD_DOCUMENT = loader('../../../../../../../settings/account/supplier/view/ActionMenu/SupplierAttach/UploadForm/SupplierUploadDocument.graphql');

interface UploadFormProps {
  availableDocumentTypes: SupplierAvailableDocumentTypes | undefined;
  supplier?: SupplierDetails_supplier;
  onUpload?: (
    fileSelected: File,
    documentData: SupplierUploadDocument_supplierUploadDocument,
    toastId: string
  ) => void;
}
export const UploadForm: React.FC<UploadFormProps> = ({
  availableDocumentTypes,
  supplier,
  onUpload,
}) => {
  const {
    formState: {
      isDirty
    }
  } = useFormContext<PurchaseOrderValues>();
  const [uploadDocument] = useMutation<
    SupplierUploadDocument,
    SupplierUploadDocumentVariables
  >(SUPPLIER_UPLOAD_DOCUMENT, { errorPolicy: 'all' });
  const { updateToast, addToast } = useToasts();
  const { isDraft, id } = { ...supplier };
  const documentTypeOptions =
    availableDocumentTypes?.supplierAvailableDocumentTypes?.nodes.map(
      (doctype) =>
      ({
        key: doctype.id,
        text: doctype.documentType || '',
        isAccountingDocument: doctype.isAccountingDocument,
        title:
          isDraft && doctype.isAccountingDocument
            ? 'Upload prohibited during/after the approval process'
            : undefined,
        disabled: (!isDraft && doctype.isAccountingDocument) || undefined,
      } as DocumentTypeOption)
    ) || [];
  return (
    <UploadFiles
      documentTypes={documentTypeOptions!}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, filSelected) => {
          filSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });
            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                supplierId: id!,
              },
            });
            if (uploadMutationResults.errors) {
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });
            }
            if (
              uploadMutationResults.data?.supplierUploadDocument?.document &&
              uploadMutationResults.data?.supplierUploadDocument?.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data?.supplierUploadDocument,
                toastId!
              );
              const client = new BlockBlobClient(
                uploadMutationResults.data?.supplierUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
      disableUpload={isDirty}
    />
  );
};
