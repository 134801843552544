import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { DepartmentGroupsVariables } from './__generated__/DepartmentGroups';
import { TABLE_ROWS } from 'common/constants';
import { DepartmentGroupsOrderBy } from 'common/types/globalTypes';

type OrderLookup = {
  [direction in OrderDirection]: DepartmentGroupsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [DepartmentGroupsOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [DepartmentGroupsOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [DepartmentGroupsOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [DepartmentGroupsOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'isTravel',
    {
      [OrderDirection.ASC]: [
        DepartmentGroupsOrderBy.IS_TRAVEL_DEPARTMENT_GROUP_ASC,
      ],
      [OrderDirection.DESC]: [
        DepartmentGroupsOrderBy.IS_TRAVEL_DEPARTMENT_GROUP_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const defaultOrderBy = [
    DepartmentGroupsOrderBy.NAME_ASC,
    DepartmentGroupsOrderBy.PRIMARY_KEY_ASC,
  ];
  const orderByArray = [DepartmentGroupsOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return defaultOrderBy;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const onHandleSearch = (sortOrderParam: SortOrder | undefined) => {
  const variables: DepartmentGroupsVariables = {
    first: TABLE_ROWS,
    orderBy: toOrderByVariable(sortOrderParam),
  };
  return {
    queryVariables: variables,
  };
};
