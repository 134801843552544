import React, { useEffect, useState } from 'react';
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { FontOptionView } from './FontOptionView';
import { fontOptionsStyles } from './fonts';
import { useCommonStyles } from 'common/styles';
import { CreateSignatureProps } from '..';
import { useStyles } from './index.styles';
import { ColorPickerCallout } from '../../ColorPickerCallout';
import WebFont from 'webfontloader';
import { useFormikContext } from 'formik';
import { SignatureValues } from '../../types';
import { ShimmerView } from './ShimmerViews';

type TextToSignatureProps = CreateSignatureProps;
export const TextToSignature: React.FC<TextToSignatureProps> = ({
  ...props
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Pushster',
          'Cookie',
          'Dancing Script: 700]',
          'Mrs Saint Delafield',
          'Yesteryear',
          'Pacifico',
          'Shadows Into Light',
          'Great Vibes',
          'Italianno',
          'Caveat',
          'Indie Flower',
          'Arizonia',
        ],
      },
    });
  }, []);

  return (
    <>
      <TooltipHost content={'Choose from a list of signature styles'}>
        <DefaultButton
          styles={{ root: { width: 200 } }}
          iconProps={{ iconName: 'EditStyle' }}
          text="Style Gallery"
          onClick={() => setVisible(true)}
        />
      </TooltipHost>
      {visible && (
        <TextToSignatureModal onDismiss={() => setVisible(false)} {...props} />
      )}
    </>
  );
};

interface TextToSignatureModalProps extends CreateSignatureProps {
  onDismiss: () => void;
  onSelect?: (imageUrl: string | null) => void;
}
export const TextToSignatureModal: React.FC<TextToSignatureModalProps> = ({
  onDismiss,
  name,
  onSelect,
}) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(true);
  const [GetSelection, setSetGetSelection] = useState(false);
  const commonStyles = useCommonStyles();
  const [penColor, setPenColor] = useState('black');
  const [styleSelected, setStyleSelected] = useState<{
    data: string | undefined;
    index: number | undefined;
  }>();
  const { setFieldValue } = useFormikContext<SignatureValues>();

  const onSelectStyle = (data: string, index: number) =>
    setStyleSelected({ data, index });

  const onSubmit = () => {
    onSelect?.(styleSelected?.data!);
    setFieldValue('imageUrl', styleSelected?.data);
    onDismiss();
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [loading]);

  const onColorChange = (color: string) => {
    setLoading(true);
    setSetGetSelection(false);
    onSelect?.(null);
    let styleValue = { ...styleSelected };
    setStyleSelected({ data: undefined, index: undefined });

    setTimeout(() => {
      setPenColor(color);
      if (styleValue) {
        setStyleSelected({
          data: styleSelected?.data,
          index: styleSelected?.index,
        });

        setTimeout(() => {
          setSetGetSelection(true);
        }, 500);
      }
    }, 300);
  };

  return (
    <Modal onDismiss={() => {}} isOpen>
      <Stack style={{ height: 700, width: 1050, overflowY: 'hidden' }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ padding: 25 }}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">Create Signature</Text>
            <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
              Text-to-Signature
            </Text>
          </Stack>
          <CloseButton onClick={onDismiss} />
        </Stack>

        {!loading && (
          <Stack
            tokens={{ childrenGap: 20, padding: 25 }}
            className={styles.fontStylesContainer}
            horizontal
            wrap
          >
            {fontOptionsStyles.map(({ ...props }, index) => (
              <FontOptionView
                key={index}
                {...props}
                name={name}
                index={index}
                GetSelection={GetSelection}
                selectedData={styleSelected}
                isSelected={styleSelected?.index === index}
                penColor={penColor}
                onSelectStyle={(data) => {
                  onSelectStyle(data, index);
                }}
              />
            ))}
          </Stack>
        )}

        {loading && <ShimmerView />}
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ padding: 25 }}
        >
          <ColorPickerCallout onColorChange={onColorChange} />

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <PrimaryButton text="Submit" onClick={onSubmit} />
            <DefaultButton text="Cancel" onClick={onDismiss} />
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
