import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const commonColumnProps: Partial<ColumnData> = {
  isResizable: false,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
  columnActionsMode: ColumnActionsMode.disabled,
};
const expenditureTypeStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 8,
  },
};
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const centerAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'center',
  },
};

export const columns: ColumnData[] = [
  {
    key: 'expenditureType',
    name: 'Type',
    fieldName: 'expenditureType',
    minWidth: 180,
    styles: expenditureTypeStyle,
    ...commonColumnProps,
  },
  {
    key: 'country1',
    name: 'From: Country',
    fieldName: 'country1',
    minWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'stateRegion1',
    name: 'State/Region',
    fieldName: 'stateRegion1',
    minWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'country2',
    name: 'To: Country',
    fieldName: 'country2',
    minWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'stateRegion2',
    name: 'State/Region',
    fieldName: 'stateRegion2',
    minWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'policyAllowance',
    name: 'Allowance',
    fieldName: 'policyAllowance',
    minWidth: 120,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'policyMiles',
    name: 'Miles',
    fieldName: 'policyMiles',
    minWidth: 100,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'isAllowanceCustomizable',
    name: 'Customizable',
    fieldName: 'isAllowanceCustomizable',
    minWidth: 100,
    styles: centerAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'action',
    name: '',
    fieldName: 'action',
    minWidth: 352,
    isResizable: false,
    ...commonColumnProps,
  },
];
