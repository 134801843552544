import { NetworkStatus, useQuery } from '@apollo/client';
import {
  ActionButton,
  IColumn,
  IconButton,
  IStackStyles,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';

import { EntityDocumentFilter } from 'common/types/globalTypes';
import { SortOrder } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useCallback, useMemo, useState } from 'react';
import { PayCycleValues } from '../types';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { toOrderByVariable } from './utils';
import { PayCycleAvailableDocumentTypes } from 'ap/paymentCycle/__generated__/PayCycleAvailableDocumentTypes';
import {
  AttachablePayCycleDocuments,
  AttachablePayCycleDocumentsVariables,
  AttachablePayCycleDocuments_attachablePayCycleDocuments_nodes,
} from 'ap/paymentCycle/__generated__/AttachablePayCycleDocuments';

const ATTACHABLE_PAY_CYCLE_DOCUMENTS = loader(
  '../../AttachablePayCycleDocuments.graphql'
);

const AVAILABLE_DOCUMENT_TYPES = loader(
  '../../PayCycleAvailableDocumentTypes.graphql'
);
interface ParamType {
  fieldName: string;
  value: string | number;
}

export type AttachableDataType =
  AttachablePayCycleDocuments_attachablePayCycleDocuments_nodes;

export interface attachedPaymentDocumentsData {
  attachedDocuments: AttachableDataType[];
}

interface AttachDocumentsFormProps {
  paymentAccountId?: string | null;
  currencyId?: number | null;
  departmentId?: string | null;
  cardHolderId?: string | null;
  onDocumentsAttached: (
    attachedDocumentsData: attachedPaymentDocumentsData
  ) => void;
  prevSelectedDocuments?: AttachableDataType[];
  previouslyAppliedAmount?: number;
  previousReceiptTotal?: string | null;
  onReceiptTotal?: (receiptTotal: string) => void;
}
const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };

export const AttachDocumentsForm: React.FC<AttachDocumentsFormProps> = (
  props
) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  return (
    <>
      <Stack
        horizontal
        styles={stackStyles}
        horizontalAlign="end"
        style={{ padding: '30px 20px' }}
      >
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Documents"
            checked={true}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachDocumentsFormModal {...props} setOpen={setDialogVisible} />
      )}
    </>
  );
};

const AttachDocumentsFormModal: React.FC<
  AttachDocumentsFormProps & { setOpen: (open: boolean) => void }
> = ({
  paymentAccountId,
  onDocumentsAttached,
  prevSelectedDocuments,
  setOpen,
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<PayCycleValues>();
  const styles = useStyles();
  const [selected, setSelected] = useState<AttachableDataType[]>([]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const { data: documentTypesData } = useQuery<PayCycleAvailableDocumentTypes>(
    AVAILABLE_DOCUMENT_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const documentTypeOptions =
    documentTypesData?.payCycleAvailableDocumentTypes?.nodes.map((doctype) => ({
      key: doctype.id,
      text: doctype.documentType || '',
    })) || [];

  const {
    data: documentsList,
    loading: documentsLoading,
    fetchMore,
    variables: documentsVariables,
    networkStatus,
    refetch,
  } = useQuery<
    AttachablePayCycleDocuments,
    AttachablePayCycleDocumentsVariables
  >(ATTACHABLE_PAY_CYCLE_DOCUMENTS, {
    variables: {
      paymentAccountId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const loadMore = useCallback(async () => {
    await fetchMore?.({
      variables: {
        ...documentsVariables,
        after: documentsList?.attachablePayCycleDocuments?.pageInfo.endCursor,
      },
    });
  }, [fetchMore, documentsVariables, documentsList]);

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null | undefined) => {
      await refetch?.({
        ...documentsVariables,
        documentTypeId,
      });
    },
    [refetch, documentsVariables]
  );

  const setTransactionFieldValues = (fieldName: string, fieldValue: any) => {
    setFieldValue(fieldName, fieldValue);
    setTimeout(() => {
      setFieldTouched(fieldName, true);
    }, 200);
  };

  const transformedData = useMemo(() => {
    const documents = documentsList?.attachablePayCycleDocuments?.nodes.map(
      (documents) =>
        ({
          ...documents,
          available: documents.documentAppliedAmounts?.remainingTotal,
          isoCode: documents.currency?.isoCode,
        } as AttachableDataType)
    );
    if (prevSelectedDocuments?.length && documents?.length) {
      const filteredArray = documents.filter(
        ({ id: id1 }) =>
          !prevSelectedDocuments.some(({ id: id2 }) => id2 === id1)
      );
      return filteredArray;
    }
    return documents;
  }, [documentsList, prevSelectedDocuments]);

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;
      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;

      
      switch (column.key) {
        case 'indexAmount':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <AmountTextView
                value={fieldContent}
                className={styles.contentColumnAlignRight}
              />
            </Stack>
          );
        case '_documentPoolName':
          return (
            <Stack
              horizontal
              className={styles.columnHeight}
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              {/* {item._isDocumentDistributionAttachable && (
                <TooltipHost
                  content={'Document distributions will automatically be added'}
                >
                  <FontIcon
                    iconName="InfoSolid"
                    className={commonStyles.colorThemePrimary}
                  />
                </TooltipHost>
              )} */}
              <Text>{fieldContent}</Text>
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout item={item} />
            </Stack>
          );
        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{getGlobalDateFormat(item._uploadDate!)}</Text>
            </Stack>
          );

        case 'indexTransactionDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              {item.indexTransactionDate && (
                <Text>
                  {dateFormat(dateConvertions(item.indexTransactionDate))}
                </Text>
              )}
            </Stack>
          );
        case 'action':
          const viewDocumentVisible =
            item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );

        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    },
    [styles]
  );

  const _onAttach = async () => {
    const attachedDocuments = selected.map((item) => {
      return {
        ...item,
      } as AttachableDataType;
    });

    const populativeData = {
      ...attachedDocuments.filter((item) => item._isAccountingDocument)[0],
    };

    const { indexTransactionDate } = { ...populativeData };
    if (populativeData) {
      if (indexTransactionDate)
        setTransactionFieldValues(
          'defaultPaymentDate',
          dateFormat(dateConvertions(indexTransactionDate))
        );
      setTransactionFieldValues('description', populativeData.indexDescription);
      setTransactionFieldValues(
        'controlTotalAmount',
        populativeData.indexAmount
      );
    }
    onDocumentsAttached({
      attachedDocuments,
    });

    setOpen(false);
  };

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );

  const onSelectionChanged = useCallback((items: AttachableDataType[]) => {
    setSelected(items);
  }, []);

  const attachBtnDisabled = selected.length === 0;

  return (
    <>
      <AttachDocumentModal
        columns={columns}
        loading={documentsLoading}
        modalWidth={1450}
        items={
          networkStatus === NetworkStatus.refetch ||
          networkStatus === NetworkStatus.setVariables
            ? undefined
            : transformedData
        }
        hasNextPage={
          documentsList?.attachablePayCycleDocuments?.pageInfo.hasNextPage
        }
        attachLoading={false}
        availableDocumentTypes={documentTypeOptions}
        setOpen={setOpen}
        onSortReload={reload}
        onLoadMore={loadMore}
        onFiltersReload={onFiltersReload}
        onDocumentTypeChange={onDocumentTypeReload}
        onSelectionChanged={onSelectionChanged}
        attachDisabled={attachBtnDisabled}
        dropdownDisabled={selected.length > 0}
        onRenderItemColumn={onRenderItemColumn}
        onAttachDocuments={_onAttach}
        onCancel={() => setSelected([])}
      />
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
