import { useMutation } from '@apollo/client';
import { BlockBlobClient } from '@azure/storage-blob';
import { UploadFiles } from 'common/components/UploadFiles';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { loader } from 'graphql.macro';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { PurchaseOrderAvailableDocumentTypes } from '../__generated__/PurchaseOrderAvailableDocumentTypes';
import { PurchaseOrderUploadDocument_purchaseOrderUploadDocument } from './__generated__/PurchaseOrderUploadDocument';
import { PurchaseOrderUploadDocument } from './__generated__/PurchaseOrderUploadDocument';
import { PurchaseOrderUploadDocumentVariables } from './__generated__/PurchaseOrderUploadDocument';
import { PurchaseOrder_purchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
const PURCHASE_ORDER_UPLOAD_DOCUMENT = loader('./PurchaseOrderUploadDocument.graphql');

interface UploadFormProps {
  availableDocumentTypes: PurchaseOrderAvailableDocumentTypes | undefined;
  purchaseOrder?: PurchaseOrder_purchaseOrder;
  onUpload?: (
    fileSelected: File,
    documentData: PurchaseOrderUploadDocument_purchaseOrderUploadDocument,
    toastId: string
  ) => void;
}

export const UploadForm: React.FC<UploadFormProps> = ({
  availableDocumentTypes,
  purchaseOrder,
  onUpload,
}) => {
  const {
    formState: {
      isDirty
    }
  } = useFormContext<PurchaseOrderValues>();

  const [uploadDocument] = useMutation<
    PurchaseOrderUploadDocument,
    PurchaseOrderUploadDocumentVariables
  >(PURCHASE_ORDER_UPLOAD_DOCUMENT, { errorPolicy: 'all' });

  const { updateToast, addToast } = useToasts();

  const { isDraft, id } = { ...purchaseOrder };

  const documentTypeOptions =
    availableDocumentTypes?.purchaseOrderAvailableDocumentTypes?.nodes.map(
      (doctype) =>
      ({
        key: doctype.id,
        text: doctype.documentType || '',
        isAccountingDocument: doctype.isAccountingDocument,
        title:
          isDraft && doctype.isAccountingDocument
            ? 'Upload prohibited during/after the approval process'
            : undefined,
        disabled: (!isDraft && doctype.isAccountingDocument) || undefined,
      } as DocumentTypeOption)
    ) || [];

  return (
    <UploadFiles
      documentTypes={documentTypeOptions!}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, filSelected) => {
          filSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });
            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                purchaseOrderId: id!,
              },
            });
            if (uploadMutationResults.errors) {
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });
            }
            if (
              uploadMutationResults.data?.purchaseOrderUploadDocument?.document &&
              uploadMutationResults.data?.purchaseOrderUploadDocument?.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data?.purchaseOrderUploadDocument,
                toastId!
              );
              const client = new BlockBlobClient(
                uploadMutationResults.data?.purchaseOrderUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
      disableUpload={isDirty}
    />
  );
};
