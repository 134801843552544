import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  textColor: {
    color: theme.palette.themePrimary,
  },
}));
