import { FilterArrayType } from 'common/components/Filters';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { BatchTransactionsOrderBy } from 'common/types/globalTypes';

type OrderLookup = {
  [direction in OrderDirection]: BatchTransactionsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'description',
    {
      [OrderDirection.ASC]: [BatchTransactionsOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [BatchTransactionsOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'weekNumber',
    {
      [OrderDirection.ASC]: [BatchTransactionsOrderBy.WEEK_NUMBER_ASC],
      [OrderDirection.DESC]: [BatchTransactionsOrderBy.WEEK_NUMBER_DESC],
    },
  ],
  [
    'referenceNumber',
    {
      [OrderDirection.ASC]: [BatchTransactionsOrderBy.REFERENCE_NUMBER_ASC],
      [OrderDirection.DESC]: [BatchTransactionsOrderBy.REFERENCE_NUMBER_DESC],
    },
  ],
  [
    'postingDate',
    {
      [OrderDirection.ASC]: [BatchTransactionsOrderBy.POSTING_DATE_ASC],
      [OrderDirection.DESC]: [BatchTransactionsOrderBy.POSTING_DATE_DESC],
    },
  ],
  [
    '_periodYear',
    {
      [OrderDirection.ASC]: [BatchTransactionsOrderBy.CORPORATE_PERIOD_ID_ASC],
      [OrderDirection.DESC]: [
        BatchTransactionsOrderBy.CORPORATE_PERIOD_ID_DESC,
      ],
    },
  ],
  [
    'currencyData',
    {
      [OrderDirection.ASC]: [BatchTransactionsOrderBy.CURRENCY_ID_ASC],
      [OrderDirection.DESC]: [BatchTransactionsOrderBy.CURRENCY_ID_DESC],
    },
  ],
  [
    'controlTotalAmount',
    {
      [OrderDirection.ASC]: [BatchTransactionsOrderBy.CONTROL_TOTAL_AMOUNT_ASC],
      [OrderDirection.DESC]: [
        BatchTransactionsOrderBy.CONTROL_TOTAL_AMOUNT_DESC,
      ],
    },
  ],
  [
    '_batchTransactionTotal',
    {
      [OrderDirection.ASC]: [
        BatchTransactionsOrderBy._BATCH_TRANSACTION_TOTAL_ASC,
      ],
      [OrderDirection.DESC]: [
        BatchTransactionsOrderBy._BATCH_TRANSACTION_TOTAL_DESC,
      ],
    },
  ],
  [
    '_batchTransactionCount',
    {
      [OrderDirection.ASC]: [
        BatchTransactionsOrderBy._BATCH_TRANSACTION_COUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        BatchTransactionsOrderBy._BATCH_TRANSACTION_COUNT_DESC,
      ],
    },
  ],
  [
    '_createdByUserid',
    {
      [OrderDirection.ASC]: [
        BatchTransactionsOrderBy._CREATED_BY_USER_NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        BatchTransactionsOrderBy._CREATED_BY_USER_NAME_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    BatchTransactionsOrderBy.REFERENCE_NUMBER_ASC,
    BatchTransactionsOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [
    ...(orderBy ? orderBy : []),
    BatchTransactionsOrderBy.PRIMARY_KEY_ASC,
  ];
};

export const toBatchTransactionsFilterVariable = (
  filterList: FilterArrayType[]
) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'currencyId':
      case 'weekNumber':
      case '_createdByUserid':
      case 'corporatePeriodId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      default:
        return undefined;
    }
  });
