import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  footer:{
    height: 80,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  buttonContainer:{
    padding: "10px 20px 10px",
  },
  disabledButton: {
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
}));