import { useApolloClient, useMutation } from '@apollo/client';
import { PrimaryButton, Stack } from '@fluentui/react';
import { ReportStatusType } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TravelAuthorizationTravelPlanReportStatus, TravelAuthorizationTravelPlanReportStatusVariables } from './__generated__/TravelAuthorizationTravelPlanReportStatus';
import { TravelPlanGenerateDocument, TravelPlanGenerateDocumentVariables } from './__generated__/TravelPlanGenerateDocument';
const TRAVEL_PLAN_GENERATE_DOCUMENT = loader('./TravelPlanGenerateDocument.graphql');
const TRAVEL_PLAN_REPORT_STATUS = loader('./TravelAuthorizationTravelPlanReportStatus.graphql');

interface PlanningDocumentProps {
  isNew: boolean;
  travelAuthorizationData: TravelAuthorization_travelAuthorization | null | undefined;
  onRefetch: () => void;
}

export const PlanningDocument: React.FC<PlanningDocumentProps> = ({
  isNew,
  travelAuthorizationData,
  onRefetch,
}) => {
  const client = useApolloClient();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false)
  const {
    id,
    _isPlanningPhase
  } = { ...travelAuthorizationData }

  const [onTravelPlanGenerateDocument] = useMutation<
    TravelPlanGenerateDocument,
    TravelPlanGenerateDocumentVariables
  >(TRAVEL_PLAN_GENERATE_DOCUMENT, { errorPolicy: 'all' });

  const _onTravelPlanGenerateDocument = async () => {

    if (id) {
      setIsLoading(true)
      const observer = client.subscribe<
        TravelAuthorizationTravelPlanReportStatus,
        TravelAuthorizationTravelPlanReportStatusVariables
      >({
        query: TRAVEL_PLAN_REPORT_STATUS,
        variables: {
          id: id!,
        },
      });
      const subscription = observer.subscribe(({ data, errors }) => {
        setIsLoading(false);
        if (errors) {
          addToast(
            'Errors received while subscribing to report status',
            { appearance: 'error' }
          );
        } else {
          const { status } = {
            ...data?.travelAuthorizationTravelPlanReportStatus,
          };
          if (status === ReportStatusType.SUCCESS) {
            addToast(
              'Report generation successful.',
              { appearance: "success" }
            );
            onRefetch();
          }
          if (status === ReportStatusType.FAILURE) {
            addToast(
              'Report generation failed.',
              { appearance: 'error' }
            );
          }
        }
        subscription.unsubscribe();
      });
      const { data, errors } = await onTravelPlanGenerateDocument({
        variables: {
          travelPlanId: id
        }
      })
      if (!!errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
        subscription.unsubscribe();
      }
      if (!data?.travelPlanGenerateDocument?.isGenerating) {
        subscription.unsubscribe();
      }
    }
  }

  if (isNew && !id) return null;

  return (
    <Stack>
      <PrimaryButton
        disabled={!_isPlanningPhase || isLoading}
        text="Planning Document"
        onClick={_onTravelPlanGenerateDocument}
      />
    </Stack>
  )
}
