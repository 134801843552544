import { InvoicePaymentsValues, PaymentValues } from './types';

export const INVOICE_PAYMENT_INITIAL_VALUES: InvoicePaymentsValues = {
  invoiceId: null,
};

export const PAYMENT_INITIAL_VALUES: PaymentValues = {
  _documentPackageId: null,
  transactionTypeId: null,
  payCycleId: null,
  vendorId: null,
  vendorReference: null,
  memo: null,
  paymentReference: null,
  paidDate: null,
  paidAmount: null,
  rowSecurityId: null,
  _isUpdatable: false,
  currency: null,
  _isProtected: null,
  secureRowLevel: {
    id: '',
    name: null,
    description: null,
    securityLevel: null,
  },
  notesByEntityId: null,
  paymentHistoriesByEntityId: null,
  entityTagsByEntityId: null,
  entityDocumentsByEntityId: null,
  accountingInvoiceTotal: null,
  invoicePayments: [{ ...INVOICE_PAYMENT_INITIAL_VALUES }],
};
