import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IDialogStyles,
  IconButton,
  Stack,
} from '@fluentui/react';
import { DangerButton } from 'common/components/DangerButton';

import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { MovementOrderJourney } from '..';
import { MovementOrders } from '../../../__generated__/MovementOrders';
import { MovementOrderTraveler } from '../../Sidebar';
import {
  MovementTravelerDelete,
  MovementTravelerDeleteVariables,
} from './__generated__/MovementTravelerDelete';
const MOVEMENT_ORDER_JOURNEY_UPDATE = loader(
  './MovementTravelerDelete.graphql'
);
const TITLE = 'Are you sure ?';
const SUBTEXT = 'The selected movement line will be deleted.';
const dialogContentProps: Partial<IDialogContentProps> = {
  title: TITLE,
  type: DialogType.largeHeader,
  subText: SUBTEXT,
  styles: {
    inner: {
      padding: '24px',
    },
    title: {
      padding: '16px 46px 0px 24px',
    },
    subText: {
      margin: 0,
    },
  },
};

const dialogStyles: Partial<IDialogStyles> = {
  main: {
    minHeight: 120,
  },
};

export const DeleteModal: React.FC<{
  data: MovementOrders | undefined;
  selectedTraveler: MovementOrderTraveler | undefined;
  journey: MovementOrderJourney | undefined;
}> = (props) => {
  const { addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const [deleteJourney] = useMutation<
    MovementTravelerDelete,
    MovementTravelerDeleteVariables
  >(MOVEMENT_ORDER_JOURNEY_UPDATE, { errorPolicy: 'all' });

  const onProceed = async () => {
    setVisible(false);
    const response = await deleteJourney({
      variables: {
        input: {
          movementTravelerId: props.selectedTraveler?.id!,
          movementTravelerJourneyDelete: [
            {
              id: props.journey?.id!,
              rowTimestamp: props.journey?._rowTimestamp!,
            },
          ],
        },
      },
      update(cache) {
        cache.evict({
          id: `MovementTravelerJourney:${props.journey?.id}`,
        });
        cache.gc();
      },
    });

    if (!!response.errors?.length)
      addToast('Error while deletion', { appearance: 'error' });
    else addToast('Deleted successfully', { appearance: 'success' });
  };

  return (
    <Stack>
      <IconButton
        iconProps={{ iconName: 'Delete' }}
        disabled={!props?.journey?._isUpdatable}
        onClick={() => setVisible(true)}
      />
      <ModalContainer
        onProceed={onProceed}
        onCancel={() => setVisible(false)}
        visible={visible}
      />
    </Stack>
  );
};

const ModalContainer: React.FC<{
  visible: boolean;
  onCancel: () => void;
  onProceed: () => void;
}> = ({ visible, onCancel, onProceed }) => {
  if (visible)
    return (
      <Dialog
        hidden={false}
        dialogContentProps={dialogContentProps}
        styles={dialogStyles}
        minWidth={350}
      >
        <Stack style={{ height: 40 }}>
          <DialogFooter>
            <DangerButton onClick={onProceed} text={'Delete'} />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </DialogFooter>
        </Stack>
      </Dialog>
    );
  else return null;
};
