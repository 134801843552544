import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { RequiredNameProps } from 'common/types/utility';
import {
  TextField,
  ITextFieldProps,
  Stack,
  Spinner,
  ITextFieldStyles,
  useTheme,
  IconButton,
  makeStyles,
} from '@fluentui/react';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import { LookUpCallout } from 'common/components/LookUp';
import {
  LookupNameSearch_lookupNameSearch_nodes,
  LookupNameSearch,
  LookupNameSearchVariables,
} from 'common/components/LookUp/__generated__/LookupNameSearch';
import { TextFieldLabel } from './TextFieldLabel';

const LOOKUP = loader('../../LookUp/LookupNameSearch.graphql');

type AutoCompleteProps = RequiredNameProps<ITextFieldProps> & {
  transactionTypeId?: number;
  documentTypeId?: number;
  lookUpCalloutWidth?: number;
  lookupData?: LookupNameSearch_lookupNameSearch_nodes | null;
  clearTextField?: boolean | null;
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
  },
  underline: {
    color: theme.semanticColors.inputBorder,
    backgroundColor: theme.semanticColors.inputBorder,
    height: 1,
  },
  detailsCallout: {
    width: 400,
    flexGrow: 1,
  },
  separator: {
    height: 1,
  },
  lookupCode: {
    border: `1px solid ${theme.palette.themePrimary}`,
    borderRadius: 5,
    padding: '0px 5px',
  },
}));

export const AutoCompleteFormikTextField: FC<AutoCompleteProps> = ({
  transactionTypeId,
  documentTypeId,
  lookUpCalloutWidth,
  lookupData,
  clearTextField,
  ...props
}) => {
  const theme = useTheme();
  const classStyles = useStyles();
  const textfiledStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: props.disabled
        ? theme.palette.neutralLighter
        : theme.palette.white,
    },
  };
  const [field, meta, helpers] = useField<string | undefined | null>(
    props.name
  );
  const renderRef = useRef(false);
  const { value, ...rest } = field;
  const [calloutVisible, setCalloutVisible] = useState(true);
  const [disableTextfield, setDisableTextfield] = useState<
    boolean | null | undefined
  >(lookupData?.isApprovedVendor);

  const [selectedRecord, setSelectedRecord] = useState<
    LookupNameSearch_lookupNameSearch_nodes | undefined | null
  >(lookupData);
  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupNameSearch,
    LookupNameSearchVariables
  >(LOOKUP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const { id, label, disabled, required, ...textfieldProps } = props;
  useEffect(() => {
    if (clearTextField) {
      setDisableTextfield(false);
    }
  }, [clearTextField, transactionTypeId, documentTypeId]);

  const lookupCallBack = useCallback(
    (transactionTypeId, documentTypeId, value) => {
      fetchLookUp({
        variables: {
          transactionTypeId,
          documentTypeId,
          lookupName: value,
        },
      });
    },
    [fetchLookUp]
  );

  useEffect(() => {
    if (documentTypeId && value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(undefined, documentTypeId, value);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack, documentTypeId]);

  useEffect(() => {
    if (transactionTypeId && value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(transactionTypeId, undefined, value);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack, transactionTypeId]);

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          transactionTypeId,
          documentTypeId,
          lookupName: value,
        },
      });
      queryCalled();
    }, 350),
    [transactionTypeId, documentTypeId]
  );

  const renderSpinner = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disabled ? null : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              helpers.setValue(null);
              setDisableTextfield(false);
              setSelectedRecord(null);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={classStyles.icon}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };
  return (
    <Stack>
      <TextFieldLabel
        disabled={disabled}
        showIcon={disableTextfield}
        label={label}
        data={selectedRecord}
        required={required}
      />
      <TextField
        {...rest}
        {...textfieldProps}
        id={id}
        autoComplete={'off'}
        errorMessage={meta.error}
        value={value || ''}
        onChange={(_event, value) => {
          if (value) {
            helpers.setValue(value);
            if (transactionTypeId !== null || documentTypeId !== null) {
              callbackLookUp(value, () => {
                if (calloutVisible === false) setCalloutVisible(true);
              });
            }
          } else {
            helpers.setValue(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textfiledStyles}
        disabled={disabled ? true : disableTextfield ? true : false}
      />
      <Stack style={{ position: 'absolute', top: 0, left: 0 }}>
        {calloutVisible && !loading && (
          <LookUpCallout
            lookUpCalloutWidth={lookUpCalloutWidth}
            onDismiss={() => setCalloutVisible(false)}
            onSelect={(value) => {
              helpers.setValue(value.lookupName);
              if (value.isApprovedVendor) {
                setDisableTextfield(true);
                setSelectedRecord(value);
              }
            }}
            targetID={`#${id!}`}
            data={data?.lookupNameSearch?.nodes!}
          />
        )}
      </Stack>
    </Stack>
  );
};
