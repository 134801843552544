import { useMutation, useQuery } from '@apollo/client';
import {
  BatchTransactionCreateInput,
  BatchTransactionPatch,
  BatchTransactionUpdateInput,
  EntityDocumentInput,
} from 'common/types/globalTypes';
import { dateConvertions, invoiceDateFormat } from 'common/utils/dateFormats';
import { Formik } from 'formik';
import { loader } from 'graphql.macro';
import { BatchListOption, setBatchSelected } from 'postingTracker/batchEdit';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { BatchTransactions_batchTransactions } from '../list/__generated__/BatchTransactions';
import { BATCH_INITIAL_VALUES } from './constants';
import { FormView } from './Form';
import { AttachableBatchDocumentsType } from './Form/AttachBatchDocuments';
import { BatchValues } from './types';
import { validationSchema } from './validation';
import {
  BatchTransaction,
  BatchTransactionVariables,
} from './__generated__/BatchTransaction';
import {
  BatchTransactionCreate,
  BatchTransactionCreateVariables,
} from './__generated__/BatchTransactionCreate';
import {
  BatchTransactionUpdate,
  BatchTransactionUpdateVariables,
} from './__generated__/BatchTransactionUpdate';

const BATCH_TRANSACTION = loader('./BatchTransaction.graphql');
const BATCH_TRANSACTION_CREATE = loader('./BatchTransactionCreate.graphql');
const BATCH_TRANSACTION_UPDATE = loader('./BatchTransactionUpdate.graphql');

interface BatchDetailsProps {}

export const BatchDetails: React.FC<BatchDetailsProps> = () => {
  const { batchId } = useParams<{ batchId: string | undefined }>();
  const { addToast } = useToasts();
  const saveAnother = useRef<boolean>(false);
  const isNew = !batchId;
  const history = useHistory();
  const [attachedDocuments, setAttachedDocuments] =
    useState<AttachableBatchDocumentsType[]>();

  const {
    data: batchTransaction,
    loading: dataLoading,
    refetch,
  } = useQuery<BatchTransaction, BatchTransactionVariables>(BATCH_TRANSACTION, {
    variables: {
      id: batchId!,
    },
    skip: !batchId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [createBatch, { loading: batchCreationInProgress }] = useMutation<
    BatchTransactionCreate,
    BatchTransactionCreateVariables
  >(BATCH_TRANSACTION_CREATE, { errorPolicy: 'all' });

  const [updateBatch, { loading: batchUpdateInProgress }] = useMutation<
    BatchTransactionUpdate,
    BatchTransactionUpdateVariables
  >(BATCH_TRANSACTION_UPDATE, { errorPolicy: 'all' });

  const handleSubmit = async (values: BatchValues) => {
    const {
      description,
      referenceNumber,
      currencyId,
      postingDate,
      corporatePeriodId,
      controlTotalAmount,
      weekNumber,
    } = { ...values };
    if (isNew) {
      const entityDocuments = attachedDocuments?.map(
        (item) =>
          ({
            id: item.id,
            comment: item.comment,
            entityId: item.entityId,
            fileReference: item.fileReference,
            fileIndexInformation: item.fileIndexInformation,
            entityDocumentTypeId: item.entityDocumentTypeId,
            indexName: item.indexName,
            indexDescription: item.indexDescription,
            indexTransactionDate: item.indexTransactionDate,
            indexAmount: item.indexAmount,
            indexCurrencyId: item.indexCurrencyId,
            documentAppliedAmount: item.documentAppliedAmount,
            documentStatusExplanation: item.documentStatusExplanation,
            isAppliedAmountRetired: item.isAppliedAmountRetired,
            isAppliedAmountOverage: item.isAppliedAmountOverage,
          } as EntityDocumentInput)
      );
      const inputFields: BatchTransactionCreateInput = {
        entityDocuments,
        batchTransaction: {
          description: description!,
          referenceNumber: referenceNumber!,
          currencyId: currencyId!,
          postingDate: postingDate ? invoiceDateFormat(postingDate) : null,
          corporatePeriodId: corporatePeriodId!,
          controlTotalAmount: controlTotalAmount,
          weekNumber: weekNumber !== null ? Number(weekNumber) : null,
        },
      };
      const { data, errors } = await createBatch({
        variables: {
          input: inputFields,
        },
        update: (cache, { data }) => {
          if (data?.batchTransactionCreate?.batchTransaction?.id) {
            cache.modify({
              fields: {
                batchTransactions: (
                  existingData: BatchTransactions_batchTransactions,
                  { toReference }
                ) => {
                  const {
                    controlTotalAmount,
                    _batchTransactionTotal,
                    _batchTransactionCount,
                  } = { ...data?.batchTransactionCreate?.batchTransaction };
                  const { sum } = {
                    ...existingData?.aggregates,
                  };

                  const controlTotalAmountNew =
                    Number(sum?.controlTotalAmount) +
                    Number(controlTotalAmount);

                  const _batchTransactionTotalNew =
                    Number(sum?._batchTransactionTotal) +
                    Number(_batchTransactionTotal);

                  const _batchTransactionCountNew =
                    Number(sum?._batchTransactionCount) +
                    Number(_batchTransactionCount);

                  return {
                    ...existingData,
                    nodes: [
                      ...existingData.nodes,
                      data.batchTransactionCreate?.batchTransaction,
                    ],
                    aggregates: {
                      ...existingData?.aggregates,
                      sum: {
                        ...existingData?.aggregates?.sum,
                        controlTotalAmount: controlTotalAmountNew.toString(),
                        _batchTransactionTotal:
                          _batchTransactionTotalNew.toString(),
                        _batchTransactionCount:
                          _batchTransactionCountNew.toString(),
                      },
                    },
                  };
                },
              },
            });
          }
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        if (!saveAnother.current) {
          const { batchTransaction } = { ...data?.batchTransactionCreate };
          const option: BatchListOption = {
            key: batchTransaction?.id!,
            text:
              `${batchTransaction?.description}  ${
                batchTransaction?.currency?.isoCode
                  ? `(${batchTransaction?.currency?.isoCode})`
                  : ''
              } ` || '',
            description: batchTransaction?.description!,
            isoCode: batchTransaction?.currency?.isoCode!,
            count: batchTransaction?._batchTransactionCount!,
            total: batchTransaction?._batchTransactionTotal!,
          };
          setBatchSelected(option);

          history.push(
            `/postingTracker/batches/batch/${data?.batchTransactionCreate?.batchTransaction?.id}`
          );
        }
        addToast('Batch Created successfully', {
          appearance: 'success',
        });
      }
    } else {
      // Patch created
      const inputPatch: BatchTransactionPatch = {
        description: description!,
        referenceNumber: referenceNumber!,
        postingDate: postingDate ? invoiceDateFormat(postingDate) : null,
        corporatePeriodId: corporatePeriodId!,
        controlTotalAmount,
        weekNumber: weekNumber !== null ? Number(weekNumber) : null,
        currencyId: values.currencyId,
      };
      // Patch refined and sanitized
      const inputFieldsPatch: BatchTransactionPatch = Object.entries(
        inputPatch
      ).reduce((res, [key, val]) => {
        if (val !== initialValues[key as keyof BatchValues]) {
          return { ...res, [key]: val };
        }
        return res;
      }, {});

      // Input object data created
      const input: BatchTransactionUpdateInput = {
        id: batchId!,
        rowTimestamp: batchTransaction?.batchTransaction?._rowTimestamp!,
        batchTransactionPatch: inputFieldsPatch,
      };

      const { errors } = await updateBatch({
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: BATCH_TRANSACTION,
            variables: {
              id: batchId,
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Batch updated successfully', {
          appearance: 'success',
        });
      }
    }
  };

  const initialValues: BatchValues =
    isNew || batchTransaction?.batchTransaction === undefined
      ? BATCH_INITIAL_VALUES
      : {
          ...batchTransaction?.batchTransaction!,
          postingDate: batchTransaction?.batchTransaction?.postingDate
            ? dateConvertions(batchTransaction?.batchTransaction?.postingDate)
            : null,
        };

  return (
    <Formik<BatchValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <FormView
            data={batchTransaction}
            editInProgress={batchUpdateInProgress}
            createInProgress={batchCreationInProgress}
            dataLoading={dataLoading}
            onSave={(value) => (saveAnother.current = value)}
            onDocumentsAttached={(documentsData) => {
              setAttachedDocuments(documentsData || []);
            }}
            refetchBatch={refetch}
          />
        );
      }}
    </Formik>
  );
};
