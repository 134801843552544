import { PivotMenuItemProps } from 'common/components/PivotDisplay';
import { GlobalActions } from 'common/constants';

export const pivotActionItems: PivotMenuItemProps[] = [
  {
    name: 'Protect',
    iconName: 'Unlock',
    itemKey: GlobalActions.protect,
    count: 0,
  },
  {
    name: 'Files',
    iconName: 'Attach',
    itemKey: GlobalActions.attach,
    count: 0,
  },
  {
    name: 'Notes',
    iconName: 'Page',
    itemKey: GlobalActions.notes,
    count: 0,
  },
  {
    name: 'History',
    iconName: 'History',
    itemKey: GlobalActions.history,
    count: 0,
  },
  {
    name: 'Tags',
    iconName: 'Tag',
    itemKey: GlobalActions.tags,
    count: 0,
  },
  {
    name: 'Message',
    iconName: 'Message',
    itemKey: GlobalActions.messages,
    count: 0,
  },
];
