import React, { ReactNode, useCallback, useState } from 'react';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TravelerAuthorizationCommonData_secureRowLevels } from '../__generated__/TravelerAuthorizationCommonData';
import { useToasts } from 'react-toast-notifications';
import { useFormContext } from 'react-hook-form';
import { TravelAuthorizationValues } from 'travelAuthorization/TravelPlan/view/interface';
import { useStyles } from './index.styles';
import { GlobalActions } from 'common/constants';
import { loader } from 'graphql.macro';
import {
  TravelAuthorizationRowProtection,
  TravelAuthorizationRowProtectionVariables,
} from './__generated__/TravelAuthorizationRowProtection';
import { useMutation, useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import {
  PivotDisplay,
  PivotMenuItemProps,
} from 'common/components/PivotDisplay';
import {
  UserEntityMessageCounts,
  UserEntityMessageCountsVariables,
} from 'common/graphql/__generated__/UserEntityMessageCounts';
import { pivotActionItems } from './constants';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { ProtectSection } from 'common/components/Protect';
import { HistoryActionMenuView } from 'common/components/History';
import { Notes } from './Notes';
import { Messages } from './Messages';
import { Attach } from './Attach';
import { TravelAuthorizationUploadDocument_travelAuthorizationUploadDocument } from './Attach/UploadForm/__generated__/TravelAuthorizationUploadDocument';
import {
  ListTravelAuthorizationNotes,
  ListTravelAuthorizationNotesVariables,
} from 'common/graphql/__generated__/ListTravelAuthorizationNotes';
import { Tags } from './Tags';
const TRAVEL_AUTHORIZATION_ROW_PROTECTION = loader(
  './TravelAuthorizationRowProtection.graphql'
);
const USER_ENTITY_MESSAGE_COUNTS = loader(
  '../../../../../common/graphql/UserEntityMessageCounts.graphql'
);
const LIST_TRAVEL_AUTHORIZATION_NOTES = loader(
  '../../../../../common/graphql/ListTravelAuthorizationNotes.graphql'
);

interface ActionMenuProps {
  travelAuthorizationData: TravelAuthorization_travelAuthorization;
  secureRowLevels:
  | TravelerAuthorizationCommonData_secureRowLevels
  | null
  | undefined;
  onRefetch?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: TravelAuthorizationUploadDocument_travelAuthorizationUploadDocument,
    toastId: string
  ) => void;
  children: ReactNode;
}

interface TravelerAuthorizationPropertiesCount {
  name: string;
  count: number;
  iconName: string;
}

export const ActionMenu: React.FC<ActionMenuProps> = ({
  travelAuthorizationData,
  secureRowLevels,
  onRefetch,
  onUpload,
  children,
}) => {
  const styles = useStyles();
  const {
    id,
    secureRowLevel,
    notesByEntityId,
    entityDocumentsByEntityId,
    travelAuthorizationHistoriesByEntityId,
    entityTagsByEntityId,
    _isUpdatable,
    _isProtected,
  } = { ...travelAuthorizationData };
  let updatedPivotActionItems: PivotMenuItemProps[] = [];
  const { addToast } = useToasts();
  const [expandMenu, setExpandMenu] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    GlobalActions.protect
  );
  const {
    formState: { isDirty },
  } = useFormContext<TravelAuthorizationValues>();
  const isUpdatable = isDirty ? false : !!_isUpdatable ? true : false;

  const [travelAuthorizationRowProtection, { loading: protectLoading }] =
    useMutation<
      TravelAuthorizationRowProtection,
      TravelAuthorizationRowProtectionVariables
    >(TRAVEL_AUTHORIZATION_ROW_PROTECTION, { errorPolicy: 'all' });

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      USER_ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          entityId: id,
        },
        skip: !id,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );

  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListTravelAuthorizationNotes,
    ListTravelAuthorizationNotesVariables
  >(LIST_TRAVEL_AUTHORIZATION_NOTES, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const getEntityNotes = () => {
    const variables: ListTravelAuthorizationNotesVariables = {
      id,
    };
    entityNotesListRefetch(variables);
  };

  const getEntityNotesMemo = useCallback(getEntityNotes, [id]);

  const updateRowLevel = async (selectedLevel: string | null) => {
    const inputData =
      selectedLevel !== null
        ? {
          entityId: id,
          rowSecurityId: selectedLevel,
        }
        : {
          entityId: id,
          isProtectionRemoval: true,
        };
    const { errors } = await travelAuthorizationRowProtection({
      variables: {
        input: inputData,
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      onRefetch?.();
      addToast('Security/Protection level updated', {
        appearance: 'success',
      });
    }
  };

  const getUpdatedPivotActionItems = (
    pivotItems: TravelerAuthorizationPropertiesCount[]
  ) => {
    return pivotActionItems.map((item) => ({
      ...item,
      count: pivotItems.find((item2) => item2.name === item.name)?.count,
      iconName: pivotItems.find((item2) => item2.name === item.name)?.iconName!,
    }));
  };

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.protect:
        return (
          <Stack tokens={{ padding: '0px 0px 0px 20px' }}>
            <ProtectSection
              updateLoading={protectLoading}
              updateRowLevel={async (selectedLevel) => {
                updateRowLevel(selectedLevel);
              }}
              secureRowLevels={secureRowLevels?.nodes || []}
              secureRowLevelId={secureRowLevel?.id}
              isUpdatable={isUpdatable}
            />
          </Stack>
        );
      case GlobalActions.notes:
        return (
          <Stack>
            <Notes
              listRefreshRequest={getEntityNotesMemo}
              id={id}
              data={
                entityNotesData?.travelAuthorization?.notesByEntityId.nodes || []
              }
            />
          </Stack>
        );
      case GlobalActions.history:
        return (
          <Stack>
            <HistoryActionMenuView
              moduleName="Travel Authorization"
              numberOfItems={3}
              historyData={travelAuthorizationHistoriesByEntityId?.nodes || []}
            />
          </Stack>
        );
      case GlobalActions.attach:
        return (
          <Stack>
            {travelAuthorizationData && (
              <Attach
                travelAuthorizationData={travelAuthorizationData}
                onUpload={onUpload}
                onRemove={onRefetch}
              />
            )}
          </Stack>
        );
      case GlobalActions.tags:
        return (
          <Stack>
            {id && <Tags travelAuthorizationId={id} dirty={isDirty} />}
          </Stack>
        );
      case GlobalActions.messages:
        return (
          <Stack>
            <Messages
              dirty={isDirty}
              onCreateMessage={userMessageCountsRefetch}
              userMessageCounts={userMessageCounts}
              travelAuthorizationId={id}
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  if (travelAuthorizationData) {
    const TravelerAuthorizationCountArray: TravelerAuthorizationPropertiesCount[] =
      [
        {
          name: 'Protect',
          iconName: _isProtected ? 'Lock' : 'Unlock',
          count: 0,
        },
        {
          name: 'Files',
          iconName: 'Attach',
          count: entityDocumentsByEntityId?.totalCount || 0,
        },
        {
          name: 'Notes',
          iconName: 'Page',
          count: notesByEntityId?.nodes?.length || 0,
        },
        {
          name: 'History',
          iconName: 'History',
          count: travelAuthorizationHistoriesByEntityId?.nodes?.length || 0,
        },
        {
          name: 'Tags',
          iconName: 'Tag',
          count: entityTagsByEntityId?.nodes?.length || 0,
        },
        {
          name: 'Message',
          iconName: 'Message',
          count: userMessageCounts?.userEntityMessageCounts?.totalMessages || 0,
        },
      ];
    updatedPivotActionItems = getUpdatedPivotActionItems(
      TravelerAuthorizationCountArray
    );
  } else updatedPivotActionItems = pivotActionItems;

  return (
    <Stack
      verticalAlign="center"
    >
      <Stack
        grow
      >
        {expandMenu ? (
          <Stack>
            <Stack
              horizontalAlign="space-between"
              horizontal
              className={styles.pivotContainer}
            >
              <Stack horizontal>
                <Pivot
                  selectedKey={selectedKey}
                  onLinkClick={(item: PivotItem | undefined) => {
                    setSelectedKey(item?.props.itemKey);
                  }}
                >
                  {updatedPivotActionItems.map((item, index) => {
                    return (
                      <PivotItem
                        key={index}
                        itemKey={item.itemKey}
                        itemIcon={item.iconName}
                        headerText={item.name}
                        itemCount={item.count}
                        onRenderItemLink={PivotCustomRender}
                      />
                    );
                  })}
                </Pivot>
                <IconButton
                  onClick={() => setExpandMenu(false)}
                  iconProps={{ iconName: 'ChevronUp' }}
                  className={styles.iconButtonColor}
                />
              </Stack>
              {children}
            </Stack>
            <Stack>{getSelectedSection(selectedKey!)}</Stack>
          </Stack>
        ) : (
          <Stack
            horizontalAlign="space-between"
            horizontal
            className={styles.pivotContainer}
          >
            <Stack horizontal verticalAlign="center">
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 5 }}
              >
                {updatedPivotActionItems.map((item, index) => {
                  return (
                    <PivotDisplay
                      key={index}
                      {...item}
                      onPivotClick={() => {
                        setSelectedKey(item.itemKey);
                        setExpandMenu(true);
                      }}
                    />
                  );
                })}
              </Stack>
              <IconButton
                onClick={() => setExpandMenu(true)}
                iconProps={{ iconName: 'ChevronDown' }}
                className={styles.iconButtonColor}
              />
            </Stack>
            {children}
          </Stack>
        )}

      </Stack>

    </Stack>
  );
};
