import { useLazyQuery, useMutation } from '@apollo/client';
import {
  IIconProps,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import {
  TransferCycles,
  TransferCyclesVariables,
} from 'common/graphql/__generated__/TransferCycles';
import {
  EntitySelectionInput,
  SupplierExportInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useToasts } from 'react-toast-notifications';
import { SupplierRowItem } from '..';
import {
  SupplierExport,
  SupplierExportVariables,
} from './__generated__/SupplierExport';

const SUPPLIER_LIST_EXPORT = loader('./SupplierExport.graphql');
const TRANSFER_CYCLE = loader(
  '../../../../../common/graphql/transferCycles.graphql'
);

interface exportSuppliersProps {
  selectedRows: SupplierRowItem[];
  isSelectedAllRows: boolean;
}
const exportIcon: IIconProps = {
  iconName: 'Go',
  styles: {
    root: {
      fill: 'white',
      marginRight: 10,
    },
  },
};
export const ExportSuppliers: React.FC<exportSuppliersProps> = ({
  selectedRows,
  isSelectedAllRows,
}) => {
  const { addToast } = useToasts();
  const SUPPLIER_EXPORT_TITLE =
    'Enter the description and press confirm to download the Supplier list.';
  const [transferCycle, { data: nextCycleData }] = useLazyQuery<
    TransferCycles,
    TransferCyclesVariables
  >(TRANSFER_CYCLE, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [supplierExport] = useMutation<SupplierExport, SupplierExportVariables>(
    SUPPLIER_LIST_EXPORT
  );
  const [description, setDescription] = React.useState<string>('');
  const csvLink = useRef<HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [csvFileName, setCsvFileName] = useState<string>();
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [isAllSuppliers, setAllSuppliers] =
    useState<boolean>(isSelectedAllRows);
  const handleConfirmDownloadPress = async () => {
    const rows: EntitySelectionInput[] = [];
    for (let data of selectedRows) {
      rows.push({
        id: data?.id,
        rowTimestamp: data?._rowTimestamp!,
      });
    }
    const exportVariable: SupplierExportInput = {
      exportDescription: description,
      entitySelection: rows!,
      isAllExported: isAllSuppliers,
    };
    const { errors, data } = await supplierExport({
      variables: {
        input: exportVariable,
      },
    });
    toggleConfirmDialog();
    if (!errors) {
      setDescription('');
      const { exportResult } = { ...data?.supplierExport };
      if (
        exportResult?.isCreateLocalFilesRequired &&
        exportResult?.exportCycles
      ) {
        const idArray = exportResult.exportCycles.map((item) => item as string);
        transferCycle({
          variables: {
            idArray,
          },
        });
      }
    }

    if (errors) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
      setDescription('');
    }
  };
  useEffect(() => {
    setAllSuppliers(isSelectedAllRows);
  }, [isSelectedAllRows]);
  useEffect(() => {
    const extractData = (str: string) => {
      return str.split('"').filter((e, i) => i & 1);
    };
    nextCycleData?.transferCycles?.nodes.forEach((item, index) => {
      const csvFormattedData: string[][] = [];
      if (item) {
        if (csvLink?.current) {
          setTimeout(() => {
            setCsvFileName(item?._exportFilename!);
            csvFormattedData.push(extractData(item?.transferHeaders!));
            for (let col of item.transactionTransfers.nodes) {
              csvFormattedData.push(extractData(col?.transferValues!));
            }
            setCsvData(csvFormattedData!);
            csvLink.current?.link?.click();
          }, index * 500);
        }
      }
    });

    if (!!nextCycleData?.transferCycles?.nodes.length) {
      addToast('CSV Exported', {
        appearance: 'info',
      });
    }
  }, [addToast, nextCycleData]);

  return (
    <>
      {!!selectedRows.length && (
        <PrimaryButton
          text="Export"
          onClick={toggleConfirmDialog}
          iconProps={exportIcon}
        />
      )}
      {csvData ? (
        <CSVLink
          data={csvData}
          filename={csvFileName}
          target="_blank"
          ref={csvLink}
        ></CSVLink>
      ) : null}
      <ConfirmDialog
        isConfirmPrimaryButton
        hidden={hideConfirmRequestDialog}
        title={SUPPLIER_EXPORT_TITLE}
        onDismiss={() => {
          toggleConfirmDialog();
          setDescription('');
        }}
        minWidth={500}
        onConfirm={handleConfirmDownloadPress}
      >
        <Stack tokens={{ childrenGap: 20 }}>
          <TextField
            rows={3}
            value={description}
            onChange={(_event, value) => setDescription(value || '')}
            placeholder="Worksheet description"
            resizable={true}
          />

          <Toggle
            inlineLabel
            label="All Suppliers"
            defaultChecked={isAllSuppliers}
            onChange={(_, value) => {
              setAllSuppliers(!!value);
            }}
          />
        </Stack>
      </ConfirmDialog>
    </>
  );
};
