import { useQuery } from '@apollo/client';
import { Stack } from '@fluentui/react';
import {
  FormHookCountryRegion,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { Countries } from 'common/graphql/__generated__/Countries';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React from 'react';
import { CityLookUp } from './CityLookUp';
const COUNTRIES = loader(
  '../../../../../../../../../../../common/graphql/Countries.graphql'
);

export const AddressSectionInput: React.FC<{
  baseField: string;
  disabled: boolean;
}> = ({ baseField, disabled }) => {
  const { data: countries } = useQuery<Countries>(COUNTRIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const countriesOptions = formatDropdownOptions(countries?.countries?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.country!,
    includeAll: false,
  });
  return (
    <Stack grow={3} tokens={{ childrenGap: 20 }}>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item grow={1}>
          <FormHookTextField
            name={`${baseField}rentalAddress1`}
            label="Street"
            placeholder="Enter street name"
            disabled={disabled}
          />
        </Stack.Item>
        <Stack.Item grow={1}>
          <FormHookTextField
            name={`${baseField}rentalAddress2`}
            label="Apartment"
            placeholder="Enter apartment"
            disabled={disabled}
          />
        </Stack.Item>
        <CityLookUp baseField={baseField} disabled={disabled} />
      </Stack>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{
          childrenGap: 20,
        }}
      >
        <FormHookCountryRegion
          countries={countriesOptions}
          countryName={`${baseField}rentalCountryId`}
          regionName={`${baseField}rentalStateRegionId`}
          CountryAutocompleteProps={{
            label: 'Country',
            errorMessage: '',
          }}
          countryPickerStyles={{ root: { width: 210 } }}
          statePickerStyles={{ root: { width: 210 } }}
          StateRegionAutocompleteProps={{
            label: 'State / Region / Province',
            errorMessage: '',
          }}
          disabled={disabled}
        />
        <Stack.Item grow={1}>
          <FormHookTextField
            name={`${baseField}rentalPostalCode`}
            label="Zip Code"
            placeholder="Zip Code"
            disabled={disabled}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
