import React, { useState } from 'react';
import { Separator, Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import { ApprovalDataItem } from './ApprovalDataItem';
import { useFormikContext } from 'formik';
import { ApprovalValues } from '../types';
import { FieldArray } from 'formik';
import clsx from 'clsx';
import { ApprovalSetupCommonData } from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { APPROVAL_LEVELS_INITIAL_VALUES } from '../constants';
interface ApprovalLevelProps {
  approvalTypeData: ApprovalSetupCommonData | undefined;
  deleteRow?: (id: string, _rowTimestamp: string | null) => void;
  deleteBranchRow: (id: string, _rowTimestamp: string | null) => void;
  isUpdateble: boolean;
}

export const ApprovalLevels: React.FC<ApprovalLevelProps> = ({
  approvalTypeData,
  deleteRow,
  deleteBranchRow,
  isUpdateble,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [approvalSelected, setApprovalSelected] = useState<
    undefined | null | string | number
  >();
  const [approverSelected, setApproverSelected] = useState<
    string | number | undefined
  >();
  const onItemClick = (index: number) => {
    setApprovalSelected(index === approvalSelected ? null : index);
  };

  // Disable condition based on _isUpdatable
  const isDisabled = isUpdateble;
  const { values } = useFormikContext<ApprovalValues>();

  return (
    <Stack>
      <Stack tokens={{ padding: '25px 0px 0px 25px' }}>
        <Text variant="xLarge">Approval Levels</Text>
      </Stack>
      <Stack className={styles.scrollboxWrapper}>
        <Stack
          horizontalAlign="space-between"
          horizontal
          tokens={{ padding: '50px 50px 0px 100px' }}
        >
          <Stack horizontal>
            <Stack className={styles.width120}>
              <Text className={commonStyles.bold}>Levels</Text>
            </Stack>
            <Stack className={styles.width120}>
              <Text className={commonStyles.bold}>Name</Text>
            </Stack>
            <Stack className={styles.width180}>
              <Text className={commonStyles.bold}>Comment</Text>
            </Stack>
            <Stack className={styles.width180}>
              <Text className={commonStyles.bold}>Required Approvals</Text>
            </Stack>
            <Stack className={styles.width180}>
              <Text className={commonStyles.bold}>Approved by consensus?</Text>
            </Stack>
            <Stack className={styles.width150}>
              <Text className={commonStyles.bold}>Single Decline?</Text>
            </Stack>
            <Stack className={styles.width180}>
              <Text className={commonStyles.bold}>
                Require minimum Approver?
              </Text>
            </Stack>
            <Stack className={styles.width180}>
              <Text className={commonStyles.bold}>Additional Dept Level?</Text>
            </Stack>
          </Stack>
        </Stack>

        <Separator />
        <Stack className={styles.renderAccountingOptions}>
          <FieldArray name="approvalTrees">
            {({ remove, insert }) => (
              <>
                {values.approvalTrees?.map((item, index) => {
                  const approvalLevelsName = `approvalTrees[${index}]`;
                  return (
                    <Stack
                      key={index}
                      className={clsx(
                        index !== 0 && styles.accountingRowMargin
                      )}
                    >
                      <ApprovalDataItem
                        index={index}
                        lastRow={values.approvalTrees?.length! - 1}
                        distributionTypes={approvalTypeData!}
                        data={item}
                        approvalLevelsName={approvalLevelsName}
                        selectedTransaction={approverSelected}
                        onTransactionClick={(index) =>
                          setApproverSelected(index)
                        }
                        deleteCurrentRow={(removeIndex: number) => {
                          remove(removeIndex);
                          if (item.id) {
                            deleteRow?.(item.id, item._rowTimestamp);
                          }
                        }}
                        deleteBranchRow={(id, rowTimestamp) =>
                          deleteBranchRow(id, rowTimestamp)
                        }
                        isDisabled={isDisabled}
                        insertAdditionalRow={() => {
                          insert(
                            values.approvalTrees?.length!,
                            APPROVAL_LEVELS_INITIAL_VALUES
                          );
                        }}
                        isSelected={approvalSelected === index}
                        key={index.toString()}
                        onItemClick={() => onItemClick(index)}
                      />
                    </Stack>
                  );
                })}
              </>
            )}
          </FieldArray>
        </Stack>
      </Stack>
    </Stack>
  );
};
