import { PeriodRangeValues } from "./types";

export const PERIOD_RANGE_VALUES: PeriodRangeValues = {
  companyCorporateYearId: null,
  startPeriodNumber: null,
  startDate: null,
  frequencyId: null,
  intervals: null,
  isPeriodOpen: false,
  isEntryAllowed: false,
}