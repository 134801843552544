import { PrimaryButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { ReviseModal } from './ReviseModal';

interface ReviseTravelPlanProps {
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
}
export const ReviseTravelPlan: React.FC<ReviseTravelPlanProps> = ({
  travelAuthorizationData,
}) => {
  const [visibility, setVisibility] = useState(false);
  const { _isRevisable } = {
    ...travelAuthorizationData,
  };

  return (
    <Stack>
      {_isRevisable && (
        <PrimaryButton text="Revise" onClick={() => setVisibility(true)} />
      )}
      {visibility && (
        <ReviseModal
          isOpen={visibility}
          travelAuthorizationData={travelAuthorizationData}
          onClose={() => setVisibility(false)}
        />
      )}
    </Stack>
  );
};
