import React, { memo } from 'react';
import { ITextProps, Text, useTheme } from '@fluentui/react';
import { useReactiveVar } from '@apollo/client';
import { highlightTextMode } from 'utility/cache/ui';

type HighlightTextViewProps = ITextProps & {
  isHighlightable?: boolean;
  highlightText: string;
  text: string;
  textColor?: string;
};

export const HighlightTextView: React.FC<HighlightTextViewProps> = memo(({
  isHighlightable,
  highlightText,
  text,
  textColor,
  ...props
}) => {
  const escapedHighlightText = highlightText.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&'
  );
  const parts = text?.split(new RegExp(`(${escapedHighlightText})`, 'gi'));

  const highlightTextState = useReactiveVar(highlightTextMode);
  const theme = useTheme();
  return (
    <Text variant="xLarge" {...props}>
      {highlightTextState ? (
        <>
          {parts?.map((part, index) => (
            <Text
              key={index}
              style={
                part.toLowerCase() === highlightText.toLowerCase()
                  ? {
                    borderRadius: 2,
                    color: textColor ? textColor : theme.palette.black,
                    backgroundColor: 'rgb(235,157,56)',
                  }
                  : { color: textColor ? textColor : theme.palette.black }
              }
            >
              {part}
            </Text>
          ))}
        </>
      ) : (
        <Text style={{ color: textColor ? textColor : theme.palette.black }}>
          {text}
        </Text>
      )}
    </Text>
  );
});