import { makeVar, useReactiveVar } from '@apollo/client';
import { FontIcon, IconButton, Stack, Text, useTheme } from '@fluentui/react';
import React, { ReactNode } from 'react';
import { CloseButton } from '../Buttons';
import { useStyles } from './index.styles';

export const togglePanelSize = makeVar<boolean>(false);

interface PanelHeaderProps {
  children?: ReactNode;
  title?: string;
  panelIcon?: string;
  hasHeaderText?: boolean;
  onClose: () => void;
  isPanelStretchable?: boolean;
  onPanelSizeToggle?: () => void;
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  children,
  title = 'Heading',
  panelIcon,
  hasHeaderText = true,
  onClose,
  isPanelStretchable = true,
  onPanelSizeToggle,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const isExpanded = useReactiveVar(togglePanelSize);
  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        className={styles.panelHeader}
        tokens={{ childrenGap: 10, padding: 25 }}
      >
        {hasHeaderText ? (
          <Stack tokens={{ childrenGap: 10 }} verticalAlign="center" horizontal>
            <Text variant="xLarge">{title}</Text>
            <FontIcon iconName={panelIcon} className={styles.panelIcon} />
          </Stack>
        ) : (
          <>{children}</>
        )}

        <Stack
          tokens={{ padding: '0px 0px 0px 0px' }}
          style={{ alignSelf: 'start' }}
        >
          <CloseButton onClick={onClose} />
        </Stack>
      </Stack>
      {isPanelStretchable && (
        <Stack>
          <IconButton
            onClick={() => {
              onPanelSizeToggle?.();
              togglePanelSize(!isExpanded);
            }}
            iconProps={{ iconName: isExpanded ? 'BackToWindow' : 'FullScreen' }}
            styles={{
              root: { backgroundColor: theme.palette.neutralLighter },
              icon: {
                color: isExpanded
                  ? theme.palette.themePrimary
                  : theme.palette.black,
              },
              rootHovered: {
                backgroundColor: theme.palette.neutralLight,
              },
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
