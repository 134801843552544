import { IColumn, Stack, Text } from "@fluentui/react";
import clsx from 'clsx';
import React from 'react';
import { BusinessUnitRow } from '.';
import { BusinessUnitModal } from '../view';

export type ColumnStylesProps = {
  onRenderColumnStack: string;
  businessUnitCol: string;
};

export const onRenderItems = (
  styles: ColumnStylesProps
) => {
  const renderItemColumn = (
    item: BusinessUnitRow | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof BusinessUnitRow
      ] as string;

      switch (column?.key) {
        case 'name':
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.businessUnitCol, styles.onRenderColumnStack)}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <BusinessUnitModal
                businessUnit={item}
              />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  }
  return renderItemColumn
}
