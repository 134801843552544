import React from 'react';
import { IPivotItemProps, Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import clsx from 'clsx';
export function PivotCustomRender(
  props?: IPivotItemProps,
  defaultRenderer?: (props?: IPivotItemProps) => JSX.Element | null
): JSX.Element | null {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  if (!props || !defaultRenderer) {
    return null;
  }

  return (
    <Stack horizontal>
      {defaultRenderer({ ...props, itemCount: undefined })}
      {props.itemCount !== 0 && <div className={styles.width10} />}
      {props.itemCount !== 0 && (
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          className={styles.container}
        >
          <Text
            variant="medium"
            className={clsx(commonStyles.semibold, styles.colorWhite)}
          >
            {props.itemCount}
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
