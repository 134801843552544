import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    padding: '0px 10px 0px 15px',
    marginBottom: 20,
    marginTop: 8,
  },

  iconButtonStyles: {
    color: theme.palette.neutralPrimaryAlt,
    selectors: {
      ':hover': {
        color: theme.palette.neutralPrimaryAlt,
        borderRadius: 2,
        backgroundColor: theme.palette.neutralLight,
      },
    },
  },
  iconButtonPrimaryStyles: {
    color: 'white',
    borderRadius: 5,
    backgroundColor: 'blue',
  },
}));
