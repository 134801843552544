import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  headerStyle: {
    padding: 25,
    boxShadow: `0px 4px 13px rgba(163, 163, 163, 0.33)`,
  },
  closeIcon: {
    color: theme.palette.neutralDark,
  },
  container: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  backIconStyle: { fontSize: FontSizes.size18 },
  pickerTitle: {
    fontSize: FontSizes.size14,
  },
  headerContainer: {
    alignItems: 'center',
  },
  pickerContainer: {
    marginRight: 20,
  },
}));
