import React from 'react';
import { Separator, Stack } from '@fluentui/react';
import { useStyles } from './index.styles';
import { AttachForm } from './AttachForm';
import { UploadForm } from './UploadForm';
import { loader } from 'graphql.macro';
import { secureRowLevelNode } from 'common/components/SecureRowLevel';
import {
  GetPaymentDetails,
  GetPaymentDetailsVariables,
  GetPaymentDetails_payment,
} from '../../../__generated__/GetPaymentDetails';
import { PaymentUploadDocument_paymentUploadDocument } from './UploadForm/__generated__/PaymentUploadDocument';
import { useApolloClient, useQuery } from '@apollo/client';
import { NoDataView } from 'common/components/DataPlaceholders';
import {
  PaymentAvailableDocumentTypes,
  PaymentAvailableDocumentTypesVariables,
} from '../../../__generated__/PaymentAvailableDocumentTypes';
import { DocumentList } from 'common/components/DocumentList';
import { documentColumns } from '../../../document.columns';
const PAYMENT_DETAILS = loader('../../../GetPaymentDetails.graphql');
const AVAILABLE_DOCUMENT_TYPES = loader(
  '../../../PaymentAvailableDocumentTypes.graphql'
);

interface AttachSectionProps {
  secureRowLevel: secureRowLevelNode[];
  paymentDetails: GetPaymentDetails_payment;
  onUpload?: (
    fileSelected: File,
    documentData: PaymentUploadDocument_paymentUploadDocument,
    toastId: string
  ) => void;
  isViewOnly?: boolean;
}
export const AttachSection: React.FC<AttachSectionProps> = ({
  secureRowLevel,
  paymentDetails,
  onUpload,
  isViewOnly = false,
}) => {
  const styles = useStyles();
  const { data: AvailableDocumentTypes } = useQuery<
    PaymentAvailableDocumentTypes,
    PaymentAvailableDocumentTypesVariables
  >(AVAILABLE_DOCUMENT_TYPES, {
    variables: {
      transactionTypeId: paymentDetails && paymentDetails.transactionTypeId!,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const client = useApolloClient();

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      {!isViewOnly && (
        <Stack
          horizontal
          horizontalAlign="end"
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
        >
          <AttachForm
            AvailableDocumentTypes={AvailableDocumentTypes}
            payment={paymentDetails}
          />
          <Separator vertical />
          <UploadForm
            availableDocumentTypes={AvailableDocumentTypes}
            payment={paymentDetails}
            onUpload={onUpload}
          />
        </Stack>
      )}
      {paymentDetails?.entityDocumentsByEntityId.nodes.length! > 0 && (
        <Stack tokens={{ childrenGap: 10, padding: 10 }}>
          <DocumentList
            isNew={false}
            columns={documentColumns}
            data={paymentDetails?.entityDocumentsByEntityId?.nodes! || []}
            isDeleteButtonVisible={!isViewOnly}
            isShareAndProtectButtonsVisible
            secureRowLevel={isViewOnly ? undefined : secureRowLevel}
            onRemoveRow={(data) => {
              const cacheData = client.readQuery<
                GetPaymentDetails,
                GetPaymentDetailsVariables
              >({
                query: PAYMENT_DETAILS,
                variables: {
                  id: paymentDetails?.id!,
                },
              });
              if (cacheData) {
                const filteredList =
                  cacheData?.payment?.entityDocumentsByEntityId.nodes.filter(
                    (ele) => ele.id !== data.id
                  );
                const updatedData: GetPaymentDetails = {
                  payment: {
                    ...cacheData?.payment!,
                    _isApprovalDocumentsRequired:
                      data.paymentDocument?._isApprovalDocumentsRequired!,
                    _requiredApprovalDocuments:
                      data.paymentDocument?._requiredApprovalDocuments!,
                    entityDocumentsByEntityId: {
                      ...cacheData?.payment?.entityDocumentsByEntityId,
                      nodes: filteredList!,
                    },
                  },
                };
                client.writeQuery<
                  GetPaymentDetails,
                  GetPaymentDetailsVariables
                >({
                  query: PAYMENT_DETAILS,
                  data: updatedData,
                  variables: {
                    id: paymentDetails?.id!,
                  },
                });
              }
            }}
          />
        </Stack>
      )}
      <NoDataView
        show={paymentDetails?.entityDocumentsByEntityId.nodes.length === 0}
        title="No Attachments"
      />
    </Stack>
  );
};
