import { useMutation } from '@apollo/client';
import {
  ContextualMenu,
  IDragOptions,
  Modal,
  Stack,
  makeStyles,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  LookupCorporateAccountPatch,
  LookupCorporateAccountUpdateInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { EditCorporateAccountProps } from '..';
import { LookupCorporateAccountSearch_lookupCorporateAccountSearch } from '../../list/__generated__/LookupCorporateAccountSearch';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { Header } from './Header';
import {
  LookupCorporateAccountCreate,
  LookupCorporateAccountCreateVariables,
} from './__generated__/LookupCorporateAccountCreate';
import {
  LookupCorporateAccountUpdate,
  LookupCorporateAccountUpdateVariables,
} from './__generated__/LookupCorporateAccountUpdate';
import { CorporateAccountValues } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validation';
const LOOKUP_CORPORATE_ACCOUNT_CREATE = loader(
  './LookupCorporateAccountCreate.graphql'
);
const LOOKUP_CORPORATE_ACCOUNT_UPDATE = loader(
  './lookupCorporateAccountUpdate.graphql'
);
const LOOKUP_CORPORATE_ACCOUNT_FIELDS = loader(
  '../../LookupCorporateAccountFields.graphql'
);
const LOOKUP_CORPORATE_ACCOUNT_FRAGMENT_NAME = 'LookupCorporateAccountFields';

const useStyles = makeStyles(() => ({
  container: {
    width: 600,
    maxHeight: 600,
  },
}));

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

type CorporateAccountModalProps = Partial<EditCorporateAccountProps> & {
  chartOfAccountId: string | null;
  onClose: () => void;
};

export const CorporateAccountModal: React.FC<CorporateAccountModalProps> = ({
  chartOfAccountId,
  corporateAccount,
  onClose,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const [createCorporateAccount, { loading: createCorporateAccountLoading }] =
    useMutation<
      LookupCorporateAccountCreate,
      LookupCorporateAccountCreateVariables
    >(LOOKUP_CORPORATE_ACCOUNT_CREATE, { errorPolicy: 'all' });

  const [updateCorporateAccount, { loading: updateCorporateAccountLoading }] =
    useMutation<
      LookupCorporateAccountUpdate,
      LookupCorporateAccountUpdateVariables
    >(LOOKUP_CORPORATE_ACCOUNT_UPDATE, { errorPolicy: 'all' });

  const formMethods = useForm<CorporateAccountValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { handleSubmit, reset, trigger } = { ...formMethods };

  const { id, _rowTimestamp } = { ...corporateAccount };
  const isEdit = !!id;

  const onHandleSubmit = async (values: CorporateAccountValues) => {
    if (isEdit && corporateAccount) {
      const defaultValues = getDefaultValues({
        corporateAccount,
      });
      const lookupCorporateAccountPatch: LookupCorporateAccountPatch =
        Object.entries(values).reduce((res, [key, val]) => {
          if (val !== defaultValues[key as keyof CorporateAccountValues]) {
            return { ...res, [key]: val };
          }
          return res;
        }, {});
      const { errors } = await updateCorporateAccount({
        variables: {
          input: {
            id,
            rowTimestamp: _rowTimestamp,
            lookupCorporateAccountPatch: !isEmpty(lookupCorporateAccountPatch)
              ? lookupCorporateAccountPatch
              : undefined,
          } as LookupCorporateAccountUpdateInput,
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Corporate account edited successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const { errors } = await createCorporateAccount({
        variables: {
          input: {
            chartOfAccountId: chartOfAccountId || '',
            lookupCorporateAccount: {
              ...values,
              lookupName: values.lookupName || '',
              subAccount: values.subAccount || '',
              account: values.account || '',
              isTravelAccountForAllDepartments: !!values.isTravelAccountForAllDepartments || false
            }
          }
        },
        update: (cache, { data }) => {
          if (
            !!data?.lookupCorporateAccountCreate?.lookupCorporateAccount?.id
          ) {
            cache.modify({
              fields: {
                lookupCorporateAccountSearch(
                  existing: LookupCorporateAccountSearch_lookupCorporateAccountSearch
                ) {
                  if (
                    data?.lookupCorporateAccountCreate?.lookupCorporateAccount
                  ) {
                    const newCorporateAccountRef = cache.writeFragment({
                      data: data.lookupCorporateAccountCreate
                        .lookupCorporateAccount,
                      fragment: LOOKUP_CORPORATE_ACCOUNT_FIELDS,
                      fragmentName: LOOKUP_CORPORATE_ACCOUNT_FRAGMENT_NAME,
                    });
                    return {
                      ...existing,
                      nodes: [newCorporateAccountRef, ...existing.nodes],
                    };
                  }
                },
              },
            });
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Corporate account created successfully.', {
          appearance: 'success',
        });
        onClose();
      }
    }
  };

  const loading =
    createCorporateAccountLoading || updateCorporateAccountLoading;

  useEffect(() => {
    if (corporateAccount) {
      const defaultValues = getDefaultValues({
        corporateAccount,
      });
      reset(defaultValues);
    }
    trigger();
  }, [corporateAccount, reset, trigger]);

  return (
    <Modal isOpen isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        <FormProvider {...formMethods}>
          <Header isEdit={isEdit} onClose={onClose} />
          <BasicForm />
          <Footer
            loading={loading}
            onSubmit={handleSubmit(onHandleSubmit)}
            onCancel={onClose}
          />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
