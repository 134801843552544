import { Stack, TooltipHost } from '@fluentui/react';
import React from 'react';
import { TravelAuthorizationItem } from '..';

import { ReactComponent as AccommodationIcon } from 'assets/svgs/TripIcons/accomodationIcon.svg';
import { ReactComponent as VehicleRentalIcon } from 'assets/svgs/TripIcons/driveIcon.svg';
import { ReactComponent as FlightIcon } from 'assets/svgs/TripIcons/flightIcon.svg';
import { ReactComponent as GroundTransportationIcon } from 'assets/svgs/TripIcons/groundTransportIcon.svg';
import { ReactComponent as OtherChargesIcon } from 'assets/svgs/TripIcons/otherChargesIcon.svg';
import { ReactComponent as RailIcon } from 'assets/svgs/TripIcons/railIcon.svg';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { useStyles } from '../index.styles';

interface SectionIconsProps {
  data: TravelAuthorizationItem | undefined;
}
export const SectionIcons: React.FC<SectionIconsProps> = ({ data }) => {
  const {
    _isAirfareExist,
    _isAccommodationExist,
    _isIncidentalExist,
    _isTrainExist,
    _isVehicleRentalExist,
    _isTransportationExist,
  } = { ...data };

  const styles = useStyles();
  return (
    <Stack style={{ height: 30 }} tokens={{ childrenGap: 10 }} horizontal>
      {_isAirfareExist && (
        <TooltipHost
          content={getFormattedAmountValue(
            parseInt(data?._airfareAmount || '0')?.toFixed(2)
          )}
          hidden={!data?._airfareAmount}
        >
          <FlightIcon className={styles.sectionIcons} />
        </TooltipHost>
      )}
      {_isAccommodationExist && (
        <TooltipHost
          content={getFormattedAmountValue(
            parseInt(data?._accommodationAmount || '0')?.toFixed(2)
          )}
          hidden={!data?._accommodationAmount}
        >
          <AccommodationIcon className={styles.sectionIcons} />
        </TooltipHost>
      )}
      {_isVehicleRentalExist && (
        <TooltipHost
          content={getFormattedAmountValue(
            parseInt(data?._vehicleRentalAmount || '0')?.toFixed(2)
          )}
          hidden={!data?._vehicleRentalAmount}
        >
          <VehicleRentalIcon className={styles.sectionIcons} />
        </TooltipHost>
      )}
      {_isTransportationExist && (
        <TooltipHost
          content={getFormattedAmountValue(
            parseInt(data?._transportationAmount || '0')?.toFixed(2)
          )}
          hidden={!data?._transportationAmount}
        >
          <GroundTransportationIcon className={styles.sectionIcons} />
        </TooltipHost>
      )}

      {_isTrainExist && (
        <TooltipHost
          content={getFormattedAmountValue(
            parseInt(data?._trainAmount || '0')?.toFixed(2)
          )}
          hidden={!data?._trainAmount}
        >
          <RailIcon className={styles.sectionIcons} />
        </TooltipHost>
      )}
      {_isIncidentalExist && (
        <TooltipHost
          content={getFormattedAmountValue(
            parseInt(data?._incidentalAmount || '0')?.toFixed(2)
          )}
          hidden={!data?._incidentalAmount}
        >
          <OtherChargesIcon className={styles.sectionIcons} />
        </TooltipHost>
      )}
    </Stack>
  );
};
