import {
  DepartmentBudgetInput,
  DepartmentBudgetUpdateTypeInput,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { differenceBy, intersection, isEmpty } from 'lodash';
import { Budgets_budgets_nodes } from './__generated__/Budgets';
import {
  DepartmentBudgets,
  DepartmentBudgets_departmentBudgets_nodes,
} from './__generated__/DepartmentBudgets';
import { BudgetItemFormValues, DepartmentItemValues } from './types';
import { DEPARTMENT_BUDGET_INITIAL_VALUES } from './constant';
import { FilterArrayType } from 'common/components/Filters';
import { dateFormat } from 'common/utils/dateFormats';

interface DefaultValueProps {
  budgetItem: Budgets_budgets_nodes | undefined | null;
}

interface DefaultValueProps {
  isNew?: boolean;
  budgetItem: Budgets_budgets_nodes | undefined | null;
  departmentBudgetData: DepartmentBudgets | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const {
    isNew = false,
    budgetItem,
    departmentBudgetData,
  } = {
    ...props,
  };

  const { departmentBudgets } = {
    ...departmentBudgetData,
  };

  let defaultValues: BudgetItemFormValues | null = null;

  if (budgetItem && !isNew) {
    const { nodes } = {
      ...departmentBudgets,
    };
    defaultValues = {
      id: budgetItem?.id,
      name: budgetItem?.name,
      startingBudgetDate: budgetItem?.startingBudgetDate,
      endingBudgetDate: budgetItem?.endingBudgetDate,
      isPrimary: budgetItem?.isPrimary,
      departmentBudgets: !!nodes?.length
        ? [
            ...nodes.map((row) => {
              const lookUpName = row.lookupAccount?.lookupAccount;
              const patch = Object.entries(row).reduce((res, [key, val]) => {
                if (
                  key === 'departmentId' ||
                  key === 'businessUnitId' ||
                  key === 'budgetTypeId' ||
                  key === 'lookupAccountId' ||
                  key === 'currencyId' ||
                  key === 'budgetAmount' ||
                  key === 'id' ||
                  key === '_rowTimestamp' ||
                  key === '_isUpdatable' ||
                  key === '_isDeletable'
                )
                  return { ...res, [key]: val };
                if (key === 'lookupAccount') {
                  return { ...res, [key]: lookUpName };
                } else return res;
              }, {});
              return patch as DepartmentItemValues;
            }),
            DEPARTMENT_BUDGET_INITIAL_VALUES,
          ]
        : [DEPARTMENT_BUDGET_INITIAL_VALUES],
    };
  }
  return defaultValues as BudgetItemFormValues;
};

export const getDepartmentBudgetUpdatePatch = (
  formValues: DepartmentItemValues[] | null,
  departmentBudgetData: DepartmentBudgets_departmentBudgets_nodes[] | undefined
) => {
  const updatedDeptBudget =
    departmentBudgetData &&
    formValues &&
    intersection(
      departmentBudgetData.map((dept) => dept.id),
      formValues.filter((dept) => dept.id).map((dept) => dept.id)
    ).reduce((arr, targetId) => {
      const initialDeptBudget = departmentBudgetData.find(
        (dept) => dept.id === targetId
      )!;
      const {
        _isUpdatable,
        _isDeletable,
        lookupAccount,
        ...updatedDeptBudget
      } = formValues!.find((dept) => dept.id === targetId)!;
      const patch = Object.entries(updatedDeptBudget).reduce(
        (res, [key, val]) => {
          if (
            val !==
            initialDeptBudget[
              key as keyof DepartmentBudgets_departmentBudgets_nodes
            ]
          ) {
            if (key === 'currencyId' || key === 'budgetTypeId')
              return { ...res, [key]: Number(val) };
            return { ...res, [key]: val };
          }
          return res;
        },
        {}
      );
      if (!isEmpty(patch))
        return [
          ...arr,
          {
            id: updatedDeptBudget.id,
            rowTimestamp: updatedDeptBudget._rowTimestamp,
            departmentBudgetPatch: patch,
          },
        ];
      else return [...arr];
    }, [] as DepartmentBudgetUpdateTypeInput[]);
  return updatedDeptBudget;
};

export const getDepartmentBudgetCreatePatch = (
  formValues: DepartmentItemValues[] | null
) => {
  const newDepartmentBudget = formValues
    ?.filter(
      (dept) =>
        !dept.id && dept.departmentId !== null && dept.budgetAmount !== null
    )
    .map(
      ({
        id,
        _rowTimestamp,
        _isUpdatable,
        _isDeletable,
        lookupAccount,
        ...dept
      }) => {
        const patch = Object.entries(dept).reduce((res, [key, val]) => {
          if (key === 'currencyId' || key === 'budgetTypeId')
            return { ...res, [key]: Number(val) };
          return { ...res, [key]: val };
        }, {});
        return patch;
      }
    ) as DepartmentBudgetInput[];
  return newDepartmentBudget;
};

export const getDepartmentBudgetDeletePatch = (
  formValues: DepartmentItemValues[] | null,
  departmentBudgetData:
    | DepartmentBudgets_departmentBudgets_nodes[]
    | undefined[]
    | undefined
) => {
  const deletedDeptRows = differenceBy(
    departmentBudgetData,
    formValues || [],
    'id'
  ).map((dept) => ({
    id: dept?.id!,
    rowTimestamp: dept?._rowTimestamp!,
  })) as EntityDeleteInput[];
  return deletedDeptRows;
};

export const getBudgetUpdatePatch = (
  formValues: BudgetItemFormValues,
  selectedBudget: Budgets_budgets_nodes
) => {
  const patch = Object.entries(formValues).reduce((res, [key, val]) => {
    if (val !== selectedBudget[key as keyof Budgets_budgets_nodes]) {
      if (key === 'name' || key === 'isPrimary') return { ...res, [key]: val };
      if (key === 'startingBudgetDate' || key === 'endingBudgetDate')
        return { ...res, [key]: dateFormat(val?.toString() ?? '') };
    }
    return res;
  }, {});
  return patch;
};

export const budgetFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'departmentId':
      case 'currencyId':
      case 'businessUnitId':
      case 'budgetTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      default:
        return undefined;
    }
  });
