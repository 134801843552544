import { useLazyQuery } from '@apollo/client'
import { ITextFieldProps, ITextFieldStyles, Spinner, Stack, TextField, makeStyles, useTheme } from '@fluentui/react'
import { RequiredNameProps } from 'common/types/utility'
import { loader } from 'graphql.macro'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form';
import _ from 'lodash';
import { SupplierLookUpCallout } from '../../../../../common/components/SupplierLookUpCallout'
import { LookupSupplierSearch, LookupSupplierSearchVariables } from 'common/graphql/__generated__/LookupSupplierSearch'
const LOOKUP_SUPPLIER_SEARCH = loader('../../../../../common/graphql/LookupSupplierSearch.graphql');

type CompanyAutoCompleteProps = RequiredNameProps<ITextFieldProps> & {
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  calloutContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export const CompanyAutoComplete: React.FC<CompanyAutoCompleteProps> = ({ ...props }) => {
  const styles = useStyles();
  const theme = useTheme();
  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: props.disabled
        ? theme.palette.neutralLighter
        : theme.palette.white,
    },
  };

  const {
    field: { onChange, value, },
    fieldState: { error, },
  } = useController({
    name: props.name,
  });

  const renderRef = useRef(false);
  const [calloutVisible, setCalloutVisible] = useState(true);


  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupSupplierSearch,
    LookupSupplierSearchVariables
  >(LOOKUP_SUPPLIER_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { id, disabled, ...textfieldProps } = props;

  const lookupCallBack = useCallback(
    (value) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
    },
    [fetchLookUp]
  );

  useEffect(() => {
    if (value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(value);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack]);

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
      queryCalled();
    }, 350),
    []
  );

  const renderSpinner = () => {
    return (
      <>
        {
          loading && <Spinner />
        }
      </>
    );
  };

  return (
    <Stack>
      <TextField
        {...textfieldProps}
        id={id}
        autoComplete={'off'}
        errorMessage={error?.message}
        value={value || null}
        onChange={(_event, value) => {
          if (value) {
            onChange(value);
            callbackLookUp(value, () => {
              if (calloutVisible === false) setCalloutVisible(true);
            });
          } else {
            onChange(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textfieldStyles}
        disabled={disabled ? true : false}
      />
      <Stack className={styles.calloutContainer}>
        {calloutVisible && !loading && (
          <SupplierLookUpCallout
            lookUpCalloutWidth={400}
            onDismiss={() => {
              setCalloutVisible(false)
            }}
            onSelect={() => { }}
            targetID={`#${id!}`}
            data={data?.lookupSupplierSearch?.nodes!}
          />
        )}
      </Stack>
    </Stack>
  )
}
