import { Dialog, IDialogProps } from '@fluentui/react';
import React, { useEffect } from 'react';
import { closeOpenedDocument, setActiveOrderIndex } from 'utility/cache/ui';

export const DialogWrapper: React.FunctionComponent<IDialogProps> = (props) => {
  useEffect(() => {
    if (!props.hidden) {
      const current = setActiveOrderIndex();
      current.clear();
      setActiveOrderIndex(current);
      closeOpenedDocument(true);
    }
  }, [props.hidden]);

  return <Dialog {...props} />;
};
