import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  formRow: {
    margin: "20px 0px"
  },
  fieldContainer: {
    flex: 1
  },
  heading: {
    margin: "10px 0px 30px"
  },
  verifyButtonContainer: {
    marginBottom: "20px"
  },
  swiftCodeContainer: {
    flex: 0.32
  },
  iconButtonContainer:{
    padding: "5px 0px"  
  },
  iconButtonColor:{
    color: theme.palette.neutralDark
  }
}));