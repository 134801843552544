import { useQuery } from '@apollo/client';
import { ContextualMenu, MessageBar, MessageBarType, Separator, Stack } from '@fluentui/react';
import { ShimmerView } from 'ap/paymentCycle/view/ShimmerView/ShimmerViews';
import { ActionsMenu } from 'ap/paymentTracking/view/PaymentTrackingView/ActionMenu';
import { BasicForm } from 'ap/paymentTracking/view/PaymentTrackingView/BasicForm';
import { Header } from 'ap/paymentTracking/view/PaymentTrackingView/Header';
import {
  GetPaymentDetails,
  GetPaymentDetails_payment_currency,
  GetPaymentDetailsVariables,
} from 'ap/paymentTracking/view/PaymentTrackingView/__generated__/GetPaymentDetails';
import { PaymentTypes } from 'ap/paymentTracking/view/PaymentTrackingView/__generated__/PaymentTypes';
import { PAYMENT_INITIAL_VALUES } from 'ap/paymentTracking/view/PaymentTrackingView/constant';
import { PaymentValues } from 'ap/paymentTracking/view/PaymentTrackingView/types';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { StamperView, StampOptions } from 'common/components/StamperView';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { Formik } from 'formik';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useStyles } from './index.styles';

const GET_PAYMENT_DETAILS = loader(
  '../../../../ap/paymentTracking/view/PaymentTrackingView/GetPaymentDetails.graphql'
);
const PAYMENT_DATA = loader(
  '../../../../ap/paymentTracking/view/PaymentTrackingView/PaymentTypes.graphql'
);

interface PaymentModalProps {
  onClose: () => void;
  paymentId: string;
}

export const PaymentModal: React.FC<PaymentModalProps> = ({
  paymentId,
  onClose,
}) => {
  const styles = useStyles();
  const [stampData, setStampData] = useState<StampOptions>();
  const [forNewPaymentCycleId, setForNewPaymentCycleId] = useState<string>('');
  const [forNewPaymentCycleDate, setForNewPaymentCycleDate] =
    useState<string>('');
  const [forNewPaymentCycleCurrency, setForNewPaymentCycleCurrency] =
    useState<GetPaymentDetails_payment_currency>();
  const { data: paymentDetailsData, loading: paymentDetailsLoading } = useQuery<
    GetPaymentDetails,
    GetPaymentDetailsVariables
  >(GET_PAYMENT_DETAILS, {
    variables: {
      id: paymentId!,
    },
    skip: !paymentId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const { data: paymentTypeData } = useQuery<PaymentTypes>(PAYMENT_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  let initialValues: PaymentValues = PAYMENT_INITIAL_VALUES;
  if (paymentDetailsData?.payment) {
    initialValues = {
      ...paymentDetailsData.payment,
      paidDate: dateConvertions(paymentDetailsData.payment.paidDate),
      accountingInvoiceTotal: paymentDetailsData.payment.invoicePayments
        .aggregates?.sum?.appliedAmount
        ? parseFloat(
            paymentDetailsData.payment.invoicePayments.aggregates?.sum
              ?.appliedAmount
          )
        : 0.0,
      invoicePayments: [],
    };
  } else {
    initialValues = {
      ...initialValues,
      payCycleId: forNewPaymentCycleId || '',
      currency: forNewPaymentCycleCurrency!,
      paidDate: forNewPaymentCycleDate,
      accountingInvoiceTotal: 0,
      invoicePayments: [],
    };
  }
  useEffect(() => {
    if (paymentDetailsData) {
      if (paymentDetailsData.payment?.payCycleId) {
        setForNewPaymentCycleId(paymentDetailsData.payment?.payCycleId);
      }
      if (paymentDetailsData.payment?.currency) {
        setForNewPaymentCycleCurrency(paymentDetailsData.payment?.currency);
      }
      if (paymentDetailsData.payment?.paidDate) {
        setForNewPaymentCycleDate(
          dateFormat(dateConvertions(paymentDetailsData.payment?.paidDate))
        );
      }
      const stampOptions: StampOptions = {
        _accountingStampDate: paymentDetailsData.payment?._accountingStampDate,
        _accountingStampTransactionReference:
          paymentDetailsData.payment?._accountingStampTransactionReference,
        _accountingStampUserName:
          paymentDetailsData.payment?._accountingStampUserName,
        _isAccountingEntryStampedComplete:
          paymentDetailsData.payment?._isAccountingEntryStampedComplete,
        _isTransactionCancelled:
          paymentDetailsData.payment?._isTransactionCancelled,
      };
      setStampData(stampOptions);
    }
  }, [paymentDetailsData]);
  return (
    <Formik<PaymentValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      <ModalWrapper
        isOpen
        containerClassName={styles.modalContainer}
        onDismiss={onClose}
        dragOptions={{
          moveMenuItemText: 'Move',
          closeMenuItemText: 'Close',
          menu: ContextualMenu,
          dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
        }}
      >
        <Stack
          grow
          horizontal
          horizontalAlign="space-between"
          className={styles.header}
        >
          <Header
            isNew={false}
            dirty={false}
            isSubmitting={false}
            dataLoading={paymentDetailsLoading}
            payment={paymentDetailsData?.payment}
          />
          <Stack horizontal>
            <Stack>
              {
                <Stack style={{ marginRight: 10 }}>
                  {stampData &&
                    paymentDetailsData?.payment
                      ?._isAccountingEntryStampedComplete && (
                      <StamperView invoiceDetails={stampData} />
                    )}
                </Stack>
              }
            </Stack>
            <CloseButton onClick={onClose} />
          </Stack>
        </Stack>
        {paymentDetailsLoading ? (
          <Stack
            className={styles.shimmerViewMainContainer}
            tokens={{ childrenGap: 20 }}
          >
            <Stack
              className={styles.headerContainer}
              tokens={{ childrenGap: 10 }}
            >
              <ShimmerView />
            </Stack>
          </Stack>
        ) : (
          <Stack>
            {
              <Stack>
                <Stack
                  className={styles.headerContainer}
                  tokens={{ childrenGap: 10 }}
                >
                  <Stack className={styles.requiredAttachmentsMessage}>
                    {paymentDetailsData?.payment
                      ?._isApprovalDocumentsRequired &&
                      paymentDetailsData?.payment
                        ?._requiredApprovalDocuments && (
                        <MessageBar messageBarType={MessageBarType.error}>
                          {
                            paymentDetailsData?.payment
                              ?._requiredApprovalDocuments
                          }
                        </MessageBar>
                      )}
                    {paymentDetailsData?.payment && (
                      <ActionsMenu
                        payment={paymentDetailsData?.payment}
                        secureRowLevels={paymentTypeData?.secureRowLevels}
                        isViewOnly
                      />
                    )}
                  </Stack>
                </Stack>
                <Separator />
              </Stack>
            }
            <BasicForm paymentDetails={paymentDetailsData} isNew={false} />
          </Stack>
        )}
      </ModalWrapper>
    </Formik>
  );
};
