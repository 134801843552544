import { useQuery } from '@apollo/client';
import {
  Dropdown,
  IDropdownStyles,
  Stack,
  Theme,
  useTheme,
} from '@fluentui/react';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React from 'react';
import {
  ChartOfAccounts,
  ChartOfAccounts_chartOfAccounts_nodes,
} from './__generated__/ChartOfAccounts';
const CHART_ACCOUNTING = loader('./ChartOfAccounts.graphql');

const getDropdownStyles = (theme: Theme) => {
  const dropdownStyles: Partial<IDropdownStyles> = {
    title: {
      border: 0,
      outline: 0,
      borderBottom: `1px solid ${theme.semanticColors.inputBorder}`,
    },
    dropdown: {
      width: 190,
    },
  };
  return dropdownStyles;
};

interface ChartAccountProps {
  onSelectOption: (
    option: ChartOfAccounts_chartOfAccounts_nodes | null
  ) => void;
  chartOfAccountId: ChartOfAccounts_chartOfAccounts_nodes | null;
}

export const ChartAccount: React.FC<ChartAccountProps> = ({
  onSelectOption,
  chartOfAccountId,
}) => {
  const { data } = useQuery<ChartOfAccounts>(CHART_ACCOUNTING, {
    onCompleted: (data) => {
      if (!!data?.chartOfAccounts?.nodes.length && !chartOfAccountId) {
        onSelectOption(data?.chartOfAccounts?.nodes?.[0]);
      } else onSelectOption(null);
    },
  });
  const theme = useTheme();
  const dropdownStyles = getDropdownStyles(theme);
  const options = formatDropdownOptions(data?.chartOfAccounts?.nodes, {
    getKey: (item) => item.id!,
    getText: (item) => item.name!,
    includeAll: false,
  });

  return (
    <Stack>
      <Dropdown
        options={options}
        selectedKey={chartOfAccountId?.id}
        placeholder="Choose Chart of Accounts"
        styles={dropdownStyles}
        onChange={(_event, option) => {
          if (option?.key) {
            onSelectOption(
              data?.chartOfAccounts?.nodes.find(
                (item) => item.id === option.key
              ) || null
            );
          }
        }}
      />
    </Stack>
  );
};
