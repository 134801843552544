import {
  Dropdown,
  IDropdownOption,
  IDropdownProps,
  IDropdownStyleProps,
  IDropdownStyles,
  TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { FilterArrayType, filterCombineType } from '.';

type DropdownFilterProps = {
  placeholder?: string;
  options: IDropdownOption[];
  filterKey: string;
  onOptionChange?: (data: FilterArrayType) => void;
  disabled?: boolean;
  selectedKey?: number | string | undefined | null;
  defaultFilter?: boolean;
  controlled?: boolean;
  combineType?:
    | filterCombineType.AND
    | filterCombineType.NOT
    | filterCombineType.OR;
  maxHeight?: number;
  multiSelect?: boolean;
  width?: number;
  NoBgColor?: boolean;
} & Pick<IDropdownProps, 'dropdownWidth' | 'defaultSelectedKey' | 'styles'>;

export const DropdownFilter: React.FC<DropdownFilterProps> = ({
  placeholder = 'Select item',
  defaultFilter = false,
  onOptionChange,
  options = [],
  filterKey,
  disabled = false,
  selectedKey,
  controlled = false,
  combineType = filterCombineType.AND,
  maxHeight,
  multiSelect = false,
  NoBgColor = false,
  width,
  ...props
}) => {
  const label = defaultFilter ? placeholder : undefined;
  const tooltipContent = defaultFilter ? undefined : placeholder;
  const placeholderContent = defaultFilter ? 'Select' : placeholder;
  const selectedKeyValue = controlled ? selectedKey : undefined;
  const [selectedValues] = React.useState<Map<string, boolean>>(new Map());
  const dropdownWidth = width ? width : undefined;
  function getDropdownStyles(
    props: IDropdownStyleProps
  ): Partial<IDropdownStyles> {
    return {
      root: {
        textAlign: 'center',
        border: 0,
        width: dropdownWidth,
      },
      callout: { height: maxHeight },
      title: {
        backgroundColor: NoBgColor
          ? undefined
          : props.theme?.palette.neutralLighter,
        selectors: {
          ':hover': {
            borderRadius: 5,
            backgroundColor: props.theme?.palette.neutralQuaternary,
          },
        },
        border: 0,
      },
    };
  }
  return (
    <TooltipHost content={tooltipContent}>
      <Dropdown
        dropdownWidth={'auto'}
        disabled={disabled}
        placeholder={placeholderContent}
        options={options}
        multiSelect={multiSelect}
        label={label}
        selectedKey={selectedKeyValue}
        styles={defaultFilter ? undefined : getDropdownStyles}
        onChange={(_, option) => {
          let value: string | number | string[] = option?.key!;
          if (multiSelect) {
            if (option) {
              if (option.selected) {
                selectedValues.set(option?.key as string, true);
                value = Array.from(selectedValues.keys());
              } else {
                // Remove the deselected item from the state
                selectedValues.delete(option?.key as string);
                value = Array.from(selectedValues.keys());
              }
            }
          }

          onOptionChange?.({
            value: value,
            filterKey,
            label: option?.text!,
            combineType,
          });
        }}
        {...props}
      />
    </TooltipHost>
  );
};

export function getDefaultDropdownStyles():
  | Partial<IDropdownStyles>
  | undefined {
  return undefined;
}
