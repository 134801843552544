import { Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CorporateWorkgroup } from '../../__generated__/CorporateWorkgroup';
import { CorporateWorkgroupValues } from '../../types';
import { DeleteWorkGroup } from './DeleteWorkGroup';
import { useStyles } from './index.styles';

export interface HeaderProps {
  isNew: boolean;
  dataLoading: boolean;
  corporateWorkgroupData: CorporateWorkgroup | undefined;
  onWorkgroupDelete: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  isNew,
  dataLoading,
  corporateWorkgroupData,
  onWorkgroupDelete,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { formState: { isDirty, isSubmitting } } = useFormContext<CorporateWorkgroupValues>();

  const {
    workgroupNumber,
    statusType,
  } = { ...corporateWorkgroupData?.corporateWorkgroup }

  const panelHeading = isNew ? 'Create New Workgroup' : 'Edit Workgroup';
  const status = !isNew && corporateWorkgroupData ? `(${statusType?.statusType})` : null;

  return (
    <Stack
      horizontal
      className={styles.fullWidth}
      verticalAlign="center"
    >
      <Stack grow>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Text variant="xLarge">{panelHeading}</Text>
          {!isNew && (
            <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
              {workgroupNumber}
            </Text>
          )}
          <UnsavedIndicator
            visible={!isNew && !dataLoading && isDirty && !isSubmitting}
          />
        </Stack>
      </Stack>
      <DeleteWorkGroup
        isNew={isNew}
        corporateWorkgroupData={corporateWorkgroupData}
        onWorkgroupDelete={onWorkgroupDelete}
      />
      <Text
        variant="mediumPlus"
        className={clsx(commonStyles.colorThemePrimary, styles.status)}>
        {status}
      </Text>
    </Stack>
  )
}
