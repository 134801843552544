import {
  Separator,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,
} from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './index.styles';

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection2 = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 30, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection4 = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <ShimmerElementsGroup
        width="150px"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 30, width: 150 },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          {
            type: ShimmerElementType.gap,
            height: 30,
            width: '100%',
          },
        ]}
      />
    </div>
  );
};

export const ShimmerSection3 = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '60%' },
          { type: ShimmerElementType.gap, height: 40, width: 20 },
          { type: ShimmerElementType.line, height: 40, width: '40%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '60%' },
          { type: ShimmerElementType.gap, height: 40, width: 20 },
          { type: ShimmerElementType.line, height: 40, width: '40%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '60%' },
          { type: ShimmerElementType.gap, height: 40, width: 20 },
          { type: ShimmerElementType.line, height: 40, width: '40%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '50%' },
          { type: ShimmerElementType.gap, height: 40, width: '10%' },
          { type: ShimmerElementType.line, height: 40, width: '40%' },
        ]}
      />
    </div>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Stack className={clsx(styles.containerPadding, styles.containerSetting)}>
        <Shimmer customElementsGroup={ShimmerSection()} />
      </Stack>
      <Stack className={styles.containerPadding}>
        <Shimmer customElementsGroup={ShimmerSection3()} />
      </Stack>
      <Separator />

      <Stack className={styles.containerMargin}>
        <Shimmer customElementsGroup={ShimmerSection4()} />
      </Stack>
      <Stack tokens={{ childrenGap: 20 }} className={styles.containerMargin}>
        <Shimmer customElementsGroup={ShimmerSection2()} />
        <Shimmer customElementsGroup={ShimmerSection2()} />
      </Stack>
      <Separator />
    </React.Fragment>
  );
};
