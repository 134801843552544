import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { useStyles } from './index.styles';
import { UserSetupNotesSection } from './UserNotes';
import { AttachSection } from './UserAttach';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { UserProfile } from '../__generated__/UserProfile';
import { UserSetupUploadDocument_userSetupUploadDocument_document } from './UserAttach/UploadForm/__generated__/UserSetupUploadDocument';
import { UserSetupCommonData_secureRowLevels } from 'settings/account/userSetup/__generated__/UserSetupCommonData';

import { GlobalActions } from 'common/constants';
import {
  ListUserProfileNotes,
  ListUserProfileNotesVariables,
} from './__generated__/ListUserProfileNotes';
import { HistoryActionMenuView } from 'common/components/History';
import { ListInvoiceNotesVariables } from 'common/graphql/__generated__/ListInvoiceNotes';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';

const ENTITY_NOTES_LIST = loader('./ListUserProfileNotes.graphql');
interface ApprovalDetailsProps {
  userDetails: UserProfile | undefined;
  onUpload?: (
    fileSelected: File,
    documentData: UserSetupUploadDocument_userSetupUploadDocument_document,
    toastId: string
  ) => void;
  secureRowLevels: UserSetupCommonData_secureRowLevels | undefined | null;
}
export const ActionsMenu: React.FC<ApprovalDetailsProps> = ({
  userDetails,
  onUpload,
  secureRowLevels,
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    GlobalActions.attach
  );
  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListUserProfileNotes,
    ListUserProfileNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id: userDetails?.userProfile?.id!,
    },
  });
  const getEntityNotes = () => {
    const variables: ListInvoiceNotesVariables = {
      id: userDetails?.userProfile?.id!,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    userDetails?.userProfile?.id,
  ]);

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.attach:
        return (
          <AttachSection
            secureRowLevels={secureRowLevels!}
            userDetails={userDetails!}
            onUpload={onUpload}
          />
        );
      case GlobalActions.notes:
        return (
          <UserSetupNotesSection
            listRefreshRequest={getEntityNotesMemo}
            id={userDetails?.userProfile?.id!}
            data={entityNotesData?.userProfile?.notesByEntityId.nodes!}
          />
        );
      case GlobalActions.history:
        return (
          <HistoryActionMenuView
            moduleName="User setup"
            historyData={
              userDetails?.userProfile?.userProfileHistoriesByEntityId.nodes || []
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                <PivotItem
                  itemKey={GlobalActions.attach}
                  itemIcon="Attach"
                  headerText="Files"
                  itemCount={
                    userDetails?.userProfile?.entityDocumentsByEntityId.nodes
                      .length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={GlobalActions.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    userDetails?.userProfile?.notesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={GlobalActions.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={
                    userDetails?.userProfile?.userProfileHistoriesByEntityId
                      .nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
          </Stack>

          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack horizontalAlign="space-between" horizontal>
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Files"
                iconName="Attach"
                count={
                  userDetails?.userProfile?.entityDocumentsByEntityId.nodes
                    .length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.attach);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Notes"
                iconName="Page"
                count={
                  entityNotesData?.userProfile?.notesByEntityId.nodes.length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.notes);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                count={
                  userDetails?.userProfile?.userProfileHistoriesByEntityId.nodes
                    .length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.history);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
