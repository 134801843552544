import { StyleSheet } from '@react-pdf/renderer';

export const commonPdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
  },
  section: {
    padding: 5,
  },
});
