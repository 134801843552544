import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  makeStyles,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { GetEntityDocument } from '../folder/view/__generated__/GetEntityDocument';
import { DangerButton } from 'common/components/DangerButton';
import { loader } from 'graphql.macro';
import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client';
import {
  EntityDocumentDelete,
  EntityDocumentDeleteVariables,
} from 'common/graphql/__generated__/EntityDocumentDelete';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { DocumentPoolsOrderBy } from 'common/types/globalTypes';
import { setActivePoolDocument } from 'utility/cache/ui';
const DELETE_DOCUMENT = loader(
  '../../../common/graphql/EntityDocumentDelete.graphql'
);
const DOCUMENT_POOL_DATA = loader(
  '../../../common/graphql/DocumentPool.graphql'
);

interface DeleteDocumentProps {
  document?: GetEntityDocument;
  selectedPoolId?: string | undefined;
  _onConfirm?: () => void;
}
const useStyles = makeStyles((theme) => ({
  container: {
    height: 40,
  },
}));

export const DeleteDocument: React.FC<DeleteDocumentProps> = ({
  _onConfirm,
  selectedPoolId,
  document,
}) => {
  const activeDocument = useReactiveVar(setActivePoolDocument);
  const { cache } = useApolloClient();
  const { addToast } = useToasts();
  const styles = useStyles();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { _isDeletable, id, _rowTimestamp, _isSelected } = {
    ...document?.entityDocument,
  };
  const [deleteDocument] = useMutation<
    EntityDocumentDelete,
    EntityDocumentDeleteVariables
  >(DELETE_DOCUMENT);

  const onDelete = async () => {
    const { errors } = await deleteDocument({
      variables: {
        input: { entityDelete: [{ id: id!, rowTimestamp: _rowTimestamp! }] },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DOCUMENT_POOL_DATA,
          variables: {
            orderBy: [DocumentPoolsOrderBy.NAME_ASC],
            filter: {
              defaultDocumentTypes: {
                isReportingDocument: {
                  equalTo: false,
                },
              },
            },
          },
        },
      ],
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      history.replace('/doc/documentAssignment');
      const identify = cache.identify({
        ...document?.entityDocument,
      });
      cache.evict({ id: identify });
      cache.gc();
      if (selectedPoolId) {
        cache.modify({
          id: `DocumentPool:${selectedPoolId}`,
          fields: {
            _documentFileCount(existing) {
              const availableDocuments = _isSelected
                ? existing.availableDocuments
                : existing.availableDocuments - 1;
              return {
                ...existing,
                availableDocuments,
              };
            },
          },
        });
      }
      const activeDocCount =
        activeDocument?._documentFileCount?.availableDocuments ?? 0;

      setActivePoolDocument(
        activeDocument
          ? {
              ...activeDocument,
              _documentFileCount: {
                availableDocuments: activeDocCount - 1,
                selectedDocuments:
                  activeDocument?._documentFileCount?.selectedDocuments ?? 0,
              },
            }
          : undefined
      );
      addToast('Document deleted successfully', { appearance: 'success' });
    }
  };

  return (
    <Stack>
      <IconButton
        disabled={!_isDeletable}
        iconProps={{ iconName: 'Delete' }}
        onClick={() => setVisible(true)}
      />

      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={{
            title: `Delete Document`,
            type: DialogType.largeHeader,
            subText: 'Are you sure you want to delete this document?',
          }}
          minWidth={350}
        >
          <Stack className={styles.container}>
            <DialogFooter>
              <DangerButton
                onClick={() => {
                  onDelete();
                  setVisible(false);
                }}
                text={'Delete'}
              />
              <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
            </DialogFooter>
          </Stack>
        </Dialog>
      )}
    </Stack>
  );
};
