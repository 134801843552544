import { useMutation } from '@apollo/client';
import {
  Checkbox,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  DialogType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import { DialogWrapper } from 'common/components/DialogWrapper';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorizationSearch_travelAuthorizationSearch } from 'travelAuthorization/TravelPlan/list/__generated__/TravelAuthorizationSearch';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  TravelAuthorizationCopy,
  TravelAuthorizationCopyVariables,
} from './__generated__/TravelAuthorizationCopy';
import { TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals } from 'travelAuthorization/TravelPlan/list/__generated__/TravelAuthorizationSearchFilterTotals';
const TRAVEL_AUTHORIZATION_COPY = loader('./TravelAuthorizationCopy.graphql');

interface CopyTravelPlanDialogProps {
  hidden: boolean;
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  onDismiss: () => void;
}

export const CopyTravelPlanDialog: React.FC<CopyTravelPlanDialogProps> = ({
  hidden,
  travelAuthorizationData,
  onDismiss,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [departureDate, setDepartureDate] = useState<string | undefined>();
  const [returnDate, setReturnDate] = useState<string | undefined>();
  const [isAmountIncluded, setIsAmountIncluded] = useState<
    boolean | undefined
  >();

  const [travelAuthorizationCopy, { loading }] = useMutation<
    TravelAuthorizationCopy,
    TravelAuthorizationCopyVariables
  >(TRAVEL_AUTHORIZATION_COPY, { errorPolicy: 'all' });

  const { id } = { ...travelAuthorizationData };

  const onConfirm = async () => {
    if (id) {
      const { data, errors } = await travelAuthorizationCopy({
        variables: {
          input: {
            entityId: id,
            departureDate,
            returnDate,
            isAmountIncluded,
          },
        },
        update: (cache, { data }) => {
          try {
            cache.modify({
              fields: {
                travelAuthorizationSearchFilterTotals(
                  existing: TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    const controlTotalAmount =
                      Number(existing?.totalAmount1) +
                        Number(
                          data?.travelAuthorizationCopy?.newTravelAuthorization
                            ?.controlTotalAmount
                        ) ?? 0;
                    return {
                      ...existing,
                      totalAmount1: controlTotalAmount.toString(),
                      totalCount1: existing?.totalCount1! + 1,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
                travelAuthorizationSearch(
                  existing: TravelAuthorizationSearch_travelAuthorizationSearch
                ) {
                  return {
                    ...existing,
                    nodes: [
                      data?.travelAuthorizationCopy?.newTravelAuthorization!,
                      ...existing?.nodes!,
                    ],
                    pageInfo: existing?.pageInfo,
                  };
                },
              },
            });
          } catch (error) {}
        },
      });
      if (!!errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        history.replace(
          `/ta/travel-plan/travel/${data?.travelAuthorizationCopy?.newTravelAuthorization?.id}`
        );
        addToast('Travel plan copy added successfully.', {
          appearance: 'success',
        });
        onDismiss();
      }
    }
  };

  return (
    <>
      <DialogWrapper
        hidden={hidden}
        onDismiss={onDismiss}
        dialogContentProps={{
          title: 'Create Travel Authorization (Clone)',
          type: DialogType.largeHeader,
        }}
        minWidth={500}
      >
        <Stack
          tokens={{
            childrenGap: 20,
            padding: '10px 0px 10px 0px',
          }}
        >
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
            }}
          >
            <Stack style={{ flex: 1 }}>
              <DatePicker
                minDate={new Date()}
                firstDayOfWeek={DayOfWeek.Sunday}
                placeholder="Select Date "
                label="Departure Date"
                formatDate={onFormatDate}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay
                showGoToToday={false}
                onSelectDate={(date) =>
                  setDepartureDate(dateFormat(date!.toString()))
                }
              />
            </Stack>
            <Stack style={{ flex: 1 }}>
              <DatePicker
                minDate={new Date()}
                firstDayOfWeek={DayOfWeek.Sunday}
                placeholder="Select Date"
                label="Return Date"
                formatDate={onFormatDate}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay
                showGoToToday={false}
                onSelectDate={(date) =>
                  setReturnDate(dateFormat(date!.toString()))
                }
              />
            </Stack>
          </Stack>
          <Stack>
            <Checkbox
              label="Include amounts"
              onChange={(_, value) => {
                setIsAmountIncluded(value);
              }}
            />
          </Stack>
        </Stack>

        <Stack
          tokens={{
            padding: '30px 0px 0px 0px',
            childrenGap: 20,
          }}
        >
          {loading && <ProgressIndicator />}
          <Stack
            horizontal
            horizontalAlign="end"
            tokens={{
              childrenGap: 20,
            }}
          >
            <PrimaryButton text="Confirm" onClick={onConfirm} />
            <DefaultButton onClick={onDismiss} text="Cancel" />
          </Stack>
        </Stack>
      </DialogWrapper>
    </>
  );
};
