import { BatchValues } from './types';

export const BATCH_INITIAL_VALUES: BatchValues = {
  referenceNumber: null,
  description: null,
  currencyId: null,
  controlTotalAmount: null,
  corporatePeriodId: null,
  postingDate: null,
  statusType: null,
  _isApprovalDocumentsRequired: null,
  _requiredApprovalDocuments: null,
  weekNumber: null,
};
