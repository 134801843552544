import { useQuery } from '@apollo/client';
import {
  ContextualMenu,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsRowProps,
  IDetailsRowStyles,
  IGroup,
  SelectionMode,
  Separator,
  Stack,
} from '@fluentui/react';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { StatusIcon } from 'common/components/StatusIcon';
import { dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { ApprovalHistory_approvalHistory_iconType } from '../__generated__/ApprovalHistory';
import {
  ApprovalHistoryBranches,
  ApprovalHistoryBranches_approvalHistoryBranches_nodes,
  ApprovalHistoryBranchesVariables,
} from './__generated__/ApprovalHistoryBranches';
import { useColumns } from './column.data';
import { Header } from './Header';
import { ReplaceApproverModal } from './ReplaceRemoveApproverModal';
import ShimmerView from './ShimmerView';
const { columns } = useColumns();

export interface ReplaceRemoveApproverProps {
  entityApprovalId: string | undefined;
  approvalType: string | null | undefined;
  iconType: ApprovalHistory_approvalHistory_iconType | null | undefined;
  onDismiss: () => void;
}

const APPROVAL_HOSTORY_BRANCHES = loader('./ApprovalHistoryBranches.graphql');

export const ReplaceRemoveApprover: React.FC<ReplaceRemoveApproverProps> = ({
  entityApprovalId,
  approvalType,
  onDismiss,
  iconType,
}) => {
  const [replaceApprover, setReplaceApprover] = useState<boolean>(false);
  const [removeApprover, setRemoveApprover] = useState<boolean>(false);

  const [entityItem, setEntityItem] = useState<
    ApprovalHistoryBranches_approvalHistoryBranches_nodes | undefined
  >(undefined);
  const { data: approvalHistoryBranchesData, loading } = useQuery<
    ApprovalHistoryBranches,
    ApprovalHistoryBranchesVariables
  >(APPROVAL_HOSTORY_BRANCHES, {
    variables: {
      id: entityApprovalId!,
    },
    skip: !entityApprovalId,
  });

  // Define groups based on treeApprovalLevel
  const groups: IGroup[] = [];
  const groupedByLevel: Record<number, { startIndex: number; count: number }> =
    {};

  approvalHistoryBranchesData?.approvalHistoryBranches?.nodes.forEach(
    (item, index) => {
      if (item.treeApprovalLevel && !groupedByLevel[item.treeApprovalLevel]) {
        groupedByLevel[item.treeApprovalLevel] = {
          startIndex: index,
          count: 0,
        };
        groups.push({
          key: `group-${item.treeApprovalLevel}`,
          name: `${item.treeDescription}`,
          startIndex: index,
          count: 0,
          level: 0,
        });
      }
      if (item.treeApprovalLevel)
        groupedByLevel[item.treeApprovalLevel].count++;
    }
  );

  groups.forEach((group) => {
    const level = parseInt(group.key.split('-')[1], 10);
    group.count = groupedByLevel[level].count;
  });

  const onRenderRow = (props?: IDetailsRowProps): JSX.Element | null => {
    const customRowStyles: Partial<IDetailsRowStyles> = {
      root: {
        height: 50,
        fontSize: 14,
      },
    };
    return props ? <DetailsRow {...props} styles={customRowStyles} /> : null;
  };
  const renderColumn = (
    item?: ApprovalHistoryBranches_approvalHistoryBranches_nodes,
    _index?: number,
    column?: IColumn
  ) => {
    if (!item || !column) return undefined;
    const fieldContent = item[
      column.fieldName as keyof ApprovalHistoryBranches_approvalHistoryBranches_nodes
    ] as string | null;
    switch (column.key) {
      case 'statusType':
        if (iconType)
          return (
            <Stack>
              <StatusIcon approval iconType={iconType} />
            </Stack>
          );
        break;
      case 'actionDate':
        return (
          <Stack verticalAlign="center">
            {fieldContent ? dateFormat(fieldContent) : null}
          </Stack>
        );
      case 'action':
        return (
          <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center">
            <DefaultButton
              iconProps={{ iconName: 'FollowUser' }}
              primary
              disabled={!item._isBranchReplaceAllowed}
              text="Replace"
              onClick={() => {
                setEntityItem(item);
                setReplaceApprover(true);
              }}
            />
            <DefaultButton
              iconProps={{ iconName: 'UserRemove' }}
              text="Remove"
              primary
              disabled={!item._isBranchBypassAllowed}
              onClick={() => {
                setEntityItem(item);
                setRemoveApprover(true);
              }}
            />
          </Stack>
        );

      default:
        return <Stack verticalAlign="center">{fieldContent}</Stack>;
    }
  };
  return (
    <ModalWrapper
      isOpen
      isBlocking
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
    >
      <Header
        title="Replace /Remove Approver - "
        subTitle={`${approvalType}`}
        onDismiss={onDismiss}
      />
      <Separator />
      <Stack style={{ paddingBottom: 40 }}>
        {loading ? (
          <ShimmerView />
        ) : (
          <DetailsList
            selectionMode={SelectionMode.none}
            items={
              approvalHistoryBranchesData?.approvalHistoryBranches?.nodes ?? []
            }
            groups={groups}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            groupProps={{
              showEmptyGroups: false,
              isAllGroupsCollapsed: false,
            }}
            onRenderRow={onRenderRow}
            onRenderItemColumn={renderColumn}
          />
        )}
      </Stack>
      {(replaceApprover || removeApprover) && (
        <ReplaceApproverModal
          onDismiss={() => {
            setReplaceApprover(false);
            setRemoveApprover(false);
          }}
          entityApprovalId={entityApprovalId}
          entityItem={entityItem}
          removeApprover={removeApprover}
        />
      )}
    </ModalWrapper>
  );
};
