import React, { FC } from 'react';
import { useField } from 'formik';
import { RequiredNameProps } from 'common/types/utility';
import { Checkbox, ICheckboxProps } from '@fluentui/react';
import { ErrorText } from '../ErrorText';

export const FormikCheckBox: FC<RequiredNameProps<ICheckboxProps>> = ({
  name,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField<boolean | undefined>(name);

  return (
    <>
      <Checkbox
        {...props}
        checked={field.value}
        onChange={(event, checked) => {
          helpers.setValue(checked);
          onChange?.(event, checked);
        }}
      />
      {meta.touched && meta.error && <ErrorText message={meta.error} />}
    </>
  );
};
