import React, { FC } from 'react';
import { useField } from 'formik';
import { RequiredNameProps } from 'common/types/utility';
import { TagSelection_tags_nodes } from 'common/graphql/__generated__/TagSelection';
import { Tags } from './Tags';
import { ErrorText } from 'common/components/ErrorText';

export interface TagSelectOption {
  tagId: string;
}

interface FormikMultiSelectTagsProps {
  tagsData: TagSelection_tags_nodes[];
}

export const FormikMultiSelectTags: FC<
  RequiredNameProps<FormikMultiSelectTagsProps>
> = ({ name, tagsData }) => {
  const [field, meta, helpers] = useField<TagSelectOption[]>(name);

  return (
    <>
      <Tags
        options={tagsData}
        selectedTags={field.value}
        onSelectionChange={(data) => data.length && helpers.setValue(data)}
      />
      {meta.touched && meta.error && <ErrorText message={meta.error} />}
    </>
  );
};
