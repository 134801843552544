import { useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { HistoryActionMenuView } from 'common/components/History';
import { PivotCustomRender } from 'common/components/PivotComponent';
import {
  PivotDisplay,
  PivotMenuItemProps,
} from 'common/components/PivotDisplay';
import { GlobalActions } from 'common/constants';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { SectionProps } from '../..';
import { Trip_trip } from '../../__generated__/Trip';
import { SectionToggle } from '../SectionToggle';
import { Attach } from './Attach';
import { TripUploadDocument_tripUploadDocument } from './Attach/UploadForm/__generated__/TripUploadDocument';
import {
  getUpdatedPivotActionItems,
  pivotActionItems,
  TripModalCount,
} from './constants';
import { useStyles } from './index.styles';
import { Notes } from './Notes';
import {
  ListTripNotes,
  ListTripNotesVariables,
} from './__generated__/ListTripNotes';
import { CarbonAccounting } from './CarbonAccounting';
const LIST_TRIP_NOTES = loader('./ListTripNotes.graphql');

interface ActionMenuProps {
  onRemove?: () => void;
  onRefetch?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: TripUploadDocument_tripUploadDocument,
    toastId: string
  ) => void;
  travelAuthorizationData:
  | Partial<TravelAuthorization_travelAuthorization>
  | null
  | undefined;
  tripItemsState: Map<string, SectionProps>;
  onTripItemsUpdate: (
    mapData: Map<string, SectionProps>,
    layoutAdded: string,
    adding: boolean
  ) => void;
  commonData: TravelerAuthorizationCommonData | undefined;
  tripData: Trip_trip | null | undefined;
  inputsDisabled: boolean;
}

export const ActionMenu: React.FC<ActionMenuProps> = ({
  onRemove,
  onUpload,
  travelAuthorizationData,
  tripItemsState,
  onTripItemsUpdate,
  commonData,
  tripData,
  inputsDisabled,
  onRefetch,
}) => {
  const styles = useStyles();
  const [expandMenu, setExpandMenu] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    GlobalActions.attach
  );

  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListTripNotes,
    ListTripNotesVariables
  >(LIST_TRIP_NOTES, {
    variables: {
      id: tripData?.id!,
    },
    skip: !tripData?.id,
  });

  const getEntityNotes = () => {
    const variables: ListTripNotesVariables = {
      id: tripData?.id!,
    };
    entityNotesListRefetch(variables);
  };

  const getEntityNotesMemo = useCallback(getEntityNotes, [tripData?.id!]);

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.notes:
        return (
          <Stack>
            <Notes
              listRefreshRequest={getEntityNotesMemo}
              id={tripData?.id!}
              data={entityNotesData?.trip?.notesByEntityId.nodes!}
            />
          </Stack>
        );
      case GlobalActions.history:
        return (
          <Stack>
            <HistoryActionMenuView
              moduleName="Trip"
              historyData={tripData?.tripHistoriesByEntityId?.nodes || []}
            />
          </Stack>
        );
      case GlobalActions.attach:
        return (
          <Stack>
            {tripData && (
              <Attach
                tripData={tripData}
                onUpload={onUpload}
                onRemove={onRemove}
              />
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  let updatedPivotActionItems: PivotMenuItemProps[] = [];
  if (tripData) {
    const {
      tripHistoriesByEntityId,
      notesByEntityId,
      entityDocumentsByEntityId,
    } = tripData;

    const supplierTransactionCountArray: TripModalCount[] = [
      {
        name: 'Files',
        count: entityDocumentsByEntityId?.nodes?.length,
      },
      {
        name: 'Notes',
        count: notesByEntityId?.nodes?.length,
      },
      {
        name: 'History',
        count: tripHistoriesByEntityId?.nodes?.length,
      },
    ];
    updatedPivotActionItems = getUpdatedPivotActionItems(
      supplierTransactionCountArray
    );
  } else updatedPivotActionItems = pivotActionItems;

  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.pivotContainer}
            >
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                {updatedPivotActionItems.map((item, index) => {
                  return (
                    <PivotItem
                      key={index}
                      itemKey={item.itemKey}
                      itemIcon={item.iconName}
                      headerText={item.name}
                      itemCount={item.count}
                      onRenderItemLink={PivotCustomRender}
                    />
                  );
                })}
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
              <Stack tokens={{ padding: "0px 20px" }}>
                <CarbonAccounting
                  trip={tripData}
                  refetch={() => onRefetch?.()}
                />
              </Stack>
            </Stack>
            <Stack>
              <SectionToggle
                inputsDisabled={inputsDisabled}
                travelAuthorizationData={travelAuthorizationData}
                tripItemsState={tripItemsState}
                onTripItemsUpdate={onTripItemsUpdate}
                commonData={commonData}
                tripData={tripData}
              />
            </Stack>
          </Stack>
          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack
          horizontalAlign="space-between"
          horizontal
          className={styles.pivotContainer}
        >
          <Stack horizontal verticalAlign="center">
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              {updatedPivotActionItems.map((item, index) => {
                return (
                  <PivotDisplay
                    key={index}
                    {...item}
                    onPivotClick={() => {
                      setSelectedKey(item.itemKey);
                      setExpandMenu(true);
                    }}
                  />
                );
              })}
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
            <Stack tokens={{ padding: "0px 20px" }}>
              <CarbonAccounting
                trip={tripData}
                refetch={() => onRefetch?.()}
              />
            </Stack>
          </Stack>
          <Stack>
            <SectionToggle
              inputsDisabled={inputsDisabled}
              travelAuthorizationData={travelAuthorizationData}
              tripItemsState={tripItemsState}
              onTripItemsUpdate={onTripItemsUpdate}
              commonData={commonData}
              tripData={tripData}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
