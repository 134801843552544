
import { StyleSheet } from '@react-pdf/renderer';

export const getTableStyle = StyleSheet.create({
  headerStyle: {
    fontSize: 8,
    fontWeight: 'bold',
    color: 'black',
    
  },
  tableValueStyle: {
    fontSize: 8,    
    color: '#605e5c',    
  },
  rightAlignheaderStyle: {
    fontSize: 8,
    fontWeight: 'bold',
    color:'black',
    textAlign:'right'
  },

  rightAlignTablevalueStyle: {
    fontSize: 8,    
    color: '#605e5c',  
    textAlign:'right'
  },
  
  rightAlignSingleFlex: {
    flex: 1, 
    textAlign:'right'
  },
 
  
  
  
  tableHeaderWrapper: {
    flexDirection: 'row',
    marginTop: 10,
    height: 40,
    marginHorizontal:5,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 0.2,
  },

  tableBottomHeaderWrapper: {
    flexDirection: 'row',
    height: 40,
    marginHorizontal:5,
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  
  
  tableRowWrapper: {
    flexDirection: 'row',
    marginHorizontal:5,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallFlex: {
    flex: 0.5,
  },
  singleFlex: {
    flex: 1,    
  },
  mediumFlex: {
    flex: 1.5,
  },
  largeFlex: {
    flex: 2,
  },
});
