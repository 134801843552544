import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    workgroupNumber: Yup.string().required('Required').nullable(),
    name: Yup.string().required('Required').nullable(),
    businessUnitId: Yup.string().required('Required').nullable(),
    chartOfAccountsId: Yup.string().required('Required').nullable(),
    productionStatusTypeId: Yup.number().required('Required').nullable(),
    prefixCode: Yup.string().required('Required').nullable(),
  });
