import { IColumn } from '@fluentui/react';

const commonProps: Partial<IColumn> = {
  isResizable: true,
  isSorted: false,
};

export const columns: IColumn[] = [
  {
    key: 'description',
    name: 'Airport/Depot',
    fieldName: 'description',
    minWidth: 250,
    ...commonProps,
  },
  {
    key: 'stateRegion',
    name: 'State/Region',
    fieldName: 'stateRegion',
    minWidth: 175,
    ...commonProps,
  },
  {
    key: 'cityArea',
    name: 'City/Town',
    fieldName: 'cityArea',
    minWidth: 175,
    ...commonProps,
  },
];
