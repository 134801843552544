import React from 'react';
import {
  CountryRegionSelector,
  CountryRegionSelectorProps,
} from '../CountryRegionFields';
import { useController } from 'react-hook-form';

interface CountryRegionFormFieldProps {
  countryName: string;
  regionName: string;
}

export const FormHookCountryRegion: React.FC<
  CountryRegionFormFieldProps & CountryRegionSelectorProps
> = ({
  countryName,
  regionName,
  countries,
  CountryAutocompleteProps,
  StateRegionAutocompleteProps,
  ...props
}) => {
  const {
    field: { value: countryValue, onChange: onCountryChange },
    fieldState: { error: countryError },
  } = useController({ name: countryName });

  const {
    field: { value: regionValue, onChange: onRegionChange },
    fieldState: { error: regionError },
  } = useController({ name: regionName });

  return (
    <CountryRegionSelector
      countries={countries}
      CountryAutocompleteProps={{
        value: countryValue || null,
        onChange: (_event, option) => {
          if (option?.key && option.key !== -1) {
            let numberKey = parseInt(option?.key.toString());
            onCountryChange(numberKey);
          } else {
            onCountryChange(null);
            onRegionChange(null);
          }
          // clear region value if no country is selected
          // if (!option) onRegionChange(undefined);
        },
        error: !props.disabled && countryError !== undefined,
        helperText: !props.disabled && countryError,
        errorMessage: countryError?.message || '',
        ...CountryAutocompleteProps,
      }}
      StateRegionAutocompleteProps={{
        value: regionValue || null,
        onChange: (_event, value) => {
          if (value?.key) {
            let numberKey = parseInt(value?.key.toString());
            onRegionChange(numberKey);
          } else onRegionChange(null);
        },
        error: !props.disabled && regionError?.message !== undefined,
        helperText: !props.disabled && regionError?.message,
        errorMessage: regionError?.message || '',

        ...StateRegionAutocompleteProps,
      }}
      {...props}
    />
  );
};
