import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    transactionTypeId: Yup.string().required('Required').nullable(),
    businessUnitId: Yup.string().required('Required').nullable(),
    controlTotalAmount: Yup.number().required('Required').nullable(),
    currencyId: Yup.string().required('Required').nullable(),
    description: Yup.string().required('Required').nullable(),
    cardHolderId: Yup.string().when('layoutType', {
      is: 'PAY_CARD',
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    vendorReference: Yup.string().when('layoutType', {
      is: 'PAY_CARD',
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    invoiceDistributions: Yup.array().of(
      Yup.object().shape({
        statusExplanation: Yup.string().when('statusTypeId', {
          is: 602,
          then: Yup.string().required('Required').nullable(),
          otherwise: Yup.string().nullable(),
        }),
      })
    ),
  });
