import { DayOfWeek, IDropdownOption, Stack } from '@fluentui/react';
import { FormikDropdown, AmountTextField, FormikTextField } from 'common/components';
import { AutoCompleteFormikTextField } from 'common/components/FormikFields/AutoCompleteFormikTextField';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import { onFormatDate } from 'common/utils/dateFormats';
import { EntityDocumentValues } from 'documents/personalPool/types';
import { EntityDocumentsPersonalPool_entityDocuments_nodes } from 'documents/personalPool/__generated__/EntityDocumentsPersonalPool';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { DocumentTypeOptions } from '..';
import { useStyles } from "./index.styles";
interface FormViewProps {
  isEdit: boolean;
  isAccountingDocument: boolean;
  isDocumentProtected: boolean;
  showIsAccountingDocumentInputs: boolean;
  documentData?: EntityDocumentsPersonalPool_entityDocuments_nodes | null;
  documentType?: DocumentTypeOptions;
  companyCurrencies?: IDropdownOption[];
  onInputFieldsDisabled?: (data: boolean) => void;
  dataExtractModelOptions?: IDropdownOption[];
  hideExtractionDropdown: boolean;
}
export const FormView: React.FC<FormViewProps> = ({
  isEdit,
  isAccountingDocument,
  isDocumentProtected,
  showIsAccountingDocumentInputs,
  documentData,
  documentType,
  companyCurrencies,
  onInputFieldsDisabled,
  dataExtractModelOptions,
  hideExtractionDropdown
}) => {
  const styles = useStyles();
  const { values, setFieldValue } = useFormikContext<EntityDocumentValues>();
  const [isDisabled, setIsDisabled] = useState(false)
  const [showDataExtractDropdown, setShowDataExtractDropdown] = useState(false);

  useEffect(() => {
    if (isEdit) {
      if (documentData?.extractionType !== null) {
        if (isDocumentProtected) {
          setIsDisabled(true);
          onInputFieldsDisabled?.(true);
        } else {
          setIsDisabled(documentData?.extractionType.isExtractProtected!);
          onInputFieldsDisabled?.(documentData?.extractionType.isExtractProtected!);
        }
      } else {
        setIsDisabled(isDocumentProtected);
        onInputFieldsDisabled?.(isDocumentProtected);
      }
    } else {
      if (values.extractionTypeId) {
        setIsDisabled(true);
        onInputFieldsDisabled?.(true);
      } else {
        setIsDisabled(false);
        onInputFieldsDisabled?.(false);
      }
    }
  }, [isEdit, values.extractionTypeId, documentData, isDocumentProtected, onInputFieldsDisabled]);

  const disableReferenceNumber = isDisabled ? true : (documentData && documentData?._isUpdatable) ? false : false;

  useEffect(() => {
    if (isEdit && (hideExtractionDropdown || values.extractionTypeId === null)) {
      setShowDataExtractDropdown(false)
    } else if (dataExtractModelOptions && dataExtractModelOptions?.length > 0) {
      setShowDataExtractDropdown(true)
    } else {
      setShowDataExtractDropdown(false)
    }
  }, [dataExtractModelOptions, isEdit, hideExtractionDropdown, values.extractionTypeId]);

  return (
    <>
      {!isAccountingDocument && (
        <FormikTextField
          disabled={isDocumentProtected}
          name="_documentContents"
          label="Contents"
          placeholder="Enter contents"
        />
      )}
      {
        showDataExtractDropdown &&
        <Stack grow>
          <FormikDropdown
            label="Data Extraction Template"
            placeholder="Select"
            name="extractionTypeId"
            options={dataExtractModelOptions || []}
            disabled={isEdit}
          />
        </Stack>
      }
      {isAccountingDocument && (
        <Stack tokens={{ childrenGap: 20 }}>
          <AutoCompleteFormikTextField
            name="indexName"
            label="Name"
            placeholder="Enter name"
            disabled={isDisabled || isDocumentProtected}
            lookupData={documentData?.lookupName}
            documentTypeId={values.entityDocumentTypeId!}
            id={'indexName'}
            lookUpCalloutWidth={550}
          />
          {showIsAccountingDocumentInputs && (
            <>
              <FormikTextField
                disabled={isDisabled || isDocumentProtected}
                name="indexDescription"
                label="Description"
                placeholder="Enter description"
              />
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item className={styles.flex50}>
                  <FormikTextField
                    disabled={disableReferenceNumber}
                    name="indexReferenceNumber"
                    label="Reference Number"
                    placeholder={
                      documentType?.text
                        ? `Enter ${documentType.text.toLowerCase()} number`
                        : 'Enter reference number'
                    }
                  />
                </Stack.Item>
                <Stack.Item className={styles.flex50}>
                  <FormikDatePicker
                    disabled={isDisabled || isDocumentProtected}
                    name="indexTransactionDate"
                    onSelectDate={(date) => {
                      if (date)
                        setFieldValue(
                          'indexTransactionDate',
                          onFormatDate(date)
                        );
                    }}
                    placeholder="Select date"
                    ariaLabel="Document Date"
                    label="Date"
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                  />
                </Stack.Item>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item className={styles.flex50}>
                  <AmountTextField
                    name="indexAmount"
                    label="Amount"
                    placeholder="Amount"
                    disabled={isDisabled || isDocumentProtected}
                    fixedDecimalScale
                    decimalScale={2}
                    allowNegative
                  />
                </Stack.Item>
                <Stack.Item className={styles.flex50}>
                  <FormikDropdown
                    label="Currency"
                    placeholder="Select"
                    name="indexCurrencyId"
                    options={companyCurrencies || []}
                    disabled={isDisabled || isDocumentProtected}
                  />
                </Stack.Item>
              </Stack>
            </>
          )}
        </Stack>
      )}
      {showIsAccountingDocumentInputs && (
        <FormikTextField
          disabled={isDisabled || isDocumentProtected}
          name="comment"
          label="Comment"
          placeholder="Enter comment"
        />
      )}
    </>
  )
}
