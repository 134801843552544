export const generateApprovalName = (param: string) => {
    if(!param?.includes("(Copy)"))
      return `${param + " (Copy)"}`;
      //Split approvalName String and copy count Integer
    const splitApprovalName = param.split(/(\d+)/);
      const approvalNameString = splitApprovalName[0];
      const copyCount = splitApprovalName[1];
      if(copyCount)
      return `${approvalNameString + (parseInt(copyCount) + 1)}`;
      else 
      return `${approvalNameString} 2`;
  }