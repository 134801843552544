import {
  DirectionalHint,
  Icon,
  IconButton,
  IIconStyles,
  Label,
  Stack,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { PurchaseOrder_purchaseOrder_supplier } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { SupplierModal } from 'purchaseOrder/view/FormView/SupplierModal';
import React, { useState } from 'react';
import { SupplierCreate_supplierCreate_supplier } from 'settings/account/supplier/view/__generated__/SupplierCreate';
import { LookupSupplierSearch_lookupSupplierSearch_nodes } from 'common/graphql/__generated__/LookupSupplierSearch';
import { AddressDetailsCallout } from 'common/components/SupplierLookUpCallout/DetailsCallout/AddressDetailsCallout';

interface TextFieldLabelProps {
  data?: LookupSupplierSearch_lookupSupplierSearch_nodes | null;
  disabled: boolean | undefined;
  showAddIcon?: boolean;
  label: string | undefined;
  required?: boolean | undefined;
  supplier: PurchaseOrder_purchaseOrder_supplier | null;
  purchaseOrderId: string | undefined;
  onSupplierCreate: (param: SupplierCreate_supplierCreate_supplier) => void;
  onSupplierDelete: () => void;
}

export const TextFieldLabel: React.FC<TextFieldLabelProps> = ({
  disabled,
  showAddIcon,
  label,
  data,
  required = false,
  supplier,
  purchaseOrderId,
  onSupplierCreate,
  onSupplierDelete,
}) => {
  const theme = useTheme();
  const iconButtonId: string = useId('iconButton');
  const calloutId = useId(`callOutId${data?.id}`);
  const iconStyles: Partial<IIconStyles> = {
    root: {
      color: theme.palette.themePrimary,
      margin: '6px 0px 0px 6px',
    },
  };
  const [supplierDialogVisible, setSupplierDialogVisible] =
    useState<boolean>(false);
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [isNew, setIsNew] = useState<boolean>(false);

  return (
    <Stack horizontal verticalAlign="baseline">
      {supplierDialogVisible && (
        <SupplierModal
          setOpen={setSupplierDialogVisible}
          onSupplierCreate={onSupplierCreate}
          selectedSupplierId={supplier?.id}
          isNew={isNew}
          onSupplierDelete={onSupplierDelete}
          purchaseOrderId={purchaseOrderId}
        />
      )}
      <Label
        disabled={disabled ? true : false}
        id={calloutId}
        required={required}
        onMouseEnter={() => setCalloutVisible(true)}
        onMouseLeave={() => setCalloutVisible(false)}
      >
        {label}
      </Label>
      {calloutVisible && supplier && (
        <AddressDetailsCallout
          onDismiss={() => setCalloutVisible(false)}
          data={supplier!}
          detailsTargetId={calloutId}
          directionalHint={DirectionalHint.leftCenter}
        />
      )}
      {supplier?._isApproved && (
        <TooltipHost content={'Verified'}>
          <Icon iconName="CheckMark" styles={iconStyles} />
        </TooltipHost>
      )}
      {!supplier?.id && !disabled && showAddIcon && (
        <TooltipHost content={'Add Supplier'}>
          <IconButton
            id={iconButtonId}
            iconProps={{ iconName: 'AddTo' }}
            styles={iconStyles}
            onClick={() => {
              setIsNew(true);
              setSupplierDialogVisible(true);
            }}
          />
        </TooltipHost>
      )}
      {!disabled && supplier?.id && (
        <TooltipHost content={'Edit Supplier'}>
          <IconButton
            id={iconButtonId}
            iconProps={{ iconName: 'Edit' }}
            styles={iconStyles}
            onClick={() => {
              setIsNew(false);
              setSupplierDialogVisible(true);
            }}
          />
        </TooltipHost>
      )}
    </Stack>
  );
};
