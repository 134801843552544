import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
// import { AttachFormModal } from './AttachFormModal';
import { PayCycleAvailableDocumentTypes } from 'ap/paymentCycle/__generated__/PayCycleAvailableDocumentTypes';
import { AttachSectionProps } from '..';
import { AttachFormModal } from './AttachFormModal';

export type AttachFormProps = Pick<AttachSectionProps, "paymentCycle"> & {
  availableDocumentTypes: PayCycleAvailableDocumentTypes | undefined;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  paymentCycle,
  availableDocumentTypes
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          paymentCycle={paymentCycle}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  )
}
