import { makeVar, useReactiveVar } from '@apollo/client';
import {
  IButtonProps,
  IconButton,
  Stack,
  Text,
  useTheme,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import {
  TravelAuthorization_travelAuthorization,
  TravelAuthorization_travelAuthorization_trips_nodes,
} from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TripsModal } from './TripModal';
import { isTACreated } from 'travelAuthorization/TravelPlan/view';
import { useWatch } from 'react-hook-form';
import { TravelAuthorizationValues } from '../../interface';

export const setTripsModalState = makeVar<{
  trip:
    | Partial<TravelAuthorization_travelAuthorization_trips_nodes>
    | undefined;
  isModalOpen: boolean;
  isNew: boolean;
}>({
  trip: undefined,
  isNew: true,
  isModalOpen: false,
});
interface TripsProps {
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  inputsDisabled: boolean;
  isDirty: boolean;
}
export const Trips: React.FC<TripsProps> = ({ ...props }) => {
  const theme = useTheme();
  const watch = useWatch<TravelAuthorizationValues>();

  const tripsModalState = useReactiveVar(setTripsModalState);
  const isOpen = useReactiveVar(isTACreated);
  const iconButtonProps: IButtonProps = {
    text: 'Add Trips',
    iconProps: {
      iconName: 'Add',
    },
    styles: {
      root: {
        border: `1px solid ${theme.palette.themePrimary}`,
      },
    },
  };

  useEffect(() => {
    if (isOpen) {
      setTripsModalState({
        trip: undefined,
        isModalOpen: true,
        isNew: true,
      });
    }
  }, [isOpen]);
  return (
    <Stack>
      <Stack horizontal tokens={{ childrenGap: 10, padding: '0px 20px' }}>
        <Text variant="xLarge">Trips</Text>
        <IconButton
          {...iconButtonProps}
          disabled={props.inputsDisabled || props.isDirty}
          onClick={() => {
            setTripsModalState({
              ...tripsModalState,
              isModalOpen: true,
              isNew: true,
            });
          }}
        />
      </Stack>
      {tripsModalState.isModalOpen && (
        <TripsModal
          {...props}
          businessUnitId={watch.businessUnitId || null}
          isOpen={tripsModalState.isModalOpen}
        />
      )}
    </Stack>
  );
};
