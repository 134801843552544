import {
  DefaultButton,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OccupationValues } from '../types';
import { useStyles } from './index.styles';

interface FooterProps {
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  loading,
  onSubmit,
  onCancel,
}) => {
  const styles = useStyles();
  const {
    formState: { isDirty, errors, isSubmitting },
  } = useFormContext<OccupationValues>();
  const isDisabled = !isDirty || Object.keys(errors).length > 0 || isSubmitting;

  return (
    <Stack className={styles.container}>
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack className={styles.progressContainer}>
          {loading && <ProgressIndicator />}
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
          }}
        >
          <PrimaryButton text="Save" onClick={onSubmit} disabled={isDisabled} />
          <DefaultButton text="Cancel" onClick={onCancel} />
        </Stack>
      </Stack>
    </Stack>
  );
};
