import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  disabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  marginT10: {
    marginTop: 10,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
  tagsContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  bottomMargin: {
    marginBottom: 100,
  },
  modalContainer: {
    width: 1200,
    height: 900,
  },
  fixedHeightContainer: {
    height: 800,
    overflowY: 'auto',
  },
  headerContainer: {
    padding: '25px 25px 0px 25px',
  },
}));
