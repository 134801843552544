import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { MessageTravelAuthorizationCreate, MessageTravelAuthorizationCreateVariables } from './__generated__/MessageTravelAuthorizationCreate';
import { PivotKeys } from 'common/components/MessagesSections';
import { CreateMessageData, CreateNewMessage } from 'common/components/CreateNewMessage';
import { Stack } from '@fluentui/react';
const MESSAGE_TRAVEL_AUTHORIZATION_CREATE = loader("./MessageTravelAuthorizationCreate.graphql")
const TRAVEL_AUTHORIZATION = loader("../../../../TravelAuthorization.graphql")
interface NewMessageProps {
  travelAuthorizationId: string;
  selectedKey: string;
  onCreateMessage: () => void;
  dirty?: boolean;
}

export const NewMessage: React.FC<NewMessageProps> = ({
  travelAuthorizationId,
  selectedKey,
  onCreateMessage,
  dirty,
}) => {

  const { addToast } = useToasts();
  const [createMessage, { loading }] = useMutation<
    MessageTravelAuthorizationCreate,
    MessageTravelAuthorizationCreateVariables
  >(MESSAGE_TRAVEL_AUTHORIZATION_CREATE);

  const messageFilter =
    selectedKey === PivotKeys.sent
      ? { _isOutgoingMessage: { equalTo: true } }
      : { _isIncomingMessage: { equalTo: true } };

  const onSubmit = async (formData: CreateMessageData) => {
    const { messageComment, reminderDates, isResponseRequired, receivers } = {
      ...formData,
    };
    const { communicationChannel, department, user } = { ...receivers };
    const { errors } = await createMessage({
      variables: {
        input: {
          entityId: travelAuthorizationId,
          message: { messageComment, reminderDates, isResponseRequired },
          communicationChannel,
          department,
          user,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: TRAVEL_AUTHORIZATION,
          variables: {
            id: travelAuthorizationId,
            first: 10,
            filter: messageFilter,
          },
        },
      ],
    });

    if (!errors) {
      onCreateMessage();
      addToast('Message sent successfully.', {
        appearance: 'success',
      });
    } else
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
  };



  return (
    <Stack tokens={{ padding: "0px 10px 0px 0px" }}>
      <CreateNewMessage
        onSubmit={onSubmit}
        loading={loading}
        dirty={dirty}
      />
    </Stack>
  )
}
