import { PayCycleValues } from './types';

export const PAYCYCLE_INITIAL_VALUES: PayCycleValues = {
  transactionTypeId: null,
  paymentAccountId: null,
  description: null,
  defaultPaymentDate: null,
  corporatePeriodId: null,
  controlTotalAmount: null,
};
