import { Persona, PersonaSize, Separator, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { NotificationDateTime } from 'common/components/DateTime';
import { GroupedPersona } from 'common/components/GroupedPersona';
import { EntityUserMessageFields_userMessageDetailsByThreadId_nodes } from 'common/graphql/__generated__/EntityUserMessageFields';
import React from 'react';
import { PivotKeys } from '../..';
import { useStyles } from './index.styles';
import { MultipleRecipients } from './MultipleRecipients';

interface MessageRowDetailsProps {
  user: string;
  date: string;
  message: string;
  isMessageResponse?: boolean;
  listType?: PivotKeys;
  multipleRecipientsData: EntityUserMessageFields_userMessageDetailsByThreadId_nodes[];
  refetchList: (listName?: string) => void;
}

export const MessageRowDetails: React.FC<MessageRowDetailsProps> = ({
  user,
  date,
  message,
  isMessageResponse,
  multipleRecipientsData,
  listType,
  refetchList
}) => {
  const styles = useStyles();

  return (
    <Stack
      horizontal
      verticalAlign="center"
      className={clsx(isMessageResponse && styles.messageResponseContainer)}
    >
      {isMessageResponse && (
        <Stack className={styles.messageResponseSeparatorContainer}>
          <Separator />
        </Stack>
      )}
      <Stack
        horizontal
        className={clsx(
          isMessageResponse
            ? styles.messageResponsePersonaContainer
            : styles.messageRowPersonaContainer
        )}
      >
        {multipleRecipientsData.length && listType === PivotKeys.sent ? (
          <GroupedPersona isMultiplePeople name={user} />
        ) : (
          <Persona text={user} size={PersonaSize.size72} coinSize={42} />
        )}
      </Stack>
      <Stack
        horizontal
        className={clsx(
          isMessageResponse
            ? styles.messageResponseMessageMainContainer
            : styles.messageRowMessageMainContainer
        )}
      >
        <Stack
          className={clsx(
            isMessageResponse
              ? styles.messageResponseMessageContainer
              : styles.messageRowMessageContainer
          )}
        >
          {multipleRecipientsData.length && listType === PivotKeys.sent ? (
            <MultipleRecipients
              refetchList={refetchList}
              data={multipleRecipientsData}
            />
          ) : (
            <Text className={styles.sendingUser}>{user}</Text>
          )}
          <NotificationDateTime dateTime={date} />
          <Text className={styles.messageTitle}>{message}</Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
