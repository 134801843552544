import { HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { currentUserRole } from 'utility/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { config } from 'config';

export const getHttpLink = () =>
  new HttpLink({
    uri: `${config!.tls ? 'https' : 'http'}://${config!.apiDomain}/graphql`,
  });

export const getWebSocketLink = (
  getAccessTokenSilently: GetAccessTokenSilently
) =>
  new WebSocketLink({
    uri: `${config!.tls ? 'wss' : 'ws'}://${config!.apiDomain}/graphql`,
    options: {
      lazy: true,
      reconnect: true,
      minTimeout: 10000,
      connectionParams: async () => {
        const token = await getAccessTokenSilently();
        const authHeaders: any = {
          authorization: token ? `Bearer ${token}` : '',
        };

        const role = currentUserRole();
        if (role) {
          authHeaders.tenant_user_profile_id = role.id;
        }

        return authHeaders;
      },
    },
  });

type GetAccessTokenSilently = (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;
export const getAuthLink = (getAccessTokenSilently: GetAccessTokenSilently) =>
  setContext(async (_, { headers }: { headers: any }) => {
    const token = await getAccessTokenSilently();
    const authHeaders = {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    };

    const role = currentUserRole();
    if (role) {
      authHeaders.tenant_user_profile_id = role.id;
    }

    return {
      headers: authHeaders,
    };
  });

export const consoleErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
