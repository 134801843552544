import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles(() => ({
  container: {
    width: 1300
  },
  headerContainer: {
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.10)`,
    marginBottom: 10,
  },
  modalContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  noScrollContainer: {
    overflow: 'hidden',
  },
}));
