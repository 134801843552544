import { ActionButton, Stack, Text, makeStyles } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { DocumentPackageEmailModalProps } from '../..';
import { IconState } from 'common/types/globalTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    cursor: 'move',
  },
}));

type HeaderProps = Pick<DocumentPackageEmailModalProps, "emailDocument"> & {
  onClose: () => void;
  onCreate: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  emailDocument,
  onClose,
  onCreate,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const { _sendIconState, _isEmailCreateAllowed } = { ...emailDocument }
  const isDisabled = !_isEmailCreateAllowed && _sendIconState === IconState.PENDING

  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20
      }}
    >
      <Stack>
        <Text variant='xLarge' className={commonStyles.colorThemePrimary}>
          Email Recipients
        </Text>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <ActionButton
          onClick={onCreate}
          iconProps={{ iconName: 'Read' }}
          text="Create New Email"
          disabled={isDisabled}
        />
        <CloseButton onClick={onClose} />
      </Stack>
    </Stack>
  )
}
