import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Traveler_traveler } from '../../../__generated__/Traveler';
import { AttachFormModal } from './AttachFormModal';
import { TravelerAvailableDocumentTypes } from 'common/graphql/__generated__/TravelerAvailableDocumentTypes';
export interface AttachFormProps {
  availableDocumentTypes: TravelerAvailableDocumentTypes | undefined;
  traveler: Traveler_traveler;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  traveler,
  availableDocumentTypes,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { dirty } = useFormikContext<any>();

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={dirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          traveler={traveler}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  );
};
