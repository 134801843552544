import { IDropdownOption } from '@fluentui/react';

const departureFlightClassOptions: IDropdownOption[] = [
  {
    key: 'isDepartureEconomyClass',
    text: 'Economy',
  },
  {
    key: 'isDeparturePremiumClass',
    text: 'Premium',
  },
  {
    key: 'isDepartureBusinessClass',
    text: 'Business',
  },
  {
    key: 'isDepartureFirstClass',
    text: 'First class',
  },
  {
    key: 'isDepartureCharterClass',
    text: 'Charter',
  },
];

const returnFlightClassOptions: IDropdownOption[] = [
  {
    key: 'isReturnEconomyClass',
    text: 'Economy',
  },
  {
    key: 'isReturnPremiumClass',
    text: 'Premium',
  },
  {
    key: 'isReturnBusinessClass',
    text: 'Business',
  },
  {
    key: 'isReturnFirstClass',
    text: 'First class',
  },
  {
    key: 'isReturnCharterClass',
    text: 'Charter',
  },
];
export { departureFlightClassOptions, returnFlightClassOptions };
