import React from 'react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import { NotesListItem } from './NotesListItem';
interface NotesListProps {
  data: NotesEntityFields[] | undefined;
  dataLoading: boolean;
  isDirty?: boolean;
  isViewOnly?:boolean
}
export const NotesList: React.FC<NotesListProps> = ({
  data,
  dataLoading,
  isViewOnly=false,
  ...props
}) => {
  return (
    <>
      {data?.map((item, index) => {
        return (
          <NotesListItem
            key={index}
            item={item}
            index={index}
            totalNotes={data.length}
            isViewOnly={isViewOnly}
            {...props}
          />
        );
      })}
      {!dataLoading && !data?.length && (
        <NoDataView title="No available notes" show />
      )}
    </>
  );
};
