import { FontSizes, makeStyles } from '@fluentui/react';

const GREEN_700 = '#1DBA6E';
const GREEN_800 = '#1ab068';
const GREEN_900 = '#149D5B';
const RED = '#BF3232';
const NODE_LINK = '#9c9c9c';
export const useStyles = makeStyles((theme) => ({
  iconColor: {
    color: 'white',
  },
  treeIcon: {
    color: theme.palette.themePrimary,
    fontSize: FontSizes.large,
  },

  iconColorPending: {
    color: theme.palette.neutralSecondary,
  },

  descriptionColor: {
    color: theme.palette.neutralSecondaryAlt,
  },
  descriptionColorApprove: {
    color: 'white',
  },
  headerApprove: {
    color: 'white',
  },
  headerDeclined: {
    color: theme.palette.black,
  },
  headerPending: {
    color: theme.palette.themePrimary,
  },
  treeHeadingStyle: {
    backgroundColor: theme.palette.white,
    width: 150,
    padding: '7px 10px 7px 10px',
    borderRadius: 10,
    border: `1px dashed ${theme.palette.neutralTertiary}`,
  },
  statusBlock: {
    padding: '10px 25px 10px 25px',
    borderRadius: 10,
    marginTop: 10,
  },
  whiteBackground: { backgroundColor: theme.palette.white },
  backgroundApprove: {
    backgroundColor: GREEN_700,
  },
  backgroundApprovedBlock: {
    backgroundColor: GREEN_900,
  },
  backgroundPendingBlock: {
    backgroundColor: theme.palette.neutralLight,
  },
  backgroundDeclineBlock: {
    backgroundColor: RED,
  },

  backgroundDefault: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  orgchartcontainer: {
    backgroundColor: 'wheat',
  },
  nodeContainer: {
    boxSizing: 'border-box',
    width: 200,
    height: 260,
    borderRadius: 10,
    padding: 10,
    boxShadow: '1px 10px 40px -12px rgba(0, 0, 0, 0.48)',
    MozWindowShadow: '1px 10px 40px -12px rgba(0, 0, 0, 0.48)',
    WebkitBoxShadow: '1px 10px 40px -12px rgba(0, 0, 0, 0.48)',
  },
  treeContainer: {
    width: 0,
    height: 220,
    justifyContent: 'flex-end',
    alignItems: 'center',
    border: `1px solid ${NODE_LINK}`,
  },
  msgButton: {
    borderRadius: 10,
    height: 40,
  },
  msgButtonAppproved: {
    color: 'white',
    backgroundColor: GREEN_700,
    borderColor: GREEN_700,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: GREEN_800,
      borderColor: GREEN_800,
    },
    ':active': {
      color: 'white',
      backgroundColor: GREEN_900,
      borderColor: GREEN_900,
    },
  },

  msgButtonDeclined: {
    ':hover': {},
    ':active': {},
  },
  textviewStyles: {
    alignSelf: 'center',
    wordWrap: 'break-word',
    width: 140,
  },
}));
