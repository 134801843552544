import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  statusType: {
    display: 'block',
    width: 100,
  },
  amountStack: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
  },
  logo: {
    fill: theme.palette.neutralPrimary,
  },
  cellContainer: {
    height: 25,
  },
  // bg: {
  //   backgroundColor: 'red',
  //   color: 'blue',
  //   flex: 1,
  // },
  // currencyCell: {
  //   height: 25,
  //   alignItems: "center"
  // },
  checkboxContainer: {
    flex: 0.2,
    justifyContent: 'end',
  },
  rowBaseStyle: { borderLeft: `5px solid transparent` },
}));
