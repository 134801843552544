import React from 'react';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { TABLE_ROWS } from 'common/constants';
import { loader } from 'graphql.macro';
import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AttachFormProps } from '..';
import { useStyles } from './index.styles';
import {
  AttachableSupplierDocuments,
  AttachableSupplierDocumentsVariables,
  AttachableSupplierDocuments_attachableSupplierDocuments_nodes,
} from './__generated__/AttachableSupplierDocuments';
import {
  AttachSupplierDocument,
  AttachSupplierDocumentVariables,
} from './__generated__/AttachSupplierDocument';
import {
  DirectionalHint,
  IColumn,
  IconButton,
  IDetailsRowProps,
  IRenderFunction,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { EntityDocumentFilter } from 'common/types/globalTypes';
import { SortOrder } from '../types';
import { toOrderByVariable } from '../utils';
import { _columns } from './columns.data';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';

const ATTACHABLE_SUPPLIER_DOCUMENTS = loader(
  './AttachableSupplierDocuments.graphql'
);
const ATTACH_SUPPLIER_DOCUMENTS = loader('./AttachSupplierDocument.graphql');
const SUPPLIER_DETAILS = loader('../../../../Supplier.graphql');

type AttachableDataType =
  AttachableSupplierDocuments_attachableSupplierDocuments_nodes;
export const AttachFormModal: React.FC<
  AttachFormProps & { setOpen: (open: boolean) => void }
> = ({ availableDocumentTypes, supplier, setOpen }) => {
  const styles = useStyles();
  const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const {
    data: documentsList,
    loading: documentsLoading,
    variables: documentsVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<
    AttachableSupplierDocuments,
    AttachableSupplierDocumentsVariables
  >(ATTACHABLE_SUPPLIER_DOCUMENTS, {
    variables: { supplierId: supplier.id!, first: TABLE_ROWS },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [attachDocuments, { loading: attachLoading, error: attachError }] =
    useMutation<AttachSupplierDocument, AttachSupplierDocumentVariables>(
      ATTACH_SUPPLIER_DOCUMENTS
    );
  const { addToast } = useToasts();
  const canSelectItem = useCallback(
    (item: AttachableDataType) =>
      supplier?.isDraft! ? true : !item._isAccountingDocument!,
    [supplier]
  );

  const documentTypeOptions =
    availableDocumentTypes?.supplierAvailableDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
        title:
          !supplier?.isDraft && doctype.isAccountingDocument
            ? 'Upload prohibited during/after the approval process' || undefined
            : doctype.documentType || '',

        disabled:
          (!supplier?.isDraft && doctype.isAccountingDocument) || undefined,
      })
    ) || [];

  const _onAttach = async () => {
    const entityDocIds = selectedList.map((item) => item.id);

    await attachDocuments({
      variables: {
        input: {
          supplierId: supplier?.id!,
          entityDocumentId: entityDocIds,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: SUPPLIER_DETAILS,
          variables: {
            id: supplier?.id,
          },
        },
      ],
    });
    if (!attachError)
      addToast('Document attached successfully', { appearance: 'success' });
    else addToast('Cannot attach the document', { appearance: 'error' });

    setOpen(false);
  };

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;

      const disableLogic = supplier?.isDraft
        ? false
        : item._isAccountingDocument;

      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;
    
      switch (column.key) {
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout disabled={disableLogic!} item={item} />
            </Stack>
          );
        case 'documentType':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {item.documentTypes?.documentType}
              </Text>
            </Stack>
          );
        case 'indexAmount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignRight}>
                {fieldContent}
              </Text>
            </Stack>
          );
        case 'view':
            const viewDocumentVisible =
              item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case 'isoCode':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {item.currency?.isoCode}
              </Text>
            </Stack>
          );

        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {getGlobalDateFormat(item._uploadDate!)}
              </Text>
            </Stack>
          );
        case 'indexTransactionDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              {item.indexTransactionDate && (
                <Text>
                  {dateFormat(dateConvertions(item.indexTransactionDate!))}
                </Text>
              )}
            </Stack>
          );

        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {fieldContent}
              </Text>
            </Stack>
          );
      }
    },
    [styles, supplier]
  );

  const { pageInfo, nodes } = {
    ...documentsList?.attachableSupplierDocuments,
  };

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const { item } = { ...props };
    const newProps: IDetailsRowProps | undefined = props
      ? { ...props, className: styles.row }
      : undefined;

    const tootTipMessage =
      'Upload prohibited during/after the approval process';
    const tooltipVisible = !supplier?.isDraft && item._isAccountingDocument;
    return (
      <>
        <TooltipHost
          content={tooltipVisible ? tootTipMessage : ''}
          directionalHint={DirectionalHint.rightCenter}
        >
          {defaultRender!(newProps)}
        </TooltipHost>
      </>
    );
  };

  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...documentsVariables,
          after: documentsList?.attachableSupplierDocuments?.pageInfo.endCursor,
        },
      }),
    [fetchMore, documentsVariables, documentsList]
  );

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null) =>
      await refetch({ ...documentsVariables, documentTypeId }),
    [refetch, documentsVariables]
  );

  const attachDisabled = selectedList.length === 0;

  return (
    <>
      <AttachDocumentModal
        columns={_columns}
        loading={documentsLoading}
        modalWidth={1350}
        items={
          networkStatus === NetworkStatus.refetch ||
          networkStatus === NetworkStatus.setVariables
            ? undefined
            : nodes
        }
        hasNextPage={pageInfo?.hasNextPage}
        attachLoading={attachLoading}
        availableDocumentTypes={documentTypeOptions}
        setOpen={setOpen}
        onSortReload={reload}
        onLoadMore={loadMore}
        onRenderRow={onRenderRow}
        onFiltersReload={onFiltersReload}
        onDocumentTypeChange={onDocumentTypeReload}
        attachDisabled={attachDisabled}
        onSelectionChanged={setSelectedList}
        dropdownDisabled={selectedList.length > 0}
        onRenderItemColumn={onRenderItemColumn}
        onAttachDocuments={_onAttach}
        canSelectItem={canSelectItem}
      />
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
