import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import { DocumentViewModalState } from 'common/components/DocumentList';
import React, { useState } from 'react';
import { DocumentContainer } from '../DocumentContainer';

interface DocumentPackageProps {
  documentPackageId: string | null | undefined;
  paddingLeft?: number;
}
export const DocumentPackage: React.FC<DocumentPackageProps> = ({
  documentPackageId,
  paddingLeft,
}) => {
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  if (documentPackageId !== null && documentPackageId !== undefined)
    return (
      <Stack>
        <TooltipHost content={'View Document Package'}>
          <IconButton
            iconProps={{ iconName: 'TextDocument' }}
            onClick={() => {
              setDocViewState({
                ...docViewState,
                entityDocumentId: documentPackageId,
                isOpen: true,
              });
            }}
          />
        </TooltipHost>
        <DocumentContainer
          onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
          {...docViewState}
        />
      </Stack>
    );
  else return null;
};
