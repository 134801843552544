import { Stack } from '@fluentui/react';
import { AdvancedFormikDropdown } from 'common/components/FormikFields/AdvancedFormikDropdown';
import React from 'react';
import { BasicFormProps } from '../';
import { useStyles } from './index.styles';

export const BasicForm: React.FC<BasicFormProps> = ({
  data,
  isUpdatable,
  isEdit,
}) => {
  const style = useStyles();

  const documentPoolOptions =
    data?.documentPools?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
    })) || [];

  const deptOptions =
    data?.companyDepartments?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
    })) || [];

  const userGroupsOptions =
    data?.companyUserGroups?.nodes.map((item) => ({
      key: item.userGroupId,
      text: item.userGroup?.name || '',
    })) || [];

  const companyUserOptions =
    data?.availableCardHolderUsers?.nodes.map((item) => ({
      key: item.id,
      text: item.userName || '',
    })) || [];

  const isDisabled = isEdit && !isUpdatable;

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 50 }}
        className={style.mainContainer}
      >
        <Stack
          grow={1}
          tokens={{ childrenGap: 20 }}
          className={style.detailsContainer}
        >
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <AdvancedFormikDropdown
                disabled={isDisabled}
                label="Department"
                placeholder="Select"
                options={deptOptions}
                name="departmentId"
                dropdownWidth={300}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <AdvancedFormikDropdown
                disabled={isDisabled}
                label="User Group"
                placeholder="Select"
                options={userGroupsOptions}
                name="userGroupId"
                dropdownWidth={300}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <AdvancedFormikDropdown
                placeholder="Select"
                label="Document Folder"
                name="documentPoolId"
                options={documentPoolOptions}
                disabled={isDisabled}
                dropdownWidth={300}
              />
            </Stack.Item>
          </Stack>
          <Stack
            horizontal
            tokens={{ childrenGap: 20, padding: '0px 0px 30px 0px' }}
          >
            <Stack.Item className={style.flex50}>
              <AdvancedFormikDropdown
                placeholder="Select"
                name="tenantUserId"
                label="User Documents"
                options={companyUserOptions}
                disabled={isDisabled}
                dropdownWidth={300}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
