import React, { useEffect, useRef, useState, useCallback } from 'react';
import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
  IColumn,
  IconButton,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  CommunicationChannelsOrderBy,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import { columns } from './column.data';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import { useStyles } from './index.styles';
import { InfiniteList } from 'common/components/InfiniteList';
import { useBoolean } from '@fluentui/react-hooks';
import {
  CommunicationChannels,
  CommunicationChannelsVariables,
  CommunicationChannels_communicationChannels_nodes,
} from './__generated__/CommunicationChannels';
import { toOrderByVariable } from './utils';
import { CreateCommunicationChannelForm } from '../view';
import {
  CommunicationChannelDelete,
  CommunicationChannelDeleteVariables,
} from './__generated__/CommunicationChannelDelete';
import { useToasts } from 'react-toast-notifications';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
const COMMUNICATION_CHANNEL = loader('./CommunicationChannels.graphql');
const DELETE_CHANNEL = loader('./CommunicationChannelDelete.graphql');

export type CommunicationChannelListItem =
  CommunicationChannels_communicationChannels_nodes;

export const CommunicationChannel: React.FC = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const renderRef = useRef(false);
  const { addToast } = useToasts();
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<
    CommunicationChannelListItem[]
  >([]);
  const [updateData, setUpdateData] = useState<
    CommunicationChannels_communicationChannels_nodes | undefined | null
  >();
  const [hideCreateForm, { toggle: toggleCreateForm }] = useBoolean(false);
  const [
    fetchCommunicationChannelList,
    {
      loading: communicationChannelListLoading,
      data: communicationChannelListData,
      networkStatus,
      fetchMore,
      variables,
    },
  ] = useLazyQuery<CommunicationChannels, CommunicationChannelsVariables>(
    COMMUNICATION_CHANNEL,
    {
      variables: {
        first: TABLE_ROWS,
        orderBy: [CommunicationChannelsOrderBy.NAME_ASC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const [deleteChannel] = useMutation<
    CommunicationChannelDelete,
    CommunicationChannelDeleteVariables
  >(DELETE_CHANNEL, {
    errorPolicy: 'all',
  });

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const transformedData: CommunicationChannelListItem[] | undefined = refetching
    ? undefined
    : communicationChannelListData?.communicationChannels?.nodes.map(
        (appr) => ({
          ...appr,
          _isUpdatable: appr._isUpdatable,
        })
      );

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const handleSearch = (showMore: boolean) => {
    const variables: CommunicationChannelsVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? communicationChannelListData?.communicationChannels?.pageInfo
            .endCursor
        : undefined,
      filter: { notificationTypeId: { isNull: true } },
      orderBy: toOrderByVariable(sortOrderParam),
    };

    if (showMore) fetchMore?.({ variables });
    else fetchCommunicationChannelList({ variables: variables });
  };

  const handleSearchMemo = useCallback(handleSearch, [sortOrderParam]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    handleSearchMemo(false);
    renderRef.current = true;
  }, [handleSearchMemo]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
  }, [handleSearchMemo]);
  const getSelectorValue = (value: boolean) => {
    return value ? 'Yes' : 'No';
  };
  const _onUpdateModalOpen = (
    item: CommunicationChannels_communicationChannels_nodes
  ) => {
    if (item) {
      setUpdateData(item);
      setIsNew(false);
    }
    toggleCreateForm();
  };
  const _renderItemColumn = (
    item: CommunicationChannelListItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      // const key = column.key as keyof CommunicationChannelListItem;
      const fieldContent = item?.[
        column?.fieldName as keyof CommunicationChannelListItem
      ] as string;
      switch (column?.key) {
        case 'name':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item.name}</Text>
            </Stack>
          );
        case 'description':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item?.description}</Text>
            </Stack>
          );
        case '_createdDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignRight}>
                {fieldContent && getGlobalDateFormat(fieldContent)}
              </Text>
            </Stack>
          );
        case 'isReports':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{getSelectorValue(item.isReports!)}</Text>
            </Stack>
          );
        case 'isMessages':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{getSelectorValue(item.isMessages!)}</Text>
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
              className={styles.contentColumnCenter}
            >
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                disabled={!item._isUpdatable}
                onClick={() => {
                  _onUpdateModalOpen(item);
                }}
              />
            </Stack>
          );

        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{fieldContent ?? ''}</Text>
            </Stack>
          );
      }
    }
  };
  const _onConfirm = async () => {
    const deletableTagItems = selectedRows.filter((item) => item._isDeletable);
    if (deletableTagItems.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableTagItems.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      const { errors } = await deleteChannel({
        variables: {
          input: {
            entityDelete: deleteInputArray,
          },
        },
        update: (cache, { data }) => {
          const deletedIds =
            data?.communicationChannelDelete?.deletedEntities?.map(
              (entity) => entity?.id
            );
          if (deletedIds) {
            const filteredList =
              communicationChannelListData?.communicationChannels?.nodes.filter(
                (channel) => deletedIds.indexOf(channel.id) === -1
              );
            const newData: CommunicationChannels = {
              communicationChannels: {
                pageInfo:
                  communicationChannelListData?.communicationChannels
                    ?.pageInfo!,
                nodes: filteredList!,
                totalCount:
                  communicationChannelListData?.communicationChannels
                    ?.totalCount! - deletedIds.length,
              },
            };
            cache.writeQuery<
              CommunicationChannels,
              CommunicationChannelsVariables
            >({
              query: COMMUNICATION_CHANNEL,
              variables,
              data: newData,
            });
          }
        },
      });
      if (!errors) {
        addToast('Channel deleted Successfully', {
          appearance: 'success',
        });
        setSelectedRows([]);
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };
  const disabled = !selectedRows.some((selected) => selected._isDeletable);
  const onCreateChannel = () => {
    setUpdateData(null);
    setIsNew(true);
    toggleCreateForm();
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Communication Channels</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.CommunicationChannel}
              disabled={disabled}
              visible={selectedRows.length > 0}
              multiple={{
                validCount: selectedRows.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedRows
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete.name),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              iconProps={{
                iconName: 'Add',
              }}
              text="New Channel"
              onClick={onCreateChannel}
            />
          </Stack>
        </Stack>
        <Stack>
          <CommunicationChannelSearchBar
            columns={gridColumns}
            onRefresh={() => handleSearch(false)}
            onToggleVisibility={setGridColumns}
          />
        </Stack>
      </Stack>
      <InfiniteList<CommunicationChannelListItem>
        loading={communicationChannelListLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={
          communicationChannelListData?.communicationChannels?.pageInfo
            .hasNextPage
        }
        items={transformedData}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={() => handleSearch(true)}
        onSelectionChanged={setSelectedRows}
      />
      {hideCreateForm && (
        <CreateCommunicationChannelForm
          visible={hideCreateForm}
          onDismiss={toggleCreateForm}
          channelData={updateData!}
          isNew={isNew}
        />
      )}
    </>
  );
};
type ChannelSearchBarProps = Omit<SearchBarProps, 'searchEnabled'>;
export const CommunicationChannelSearchBar: React.FC<ChannelSearchBarProps> = ({
  ...props
}) => {
  return <SearchBar searchEnabled={false} {...props}></SearchBar>;
};
