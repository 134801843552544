import { IconButton, Stack, Text, TooltipHost, makeStyles } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { DocumentPackage } from 'common/components/DocumentPackage';
import { StampOptions, StamperView } from 'common/components/StamperView';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getRedirectionLink } from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "3.5vh",
  },
  linkIcon: {
    color: theme.palette.neutralSecondary,
    fontSize: 20,
  },
}));

interface HeaderComponentProps {
  entityId: string;
  entityType: string;
  heading: string;
  isApproved: boolean;
  documentPackageId?: string | null;
  stampData?: StampOptions;
  onDismiss: () => void;
}
export const HeaderComponent: React.FC<HeaderComponentProps> = ({
  entityId,
  entityType,
  heading,
  isApproved,
  documentPackageId,
  stampData,
  onDismiss,
}) => {
  const styles = useStyles()
  const history = useHistory();
  const commonStyles = useCommonStyles();
  const link = getRedirectionLink({ isApproved, entityId, entityType })

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      className={styles.container}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 10 }}
      >
        <Text variant={'xLarge'}>Transaction</Text>
        <Text
          variant={'xLarge'}
          className={commonStyles.colorThemePrimary}
        >
          {heading}
        </Text>
        {
          link &&
          <TooltipHost content='Details'>
            <IconButton
              onClick={() =>
                history.push(link)
              }
              iconProps={{ iconName: 'ArrowUpRight' }}
              styles={{
                icon: styles.linkIcon,
              }}
            />
          </TooltipHost>
        }
        <DocumentPackage
          documentPackageId={documentPackageId}
        />
      </Stack>
      <Stack verticalAlign="start" horizontal tokens={{ childrenGap: 10 }}>
        {stampData && <StamperView invoiceDetails={stampData} />}
        <CloseButton onClick={onDismiss} />
      </Stack>
    </Stack>
  )
}
