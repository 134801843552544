import { Label, Stack, Sticky, StickyPositionType } from '@fluentui/react';
import React from 'react';
import { ColumnData } from '../column.data';

interface ColumnsHeaderProps {
  columnsArray: ColumnData[];
}
export const ColumnsHeader: React.FC<ColumnsHeaderProps> = ({
  columnsArray,
}) => {
  return (
    <Sticky stickyPosition={StickyPositionType.Header}>
      <Stack horizontal tokens={{ childrenGap: 30 }}>
        {columnsArray.map((value, key) => {
          if (value.isVisible) {
            switch (value.key) {
              default:
                return (
                  <Stack
                    style={{
                      height: 30,
                      width: value.maxWidth,
                    }}
                    key={key}
                    horizontalAlign={
                      value.key === 'budgetAmount' ? 'end' : 'center'
                    }
                    verticalAlign="center"
                  >
                    <Label key={key} style={{ flexWrap: 'nowrap' }}>
                      {value.name}
                    </Label>
                  </Stack>
                );
            }
          } else return null;
        })}
      </Stack>
    </Sticky>
  );
};
