import { useReactiveVar } from '@apollo/client';
import { IContextualMenuItem, IContextualMenuProps, PrimaryButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { setTripsModalState } from '../../../..';
import { FooterProps } from '..';

type SaveButtonProps = Pick<FooterProps, 'onSave'> & {
  disabled: boolean;
}
enum ButtonText {
  SAVE_AND_CLOSE = 'Save and close',
  SAVE_AND_ADD_NEW = 'Save + Add New',
  SAVE = 'Save'
}
enum ButtonKey {
  SAVE_AND_CLOSE = 'saveClose',
  SAVE_AND_ADD_NEW = 'saveAddNew',
  SAVE = 'save'
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  disabled,
  onSave,
}) => {
  const tripsModalState = useReactiveVar(setTripsModalState);
  const [buttonText, setButtonText] = useState<string>(ButtonText.SAVE);
  const [buttonKey, setButtonKey] = useState<string>(ButtonKey.SAVE);

  const { isNew } = { ...tripsModalState }

  const onSaveForm = () => {
    switch (buttonKey) {
      case ButtonKey.SAVE:
        return onSave(false, false)
      case ButtonKey.SAVE_AND_CLOSE:
        return onSave(isNew, false)
      case ButtonKey.SAVE_AND_ADD_NEW: {
        return onSave(false, isNew)
      }
    }
  }

  const onButtonClick = (
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) => {
    setButtonText(item?.text || "");
    setButtonKey(item?.key || '');
    switch (item?.key) {
      case ButtonKey.SAVE_AND_CLOSE:
        return onSave(true, false)
      case ButtonKey.SAVE_AND_ADD_NEW:
        return onSave(false, isNew)
      case ButtonKey.SAVE:
        return onSave(false, false)
    }
  }

  const getMenuProps = () => {
    const newMenuProps: IContextualMenuProps = {
      items: [
        {
          key: ButtonKey.SAVE_AND_CLOSE,
          text: ButtonText.SAVE_AND_CLOSE,
          onClick: onButtonClick
        },
        {
          key: ButtonKey.SAVE_AND_ADD_NEW,
          text: ButtonText.SAVE_AND_ADD_NEW,
          onClick: onButtonClick
        },
      ],
    };

    const editMenuProps: IContextualMenuProps = {
      items: [
        {
          key: ButtonKey.SAVE_AND_CLOSE,
          text: ButtonText.SAVE_AND_CLOSE,
          onClick: onButtonClick
        },
      ],
    };
    return isNew ? newMenuProps : (editMenuProps as IContextualMenuProps);
  };

  const menuProps = getMenuProps();

  useEffect(() => {
    if (!isNew) {
      setButtonText(ButtonText.SAVE);
      setButtonKey(ButtonKey.SAVE);
    }
  }, [isNew])

  return (
    <PrimaryButton
      text={buttonText}
      menuProps={menuProps}
      split
      disabled={disabled}
      onClick={onSaveForm}
    />
  )
}
