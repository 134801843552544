import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  Stack,
} from '@fluentui/react';
import { DangerButton } from 'common/components/DangerButton';
import { loader } from 'graphql.macro';
import { PurchaseOrder_purchaseOrder_purchaseOrderItems_nodes } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { PurchaseOrderType } from '../..';
import {
  PurchaseOrderItemDelete,
  PurchaseOrderItemDeleteVariables,
} from './__generated__/PurchaseOrderItemDelete';
import { PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals } from 'purchaseOrder/list/__generated__/PurchaseOrderSearchFilterTotals';
import Decimal from 'decimal.js';

const DELETE = loader('./PurchaseOrderItemDelete.graphql');
const dialogStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
  type: DialogType.normal,
  title: 'Warning',
  closeButtonAriaLabel: 'Close',
  subText: 'Are you sure you want to delete this purchase order item?',
};

interface DeleteItemProps {
  inputsDisabled: boolean;
  item: PurchaseOrder_purchaseOrder_purchaseOrderItems_nodes;
  onSuccess?: () => void;
  purchaseOrder: PurchaseOrderType | undefined | null;
}
export const DeleteItem: React.FC<DeleteItemProps> = ({
  item,
  purchaseOrder,
  onSuccess,
  inputsDisabled,
}) => {
  const [visible, setVisible] = useState(false);
  const { addToast } = useToasts();
  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: dialogStyles,
    }),
    []
  );

  const [deleteItem] = useMutation<
    PurchaseOrderItemDelete,
    PurchaseOrderItemDeleteVariables
  >(DELETE, { errorPolicy: 'all' });

  const onDeleteClick = async () => {
    const { errors } = await deleteItem({
      variables: {
        input: {
          entityDelete: [{ id: item.id, rowTimestamp: item._rowTimestamp! }],
        },
        purchaseOrderId: purchaseOrder?.id!,
      },
      update(cache) {
        try {
          cache.modify({
            fields: {
              purchaseOrderSearchFilterTotals(
                existing: PurchaseOrderSearchFilterTotals_purchaseOrderSearchFilterTotals
              ) {
                if (existing?.totalCount1 !== 0) {
                  return {
                    ...existing,
                    totalAmount1: new Decimal(existing.totalAmount1 || '0')
                      .minus(item?._extendedItemTotal || '0')
                      .toNumber(),
                  };
                } else {
                  return {
                    ...existing,
                  };
                }
              },
            },
          });
        } catch (error) {}
      },
    });

    if (errors) addToast(errors?.[0].message, { appearance: 'error' });
    else {
      setVisible(false);
      addToast('PO Item - Deleted Successfully', {
        appearance: 'success',
      });
      onSuccess?.();
    }
  };

  return (
    <Stack tokens={{ padding: '0px 0px 0px 6px' }}>
      <IconButton
        disabled={inputsDisabled}
        iconProps={{ iconName: 'delete' }}
        onClick={() => setVisible(true)}
      />
      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <DangerButton onClick={onDeleteClick} text="Delete" />
            <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}
    </Stack>
  );
};
