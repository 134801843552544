import { useMutation } from '@apollo/client';
import { ContextualMenu, DayOfWeek, DefaultButton, IDragOptions, Label, Modal, PrimaryButton, ProgressIndicator, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseButton } from 'common/components/Buttons';
import { FormHookDatePicker, FormHookDropdown, FormHookTextField } from 'common/components/FormHooksFields';
import { EntityEnvironmentalDelete, EntityEnvironmentalDeleteVariables } from 'common/graphql/__generated__/EntityEnvironmentalDelete';
import { EntityEnvironmentalUpdate, EntityEnvironmentalUpdateVariables } from 'common/graphql/__generated__/EntityEnvironmentalUpdate';
import { EntityEnvironmentalItemPatch, EntityEnvironmentalItemUpdateTypeInput, TransactionLayout } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { CarbonAccountingProps, CategoryOptionsType } from '..';
import { Trip_trip_entityEnvironmentalsByEntityId_nodes_entityEnvironmentalItems_nodes } from '../../../../__generated__/Trip';
import { EntityEnvironmentalTripCreate, EntityEnvironmentalTripCreateVariables } from './__generated__/EntityEnvironmentalTripCreate';
import { CARBONACCOUNTING_INITIAL_VALUES, ENVIRONMENTAL_ITEMS_VALUES } from './constants';
import { useStyles } from './index.styles';
import { CarbonAccountingValues, EnvironmentalItemsRowValues } from './types';
import { validationSchema } from './validation';
import { EnvironmentalItemsView } from './EnvironmentalItemsView';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
const ENTITY_ENVIRONMENT_TRIP_ORDER_CREATE = loader("./EntityEnvironmentalTripCreate.graphql");
const ENTITY_ENVIRONMENT_DELETE = loader('../../../../../../../../../../common/graphql/EntityEnvironmentalDelete.graphql');
const ENTITY_ENVIRONMENT_UPDATE = loader('../../../../../../../../../../common/graphql/EntityEnvironmentalUpdate.graphql');
const CONFIRM_DELETE_DIALOG_TITLE = 'Please confirm the action.';
const CONFIRM_DELETE_DIALOG_SUBTEXT = 'This will remove the accounting from this purchase order.';

export type EntityEnvironmentalItemsType = Omit<
  Trip_trip_entityEnvironmentalsByEntityId_nodes_entityEnvironmentalItems_nodes,
  '_isIntegerFormat' | '_isFloatFormat' | '_isAmountFormat'
> & {
  isIntegerFormat: boolean | null;
  isFloatFormat: boolean | null;
  isAmountFormat: boolean | null;
};

type FormModalProps = CarbonAccountingProps & {
  categoryOptions: CategoryOptionsType[];
  setOpen: (param: boolean) => void;
}

const modalDragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
}

export const FormModal: React.FC<FormModalProps> = ({
  trip,
  categoryOptions,
  refetch,
  setOpen
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const formMethods = useForm<CarbonAccountingValues>({
    mode: "all",
    resolver: yupResolver(validationSchema()),
  });
  const [categoryName, setCategoryName] = useState<string | undefined>();
  const [categoryId, setCategoryId] = useState<string | undefined>();
  const [layoutType, setLayoutType] = useState<TransactionLayout | undefined>();
  const [entityEnvironmentalItems, setEntityEnvironmentalItems] = useState<EnvironmentalItemsRowValues[]>([]);
  const [hideConfirmDialog, { toggle: toggleConfirmDialog }] = useBoolean(true);

  const [createEnvironment, { loading: createLoading }] = useMutation<
    EntityEnvironmentalTripCreate,
    EntityEnvironmentalTripCreateVariables
  >(ENTITY_ENVIRONMENT_TRIP_ORDER_CREATE, { errorPolicy: 'all' });

  const [deleteEnvironment, { loading: deleteLoading }] =
    useMutation<EntityEnvironmentalDelete, EntityEnvironmentalDeleteVariables>(
      ENTITY_ENVIRONMENT_DELETE,
      { errorPolicy: 'all' }
    );

  const [updateEnvironment, { loading: updateEnvironmentLoading }] =
    useMutation<EntityEnvironmentalUpdate, EntityEnvironmentalUpdateVariables>(
      ENTITY_ENVIRONMENT_UPDATE,
      { errorPolicy: 'all' }
    );

  const {
    _isEnvironmentalsExist,
    entityEnvironmentalsByEntityId,
  } = { ...trip }

  const {
    id,
    _rowTimestamp
  } = { ...entityEnvironmentalsByEntityId?.nodes[0] }

  const showDeleteButton = !!entityEnvironmentalsByEntityId?.nodes.length;
  const showProgressIndicator = createLoading || deleteLoading || updateEnvironmentLoading;

  const {
    handleSubmit,
    reset,
    trigger,
    formState: {
      errors,
      isDirty,
      isSubmitting
    }
  } = { ...formMethods }

  const onHandleSubmit = async (values: CarbonAccountingValues) => {
    if (_isEnvironmentalsExist && categoryId) {
      //Map the initial values of Environmental Items row to the EntityEnvironmentalItemUpdateTypeInput type array
      let itemUpdateFilter: EntityEnvironmentalItemUpdateTypeInput[] = [];
      if (!!values.entityEnvironmentalItems?.length) {
        values.entityEnvironmentalItems?.map((item) => {
          const {
            id,
            entityEnvironmentalId,
            carbonCreditsAmount,
            isIntegerFormat,
            isAmountFormat,
            isFloatFormat,
            ...itemValues
          } = { ...item }
          if (item.id) {
            const environmentalItemObj: EntityEnvironmentalItemUpdateTypeInput = {
              id,
              rowTimestamp: _rowTimestamp,
              entityEnvironmentalItemPatch: {
                ...itemValues
              } as EntityEnvironmentalItemPatch,
            };
            itemUpdateFilter.push(environmentalItemObj);
          }
        });
      }
      const { errors } = await updateEnvironment({
        variables: {
          input: {
            id: id!,
            rowTimestamp: _rowTimestamp!,
            entityEnvironmentalPatch: {
              description: values.description,
              startDate: values.startDate ? dateFormat(values.startDate) : null,
              endDate: values.endDate ? dateFormat(values.endDate) : null,
            },
            entityEnvironmentalItemsUpdate: itemUpdateFilter,
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Environmental updated successfully', {
          appearance: 'success',
        });
        setOpen(false);
        refetch();
      }
    } else {
      const { errors } = await createEnvironment({
        variables: {
          input: {
            entityEnvironmental: {
              description: values.description,
              entityId: trip?.id!,
              environmentalMetricId: values.environmentalMetricId!,
              startDate: values.startDate ? dateFormat(values.startDate) : null,
              endDate: values.endDate ? dateFormat(values.endDate) : null,
            },
            entityEnvironmentalItems: values.entityEnvironmentalItems?.map(
              ({ isIntegerFormat, isFloatFormat, isAmountFormat, ...rest }) =>
                rest
            ),
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Environmental added successfully.', { appearance: 'success' });
        setOpen(false);
        refetch();
      }
    }
  }

  const entityEnvironmentalDelete = async () => {
    const { errors } = await deleteEnvironment({
      variables: {
        input: {
          entityDelete: [
            {
              id: id!,
              rowTimestamp: _rowTimestamp!,
            },
          ],
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Environmental deleted successfully', { appearance: 'success' });
      setEntityEnvironmentalItems([]);
      setCategoryId(undefined);
      setOpen(false);
      refetch();
    }
  };

  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack])

  useEffect(() => {
    if (!!entityEnvironmentalsByEntityId?.nodes.length) {
      const {
        environmentalMetricId,
        environmentalMetric,
      } = { ...entityEnvironmentalsByEntityId?.nodes[0] }
      setCategoryId(environmentalMetricId);
      setLayoutType(environmentalMetric?.layoutType!);
      setCategoryName(environmentalMetric?.environmentalMetric!);
      //Map the values coming from entityEnvironmentalsByEntityId to a entityEnvironmentalItems state used to set the initial values of entityEnvironmentalItems.
      if (!!entityEnvironmentalsByEntityId?.nodes[0]?.entityEnvironmentalItems?.nodes.length) {
        const environmentalItemsArray: EnvironmentalItemsRowValues[] =
          entityEnvironmentalsByEntityId?.nodes[0]?.entityEnvironmentalItems?.nodes?.map(
            (ele) => {
              const {
                _isIntegerFormat,
                _isFloatFormat,
                _isAmountFormat,
                _rowTimestamp,
                _isUpdatable,
                _isDeletable,
                __typename,
                ...fields
              } = { ...ele }
              return {
                ...fields
              };
            }
          );
        setEntityEnvironmentalItems(environmentalItemsArray);
      }
    }
  }, [entityEnvironmentalsByEntityId]);

  useEffect(() => {
    let initialValues: CarbonAccountingValues = CARBONACCOUNTING_INITIAL_VALUES;
    if (!!entityEnvironmentalsByEntityId?.nodes?.length) {
      initialValues = {
        environmentalMetricId: entityEnvironmentalsByEntityId?.nodes[0]?.environmentalMetricId!,
        startDate: entityEnvironmentalsByEntityId?.nodes[0]?.startDate
          ? dateConvertions(entityEnvironmentalsByEntityId?.nodes[0]?.startDate)
          : null,
        endDate: entityEnvironmentalsByEntityId?.nodes[0]?.endDate
          ? dateConvertions(entityEnvironmentalsByEntityId?.nodes[0]?.endDate)
          : null,
        description: entityEnvironmentalsByEntityId?.nodes[0]?.description,
        entityEnvironmentalItems:
          !!entityEnvironmentalItems.length
            ? [...entityEnvironmentalItems]
            : [ENVIRONMENTAL_ITEMS_VALUES],
      };
    }
    reset(initialValues);
  }, [entityEnvironmentalsByEntityId, entityEnvironmentalItems, reset]);

  return (
    <Modal
      isOpen
      dragOptions={modalDragOptions}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        className={styles.header}
        tokens={{ padding: 20 }}
      >
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <Text variant={'xLarge'}>Carbon accounting</Text>
          {_isEnvironmentalsExist && categoryName && (
            <Text variant={'xLarge'} className={styles.accountingType}>
              {categoryName}
            </Text>
          )}
          <UnsavedIndicator visible={isDirty && !!_isEnvironmentalsExist && !!categoryId} />
        </Stack>
        <Stack>
          <CloseButton
            onClick={
              () => {
                reset();
                setOpen(false)
              }}
          />
        </Stack>
      </Stack>
      <FormProvider {...formMethods}>
        <Stack className={styles.mainContainer} tokens={{ childrenGap: 20 }}>
          <Stack
            className={styles.formContainer}
            tokens={{ childrenGap: 20 }}
          >
            <FormHookDropdown
              label="Category"
              placeholder="Select"
              options={categoryOptions}
              name="environmentalMetricId"
              onChange={(_event, option) => {
                const selectedOption = option as CategoryOptionsType;
                setCategoryId(selectedOption.key.toString());
                setLayoutType(selectedOption.layoutType!);
              }}
              disabled={!!_isEnvironmentalsExist}
              required
            />
            <Stack>
              <Label>Time period</Label>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item grow={1}>
                  <FormHookDatePicker
                    name="startDate"
                    placeholder="From date..."
                    ariaLabel="From date"
                    firstDayOfWeek={DayOfWeek.Monday}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                  />
                </Stack.Item>
                <Stack.Item grow={1}>
                  <FormHookDatePicker
                    name="endDate"
                    placeholder="To date..."
                    ariaLabel="To date"
                    firstDayOfWeek={DayOfWeek.Monday}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                  />
                </Stack.Item>
              </Stack>
            </Stack>
            <FormHookTextField
              name="description"
              label="Comment"
              placeholder="Enter a comment"
            />
          </Stack>
          {categoryId && layoutType && (
            <EnvironmentalItemsView
              categoryId={categoryId}
              layoutType={layoutType}
              isEnvironmentalsExist={!!_isEnvironmentalsExist}
            />
          )}
          {showProgressIndicator && <ProgressIndicator />}
          <ConfirmDialog
            hidden={hideConfirmDialog}
            title={CONFIRM_DELETE_DIALOG_TITLE}
            subText={CONFIRM_DELETE_DIALOG_SUBTEXT}
            onDismiss={toggleConfirmDialog}
            onConfirm={() => {
              toggleConfirmDialog();
              entityEnvironmentalDelete();
            }}
          />
          <Stack
            horizontal
            horizontalAlign="end"
            tokens={{ childrenGap: 20 }}
          >
            {showDeleteButton && (
              <PrimaryButton
                onClick={() => toggleConfirmDialog()}
                text="Delete"
              />
            )}
            <PrimaryButton
              disabled={
                !isDirty || Object.keys(errors).length > 0 || isSubmitting
              }
              onClick={handleSubmit(onHandleSubmit)}
              text="Save"
            />
            <DefaultButton
              onClick={() => {
                reset();
                setOpen(false);
              }}
              text="Cancel"
            />
          </Stack>
        </Stack>
      </FormProvider>
    </Modal>
  )
}
