import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    entityDocumentTypeId: Yup.string().required('Required').nullable(),
    indexCurrencyId: Yup.string().when('indexAmount', {
      is: (indexAmount: number) => indexAmount != null,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
  });
