import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  onrenderColumnStack: {
    height: 50,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  groupItemHeight: {
    height: 30,
    padding: 10,
  },
  contentColumnCenter: {
    display: 'block',
    textAlign: 'center',
  },
}));
