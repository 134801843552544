import { IDropdownOption, Stack } from '@fluentui/react';
import { FormikDropdown, FormikTextField } from 'common/components';
import { AdvancedFormikDropdown } from 'common/components/FormikFields/AdvancedFormikDropdown';
import { useFormikContext } from 'formik';
import React from 'react';
import { BasicFormProps } from '..';
import { CardHolderValues } from '../../types';
import { useStyles } from './index.styles';

interface AvailableCardHolderUsersCardCompanyOptions extends IDropdownOption {
  departmentId: string | null;
  userGroupId: string | null;
}

export const IsManualBasicForm: React.FC<BasicFormProps> = ({
  data,
  isUpdatable,
  isEdit,
}) => {
  const style = useStyles();
  const { values, setFieldValue } = useFormikContext<CardHolderValues>();

  const deptOptions =
    data?.companyDepartments?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
    })) || [];

  const userGroupsOptions =
    data?.companyUserGroups?.nodes.map((item) => ({
      key: item.userGroupId,
      text: item.userGroup?.name || '',
    })) || [];

  const availableCardHolderUsersCardCompanyOptions: AvailableCardHolderUsersCardCompanyOptions[] =
    data?.availableCardHolderUsers?.nodes.map((ele) => ({
      key: ele.id || '',
      text: ele.userName || '',
      departmentId: ele.departmentId,
      userGroupId: ele.userGroupId,
    })) || [];

  const isDisabled = !isUpdatable && isEdit;

  const onApproverSelect = async (
    selectedOption: AvailableCardHolderUsersCardCompanyOptions
  ) => {
    !values.cardFullName &&
      (await setFieldValue('cardFullName', selectedOption.text));
    !values.departmentId &&
      (await setFieldValue('departmentId', selectedOption.departmentId));
    !values.userGroupId &&
      (await setFieldValue('userGroupId', selectedOption.userGroupId));
  };

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 50 }}
        className={style.mainContainer}
      >
        <Stack
          grow={1}
          tokens={{ childrenGap: 20 }}
          className={style.detailsContainer}
        >
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <FormikDropdown
                label="Approver"
                placeholder="Select"
                name="tenantUserId"
                options={availableCardHolderUsersCardCompanyOptions}
                onChange={async (_, option) => {
                  const selectedOption =
                    option as AvailableCardHolderUsersCardCompanyOptions;
                  onApproverSelect(selectedOption);
                }}
                dropdownWidth={300}
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <FormikTextField
                name="cardFullName"
                label="Buyer/Card Holder"
                placeholder="Card Holder"
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <FormikTextField
                name="cardNumber"
                label="Card Number"
                placeholder="Card Number"
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <AdvancedFormikDropdown
                label="Department"
                placeholder="Select"
                options={deptOptions}
                name="departmentId"
                disabled={isDisabled}
                dropdownWidth={300}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <AdvancedFormikDropdown
                label="User Group"
                placeholder="Select"
                options={userGroupsOptions}
                name="userGroupId"
                disabled={isDisabled}
                dropdownWidth={300}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
