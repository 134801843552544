export const colorCells = [
  { id: 'a', label: 'red', color: '#a4262c' },
  { id: 'b', label: 'orange', color: '#ca5010' },
  { id: 'c', label: 'orangeYellow', color: '#986f0b' },
  { id: 'd', label: 'yellowGreen', color: '#8cbd18' },
  { id: 'e', label: 'green', color: '#0b6a0b' },
  { id: 'f', label: 'cyan', color: '#038387' },
  { id: 'g', label: 'cyanBlue', color: '#004e8c' },
  { id: 'h', label: 'magenta', color: '#881798' },
  { id: 'i', label: 'magentaPink', color: '#9b0062' },
  { id: 'j', label: 'black', color: '#000000' },
  { id: 'k', label: 'gray', color: '#7a7574' },
  { id: 'l', label: 'gray20', color: '#69797e' },
];
