import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  onrenderColumnStack: {
    height: 25,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  contentColumnCenter: {
    display: 'block',
    textAlign: 'center',
  },
}));
