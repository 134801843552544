import { Spinner, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { FormHookAmount } from 'common/components/FormHooksFields';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TripCreateFormValues } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/types';

interface RateAndTotalSectionProps {
  baseField: string;
  tripIndex?: number;
  loading?: boolean;
  onRateEntry: (rate: string) => void;
  disabled?: boolean;
  isAccommodation?: string;
}

export const RateAndTotalSection: React.FC<RateAndTotalSectionProps> = ({
  baseField,
  tripIndex,
  loading,
  onRateEntry,
  disabled,
  isAccommodation = 'Days',
}) => {
  const commonStyles = useCommonStyles();
  const { setValue } = useFormContext<TripCreateFormValues>();
  const rentalTerm = useWatch({ name: `${baseField}rentalTerm` });

  return (
    <Stack
      horizontal
      verticalAlign="end"
      horizontalAlign="end"
      tokens={{ childrenGap: 20 }}
    >
      {rentalTerm && (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Text variant="large">{isAccommodation}:</Text>
          <Text
            variant="large"
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
          >
            {rentalTerm}
          </Text>
          {loading && <Spinner />}
        </Stack>
      )}

      <FormHookAmount
        name={`${baseField}rentalPrice`}
        label="Rate"
        placeholder="Enter rate"
        fixedDecimalScale
        allowNegative={false}
        decimalScale={2}
        style={{ width: 160 }}
        onAmountChange={(value) => {
          if (value) {
            onRateEntry(value);
          }
        }}
        disabled={disabled}
      />

      <FormHookAmount
        name={`${baseField}itemTotal`}
        label="Total"
        placeholder="Enter total"
        fixedDecimalScale
        allowNegative={false}
        decimalScale={2}
        style={{ width: 160 }}
        onAmountChange={(value) => {
          if (value) {
            setValue(`tripItems.${tripIndex!}.rentalPrice`, null);
          }
        }}
        disabled={disabled}
      />
    </Stack>
  );
};
