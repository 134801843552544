import React from 'react';
import { Icon, makeStyles } from '@fluentui/react';

interface FolderExpandProps {
  isOpen: boolean;
}

export const FolderExpand: React.FC<FolderExpandProps> = ({
  isOpen = false,
}) => {
  const classStyles = useStyles();
  return (
    <Icon
      iconName={isOpen ? 'FabricOpenFolderHorizontal' : 'FabricFolder'}
      ariaLabel="Folder"
      className={
        isOpen ? classStyles.IconColorExpand : classStyles.IconColorCollapse
      }
    />
  );
};

const useStyles = makeStyles((theme) => {
  return {
    IconColorCollapse: {
      color: theme.palette.black,
    },
    IconColorExpand: {
      color: theme.palette.themePrimary,
    },
  };
});
