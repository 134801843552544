import { ColumnActionsMode, IColumn } from '@fluentui/react';

const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.clickable,
};

export const columns: IColumn[] = [
  {
    key: 'travelAuthorizationNumber',
    name: 'TA Number',
    fieldName: 'travelAuthorizationNumber',
    minWidth: 100,
    ...commonProps,
  },
  {
    key: 'requesterName',
    name: 'Request name',
    fieldName: 'requesterName',
    minWidth: 150,
    ...commonProps,
  },
  {
    key: 'dateRequested',
    name: 'Request date',
    fieldName: 'dateRequested',
    minWidth: 130,
    ...commonProps,
  },
  {
    key: 'tripPurpose',
    name: 'Trip purpose',
    fieldName: 'tripPurpose',
    minWidth: 130,
    ...commonProps,
  },
  {
    key: 'travelPlan',
    name: 'Travel plan',
    fieldName: 'travelPlan',
    minWidth: 130,
    ...commonProps,
  },
  {
    key: 'taTotal',
    name: 'Amount',
    fieldName: 'taTotal',
    minWidth: 100,
    isPadded: true,
    styles: { cellTitle: { paddingRight: 32, justifyContent: 'flex-end' } },
    ...commonProps,
  },
];
