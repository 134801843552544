import { Separator, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { EmailDocumentFields } from 'common/components/DocumentPackageEmail/__generated__/EmailDocumentFields';
import { FormHookTextField } from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EmailCreateValues } from '../types';
import { EmailContactsForm } from './EmailContactsForm';
import { useStyles } from './index.styles';

interface CreateNewEmailProps {
  emailDocument: EmailDocumentFields | null | undefined;
}

export const CreateNewEmail: React.FC<CreateNewEmailProps> = ({
  emailDocument,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { setValue } = useFormContext<EmailCreateValues>();

  const { _currentSupplier, _isUserSenderAllowed, _isSupplierEmailAllowed } = {
    ...emailDocument,
  };

  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        verticalAlign="end"
        tokens={{ padding: '20px 20px 10px', childrenGap: 20 }}
      >
        <Stack className={styles.flex1}>
          <FormHookTextField label="Comment" name="memoArea" />
        </Stack>
        {_isUserSenderAllowed && (
          <Stack className={styles.flex1}>
            <FormHookCheckBox
              label="Include Your Email Account"
              name="isUserSenderIncluded"
            />
          </Stack>
        )}
      </Stack>
      <Separator />
      <Stack tokens={{ padding: '10px 20px 0px', childrenGap: 10 }}>
        <Text
          variant="xLarge"
          className={styles.sendToLabel}
        >
          Send To
        </Text>
        {
          _isSupplierEmailAllowed &&
          <Stack horizontal verticalAlign="end">
            <FormHookCheckBox name="isSupplierEmail" />
            {
              !!_currentSupplier &&
              <Text variant="large"
                className={clsx(styles.supplierName, commonStyles.colorThemePrimary)}
              >
                {_currentSupplier}
              </Text>
            }
          </Stack>
        }
      </Stack>
      <EmailContactsForm
        onContactsUpdated={(data) => {
          if (!!data.userIds?.length) {
            setValue('userId', data.userIds, {
              shouldDirty: true,
              shouldValidate: true,
            });
          } else
            setValue('userId', [], { shouldDirty: true, shouldValidate: true });
        }}
      />
      <Stack className={styles.flex1} tokens={{ padding: '0px 20px 50px' }}>
        <FormHookTextField
          label="Other Email Recipients"
          name="externalEmail"
        />
      </Stack>
    </Stack>
  );
};
