import { IButtonStyles, IconButton, Stack, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import { CalloutInfo } from './CalloutInfo';
import { Trip_trip } from '../../../__generated__/Trip';
const buttonStyles: Partial<IButtonStyles> = {
  icon: {
    color: '#FFCC00',
  },
};
interface WarningsCallout {
  tripData: Trip_trip | null | undefined;
}
export const WarningsCallout: React.FC<WarningsCallout> = ({ tripData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const calloutId = useId(`calloutInfoId`);
  if (!tripData) return null;
  let valuesExist: boolean = true;
  if (tripData) {
    const warningValuesArray = Object.keys(tripData)
      .filter((key) => key.startsWith('warning'))
      .map((item) => {
        const key = item as keyof Trip_trip;
        return tripData?.[key];
      });
    valuesExist = warningValuesArray.some((ele) => ele);
  }
  if (valuesExist)
    return (
      <Stack>
        <TooltipHost content={valuesExist ? 'Warnings' : ''}>
          <IconButton
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            iconProps={{ iconName: 'Info' }}
            id={calloutId}
            styles={buttonStyles}
          />
        </TooltipHost>

        <CalloutInfo
          isOpen={isOpen}
          targetID={`#${calloutId}`}
          tripData={tripData}
        />
      </Stack>
    );
  else return null;
};
