import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  resultItem: {
    width: 500,
    backgroundColor: theme.palette.neutralLighterAlt,
    textDecoration: 'none',
    margin: '0px -20px',
    padding: '6px 20px',
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
    ':active': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
  icon: {
    color: theme.palette.black,
  },
}));
