import { useReactiveVar } from '@apollo/client';
import { Icon, Stack, Text, TooltipHost } from '@fluentui/react';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { SectionProps } from '../..';
import { Trip_trip } from '../../__generated__/Trip';
import { TripCreateFormValues, TripItemFormValues } from '../../types';
import { setSelectedTraveler } from '../BasicForm/TravelerAutoComplete';
import { useStyles } from './index.styles';
import { getInitialValues, useTravelIcon } from './utils';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { getDefaultDistribution } from '../../utils';
import { setUserDefaults } from 'utility/cache/ui';

export const SectionToggle: React.FC<{
  inputsDisabled: boolean;
  tripItemsState: Map<string, SectionProps>;
  onTripItemsUpdate: (
    mapData: Map<string, SectionProps>,
    layoutAdded: string,
    adding: boolean
  ) => void;
  commonData: TravelerAuthorizationCommonData | undefined;
  tripData: Trip_trip | null | undefined;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
}> = ({
  tripItemsState,
  onTripItemsUpdate,
  commonData,
  inputsDisabled,
  travelAuthorizationData,
  tripData,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const { append } = useFieldArray({ name: 'tripItems' });
  const { control } = useFormContext<TripCreateFormValues>();
  const tripItemsValues = useWatch({ name: 'tripItems', control });
  const styles = useStyles();
  const { getIcon, getOutlineIcon } = useTravelIcon();
  const selectedTraveler = useReactiveVar(setSelectedTraveler);

  const tooltipContent = (item: SectionProps, added: boolean) => {
    return (
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
        {!added && <Icon iconName="add" />}
        <Text> {`${item.name}`}</Text>
      </Stack>
    );
  };

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 10 }}
      className={styles.container}
      horizontalAlign="end"
    >
      {commonData?.tripSections?.nodes.map((item, index) => {
        const tripItemAdded = tripItemsState.has(item.layoutType!);

        return (
          <Stack
            key={index}
            className={styles.tabContainer}
            onClick={() => {
              const adding = !tripItemsState.has(item.layoutType!);

              const tripDefaultValues = getInitialValues(
                item.layoutType,
                selectedTraveler,
                travelAuthorizationData,
                tripItemsValues
              );

              const values = getDefaultDistribution(
                item.id!,
                userDefaults,
                true
              );
              if (adding && !inputsDisabled) {
                const tripItemRow: TripItemFormValues = {
                  ...tripDefaultValues,
                  tripSectionItemId: item.tripSectionItems.nodes?.[0].id,
                  layout: item.layoutType,
                  tripSectionId: item.id,
                  currencyId:
                    tripData?.travelAuthorization?.currency?.id || null,
                  tripDistributions: [{ ...values }],
                };
                append(tripItemRow);
              }
              if (!inputsDisabled) {
                onTripItemsUpdate(
                  tripItemsState.set(item.layoutType!, { ...item }),
                  item.layoutType!,
                  adding
                );
              }
            }}
          >
            <TooltipHost content={tooltipContent(item, tripItemAdded)}>
              <Stack className={styles.toggleButton}>
                {tripItemAdded
                  ? getIcon(item.layoutType)
                  : getOutlineIcon(item.layoutType)}
              </Stack>
            </TooltipHost>
          </Stack>
        );
      })}
    </Stack>
  );
};
