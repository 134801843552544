import { IDropdownOption, Stack } from '@fluentui/react';
import { FormHookAmount, FormHookDropdown, FormHookTextField } from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { CompanyCurrencies_companyCurrencies_nodes } from 'common/graphql/__generated__/CompanyCurrencies';
import React, { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TravelPolicyValues } from '../types';
import { FareClasses } from './FareClasses';

interface BasicFormProps {
  currencies: CompanyCurrencies_companyCurrencies_nodes[] | null | undefined;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  currencies,
}) => {
  const { getValues, control, setValue, } = useFormContext<TravelPolicyValues>();
  const isContractual = useWatch({ control, name: "isContractual" });

  const fareClassOptions: IDropdownOption[] = [
    { key: 'isEconomyClassAllowed', text: 'Economy' },
    { key: 'isPremiumClassAllowed', text: 'Premium' },
    { key: 'isBusinessClassAllowed', text: 'Business' },
    { key: 'isFirstClassAllowed', text: 'First class' },
    { key: 'isCharterClassAllowed', text: 'Charter' },
  ];
  const fareClassKeys = [
    "isEconomyClassAllowed",
    "isPremiumClassAllowed",
    "isBusinessClassAllowed",
    "isFirstClassAllowed",
    "isCharterClassAllowed",
  ];
  const values = getValues()
  const selectedFareClass = fareClassKeys.find((fareClass) => {
    const key = fareClass as keyof TravelPolicyValues
    return values[key] === true
  });

  const currencyOptions: IDropdownOption[] =
    currencies?.map(ele => {
      const text = !!ele.name ? `${ele.isoCode} - ${ele.name}` : ele.isoCode || '';
      return { key: ele.id, text }
    }) || [];

  const resetCompanionValues = () => {
    if (!isContractual) {
      setValue("companionTickets", null)
      setValue("companionAmount", null)
    }
  }

  const resetCompanionValuesMemo = useCallback(resetCompanionValues, [isContractual])

  useEffect(() => {
    resetCompanionValuesMemo()
  }, [resetCompanionValuesMemo])

  return (
    <Stack
      tokens={{
        childrenGap: 15,
        padding: 20
      }}
    >
      <Stack
        horizontal
        tokens={{
          childrenGap: 20
        }}
        wrap
      >
        <Stack
          style={{
            flex: 1
          }}
        >
          <FormHookTextField
            name="description"
            label='Description'
            placeholder="Description"
            multiline
            rows={3}
          />
        </Stack>
        <Stack
          style={{
            flex: 1
          }}
        >
          <FormHookDropdown
            label="Currency"
            placeholder="Select"
            options={currencyOptions}
            name="currencyId"
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 30
        }}
        verticalAlign="center"
        wrap
      >
        <Stack
          style={{
            flex: 0.3
          }}
        >
          <FareClasses
            label="Fare Class"
            placeholder="Select"
            options={fareClassOptions}
            selectedFareClass={selectedFareClass}
          />
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: 10
          }}
          style={{
            flex: 0.7
          }}
        >
          <Stack
            style={{
              flex: 1
            }}
          >
            <FormHookCheckBox
              name="isDefaultCustomizable"
              label="Customize Default"
            />
          </Stack>
          <Stack
            style={{
              flex: 1
            }}
          >
            <FormHookCheckBox
              name="isContractual"
              label="Contractual"
            />
          </Stack>
          <Stack
            style={{
              flex: 1
            }}
          >
            <FormHookCheckBox
              name="isCompanion"
              label="Companion"
            />
          </Stack>
        </Stack>
      </Stack>
      {
        isContractual &&
        <Stack
          style={{
            width: "50%"
          }}
          tokens={{ childrenGap: 20 }}
          horizontal
          wrap
        >
          <Stack
            style={{ flex: 1 }}
          >
            <FormHookAmount
              label="Tickets"
              name="companionTickets"
              placeholder="Enter Tickets"
              fixedDecimalScale
              allowNegative={false}
              decimalScale={0}
              maxLength={3}
            />
          </Stack>
          <Stack
            style={{ flex: 1 }}
          >
            <FormHookAmount
              name="companionAmount"
              placeholder="Enter Amount"
              label="Amount"
              fixedDecimalScale
              allowNegative={false}
              decimalScale={2}
            />
          </Stack>
        </Stack>
      }
    </Stack>
  )
}