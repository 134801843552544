import { useApolloClient } from '@apollo/client';
import { Separator, Stack } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import { VIEW_DOC_MODAL_WIDTH } from 'common/components/DocumentList/DocumentViewModal';
import { secureRowLevelNode } from 'common/components/SecureRowLevel';
import { getWindowDimensions } from 'common/utils/columnUtilities';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { AttachForm } from './AttachForm';
import { UploadForm } from './UploadForm';
import { InvoiceUploadDocument_invoiceUploadDocument } from './UploadForm/__generated__/InvoiceUploadDocument';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { useFormContext } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { InvoiceDetails, InvoiceDetails_invoice, InvoiceDetailsVariables } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
import { TransactionSigningValues } from 'common/components/Modules/TransactionEdit/types';
import { AvailablePurchaseOrders, AvailablePurchaseOrdersVariables } from 'common/components/Modules/TransactionEdit/graphql/__generated__/AvailablePurchaseOrders';
const AVAILABLE_PURCHASE_ORDER = loader(
  '../../../../../../../../common/components/Modules/TransactionEdit/graphql/AvailablePurchaseOrders.graphql'
);
const GET_INVOICE_DETAILS = loader(
  '../../../../../../../../common/components/Modules/TransactionEdit/graphql/InvoiceDetails.graphql'
);
interface AttachSectionProps {
  secureRowLevel: secureRowLevelNode[];
  invoice: InvoiceDetails_invoice;
  onUpload?: (
    fileSelected: File,
    documentData: InvoiceUploadDocument_invoiceUploadDocument,
    toastId: string
  ) => void;
  onDocumentDelete?: () => void;
}
export const AttachSection: React.FC<AttachSectionProps> = ({
  secureRowLevel,
  invoice,
  onUpload,
}) => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const client = useApolloClient();

  const {
    formState: { isDirty },
  } = useFormContext<TransactionSigningValues>();

  const { width } = getWindowDimensions();
  const documentViewPosition = width
    ? width - (1200 + VIEW_DOC_MODAL_WIDTH)
    : 50;

  const fetchInvoiceDetails = async () => {
    if (invoice.id) {
      await client.query<InvoiceDetails, InvoiceDetailsVariables>({
        query: GET_INVOICE_DETAILS,
        variables: { id: invoice.id },
        fetchPolicy: 'network-only',
      });
    }
  };

  const fetchAvailablePOs = async () => {
    if (invoice.id) {
      await client.query<
        AvailablePurchaseOrders,
        AvailablePurchaseOrdersVariables
      >({
        query: AVAILABLE_PURCHASE_ORDER,
        variables: { invoiceId: invoice.id },
        fetchPolicy: 'network-only',
      });
    }
  };

  const refetchData = async () => {
    await fetchInvoiceDetails();
    await fetchAvailablePOs();
  };

  return (
    <Stack tokens={{ childrenGap: 5 }} className={styles.mainStack}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        <AttachForm invoice={invoice} onAttachSuccess={refetchData} />
        <Separator vertical />
        <UploadForm invoice={invoice} onUpload={onUpload} />
      </Stack>
      {invoice?.entityDocumentsByEntityId?.nodes.length! > 0 && (
        <Stack tokens={{ childrenGap: 10, padding: '0px 10px' }}>
          <DocumentList
            disableUpdate={isDirty}
            focusTrap={false}
            paddingLeft={documentViewPosition}
            isNew={false}
            data={invoice?.entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevel}
            onRemoveRow={() => {
              refetchData();
              addToast('Record deleted successfully', {
                appearance: 'success',
              });
            }}
            onUpdateDocument={fetchInvoiceDetails}
            columns={columns}
          />
        </Stack>
      )}
      <NoDataView
        show={!invoice?.entityDocumentsByEntityId?.nodes?.length}
        title="No Attachments"
      />
    </Stack>
  );
};
