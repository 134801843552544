import React from 'react';
import { DefaultButton, PrimaryButton, Stack, makeStyles } from '@fluentui/react';
const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0
  },
}));
interface ModalFooterProps {
  disableSubmit: boolean;
  onSubmitPress: () => void;
  onCancelPress: () => void;
}
export const ModalFooter: React.FC<ModalFooterProps> = ({
  disableSubmit,
  onSubmitPress,
  onCancelPress,
}) => {
  const styles = useStyles();
  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        tokens={{ childrenGap: 6, padding: 20 }}
      >
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <PrimaryButton
            text="Submit"
            onClick={onSubmitPress}
            disabled={disableSubmit}
          />
          <DefaultButton text="Cancel" onClick={onCancelPress} />
        </Stack>
      </Stack>
    </Stack>
  );
};
