import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';

interface LinkedTransactionProps {
  _linkedInvoiceId: string | undefined;
}
export const LinkedTransaction: React.FC<LinkedTransactionProps> = ({
  _linkedInvoiceId,
}) => {
  const [visibility, { toggle: toggleVisibility }] = useBoolean(false);
  if (_linkedInvoiceId)
    return (
      <Stack>
        <TooltipHost content={'Linked transaction'}>
          <IconButton
            iconProps={{ iconName: 'Link' }}
            onClick={toggleVisibility}
          />
        </TooltipHost>

        {visibility && (
          <TransactionModal
            isOpen
            entityId={_linkedInvoiceId}
            onDismiss={toggleVisibility}
          />
        )}
      </Stack>
    );
  else return null;
};
