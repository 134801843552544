import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  defaultButtonContainer: {
    marginBottom: 30
  },
  wrapper: {
    width: 500,
    height: 350,
  },
  ratingsFormContainer:{
    padding: 20,
    marginTop: "72px !important"
  },
  container: { marginTop: 10 }
}));