import { useMutation } from '@apollo/client';
import {
  UserSignatureUploadDocument,
  UserSignatureUploadDocumentVariables,
} from 'common/graphql/__generated__/UserSignatureUploadDocument';
import { TextToSignatureModal } from 'documents/personalPool/Signature/CreateSignature/TextToSignature';
import { loader } from 'graphql.macro';
import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import WebFont from 'webfontloader';
import { UserProfile } from '../__generated__/UserProfile';
const UPLOAD_SIGNATURE = loader(
  '../../../../../common/graphql/UserSignatureUploadDocument.graphql'
);

interface AddSignatureModalProps {
  isModalVisible: boolean;
  userDetails: UserProfile;
  onDismiss: () => void;
  onSignatureUpload: (
    data: UserSignatureUploadDocument,
    toastId: string,
    imageUrl: string
  ) => void;
}

export const AddSignatureModal: React.FC<AddSignatureModalProps> = ({
  isModalVisible,
  onDismiss,
  onSignatureUpload,
  userDetails,
}) => {
  const { addToast } = useToasts();
  const [uploadSignature] = useMutation<
    UserSignatureUploadDocument,
    UserSignatureUploadDocumentVariables
  >(UPLOAD_SIGNATURE, { errorPolicy: 'all' });

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Pushster',
          'Cookie',
          'Dancing Script: 700]',
          'Mrs Saint Delafield',
          'Yesteryear',
          'Pacifico',
          'Shadows Into Light',
          'Great Vibes',
          'Italianno',
          'Caveat',
          'Indie Flower',
          'Arizonia',
        ],
      },
    });
  }, []);

  const onSelect = async (imageUrl: string | null) => {
    // callNewToast();
    const { data: uploadSignatureData, errors } = await uploadSignature({
      variables: { userProfileId: userDetails.userProfile?.id },
    });

    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      const toastId = `file.name.${uploadSignatureData?.userSignatureUploadDocument.document._documentFileId}`;
      addToast(`Uploading Signature...`, {
        appearance: 'info',
        id: toastId,
        autoDismiss: false,
      });

      onSignatureUpload(uploadSignatureData!, toastId, imageUrl!);
    }
  };

  return (
    <>
      {isModalVisible && (
        <TextToSignatureModal
          name={userDetails.userProfile?.name!}
          onDismiss={onDismiss}
          onSignatureCreate={() => {}}
          onSelect={onSelect}
        />
      )}
    </>
  );
};
