import React from 'react';
import {
  DefaultButton,
  PrimaryButton,
  Stack,
  makeStyles,
} from '@fluentui/react';
import { useFormikContext } from 'formik';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
  },
}));
interface ModalFooterProps {
  isEdit: boolean;
  onSubmit: () => void;
  onCancelPress: () => void;
}
export const ModalFooter: React.FC<ModalFooterProps> = ({
  onCancelPress,
  isEdit,
  onSubmit,
}) => {
  const styles = useStyles();
  const { submitForm, errors, dirty, resetForm } = useFormikContext();
  const buttonTitle = isEdit ? 'Update' : 'Create';

  const isDisabled = isEdit
    ? Object.keys(errors).length > 0 || !dirty
    : Object.keys(errors).length > 0;

  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        tokens={{ childrenGap: 6, padding: '25px 25px 20px 25px' }}
      >
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <PrimaryButton
            text={buttonTitle}
            onClick={async () => {
              await submitForm();
              resetForm();
              onSubmit();
            }}
            disabled={isDisabled}
          />
          <DefaultButton
            text="Cancel"
            onClick={() => {
              onCancelPress();
              resetForm();
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
