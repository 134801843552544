import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.isInverted
      ? theme.palette.neutralTertiaryAlt
      : theme.palette.neutralTertiary,
    opacity: 0.8,
    fontWeight: FontWeights.semibold,
  },
  icon: {
    height: 120,
    width: '100%',
    fill: theme.isInverted
      ? theme.palette.neutralTertiaryAlt
      : theme.palette.neutralTertiary,
    opacity: 0.5,
  },
}));
