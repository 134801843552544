import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  footerStyle: {
    backgroundColor: theme.palette.neutralLighterAlt,
    boxShadow: `-1px -4px 13px rgba(0, 0, 0, 0.15)`,
  },
  headerContainer: {
    padding: '25px 25px 0px 25px',
  },
  diabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  payCycleStatus: {
    color: theme.palette.themePrimary,
    fontSize: FontSizes.size18,
  },
  footerActionsContainer: {
    justifyContent: 'space-between',
  },
  marginT10: {
    marginTop: 10,
  },
  approvalHistoryContainer: {
    margin: '20px 0',
    paddingLeft: 25,
    paddingRight: 25,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
  fileListContainer: {
    margin: 20,
  },
}));
