import { Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React from 'react';

interface HeadingProps {
  data: string | null | undefined;
}

export const Heading: React.FC<HeadingProps> = ({ data }) => {
  const commonStyles = useCommonStyles();

  if (data)
    return (
      <Stack>
        <Text
          className={clsx(
            commonStyles.semibold,
            commonStyles.colorThemePrimary
          )}
          variant="mediumPlus"
        >
          {data}
        </Text>
      </Stack>
    )
  else
    return null
}
