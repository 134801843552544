import {
  Icon,
  IconButton,
  IIconStyles,
  Label,
  Stack,
  StackItem,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { Traveler } from 'settings/account/Travelers/view/__generated__/Traveler';
import { TravelerModal } from '../TravelerModal';
import { TravelerAlias } from 'settings/account/Travelers/view/BasicForm/TravelerAlias';

interface TextFieldLabelProps {
  disabled: boolean | undefined;
  label: string | undefined;
  required?: boolean | undefined;
  traveler: Traveler | undefined;
}

export const TextFieldLabel: React.FC<TextFieldLabelProps> = ({
  disabled,
  label,
  required = false,
  traveler,
}) => {
  const theme = useTheme();
  const iconButtonId: string = useId('iconButton');
  const iconStyles: Partial<IIconStyles> = {
    root: {
      color: theme.palette.themePrimary,
      margin: '6px 0px 0px 6px',
    },
  };
  const [travelerDialogVisible, setTravelerDialogVisible] =
    useState<boolean>(false);

  return (
    <Stack horizontal verticalAlign="baseline">
      {travelerDialogVisible && (
        <TravelerModal
          setOpen={setTravelerDialogVisible}
          travelerData={traveler}
        />
      )}
      <Label disabled={disabled ? true : false} required={required}>
        {label}
      </Label>
      {traveler?.traveler?._isApproved && (
        <TooltipHost content={'Verified'}>
          <Icon iconName="CheckMark" styles={iconStyles} />
        </TooltipHost>
      )}
      {traveler?.traveler?.id && (
        <TooltipHost content={'Edit Traveler'}>
          <IconButton
            id={iconButtonId}
            iconProps={{ iconName: 'Edit' }}
            styles={iconStyles}
            onClick={() => {
              setTravelerDialogVisible(true);
            }}
          />
        </TooltipHost>
      )}
      {!!traveler?.traveler?.id && (
        <StackItem style={{ marginLeft: 'auto' }}>
          <TravelerAlias traveler={traveler?.traveler} isProtected />
        </StackItem>
      )}
    </Stack>
  );
};
