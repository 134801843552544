import { Icon, makeStyles } from "@fluentui/react";
import { IconState } from "common/types/globalTypes";
import React from "react";
import { ReactComponent as ApprovedIcon } from '../../../../assets/svgs/ApproveStatusIcons/Approved.svg';
import { ReactComponent as ApprovedPendingIcon } from '../../../../assets/svgs/ApproveStatusIcons/ApprovedPending.svg';
import { ReactComponent as CanceledIcon } from '../../../../assets/svgs/ApproveStatusIcons/Canceled.svg';
import { ReactComponent as DeclinedIcon } from '../../../../assets/svgs/ApproveStatusIcons/Declined.svg';
import { ReactComponent as DeclinedPendingIcon } from '../../../../assets/svgs/ApproveStatusIcons/DeclinedPending.svg';
import { ReactComponent as DraftIcon } from '../../../../assets/svgs/ApproveStatusIcons/Draft.svg';
import { ReactComponent as PendingIcon } from '../../../../assets/svgs/ApproveStatusIcons/Pending.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    fill: theme.palette.neutralPrimary,
  },
}));

interface GetStatusParam {
  status: string;
}

export const RenderIcon: React.FC<GetStatusParam> = ({
  status,
}) => {
  const styles = useStyles();

  switch (status) {
    case IconState.REJECTED:
      return <DeclinedIcon />;
    case IconState.VOIDED:
    case IconState.AMENDED:
    case IconState.CANCELED:
      return <CanceledIcon />;
    case IconState.REJECTED_PENDING:
      return <DeclinedPendingIcon />;
    case IconState.ENTERED:
      return <Icon className={styles.logo} iconName="Stamp" />;
    case IconState.APPROVED:
      return <ApprovedIcon />;
    case IconState.APPROVED_PENDING:
      return <ApprovedPendingIcon />;
    case IconState.PENDING:
      return <PendingIcon />;
    default:
      return <DraftIcon />;
  }
}