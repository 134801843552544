import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    height: '50vh',
  },
  loadingInnerContainer: {
    width: 300,
  },
  loadingText: {
    alignSelf: 'center',
  },
}));
