import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { DefaultButton, Spinner, TooltipHost } from '@fluentui/react';
import { FilterArrayType } from 'common/components/Filters';
import {
  ExportDocuments,
  ExportDocumentsVariables,
} from 'common/graphql/__generated__/ExportDocuments';
import {
  ExportDocumentsStatusType,
  SearchRequestInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { DocumentEntity } from '../../folder/list';
import { FilterVariableType, toFilterVariable } from '../utils';
import {
  ExportDocumentsStatusSub,
  ExportDocumentsStatusSubVariables,
} from './__generated__/ExportDocumentsStatusSub';
import {
  FileCabinetExportSearch,
  FileCabinetExportSearchVariables,
} from './__generated__/FileCabinetExportSearch';

const EXPORT_DOCUMENTS = loader(
  '../../../../common/graphql/ExportDocuments.graphql'
);
const EXPORT_DOCUMENTS_STATUS = loader('./ExportDocumentsStatusSub.graphql');
const FILE_CABINET_EXPORT_SEARCH = loader('./FileCabinetExportSearch.graphql');

interface MultipleDownloadProps {
  selectedList: Pick<DocumentEntity, '_documentFileId'>[];
  searchFilters: FilterArrayType[] | undefined;
  fileCabinetDocumentSearchRequest: SearchRequestInput | null;
  isSelectedAll: boolean;
}

export const MultipleDownload: React.FC<MultipleDownloadProps> = ({
  selectedList,
  searchFilters,
  fileCabinetDocumentSearchRequest,
  isSelectedAll,
}) => {
  const { addToast } = useToasts();
  const [documentIds, setDocumentIds] = useState<(string | null)[]>([]);
  const [disableExport, setDisableExport] = useState<boolean>(false);

  const client = useApolloClient();
  const [generateExportId, { loading: loadingExportId }] = useMutation<
    ExportDocuments,
    ExportDocumentsVariables
  >(EXPORT_DOCUMENTS, { errorPolicy: 'all' });

  const [getDocumentIdsArray] = useLazyQuery<
    FileCabinetExportSearch,
    FileCabinetExportSearchVariables
  >(FILE_CABINET_EXPORT_SEARCH, {
    onCompleted: (data) => {
      if (data?.fileCabinetExportSearch) onClick(data?.fileCabinetExportSearch);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const onClick = async (selectedDocumentIds: (string | null)[]) => {
    const { errors, data } = await generateExportId({
      variables: {
        input: {
          documentIds: selectedDocumentIds as string[],
        },
      },
    });
    if (!!errors?.length) {
      addToast(errors?.[0].message, { appearance: 'error' });
      setDisableExport(false);
    } else {
      if (!!data?.exportDocuments.exportDocumentId) {
        addToast(`Export process submitted.`, {
          appearance: 'info',
        });
        const exportDocumentId = data?.exportDocuments.exportDocumentId;
        const observer = client.subscribe<
          ExportDocumentsStatusSub,
          ExportDocumentsStatusSubVariables
        >({
          query: EXPORT_DOCUMENTS_STATUS,
          variables: {
            exportDocumentId: exportDocumentId,
          },
        });
        const subscription = observer.subscribe(({ data, errors }) => {
          if (errors) {
            addToast(
              'Errors received while Subscribing to export document status',
              {
                appearance: 'error',
              }
            );
            setDisableExport(false);
          } else {
            const { status } = {
              ...data?.exportDocumentsStatus,
            };
            if (status === ExportDocumentsStatusType.SUCCESS) {
              addToast(
                'Export file successfully created in the export folder',
                { appearance: 'success' }
              );
            }
            if (status === ExportDocumentsStatusType.FAILURE) {
              setDisableExport(false);
              addToast(
                'Error occurred while creating export file in the export folder',
                {
                  appearance: 'error',
                }
              );
            }
          }
          subscription.unsubscribe();
        });
      }
    }
  };

  useEffect(() => {
    setDisableExport(false);
    const newDocumentIds = selectedList.map((ele) => ele._documentFileId!);
    setDocumentIds(newDocumentIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  return (
    <>
      <TooltipHost content="Download">
        {loadingExportId ? (
          <Spinner />
        ) : (
          <DefaultButton
            iconProps={{ iconName: 'Download' }}
            disabled={disableExport}
            onClick={() => {
              setDisableExport(true);
              if (isSelectedAll) {
                const filterVariable: FilterVariableType = toFilterVariable(
                  searchFilters ?? []
                );
                getDocumentIdsArray({
                  variables: {
                    fileCabinetDocumentSearchRequest,
                    ...filterVariable,
                  },
                });
              } else {
                onClick(documentIds);
              }
            }}
            text="Export"
          />
        )}
      </TooltipHost>
    </>
  );
};
