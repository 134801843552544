import { Stack } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import { BatchTransaction_batchTransaction } from 'postingTracker/batchEdit/batch/view/__generated__/BatchTransaction';
import React from 'react';
import { columns } from './coloumn.data';
import { useStyles } from './index.styles';
// import {} from './col';

interface AttachProps {
  batchTransaction: BatchTransaction_batchTransaction;
}

export const Attach: React.FC<AttachProps> = ({ batchTransaction }) => {
  const styles = useStyles();

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.mainStack}>
      {batchTransaction?.entityDocumentsByEntityId?.nodes.length! > 0 && (
        <Stack
          tokens={{
            childrenGap: 10,
          }}
        >
          <DocumentList
            isNew={false}
            data={batchTransaction?.entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            readOnly
            columns={columns}
          />
        </Stack>
      )}
      <Stack tokens={{ padding: '0px 25px' }}>
        <NoDataView
          show={batchTransaction?.entityDocumentsByEntityId.totalCount === 0}
          title="No Attachments"
        />
      </Stack>
    </Stack>
  );
};
