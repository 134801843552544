import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  detailsContainer: {
    marginTop: '10px !important',
  },
  flex50: {
    flex: 0.5,
  },
  differenceText: {
    color:theme.palette.themePrimary,
    marginLeft: "6px !important",
    marginTop: 5,
  },
}));
