import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    fileTextCell: {
      textAlign: 'left',
      fontSize: '15px',
    },
    textFieldStack: {
      marginTop: 15,
      marginBottom: 5,
    },
    textFieldUpperStack: {
      width: 500,
    },
    progressIndicatorStack: {
      width: '100%',
      height: 10,
    },
    footerStack: {
      alignItems: 'self-start',
      paddingTop: 40,
    },
    channelUpperStack: {
      paddingTop: 25,
    },
    msgForNonSharableColor: {
      color: 'red',
    },
    dragDropViewUploadModal: {
      padding: 10,
      height: 100,
      border: '1px dashed #3C3C3C',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      boxSizing: 'border-box',
    },
    topIconsStack: {
      paddingRight: 25,
    },
    pivotStack: {
      paddingLeft: 25,
      paddingBottom: 10,
    },
    scrollablePaneContactList: {
      marginTop: 80,
      marginBottom: 30,
    },
    contactListUpperStack: {
      height: '560px',
      overflow: 'auto',
      overflowX: 'none',
    },
    headerWrapper: {
      height: 40,
      alignItems: 'center',
    },
    messageBarContainer:{ 
      marginTop: 20 
    }
  };
});
