import React, { useCallback, useEffect, useState } from 'react';
import { GetPaymentDetails_payment } from '../__generated__/GetPaymentDetails';
import { Stack, Text, makeStyles } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useToasts } from 'react-toast-notifications';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { DocumentPackage } from 'common/components/DocumentPackage';
import { loader } from 'graphql.macro';
import {
  PaymentEmailCreate,
  PaymentEmailCreateVariables,
} from './__generated__/PaymentEmailCreate';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import { DocumentPackageEmail } from 'common/components/DocumentPackageEmail/DocumentPackageEmail';
import {
  PaymentEmailStatus,
  PaymentEmailStatusVariables,
} from './__generated__/PaymentEmailStatus';
import { IconState } from 'common/types/globalTypes';
const PAYMENT_ORDER_EMAIL_CREATE = loader('./PaymentEmailCreate.graphql');
const PAYMENT = loader('../GetPaymentDetails.graphql');
const FETCH_EMAIL_STATUS = loader('./PaymentEmailStatus.graphql');

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
}));

interface HeaderProps {
  isNew: boolean;
  dataLoading: boolean;
  dirty: boolean;
  isSubmitting: boolean;
  payment: GetPaymentDetails_payment | null | undefined;
}

export const Header: React.FC<HeaderProps> = ({
  isNew,
  dataLoading,
  dirty,
  isSubmitting,
  payment,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();

  const [paymentEmailCreate, { loading, data }] = useMutation<
    PaymentEmailCreate,
    PaymentEmailCreateVariables
  >(PAYMENT_ORDER_EMAIL_CREATE, { errorPolicy: 'all' });

  const panelHeading = isNew ? 'Create Payment' : 'Edit Payment';
  const {
    id,
    _documentPackageId,
    transactionType,
    _emailDocument,
    emailDocumentsByEntityId,
  } = {
    ...payment,
  };

  const _onSubmitValues = async (values: EmailCreateValues) => {
    if (id) {
      const { errors } = await paymentEmailCreate({
        variables: {
          input: {
            entityId: id!,
            ...values,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: PAYMENT,
            variables: {
              id,
            },
          },
        ],
      });
      if (!!errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: id!,
          },
        });
      }
    }
  };
  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    PaymentEmailStatus,
    PaymentEmailStatusVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      if (data?.payment?._emailDocument?._sendIconState === IconState.SENT) {
        stopPolling?.();
        setShowEmailStatus(true);
      }
    },
  });
  const isSuccessful = !!data?.paymentEmailCreate;
  
  const fetchEmailStatusMemo = useCallback(() => {
    fetchEmailStatus({
      variables: {
        id: id!,
      },
    });
  }, [fetchEmailStatus, id]);

  useEffect(() => {
    if (_emailDocument?._sendIconState === IconState.PENDING) {
      fetchEmailStatusMemo();
    }
  }, [_emailDocument, fetchEmailStatusMemo]);

  return (
    <Stack className={styles.container}>
      <Stack grow horizontal>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {!isNew && (
              <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
                {transactionType?.transactionType}
              </Text>
            )}
            <UnsavedIndicator
              visible={!isNew && !dataLoading && dirty && !isSubmitting}
            />
            {_documentPackageId && (
              <DocumentPackage documentPackageId={_documentPackageId} />
            )}
            <DocumentPackageEmail
              emailDocument={_emailDocument}
              emailDocumentsByEntityId={emailDocumentsByEntityId?.nodes || []}
              isSuccessful={isSuccessful}
              loading={loading}
              onSubmitValues={_onSubmitValues}
              dataLoading={dataLoading}
              showEmailStatus={showEmailStatus}
              setShowEmailStatus={setShowEmailStatus}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
