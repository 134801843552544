import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  shareIcon: {
    color: theme.palette.themePrimary,
    fontSize: 20,
  },
  privateIcon: {
    color: theme.palette.redDark,
    fontSize: 20,
  },
  toggle: {
    marginBottom: 0,
  },
}));
