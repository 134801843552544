import { useLazyQuery, useMutation } from '@apollo/client';
import { AnimationClassNames, Modal, Stack } from '@fluentui/react';
import { CardAccountsOrderBy } from 'common/types/globalTypes';
import { Formik } from 'formik';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { CardAccounts, CardAccountsVariables } from '../../list/__generated__/CardAccounts';
import { CardCompaniesList, CardCompanyRowItem } from '../list';
import { CARD_COMPANY_INITIAL_VALUES } from './constant';
import { ModalFooter } from './Footer';
import { FormView } from './Form';
import { ModalHeader } from './Header';
import { useStyles } from './index.styles';
import { CardCompanyValues } from './types';
import { validationSchema } from './validations';
import {
  CompanyCardCompanyCreate, CompanyCardCompanyCreateVariables
} from './__generated__/CompanyCardCompanyCreate';
import {
  CompanyCardCompanyUpdate,
  CompanyCardCompanyUpdateVariables
} from './__generated__/CompanyCardCompanyUpdate';
const COMPANY_CARD_CREATE = loader('./CompanyCardCompanyCreate.graphql');
const COMPANY_CARD_UPDATE = loader('./CompanyCardCompanyUpdate.graphql');
const CARD_ACCOUNTS = loader('../../list/CardAccounts.graphql');
export interface CardCompaniesModalProps {
  onClose: () => void;
}

export const CardCompaniesModal: React.FC<CardCompaniesModalProps> = ({
  onClose,
}) => {
  const [listMode, setListMode] = useState(true);
  const [selectedRow, setSelectedRow] = useState<CardCompanyRowItem | null>(
    null
  );
  const styles = useStyles();
  const onCreate = () => setListMode((prevState) => !prevState);
  const { addToast } = useToasts();

  const [createCardCompany] = useMutation<
    CompanyCardCompanyCreate,
    CompanyCardCompanyCreateVariables
  >(COMPANY_CARD_CREATE, {
    errorPolicy: 'all',
  });

  const [updateCompanyCard] = useMutation<
    CompanyCardCompanyUpdate,
    CompanyCardCompanyUpdateVariables
  >(COMPANY_CARD_UPDATE, {
    errorPolicy: 'all',
  });

  const [getCardAccounts] = useLazyQuery<
    CardAccounts, CardAccountsVariables
  >(
    CARD_ACCOUNTS,
    {
      variables: {
        orderBy: [CardAccountsOrderBy.NAME_ASC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  )

  // Animations
  const SlideLeftIn = AnimationClassNames.slideLeftIn400;
  const SlideLeftOut = AnimationClassNames.slideLeftOut400;
  const slideRightIn = AnimationClassNames.slideRightIn400;
  const slideRightOut = AnimationClassNames.slideRightOut400;

  const onEdit = (item: CardCompanyRowItem) => {
    setListMode(false);
    setSelectedRow(item);
  };

  const isEdit = selectedRow !== null;

  let initialValues: CardCompanyValues = isEdit
    ? {
      apiKey: selectedRow?.apiKey!,
      cardCompanyId: selectedRow?.cardCompanyId!,
      description: selectedRow?.cardAccounts?.nodes[0]?.description || null,
      businessUnitId: selectedRow?.cardAccounts?.nodes[0]?.businessUnitId || null,
      isManualProcess: selectedRow?._isManualProcess || false,
    }
    : CARD_COMPANY_INITIAL_VALUES;

  const onCancelPress = () => {
    setListMode(true);
    setSelectedRow(null);
  };

  const handleSubmit = async (values: CardCompanyValues) => {
    if (isEdit) {
      const { errors } = await updateCompanyCard({
        variables: {
          input: {
            id: selectedRow?.id!,
            rowTimestamp: selectedRow?._rowTimestamp!,
            companyCardCompanyPatch: {
              apiKey: values.apiKey!,
              cardCompanyId: values.cardCompanyId!,
            },
          },
        },
      });
      if (!errors) {
        addToast('Company Card Updated Successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    } else {
      const { errors } = await createCardCompany({
        variables: {
          input: {
            companyCardCompany: {
              cardCompanyId: values.cardCompanyId!,
              apiKey: values.apiKey
            },
            cardAccount: {
              description: values.description,
              businessUnitId: values.businessUnitId,
            },
          },
        },
      });
      if (!errors) {
        addToast('Company Card Created Successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };

  return (
    <Formik<CardCompanyValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Modal isOpen={true} isBlocking={true}>
            <Stack className={styles.container}>
              <ModalHeader
                isEdit={isEdit}
                listMode={listMode}
                onCreate={onCreate}
                onBack={() => {
                  setSelectedRow(null);
                  setListMode(true);
                }}
                onClose={() => {
                  onClose();
                  setSelectedRow(null);
                }}
              />

              {listMode && (
                <Stack className={!listMode ? SlideLeftOut : slideRightIn}>
                  <CardCompaniesList onEdit={onEdit} />
                </Stack>
              )}
              {!listMode && (
                <Stack className={!listMode ? SlideLeftIn : slideRightOut}>
                  <FormView item={selectedRow} isEdit={isEdit} />
                </Stack>
              )}

              {!listMode && (
                <ModalFooter
                  isEdit={isEdit}
                  onSubmit={() => {
                    getCardAccounts();
                    setListMode(true);
                  }}
                  onCancelPress={onCancelPress}
                />
              )}
            </Stack>
          </Modal>
        );
      }}
    </Formik>
  );
};
