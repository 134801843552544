import React from 'react';
import {
  Text,
  ITextProps,
  Stack,
  Separator,
  ISeparatorStyles,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
interface CalloutTextProps extends ITextProps {
  label?: string | null;
  text: string | null | undefined;
  hasLabel?: boolean | null;
  hasSeparator?: boolean;
  alignRight?: boolean;
  isAmountValue?: boolean;
  primary?: boolean;
  vertical?: boolean;
  boldTitle?: boolean;
}
export const CalloutText: React.FC<CalloutTextProps> = ({
  label,
  text,
  hasLabel = true,
  variant,
  className,
  hasSeparator,
  alignRight,
  isAmountValue = false,
  primary,
  vertical,
  boldTitle,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const separatorStyles: Partial<ISeparatorStyles> = {
    root: {
      padding: 0,
    },
  };

  if (!text) return null;

  return (
    <Stack>
      <Stack
        horizontal={!vertical}
        grow
        className={clsx(alignRight && styles.spaceBetween)}
      >
        {hasLabel && (
          <Stack className={styles.label}>
            <Text
              variant={variant}
              className={clsx(
                className,
                primary ? commonStyles.colorThemePrimary : '',
                boldTitle ? commonStyles.bold : ''
              )}
            >{`${label}:`}</Text>
          </Stack>
        )}
        <Stack>
          {isAmountValue ? (
            <AmountTextView
              className={primary ? commonStyles.colorThemePrimary : ''}
              value={text}
            />
          ) : (
            <Text
              className={primary ? commonStyles.colorThemePrimary : ''}
              variant={variant}
            >
              {text}
            </Text>
          )}
        </Stack>
      </Stack>
      {hasSeparator && <Separator styles={separatorStyles} />}
    </Stack>
  );
};
