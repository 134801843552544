import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  messageRowMainContainer: {
    alignItems: 'center',
    padding: '10px 14px'
  },
  respondButtonContainer: {
    flex: 1,
    marginTop: 15,
    marginBottom: 15,
    alignItems: 'flex-end',
  },
  respondButton: {
    height: 20,
    color: '#1976D2',
  },
  actionContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  colorPrimary: {
    color: theme.palette.themePrimary,
  },
  redirectIcon: {
    height: 20,
    color: '#1976D2',
  },
  moreLink: {
    marginTop: 20,
    marginLeft: 14,
    color: '#157ADD',
    fontSize: FontSizes.size14,
  },
  messageReadBgColor: {
    backgroundColor: theme.palette.white,
  },
  messageUnreadBgColor: {
    backgroundColor: theme.palette.neutralLight,
  },
}));
