import { Stack } from '@fluentui/react';
import { FormHookTextField } from 'common/components/FormHooksFields';
import React from 'react';
import { useStyles } from './index.styles';

export const BasicForm: React.FC = () => {
  const styles = useStyles();
  return (
    <Stack
      tokens={{
        padding: 20,
        childrenGap: 10,
      }}
      className={styles.container}
    >
      <Stack
        horizontal
        tokens={{
          childrenGap: 15,
        }}
      >
        <Stack
          className={styles.fieldContainer}
          tokens={{
            childrenGap: 15,
          }}
        >
          <FormHookTextField
            label="Title"
            name="userOccupationTitle"
            required
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
