import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { dateConvertions } from 'common/utils/dateFormats';
import { TravelAuthorization } from './__generated__/TravelAuthorization';
import { TRAVEL_AUTHORIZATION_INITIAL_VALUES } from './constant';
import { TravelAuthorizationValues } from './interface';

interface DefaultValueProps {
  isNew: boolean;
  travelAuthorizationData: TravelAuthorization | undefined;
  userDefaultsData: UserDefaults_userDefaults_nodes[];
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, travelAuthorizationData, userDefaultsData } = { ...props };
  const { businessUnitId, currencyId, departmentId, businessUnit } = {
    ...userDefaultsData?.[0],
  };
  const { supplier, ...travelAuthorizationOrderDetails } = {
    ...travelAuthorizationData?.travelAuthorization,
  };

  if (travelAuthorizationData?.travelAuthorization && !isNew) {
    const defaultValues = {
      ...travelAuthorizationData?.travelAuthorization,
      travelAuthorizationDate:
        travelAuthorizationOrderDetails.travelAuthorizationDate
          ? dateConvertions(
              travelAuthorizationOrderDetails.travelAuthorizationDate
            )
          : null,
      _isCorporateWorkgroupRequired:
        !!travelAuthorizationOrderDetails.businessUnit
          ?._isCorporateWorkgroupRequired,
    };

    return defaultValues;
  } else {
    const defaultValues: TravelAuthorizationValues = {
      ...TRAVEL_AUTHORIZATION_INITIAL_VALUES,
      businessUnitId: businessUnitId || null,
      currencyId: currencyId || null,
      departmentId: departmentId || null,
      _isCorporateWorkgroupRequired:
        businessUnit?._isCorporateWorkgroupRequired || null,
    };

    return defaultValues as TravelAuthorizationValues;
  }
};
