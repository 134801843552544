import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  mainStack: {
    paddingTop: 24,
  },
  footerStack: {
    alignItems: 'self-start',
    paddingTop: 40,
  },
  iconSize: {
    fontSize: 14,
  },
  contactListUpperStack: {
    height: '560px',
    overflow: 'scroll',
    overflowX: 'clip',
  },
}));
