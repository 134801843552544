import React from 'react';
import {
  DetailsList,
  SelectionMode,
  IColumn,
  Stack,
  Text,
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsFooterProps,
  DetailsRow,
  useTheme,
  IDetailsColumnStyles,
  IDetailsRowProps,
  IDetailsRowStyles,
} from '@fluentui/react';
import {
  DocumentFileDistributionsByDocumentFileId_documentFileDistributionsByDocumentFileId,
  DocumentFileDistributionsByDocumentFileId_documentFileDistributionsByDocumentFileId_nodes,
} from 'common/graphql/__generated__/DocumentFileDistributionsByDocumentFileId';
import { useStyles } from './index.styles';
type AccountingDetailsData =
  DocumentFileDistributionsByDocumentFileId_documentFileDistributionsByDocumentFileId;
type DocumentFileDistributionsByDocumentFileId_nodes =
  DocumentFileDistributionsByDocumentFileId_documentFileDistributionsByDocumentFileId_nodes;
interface AccountingDetailsProps {
  data: AccountingDetailsData;
}
export const AccountingDetails: React.FC<AccountingDetailsProps> = ({
  data,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const customStyles: Partial<IDetailsRowStyles> = {
    cell: {
      backgroundColor: theme.palette.neutralLighterAlt,
    },
  };
  const onRenderItemColumn = (
    item: DocumentFileDistributionsByDocumentFileId_nodes | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof DocumentFileDistributionsByDocumentFileId_nodes
      ] as string;
      switch (column?.key) {
        case 'distributionAmount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.cellAlignRight}>
                {item.distributionAmount}
              </Text>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };
  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    if (!props || !defaultRender) {
      return null;
    }
    return defaultRender({
      ...props,
      styles: {
        root: {
          paddingTop: 0,
        },
      },
    });
  };
  const renderFooterColumn = (
    _item?: AccountingDetailsData,
    _index?: number,
    column?: IColumn
  ) => {
    if (
      column?.key === 'distributionAmount' &&
      data.aggregates?.sum?.distributionAmount
    )
      return (
        <Text className={styles.totalAmount}>
          {data.aggregates?.sum?.distributionAmount
            ? Number(data.aggregates?.sum?.distributionAmount).toFixed(2)
            : '-'}
        </Text>
      );

    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !data.aggregates?.sum?.distributionAmount) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
        styles={{
          root: {
            borderTop: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
            borderBottom: 'none',
            backgroundColor: theme.palette.neutralLighterAlt,
          },
        }}
      />
    );
  };
  const onRenderRow: IRenderFunction<IDetailsRowProps> = (props) => {
    if (!props) {
      return null;
    }
    return <DetailsRow {...props} styles={customStyles} />;
  };
  const commonHeaderStyle: Partial<IDetailsColumnStyles> = {
    cellTitle: {
      backgroundColor: theme.palette.neutralLighterAlt,
    },
  };
  const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
    cellTitle: {
      justifyContent: 'flex-end',
      backgroundColor: theme.palette.neutralLighterAlt,
      paddingRight: 20,
    },
  };
  const columns: IColumn[] = [
    {
      key: 'accountReference',
      name: 'Account',
      fieldName: 'accountReference',
      minWidth: 120,
      maxWidth: 120,
      styles: commonHeaderStyle,
    },
    {
      key: 'additionalDescription',
      name: 'Description',
      fieldName: 'additionalDescription',
      minWidth: 180,
      maxWidth: 180,
      styles: commonHeaderStyle,
    },
    {
      key: 'distributionAmount',
      name: 'Amount',
      fieldName: 'distributionAmount',
      minWidth: 120,
      maxWidth: 120,
      styles: rightAlignHeaderStyle,
    },
  ];

  return (
    <Stack style={{ backgroundColor: theme.palette.neutralLighterAlt }}>
      <Text variant="large" className={styles.heading}>
        Accounting Details
      </Text>
      <Stack className={styles.detailsListContainer}>
        <DetailsList
          onRenderDetailsHeader={onRenderDetailsHeader}
          onRenderDetailsFooter={renderFooter}
          setKey="set"
          compact
          selectionMode={SelectionMode.none}
          items={data.nodes}
          columns={columns}
          onRenderItemColumn={onRenderItemColumn}
          onRenderRow={onRenderRow}
        />
      </Stack>
    </Stack>
  );
};
