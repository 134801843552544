import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    supplierId: Yup.string().required('Required').nullable(),
    purchaseOrderTypeId: Yup.string().required('Required').nullable(),
    purchaseOrderDate: Yup.string().required('Required').nullable(),  
    currencyId: Yup.string().required('Required').nullable(),
    departmentId: Yup.string().required('Required').nullable(),
    businessUnitId: Yup.string().required('Required').nullable(), 
  });