import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  Stack,
  Text,
  useTheme,
} from '@fluentui/react';
import { InvoiceRow } from 'ap/signing/types';
import { useCommonStyles } from 'common/styles';
import React from 'react';

import { useQuery } from '@apollo/client';
import { ExportCsvButton } from 'ap/signing/components/ExportCsv';
import {
  InvoicesSigningSearch,
  InvoicesSigningSearchVariables,
} from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoicesSigningSearch';
import { TransactionSigningStampPrivileges } from 'common/components/Modules/TransactionEdit/graphql/__generated__/TransactionSigningStampPrivileges';
import { loader } from 'graphql.macro';

const STAMP_PRIVILEGES_DATA = loader(
  '../../../../../common/components/Modules/TransactionEdit/graphql/TransactionSigningStampPrivileges.graphql'
);

interface HeaderProps {
  selectedRows: InvoiceRow[];
  invoicesData: InvoicesSigningSearch | undefined;
  variables: InvoicesSigningSearchVariables | undefined;

  refetch: () => void;
  onStamp: () => void;
  defaultDataView: any;
  onDataViewChange: (key: string | number | undefined) => void;
  isHistorySearch: boolean;
}
export const Header: React.FC<HeaderProps> = ({
  selectedRows,
  invoicesData,
  refetch,
  onStamp,
  defaultDataView,
  variables,
  onDataViewChange,
  isHistorySearch,
}) => {
  const commonStyles = useCommonStyles();
  const theme = useTheme();

  const { data: stampPrivilegesData } =
    useQuery<TransactionSigningStampPrivileges>(STAMP_PRIVILEGES_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const stampButtonVisible =
    stampPrivilegesData?.transactionSigningStampPrivileges?.isStampingAllowed &&
    !isHistorySearch;

  const dropdownStyles: Partial<IDropdownStyles> = {
    title: {
      border: 0,
      outline: 0,
      borderBottom: `1px solid ${theme.semanticColors.inputBorder}`,
    },
    dropdown: {
      width: 140,
    },
  };
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      className={commonStyles.listTitleContainer}
    >
      <Text variant="xLarge">Accounting Entry</Text>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Dropdown
          placeholder="Select"
          selectedKey={defaultDataView}
          options={defaultDataViewOptions}
          onChange={(_, option) => {
            onDataViewChange(option?.key);
          }}
          styles={dropdownStyles}
        />

        {stampButtonVisible && (
          <DefaultButton
            text="Stamp"
            onClick={onStamp}
            iconProps={{
              iconName: 'StampSmall',
              styles: {
                root: {
                  fill: theme.palette.black,
                },
              },
            }}
          />
        )}
        {selectedRows.length > 0 && (
          <Stack style={{ alignItems: 'flex-end' }}>
            <ExportCsvButton selectedRows={selectedRows} refetch={refetch} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export enum DefaultDataViewEnum {
  all_Transactions = 'ALL_TRANSACTIONS',
  my_Transactions = 'MY_TRANSACTIONS',
}

const defaultDataViewOptions: IDropdownOption[] = [
  {
    key: DefaultDataViewEnum.my_Transactions,
    text: 'My Transactions',
  },
  {
    key: DefaultDataViewEnum.all_Transactions,
    text: 'All Transactions',
  },
];
