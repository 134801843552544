import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  sectionMainContainer: {
    padding: 20,
  },
  borderStyle: {
    // borderColor: theme.palette.neutralLight,
    borderColor: `${theme.palette.neutralTertiary}55`,
    borderStyle: 'solid',
    borderWidth: 2,
    borderBottomWidth: 0,
    width: 150,
    padding: 5,
    height: 50,
  },
  valueBorderStyle: {
    // borderColor: theme.palette.neutralLight,
    borderColor: `${theme.palette.neutralTertiary}55`,
    borderStyle: 'solid',
    borderWidth: 2,
    width: 150,
    padding: 5,
  },
  rightBorderWidth: {
    borderRightWidth: 0,
  },
  bottomBorderWidth: {
    borderBottomWidth: 0,
  },

  marginTop10: {
    paddingTop: 10,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignEnd: {
    textAlign: 'end',
  },
  textAlignStart: {
    textAlign: 'start',
  },
}));
