import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  onrenderColumnStack: {
    height: 25,
  },
  contentColumnAlignCenter: {
    display: 'block',
    textAlign: 'center',
  },
  rowBaseStyle: { borderLeft: `5px solid transparent` },
}));
