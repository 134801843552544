import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    alignSelf: 'end',
    padding: '0px 25px 0px 0px',
  },
  root: { flex: 0.6, paddingLeft: 25 },
  contactsGroupWrapper: {
    height: 550,
    overflowY: 'scroll',
    padding: '0 25px 15px 0',
  },
}));
