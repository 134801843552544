import { ColumnActionsMode } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

const useColumns = () => {
  const columns: ColumnData[] = [
    {
      key: 'approverName',
      name: 'User',
      fieldName: 'approverName',
      minWidth: 200,
      maxWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
    {
      key: 'actionDate',
      name: 'Date',
      fieldName: 'actionDate',
      minWidth: 200,
      maxWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },

    {
      key: 'comments',
      name: 'Comments',
      fieldName: 'comments',
      minWidth: 200,
      maxWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
    {
      key: 'statusType',
      name: 'Status',
      fieldName: 'statusType',
      minWidth: 200,
      maxWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: 'action',
      minWidth: 250,
      maxWidth: 250,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
  ];
  return { columns };
};
export { useColumns };
