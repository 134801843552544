import { useReactiveVar } from '@apollo/client';
import { TransactionLayout } from 'common/types/globalTypes';
import { setUserDefaults } from 'utility/cache/ui';

function useGetScreenWidth() {
  const userDefaults = useReactiveVar(setUserDefaults);
  const isSm =
    userDefaults?.listviewSize === TransactionLayout.LISTVIEW_SIZE_SMALL;
  const isMd =
    userDefaults?.listviewSize === TransactionLayout.LISTVIEW_SIZE_MEDIUM;
  const isLg =
    userDefaults?.listviewSize === TransactionLayout.LISTVIEW_SIZE_LARGE;
  return {
    isSm,
    isMd,
    isLg,
  };
}

export default useGetScreenWidth;
