import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    callout: {
      maxHeight: 300,
      flexGrow: 1,
    },
    // calloutContainer: {
    //   maxHeight: 400,
    //   padding: 10,
    //   width: 400,
    //   backgroundColor: theme.palette.neutralLighterAlt,
    // },
    itemContainer: {
      overflow: 'hidden',
      padding: 10,
      backgroundColor: theme.palette.neutralLighterAlt,
    },
    resultItem: {
      cursor: 'pointer',
      textDecoration: 'none',
      margin: '0px -20px',
      padding: '6px 20px',
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
      ':active': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    spinnerContainer: {
      width: 12,
    },
  };
});
