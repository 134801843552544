import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  groupHeader: {
    cursor: 'pointer',
  },
  container: {
    marginBottom: 100,
  },
  documentActionConatiner: {
    fontSize: FontSizes.medium,
    color: theme.palette.neutralSecondary,
  },
  progressIndicatorWrapper: {
    top: -10,
  },
  onrenderColumnStack: {
    height: 25,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  contentColumnAlignCenter: {
    display: 'block',
    textAlign: 'center',
  },
  textAlignEnd: {
    textAlign: 'end',
  },
  columnHeight: {
    height: 25,
  },
}));
