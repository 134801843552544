import React from 'react';
import {
  Pivot,
  PivotItem,
  Callout,
  Stack,
  Text,
  PrimaryButton,
  Toggle,
  useTheme,
} from '@fluentui/react';
import { DefaultsView } from './Defaults';
import { ChartsView } from './Charts';
import { Form, Formik } from 'formik';
import { UserDefaultFormValues } from './types';
import { useMutation, useQuery } from '@apollo/client';
import { UserDefaults } from './__generated__/UserDefaults';
import { loader } from 'graphql.macro';
import { USER_DEFAULT_INITIAL_VALUES } from './constants';
import { UserDefaultDropdownData } from './__generated__/UserDefaultDropdownData';
import { UserCharts } from './__generated__/UserCharts';
import { useStyles } from './index.styles';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import { darkMode, setUserDefaults } from 'utility/cache/ui';
import { storedDarkMode } from 'utility/localStorage';
import { Signature } from './Signature';
import { useToasts } from 'react-toast-notifications';
import { TransactionLayout, UserChartInput } from 'common/types/globalTypes';
import { DASHBOARD_CHARTS_QUERY } from 'dashboard';
import { ShimmerView } from './ShimmerView';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import {
  UserDefaultUpdate,
  UserDefaultUpdateVariables,
} from './__generated__/UserDefaultUpdate';

const USER_DEFAULTS = loader('./UserDefaults.graphql');
const USER_DEFAULTS_DROPDOWN_DATA = loader('./UserDefaultDropdownData.graphql');
const USER_CHARTS = loader('./UserCharts.graphql');
const UPDATE_USER_DEFAULTS = loader('./UserDefaultUpdate.graphql');
interface PreferencesProps {
  onDismiss?: () => void;
  isOpen: boolean;
  calloutId: string;
}

export const Preferences: React.FC<PreferencesProps> = ({
  isOpen,
  onDismiss,
  calloutId,
}) => {
  const [selectedKey, setSelectedKey] = React.useState('Defaults');
  const theme = useTheme();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();

  const [updateUserDefaults, { loading: updateLoading }] = useMutation<
    UserDefaultUpdate,
    UserDefaultUpdateVariables
  >(UPDATE_USER_DEFAULTS, {
    refetchQueries: [DASHBOARD_CHARTS_QUERY, USER_CHARTS],
  });

  const { data, loading } = useQuery<UserDefaults>(USER_DEFAULTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: chartsData, loading: loadingCharts } = useQuery<UserCharts>(
    USER_CHARTS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { data: commonData } = useQuery<UserDefaultDropdownData>(
    USER_DEFAULTS_DROPDOWN_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const {
    departmentId,
    projectId,
    currencyId,
    proxyUserId,
    businessUnitId,
    corporatePeriodId,
    isDocumentAutoviewEnabled,
    lookupAccountId,
    referenceCode6,
    referenceCode7,
    delegateUserProfileId,
    delegateEndDate,
    delegateStartDate,
    delegateEndTime,
    delegateStartTime,
    isEmailSentOnFinalApproval,
    isRequesterEmailSentEachApprovalCycle,
    isAccountingAreaExpanded,
    isRowSelectorBold,
    listviewSize,
    projectReference,
    setReference
  } = { ...data?.userDefaults?.nodes[0] };

  const { nodes: chartNodes } = { ...chartsData?.userCharts };
  const initialValues: UserDefaultFormValues = {
    userDefaultPatch: {
      ...USER_DEFAULT_INITIAL_VALUES,
      corporatePeriodId: corporatePeriodId!,
      businessUnitId: businessUnitId!,
      proxyUserId: proxyUserId!,
      currencyId: currencyId!,
      projectId: projectId!,
      departmentId: departmentId!,
      isEmailSentOnFinalApproval: !!isEmailSentOnFinalApproval,
      isDocumentAutoviewEnabled:
        isDocumentAutoviewEnabled === null ? true : isDocumentAutoviewEnabled!,
      isAccountingAreaExpanded: isAccountingAreaExpanded
        ? isAccountingAreaExpanded
        : false,
      isRowSelectorBold: isRowSelectorBold ? isRowSelectorBold : false,
      listviewSize: listviewSize
        ? listviewSize
        : TransactionLayout.LISTVIEW_SIZE_LARGE,
      lookupAccountId: lookupAccountId!,
      referenceCode6: referenceCode6!,
      referenceCode7: referenceCode7!,
      projectReference:projectReference!,
      setReference:setReference!,
      delegateUserProfileId: delegateUserProfileId!,
      delegateEndDate: delegateEndDate
        ? dateFormat(dateConvertions(delegateEndDate))
        : null,
      delegateStartDate: delegateStartDate
        ? dateFormat(dateConvertions(delegateStartDate))
        : null,
      delegateEndTime: delegateEndTime!,
      delegateStartTime: delegateStartTime!,
      isRequesterEmailSentEachApprovalCycle:
        isRequesterEmailSentEachApprovalCycle!,
    },
    userCharts: chartNodes || [],
  };

  const handleSubmit = async (values: UserDefaultFormValues) => {
    const userChartsData: UserChartInput[] =
      values.userCharts?.map(
        (item) =>
          ({
            chartPosition: item.chartPosition,
            isActive: item.isActive,
            id: item.id,
          } as UserChartInput)
      ) || [];

    const userDefaultPatch = Object.entries(values.userDefaultPatch!).reduce(
      (prev, [key, value]) => {
        if (key === 'delegateStartDate' || key === 'delegateEndDate') {
          return {
            ...prev,
            [key]: value ? dateFormat(value.toString()) : null,
          };
        } else {
          return { ...prev, [key]: value };
        }
      },
      {}
    );

    const { errors, data: response } = await updateUserDefaults({
      variables: {
        input: {
          id: data?.userDefaults?.nodes[0].id!,
          userDefaultPatch: userDefaultPatch,
          userCharts: userChartsData,
        },
      },
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      if (response?.userDefaultUpdate?.userDefault)
        setUserDefaults(response?.userDefaultUpdate?.userDefault);
      addToast('Update Successful', {
        appearance: 'success',
      });
    }
  };

  return (
    <Formik<UserDefaultFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ submitForm, dirty }) => {
        return (
          <Form>
            {isOpen && (
              <Callout
                isBeakVisible={false}
                target={`#${calloutId}`}
                className={styles.root}
                onDismiss={onDismiss}
              >
                <Stack className={styles.container}>
                  <Stack
                    horizontalAlign="space-between"
                    horizontal
                    tokens={{ padding: '10px 20px' }}
                    className={styles.headerStyles}
                  >
                    <Text className={commonStyles.bold} variant="xLarge">
                      Preferences
                    </Text>
                    <CloseButton onClick={onDismiss!} />
                  </Stack>
                  <Stack className={styles.body}>
                    <Stack
                      horizontal
                      horizontalAlign="space-between"
                      verticalAlign="center"
                      tokens={{ padding: '0px 20px 0px 0px' }}
                    >
                      <Pivot
                        selectedKey={selectedKey}
                        onLinkClick={(item: PivotItem | undefined) => {
                          setSelectedKey(item?.props.itemKey || 'Defaults');
                        }}
                        styles={{ root: { padding: '0px 20px' } }}
                      >
                        <PivotItem
                          itemKey={'Defaults'}
                          headerText="Defaults"
                          onClick={() => {
                            setSelectedKey('Defaults');
                          }}
                        />
                        <PivotItem
                          itemKey={'Charts'}
                          headerText="Charts"
                          onClick={() => {
                            setSelectedKey('Charts');
                          }}
                        />
                      </Pivot>
                      <Toggle
                        className={styles.toggle}
                        checked={darkMode()}
                        onText="Dark"
                        offText="Light"
                        onChange={() => {
                          storedDarkMode(!darkMode());
                          darkMode(!darkMode());
                        }}
                        styles={{
                          text: {
                            color: theme.palette.neutralSecondaryAlt,
                          },
                        }}
                      />
                    </Stack>
                    {selectedKey === 'Defaults' ? (
                      <>
                        {loading ? (
                          <ShimmerView />
                        ) : (
                          <DefaultsView
                            dropdownData={commonData!}
                            userDefaults={data?.userDefaults?.nodes[0]!}
                          />
                        )}
                      </>
                    ) : (
                      <ChartsView chartsData={chartsData!} />
                    )}
                  </Stack>
                  <Stack
                    horizontalAlign="space-between"
                    horizontal
                    tokens={{ padding: '20px 20px' }}
                    className={styles.footerStyles}
                  >
                    <PrimaryButton
                      disabled={updateLoading || loadingCharts || !dirty}
                      text="Save"
                      onClick={() => {
                        submitForm();
                      }}
                    />
                    <Signature />
                  </Stack>
                </Stack>
              </Callout>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
