import { useQuery } from '@apollo/client';
import { Separator, Stack } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import { loader } from 'graphql.macro';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TravelAuthorizationValues } from 'travelAuthorization/TravelPlan/view/interface';
import { AttachForm } from './AttachForm';
import { UploadForm } from './UploadForm';
import { TravelAuthorizationUploadDocument_travelAuthorizationUploadDocument } from './UploadForm/__generated__/TravelAuthorizationUploadDocument';
import { TravelAuthorizationSecureRowLevels } from './__generated__/TravelAuthorizationSecureRowLevels';
import { columns } from './column.data';
const TRAVEL_AUTHORIZATION_ROW_LEVELS = loader(
  './TravelAuthorizationSecureRowLevels.graphql'
);

interface AttachProps {
  travelAuthorizationData: TravelAuthorization_travelAuthorization;
  onRemove?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: TravelAuthorizationUploadDocument_travelAuthorizationUploadDocument,
    toastId: string
  ) => void;
}

export const Attach: React.FC<AttachProps> = ({
  travelAuthorizationData,
  onUpload,
  onRemove,
}) => {
  const { entityDocumentsByEntityId } = { ...travelAuthorizationData };
  const {
    formState: { isDirty },
  } = useFormContext<TravelAuthorizationValues>();

  const { data: travelAuthorizationSecureRowLevels } =
    useQuery<TravelAuthorizationSecureRowLevels>(
      TRAVEL_AUTHORIZATION_ROW_LEVELS,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
      }
    );

  const { secureRowLevels } = { ...travelAuthorizationSecureRowLevels };

  return (
    <Stack>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{
          childrenGap: 10,
        }}
      >
        <AttachForm travelAuthorizationData={travelAuthorizationData} />
        <Separator vertical />
        <UploadForm
          travelAuthorizationData={travelAuthorizationData}
          onUpload={onUpload}
        />
      </Stack>
      {!!entityDocumentsByEntityId?.nodes.length && (
        <Stack
          tokens={{
            padding: '0px 0px 0px 10px',
          }}
        >
          <DocumentList
            isNew={false}
            data={entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevels?.nodes}
            onRemoveRow={() => {
              onRemove?.();
            }}
            columns={columns}
            disableUpdate={isDirty}
          />
        </Stack>
      )}
      <Stack tokens={{ padding: '10px 25px 0px' }}>
        <NoDataView
          show={entityDocumentsByEntityId?.totalCount === 0}
          title="No Attachments"
        />
      </Stack>
    </Stack>
  );
};
