import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  stickyInsideStack: {
    alignItems: 'self-start',
    padding:"25px 20px 0px",
    cursor: "move"
  },
  requestComment: {
    marginTop: 10
  }
}));