import { makeVar, useReactiveVar } from '@apollo/client';
import {
  FontIcon,
  IPanelProps,
  Panel,
  PanelType,
  useTheme,
} from '@fluentui/react';
import { DRAWER_CLOSE_WIDTH } from 'common/constants';
import React, { useCallback, useEffect, useRef } from 'react';
import { togglePanelSize } from '../PanelHeader';

type DraggablePanelProps = Omit<IPanelProps, 'type' | 'customWidth'> & {
  initialWidth: number;
  minWidth?: number;
};

interface DragState {
  active: boolean;
  x?: number;
}

export const setPanelWidth = makeVar<number | undefined>(undefined);
const DraggablePanel: React.FC<DraggablePanelProps> = ({
  children,
  initialWidth,
  minWidth = 300,
  layerProps,
  onDismiss,
  ...props
}) => {
  const renderRef = useRef(true);
  const panelWidth = useReactiveVar(setPanelWidth);
  const isExpanded = useReactiveVar(togglePanelSize);
  const dragRef = useRef<DragState>({
    active: false,
  });
  const theme = useTheme();

  const onMouseUp = useCallback((e: MouseEvent) => {
    dragRef.current = { active: false };
  }, []);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      const { active, x } = dragRef.current;

      if (active && x !== undefined) {
        const diff = Math.abs(x - e.clientX);
        const newWidth =
          x > e.clientX ? (panelWidth || 0) + diff : (panelWidth || 0) - diff;
        const value = Math.max(
          minWidth,
          Math.min(newWidth, window.innerWidth - DRAWER_CLOSE_WIDTH)
        );
        setPanelWidth(value);
        dragRef.current = { active: true, x: e.clientX };

        e.preventDefault();
      }
    },
    [minWidth, panelWidth]
  );

  const removeEventListeners = useCallback(() => {
    window.removeEventListener('mouseup', onMouseUp);
    window.removeEventListener('mousemove', onMouseMove);
  }, [onMouseUp, onMouseMove]);

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      removeEventListeners();
    };
  }, [onMouseUp, onMouseMove, removeEventListeners]);

  useEffect(() => {
    setPanelWidth(
      isExpanded
        ? window.innerWidth - DRAWER_CLOSE_WIDTH
        : renderRef.current
          ? panelWidth
            ? panelWidth
            : minWidth
          : initialWidth
    );
    renderRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <Panel
      {...props}
      onDismiss={(e) => {
        removeEventListeners();
        onDismiss?.(e);
      }}
      type={PanelType.custom}
      customWidth={`${panelWidth}px`}
      layerProps={{ eventBubblingEnabled: true, ...layerProps }}
    >
      <div
        style={{
          position: 'fixed',
          height: '100%',
          width: 10,
          right: (panelWidth || 0) - 10,
          top: 0,
          cursor: 'ew-resize',
          backgroundColor: theme.palette.neutralLighter,
          display: 'flex',
          alignItems: 'center',
        }}
        onMouseDown={(e) => {
          dragRef.current = { active: true, x: e.clientX };
        }}
      >
        <FontIcon
          iconName="GripperBarVertical"
          style={{ marginLeft: '-2px', color: theme.palette.neutralQuaternary }}
        />
      </div>

      {children}
    </Panel>
  );
};

export default DraggablePanel;
