import React, { memo, useEffect } from 'react';
import {
  IDetailsHeaderProps,
  Stack,
  IRenderFunction,
  Sticky,
  StickyPositionType,
  ShimmeredDetailsList,
  ConstrainMode,
  DetailsListLayoutMode,
  Selection,
  ScrollablePane,
  IDetailsRowProps,
  IShimmeredDetailsListProps,
  IDetailsFooterProps,
  DetailsRow,
  IDetailsRowBaseProps,
  TooltipHost,
  DirectionalHint,
  ITooltipProps,
  Text,
} from '@fluentui/react';
import { NoDataView } from '../DataPlaceholders';
import { Waypoint } from 'react-waypoint';
import { useStyles } from './index.styles';
import { useRef } from 'react';
import clsx from 'clsx';
import { useId } from '@fluentui/react-hooks';

interface IWithId {
  id: string;
}

interface InfiniteListCustomProps<T extends IWithId> {
  loading: boolean;
  items?: T[];
  hasNextPage?: boolean;
  showFooter?: boolean;
  onRenderFooterItemColumn?: IDetailsRowBaseProps['onRenderItemColumn'];
  onLoadMore?: (() => Promise<void>) | (() => void);
  onSelectionChanged?: (items: T[]) => void;
  canSelectItem?: (item: T, index?: number) => boolean;
  requestedIndices?: number[];
  releasedIndices?: number[];
  selectedRows?: T[];
  disableFullRowSelect?: boolean;
  totalRowsCount?: number | null | undefined;
}

export type InfiniteListProps<T extends IWithId> = InfiniteListCustomProps<T> &
  Omit<
    IShimmeredDetailsListProps,
    | 'items'
    | 'contrainMode'
    | 'shimmerLines'
    | 'detailsListStyles'
    | 'setKey'
    | 'selection'
    | 'layoutMode'
  >;

const InfiniteListComponent = <T extends IWithId>({
  loading,
  items,
  hasNextPage,
  showFooter = false,
  onRenderFooterItemColumn,
  onLoadMore,
  onSelectionChanged,
  canSelectItem,
  onRenderRow,
  requestedIndices,
  releasedIndices,
  selectedRows,
  disableFullRowSelect,
  totalRowsCount,
  ...props
}: InfiniteListProps<T>) => {
  const styles = useStyles();
  const tooltipId = useId('tooltip');

  // Types for Selection and ISelection are bugged.
  // https://github.com/microsoft/fluentui/issues/19657
  // https://github.com/microsoft/fluentui/issues/13411
  const selection: React.MutableRefObject<Selection<T>> = useRef(
    // @ts-ignore
    new Selection<T>({
      getKey: (item: T) => item.id,
      canSelectItem,
      onSelectionChanged: onSelectionChanged
        ? () =>
            onSelectionChanged(
              selection.current.getSelection().filter((item) => !!item)
            )
        : undefined,
    })
  );

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    const newProps: IDetailsHeaderProps | undefined = props
      ? { ...props, className: styles.header }
      : undefined;
    return (
      <Sticky stickyPosition={StickyPositionType.Header}>
        {defaultRender!(newProps)}
      </Sticky>
    );
  };

  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <Stack horizontal tokens={{ childrenGap: 4, padding: 6 }}>
        <Text className={styles.totalCount} variant="medium">
          {totalRowsCount}
        </Text>
        <Text>row(s)</Text>
      </Stack>
    ),
  };

  const _onRenderDetailsFooter: IRenderFunction<IDetailsFooterProps> = (
    props
  ) => {
    if (!props || !showFooter || items?.length === 0) {
      return null;
    }
    return (
      <Sticky stickyPosition={StickyPositionType.Footer} isScrollSynced>
        {totalRowsCount ? (
          <TooltipHost
            tooltipProps={tooltipProps}
            id={tooltipId}
            directionalHint={DirectionalHint.topCenter}
          >
            <DetailsRow
              {...props}
              item={{}}
              itemIndex={-1}
              onRenderItemColumn={onRenderFooterItemColumn}
              className={clsx(styles.row, styles.backgroundcolor)}
            />
          </TooltipHost>
        ) : (
          <DetailsRow
            {...props}
            item={{}}
            itemIndex={-1}
            onRenderItemColumn={onRenderFooterItemColumn}
            className={clsx(styles.row, styles.backgroundcolor)}
          />
        )}
      </Sticky>
    );
  };

  useEffect(() => {
    if (requestedIndices?.length! > 0) {
      requestedIndices?.map((indexNumber) =>
        selection.current.setIndexSelected(indexNumber, true, true)
      );
    } else {
      selection.current.setAllSelected(false);
    }
  }, [requestedIndices]);

  useEffect(() => {
    if (releasedIndices?.length! > 0) {
      releasedIndices?.map((indexNumber) =>
        selection.current.setIndexSelected(indexNumber, true, true)
      );
    } else {
      selection.current.setAllSelected(false);
    }
  }, [releasedIndices]);

  useEffect(() => {
    if (selectedRows?.length! === 0) {
      selection.current.setAllSelected(false);
    }
  }, [selectedRows]);

  return (
    <Stack grow className={styles.container}>
      <ScrollablePane>
        <ShimmeredDetailsList
          // setKey is required to be set to a value or we lose selection on load more
          // https://github.com/microsoft/fluentui/issues/7817
          setKey="set"
          compact
          selectionPreservedOnEmptyClick
          items={[
            ...(items || []),
            ...(items && hasNextPage ? Array(5).fill(null) : []),
          ]}
          constrainMode={ConstrainMode.unconstrained}
          onRenderDetailsHeader={onRenderDetailsHeader}
          onRenderRow={(props, defaultRender) => {
            const newProps: IDetailsRowProps | undefined = props
              ? {
                  ...props,
                  className: styles.row,
                  styles: {
                    cell: { alignSelf: 'center' },
                    check: { height: '100% !important' },
                  },
                }
              : undefined;

            return (
              <span
                data-selection-disabled={disableFullRowSelect}
                onClick={
                  disableFullRowSelect
                    ? (ev: any) => {
                        if (
                          ev.target.classList.contains('ms-Check-check') &&
                          newProps?.item
                        ) {
                          const key = selection.current.getKey(newProps.item);
                          selection.current.setKeySelected(
                            key,
                            !selection.current.isKeySelected(key),
                            false
                          );
                        }
                      }
                    : undefined
                }
              >
                {onRenderRow
                  ? onRenderRow(newProps, defaultRender)
                  : defaultRender?.(newProps) || null}
              </span>
            );
          }}
          onRenderDetailsFooter={_onRenderDetailsFooter}
          shimmerLines={20}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          // @ts-ignore
          selection={selection.current}
          enableShimmer={!items?.length && loading}
          onRenderCustomPlaceholder={(rowProps, index, defaultRender) => {
            if (items?.length && index === items.length && onLoadMore) {
              return (
                <Waypoint
                  onEnter={async () => {
                    if (!loading && hasNextPage) await onLoadMore();
                  }}
                >
                  {defaultRender!(rowProps)}
                </Waypoint>
              );
            }

            return defaultRender!(rowProps);
          }}
          isSelectedOnFocus={false}
          {...props}
        />
        {!items?.length && !loading && (
          <Stack tokens={{ padding: '0px 30px' }}>
            <NoDataView show />
          </Stack>
        )}
      </ScrollablePane>
    </Stack>
  );
};

export const InfiniteList = memo(
  InfiniteListComponent
) as typeof InfiniteListComponent;
