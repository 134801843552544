import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useReactiveVar,
} from '@apollo/client';
import {
  IColumn,
  IDetailsRowBaseProps,
  IDetailsRowProps,
  IRenderFunction,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import clsx from 'clsx';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  MultiSelectOption,
  MultiSelectTags,
} from 'common/components/MultiSelectTags';
import { RedBoxIndicator } from 'common/components/RedBoxIndicator';
import { ColumnData } from 'common/components/SearchBar';
import { StatusIcon } from 'common/components/StatusIcon';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  EntityDeleteInput,
  PayCycleFilter,
  PayCyclesOrderBy,
  SearchRequestInput,
} from 'common/types/globalTypes';
import { EntityType } from 'common/types/utility';
import { getColumn, getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { StringParam } from 'serialize-query-params';
import { useQueryParams } from 'use-query-params';
import { globalMode, setGlobalSearchText } from 'utility/cache/ui';
import {
  PayCycleDelete,
  PayCycleDeleteVariables,
} from '../__generated__/PayCycleDelete';
import { PaymentCycleSearchBar } from './PaymentCycleSearchBar';
import {
  payCycleSearch,
  payCycleSearchVariables,
  payCycleSearch_payCycleSearch_nodes,
} from './__generated__/payCycleSearch';
import {
  payCycleSearchHints,
  payCycleSearchHintsVariables,
} from './__generated__/payCycleSearchHints';
import { useStyles } from './index.styles';
import { toOrderByVariable, toPayCycleFilterVariable } from './utils';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useColumns } from './column.data';
const PAY_CYCLES = loader('./PayCycleSearch.graphql');
const PAY_CYCLES_HINTS = loader('./PayCycleSearchHints.graphql');
const DELETE_PAY_CYCLES = loader('../PayCycleDelete.graphql');
export interface PayCycleItem extends payCycleSearch_payCycleSearch_nodes {
  key: string;
  _paymentAccount: string;
  _paymentName: string;
  currencyIsoCode: string;
  status: string;
  _paymentType: string;
}

export const PaymentCycleList: React.FC = () => {
  let payCyclesList: PayCycleItem[] = [];
  const renderRef = useRef(false);
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const { columns } = useColumns();
  const globalState = useReactiveVar(globalMode);
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });

  const { t: searchParam } = urlParameters;
  const [searchValue, setSearchValue] = useState<string | undefined | null>(
    searchParam! || null
  );
  const [selectedHint, setSelectedHint] = useState<number | null>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [selectedList, setSelectedList] = useState<PayCycleItem[]>([]);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();
  const [hintsVisibility, setHintsVisibility] = useState<boolean>(false);

  const [
    fetchPayCycles,
    {
      loading: payCyclesLoading,
      data: payCyclesData,
      variables: payCyclesVariables,
      networkStatus,
      fetchMore,
    },
  ] = useLazyQuery<payCycleSearch, payCycleSearchVariables>(PAY_CYCLES, {
    variables: {
      isSigningSearch: true,
      isHistorySearch: false,
      first: TABLE_ROWS,
      orderBy: [PayCyclesOrderBy.PRIMARY_KEY_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [fetchPayCyclesHints, { data: payCyclesHintsData }] = useLazyQuery<
    payCycleSearchHints,
    payCycleSearchHintsVariables
  >(PAY_CYCLES_HINTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [deletePayCycle] = useMutation<PayCycleDelete, PayCycleDeleteVariables>(
    DELETE_PAY_CYCLES,
    { errorPolicy: 'all' }
  );

  const refetching =
    payCyclesLoading && networkStatus !== NetworkStatus.fetchMore;

  const transformedData = refetching
    ? undefined
    : payCyclesData?.payCycleSearch?.nodes.map(
        (payCycle) =>
          ({
            ...payCycle,
            key: payCycle.id,
            _paymentType: payCycle.transactionType?.transactionType,
            _paymentAccount: payCycle.paymentAccount?.bankAccountNumber,
            _paymentName: payCycle.paymentAccount?.name,
            defaultPaymentDate: payCycle.defaultPaymentDate
              ? dateFormat(dateConvertions(payCycle.defaultPaymentDate))
              : '',
            currencyIsoCode: payCycle.currency?.isoCode,
            status: payCycle.statusType?.statusType,
          } as PayCycleItem)
      );
  if (transformedData) payCyclesList = transformedData;

  const _renderItemColumn = (
    item: PayCycleItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof PayCycleItem
      ] as string;
      const _isUrgentApproval = !Boolean(item._urgencyLevel);
      switch (column?.key) {
        case '_urgencyLevel':
          return (
            <RedBoxIndicator
              _isUrgentApproval={_isUrgentApproval}
              itemId={item?.id}
            />
          );
        case 'description':
          return (
            <Stack verticalAlign="center">
              <HighLightActiveLink
                to={`/pay/payment_cycle/${
                  item._isAccountingEntry ? 'accounting' : 'cycle'
                }/${item.id}`}
                highlightText={globalSearchText}
                text={item.description}
              />
            </Stack>
          );
        case 'defaultPaymentDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <HighlightTextView
                className={commonStyles.contentColumnAlignRight}
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );
        case '_paymentAccount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={`${item._paymentName}  ${
                  item._paymentAccount ? `( ${item._paymentAccount})` : ''
                }`}
              />
            </Stack>
          );
        case 'controlTotalAmount':
        case '_totalPayments':
          return (
            <AmountColumnTextView
              value={fieldContent}
              searchText={globalSearchText}
            />
          );
        case '_paymentCount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                  className={styles.paymentCount}
                />
              )}
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
              horizontal
              horizontalAlign="space-between"
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={convertToTitleCase(item?.status!)!}
              />
              <Stack>
                <StatusIcon
                  approval={false}
                  iconType={item.statusType!}
                  approvalData={item!}
                />
              </Stack>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      switch (column?.key) {
        case 'controlTotalAmount':
          return (
            <AmountTextView
              variant="medium"
              className={clsx(
                styles.amountStack,
                styles.contentColumnAlignRight
              )}
              value={
                payCyclesData?.payCycleSearch?.aggregates?.sum
                  ?.controlTotalAmount || ''
              }
            />
          );
        case '_paymentCount':
          return (
            <AmountTextView
              variant="medium"
              className={clsx(
                styles.amountStack,
                styles.contentColumnAlignRight
              )}
              value={
                payCyclesData?.payCycleSearch?.aggregates?.sum?._paymentCount ||
                ''
              }
            />
          );
        case '_totalPayments':
          return (
            <AmountTextView
              variant="medium"
              className={clsx(
                styles.amountStack,
                styles.contentColumnAlignRight
              )}
              value={
                payCyclesData?.payCycleSearch?.aggregates?.sum
                  ?._totalPayments || ''
              }
            />
          );
        default:
          return undefined;
      }
    };

  const _deleteTransaction = async () => {
    const deleteInputEntities: EntityDeleteInput[] = selectedList
      .filter((entity) => entity._isDeletable)
      .map((entity) => ({
        id: entity.id,
        rowTimestamp: entity._rowTimestamp!,
      }));
    const { errors } = await deletePayCycle({
      variables: { input: { entityDelete: deleteInputEntities } },
      update: (cache, { data }) => {
        const deletedIds = data?.payCycleDelete?.deletedEntities?.map(
          (entity) => entity?.id
        );
        if (deletedIds) {
          const filteredList = payCyclesData?.payCycleSearch?.nodes.filter(
            (emp) => deletedIds.indexOf(emp.id) === -1
          );
          const newData: payCycleSearch = {
            payCycleSearch: {
              pageInfo: payCyclesData?.payCycleSearch?.pageInfo!,
              totalCount:
                payCyclesData?.payCycleSearch?.totalCount! - deletedIds.length,
              nodes: filteredList!,
              aggregates: {
                sum: payCyclesData?.payCycleSearch?.aggregates?.sum!,
              },
            },
          };
          cache.writeQuery<payCycleSearch, payCycleSearchVariables>({
            query: PAY_CYCLES,
            variables: payCyclesVariables,
            data: newData,
          });
        }
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
  };
  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const item: PayCycleItem = { ...props.item };
    const { _urgencyLevel } = { ...item };
    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          className: clsx(
            styles.rowBaseStyle,
            _urgencyLevel === 0 ? commonStyles.urgentRow : styles.rowBaseStyle
          ),
        }
      : undefined;

    return <>{defaultRender?.(newProps)}</>;
  };

  const handleSearch = (showMore: boolean, newHints: boolean) =>
    handleSeachGlobal(showMore, newHints, globalState);

  // Handle Search query with globalSearch param
  const handleSeachGlobal = (
    showMore: boolean,
    newHints: boolean,
    globalValue: boolean
  ) => {
    const searchRequest: SearchRequestInput | undefined = searchValue
      ? {
          searchText: [searchValue ? searchValue : ''],
          searchHintId: selectedHint,
        }
      : undefined;

    const searchHintsRequest: SearchRequestInput = {
      searchText: [searchValue ? searchValue : ''],
      searchHintId: null,
    };

    const variables: payCycleSearchVariables = {
      searchRequest: searchRequest,
      first: TABLE_ROWS,
      isSigningSearch: true,
      after: showMore
        ? payCyclesData?.payCycleSearch?.pageInfo.endCursor
        : undefined,
      filter: searchFilters?.length
        ? ({
            and: toPayCycleFilterVariable(searchFilters),
          } as PayCycleFilter)
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam!),
      isLinkSearch: globalValue,
    };

    const variableHints: payCycleSearchHintsVariables = {
      SearchRequestInput: searchHintsRequest,
      isHistorySearch: false,
      isSigningSearch: true,
      isLinkSearch: globalValue,
    };

    const searchQueryParam =
      searchValue && searchValue.length > 0 ? searchValue : undefined;

    setUrlParameters({ t: searchQueryParam }, 'replace');

    if (showMore) fetchMore?.({ variables });
    else fetchPayCycles({ variables: variables });
    if (newHints && searchRequest && !selectedHint) {
      setSelectedHint(null);
      fetchPayCyclesHints({ variables: variableHints });
    }
  };

  const searchHintsOptions: MultiSelectOption[] =
    payCyclesHintsData?.payCycleSearchHints?.nodes.map(
      (hint) =>
        ({
          key: hint.id?.toString(),
          text: `${hint.caption} (${hint.resultsCount})`,
        } as MultiSelectOption)
    ) || [];

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const getNewColumns = () => {
    if (sortOrderParam?.column !== '' && sortOrderParam) {
      const columnData = getColumn(sortOrderParam?.column!, gridColumns);
      const sortedColumns = getSortedColumns(columnData, gridColumns);
      return sortedColumns;
    } else return undefined;
  };
  const getNewColumnsMemo = useCallback(getNewColumns, []);
  useEffect(() => {
    const sortedColumns = getNewColumnsMemo();
    if (sortedColumns && renderRef.current)
      setGridColumns(sortedColumns.newColumns);
  }, [getNewColumnsMemo]);

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchValue,
    selectedHint,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, true);
  }, [searchFilters, sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    handleSearchMemo(false, true);
    renderRef.current = true;
  }, [searchValue, handleSearchMemo]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, false);
  }, [selectedHint, handleSearchMemo]);

  const hasHints = searchHintsOptions.length > 0 && searchValue?.length! > 0;

  const showFooter = refetching || !!payCyclesData?.payCycleSearch?.totalCount;

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Pay Cycles</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.PayCycle}
              disabled={!selectedList.some((selected) => selected._isDeletable)}
              visible={selectedList.length > 0}
              multiple={{
                validCount: selectedList.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedList
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete.description),
              }}
              onConfirm={() => _deleteTransaction()}
            />
            <PrimaryButton
              onClick={() => {
                history.push('/pay/payment_cycle/cycle');
              }}
              iconProps={{ iconName: 'Add' }}
              text="New Pay Cycle"
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack tokens={{ padding: '0px 25px', childrenGap: 20 }}>
        <PaymentCycleSearchBar
          urlParams={{
            searchParam: searchParam!,
          }}
          toggleActions={() => {
            if (globalState) globalMode(false);
          }}
          hintsAvailable={hasHints}
          columns={gridColumns}
          onRefresh={() => handleSearch(false, false)}
          onEnterPress={(value, globalSearch) => {
            setSelectedHint(null);
            setHintsVisibility(false);
            setSearchValue(value);
            setGlobalSearchText(value);
          }}
          onFilterChange={(filters) => {
            if (globalState) globalMode(false);
            setSearchFilters(filters);
          }}
          onHintsViewToggle={() =>
            setHintsVisibility((prevState) => !prevState)
          }
          onToggleVisibility={(columns) => {
            setGridColumns(columns);
          }}
        />
        {hintsVisibility && hasHints && (
          <MultiSelectTags
            multiSelect={false}
            options={searchHintsOptions}
            onSelectionChange={(selectedTags) => {
              const hints = selectedTags.map((item) => parseInt(item.key));
              setSelectedHint(hints[0]);
            }}
          />
        )}
      </Stack>

      <InfiniteList
        items={payCyclesList}
        loading={payCyclesLoading}
        hasNextPage={payCyclesData?.payCycleSearch?.pageInfo.hasNextPage}
        showFooter={showFooter}
        columns={gridColumns.filter((column) => column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={() => handleSearch(true, false)}
        onRenderRow={_onRenderRow}
        onSelectionChanged={setSelectedList}
      />
    </>
  );
};
