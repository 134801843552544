import React, { useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { PaymentCycleList } from './list';
import { PayCycleAccEntryList } from './AccountingEntry/list';
import { globalMode } from 'utility/cache/ui';

export const payCycleMode = makeVar<boolean>(true);

export const PayCycle: React.FC = () => {
  const payCycleState = useReactiveVar(payCycleMode);
  
  const globalState = useReactiveVar(globalMode);

  if (!payCycleState && globalState) payCycleMode(true);
  // defaulting to Pay Cycle list after Route change
  useEffect(() => {
    return () => {
      payCycleMode(true);
    };
  }, []);

  return <>{payCycleState ? <PaymentCycleList /> : <PayCycleAccEntryList />}</>;
};
