import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  groupIconContainer: {
    backgroundColor: theme.palette.themePrimary,
    height: 42,
    width: 42,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
