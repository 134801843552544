const ResourceType = [
  { ENV_FUEL: ['Gallons'] },
  { ENV_VEHICLES: ['Quantity'] },
  { ENV_TRAVEL: ['Quantity', 'Miles'] },
  { ENV_UNITS: ['Quantity'] },
  { ENV_TONNES: ['Tons'] },
  { ENV_THERMS: ['Therms'] },
  { ENV_KWH: ['KilowattHours'] },
];

export { ResourceType };
