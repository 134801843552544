import React, { FC } from 'react';
import { Checkbox, ICheckboxProps } from '@fluentui/react';
import { useController, UseControllerProps } from 'react-hook-form';
import { ErrorText } from '../ErrorText';
interface FormHookCheckBoxProps {
  name: string;
  rules?: UseControllerProps['rules'];
  defaultValue?: any;
}
export const FormHookCheckBox: FC<FormHookCheckBoxProps & ICheckboxProps> = ({
  name,
  onChange,
  ...props
}) => {
  const {
    field: { value, onChange: onCheckBoxChange },
    fieldState: { error, isTouched },
  } = useController({ name });

  return (
    <>
      <Checkbox
        {...props}
        checked={!!value}
        onChange={(event, checked) => {
          if (event) {
            onCheckBoxChange(checked);
            onChange?.(event, checked);
          }
        }}
      />
      {isTouched && error && <ErrorText message={error.message || ''} />}
    </>
  );
};
