import { dateConvertions } from 'common/utils/dateFormats';
import { EntityDocumentInput } from 'common/types/globalTypes';
import { InvoiceDetails } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
import { ACCOUNTING_INITIAL_VALUES, INVOICE_INITIAL_VALUES } from './constant';
import {
  AccountingViewRowValues,
  AttachableDataType,
  TransactionSigningValues,
} from 'common/components/Modules/TransactionEdit/types';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';

export const getDefaultDistribution = (
  userDefaults?: UserDefaults_userDefaults_nodes | undefined
) => {
  const { referenceCode6, referenceCode7, projectReference, setReference } = {
    ...userDefaults,
  };

  let defaultValues: AccountingViewRowValues | undefined = {
    ...ACCOUNTING_INITIAL_VALUES,
    referenceCode6: referenceCode6 === undefined ? null : referenceCode6,
    referenceCode7: referenceCode7 === undefined ? null : referenceCode7,
    projectReference: projectReference ?? null,
    setReference: setReference ?? null,
  };
  return defaultValues;
};
interface DefaultValueProps {
  isNew: boolean;
  invoiceDetailsData: InvoiceDetails | undefined;
  userDefaults: UserDefaults_userDefaults_nodes | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, invoiceDetailsData, userDefaults } = { ...props };
  const initialRow = getDefaultDistribution(userDefaults);
  let defaultValues: TransactionSigningValues = {
    ...INVOICE_INITIAL_VALUES,
    invoiceDistributions: [{ ...initialRow }],
  };
  if (invoiceDetailsData?.invoice && !isNew) {
    if (invoiceDetailsData.invoice._isUpdatable) {
      defaultValues = {
        ...invoiceDetailsData.invoice,
        invoiceDate: invoiceDetailsData.invoice.invoiceDate
          ? dateConvertions(invoiceDetailsData.invoice.invoiceDate!)
          : null,
        layoutType: invoiceDetailsData.invoice.transactionType?.layoutType!,
        invoiceDistributions:
          invoiceDetailsData.invoice.invoiceDistributions.nodes.length > 0
            ? [
                ...invoiceDetailsData.invoice.invoiceDistributions.nodes,
                { ...initialRow },
              ]
            : [{ ...initialRow }],
      };
    } else {
      defaultValues = {
        ...invoiceDetailsData.invoice,
        layoutType: invoiceDetailsData.invoice.transactionType?.layoutType!,
        invoiceDistributions:
          invoiceDetailsData?.invoice?.invoiceDistributions?.nodes,
      };
    }
  }
  return defaultValues as TransactionSigningValues;
};

export const formatEntityDocuments = (documents: EntityDocumentInput[]) => {
  if (!documents.length) return [];
  return documents.map((doc) => {
    const {
      id,
      comment,
      entityId,
      fileReference,
      fileIndexInformation,
      entityDocumentTypeId,
      indexName,
      indexDescription,
      indexTransactionDate,
      indexAmount,
      indexCurrencyId,
      documentAppliedAmount,
      documentStatusExplanation,
      isAppliedAmountRetired,
      isAppliedAmountOverage,
      documentRetiredAmount,
    } = { ...doc };
    return {
      id,
      comment,
      entityId,
      fileReference,
      fileIndexInformation,
      entityDocumentTypeId,
      indexName,
      indexDescription,
      indexTransactionDate,
      indexAmount,
      indexCurrencyId,
      documentAppliedAmount,
      documentStatusExplanation,
      isAppliedAmountRetired,
      isAppliedAmountOverage,
      documentRetiredAmount,
    };
  });
};

export const getPOInvoiceSchedules = (documents: AttachableDataType[]) => {
  if (!documents.length) return [];
  const tempPOInvoiceSchedulesArray = documents.map(
    (ele) => ele.poInvoiceSchedules
  );
  if (!!tempPOInvoiceSchedulesArray.length) {
    return tempPOInvoiceSchedulesArray.reduce(
      (accumulator, currentValue) => accumulator?.concat(currentValue!),
      []
    );
  } else return [];
};
