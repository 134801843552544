import React from 'react';
import { Stack, Text, Separator } from '@fluentui/react';
import { useStyles } from './index.styles';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { CalloutText } from './CalloutText';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';
// import { EntityDocumentType } from '..';
import { AccountingDetails } from './AccountingDetails';
import { getFormattedAmountValue } from 'common/components/AmountView/utils';
import { DocumentDataCalloutType } from 'common/components/AttachDocumentModal/DocumentDataCallout/types';

interface DocumentCalloutProps {
  calloutId: string;
  // documentData: EntityDocumentType;
  documentData: DocumentDataCalloutType;
  /* To check if the Callout is used in Create Record panel*/
  isNew?: boolean;
}
export const DocumentCallout: React.FC<DocumentCalloutProps> = ({
  calloutId,
  documentData,
  isNew,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const {
    indexName,
    comment,
    indexDescription,
    indexReferenceNumber,
    indexTransactionDate,
    indexAmount,
    currency,
    _isDocumentAmountApplied,
    _isAccountingDocument,
    documentTypes,
    documentAppliedAmounts,
    isAppliedAmountRetired,
    documentRetiredAmount,
    documentOverageAmount,
    isAppliedAmountOverage,
    documentStatusExplanation,
    documentAppliedAmount,
    documentStatusType,
    documentFileDistributionsByDocumentFileId,
    _poAllocationTotal,
  } = documentData;

  const hasExtendedInfo = [
    indexName,
    comment,
    indexDescription,
    indexReferenceNumber,
    indexTransactionDate,
    indexAmount,
    _isDocumentAmountApplied,
  ].some((val) => !!val);

  const formattedIndexAmount =
    indexAmount && currency?.isoCode
      ? `${getFormattedAmountValue(indexAmount)} ${currency?.isoCode}`
      : getFormattedAmountValue(indexAmount);

  const showRetiredLabel =
    documentAppliedAmount &&
    indexAmount &&
    Number(documentAppliedAmount) < Number(indexAmount);
  const isAccountingDetailsExists =
    documentFileDistributionsByDocumentFileId?.nodes?.length! > 0;

  const total =
    parseFloat(documentAppliedAmount || '0.00') +
    parseFloat(documentOverageAmount || '0.00');
  const distributed = parseFloat(_poAllocationTotal || '0.00');
  const difference = (total || 0) - distributed;

  return (
    <Stack className={styles.container}>
      {_isAccountingDocument && hasExtendedInfo ? (
        <>
          <Stack className={styles.typeContainer}>
            <Text
              className={clsx(commonStyles.semibold, styles.documentType)}
              variant="mediumPlus"
            >
              {documentTypes?.documentType}
            </Text>
          </Stack>
          <Separator className={styles.separator} />
          <Stack horizontal tokens={{ padding: 10 }}>
            <Stack
              className={documentAppliedAmount ? styles.leftCol : ''}
              tokens={{
                childrenGap: 10,
                padding: '0px 10px 10px 0px',
              }}
            >
              <CalloutText text={indexName} hasLabel={false} />
              <CalloutText text={indexDescription} hasLabel={false} />
              <CalloutText text={indexReferenceNumber} hasLabel={false} />
              {indexTransactionDate && (
                <CalloutText
                  text={dateFormat(dateConvertions(indexTransactionDate))}
                  hasLabel={false}
                />
              )}
              <Stack horizontal tokens={{ childrenGap: 50 }}>
                <Stack>
                  {formattedIndexAmount && (
                    <CalloutText
                      text={formattedIndexAmount}
                      label={'Amount'}
                      vertical
                      boldTitle
                    />
                  )}
                </Stack>
                <Stack>
                  {documentAppliedAmounts?.remainingTotal !== null &&
                    documentTypes?._isAppliableDocumentType && (
                      <CalloutText
                        text={documentAppliedAmounts?.remainingTotal}
                        label={'Remaining'}
                        primary
                        isAmountValue
                        vertical
                        boldTitle
                      />
                    )}
                </Stack>
              </Stack>

              {comment && (
                <Stack wrap>
                  <Text>{comment}</Text>
                </Stack>
              )}
            </Stack>

            <Stack>
              {documentTypes?._isAppliableDocumentType && (
                <Stack
                  tokens={{
                    childrenGap: 10,
                    padding: '0px 0px 50px 0px',
                  }}
                >
                  <CalloutText
                    label="Applied"
                    text={total ? total.toString() : '0.00'}
                    hasLabel
                    className={styles.boldText}
                    alignRight={true}
                    isAmountValue
                  />
                  <CalloutText
                    label="Distributed"
                    text={distributed.toString()}
                    hasLabel
                    className={styles.boldText}
                    alignRight={true}
                    isAmountValue
                  />
                  <CalloutText
                    label="Difference"
                    text={difference ? difference.toString() : null}
                    hasLabel
                    className={styles.boldText}
                    alignRight={true}
                    isAmountValue
                    primary
                  />
                </Stack>
              )}

              <Stack
                tokens={{
                  childrenGap: 10,
                  // padding: '0px 10px',
                }}
              >
                {isAppliedAmountRetired && (
                  <CalloutText
                    label="Retired"
                    text={documentRetiredAmount}
                    hasLabel
                    className={styles.boldText}
                    alignRight={true}
                    isAmountValue
                  />
                )}
                {isAppliedAmountOverage && (
                  <CalloutText
                    label="Overage"
                    text={documentOverageAmount}
                    hasLabel
                    className={styles.boldText}
                    alignRight={true}
                    isAmountValue
                  />
                )}
                {(isAppliedAmountOverage || isAppliedAmountRetired) && (
                  <Stack>
                    {documentStatusExplanation && (
                      <Stack wrap>
                        <Text>{documentStatusExplanation}</Text>
                      </Stack>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>

            {isNew && documentAppliedAmount && (
              <Stack
                className={styles.rightCol}
                tokens={{
                  childrenGap: 10,
                  padding: '0px 10px',
                }}
              >
                <Stack>
                  {documentAppliedAmount && (
                    <CalloutText
                      label="Applied"
                      text={documentAppliedAmount}
                      hasLabel
                      className={styles.boldText}
                      alignRight={true}
                      isAmountValue
                    />
                  )}
                  {documentStatusType?.statusType && (
                    <Text className={styles.boldText}>
                      {documentStatusType?.statusType}
                    </Text>
                  )}
                </Stack>
                {showRetiredLabel && (
                  <Stack>
                    <Text className={styles.boldText}>Retired</Text>
                  </Stack>
                )}
                <CalloutText
                  label="Overage"
                  text={documentOverageAmount}
                  hasLabel
                  className={styles.boldText}
                  alignRight={true}
                  isAmountValue
                />
                {isAppliedAmountOverage && (
                  <Stack>
                    {documentStatusExplanation && (
                      <Stack wrap>
                        <Text>{documentStatusExplanation}</Text>
                      </Stack>
                    )}
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>

          {isAccountingDetailsExists && (
            <>
              <Separator className={styles.separator} />
              <AccountingDetails
                data={documentFileDistributionsByDocumentFileId}
              />
            </>
          )}
        </>
      ) : (
        <Stack>
          <Stack className={styles.typeContainer}>
            <Text
              className={clsx(commonStyles.semibold, styles.documentType)}
              variant="mediumPlus"
            >
              {documentTypes?.documentType}
            </Text>
          </Stack>
          {comment && (
            <>
              <Separator className={styles.separator} />
              <Stack
                tokens={{ padding: '10px 20px 20px' }}
                className={styles.commentStyle}
                wrap
              >
                <Text>{comment}</Text>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};
