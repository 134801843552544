import React from 'react';
import { OccupationRowItem } from '..';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  OccupationDelete,
  OccupationDeleteVariables,
} from './__generated__/OccupationDelete';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityAction, EntityType } from 'common/types/utility';

const OCCUPATION_DELETE = loader('./OccupationDelete.graphql');

interface DeleteOccupationProps {
  selectedRows: OccupationRowItem[];
}

export const DeleteOccupation: React.FC<DeleteOccupationProps> = ({
  selectedRows,
}) => {
  const { addToast } = useToasts();

  const [deleteOccupation] = useMutation<
    OccupationDelete,
    OccupationDeleteVariables
  >(OCCUPATION_DELETE, { errorPolicy: 'all' });

  const _onConfirm = async () => {
    const deletableRows: OccupationRowItem[] = selectedRows.filter(
      (item) => item._isDeletable
    );
    const entityDelete: EntityDeleteInput[] = deletableRows.map((item) => {
      return { id: item.id, rowTimestamp: item._rowTimestamp! };
    });
    const { errors } = await deleteOccupation({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache) {
        deletableRows.forEach((item) => {
          const identity = cache.identify({
            ...item,
          });
          cache.evict({ id: identity });
          cache.gc();
        });
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted successfully.', {
        appearance: 'success',
      });
    }
  };

  return (
    <ActionMessageModal
      entityType={EntityType.Occupation}
      action={EntityAction.Remove}
      disabled={!selectedRows.some((selected) => selected._isDeletable)}
      visible={!!selectedRows.length}
      multiple={{
        validCount: selectedRows.filter((selected) => selected._isDeletable)
          .length,
        invalidNames: selectedRows
          .filter((selected) => !selected._isDeletable)
          .map((selected) => selected.userOccupationTitle || ''),
      }}
      onConfirm={_onConfirm}
    />
  );
};
