import { AccountsEntryViewProps } from '..';
import { ACCOUNT_ENTRY_VALUES } from './constants';
import { AccountEntryValues } from './types';

type DefaultValueProps = Pick<AccountsEntryViewProps, 'account'>;

export const getDefaultValues = (props: DefaultValueProps) => {
  const { account } = { ...props };
  const {
    __typename,
    id,
    _isUpdatable,
    _isDeletable,
    _rowTimestamp,
    corporateLookupAccountId,
    corporateAccount,
    departmentGroup,
    travelAccountType,
    ...accountFields
  } = {
    ...account,
  };
  let defaultValues: AccountEntryValues = ACCOUNT_ENTRY_VALUES;
  if (!!id)
    defaultValues = {
      ...accountFields,
      corporateAccount: {
        id: corporateAccount?.id || '',
        lookupAccount: corporateAccount?.lookupAccount || '',
      },
    };
  return defaultValues;
};
