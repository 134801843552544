import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Checkbox,
  IIconProps,
  PrimaryButton,
  Stack,
  TextField,
  makeStyles,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { CustomDropdown } from 'common/components/CustomDropdown';
import {
  CompanyCorporatePeriods,
  CompanyCorporatePeriodsVariables,
} from 'common/graphql/__generated__/CompanyCorporatePeriods';
import {
  TransferCycles,
  TransferCyclesVariables,
} from 'common/graphql/__generated__/TransferCycles';
import {
  CompanyCorporatePeriodsOrderBy,
  EntityDeleteInput,
  PurchaseOrderExportInput,
} from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useToasts } from 'react-toast-notifications';
import { TripBreakdownItem } from '..';
import {
  TripBreakdownExport,
  TripBreakdownExportVariables,
} from './__generated__/TripBreakdownExport';

const TRIP_BREAKDOWN_EXPORT = loader('./TripBreakdownExport.graphql');
const COMPANY_CORPORATE_PERIOD = loader(
  '../../../../common/graphql/CompanyCorporatePeriods.graphql'
);
const TRANSFER_CYCLE = loader(
  '../../../../common/graphql/transferCycles.graphql'
);

const STAMPING_TITLE =
  'Enter the description and press confirm to download the trip worksheet.';
const exportIcon: IIconProps = {
  iconName: 'Go',
  styles: {
    root: {
      fill: 'white',
      marginRight: 10,
    },
  },
};

const useStyles = makeStyles(() => ({
  description: {
    marginTop: 10,
  },
}));

interface ExportTripsProps {
  selectedRows: TripBreakdownItem[];
}

export const ExportTrips: React.FC<ExportTripsProps> = ({ selectedRows }) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const csvLink = useRef<HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [stampDescription, setStampDescription] = useState<string>('');
  const [includeAccDistro, setIncludeAccDistro] = useState<boolean>();
  const [selectedAccountingOption, setSelectedOption] = useState<string | null>(
    null
  );
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [csvFileName, setCsvFileName] = useState<string>();

  const [tripBreakdownExport] = useMutation<
    TripBreakdownExport,
    TripBreakdownExportVariables
  >(TRIP_BREAKDOWN_EXPORT);

  const { data: accoutringPeriods } = useQuery<
    CompanyCorporatePeriods,
    CompanyCorporatePeriodsVariables
  >(COMPANY_CORPORATE_PERIOD, {
    variables: {
      filter: {
        isEntryAllowed: {
          equalTo: true,
        },
      },
      orderBy: [
        CompanyCorporatePeriodsOrderBy.IS_ENTRY_ALLOWED_DESC,
        CompanyCorporatePeriodsOrderBy.START_DATE_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [transferCycle, { data: nextCycleData }] = useLazyQuery<
    TransferCycles,
    TransferCyclesVariables
  >(TRANSFER_CYCLE, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const handleConfirmPress = async () => {
    toggleConfirmDialog();
    const entityDeleteRows: EntityDeleteInput[] = [];
    for (let data of selectedRows) {
      entityDeleteRows.push({
        id: data?.id,
        rowTimestamp: data?._rowTimestamp!,
      });
    }
    selectedRows.map((data) =>
      entityDeleteRows.push({
        id: data?.id,
        rowTimestamp: data?._rowTimestamp!,
      })
    );
    const exportVariable: PurchaseOrderExportInput = {
      exportDescription: stampDescription,
      entityDelete: entityDeleteRows || [],
      corporatePeriodId: selectedAccountingOption,
      isDistributionIncluded: includeAccDistro,
    };
    const { errors, data } = await tripBreakdownExport({
      variables: {
        input: exportVariable,
      },
    });
    if (!errors) {
      const { exportResult } = { ...data?.tripBreakdownExport };
      if (
        exportResult?.isCreateLocalFilesRequired &&
        exportResult?.exportCycles
      ) {
        const idArray = exportResult.exportCycles.map((item) => item as string);
        transferCycle({
          variables: {
            idArray,
          },
        });
      }
    }
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
      setStampDescription('');
    }
  };

  const accountingPeriodOptions = useMemo(
    () =>
      accoutringPeriods?.companyCorporatePeriods?.nodes.map((item) => ({
        disabled: item.isEntryAllowed ? false : true,
        key: item.id,
        text:
          item._periodYear +
            ' (' +
            dateFormat(dateConvertions(item.startDate)) +
            ' - ' +
            dateFormat(dateConvertions(item.endDate)) +
            ')' || '',
      })) || [],
    [accoutringPeriods]
  );

  useEffect(() => {
    const extractData = (str: string) => {
      return str.split('"').filter((e, i) => i & 1);
    };
    nextCycleData?.transferCycles?.nodes?.map((item, index) => {
      const csvFormattedData: string[][] = [];
      if (item) {
        if (csvLink?.current) {
          setTimeout(() => {
            setCsvFileName(item?._exportFilename!);
            csvFormattedData.push(extractData(item?.transferHeaders!));
            item?.transactionTransfers?.nodes?.map((col) =>
              csvFormattedData.push(extractData(col?.transferValues!))
            );
            setCsvData(csvFormattedData!);
            setStampDescription('');
            csvLink.current?.link?.click();
          }, index * 500);
        }
      }
    });
    if (!!nextCycleData?.transferCycles?.nodes.length) {
      addToast('CSV Exported', {
        appearance: 'info',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextCycleData]);

  useEffect(() => {
    setSelectedOption(accountingPeriodOptions[0]?.key);
  }, [accountingPeriodOptions]);

  return (
    <>
      {!!selectedRows.length && (
        <PrimaryButton
          text="Export"
          onClick={() => {
            toggleConfirmDialog();
          }}
          iconProps={exportIcon}
        />
      )}
      {csvData && (
        <CSVLink
          data={csvData}
          filename={csvFileName}
          target="_blank"
          ref={csvLink}
        ></CSVLink>
      )}
      {
        <ConfirmDialog
          isConfirmPrimaryButton
          hidden={hideConfirmRequestDialog}
          title={STAMPING_TITLE}
          onDismiss={toggleConfirmDialog}
          minWidth={500}
          onConfirm={handleConfirmPress}
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <TextField
              label="Description"
              className={styles.description}
              rows={3}
              value={stampDescription}
              onChange={(_event, value) => setStampDescription(value || '')}
              placeholder="Worksheet description"
              resizable={true}
            />
            <CustomDropdown
              label="Accounting Period"
              placeholder="Select"
              selectedKey={selectedAccountingOption}
              options={accountingPeriodOptions}
              onChange={(_, option) => {
                setSelectedOption(option?.key + '');
              }}
              onClear={() => {
                setSelectedOption(null);
              }}
            />
            <Checkbox
              label="Include account distribution"
              onChange={(ev, value) => {
                if (ev) {
                  setIncludeAccDistro(value);
                }
              }}
            />
          </Stack>
        </ConfirmDialog>
      }
    </>
  );
};
