import { AccountingPeriodValues, PeriodRowValues } from "./types";

export const PERIOD_ROW_VALUES: PeriodRowValues = {
  id: null,
  fiscalYearId: null,
  fiscalPeriod: null,
  startDate: null,
  endDate: null,
  isPeriodOpen: null,
  isEntryAllowed: null,
  _isUpdatable: null,
  _isDeletable: null,
  _rowTimestamp: null,
}

export const ACCOUNTING_PERIOD_VALUES:AccountingPeriodValues = {
  id: null,
  _rowTimestamp: null,
  fiscalYear: null,
  startDate: null,
  endDate: null,
  isYearOpen: null,
  isEntryAllowed: null,
  companyCorporatePeriodsByFiscalYearId: [PERIOD_ROW_VALUES]
}