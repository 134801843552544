import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  iconsContainer: {
    alignItems: 'center',
  },
}));
