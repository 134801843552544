import { ActionButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import { FilterArrayType } from '../Filters';
import { AttachListBadge } from './Badge';
import { FiltersCallout } from './ListFilterCallout';
import { useQuery } from '@apollo/client';
import {
  DocumentPoolFiles,
  DocumentPoolFilesVariables,
} from 'common/graphql/__generated__/DocumentPoolFiles';
import { loader } from 'graphql.macro';
import { DocumentPoolsOrderBy } from 'common/types/globalTypes';
const DOCUMENT_POOL = loader('../../graphql/DocumentPool.graphql');
export type filterOptionProps = {
  filterTypes: FilterArrayType[];
  startsWith: boolean;
};

export interface AttachDocumentFiltersProps {
  filterOptions: filterOptionProps;
  onFilterChange: (filterOptions: filterOptionProps) => void;
}

export const AttachDocumentFilters: React.FC<AttachDocumentFiltersProps> = ({
  ...props
}) => {
  const calloutId = useId(`attach-filter-callOutId`);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const filterToggle = () => setFiltersApplied((prevState) => !prevState);
  const filtersCount = props?.filterOptions.filterTypes.length;
  const { data: documentPools } = useQuery<
    DocumentPoolFiles,
    DocumentPoolFilesVariables
  >(DOCUMENT_POOL,
    {
      variables: {
        orderBy: [DocumentPoolsOrderBy.NAME_ASC],
        filter: {
          defaultDocumentTypes: {
            isReportingDocument: {
              equalTo: false
            }
          }
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  return (
    <Stack horizontal>
      <AttachListBadge visible={filtersCount > 0} filtersCount={filtersCount}>
        <ActionButton
          onClick={filterToggle}
          id={calloutId}
          iconProps={{ iconName: filtersApplied ? 'ClearFilter' : 'Filter' }}
          text="Filters"
        />
      </AttachListBadge>

      {filtersApplied && (
        <FiltersCallout
          documentPools={documentPools}
          onDismiss={filterToggle}
          calloutId={calloutId}
          {...props}
        />
      )}
    </Stack>
  );
};
