import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  cellItemInitial: {
    border: `1px solid ${theme.palette.neutralQuaternary}`,
    borderRight: 'none',
    borderBottom: 'none',
    alignItems: 'start',
    justifyContent: 'start',
  },
  cellEnd: {
    borderRight: `1px solid ${theme.palette.neutralQuaternary}`,
  },
  cellBottom: {
    borderBottom: `1px solid ${theme.palette.neutralQuaternary}`,
  },
  link: {
    width: '100%',
    fontSize: theme.fonts.medium.fontSize,
    color: theme.palette.black,
    ':focus': {
      color: theme.palette.black,
    },
  },
  extendedTotal: {
    fontWeight: 700,
    color: theme.palette.themePrimary,
    fontSize: 16,
  },
  badge: {
    marginLeft: -18,
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: theme.palette.orangeLighter,
  },
  alignCenter: {
    width: '100%',
    alignItems: 'center',
  },
  alignRight: {
    width: '100%',
    alignItems: 'end',
  },
}));
