import { makeStyles } from "@fluentui/react";
export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px 25px"
  },
  formRow: {
    margin: "20px 0px"
  },
  fieldContainer: {
    flex: 1
  },
  width50: {
    width: "50%"
  }
}));