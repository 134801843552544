import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    marginTop: -5,
  },
  toggle: {
    margin: 0,
    paddingLeft: 24,
  },
  signatureCallout: {
    width: 250,
    height: 150,
    padding: 10,
  },
  ImageContainer: {
    width: 220,
    height: 90,
    borderRadius: 5,
    backgroundColor: 'white',
  },
  headerStyles: {
    alignItems: 'self-start',
    padding: 25,
    paddingBottom: 15,
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
  },
  footerStyles: {
    position: 'fixed',
    // width: '100%',
    width: 500,
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
  },
  container: {
    height: 680,
  },
  body: {
    paddingTop: 70,
    paddingBottom: 100,
  },
}));
