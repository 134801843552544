import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  itemCountStyle: {
    backgroundColor: theme.palette.themePrimary,
    height: 30,
    borderRadius: 10,
  },
  contentWrapper: {
    height: 25,
    alignSelf: 'center',
    borderRadius: 13,
    minWidth: 25,
    backgroundColor: theme.palette.themePrimary,
  },
  width10: {
    width: 10,
  },
  itemCount: {
    color: 'white',
  },
}));
