import React, { useState } from 'react';
import { Callout, SearchBox, Spinner, Stack, Text } from '@fluentui/react';
import { useStyles } from './index.styles';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GlobalSearchHints,
  GlobalSearchHintsVariables,
} from './__generated__/GlobalSearchHints';
import { Link } from 'react-router-dom';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';
import { globalMode } from 'utility/cache/ui';
import { getNavigationLink } from './utils';
import {
  transactionHistoryPivotTabs,
  transactionHistoryTabMode,
} from 'postingTracker/transactionHistory';
import { AppRouter } from 'common/types/globalTypes';
import { setPanelWidth } from 'common/components/DraggablePanel';
const GLOBAL_SEARCH = loader('./GlobalSearchHints.graphql');

export const GlobalSearch = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [searchInput, setSearchInput] = React.useState<string | undefined>();
  const [searching, setSearching] = useState<boolean>(false);

  const [
    fetchGlobalSearch,
    { loading: globalSearchLoading, data: globalSearchData },
  ] = useLazyQuery<GlobalSearchHints, GlobalSearchHintsVariables>(
    GLOBAL_SEARCH,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  const onBlur = () => {
    if (searchInput?.length === 0) setSearching(false);
  };

  const onChange = (value: string | undefined) => {
    setSearchInput(value);
  };

  const onSearch = (value: string | undefined) => {
    if (!searching) setSearching(true);
    if (value?.length) {
      fetchGlobalSearch({
        variables: {
          searchRequest: { searchText: [value] },
        },
      });
    }
  };

  const searchResultsTotal = globalSearchData?.globalSearchHints?.nodes
    .map((item) => item.resultsCount!)
    .reduce((total, item) => total + item, 0);

  const SearchLoader = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Text>Loading results</Text>
        <Spinner />
      </Stack>
    );
  };

  return (
    <Stack>
      <SearchBox
        className={styles.search}
        autoComplete="off"
        id={'expandable-search-box'}
        placeholder="Search..."
        onChange={(_, value) => onChange(value)}
        onBlur={onBlur}
        onSearch={(value) => onSearch(value)}
        styles={{
          root: {
            width: searchInput?.length === 0 || !searchInput ? 200 : 400,
            transition: 'width 0.167s ease 0s',
            '&.is-active': {
              width: 400,
            },
          },
        }}
      />
      {searching && searchInput && (
        <Callout
          onDismiss={() => setSearching(false)}
          gapSpace={10}
          isBeakVisible={false}
          className={styles.callout}
          target={'#expandable-search-box'}
        >
          <Stack className={styles.searchAreaContainer}>
            {globalSearchLoading ? (
              <SearchLoader />
            ) : (
              <>
                {globalSearchData?.globalSearchHints?.nodes.length! === 0 ? (
                  <Text>No results</Text>
                ) : (
                  <Stack tokens={{ childrenGap: 10 }}>
                    <Text block variant="large" className={commonStyles.bold}>
                      Search results: {searchResultsTotal}
                    </Text>
                    <Stack>
                      {globalSearchData?.globalSearchHints?.nodes.map(
                        (data, index) => {
                          return (
                            <Link
                              onClick={() => {
                                globalMode(true);
                                setPanelWidth(undefined);
                                setSearching(false);
                                if (
                                  data.linkType ===
                                  AppRouter.POSTED_TRANSACTIONS
                                ) {
                                  transactionHistoryTabMode(
                                    transactionHistoryPivotTabs.postedTransaction
                                  );
                                }
                              }}
                              to={getNavigationLink(
                                data.linkType!,
                                searchInput
                              )}
                              className={styles.resultItem}
                              key={index.toString()}
                            >
                              <Stack horizontal horizontalAlign="space-between">
                                <Text>{data.caption} </Text>
                                <Text
                                  className={clsx(
                                    commonStyles.colorThemePrimary,
                                    commonStyles.bold
                                  )}
                                >
                                  {data.resultsCount}
                                </Text>
                              </Stack>
                            </Link>
                          );
                        }
                      )}
                    </Stack>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};
