import {
  IDropdownOption,
  ITextFieldProps,
  IconButton,
  Label,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import {
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { formatDropdownOptions } from 'common/utils';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CorporateWorkgroupCommonData } from '../../__generated__/CorporateWorkgroupCommonData';
import { CorporateWorkgroupValues } from '../../types';

interface BusinessUnitOptionsType extends IDropdownOption {
  key: string | number;
  text: string;
  chartOfAccountId: GqlUUID | null;
}
interface BasicFormProps {
  commonData: CorporateWorkgroupCommonData | undefined;
}
const PREFIXCODE_TOOLTIP =
  'The prefix code is used to precede the Travel Authorization #';

export const BasicForm: React.FC<BasicFormProps> = ({ commonData }) => {
  const { setValue, trigger } = useFormContext<CorporateWorkgroupValues>(); // retrieve all hook methods

  const {
    companyBusinessUnits,
    corporateChartOfAccounts,
    productionPhases,
    productionStatuses,
  } = {
    ...commonData,
  };

  const businessUnitsOptions: BusinessUnitOptionsType[] =
    companyBusinessUnits?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
      chartOfAccountId: item.chartOfAccountId,
    })) || [];

  const productionStatusOptions = formatDropdownOptions(
    productionStatuses?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType || '',
      includeAll: false,
    }
  );

  const corporateChartOfAccountsOptions = formatDropdownOptions(
    corporateChartOfAccounts?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name || '',
      includeAll: false,
    }
  );

  const productionPhasesOptions = formatDropdownOptions(
    productionPhases?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.statusType || '',
      includeAll: false,
    }
  );
  const onRenderLabel = (props: ITextFieldProps | undefined): JSX.Element => {
    return (
      <>
        <Stack horizontal>
          <TooltipHost content={PREFIXCODE_TOOLTIP} id="tooltipId">
            <IconButton iconProps={{ iconName: 'info' }} />
          </TooltipHost>
          <Label disabled={props?.disabled} required>
            {props?.label}
          </Label>
        </Stack>
      </>
    );
  };

  const onBusinessUnitChange = (option: BusinessUnitOptionsType) => {
    setValue('chartOfAccountsId', option.chartOfAccountId || null);
    trigger();
  };

  return (
    <Stack
      tokens={{
        childrenGap: 20,
        padding: '10px 25px 0px',
      }}
    >
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="workgroupNumber"
            label="Workgroup Number"
            required
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField name="name" label="Name" required />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="description"
            label="Description"
            multiline
            rows={3}
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="prefixCode"
            label="Prefix Code"
            onRenderLabel={onRenderLabel}
            maxLength={10}
            required
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Business Unit"
            placeholder="Select"
            options={businessUnitsOptions}
            name="businessUnitId"
            onChange={(_e, option) => {
              const selectedOptions = option as BusinessUnitOptionsType;
              onBusinessUnitChange(selectedOptions);
            }}
            required
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Chart Of Accounts"
            placeholder="Select"
            options={corporateChartOfAccountsOptions}
            name="chartOfAccountsId"
            required
          />
        </Stack>
      </Stack>
      <Stack
        horizontal
        style={{ flex: 1 }}
        tokens={{
          childrenGap: 20,
        }}
      >
        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Production Phases"
            placeholder="Select"
            options={productionPhasesOptions}
            name="productionPhaseId"
          />
        </Stack>

        <Stack style={{ flex: 1 }}>
          <FormHookDropdown
            label="Production Status"
            placeholder="Select"
            options={productionStatusOptions}
            name="productionStatusTypeId"
            required
          />
        </Stack>
      </Stack>
      <FormHookCheckBox
        name="isNewWorkgroupSelectable"
        label="Allow selection On New TA"
      />
    </Stack>
  );
};
