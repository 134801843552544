import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  BatchTransaction,
  BatchTransactionVariables,
} from 'postingTracker/batchEdit/batch/view/__generated__/BatchTransaction';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormView } from './Form';
const BATCH_TRANSACTION = loader(
  '../../../batchEdit/batch/view/BatchTransaction.graphql'
);

export const TransactionHistoryBatchDetails = () => {
  const { batchId } = useParams<{ batchId: string | undefined }>();
  const { data: batchTransaction, loading: dataLoading } = useQuery<
    BatchTransaction,
    BatchTransactionVariables
  >(BATCH_TRANSACTION, {
    variables: {
      id: batchId!,
    },
    skip: !batchId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  return <FormView batchTransactionData={batchTransaction} dataLoading={dataLoading} />;
};
