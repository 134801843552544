import { useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  Chart,
  ChartProps,
  CustomizedAxisTick,
  getSecondaryShade,
  useStyles,
} from '../common';
import { loader } from 'graphql.macro';
import { DocumentsChartData } from './__generated__/DocumentsChartData';
import { getHoverColor } from 'common/utils/color';
import { useHistory } from 'react-router-dom';
import {
  DocumentAssignmentLocationState,
  DocumentAssignmentFilter,
} from 'utility/locationStates';

type ActiveIndex = {
  index: number;
  set: DocumentAssignmentFilter;
};

const query = loader('./DocumentsChartData.graphql');
const Component: React.FC<ChartProps<DocumentsChartData>> = ({
  data,
  height,
  width,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState<ActiveIndex | undefined>();
  const [animation, setAnimation] = useState(true);
  const history = useHistory<DocumentAssignmentLocationState>();

  const onClickCell = (state: DocumentAssignmentLocationState) =>
    history.push('/doc/documentAssignment/', state);

  return (
    <BarChart
      height={height}
      width={width}
      data={data?.kpiDocumentBreakdowns?.nodes}
      margin={{
        top: 20,
        right: 20,
        left: 0,
        bottom: 20,
      }}
    >
      <CartesianGrid
        strokeDasharray="3 3"
        stroke={theme.palette.neutralQuaternary}
      />
      <XAxis
        dataKey="name"
        interval={0}
        tick={
          <CustomizedAxisTick
            fill={theme.palette.neutralPrimary}
            width={140}
            maxLines={2}
          />
        }
      />
      <YAxis tick={{ fill: theme.palette.neutralPrimary }} />
      <Tooltip
        separator=": "
        contentStyle={{
          backgroundColor: theme.palette.white,
          borderColor: theme.palette.neutralLighter,
        }}
        cursor={{
          fill: theme.palette.neutralQuaternaryAlt,
          opacity: 0.4,
        }}
        offset={60}
        labelStyle={{ fontWeight: 600 }}
        isAnimationActive={false}
      />
      {/* <Legend /> */}
      <Bar
        dataKey="invoiceActiveCount"
        stackId="invoices"
        name="Active Transactions"
        className={styles.pointer}
        fill={theme.palette.blue}
        onMouseEnter={(_, index) =>
          setActiveIndex({ index, set: 'activeInvoice' })
        }
        onMouseLeave={(_, index) => {
          if (
            activeIndex?.set === 'activeInvoice' &&
            activeIndex?.index === index
          )
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiDocumentBreakdowns?.nodes.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              activeIndex?.set === 'activeInvoice' &&
              activeIndex?.index === index
                ? getHoverColor(theme.palette.blue, theme)
                : theme.palette.blue
            }
            onClick={() =>
              onClickCell({ poolId: entry.id, filter: 'activeInvoice' })
            }
          />
        ))}
      </Bar>
      <Bar
        dataKey="invoiceEntryCount"
        stackId="invoices"
        name="Entered Transactions"
        className={styles.pointer}
        fill={getSecondaryShade(theme.palette.blue)}
        onMouseEnter={(_, index) =>
          setActiveIndex({ index, set: 'enteredInvoice' })
        }
        onMouseLeave={(_, index) => {
          if (
            activeIndex?.set === 'enteredInvoice' &&
            activeIndex?.index === index
          )
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiDocumentBreakdowns?.nodes.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              activeIndex?.set === 'enteredInvoice' &&
              activeIndex?.index === index
                ? getHoverColor(getSecondaryShade(theme.palette.blue), theme)
                : getSecondaryShade(theme.palette.blue)
            }
            onClick={() =>
              onClickCell({ poolId: entry.id, filter: 'enteredInvoice' })
            }
          />
        ))}
      </Bar>
      <Bar
        dataKey="paymentActiveCount"
        stackId="payments"
        name="Active Payments"
        className={styles.pointer}
        fill={theme.palette.green}
        onMouseEnter={(_, index) =>
          setActiveIndex({ index, set: 'activePayment' })
        }
        onMouseLeave={(_, index) => {
          if (
            activeIndex?.set === 'activePayment' &&
            activeIndex?.index === index
          )
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiDocumentBreakdowns?.nodes.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              activeIndex?.set === 'activePayment' &&
              activeIndex?.index === index
                ? getHoverColor(theme.palette.green, theme)
                : theme.palette.green
            }
            onClick={() =>
              onClickCell({ poolId: entry.id, filter: 'activePayment' })
            }
          />
        ))}
      </Bar>
      <Bar
        dataKey="paymentEntryCount"
        stackId="payments"
        name="Entered Payments"
        className={styles.pointer}
        fill={getSecondaryShade(theme.palette.green)}
        onMouseEnter={(_, index) =>
          setActiveIndex({ index, set: 'enteredPayment' })
        }
        onMouseLeave={(_, index) => {
          if (
            activeIndex?.set === 'enteredPayment' &&
            activeIndex?.index === index
          )
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiDocumentBreakdowns?.nodes.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              activeIndex?.set === 'enteredPayment' &&
              activeIndex?.index === index
                ? getHoverColor(getSecondaryShade(theme.palette.green), theme)
                : getSecondaryShade(theme.palette.green)
            }
            onClick={() =>
              onClickCell({ poolId: entry.id, filter: 'enteredPayment' })
            }
          />
        ))}
      </Bar>
      <Bar
        dataKey="unusedDocumentCount"
        name="Available"
        className={styles.pointer}
        fill={theme.palette.orange}
        onMouseEnter={(_, index) => setActiveIndex({ index, set: 'available' })}
        onMouseLeave={(_, index) => {
          if (activeIndex?.set === 'available' && activeIndex?.index === index)
            setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiDocumentBreakdowns?.nodes.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              activeIndex?.set === 'available' && activeIndex?.index === index
                ? getHoverColor(theme.palette.orange, theme)
                : theme.palette.orange
            }
            onClick={() =>
              onClickCell({ poolId: entry.id, filter: 'available' })
            }
          />
        ))}
      </Bar>
    </BarChart>
  );
};

export const DocumentsChart: Chart<DocumentsChartData> = {
  query,
  Component,
  hasData: (data) => !!data.kpiDocumentBreakdowns?.nodes.length,
};
