import { useLazyQuery } from '@apollo/client';
import { ProgressIndicator, Separator, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import { TransactionLayout } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ResourceType } from '../constant';
import { CarbonAccountingValues, EnvironmentalItemsRowValues } from '../types';
import { EntityEnvironmentalItemsRow } from './EntityEnvironmentalItemsRow';
import { useStyles } from './index.styles';
import {
  EnvironmentalMetrics,
  EnvironmentalMetricsVariables,
  EnvironmentalMetrics_environmentalMetrics_nodes,
} from 'common/graphql/__generated__/EnvironmentalMetrics';
const GET_ENVIRONMENT_CATEGORY = loader(
  '../../../../../common/graphql/EnvironmentalMetrics.graphql'
);
interface EnvironmentalItemsViewProps {
  categoryId: string;
  layoutType: TransactionLayout | undefined;
  isEnvironmentalsExist: boolean;
}
interface EnvironmentalMetricItemsType {
  environmentalImpact: string | undefined;
  environmentalImpactTypeId: number | undefined;
  isIntegerFormat: boolean | null | undefined;
  isFloatFormat: boolean | null | undefined;
  isAmountFormat: boolean | null | undefined;
}
export interface EnvironmentalMetricDataType {
  environmentalMetricItems: EnvironmentalMetricItemsType[];
}
export const EnvironmentalItemsView: React.FC<EnvironmentalItemsViewProps> = ({
  categoryId,
  layoutType,
  isEnvironmentalsExist,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { control, setValue } = useFormContext<CarbonAccountingValues>();

  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'entityEnvironmentalItems', // unique name for your Field Array
  });

  const [resourceColumn, setResourceColumn] = useState<string[]>([]);
  const [environmentalMetricData, setEnvironmentalMetricData] =
    useState<EnvironmentalMetricDataType>();

  const [
    getEnvironmentalMetrics,
    { data: environmentCategoryData, loading: environmentCategoryDataLoading },
  ] = useLazyQuery<EnvironmentalMetrics, EnvironmentalMetricsVariables>(
    GET_ENVIRONMENT_CATEGORY,
    {
      variables: {
        filter: {
          id: {
            equalTo: categoryId,
          },
        },
      },
    }
  );

  const transformEnvironmentalMetricData = (
    data: EnvironmentalMetrics_environmentalMetrics_nodes[]
  ) => {
    let environmentalMetricArray: EnvironmentalItemsRowValues[] = [];
    //Removed blank entityEnvironmentalItems rows to show previously populated entityEnvironmentalItems rows.
    const transformedArray: EnvironmentalMetricDataType[] = data.map((ele) => {
      return {
        environmentalMetricItems: ele.environmentalMetricItems.nodes.map(
          (item) => {
            if (!isEnvironmentalsExist) {
              environmentalMetricArray.push({
                environmentalImpactTypeId: item.environmentalImpact?.id!,
                feet: null,
                gallons: null,
                hours: null,
                kilogram: null,
                kilometers: null,
                kilowattHours: null,
                litres: null,
                meters: null,
                metricTonnes: null,
                miles: null,
                omissionsEquivalent: null,
                pounds: null,
                quantity: null,
                therms: null,
                tons: null,
                yards: null,
                carbonCreditsAmount: null,
                isIntegerFormat: item.environmentalImpact?.isIntegerFormat,
                isAmountFormat: item.environmentalImpact?.isAmountFormat,
                isFloatFormat: item.environmentalImpact?.isFloatFormat,
              });
            }
            return {
              environmentalImpact:
                item.environmentalImpact?.environmentalImpact,
              environmentalImpactTypeId: item.environmentalImpact?.id,
              isIntegerFormat: item.environmentalImpact?.isIntegerFormat,
              isFloatFormat: item.environmentalImpact?.isFloatFormat,
              isAmountFormat: item.environmentalImpact?.isAmountFormat,
            };
          }
        ),
      };
    });
    if (transformedArray) setEnvironmentalMetricData(transformedArray[0]);
    if (!isEnvironmentalsExist)
      setValue('entityEnvironmentalItems', environmentalMetricArray);
  };

  const handleEnvironmentalMetrics = () => {
    if (categoryId) {
      //create an array of resources from ResourceType array.
      getEnvironmentalMetrics({
        variables: { filter: { id: { equalTo: categoryId } } },
      });
      ResourceType.map((type) => {
        if (Object.keys(type).toString() === layoutType) {
          let nestedResourceColumnsArray = Object.values(type);
          let resourceColumnsArray: string[] = [];
          nestedResourceColumnsArray.map((item) => {
            if (item) {
              for (let i = 0; i < item.length; i++) {
                resourceColumnsArray.push(item[i]);
              }
            }
            return true;
          });
          setResourceColumn(resourceColumnsArray);
        }
        return true;
      });
    }
  };

  const handleEnvironmentalMetricsMemo = useCallback(
    handleEnvironmentalMetrics,
    [categoryId]
  );
  useEffect(() => {
    handleEnvironmentalMetricsMemo();
  }, [handleEnvironmentalMetricsMemo]);
  const handleEnvironmentalCategoryData = () => {
    if (environmentCategoryData?.environmentalMetrics) {
      transformEnvironmentalMetricData(
        environmentCategoryData.environmentalMetrics.nodes
      );
    }
  };
  const environmentCategoryDataMemo = useCallback(
    handleEnvironmentalCategoryData,
    [environmentCategoryData]
  );
  useEffect(() => {
    environmentCategoryDataMemo();
  }, [environmentCategoryDataMemo]);

  return (
    <>
      {environmentCategoryDataLoading && <ProgressIndicator />}
      {environmentalMetricData?.environmentalMetricItems.length! > 0 && (
        <Stack horizontal>
          <Stack className={styles.resourceMainContainer}>
            <Text className={commonStyles.semibold}>Resource</Text>
            <Separator />
            <Stack className={styles.resourceColumnContainer}>
              {environmentalMetricData?.environmentalMetricItems.map(
                (item, index) => {
                  return (
                    <Stack
                      key={item.environmentalImpactTypeId}
                      className={clsx(
                        styles.environmentalImpact,
                        index !== 0 && styles.marginTop5
                      )}
                    >
                      <Text className={commonStyles.semibold}>
                        {item.environmentalImpact}
                      </Text>
                    </Stack>
                  );
                }
              )}
            </Stack>
          </Stack>
          <Stack horizontal className={styles.resourceColumnMainContainer}>
            {resourceColumn.map((item, index) => {
              const capitalize = (word: string) => {
                const lower = word.toLowerCase();
                return word.charAt(0).toUpperCase() + lower.slice(1);
              };

              return (
                <Stack
                  className={clsx(
                    index !== 0 && styles.marginLeft10,
                    styles.resouce
                  )}
                  key={index}
                >
                  <Text className={styles.resourceType}>
                    {capitalize(item)}
                  </Text>
                  <Separator />
                  <Stack className={styles.resourceTypeDataMainContainer}>
                    <>
                      {fields &&
                        fields?.map((ele, index) => {
                          const environmentalItemsName = `entityEnvironmentalItems[${index}]`;

                          return (
                            <Stack
                              horizontal
                              key={index}
                              className={clsx(index !== 0 && styles.marginTop5)}
                            >
                              <EntityEnvironmentalItemsRow
                                fieldName={item}
                                data={ele}
                                environmentalItemsName={environmentalItemsName}
                                isIntegerFormat={ele.isIntegerFormat}
                                isFloatFormat={ele.isFloatFormat}
                                isAmountFormat={ele.isAmountFormat}
                              />
                            </Stack>
                          );
                        })}
                    </>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
    </>
  );
};
