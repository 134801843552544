import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  inputContainerLabel: {
    fontWeight: 'bold',
    marginLeft: 14,
    padding: 10,
    flex: 1,
  },
  textInputConatiner: {
    flex: 1,
    marginRight: 24,
    marginLeft: 24,
  },
  twoInputContainer: {
    height: 60,
  },
  currency: {
    marginRight: 6,
  },
}));
