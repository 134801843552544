import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  scrollboxWrapper: {
    width: '100%',
    overflowX: 'scroll',
    marginTop: 20,
  },
  marginL5: {
    marginLeft: 5,
  },
  mainContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  renderAccountingOptions: {
    marginTop: 10,
  },
  accountingRowMargin: {
    marginTop: '5px !important',
  },
  separator: {
    width: '1840px',
  },
  renderAccountingOptionsContainer: {
    marginLeft: 25,
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
  },
  removeIconContainer: {
    alignItems: 'center',
    width: 20,
  },
}));
