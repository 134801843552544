import { Callout, DirectionalHint, Stack, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React, { useState } from 'react';
import { RenderIcon } from './RenderIcon';
import { IconTypeFields } from './__generated__/IconTypeFields';
import { StatusTypeFields } from './__generated__/StatusTypeFields';
import { useStyles } from './index.styles';

type IconStatusData = IconTypeFields & StatusTypeFields;

interface ApprovalData {
  id: string;
  _pendingApprovers?: string | null;
}

export interface StatusIconProps {
  iconType: Partial<IconStatusData> | undefined;
  approval: boolean;
  approvalData?: ApprovalData;
}

export const StatusIcon: React.FC<StatusIconProps> = ({
  iconType,
  approval = false,
  approvalData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const calloutId = useId(`callOutId_Pending_Approvers${approvalData?.id!}`);

  return (
    <>
      <Stack
        verticalAlign="center"
        id={calloutId}
        onMouseEnter={() => setIsCalloutVisible(true)}
        onMouseLeave={() => setIsCalloutVisible(false)}
      >
        <>
          {
            <RenderIcon
              status={
                approval
                  ? iconType?.iconState!
                  : iconType?._transactionIconStatus!
              }
            />
          }
        </>
        {isCalloutVisible && iconType !== null && (
          <Callout
            className={styles.callout}
            gapSpace={0}
            target={`#${calloutId}`}
            directionalHint={DirectionalHint.leftCenter}
            onDismiss={() => setIsCalloutVisible(false)}
            setInitialFocus
          >
            <>
              <Stack className={styles.typeContainer}>
                <Text className={commonStyles.semibold} variant="smallPlus">
                  {!approval ? iconType?.statusType! : iconType?.iconType!}
                </Text>
                {approvalData?._pendingApprovers && (
                  <Text
                    className={clsx(commonStyles.semibold, styles.categoryType)}
                    variant="smallPlus"
                  >
                    {approvalData?._pendingApprovers}
                  </Text>
                )}
              </Stack>
            </>
          </Callout>
        )}
      </Stack>
    </>
  );
};
