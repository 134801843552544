type HierarchyNodeExtendedProps = {
  isBranch: boolean;
};
const getTreeGraph = (arr: any[] = []) => {
  let children: HierarchyNodeExtendedProps[] = [];
  let iterator = 0;
  while (iterator <= arr.length) {
    if (arr[iterator]) {
      if (arr[iterator].isBranch === true) {
        children.push(arr[iterator]);
      } else {
        let treeObj = arr[iterator];
        treeObj.children = getTreeGraph(
          arr.slice(iterator + 1) ? arr.slice(iterator + 1) : []
        );
        children.splice(children.length / 2, 0, treeObj);
        break;
      }
    } else break;
    iterator++;
  }
  return children;
};

export { getTreeGraph };
