import { IPalette } from '@fluentui/react';
import { StyleSheet } from '@react-pdf/renderer';

export const getStyles = (palette: IPalette) => {
  return StyleSheet.create({
    valueHeader: {
      fontSize: 10,
      color: palette.themePrimary,
    },
    keyHeader: {
      fontSize: 10,
    },
    paddingHor: {
      paddingHorizontal: 10,
    },
  });
};
