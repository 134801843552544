import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ApolloLink,
  ApolloClient,
  ApolloProvider,
  split,
  useReactiveVar,
} from '@apollo/client';
import {
  consoleErrorLink,
  getAuthLink,
  getHttpLink,
  getWebSocketLink,
} from 'utility/links';
import { _cache, currentUserRole } from 'utility/cache';
import { getMainDefinition } from '@apollo/client/utilities';
import { useRef } from 'react';
import { SentryLink } from 'apollo-link-sentry';
import { config } from 'config';

export const ApolloWrapper: React.FC = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const reactiveUserRole = useReactiveVar(currentUserRole);
  const client = useRef(
    new ApolloClient({
      cache: _cache,
      defaultOptions: {
        mutate: {
          errorPolicy: 'all',
        },
      },
    })
  );

  useEffect(() => {
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      getWebSocketLink(getAccessTokenSilently),
      getHttpLink()
    );

    const links: ApolloLink[] = [
      config?.sentry ? new SentryLink() : consoleErrorLink,
      getAuthLink(getAccessTokenSilently),
      splitLink,
    ];

    const composedLink = ApolloLink.from(links);
    client.current.setLink(composedLink);
  }, [reactiveUserRole, getAccessTokenSilently]);

  return (
    <ApolloProvider client={client.current}>{props.children}</ApolloProvider>
  );
};
