import { IDropdownOption, Stack, Text } from '@fluentui/react';
import {
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import React from 'react';
import { FormHookCorporateAccountAutoComplete } from 'settings/account/accountsEntry/components/FormHookCorporateAccountAutoComplete';
import { useStyles } from './index.styles';
import { loader } from 'graphql.macro';
import { AccountsCommonData } from './__generated__/AccountsCommonData';
import { useQuery } from '@apollo/client';
import { useFormContext, useWatch } from 'react-hook-form';
import { AccountEntryValues } from '../types';
import { useCommonStyles } from 'common/styles';

const ACCOUNT_COMMON_DATA = loader('./AccountsCommonData.graphql');

export const BasicForm: React.FC<{ corporateChartOfAccountId: string }> = ({
  corporateChartOfAccountId,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { control } = useFormContext<AccountEntryValues>();
  const departmentGroupId = useWatch({ name: 'departmentGroupId', control });
  const isTravelAccountForAllDepartments = useWatch({
    name: 'isTravelAccountForAllDepartments',
    control,
  });

  const { data: commonData } =
    useQuery<AccountsCommonData>(ACCOUNT_COMMON_DATA);

  const { departmentGroups, travelAccountTypes } = { ...commonData };

  const departmentGroupsOptions: IDropdownOption[] =
    departmentGroups?.nodes.map((item) => ({
      key: item.id,
      text: item.name,
    })) || [];

  const travelAccountTypesOptions: IDropdownOption[] =
    travelAccountTypes?.nodes.map((item) => ({
      key: item.id,
      text: item.accountType || '',
    })) || [];

  return (
    <Stack
      tokens={{
        padding: 20,
        childrenGap: 20,
      }}
      className={styles.container}
    >
      <Stack
        horizontal
        tokens={{
          childrenGap: 15,
        }}
      >
        <Stack className={styles.fieldContainer}>
          <FormHookTextField
            // projectAccount
            label="Account Number"
            name="lookupAccount"
            required
          />
        </Stack>
        <Stack className={styles.fieldContainer}>
          <FormHookTextField label="Name" name="lookupName" required />
        </Stack>
      </Stack>
      <Stack
        horizontal
        tokens={{
          childrenGap: 15,
        }}
      >
        <Stack className={styles.fieldContainer}>
          <FormHookTextField label="Set" name="setAccount" />
        </Stack>
        <Stack className={styles.fieldContainer}>
          {/* <FormHookTextField label="Project" name="projectAccount" /> */}
        </Stack>
      </Stack>
      <Stack>
        <FormHookCorporateAccountAutoComplete
          chartOfAccountId={corporateChartOfAccountId}
          label="Corporate Account"
          name="corporateAccount"
          id="account-auto-complete"
        />
      </Stack>

      <Stack className={styles.mt15}>
        <Stack horizontal>
          <Text
            variant="medium"
            className={commonStyles.semibold}
            style={{ flex: 1.5 }}
          >
            Departments
          </Text>
          <Stack
            style={{ flex: 2.5 }}
            tokens={{
              childrenGap: 10,
            }}
          >
            <FormHookDropdown
              placeholder="Select"
              options={departmentGroupsOptions}
              name="departmentGroupId"
              disabled={!!isTravelAccountForAllDepartments}
            />
            <FormHookCheckBox
              label="All Departments"
              name="isTravelAccountForAllDepartments"
              disabled={!!departmentGroupId}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack horizontal className={styles.mt20}>
        <Text
          variant="medium"
          className={commonStyles.semibold}
          style={{ flex: 1.5 }}
        >
          Travel Section
        </Text>
        <Stack style={{ flex: 2.5 }}>
          <FormHookDropdown
            placeholder="Select"
            options={travelAccountTypesOptions}
            name="travelAccountTypeId"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
