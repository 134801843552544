import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  badge: {
    width: 13,
    height: 13,
    borderRadius: 10,
    backgroundColor: theme.palette.orangeLighter,
    cursor: "pointer"
  },
  disabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  marginT10: {
    marginTop: 10,
  },
  approvalHistoryContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: FontWeights.bold,
  },
  stampButtonContainer:{
    width: 250
  },
  stamperViewContainer:{
    width: 250 
  },
  unStampButton:{
    backgroundColor:theme.palette.red
  }
}));