import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  amount: {
    textAlign: 'right',
    display: 'block',
  },
  amountTotal: {
    fontWeight: 'bold',
    color: theme.palette.themePrimary,
  },
  logo: {
    fill: theme.palette.green,
    alignSelf:'center'
  },
}));
