import { Stack } from '@fluentui/react';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import {
  ColumnData,
  SearchBar,
  SearchBarProps,
} from 'common/components/SearchBar';
import { formatDropdownOptions } from 'common/utils';
import React, { useState } from 'react';
import { FileCabinetCommonData } from '../__generated__/FileCabinetCommonData';
import { ToggleFilter } from 'common/components/Filters/ToggleFilter';
interface DocumentsSearchBar extends SearchBarProps {
  onHintsViewToggle?: () => void;
  columns: ColumnData[];
  onFilterChange: (filterArray: FilterArrayType[]) => void;
  onToggleVisibility?: (columns: ColumnData[]) => void;
  toggleActions?: () => void;
  fileCabinetCommonData: FileCabinetCommonData | undefined;
}
export const DocumentsSearchBar: React.FC<DocumentsSearchBar> = ({
  columns,
  onEnterPress,
  onRefresh,
  searchInputPlaceholderText,
  onToggleVisibility,
  urlParams,
  onFilterChange,
  onGlobalToggle,
  fileCabinetCommonData,
}) => {
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const currencyOptions = formatDropdownOptions(
    fileCabinetCommonData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.isoCode || '',
      includeAll: true,
    }
  );
  const documentTypeOptions = formatDropdownOptions(
    fileCabinetCommonData?.fileCabinetDocumentTypes?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.documentType || '',
      includeAll: true,
    }
  );
  const poolOptions = formatDropdownOptions(
    fileCabinetCommonData?.fileCabinetPools?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.name,
      includeAll: true,
    }
  );

  const processHistoryOptions = formatDropdownOptions(
    fileCabinetCommonData?.fileCabinetProcessHistories?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item.exportInfo ?? '',
      includeAll: true,
      multiSelect: true,
    }
  );
  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    const newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    onFilterChange?.(newFilters);
    setFilterTypes(newFilters);
  };
  return (
    <SearchBar
      onFilterClear={() => {
        if (filterTypes.length) onFilterChange([]);
        setFilterTypes([]);
      }}
      onGlobalToggle={onGlobalToggle}
      isGlobalAvailable={false}
      urlParams={urlParams}
      columns={columns}
      searchInputPlaceholderText={searchInputPlaceholderText}
      onEnterPress={onEnterPress}
      onRefresh={onRefresh}
      hintsAvailable={false}
      onToggleVisibility={onToggleVisibility}
    >
      <Stack
        verticalAlign="center"
        horizontal
        tokens={{ childrenGap: 20, padding: '0px 0px 0px 20px' }}
      >
        <DropdownFilter
          filterKey={'filterCurrencyId'}
          options={currencyOptions}
          placeholder={'Currency'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'filterDocumentTypeId'}
          options={documentTypeOptions}
          placeholder={'Document Type'}
          onOptionChange={onOptionChange}
        />
        <DropdownFilter
          filterKey={'filterFolderId'}
          options={poolOptions}
          placeholder={'Folder'}
          onOptionChange={onOptionChange}
        />

        <DropdownFilter
          filterKey={'filterProcessHistoryId'}
          options={processHistoryOptions}
          placeholder={'Exported'}
          onOptionChange={onOptionChange}
          width={150}
          maxHeight={400}
          multiSelect
        />
        <Stack verticalAlign="end" style={{ marginTop: 5 }}>
          <ToggleFilter
            placeholder="New"
            inlineLabel
            filterKey="isFilterUnprocessedDocuments"
            onOptionChange={onOptionChange}
          />
        </Stack>
      </Stack>
    </SearchBar>
  );
};
