import { EntityDocumentsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from './types';

type OrderLookup = {
  [direction in OrderDirection]: EntityDocumentsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    '_documentPoolName',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._DOCUMENT_POOL_NAME_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._DOCUMENT_POOL_NAME_DESC],
    },
  ],
  [
    'indexName',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_NAME_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_NAME_DESC],
    },
  ],
  [
    'indexReferenceNumber',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_REFERENCE_NUMBER_ASC],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.INDEX_REFERENCE_NUMBER_DESC,
      ],
    },
  ],
  [
    '_documentContents',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._DOCUMENT_CONTENTS_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._DOCUMENT_CONTENTS_DESC],
    },
  ],
  [
    'fileReference',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.FILE_REFERENCE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.FILE_REFERENCE_DESC],
    },
  ],
  [
    '_uploadDate',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._UPLOAD_DATE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._UPLOAD_DATE_DESC],
    },
  ],
  [
    'indexAmount',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_DESC],
    },
  ],
  [
    'available',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_DESC],
    },
  ],

  [
    'isoCode',
    {
      [OrderDirection.ASC]: [
        EntityDocumentsOrderBy.COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_ASC,
      ],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_DESC,
      ],
    },
  ],
  [
    'documentAppliedAmount',
    {
      [OrderDirection.ASC]: [
        EntityDocumentsOrderBy.DOCUMENT_APPLIED_AMOUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.DOCUMENT_APPLIED_AMOUNT_DESC,
      ],
    },
  ],
  [
    'Comment',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.COMMENT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.COMMENT_DESC],
    },
  ],
  [
    '_uploadDate',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._UPLOAD_DATE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._UPLOAD_DATE_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
    EntityDocumentsOrderBy.PRIMARY_KEY_DESC,
  ];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), ...orderByArray];
};
