import { makeStyles } from '@fluentui/react';
const RED_700 = '#D32F2F';
export const useStyles = makeStyles((theme) => ({
  categoryType: {
    color: theme.palette.themePrimary,
  },
  typeContainer: { alignItems: 'center', margin: '10px 20px', width: 150 },

  callout: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  onrenderColumnStack: {
    height: 25,
  },
  groupItemHeight: {
    height: 30,
    padding: 10,
  },
  backgroundWithIsAlert: {
    color: 'white',
    backgroundColor: RED_700,
    borderRadius: 5,
  },
  backgroundWithoutIsAlert: {
    borderRadius: 5,
    backgroundColor: theme.palette.themePrimary,
  },
  fontColor: {
    color: 'white',
  },
}));
