import {
  IChoiceGroupOption,
  IChoiceGroupStyles,
  Stack,
  Text,
} from '@fluentui/react';
import { FormHookTextField } from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { FormHookChoiceGroup } from 'common/components/FormHooksFields/FormHookChoiceGroup';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TripCreateFormValues } from '../../../../../types';
import { NotesHint } from './NotesHint';
import { useStyles } from './index.styles';
import { getPreferredSupplierLabel } from './utils';

const choiceGroupStyles: IChoiceGroupStyles = {
  label: {
    display: 'inline',
  },
  flexContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
};

type test = Omit<IChoiceGroupOption, 'key'> & {
  key: number;
};

const options: IChoiceGroupOption[] = [
  { key: '0', text: 'N/A' },
  { key: '1', text: 'Yes' },
  { key: '2', text: 'No' },
];

interface PreferredAreaProps {
  visible: boolean;
  tripItemIndex: number;
  selectedLayout: string | null;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
}

export const PreferredArea: React.FC<PreferredAreaProps> = ({
  visible,
  tripItemIndex,
  selectedLayout,
  travelAuthorizationData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const preferredSupplierLabel = getPreferredSupplierLabel(selectedLayout);
  const { control } = useFormContext<TripCreateFormValues>();
  const preferredRateTypeId = useWatch({
    control,
    name: `tripItems.${tripItemIndex}.preferredRateTypeId`,
  });
  const isExceptionRequired = useWatch({
    control,
    name: `tripItems.${tripItemIndex}.isExceptionRequired`,
  });

  const { _isUpdatable } = { ...travelAuthorizationData };

  return (
    <Stack>
      <Text variant="large" className={commonStyles.colorThemePrimary}>
        Preferred
      </Text>
      <Stack
        horizontal
        verticalAlign="end"
        tokens={{
          childrenGap: 20,
        }}
        style={{ marginTop: 5 }}
      >
        <Stack>
          <FormHookCheckBox
            name={`tripItems.${tripItemIndex}.isPreferredSupplier`}
            label={preferredSupplierLabel}
            disabled={!_isUpdatable}
          />
        </Stack>
        <Stack className={styles.preferredRate} tokens={{ childrenGap: 10 }}>
          <Text className={styles.groupHeading}>Rate</Text>
          <FormHookChoiceGroup
            name={`tripItems.${tripItemIndex}.preferredRateTypeId`}
            options={options}
            styles={choiceGroupStyles}
            value={preferredRateTypeId?.toString() || '0'}
            disabled={!_isUpdatable}
          />
        </Stack>
        <Stack>
          <FormHookCheckBox
            name={`tripItems.${tripItemIndex}.isExceptionRequired`}
            label="Exception Needed"
            disabled={!_isUpdatable}
          />
        </Stack>
      </Stack>
      {!!isExceptionRequired && (
        <Stack
          className={styles.notesContainer}
          horizontal
          tokens={{
            childrenGap: 4,
          }}
        >
          <NotesHint />
          <FormHookTextField
            name={`tripItems.${tripItemIndex}.exceptionComments`}
            placeholder="Enter notes"
            multiline
            rows={2}
            disabled={!_isUpdatable}
            className={styles.notes}
          />
        </Stack>
      )}
    </Stack>
  );
};
