import { Persona, PersonaSize, Stack, useTheme } from '@fluentui/react';
import React from 'react'
interface RecipientNameProps {
  name: string;
}
export const RecipientName: React.FC<RecipientNameProps> = ({ name }) => {
  const theme = useTheme()
  return (
    <Stack
      style={{
        backgroundColor: theme.palette.neutralLighter,
        padding: "5px 10px",
        borderRadius: 30
      }}
    >
      <Persona text={name} size={PersonaSize.size24} />
    </Stack>
  )
}
