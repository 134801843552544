import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom:25
  },
  carbonAccountContainer: {
    horizontalAlign: 'space-between',
  },
  footerStyle: {
    zIndex: 10,
    position: 'fixed',
    backgroundColor: theme.palette.neutralLighterAlt,
    bottom: 0,
    left:0,
    width: '100%',
    boxShadow: `-1px -4px 13px rgba(0, 0, 0, 0.15)`,
  },
  diabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  headerStyle: {
    zIndex: 10,
    padding: 25,
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.33)`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  headerStyle2: {
    zIndex: 10,
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.33)`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  approvalStatus: {
    color: theme.palette.themePrimary,
    fontSize: FontSizes.size18,
  },
  shimmerViewMainContainer: {
    marginTop: 30,
    marginBottom: 90,
  },
  footerActionsContainer: {
    justifyContent: 'space-between',
  },
  primaryButton: {
    margin: '0px 5px',
  },
  marginL5: {
    marginLeft: '5px !important',
  },
  marginL10: {
    marginLeft: 10,
  },
  marginT10: {
    marginTop: 10,
  },
  stickyFooterContainer: {
    paddingTop: 10,
  },
  fileListContainer: {
    margin: 20,
  },
  approvalHistoryContainer: {
    margin: '20px 0',
    paddingLeft: 25,
    paddingRight: 25,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  container: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
  requiredAttachmentsMessage:{
    paddingTop: 20
  }
}));
