import { useQuery } from '@apollo/client';
import { Separator, Stack } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { DocumentList } from 'common/components/DocumentList';
import { secureRowLevelNode } from 'common/components/SecureRowLevel';
import { loader } from 'graphql.macro';
import React from 'react';
import {
  Supplier_supplier
} from '../../__generated__/Supplier';
import { AttachForm } from './AttachForm';
import { UploadForm } from './UploadForm';
import { SupplierUploadDocument_supplierUploadDocument } from './UploadForm/__generated__/SupplierUploadDocument';
import {
  SupplierAvailableDocumentTypes
} from './__generated__/SupplierAvailableDocumentTypes';
import { columns } from './column.data';
import { useStyles } from './index.styles';
const SUPPLIER_AVAILABLE_DOCUMENT_TYPES = loader(
  './SupplierAvailableDocumentTypes.graphql'
);
interface AttachSectionProps {
  secureRowLevel: secureRowLevelNode[];
  supplier: Supplier_supplier;
  onUpload?: (
    fileSelected: File,
    documentData: SupplierUploadDocument_supplierUploadDocument,
    toastId: string
  ) => void;
  onRefetch?: () => void;
}
export const AttachSection: React.FC<AttachSectionProps> = ({
  secureRowLevel,
  supplier,
  onUpload,
  onRefetch
}) => {
  const styles = useStyles();
  const { data: availableDocumentTypes } =
    useQuery<
      SupplierAvailableDocumentTypes
    >(SUPPLIER_AVAILABLE_DOCUMENT_TYPES, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  return (
    <Stack
      tokens={{ childrenGap: 5 }}
      className={styles.mainStack}
    >
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10, padding: "0px 25px" }}
      >
        <AttachForm
          supplier={supplier}
          availableDocumentTypes={availableDocumentTypes}
        />

        <Separator vertical />
        <UploadForm
          availableDocumentTypes={availableDocumentTypes}
          supplier={supplier}
          onUpload={onUpload}
        />
      </Stack>
      {supplier?.entityDocumentsByEntityId?.nodes.length! > 0 && (
        <Stack
          tokens={{
            childrenGap: 10,
            padding: "0px 10px"
          }}
        >
          <DocumentList
            isNew={false}
            data={supplier?.entityDocumentsByEntityId?.nodes || []}
            isDeleteButtonVisible
            isShareAndProtectButtonsVisible
            secureRowLevel={secureRowLevel}
            onRemoveRow={onRefetch}
            columns={columns}
          />
        </Stack>
      )}
      <Stack tokens={{ padding: "0px 25px" }}>
        <NoDataView
          show={supplier?.entityDocumentsByEntityId.nodes.length === 0}
          title="No Attachments"
        />
      </Stack>

    </Stack>
  );
};
