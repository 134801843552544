import { ProgressIndicator } from '@fluentui/react';
import React from 'react';
import { Waypoint } from 'react-waypoint';

interface PaginationProps {
  showProgressIndicator: boolean;
  onLoadMore?: () => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  showProgressIndicator,
  onLoadMore,
}) => {
  if (showProgressIndicator)
    return (
      <Waypoint onEnter={onLoadMore}>
        <div style={{ height: 40 }}>
          <ProgressIndicator barHeight={4} />
        </div>
      </Waypoint>
    );
  else return null;
};
