import { makeStyles } from '@fluentui/react';
import { Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import React from 'react';

interface NoDataViewProps {
  show: boolean;
  title?: string;
}
export const NoDataView: React.FC<NoDataViewProps> = ({
  title = 'No data is available',
  show = false,
}) => {
  const classStyles = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <>
      {show && (
        <Stack className={classStyles.root}>
          <Text variant="medium" className={commonStyles.semibold}>
            {title}
          </Text>
        </Stack>
      )}
    </>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.palette.neutralLight,
    borderStyle: 'solid',
  },
}));
