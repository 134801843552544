import { NetworkStatus, useQuery } from '@apollo/client';
import { Stack, Sticky } from '@fluentui/react';
import DraggablePanel from 'common/components/DraggablePanel';
import { PanelHeader } from 'common/components/PanelHeader';
import { MESSAGES_PER_PAGE } from 'common/constants';
import { PanelCommonProps, PANEL_COMMON_WIDTH } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect } from 'react';
import { useStyles } from './index.styles';
import { MessagesSections, PivotKeys } from './MessagesSections';
import { NewMessage } from './NewMessage';
import {
  GetUserMessages,
  GetUserMessagesVariables
} from './__generated__/GetUserMessages';
import { UserMessageCounts } from './__generated__/UserMessageCounts';
const GET_USER_MESSAGES = loader('./GetUserMessages.graphql');
const USER_MESSAGE_COUNTS = loader('./UserMessageCounts.graphql');

export interface GroupData {
  name?: string | null;
  count?: number;
  isCollapsed?: boolean;
  key?: string | null;
  level?: number;
  startIndex?: number;
  minWidth?: number;
  userID?: string | null;
  children?: ChilderenData[];
  id?: string | null;
}
export interface ChilderenData {
  groupName: string | null;
  label: string | null;
  name: string | null;
  userID: string | null;
  value: string | null;
  id: string | null;
}
interface MessagingViewProps {
  isOpenPanel: boolean;
  onPanelDismiss: () => void;
}

export const MessagingView: React.FC<MessagingViewProps> = ({
  onPanelDismiss,
  isOpenPanel,
}) => {
  const styles = useStyles();

  const [selectedKey, setSelectedKey] = React.useState<string>(
    PivotKeys.received
  );

  const onDismiss = () => {
    onPanelDismiss();
  };

  const onRenderHeader = () => {
    return (
      <PanelHeader
        title={'Messages'}
        panelIcon={'Message'}
        onClose={onDismiss}
      />
    );
  };

  const { data: userMessageCountsData, refetch: refetchUserMessageCount } =
    useQuery<UserMessageCounts>(USER_MESSAGE_COUNTS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const {
    loading: loadingMessageList,
    data: messageListData,
    variables: messageListVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<GetUserMessages, GetUserMessagesVariables>(GET_USER_MESSAGES, {
    variables: {
      first: MESSAGES_PER_PAGE,
      filter: { _isIncomingMessage: { equalTo: true } },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const onListChange = useCallback(
    async (listName?: string) => {
      if (listName) setSelectedKey(listName);
      if (listName === 'RECEIVED') {
        await refetch({
          ...messageListVariables,
          filter: {
            _isIncomingMessage: { equalTo: true },
          },
        });
      } else {
        await refetch({
          ...messageListVariables,
          filter: {
            _isOutgoingMessage: { equalTo: true },
          },
        });
      }
    },
    [refetch, messageListVariables]
  );
  const refetchCallBack = useCallback(refetchUserMessageCount, []);

  useEffect(() => {
    refetchCallBack();
  }, [refetchCallBack]);

  const loadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        ...messageListVariables,
        after: messageListData?.userMessages?.pageInfo.endCursor,
      },
    });
  }, [fetchMore, messageListVariables, messageListData]);

  const data =
    networkStatus === NetworkStatus.setVariables
      ? undefined
      : messageListData?.userMessages?.nodes;

  return (
    <Stack>
      <DraggablePanel
        {...PanelCommonProps}
        initialWidth={PANEL_COMMON_WIDTH}
        minWidth={PANEL_COMMON_WIDTH}
        onRenderHeader={onRenderHeader}
        isOpen={isOpenPanel}
        onDismiss={() => {
          onDismiss();
        }}
        isLightDismiss
      >
        <Sticky>
          <Stack>
            <MessagesSections
              messagesReceived={
                userMessageCountsData?.userMessageCounts?.messagesReceived!
              }
              repliesReceived={
                userMessageCountsData?.userMessageCounts?.repliesReceived!
              }
              data={data || []}
              loadingMessages={loadingMessageList}
              onListChange={onListChange}
              onLoadMore={loadMore}
              hasNextPage={messageListData?.userMessages?.pageInfo?.hasNextPage}
              defaultList={selectedKey}
            >
              <Stack className={styles.createNewMessageContainer}>
                <NewMessage
                  onMessageCreate={() => setSelectedKey(PivotKeys.sent)}
                />
              </Stack>
            </MessagesSections>
          </Stack>
        </Sticky>
      </DraggablePanel>
    </Stack>
  );
};
