import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  disabledText: {
    color: `${theme.palette.neutralTertiary}${theme.isInverted ? '55' : ''}`,
  },
  columnHeight: {
    height: 25,
  },
  distributionColor: {
    color: theme.palette.themePrimary,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
}));
