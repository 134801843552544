import {
  DatePicker,
  IconButton,
  IDatePicker,
  IDatePickerProps,
  makeStyles,
  Stack,
} from '@fluentui/react';
import React from 'react';
interface CustomDatePickerProps extends IDatePickerProps {
  onClear: () => void;
}
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: '0px 4px',
  },
}));
export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  onClear,
  ...props
}) => {
  const styles = useStyles();
  const datePickerRef = React.createRef<IDatePicker>();
  const onRemoveFocus = () => datePickerRef.current!.reset();
  return (
    <Stack
      horizontal
      // verticalAlign="baseline"
      verticalAlign="end"
      tokens={{ childrenGap: 4 }}
    >
      <Stack.Item grow={1}>
        <DatePicker componentRef={datePickerRef} {...props} />
      </Stack.Item>
      <Stack>
        {props.value && !props.isRequired && !props.disabled && (
          <IconButton
            type="button"
            onClick={() => {
              onRemoveFocus();
              onClear();
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        )}
      </Stack>
    </Stack>
  );
};
