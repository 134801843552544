import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  NotesSection,
  NotesSectionProps,
} from 'common/components/NotesSection';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import {
  NoteTripCreate,
  NoteTripCreateVariables,
} from './__generated__/NoteTripCreate';
import { useFormContext } from 'react-hook-form';
import { TripCreateFormValues } from '../../../types';
const NOTE_TRIP_CREATE = loader('./NoteTripCreate.graphql');

type NotesProps = Pick<NotesSectionProps, 'listRefreshRequest'> & {
  id: string;
  data: NotesEntityFields[];
  loading?: boolean;
};

export const Notes: React.FC<NotesProps> = ({
  id,
  listRefreshRequest,
  data,
  loading,
}) => {
  const { formState: { isDirty }, } = useFormContext<TripCreateFormValues>();
  const [createNote, { loading: createNoteInProgress, error }] = useMutation<
    NoteTripCreate,
    NoteTripCreateVariables
  >(NOTE_TRIP_CREATE);

  const noteAddMutation: NoteAddMutationProps = {
    loading: createNoteInProgress,
    createNote: async (formData) => {
      const { noteComment, isShared } = { ...formData };
      await createNote({
        variables: {
          input: {
            note: {
              entityId: id,
              noteComment: noteComment!,
              isShared: isShared!,
            },
          },
        },
      });
      if (!error) listRefreshRequest?.();
      return error;
    },
  };
  return (
    <NotesSection
      dataLoading={loading!}
      noteAddMutation={noteAddMutation}
      addingNoteInProgress={createNoteInProgress}
      listRefreshRequest={listRefreshRequest}
      data={data}
      isDirty={isDirty}
    />
  );
};
