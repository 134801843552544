import { Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { REQ_COND_COLUMNS } from '..';
import { useStyles } from './index.styles';

export const RequestorConditionsColumns: React.FC = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack
      horizontalAlign="center"
      className={styles.renderAccountingOptionsContainer}
      horizontal
    >
      {Object.values(REQ_COND_COLUMNS).map((item, index) => {
        return item.title === 'Amount From' ? (
          <Text
            key={index}
            style={{
              marginLeft: index === 0 ? 0 : 5,
              width: item.width,
            }}
            className={commonStyles.semibold}
          >
            {item.title}
          </Text>
        ) : item.title === 'Amount To' ? (
          <Text
            key={index}
            style={{
              marginLeft: index === 0 ? 0 : 5,
              width: item.width,
            }}
            className={commonStyles.semibold}
          >
            {item.title}
          </Text>
        ) : item.title === 'Auto Approved' || item.title === 'AND' ? (
          <Text
            key={index}
            style={{
              textAlign: 'center',
              width: item.width,
            }}
            className={commonStyles.semibold}
          >
            {item.title}
          </Text>
        ) : (
          <Text
            key={index}
            style={{
              marginLeft: index === 0 ? 0 : 5,
              width: item.width,
            }}
            className={commonStyles.semibold}
          >
            {item.title}
          </Text>
        );
      })}
    </Stack>
  );
};
