import { useMutation, useReactiveVar } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { DangerButton } from 'common/components/DangerButton';
import { DocumentPoolFiles_documentPools_nodes } from 'common/graphql/__generated__/DocumentPoolFiles';
import { DocumentPoolsOrderBy } from 'common/types/globalTypes';
import { GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes } from 'documents/__generated__/GetDocumentPoolCommonData';
import {
  DocumentPoolDelete,
  DocumentPoolDeleteVariables,
} from 'documents/documentsPool/list/__generated__/DocumentPoolDelete';
import { DocumentTypeOptions } from 'documents/personalPool/UploadDocumentsForm';
import { loader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { DocumentAssignmentLocationState } from 'utility/locationStates';
import { GroupHeader } from '../../GroupHeader';
import { nodeMapType } from '../../utils';
import {
  DocumentFolderUpdate,
  DocumentFolderUpdateVariables,
} from '../__generated__/DocumentFolderUpdate';
import {
  DocumentPoolSubFolderCreate,
  DocumentPoolSubFolderCreateVariables,
} from '../__generated__/DocumentPoolSubFolderCreate';
import { FolderUpdateModal } from './FolderUpdateModal';
import { RightClickMenu } from './RightClickMenu';
import { GroupSubFolder } from './Subfolder';
import { useStyles } from './index.styles';
import { FolderUpdateValues } from './types';
import { setActivePoolDocument } from 'utility/cache/ui';

const CREATE_SUBFOLDER = loader('../DocumentPoolSubFolderCreate.graphql');
const UPDATE_DOCUMENT_POOL = loader('../DocumentFolderUpdate.graphql');
const DELETE_DOCUMENT_POOL = loader(
  '../../../../../documentsPool/list/DeleteDocumentPool.graphql'
);
const DOCUMENT_POOL_DATA = loader(
  '../../../../../../common/graphql/DocumentPool.graphql'
);
interface LeftPanelProps {
  DocumentFilesData: nodeMapType[] | undefined;
  setDocumentOptions: React.Dispatch<
    React.SetStateAction<DocumentTypeOptions[]>
  >;
  filterDocumentOptions: (
    documentPoolItem: DocumentPoolFiles_documentPools_nodes
  ) =>
    | {
        key: number;
        text: string;
        isAccountingDocument: boolean;
        isSigningRequired: boolean | null;
        extractionTypes: GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes;
      }[]
    | undefined;
  withoutFilterDocumentOptions: (
    documentPoolItem: DocumentTypeOptions[]
  ) => DocumentTypeOptions[];
  setDefaultDocumentTypeId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setIsDocumentTypeSelector: React.Dispatch<React.SetStateAction<boolean>>;
  setExtractionTypeId: React.Dispatch<React.SetStateAction<number | null>>;
  _headerOnClick: (
    poolId: string,
    documentPoolItem: nodeMapType,
    filterRouteState?: string | undefined
  ) => void;
}

export const LeftPanel: React.FC<LeftPanelProps> = ({
  DocumentFilesData,
  setDocumentOptions,
  filterDocumentOptions,
  withoutFilterDocumentOptions,
  setDefaultDocumentTypeId,
  setIsDocumentTypeSelector,
  setExtractionTypeId,
  _headerOnClick,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
const activeDocument=useReactiveVar(setActivePoolDocument)
  const [parentId, setParentId] = useState<string>(activeDocument?.id!);
  const [parentPool, setParentPool] = useState<nodeMapType>(activeDocument!);

  const history = useHistory<DocumentAssignmentLocationState | undefined>();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [action, setAction] = useState<'CREATE' | 'UPDATE' | 'DELETE'>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [createSubFolder, { loading: createDocumentPoolLoading }] = useMutation<
    DocumentPoolSubFolderCreate,
    DocumentPoolSubFolderCreateVariables
  >(CREATE_SUBFOLDER, { errorPolicy: 'all' });
  const [updateDocumentPool, { loading: updateDocumentPoolLoading }] =
    useMutation<DocumentFolderUpdate, DocumentFolderUpdateVariables>(
      UPDATE_DOCUMENT_POOL,
      { errorPolicy: 'all' }
    );
  const [documentPoolDelete] = useMutation<
    DocumentPoolDelete,
    DocumentPoolDeleteVariables
  >(DELETE_DOCUMENT_POOL, { errorPolicy: 'all' });

  const handleClickCreateSubFolder = async (values: FolderUpdateValues) => {
    const { errors } = await createSubFolder({
      variables: {
        input: {
          parentPoolId: activeDocument?.id||'',
          documentPool: {
            name: values.name!,
            extractionTypeId: values.extractionTypeId,
            userGroupId: values.userGroupId,
            defaultBusinessUnitId: values.defaultBusinessUnitId,
            defaultDepartmentId: values.defaultDepartmentId,
          },
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DOCUMENT_POOL_DATA,
          variables: {
            orderBy: [DocumentPoolsOrderBy.NAME_ASC],
            filter: {
              defaultDocumentTypes: {
                isReportingDocument: {
                  equalTo: false,
                },
              },
            },
          },
        },
      ],
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Folder created successfully', {
        appearance: 'success',
      });
      setShowModal(false);
    }
  };

  const handleClickUpdateSubFolder = async (values: FolderUpdateValues) => {
    const { errors } = await updateDocumentPool({
      variables: {
        input: {
          documentPoolsUpdate: [
            {
              id: activeDocument?.id,
              rowTimestamp: activeDocument?._rowTimestamp,
              documentPoolPatch: {
                name: values.name!,
                extractionTypeId: values.extractionTypeId,
                userGroupId: values.userGroupId,
                defaultBusinessUnitId: values.defaultBusinessUnitId,
                defaultDepartmentId: values.defaultDepartmentId,
              },
            },
          ],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DOCUMENT_POOL_DATA,
          variables: {
            orderBy: [DocumentPoolsOrderBy.NAME_ASC],
            filter: {
              defaultDocumentTypes: {
                isReportingDocument: {
                  equalTo: false,
                },
              },
            },
          },
        },
      ],
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Folder updated successfully', {
        appearance: 'success',
      });
      setShowModal(false);
    }
  };
  const _documentPoolDelete = async () => {
    const { errors } = await documentPoolDelete({
      variables: {
        input: {
          entityDelete: [
            {
              id: activeDocument?.id!,
              rowTimestamp: activeDocument?._rowTimestamp ?? '',
            },
          ],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DOCUMENT_POOL_DATA,
          variables: {
            orderBy: [DocumentPoolsOrderBy.NAME_ASC],
            filter: {
              defaultDocumentTypes: {
                isReportingDocument: {
                  equalTo: false,
                },
              },
            },
          },
        },
      ],
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else {
      addToast('Folder deleted successfully', {
        appearance: 'success',
      });
      setActivePoolDocument(parentPool);
    }
  };
  useEffect(
    () => {
      if (history?.location?.state?.poolId) {
        scrollToRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        setParentId(history?.location?.state?.poolId);
        const documentPoolItem = DocumentFilesData?.find(
          (item) => item.id === history?.location?.state?.poolId
        );
        if (documentPoolItem) {
          setParentPool(documentPoolItem);
          setActivePoolDocument(documentPoolItem);
          _headerOnClick(
            history?.location?.state?.poolId,
            documentPoolItem,
            history.location.state.filter
          );
        }
        // we only want to use location state for initial scroll after data load, not any
        // subsequent renders, so this clears it
        history.replace(history.location.pathname, undefined);
      } else {
        if (activeDocument?.defaultDocumentTypeId) {
          if (activeDocument.isDocumentTypeSelector) {
            setDocumentOptions(filterDocumentOptions(activeDocument) || []);
          } else setDocumentOptions(withoutFilterDocumentOptions);
        } else {
          setDocumentOptions(withoutFilterDocumentOptions);
        }
        setDefaultDocumentTypeId(activeDocument?.defaultDocumentTypeId!);
        setIsDocumentTypeSelector(activeDocument?.isDocumentTypeSelector!);
        setExtractionTypeId(activeDocument?.extractionTypeId!);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [DocumentFilesData]
  );

  const handleRightClickMenu = (value: 'CREATE' | 'UPDATE' | 'DELETE') => {
    setAction(value);
    if (value === 'DELETE') setShowDeleteModal(true);
    else setShowModal(true);
  };

  return (
    <Stack
      grow
      tokens={{ childrenGap: 10 }}
      className={styles.leftPanelContainer}
    >
      {showModal && activeDocument && (
        <FolderUpdateModal
          selectedPool={activeDocument}
          setShowModal={setShowModal}
          title={action === 'CREATE' ? 'Create Folder' : 'Edit Folder'}
          isEdit={action === 'UPDATE'}
          onClick={(values) => {
            action === 'CREATE'
              ? handleClickCreateSubFolder(values)
              : handleClickUpdateSubFolder(values);
          }}
          isLoading={createDocumentPoolLoading || updateDocumentPoolLoading}
        />
      )}
      {showDeleteModal && (
        <Dialog
          hidden={false}
          dialogContentProps={{
            title: `Delete Document`,
            type: DialogType.normal,
            subText: 'Are you sure you want to delete this folder?',
          }}
        >
          <Stack>
            <DialogFooter>
              <DangerButton
                onClick={() => {
                  _documentPoolDelete();
                  setShowDeleteModal(false);
                }}
                text={'Delete'}
              />
              <DefaultButton
                onClick={() => {
                  setShowDeleteModal(false);
                }}
                text="Cancel"
              />
            </DialogFooter>
          </Stack>
        </Dialog>
      )}
      <Stack>
        {DocumentFilesData?.map((documentPool, index) => {
          return (
            <Stack key={index.toString()}>
              <div
                ref={
                  documentPool.id === history.location.state?.poolId
                    ? scrollToRef
                    : undefined
                }
              >
                <Stack
                  style={{ marginTop: 5 }}
                  className={clsx(
                    documentPool.id === activeDocument?.id &&
                      styles.groupSelected
                  )}
                >
                 { activeDocument && <RightClickMenu
                    id={documentPool.id}
                    selectedPool={activeDocument}
                    onClick={(value: 'CREATE' | 'UPDATE' | 'DELETE') => {
                      handleRightClickMenu(value);
                    }}
                  >
                    <GroupHeader
                      isOpen={parentId === documentPool.id}
                      documentPool={documentPool}
                      onHeaderClick={(documentPoolItem) => {
                        setActivePoolDocument(documentPoolItem);
                        setParentId(documentPoolItem.id);
                        setParentPool(documentPoolItem);
                        _headerOnClick(
                          documentPoolItem.id,
                          documentPoolItem as nodeMapType
                        );
                      }}
                    />
                  </RightClickMenu>}
                </Stack>
              </div>
              {parentId === documentPool.id &&
                documentPool?.children &&
                documentPool.children.length > 0 && (
                  <Stack className={styles.lightBackground}>
                    {documentPool.children?.map((childNode: nodeMapType) => {
                      const historyCount =
                        childNode?._subfolderDocumentFileCount
                          ?.selectedDocuments;
                      return (
                        <Stack
                          className={clsx(
                            childNode.id === activeDocument?.id &&
                              styles.groupSelected
                          )}
                        >
                          <TooltipHost
                            content={
                              historyCount && historyCount > 0
                                ? `History Documents: ${historyCount?.toString()}`
                                : undefined
                            }
                            calloutProps={{ gapSpace: 4 }}
                          >
                            {activeDocument && (
                              <GroupSubFolder
                                key={childNode?.id}
                                documentPool={childNode}
                                onHeaderClick={(documentPoolItem) => {
                                  setActivePoolDocument(documentPoolItem);

                                  _headerOnClick(
                                    documentPoolItem.id,
                                    documentPoolItem
                                  );
                                }}
                                _headerOnClick={_headerOnClick}
                                selectedPool={activeDocument}
                                handleRightClickMenu={handleRightClickMenu}
                              />
                            )}
                          </TooltipHost>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
