import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    callout: {
      maxHeight: '500px !important',
      flexGrow: 1,
    },
    searchAreaContainer: {
      overflow: 'hidden',
      padding: 10,
      backgroundColor: theme.palette.neutralLighterAlt,
    },
    resultItem: {
      textDecoration: 'none',
      margin: '0px -20px',
      padding: '6px 20px',
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
      ':active': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
  };
});
