import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    height: '50vh',
  },
  loadingInnerContainer: {
    width: 300,
  },
  loadingText: {
    alignSelf: 'center',
  },
  statusBlock: {
    padding: '10px 25px 10px 25px',
    borderRadius: 10,
  },
}));