import React from 'react';
import { Text as RechartText, TextProps } from 'recharts';

type CustomizedAxisTickProps = Omit<TextProps, 'ref'> & {
  payload?: { value: string };
};

export const CustomizedAxisTick: React.FC<CustomizedAxisTickProps> = ({
  payload,
  ...props
}) => <RechartText {...props}>{payload?.value}</RechartText>;
