import { useQuery } from '@apollo/client';
import {
  Modal,
  PivotItem,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react';
import { HierarchyData } from 'ap/paymentCycle/view/TransactionModal/HierarchyData';
import { SignatureView } from 'common/components/Signatures';
import { StampOptions } from 'common/components/StamperView';
import { ApprovalState } from 'common/types/globalTypes';
import { GetDocumentDetails_fileCabinetHistoryTransaction_nodes } from 'documents/fileCabinet/folder/view/__generated__/GetDocumentDetails';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { HeaderComponent } from '../components/HeaderComponent';
import { PivotComponent, PivotItemsArray } from '../components/PivotComponent';
import {
  TravelPlanDetails,
  TravelPlanDetailsVariables,
} from './__generated__/TravelPlanDetails';
import { useStyles } from './index.styles';
import { TravelPlanDetail } from './TravelPlanDetail';
const TRAVEL_PLAN_DETAILS = loader('./TravelPlanDetails.graphql');
enum HeaderText {
  DETAILS = 'viewDetails',
  HIERARCHY = 'viewHierarchy',
  SIGNATURE = 'viewSignature',
}
const pivotItemsArray: PivotItemsArray[] = [
  {
    itemKey: HeaderText.DETAILS,
    headerText: 'Details',
  },
  {
    itemKey: HeaderText.HIERARCHY,
    headerText: 'Hierarchy',
  },
  {
    itemKey: HeaderText.SIGNATURE,
    headerText: 'Signature',
  },
];

interface TravelPlanModalProps {
  data: GetDocumentDetails_fileCabinetHistoryTransaction_nodes;
  onDismiss: () => void;
}
export const TravelPlanModal: React.FC<TravelPlanModalProps> = ({
  data,
  onDismiss,
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = useState<string>(HeaderText.DETAILS);
  const { entityId, linkType } = { ...data };

  const { data: travelPlanDetailsData, loading } = useQuery<
    TravelPlanDetails,
    TravelPlanDetailsVariables
  >(TRAVEL_PLAN_DETAILS, {
    variables: {
      id: entityId!,
    },
    skip: !entityId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const {
    id,
    statusType,
    _accountingStampDate,
    _accountingStampTransactionReference,
    _accountingStampUserName,
    _isAccountingEntryStampedComplete,
    _isTransactionCancelled,
    approvalHistorySignaturesByEntityId,
    stampedEntityDocumentId,
  } = { ...travelPlanDetailsData?.travelAuthorization };

  const stampData: StampOptions = {
    _accountingStampDate,
    _accountingStampTransactionReference,
    _accountingStampUserName,
    _isAccountingEntryStampedComplete,
    _isTransactionCancelled,
  };

  const isApproved = statusType?.approvalState === ApprovalState.APPROVED;

  const onLinkClick = (item?: PivotItem) => {
    if (item?.props.itemKey) setSelectedKey(item.props.itemKey);
  };

  const getSelectedSection = (key: string) => {
    switch (key) {
      case HeaderText.DETAILS:
        return (
          <Stack>
            <TravelPlanDetail
              travelAuthorization={travelPlanDetailsData?.travelAuthorization}
              loading={loading}
            />
          </Stack>
        );
      case HeaderText.HIERARCHY:
        return <HierarchyData entityId={id!} />;
      case HeaderText.SIGNATURE:
        return (
          <Stack tokens={{ padding: 20 }}>
            {!!approvalHistorySignaturesByEntityId?.nodes.length && (
              <SignatureView
                signatureData={approvalHistorySignaturesByEntityId?.nodes}
              />
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen isBlocking onDismiss={onDismiss}>
      <Stack className={styles.container}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <Stack
              className={styles.stickyInsideStack}
              tokens={{ childrenGap: 10 }}
            >
              <HeaderComponent
                entityId={entityId!}
                entityType={linkType!}
                heading={'Travel Authorization'}
                isApproved={isApproved}
                documentPackageId={stampedEntityDocumentId}
                stampData={stampData}
                onDismiss={onDismiss}
              />
              <PivotComponent
                pivotItems={pivotItemsArray}
                selectedKey={selectedKey}
                onLinkClick={onLinkClick}
              />
            </Stack>
          </Sticky>
          {getSelectedSection(selectedKey)}
        </ScrollablePane>
      </Stack>
    </Modal>
  );
};
