import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  headerStyle2: {
    zIndex: 10,
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.33)`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  headerContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  diabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  footerStyle: {
    zIndex: 10,
    backgroundColor: theme.palette.neutralLighterAlt,
    width: '100%',
    boxShadow: `-1px -4px 13px rgba(0, 0, 0, 0.15)`,
  },
  stickyFooterContainer: {
    paddingTop: 100,
  },
  tagsContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
  paddingTop10: {
    paddingTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  shimmerViewMainContainer: {
    marginTop: 30,
    marginBottom: 90,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
  fileListContainer: {
    margin: 20,
  },
  unstampButton:{
    backgroundColor:theme.palette.red
  }
}));
