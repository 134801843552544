import { useMutation } from '@apollo/client';
import { InvoiceRow } from 'ap/signing/types';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  InvoiceSigningSearchFilterTotals,
  InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals,
} from 'ap/signing/__generated__/InvoiceSigningSearchFilterTotals';
import { getSubtractedValue } from 'common/utils/columnUtilities';
import {
  InvoicesSigningSearch,
  InvoicesSigningSearch_invoiceSigningSearch,
  InvoicesSigningSearchVariables,
} from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoicesSigningSearch';
import {
  InvoiceSigningDelete,
  InvoiceSigningDeleteVariables,
} from 'common/graphql/__generated__/InvoiceSigningDelete';
const DELETE_TRANSACTION = loader(
  '../../../../../common/graphql/InvoiceSigningDelete.graphql'
);

interface DeleteTransactionProps {
  selectedRows: InvoiceRow[];
  invoicesData: InvoicesSigningSearch | undefined;
  invoicesFilterTotal: InvoiceSigningSearchFilterTotals | undefined;
  variables: InvoicesSigningSearchVariables | undefined;
}

export const DeleteTransaction: React.FC<DeleteTransactionProps> = ({
  invoicesData,
  invoicesFilterTotal,
  variables,
  selectedRows,
}) => {
  const [deleteTransaction] = useMutation<
    InvoiceSigningDelete,
    InvoiceSigningDeleteVariables
  >(DELETE_TRANSACTION, { errorPolicy: 'all' });

  const { addToast } = useToasts();

  const _deleteTransaction = async () => {
    const deletableData: EntityDeleteInput[] = selectedRows.map((obj) => {
      return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
    });
    const { errors } = await deleteTransaction({
      variables: { input: { entityDelete: deletableData } },
      update: (cache, { data }) => {
        const deletedIds = data?.invoiceSigningDelete?.deletedEntities?.map(
          (entity) => entity?.id
        );
        if (deletedIds) {
          const filteredList = invoicesData?.invoiceSigningSearch?.nodes.filter(
            (emp) => deletedIds.indexOf(emp.id) === -1
          );
          const deletedfilteredList =
            invoicesData?.invoiceSigningSearch?.nodes.filter(
              (emp) => deletedIds.indexOf(emp.id) !== -1
            );

          try {
            cache.modify({
              fields: {
                invoiceSigningSearchFilterTotals(
                  existing: InvoiceSigningSearchFilterTotals_invoiceSigningSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0) {
                    const controlTotalAmount = deletedfilteredList?.map(
                      (item) => item.controlTotalAmount
                    );
                    const baseCurrencyAmount = deletedfilteredList?.map(
                      (item) => item._baseCurrencyAmount
                    );
                    const spotCurrencyAmount = deletedfilteredList?.map(
                      (item) => item._spotCurrencyAmount
                    );
                    const newSum = getSubtractedValue(
                      existing?.totalAmount1,
                      controlTotalAmount
                    );
                    const baseCurrencyAmountSum = getSubtractedValue(
                      existing?.totalAmount2,
                      baseCurrencyAmount
                    );
                    const spotCurrencyAmountSum = getSubtractedValue(
                      existing?.totalAmount3,
                      spotCurrencyAmount
                    );
                    return {
                      ...existing,
                      totalAmount1: newSum.toString(),
                      totalAmount2: baseCurrencyAmountSum.toString(),
                      totalAmount3: spotCurrencyAmountSum.toString(),
                      totalCount1: (existing?.totalCount1 || 0) - 1,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
                invoiceSigningSearch(
                  existing: InvoicesSigningSearch_invoiceSigningSearch
                ) {
                  return {
                    ...existing,
                    nodes: filteredList!,
                    pageInfo: invoicesData?.invoiceSigningSearch?.pageInfo!,
                  };
                },
              },
            });
          } catch (error) {}
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
  };

  return (
    <>
      <ActionMessageModal
        entityType={EntityType.Transaction}
        action={EntityAction.Remove}
        disabled={!selectedRows.some((selected) => selected._isDeletable)}
        visible={selectedRows.length > 0}
        multiple={{
          validCount: selectedRows.filter((selected) => selected._isDeletable)
            .length,
          invalidNames: selectedRows
            .filter((selected) => !selected._isDeletable)
            .map(
              (cannotDelete) =>
                cannotDelete.invoiceNumber ||
                `(${cannotDelete.transactionType?.transactionType} of ${cannotDelete.controlTotalAmount})`
            ),
        }}
        onConfirm={async () => await _deleteTransaction()}
      />
    </>
  );
};
