import { DetailsList, DetailsRow, FontWeights, IColumn, IDetailsFooterProps, IRenderFunction, SelectionMode, Stack, Text, makeStyles } from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import Decimal from 'decimal.js';
import React from 'react';
import { TravelPlanDetails_travelAuthorization_trips, TravelPlanDetails_travelAuthorization_trips_nodes } from '../../__generated__/TravelPlanDetails';
import { columns } from './columns';

const useStyles = makeStyles(() => ({
  amount: {
    display: 'block',
    textAlign: 'right',
  },
  cell: {
    height: 25,
  },
  footerLabel: {
    fontWeight: FontWeights.bold,
    textAlign: "right"
  }
}));

interface TripsProps {
  tripsData: TravelPlanDetails_travelAuthorization_trips | undefined;
}
type TripRowItem = TravelPlanDetails_travelAuthorization_trips_nodes;

export const Trips: React.FC<TripsProps> = ({
  tripsData
}) => {
  const styles = useStyles();
  const {
    nodes
  } = { ...tripsData }

  const transformedData: TripRowItem[] =
    nodes?.map((ele) => ({
      ...ele
    })) || [];

  const calculateTotal = (field: string, tripsArray: TravelPlanDetails_travelAuthorization_trips_nodes[]) => {
    return tripsArray.map((item) => item[field as keyof TravelPlanDetails_travelAuthorization_trips_nodes])
      .reduce((sum, curr) => {
        return new Decimal(curr || 0).plus(sum);
      }, new Decimal(0))
      .toNumber();
  }

  const onRenderItemColumn = (
    item: TripRowItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof TripRowItem
      ] as string;
      switch (column?.key) {
        case 'airfareAmount':
        case 'accommodationAmount':
        case 'vehicleRentalAmount':
        case 'transportationAmount':
        case 'trainAmount':
        case 'incidentalAmount':
        case 'controlTotalAmount':
          return (
            <AmountTextView
              className={styles.amount}
              value={fieldContent}
            />
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.cell}
            >
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  const renderFooterColumn = (
    _item?: TripRowItem,
    _index?: number,
    column?: IColumn
  ) => {
    let totalValue;
    switch (column?.key) {
      case 'accountReference':
        return (
          <Stack>
            <Text className={styles.footerLabel}>Total</Text>
          </Stack>
        );
      case 'controlTotalAmount':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      case 'airfareAmount':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      case 'accommodationAmount':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      case 'vehicleRentalAmount':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      case 'transportationAmount':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      case 'trainAmount':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      case 'incidentalAmount':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      default:
        return null;
    }

    return (
      <AmountTextView
        value={totalValue.toString()}
        className={clsx(styles.amount, styles.footerLabel)}
      />
    );
  };


  return (
    <DetailsList
      compact
      selectionMode={SelectionMode.none}
      items={transformedData}
      columns={columns}
      onRenderItemColumn={onRenderItemColumn}
      onRenderDetailsFooter={renderFooter}
    />
  )
}
