import { IconButton, Stack, TooltipHost } from '@fluentui/react'
import React, { useState } from 'react'
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { CopyTravelPlanDialog } from './CopyTravelPlanDialog';

interface CopyTravelPlanProps {
  travelAuthorizationData: TravelAuthorization_travelAuthorization | null | undefined;
}


export const CopyTravelPlan: React.FC<CopyTravelPlanProps> = ({
  travelAuthorizationData
}) => {

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const {
    _isCloningAllowed
  } = { ...travelAuthorizationData }

  if (!_isCloningAllowed) return null;

  return (
    <Stack>
      <TooltipHost content="Clone">
        <IconButton
          iconProps={{ iconName: 'Copy' }}
          ariaLabel="Copy"
          onClick={() => setShowDialog(true)}
        // className={styles.iconButton}
        />
      </TooltipHost>
      <CopyTravelPlanDialog
        hidden={!showDialog}
        travelAuthorizationData={travelAuthorizationData}
        onDismiss={() => setShowDialog(false)}
      />
    </Stack>
  )
}
