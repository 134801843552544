import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  status: {
    color: theme.palette.themePrimary,
    fontSize: FontSizes.size18,
  },
  approvalHistoryContainer: {
    margin: '20px 0',
  },
  actionsMenuContainer: {
    flex: 1
  },
  transactionHistoryHeading: {
    marginLeft: 30
  }
}));
