import { useMutation } from '@apollo/client';
import {
  IconButton,
  Pivot,
  PivotItem,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { HistoryActionMenuView } from 'common/components/History';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';
import { ProtectSection } from 'common/components/Protect';
import {
  EntityDocumentRowProtection,
  EntityDocumentRowProtectionVariables,
} from 'common/graphql/__generated__/EntityDocumentRowProtection';
import { GetDocumentPoolCommonData_secureRowLevels } from 'documents/__generated__/GetDocumentPoolCommonData';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { EntityDocumentValues } from '../../types';
import { GetEntityDocument } from '../../__generated__/GetEntityDocument';
import { useStyles } from './index.styles';
const UPDATE_DOCUMENT_SECURITY = loader('../EntityDocumentProtection.graphql');
const DOCUMENT_POOL_DATA = loader(
  '../../../../../../common/graphql/DocumentPool.graphql'
);

const ActionConsts = {
  protect: 'Protect_Action',
  history: 'History_Action',
};
interface ActionMenuProps {
  invoiceDetails: GetEntityDocument;
  secureRowLevels: GetDocumentPoolCommonData_secureRowLevels | undefined | null;
}
export const ActionsMenu: React.FC<ActionMenuProps> = ({
  invoiceDetails,
  secureRowLevels,
}) => {
  const {
    formState: { isDirty },
  } = useFormContext<EntityDocumentValues>();
  const [selectedKey, setSelectedKey] = React.useState(ActionConsts.protect);
  const [expandMenu, setExpandMenu] = useState(false);
  const { addToast } = useToasts();
  const styles = useStyles();

  const [invoiceRowProtection, { loading: updateLoading }] = useMutation<
    EntityDocumentRowProtection,
    EntityDocumentRowProtectionVariables
  >(UPDATE_DOCUMENT_SECURITY, { errorPolicy: 'all' });

  const updateRowLevel = async (selectedLevel: string | null) => {
    if (!!invoiceDetails?.entityDocument?.id) {
      const inputData =
        selectedLevel !== null
          ? {
            entityId: invoiceDetails.entityDocument.id,
            rowSecurityId: selectedLevel,
          }
          : {
            entityId: invoiceDetails.entityDocument.id,
            isProtectionRemoval: true,
          };
      const { errors } = await invoiceRowProtection({
        variables: {
          input: inputData,
        },
        update(cache, { data: dataSet }) {
          cache.modify({
            id: cache.identify({}),
            fields: {
              documentPools(existinPools = []) {
                const NewPoolData = cache.writeFragment({
                  data: dataSet,
                  fragment: DOCUMENT_POOL_DATA,
                });
                return [...existinPools, NewPoolData];
              },
            },
          });
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else
        addToast('Security/Protection level upgraded', {
          appearance: 'success',
        });
    }
  };

  const getSelectedSection = (key: string) => {
    const { entityDocument } = { ...invoiceDetails }
    const { _isUpdatable, entityDocumentSecureRowLevels, entityDocumentHistoriesByEntityId } = { ...entityDocument }
    switch (key) {
      case 'Protect_Action':
        return (
          <ProtectSection
            isUpdatable={!!_isUpdatable}
            updateLoading={updateLoading}
            updateRowLevel={async (selectedLevel) => {
              updateRowLevel(selectedLevel);
            }}
            secureRowLevels={secureRowLevels?.nodes || []}
            secureRowLevelId={entityDocumentSecureRowLevels?.id}
          />
        );
      case 'History_Action':
        return (
          <HistoryActionMenuView
            moduleName="Document"
            numberOfItems={3}
            historyData={entityDocumentHistoriesByEntityId?.nodes || []}
          />
        );
      default:
        return null;
    }
  };

  const _onPivotClick = (pivotName: string) => {
    if (!isDirty) {
      setSelectedKey(pivotName);
      setExpandMenu(true);
    }
  };

  return (
    <TooltipHost
      content={
        isDirty ? 'Save the transaction before accessing this section.' : ''
      }
    >
      <Stack className={clsx({ [styles.disabledColor]: isDirty })}>
        {expandMenu ? (
          <Stack>
            <Stack horizontalAlign="space-between" horizontal>
              <Stack horizontal className={styles.centerAlign}>
                <Pivot
                  selectedKey={selectedKey}
                  onLinkClick={(item: PivotItem | undefined) => {
                    setSelectedKey(item?.props.itemKey || ActionConsts.protect);
                  }}
                  aria-label="Menu_Actions"
                >
                  <PivotItem
                    itemKey={ActionConsts.protect}
                    itemIcon={
                      invoiceDetails?.entityDocument?._isProtected
                        ? 'Lock'
                        : 'Unlock'
                    }
                    headerText="Protect"
                    itemCount={0}
                    onRenderItemLink={PivotCustomRender}
                  />
                  <PivotItem
                    itemKey={ActionConsts.history}
                    itemIcon="History"
                    headerText="History"
                    itemCount={
                      invoiceDetails.entityDocument
                        ?.entityDocumentHistoriesByEntityId?.nodes?.length
                    }
                    onRenderItemLink={PivotCustomRender}
                  />
                </Pivot>
                <IconButton
                  onClick={() => setExpandMenu(false)}
                  iconProps={{ iconName: 'ChevronUp' }}
                  className={styles.iconButtonColor}
                  ariaLabel="Collapse"
                />
              </Stack>
            </Stack>
            <Stack>{getSelectedSection(selectedKey)}</Stack>
          </Stack>
        ) : (
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal verticalAlign="center">
              <Stack horizontal verticalAlign="center">
                <PivotDisplay
                  name="Protect"
                  iconName={
                    invoiceDetails?.entityDocument?._isProtected
                      ? 'Lock'
                      : 'Unlock'
                  }
                  onPivotClick={() => _onPivotClick(ActionConsts.protect)}
                />
                <PivotDisplay
                  name="History"
                  iconName="History"
                  count={
                    invoiceDetails?.entityDocument
                      ?.entityDocumentHistoriesByEntityId?.nodes?.length
                  }
                  onPivotClick={() => _onPivotClick(ActionConsts.history)}
                />
              </Stack>
              <IconButton
                onClick={() => setExpandMenu(true)}
                iconProps={{ iconName: 'ChevronDown' }}
                className={styles.iconButtonColor}
                ariaLabel="Expand"
                disabled={isDirty}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </TooltipHost>
  );
};
