import { useMutation } from '@apollo/client';
import { Stack, TextField, ActionButton } from '@fluentui/react';
import { PivotKeys } from 'common/components/MessagesSections';
import {
  UpdateMessages,
  UpdateMessagesVariables,
} from 'common/graphql/__generated__/UpdateMessages';
import { loader } from 'graphql.macro';
import { ListLoader } from 'notification/view';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';
const UPDATE_MESSAGE = loader('../../../../../graphql/UpdateMessages.graphql');
interface SentRowActionProps {
  id: string;
  messageComment: string;
  refetchList: (listName?: string) => void;
  closeSentRowActions: (param: boolean) => void;
}

export const SentRowAction: React.FC<SentRowActionProps> = ({
  id,
  messageComment,
  refetchList,
  closeSentRowActions,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [messageCommentText, setMessageCommentText] =
    React.useState<string>(messageComment);

  const [updateMessage, { loading: loadingUpdateMessage }] = useMutation<
    UpdateMessages,
    UpdateMessagesVariables
  >(UPDATE_MESSAGE, {
    errorPolicy: 'all',
  });

  const onSubmit = async () => {
    const { errors } = await updateMessage({
      variables: {
        input: {
          id: id,
          messagePatch: {
            isResponseRequired: true,
            messageComment: messageCommentText,
          },
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      setMessageCommentText('');
      refetchList?.(PivotKeys.sent);
      closeSentRowActions(false);
      addToast('Message has updated Successfully', {
        appearance: 'success',
      });
    }
  };

  return (
    <>
      <Stack horizontal className={styles.respondViewContainer}>
        <TextField
          value={messageCommentText}
          onChange={(_event, value) => setMessageCommentText(value || '')}
          className={styles.respondMessage}
        />
        <Stack horizontal className={styles.respondSendCancelContainer}>
          <ActionButton
            iconProps={{ iconName: 'Edit' }}
            allowDisabledFocus
            onClick={onSubmit}
            disabled={messageCommentText?.length! > 0 ? false : true}
          >
            Update
          </ActionButton>
          <ActionButton
            iconProps={{ iconName: 'Cancel' }}
            allowDisabledFocus
            onClick={() => closeSentRowActions(false)}
            disabled={loadingUpdateMessage}
          >
            Cancel
          </ActionButton>
        </Stack>
      </Stack>
      {loadingUpdateMessage && (
        <Stack className={styles.listLoaderContainer}>
          <ListLoader
            label="Updating message"
            loaderStatus={loadingUpdateMessage}
          />
        </Stack>
      )}
    </>
  );
};
