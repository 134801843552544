import {
  Callout,
  CommandBarButton,
  DirectionalHint,
  Icon,
  Separator,
  Stack,
  Text
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import {
  getGlobalDateFormat,
  getPrettierdate,
  getTimeFormat,
} from 'common/utils/dateFormats';
import React from 'react';
import { MessageCallout } from './MessageCallout';
import { useStyles } from './index.styles';
import './my-node.css';
import { DataView } from 'common/components/PreviewHierarchyComponents/DataView';
import { HierarchyNode } from '..';

export interface SendMessageData {
  message: string;
  messageUserId: string;
}

interface NodeViewProps {
  nodeData: HierarchyNode;
}

export const NodeView: React.FC<NodeViewProps> = ({
  nodeData,
}) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const buttonId = useId(`callout-label${nodeData.id}`);
  const nodeId = useId(`nodeId-label${nodeData.id}`);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [
    detailsCardVisible,
    { setTrue: openDetailsCard, setFalse: closeDetailsCard },
  ] = useBoolean(false);

  const getStatus = () => {
    let status = '';
    if (nodeData.isApproved === true) status = 'Approved';
    else if (nodeData.isRejected === true) status = 'Declined';
    else status = 'Pending';
    return status;
  };

  const status = getStatus();

  const getStatusIcon = () =>
    status === 'Approved'
      ? 'accept'
      : status === 'Declined'
        ? 'cancel'
        : 'Clock';

  const getBlockColor = () => {
    switch (status) {
      case 'Approved':
        return styles.backgroundApprovedBlock;
      case 'Declined':
        return styles.backgroundDeclineBlock;
      default:
        return nodeData.isTree
          ? styles.whiteBackground
          : styles.backgroundPendingBlock;
    }
  };

  const getHeaderColor = () => {
    switch (status) {
      case 'Approved':
        return styles.headerApprove;
      case 'Declined':
        return nodeData.isTree ? styles.headerApprove : styles.headerDeclined;
      default:
        return styles.headerPending;
    }
  };

  const getDescriptionColor = () =>
    status === 'Approved'
      ? styles.descriptionColorApprove
      : styles.descriptionColor;

  const getIconColor = () =>
    status === 'Pending' ? styles.iconColorPending : styles.iconColor;

  function isEmpty(str: string) {
    return !str || str.length === 0;
  }

  const getTreeHeadingStyle = () => {
    if (nodeData.isTree) return clsx(styles.treeHeadingStyle, getBlockColor());
  };

  const detailCardOpening =
    isEmpty(nodeData.requestedDate!) &&
    isEmpty(nodeData.topic!) &&
    isEmpty(nodeData.subTopic!);

  return (
    <div>
      <Stack
        onMouseEnter={() => {
          if (!detailCardOpening) openDetailsCard();
        }}
        onMouseLeave={() => closeDetailsCard()}
        verticalAlign="space-between"
        className={
          nodeData.isTree
            ? styles.treeContainer
            : clsx(
              styles.nodeContainer,
              status === 'Approved'
                ? styles.backgroundApprove
                : styles.backgroundDefault
            )
        }
      >
        <Stack id={nodeId} tokens={{ childrenGap: 10 }}>
          <Stack className={getTreeHeadingStyle()}>
            {!nodeData.parentId && (
              <>
                <Text
                  variant="medium"
                  className={clsx(commonStyles.semibold, getHeaderColor())}
                >
                  Requestor
                </Text>
                <Separator />
              </>
            )}
            <Text
              variant={'mediumPlus'}
              className={clsx(commonStyles.semibold, getHeaderColor())}
            >
              {nodeData.name}
            </Text>
          </Stack>

          {nodeData.statusDate && !nodeData.isTree && (
            <Stack>
              <Text className={getDescriptionColor()}>
                {`${getPrettierdate(
                  getGlobalDateFormat(nodeData.statusDate!)
                )}, ${getTimeFormat(nodeData.statusDate!)}`}
              </Text>
            </Stack>
          )}
        </Stack>

        {!nodeData.isTree && (
          <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Text
              className={clsx(getDescriptionColor(), styles.textviewStyles)}
              variant="xSmall"
            >
              {nodeData.digitalSignature}
            </Text>
            {!nodeData.isTree && (
              <CommandBarButton
                className={clsx(
                  getStatus() === 'Approved'
                    ? styles.msgButtonAppproved
                    : styles.msgButtonDeclined,
                  styles.msgButton
                )}
                id={buttonId}
                onClick={toggleIsCalloutVisible}
                text="Message"
                iconProps={{ iconName: 'comment' }}
              />
            )}
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              horizontalAlign="center"
              verticalAlign="center"
              className={clsx(getBlockColor(), styles.statusBlock)}
            >
              <Icon iconName={getStatusIcon()} className={getIconColor()} />
              <Text className={getIconColor()}>{status}</Text>
            </Stack>
          </Stack>
        )}
      </Stack>
      {isCalloutVisible && (
        <MessageCallout
          onclick={toggleIsCalloutVisible}
          buttonId={buttonId}
          nodeData={nodeData}
        />
      )}

      {detailsCardVisible && (
        <Callout
          directionalHint={DirectionalHint.topAutoEdge}
          calloutWidth={400}
          target={`#${nodeId}`}
        >
          <Stack tokens={{ childrenGap: 20, padding: 20 }}>
            {!isEmpty(nodeData.requestedDate!) && (
              <DataView
                title={'Requested date'}
                content={getGlobalDateFormat(nodeData.requestedDate!)}
              />
            )}
            {!isEmpty(nodeData.topic!) && (
              <DataView title={'Topic'} content={nodeData.topic!} />
            )}
            {!isEmpty(nodeData.subTopic!) && (
              <DataView title={'Subtopic'} content={nodeData.subTopic!} />
            )}
          </Stack>
        </Callout>
      )}
    </div>
  );
};

