import React, { ReactNode } from 'react';
import { Callout, DirectionalHint, Stack } from '@fluentui/react';
import { useStyles } from './index.styles';

interface LookUpCalloutProps {
  onDismiss?: () => void;
  targetID: string;
  lookUpCalloutWidth?: number;
  children?: ReactNode;
}

export const LookUpCallout: React.FC<LookUpCalloutProps> = ({
  onDismiss,
  targetID,
  lookUpCalloutWidth,
  children,
}) => {
  const styles = useStyles();
  return (
    <Callout
      onDismiss={onDismiss}
      gapSpace={10}
      isBeakVisible={false}
      className={styles.callout}
      directionalHint={DirectionalHint.bottomLeftEdge}
      target={targetID}
      style={{ width: lookUpCalloutWidth }}
    >
      <Stack
        className={styles.searchAreaContainer}
        style={{ width: lookUpCalloutWidth }}
      >
        <Stack>{children}</Stack>
      </Stack>
    </Callout>
  );
};
