import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  onRenderColumnStack: {
    height: 25,
  },
  businessUnitCol: {
    paddingLeft: 13,
  },
  badgeWrapper: {
    height: 25,
    width: 25,
    borderRadius: 3,
    boxShadow: '0px 5px 10px -4px rgba(0,0,0,0.4)',
  },
  activeIndicator: {
    color: theme.palette.green,
  },
  disabledIndicator: {
    color: 'gray',
  },
}));
