import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  pivotContainer: {
    marginLeft: 10,
    paddingTop:20
  },
  pivotCompContainers: {
    marginTop: 10,
  },
}));
