import { AddressRowValues, CompanyValues } from './types';

export const COMPANY_INITIAL_VALUES: CompanyValues = {
  id: null,
  name: null,
  approvalPhaseTypeId: null,
  homeCountryId: null,
  currencyId: null,
  timeZoneId: null,
  distributionLayoutType: null,
  corporationName: null,
  corporateWorkgroupId: null,
  companyAddresses: [],
  companyValidCurrencies:[]
};
export const ADDRESS_INITIAL_VALUES: AddressRowValues = {
  id: null,
  _rowTimestamp: null,
  addressTypeId: null,
  addressLine1: null,
  addressLine2: null,
  cityName: null,
  stateRegionId: null,
  countryId: null,
  postalCode: null,
  telephoneNumber: null,
  mobileNumber: null,
  emailAddress: null,
  mainContact: null,
  isPrimaryAddress: null,
  isDeliveryAddress: null,
};
