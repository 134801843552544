import { Stack, Text, makeStyles } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { CorporateChartOfAccountsValues } from '../types';
import { CorporateChartOfAccount } from '../__generated__/CorporateChartOfAccount';
import { DeleteChartsOfAccounts } from './DeleteChartsOfAccounts';

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    cursor: 'move',
  },
  row: {
    height: 30
  }
}));

export interface HeaderProps {
  isEdit: boolean;
  data: CorporateChartOfAccount | undefined
  onClose: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  isEdit,
  data,
  onClose,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { formState: { isDirty } } = useFormContext<CorporateChartOfAccountsValues>();
  const heading = isEdit ? "Edit Chart of Accounts" : "Create Chart of Accounts"
  const { _defaults } = { ...data?.corporateChartOfAccount };
  const { _isCurrentDefault } = { ..._defaults }
  const defaultText = _isCurrentDefault ? "(Default)" : null;

  return (
    <Stack
      horizontal
      className={styles.container}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20
      }}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.row}>
        <Text variant='xLarge' className={commonStyles.colorThemePrimary}>
          {heading}
        </Text>
        <UnsavedIndicator visible={isEdit && isDirty} />
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 4 }}
        verticalAlign="center"
      >
        <DeleteChartsOfAccounts
          isEdit={isEdit}
          data={data}
          onClose={onClose}
        />
        <Text variant="large" className={commonStyles.colorThemePrimary}>{defaultText}</Text>
        <CloseButton onClick={onClose} />
      </Stack>
    </Stack>
  )
}
