import { ITextFieldProps, Stack, TextField } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import React, { FC, useEffect, useState } from 'react';
import { Traveler } from 'settings/account/Travelers/view/__generated__/Traveler';
import { TextFieldLabel } from './TextFieldLabel';

type TravelerAutoCompleteProps = RequiredNameProps<ITextFieldProps> & {
  clearTextField?: boolean | null;
  showAddIcon?: boolean;
  traveler: Traveler | undefined;
};

export const TravelerAutoComplete: FC<TravelerAutoCompleteProps> = ({
  clearTextField,
  showAddIcon,
  traveler,
  ...props
}) => {
  const [fullName, setFullName] = useState<string | null | undefined>(null);
  const { id, label, disabled, required, ...textfieldProps } = props;
  const { _fullName } = { ...traveler?.traveler };
  useEffect(() => {
    setFullName(_fullName);
  }, [_fullName]);

  return (
    <Stack>
      <TextFieldLabel
        disabled={false}
        label={label}
        required={required}
        traveler={traveler}
      />
      <TextField {...textfieldProps} id={id} value={fullName || ''} />
    </Stack>
  );
};
