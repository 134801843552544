import { format } from 'date-fns';
import { TravelAuthorizationValues } from './interface';

export const TRAVEL_AUTHORIZATION_INITIAL_VALUES: TravelAuthorizationValues = {
  tripPurpose: null,
  _urgencyLevel:null,
  comment: null,
  travelAuthorizationDate: format(new Date(), 'yyyy/MM/dd'),
  bookingFee: null,
  departmentId: null,
  currencyId: null,
  isPersonalTravelAuthorization: null,
  businessUnitId: null,
  corporateWorkgroupId: null,
  _isCorporateWorkgroupRequired: null,
  supplierId: null,
  budgetAmount: null,
  controlTotalAmount: null,
};
