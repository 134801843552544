import {
  IPanelHeaderRenderer,
  IPanelProps,
  IRenderFunction,
} from '@fluentui/react';
import DraggablePanel from 'common/components/DraggablePanel';
import { PanelHeader } from 'common/components/PanelHeader';
import { PanelCommonProps } from 'common/types/utility';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { CorporateWorkgroup } from '../__generated__/CorporateWorkgroup';
import { Footer } from './Footer';
import { Header } from './Header';
import { BasicForm } from './BasicForm';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { CorporateWorkgroupCommonData } from '../__generated__/CorporateWorkgroupCommonData';
import {
  CorporateWorkgroupDelete,
  CorporateWorkgroupDeleteVariables,
} from '../../__generated__/CorporateWorkgroupDelete';
import { ShimmerView } from './Shimmer/ShimmerViews';
const CORPORATE_WORKGROUP_COMMON_DATA = loader(
  '../CorporateWorkgroupCommonData.graphql'
);
const CORPORATE_WORKGROUP_DELETE = loader(
  '../../CorporateWorkgroupDelete.graphql'
);
const PANEL_WIDTH = 900;

interface FormViewProps {
  onSave: () => void;
  isSaveAnother: (saveAnother: boolean) => void;
  isNew: boolean;
  corporateWorkgroupData: CorporateWorkgroup | undefined;
  dataLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  refetch: () => void;
}

export const FormView: React.FC<FormViewProps> = ({
  isNew,
  corporateWorkgroupData,
  dataLoading,
  isCreating,
  isUpdating,
  onSave,
  isSaveAnother,
  refetch,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { data: commonData } = useQuery<CorporateWorkgroupCommonData>(
    CORPORATE_WORKGROUP_COMMON_DATA
  );
  const [
    deleteCorporateWorkgroupDelete,
    { loading: deleteCorporateWorkgroupDeleteLoading },
  ] = useMutation<CorporateWorkgroupDelete, CorporateWorkgroupDeleteVariables>(
    CORPORATE_WORKGROUP_DELETE,
    {
      errorPolicy: 'all',
    }
  );

  const { id, _rowTimestamp } = {
    ...corporateWorkgroupData?.corporateWorkgroup,
  };

  const onDelete = async () => {
    if (!!id && !!_rowTimestamp) {
      const { errors } = await deleteCorporateWorkgroupDelete({
        variables: {
          input: {
            entityDelete: [
              {
                id: id!,
                rowTimestamp: _rowTimestamp!,
              },
            ],
          },
        },
        update: (cache, { data }) => {
          if (!!data?.corporateWorkgroupDelete?.deletedEntities?.length) {
            const identity = cache.identify({
              ...corporateWorkgroupData?.corporateWorkgroup,
            });
            cache.evict({ id: identity });
            cache.gc();
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Record Deleted Successfully', {
          appearance: 'success',
        });
        history.replace('/account-management/work-groups');
      }
    }
  };

  const isLoading =
    isCreating || isUpdating || deleteCorporateWorkgroupDeleteLoading;

  const _onRenderHeader: IPanelHeaderRenderer = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/account-management/work-groups');
        }}
      >
        <Header
          isNew={isNew}
          dataLoading={dataLoading}
          corporateWorkgroupData={corporateWorkgroupData}
          onWorkgroupDelete={onDelete}
        />
      </PanelHeader>
    );
  };

  const _onRenderFooter: IRenderFunction<IPanelProps> = () => {
    return (
      <Footer
        isNew={isNew}
        isLoading={isLoading}
        onSave={(saveAnother) => {
          isSaveAnother(saveAnother);
          onSave();
        }}
      />
    );
  };

  const showShimmer = !isNew && dataLoading;

  return (
    <DraggablePanel
      initialWidth={PANEL_WIDTH}
      minWidth={PANEL_WIDTH}
      {...PanelCommonProps}
      isBlocking={isNew}
      isOpen
      onRenderHeader={_onRenderHeader}
      onRenderFooter={_onRenderFooter}
      onDismiss={() => {
        history.replace('/account-management/work-groups');
      }}
      isLightDismiss
    >
      {showShimmer ? <ShimmerView /> : <BasicForm commonData={commonData} />}
    </DraggablePanel>
  );
};
