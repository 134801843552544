import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const centerAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'center',
  },
};
export const columns: ColumnData[] = [
  {
    key: 'name',
    name: 'Group',
    fieldName: 'name',
    minWidth: 250,
    maxWidth: 250,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 280,
    maxWidth: 280,
    ...commonColumnProps,
  },
  {
    key: 'communicationGroupItemsByCommunicationGroupId',
    name: '',
    fieldName: 'communicationGroupItemsByCommunicationGroupId',
    minWidth: 900,
    maxWidth: 900,
    ...commonColumnProps,
  },
  {
    key: 'action',
    name: 'Action',
    fieldName: 'action',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    styles: centerAlignHeaderStyle,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
  },
];
