import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  IDropdownOption,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { PayCycleCommonTypes } from 'ap/paymentCycle/__generated__/PayCycleCommonTypes';
import { Payments } from 'ap/paymentCycle/view/Payments';
import {
  PayCycleApprovalRevoke,
  PayCycleApprovalRevokeVariables,
} from 'ap/paymentCycle/view/__generated__/PayCycleApprovalRevoke';
import { ApprovalHistory } from 'common/components/ApprovalHistory';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { CustomDropdown } from 'common/components/CustomDropdown';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { SignatureOptions, SignatureView } from 'common/components/Signatures';
import { StampOptions, StamperView } from 'common/components/StamperView';
import {
  PayCycleBankingDocumentPackageStatus,
  PayCycleBankingDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/PayCycleBankingDocumentPackageStatus';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  DocumentPackageStatusType,
  IconState,
  PayCycleApprovalRevokeInput,
  UploadStatusType,
} from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ActionsMenu } from './ActionMenu';
import { BasicForm } from './BasicForm';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { UnStamp } from './UnStamp';
import {
  AccountingEntryPayCycle,
  AccountingEntryPayCycleVariables,
} from './__generated__/AccountingEntryPayCycle';
import {
  PayCycleAccountingStamper,
  PayCycleAccountingStamperVariables,
} from './__generated__/PayCycleAccountingStamper';
import { useStyles } from './index.styles';
import { DocumentPackage } from 'common/components/DocumentPackage';
import { DocumentPackageEmail } from 'common/components/DocumentPackageEmail/DocumentPackageEmail';
import {
  PayCycleEmailCreate,
  PayCycleEmailCreateVariables,
} from './__generated__/PayCycleEmailCreate';
import { EmailCreateValues } from 'common/components/DocumentPackageEmail/DocumentPackageEmail/DocumentPackageEmailModal/FormModal/types';
import {
  PayCycleEmailStatusUpdate,
  PayCycleEmailStatusUpdateVariables,
} from './__generated__/PayCycleEmailStatusUpdate';
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const ACCOUNTING_ENTRY_PAYCYCLE = loader('./AccountingEntryPayCycle.graphql');
const COMMON_DATA = loader('../../PayCycleCommonTypes.graphql');
const UPDATE_STAMPER = loader('./PayCycleAccountingStamper.graphql');
const PAY_CYCLE_APPROVAL_REVOKE = loader(
  '../../view/PayCycleApprovalRevoke.graphql'
);
const PAYMENT_DOCUMENT_PACKAGE_STATUS = loader(
  '../../../../common/graphql/DocumentPackageSubscription/PayCycleBankingDocumentPackageStatus.graphql'
);
const PAY_CYCLE_EMAIL_CREATE = loader('./PayCycleEmailCreate.graphql');
const FETCH_EMAIL_STATUS = loader('./PayCycleEmailStatusUpdate.graphql');

export const AccountingEntryPayCycleView: React.FC = () => {
  const isOpen = true;
  const history = useHistory();
  const styles = useStyles();
  const { addToast, updateToast } = useToasts();
  const commonStyles = useCommonStyles();
  const client = useApolloClient();
  const panelHeading = 'Accounting Entry';
  const [stampData, setStampData] = useState<StampOptions>();
  const [requestAccountingTransaction, setRequestAccountingTransaction] =
    useState<string>();
  const [corporatePeriodId, setCorporatePeriodId] = useState<
    string | undefined
  >();
  const [accountingPeriodOptions, setaccountingPeriodOptions] = useState<
    IDropdownOption[]
  >([]);
  const [signatureData, setSignatureData] = useState<SignatureOptions[]>([]);
  const [hideConfirmDialog, setHideConfirmDialog] = useState<boolean>(true);
  const { accountingEntryId } = useParams<{
    accountingEntryId: string | undefined;
  }>();
  const toggleConfirmDialog = () =>
    setHideConfirmDialog((prevState) => !prevState);

  const [hideConfirmAmendDialog, setHideConfirmAmendDialog] =
    useState<boolean>(true);
  const toggleConfirmAmendDialog = () =>
    setHideConfirmAmendDialog((prevState) => !prevState);
  const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
  const CONFIRM_AMEND_DIALOG_SUBTEXT =
    'This will remove the Pay cycle from the approval cycle and require re-approval.';

  const [payCycleApprovalRevoke] = useMutation<
    PayCycleApprovalRevoke,
    PayCycleApprovalRevokeVariables
  >(PAY_CYCLE_APPROVAL_REVOKE, { errorPolicy: 'all' });
  const [
    getPayCycleDetails,
    { loading: payCycleDetailsDataLoading, data: payCycleDetailsData, refetch },
  ] = useLazyQuery<AccountingEntryPayCycle, AccountingEntryPayCycleVariables>(
    ACCOUNTING_ENTRY_PAYCYCLE
  );
  const [payCycleEmailCreate, { loading, data }] = useMutation<
    PayCycleEmailCreate,
    PayCycleEmailCreateVariables
  >(PAY_CYCLE_EMAIL_CREATE, { errorPolicy: 'all' });

  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const [fetchEmailStatus, { stopPolling }] = useLazyQuery<
    PayCycleEmailStatusUpdate,
    PayCycleEmailStatusUpdateVariables
  >(FETCH_EMAIL_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    onCompleted(data) {
      if (data.payCycle?._emailDocument?._sendIconState === IconState.SENT) {
        stopPolling?.();
        setShowEmailStatus(true);
      }
    },
  });

  const { _emailDocument, emailDocumentsByEntityId } = {
    ...payCycleDetailsData?.payCycle,
  };

  const { data: commonData } = useQuery<PayCycleCommonTypes>(COMMON_DATA);

  const [updateStamper] = useMutation<
    PayCycleAccountingStamper,
    PayCycleAccountingStamperVariables
  >(UPDATE_STAMPER, { errorPolicy: 'all' });

  useEffect(() => {
    const accP =
      commonData?.companyCorporatePeriods?.nodes.map((item) => ({
        disabled: item.isEntryAllowed ? false : true,
        key: item.id,
        text:
          item._periodYear +
            ' (' +
            dateFormat(dateConvertions(item.startDate!)) +
            ' - ' +
            dateFormat(dateConvertions(item.endDate!)) +
            ')' || '',
      })) || [];
    setaccountingPeriodOptions(accP);
    if (payCycleDetailsData?.payCycle?.corporatePeriodId)
      setCorporatePeriodId(payCycleDetailsData?.payCycle?.corporatePeriodId);
  }, [commonData, payCycleDetailsData]);

  useEffect(() => {
    if (accountingEntryId && accountingEntryId !== '') {
      getPayCycleDetails({
        variables: {
          id: accountingEntryId,
        },
      });
    }
  }, [getPayCycleDetails, accountingEntryId]);

  useEffect(() => {
    if (payCycleDetailsData) {
      const stampOptions: StampOptions = {
        _accountingStampDate:
          payCycleDetailsData.payCycle?._accountingStampDate,
        _accountingStampTransactionReference:
          payCycleDetailsData.payCycle?._accountingStampTransactionReference,
        _accountingStampUserName:
          payCycleDetailsData.payCycle?._accountingStampUserName,
        _isAccountingEntryStampedComplete:
          payCycleDetailsData.payCycle?._isAccountingEntryStampedComplete,
        _isTransactionCancelled:
          payCycleDetailsData.payCycle?._isTransactionCancelled,
      };
      setStampData(stampOptions);
    }
  }, [payCycleDetailsData]);

  useEffect(() => {
    if (
      payCycleDetailsData?.payCycle &&
      payCycleDetailsData?.payCycle.approvalHistorySignaturesByEntityId.nodes
        .length > 0
    ) {
      const signatureOptions: SignatureOptions[] =
        payCycleDetailsData?.payCycle.approvalHistorySignaturesByEntityId.nodes.map(
          (item) =>
            ({
              actionDate: item.actionDate,
              additionalInformation: item.additionalInformation,
              digitalSignature: item.digitalSignature,
              id: item.id,
              isApproved: item.isApproved,
              isRejected: item.isRejected,
              isRequested: item.isRequested,
              userName: item.userName,
              entityDocumentId: item.entityDocumentId,
              userOccupationTitle: item.userOccupationTitle,
              userSignatureDocument: {
                _downloadLink: item.userSignatureDocument?._downloadLink,
                fileIndexInformation:
                  item.userSignatureDocument?.fileIndexInformation,
                fileReference: item.userSignatureDocument?.fileReference,
                iconType: item.userSignatureDocument?.iconType,
                id: item.userSignatureDocument?.id,
              },
            } as SignatureOptions)
        );
      setSignatureData(signatureOptions);
    }
  }, [payCycleDetailsData]);

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        title={panelHeading}
        onClose={() => {
          history.replace('/pay/payment_cycle');
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {payCycleDetailsData?.payCycle?.transactionType
              ?.transactionType && (
              <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
                {
                  payCycleDetailsData?.payCycle?.transactionType
                    ?.transactionType
                }
              </Text>
            )}
            <DocumentPackage
              documentPackageId={
                payCycleDetailsData?.payCycle?._documentPackageId
              }
            />
            <DocumentPackageEmail
              emailDocument={_emailDocument}
              emailDocumentsByEntityId={emailDocumentsByEntityId?.nodes || []}
              isSuccessful={isSuccessful}
              loading={loading}
              onSubmitValues={_onSubmitValues}
              showEmailStatus={showEmailStatus}
              setShowEmailStatus={setShowEmailStatus}
              dataLoading={payCycleDetailsDataLoading}
            />
          </Stack>
          <Stack>
            {stampData &&
              payCycleDetailsData?.payCycle?._isAccountingEntry &&
              payCycleDetailsData?.payCycle._isHistory && (
                <StamperView invoiceDetails={stampData} />
              )}
            {payCycleDetailsData?.payCycle?._isAccountingEntry &&
              !payCycleDetailsData?.payCycle._isHistory &&
              payCycleDetailsData?.payCycle._isUpdatable && (
                <PrimaryButton
                  iconProps={{
                    iconName: 'StampSmall',
                    styles: {
                      root: {
                        fill: 'white',
                      },
                    },
                  }}
                  onClick={() => toggleConfirmDialog()}
                  text="Stamp Entry"
                />
              )}
          </Stack>
        </Stack>
      </PanelHeader>
    );
  };

  const _onRenderFooter = () => {
    return (
      <FooterActionBar
        onCancel={() => history.replace('/pay/payment_cycle')}
        isCreate={false}
        showButtons={{
          showCreateNewButton: false,
          showOnSaveButton: false,
        }}
        children={
          <Stack>
            {payCycleDetailsData?.payCycle?._isApprovalRevocable && (
              <PrimaryButton
                className={styles.diabledButton}
                text="Amend Pay Cycle"
                onClick={() => toggleConfirmAmendDialog()}
              />
            )}
            <UnStamp payCycle={payCycleDetailsData} />
            <ConfirmDialog
              isAmendButton
              hidden={hideConfirmAmendDialog}
              title={CONFIRM_AMEND_DIALOG_TITLE}
              subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
              onDismiss={toggleConfirmAmendDialog}
              onConfirm={async () => {
                toggleConfirmAmendDialog();
                const inputVariables: PayCycleApprovalRevokeInput = {
                  entityId: payCycleDetailsData?.payCycle?.id!,
                  rowTimestamp: payCycleDetailsData?.payCycle?._rowTimestamp!,
                };
                const { errors } = await payCycleApprovalRevoke({
                  variables: {
                    input: inputVariables,
                  },
                  awaitRefetchQueries: true,
                  update(cache, { data }) {
                    const identity = cache.identify({
                      ...payCycleDetailsData?.payCycle,
                    });
                    cache.evict({ id: identity });
                    cache.gc();
                  },
                });
                if (errors?.length)
                  addToast(errors[0].message, {
                    appearance: 'error',
                  });
                else {
                  history.replace('/pay/payment_cycle');
                  addToast('Approval amended successfully', {
                    appearance: 'success',
                  });
                }
              }}
            />
          </Stack>
        }
      />
    );
  };

  const [successBtnDisable, setSuccessBtnDisable] = useState<boolean>(true);
  const _onSubmitValues = async (values: EmailCreateValues) => {
    if (payCycleDetailsData?.payCycle?.id) {
      const { errors } = await payCycleEmailCreate({
        variables: {
          input: {
            entityId: payCycleDetailsData?.payCycle?.id!,
            ...values,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ACCOUNTING_ENTRY_PAYCYCLE,
            variables: {
              id: payCycleDetailsData?.payCycle?.id,
            },
          },
        ],
      });
      if (!!errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Email sent successfully.', {
          appearance: 'success',
        });
        fetchEmailStatus({
          variables: {
            id: payCycleDetailsData?.payCycle?.id!,
          },
        });
      }
    }
  };

  const isSuccessful = !!data?.payCycleEmailCreate;

  const fetchEmailStatusMemo = useCallback(() => {
    fetchEmailStatus({
      variables: {
        id: payCycleDetailsData?.payCycle?.id!,
      },
    });
  }, [fetchEmailStatus, payCycleDetailsData]);

  useEffect(() => {
    if (_emailDocument?._sendIconState === IconState.PENDING) {
      fetchEmailStatusMemo();
    }
  }, [_emailDocument, fetchEmailStatusMemo]);

  return (
    <DraggablePanel
      {...PanelCommonProps}
      initialWidth={1200}
      minWidth={1200}
      isBlocking={false}
      onRenderHeader={_onRenderHeader}
      onRenderFooter={_onRenderFooter}
      isOpen={isOpen}
      onDismiss={() => {
        history.replace('/pay/payment_cycle');
      }}
      isLightDismiss
    >
      {payCycleDetailsDataLoading ? (
        <Stack
          className={styles.shimmerViewMainContainer}
          tokens={{ childrenGap: 20 }}
        >
          <Stack
            className={styles.headerContainer}
            tokens={{ childrenGap: 10 }}
          >
            <ShimmerView />
          </Stack>
        </Stack>
      ) : (
        <Stack className={styles.marginTop20}>
          <ConfirmDialog
            isConfirmPrimaryButton
            successBtnDisable={successBtnDisable}
            hidden={hideConfirmDialog}
            title={
              'Are you sure you want to stamp this transaction as Entered?'
            }
            onDismiss={toggleConfirmDialog}
            minWidth={500}
            onConfirm={async () => {
              if (requestAccountingTransaction) {
                let dataVariables: PayCycleAccountingStamperVariables = {
                  input: {
                    id: payCycleDetailsData?.payCycle?.id || '',
                    rowTimestamp:
                      payCycleDetailsData?.payCycle?._rowTimestamp || '',
                    corporatePeriodId: corporatePeriodId?.toString(),
                    transactionReference: requestAccountingTransaction,
                  },
                };

                const { errors } = await updateStamper({
                  variables: dataVariables,
                });

                if (errors?.length) {
                  toggleConfirmDialog();
                  addToast(errors[0].message, {
                    appearance: 'error',
                  });
                } else {
                  addToast('Stamp successful', {
                    appearance: 'success',
                  });
                  refetch?.();
                  toggleConfirmDialog();

                  const observer = client.subscribe<
                    PayCycleBankingDocumentPackageStatus,
                    PayCycleBankingDocumentPackageStatusVariables
                  >({
                    query: PAYMENT_DOCUMENT_PACKAGE_STATUS,
                    variables: {
                      id: payCycleDetailsData?.payCycle?.id!,
                    },
                  });
                  const subscription = observer.subscribe(
                    ({ data, errors }) => {
                      if (errors)
                        addToast(
                          'Errors received while Subscribing to document package',
                          { appearance: 'error' }
                        );
                      else {
                        const { document, status } = {
                          ...data?.payCycleBankingDocumentPackageStatus,
                        };
                        if (
                          status ===
                          DocumentPackageStatusType.REGENERATION_FAILURE
                        ) {
                          addToast(
                            'Report generation failed. Document package was not created',
                            { appearance: 'error' }
                          );
                        }
                        if (status === DocumentPackageStatusType.FAILURE) {
                          addToast('Error while creating Document package ', {
                            appearance: 'error',
                          });
                        }
                        if (document) {
                          addToast('Document package created', {
                            appearance: 'success',
                          });
                          client.cache.modify({
                            id: client.cache.identify({
                              ...payCycleDetailsData?.payCycle,
                            }),
                            fields: {
                              _documentPackageId: () => {
                                return document.id;
                              },
                            },
                          });
                        }
                      }
                      subscription.unsubscribe();
                    }
                  );
                }
              }
            }}
          >
            <Stack tokens={{ childrenGap: 10 }}>
              <TextField
                value={requestAccountingTransaction}
                placeholder="Enter Accounting System Transaction #"
                resizable={false}
                onChange={(_event, value) => {
                  setRequestAccountingTransaction(value || '');
                  if (value) {
                    setSuccessBtnDisable(false);
                  } else {
                    setSuccessBtnDisable(true);
                  }
                }}
              />
              <CustomDropdown
                label="Accounting Period"
                placeholder="Select"
                selectedKey={corporatePeriodId}
                options={accountingPeriodOptions}
                onChange={(_, option) => {
                  setCorporatePeriodId(option?.key + '');
                }}
                onClear={() => setCorporatePeriodId('')}
                notifyOnReselect
              />
            </Stack>
          </ConfirmDialog>
          <Stack
            className={styles.headerContainer}
            tokens={{ childrenGap: 10 }}
          >
            {payCycleDetailsData?.payCycle && (
              <ActionsMenu
                secureRowLevels={commonData?.secureRowLevels}
                payCycleDetails={payCycleDetailsData}
                onUpload={async (fileSelected, document, toastId) => {
                  const observer = client.subscribe({
                    query: DOCUMENT_UPLOAD_STATUS,
                    variables: {
                      documentId: document.document._documentFileId!,
                    },
                  });

                  const subscription = observer.subscribe((data) => {
                    const subscribedData = data.data as OnDocumentUploadStatus;

                    const { status, document } = {
                      ...subscribedData.documentUploadStatus,
                    };

                    if (!document) {
                      if (status.type === UploadStatusType.VALIDATING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Validating files ${fileSelected.name} - ${status.message}`
                            : `Validating files ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.EXTRACTING) {
                        updateToast(toastId!, {
                          content: status.message
                            ? `Extracting data from ${fileSelected.name} - ${status.message}`
                            : `Extracting data from ${fileSelected.name}`,
                          appearance: 'info',
                          autoDismiss: false,
                        });
                      } else if (status.type === UploadStatusType.FAILURE) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Upload of ${fileSelected.name} failed - ${status.message}`
                            : `Upload of ${fileSelected.name} failed`,
                          appearance: 'error',
                          autoDismiss: true,
                        });
                      } else if (status.type === UploadStatusType.WARNING) {
                        subscription.unsubscribe();
                        updateToast(toastId!, {
                          content: status.message
                            ? `Warning for file ${fileSelected.name}: ${status.message}`
                            : `Warning for file ${fileSelected.name}`,
                          appearance: 'warning',
                          autoDismiss: true,
                        });
                      }
                    } else {
                      subscription.unsubscribe();
                      updateToast(toastId!, {
                        content: status.message
                          ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                          : `Successfully uploaded ${fileSelected.name}`,
                        appearance: 'success',
                        autoDismiss: true,
                      });

                      const cacheData = client.readQuery<
                        AccountingEntryPayCycle,
                        AccountingEntryPayCycleVariables
                      >({
                        query: ACCOUNTING_ENTRY_PAYCYCLE,
                        variables: {
                          id: payCycleDetailsData?.payCycle?.id!,
                        },
                      });
                      if (cacheData) {
                        const updatedData: AccountingEntryPayCycle = {
                          payCycle: {
                            ...cacheData?.payCycle!,
                            _isApprovalDocumentsRequired:
                              document?.payCycleDocument
                                ?._isApprovalDocumentsRequired!,
                            _requiredApprovalDocuments:
                              document?.payCycleDocument
                                ?._requiredApprovalDocuments!,
                            entityDocumentsByEntityId: {
                              ...cacheData?.payCycle?.entityDocumentsByEntityId,
                              nodes: [
                                document!,
                                ...cacheData?.payCycle
                                  ?.entityDocumentsByEntityId.nodes!,
                              ],
                            },
                          },
                        };
                        client.writeQuery<
                          AccountingEntryPayCycle,
                          AccountingEntryPayCycleVariables
                        >({
                          query: ACCOUNTING_ENTRY_PAYCYCLE,
                          data: updatedData,
                          variables: {
                            id: payCycleDetailsData?.payCycle?.id!,
                          },
                        });
                      }
                    }
                  });
                }}
              />
            )}
          </Stack>
          <Stack className={styles.marginTop10}>
            <Separator />
          </Stack>
          <Stack className={styles.paddingTop10}>
            <BasicForm payCycleDetails={payCycleDetailsData} />
          </Stack>

          {payCycleDetailsData?.payCycle?.payments?.nodes.length !== 0 && (
            <Stack>
              <Separator />
              <Stack tokens={{ childrenGap: 20 }}>
                <Payments
                  paymentListData={payCycleDetailsData?.payCycle?.payments!}
                />
              </Stack>
            </Stack>
          )}
          {payCycleDetailsData?.payCycle?.approvalHistorySignaturesByEntityId
            .nodes.length! > 0 && (
            <Stack>
              <Separator />
              <Stack
                className={styles.tagsContainer}
                tokens={{ childrenGap: 20 }}
              >
                <Text variant="xLarge">Signatures</Text>
                <SignatureView signatureData={signatureData} />
              </Stack>
            </Stack>
          )}
          {payCycleDetailsData?.payCycle &&
            payCycleDetailsData?.payCycle?.approvalHistoriesByEntityId?.nodes
              .length! > 0 && (
              <Stack>
                <Separator />
                <Stack
                  className={styles.tagsContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Text variant="xLarge">Approval History</Text>
                  <Text variant="medium" className={styles.requestedByText}>
                    Requested By:
                  </Text>
                  <ApprovalHistory
                    data={
                      payCycleDetailsData.payCycle.approvalHistoriesByEntityId
                        .nodes
                    }
                  />
                </Stack>
              </Stack>
            )}
        </Stack>
      )}
    </DraggablePanel>
  );
};
