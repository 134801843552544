import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  cellText: {
    paddingLeft: 10,
  },
  amountAlign: {
    textAlign: 'right',
    paddingRight: 20,
  },
  onrenderColumnStack: {
    height: 20,
  },
  heading: {
    color: theme.palette.themePrimary,
    fontWeight: 600,
    margin: '12px 0px 6px 10px',
  },
  detailsListContainer: {
    maxHeight: 200,
    // width: 500,
    overflowY: 'scroll',
    padding: '0px 0px 10px',
  },
  accountingDetailsHeader: {
    marginLeft: 20,
    fontWeight: FontWeights.bold,
  },
  totalAmount: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
    textAlign: 'right',
    paddingRight: 10,
  },
}));
