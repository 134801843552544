import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  Icon,
  IDetailsFooterProps,
  IRenderFunction,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { TransactionModal } from 'ap/paymentCycle/view/TransactionModal';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { useCommonStyles } from 'common/styles';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React, { useState } from 'react';
import {
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId,
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes,
} from '../__generated__/userApproval';
import { columnsBatchTransaction } from './column.data';
import { useStyles } from './index.styles';
import { StyledLink } from 'common/components/StyledLink';

type ItemRow =
  userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId_nodes;

interface InvoiceAccountingListProps {
  data: userApproval_userApproval_approvalHistoryItemsByDisplayEntityApprovalId;
}

export const BatchTransactionLayout: React.FC<InvoiceAccountingListProps> = ({
  data,
}) => {
  const styles = useStyles();
  const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>();
  const commonStyles = useCommonStyles();
  const renderColumn = (item?: ItemRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;

    const fieldContent = item[column.fieldName as keyof ItemRow] as
      | string
      | null;

    switch (column?.key) {
      case 'itemDescription1':
        return (
          <StyledLink
            onLinkClick={() => setSelectedInvoice(item.id1!)}
            linkText={fieldContent}
          />
        );
      case 'itemDescription2':
        return (
          <Stack horizontal verticalAlign="center">
            {item._batchComment && (
              <TooltipHost content={item._batchComment}>
                <Icon
                  style={{ marginRight: 3 }}
                  iconName="InfoSolid"
                  className={commonStyles.colorThemePrimary}
                />
              </TooltipHost>
            )}
            <Text style={{ marginLeft: item._batchComment ? 0 : 15 }}>
              {fieldContent}
            </Text>
          </Stack>
        );
      case 'itemDate1':
        return (
          <Text className={clsx(styles.amount)}>
            {fieldContent && dateFormat(dateConvertions(fieldContent))}
          </Text>
        );

      case '_isVerified':
        return (
          item?._isVerified && (
            <TooltipHost content={`Validated`}>
              <Stack style={{ marginTop: 5 }}>
                <Icon className={styles.logo} iconName="Accept" />
              </Stack>
            </TooltipHost>
          )
        );
      case 'amount':
        return (
          <AmountTextView
            className={clsx(styles.amount)}
            value={fieldContent}
          />
        );
      case '_accountingStampTransactionReference':
        return <Text>{item._accountingStampTransactionReference}</Text>;
      default:
        return <Text>{fieldContent}</Text>;
    }
  };

  const renderFooterColumn = (
    _item?: ItemRow,
    _index?: number,
    column?: IColumn
  ) => {
    if (column?.key === 'amount' && data?.aggregates?.sum)
      return (
        <Text>
          <AmountTextView
            value={Number(data.aggregates.sum.itemAmount1).toFixed(2)}
            className={clsx(styles.amount, styles.amountTotal)}
          />
        </Text>
      );

    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !data.aggregates?.sum?.itemAmount1) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  return (
    <Stack tokens={{ padding: '0px 0px 0px 10px' }}>
      <DetailsList
        compact
        columns={columnsBatchTransaction}
        items={data.nodes}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        onRenderItemColumn={renderColumn}
        onRenderDetailsFooter={renderFooter}
      />

      {selectedInvoice !== undefined && (
        <TransactionModal
          entityId={selectedInvoice}
          isOpen={selectedInvoice !== undefined}
          onDismiss={() => setSelectedInvoice(undefined)}
        />
      )}
    </Stack>
  );
};
