import React from 'react'
import { DepartmentGroups_departmentGroups_nodes_departmentGroupItems_nodes } from '../__generated__/DepartmentGroups'
import { Stack, Text } from '@fluentui/react';
import { useStyles } from './index.styles'
import clsx from 'clsx';

interface DepartmentGroupItemProps {
  item: DepartmentGroups_departmentGroups_nodes_departmentGroupItems_nodes;
  isTravelDepartmentGroup: boolean;
}

export const DepartmentGroupItem: React.FC<DepartmentGroupItemProps> = ({
  item,
  isTravelDepartmentGroup,
}) => {
  const styles = useStyles()
  const {
    department,
  } = { ...item }
  const { name } = { ...department }
  return (
    <Stack>
      <Stack verticalAlign="center">
        <Stack
          verticalAlign="center"
          className={clsx({
            [styles.onRenderColumnStack]: true,
            [styles.groupItemHeight]: true,
            [styles.isNonTravelDepartmentGroup]: !isTravelDepartmentGroup,
            [styles.isTravelDepartmentGroup]: isTravelDepartmentGroup,
          })}
        >
          <Text
            // onMouseEnter={() => setIsCalloutVisible(true)}
            // onMouseLeave={() => setIsCalloutVisible(false)}
            // id={calloutId}
            className={styles.fontColor}
            variant="medium"
          >
            {name}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}
