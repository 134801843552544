import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  amount: {
    textAlign: 'right',
    display: 'block',
  },

  columnHeight: {
    height: 30,
  },
  currency: {
    display: 'block',
  },
  marginEnd: {
    height: 30,
    paddingLeft: 25,
    paddingRight: 25,
  },
  rightAlignContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  logo: {
    fill: theme.palette.neutralPrimary,
  },
}));
