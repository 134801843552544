import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import { ListFilterCallout } from './ListFilterCallout';
import { Badge } from './Badge';
import { FilterArrayType } from 'common/components/Filters';

export type filterOptionProps = {
  filterTypes: FilterArrayType[];
  startsWith: boolean;
};

export interface ListFiltersProps {
  filterOptions: filterOptionProps;
  onFilterChange: (filterOptions: filterOptionProps) => void;
}

export const ListFilters: React.FC<ListFiltersProps> = ({ ...props }) => {
  const calloutId = useId(`supplier-filter-callOutId`);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const filterToggle = () => setFiltersApplied((prevState) => !prevState);

  const filtersCount = props.filterOptions?.filterTypes?.length;

  return (
    <Stack horizontal>
      <TooltipHost content={filtersApplied ? 'Close filters' : 'Open Filters'}>
        <Badge visible={filtersCount > 0} filtersCount={filtersCount}>
          <ActionButton
            onClick={filterToggle}
            id={calloutId}
            iconProps={{ iconName: filtersApplied ? 'ClearFilter' : 'Filter' }}
            text="Filters"
          />
        </Badge>
      </TooltipHost>
      {filtersApplied && (
        <ListFilterCallout
          onDismiss={filterToggle}
          calloutId={calloutId}
          {...props}
        />
      )}
    </Stack>
  );
};
