import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  approvalStatus: {
    color: theme.palette.themePrimary,
    fontSize: FontSizes.size18,
  },
  callout: {
    backgroundColor: theme.palette.neutralLighterAlt,
    maxWidth: '50%',
  },
  container: {
    width: '100%',
  },
}));
