import { AccountingViewRowValues, EntityDocumentValues } from '../types';

export const ACCOUNTING_INITIAL_VALUES: AccountingViewRowValues = {
  id: null,
  _isDeletable: null,
  _isUpdatable: null,
  _rowTimestamp: null,
  accountReference: null,
  additionalDescription: null,
  distributionAmount: null,
  projectReference: null,
  setReference: null,
  referenceCode1: null,
  referenceCode2: null,
  referenceCode3: null,
  referenceCode4: null,
  referenceCode5: null,
  tax1099T4TypeId: null,
  referenceCode6: null,
  referenceCode7: null,
  distributionDate: null,
  _accountName: null,
};

export const DOCUMENT_INITIAL_VALUES: EntityDocumentValues = {
  fileIndexInformation: null,
  _documentContents: null,
  comment: null,
  entityDocumentTypeId: null,
  indexTransactionDate: null,
  indexName: null,
  indexDescription: null,
  indexReferenceNumber: null,
  indexAmount: null,
  indexCurrencyId: null,
  extractionTypeId: null,
  indexTaxAccount1: null,
  indexTaxAmount1: null,
  indexTaxCode1: null,
  indexTaxAccount2: null,
  indexTaxAmount2: null,
  indexTaxCode2: null,
  indexTaxAccount3: null,
  indexTaxAmount3: null,
  indexTaxCode3: null,
  indexTaxAccount4: null,
  indexTaxAmount4: null,
  indexTaxCode4: null,
  invoiceDistributions: [{ ...ACCOUNTING_INITIAL_VALUES }],
};
