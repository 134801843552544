import { ColumnData } from 'common/components/SearchBar';
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: 'cardHolder',
    name: 'Card Holder',
    fieldName: 'cardHolder',
    minWidth: 200,
    maxWidth: 300,
    ...commonColumnProps,
  },
  {
    key: 'cardNumber',
    name: 'Number',
    fieldName: 'cardNumber',
    minWidth: 200,
    maxWidth: 300,
    ...commonColumnProps,
  },
  {
    key: 'cardExpiry',
    name: 'Expiry',
    fieldName: 'cardExpiry',
    minWidth: 150,
    maxWidth: 200,
    ...commonColumnProps,
  },
  {
    key: 'department',
    name: 'Department',
    fieldName: 'department',
    minWidth: 150,
    maxWidth: 250,
    ...commonColumnProps,
  },
  {
    key: 'documentFolder',
    name: 'Document Folder',
    fieldName: 'documentFolder',
    minWidth: 150,
    maxWidth: 250,
    ...commonColumnProps,
  },
  {
    key: 'cardStatus',
    name: 'Status',
    fieldName: 'cardStatus',
    minWidth: 150,
    maxWidth: 230,
    ...commonColumnProps,
  },
];
