import { IChoiceGroupStyles } from "@fluentui/react";

export const choiceGroupStyles: IChoiceGroupStyles = {
  label: {
    display: 'inline',
  },
  flexContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 240,
  },
};
export const rowSelectorGroupStyles: IChoiceGroupStyles = {
  label: {
    display: 'inline',
  },
  flexContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 150,
  },
};
