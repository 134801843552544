import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  makeStyles,
  MessageBar,
  MessageBarType,
  Stack,
} from '@fluentui/react';
import { DocumentListItem } from '../Documents/interface';
import React, { useState } from 'react';
import { SuccessButton } from 'common/components/SuccessButton';
import { GetEntityDocument } from '../folder/view/__generated__/GetEntityDocument';
interface VerifyDocumentsProps {
  selectedDocuments?: DocumentListItem[];
  document?: GetEntityDocument;
  _onConfirm: () => void;
  isDisabled?: boolean;
}
const useStyles = makeStyles((theme) => ({
  container: {
    height: 40,
  },
}));
export const VerifyDocuments: React.FC<VerifyDocumentsProps> = ({
  selectedDocuments,
  _onConfirm,
  document,
  isDisabled,
}) => {
  const styles = useStyles();
  const [visible, setVisible] = useState(false);
  const inValidDocs = selectedDocuments?.filter(
    (i) => !i._isUpdatable || i._isVerified
  );
  const isVerifiableDocuments = selectedDocuments?.filter(
    (i) => i._isUpdatable && !i._isVerified
  );
  const subText =
    isVerifiableDocuments?.length! > 0
      ? `The selected documents will be Verified.`
      : 'Are you sure you want to Verify this document?';
  const { _isUpdatable, _isVerified } = { ...document?.entityDocument };

  return (
    <>
      <>
        {document ? (
          <>
            {_isUpdatable && !_isVerified && (
              <DefaultButton
                text="Verify"
                disabled={isDisabled}
                iconProps={{ iconName: 'Accept' }}
                onClick={() => setVisible(true)}
              />
            )}
          </>
        ) : (
          <>
            {isVerifiableDocuments?.length! > 0 && (
              <DefaultButton
                text="Verify"
                iconProps={{ iconName: 'Accept' }}
                onClick={() => setVisible(true)}
              />
            )}
          </>
        )}
      </>

      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={{
            title: `Verify Document`,
            type: DialogType.largeHeader,
            subText,
          }}
          minWidth={350}
        >
          <Stack className={styles.container}>
            {inValidDocs && inValidDocs.length > 0 && (
              <MessageBar messageBarType={MessageBarType.severeWarning}>
                Some documents cannot be verified
              </MessageBar>
            )}
            <DialogFooter>
              <SuccessButton
                onClick={() => {
                  _onConfirm();
                  setVisible(false);
                }}
                text={'Verify'}
              />
              <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
            </DialogFooter>
          </Stack>
        </Dialog>
      )}
    </>
  );
};
