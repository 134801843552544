import { IColumn, Stack, Text } from "@fluentui/react";
import clsx from "clsx";
import React from "react";
import { StatusIcon } from "common/components/StatusIcon";
import { convertToTitleCase } from "common/utils/convertToTitleCase";
import { CorporateWorkgroupsFields } from "../__generated__/CorporateWorkgroupsFields";
import { ActiveLink } from "common/components/ActiveRowLink";

export type ColumnStylesProps = {
  onRenderColumnStack: string;
};

export const onRenderItems = (styles: ColumnStylesProps) => {
  const renderItemColumn = (
    item: CorporateWorkgroupsFields,
    _index: number | undefined,
    column: IColumn | undefined,
  ) => {
    if (item) {
      const fieldContent = item[column?.fieldName as keyof CorporateWorkgroupsFields] as string;
      switch (column?.key) {
        case "workgroupNumber":
          return (
            <ActiveLink
              to={`/account-management/work-groups/work-group/${item.id}`}
            >
              {fieldContent}
            </ActiveLink>
          )
        case "isNewWorkgroupSelectable":
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.onRenderColumnStack)}
            >
              <Text>{!!fieldContent ? "Yes" : "No"}</Text>
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
              horizontal
              tokens={{ childrenGap: 10 }}
            >
              <Text>{convertToTitleCase(fieldContent)}</Text>
              <StatusIcon
                approval={false}
                iconType={item.statusType!}
                approvalData={item!}
              />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  }
  return renderItemColumn
}