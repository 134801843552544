import { DetailsRow, IColumn, IDetailsHeaderProps, IDetailsListProps, IRenderFunction, SelectionMode, Stack, Sticky, StickyPositionType, Text } from '@fluentui/react';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { LookupDepotAreaSearch_lookupDepotAreaSearch, LookupDepotAreaSearch_lookupDepotAreaSearch_nodes } from '../DepotSearchAutoComplete/__generated__/LookupDepotAreaSearch';
import { columns } from './column.data';
import { useStyles } from './index.styles';

export type DepotListRow = LookupDepotAreaSearch_lookupDepotAreaSearch_nodes;
export type DepotDataType = LookupDepotAreaSearch_lookupDepotAreaSearch;

interface DepotListProps {
  depotData: DepotDataType | null | undefined;
  loading: boolean;
  onDepotAreaSelect: (depot: DepotListRow) => void;
  onColumnClick?: (order: SortOrder) => void;
  onPagination: () => void;
}

export const DepotList: React.FC<DepotListProps> = ({
  depotData,
  loading,
  onDepotAreaSelect,
  onColumnClick,
  onPagination,
}) => {
  const styles = useStyles();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();

  const { nodes, pageInfo } = { ...depotData }

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const { item } = { ...props }
    if (!props) return null;
    return (
      <Stack
        style={{ cursor: "pointer" }}
        onClick={() => onDepotAreaSelect(item)}
      >
        <DetailsRow
          className={styles.row}
          {...props}
        />
      </Stack>
    )
  };

  const onRenderItemColumn = (item?: DepotListRow, _index?: number, column?: IColumn) => {
    if (!item || !column) return undefined;
    const fieldContent = item[column.fieldName as keyof DepotListRow] as
      | string
      | null;
    switch (column?.key) {
      case 'description':
        return (
          <Stack
            horizontal
            className={styles.columnHeight}
            verticalAlign="center"
          >
            <Text
              className={styles.depot}
              onClick={() => onDepotAreaSelect(item)}
            >
              {fieldContent ? fieldContent : ''}
            </Text>
          </Stack >
        )
      default:
        return (
          <Stack
            horizontal
            className={styles.columnHeight}
            verticalAlign="center"
          >
            <Text>{fieldContent ? fieldContent : ''}</Text>
          </Stack>
        );
    }
  };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        setSortOrderParam({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
      }
    },
    [gridColumns]
  );

  useEffect(() => {
    if (sortOrderParam) {
      onColumnClick?.(sortOrderParam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrderParam])

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    const newProps: IDetailsHeaderProps | undefined = props
      ? { ...props, }
      : undefined;
    return (
      <Sticky stickyPosition={StickyPositionType.Header}>
        {defaultRender!(newProps)}
      </Sticky>
    );
  };

  return (
    <Stack
      tokens={{
        padding: "0px 20px"
      }}
    >
      <Stack className={styles.listContainer}>
        <InfiniteList
          loading={loading}
          hasNextPage={pageInfo?.hasNextPage}
          columns={gridColumns}
          items={nodes || []}
          selectionMode={SelectionMode.none}
          onColumnHeaderClick={_onColumnClick}
          onRenderItemColumn={onRenderItemColumn}
          onRenderRow={onRenderRow}
          onRenderDetailsHeader={onRenderDetailsHeader}
          onLoadMore={onPagination}
        />
      </Stack>
    </Stack>
  )
}
