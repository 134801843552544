import { Separator, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import {
  FormHookAmount,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { useCommonStyles } from 'common/styles';
import React, { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TripCreateFormValues, TripItemFormValues } from '../../../../types';
import { calcuLateTotalAmount, calculateCharge } from './utils';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';

export const OtherCharges: React.FC<{
  tripIndex: number;
  inputsDisabled: boolean;
}> = ({ tripIndex, inputsDisabled }) => {
  const commonStyles = useCommonStyles();
  const { control, setValue, getValues, trigger } =
    useFormContext<TripCreateFormValues>();
  const fieldName = `tripItems.${tripIndex}`;
  const tripItems = useWatch({ name: 'tripItems', control });
  const itemTotal = useWatch({ name: `${fieldName}.itemTotal` });
  const miscellaneousTotal = useWatch({
    name: `${fieldName}.miscellaneousTotal`,
  });

  const total = calcuLateTotalAmount(tripItems);
  const calcuLateTotalAmountMemo = useCallback(() => {
    setValue(`tripItems.${tripIndex}.itemTotal`, total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, tripIndex]);

  useEffect(() => {
    calcuLateTotalAmountMemo();
  }, [calcuLateTotalAmountMemo]);

  const handleFieldChange = (name: string, value: string) => {
    trigger();
    const fieldName = name as keyof TripItemFormValues;
    setValue(`tripItems.${tripIndex}.${fieldName}`, Number(value));
    const { tripItems } = getValues();
    const bookingFeeQuantity = tripItems?.[tripIndex]?.bookingFeeQuantity;
    const bookingFeeRate = tripItems?.[tripIndex]?.bookingFeeRate;
    const perDiemQuantity = tripItems?.[tripIndex]?.perDiemQuantity;
    const perDiemRate = tripItems?.[tripIndex]?.perDiemRate;
    const incidentalQuantity = tripItems?.[tripIndex]?.incidentalQuantity;
    const incidentalRate = tripItems?.[tripIndex]?.incidentalRate;
    const miscellaneousQuantity = tripItems?.[tripIndex]?.miscellaneousQuantity;
    const miscellaneousRate = tripItems?.[tripIndex]?.miscellaneousRate;
    const bookingFeeTotal = calculateCharge(bookingFeeQuantity, bookingFeeRate);
    const perDiemTotal = calculateCharge(perDiemQuantity, perDiemRate);
    const incidentalTotal = calculateCharge(incidentalQuantity, incidentalRate);
    const miscellaneousTotal = calculateCharge(
      miscellaneousQuantity,
      miscellaneousRate
    );
    if (bookingFeeTotal) {
      setValue(`tripItems.${tripIndex}.bookingFeeTotal`, bookingFeeTotal);
    }
    if (perDiemTotal) {
      setValue(`tripItems.${tripIndex}.perDiemTotal`, perDiemTotal);
    }
    if (incidentalTotal) {
      setValue(`tripItems.${tripIndex}.incidentalTotal`, incidentalTotal);
    }
    if (miscellaneousTotal) {
      setValue(`tripItems.${tripIndex}.miscellaneousTotal`, miscellaneousTotal);
    }
  };

  const handleChargeChange = (fieldName: string, value: string) => {
    switch (fieldName) {
      case 'bookingFeeTotal':
        setValue(`tripItems.${tripIndex}.bookingFeeQuantity`, null);
        setValue(`tripItems.${tripIndex}.bookingFeeTotal`, value);
        break;
      case 'perDiemTotal':
        setValue(`tripItems.${tripIndex}.perDiemQuantity`, null);
        setValue(`tripItems.${tripIndex}.perDiemTotal`, value);
        break;
      case 'incidentalTotal':
        setValue(`tripItems.${tripIndex}.incidentalQuantity`, null);
        setValue(`tripItems.${tripIndex}.incidentalTotal`, value);
        break;
      case 'miscellaneousTotal':
        setValue(`tripItems.${tripIndex}.miscellaneousQuantity`, null);
        setValue(`tripItems.${tripIndex}.miscellaneousTotal`, value);
        break;
      case 'comments':
        setValue(`tripItems.${tripIndex}.comments`, null);
        break;
    }
  };

  return (
    <Stack tokens={{ childrenGap: 30 }}>
      <Stack horizontal horizontalAlign="end">
        <Stack
          horizontal
          tokens={{ childrenGap: 20, padding: '0px 20px 0px 0px' }}
        >
          <Text variant="xLarge">Total </Text>
          <AmountTextView
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
            value={itemTotal?.toString() || '0.00'}
            variant="xLarge"
          />
        </Stack>
      </Stack>
      {/* HEADERS */}
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack styles={{ root: { width: 300 } }} />
        <Stack styles={{ root: { width: 110 } }} horizontalAlign="center">
          <Text variant="large" className={clsx(commonStyles.bold)}>
            Quantity
          </Text>
        </Stack>
        <Stack styles={{ root: { width: 200 } }} horizontalAlign="end">
          <Text variant="large" className={clsx(commonStyles.bold)}>
            Rate
          </Text>
        </Stack>
        <Stack styles={{ root: { width: 200 } }} horizontalAlign="end">
          <Text variant="large" className={clsx(commonStyles.bold)}>
            Charges
          </Text>
        </Stack>
      </Stack>

      {/* Booking fee */}
      <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 50 }}>
        <Text styles={{ root: { width: 270 } }} variant="large">
          Booking fee
        </Text>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <FormHookAmount
            name={`${fieldName}.bookingFeeQuantity`}
            placeholder="Enter quantity"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 110 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleFieldChange('bookingFeeQuantity', value);
              }
            }}
            allowNegative={false}
          />
          <FormHookAmount
            name={`${fieldName}.bookingFeeRate`}
            placeholder="Enter rate"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 200 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleFieldChange('bookingFeeRate', value);
              }
            }}
            allowNegative={false}
          />
          <FormHookAmount
            name={`${fieldName}.bookingFeeTotal`}
            placeholder="Enter charge"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 200 } }}
            onAmountChange={(value) => {
              if (value) {
                handleChargeChange('bookingFeeTotal', value);
              }
            }}
            disabled={inputsDisabled}
            allowNegative={false}
          />
        </Stack>
      </Stack>
      {/* Per diem */}
      <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 50 }}>
        <Text styles={{ root: { width: 270 } }} variant="large">
          Per diem
        </Text>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <FormHookAmount
            name={`${fieldName}.perDiemQuantity`}
            placeholder="Enter quantity"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 110 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleFieldChange('perDiemQuantity', value);
              }
            }}
            allowNegative={false}
          />
          <FormHookAmount
            name={`${fieldName}.perDiemRate`}
            placeholder="Enter rate"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 200 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleFieldChange('perDiemRate', value);
              }
            }}
            allowNegative={false}
          />
          <FormHookAmount
            name={`${fieldName}.perDiemTotal`}
            placeholder="Enter charge"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 200 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleChargeChange('perDiemTotal', value);
              }
            }}
            allowNegative={false}
          />
        </Stack>
      </Stack>
      {/* Incidentals */}
      <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 50 }}>
        <Text styles={{ root: { width: 270 } }} variant="large">
          Incidentals
        </Text>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <FormHookAmount
            name={`${fieldName}.incidentalQuantity`}
            placeholder="Enter quantity"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 110 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleFieldChange('incidentalQuantity', value);
              }
            }}
            allowNegative={false}
          />
          <FormHookAmount
            name={`${fieldName}.incidentalRate`}
            placeholder="Enter rate"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 200 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleFieldChange('incidentalRate', value);
              }
            }}
            allowNegative={false}
          />
          <FormHookAmount
            name={`${fieldName}.incidentalTotal`}
            placeholder="Enter charge"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 200 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleChargeChange('incidentalTotal', value);
              }
            }}
            allowNegative={false}
          />
        </Stack>
      </Stack>
      {/* Miscellaneous */}
      <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 50 }}>
        <Stack tokens={{ childrenGap: 20 }}>
          <Text variant="large" styles={{ root: { width: 270 } }}>
            Miscellaneous
          </Text>
          {miscellaneousTotal && (
            <FormHookTextField
              name={`${fieldName}.comments`}
              placeholder="Comment (optional)"
              disabled={inputsDisabled}
            />
          )}
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <FormHookAmount
            name={`${fieldName}.miscellaneousQuantity`}
            placeholder="Enter quantity"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 110 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleFieldChange('miscellaneousQuantity', value);
              }
            }}
            allowNegative={false}
          />
          <FormHookAmount
            name={`${fieldName}.miscellaneousRate`}
            placeholder="Enter rate"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 200 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleFieldChange('miscellaneousRate', value);
              }
            }}
            allowNegative={false}
          />
          <FormHookAmount
            name={`${fieldName}.miscellaneousTotal`}
            placeholder="Enter charge"
            decimalScale={2}
            fixedDecimalScale
            styles={{ field: { textAlign: 'end' }, fieldGroup: { width: 200 } }}
            disabled={inputsDisabled}
            onAmountChange={(value) => {
              if (value) {
                handleChargeChange('miscellaneousTotal', value);
              } else handleChargeChange('comments', '');
            }}
            allowNegative={false}
          />
        </Stack>
      </Stack>
      <Separator />
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
          padding: '0px 340px 0px 0px',
        }}
        horizontalAlign="end"
      >
        <Text variant="large">Total</Text>
        <FormHookAmount
          name={`${fieldName}.itemTotal`}
          decimalScale={2}
          fixedDecimalScale
          disabled={inputsDisabled}
          styles={{ field: { textAlign: 'end' } }}
          allowNegative={false}
        />
      </Stack>
    </Stack>
  );
};
