import {
  IDropdownOption,
  ITextFieldProps,
  Label,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import {
  FormHookCountryRegion,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import React from 'react';
import { TravelerDetails_traveler } from '../__generated__/TravelerDetails';
import { TravelerModalDropdownsData } from '../__generated__/TravelerModalDropdownsData';
import { useStyles } from './index.styles';
import { TravelerNameAutocomplete } from './TravelerNameAutocomplete';
import { TravelerAlias } from 'settings/account/Travelers/view/BasicForm/TravelerAlias';
import { TravelerCreate_travelerCreate_traveler } from 'common/graphql/__generated__/TravelerCreate';
import { Trip_trip } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/__generated__/Trip';

interface BasicFormProps {
  isNew: boolean;
  isUpdatable: boolean;
  isUpdateTravelerOverride: boolean;
  traveler: TravelerDetails_traveler | null | undefined;
  commonData: TravelerModalDropdownsData | undefined;
  tripData: Trip_trip | null | undefined;
  onTravelerCreate: (param: TravelerCreate_travelerCreate_traveler) => void;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  isNew,
  isUpdatable,
  isUpdateTravelerOverride,
  traveler,
  commonData,
  tripData,
  onTravelerCreate,
}) => {
  const styles = useStyles();
  const { companyDepartments, userOccupationTitles, countries } = {
    ...commonData,
  };
  const { _isUpdatable } = { ...tripData };
  const isProtected = !!tripData?.id ? !_isUpdatable : false;

  const departmentsOptions: IDropdownOption[] =
    companyDepartments?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.name || '',
    })) || [];

  const occupationOptions: IDropdownOption[] =
    userOccupationTitles?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.userOccupationTitle || '',
    })) || [];

  const countryOptions: IDropdownOption[] =
    countries?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.country || '',
    })) || [];

  const isDisabled = isUpdateTravelerOverride
    ? false
    : isUpdatable
    ? false
    : true;

  const onRenderLabel = (props: ITextFieldProps | undefined): JSX.Element => {
    return (
      <>
        <Stack
          horizontal
          verticalAlign="baseline"
          horizontalAlign="space-between"
        >
          <Label disabled={props?.disabled}>{props?.label}</Label>
          {!isNew && (
            <TravelerAlias
              traveler={traveler}
              isTravelAuthMutation
              onAliasUpdate={onTravelerCreate}
              isProtected={isProtected}
            />
          )}
        </Stack>
      </>
    );
  };

  return (
    <Stack>
      <Stack className={styles.topSheet} tokens={{ childrenGap: 10 }}>
        <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 20 }}>
          <Stack className={styles.inputContainer}>
            <TravelerNameAutocomplete
              name="firstName"
              label="Given Name"
              placeholder="Given Name"
              id={'givenName'}
              disabled={!isUpdatable || !!traveler?._isAliasInvoked}
              onRenderLabel={onRenderLabel}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <TravelerNameAutocomplete
              name="companyOrLastName"
              label="Family Name"
              placeholder="Family Name"
              id={'familyName'}
              disabled={!isUpdatable || !!traveler?._isAliasInvoked}
              required
            />
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack className={styles.inputContainer}>
            <FormHookDropdown
              label="Occupation/Title"
              placeholder="Select"
              options={occupationOptions}
              name="userOccupationTitleId"
              disabled={isDisabled}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookDropdown
              label="Department"
              placeholder="Select"
              options={departmentsOptions}
              name="travelerDepartmentId"
              disabled={isDisabled}
              required
            />
          </Stack>
        </Stack>
      </Stack>
      <Separator />
      <Text variant="xLarge" className={styles.addressLabel}>
        Addresses
      </Text>
      <Stack className={styles.addressContainer} tokens={{ childrenGap: 16 }}>
        <Stack verticalAlign="center">
          <FormHookTextField
            name="travelerAddressesByTravelerId[0].addressLine1"
            label="Street"
            placeholder="Street Number and PO Box"
            disabled={!isUpdatable}
            required
          />
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{
            childrenGap: 20,
          }}
        >
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].addressLine2"
              label="Apartment"
              placeholder="Apartment, Suite, Unit, Building, Floor"
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].cityName"
              label="City"
              placeholder="City/Town"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{
            childrenGap: 20,
          }}
        >
          <Stack style={{ flex: 2 }}>
            <FormHookCountryRegion
              countries={countryOptions}
              countryName={`travelerAddressesByTravelerId[0].countryId`}
              regionName={`travelerAddressesByTravelerId[0].stateRegionId`}
              CountryAutocompleteProps={{
                label: 'Country',
                errorMessage: '',
              }}
              StateRegionAutocompleteProps={{
                label: 'State / Region / Province',
                errorMessage: '',
              }}
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].postalCode"
              label="Zip/Postal Code"
              placeholder="Zip/Postal Code"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          verticalAlign="baseline"
          tokens={{
            childrenGap: 20,
          }}
        >
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].telephoneNumber"
              label="Phone"
              placeholder="Phone"
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookTextField
              name="travelerAddressesByTravelerId[0].emailAddress"
              label="Email"
              placeholder="Email"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
          }}
          className={styles.checkboxContainer}
        >
          <Stack className={styles.inputContainer}>
            <FormHookCheckBox
              name="travelerAddressesByTravelerId[0].isGroundTransferAddress"
              label="Ground Transfer"
              disabled={!isUpdatable}
            />
          </Stack>
          <Stack className={styles.inputContainer}>
            <FormHookCheckBox
              name="travelerAddressesByTravelerId[0].isDeliveryAddress"
              label="Delivery"
              disabled={!isUpdatable}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
