import { useQuery } from '@apollo/client';
import { IDropdownOption, Stack } from '@fluentui/react';
import { FormikDropdown, FormikTextField } from 'common/components';
import { BusinessUnits } from 'common/graphql/__generated__/BusinessUnits';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect } from 'react';
import { CardCompanyRowItem } from '../../list';
import { CardCompanyValues } from '../types';
import {
  AvailableCardCompanies,
  AvailableCardCompaniesVariables
} from '../__generated__/AvailableCardCompanies';
import { useStyles } from './index.styles';
const AVAILABLE_CARD_COMPANIES = loader('../AvailableCardCompanies.graphql');
const BUSINESS_UNITS = loader('../../../../../../common/graphql/BusinessUnits.graphql');
interface FormViewProps {
  isEdit: boolean;
  item: CardCompanyRowItem | null;
}
interface CardCompanyOptions extends IDropdownOption {
  isManualProcess: boolean | null;
}
export const FormView: React.FC<FormViewProps> = ({ isEdit }) => {
  const styles = useStyles();
  const { validateForm, setFieldValue, values } = useFormikContext<CardCompanyValues>();

  const { data } = useQuery<
    AvailableCardCompanies,
    AvailableCardCompaniesVariables
  >(AVAILABLE_CARD_COMPANIES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const { data: businessUnitData } = useQuery<
    BusinessUnits
  >(BUSINESS_UNITS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const cardCompanyOptions: CardCompanyOptions[] = data?.availableCardCompanies?.nodes.map((ele) => ({
    key: ele.id || '',
    text: ele.name || '',
    isManualProcess: ele.isManualProcess
  })) || [];

  const businessUnitOptions = businessUnitData?.businessUnits?.nodes.map((ele) => ({
    key: ele.id || '',
    text: ele.name || ''
  })) || [];

  const validateFormMemo = useCallback(validateForm, []);

  useEffect(() => {
    validateFormMemo();
  }, [validateFormMemo]);

  const onCardCompanyClear = async () => {
    await setFieldValue("cardCompanyId", null)
    await setFieldValue("isManualProcess", null)
    await setFieldValue("apiKey", null)
    await setFieldValue("description", null)
    await setFieldValue("businessUnitId", null)
  }

  return (
    <Stack className={styles.container}>
      <Stack
        tokens={{ padding: 30, childrenGap: 30 }}
      >
        <FormikDropdown
          label="Card Company"
          placeholder="Select"
          name="cardCompanyId"
          options={cardCompanyOptions}
          disabled={isEdit}
          onChange={(_, option) => {
            const selectedOption = option as CardCompanyOptions
            setFieldValue("isManualProcess", selectedOption.isManualProcess || false)
          }}
          onClear={onCardCompanyClear}
        />
        {
          values.isManualProcess &&
          <>
            <FormikTextField
              name="description"
              label="Description"
              placeholder="Description"
              disabled={isEdit}
            />
            <FormikDropdown
              label="Business Unit"
              placeholder="Select"
              name="businessUnitId"
              options={businessUnitOptions}
              disabled={isEdit}
            />
          </>
        }
        {
          values.isManualProcess === false &&
          <FormikTextField
            name="apiKey"
            label="Api Token"
            placeholder="Api Token"
          />
        }
      </Stack>
    </Stack>
  );
};
