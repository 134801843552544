import {
  Checkbox,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  Stack,
} from '@fluentui/react';
import { InvoiceRow } from 'ap/signing/types';

import React from 'react';
import { useStyles } from './index.styles';

interface CheckboxControlsProps {
  data: InvoiceRow[] | undefined;
  isAllReleaseReview: boolean;
  setReleasedIndices: (data: number[]) => void;
  setIsAllReleaseReview: (data: boolean) => void;
  isAllRequestChecked: boolean;
  setIsAllRequestlChecked: (data: boolean) => void;
  setRequestedIndices: (data: number[]) => void;
}

export const CheckboxControls: React.FC<CheckboxControlsProps> = ({
  isAllReleaseReview,
  setReleasedIndices,
  data,
  setIsAllReleaseReview,
  isAllRequestChecked,
  setIsAllRequestlChecked,
  setRequestedIndices,
}) => {
  const styles = useStyles();

  const showReleaseAll = data?.some(
    (invoice) =>
      invoice.statusType?.isDraftReleaseRequired &&
      !invoice._isApprovalDocumentsRequired
  );
  const showRequestAll = data?.some(
    (invoice) => invoice._isStagedApprovalRequest
  );

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 12 }}
      className={styles.checkboxContainer}
    >
      {showReleaseAll && (
        <Checkbox
          boxSide="end"
          styles={checkBoxStyle}
          checked={isAllReleaseReview}
          label="Release All"
          onChange={(_, value) => {
            setIsAllReleaseReview(!isAllReleaseReview!);
            if (value) {
              const selectedIndices: number[] = [];
              data?.forEach((data, index) =>
                data.statusType?.isDraftReleaseRequired &&
                !data._isApprovalDocumentsRequired
                  ? selectedIndices.push(index)
                  : null
              );
              setReleasedIndices(selectedIndices);
            } else {
              setReleasedIndices([]);
            }
          }}
        />
      )}
      {showRequestAll && (
        <Checkbox
          boxSide="end"
          styles={checkBoxStyle}
          checked={isAllRequestChecked}
          label="Request All"
          onChange={(_, value) => {
            setIsAllRequestlChecked(!isAllRequestChecked!);
            if (value) {
              const requestedIndicesArray = data?.reduce(function (
                array: number[],
                element,
                index
              ) {
                if (element._isStagedApprovalRequest) array.push(index);
                return array;
              },
              []);
              setRequestedIndices(requestedIndicesArray || []);
            } else {
              setRequestedIndices([]);
            }
          }}
        />
      )}
    </Stack>
  );
};

const checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};
