import { DefaultButton, PrimaryButton, ProgressIndicator, Stack, makeStyles } from '@fluentui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EmailCreateValues } from '../types';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
    padding: "10px 20px",
    height: 84,
  },
  progressContainer: {
    height: 12
  }
}));

interface FooterProps {
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  loading,
  onSubmit,
  onCancel,
}) => {
  const styles = useStyles();
  const { formState: { isDirty, dirtyFields } } = useFormContext<EmailCreateValues>();
  const requiredFields = ["isSupplierEmail", "userId", "externalEmail"];
  const isExist = Object.keys(dirtyFields).some(ele => requiredFields.includes(ele));
  const isDisabled = !isDirty || !isExist;

  return (
    <Stack
      className={styles.container}
    >
      <Stack
        tokens={{ childrenGap: 10 }}
      >
        <Stack className={styles.progressContainer}>
          {loading && <ProgressIndicator />}
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
          }}
        >
          <PrimaryButton
            text="Send"
            onClick={onSubmit}
            disabled={isDisabled}
          />
          <DefaultButton
            text="Cancel"
            onClick={onCancel}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
