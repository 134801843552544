import { IDropdownOption, makeStyles, Text } from '@fluentui/react';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { DIETARY_REQUIREMENTS_INITIAL_VALUES } from '../../constants';
import { TravelerValues } from '../../types';
import { TravelerCommonData_dietaryRequirements_nodes } from '../../__generated__/TravelerCommonData';
import { DietaryRequirementsInputRow } from './DietaryRequirementsInputRow';

interface DietaryRequirementsInputProps {
  dietaryRequirements: TravelerCommonData_dietaryRequirements_nodes[];
  inputsDisabled: boolean;
}
export interface DietaryRequirementsOptionsProps extends IDropdownOption {
  isDescriptionRequired: boolean | null;
}

const useStyles = makeStyles((theme) => ({
  dietaryRequirements: {
    marginBottom: 20
  }
}));


export const DietaryRequirementsInput: React.FC<DietaryRequirementsInputProps> = ({
  dietaryRequirements,
  inputsDisabled
}) => {
  const styles = useStyles();
  const { values } = useFormikContext<TravelerValues>();

  return (
    <>
      <Text variant="xLarge" className={styles.dietaryRequirements}>Dietary Requirements</Text>
      <FieldArray name="travelerDietaryRequirementsByTravelerId">
        {({ remove, push }) => (
          <>
            {
              values.travelerDietaryRequirementsByTravelerId?.map((item, index) => {
                const isLast =
                  !!values.travelerDietaryRequirementsByTravelerId &&
                  index === values.travelerDietaryRequirementsByTravelerId.length - 1;

                const isFirstRow =
                  !!values.travelerDietaryRequirementsByTravelerId &&
                  index === 0;
                const dietaryRequirementsOptions: DietaryRequirementsOptionsProps[] = dietaryRequirements
                  .filter(
                    (type) =>
                      type.id === item.dietaryRequirementId ||
                      !values.travelerDietaryRequirementsByTravelerId?.some(
                        (row) => row.dietaryRequirementId === type.id
                      )
                  )
                  .map((type) => ({
                    key: type.id,
                    text: type.description || '',
                    isDescriptionRequired: type.isDescriptionRequired
                  }));
                return (
                  <DietaryRequirementsInputRow
                    key={index}
                    fieldName={`travelerDietaryRequirementsByTravelerId[${index}]`}
                    dietaryRequirementsOptions={dietaryRequirementsOptions}
                    onChange={() => {
                      if (isLast && dietaryRequirementsOptions.length > 1)
                        push(DIETARY_REQUIREMENTS_INITIAL_VALUES);
                    }}
                    onRemove={() => {
                      remove(index);
                      if (dietaryRequirementsOptions.length === 1) {
                        push(DIETARY_REQUIREMENTS_INITIAL_VALUES);
                      }
                    }}
                    isFirstRow={isFirstRow}
                    rowIndex={index}
                    inputsDisabled={inputsDisabled}
                  />
                )
              })
            }
          </>
        )}
      </FieldArray>
    </>
  )
}
