import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  makeStyles,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';
import { DangerButton } from 'common/components/DangerButton';
import { loader } from 'graphql.macro';
import { useApolloClient, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { GetPaymentDetails } from '../__generated__/GetPaymentDetails';
import {
  PaymentDelete,
  PaymentDeleteVariables,
} from 'ap/paymentTracking/__generated__/PaymentDelete';
import { PaymentSearchFilterTotals_paymentSearchFilterTotals } from 'ap/paymentTracking/list/__generated__/PaymentSearchFilterTotals';
const DELETE_PAYMENT = loader('../../../PaymentDelete.graphql');

interface DeletePaymentProps {
  payment?: GetPaymentDetails;
  _onConfirm?: () => void;
}
const useStyles = makeStyles((theme) => ({
  container: {
    height: 40,
  },
}));

export const DeletePayment: React.FC<DeletePaymentProps> = ({ payment }) => {
  const { cache } = useApolloClient();
  const { addToast } = useToasts();
  const styles = useStyles();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { _isDeletable, id, _rowTimestamp } = {
    ...payment?.payment,
  };
  const [DeletePayment] = useMutation<PaymentDelete, PaymentDeleteVariables>(
    DELETE_PAYMENT
  );

  const onDelete = async () => {
    const { errors } = await DeletePayment({
      variables: {
        input: { entityDelete: [{ id: id!, rowTimestamp: _rowTimestamp! }] },
      },
      update: (cache, { data }) => {
        try {
          cache.modify({
            fields: {
              paymentSearchFilterTotals(
                existing: PaymentSearchFilterTotals_paymentSearchFilterTotals
              ) {
                if (existing?.totalCount1 !== 0) {
                  return {
                    ...existing,
                    totalAmount1:
                      Number(existing?.totalAmount1) -
                      Number(payment?.payment?.paidAmount ?? 0),
                    totalCount1: (existing?.totalCount1 || 0) - 1,
                  };
                } else {
                  return {
                    ...existing,
                  };
                }
              },
            },
          });
        } catch (error) {}
      },
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      history.replace('/pay/payment_tracking');
      const identify = cache.identify({
        ...payment?.payment,
      });
      cache.evict({ id: identify });
      cache.gc();

      addToast('Payment deleted successfully', { appearance: 'success' });
    }
  };

  return (
    <Stack>
      <IconButton
        disabled={!_isDeletable}
        iconProps={{ iconName: 'Delete' }}
        onClick={() => setVisible(true)}
      />

      {visible && (
        <Dialog
          hidden={false}
          dialogContentProps={{
            title: `Delete Document`,
            type: DialogType.largeHeader,
            subText: 'Are you sure you want to delete this document?',
          }}
          minWidth={350}
        >
          <Stack className={styles.container}>
            <DialogFooter>
              <DangerButton
                onClick={() => {
                  onDelete();
                  setVisible(false);
                }}
                text={'Delete'}
              />
              <DefaultButton onClick={() => setVisible(false)} text="Cancel" />
            </DialogFooter>
          </Stack>
        </Dialog>
      )}
    </Stack>
  );
};
