import { useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import {
  Area,
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  capitalize,
  Chart,
  ChartProps,
  currencyFormatter,
  CustomizedAxisTick,
  useColors,
  useStyles,
} from '../common';
import { useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { PayableChartData } from './__generated__/PayableChartData';

const query = loader('./PayableChartData.graphql');
const Component: React.FC<ChartProps<PayableChartData>> = ({
  data,
  height,
  width,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const colors = useColors();
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [animation, setAnimation] = useState(true);
  const history = useHistory();

  return (
    <ComposedChart
      height={height}
      width={width}
      margin={{
        top: 20,
        right: 20,
        left: 20,
        bottom: 10,
      }}
      data={data?.kpiAgedPayables?.nodes}
      className={styles.pointer}
      barCategoryGap={'20%'}
      onClick={() => history.push('/pay/payment_tracking')}
    >
      <CartesianGrid
        strokeDasharray="3 3"
        stroke={theme.palette.neutralQuaternary}
      />
      <XAxis
        dataKey="title"
        tick={<CustomizedAxisTick fill={theme.palette.neutralPrimary} />}
      />
      <YAxis tick={{ fill: theme.palette.neutralPrimary }} />
      <Legend />
      <Tooltip
        formatter={(value: number, name: string) => [
          currencyFormatter.format(value),
          capitalize(name),
        ]}
        separator=": "
        contentStyle={{
          backgroundColor: theme.palette.white,
          borderColor: theme.palette.neutralLighter,
        }}
        cursor={{
          fill: theme.palette.neutralQuaternaryAlt,
        }}
        labelStyle={{ fontWeight: 600 }}
        isAnimationActive={false}
      />
      <Area
        type="monotone"
        dataKey="due"
        fill={theme.palette.green}
        stroke={theme.palette.green}
        fillOpacity={0.5}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      />
      <Bar
        dataKey="charges"
        fill={theme.palette.blue}
        onMouseEnter={(_, index) => setActiveIndex(index)}
        onMouseLeave={(_, index) => {
          if (activeIndex === index) setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
      >
        {data?.kpiAgedPayables?.nodes.map((_entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={colors[0][index === activeIndex ? 1 : 0]}
          />
        ))}
      </Bar>
      <Line type="monotone" dataKey="payments" stroke={theme.palette.orange} />
    </ComposedChart>
  );
};

export const PayableChart: Chart<PayableChartData> = {
  Component,
  query,
  hasData: (data) => !!data.kpiAgedPayables?.nodes.length,
};
