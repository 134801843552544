import React, { useState } from 'react';
import './my-node.css';
import {
  Icon,
  Stack,
  Text,
  TextField,
  CommandBarButton,
  Callout,
  Persona,
  PersonaSize,
  PrimaryButton,
  DefaultButton,
  DirectionalHint,
  Separator,
} from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { HierarchyNode } from '../index';
import { useToasts } from 'react-toast-notifications';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  MessageApprovalCreate,
  MessageApprovalCreateVariables,
} from 'common/graphql/__generated__/MessageApprovalCreate';
import {
  getGlobalDateFormat,
  getPrettierdate,
  getTimeFormat,
} from 'common/utils/dateFormats';
const ADD_NEW_MESSAGE = loader(
  '../../../graphql/MessageApprovalCreate.graphql'
);

interface NodeViewProps {
  nodeData: HierarchyNode;
}
export const NodeView: React.FC<NodeViewProps> = ({ nodeData }) => {
  const buttonId = useId(`callout-label${nodeData.id}`);
  const nodeId = useId(`nodeId-label${nodeData.id}`);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [
    detailsCardVisible,
    { setTrue: openDetailsCard, setFalse: closeDetailsCard },
  ] = useBoolean(false);

  const commonStyles = useCommonStyles();
  const styles = useStyles();

  const getStatus = () => {
    let status = '';
    if (nodeData.isApproved === true) status = 'Approved';
    else if (nodeData.isRejected === true) status = 'Declined';
    else status = 'Pending';
    return status;
  };

  const status = getStatus();

  const getStatusIcon = () =>
    status === 'Approved'
      ? 'accept'
      : status === 'Declined'
        ? 'cancel'
        : 'Clock';

  const getBlockColor = () => {
    switch (status) {
      case 'Approved':
        return styles.backgroundApprovedBlock;
      case 'Declined':
        return styles.backgroundDeclineBlock;
      default:
        return nodeData.isTree
          ? styles.whiteBackground
          : styles.backgroundPendingBlock;
    }
  };

  const getHeaderColor = () => {
    // if (nodeData.isTree) return styles.headerPending;
    switch (status) {
      case 'Approved':
        return styles.headerApprove;
      case 'Declined':
        return nodeData.isTree ? styles.headerApprove : styles.headerDeclined;
      default:
        return styles.headerPending;
    }
  };

  const getDescriptionColor = () =>
    status === 'Approved'
      ? styles.descriptionColorApprove
      : styles.descriptionColor;

  const getIconColor = () =>
    status === 'Pending' ? styles.iconColorPending : styles.iconColor;

  function isEmpty(str: string) {
    return !str || str.length === 0;
  }

  const getTreeHeadingStyle = () => {
    if (nodeData.isTree) return clsx(styles.treeHeadingStyle, getBlockColor());
  };

  const detailCardOpening =
    isEmpty(nodeData.requestedDate!) &&
    isEmpty(nodeData.topic!) &&
    isEmpty(nodeData.subTopic!);

  return (
    <div>
      <Stack
        onMouseEnter={() => {
          if (!detailCardOpening) openDetailsCard();
        }}
        onMouseLeave={() => closeDetailsCard()}
        verticalAlign="space-between"
        className={
          nodeData.isTree
            ? styles.treeContainer
            : clsx(
              styles.nodeContainer,
              status === 'Approved'
                ? styles.backgroundApprove
                : styles.backgroundDefault
            )
        }
      >
        <Stack id={nodeId} tokens={{ childrenGap: 10 }}>
          <Stack className={getTreeHeadingStyle()}>
            {!nodeData.parentId && <>
              <Text
                variant="medium"
                className={clsx(commonStyles.semibold, getHeaderColor())}
              >Requestor</Text>
              <Separator />
            </>}
            <Text
              variant={'mediumPlus'}
              className={clsx(commonStyles.semibold, getHeaderColor())}
            >
              {nodeData.name}
            </Text>
          </Stack>

          {nodeData.statusDate && !nodeData.isTree && (
            <Stack>
              <Text className={getDescriptionColor()}>
                {`${getPrettierdate(
                  getGlobalDateFormat(nodeData.statusDate!)
                )}, ${getTimeFormat(nodeData.statusDate!)}`}
              </Text>
            </Stack>
          )}
        </Stack>

        {!nodeData.isTree && (
          <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Text
              className={clsx(getDescriptionColor(), styles.textviewStyles)}
              variant="xSmall"
            >
              {nodeData.digitalSignature}
            </Text>
            {!nodeData.isTree && (
              <CommandBarButton
                className={clsx(
                  getStatus() === 'Approved'
                    ? styles.msgButtonAppproved
                    : styles.msgButtonDeclined,
                  styles.msgButton
                )}
                id={buttonId}
                onClick={toggleIsCalloutVisible}
                text="Message"
                iconProps={{ iconName: 'comment' }}
              />
            )}
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              horizontalAlign="center"
              verticalAlign="center"
              className={clsx(getBlockColor(), styles.statusBlock)}
            >
              <Icon iconName={getStatusIcon()} className={getIconColor()} />
              <Text className={getIconColor()}>{status}</Text>
            </Stack>
          </Stack>
        )}
      </Stack>
      {isCalloutVisible && (
        <MessageCallout
          onclick={toggleIsCalloutVisible}
          buttonId={buttonId}
          nodeData={nodeData}
        />
      )}

      {detailsCardVisible && (
        <Callout
          directionalHint={DirectionalHint.topAutoEdge}
          calloutWidth={400}
          target={`#${nodeId}`}
        >
          <Stack tokens={{ childrenGap: 20, padding: 20 }}>
            {!isEmpty(nodeData.requestedDate!) && (
              <DataView
                title={'Requested date'}
                content={getGlobalDateFormat(nodeData.requestedDate!)}
              />
            )}
            {!isEmpty(nodeData.topic!) && (
              <DataView title={'Topic'} content={nodeData.topic!} />
            )}
            {!isEmpty(nodeData.subTopic!) && (
              <DataView title={'Subtopic'} content={nodeData.subTopic!} />
            )}
          </Stack>
        </Callout>
      )}
    </div>
  );
};

interface MessageCalloutProps {
  onclick: () => void;
  buttonId: string;
  nodeData: HierarchyNode;
}
const MessageCallout: React.FC<MessageCalloutProps> = ({
  onclick,
  buttonId,
  nodeData,
}) => {
  const { addToast } = useToasts();

  const [addNewMessage] = useMutation<
    MessageApprovalCreate,
    MessageApprovalCreateVariables
  >(ADD_NEW_MESSAGE, { errorPolicy: 'all' });

  const [message, setmessage] = useState<string | undefined>();
  const onSendMessage = async () => {
    const { errors } = await addNewMessage({
      variables: {
        input: {
          entityId: nodeData.entityApprovalId!,
          message: {
            messageComment: message!,
            isResponseRequired: false,
            reminderDates: null,
          },
          department: null,
          communicationChannel: null,
          user: [nodeData.messageUserId],
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      onclick();
      addToast('Message has been sent', { appearance: 'success' });
    }
  };

  const sendBtnDisabled = message?.length === 0 || message === undefined;
  return (
    <Callout calloutWidth={500} target={`#${buttonId}`}>
      <Stack tokens={{ childrenGap: 20, padding: 20 }}>
        <Stack horizontal horizontalAlign="space-between">
          <Text block variant="xLarge">
            Send message
          </Text>
          <CloseButton onClick={onclick} />
        </Stack>
        <Persona text={nodeData.name!} size={PersonaSize.size24} />
        <TextField
          multiline
          placeholder="Enter message"
          onChange={(_, value) => {
            setmessage(value);
          }}
        />
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              disabled={sendBtnDisabled}
              onClick={onSendMessage}
              text="Send message"
            />
            <DefaultButton onClick={onclick} text="Cancel" />
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
interface DataViewProps {
  title: string;
  content: string;
}
const DataView: React.FC<DataViewProps> = ({ title, content }) => {
  const commonStyles = useCommonStyles();
  return (
    <Stack>
      <Text className={commonStyles.semibold} variant="medium">
        {title}
      </Text>
      <Text>{content}</Text>
    </Stack>
  );
};
