import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container:{
    height: 500,
  },
  defaultWidth: {
    width: 1400,
  },
  defaultHeight: {
    height: 500,
  },
  customHeight:{
  height: 270
  }
}));
