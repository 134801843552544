import { BudgetItemFormValues, DepartmentItemValues } from './types';

export const DEPARTMENT_BUDGET_INITIAL_VALUES: DepartmentItemValues = {
  id: null,
  departmentId: null,
  businessUnitId: null,
  budgetTypeId: null,
  currencyId: null,
  lookupAccountId: null,
  budgetAmount: null,
  _rowTimestamp: null,
  _isUpdatable: null,
  _isDeletable: null,
  lookupAccount: null,
};
export const BUDGET_ITEM_INITIAL_VALUES: BudgetItemFormValues = {
  id: null,
  name: null,
  startingBudgetDate: null,
  endingBudgetDate: null,
  isPrimary: null,
  departmentBudgets: null,
};
