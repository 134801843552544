import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: `2px dashed ${theme.palette.neutralQuaternary}`,
    padding: 10,
    height: 100,
  },
  fileContainerStyles: {
    margin: '0px 20px 10px 0px',
    paddingLeft: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.neutralLighter,
  },
  uploadIconStyle: { color: 'white', fontSize: FontSizes.size24 },
  dropZoneHover: {
    backgroundColor: theme.palette.themePrimary,
    borderColor: theme.palette.white,
  },
  dialogStyles: {
    alignItems: 'self-start',
    padding: 25,
    paddingBottom: 0,
  },
  container: {
    width: 600,
  },
  containerStyles: {
    paddingRight: 25,
    paddingLeft: 25,
  },
  fieldWraper: {
    width: '50%',
    marginRight: 10,
  },
  widthContainer: {
    width: '50%',
  },
  flex50: {
    flex: 0.5,
  },
}));
