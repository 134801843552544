import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 180,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: '_activeApprovalCount',
    name: 'Existing Approvals',
    fieldName: '_activeApprovalCount',
    minWidth: 120,
    maxWidth: 120,
    ...commonColumnProps,
  },
];
