import { useQuery, useReactiveVar } from '@apollo/client';
import { IconButton, Stack, Text, makeStyles } from '@fluentui/react';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { PurchaseOrderCommonData } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { setUserDefaults } from 'utility/cache/ui';
import { getDefaultDistribution } from '../utils';
import { ColumnsHeader } from './ColumnHeader';
import { DistributionRowTotal } from './Footer';
import { FormField } from './FormField';
import { getColumns } from './columns.data';
import { PurchaseOrderItemFormProps } from './interface';
import { ReferenceCodeList } from 'common/graphql/__generated__/ReferenceCodeList';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
const COMMON_DATA = loader('../../../../PurchaseOrderCommonData.graphql');
const REFERENCE_CODE = loader(
  '../../../../../../common/graphql/ReferenceCodesList.graphql'
);

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    margin: '0px 20px',
  },
}));

interface AccountingProps {
  purchaseOrder: PurchaseOrder | undefined;
  isUpdatable: boolean;
  // businessUnitId?: string;
}

export const Accounting: React.FC<AccountingProps> = ({
  purchaseOrder,
  isUpdatable,
}) => {
  const styles = useStyles();
  const userDefaults = useReactiveVar(setUserDefaults);
  const { columnArray } = getColumns(userDefaults);
  const renderRef = useRef<boolean>(true);

  const {
    setValue,
    // reset,
    watch,
  } = useFormContext<PurchaseOrderItemFormProps>();

  const { fields, remove, append } = useFieldArray<PurchaseOrderItemFormProps>({
    name: 'purchaseOrderItemDistribution',
  });

  const { data: commonData } = useQuery<PurchaseOrderCommonData>(COMMON_DATA, {
    fetchPolicy: 'cache-first',
  });

  const { data: referenceCodes } = useQuery<ReferenceCodeList>(REFERENCE_CODE, {
    fetchPolicy: 'cache-first',
  });

  const dropDownValues = {
    productionIncentivesOptions: formatDropdownOptions(
      commonData?.productionIncentives?.nodes,
      {
        getKey: (item) => item.id,
        getText: (item) => item._fullDescription!,
        includeAll: false,
      }
    ),
    tax1099T4Options: formatDropdownOptions(commonData?.tax1099T4Types?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.tax1099T4Type!,
      includeAll: false,
    }),
  };

  const watchInvoiceDistributions = watch('purchaseOrderItemDistribution');

  const watchValue = useWatch<PurchaseOrderValues>();
  const lastItem = watchInvoiceDistributions?.[fields.length - 1];

  const isValueExist = lastItem
    ? Object.entries(lastItem!).reduce((prev, [key, value]) => {
        if (
          (key === 'referenceCode6' && value !== null) ||
          (key === 'referenceCode7' && value !== null) ||
          (key === 'projectReference' && !!value) ||
          (key === 'accountReference' && !!value) ||
          (key === '_accountName' && !!value)
        )
          return prev;
        const isValue = value !== null && value !== undefined;
        return isValue || prev;
      }, false)
    : false;

  useEffect(() => {
    if (isValueExist && !renderRef.current && isUpdatable) {
      const values = getDefaultDistribution(userDefaults);
      append(values, { shouldFocus: false });
    }
    renderRef.current = false;
  }, [append, isValueExist, userDefaults, isUpdatable]);

  const refCodesOptions = {
    refCode1Options: formatDropdownOptions(
      referenceCodes?.reference1Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode2Options: formatDropdownOptions(
      referenceCodes?.reference1Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode3Options: formatDropdownOptions(
      referenceCodes?.reference1Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode4Options: formatDropdownOptions(
      referenceCodes?.reference1Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
  };

  const { _isInvoiceScheduleUpdatable } = { ...purchaseOrder?.purchaseOrder };

  return (
    <Stack>
      <Stack tokens={{ padding: '20px 0px 0px 20px' }}>
        <Text variant="xLarge">Accounting</Text>
      </Stack>
      <Stack className={styles.scrollContainer}>
        <Stack tokens={{ padding: '10px 0px 10px' }}>
          <Stack horizontal style={{ overflowX: 'clip' }}>
            <Stack tokens={{ padding: '52px 0px', childrenGap: 3 }}>
              {fields.map((field, index) => {
                const isDeletable = field._isDeletable !== false;
                const isLastItem = fields.length - 1 !== index;
                return (
                  <Stack key={index} style={{ height: 33, width: 40 }}>
                    {isLastItem && isDeletable && isUpdatable && (
                      <IconButton
                        disabled={!isDeletable}
                        iconProps={{ iconName: 'Blocked2Solid' }}
                        ariaLabel="delete"
                        onClick={() => remove(index)}
                        style={{ color: 'red' }}
                      />
                    )}
                  </Stack>
                );
              })}
            </Stack>
            <Stack style={{ padding: '0px 0px 40px 0px' }}>
              <ColumnsHeader columnsArray={columnArray} />
              {fields.map((field, index) => {
                const baseField = `purchaseOrderItemDistribution.${index}`;
                const isRowUpdatable = _isInvoiceScheduleUpdatable
                  ? field._isUpdatable !== false
                    ? true
                    : false
                  : false;

                return (
                  <Stack key={index} horizontal tokens={{ childrenGap: 10 }}>
                    {columnArray.map((value, key) => {
                      return (
                        <FormField
                          index={index}
                          businessUnitId={watchValue.businessUnitId || null}
                          _isUpdatable={isRowUpdatable}
                          dropdownValues={dropDownValues}
                          baseField={baseField}
                          columnData={value}
                          key={field.id + key}
                          refCodesOptions={refCodesOptions}
                          onSelectAccount={(data) => {
                            setValue(
                              `purchaseOrderItemDistribution.${index}._accountName`,
                              data.lookupName
                            );
                            if (!!data.projectAccount) {
                              setValue(
                                `purchaseOrderItemDistribution.${index}.projectReference`,
                                data.projectAccount
                              );
                            }
                            if (!!data.setAccount) {
                              setValue(
                                `purchaseOrderItemDistribution.${index}.setReference`,
                                data.setAccount
                              );
                            }
                          }}
                        />
                      );
                    })}
                  </Stack>
                );
              })}
              <DistributionRowTotal columnsArray={columnArray} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
