import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.neutralLight,
  },
  applyAmountStyle: {
    width: 300,
  },
  requiredFieldStyle: {
    color: theme.palette.red,
  },
  explanationStyles: {
    marginLeft: 20,
    marginRight: "10px !important",
  },
  textFieldStyles: {
    width: 300,
  },
}));
