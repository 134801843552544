import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => {
  return {
  contentWrapper: {
    height: 25,
    alignSelf: 'center',
    borderRadius: 13,
    minWidth: 25,
    backgroundColor: theme.palette.themePrimary,
  },
  width10: {
    width: 10
  },
  itemCount: {
    color: 'white'
  }
}
});


