import { Label, Stack, makeStyles } from '@fluentui/react';
import { FormHookTextField } from 'common/components/FormHooksFields/FormHookTextField';
import React from 'react';

export const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 18,
  },
}));

interface BankDetailsProps {
  disabled: boolean;
}

export const BankDetails: React.FC<BankDetailsProps> = ({ disabled }) => {
  const styles = useStyles();
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Label className={styles.heading}>Bank Details</Label>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="bankAccountName"
            label="Account Name"
            placeholder="Account Name"
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="bankRoutingNumber"
            label="Routing Number"
            placeholder="Routing Number"
          />
        </Stack>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="bankName"
            label="Bank Name"
            placeholder="Bank Name"
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="bankSortCode"
            label="Sort Code"
            placeholder="Sort Code"
          />
        </Stack>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="bankIbanCode"
            label="IBAN (INTL Wires)"
            placeholder="IBAN (INTL Wires)"
          />
        </Stack>
        <Stack style={{ flex: 1 }}>
          <FormHookTextField
            name="bankAccountNumber"
            label="Account Number"
            placeholder="Account Number"
          />
        </Stack>
      </Stack>
      <Stack>
        <Stack style={{ width: '49%' }}>
          <FormHookTextField
            name="bankSwiftCode"
            label="BIC/SWIFT Code"
            placeholder="BIC/SWIFT Code"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
