import { useMutation, useQuery } from '@apollo/client';
import {
  ContextualMenu,
  IColumn,
  IconButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { InfiniteList } from 'common/components/InfiniteList';
import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import {
  AttachDocumentPoolInput,
  DocumentPoolRecipientsOrderBy,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import {
  AttachDocumentPoolDocuments,
  AttachDocumentPoolDocumentsVariables,
} from './__generated__/AttachDocumentPoolDocuments';
import {
  DocumentPoolRecipients,
  DocumentPoolRecipientsVariables,
  DocumentPoolRecipients_documentPoolRecipients_nodes,
} from './__generated__/DocumentPoolRecipients';
const DOCUMENTS_FOLDER_RECIEPTS = loader('./DocumentPoolRecipients.graphql');
const ATTACH_DOCUMENTS_TO_FOLDER = loader('./AttachDocumentPool.graphql');

interface MoveToFolderProps {
  poolSelectedID?: string | undefined;
  documentsSelected: Partial<EntityDocumentsFields>[];
  onSuccess: (poolMovedToId: string, filesMovedCount: number) => void;
}
export const MoveToFolder: React.FC<MoveToFolderProps> = ({
  onSuccess,
  ...props
}) => {
  const [visibility, setVisibility] = useState(false);
  return (
    <Stack>
      <TooltipHost content={'Move'}>
        <IconButton
          iconProps={{ iconName: 'OpenInNewWindow' }}
          onClick={() => setVisibility(true)}
        />
      </TooltipHost>
      {visibility && (
        <MoveToFolderModal
          {...props}
          closeModal={() => {
            setVisibility(false);
          }}
          onSuccess={(poolMovedToId, filesMovedCount) => {
            setVisibility(false);
            onSuccess(poolMovedToId, filesMovedCount);
          }}
        />
      )}
    </Stack>
  );
};

type MoveToFolderModal = MoveToFolderProps & {
  closeModal: () => void;
  onSuccess: (poolMovedToId: string, filesMovedCount: number) => void;
};
export const MoveToFolderModal: React.FC<MoveToFolderModal> = ({
  closeModal,
  documentsSelected,
  onSuccess,
  poolSelectedID,
}) => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const [selectedList, setSelectedList] = useState<
    DocumentPoolRecipients_documentPoolRecipients_nodes[]
  >([]);
  const { data, loading } = useQuery<
    DocumentPoolRecipients,
    DocumentPoolRecipientsVariables
  >(DOCUMENTS_FOLDER_RECIEPTS, {
    variables: {
      orderBy: [DocumentPoolRecipientsOrderBy.NAME_ASC],
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [moveDocumentsToFolder, { loading: attachLoading }] = useMutation<
    AttachDocumentPoolDocuments,
    AttachDocumentPoolDocumentsVariables
  >(ATTACH_DOCUMENTS_TO_FOLDER, { errorPolicy: 'all' });

  let _renderItemColumn = (
    item: DocumentPoolRecipients_documentPoolRecipients_nodes,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    switch (column?.key) {
      case 'name':
        return <Text>{item.name}</Text>;
      case 'documentType':
        return <Text>{item.recipientDefaultDocumentTypes?.documentType}</Text>;
      case 'availableDocuments':
        return (
          <TooltipHost
            content={`${item.recipientPoolTotals?.selectedDocuments} Attached`}
          >
            <Text>{item.recipientPoolTotals?.availableDocuments}</Text>
          </TooltipHost>
        );
    }
  };

  const onDocumentsMove = async () => {
    const documentIds = documentsSelected.map((selectedIndex) => {
      return selectedIndex.id!;
    });
    const variables: AttachDocumentPoolInput = {
      poolId: selectedList[0].id,
      entityDocumentId: documentIds,
    };

    const { errors } = await moveDocumentsToFolder({
      variables: {
        input: variables,
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      setSelectedList([]);
      onSuccess(selectedList[0].id, documentIds.length);
      addToast(
        `${documentIds.length} files moved to "${selectedList[0].name}"`,
        { appearance: 'success' }
      );
    }
  };

  const documentPoolRows = poolSelectedID
    ? data?.documentPoolRecipients?.nodes.filter(
        (item) => item.id !== poolSelectedID
      )
    : data?.documentPoolRecipients?.nodes;

  return (
    <Modal
      isOpen={true}
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
    >
      <Stack className={styles.mainContainer} tokens={{ childrenGap: 20 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ padding: 25 }}
        >
          <Stack tokens={{ childrenGap: 10 }} horizontal>
            <Text variant={'xLarge'}>Document Folders</Text>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              text="Move"
              disabled={!selectedList.length || attachLoading}
              iconProps={{ iconName: 'OpenInNewWindow' }}
              onClick={onDocumentsMove}
            />
            <CloseButton onClick={closeModal} />
          </Stack>
        </Stack>

        <ProgressIndicator progressHidden={!attachLoading} />
        <InfiniteList<DocumentPoolRecipients_documentPoolRecipients_nodes>
          items={documentPoolRows}
          loading={loading}
          selectionMode={SelectionMode.single}
          columns={columns}
          onRenderItemColumn={_renderItemColumn}
          onSelectionChanged={setSelectedList}
        />
      </Stack>
    </Modal>
  );
};
