import * as  Yup from 'yup';

export const validationSchema = () => (
    Yup.object({
        name: Yup.string().required('Required').nullable(),
        defaultDirectoryTypeId: Yup.number().nullable(),
        defaultDocumentTypeId: Yup.number()
          .when("isDocumentTypeSelector", {
            is: true,
            then: Yup.number().required('Required').nullable(),
            otherwise: Yup.number().nullable()
          }),   
        isBusinessUnitSelector: Yup.boolean(),     
        defaultBusinessUnitId: Yup.string()
          .when("isBusinessUnitSelector", {
            is: true,
            then: Yup.string().required("Required").nullable(),
            otherwise: Yup.string().nullable()
          }),
        isDepartmentSelector: Yup.boolean(),
        defaultDepartmentId: Yup.string()
          .when("isDepartmentSelector", {
            is: true,
            then: Yup.string().required("Required").nullable(),
            otherwise: Yup.string().nullable()
          })
    })
)


