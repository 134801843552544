import React from 'react';
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { Form, Formik } from 'formik';
import { TAG_INITIAL_VALUES } from './constant';
import { validationSchema } from './validations';
import { TagEntityValues } from './types';
import { FormikCheckBox, FormikTextField } from 'common/components';
import { ColorPickerCallout } from './ColorPickerCallout';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { TagCreate, TagCreateVariables } from './__generated__/TagCreate';
import { useToasts } from 'react-toast-notifications';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import {
  Tags,
  TagsVariables,
  Tags_tags_nodes,
} from '../list/__generated__/Tags';
import { TagUpdate, TagUpdateVariables } from './__generated__/TagUpdate';
import { TABLE_ROWS } from 'common/constants';
import { TagsOrderBy } from 'common/types/globalTypes';

const TAG_LIST = loader('../list/Tags.graphql');
const CREATE_TAG = loader('./TagCreate.graphql');
const UPDATE_TAG = loader('./TagUpdate.graphql');

interface TagFormProps {
  tagData: Tags_tags_nodes | null;
  visible: boolean;
  onDismiss: () => void;
  isNew: boolean;
}

export const CreateTagForm: React.FC<TagFormProps> = ({
  visible,
  onDismiss,
  tagData,
  isNew,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  let initialValues: TagEntityValues = TAG_INITIAL_VALUES;
  if (tagData) {
    initialValues = {
      ...tagData,
    };
  }

  const [createTag, { loading: tagCreationLoading, }] = useMutation<
    TagCreate,
    TagCreateVariables
  >(CREATE_TAG, {
    errorPolicy: 'all',
  });

  const [updateTag, { loading: tagUpdateLoading }] = useMutation<
    TagUpdate,
    TagUpdateVariables
  >(UPDATE_TAG, {
    errorPolicy: 'all',
  });

  const constructInputData = (values: TagEntityValues) => {
    return {
      name: values.name!,
      badgeName: values.badgeName!,
      badgeColor: values.badgeColor!,
      isPrivate: values.isPrivate,
      isMonitored: values.isMonitored,
      _isActive: values._isActive,
      description: values.description,
      isAccessTag: values.isAccessTag
    };
  };

  const handleSubmit = async (values: TagEntityValues) => {
    if (isNew) {
      const { errors } = await createTag({
        variables: {
          input: {
            tag: {
              ...constructInputData(values),
            },
          },
        },
        update: (cache, { data }) => {
          if (data?.tagCreate?.tag) {
            const cacheData = cache.readQuery<Tags, TagsVariables>({
              query: TAG_LIST,
            });
            if (cacheData) {
              const updatedTagsData: Tags = {
                tags: {
                  ...cacheData?.tags!,
                  pageInfo: cacheData?.tags?.pageInfo!,
                  nodes: [data?.tagCreate?.tag!, ...cacheData?.tags?.nodes!],
                },
              };
              cache.writeQuery<Tags, TagsVariables>({
                query: TAG_LIST,
                data: updatedTagsData,
              });
            }
          }
        },
      });
      if (!errors) {
        addToast('Tag Created Successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    } else {
      //Update Tag
      const { errors } = await updateTag({
        variables: {
          input: {
            id: tagData?.id!,
            rowTimestamp: tagData?._rowTimestamp!,
            tagPatch: {
              ...constructInputData(values),
            },
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: TAG_LIST,
            variables: {
              first: TABLE_ROWS,
              orderBy: [TagsOrderBy.NAME_ASC],
            },
          },
        ],
      });
      if (!errors) {
        addToast('Tag Updated Successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };

  const headerTitle = isNew ? 'Create Tag' : 'Update Tag';
  return (
    <Stack>
      <Formik<TagEntityValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, dirty, resetForm, errors, isSubmitting }) => {
          return (
            <Form>
              <Modal isOpen={visible} isBlocking={true} onDismiss={onDismiss}>
                <Stack className={styles.container}>
                  <Stack
                    horizontal
                    horizontalAlign={'space-between'}
                    className={styles.dialogStyles}
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <Text variant={'xLarge'}>{headerTitle}</Text>
                      <UnsavedIndicator
                        visible={!isNew && dirty && !isSubmitting}
                      />
                    </Stack>
                    <CloseButton
                      onClick={() => {
                        resetForm();
                        onDismiss();
                      }}
                    />
                  </Stack>
                  <Stack tokens={{ childrenGap: 20, padding: 25 }}>
                    <FormikTextField
                      name="name"
                      label="Name"
                      placeholder="Enter name"
                      required
                      maxLength={15}
                    />

                    <FormikTextField
                      name="badgeName"
                      label="Badge Name"
                      placeholder="Enter badge name"
                      required
                      maxLength={30}
                    />

                    <FormikTextField
                      name="description"
                      label="Description"
                      multiline
                      placeholder="Enter description"
                    />

                    <Stack
                      horizontal
                      className={styles.checkBoxContainer}
                      tokens={{ childrenGap: 30 }}
                    >
                      <FormikCheckBox
                        name="_isActive"
                        label="Active"
                        boxSide="end"
                      />

                      <FormikCheckBox
                        name="isMonitored"
                        label="Monitored"
                        boxSide="end"
                      />

                      <FormikCheckBox
                        name="isPrivate"
                        label="Private"
                        boxSide="end"
                      />
                      <FormikCheckBox
                        name="isAccessTag"
                        label="Data Access"
                        boxSide="end"
                      />
                    </Stack>
                    <Stack horizontal className={styles.colorPickerContainer}>
                      <ColorPickerCallout name="badgeColor" />
                    </Stack>
                  </Stack>

                  {(tagCreationLoading || tagUpdateLoading) && (
                    <ProgressIndicator />
                  )}

                  <Stack
                    horizontalAlign="space-between"
                    className={styles.buttonContainer}
                    horizontal
                    tokens={{ childrenGap: 6, padding: 25 }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <PrimaryButton
                        disabled={!dirty || Object.keys(errors).length > 0}
                        text={headerTitle}
                        onClick={async () => {
                          await submitForm();
                          resetForm();
                          onDismiss();
                        }}
                      />
                      <DefaultButton
                        onClick={() => {
                          resetForm();
                          onDismiss();
                        }}
                        text="Cancel"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
