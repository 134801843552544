import { useMutation, useQuery } from '@apollo/client';
import { DefaultButton, Modal, PrimaryButton, ProgressIndicator, Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import { CompanyCorporatePeriodRangeCreateInput } from 'common/types/globalTypes';
import { dateFormat } from 'common/utils/dateFormats';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { CompanyPeriodFrequencies } from '../__generated__/CompanyPeriodFrequencies';
import { PERIOD_RANGE_VALUES } from './constants';
import { CorporateRangeForm } from './CorporateRangeForm';
import { validationSchema } from './corporateRangeValidation';
import { useStyles } from './index.styles';
import { PeriodRangeValues } from './types';
import { CompanyCorporatePeriodRangeCreate, CompanyCorporatePeriodRangeCreateVariables } from './__generated__/CompanyCorporatePeriodRangeCreate';
const COMPANY_CORPORATE_PERIOD_RANGE_CREATE = loader("./CompanyCorporatePeriodRangeCreate.graphql");
const COMPANY_PERIOD_FREQUENCY = loader("../CompanyPeriodFrequencies.graphql");
const COMPANY_CORPORATE_YEAR = loader("../CompanyCorporateYear.graphql")
interface CorporateRangeViewProps {
  visible: boolean;
  onDismiss: () => void;
  isNew: boolean;
  fiscalYearId: string;
}
export const CorporateRangeView: React.FC<CorporateRangeViewProps> = ({
  visible,
  onDismiss,
  isNew,
  fiscalYearId,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();

  const headerTitle = 'Add Period Range';
  let initialValues: PeriodRangeValues = PERIOD_RANGE_VALUES;

  const [companyCorporatePeriodRangeCreate, { loading: companyCorporatePeriodRangeLoading }] = useMutation<
    CompanyCorporatePeriodRangeCreate,
    CompanyCorporatePeriodRangeCreateVariables
  >(COMPANY_CORPORATE_PERIOD_RANGE_CREATE, { errorPolicy: 'all' });

  const { data: companyPeriodFrequenciesData } = useQuery<CompanyPeriodFrequencies>(COMPANY_PERIOD_FREQUENCY);

  const handleSubmit = async (values: PeriodRangeValues) => {
    if (isNew) {
      const { errors } = await companyCorporatePeriodRangeCreate({
        variables: {
          input: {
            companyCorporateYearId: values.companyCorporateYearId,
            startPeriodNumber: Number(values.startPeriodNumber),
            startDate: values.startDate ? dateFormat(values.startDate) : null,
            frequencyId: values.frequencyId,
            intervals: Number(values.intervals),
            isPeriodOpen: values.isPeriodOpen,
            isEntryAllowed: values.isEntryAllowed,
          } as CompanyCorporatePeriodRangeCreateInput
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: COMPANY_CORPORATE_YEAR,
            variables: {
              id: fiscalYearId!,
            },
          },
        ],
      });
      if (!errors) {
        addToast('Period Range Created Successfully', {
          appearance: 'success',
        });
        onDismiss();
      }
      else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  }

  const loading = companyCorporatePeriodRangeLoading;

  return (
    <>
      <Formik<PeriodRangeValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, resetForm, dirty, errors }) => {
          const onClose = () => {
            resetForm();
            onDismiss();
          };
          const onSubmit = async () => {
            await submitForm();
          };
          return (
            <Form>
              <Modal isOpen={visible} isBlocking={true} onDismiss={onDismiss}>
                <Stack className={styles.container}>
                  <Stack
                    horizontal
                    horizontalAlign={'space-between'}
                    className={styles.dialogStyles}
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <Text
                        variant={'xLarge'}
                        className={commonStyles.colorThemePrimary}
                      >{headerTitle}
                      </Text>
                      <UnsavedIndicator
                        visible={!isNew && dirty && !isSubmitting}
                      />
                    </Stack>
                    <CloseButton onClick={onClose} />
                  </Stack>
                  <CorporateRangeForm
                    fiscalYearId={fiscalYearId}
                    companyPeriodFrequencies={
                      companyPeriodFrequenciesData?.companyPeriodFrequencies?.nodes || []
                    }
                  />
                  {loading && <ProgressIndicator />}
                  <Stack
                    horizontalAlign="space-between"
                    className={styles.buttonContainer}
                    horizontal
                    tokens={{ childrenGap: 6, padding: 25 }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <PrimaryButton
                        disabled={!dirty || Object.keys(errors).length > 0}
                        text={headerTitle}
                        onClick={onSubmit}
                      />
                      <DefaultButton onClick={onClose} text="Cancel" />
                    </Stack>
                  </Stack>
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  )
}
