import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Required').nullable(),
  emailAccount: Yup.string().required('Required').nullable(),
  userOccupationTitleId: Yup.string().required('Required').nullable(),
  departmentId: Yup.string().required('Required').nullable(),
  dataAccessPolicyId: Yup.string().when('departmentOccupationTemplateId', {
    is: (departmentOccupationTemplateId: string) =>
      departmentOccupationTemplateId != null,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Required').nullable(),
  }),
  roleId: Yup.string().when('departmentOccupationTemplateId', {
    is: (departmentOccupationTemplateId: string) =>
      departmentOccupationTemplateId != null,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Required').nullable(),
  }),
});
