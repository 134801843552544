import { FontIcon, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './index.styles';
import { SVGIconOrFontIconName } from 'nav/navGroups';
import { useCommonStyles } from 'common/styles';
import { useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { CommonDataOnAppLoading } from 'common/graphql/__generated__/CommonDataOnAppLoading';

const COMMON_APP_DATA = loader(
  '../../../common/graphql/CommonDataOnAppLoading.graphql'
);
interface NavItemProps {
  text: string;
  Icon: SVGIconOrFontIconName;
  to: string;
  expanded: boolean;
  active?: boolean;
  smallIcon?: boolean;
  compact?: boolean;
  bold?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  id?: string;
  onLinkClick?: () => void;
  className?: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  text,
  to,
  Icon,
  expanded,
  active,
  smallIcon,
  bold,
  onMouseEnter,
  onMouseLeave,
  id,
  compact,
  onLinkClick,
  className,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const client = useApolloClient();
  const commonData: CommonDataOnAppLoading | null = client.readQuery({
    query: COMMON_APP_DATA,
  });
  return (
    <Link
      id={id}
      to={to}
      className={clsx(
        compact ? styles.linkLightHover : styles.linkHover,
        styles.link,
        className
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onLinkClick}
    >
      <Stack
        className={clsx(active && styles.active)}
        horizontal
        verticalAlign="center"
        grow
      >
        <Stack
          className={
            compact ? styles.compactIconContainer : styles.iconContainer
          }
          horizontalAlign="center"
        >
          {typeof Icon === 'string' ? (
            <FontIcon
              className={smallIcon ? styles.smallIcon : styles.largeIcon}
              iconName={Icon}
            />
          ) : text === 'Approvals' && !!commonData?.userPendingApprovalCount ? (
            <Stack horizontal className={styles.root}>
              <Stack className={styles.badge}>
                <Text
                  className={clsx(styles.textStyles, commonStyles.bold)}
                  style={{ padding: 3 }}
                >
                  {commonData?.userPendingApprovalCount}
                </Text>
              </Stack>
            </Stack>
          ) : (
            <Icon className={smallIcon ? styles.smallIcon : styles.largeIcon} />
          )}
        </Stack>
        {expanded && (
          <Text className={clsx(bold && commonStyles.semibold)} nowrap block>
            {text}
          </Text>
        )}
      </Stack>
    </Link>
  );
};
