import { getGlobalDateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { styles } from './index.styles';
import { View, Text } from '@react-pdf/renderer';

export interface StampOptions {
  _isTransactionCancelled: boolean | null | undefined;
  _isAccountingEntryStampedComplete: boolean | null | undefined;
  _accountingStampDate: string | null | undefined;
  _accountingStampUserName: string | null | undefined;
  _accountingStampTransactionReference: string | null | undefined;
}

interface StamperViewProps {
  invoiceDetails: StampOptions;
}

export const StamperView: React.FC<StamperViewProps> = ({ invoiceDetails }) => {
  const isVoided =
    invoiceDetails._isTransactionCancelled &&
    !invoiceDetails._isAccountingEntryStampedComplete;
  const colorClass = isVoided ? styles.grey : styles.red;

  return (
    <View
      style={{ padding: 10, backgroundColor: '#FFFFFF33', borderRadius: 5 }}
    >
      <View style={styles.fixedWidth}>
        <View style={styles.titleContainer}>
          <Text style={colorClass}>{isVoided ? 'VOIDED' : 'ENTERED'}</Text>
        </View>
        <View style={styles.rowContainer}>
          <Text style={styles.red}>TRANSACTION #</Text>
          <Text style={{ fontSize: 8 }}>
            {invoiceDetails._accountingStampTransactionReference}
          </Text>
        </View>
        <View style={styles.rowContainer}>
          <Text style={styles.red}>USER</Text>
          <Text style={{ fontSize: 8 }}>
            {invoiceDetails._accountingStampUserName}
          </Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.red}>DATE</Text>
          <Text style={{ fontSize: 8 }}>
            {invoiceDetails._accountingStampDate &&
              getGlobalDateFormat(invoiceDetails._accountingStampDate)}
          </Text>
        </View>
      </View>
    </View>
  );
};
