import { Stack } from '@fluentui/react';
import React, { useCallback } from 'react';
import { NewMessage } from './NewMessage';
import { useStyles } from './index.styles';
import {
  MessagesListsProps,
  MessagesSections,
  PivotKeys,
} from 'common/components/MessagesSections';
import { loader } from 'graphql.macro';
import { NetworkStatus, useQuery } from '@apollo/client';
import {
  TravelerMessages,
  TravelerMessagesVariables,
} from './__generated__/TravelerMessages';

const TRAVELER_MESSAGES = loader('./TravelerMessages.graphql');
interface TravelerMessageProps
  extends Pick<MessagesListsProps, 'userMessageCounts'> {
  travelerID: string;
  onCreateMessage: () => void;
}

export const TravelerMessage: React.FC<TravelerMessageProps> = ({
  travelerID,
  userMessageCounts,
  onCreateMessage,
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = React.useState<string>(
    PivotKeys.received
  );

  const {
    loading: loadingMessages,
    data: messagesData,
    variables: messageListVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<TravelerMessages, TravelerMessagesVariables>(TRAVELER_MESSAGES, {
    variables: {
      id: travelerID,
      first: 10,
      filter: { _isIncomingMessage: { equalTo: true } },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const onListChange = useCallback(
    async (listName?: string) => {
      if (listName) setSelectedKey(listName);
      const _isOutgoingMessage = { equalTo: listName === PivotKeys.sent };
      await refetch({
        ...messageListVariables,
        filter: {
          _isOutgoingMessage,
        },
      });
    },
    [refetch, messageListVariables]
  );

  const loadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        ...messageListVariables,
        after:
          messagesData?.traveler?.userMessagesByEntityId.pageInfo.endCursor,
      },
    });
  }, [fetchMore, messageListVariables, messagesData]);

  const data =
    networkStatus === NetworkStatus.setVariables
      ? undefined
      : messagesData?.traveler?.userMessagesByEntityId.nodes;

  return (
    <Stack className={styles.container}>
      <MessagesSections
        userMessageCounts={userMessageCounts}
        entityId={travelerID}
        data={data || []}
        loadingMessages={loadingMessages}
        onListChange={onListChange}
        onLoadMore={loadMore}
        hasNextPage={
          messagesData?.traveler?.userMessagesByEntityId?.pageInfo?.hasNextPage
        }
      >
        <NewMessage
          onCreateMessage={onCreateMessage}
          selectedKey={selectedKey}
          travelerID={travelerID}
        />
      </MessagesSections>
    </Stack>
  );
};
