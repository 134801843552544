import { Callout, DirectionalHint, Stack, Text } from '@fluentui/react';
import { IconStatusData } from 'approvals/ApprovalInputGroup';
import { UserApprovalSearch_userApprovalSearch_nodes } from 'approvals/MyApprovals/__generated__/UserApprovalSearch';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import { IconState } from 'common/types/globalTypes';
import React, { useState } from 'react';
import { ReactComponent as ApprovedIcon } from '../../../assets/svgs/ApproveStatusIcons/Approved.svg';
import { ReactComponent as ApprovedPendingIcon } from '../../../assets/svgs/ApproveStatusIcons/ApprovedPending.svg';
import { ReactComponent as CanceledIcon } from '../../../assets/svgs/ApproveStatusIcons/Canceled.svg';
import { ReactComponent as DeclinedIcon } from '../../../assets/svgs/ApproveStatusIcons/Declined.svg';
import { ReactComponent as DeclinedPendingIcon } from '../../../assets/svgs/ApproveStatusIcons/DeclinedPending.svg';
import { ReactComponent as DraftIcon } from '../../../assets/svgs/ApproveStatusIcons/Draft.svg';
import { ReactComponent as PendingIcon } from '../../../assets/svgs/ApproveStatusIcons/Pending.svg';
import { useStyles } from './index.styles';
import { useId } from '@fluentui/react-hooks';
interface approvalDataProps {
  id: string;
  _pendingApprovers?: string | null;
}
interface StatusIconProps {
  iconType: Partial<IconStatusData> | undefined;
  approval: boolean;
  approvalData?:
  | approvalDataProps
  | Partial<UserApprovalSearch_userApprovalSearch_nodes>;
}
export const StatusIcon: React.FC<StatusIconProps> = ({
  iconType,
  approval = false,
  approvalData,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const calloutId = useId(`callOutId_Pending_Approvers${approvalData?.id!}`);

  return (
    <>
      <Stack
        verticalAlign="center"
        id={calloutId}
        onMouseEnter={() => setIsCalloutVisible(true)}
        onMouseLeave={() => setIsCalloutVisible(false)}
      >
        <>
          {IconValue(
            !approval ? iconType?.approvalState! : iconType?.iconState!
          )}
        </>
        {isCalloutVisible && iconType !== null && (
          <Callout
            className={styles.callout}
            gapSpace={0}
            target={`#${calloutId}`}
            directionalHint={DirectionalHint.topCenter}
            onDismiss={() => setIsCalloutVisible(false)}
            setInitialFocus
          >
            <>
              <Stack className={styles.typeContainer}>
                <Text className={commonStyles.semibold} variant="smallPlus">
                  {!approval ? iconType?.statusType! : iconType?.iconType!}
                </Text>
                <Text
                  className={clsx(commonStyles.semibold, styles.categoryType)}
                  variant="smallPlus"
                >
                  {approvalData?._pendingApprovers}
                </Text>
              </Stack>
            </>
          </Callout>
        )}
      </Stack>
    </>
  );
};

const IconValue = (status: string) => {
  switch (status) {
    case IconState.APPROVED:
      return <ApprovedIcon />;
    case IconState.APPROVED_PENDING:
      return <ApprovedPendingIcon />;
    case IconState.REJECTED:
      return <DeclinedIcon />;
    case IconState.REJECTED_PENDING:
      return <DeclinedPendingIcon />;
    case IconState.PENDING:
      return <PendingIcon />;
    case IconState.VOIDED:
    case IconState.AMENDED:
    case IconState.CANCELED:
      return <CanceledIcon />;
    default:
      return <DraftIcon />;
  }
};
