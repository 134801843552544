import { useMutation } from '@apollo/client';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import {
  EntityDocumentDelete,
  EntityDocumentDeleteVariables,
} from 'common/graphql/__generated__/EntityDocumentDelete';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { DocumentListItem } from '../interface';
const DOCUMENT_DELETE = loader(
  '../../../../common/graphql/EntityDocumentDelete.graphql'
);

interface DeleteModalProps {
  onDeletion: () => void;
  selectedDocuments: DocumentListItem[];
}
export const DeleteModal: React.FC<DeleteModalProps> = ({
  onDeletion,
  selectedDocuments,
}) => {
  const { addToast } = useToasts();
  const [deleteDocuments] = useMutation<
    EntityDocumentDelete,
    EntityDocumentDeleteVariables
  >(DOCUMENT_DELETE, { errorPolicy: 'all' });

  const _onConfirm = async () => {
    const entities = selectedDocuments.filter((i) => i._isDeletable);

    if (entities.length) {
      const entityDelete = entities.map(
        (i) =>
          ({ id: i.id, rowTimestamp: i._rowTimestamp } as EntityDeleteInput)
      );
      const { errors } = await deleteDocuments({
        variables: {
          input: {
            entityDelete,
          },
        },
        update(cache, { data: response }) {
          response?.entityDocumentDelete?.deletedEntities?.forEach((item) => {
            const identity = `EntityDocument:${item?.id}`;
            cache.evict({ id: identity });
            cache.gc();
          });
        },
      });

      if (errors?.length) {
        addToast(errors[0].message, { appearance: 'error' });
      } else {
        addToast('Documents deleted successfully', { appearance: 'success' });
      }
    }
  };

  return (
    <>
      {selectedDocuments.length > 0 && (
        <ActionMessageModal
          entityType={EntityType.Document}
          disabled={
            !selectedDocuments.some((selected) => selected._isDeletable)
          }
          visible={selectedDocuments.length > 0}
          multiple={{
            validCount: selectedDocuments.filter(
              (selected) => selected._isDeletable
            ).length,
            invalidNames: selectedDocuments
              .filter((selected) => !selected._isDeletable)
              .map((cannotDelete) => cannotDelete.fileReference),
          }}
          onConfirm={_onConfirm}
        />
      )}
    </>
  );
};
