import { NetworkStatus, useQuery } from '@apollo/client';
import { Stack, makeStyles } from '@fluentui/react';
import { MessagesListsProps, MessagesSections, PivotKeys } from 'common/components/MessagesSections';
import { loader } from 'graphql.macro';
import React, { useCallback } from 'react';
import { TravelAuthorizationMessage, TravelAuthorizationMessageVariables } from './__generated__/TravelAuthorizationMessage';
import { NewMessage } from './NewMessage';
const TRAVEL_AUTHORIZATION_MESSAGE = loader("./TravelAuthorizationMessage.graphql");
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 10,
    marginTop: 20,
  },
}));

interface TravelAuthorizationMessageProps
  extends Pick<MessagesListsProps, 'userMessageCounts'> {
  travelAuthorizationId: string;
  onCreateMessage: () => void;
  dirty?: boolean;
}

export const Messages: React.FC<TravelAuthorizationMessageProps> = ({
  travelAuthorizationId,
  userMessageCounts,
  onCreateMessage,
  dirty,
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = React.useState<string>(PivotKeys.received);

  const {
    loading: loadingMessages,
    data: messagesData,
    variables: messageListVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<TravelAuthorizationMessage, TravelAuthorizationMessageVariables>(
    TRAVEL_AUTHORIZATION_MESSAGE,
    {
      variables: {
        id: travelAuthorizationId,
        first: 10,
        filter: { _isIncomingMessage: { equalTo: true } },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const { userMessagesByEntityId } = { ...messagesData?.travelAuthorization }

  const onListChange = useCallback(
    async (listName?: string) => {
      if (listName) setSelectedKey(listName);
      const _isOutgoingMessage = { equalTo: listName === PivotKeys.sent };
      await refetch({
        ...messageListVariables,
        filter: {
          _isOutgoingMessage,
        },
      });
    },
    [
      refetch,
      messageListVariables
    ]
  );

  const loadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        ...messageListVariables,
        after: userMessagesByEntityId?.pageInfo?.endCursor,
      },
    });
  }, [
    fetchMore,
    messageListVariables,
    userMessagesByEntityId
  ]);

  const data =
    networkStatus === NetworkStatus.setVariables
      ? undefined
      : userMessagesByEntityId?.nodes;



  return (
    <Stack className={styles.container}>
      <MessagesSections
        userMessageCounts={userMessageCounts}
        entityId={travelAuthorizationId}
        data={data || []}
        loadingMessages={loadingMessages}
        onListChange={onListChange}
        onLoadMore={loadMore}
        hasNextPage={
          userMessagesByEntityId?.pageInfo?.hasNextPage
        }
      >
        <NewMessage
          onCreateMessage={onCreateMessage}
          selectedKey={selectedKey}
          travelAuthorizationId={travelAuthorizationId}
          dirty={dirty}
        />
      </MessagesSections>
    </Stack>
  )
}
