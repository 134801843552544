import { useLazyQuery } from '@apollo/client';
import {
  ITextFieldProps,
  ITextFieldStyles,
  IconButton,
  Spinner,
  Stack,
  TextField,
  makeStyles,
  useTheme,
} from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import { PurchaseOrder_purchaseOrder_supplier } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { SupplierCreate_supplierCreate_supplier } from 'settings/account/supplier/view/__generated__/SupplierCreate';

import { TextFieldLabel } from './TextFieldLabel';

import { SupplierLookUpCallout } from 'common/components/SupplierLookUpCallout';
import {
  LookupTripSectionSupplierSearch,
  LookupTripSectionSupplierSearchVariables,
  LookupTripSectionSupplierSearch_lookupTripSectionSupplierSearch_nodes,
} from './__generated__/LookupTripSectionSupplierSearch';

const LOOKUP_TRIP_SECTION_SUPPLIER_SEARCH = loader(
  './LookupTripSectionSupplierSearch.graphql'
);

type SupplierTripSectionAutoCompleteProps =
  RequiredNameProps<ITextFieldProps> & {
    lookUpCalloutWidth?: number;
    lookupData?: LookupTripSectionSupplierSearch_lookupTripSectionSupplierSearch_nodes | null;
    clearTextField?: boolean | null;
    showAddIcon?: boolean;
    supplier: PurchaseOrder_purchaseOrder_supplier | null;
    purchaseOrderId?: string | undefined;
    travelerId: string;
    tripSectionId: string;
  };

const useStyles = makeStyles((theme) => ({
  calloutContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  icon: {
    color: theme.palette.black,
  },
}));

export const SupplierTripSectionAutoComplete: FC<
  SupplierTripSectionAutoCompleteProps
> = ({
  lookUpCalloutWidth,
  lookupData,
  clearTextField,
  showAddIcon,
  supplier,
  purchaseOrderId,
  travelerId,
  tripSectionId,
  ...props
}) => {
    const styles = useStyles();
    const theme = useTheme();
    const textfiledStyles: Partial<ITextFieldStyles> = {
      suffix: {
        backgroundColor: props.disabled
          ? theme.palette.neutralLighter
          : theme.palette.white,
      },
    };
    const {
      field: { onChange },
      fieldState: { error },
    } = useController({
      name: props.name,
    });

    const renderRef = useRef(false);
    const [calloutVisible, setCalloutVisible] = useState(true);
    const [fullName, setFullName] = useState<string | null | undefined>(null);
    const [selectedRecord, setSelectedRecord] = useState<
      | LookupTripSectionSupplierSearch_lookupTripSectionSupplierSearch_nodes
      | undefined
      | null
    >(lookupData);
    const [selectedSupplier, setSelectedSupplier] =
      useState<PurchaseOrder_purchaseOrder_supplier | null>(null);
    const [disableTextfield, setDisableTextfield] = useState<
      boolean | null | undefined
    >(false);

    const [fetchLookUp, { data, loading }] = useLazyQuery<
      LookupTripSectionSupplierSearch,
      LookupTripSectionSupplierSearchVariables
    >(LOOKUP_TRIP_SECTION_SUPPLIER_SEARCH, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    });

    const { id, label, disabled, required, ...textfieldProps } = props;
    const { _fullName } = { ...supplier };

    const lookupCallBack = useCallback(
      (value) => {
        fetchLookUp({
          variables: {
            lookupName: value,
            tripSectionId,
            travelerId,
          },
        });
      },
      [fetchLookUp]
    );

    // useEffect(() => {
    //   if (value && value?.length! > 0) {
    //     if (renderRef.current) {
    //       setCalloutVisible(true);
    //       lookupCallBack(undefined);
    //     } else {
    //       renderRef.current = true;
    //     }
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [lookupCallBack]);

    useEffect(() => {
      if (!!fullName) {
        if (renderRef.current) {
          setCalloutVisible(true);
          lookupCallBack(fullName);
        } else {
          renderRef.current = true;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookupCallBack]);

    const callbackLookUp = useCallback(
      _.debounce((value, queryCalled) => {
        fetchLookUp({
          variables: {
            lookupName: value,
            tripSectionId,
            travelerId,
          },
        });
        queryCalled();
      }, 350),
      []
    );

    const renderSpinner = () => {
      return (
        <>
          {loading ? (
            <Spinner />
          ) : disabled ? null : disableTextfield ? (
            <IconButton
              type="button"
              style={{
                backgroundColor: '#e9e9e900', // Background color was not setting by the class.
              }}
              onClick={() => {
                onChange(null);
                setFullName(null);
                setSelectedRecord(null);
                setSelectedSupplier(null);
                setDisableTextfield(false);
              }}
              iconProps={{ iconName: 'cancel' }}
              className={styles.icon}
              ariaLabel="Close"
            />
          ) : null}
        </>
      );
    };

    useEffect(() => {
      setFullName(_fullName);
    }, [_fullName]);

    useEffect(() => {
      setSelectedSupplier(supplier);
      setDisableTextfield(!!supplier);
    }, [supplier]);

    const onSupplierCreate = (
      supplier: SupplierCreate_supplierCreate_supplier
    ) => {
      if (supplier) {
        onChange(supplier.id);
        setFullName(supplier._fullName);
        setSelectedSupplier(supplier);
        setDisableTextfield(true);
      }
    };
    const onSupplierDelete = () => {
      onChange(null);
      setFullName(null);
      setSelectedSupplier(null);
      setDisableTextfield(false);
    };

    return (
      <Stack>
        <TextFieldLabel
          disabled={disabled}
          label={label}
          data={selectedRecord}
          required={required}
          showAddIcon={showAddIcon}
          supplier={selectedSupplier}
          purchaseOrderId={purchaseOrderId}
          onSupplierCreate={onSupplierCreate}
          onSupplierDelete={onSupplierDelete}
        />
        <TextField
          {...textfieldProps}
          id={id}
          autoComplete={'off'}
          errorMessage={error?.message}
          value={fullName || ''}
          onChange={(_event, value) => {
            if (value) {
              setFullName(value);
              callbackLookUp(value, () => {
                if (calloutVisible === false) setCalloutVisible(true);
              });
            } else {
              onChange(null);
              setFullName(null);
              setSelectedSupplier(null);
              setCalloutVisible(false);
            }
          }}
          onRenderSuffix={renderSpinner}
          styles={textfiledStyles}
          disabled={disabled ? true : disableTextfield ? true : false}
        />
        <Stack className={styles.calloutContainer}>
          {calloutVisible && !loading && (
            <SupplierLookUpCallout
              lookUpCalloutWidth={lookUpCalloutWidth}
              onDismiss={() => {
                setCalloutVisible(false);
              }}
              onSelect={(value) => {
                if (value) {
                  onChange(value.id);
                  setFullName(value._fullName);
                  setSelectedRecord(value);
                  setSelectedSupplier(value);
                  setDisableTextfield(true);
                }
              }}
              targetID={`#${id!}`}
              data={data?.lookupTripSectionSupplierSearch?.nodes!}
            />
          )}
        </Stack>
      </Stack>
    );
  };
