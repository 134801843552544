import React from 'react';

import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import {
  CreateInvoiceNote,
  CreateInvoiceNoteVariables,
} from 'ap/paymentCycle/view/TransactionModal/TransactionDetail/ActionMenu/TransactionDetailNotes/__generated__/CreateInvoiceNote';
import {
  NotesSectionProps,
  NotesSection,
} from 'common/components/NotesSection';

const ADD_NEW_NOTE = loader('./NoteSupplierCreate.graphql');

type SupplierNotesProps = Pick<
  NotesSectionProps,
  'listRefreshRequest'
> & {
  supplierId: string;
  data: NotesEntityFields[];
  loading?: boolean;
};

export const SupplierNotesSection: React.FC<SupplierNotesProps> =
  ({ supplierId, data, listRefreshRequest, loading }) => {
    const [createNote, { loading: createNoteInProgress, error }] = useMutation<
      CreateInvoiceNote,
      CreateInvoiceNoteVariables
    >(ADD_NEW_NOTE);

    const noteAddMutation: NoteAddMutationProps = {
      loading: createNoteInProgress,
      createNote: async (formData) => {
        const { noteComment, isShared } = { ...formData };
        await createNote({
          variables: {
            input: {
              note: {
                entityId: supplierId,
                noteComment: noteComment!,
                isShared: isShared!,
              },
            },
          },
        });
        if (!error) listRefreshRequest?.();
        return error;
      },
    };

    return (
      <NotesSection
        dataLoading={loading!}
        noteAddMutation={noteAddMutation}
        addingNoteInProgress={createNoteInProgress}
        listRefreshRequest={listRefreshRequest}
        data={data}
      />
    );
  };
