import {
  IDropdownOption,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { CustomDropdown } from 'common/components/CustomDropdown';
import React, { useState } from 'react';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { TravelerAuthorizationCommonData_companyCorporatePeriods } from '../../__generated__/TravelerAuthorizationCommonData';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { useApolloClient, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  TravelAuthorizationAccountingStamper,
  TravelAuthorizationAccountingStamperVariables,
} from './__generated__/TravelAuthorizationAccountingStamper';
import { useToasts } from 'react-toast-notifications';
import { DocumentPackageStatusType } from 'common/types/globalTypes';
import {
  TravelAuthorizationDocumentPackageStatus,
  TravelAuthorizationDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/TravelAuthorizationDocumentPackageStatus';
const TRAVELER_AUTHORIZATION_ACCOUNTING_STAMPER = loader(
  './TravelAuthorizationAccountingStamper.graphql'
);
const TA_DOCUMENT_PACKAGE_STATUS = loader(
  '../../../../../../common/graphql/DocumentPackageSubscription/TravelAuthorizationDocumentPackageStatus.graphql'
);

interface StampEntryProps {
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  companyCorporatePeriods:
    | TravelerAuthorizationCommonData_companyCorporatePeriods
    | null
    | undefined;
  onRefetch: () => void;
}
export const StampEntry: React.FC<StampEntryProps> = ({
  travelAuthorizationData,
  companyCorporatePeriods,
  onRefetch,
}) => {
  const { addToast } = useToasts();
  const client = useApolloClient();
  const [hideConfirmStampDialog, { toggle: toggleConfirmStampDialog }] =
    useBoolean(true);
  const [successBtnDisable, setSuccessBtnDisable] = useState<boolean>(true);
  const [requestAccountingTransaction, setRequestAccountingTransaction] =
    useState<string>();
  const [corporatePeriod, setCorporatePeriod] = useState<IDropdownOption>();

  const [updateStamper, { loading: accountingStamperLoading }] = useMutation<
    TravelAuthorizationAccountingStamper,
    TravelAuthorizationAccountingStamperVariables
  >(TRAVELER_AUTHORIZATION_ACCOUNTING_STAMPER, { errorPolicy: 'all' });

  const { id, _rowTimestamp, _isAccountingEntry, _isHistory, _isStampAllowed } =
    { ...travelAuthorizationData };

  const showStampEntry = _isAccountingEntry && !_isHistory && _isStampAllowed;
  if (!showStampEntry) return null;

  const companyCorporatePeriodsOptions: IDropdownOption[] =
    companyCorporatePeriods?.nodes.map((item) => ({
      disabled: item.isEntryAllowed ? false : true,
      key: item.id,
      text:
        item._periodYear +
        `(${
          item.startDate! ? dateFormat(dateConvertions(item.startDate!)) : ''
        } - ${
          item.endDate! ? dateFormat(dateConvertions(item.endDate!)) : ''
        })`,
    })) || [];

  return (
    <Stack>
      <PrimaryButton
        iconProps={{
          iconName: 'StampSmall',
          styles: {
            root: {
              fill: 'white',
            },
          },
        }}
        onClick={() => toggleConfirmStampDialog()}
        text="Stamp Entry"
      />
      <ConfirmDialog
        isConfirmPrimaryButton
        successBtnDisable={successBtnDisable || accountingStamperLoading}
        hidden={hideConfirmStampDialog}
        title={'Are you sure you want to stamp this travel plan as entered?'}
        onDismiss={() => {
          toggleConfirmStampDialog();
          setCorporatePeriod(undefined);
        }}
        minWidth={500}
        onConfirm={async () => {
          if (requestAccountingTransaction) {
            let dataVariables: TravelAuthorizationAccountingStamperVariables = {
              input: {
                id: id!,
                rowTimestamp: _rowTimestamp!,
                transactionReference: requestAccountingTransaction,
                corporatePeriodId: corporatePeriod?.key?.toString(),
              },
            };
            const { errors } = await updateStamper({
              variables: dataVariables,
            });
            if (errors?.length) {
              toggleConfirmStampDialog();
              addToast(errors[0].message, {
                appearance: 'error',
              });
            } else {
              addToast('Travel plan stamped successfully.', {
                appearance: 'success',
              });
              onRefetch();
              setRequestAccountingTransaction(undefined);
              setSuccessBtnDisable(true);
              toggleConfirmStampDialog();
              const observer = client.subscribe<
                TravelAuthorizationDocumentPackageStatus,
                TravelAuthorizationDocumentPackageStatusVariables
              >({
                query: TA_DOCUMENT_PACKAGE_STATUS,
                variables: {
                  id: id!,
                },
              });
              const subscription = observer.subscribe(({ data, errors }) => {
                if (errors)
                  addToast(
                    'Errors received while subscribing to document package',
                    { appearance: 'error' }
                  );
                else {
                  const { document, status } = {
                    ...data?.travelAuthorizationDocumentPackageStatus,
                  };
                  if (
                    status === DocumentPackageStatusType.REGENERATION_FAILURE
                  ) {
                    addToast(
                      'Report generation failed. Document package was not created',
                      { appearance: 'error' }
                    );
                  }
                  if (status === DocumentPackageStatusType.FAILURE) {
                    addToast('Error while creating Document package ', {
                      appearance: 'error',
                    });
                  }
                  if (document) {
                    addToast('Document package created', {
                      appearance: 'success',
                    });
                    client.cache.modify({
                      id: client.cache.identify({
                        ...travelAuthorizationData,
                      }),
                      fields: {
                        stampedEntityDocumentId: () => {
                          return document.id;
                        },
                      },
                    });
                  }
                }
                subscription.unsubscribe();
              });
            }
          }
        }}
      >
        <Stack tokens={{ childrenGap: 10, padding: '0px 0px 20px 0px' }}>
          <TextField
            label="Transaction #"
            value={requestAccountingTransaction}
            placeholder="Enter the Accounting System Transaction #"
            resizable={false}
            onChange={(_event, value) => {
              setRequestAccountingTransaction(value || '');
              if (value) {
                setSuccessBtnDisable(false);
              } else {
                setSuccessBtnDisable(true);
              }
            }}
          />
          <CustomDropdown
            label="Accounting Period"
            placeholder="Select"
            selectedKey={corporatePeriod ? corporatePeriod.key : null}
            options={companyCorporatePeriodsOptions}
            onChange={(_, option) => {
              setCorporatePeriod(option);
            }}
            onClear={() => setCorporatePeriod(undefined)}
          />
        </Stack>
      </ConfirmDialog>
    </Stack>
  );
};
