import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ActionButton,
  CommandBar,
  FontIcon,
  ICommandBarItemProps,
  Persona,
  PersonaSize,
  Separator,
  Stack,
  Text,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { Preferences } from 'Preferences';
import clsx from 'clsx';
import { Badge } from 'common/components/Badge';
import { setPanelWidth } from 'common/components/DraggablePanel';
import { NOTIFICATION_POLL_INTERVAL } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { getHoverColor } from 'common/utils/color';
import { config } from 'config';
import { loader } from 'graphql.macro';
import { MessagingView } from 'messaging/view';
import { NotificationView } from 'notification/view';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { expandedNav, highlightTextMode } from 'utility/cache/ui';
import { ReactComponent as Logo } from '../assets/svgs/logo.svg';
import { ReactComponent as CollapsedLogo } from '../assets/svgs/logo_collapsed.svg';
import { GlobalSearch } from './GlobalSearch';
import { GetNotificationMessageCount } from './__generated__/GetNotificationMessageCount';
import {
  UserMessageUnreadCountReminderUpdate,
  UserMessageUnreadCountReminderUpdateVariables,
} from './__generated__/UserMessageUnreadCountReminderUpdate';
import { useStyles } from './index.styles';
import { isDashBoardScreen } from 'utility/cache/ui';
import { NewMessageAlert } from './newMessageAlert';
const COUNTS_QUERY = loader('./GetNotificationMessageCount.graphql');
const UNREAD_QUERY = loader('./UserMessageUnreadCountReminderUpdate.graphql');

export const TopBar: React.FC = () => {
  const dashBoardScreen = useReactiveVar(isDashBoardScreen);
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const highlightTextState = useReactiveVar(highlightTextMode);
  const [isMessagesOpen, setMessagesOpen] = React.useState(false);
  const [isNotificationsOpen, setNotificationsOpen] = React.useState(false);
  const renderRef = useRef<boolean>(true);
  const expanded = useReactiveVar(expandedNav);
  const [preferenceVisible, setPreferenceVisible] = useState<boolean>(false);
  const { user, logout } = useAuth0();
  const preferenceCalloutId = useId('callout-preferenceCalloutId');
  const { data } = useQuery<GetNotificationMessageCount>(COUNTS_QUERY, {
    pollInterval: NOTIFICATION_POLL_INTERVAL,
  });

  const [fetchData, { data: unreadCountData }] = useMutation<
    UserMessageUnreadCountReminderUpdate,
    UserMessageUnreadCountReminderUpdateVariables
  >(UNREAD_QUERY);
  const newMessageBadgeVisible =
    renderRef.current === true
      ? !!unreadCountData?.userMessageUnreadCountReminderUpdate
          ?._NewUserMessageCounts
      : !!data?.userMessageUnreadCounts;
  const items: ICommandBarItemProps[] = [
    {
      key: 'notifications',
      className: styles.iconButton,
      onRenderIcon: () => (
        <Badge visible={!!data?.userNotificationUnreadCounts}>
          <FontIcon iconName="Ringer" className={styles.icon} />
        </Badge>
      ),
      onClick: () => {
        setPanelWidth(undefined);
        setNotificationsOpen(true);
      },
    },
    {
      key: 'messages',
      className: styles.iconButton,
      onRenderIcon: () => (
        <Badge visible={newMessageBadgeVisible}>
          <FontIcon iconName="Message" className={styles.icon} />
        </Badge>
      ),
      onClick: () => {
        setPanelWidth(undefined);
        setMessagesOpen(true);
      },
    },
    // {
    //   key: 'settings',
    //   className: styles.iconButton,
    //   onRenderIcon: () => (
    //     <FontIcon iconName="PlayerSettings" className={styles.icon} />
    //   ),
    // },
    {
      key: 'user',
      className: styles.iconButton,
      // menuIconProps isn't defined by the fluent-ui type definition, it's a bug in the library
      // https://github.com/microsoft/fluentui/issues/10569
      menuIconProps: { style: { display: 'none' } },
      onRenderIcon: () => (
        <Persona
          hidePersonaDetails
          text={user?.name}
          size={PersonaSize.size32}
        />
      ),
      subMenuProps: {
        items: [
          {
            key: 'Preferences',
            text: 'Preferences',
            iconProps: { iconName: 'Settings' },
            onClick: () => setPreferenceVisible(true),
          },
          {
            key: 'logout',
            text: 'Sign out',
            iconProps: { iconName: 'leave' },
            onClick: () =>
              logout({
                client_id: config!.auth0.clientId,
                returnTo: window.location.origin,
              }),
          },
        ],
      },
    },
  ];

  const fetchCountDataCallback = useCallback(() => {
    fetchData({
      variables: {
        input: { updateAllReminders: true },
      },
    });
  }, [fetchData]);

  useEffect(() => {
    fetchCountDataCallback();
    renderRef.current = false;
  }, [fetchCountDataCallback]);

  return (
    <>
      {isNotificationsOpen && (
        <NotificationView
          isOpened={isNotificationsOpen}
          closePanel={() => {
            setPanelWidth(undefined);
            setNotificationsOpen(false);
          }}
        />
      )}
      {dashBoardScreen &&
        !isMessagesOpen &&
        !!data?.userNotificationUnreadCounts && (
          <NewMessageAlert
            closeAlert={() => {
              setNotificationsOpen(true);
            }}
          />
        )}
      {dashBoardScreen && !isNotificationsOpen && newMessageBadgeVisible && (
        <NewMessageAlert
          isNewMessage={true}
          closeAlert={() => {
            setMessagesOpen(true);
          }}
        />
      )}
      {isMessagesOpen && (
        <MessagingView
          isOpenPanel={isMessagesOpen}
          onPanelDismiss={() => {
            setPanelWidth(undefined);
            setMessagesOpen(false);
          }}
        />
      )}
      {preferenceVisible && (
        <Preferences
          isOpen={preferenceVisible}
          calloutId={preferenceCalloutId}
          onDismiss={() => setPreferenceVisible(false)}
        />
      )}
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        className={styles.root}
      >
        <Stack horizontal className={styles.logoContainer}>
          <Stack
            horizontal
            verticalAlign="end"
            className={
              expanded
                ? styles.logoContainerExpanded
                : styles.logoContainerCollapsed
            }
          >
            <Link className={styles.logo} to="/">
              {expanded ? (
                <>
                  <Logo />
                  <Text
                    variant="mediumPlus"
                    className={clsx(styles.logoText, commonStyles.semibold)}
                  >
                    Expenses
                  </Text>
                </>
              ) : (
                <CollapsedLogo />
              )}
            </Link>
          </Stack>
        </Stack>
        <Stack horizontal verticalAlign="center">
          <GlobalSearch />

          <TooltipHost content="Highlight search terms">
            <ActionButton
              iconProps={{
                iconName: highlightTextState
                  ? 'FabricTextHighlightComposite'
                  : 'FabricTextHighlight',
              }}
              styles={{
                iconPressed: {
                  color: highlightTextState
                    ? getHoverColor(theme.palette.orangeLighter, theme)
                    : getHoverColor(theme.palette.neutralTertiary, theme),
                },
                icon: {
                  fontSize: 22,
                  color: highlightTextState
                    ? theme.palette.orangeLighter
                    : theme.palette.neutralTertiary,
                },
                iconHovered: {
                  color: highlightTextState
                    ? getHoverColor(theme.palette.orangeLighter, theme)
                    : getHoverColor(theme.palette.neutralTertiary, theme),
                },
              }}
              onClick={() => highlightTextMode(!highlightTextState)}
            />
          </TooltipHost>
          <Separator vertical />
          <CommandBar
            id={preferenceCalloutId}
            items={items}
            onReduceData={() => undefined}
            styles={{ root: { padding: 0, height: 48 } }}
          />
        </Stack>
      </Stack>
    </>
  );
};
