import { useMutation } from '@apollo/client';
import {
  NotesSection,
  NotesSectionProps,
} from 'common/components/NotesSection';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import { loader } from 'graphql.macro';
import React from 'react';

import {
  NoteDocumentPoolCreate,
  NoteDocumentPoolCreateVariables,
} from './__generated__/NoteDocumentPoolCreate';

const NOTE_DOCUMENT_POOL_CREATE = loader('./NoteDocumentPoolCreate.graphql');
type NotesDocumentPoolProps = Pick<NotesSectionProps, 'listRefreshRequest'> & {
  documentPoolId: string;
  data: NotesEntityFields[];
  loading?: boolean;
};
export const NotesDocumentPool: React.FC<NotesDocumentPoolProps> = ({
  documentPoolId,
  listRefreshRequest,
  data,
  loading,
}) => {
  const [createNote, { loading: createNoteInProgress, error }] = useMutation<
    NoteDocumentPoolCreate,
    NoteDocumentPoolCreateVariables
  >(NOTE_DOCUMENT_POOL_CREATE);

  const noteAddMutation: NoteAddMutationProps = {
    loading: createNoteInProgress,
    createNote: async (formData) => {
      const { noteComment, isShared } = { ...formData };
      await createNote({
        variables: {
          input: {
            note: {
              entityId: documentPoolId,
              noteComment: noteComment!,
              isShared: isShared!,
            },
          },
        },
      });
      if (!error) listRefreshRequest?.();
      return error;
    },
  };
  return (
    <NotesSection
      dataLoading={loading!}
      noteAddMutation={noteAddMutation}
      addingNoteInProgress={createNoteInProgress}
      listRefreshRequest={listRefreshRequest}
      data={data}
    />
  );
};
