import { IColumn, Stack, Text, TooltipHost } from '@fluentui/react';
import React from 'react';
import { LookupAccountSearchFields } from '.';
import { AccountsEntryView } from '../view';
import clsx from 'clsx';
import { ChartOfAccounts_chartOfAccounts_nodes } from './chartAccount/__generated__/ChartOfAccounts';

export type ColumnStylesProps = {
  onRenderColumnStack: string;
};

export const onRenderItems = (
  styles: ColumnStylesProps,
  chartOfAccount: ChartOfAccounts_chartOfAccounts_nodes | null
) => {
  const renderItemColumn = (
    item: LookupAccountSearchFields | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof LookupAccountSearchFields
      ] as string;
      const { corporateAccountName, ...itemFields } = { ...item };
      switch (column?.key) {
        case 'department':
          const allDepartment =
            item[
              'isTravelAccountForAllDepartments' as keyof LookupAccountSearchFields
            ];
          const departmentContent = fieldContent
            ? fieldContent
            : allDepartment
            ? 'All'
            : null;
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.onRenderColumnStack)}
            >
              <Text>{departmentContent}</Text>
            </Stack>
          );

        case 'corporateAccountName':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              <TooltipHost content={item?.corporateAccount?.lookupName}>
                <Text>{fieldContent}</Text>
              </TooltipHost>
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              {!!chartOfAccount && (
                <AccountsEntryView
                  account={itemFields}
                  chartOfAccount={chartOfAccount}
                />
              )}
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onRenderColumnStack}
            >
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  };
  return renderItemColumn;
};
