import { Stack, Text, TooltipHost } from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import React from 'react';
import { GridColumnProps } from '../columns.data';
import { useStyles } from './index.styles';
import { TripItemProps } from '..';
import clsx from 'clsx';
import { StyledLink } from 'common/components/StyledLink';

interface CellRenderProps {
  column: GridColumnProps;
  fieldContent: any;
  index: number;
  onItemClick?: () => void;
  item: TripItemProps;
  toolTipContent: string;
  isDirty: boolean;
}

export const CellRender: React.FC<CellRenderProps> = ({
  column,
  fieldContent,
  onItemClick,
  index,
  toolTipContent,
  item,
  isDirty,
}) => {
  const styles = useStyles();

  const { _isOverBudget } = { ...item };

  switch (column.key) {
    case 'tripLocator':
      return (
        <Stack key={index}>
          {isDirty ? (
            <Stack className={styles.inactiveLink}>{fieldContent}</Stack>
          ) : (
            <StyledLink onLinkClick={onItemClick} linkText={fieldContent} />
          )}
        </Stack>
      );
    case 'airfareAmount':
    case 'accommodationAmount':
    case 'vehicleRentalAmount':
    case 'transportationAmount':
    case 'trainAmount':
    case 'incidentalAmount':
      return (
        <Stack key={index} className={styles.alignRight}>
          {fieldContent > 0 && <AmountTextView value={fieldContent} />}
        </Stack>
      );
    case 'controlTotalAmount':
      return (
        <Stack key={index} className={styles.alignRight}>
          {fieldContent > 0 && (
            <TooltipHost content={toolTipContent || ''}>
              <AmountTextView
                value={fieldContent}
                className={clsx({
                  [styles.overageAmount]: _isOverBudget,
                })}
              />
            </TooltipHost>
          )}
        </Stack>
      );
    case 'accountReference':
      return (
        <Stack
          key={index}
          horizontalAlign="center"
          className={styles.accountReference}
        >
          <Text>{fieldContent}</Text>
        </Stack>
      );
    default:
      return (
        <Stack key={index}>
          <Text>{fieldContent}</Text>
        </Stack>
      );
  }
};
