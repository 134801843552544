import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { closeOpenedDocument, setActiveOrderIndex } from 'utility/cache/ui';
import {
  DocumentViewModal,
  DocumentViewModalProps,
} from '../DocumentList/DocumentViewModal';

type DocumentContainerProps = Omit<
  DocumentViewModalProps,
  'orderIndex' | 'layerIndex'
>;

const PADDING_TOP_OFFSET = 20;

export const DocumentContainer: React.FC<DocumentContainerProps> = (props) => {
  const [value, setValue] = useState<{
    orderNumber: number;
    layerNumber: number;
  }>({ orderNumber: -1, layerNumber: -1 });

  useEffect(() => {
    const currentMap = setActiveOrderIndex();
    if (props.isOpen) {
      const maxKey = Array.from(currentMap.keys()).reduce(
        (max, key) => Math.max(max, key),
        0
      );
      const newKey = maxKey + 1;
      const newEntry = { orderNumber: newKey, layerNumber: newKey };
      if (!currentMap.values.length) closeOpenedDocument(false);
      setValue({ orderNumber: newKey, layerNumber: newKey });
      currentMap.set(newKey, newEntry);
      setActiveOrderIndex(currentMap);
    }
  }, [props.isOpen]);

  const onFileClose = () => {
    const currentMap = setActiveOrderIndex();
    currentMap.delete(value.orderNumber);
    setActiveOrderIndex(currentMap);
    props.onDismiss();
  };

  return (
    <Stack>
      <DocumentViewModal
        {...props}
        onDismiss={onFileClose}
        layerIndex={value.layerNumber}
        orderIndex={value.orderNumber}
        paddingTop={value.orderNumber * PADDING_TOP_OFFSET}
      />
    </Stack>
  );
};
