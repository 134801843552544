import { useLazyQuery, useMutation } from '@apollo/client';
import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import {
  AttachDocumentPoolInput,
  FileCabinetPoolRecipientsOrderBy,
} from 'common/types/globalTypes';
import {
  AttachDocumentPoolDocuments,
  AttachDocumentPoolDocumentsVariables,
} from 'documents/MoveToFolder/__generated__/AttachDocumentPoolDocuments';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { DocumentEntity } from '../list';
import { FileCabinetPools_fileCabinetPools_nodes } from '../list/__generated__/FileCabinetPools';
import { MoveToFolderModal } from './MoveToFolderModal';
import {
  FileCabinetPoolRecipients,
  FileCabinetPoolRecipientsVariables,
  FileCabinetPoolRecipients_fileCabinetPoolRecipients_nodes,
} from './__generated__/FileCabinetPoolRecipients';
const FILE_CABINET_POOL_RECIPIENTS = loader(
  './FileCabinetPoolRecipients.graphql'
);
const ATTACH_DOCUMENTS_TO_FOLDER = loader(
  '../../../MoveToFolder/AttachDocumentPool.graphql'
);
interface MoveToFolderProps {
  poolSelected?: FileCabinetPools_fileCabinetPools_nodes | undefined;
  documentsSelected: DocumentEntity[];
  onSuccess: (receivingFolderId: string, movedDocumentsCount: number) => void;
}
export const MoveToFolder: React.FC<MoveToFolderProps> = ({
  poolSelected,
  documentsSelected,
  onSuccess,
}) => {
  const { addToast } = useToasts();
  const [visibility, setVisibility] = useState(false);
  const [
    getFileCabinetPoolRecipients,
    {
      data: fileCabinetPoolRecipientsData,
      loading: fileCabinetPoolRecipientsLoading,
    },
  ] = useLazyQuery<
    FileCabinetPoolRecipients,
    FileCabinetPoolRecipientsVariables
  >(FILE_CABINET_POOL_RECIPIENTS, {
    variables: {
      orderBy: [FileCabinetPoolRecipientsOrderBy.NAME_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const openModal = () => {
    getFileCabinetPoolRecipients();
    setVisibility(true);
  };

  const { nodes } = {
    ...fileCabinetPoolRecipientsData?.fileCabinetPoolRecipients,
  };

  const [moveDocumentsToFolder, { loading: attachLoading }] = useMutation<
    AttachDocumentPoolDocuments,
    AttachDocumentPoolDocumentsVariables
  >(ATTACH_DOCUMENTS_TO_FOLDER, { errorPolicy: 'all' });

  const onDocumentsMove = async (
    selectedFolder: FileCabinetPoolRecipients_fileCabinetPoolRecipients_nodes
  ) => {
    const documentIds = documentsSelected.map((selectedIndex) => {
      return selectedIndex.id!;
    });
    const variables: AttachDocumentPoolInput = {
      poolId: selectedFolder.id,
      entityDocumentId: documentIds,
    };
    const { errors } = await moveDocumentsToFolder({
      variables: {
        input: variables,
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      onSuccess(selectedFolder.id, documentIds.length);
      setVisibility(false);
    }
  };

  return (
    <Stack>
      <TooltipHost content={'Move'}>
        <IconButton
          iconProps={{ iconName: 'OpenInNewWindow' }}
          onClick={openModal}
        />
      </TooltipHost>
      {visibility && (
        <MoveToFolderModal
          poolSelectedID={poolSelected?.id}
          fileCabinetPoolRecipients={nodes || []}
          foldersLoading={fileCabinetPoolRecipientsLoading}
          moveDocumentsLoading={attachLoading}
          closeModal={() => {
            setVisibility(false);
          }}
          onMove={onDocumentsMove}
        />
      )}
    </Stack>
  );
};
