import { AppRouter } from 'common/types/globalTypes';

export const getNavigationLink = (module: AppRouter, inputText: string) => {
  const searchInput = encodeURIComponent(inputText);
  switch (module) {
    case AppRouter.FILE_CABINET:
      return `/doc/fileCabinet?t=${searchInput}`;
    case AppRouter.DOCUMENT_POOL_FILE_LOADER:
      return `/doc/documentAssignment?t=${searchInput}`;
    case AppRouter.APPROVALS:
      return `/approvals?t=${searchInput}`;
    case AppRouter.APPROVAL_HISTORY:
      return `/approval_history?t=${searchInput}`;
    case AppRouter.TRANSACTION_SIGNING:
      return `/signing?t=${searchInput}`;
    case AppRouter.PAY_CYCLE:
      return `/pay/payment_cycle?t=${searchInput}`;
    case AppRouter.PAYMENT_TRACKING:
      return `/pay/payment_tracking?t=${searchInput}`;
    case AppRouter.POSTED_TRANSACTIONS:
      return `../../postingTracker/history?t=${searchInput}`;
    case AppRouter.PURCHASE_ORDER:
      return `/purchase-orders?t=${searchInput}`;
    case AppRouter.TRAVEL_AUTHORIZATION:
      return `/ta/travel-plan?t=${searchInput}`;
    case AppRouter.TRIP_BREAKDOWN:
      return `/ta/trip-breakdown?t=${searchInput}`;
    default:
      return `/404`;
  }
};
