import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  container:{
    padding: '10px 14px 0px'
  },
  listLoaderContainer: {
    marginLeft: 30,
    marginRight: 30,
  },
}));
