import { DayOfWeek, IDropdownOption, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import {
  FormikDropdown,
  AmountTextField,
  FormikTextField,
} from 'common/components';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AutoCompleteFormikTextField } from 'common/components/FormikFields/AutoCompleteFormikTextField';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import { onFormatDate } from 'common/utils/dateFormats';
import { EntityDocumentValues } from 'documents/personalPool/types';
import { DocumentTypeOptions } from 'documents/personalPool/UploadDocumentsForm';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes } from 'documents/documentAssignment/folder/list/__generated__/DocumentPoolDocuments';
interface FormViewProps {
  isEdit: boolean;
  isAccountingDocument: boolean;
  isDocumentProtected: boolean;
  showIsAccountingDocumentInputs: boolean;
  documentData?: DocumentPoolDocuments_documentPool_entityDocumentsByDocumentPoolIdAndEntityTypeId_nodes | null;
  documentType?: DocumentTypeOptions;
  companyCurrencies?: IDropdownOption[];
  onInputFieldsDisabled?: (data: boolean) => void;
  dataExtractModelOptions?: IDropdownOption[];
  isDocumentTypeSelector: boolean;
  hideExtractionDropdown: boolean;
  isExtractionTypeSelected: boolean;
}
export const FormView: React.FC<FormViewProps> = ({
  isEdit,
  isAccountingDocument,
  isDocumentProtected,
  showIsAccountingDocumentInputs,
  documentData,
  documentType,
  companyCurrencies,
  onInputFieldsDisabled,
  dataExtractModelOptions,
  isDocumentTypeSelector,
  hideExtractionDropdown,
  isExtractionTypeSelected,
}) => {
  const styles = useStyles();
  const { values, setFieldValue } = useFormikContext<EntityDocumentValues>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [disabledExtractionTypeId, setDisabledExtractionTypeId] =
    useState(false);
  const [showDataExtractDropdown, setShowDataExtractDropdown] = useState(false);

  const showCommentField =
    showIsAccountingDocumentInputs &&
    !(documentType?.isSigningRequired && !documentType.isAccountingDocument);

  // const showNameField = (documentType?.isSigningRequired || documentType?.isAccountingDocument);

  const isSigningDocument =
    documentType?.isSigningRequired && !documentType.isAccountingDocument;

  const extractionTypeLabel =
    documentType?.isSigningRequired && !documentType.isAccountingDocument
      ? 'Approval Flow'
      : 'Data Extraction Template';

  useEffect(() => {
    if (isEdit) {
      if (documentData?.extractionType !== null) {
        if (isDocumentProtected) {
          setIsDisabled(true);
          onInputFieldsDisabled?.(true);
        } else {
          setIsDisabled(documentData?.extractionType.isExtractProtected!);
          onInputFieldsDisabled?.(
            documentData?.extractionType.isExtractProtected!
          );
        }
      } else {
        setIsDisabled(isDocumentProtected);
        onInputFieldsDisabled?.(isDocumentProtected);
      }
    } else {
      if (values.extractionTypeId) {
        if (!isSigningDocument) {
          setIsDisabled(true);
          onInputFieldsDisabled?.(true);
        }
      } else {
        setIsDisabled(false);
        onInputFieldsDisabled?.(false);
      }
    }
  }, [
    isEdit,
    values.extractionTypeId,
    documentData,
    isDocumentProtected,
    onInputFieldsDisabled,
    isSigningDocument,
  ]);

  useEffect(() => {
    if (
      isEdit &&
      (hideExtractionDropdown || values.extractionTypeId === null)
    ) {
      setShowDataExtractDropdown(false);
    } else if (dataExtractModelOptions && dataExtractModelOptions?.length > 0) {
      setShowDataExtractDropdown(true);
    } else {
      setShowDataExtractDropdown(false);
    }
  }, [
    dataExtractModelOptions,
    isEdit,
    hideExtractionDropdown,
    values.extractionTypeId,
  ]);

  useEffect(() => {
    if (isDocumentTypeSelector && isExtractionTypeSelected) {
      setDisabledExtractionTypeId(true);
    }
  }, [isDocumentTypeSelector, isExtractionTypeSelected]);

  return (
    <>
      {isSigningDocument && (
        <AutoCompleteFormikTextField
          name="indexName"
          label="Name"
          placeholder="Enter name"
          disabled={isDisabled || isDocumentProtected}
          lookupData={documentData?.lookupName}
          documentTypeId={values.entityDocumentTypeId!}
          id={'indexName'}
          lookUpCalloutWidth={550}
        />
      )}
      {!isAccountingDocument && (
        <FormikTextField
          disabled={isDocumentProtected}
          name="indexDescription"
          label="Contents"
          placeholder="Enter contents"
        />
      )}
      {showDataExtractDropdown && (
        <Stack>
          <FormikDropdown
            label={extractionTypeLabel}
            placeholder="Select"
            name="extractionTypeId"
            options={dataExtractModelOptions || []}
            disabled={isEdit || disabledExtractionTypeId}
          />
        </Stack>
      )}
      {isAccountingDocument && (
        <Stack tokens={{ childrenGap: 20 }}>
          <AutoCompleteFormikTextField
            name="indexName"
            label="Name"
            placeholder="Enter name"
            disabled={isDisabled || isDocumentProtected}
            lookupData={documentData?.lookupName}
            documentTypeId={values.entityDocumentTypeId!}
            id={'indexName'}
            lookUpCalloutWidth={550}
          />
          {showIsAccountingDocumentInputs && (
            <>
              <FormikTextField
                disabled={isDisabled || isDocumentProtected}
                name="indexDescription"
                label="Description"
                placeholder="Enter description"
              />
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item className={styles.flex50}>
                  <FormikTextField
                    disabled={isDisabled || isDocumentProtected}
                    name="indexReferenceNumber"
                    label="Reference Number"
                    placeholder={
                      documentType?.text
                        ? `Enter ${documentType.text.toLowerCase()} number`
                        : 'Enter reference number'
                    }
                  />
                </Stack.Item>
                <Stack.Item className={styles.flex50}>
                  <FormikDatePicker
                    disabled={isDisabled || isDocumentProtected}
                    name="indexTransactionDate"
                    onSelectDate={(date) => {
                      if (date)
                        setFieldValue(
                          'indexTransactionDate',
                          onFormatDate(date)
                        );
                    }}
                    placeholder="Select date"
                    ariaLabel="Document Date"
                    label="Date"
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                  />
                </Stack.Item>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item className={styles.flex50}>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <AmountTextField
                      name="indexAmount"
                      label="Amount"
                      disabled={isDisabled || isDocumentProtected}
                      fixedDecimalScale
                      decimalScale={2}
                      placeholder="Amount"
                      allowNegative
                    />
                    <Stack horizontal className={styles.amountRow}>
                      {documentData?.documentAppliedAmounts?.usedTotal && (
                        <Stack horizontal>
                          <Text className={styles.label}>{`Used: `}</Text>
                          <AmountTextView
                            className={clsx(styles.amountText)}
                            value={
                              documentData.documentAppliedAmounts.usedTotal
                            }
                          />
                        </Stack>
                      )}
                      {documentData?.documentAppliedAmounts
                        ?._isRemainingAmountAvailable && (
                        <Stack horizontal>
                          <Text className={styles.label}>{`Remaining: `}</Text>
                          <AmountTextView
                            className={clsx(styles.amountText)}
                            value={
                              documentData?.documentAppliedAmounts
                                ?.remainingTotal
                            }
                          />
                        </Stack>
                      )}
                    </Stack>
                    <Stack horizontal className={styles.amountRow}>
                      {documentData?.documentAppliedAmount && (
                        <Stack horizontal>
                          <Text className={styles.label}>{`Applied: `}</Text>
                          <AmountTextView
                            className={clsx(styles.amountText)}
                            value={documentData?.documentAppliedAmount}
                          />
                        </Stack>
                      )}
                      {documentData?.documentStatusType?.statusType &&
                        !documentData.isAppliedAmountOverage && (
                          <Text className={styles.amountText}>
                            {documentData?.documentStatusType?.statusType}
                          </Text>
                        )}
                    </Stack>
                    <Stack horizontal className={styles.amountRow}>
                      {documentData?.isAppliedAmountOverage && (
                        <Stack horizontal>
                          <Text className={styles.label}>{`Retired: `}</Text>
                          <AmountTextView
                            className={clsx(styles.amountText)}
                            value={documentData?.documentRetiredAmount}
                          />
                        </Stack>
                      )}
                      {documentData?.documentOverageAmount && (
                        <Stack horizontal>
                          <Text className={styles.label}>{`Overage: `}</Text>
                          <AmountTextView
                            className={clsx(styles.amountText)}
                            value={documentData?.documentOverageAmount}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack.Item>
                <Stack.Item className={styles.flex50}>
                  <FormikDropdown
                    label="Currency"
                    placeholder="Select"
                    name="indexCurrencyId"
                    options={companyCurrencies || []}
                    disabled={isDisabled || isDocumentProtected}
                  />
                </Stack.Item>
              </Stack>
            </>
          )}
        </Stack>
      )}
      {showCommentField && (
        <FormikTextField
          disabled={isDisabled || isDocumentProtected}
          name="comment"
          label="Comment"
          placeholder="Enter comment"
        />
      )}
    </>
  );
};
