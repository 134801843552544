import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';

import { BlockBlobClient } from '@azure/storage-blob';
import { UploadFiles } from 'common/components/UploadFiles';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { UploadStatusType } from 'common/types/globalTypes';
import { useToasts } from 'react-toast-notifications';
import {
  ApprovalExtendedDocumentTypes,
  ApprovalExtendedDocumentTypesVariables,
} from './__generated__/ApprovalExtendedDocumentTypes';
import {
  ApprovalUploadDocument,
  ApprovalUploadDocument_approvalUploadDocument,
  ApprovalUploadDocumentVariables,
} from './__generated__/ApprovalUploadDocument';

const APPROVAL_UPLOAD_DOCUMENT = loader('./ApprovalUploadDocument.graphql');
const AVAILABLE_DOCUMENT_TYPES = loader(
  './ApprovalExtendedDocumentTypes.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);

interface UploadFormProps {
  entityApprovalId: string;
  onRefetch: () => void;
}
export const UploadForm: React.FC<UploadFormProps> = ({
  entityApprovalId,
  onRefetch,
}) => {
  const client = useApolloClient();

  const onUpload = async (
    fileSelected: File,
    document: ApprovalUploadDocument_approvalUploadDocument,
    toastId: string
  ) => {
    const observer = client.subscribe({
      query: DOCUMENT_UPLOAD_STATUS,
      variables: {
        documentId: document.document._documentFileId!,
      },
    });
    const subscription = observer.subscribe((data) => {
      const subscribedData = data.data as OnDocumentUploadStatus;
      const { status, document } = {
        ...subscribedData.documentUploadStatus,
      };
      if (!document) {
        if (status.type === UploadStatusType.VALIDATING) {
          updateToast(toastId!, {
            content: status.message
              ? `Validating files ${fileSelected.name} - ${status.message}`
              : `Validating files ${fileSelected.name}`,
            appearance: 'info',
            autoDismiss: false,
          });
        } else if (status.type === UploadStatusType.EXTRACTING) {
          updateToast(toastId!, {
            content: status.message
              ? `Extracting data from ${fileSelected.name} - ${status.message}`
              : `Extracting data from ${fileSelected.name}`,
            appearance: 'info',
            autoDismiss: false,
          });
        } else if (status.type === UploadStatusType.FAILURE) {
          subscription.unsubscribe();
          updateToast(toastId!, {
            content: status.message
              ? `Upload of ${fileSelected.name} failed - ${status.message}`
              : `Upload of ${fileSelected.name} failed`,
            appearance: 'error',
            autoDismiss: true,
          });
        } else if (status.type === UploadStatusType.WARNING) {
          subscription.unsubscribe();
          updateToast(toastId!, {
            content: status.message
              ? `Warning for file ${fileSelected.name}: ${status.message}`
              : `Warning for file ${fileSelected.name}`,
            appearance: 'warning',
            autoDismiss: true,
          });
        }
      } else {
        subscription.unsubscribe();
        onRefetch?.();
        updateToast(toastId!, {
          content: status.message
            ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
            : `Successfully uploaded ${fileSelected.name}`,
          appearance: 'success',
          autoDismiss: true,
        });
      }
    });
  };
  const { updateToast, addToast } = useToasts();
  const [uploadDocument] = useMutation<
    ApprovalUploadDocument,
    ApprovalUploadDocumentVariables
  >(APPROVAL_UPLOAD_DOCUMENT);

  const { data: AvailableDocumentTypes } = useQuery<
    ApprovalExtendedDocumentTypes,
    ApprovalExtendedDocumentTypesVariables
  >(AVAILABLE_DOCUMENT_TYPES, {
    variables: {
      entityApprovalId: entityApprovalId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const documentTypeOptions =
    AvailableDocumentTypes?.approvalExtendedDocumentTypes?.nodes?.map(
      (doctype) =>
        ({
          key: doctype.id,
          text: doctype.documentType || '',
          isAccountingDocument: doctype.isAccountingDocument,
          extractionTypes: doctype.extractionTypes,
        } as DocumentTypeOption)
    ) || [];

  return (
    <UploadFiles
      documentTypes={documentTypeOptions!}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, fileSelected) => {
          fileSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });

            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                approvalId: entityApprovalId,
              },
            });

            if (uploadMutationResults.errors)
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });

            if (
              uploadMutationResults.data?.approvalUploadDocument?.document &&
              uploadMutationResults.data.approvalUploadDocument.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data?.approvalUploadDocument,
                toastId!
              );

              const client = new BlockBlobClient(
                uploadMutationResults.data?.approvalUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
    />
  );
};
