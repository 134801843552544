import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, IDialogProps, IModalProps } from '@fluentui/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ActiveApproverListItem } from '..';
import { AvailableApproversList } from './AvailableApproversList';
import { AvailableApproverList_availableApproverList_nodes } from './AvailableApproversList/__generated__/AvailableApproverList';
import { useStyles } from './index.styles';
import { ConfirmDialog } from './ConfirmDialog';

export const modalProps: IModalProps = {
  isBlocking: false,
  dragOptions: {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  },
};

interface ConfirmDialogReplaceProps extends IDialogProps {
  hidden: boolean;
  selectedUser: ActiveApproverListItem | undefined;
  onDismiss: () => void;
}

export type AvailableApproverListType =
  AvailableApproverList_availableApproverList_nodes;

export const ConfirmDialogReplace: React.FC<ConfirmDialogReplaceProps> = ({
  hidden,
  selectedUser,
  onDismiss,
}) => {
  const styles = useStyles();
  const [selectedAvailableUser, setSelectedAvailableUser] = useState<AvailableApproverListType>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const disabledAcceptButton = !selectedAvailableUser;
  const replaceButtonStyle = clsx(disabledAcceptButton ? styles.acceptButtonDisabled : styles.acceptButton);

  const onDismissDialog = () => {
    setSelectedAvailableUser(undefined);
    setShowConfirmDialog(false);
    onDismiss();
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismissDialog}
      dialogContentProps={{
        title: "Available Approvers:",
        type: DialogType.largeHeader,
      }}
      minWidth={500}
      modalProps={modalProps}
    >
      <AvailableApproversList
        onRowSelection={(data) => {
          setSelectedAvailableUser(data[0])
        }}
      />
      <DialogFooter>
        <DefaultButton
          toggle
          primary={true}
          className={replaceButtonStyle}
          selected={true}
          onClick={() => setShowConfirmDialog(true)}
          text="Replace"
          disabled={disabledAcceptButton}
        />
        <DefaultButton
          onClick={onDismissDialog}
          text="Cancel"
        />
      </DialogFooter>
      <ConfirmDialog
        hidden={!showConfirmDialog}
        selectedUser={selectedUser}
        selectedAvailableUser={selectedAvailableUser}
        onDismiss={() => setShowConfirmDialog(false)}
        onSubmit={onDismissDialog}
      />
    </Dialog>
  )
}
