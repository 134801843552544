import { useLazyQuery } from '@apollo/client';
import { IRenderFunction, ITextFieldProps, ITextFieldStyles, IconButton, Label, Spinner, Stack, TextField, useTheme } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { DepotSelection } from './DepotSelection';
// import { DepotListRow } from './DepotSelection/DepotSelectionCallout/DepotList';
import { LookUpCallout } from './LookupCallout';
import { LookupDepotSearch, LookupDepotSearchVariables, LookupDepotSearch_lookupDepotSearch_nodes } from './__generated__/LookupDepotSearch';
import { useStyles } from './index.styles';
const LOOKUP_DEPOT_SEARCH = loader("./LookupDepotSearch.graphql")

type SelectedDepot = LookupDepotSearch_lookupDepotSearch_nodes;

type DepotAutoCompleteProps = RequiredNameProps<ITextFieldProps> & {
  lookupData?: LookupDepotSearch_lookupDepotSearch_nodes | null;
  depotData?: string | null | undefined;
  lookUpCalloutWidth?: number;
  isAirport?: boolean;
  isTrain?: boolean;
  labelId?: string;
  onDepotSelect?: (depot: SelectedDepot) => void;
  onAreaSelect?: (depot: SelectedDepot) => void;
  onClear?: () => void;
};

export const DepotAutoComplete: React.FC<DepotAutoCompleteProps> = ({
  lookupData,
  lookUpCalloutWidth,
  depotData,
  isAirport,
  isTrain,
  labelId,
  onDepotSelect,
  onAreaSelect,
  onClear,
  ...props
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const renderRef = useRef(false);
  const [calloutVisible, setCalloutVisible] = useState(true);
  const [depotDescription, setDepotDescription] = useState<string | null | undefined>(null);
  const [disableTextfield, setDisableTextfield] = useState<boolean | null | undefined>(false);
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name: props.name });

  const [
    fetchLookUp,
    {
      data,
      loading,
    }
  ] = useLazyQuery<
    LookupDepotSearch,
    LookupDepotSearchVariables
  >(LOOKUP_DEPOT_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: props.disabled
        ? theme.palette.neutralLighter
        : theme.palette.white,
    },
  };

  const { id, disabled, required, ...textfieldProps } = props;

  const renderSpinner = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disabled ? null : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              onChange(null);
              onClear?.();
              setDepotDescription(null);
              setDisableTextfield(false);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupDepot: value,
          isAirport: !!isAirport,
          isTrain: !!isTrain,
          first: 40
        },
      });
      queryCalled();
    }, 350),
    [value]
  );

  const lookupCallBack = useCallback(
    (value) => {
      fetchLookUp({
        variables: {
          lookupDepot: value,
          isAirport: !!isAirport,
          isTrain: !!isTrain,
          first: 40
        },
      });
    },
    [fetchLookUp, isAirport, isTrain]
  );

  useEffect(() => {
    if (value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(value);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack]);

  useEffect(() => {
    setDepotDescription(depotData);
    if (depotData) {
      setDisableTextfield(true);
    } else {
      setDisableTextfield(false);
    }
  }, [depotData]);

  const onRenderLabel: IRenderFunction<ITextFieldProps> = (props): JSX.Element => {
    if (disabled || !labelId) return <Label disabled={disabled}>{props?.label}</Label>
    return (
      <>
        <DepotSelection
          targetId={labelId}
          onDepotAreaSelect={(data) => {
            if (data) {
              onChange(data.id);
              onDepotSelect?.(data);
            }
          }}
          isAirport={isAirport}
          isTrain={isTrain}
          {...props}
        />
      </>
    );
  };

  return (
    <Stack style={{ width: 300 }}>
      <TextField
        {...textfieldProps}
        onRenderLabel={onRenderLabel}
        autoComplete={'off'}
        errorMessage={error?.message}
        id={id}
        value={depotDescription || ''}
        onChange={(_event, value) => {
          if (value) {
            setDepotDescription(value);
            callbackLookUp(value, () => {
              if (calloutVisible === false) setCalloutVisible(true);
            });
          } else {
            onChange(null);
            setDepotDescription(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textfieldStyles}
        disabled={disabled ? true : disableTextfield ? true : false}
      />
      <Stack className={styles.calloutContainer}>
        {calloutVisible && !loading && (
          <LookUpCallout
            lookUpCalloutWidth={lookUpCalloutWidth}
            onDismiss={() => {
              setCalloutVisible(false)
            }}
            onSelect={(value) => {
              if (value) {
                onChange(value.id);
                setDepotDescription(value.description)
                setDisableTextfield(true);
                onDepotSelect?.(value)
              }
            }}
            targetID={`#${id!}`}
            data={data?.lookupDepotSearch?.nodes || []}
          />
        )}
      </Stack>
    </Stack>
  )
}
