import {
  DetailsList,
  IColumn,
  ScrollablePane,
  Stack,
  Sticky,
  StickyPositionType,
  Text
} from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import { useCommonStyles } from 'common/styles';
import React, { useCallback, useEffect, useState } from 'react';
import {
  MovementOrders,
  MovementOrders_travelAuthorization_movementOrder_movementTravelers_nodes_movementTravelerJourneys_nodes,
} from '../../__generated__/MovementOrders';
import { MovementOrderTraveler } from '../Sidebar';
import { DeleteModal } from './Delete';
import { Journey } from './Journey';
import { Print } from './Print';
import { Rebuild } from './Rebuid';
import { getColumns } from './column.data';
import { useStyles } from './index.styles';

export type MovementOrderJourney =
  MovementOrders_travelAuthorization_movementOrder_movementTravelers_nodes_movementTravelerJourneys_nodes;
interface JourneysListProps {
  data: MovementOrders | undefined;
  selectedTraveler: MovementOrderTraveler | undefined;
  travelerSet: Set<string>;
  onRefetch: () => void;
}
export const JourneysList: React.FC<JourneysListProps> = ({
  data,
  selectedTraveler,
  travelerSet,
  onRefetch,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);
  const travelerList = Array.from(travelerSet);
  const JourneyList =
    data?.travelAuthorization?.movementOrder?.movementTravelers.nodes.find(
      (item) => item.id === selectedTraveler?.id
    )?.movementTravelerJourneys.nodes || ([] as MovementOrderJourney[]);

  useEffect(() => {
    setCustomColumns();
  }, [setCustomColumns]);

  const _renderItemColumn = (
    item: MovementOrderJourney | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof MovementOrderJourney
      ] as string;

      switch (column?.key) {
        case 'bodyArea1':
          return (
            <Stack
              verticalAlign="center"
              horizontalAlign="start"
              wrap
              horizontal
              tokens={{ childrenGap: 3 }}
            >
              <Text block>{fieldContent}</Text>
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <Journey
                journey={item}
                selectedTraveler={selectedTraveler}
                data={data}
              />
              <DeleteModal
                data={data}
                selectedTraveler={selectedTraveler}
                journey={item}
              />
            </Stack>
          );
        default:
          return (
            <Stack verticalAlign="center">
              <Text>{fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  return (
    <Stack style={{ marginBottom: 20 }}>
      <ScrollablePane>
        <Sticky stickyClassName="" stickyPosition={StickyPositionType.Header}>
          <Stack
            horizontalAlign="space-between"
            horizontal
            tokens={{ padding: '10px 20px' }}
          >
            <Text variant="xLarge" className={commonStyles.bold}>
              Trip Details
            </Text>

            <Stack horizontal tokens={{ childrenGap: 20 }}>
              {!!travelerList.length && (
                <Print
                  travelerSet={travelerSet}
                  data={data}
                  selectedTraveler={selectedTraveler}
                  onRefetch={onRefetch}
                />
              )}
              {!!travelerList.length && (
                <Rebuild
                  travelerSet={travelerSet}
                  data={data}
                  selectedTraveler={selectedTraveler}
                />
              )}
            </Stack>
          </Stack>
        </Sticky>
        <Stack
          tokens={{ padding: "0px 0px 120px" }}
        >
          <DetailsList
            columns={gridColumns}
            items={JourneyList}
            onRenderItemColumn={_renderItemColumn}
            className={styles.listContainer}
          />
        </Stack>
        {/* <Stack
          tokens={{ childrenGap: 10, padding: '20px' }}
          horizontal
          horizontalAlign="end"
          className={styles.footer}
        >
          <PrimaryButton text="Save" />
          <DefaultButton text="Cancel" />
        </Stack> */}
      </ScrollablePane>
    </Stack>
  );
};
