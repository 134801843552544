import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { TravelersOrderBy } from 'common/types/globalTypes';
import { filterOptionProps } from '../Filters';

type OrderLookup = {
  [direction in OrderDirection]: TravelersOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    '_sortName',
    {
      [OrderDirection.ASC]: [TravelersOrderBy._SORT_NAME_ASC],
      [OrderDirection.DESC]: [TravelersOrderBy._SORT_NAME_DESC],
    },
  ],
  [
    'vendorNumber',
    {
      [OrderDirection.ASC]: [TravelersOrderBy.VENDOR_NUMBER_ASC],
      [OrderDirection.DESC]: [TravelersOrderBy.VENDOR_NUMBER_DESC],
    },
  ],
  [
    'travelPolicyDescription',
    {
      [OrderDirection.ASC]: [TravelersOrderBy.TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_ASC],
      [OrderDirection.DESC]: [TravelersOrderBy.TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_DESC],
    },
  ],
  [
    'departmentName',
    {
      [OrderDirection.ASC]: [TravelersOrderBy.COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__NAME_ASC],
      [OrderDirection.DESC]: [TravelersOrderBy.COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__NAME_DESC],
    },
  ],
  [
    'userOccupationTitle',
    {
      [OrderDirection.ASC]: [TravelersOrderBy.USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_ASC],
      [OrderDirection.DESC]: [TravelersOrderBy.USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_DESC],
    },
  ],
  [
    'status',
    {
      [OrderDirection.ASC]: [
        TravelersOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelersOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [TravelersOrderBy._FULL_NAME_ASC];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), TravelersOrderBy.PRIMARY_KEY_ASC];
};

export const toFilterVariable = (filterOptions: filterOptionProps) =>
  filterOptions.filterTypes.map((filter) => {
    const subFilterKey = 'travelerAddressesByTravelerId';
    const checkWith = filterOptions.startsWith
      ? 'startsWithInsensitive'
      : 'includesInsensitive';
    switch (filter.filterKey) {
      case '_sortName':
      case 'vendorNumber':
      case 'status':
        return {
          [filter.filterKey]: {
            [checkWith]: filter.value,
          },
        };
      case 'travelPolicy':
        return {
          travelPolicy: {
            description: {
              [checkWith]: filter.value,
            },
          },
        };
      case 'userOccupation':
        return {
          userOccupation: {
            userOccupationTitle: {
              [checkWith]: filter.value,
            },
          },
        };
      case 'country':
        return {
          [subFilterKey]: {
            some: {
              country: {
                [filter.filterKey]: {
                  [checkWith]: filter.value,
                },
              },
            },
          },
        };
      case 'stateRegion':
        return {
          [subFilterKey]: {
            some: {
              stateRegion: {
                stateRegionDescription: {
                  [checkWith]: filter.value,
                },
              },
            },
          },
        };
      case 'cityName':
      case 'addressLine1':
        return {
          [subFilterKey]: {
            some: {
              [filter.filterKey]: {
                [checkWith]: filter.value,
              },
            },
          },
        };
      case 'rating':
        return {
          supplierRatingResults: {
            starValue: {
              greaterThanOrEqualTo: filter.value,
            },
          },
        };
      case 'department':
        return {
          [filter.filterKey]: {
            id: {
              equalTo: filter.value,
            }
          },
        };
      default:
        return undefined;
    }
  });
