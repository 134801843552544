import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 750,
    height: 450,
  },
  columnHeight: {
    height: 25,
  },
  depot: {
    color: theme.palette.themePrimary,
  },
  row: {
    cursor: 'pointer',
  },
  suffix: {
    backgroundColor: theme.palette.white,
  },
}));
