import { useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { HistoryActionMenuView } from 'common/components/History';
import { GlobalActions } from 'common/constants';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { BalancesListView } from '../../Balances';
import { CardHolder } from '../../__generated__/CardHolder';
import { CardAccountNotesSection } from './CardAccountNotes';
import { TagsSection } from './CardAccountTags';
import { useStyles } from './index.styles';
import {
  ListCardHolderNotes,
  ListCardHolderNotesVariables,
} from './__generated__/ListCardHolderNotes';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';

const ENTITY_NOTES_LIST = loader('./ListCardHolderNotes.graphql');
interface ApprovalDetailsProps {
  cardHolderDetails: CardHolder | undefined;
}
const balances = [
  {
    currency: 'USD',
    balance: '479.50',
  },
  {
    currency: 'GBP',
    balance: '1,300.00',
  },
  {
    currency: 'EUR',
    balance: '700.00',
  },
];

export const ActionsMenu: React.FC<ApprovalDetailsProps> = ({
  cardHolderDetails,
}) => {
  const { companyCardCompanies } = {
    ...cardHolderDetails?.cardHolder?.cardAccounts,
  };
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    GlobalActions.attach
  );
  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListCardHolderNotes,
    ListCardHolderNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id: cardHolderDetails?.cardHolder?.id!,
    },
  });
  const getEntityNotes = () => {
    const variables: ListCardHolderNotesVariables = {
      id: cardHolderDetails?.cardHolder?.id!,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    cardHolderDetails?.cardHolder?.id,
  ]);
  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.notes:
        return (
          <CardAccountNotesSection
            listRefreshRequest={getEntityNotesMemo}
            id={cardHolderDetails?.cardHolder?.id!}
            data={entityNotesData?.cardHolder?.notesByEntityId.nodes!}
          />
        );
      case GlobalActions.history:
        return (
          <HistoryActionMenuView
            moduleName="Purchase order"
            historyData={
              cardHolderDetails?.cardHolder?.cardHolderHistoriesByEntityId
                .nodes || []
            }
          />
        );
      case GlobalActions.tags:
        return (
          <TagsSection cardAccountID={cardHolderDetails?.cardHolder?.id} />
        );
      default:
        return null;
    }
  };
  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                <PivotItem
                  itemKey={GlobalActions.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    entityNotesData?.cardHolder?.notesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={GlobalActions.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={
                    cardHolderDetails?.cardHolder?.cardHolderHistoriesByEntityId
                      .nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={GlobalActions.tags}
                  itemIcon="Tag"
                  headerText="Tags"
                  itemCount={
                    cardHolderDetails?.cardHolder?.entityTagsByEntityId.nodes
                      .length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
            {!companyCardCompanies?._isManualProcess && (
              <BalancesListView data={balances} />
            )}
          </Stack>

          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack horizontalAlign="space-between" horizontal>
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Notes"
                iconName="Page"
                count={
                  entityNotesData?.cardHolder?.notesByEntityId.nodes.length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.notes);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                count={
                  cardHolderDetails?.cardHolder?.cardHolderHistoriesByEntityId
                    .nodes.length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.history);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Tags"
                iconName="Tag"
                count={
                  cardHolderDetails?.cardHolder?.entityTagsByEntityId.nodes
                    .length
                }
                onPivotClick={() => {
                  setSelectedKey(GlobalActions.tags);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
          {!companyCardCompanies?._isManualProcess && (
            <BalancesListView data={balances} />
          )}
        </Stack>
      )}
    </Stack>
  );
};
