import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '72px 0px',
  },
  flex1: {
    flex: 1,
  },
  supplierName: {
    marginLeft: 15,
  },
  sendToLabel: {
    fontWeight: 600,
    color: theme.palette.themeDark,
  },
}));
