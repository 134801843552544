import { useQuery } from '@apollo/client';
import {
  ActionButton,
  ComboBox,
  IButtonStyles,
  IComboBoxOption,
  IComboBoxStyles,
  Icon,
  Spinner,
  SpinnerSize,
  makeStyles,
  useTheme,
} from '@fluentui/react';
import { FilterArrayType } from 'common/components/Filters';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React from 'react';
import {
  AvailableTripTravelers,
  AvailableTripTravelersVariables,
} from './__generated__/AvailableTripTravelers';
const AVAILABLE_TRIP_TRAVELERS = loader('./AvailableTripTravelers.graphql');

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 8,
  },
}));

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    display: 'flex',
    width: 190,
    justifyContent: 'center',
  },
};

interface TripTravelersFilterProps {
  filterKey: string;
  onOptionChange?: (data: FilterArrayType) => void;
}

export const TripTravelersFilter: React.FC<TripTravelersFilterProps> = ({
  filterKey,
  onOptionChange,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const { data, loading, fetchMore } = useQuery<
    AvailableTripTravelers,
    AvailableTripTravelersVariables
  >(AVAILABLE_TRIP_TRAVELERS, {
    variables: {
      first: 20,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { availableTripTravelers } = { ...data };
  const { hasNextPage } = { ...availableTripTravelers?.pageInfo };

  const comboBoxStyles: Partial<IComboBoxStyles> = {
    container: {
      width: 190,
    },
    callout: {
      height: 430,
    },
    root: {
      backgroundColor: theme.palette.neutralLighter,
      '::after': {
        border: 0
      },
    },
    input: {
      backgroundColor: theme.palette.neutralLighter,
    },
  };

  const options: IComboBoxOption[] = formatDropdownOptions(
    availableTripTravelers?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => item._fullName || '',
      includeAll: true,
    }
  );

  const _onRenderIcon = () => {
    if (loading)
      return <Spinner size={SpinnerSize.medium} className={styles.icon} />;
    else return <Icon className={styles.icon} iconName="Add" />;
  };

  const _onRenderLowerContent = () => {
    if (!hasNextPage) return null;
    return (
      <ActionButton
        text="Load More"
        styles={buttonStyles}
        onClick={() => {
          const variables: AvailableTripTravelersVariables = {
            first: 20,
            after: data?.availableTripTravelers?.pageInfo.endCursor,
          };
          fetchMore({ variables });
        }}
        onRenderIcon={_onRenderIcon}
      />
    );
  };

  return (
    <ComboBox
      placeholder="Traveler"
      options={options}
      styles={comboBoxStyles}
      allowFreeform
      autoComplete="on"
      onRenderLowerContent={_onRenderLowerContent}
      dropdownWidth={190}
      onChange={(_, option) => {
        if (option)
          onOptionChange?.({
            value: option?.key!,
            filterKey,
            label: option?.text!,
          });
      }}
    />
  );
};
