import { TagCategoriesOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: TagCategoriesOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [TagCategoriesOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [TagCategoriesOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [TagCategoriesOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [TagCategoriesOrderBy.DESCRIPTION_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [TagCategoriesOrderBy.NAME_ASC];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};
