import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
  IColumn,
  IconButton,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { TABLE_ROWS } from 'common/constants';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { columns } from './column.data';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { ColumnData } from 'common/components/SearchBar';
import { useCommonStyles } from 'common/styles';
import { InfiniteList } from 'common/components/InfiniteList';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { useToasts } from 'react-toast-notifications';
import { toOrderByVariable } from '../utils';
import { TagGroupDetails_tagGroup } from '../__generated__/TagGroupDetails';
import { TagGroups, TagGroupsVariables } from '../__generated__/TagGroups';
import {
  TagGroupDelete,
  TagGroupDeleteVariables,
} from './__generated__/TagGroupDelete';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
import { TagGroupForm } from '../view';
import { ToggleButton } from 'common/components/Buttons/ToggleButton';
const TAG_GROUPS = loader('../TagGroups.graphql');
const TAG_GROUP_DELETE = loader('./TagGroupDelete.graphql');

export const TagsGroupSetup: React.FC = () => {
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [selectedRows, setSelectedRows] = useState<TagGroupDetails_tagGroup[]>(
    []
  );
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [tagGroupData, setTagGroupData] = useState<
    TagGroupDetails_tagGroup | undefined | null
  >();
  const toggleShowForm = () => setShowForm((prevState) => !prevState);

  const [deleteTagGroup] = useMutation<TagGroupDelete, TagGroupDeleteVariables>(
    TAG_GROUP_DELETE,
    { errorPolicy: 'all' }
  );

  const [
    getTagGroups,
    {
      data: tagGroupsData,
      loading: tagGroupsLoading,
      fetchMore,
      networkStatus,
      variables,
    },
  ] = useLazyQuery<TagGroups, TagGroupsVariables>(TAG_GROUPS, {
    variables: {
      first: TABLE_ROWS,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const handleSortAndPagination = (showMore: boolean) => {
    const variables: TagGroupsVariables = {
      first: TABLE_ROWS,
      orderBy: toOrderByVariable(sortOrderParam),
      after: showMore ? tagGroupsData?.tagGroups?.pageInfo.endCursor : null,
    };
    if (showMore) fetchMore?.({ variables });
    else getTagGroups({ variables });
  };

  const handleSortAndPaginationMemo = useCallback(handleSortAndPagination, [
    sortOrderParam,
  ]);

  useEffect(() => {
    handleSortAndPaginationMemo(false);
  }, [handleSortAndPaginationMemo]);

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.loading;

  const openUpdateModal = (item: TagGroupDetails_tagGroup) => {
    if (item) {
      setTagGroupData(item);
      setIsNew(false);
    }
    toggleShowForm();
  };

  const _renderItemColumn = (
    item: TagGroupDetails_tagGroup | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item && column) {
      const key = column.key as keyof TagGroupDetails_tagGroup;
      switch (column.key) {
        case 'name':
          return (
            <Stack verticalAlign="center">
              <Text>{item.name}</Text>
            </Stack>
          );
        case 'tagGroupItemsByTagGroupId':
          return (
            <Stack wrap horizontal tokens={{ childrenGap: 10 }}>
              {item.tagGroupItemsByTagGroupId.nodes.map((tag) => {
                return (
                  <ToggleButton
                    key={tag.id}
                    iconName="Accept"
                    toggled
                    text={tag._tagBadgeName || ''}
                    onClick={() => {}}
                    color={tag.tag?.badgeColor}
                  />
                );
              })}
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                onClick={() => {
                  openUpdateModal(item);
                }}
                disabled={!item._isUpdatable}
              />
            </Stack>
          );
        default:
          return (
            <Stack verticalAlign="center">
              <Text>{item[key] ?? ''}</Text>
            </Stack>
          );
      }
    }
  };

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const tagGroups = [...(tagGroupsData?.tagGroups?.nodes || [])];

  const transformedData = refetching ? undefined : tagGroups;

  const deleteTagGroups = async () => {
    const deletableData: EntityDeleteInput[] = selectedRows.map((obj) => {
      return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
    });
    const { errors } = await deleteTagGroup({
      variables: { input: { entityDelete: deletableData } },
      update: (cache, { data }) => {
        if (data?.tagGroupDelete?.deletedEntities) {
          const deletedIds = data.tagGroupDelete.deletedEntities.map(
            (entity) => entity?.id
          );
          const filteredTagGroupsList = tagGroups.filter(
            (emp) => deletedIds.indexOf(emp.id) === -1
          );
          const updatedTagGroupsData: TagGroups = {
            tagGroups: {
              ...tagGroupsData?.tagGroups,
              pageInfo: tagGroupsData?.tagGroups?.pageInfo!,
              totalCount:
                tagGroupsData?.tagGroups?.totalCount! - deletedIds.length,
              nodes: filteredTagGroupsList!,
            },
          };
          cache.writeQuery<TagGroups, TagGroupsVariables>({
            query: TAG_GROUPS,
            variables,
            data: updatedTagGroupsData,
          });
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else
      addToast('Deleted Successfully', {
        appearance: 'success',
      });
  };

  const validCount = selectedRows.filter(
    (selected) => selected._isDeletable
  ).length;
  const invalidNames = selectedRows
    .filter((selected) => !selected._isDeletable)
    .map((cannotDelete) => cannotDelete.name);
  const disableDeleteButton = !selectedRows.some(
    (selected) => selected._isDeletable
  );
  const showDeleteButton = selectedRows.length > 0;

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Tag Groups</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.TagGroup}
              disabled={disableDeleteButton}
              visible={showDeleteButton}
              multiple={{ validCount, invalidNames }}
              onConfirm={deleteTagGroups}
            />
            <PrimaryButton
              iconProps={{
                iconName: 'Add',
              }}
              text="New Tag Group"
              onClick={() => {
                setIsNew(true);
                toggleShowForm();
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <InfiniteList
        loading={tagGroupsLoading}
        onRenderItemColumn={_renderItemColumn}
        hasNextPage={tagGroupsData?.tagGroups?.pageInfo.hasNextPage}
        items={transformedData || []}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onSelectionChanged={setSelectedRows}
        onLoadMore={() => handleSortAndPagination(true)}
      />
      {showForm && (
        <TagGroupForm
          visible={showForm}
          onDismiss={toggleShowForm}
          isNew={isNew}
          tagGroupDetails={!isNew ? tagGroupData : undefined}
        />
      )}
    </>
  );
};
