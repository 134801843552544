import React from 'react';
import { DefaultButton, PrimaryButton, Stack, makeStyles } from '@fluentui/react';
const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0
  },
}));
interface ModalFooterProps {
  disableSubmit: boolean;
  onUploadPress: () => void;
  onCancelPress: () => void;
}
export const ModalFooter: React.FC<ModalFooterProps> = ({
  disableSubmit,
  onUploadPress,
  onCancelPress,
}) => {
  const styles = useStyles();
  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        tokens={{ childrenGap: 6, padding: '25px 25px 20px 25px' }}
      >
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <PrimaryButton
            text={'Upload'}
            onClick={onUploadPress}
            disabled={disableSubmit}
          />
          <DefaultButton text="Cancel" onClick={onCancelPress} />
        </Stack>
      </Stack>
    </Stack>
  );
};
