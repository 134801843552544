import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  actionContainer: {
    marginTop: 100,
    padding: 20,
  },
  headerContainer: {
    position: 'fixed',
    padding: 20,
    zIndex: 100,
    backgroundColor: theme.palette.white,
    width: 1500,
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.10)`,
  },
  carbonAccountContainer: {
    horizontalAlign: 'space-between',
  },

  modalContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: 1500,
  },
}));
