import React, { useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { BatchList } from './batch/list';
import { UnpostedTransactionsList } from './unpostedTransactions/list';
import { IDropdownOption } from '@fluentui/react';

export enum BatchEditTabs {
  batch = 'batch',
  transactions = 'transactions',
}

export const batchEditTabMode = makeVar<string>(BatchEditTabs.batch);
export const setBatchSelected = makeVar<BatchListOption | undefined>(undefined);
export interface BatchListOption extends IDropdownOption {
  key: string | number;
  text: string;
  description: string;
  isoCode: string;
  count: number | null;
  total: string | null;
}

export const BatchEditSection: React.FC = () => {
  const batchState = useReactiveVar(batchEditTabMode);
  // defaulting to Payment Tracking list after Route change
  useEffect(() => {
    return () => {
      batchEditTabMode(BatchEditTabs.batch);
    };
  }, []);

  const tabSwitchLogic = batchState === BatchEditTabs.batch;

  return <>{tabSwitchLogic ? <BatchList /> : <UnpostedTransactionsList />}</>;
};
