import { useLazyQuery } from '@apollo/client';
import {
  Callout,
  DirectionalHint,
  ITextFieldStyles,
  Spinner,
  Stack,
  Text,
  TextField,
  useTheme,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  LookupCitySearch,
  LookupCitySearchVariables,
} from './__generated__/LookupCitySearch';
import { useStyles } from './index.styles';
const LOOKUP_CITY = loader('./LookupCitySearch.graphql');

interface CityLookUpProps {
  baseField: string;
  disabled: boolean;
}

const useTextFieldStyles = () => {
  const theme = useTheme();
  const textfieldStyles = useMemo(() => {
    const styles: Partial<ITextFieldStyles> = {
      suffix: { backgroundColor: theme.palette.white },
    };
    return styles;
  }, [theme]);

  return textfieldStyles;
};

export const CityLookUp: React.FC<CityLookUpProps> = ({
  baseField,
  disabled,
}) => {
  const calloutId = useId('providerSearchCallout');
  const { setValue } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name: `${baseField}rentalCityName` });
  const textFieldStyles = useTextFieldStyles();
  const styles = useStyles();
  const [callOutVisible, setCallOutVisible] = useState<boolean>(false);
  const [getLookUpdate, { data, loading }] = useLazyQuery<
    LookupCitySearch,
    LookupCitySearchVariables
  >(LOOKUP_CITY);

  const callbackLookUp = useCallback(
    _.debounce((stringValue, queryCalled) => {
      getLookUpdate({ variables: { citySearch: stringValue } });
      queryCalled();
    }, 350),
    [value, loading]
  );

  const onSearch = (_: any, value?: string) => {
    if (value) {
      onChange(value);
      callbackLookUp(value, () => {
        setCallOutVisible(true);
      });
    } else {
      onChange(null);
      setCallOutVisible(false);
    }
  };

  const renderSpinner = () => {
    return (
      <Stack className={styles.spinnerContainer}>
        {loading && <Spinner />}
      </Stack>
    )
  };

  return (
    <Stack.Item grow={1}>
      <TextField
        label="City"
        autoComplete={'off'}
        placeholder="Enter city"
        errorMessage={error?.message}
        value={value || ''}
        onChange={onSearch}
        id={calloutId}
        onRenderSuffix={renderSpinner}
        styles={textFieldStyles}
        disabled={disabled}
      />

      {callOutVisible && data?.lookupCitySearch?.nodes?.length! > 0 && (
        <Callout
          gapSpace={10}
          onDismiss={() => setCallOutVisible(false)}
          isBeakVisible={false}
          className={styles.callout}
          directionalHint={DirectionalHint.bottomLeftEdge}
          target={`#${calloutId}`}
          calloutWidth={200}
        >
          <Stack className={styles.itemContainer}>
            {data?.lookupCitySearch?.nodes.map((item, index) => {
              return (
                <Text
                  onClick={() => {
                    onChange(item.lookupCity);
                    setValue(
                      `tripItems.0.rentalStateRegionId`,
                      item.stateRegionId
                    );
                    setValue(`tripItems.0.rentalCountryId`, item.countryId);
                    setCallOutVisible(false);
                  }}
                  className={styles.resultItem}
                  key={index.toString()}
                >
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Text>{item.lookupCity}</Text>
                  </Stack>
                </Text>
              );
            })}
          </Stack>
        </Callout>
      )}
    </Stack.Item>
  );
};
