import { useMutation } from '@apollo/client';
import { PrimaryButton, Stack } from '@fluentui/react';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { TrackingEntityDocumentData } from '../__generated__/TrackingEntityDocumentData';
import {
  POEntityDocumentRetireBalance,
  POEntityDocumentRetireBalanceVariables,
} from './__generated__/POEntityDocumentRetireBalance';
import {
  POEntityDocumentUnretireBalance,
  POEntityDocumentUnretireBalanceVariables,
} from './__generated__/POEntityDocumentUnretireBalance';
const RETIRE_BALANCE = loader('./POEntityDocumentRetireBalance.graphql');
const UNRETIRE_BALANCE = loader('./POEntityDocumentUnretireBalance.graphql');
const TITLE_RETIRE = 'Are you sure you want to retire the remaining balance?';
const TITLE_UNRETIRE = 'Are you sure you want to remove the retire amount?';

export const RetireBalance: React.FC<{
  documentData: TrackingEntityDocumentData | undefined;
  onMutationSuccess: () => void;
}> = ({ documentData, onMutationSuccess }) => {
  const { _unretirePrivilege, _retirePrivilege } = {
    ...documentData?.entityDocument,
  };
  const [dialogState, setDialogState] = useState<{
    visible: boolean;
    retire: boolean;
  }>({ visible: false, retire: false });
  const { addToast } = useToasts();
  const [retire] = useMutation<
    POEntityDocumentRetireBalance,
    POEntityDocumentRetireBalanceVariables
  >(RETIRE_BALANCE, { errorPolicy: 'all' });

  const [unRetire] = useMutation<
    POEntityDocumentUnretireBalance,
    POEntityDocumentUnretireBalanceVariables
  >(UNRETIRE_BALANCE, { errorPolicy: 'all' });

  const onConfirm = async () => {
    const { _rowTimestamp, id } = { ...documentData?.entityDocument };

    if (!dialogState.retire) {
      const { errors } = await unRetire({
        variables: {
          input: {
            rowTimestamp: _rowTimestamp!,
            entityDocumentId: id!,
          },
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        setDialogState({ retire: dialogState.retire, visible: false });
        onMutationSuccess();
        addToast('Balance Unretired successfully', {
          appearance: 'success',
        });
      }
    } else {
      const { errors } = await retire({
        variables: {
          input: {
            rowTimestamp: _rowTimestamp!,
            entityDocumentId: id!,
          },
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Balance retired', {
          appearance: 'success',
        });
        onMutationSuccess();
        setDialogState({ retire: dialogState.retire, visible: false });
      }
    }
  };

  return (
    <Stack>
      {_retirePrivilege && (
        <PrimaryButton
          text="Retire Balance"
          onClick={() => {
            setDialogState({ retire: true, visible: true });
          }}
        />
      )}
      {_unretirePrivilege && (
        <PrimaryButton
          text="Unretire Balance"
          onClick={() => {
            setDialogState({ retire: false, visible: true });
          }}
        />
      )}

      <ConfirmDialog
        hidden={!dialogState.visible}
        title={dialogState.retire ? TITLE_RETIRE : TITLE_UNRETIRE}
        onDismiss={() => {
          setDialogState({ retire: dialogState.retire, visible: false });
        }}
        maxWidth={350}
        onConfirm={async () => {
          onConfirm();
        }}
      />
    </Stack>
  );
};
