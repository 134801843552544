import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  topSheet:{
    padding: "0px 20px"
  },
  addressContainer:{
    padding: "0px 20px 20px",
    marginTop: "10px !important",
  },
  addressLabel:{
    marginLeft: 20
  },
  inputContainer:{
    flex: 1
  },
  checkboxContainer:{ width: "50%", marginTop: "30px !important" }
}));
