import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

export const columns: ColumnData[] = [
  {
    key: 'invoiceSequence',
    name: 'Sequence',
    fieldName: 'invoiceSequence',
    minWidth: 70,
    maxWidth: 90,
  },
  {
    key: 'scheduledDate',
    name: 'Date',
    fieldName: 'scheduledDate',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'scheduledNote',
    name: 'Note',
    fieldName: 'scheduledNote',
    minWidth: 450,
    maxWidth: 500,
  },
  {
    key: 'invoiceNumber',
    name: 'Invoice Number',
    fieldName: 'invoiceNumber',
    minWidth: 205,
    maxWidth: 205,
  },
  {
    key: 'scheduledAmount',
    name: 'Scheduled Amount',
    fieldName: 'scheduledAmount',
    minWidth: 175,
    maxWidth: 225,
    styles: rightAlignHeaderStyle,
  },
  {
    key: 'action',
    name: 'Action',
    fieldName: 'action',
    minWidth: 100,
    maxWidth: 160,
    columnActionsMode: ColumnActionsMode.disabled,
  },
];
