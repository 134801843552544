import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "25px !important",
    width: "380px",
  },
  text:{
    color: theme.palette.themePrimary
  },
  nameText:{
    fontWeight: FontWeights.bold,
  }
}));