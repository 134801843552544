import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 200,
    maxWidth: 300,
    ...commonColumnProps,
  },
  {
    key: 'documentType',
    name: 'Document Type',
    fieldName: 'documentType',
    minWidth: 150,
    maxWidth: 180,
    ...commonColumnProps,
  },

  {
    key: 'availableDocuments',
    name: 'Documents',
    fieldName: 'availableDocuments',
    minWidth: 100,
    maxWidth: 125,
    ...commonColumnProps,
  },
];
