import { Stack, Callout, DirectionalHint, TooltipHost, IconButton, Separator, Checkbox, Text } from '@fluentui/react';
import { AmountTextField } from 'common/components';
import { useCommonStyles } from 'common/styles';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { TravelPoliciesOptions } from '..';
import { TravelerValues } from '../../types';
import { useStyles } from './index.styles'

export type SelectedTypePolicy = Pick<TravelPoliciesOptions, | "text" | "isCompanion" | "isContractual" | "isDefaultCustomizable" | "currency">

interface TravelAllowancesCalloutProps {
  calloutId: string;
  dismissCallout: (param: boolean) => void;
  closeCallout: () => void;
  isCalloutVisible: boolean;
  inputsDisabled: boolean;
  selectedTypePolicy?: SelectedTypePolicy;
}

export const TravelAllowancesCallout: React.FC<TravelAllowancesCalloutProps> = ({
  calloutId,
  dismissCallout,
  closeCallout,
  isCalloutVisible,
  inputsDisabled,
  selectedTypePolicy,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { values, setFieldValue } = useFormikContext<TravelerValues>();

  const fareClassKeys = [
    { key: 'isEconomyClassPreferred', value: "Economy" },
    { key: 'isFirstClassPreferred', value: "First" },
    { key: 'isBusinessClassPreferred', value: "Business" },
    { key: 'isCharterClassPreferred', value: "Charter" },
    { key: 'isPremiumClassPreferred', value: "Premium" },
  ];

  const selectedFareClass = fareClassKeys.find((fareClass) => {
    const key = fareClass.key as keyof TravelerValues
    return values[key] === true
  })?.value;

  return (
    <>
      {
        isCalloutVisible &&
        <Stack className={styles.container}>
          <Callout
            onDismiss={() => dismissCallout(false)}
            gapSpace={10}
            isBeakVisible={false}
            directionalHint={DirectionalHint.bottomRightEdge}
            target={`#${calloutId!}`}
          >
            <Stack
              verticalAlign="center"
              className={styles.background}
            >
              <Stack verticalAlign="center" horizontal className={styles.header}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Text variant="mediumPlus">
                    Travel Policy
                  </Text>
                  {
                    selectedTypePolicy?.text &&
                    <Text variant="mediumPlus" className={commonStyles.colorThemePrimary}>
                      {`${selectedTypePolicy.text}`}
                    </Text>
                  }
                  {
                    <Text variant="mediumPlus" className={commonStyles.colorThemePrimary}>
                      {selectedTypePolicy?.currency}
                    </Text>
                  }
                </Stack>
                <TooltipHost content="Close">
                  <IconButton
                    onClick={closeCallout}
                    iconProps={{ iconName: 'Cancel' }}
                    className={styles.iconButtonColor}
                    ariaLabel="Expand"
                  />
                </TooltipHost>
              </Stack>
              <Separator />
              <Stack
                horizontal
                tokens={{ padding: "10px 15px" }}
              >
                {
                  selectedTypePolicy?.isContractual &&
                  <>
                    <Stack.Item className={styles.labelContainer}>
                      <Text className={styles.label}>
                        Granted Tickets:
                      </Text>
                      {
                        values.travelerCompanionTickets &&
                        <Text>
                          {values.travelerCompanionTickets}
                        </Text>
                      }
                    </Stack.Item>
                    <Stack.Item className={styles.labelContainer}>
                      <Text className={styles.label}>
                        Amount:
                      </Text>
                      {
                        <Text>
                          {values.travelerCompanionAmount ? values.travelerCompanionAmount : "0.00"}
                        </Text>
                      }
                    </Stack.Item>
                  </>

                }
                <Stack.Item className={styles.labelContainer}>
                  <Text className={styles.label}>
                    Fare Class:
                  </Text>
                  {
                    selectedFareClass &&
                    <Text>
                      {selectedFareClass}
                    </Text>
                  }
                </Stack.Item>
              </Stack>
              {
                values?.travelerPolicyAllowancesByTravelerId &&
                values?.travelerPolicyAllowancesByTravelerId.length > 0 &&
                <>
                  <Separator />
                  <Stack
                    horizontal
                  >
                    <Text
                      variant="mediumPlus"
                      className={styles.travelAllowancesHeading}
                    >
                      Travel Allowances
                    </Text>
                  </Stack>
                  <Separator />
                  <Stack className={styles.policyAllowanceContainer}>
                    <FieldArray name="travelerPolicyAllowancesByTravelerId">
                      {() => (
                        <>
                          {
                            values?.travelerPolicyAllowancesByTravelerId?.map((ele, index) => {
                              const travelerPolicyAllowanceName = `travelerPolicyAllowancesByTravelerId[${index}]`;
                              return (
                                <Stack
                                  key={index}
                                  horizontal
                                  verticalAlign="baseline"
                                  tokens={{
                                    childrenGap: 10,
                                    padding: "10px 15px"
                                  }}
                                >
                                  <Stack.Item className={styles.labelContainer}>
                                    {
                                      <Checkbox
                                        label={ele._allowanceDescription!}
                                        onChange={(_e, isChecked) => {
                                          if (isChecked) {
                                            setFieldValue(`${travelerPolicyAllowanceName}.isChecked`, true);
                                          } else {
                                            setFieldValue(`${travelerPolicyAllowanceName}.allowanceOverrideAmount`, null);
                                            setFieldValue(`${travelerPolicyAllowanceName}.isChecked`, false);
                                          }
                                        }}
                                        checked={ele.isChecked || false}
                                        disabled={inputsDisabled || !ele.isAllowanceCustomizable}
                                      />
                                    }
                                  </Stack.Item>
                                  <Stack.Item className={styles.labelContainer}>
                                    {
                                      ele.isChecked &&
                                      <TooltipHost content="Override">
                                        <AmountTextField
                                          name={`${travelerPolicyAllowanceName}.allowanceOverrideAmount`}
                                          placeholder="Override"
                                          fixedDecimalScale
                                          decimalScale={2}
                                          allowNegative={false}
                                          disabled={inputsDisabled}
                                        />
                                      </TooltipHost>
                                    }
                                  </Stack.Item>
                                </Stack>
                              )
                            })
                          }
                        </>
                      )}
                    </FieldArray>
                  </Stack>
                </>
              }
            </Stack>
          </Callout>
        </Stack>
      }
    </>
  )
}
