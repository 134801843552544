import { ColumnActionsMode, IColumn } from '@fluentui/react';
import { TransactionLayout } from 'common/types/globalTypes';

export type ColumnData = IColumn & {
  /** property to determine whether this coloumn can be toggled for visibility */
  toggleVisibility?: boolean;
  /** property to determine whether the column is visble or not*/
  isVisible?: boolean;
  isFormField?: boolean;
};

const paymentColumns = new Map<string, ColumnData>([
  [
    'delete',
    {
      key: 'delete',
      name: '',
      fieldName: 'delete',
      minWidth: 40,
      maxWidth: 40,
      columnActionsMode: ColumnActionsMode.disabled,
      isFormField: false,
    },
  ],
  [
    'distributionDate',
    {
      key: 'distributionDate',
      name: 'Date',
      fieldName: 'distributionDate',
      minWidth: 150,
      maxWidth: 150,
    },
  ],
  [
    'accountReference',
    {
      key: 'accountReference',
      name: 'Invoice #',
      fieldName: 'accountReference',
      minWidth: 100,
      maxWidth: 100,
    },
  ],
  [
    'additionalDescription',
    {
      key: 'additionalDescription',
      name: 'Description',
      fieldName: 'additionalDescription',
      minWidth: 380,
      maxWidth: 380,
    },
  ],
  [
    'distributionAmount',
    {
      key: 'distributionAmount',
      name: 'Amount',
      fieldName: 'distributionAmount',
      minWidth: 140,
      maxWidth: 140,
    },
  ],
]);

const paymentRegisterColumns = new Map<string, ColumnData>([
  [
    'delete',
    {
      key: 'delete',
      name: '',
      fieldName: 'delete',
      minWidth: 40,
      maxWidth: 40,
      columnActionsMode: ColumnActionsMode.disabled,
      isFormField: false,
    },
  ],
  [
    'accountReference',
    {
      key: 'accountReference',
      name: 'Payment #',
      fieldName: 'accountReference',
      minWidth: 100,
      maxWidth: 100,
    },
  ],
  [
    'additionalDescription',
    {
      key: 'additionalDescription',
      name: 'Payee',
      fieldName: 'additionalDescription',
      minWidth: 380,
      maxWidth: 380,
    },
  ],
  [
    'distributionDate',
    {
      key: 'distributionDate',
      name: 'Date',
      fieldName: 'distributionDate',
      minWidth: 150,
      maxWidth: 150,
    },
  ],
  [
    'distributionAmount',
    {
      key: 'distributionAmount',
      name: 'Amount',
      fieldName: 'distributionAmount',
      minWidth: 140,
      maxWidth: 140,
    },
  ],
]);

export const getColumns = (
  entryLayout: TransactionLayout | null | undefined
) => {
  if (entryLayout === TransactionLayout.DOCUMENT_PAYMENT) {
    const columnArray = Array.from(paymentColumns, (data) => data[1]);
    return { columnArray };
  } else {
    const columnArray = Array.from(paymentRegisterColumns, (data) => data[1]);
    return { columnArray };
  }
};
