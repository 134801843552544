import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '70vw',
    height: '80vh',
  },
  stickyInsideStack: {
    padding: '25px 25px 0px',
    backgroundColor: theme.palette.neutralLight,
  },
}));
