import { makeStyles } from '@fluentui/react';

const RED_700 = '#D32F2F';
const RED_800 = '#C62828';
const RED_900 = '#B71C1C';

export const useStyles = makeStyles(() => ({
  promoteButton: {
    color: 'white',
    backgroundColor: RED_700,
    borderColor: RED_700,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: RED_800,
      borderColor: RED_800,
    },
    ':active': {
      color: 'white',
      backgroundColor: RED_900,
      borderColor: RED_900,
    },
  },
}));
