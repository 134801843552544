import { EntityDeleteInput } from 'common/types/globalTypes';
import { differenceBy } from 'lodash';
import { DepartmentGroupRow } from '../../list';
import { DepartmentGroups_departmentGroups_nodes_departmentGroupItems_nodes } from '../../list/__generated__/DepartmentGroups';
import { DEPARTMENT_GROUP_INITIAL_VALUES } from './constants';
import { DepartmentGroupItemsValues, DepartmentGroupValues } from './types';

interface DefaultValueProps {
  isEdit: boolean;
  departmentGroup: DepartmentGroupRow | undefined;
}
type DepartmentGroupItems =
  DepartmentGroups_departmentGroups_nodes_departmentGroupItems_nodes;
interface GroupItemsProps {
  departmentGroupItems: DepartmentGroupItems[] | undefined;
  values: DepartmentGroupItemsValues[];
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isEdit, departmentGroup } = { ...props };
  const {
    __typename,
    name,
    description,
    isTravelDepartmentGroup,
    departmentGroupItems,
    ...departmentGroupFields
  } = {
    ...departmentGroup,
  };
  let defaultValues: DepartmentGroupValues = DEPARTMENT_GROUP_INITIAL_VALUES;
  if (isEdit && departmentGroupFields) {
    defaultValues = {
      name: name || null,
      description: description || null,
      isTravelDepartmentGroup: isTravelDepartmentGroup || null,
      departmentGroupItems:
        departmentGroupItems?.nodes.map((ele) => ({
          departmentId: ele.departmentId,
        })) || [],
    };
  }
  return defaultValues;
};

export const getDeletedItems = (props: GroupItemsProps) => {
  const { departmentGroupItems, values } = { ...props };
  const deletedGroupItems =
    values &&
    (differenceBy(departmentGroupItems, values, 'departmentId').map((item) => ({
      id: item.id,
      rowTimestamp: item._rowTimestamp,
    })) as EntityDeleteInput[]);
  return deletedGroupItems;
};

export const getNewGroupItems = (props: GroupItemsProps) => {
  const { departmentGroupItems, values } = { ...props };
  const groupItemsArray = departmentGroupItems?.map((ele) => ({
    departmentId: ele.departmentId,
  }));
  const newGroupItems =
    values.filter(
      ({ departmentId: departmentId1 }) =>
        !groupItemsArray?.some(
          ({ departmentId: departmentId2 }) => departmentId2 === departmentId1
        )
    ) || [];
  return newGroupItems;
};
