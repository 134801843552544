import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  callout: {
    // padding: '15px',
    minWidth: 550,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  advancedFilterContainer: {
    backgroundColor: theme.palette.neutralLighter,
    padding: '20px 20px',
    marginLeft: '-20px',
    marginRight: '-20px',
  },
  icon: {
    fontSize: 18,
    color: theme.palette.neutralSecondaryAlt,
  },
  container: {
    padding: '20px',
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));
