import { useMutation, useQuery } from '@apollo/client';
import {
  ContextualMenu,
  IDragOptions,
  Modal,
  Stack,
  makeStyles,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChartOfAccountDefaultUpdateInput,
  ChartOfAccountPatch,
  ChartOfAccountUpdateInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { Header } from './Header';
import {
  ChartOfAccount,
  ChartOfAccountVariables,
} from './__generated__/ChartOfAccount';
import {
  ChartOfAccountCreate,
  ChartOfAccountCreateVariables,
} from './__generated__/ChartOfAccountCreate';
import {
  ChartOfAccountUpdate,
  ChartOfAccountUpdateVariables,
} from './__generated__/ChartOfAccountUpdate';
import { ChartOfAccountsValues } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validation';
import { ChartOfAccountDefaultUpdate, ChartOfAccountDefaultUpdateVariables } from 'common/graphql/__generated__/ChartOfAccountDefaultUpdate';
const CHART_OF_ACCOUNT_CREATE = loader('./ChartOfAccountCreate.graphql');
const CHART_OF_ACCOUNT_UPDATE = loader('./ChartOfAccountUpdate.graphql');
const CHART_OF_ACCOUNT = loader('./ChartOfAccount.graphql');
const CHART_OF_ACCOUNTS = loader(
  '../../../list/chartAccount/ChartOfAccounts.graphql'
);
const CHART_OF_ACCOUNT_DEFAULT_UPDATE = loader('../../../../../../common/graphql/ChartOfAccountDefaultUpdate.graphql')

const useStyles = makeStyles(() => ({
  container: {
    width: 600,
    maxHeight: 600,
  },
}));

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

interface ChartOfAccountsModalProps {
  visible: boolean;
  isEdit?: boolean;
  onClose: () => void;
  chartOfAccountId: string | null;
}

export const ChartOfAccountsModal: React.FC<ChartOfAccountsModalProps> = ({
  visible,
  isEdit = false,
  onClose,
  chartOfAccountId,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const { data: chartOfAccountData, refetch } = useQuery<
    ChartOfAccount,
    ChartOfAccountVariables
  >(CHART_OF_ACCOUNT, {
    variables: {
      id: chartOfAccountId!,
    },
    skip: !chartOfAccountId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [createChartOfAccount, { loading: createChartOfAccountLoading }] =
    useMutation<ChartOfAccountCreate, ChartOfAccountCreateVariables>(
      CHART_OF_ACCOUNT_CREATE,
      { errorPolicy: 'all' }
    );

  const [updateChartOfAccount, { loading: updateChartOfAccountLoading, }] =
    useMutation<ChartOfAccountUpdate, ChartOfAccountUpdateVariables>(
      CHART_OF_ACCOUNT_UPDATE,
      { errorPolicy: 'all' }
    );

  const [chartOfAccountDefaultUpdate, { loading: setDefaultLoading }] = useMutation<
    ChartOfAccountDefaultUpdate,
    ChartOfAccountDefaultUpdateVariables
  >(CHART_OF_ACCOUNT_DEFAULT_UPDATE, { errorPolicy: 'all' });

  const formMethods = useForm<ChartOfAccountsValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { handleSubmit, reset, trigger } = { ...formMethods };

  const defaultValues = getDefaultValues({
    isEdit,
    data: chartOfAccountData,
  });

  const onHandleSubmit = async (values: ChartOfAccountsValues) => {
    if (isEdit) {
      const { id, _rowTimestamp } = { ...chartOfAccountData?.chartOfAccount };
      const chartOfAccountPatch: ChartOfAccountPatch = Object.entries(
        values
      ).reduce((res, [key, val]) => {
        if (val !== defaultValues[key as keyof ChartOfAccountsValues]) {
          return { ...res, [key]: val };
        }
        return res;
      }, {});
      const { errors } = await updateChartOfAccount({
        variables: {
          input: {
            id,
            rowTimestamp: _rowTimestamp,
            chartOfAccountPatch: !isEmpty(chartOfAccountPatch)
              ? chartOfAccountPatch
              : undefined,
          } as ChartOfAccountUpdateInput,
        },
        update(cache, { data }) {
          const identity = cache.identify({
            ...chartOfAccountData?.chartOfAccount,
          });
          cache.modify({
            id: identity,
            fields: {
              name() {
                return data?.chartOfAccountUpdate?.chartOfAccount?.name;
              },
            },
          });
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Corporate chart of account edited successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const { errors } = await createChartOfAccount({
        variables: {
          input: {
            chartOfAccount: {
              ...values,
              name: values.name || '',
            },
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CHART_OF_ACCOUNTS,
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Corporate chart of account created successfully.', {
          appearance: 'success',
        });
        reset();
        onClose();
      }
    }
  };

  const onSetDefault = async () => {
    const { _defaults } = { ...chartOfAccountData?.chartOfAccount };
    const { _isDefaultUpdateAvailable } = { ..._defaults }
    const input: ChartOfAccountDefaultUpdateInput = !!_isDefaultUpdateAvailable ? {
      defaultChartOfAccountId: chartOfAccountId
    } : {};
    const successMessage = !!_isDefaultUpdateAvailable ? 'Default set successfully.' : 'Default removed successfully.'
    const { errors } = await chartOfAccountDefaultUpdate({
      variables: {
        input
      }
    })
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      refetch();
      addToast(successMessage, {
        appearance: 'success',
      });
    }
  }

  useEffect(() => {
    if (chartOfAccountData?.chartOfAccount) {
      const defaultValues = getDefaultValues({
        isEdit,
        data: chartOfAccountData,
      });
      reset(defaultValues);
    }
    trigger();
  }, [chartOfAccountData, isEdit, reset, trigger]);

  const loading = createChartOfAccountLoading || updateChartOfAccountLoading || setDefaultLoading;

  if (!visible) return null;

  return (
    <Modal isOpen isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        <FormProvider {...formMethods}>
          <Header isEdit={isEdit} data={chartOfAccountData} onClose={onClose} />
          <BasicForm
            isEdit={isEdit}
            data={chartOfAccountData}
          />
          <Footer
            isEdit={isEdit}
            data={chartOfAccountData}
            loading={loading}
            onSubmit={handleSubmit(onHandleSubmit)}
            onCancel={onClose}
            onSetDefault={onSetDefault}
          />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
