import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  sequenceContainer:{
    flex: 0.5
  },
  dateContainer:{
    flex: 0.7
  },
  noteContainer:{
    flex: 1
  },
  amountContainer:{
    flex: 0.8
  },
}));
