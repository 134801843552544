import {
  ConstrainMode,
  IColumn,
  IconButton, Stack,
  Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { InfiniteList } from 'common/components/InfiniteList';
import { OrderDirection } from 'common/utils/commonTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React, { useState } from 'react';
import { AccountingPeriodView } from '../../view/AccountingPeriodView';
import { AccountingPeriodValues } from '../../view/types';
import { CompanyCorporateYear_companyCorporateYear, CompanyCorporateYear_companyCorporateYear_companyCorporatePeriodsByFiscalYearId_nodes } from '../../view/__generated__/CompanyCorporateYear';
import { columns } from './column.data';
import { useStyles } from './index.styles';
export interface SortColumnType {
  column: string;
  direction: OrderDirection;
}
export type CompanyCorporatePeriod = CompanyCorporateYear_companyCorporateYear_companyCorporatePeriodsByFiscalYearId_nodes;
interface PeriodListProps {
  corporateYear: CompanyCorporateYear_companyCorporateYear | null | undefined;
  loading: boolean;
  onPeriodSelection: (selectedPeriods: CompanyCorporatePeriod[]) => void;
}
enum PeriodOpen {
  yes = "Yes",
  no = "No"
}
enum EntryAllowed {
  yes = "Yes",
  no = "No"
}
export const PeriodList: React.FC<PeriodListProps> = ({
  corporateYear,
  loading,
  onPeriodSelection,
}) => {
  const styles = useStyles();
  const [hideCreatePeriodForm, { toggle: toggleCreatePeriodForm }] = useBoolean(false);
  const [index, setIndex] = useState<number>(0);
  const [fiscalYearId, setFiscalYearId] = useState<string | null>(null);
  const [fiscalYearData, setFiscalYearData] = useState<AccountingPeriodValues>();

  const _renderItemColumn = (
    item: CompanyCorporatePeriod | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof CompanyCorporatePeriod
      ] as string;

      switch (column?.key) {
        case 'fiscalPeriod':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              tokens={{ padding: '0px 0px 0px 8px' }}
              verticalAlign="center"
            >
              {fieldContent && <Text>{fieldContent}</Text>}
            </Stack>
          );
        case 'startDate':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              {fieldContent && <Text>{dateFormat(dateConvertions(fieldContent))}</Text>}
            </Stack>
          );
        case 'endDate':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              {fieldContent && <Text>{dateFormat(dateConvertions(fieldContent))}</Text>}
            </Stack>
          );
        case 'isPeriodOpen':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              {
                fieldContent ?
                  <Text>{PeriodOpen.yes}</Text> :
                  <Text>{PeriodOpen.no}</Text>
              }
            </Stack>
          );
        case 'isEntryAllowed':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              {fieldContent ?
                <Text>{EntryAllowed.yes}</Text> :
                <Text>{EntryAllowed.no}</Text>
              }
            </Stack>
          );
        case 'action':
          return (
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 3 }}
            >
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                disabled={!item._isUpdatable}
                onClick={() => onEditPeriodClick(_index)}
              />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && <Text>{fieldContent}</Text>}
            </Stack>
          );
      }
    }
  };

  const onEditPeriodClick = (index: number | undefined) => {
    if (corporateYear) {
      const fiscalYearDetails: AccountingPeriodValues = {
        id: corporateYear.id,
        _rowTimestamp: corporateYear._rowTimestamp,
        fiscalYear: corporateYear.fiscalYear,
        startDate: corporateYear.startDate,
        endDate: corporateYear.endDate,
        isYearOpen: corporateYear.isYearOpen,
        isEntryAllowed: corporateYear.isEntryAllowed,
        companyCorporatePeriodsByFiscalYearId: [
          ...corporateYear.companyCorporatePeriodsByFiscalYearId.nodes
        ]
      }
      setIndex(index || 0);
      setFiscalYearData(fiscalYearDetails)
      setFiscalYearId(corporateYear.id)
      toggleCreatePeriodForm();
    }
  };

  const totalPeriod = corporateYear?.companyCorporatePeriodsByFiscalYearId?.nodes?.length || 0;

  const documentsListHeight = totalPeriod * 52 + 70;
  const sectionHeight =
    documentsListHeight > 500
      ? 500
      : totalPeriod === 0
        ? 150
        : documentsListHeight;

  return (
    <>
      <Stack style={{ height: sectionHeight }}>
        <InfiniteList
          items={corporateYear?.companyCorporatePeriodsByFiscalYearId?.nodes || []}
          loading={loading}
          hasNextPage={false}
          columns={columns}
          onRenderItemColumn={_renderItemColumn}
          onSelectionChanged={onPeriodSelection}
          constrainMode={ConstrainMode.unconstrained}
        />
      </Stack>
      {hideCreatePeriodForm && (
        <AccountingPeriodView
          visible={hideCreatePeriodForm}
          onDismiss={toggleCreatePeriodForm}
          fiscalYearData={fiscalYearData}
          isNew={false}
          fiscalYearId={fiscalYearId!}
          index={index}
        />
      )}
    </>
  );
};
