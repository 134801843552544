import { FontSizes, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.neutralLighter,
    height: 50,
    alignItems: 'center',
    padding: '0px 20px 0px 15px',
    marginBottom: 8,
    borderRadius: 4,
  },
  searchStyleIcon: {
    padding: 10,
    cursor: 'default',
    fontSize: FontSizes.size18,
    color: theme.palette.themePrimary,
  },
  globeStyleIcon: {
    padding: 10,
    fontSize: FontSizes.size14,
    color: 'white',
  },
  globeStyleIconDisable: {
    padding: 10,
    fontSize: FontSizes.size14,
    color: 'grey',
  },
  iconButtonStyles: {
    color: theme.palette.neutralPrimaryAlt,
    selectors: {
      ':hover': {
        color: theme.palette.neutralPrimaryAlt,
        borderRadius: 2,
        backgroundColor: theme.palette.neutralLight,
      },
    },
  },
  globalSearchStyle: {
    backgroundColor: theme.palette.themePrimary,
    color: 'white',
    borderRadius: 5,
  },
  globalSearchStyleDisable: {
    backgroundColor: theme.palette.neutralQuaternaryAlt,
    color: 'white',
    borderRadius: 5,
  },
  iconBlueStyles: {
    selectors: {
      ':hover': {
        borderRadius: 2,
        backgroundColor: theme.palette.neutralLight,
      },
    },
  },
  iconButtonPrimaryStyles: {
    color: 'white',
    borderRadius: 5,
    backgroundColor: theme.palette.themePrimary,
    selectors: {
      ':hover': {
        color: 'white',
        borderRadius: 5,
        backgroundColor: theme.palette.themeDarkAlt,
      },
    },
  },
  textDecoder: {
    textDecoration: 'none',
  },
}));
