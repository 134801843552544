import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

export const columns: ColumnData[] = [
  {
    key: 'tripLocator',
    name: 'Locator',
    fieldName: 'tripLocator',
    minWidth: 200,
    maxWidth: 280,
    ...commonColumnProps,
  },
  {
    key: 'travelerReferenceName',
    name: 'Traveler',
    fieldName: 'travelerReferenceName',
    minWidth: 180,
    maxWidth: 260,
    ...commonColumnProps,
  },
  {
    key: 'accountReference',
    name: 'Account#',
    fieldName: 'accountReference',
    minWidth: 160,
    maxWidth: 240,
    ...commonColumnProps,
  },
  {
    key: 'airfareAmount',
    name: 'Airfare',
    fieldName: 'airfareAmount',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'accommodationAmount',
    name: 'Hotel/Acc',
    fieldName: 'accommodationAmount',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'vehicleRentalAmount',
    name: 'Veh Rental',
    fieldName: 'vehicleRentalAmount',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'transportationAmount',
    name: 'Grnd Trans',
    fieldName: 'transportationAmount',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'trainAmount',
    name: 'Train',
    fieldName: 'trainAmount',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'incidentalAmount',
    name: 'Other',
    fieldName: 'incidentalAmount',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'controlTotalAmount',
    name: 'Total',
    fieldName: 'controlTotalAmount',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
];
