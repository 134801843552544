import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: 80,
    marginBottom: 100,
  },
  fieldContainer: {
    flex: 1,
  },
}));
