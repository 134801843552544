import { FontWeights, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  weekText: {
    fontWeight: FontWeights.semibold,
    // padding: 7,
  },
  weekTextContainer: {
    height: 40,
    borderRadius: 3,
    padding: '0px 10px 0px 10px',
    // backgroundColor: theme.palette.neutralLighter,
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: 25,
  },
  headerContainer: {
    width: 150,
    // backgroundColor: 'aqua',
    display: 'flex',
    alignItems: 'end',
  },
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: '0px 4px',
  },
  amountContainer: {
    alignItems: 'end',
    width: 100,
  },
  totalAmount: {
    fontSize: 20,
    color: theme.palette.themePrimary,
    fontWeight: 'bold',
  },
  amount: {
    fontSize: 18,
    color: theme.palette.black,
    // fontWeight: 'bold',
  },
}));
