import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontSize: theme.fonts.medium.fontSize,
    ':focus': {
      color: theme.palette.themeDark,
    },
  },
  link: {
    color: theme.palette.themeDark,
    ':focus': {
      color: theme.palette.themeDark,
    },
  },
  linkBold: {
    fontWeight: 'bold',
    color: theme.palette.themePrimary,
    ':focus': {
      color: theme.palette.themePrimary,
    },
  },
}));
