import { ChoiceGroup, IChoiceGroupProps } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import { useField } from 'formik';
import React, { FC } from 'react';
import { ErrorText } from '../ErrorText';

export const FormikChoiceGroup: FC<RequiredNameProps<IChoiceGroupProps>> = ({
  name,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField<string | undefined>(name);
  return (
    <>
      <ChoiceGroup
        {...props}
        selectedKey={field.value}
        onChange={(event, option) => {
          helpers.setValue(option?.key!);
          onChange?.(event, option);
        }}
      />
      {meta.touched && meta.error && <ErrorText message={meta.error} />}
    </>
  );
};
