import { DefaultButton, PrimaryButton, ProgressIndicator, Stack, makeStyles } from '@fluentui/react';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
  }
}));

interface FooterProps {
  buttonText: string;
  disabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  buttonText,
  disabled,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const styles = useStyles();

  return (
    <Stack
      className={styles.footer}
    >
      {isLoading && <ProgressIndicator />}
      <Stack
        horizontal
        tokens={{
          childrenGap: 10,
          padding: 20,
        }}
        horizontalAlign="end"
      >
        <PrimaryButton
          disabled={disabled}
          text={buttonText}
          onClick={onSubmit}
        />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </Stack>
    </Stack>
  )
}
