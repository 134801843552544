import { NetworkStatus, useQuery } from '@apollo/client';
import { PrimaryButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData, SearchBar } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { CorporateWorkgroupsOrderBy } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CorporateWorkgroupsFields } from '../__generated__/CorporateWorkgroupsFields';
import { DeleteWorkGroup } from './DeleteWorkGroup';
import { CorporateWorkgroups, CorporateWorkgroupsVariables } from './__generated__/CorporateWorkgroups';
import { getColumns } from './column.data';
import { ColumnStylesProps, onRenderItems } from './columns.render';
import { useStyles } from './index.styles';
import { onHandleSearch } from './utils';
import { useHistory } from 'react-router-dom';
const CORPORATE_WORKGROUPS = loader('./CorporateWorkgroups.graphql')

export type CorporateWorkGroupsItem = Omit<
  CorporateWorkgroupsFields,
  'businessUnit' |
  'corporateChartOfAccount' |
  'productionPhase'
> & {
  businessUnit: string | null;
  corporateChartOfAccount: string | null;
  productionPhase: string | null;
  status: string | null;
}

export const CorporateWorkGroupsList = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const renderRef = useRef(false);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [selectedRows, setSelectedRows] = useState<CorporateWorkGroupsItem[]>([]);

  const columnStyles: ColumnStylesProps = {
    onRenderColumnStack: styles.onRenderColumnStack,
  };

  const {
    loading,
    data: corporateWorkgroupsData,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery<CorporateWorkgroups, CorporateWorkgroupsVariables>(
    CORPORATE_WORKGROUPS,
    {
      variables: {
        first: TABLE_ROWS,
        orderBy: [
          CorporateWorkgroupsOrderBy.WORKGROUP_NUMBER_ASC,
          CorporateWorkgroupsOrderBy.PRIMARY_KEY_ASC,
        ],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const handleSearch = async (showMore: boolean) => {
    setSelectedRows([])
    const { queryVariables } =
      onHandleSearch(
        showMore,
        sortOrderParam,
        corporateWorkgroupsData,
      )
    if (showMore) fetchMore?.({ variables: queryVariables });
    else refetch(queryVariables);
  }

  const handleSearchMemo = useCallback(handleSearch, [
    sortOrderParam,
  ])

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const {
    nodes,
    pageInfo,
  } = { ...corporateWorkgroupsData?.corporateWorkgroups }

  const transformedData: CorporateWorkGroupsItem[] = refetching ? [] :
    nodes?.map((item) => ({
      ...item,
      businessUnit: item.businessUnit?.name || null,
      corporateChartOfAccount: item.corporateChartOfAccount?.name || null,
      productionPhase: item.productionPhase?.statusType || null,
      status: item.statusType?.statusType || null,
    })) || [];

  const _renderItemColumn = useCallback(
    onRenderItems(
      columnStyles
    ),
    [columnStyles]
  );

  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);

  useEffect(() => {
    setCustomColumns()
  }, [setCustomColumns])

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
    else renderRef.current = true;
  }, [handleSearchMemo])

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Corporate Workgroups</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DeleteWorkGroup
              selectedRows={selectedRows}
            />
            <PrimaryButton
              onClick={() => history.push('/account-management/work-groups/work-group')}
              iconProps={{
                iconName: 'Add',
              }}
              text="Create New Workgroup"
            />
          </Stack>
        </Stack>
        <SearchBar
          columns={[]}
          searchEnabled={false}
          onRefresh={() => handleSearch(false)}
        />
      </Stack>
      <InfiniteList
        loading={loading}
        items={transformedData}
        hasNextPage={pageInfo?.hasNextPage}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={async () => await handleSearch(true)}
        onSelectionChanged={setSelectedRows}
      />
    </>
  )
}
