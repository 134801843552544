import React, { useCallback, useState } from 'react';
import {
  IColumn,
  IDetailsRowBaseProps,
  Modal,
  PrimaryButton,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { loader } from 'graphql.macro';
import { CloseButton } from 'common/components/Buttons';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { InfiniteList } from 'common/components/InfiniteList';
import { columns } from './columns.data';
import { useCommonStyles } from 'common/styles';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import {
  InvoiceBatches,
  InvoiceBatchesVariables,
  InvoiceBatches_invoiceBatches_nodes,
} from './__generated__/InvoiceBatches';
import {
  InvoiceBatchDelete,
  InvoiceBatchDeleteVariables,
  InvoiceBatchDelete_invoiceBatchDelete_query_batchTransaction,
} from './__generated__/InvoiceBatchDelete';
import {
  EntityDeleteInput,
  InvoiceBatchDeleteInput,
  InvoiceBatchesOrderBy,
} from 'common/types/globalTypes';
import { useToasts } from 'react-toast-notifications';
import clsx from 'clsx';
import { BatchListOption } from 'postingTracker/batchEdit';
import { toOrderByVariable } from './utils';
import { ColumnData } from 'common/components/SearchBar';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection } from 'common/utils/commonTypes';

const INVOICE_BATCHES = loader('./InvoiceBatches.graphql');
const INVOICE_BATCHE_DELETE = loader('./InvoiceBatchDelete.graphql');

interface TransactionModalProps {
  isOpen: boolean;
  onDismiss?: () => void;
  batchTransactionId: string;
  batchDetails: BatchListOption;
  onRemove: (
    value: InvoiceBatchDelete_invoiceBatchDelete_query_batchTransaction
  ) => void;
}

export type InvoiceBatchRow = InvoiceBatches_invoiceBatches_nodes & {
  transactionType: string | null;
  name: string | null | undefined;
  date: string | null;
  description: string | null;
  invoiceNumber: string | null;
  amount: string | null;
  accountStampReference: string | null;
};

export const InvoiceBatchTransactionModal: React.FC<TransactionModalProps> = ({
  onDismiss,
  batchTransactionId,
  batchDetails,
  onRemove,
}) => {
  const { addToast } = useToasts();

  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [selectedRows, setSelectedRows] = useState<InvoiceBatchRow[]>([]);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);

  const {
    loading: invoiceBatchesLoading,
    data: invoiceBatches,
    variables: invoiceBatchVariables,
    refetch,
    networkStatus,
  } = useQuery<InvoiceBatches, InvoiceBatchesVariables>(INVOICE_BATCHES, {
    variables: {
      id: batchTransactionId,
      orderBy: [
        InvoiceBatchesOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
        InvoiceBatchesOrderBy.PRIMARY_KEY_ASC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [deleteBatchInvoice] = useMutation<
    InvoiceBatchDelete,
    InvoiceBatchDeleteVariables
  >(INVOICE_BATCHE_DELETE, { errorPolicy: 'all' });

  const canSelectItem = useCallback((item) => {
    return item?._isDeletable;
  }, []);

  const batchInvoiceDelete = async () => {
    const invoices = selectedRows.map((item) => {
      return {
        id: item.id,
        rowTimestamp: item._rowTimestamp,
      } as EntityDeleteInput;
    });
    const deleteInputEntities: InvoiceBatchDeleteInput = {
      batchTransactionId: batchDetails?.key?.toString()!,
      entityDelete: invoices,
    };
    const { errors } = await deleteBatchInvoice({
      variables: {
        input: deleteInputEntities,
        batchInputId: batchDetails?.key?.toString()!,
      },
      update: (cache, { data }) => {
        if (data?.invoiceBatchDelete?.query?.batchTransaction)
          onRemove(data.invoiceBatchDelete.query.batchTransaction);

        const deletedIds = data?.invoiceBatchDelete?.deletedEntities?.map(
          (entity) => entity?.id
        );
        if (deletedIds) {
          const filteredList = invoiceBatches?.invoiceBatches?.nodes.filter(
            (emp) => deletedIds.indexOf(emp.id) === -1
          );

          const newSum =
            filteredList?.reduce(
              (total, item) => total + Number(item.appliedAmount),
              0
            ) || 0;
          const newData: InvoiceBatches = {
            invoiceBatches: {
              pageInfo: invoiceBatches?.invoiceBatches?.pageInfo!,
              nodes: filteredList!,
              aggregates: {
                sum: {
                  appliedAmount: newSum.toString(),
                },
              },
            },
          };
          cache.writeQuery<InvoiceBatches, InvoiceBatchesVariables>({
            query: INVOICE_BATCHES,
            variables: invoiceBatchVariables,
            data: newData,
          });
        }
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
    }
  };

  const refetching =
    invoiceBatchesLoading && networkStatus !== NetworkStatus.fetchMore;

  let transformedData = refetching
    ? undefined
    : invoiceBatches?.invoiceBatches?.nodes.map(
      (inv) =>
      ({
        ...inv,
        name: inv?.invoice?.vendorReference,
        transactionType: inv?.invoiceBatchTransactionType?.transactionType,
        description: inv?.invoice?.description,
        date: inv?.invoice ? inv?.invoice?.invoiceDate : '',
        invoiceNumber: inv?.invoice?.invoiceNumber,
        amount: inv?.appliedAmount,
        accountStampReference:
          inv.invoice?._accountingStampTransactionReference,
      } as InvoiceBatchRow)
    );

  const _renderItemColumn = (
    item: InvoiceBatchRow | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (!item || !column) return undefined;

    const fieldContent = item[column.fieldName as keyof InvoiceBatchRow] as
      | string
      | null;
    switch (column.key) {
      case 'amount':
        return (
          <Stack verticalAlign="center" className={styles.onrenderColumnStack}>
            <AmountTextView
              className={styles.contentColumnAlignRight}
              variant="medium"
              value={fieldContent!}
            />
          </Stack>
        );

      case 'date':
        return (
          <Stack verticalAlign="center">
            {item.date && (
              <Text className={styles.contentColumnAlignRight}>
                {dateFormat(dateConvertions(item.date!))}
              </Text>
            )}
          </Stack>
        );

      default:
        return (
          <Stack verticalAlign="center">
            <Text>{fieldContent}</Text>
          </Stack>
        );
    }
  };

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const { appliedAmount } = {
        ...invoiceBatches?.invoiceBatches?.aggregates?.sum,
      };
      const fieldContent = column?.key === 'amount' ? appliedAmount : '';
      return (
        <AmountTextView
          variant="medium"
          className={clsx(
            styles.amountStack,
            styles.contentColumnAlignRight,
            commonStyles.colorThemePrimary
          )}
          value={fieldContent!}
        />
      );
    };

  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        await refetch({
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  return (
    <Modal isOpen isBlocking onDismiss={onDismiss}>
      <Stack className={styles.container}>
        <Sticky stickyPosition={StickyPositionType.Header}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={styles.stickyInsideStack}
          >
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Text variant="xLarge">Batch:</Text>
              <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
                {batchDetails?.description}
              </Text>
              <Text variant="xLarge">{`-  (${batchDetails?.isoCode})`}</Text>
            </Stack>

            <CloseButton onClick={() => onDismiss?.()} />
          </Stack>
        </Sticky>
        <InfiniteList
          loading={invoiceBatchesLoading}
          items={transformedData}
          compact
          hasNextPage={invoiceBatches?.invoiceBatches?.pageInfo.hasPreviousPage}
          showFooter={true}
          columns={gridColumns.filter((_column) => _column.isVisible)}
          onColumnHeaderClick={_onColumnClick}
          onRenderItemColumn={_renderItemColumn}
          onSelectionChanged={setSelectedRows}
          selectedRows={selectedRows}
          onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
          canSelectItem={canSelectItem}
        />
        {selectedRows.length !== 0 && (
          <Sticky stickyPosition={StickyPositionType.Footer}>
            <Stack
              horizontal
              horizontalAlign="space-between"
              className={styles.stickyInsideBottomStack}
            >
              <PrimaryButton
                onClick={batchInvoiceDelete}
                text="Remove Transaction"
              />
            </Stack>
          </Sticky>
        )}
      </Stack>
    </Modal>
  );
};
