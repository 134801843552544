import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexDirection: 'row',
      height: 50,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    groupName: {
      color: theme.palette.themePrimary,
    },
    groupSelected: {
      backgroundColor: theme.palette.neutralLighter,
    },
    linkLightHover: {
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    cardAccountName: {
      ':focus': {
        color: theme.palette.themeDark,
      },
    },
    active: {
      color: theme.palette.accent,
      ':focus': {
        color: theme.palette.accent,
      },
    },
    iconButtonColor: {
      color: theme.palette.neutralDark,
    },
    accountRowCell: {
      flex: 1,
      paddingLeft: 25,
      maxWidth: "25%"
    },
    rowCell: {
      flex: 1,
      paddingLeft: 20,
      maxWidth: "25%"
    }
  };
});
