import {
  ColumnActionsMode,
  IColumn,
  IDetailsColumnStyles,
} from '@fluentui/react';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const commonProps: Partial<IColumn> = {
  isResizable: true,
  columnActionsMode: ColumnActionsMode.disabled,
};

export const columns: IColumn[] = [
  {
    key: 'requesterName',
    name: 'Name',
    fieldName: 'requesterName',
    minWidth: 200,
    maxWidth: 350,
    ...commonProps,
  },
  {
    key: 'requestedDate',
    name: 'Date Requested',
    fieldName: 'requestedDate',
    minWidth: 150,
    maxWidth: 170,
    styles: rightAlignHeaderStyle,
    ...commonProps,
  },
  {
    key: 'amount1',
    name: 'Amount',
    fieldName: 'amount1',
    minWidth: 170,
    maxWidth: 200,
    styles: rightAlignHeaderStyle,
    ...commonProps,
  },
  {
    key: 'statusType',
    name: 'Status',
    fieldName: 'statusType.statusType',
    minWidth: 170,
    maxWidth: 200,
    ...commonProps,
  },
  {
    key: 'viewHierarchy',
    name: '',
    fieldName: 'viewHierarchy',
    minWidth: 40,
    maxWidth: 40,
    ...commonProps,
  },
];
