import { useMutation } from '@apollo/client';
import {
  ContextualMenu,
  DayOfWeek,
  DefaultButton,
  Label,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseButton } from 'common/components/Buttons';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import {
  FormHookDatePicker,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import {
  EntityEnvironmentalDelete,
  EntityEnvironmentalDeleteVariables,
} from 'common/graphql/__generated__/EntityEnvironmentalDelete';
import {
  EntityEnvironmentalUpdate,
  EntityEnvironmentalUpdateVariables,
} from 'common/graphql/__generated__/EntityEnvironmentalUpdate';
import {
  EntityEnvironmentalItemUpdateTypeInput,
  TransactionLayout,
} from 'common/types/globalTypes';
import { dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { CategoryOptionsType } from '..';
import { EnvironmentalItemsView } from '../EnvironmentalItemsView';
import {
  EntityEnvironmentalInvoiceCreate,
  EntityEnvironmentalInvoiceCreateVariables,
} from '../__generated__/EntityEnvironmentalInvoiceCreate';
import { validationSchema } from '../carbonAccountingValidation';
import { CarbonAccountingValues } from '../types';
import { useStyles } from './index.styles';
import { getDefaultValues } from './utils';
import { InvoiceDetails_invoice_entityEnvironmentalsByEntityId } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
const ENTITY_ENVIRONMENT_INVOICE_CREATE = loader(
  '../EntityEnvironmentalInvoiceCreate.graphql'
);
const ENTITY_ENVIRONMENT_DELETE = loader(
  '../../../../../common/graphql/EntityEnvironmentalDelete.graphql'
);
const ENTITY_ENVIRONMENT_UPDATE = loader(
  '../../../../../common/graphql/EntityEnvironmentalUpdate.graphql'
);
const CONFIRM_DELETE_DIALOG_TITLE = 'Please confirm the action';
const CONFIRM_DELETE_DIALOG_SUBTEXT =
  'This will remove the accounting from this transaction';

interface FormModalProps {
  entityId: string;
  isEnvironmentalsExist: boolean;
  categoryOptions: CategoryOptionsType[];
  invoiceDetails:
    | InvoiceDetails_invoice_entityEnvironmentalsByEntityId
    | undefined;
  environmentDeletedUpdated: (isdeleted: boolean) => void;
  setOpen: (open: boolean) => void;
}

export const FormModal: React.FC<FormModalProps> = ({
  entityId,
  isEnvironmentalsExist,
  categoryOptions,
  invoiceDetails,
  environmentDeletedUpdated,
  setOpen,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [categoryId, setCategoryId] = useState<string | undefined>();
  const [layoutType, setLayoutType] = useState<TransactionLayout | undefined>();
  const [categoryName, setCategoryName] = React.useState<string | undefined>();
  const [hideConfirmDialog, { toggle: toggleConfirmDialog }] = useBoolean(true);

  const [
    entityEnvironmentalInvoiceCreate,
    { loading: entityEnvironmentalInvoiceCreateLoading },
  ] = useMutation<
    EntityEnvironmentalInvoiceCreate,
    EntityEnvironmentalInvoiceCreateVariables
  >(ENTITY_ENVIRONMENT_INVOICE_CREATE, { errorPolicy: 'all' });

  const [deleteEnvironment, { loading: loadingDeleteEnvironment }] =
    useMutation<EntityEnvironmentalDelete, EntityEnvironmentalDeleteVariables>(
      ENTITY_ENVIRONMENT_DELETE,
      { errorPolicy: 'all' }
    );

  const [updateEnvironment, { loading: updateEnvironmentLoading }] =
    useMutation<EntityEnvironmentalUpdate, EntityEnvironmentalUpdateVariables>(
      ENTITY_ENVIRONMENT_UPDATE,
      { errorPolicy: 'all' }
    );

  const formMethods = useForm<CarbonAccountingValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const {
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isDirty, isSubmitting },
  } = { ...formMethods };

  const entityEnvironmentalDelete = async () => {
    const { errors } = await deleteEnvironment({
      variables: {
        input: {
          entityDelete: [
            {
              id: invoiceDetails?.nodes[0].id!,
              rowTimestamp: invoiceDetails?.nodes[0]._rowTimestamp!,
            },
          ],
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Environmental deleted successfully', { appearance: 'success' });
      setCategoryId(undefined);
      setOpen(false);
      environmentDeletedUpdated(true);
    }
  };

  const onHandleSubmit = async (values: CarbonAccountingValues) => {
    if (isEnvironmentalsExist && categoryId) {
      //Map the initial values of Environmental Items row to the EntityEnvironmentalItemUpdateTypeInput type array
      const itemUpdateFilter: EntityEnvironmentalItemUpdateTypeInput[] = [];
      if (!!values.entityEnvironmentalItems?.length) {
        values.entityEnvironmentalItems?.map((item) => {
          if (item.id) {
            const {
              id,
              isIntegerFormat,
              isFloatFormat,
              isAmountFormat,
              // environmentalImpactType,
              ...itemFields
            } = { ...item };
            const environmentalItemObj: EntityEnvironmentalItemUpdateTypeInput =
              {
                id: item.id,
                rowTimestamp: invoiceDetails?.nodes[0]._rowTimestamp,
                entityEnvironmentalItemPatch: {
                  ...itemFields,
                },
              };

            itemUpdateFilter.push(environmentalItemObj);
          }
        });
      }
      const { errors } = await updateEnvironment({
        variables: {
          input: {
            id: invoiceDetails?.nodes[0].id!,
            rowTimestamp: invoiceDetails?.nodes.length
              ? invoiceDetails.nodes[0]._rowTimestamp!
              : '',
            entityEnvironmentalPatch: {
              description: values.description,
              startDate: values.startDate ? dateFormat(values.startDate) : null,
              endDate: values.endDate ? dateFormat(values.endDate) : null,
            },
            entityEnvironmentalItemsUpdate: itemUpdateFilter,
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Environmental updated successfully', {
          appearance: 'success',
        });
        setOpen(false);
        environmentDeletedUpdated(true);
      }
    } else {
      const { errors } = await entityEnvironmentalInvoiceCreate({
        variables: {
          input: {
            entityEnvironmental: {
              description: values.description,
              entityId: entityId,
              environmentalMetricId: values.environmentalMetricId!,
              startDate: values.startDate ? dateFormat(values.startDate) : null,
              endDate: values.endDate ? dateFormat(values.endDate) : null,
            },
            entityEnvironmentalItems: values.entityEnvironmentalItems?.map(
              ({ isIntegerFormat, isFloatFormat, isAmountFormat, ...rest }) =>
                rest
            ),
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Environmental added successfully', { appearance: 'success' });
        setOpen(false);
        environmentDeletedUpdated(true);
      }
    }
  };

  useEffect(() => {
    if (invoiceDetails?.nodes.length! > 0) {
      setCategoryId(invoiceDetails?.nodes[0].environmentalMetricId!);
      setLayoutType(invoiceDetails?.nodes[0].environmentalMetric?.layoutType!);
      setCategoryName(
        invoiceDetails?.nodes[0].environmentalMetric?.environmentalMetric!
      );
    }
  }, [invoiceDetails]);

  useEffect(() => {
    const defaultValues = getDefaultValues({
      invoice: invoiceDetails,
    });
    reset(defaultValues);
    trigger();
  }, [invoiceDetails, reset, trigger]);

  return (
    <Modal
      isOpen
      styles={{
        scrollableContent: {
          overflowY: 'none',
        },
      }}
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
    >
      <div className={styles.header}>
        <Stack tokens={{ childrenGap: 10 }} horizontal>
          <Text variant={'xLarge'}>Carbon accounting</Text>
          {isEnvironmentalsExist && categoryName && (
            <Text variant={'xLarge'} className={styles.accountingType}>
              {categoryName}
            </Text>
          )}
        </Stack>
        <Stack>
          <CloseButton
            onClick={() => {
              reset();
              setOpen(false);
            }}
          />
        </Stack>
      </div>
      <FormProvider {...formMethods}>
        <Stack className={styles.mainContainer} tokens={{ childrenGap: 20 }}>
          <Stack className={styles.formContainer} tokens={{ childrenGap: 20 }}>
            <FormHookDropdown
              label="Category"
              placeholder="Select"
              options={categoryOptions}
              name="environmentalMetricId"
              onChange={(_event, option) => {
                const selectedOption = option as CategoryOptionsType;
                setCategoryId(selectedOption.key.toString());
                setLayoutType(selectedOption.layoutType!);
              }}
              disabled={isEnvironmentalsExist}
              required
            />
            <Stack>
              <Label>Time period</Label>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item grow={1}>
                  <FormHookDatePicker
                    name="startDate"
                    placeholder="From date..."
                    ariaLabel="From date"
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                  />
                </Stack.Item>
                <Stack.Item grow={1}>
                  <FormHookDatePicker
                    name="endDate"
                    placeholder="To date..."
                    ariaLabel="To date"
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday
                  />
                </Stack.Item>
              </Stack>
            </Stack>
            <FormHookTextField
              name="description"
              label="Comment"
              placeholder="Enter a comment"
            />
          </Stack>
          {categoryId && layoutType && (
            <EnvironmentalItemsView
              categoryId={categoryId}
              layoutType={layoutType}
              isEnvironmentalsExist={isEnvironmentalsExist}
            />
          )}
          {(entityEnvironmentalInvoiceCreateLoading ||
            loadingDeleteEnvironment ||
            updateEnvironmentLoading) && <ProgressIndicator />}
          <ConfirmDialog
            hidden={hideConfirmDialog}
            title={CONFIRM_DELETE_DIALOG_TITLE}
            subText={CONFIRM_DELETE_DIALOG_SUBTEXT}
            onDismiss={toggleConfirmDialog}
            onConfirm={() => {
              toggleConfirmDialog();
              entityEnvironmentalDelete();
            }}
          />
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 20 }}>
            {!!invoiceDetails?.nodes.length && (
              <PrimaryButton
                onClick={() => toggleConfirmDialog()}
                text="Delete"
              />
            )}
            <PrimaryButton
              disabled={
                !isDirty || Object.keys(errors).length > 0 || isSubmitting
              }
              onClick={handleSubmit(onHandleSubmit)}
              text="Save"
            />
            <DefaultButton
              onClick={() => {
                reset();
                setOpen(false);
              }}
              text="Cancel"
            />
          </Stack>
        </Stack>
      </FormProvider>
    </Modal>
  );
};
