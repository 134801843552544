import React from 'react';
import { BlockBlobClient } from '@azure/storage-blob';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { UploadFiles } from 'common/components/UploadFiles';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { useFormikContext } from 'formik';
import { BatchTransaction_batchTransaction } from '../../../__generated__/BatchTransaction';
import { BatchTransactionUploadDocument, BatchTransactionUploadDocumentVariables, BatchTransactionUploadDocument_batchTransactionUploadDocument } from './__generated__/BatchTransactionUploadDocument'
import { BatchTransactionAvailableDocumentTypes } from '../../../__generated__/BatchTransactionAvailableDocumentTypes';
const BATCH_TRANSACTION_AVAILABLE_DOCUMENTTYPES = loader('./BatchTransactionUploadDocument.graphql');
interface UploadFormProps {
  availableDocumentTypes: BatchTransactionAvailableDocumentTypes | undefined;
  batchTransaction?: BatchTransaction_batchTransaction;
  onUpload?: (
    fileSelected: File,
    documentData: BatchTransactionUploadDocument_batchTransactionUploadDocument,
    toastId: string
  ) => void;
}
export const UploadForm: React.FC<UploadFormProps> = ({
  availableDocumentTypes,
  batchTransaction,
  onUpload,
}) => {
  const { dirty } = useFormikContext();
  const [uploadDocument] = useMutation<
    BatchTransactionUploadDocument,
    BatchTransactionUploadDocumentVariables
  >(BATCH_TRANSACTION_AVAILABLE_DOCUMENTTYPES, { errorPolicy: 'all' });
  const { updateToast, addToast } = useToasts();
  const { isDraft, id } = { ...batchTransaction };
  const documentTypeOptions =
    availableDocumentTypes?.batchTransactionAvailableDocumentTypes?.nodes.map(
      (doctype) =>
      ({
        key: doctype.id,
        text: doctype.documentType || '',
        isAccountingDocument: doctype.isAccountingDocument,
        title:
          isDraft && doctype.isAccountingDocument
            ? 'Upload prohibited during/after the approval process'
            : undefined,
        disabled: (!isDraft && doctype.isAccountingDocument) || undefined,
      } as DocumentTypeOption)
    ) || [];
  return (
    <UploadFiles
      documentTypes={documentTypeOptions!}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, filSelected) => {
          filSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });
            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                batchTransactionId: id!,
              },
            });
            if (uploadMutationResults.errors) {
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });
            }
            if (
              uploadMutationResults.data?.batchTransactionUploadDocument?.document &&
              uploadMutationResults.data.batchTransactionUploadDocument?.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data.batchTransactionUploadDocument,
                toastId!
              );
              const client = new BlockBlobClient(
                uploadMutationResults.data.batchTransactionUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
      disableUpload={dirty}
    />
  );
};
