import {
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack
} from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from "./index.styles";

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection3 = (): JSX.Element => {
  const styles = useStyles();
  const rows = new Array(4).fill([]);
  return (
    <div className={styles.fullWidthContainer}>
      {
        rows.map((_ele, index) => {
          return (
            <Stack
              key={index}
              className={styles.fullWidth}
            >
              <ShimmerElementsGroup
                width="100%"
                shimmerElements={[
                  { type: ShimmerElementType.line, height: 40, width: '50%' },
                  { type: ShimmerElementType.gap, height: 40, width: 20 },
                  { type: ShimmerElementType.line, height: 40, width: '50%' },
                ]}
              />
              <ShimmerElementsGroup
                width="100%"
                shimmerElements={[
                  { type: ShimmerElementType.gap, height: 50, width: '100%' },
                ]}
              />
            </Stack>
          )
        }
        )
      }
    </div>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Stack className={clsx(styles.containerPadding, styles.containerSetting)}>
        <Shimmer customElementsGroup={ShimmerSection()} />
      </Stack>
      <Stack className={styles.containerPadding}>
        <Shimmer customElementsGroup={ShimmerSection3()} />
      </Stack>
    </React.Fragment>
  );
};
