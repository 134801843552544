import { DayOfWeek, IDropdownOption, Stack } from '@fluentui/react';
import { FormikTextField } from 'common/components';
import {
  FormikDropdown,
  AmountTextField,
} from 'common/components/FormikFields';
import { AutoCompleteFormikTextField } from 'common/components/FormikFields/AutoCompleteFormikTextField';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import { CompanyCurrencies } from 'common/graphql/__generated__/CompanyCurrencies';
import { formatDropdownOptions } from 'common/utils';
import { onFormatDate } from 'common/utils/dateFormats';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentTypeOption } from '..';
import { EntityDocumentValues } from '../types';
import { useStyles } from './index.styles';
import { getFileTypesList } from './utils';
interface FormViewProps {
  currencyData: CompanyCurrencies;
  documentType: DocumentTypeOption;
  onInputFieldsDisabled?: (data: boolean) => void;
  setFileTypesAllowed: React.Dispatch<React.SetStateAction<string[]>>;
}
export interface dataExtractModelOptionsType extends IDropdownOption {
  key: string | number;
  text: string;
  pdfAllowed: boolean | undefined | null;
  imageAllowed: boolean | undefined | null;
  textAllowed: boolean | undefined | null;
}
export const FormView: React.FC<FormViewProps> = ({
  documentType,
  currencyData,
  onInputFieldsDisabled,
  setFileTypesAllowed,
}) => {
  const styles = useStyles();
  const { values, setFieldValue } = useFormikContext<EntityDocumentValues>();
  const [showDataExtractDropdown, setShowDataExtractDropdown] = useState(false);
  const [dataExtractModelOptions, setDataExtractModelOptions] = useState<
    dataExtractModelOptionsType[]
  >([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const { isAccountingDocument } = { ...documentType };

  const currencyTypes = formatDropdownOptions(
    currencyData?.companyCurrencies?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) => `${item.isoCode}-${item.name!}`,
      includeAll: false,
    }
  );
  const getDataExtractModelOptions = () => {
    const extractionTypesArray =
      documentType?.extractionTypes?.nodes.map((ele) => ({
        key: ele.id,
        text: ele.extractionType,
        pdfAllowed: ele.isPdfDocument,
        imageAllowed: ele.isImageDocument,
        textAllowed: ele.isRawTextDocument,
      })) || [];
    if (extractionTypesArray.length > 0) {
      setDataExtractModelOptions(extractionTypesArray);
      setShowDataExtractDropdown(true);
    } else {
      setShowDataExtractDropdown(false);
      setDataExtractModelOptions([]);
    }
  };

  const getDataExtractModelOptionsMemo = useCallback(
    getDataExtractModelOptions,
    [values.entityDocumentTypeId]
  );

  useEffect(() => {
    getDataExtractModelOptionsMemo();
  }, [getDataExtractModelOptionsMemo, values.entityDocumentTypeId]);

  useEffect(() => {
    if (values.extractionTypeId) {
      setIsDisabled(true);
      onInputFieldsDisabled?.(true);
    } else {
      setIsDisabled(false);
      onInputFieldsDisabled?.(false);
    }
  }, [values.extractionTypeId, onInputFieldsDisabled]);

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      {!isAccountingDocument && (
        <FormikTextField
          name="_documentContents"
          label="Contents"
          placeholder="Enter contents"
        />
      )}
      {showDataExtractDropdown && (
        <Stack>
          <FormikDropdown
            placeholder="Select"
            label="Data Extraction Template"
            options={dataExtractModelOptions}
            name="extractionTypeId"
            onChange={(_event, option) => {
              const selectedOption = option as dataExtractModelOptionsType;
              const fileTypesList = getFileTypesList(selectedOption);
              setFileTypesAllowed(fileTypesList);
              setFieldValue('extractionTypeId', selectedOption?.key);
            }}
          />
        </Stack>
      )}
      {isAccountingDocument && (
        <Stack tokens={{ childrenGap: 20 }}>
          <AutoCompleteFormikTextField
            name="indexName"
            label="Name"
            placeholder="Enter name"
            documentTypeId={Number(documentType?.key)}
            id={'indexName'}
            lookUpCalloutWidth={550}
            disabled={isDisabled}
          />
          <FormikTextField
            name="indexDescription"
            label="Description"
            placeholder="Enter description"
            disabled={isDisabled}
          />
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={styles.flexHalf}>
              <FormikTextField
                name="indexReferenceNumber"
                label="Reference Number"
                placeholder={
                  documentType?.text
                    ? `Enter ${documentType.text.toLowerCase()} number`
                    : 'Enter reference number'
                }
                disabled={isDisabled}
              />
            </Stack.Item>
            <Stack.Item className={styles.flexHalf}>
              <FormikDatePicker
                name="indexTransactionDate"
                onSelectDate={(date) => {
                  if (date)
                    setFieldValue('indexTransactionDate', onFormatDate(date));
                }}
                placeholder="Select date"
                ariaLabel="Document Date"
                label="Date"
                firstDayOfWeek={DayOfWeek.Monday}
                showWeekNumbers
                firstWeekOfYear={1}
                showMonthPickerAsOverlay
                showGoToToday
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={styles.flexHalf}>
              <AmountTextField
                name="indexAmount"
                placeholder="Enter amount"
                label="Amount"
                disabled={isDisabled}
                fixedDecimalScale
                decimalScale={2}
                allowNegative
              />
            </Stack.Item>
            <Stack.Item className={styles.flexHalf}>
              <FormikDropdown
                label="Currency"
                placeholder="Select"
                options={currencyTypes!}
                name="indexCurrencyId"
                disabled={isDisabled}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      )}
      <FormikTextField
        name="comment"
        label="Comment"
        placeholder="Enter comment"
        disabled={isDisabled}
      />
    </Stack>
  );
};
