import {
  RequestorConditionsValues,
  ApprovalBranchViewRowValues,
  ApprovalLevelViewRowValues,
  ApprovalValues,
} from './types';
export const REQUESTOR_INITIAL_VALUES: RequestorConditionsValues = {
  id: null,
  _isDeletable: null,
  _isUpdatable: null,
  _rowTimestamp: null,
  approvalId: null,
  approval: null,
  importanceLevel: null,
  description: null,
  startAmountTotal: null,
  endAmountTotal: null,
  requesterCurrencyId: null,
  amountConversionTypeId: null,
  requesterUserOccupationTitleId: null,
  requesterDepartmentId: null,
  requesterDepartmentTypeId: null,
  requesterDepartmentGroupId: null,
  requesterSecureRowLevelId: null,
  startElapsedDays: null,
  endElapsedDays: null,
  requesterTagId: null,
  requesterUserRankId: null,
  requesterCompanyId: null,
  requesterBusinessUnitId: null,
  requesterUserId: null,
  requesterPurchaseOrderTypeId: null,
  isAutoApprovedEligible: null,
  isAndCondition: null,
};
export const APPROVAL_BRANCH_INITIAL_VALUES: ApprovalBranchViewRowValues = {
  id: null,
  branchOrder: null,
  description: null,
  _isDeletable: null,
  _isUpdatable: null,
  userOccupation: null,
  _rowTimestamp: null,
  userRank: null,
  budgetLimitAmount: null,
  budgetLimitPercent: null,
  isOverBudgetSelector: null,
  isBusinessUnitSelector: null,
  isUserGroupSelector: null,
  isProjectSelector: null,
  isDepartmentSelector: null,
  transactionStartAmountTotal: null,
  transactionEndAmountTotal: null,
  transactionAmountConversionTypeId: null,
  approverTransactionTypeId:null,
  transactionBusinessUnitId: null,
  transactionDepartmentId: null,
  transactionDepartmentGroupId: null,
  isTransactionNotCondition: null,
  approverDelayDays: null,
  requesterUserId:null,
  approverDelayHours: null,
  approverDelayStatusTypeId: null,
};
export const APPROVAL_LEVELS_INITIAL_VALUES: ApprovalLevelViewRowValues = {
  id: null,
  approvalLevel: null,
  name: null,
  description: null,
  _isDeletable: null,
  _isUpdatable: null,
  _rowTimestamp: null,
  requiredBranchApprovalCount: null,
  isApprovedByConsensus: null,
  isSingleDeclineConsensusInvoked: null,
  isRejectOnUnderApprovalCount: null,
  isUsedForAdditionalDepartments: null,
  approvalBranches: [{ ...APPROVAL_BRANCH_INITIAL_VALUES }],
};
export const APPROVAL_SETUP_INITIAL_VALUES: ApprovalValues = {
  approvalTypeId: null,
  priorityNumber: null,
  name: null,
  description: null,
  approvalType: null,
  isAddApproversEligible: false,
  isDelayedApproverAllowed: false,
  notesByEntityId: null,
  entityDocumentsByEntityId: null,
  approvalSetupHistoriesByEntityId: null,
  approvalPhaseTypeId: null,
  approvalPhaseType: null,
  approvalTrees: [{ ...APPROVAL_LEVELS_INITIAL_VALUES }],
  approvalSelectionConditions: [{ ...REQUESTOR_INITIAL_VALUES }],
};
