export interface FontStyleProps {
  // fontUrl: string;
  fontStyle: string;
}

export const fontOptionsStyles: FontStyleProps[] = [
  { fontStyle: 'Caveat' },
  { fontStyle: 'Indie Flower' },
  { fontStyle: 'Shadows Into Light' },
  { fontStyle: 'Pushster' },
  { fontStyle: 'Pacifico' },
  { fontStyle: 'Cookie' },
  { fontStyle: 'Dancing Script' },
  { fontStyle: 'Yesteryear' },
  { fontStyle: 'Mrs Saint Delafield' },
  { fontStyle: 'Arizonia' },
  { fontStyle: 'Italianno' },
  { fontStyle: 'Great Vibes' },
];
