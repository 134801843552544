import {
  DayOfWeek,
  IconButton,
  IDropdownOption,
  IDropdownProps,
  Label,
  Stack,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import {
  FormHookAmount,
  FormHookDatePicker,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import {
  PurchaseOrderCommonData,
  PurchaseOrderCommonData_companyBusinessUnits_nodes_companyAddress,
  PurchaseOrderCommonData_companyPurchaseOrderTypes_nodes_companyPurchaseOrderItemTypes_nodes,
} from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BusinessUnitAddress } from './BusinessUnitAddress';
import { useStyles } from './index.styles';
import { SupplierAutoComplete } from './SupplierAutoComplete';

export type PurchaseOrderItemProps =
  PurchaseOrderCommonData_companyPurchaseOrderTypes_nodes_companyPurchaseOrderItemTypes_nodes;

export type CompanyAddress =
  PurchaseOrderCommonData_companyBusinessUnits_nodes_companyAddress;

interface BasicFormProps {
  commonData: PurchaseOrderCommonData | undefined;
  inputsDisabled: boolean;
  userDefaultsData: UserDefaults_userDefaults_nodes[];
  isNew: boolean;
  purchaseOrderData: PurchaseOrder | undefined;
  submitBasicForm: () => void;
  onTypeChange: (value: PurchaseOrderItemProps[]) => void;
}

interface PurchaseOrderTypeOptions extends IDropdownOption {
  key: string | number;
  text: string;
  isDefault: boolean | null;
  purchaseOrderItemTypes: PurchaseOrderItemProps[] | undefined | null;
}

interface BusinessUnitOptions extends IDropdownOption {
  key: string | number;
  text: string;
  companyAddress: CompanyAddress | null;
}

interface CurrencyTypeOptions extends IDropdownOption {
  key: string | number;
  text: string;
  isoCode: string | null;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  commonData,
  onTypeChange,
  inputsDisabled,
  userDefaultsData,
  isNew,
  purchaseOrderData,
}) => {
  const style = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCompanyAddress, setSelectedCompanyAddress] =
    useState<CompanyAddress | null>(null);
  const { setValue, trigger, control } = useFormContext<PurchaseOrderValues>();

  const labelId: string = useId('label');
  const calloutId = useId(`address`);

  const {
    companyPurchaseOrderTypes,
    companyBusinessUnits,
    companyDepartments,
    companyCurrencies,
  } = { ...commonData };

  const purchaseOrderTypesOptions: PurchaseOrderTypeOptions[] =
    companyPurchaseOrderTypes?.nodes.map((item) => ({
      key: item.purchaseOrderTypeId!,
      text: item.purchaseOrderType || '',
      isDefault: item.isDefault,
      purchaseOrderItemTypes: item.companyPurchaseOrderItemTypes.nodes,
    })) || [];

  const purchaseOrderTypeId = useWatch({
    name: 'purchaseOrderTypeId',
    control,
  });

  const setPurchaseOrderTypes = useCallback(() => {
    const selectedPurchaseOrderType = purchaseOrderTypesOptions.find(
      (item) => item.key === purchaseOrderTypeId
    );
    if (selectedPurchaseOrderType)
      onTypeChange(selectedPurchaseOrderType.purchaseOrderItemTypes || []);
  }, [onTypeChange, purchaseOrderTypeId, purchaseOrderTypesOptions]);

  useEffect(() => {
    setPurchaseOrderTypes();
  }, [setPurchaseOrderTypes, purchaseOrderTypesOptions]);

  const businessUnitOptions: BusinessUnitOptions[] =
    companyBusinessUnits?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
      companyAddress: item.companyAddress,
    })) || [];

  const departmentOptions =
    companyDepartments?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
      disabled: !item._isPoSelectable,

      ...item,
    })) || [];

  const currencyOptions: CurrencyTypeOptions[] =
    companyCurrencies?.nodes.map((item) => ({
      key: item.id,
      text: item.isoCode! + '-' + item.name || '',
      isoCode: item.isoCode,
    })) || [];

  const { businessUnitId, businessUnit, currencyId, departmentId } = {
    ...userDefaultsData[0],
  };

  const setUserDefaultValues = async (isDefault: boolean) => {
    if (isNew && userDefaultsData && isDefault) {
      await setValue('businessUnitId', businessUnitId);
      await setValue('currencyId', currencyId);
      await setValue('departmentId', departmentId);
      if (businessUnit?.companyAddress)
        setSelectedCompanyAddress(businessUnit?.companyAddress);
      trigger();
    }
  };

  const onRenderLabel = (props: IDropdownProps | undefined): JSX.Element => {
    return (
      <>
        <Stack horizontal verticalAlign="center">
          {selectedCompanyAddress && (
            <IconButton
              iconProps={{ iconName: 'Info' }}
              onMouseEnter={() => setIsVisible(true)}
              onMouseLeave={() => setIsVisible(false)}
              id={calloutId}
            />
          )}
          <Label>{props?.label}</Label>
        </Stack>
      </>
    );
  };
  const supplier = purchaseOrderData?.purchaseOrder?.supplier || null;

  useEffect(() => {
    if (!isNew)
      setSelectedCompanyAddress(
        purchaseOrderData?.purchaseOrder?.businessUnit?.companyAddress || null
      );
  }, [purchaseOrderData, isNew]);

  useEffect(() => {
    if (isNew && userDefaultsData) {
      const { businessUnit, defaultPurchaseOrderTypeId } = {
        ...userDefaultsData[0],
      };
      if (defaultPurchaseOrderTypeId) {
        if (businessUnit?.companyAddress)
          setSelectedCompanyAddress(businessUnit?.companyAddress);
      }
    }
  }, [userDefaultsData, isNew]);

  return (
    <Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 50 }}
        className={style.mainContainer}
      >
        <Stack grow={4} tokens={{ childrenGap: 20 }}>
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
              padding: '20px 0px 0px 0px',
            }}
          >
            <Stack.Item grow={1}>
              <FormHookDropdown
                label="Type"
                placeholder="Select"
                options={purchaseOrderTypesOptions}
                name="purchaseOrderTypeId"
                required
                onChange={(_, option) => {
                  const newOption = option as PurchaseOrderTypeOptions;
                  onTypeChange(newOption.purchaseOrderItemTypes || []);
                  setUserDefaultValues(!!newOption.isDefault);
                }}
                disabled={inputsDisabled}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              {isVisible && (
                <BusinessUnitAddress
                  calloutId={calloutId}
                  companyAddress={selectedCompanyAddress}
                  onCalloutDismiss={() => setIsVisible(false)}
                />
              )}
              <FormHookDropdown
                label="Business Unit"
                placeholder="Select"
                options={businessUnitOptions}
                name="businessUnitId"
                required
                disabled={inputsDisabled}
                onRenderLabel={onRenderLabel}
                onChange={(_, businessUnit) => {
                  const selectedOption = businessUnit as BusinessUnitOptions;
                  setSelectedCompanyAddress(selectedOption.companyAddress);
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <SupplierAutoComplete
              purchaseOrderId={purchaseOrderData?.purchaseOrder?.id}
              name="supplierId"
              label="Supplier"
              placeholder="Supplier"
              disabled={inputsDisabled}
              lookUpCalloutWidth={400}
              id={'supplierFormikField'}
              aria-labelledby={labelId}
              showAddIcon
              supplier={supplier}
              required
            />
            <FormHookTextField
              name="reason"
              label="Special Instructions"
              multiline
              rows={3}
              disabled={inputsDisabled}
            />
          </Stack>
          {!isNew && (
            <Stack tokens={{ childrenGap: 10 }}>
              <FormHookTextField
                name="description"
                label="Order Description"
                disabled={inputsDisabled}
              />
            </Stack>
          )}
        </Stack>
        <Stack
          grow={2}
          tokens={{
            childrenGap: 20,
          }}
        >
          <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
            <FormHookDropdown
              label="Department"
              placeholder="Select"
              options={departmentOptions}
              name="departmentId"
              required
              disabled={inputsDisabled}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <FormHookDatePicker
              name="purchaseOrderDate"
              placeholder="Select a date"
              ariaLabel="Invoice date"
              label="Order Date"
              firstDayOfWeek={DayOfWeek.Sunday}
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday
              isRequired
              disabled={inputsDisabled}
            />
            <FormHookDropdown
              label="Currency"
              placeholder="Select"
              options={currencyOptions}
              name="currencyId"
              required
              disabled={inputsDisabled}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 20 }}>
            <FormHookAmount
              name="controlTotalAmount"
              label="Order Total"
              placeholder="Order Total"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={true}
              disabled
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
