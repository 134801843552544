import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  renderAccountingOptions: {
    marginTop: 10,
    marginBottom: 20,
  },
  accountingRowMargin: {
    marginTop: '5px !important',
  },
  scrollboxWrapper: {
    width: '100%',
    overflowX: 'auto',
    marginTop: 20,
  },
  width120: {
    width: 120,
  },
  width150: {
    width: 150,
  },
  width180: {
    width: 180,
  },
}));
