import React, { useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  Modal,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { Form, Formik } from 'formik';
import { CATEGORY_METRIC, TAG_CATEGORY_INITIAL_VALUES } from './constant';
import { validationSchema } from './validations';
import { TagCategoryValues } from './types';
import { loader } from 'graphql.macro';
import {
  TagCategoryCreate,
  TagCategoryCreateVariables,
  TagCategoryCreate_tagCategoryCreate_tagCategory,
} from './__generated__/TagCategoryCreate';
import { BasicForm } from './BasicForm';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { useLazyQuery, useMutation } from '@apollo/client';
import { TagSelection } from 'common/graphql/__generated__/TagSelection';
import { NoDataView } from 'common/components/DataPlaceholders';
import { FormikMultiSelectTags } from 'common/components/FormikMultiSelectTags';
import { useToasts } from 'react-toast-notifications';
import {
  EntityDeleteInput,
  TagCategoryInput,
  TagCategoryItemInput,
  TagCategoryPatch,
} from 'common/types/globalTypes';
import {
  TagCategories,
  TagCategoriesVariables,
} from '../list/__generated__/TagCategories';
import {
  TagCategoryUpdate,
  TagCategoryUpdateVariables,
} from './__generated__/TagCategoryUpdate';
const _ = require('lodash');
const TAG_SELECTION = loader('../../../../common/graphql/TagSelection.graphql');
const TAG_CATEGORIES_LIST = loader('../list/TagCategories.graphql');

const TAG_CATEGORY_CREATE = loader('./TagCategoryCreate.graphql');
const TAG_CATEGORY_UPDATE = loader('./TagCategoryUpdate.graphql');

interface TagFormProps {
  tagData: TagCategoryCreate_tagCategoryCreate_tagCategory | null;
  visible: boolean;
  onDismiss: () => void;
  isNew: boolean;
}

export const CreateTagCategoryForm: React.FC<TagFormProps> = ({
  visible,
  onDismiss,
  tagData,
  isNew,
}) => {
  const styles = useStyles();
  const [showTags, setShowTags] = useState(false);
  const toggleShowTags = () => setShowTags((prevState) => !prevState);
  const { addToast } = useToasts();

  let initialValues: TagCategoryValues = TAG_CATEGORY_INITIAL_VALUES;

  if (!isNew && tagData)
    initialValues = {
      name: tagData.name,
      description: tagData.description,
      tagCategoryItems: tagData.tagCategoryItemsByTagCategoryId.nodes.map(
        (tag) => ({ tagId: tag.tagId })
      ),
      metrics: _.pick(tagData, Object.keys(CATEGORY_METRIC)),
    };

  const [
    getTagSelection,
    { data: tagSelectionData, loading: tagSelectionLoading },
  ] = useLazyQuery<TagSelection>(TAG_SELECTION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [createTagCategory, { loading: tagCategoryCreateLoading }] =
    useMutation<TagCategoryCreate, TagCategoryCreateVariables>(
      TAG_CATEGORY_CREATE,
      {
        errorPolicy: 'all',
      }
    );

  const [updateTagCategory, { loading: tagCategoryUpdateLoading }] =
    useMutation<TagCategoryUpdate, TagCategoryUpdateVariables>(
      TAG_CATEGORY_UPDATE,
      {
        errorPolicy: 'all',
      }
    );

  const headerTitle = isNew ? 'Create Tag Category' : 'Update Tag Category';

  const getSelectionMemo = useCallback(getTagSelection, [showTags]);

  useEffect(() => getSelectionMemo(), [getSelectionMemo]);

  const handleSubmit = async (values: TagCategoryValues) => {
    const { tagCategoryItems, metrics, ...tagCategory } = values;

    if (isNew) {
      const { errors } = await createTagCategory({
        variables: {
          input: {
            tagCategory: {
              description: tagCategory.description,
              name: tagCategory.name,
              ...metrics,
            } as TagCategoryInput,
            tagCategoryItems: tagCategoryItems?.map(
              ({ ...tag }) => tag as TagCategoryItemInput
            ),
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<
            TagCategories,
            TagCategoriesVariables
          >({
            query: TAG_CATEGORIES_LIST,
          });

          if (cacheData) {
            const updatedData: TagCategories = {
              tagCategories: {
                ...cacheData?.tagCategories!,
                nodes: [
                  data?.tagCategoryCreate?.tagCategory!,
                  ...cacheData?.tagCategories?.nodes!,
                ],
                pageInfo: cacheData?.tagCategories?.pageInfo!,
                totalCount: cacheData?.tagCategories?.totalCount! + 1,
              },
            };
            cache.writeQuery<TagCategories, TagCategoriesVariables>({
              query: TAG_CATEGORIES_LIST,
              data: updatedData,
            });
          }
        },
      });
      if (!errors)
        addToast('Tag category created successfully.', {
          appearance: 'success',
        });
      else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    } else {
      const tagCategoryPatch: TagCategoryPatch = {
        name: tagCategory.name!,
        description: tagCategory.description,
        ...metrics,
      };
      const tagCategoryItemsDetails =
        tagData?.tagCategoryItemsByTagCategoryId.nodes || [];
      let tagCategoryItemsCreate: TagCategoryItemInput[] = [];
      let tagCategoryItemsDelete: EntityDeleteInput[] = [];

      if (tagCategoryItems?.length) {
        //To create input array for tag items.
        tagCategoryItemsCreate = tagCategoryItems.filter(
          (tagGroup) =>
            !tagCategoryItemsDetails.some(
              (tagItem) => tagGroup.tagId === tagItem.tagId
            )
        ) as TagCategoryItemInput[];
        /* To create delete array for tag items.
          1. Make an array of common tags between previously attached tags and tags coming from inputs.
          2. Make and array of removed tags by comparing the common tags array with the previously attached tags. 
        */
        const commonTags = tagCategoryItems.filter(({ tagId: tagId1 }) =>
          tagCategoryItemsDetails.some(({ tagId: tagId2 }) => tagId2 === tagId1)
        );
        tagCategoryItemsDelete = tagCategoryItemsDetails
          .filter(
            ({ tagId: id1 }) =>
              !commonTags.some(({ tagId: id2 }) => id2 === id1)
          )
          .map((tag) => ({
            id: tag.id,
            rowTimestamp: tag._rowTimestamp,
          })) as EntityDeleteInput[];
      }
      const { errors } = await updateTagCategory({
        variables: {
          input: {
            id: tagData?.id!,
            rowTimestamp: tagData?._rowTimestamp!,
            tagCategoryPatch,
            tagCategoryItemsCreate,
            tagCategoryItemsDelete,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: TAG_CATEGORIES_LIST,
          },
        ],
      });
      if (!errors)
        addToast('Tag category updated successfully.', {
          appearance: 'success',
        });
      else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };
  return (
    <Stack>
      <Formik<TagCategoryValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form>
              <Modal isOpen={visible} isBlocking={true} onDismiss={onDismiss}>
                <Stack className={styles.container}>
                  <ModalHeader
                    headerTitle={headerTitle}
                    isNew={isNew}
                    onDismiss={onDismiss}
                  />
                  <Stack tokens={{ childrenGap: 20, padding: 25 }}>
                    <BasicForm />
                    {showTags ? (
                      <Stack
                        tokens={{ childrenGap: 30 }}
                        className={styles.tagsContent}
                      >
                        <Stack>
                          <Stack horizontal>
                            <IconButton
                              onClick={toggleShowTags}
                              iconProps={{ iconName: 'ChevronUp' }}
                              className={styles.iconButtonColor}
                              ariaLabel="Expand"
                            />
                            <Text className={styles.selectTagsLabel}>
                              Select Tags
                            </Text>
                          </Stack>
                          <Stack className={styles.tagsSection}>
                            <FormikMultiSelectTags
                              name="tagCategoryItems"
                              tagsData={tagSelectionData?.tags?.nodes || []}
                            />
                            <NoDataView
                              title={'No tags available!'}
                              show={
                                !tagSelectionData?.tags?.nodes.length &&
                                !tagSelectionLoading
                              }
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack horizontal>
                        <IconButton
                          onClick={toggleShowTags}
                          iconProps={{ iconName: 'ChevronDown' }}
                          className={styles.iconButtonColor}
                          ariaLabel="Expand"
                        />
                        <Text className={styles.selectTagsLabel}>
                          Select Tags
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                  {(tagCategoryCreateLoading || tagCategoryUpdateLoading) && (
                    <ProgressIndicator />
                  )}

                  <ModalFooter
                    headerTitle={headerTitle}
                    onDismiss={onDismiss}
                  />
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
