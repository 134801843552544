import { useMutation } from '@apollo/client';
import {
  Callout,
  DefaultButton,
  Persona,
  PersonaSize,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  MessageTravelAuthorizationCreate,
  MessageTravelAuthorizationCreateVariables,
} from 'travelAuthorization/TravelPlan/view/FormView/ActionMenu/Messages/NewMessage/__generated__/MessageTravelAuthorizationCreate';
import { HierarchyNode } from '../..';
const ADD_NEW_MESSAGE = loader(
  '../../../../../ActionMenu/Messages/NewMessage/MessageTravelAuthorizationCreate.graphql'
);
interface MessageCalloutProps {
  onclick: () => void;
  buttonId: string;
  nodeData: HierarchyNode;
}
export const MessageCallout: React.FC<MessageCalloutProps> = ({
  onclick,
  buttonId,
  nodeData,
}) => {
  const { addToast } = useToasts();
  const [message, setMessage] = useState<string | undefined>();

  const [addNewMessage] = useMutation<
    MessageTravelAuthorizationCreate,
    MessageTravelAuthorizationCreateVariables
  >(ADD_NEW_MESSAGE, { errorPolicy: 'all' });

  const onSendMessage = async () => {
    const { errors } = await addNewMessage({
      variables: {
        input: {
          entityId: nodeData.entityId!,
          message: {
            messageComment: message!,
            isResponseRequired: false,
            reminderDates: null,
          },
          department: null,
          communicationChannel: null,
          user: [nodeData.messageUserId],
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      onclick();
      addToast('Message has been sent', { appearance: 'success' });
    }
  };

  const sendBtnDisabled = message?.length === 0 || message === undefined;
  return (
    <Callout calloutWidth={500} target={`#${buttonId}`}>
      <Stack tokens={{ childrenGap: 20, padding: 20 }}>
        <Stack horizontal horizontalAlign="space-between">
          <Text block variant="xLarge">
            Send message
          </Text>
          <CloseButton onClick={onclick} />
        </Stack>
        <Persona text={nodeData.name!} size={PersonaSize.size24} />
        <TextField
          multiline
          placeholder="Enter message"
          onChange={(_, value) => {
            setMessage(value);
          }}
        />
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              disabled={sendBtnDisabled}
              onClick={onSendMessage}
              text="Send message"
            />
            <DefaultButton onClick={onclick} text="Cancel" />
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
