import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  marginTop5: {
    marginTop: '5px !important',
  },
  resourceTypeDataMainContainer: {
    marginTop: 10,
  },
  marginL5:{
    marginLeft: 5
  }
}));
