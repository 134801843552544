import { DayOfWeek, Label, Stack, Text } from '@fluentui/react';
import { FormHookDateSelect } from 'common/components/FormHooksFields/FormHookDateSelect';
import { FormHookTextField } from 'common/components/FormHooksFields/FormHookTextField';
import { FormHookTimePicker } from 'common/components/FormHooksFields/FormHookTimePicker';
import { ColumnData } from 'common/components/SearchBar';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  StopModalItemValues,
  TripCreateFormValues,
} from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/types';
import { DepotAutoComplete } from '../../../DepotAutoComplete';

export interface FormFieldProps {
  baseField: string;
  disabled: boolean;
  columnData: ColumnData;
  index: number;
  tripIndex: number;
  isDepart?: boolean;
  item: StopModalItemValues | undefined;
  defaultLocationName?: string | null | undefined;
}

export const FormField: React.FC<FormFieldProps> = ({
  baseField,
  disabled,
  columnData,
  index,
  tripIndex,
  isDepart = false,
  // item,
  defaultLocationName,
}) => {
  const fieldArrayName = isDepart ? 'stopModalItems' : 'stopModalReturnItems';
  const { setValue } = useFormContext<TripCreateFormValues>();

  switch (columnData.key) {
    case 'depotId':
      return (
        <DepotAutoComplete
          name={`${baseField}depotId`}
          placeholder="Enter Stop"
          label={'Stop'}
          labelId="departureTo"
          lookUpCalloutWidth={300}
          disabled={disabled}
          depotData={defaultLocationName}
          isAirport
          id={`depotId${index}`}
          onDepotSelect={(data) => {
            if (data) {
              setValue(
                `tripItems.${tripIndex}.${fieldArrayName}.${index}.depotId`,
                data.id
              );
              setValue(
                `tripItems.${tripIndex}.${fieldArrayName}.${index}.depotName`,
                data.description
              );
            }
          }}
          onClear={() => {
            setValue(
              `tripItems.${tripIndex}.${fieldArrayName}.${index}.depotId`,
              null
            );
            setValue(
              `tripItems.${tripIndex}.${fieldArrayName}.${index}.depotName`,
              null
            );
          }}
        />
      );

    case 'arriveDate':
      return (
        <Stack
          style={{
            width: 160,
          }}
        >
          <FormHookDateSelect
            label="Arrival Date"
            name={`${baseField}arriveDate`}
            placeholder="Select Arrival Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            disabled={disabled}
            onSelectDateCallback={(date) => {
              if (date)
                setValue(
                  `tripItems.${tripIndex}.${fieldArrayName}.${index}.arriveDate`,
                  date.toString() || null
                );
            }}
          />
        </Stack>
      );
    case 'arriveTime':
      return (
        <Stack>
          <Label disabled={disabled}>Time</Label>
          <FormHookTimePicker
            name={`${baseField}arriveTime`}
            disabled={disabled}
          />
        </Stack>
      );
    case 'arriveTerminal':
      return (
        <Stack horizontalAlign="center">
          <Label disabled={disabled}>Terminal No</Label>
          <Stack horizontal verticalAlign="center">
            <FormHookTextField
              name={`${baseField}arriveTerminal`}
              placeholder="Arrive"
              disabled={disabled}
              underlined
              style={{ width: 90 }}
            />
            <Text>-</Text>
            <FormHookTextField
              name={`${baseField}departTerminal`}
              placeholder="Depart"
              disabled={disabled}
              underlined
              style={{ width: 90 }}
            />
          </Stack>
        </Stack>
      );
    case 'departDate':
      return (
        <Stack
          style={{
            width: 160,
          }}
        >
          <FormHookDateSelect
            label="Departure Date"
            name={`${baseField}departDate`}
            placeholder="Select Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            showGoToToday
            disabled={disabled}
            onSelectDateCallback={(date) => {
              if (date)
                setValue(
                  `tripItems.${tripIndex}.${fieldArrayName}.${index}.departDate`,
                  date.toString() || null
                );
            }}
          />
        </Stack>
      );
    case 'departTime':
      return (
        <Stack>
          <Label disabled={disabled}>Time</Label>
          <FormHookTimePicker
            name={`${baseField}departTime`}
            disabled={disabled}
          />
        </Stack>
      );
    default:
      return null;
  }
};
