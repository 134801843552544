import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  containerMain: {
    borderBottom: `1px solid ${theme.palette.black}`,
    height: 35,
  },
  container: {
    width: 600,
  },
  selector: {
    backgroundColor: theme.palette.neutralLighter,
  },
  selectedRow: {
    backgroundColor: theme.palette.neutralQuaternaryAlt,
  },
  itemRow: {
    cursor: 'pointer',
  },
}));
