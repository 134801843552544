import { IconButton, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { CorporateChartAccountsModal } from '../CreateCorporateChartAccounts/CorporateChartAccountsModal';

interface EditCorporateChartAccountsProps {
  chartOfAccountId: string | null;
}

export const EditCorporateChartAccounts: React.FC<EditCorporateChartAccountsProps> = ({
  chartOfAccountId,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isEdit = chartOfAccountId !== null ? true : false;

  if (!chartOfAccountId) return null;

  return (
    <>
      <TooltipHost content="Edit Chart of Accounts">
        <IconButton
          onClick={() => setIsOpen(true)}
          iconProps={{ iconName: 'Edit' }}
        />
      </TooltipHost>
      <CorporateChartAccountsModal
        chartOfAccountId={chartOfAccountId}
        visible={isOpen}
        isEdit={isEdit}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}
