import { Stack, Text } from '@fluentui/react';
import { IImageProps, Image } from '@fluentui/react/lib/Image';
import React from 'react';

import inCognitoImage from 'assets/svgs/incognito_filled.svg';
import { useCommonStyles } from 'common/styles';
import { useStyles } from '../index.styles';

const imageProps: IImageProps = {
  width: 30,
  height: 30,
};

export const Header: React.FC = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <Stack
      horizontal
      className={styles.containerHeader}
      horizontalAlign="space-between"
      verticalAlign="center"
      tokens={{
        padding: 20,
      }}
    >
      <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.row}>
        <Image {...imageProps} src={inCognitoImage} alt="Incognito" />
        <Text variant="xLarge" className={commonStyles.bold}>
          Alias
        </Text>
      </Stack>
    </Stack>
  );
};
