import { Link, Text, makeStyles } from '@fluentui/react';
import clsx from 'clsx';
import { GetEntityDocument_entityDocument } from 'documents/documentAssignment/folder/view/__generated__/GetEntityDocument';
import React, { useState } from 'react';
import { ConfirmPORevision } from './ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontSize: theme.fonts.medium.fontSize,
    ':focus': {
      color: theme.palette.themeDark,
    },
  },
  active: {
    color: theme.palette.accent,
    ':focus': {
      color: theme.palette.accent,
    },
  },
}));

interface POLinkProps {
  text: string;
  entityDocument: GetEntityDocument_entityDocument;
  destinationEntityDocumentId: string;
}

export const POLink: React.FC<POLinkProps> = ({
  text,
  entityDocument,
  destinationEntityDocumentId
}) => {
  const styles = useStyles();
  const [hidden, setHidden] = useState(true);

  return (
    <>
      <Link
        className={styles.root}
        onClick={() => {
          setHidden(false);
        }}
      >
        <Text
          className={clsx({
            [styles.active]: !hidden
          })}
        >{text}</Text>
      </Link>
      <ConfirmPORevision
        hidden={hidden}
        entityDocument={entityDocument}
        destinationEntityDocumentId={destinationEntityDocumentId}
        onToggle={() => setHidden(true)}
      />
    </>
  )
}
