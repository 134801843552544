import {
  useApolloClient,
  // useApolloClient,
  useMutation,
} from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  TravelAuthorizationApprovalRevision,
  TravelAuthorizationApprovalRevisionVariables,
} from './__generated__/TravelAuthorizationApprovalRevision';
import {
  TravelAuthorizationDocumentPackageStatus,
  TravelAuthorizationDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/TravelAuthorizationDocumentPackageStatus';
import { DocumentPackageStatusType } from 'common/types/globalTypes';
import { Stack } from '@fluentui/react';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
const TRAVELER_AUTHORIZATION_APPROVAL_REVISION = loader(
  './TravelAuthorizationApprovalRevision.graphql'
);
const TRAVELER_AUTHORIZATION_DOCUMENT_PACKAGE_STATUS = loader(
  '../../../../../../common/graphql/DocumentPackageSubscription/TravelAuthorizationDocumentPackageStatus.graphql'
);

const TITLE = 'Are you sure ?';
const SUBTEXT =
  'This will cancel the approved travel plan and create a revised travel plan that can be resubmitted for approval';
interface ReviseModalProps {
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  onClose?: () => void;
  isOpen: boolean;
}

export const ReviseModal: React.FC<ReviseModalProps> = ({
  travelAuthorizationData,
  onClose,
  isOpen,
}) => {
  const client = useApolloClient();
  const history = useHistory();
  const { addToast } = useToasts();

  const { id, _rowTimestamp } = { ...travelAuthorizationData };

  const [createRevision] = useMutation<
    TravelAuthorizationApprovalRevision,
    TravelAuthorizationApprovalRevisionVariables
  >(TRAVELER_AUTHORIZATION_APPROVAL_REVISION, { errorPolicy: 'all' });

  const onDismiss = () => {
    onClose?.();
  };

  const onConfirm = async () => {
    const { data, errors } = await createRevision({
      variables: {
        input: {
          entityId: id!,
          rowTimestamp: _rowTimestamp!,
        },
      },
    });
    if (errors?.length) {
      addToast(errors?.[0].message, { appearance: 'error' });
    } else {
      onClose?.();
      addToast('Successfully created a Revision', { appearance: 'success' });
      history.replace(
        `/ta/travel-plan/travel/${data?.travelAuthorizationApprovalRevision?.revisedApprovalEntity}`
      );
      const observer = client.subscribe<
        TravelAuthorizationDocumentPackageStatus,
        TravelAuthorizationDocumentPackageStatusVariables
      >({
        query: TRAVELER_AUTHORIZATION_DOCUMENT_PACKAGE_STATUS,
        variables: {
          id: id!,
        },
      });

      const subscription = observer.subscribe(({ data, errors }) => {
        if (errors)
          addToast('Errors received while Subscribing to document package', {
            appearance: 'error',
          });
        else {
          const { document, status } = {
            ...data?.travelAuthorizationDocumentPackageStatus,
          };

          if (status === DocumentPackageStatusType.REGENERATION_FAILURE) {
            addToast(
              'Report generation failed. Document package was not created',
              { appearance: 'error' }
            );
          }
          if (status === DocumentPackageStatusType.FAILURE) {
            addToast('Error while creating Document package ', {
              appearance: 'error',
            });
          }
          if (document) {
            addToast('Document package created', {
              appearance: 'success',
            });
            client.cache.modify({
              id: client.cache.identify({
                ...travelAuthorizationData,
              }),
              fields: {
                stampedEntityDocumentId: () => {
                  return document.id;
                },
              },
            });
          }
        }
        subscription.unsubscribe();
      });
    }
  };

  return (
    <Stack>
      <ConfirmDialog
        hidden={!isOpen}
        title={TITLE}
        subText={SUBTEXT}
        onDismiss={onDismiss}
        maxWidth={400}
        onConfirm={onConfirm}
        confirmText={'Revise'}
      />
    </Stack>
  );
};
