import { Stack, Text } from '@fluentui/react'
import React from 'react';
import { useStyles } from './index.styles';

interface HeaderProps {
  selectedUsername: string;
}

export const Header: React.FC<HeaderProps> = ({
  selectedUsername,
}) => {
  const styles = useStyles();
  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        verticalAlign="baseline"
        wrap
      >
        <Text variant="xLarge">
          {`Are you sure you want to bypass existing pending approvals for`}
        </Text>
        <Text
          variant="xLarge"
          className={styles.username}
        >
          {selectedUsername}
        </Text>
        <Text variant="xLarge">
          pending approvals?
        </Text>
      </Stack>
    </Stack>
  )
}
