import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  ContextualMenu,
  DatePicker,
  DayOfWeek,
  IDragOptions,
  IModalStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { CloseButton } from 'common/components/Buttons';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { SignatureView } from 'common/components/Signatures';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import {
  SupplierApprovalCreate,
  SupplierApprovalCreateVariables,
} from 'common/graphql/__generated__/SupplierApprovalCreate';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  EntityDeleteInput,
  SupplierAddressInput,
  SupplierAddressUpdateTypeInput,
  SupplierContactInput,
  SupplierContactUpdateTypeInput,
  SupplierPatch,
  UploadStatusType,
} from 'common/types/globalTypes';
import {
  dateConvertions,
  dateFormat,
  onFormatDate,
} from 'common/utils/dateFormats';
import { Formik } from 'formik';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ActionMenu } from 'settings/account/supplier/view/ActionMenu';
import { Addresses } from 'settings/account/supplier/view/Addresses';
import { ApprovalHistory } from 'settings/account/supplier/view/ApprovalHistory';
import { Banking } from 'settings/account/supplier/view/Banking';
import { BasicForm } from 'settings/account/supplier/view/BasicForm';
import { Contacts } from 'settings/account/supplier/view/Contacts';
import { ReviewsRatings } from 'settings/account/supplier/view/ReviewsRatings';
import { SignatureNodes } from 'settings/account/supplier/view/Signatures';
import { validationSchema } from 'settings/account/supplier/view/SupplierValidation';
import {
  Supplier,
  SupplierVariables,
  Supplier_supplier,
} from 'settings/account/supplier/view/__generated__/Supplier';
import { SupplierCommonData } from 'settings/account/supplier/view/__generated__/SupplierCommonData';
import {
  SupplierUpdate,
  SupplierUpdateVariables,
} from 'settings/account/supplier/view/__generated__/SupplierUpdate';
import { SUPPLIER_INITIAL_VALUES } from 'settings/account/supplier/view/constants';
import {
  AddressRowValues,
  ContactRowValues,
  SupplierValues,
} from 'settings/account/supplier/view/types';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { useStyles } from './index.styles';

const SUPPLIER_DETAILS = loader(
  '../../../../settings/account/supplier/view/Supplier.graphql'
);
const SUPPLIER_COMMON_DATA = loader(
  '../../../../settings/account/supplier/view/SupplierCommonData.graphql'
);
const SUPPLIER_UPDATE = loader(
  '../../../../settings/account/supplier/view/SupplierUpdate.graphql'
);

const SUPPLIER_APPROVAL_CREATE = loader(
  '../../../../common/graphql/SupplierApprovalCreate.graphql'
);
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this supplier for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your supplier will be requested for approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';

interface SupplierModalProps {
  setOpen: (open: boolean) => void;
  supplier: Supplier_supplier | null | undefined;
}

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

const modalStyles: Partial<IModalStyles> = {
  scrollableContent: {
    overflowY: 'visible',
  },
};

export const SupplierModal: React.FC<SupplierModalProps> = ({
  setOpen,
  supplier,
}) => {
  const { addToast, updateToast } = useToasts();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const client = useApolloClient();
  const [requestComment, setRequestComment] = useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [signatureData, setSignatureData] = useState<
    SignatureNodes[] | undefined
  >([]);
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [fetchSupplierDetails, { refetch }] = useLazyQuery<
    Supplier,
    SupplierVariables
  >(SUPPLIER_DETAILS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const { data: supplierCommonData, loading: supplierCommonDataLoading } =
    useQuery<SupplierCommonData>(SUPPLIER_COMMON_DATA, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  const { _isUpdatable, _isUpdateSupplierOverride } = {
    ...supplier,
  };
  const isEnabled = _isUpdateSupplierOverride
    ? true
    : _isUpdatable
    ? true
    : false;

  const handleSubmit = async (values: SupplierValues) => {
    const {
      supplierAddressesBySupplierId,
      supplierContactsBySupplierId,
      isRateRequired,
      isServiceRateAllowed,
      w9ExpirationDate,
      ...supplierFields
    } = values;

    const updatedSupplierFields = {
      ...supplierFields,
      w9ExpirationDate: w9ExpirationDate ? dateFormat(w9ExpirationDate) : null,
    };
    const supplierPatch: SupplierPatch = Object.entries(
      updatedSupplierFields
    ).reduce((res, [key, val]) => {
      if (
        val !== initialValues[key as keyof SupplierValues] &&
        key !== 'entityDocumentsByEntityId' &&
        key !== 'supplierHistoriesByEntityId' &&
        key !== 'entityTagsByEntityId' &&
        key !== 'userMessagesByEntityId' &&
        key !== 'notesByEntityId' &&
        key !== 'approvalHistoriesByEntityId' &&
        key !== 'approvalHistorySignaturesByEntityId' &&
        key !== 'statusType'
      ) {
        return { ...res, [key]: val };
      }
      return res;
    }, {});
    //Array for new Addresses
    const newAddresses =
      supplierAddressesBySupplierId &&
      (supplierAddressesBySupplierId
        ?.filter((addr) => !addr.id && addr.addressLine1 !== null)
        .map(
          ({ id, _rowTimestamp, ...addr }) => addr
        ) as SupplierAddressInput[]);

    //Array for deleted Addresses
    const deletedAddresses =
      initialValues.supplierAddressesBySupplierId &&
      (differenceBy(
        initialValues.supplierAddressesBySupplierId,
        supplierAddressesBySupplierId || [],
        'id'
      ).map((addr) => ({
        id: addr.id!,
        rowTimestamp: addr._rowTimestamp!,
      })) as EntityDeleteInput[]);
    //Array for updated Addresses
    const updatedAddresses =
      initialValues.supplierAddressesBySupplierId &&
      supplierAddressesBySupplierId &&
      intersection(
        initialValues.supplierAddressesBySupplierId.map((addr) => addr.id),
        supplierAddressesBySupplierId
          .filter((addr) => addr.id)
          .map((addr) => addr.id)
      ).reduce((arr, targetId) => {
        const initialAddress =
          initialValues.supplierAddressesBySupplierId!.find(
            (addr) => addr.id === targetId
          )!;
        const { id, _rowTimestamp, ...updatedAddress } =
          supplierAddressesBySupplierId!.find((addr) => addr.id === targetId)!;
        const patch = Object.entries(updatedAddress).reduce(
          (res, [key, val]) => {
            if (val !== initialAddress[key as keyof AddressRowValues])
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              supplierAddressPatch: patch,
            },
          ];
        return arr;
      }, [] as SupplierAddressUpdateTypeInput[]);

    //Array for new Contacts
    const newContacts =
      supplierContactsBySupplierId &&
      (supplierContactsBySupplierId
        ?.filter((contact) => !contact.id && contact.contactName !== null)
        .map(
          ({ id, _rowTimestamp, ...contact }) => contact
        ) as SupplierContactInput[]);
    //Array for deleted Contacts
    const deletedContacts =
      initialValues.supplierContactsBySupplierId &&
      (differenceBy(
        initialValues.supplierContactsBySupplierId,
        supplierContactsBySupplierId || [],
        'id'
      ).map((contact) => ({
        id: contact.id!,
        rowTimestamp: contact._rowTimestamp!,
      })) as EntityDeleteInput[]);
    //Array for updated Contact
    const updatedContacts =
      initialValues.supplierContactsBySupplierId &&
      supplierContactsBySupplierId &&
      intersection(
        initialValues.supplierContactsBySupplierId.map((contact) => contact.id),
        supplierContactsBySupplierId
          .filter((contact) => contact.id)
          .map((contact) => contact.id)
      ).reduce((arr, targetId) => {
        const initialContacts =
          initialValues.supplierContactsBySupplierId!.find(
            (contact) => contact.id === targetId
          )!;
        const { id, _rowTimestamp, ...updatedContact } =
          supplierContactsBySupplierId!.find(
            (contact) => contact.id === targetId
          )!;
        const patch = Object.entries(updatedContact).reduce(
          (res, [key, val]) => {
            if (val !== initialContacts[key as keyof ContactRowValues])
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              supplierContactPatch: patch,
            },
          ];
        return arr;
      }, [] as SupplierContactUpdateTypeInput[]);

    const { errors } = await updateSupplier({
      variables: {
        input: {
          id: supplier?.id!,
          rowTimestamp: supplier?._rowTimestamp!,
          supplierPatch: !isEmpty(supplierPatch) ? supplierPatch : undefined,
          supplierAddressesCreate:
            newAddresses && newAddresses.length > 0 ? newAddresses : undefined,
          supplierAddressesUpdate:
            updatedAddresses && updatedAddresses.length > 0
              ? updatedAddresses
              : undefined,
          supplierAddressesDelete:
            deletedAddresses && deletedAddresses.length > 0
              ? deletedAddresses
              : undefined,
          supplierContactsCreate:
            newContacts && newContacts.length > 0 ? newContacts : undefined,
          supplierContactsUpdate:
            updatedContacts && updatedContacts.length > 0
              ? updatedContacts
              : undefined,
          supplierContactsDelete:
            deletedContacts && deletedContacts.length > 0
              ? deletedContacts
              : undefined,
        },
      },
      update: (cache, { data }) => {
        const cacheData = cache.readQuery<Supplier, SupplierVariables>({
          query: SUPPLIER_DETAILS,
          variables: {
            id: supplier?.id!,
          },
        });
        const updatedData: Supplier = {
          supplier: {
            ...cacheData?.supplier!,
            ...data?.supplierUpdate?.supplier!,
          },
        };
        cache.writeQuery<Supplier, SupplierVariables>({
          query: SUPPLIER_DETAILS,
          variables: {
            id: supplier?.id!,
          },
          data: updatedData!,
        });
      },
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Supplier edited successfully', {
        appearance: 'success',
      });
    }
  };

  let initialValues: SupplierValues = SUPPLIER_INITIAL_VALUES;
  if (supplier) {
    const supplierAddresses = supplier.supplierAddressesBySupplierId.nodes.map(
      (ele) => {
        const { stateRegion, country, ...allAddressFields } = ele;
        return allAddressFields;
      }
    );

    initialValues = {
      ...supplier!,
      w9ExpirationDate: supplier.w9ExpirationDate
        ? dateFormat(dateConvertions(supplier.w9ExpirationDate))
        : null,
      isRateRequired: supplier.vendorServiceType?.isRateRequired || null,
      isServiceRateAllowed:
        supplier.vendorServiceType?.isServiceRateAllowed || null,
      supplierAddressesBySupplierId: [...supplierAddresses],
      supplierContactsBySupplierId: [
        ...supplier.supplierContactsBySupplierId.nodes,
      ],
    };
  }
  useEffect(() => {
    if (supplier?.id) {
      fetchSupplierDetails({
        variables: {
          id: supplier?.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier?.id]);

  const [updateSupplier, { loading: loadingSupplierUpdate }] = useMutation<
    SupplierUpdate,
    SupplierUpdateVariables
  >(SUPPLIER_UPDATE, { errorPolicy: 'all' });

  const [supplierApprovalCreate, { loading: supplierApprovalLoading }] =
    useMutation<SupplierApprovalCreate, SupplierApprovalCreateVariables>(
      SUPPLIER_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );
  const isLoading =
    supplierCommonDataLoading ||
    loadingSupplierUpdate ||
    supplierApprovalLoading;
  useEffect(() => {
    const { nodes } = {
      ...supplier?.approvalHistorySignaturesByEntityId,
    };
    if (nodes?.length) setSignatureData(nodes);
  }, [supplier]);

  const heading: string = 'Edit Supplier';

  const supplierStatus = supplier
    ? `(${supplier?.statusType?.statusType})`
    : '(Draft)';
  return (
    <>
      <Formik<SupplierValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, dirty, resetForm, errors }) => {
          return (
            <ModalWrapper
              isOpen
              isBlocking
              onDismiss={() => setOpen(false)}
              dragOptions={DragOptions}
              styles={modalStyles}
              containerClassName={styles.modalContainer}
            >
              <Stack
                horizontal
                horizontalAlign="space-between"
                className={styles.headerContainer}
              >
                <Stack
                  horizontal
                  tokens={{
                    childrenGap: 10,
                  }}
                >
                  <Text variant="xLarge">{heading}</Text>
                  <UnsavedIndicator visible={dirty} />
                </Stack>
                <Stack
                  horizontal
                  tokens={{
                    childrenGap: 10,
                  }}
                  verticalAlign="center"
                >
                  <Text
                    variant="mediumPlus"
                    className={commonStyles.colorThemePrimary}
                  >
                    {supplierStatus}
                  </Text>
                  <CloseButton onClick={() => setOpen(false)} />
                </Stack>
              </Stack>
              <Separator />
              <Stack className={styles.fixedHeightContainer}>
                {supplierCommonDataLoading ? (
                  <ShimmerView />
                ) : (
                  <>
                    {supplier && (
                      <>
                        {supplier._isApprovalDocumentsRequired &&
                          supplier._requiredApprovalDocuments && (
                            <Stack tokens={{ padding: '0px 25px' }}>
                              <MessageBar messageBarType={MessageBarType.error}>
                                {supplier?._requiredApprovalDocuments}
                              </MessageBar>
                            </Stack>
                          )}
                        {supplier && (
                          <ActionMenu
                            supplierDetails={supplier}
                            secureRowLevels={
                              supplierCommonData?.secureRowLevels
                            }
                            onUpload={async (
                              fileSelected,
                              document,
                              toastId
                            ) => {
                              const observer = client.subscribe({
                                query: DOCUMENT_UPLOAD_STATUS,
                                variables: {
                                  documentId:
                                    document.document._documentFileId!,
                                },
                              });

                              const subscription = observer.subscribe(
                                (data) => {
                                  const subscribedData =
                                    data.data as OnDocumentUploadStatus;

                                  const { status, document } = {
                                    ...subscribedData.documentUploadStatus,
                                  };

                                  if (!document) {
                                    if (
                                      status.type ===
                                      UploadStatusType.VALIDATING
                                    ) {
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Validating files ${fileSelected.name} - ${status.message}`
                                          : `Validating files ${fileSelected.name}`,
                                        appearance: 'info',
                                        autoDismiss: false,
                                      });
                                    } else if (
                                      status.type ===
                                      UploadStatusType.EXTRACTING
                                    ) {
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                          : `Extracting data from ${fileSelected.name}`,
                                        appearance: 'info',
                                        autoDismiss: false,
                                      });
                                    } else if (
                                      status.type === UploadStatusType.FAILURE
                                    ) {
                                      subscription.unsubscribe();
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                          : `Upload of ${fileSelected.name} failed`,
                                        appearance: 'error',
                                        autoDismiss: true,
                                      });
                                    } else if (
                                      status.type === UploadStatusType.WARNING
                                    ) {
                                      subscription.unsubscribe();
                                      updateToast(toastId!, {
                                        content: status.message
                                          ? `Warning for file ${fileSelected.name}: ${status.message}`
                                          : `Warning for file ${fileSelected.name}`,
                                        appearance: 'warning',
                                        autoDismiss: true,
                                      });
                                    }
                                  } else {
                                    subscription.unsubscribe();
                                    updateToast(toastId!, {
                                      content: status.message
                                        ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                        : `Successfully uploaded ${fileSelected.name}`,
                                      appearance: 'success',
                                      autoDismiss: true,
                                    });
                                    refetch?.({ id: supplier?.id! });
                                  }
                                }
                              );
                            }}
                            onRefetch={() => {
                              refetch?.({ id: supplier?.id! });
                            }}
                          />
                        )}

                        <Separator />
                        <ReviewsRatings
                          supplier={supplier}
                          countTotal={
                            supplier.supplierRatingResults?.countTotal
                          }
                        />
                        <Separator />
                      </>
                    )}
                    <BasicForm
                      supplierAvailableDocumentTypes={
                        supplierCommonData?.availableSupplierDocumentPools
                          ?.nodes || []
                      }
                      paymentTermTypes={
                        supplierCommonData?.paymentTermTypes?.nodes || []
                      }
                      environmentalMetrics={
                        supplierCommonData?.environmentalMetrics?.nodes || []
                      }
                      serviceTypes={
                        supplierCommonData?.vendorServiceTypes?.nodes || []
                      }
                      isUpdatable={supplier?._isUpdatable}
                      supplier={supplier}
                      isNew={false}
                    />
                    <Separator />
                    <Addresses
                      isEdit={true}
                      countries={supplierCommonData?.countries?.nodes || []}
                      isUpdatable={isEnabled}
                    />
                    <Separator />
                    <Contacts isEdit={false} isUpdatable={isEnabled} />
                    <Separator />
                    <Banking
                      isEdit={true}
                      isBankAccountVerified={supplier?._isBankAccountVerified}
                      isUpdatable={isEnabled}
                    />
                    {supplier?.approvalHistorySignaturesByEntityId.nodes
                      .length! > 0 && (
                      <Stack>
                        <Separator />
                        <Stack
                          className={styles.tagsContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text variant="xLarge">Signatures</Text>
                          <SignatureView signatureData={signatureData!} />
                        </Stack>
                      </Stack>
                    )}
                    {supplier &&
                      supplier?.approvalHistoriesByEntityId.nodes.length! >
                        0 && (
                        <Stack>
                          <Separator />
                          <Stack
                            className={styles.tagsContainer}
                            tokens={{ childrenGap: 20 }}
                          >
                            <Text variant="xLarge">Approval History</Text>
                            <Text
                              variant="medium"
                              className={styles.requestedByText}
                            >
                              Requested By:
                            </Text>
                            <ApprovalHistory
                              data={supplier.approvalHistoriesByEntityId}
                            />
                          </Stack>
                          <Stack></Stack>
                        </Stack>
                      )}
                  </>
                )}
              </Stack>
              <FooterActionBar
                hideCreateButton={true}
                onCancel={() => setOpen(false)}
                disabled={
                  supplierCommonDataLoading || {
                    save: !dirty || Object.keys(errors).length > 0,
                    cancel: !dirty,
                  }
                }
                isSubmitting={isSubmitting}
                isCreate={!supplier?.id ? true : false}
                onSave={async () => {
                  await submitForm();
                }}
                isLoading={isLoading}
                children={
                  supplier && (
                    <Stack>
                      {supplier?._isStagedApprovalRequest && (
                        <PrimaryButton
                          disabled={dirty}
                          className={styles.disabledButton}
                          text="Request Approval"
                          onClick={() => toggleConfirmDialog()}
                        />
                      )}
                    </Stack>
                  )
                }
              />
              <Prompt
                when={dirty && !isSubmitting}
                message="Are you sure you want to leave your changes unsaved?"
              />
              <ConfirmDialog
                hidden={hideConfirmRequestDialog}
                title={CONFIRM_REQUEST_DIALOG_TITLE}
                subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
                onDismiss={toggleConfirmDialog}
                minWidth={500}
                onConfirm={async () => {
                  toggleConfirmDialog();
                  const inputVariables: ApprovalRequestInput = {
                    entityId: supplier?.id!,
                    rowTimestamp: supplier?._rowTimestamp!,
                    comments: requestComment,
                  };
                  if (requiredDate) inputVariables.requiredDate = requiredDate;
                  const { errors } = await supplierApprovalCreate({
                    variables: {
                      input: {
                        entityApproval: [inputVariables],
                        urgencyLevel: urgencyLevel,
                      },
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [
                      {
                        query: SUPPLIER_DETAILS,
                        variables: {
                          id: supplier?.id!,
                        },
                      },
                    ],
                  });

                  if (errors?.length)
                    addToast(errors[0].message, {
                      appearance: 'error',
                    });
                  else {
                    setRequestComment('');
                    setRequiredDate('');
                    addToast('Request sent for approval', {
                      appearance: 'success',
                    });
                  }
                }}
              >
                <Stack tokens={{ childrenGap: 10 }}>
                  <TextField
                    className={styles.marginT10}
                    multiline
                    rows={3}
                    value={requestComment}
                    placeholder="Please write your comment here (optional)"
                    resizable={false}
                    onChange={(_event, value) => setRequestComment(value || '')}
                  />
                  <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                    <DatePicker
                      minDate={new Date()}
                      firstDayOfWeek={DayOfWeek.Sunday}
                      placeholder="Finalized by date (optional)"
                      ariaLabel="Date"
                      formatDate={onFormatDate}
                      showWeekNumbers
                      firstWeekOfYear={1}
                      showMonthPickerAsOverlay
                      showGoToToday={false}
                      onSelectDate={(date) =>
                        setRequiredDate(dateFormat(date!.toString()))
                      }
                    />
                  </TooltipHost>

                  <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                    <UrgencyToggle
                      onToggle={(data) => {
                        setUrgencyLevel(data ? 0 : 1);
                      }}
                    />
                  </Stack>
                </Stack>
              </ConfirmDialog>
            </ModalWrapper>
          );
        }}
      </Formik>
    </>
  );
};
