export const DRAWER_OPEN_WIDTH = 230;
export const DRAWER_CLOSE_WIDTH = 50;

export const TABLE_ROWS = 40;
export const RATING_LIST = 5;

export const NOTIFICATIONS_PER_PAGE = 20;
export const NOTIFICATION_POLL_INTERVAL = 60000;
export const MESSAGES_PER_PAGE = 20;
export const ATTACHABLE_TABLE_ROWS = 100;
export enum GlobalActions {
  protect = 'PROTECT_ACTION',
  attach = 'ATTACH_ACTION',
  notes = 'NOTES_ACTION',
  history = 'HISTORY_ACTION',
  tags = 'TAGS_ACTION',
  messages = 'MESSAGES_ACTION',
}
