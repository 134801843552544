import { ExtendedMessageData } from "common/components/MessagesSections/MessageRow/ExtendedMessage";

export const getAlignment = (column: ExtendedMessageData | null, index: number) => {
    if (!column) return null;
    switch (index) {
      case 0:
        return column.columnAlignment1;
      case 1:
        return column.columnAlignment2;
      case 2:
        return column.columnAlignment3;
      case 3:
        return column.columnAlignment4;
      case 4:
        return column.columnAlignment5;
      default:
        return null;
    }
};