import { AccountEntryValues } from './types';

export const ACCOUNT_ENTRY_VALUES: AccountEntryValues = {
  lookupName: null,
  setAccount: null,
  corporateAccount: null,
  isTravelAccountForAllDepartments: null,
  travelAccountTypeId: null,
  departmentGroupId: null,
  lookupAccount: null,
};
