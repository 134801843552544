import { FontWeights, makeStyles } from "@fluentui/react";
export const useStyles = makeStyles((theme) => ({
  container:{
    position: 'absolute', 
    top: 0, 
    left: 0 
  },
  background: {
    backgroundColor: theme.palette.neutralLighterAlt,
    width: 555
  },
  iconButtonColor: {
    color: theme.palette.themePrimary,
  },
  header: {
    justifyContent: "space-between",
    paddingLeft: 15,
    paddingRight: 10,
    paddingTop: 10,
  },
  label: {
    fontWeight: FontWeights.bold,
    marginRight: 10
  },
  labelContainer: {
    flex: 1
  },
  travelAllowancesHeading: {
    marginLeft: 15
  },
  policyAllowanceContainer: {
    paddingTop: 10,
    marginBottom: 10
  }
}));

