import {
  Callout,
  DirectionalHint,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useStyles } from './index.styles';
import { DataView } from 'common/components/PreviewHierarchyComponents/DataView';
import { LookupTravelerSearch_lookupTravelerSearch_nodes } from '../__generated__/LookupTravelerSearch';
import { AddressRow } from './AddressRow';

interface AddressDetailsCalloutType {
  data: LookupTravelerSearch_lookupTravelerSearch_nodes;
  detailsTargetId: string;
  directionalHint: DirectionalHint;
  onDismiss?: () => void;
}

export const AddressDetailsCallout: React.FC<AddressDetailsCalloutType> = ({
  onDismiss,
  data,
  detailsTargetId,
  directionalHint,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const {
    _fullName,
    vendorNumber,
    travelerAddressesByTravelerId,
    userOccupation,
    department,
  } = data || {};

  const travelerDetails = { ...travelerAddressesByTravelerId?.nodes?.[0] };

  const {
    addressLine1,
    addressLine2,
    cityName,
    stateRegion,
    postalCode,
    telephoneNumber,
    emailAddress,
  } = travelerDetails || {};

  const addressObject = {
    cityName,
    stateRegion: stateRegion?.stateRegion,
    postalCode,
    country: stateRegion?.country?.country,
    emailAddress,
    telephoneNumber,
  }

  const addressFieldsArray = Object.values(addressObject).filter((value) => !!value);

  return (
    <Callout
      onDismiss={onDismiss}
      isBeakVisible={true}
      className={styles.detailsCallout}
      directionalHint={directionalHint}
      target={`#${detailsTargetId}`}
    >
      <Stack className={styles.calloutContainer}>
        <Stack
          horizontalAlign="space-between"
          horizontal
          tokens={{ padding: '10px' }}
        >
          <Text
            className={clsx(
              commonStyles.semibold,
              commonStyles.colorThemePrimary
            )}
            variant="mediumPlus"
          >
            {_fullName}
          </Text>
          {vendorNumber && (
            <Text
              className={clsx(
                commonStyles.semibold,
                commonStyles.colorThemePrimary,
                styles.lookupCode
              )}
            >
              {`#${vendorNumber}`}
            </Text>
          )}
        </Stack>
        <Separator className={styles.separator} />
        <Stack horizontal>
          <Stack
            tokens={{
              childrenGap: 7,
              padding: '0px 10px 10px',
            }}
          >
            <Stack horizontal tokens={{ childrenGap: 50 }}>
              {!!userOccupation?.userOccupationTitle && (
                <DataView
                  title="Occupation"
                  content={userOccupation?.userOccupationTitle || ''}
                />
              )}
              {!!department?.name && (
                <DataView title="Department" content={department?.name || ''} />
              )}
            </Stack>
            <AddressRow data={_fullName} isLastRow />
            <AddressRow data={addressLine1} isLastRow />
            <AddressRow data={addressLine2} isLastRow />
            <Stack
              horizontal
              wrap
              tokens={{ childrenGap: 5 }}
            >
              {
                addressFieldsArray.map((item, index, array) => {
                  const isLast = index === (array.length - 1);
                  return (
                    <AddressRow
                      key={index}
                      data={item}
                      isLastRow={isLast}
                    />
                  )
                })
              }
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
