import {
  Callout,
  DirectionalHint,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { CompanyAddress } from '..';
import { AddressText } from './AddressText';
import { useStyles } from './index.styles';

interface BusinessUnitAddressProps {
  calloutId: string;
  companyAddress: CompanyAddress | null;
  onCalloutDismiss: () => void;
}
export const BusinessUnitAddress: React.FC<BusinessUnitAddressProps> = ({
  calloutId,
  companyAddress,
  onCalloutDismiss,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const { addressAlias, addressLine1, addressLine2, cityName, postalCode } = {
    ...companyAddress,
  };

  return (
    <Callout
      gapSpace={0}
      target={`#${calloutId}`}
      directionalHint={DirectionalHint.leftTopEdge}
      setInitialFocus
      onDismiss={onCalloutDismiss}
    >
      <Stack className={styles.container}>
        <Stack
          verticalAlign="center"
          horizontalAlign="center"
          className={styles.typeContainer}
        >
          <Text
            className={clsx(commonStyles.semibold, styles.documentType)}
            variant="mediumPlus"
          >
            Address
          </Text>
        </Stack>
        <Separator className={styles.separator} />
        <Stack tokens={{ padding: 10 }}>
          {addressAlias && (
            <AddressText
              text={
                addressLine1 || addressLine2 || cityName || postalCode
                  ? `${addressAlias},`
                  : `${addressAlias}`
              }
            />
          )}
          {addressLine1 && (
            <AddressText
              text={
                addressLine2 || cityName || postalCode
                  ? `${addressLine1},`
                  : `${addressLine1}`
              }
            />
          )}
          {addressLine2 && (
            <AddressText
              text={
                cityName || postalCode ? `${addressLine2},` : `${addressLine2}`
              }
            />
          )}
          {cityName && (
            <AddressText text={postalCode ? `${cityName},` : `${cityName}`} />
          )}
          {postalCode && <AddressText text={`${postalCode}`} />}
        </Stack>
      </Stack>
    </Callout>
  );
};
