import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  IconButton,
  ScrollablePane,
  Sticky,
  StickyPositionType,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import {
  CardAccountsOrderBy,
  CardHoldersOrderBy,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useRef, useState } from 'react';
import { CardAccountsList } from './CardAccountsList';
import { CardHoldersList, SortColumnType } from './CardHolders';
import { toOrderByVariable } from './CardHolders/utils';
import { Header } from './Header';
import { useStyles } from './index.styles';
import { PurchaseCardsShimmer } from './Shimmer';
import {
  CardAccounts,
  CardAccountsVariables,
  CardAccounts_cardAccounts_nodes,
} from './__generated__/CardAccounts';
import {
  CardHolders,
  CardHoldersVariables,
  CardHolders_cardHolders,
  CardHolders_cardHolders_nodes,
} from './__generated__/CardHolders';
import { CardCompanies } from '../cardCompanies';
import {
  CardHolderDelete,
  CardHolderDeleteVariables,
} from './__generated__/CardHolderDelete';
import { useToasts } from 'react-toast-notifications';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';
export type CardAccountType = CardAccounts_cardAccounts_nodes;
const CARD_ACCOUNTS = loader('./CardAccounts.graphql');
const CARD_HOLDERS = loader('./CardHolders.graphql');
const CARD_HOLDER_DELETE = loader('./CardHolderDelete.graphql');
export const PurchaseCards = () => {
  const heading = 'Purchase Cards';
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { addToast } = useToasts();
  const [selectedGroup, setSelectedGroup] = useState<CardAccountType | null>();
  const [selectedRows, setSelectedRows] = useState<
    CardHolders_cardHolders_nodes[]
  >([]);
  const {
    data: cardAccountsData,
    loading: cardAccountsLoading,
    refetch,
  } = useQuery<CardAccounts, CardAccountsVariables>(CARD_ACCOUNTS, {
    variables: {
      orderBy: [CardAccountsOrderBy.NAME_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [
    getCardHolders,
    {
      data: cardHoldersData,
      loading: cardHoldersLoading,
      variables: cardHoldersVariables,
      networkStatus,
    },
  ] = useLazyQuery<CardHolders, CardHoldersVariables>(CARD_HOLDERS, {
    variables: {
      orderBy: [CardHoldersOrderBy.CARD_NUMBER_ASC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [deleteCardHolder] = useMutation<
    CardHolderDelete,
    CardHolderDeleteVariables
  >(CARD_HOLDER_DELETE, { errorPolicy: 'all' });

  const _headerOnClick = (cardAccount: CardAccountType) => {
    if (selectedGroup !== cardAccount) {
      getCardHolders({
        variables: {
          ...cardHoldersVariables,
          filter: {
            cardAccountId: { equalTo: cardAccount.id },
          },
        },
      });
    }
    setSelectedGroup((prevState) => {
      return prevState === cardAccount ? null : cardAccount;
    });
  };
  const refetching =
    cardAccountsLoading && networkStatus !== NetworkStatus.fetchMore;
  const cardAccountIdRef = useRef('');
  const updateCardAccountIdRef = (newItems: string) => {
    cardAccountIdRef.current = newItems;
  };
  const sortColumn = (columnData: SortColumnType) => {
    getCardHolders({
      variables: {
        ...cardHoldersVariables,
        filter: {
          cardAccountId: {
            equalTo: cardAccountIdRef.current,
          },
        },
        orderBy: toOrderByVariable({
          column: columnData.column,
          direction: columnData.direction,
        }),
      },
    });
  };

  const onConfirmDelete = async () => {
    const deletableData: EntityDeleteInput[] = selectedRows
      .filter((ele) => ele?._isDeletable)
      .map((obj) => {
        return { id: obj.id, rowTimestamp: obj._rowTimestamp! };
      });
    const { errors } = await deleteCardHolder({
      variables: { input: { entityDelete: deletableData } },
      update: (cache, { data }) => {
        if (data?.cardHolderDelete?.deletedEntities?.length) {
          const identity = cache.identify({
            ...cardHoldersData?.cardHolders,
          });
          cache.evict({ id: identity });
          cache.gc();
          cache.modify({
            fields: {
              cardHolders: (existingData: CardHolders_cardHolders) => {
                return {
                  nodes: existingData.nodes.filter(
                    (existingRecord) =>
                      !data.cardHolderDelete?.deletedEntities?.some(
                        (deletableRecord) =>
                          existingRecord.id === deletableRecord?.id
                      )
                  ),
                  totalCount:
                    existingData.totalCount -
                    (data?.cardHolderDelete?.deletedEntities?.length || 0),
                };
              },
            },
          });
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else {
      setSelectedRows([]);
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
    }
  };

  return (
    <>
      <Stack grow className={styles.container}>
        <ScrollablePane>
          <Stack className={styles.innerContainer}>
            <Sticky stickyPosition={StickyPositionType.Header}>
              <Stack
                tokens={{ childrenGap: 20 }}
                className={commonStyles.listHeaderContainer}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  className={commonStyles.listTitleContainer}
                >
                  <Text variant="xLarge">{heading}</Text>
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {selectedRows.length > 0 && (
                      <ActionMessageModal
                        entityType={EntityType.CardHolder}
                        disabled={
                          !selectedRows.some(
                            (selected) => selected?._isDeletable
                          )
                        }
                        visible={selectedRows.length > 0}
                        multiple={{
                          validCount: selectedRows.filter(
                            (selected) => selected?._isDeletable
                          ).length,
                          invalidNames: selectedRows
                            .filter((selected) => !selected?._isDeletable)
                            .map(
                              (cannotDelete) => cannotDelete?.cardFullName || ''
                            ),
                        }}
                        onConfirm={onConfirmDelete}
                      />
                    )}
                    <TooltipHost content={'Refresh'}>
                      <IconButton
                        onClick={() => refetch()}
                        iconProps={{ iconName: 'refresh' }}
                      />
                    </TooltipHost>
                    <CardCompanies />
                  </Stack>
                </Stack>
              </Stack>
              <Header />
            </Sticky>
            {cardAccountsLoading ? (
              <PurchaseCardsShimmer />
            ) : (
              <>
                {cardAccountsData?.cardAccounts?.nodes.map(
                  (cardAccount, index) => {
                    const isCardOpen = selectedGroup?.id === cardAccount.id;

                    return (
                      <Stack key={index.toString()}>
                        <CardAccountsList
                          isOpen={isCardOpen}
                          cardAccount={cardAccount}
                          availableCount={cardAccount.cardHolders.totalCount}
                          onHeaderClick={() => {
                            _headerOnClick(cardAccount);
                            updateCardAccountIdRef(cardAccount.id);
                          }}
                          refetching={refetching}
                        />
                        {isCardOpen && (
                          <CardHoldersList
                            cardAccount={cardAccount}
                            data={cardHoldersData?.cardHolders?.nodes || []}
                            cardHoldersLoading={cardHoldersLoading}
                            getSortColumn={sortColumn}
                            onSelectionChanged={setSelectedRows}
                          />
                        )}
                      </Stack>
                    );
                  }
                )}
              </>
            )}
          </Stack>
        </ScrollablePane>
      </Stack>
    </>
  );
};
