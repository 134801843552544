import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  diabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  marginT10: {
    marginTop: 10,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
  tagsContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  bottomMargin:{
    marginBottom: 100
  }
}));
