import React from 'react';
import {
  Text,
  ITextProps,
  Stack,
  Separator,
  ISeparatorStyles,
  Icon,
} from '@fluentui/react';
import { useStyles } from './index.styles';

interface CalloutTextProps extends ITextProps {
  text: string | null | undefined;
  hasSeparator?: boolean;
}
export const CalloutText: React.FC<CalloutTextProps> = ({
  text,
  hasSeparator,
}) => {
  const separatorStyles: Partial<ISeparatorStyles> = {
    root: {
      padding: 0,
    },
  };
  const styles = useStyles();

  if (text)
    return (
      <Stack className={styles.textContainer}>
        <Stack
          horizontal
          tokens={{ childrenGap: 10 }}
          className={styles.iconWrapper}
        >
          <Icon iconName="Accept" className={styles.iconColor} />
          <Text>{text}</Text>
        </Stack>
        {hasSeparator &&  <Separator styles={separatorStyles} />}
      </Stack>
    );
  else return null;
};
