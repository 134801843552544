import { createTheme } from '@fluentui/react';

// DARK THEME
export const darkTheme = createTheme({
  components: {
    ShimmeredDetailsList: {
      styles: {
        root: {
          selectors: {
            ':after': {
              backgroundImage:
                'linear-gradient(transparent 30%, rgb(30, 32, 36) 65%, rgb(30, 32, 36) 100%)',
            },
          },
        },
      },
    },
    PersonaCoin: {
      styles: {
        initials: {
          color: 'white',
        },
      },
    },
    PrimaryButton: {
      styles: {
        label: {
          color: '#f8f8f8',
        },
        root: {
          color: '#f8f8f8',
        },
        rootHovered: {
          color: '#f8f8f8',
        },
        rootPressed: {
          color: '#f8f8f8',
        },
      },
    },
    Dropdown: {
      styles: {
        root: {
          selectors: {
            ':disabled': {
              backgroundColor: '#484c53',
            },
          },
        },
      },
    },
  },
  palette: {
    neutralLighterAlt: '#26282d',
    neutralLighter: '#2d3036',
    neutralLight: '#3a3d43',
    neutralQuaternaryAlt: '#42454c',
    neutralQuaternary: '#484c53',
    neutralTertiaryAlt: '#646870',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#1e2024',
    themePrimary: '#3a96dd',
    themeLighterAlt: '#020609',
    themeLighter: '#091823',
    themeLight: '#112d43',
    themeTertiary: '#235a85',
    themeSecondary: '#3385c3',
    themeDarkAlt: '#4ba0e1',
    themeDark: '#65aee6',
    themeDarker: '#8ac2ec',
    accent: '#3a96dd',
  },
  isInverted: true,
});
