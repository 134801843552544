import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required('Required').nullable(),
    departmentBudgets: Yup.array()
      .transform(function (value) {
        // If the array exists and has at least one item, remove the last row
        return value && value.length > 0 ? value.slice(0, -1) : value;
      })
      .of(
        Yup.object().shape({
          departmentId: Yup.string().nullable().required('Required'),
          budgetTypeId: Yup.string().nullable().required('Required'),
          currencyId: Yup.string().nullable().required('Required'),
          budgetAmount: Yup.string().nullable().required('Required'),
        })
      ),
  });
