import { AddressRowValues, SupplierValues } from './types';

export const ADDRESS_INITIAL_VALUES: AddressRowValues = {
  id: null,
  _rowTimestamp: null,
  addressLine1: null,
  addressLine2: null,
  cityName: null,
  countryId: null,
  stateRegionId: null,
  postalCode: null,
  telephoneNumber: null,
  emailAddress: null,
  webAddress: null,
  isSendEmailAccount: null,
  isPrimary: null,
};

export const SUPPLIER_INITIAL_VALUES: SupplierValues = {
  companyOrLastName: null,
  federalTaxReference:null,
  vatNumber:null,
  firstName: null,
  isRelatedPartySupplier: null,
  isCompetitiveBidsRequired: null,
  isContractualPurchaseOrderRequired: null,
  isCorporation: true,
  isIndividual: null,
  supplierAddressesBySupplierId: [
    { ...ADDRESS_INITIAL_VALUES, isPrimary: true },
  ],
};
