const configUrl = '/config.json';

type Configuration = Readonly<{
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
  };
  apiDomain: string;
  tls: boolean;
  sentry?: {
    dsn: string;
    sampleRate: number;
    env: string;
  };
}>;

/**
 * This object CANNOT be used from a static context (such as the root of a file). This is because
 * it will be hoisted and evaluated before the loadConfig fetch is called leading to runtime
 * exceptions when trying to read fields on an undefined variable.
 */
export let config: Configuration | undefined = undefined;

/**
 * Load the runtime configuration from public/config.json. This MUST be called before rendering
 * the React DOM root.
 */
export const loadConfig = () =>
  fetch(configUrl).then((res) => {
    if (res.ok) {
      return res.json().then((data) => {
        config = { tls: true, ...data };
        return config;
      });
    }
  });
