import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import {
  PurchaseOrderItem,
  PurchaseOrderItem_purchaseOrderItem,
} from '../../__generated__/PurchaseOrderItem';
import {
  DISTRIBUTION_INITIAL_VALUES,
  PURCHASE_ORDER_ITEM_INITIAL_VALUES,
} from './constant';
import { PurchaseOrderItemFormProps } from './interface';
import {
  EntityDeleteInput,
  PurchaseOrderItemDistributionPatch,
  PurchaseOrderItemDistributionUpdateTypeInput,
  TransactionLayout,
} from 'common/types/globalTypes';
import { ItemDistributionValues } from './interface';
import { isEmpty } from 'lodash';
import { UserDefaults_userDefaults_nodes } from 'Preferences/__generated__/UserDefaults';
interface DefaultValueProps {
  purchaseOrderItem: PurchaseOrderItem_purchaseOrderItem | undefined | null;
}
const getDefaultDistribution = (
  userDefaults?: UserDefaults_userDefaults_nodes | undefined
) => {
  const {
    referenceCode6,
    referenceCode7,
    projectReference,
    setReference,
    accountCode,
    lookupAccounts,
  } = {
    ...userDefaults,
  };

  let defaultValues: ItemDistributionValues | undefined = {
    ...DISTRIBUTION_INITIAL_VALUES,
    referenceCode6: referenceCode6 === undefined ? null : referenceCode6,
    referenceCode7: referenceCode7 === undefined ? null : referenceCode7,
    projectReference: projectReference ?? null,
    setReference: setReference ?? null,
    accountReference: accountCode === undefined ? null : accountCode,
    _accountName: lookupAccounts?.lookupName ?? null,
  };

  return defaultValues;
};
const getDefaultValues = (
  props: DefaultValueProps,
  userDefaults?: UserDefaults_userDefaults_nodes | undefined
) => {
  const { purchaseOrderItem } = { ...props };
  const {
    referenceCode6,
    referenceCode7,
    projectReference,
    setReference,
    accountCode,
    lookupAccounts,
  } = {
    ...userDefaults,
  };

  let defaultValues: PurchaseOrderItemFormProps | undefined = {
    ...PURCHASE_ORDER_ITEM_INITIAL_VALUES,
    purchaseOrderItemDistribution: [
      {
        ...DISTRIBUTION_INITIAL_VALUES,
        allocationPercent: '100',
        referenceCode6: referenceCode6 === undefined ? null : referenceCode6,
        referenceCode7: referenceCode7 === undefined ? null : referenceCode7,
        projectReference: projectReference ?? null,
        setReference: setReference ?? null,
        accountReference: accountCode === undefined ? null : accountCode,
        _accountName: lookupAccounts?.lookupName ?? null,
      },
    ],
  };

  if (purchaseOrderItem) {
    const { purchaseOrderItemDistributions, itemRentalDates } = {
      ...purchaseOrderItem,
    };

    defaultValues = {
      ...purchaseOrderItem,
      itemRentalDates: itemRentalDates?.length === 0 ? null : itemRentalDates,
      purchaseOrderItemDistribution: !!purchaseOrderItemDistributions.nodes
        .length
        ? [
            ...purchaseOrderItemDistributions.nodes.map((row) => {
              const patch = Object.entries(row).reduce((res, [key, val]) => {
                if (
                  key === '_isUpdatable' ||
                  key === '_isDeletable' ||
                  key === '_createdDate' ||
                  key === '__typename'
                )
                  return res;
                else if (key === 'allocationPercent')
                  return {
                    ...res,
                    allocationPercent: val ? parseInt(val).toString() : null,
                  };
                else return { ...res, [key]: val };
              }, {});
              return patch as ItemDistributionValues;
            }),
            {
              ...DISTRIBUTION_INITIAL_VALUES,
              referenceCode6: referenceCode6!,
              referenceCode7: referenceCode7!,
              projectReference: projectReference!,
              setReference: setReference!,
              accountReference: accountCode === undefined ? null : accountCode,
              _accountName: lookupAccounts?.lookupName ?? null,
            },
          ]
        : [
            {
              ...DISTRIBUTION_INITIAL_VALUES,
              allocationPercent: '100',
              referenceCode6: referenceCode6!,
              referenceCode7: referenceCode7!,
              projectReference: projectReference!,
              setReference: setReference!,
              accountReference: accountCode === undefined ? null : accountCode,
              _accountName: lookupAccounts?.lookupName ?? null,
            },
          ],
      itemFromDate: purchaseOrderItem?.itemFromDate
        ? dateConvertions(purchaseOrderItem?.itemFromDate)
        : null,
      itemToDate: purchaseOrderItem?.itemToDate
        ? dateConvertions(purchaseOrderItem?.itemToDate)
        : null,
    };
  }

  return Object.entries(defaultValues).reduce((res, [key, val]) => {
    if (
      key === 'purchaseOrderItemType' ||
      key === 'expenditureType' ||
      key === 'unitOfMeasureItem' ||
      key === 'purchaseOrderItemDistributions' ||
      key === 'purchaseOrderRevisionHistoriesByPoGroupId'
    )
      return res;
    else return { ...res, [key]: val };
  }, {}) as PurchaseOrderItemFormProps;
};

const getPatchData = (
  formValues: any,
  purchaseOrderItem: PurchaseOrderItemFormProps
) => {
  const patch = Object.entries?.(formValues)?.reduce((res, [key, val]) => {
    if (
      key === 'purchaseOrderItemType' ||
      key === 'expenditureType' ||
      key === 'unitOfMeasureItem' ||
      key === 'purchaseOrderItemDistributions' ||
      key === 'purchaseOrderItemDistribution' ||
      key === 'purchaseOrderRevisionHistoriesByPoGroupId'
    )
      return res;
    if (key === 'itemRentalDates') {
      const formDates = val as string[];
      const formattedFormDates =
        formDates?.map((date) => dateFormat(date)) || [];
      const defaultDates =
        (purchaseOrderItem[
          key as keyof PurchaseOrderItemFormProps
        ] as string[]) || [];
      const formattedDefaultDates = !!defaultDates?.length
        ? defaultDates?.map((date) => dateFormat(date))
        : [];
      const isEqual =
        formattedFormDates.length === formattedDefaultDates.length &&
        formattedFormDates?.every(
          (ele, index) => ele === formattedDefaultDates[index]
        );
      if (!isEqual) {
        return { ...res, [key]: val };
      } else {
        return res;
      }
    }
    if (val !== purchaseOrderItem[key as keyof PurchaseOrderItemFormProps]) {
      if (key === 'itemDays' || key === 'itemWeeks' || key === 'itemMonths') {
        const value = val as string;
        return { ...res, [key]: parseInt(value) };
      } else if (key === 'itemQuantity' && val === null) {
        return { ...res, itemQuantity: '1' };
      } else return { ...res, [key]: val };
    }
    return res;
  }, {});
  return patch;
};

const getDistributionFormValues = (
  values: ItemDistributionValues,
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const patch = Object.entries(values).reduce((res, [key, val]) => {
    if (
      key === 'id' ||
      key === '_rowTimestamp' ||
      key === '_accountName' ||
      (key === 'projectReference' &&
        userDefaults?.distributionLayoutType?.layoutType ===
          TransactionLayout.DEFAULT_DISTRIBUTION_LAYOUT)
    )
      return res;
    else return { ...res, [key]: val };
  }, {});
  return patch;
};

const getNonNullRows = (
  distributionRows: ItemDistributionValues[],
  userDefaults?: UserDefaults_userDefaults_nodes | undefined
) => {
  return distributionRows.filter((row) => {
    const patch = Object.entries(row).reduce((res, [key, val]) => {
      if (
        (key === 'referenceCode6' && val === userDefaults?.referenceCode6) ||
        (key === 'referenceCode7' && val === userDefaults?.referenceCode7) ||
        (key === 'projectReference' &&
          val === userDefaults?.projectReference) ||
        (key === 'setReference' && val === userDefaults?.setReference) ||
        (key === 'accountReference' && val === userDefaults?.accountCode) ||
        (key === '_accountName' &&
          val === userDefaults?.lookupAccounts?.lookupName)
      )
        return res;
      else if (
        val !== DISTRIBUTION_INITIAL_VALUES[key as keyof ItemDistributionValues]
      )
        return { ...res, [key]: val };
      return res;
    }, {});

    return !isEmpty(patch);
  });
};

const getNewDistributions = (
  formikValues: PurchaseOrderItemFormProps,
  userDefaults: UserDefaults_userDefaults_nodes | undefined
) => {
  const distributions = formikValues.purchaseOrderItemDistribution
    ? getNonNullRows(
        formikValues.purchaseOrderItemDistribution || [],
        userDefaults
      )
        ?.filter((row) => row.id === null)
        .map((item) => getDistributionFormValues(item, userDefaults))
    : [];

  return distributions;
};

const getUpdatedDistributions = (
  formikValues: PurchaseOrderItemFormProps,
  defaultValues: PurchaseOrderItemFormProps
) => {
  const { purchaseOrderItemDistribution: defaultDistribution } = {
    ...defaultValues,
  };
  return formikValues.purchaseOrderItemDistribution
    ?.filter((row) => {
      const index = defaultDistribution?.findIndex(
        (item) => item.id === row.id && row.id !== null
      );

      if (index !== -1) {
        const patch = Object.entries(row).reduce((res, [key, val]) => {
          if (
            val !==
            defaultDistribution?.[index!][key as keyof ItemDistributionValues]
          )
            return { ...res, [key]: val };
          else return res;
        }, {});
        return !isEmpty(patch);
      } else return false;
    })
    .map((item) => {
      const patch = Object.entries(item).reduce((res, [key, val]) => {
        if (
          key === '_createdDate' ||
          key === '_rowTimestamp' ||
          key === '__typename' ||
          key === '_isDeletable' ||
          key === '_isUpdatable' ||
          key === 'id' ||
          key === '_accountName'
        )
          return res;
        else return { ...res, [key]: val };
      }, {});

      return {
        id: item.id,
        rowTimestamp: item._rowTimestamp,
        purchaseOrderItemDistributionUpdatePatch:
          patch as PurchaseOrderItemDistributionPatch,
      } as PurchaseOrderItemDistributionUpdateTypeInput;
    });
};

const getRemovedDistributions = (
  data: PurchaseOrderItem | undefined,
  formikValues: PurchaseOrderItemFormProps
) => {
  return data?.purchaseOrderItem?.purchaseOrderItemDistributions.nodes
    .filter((item) => {
      return (
        formikValues.purchaseOrderItemDistribution?.findIndex((innerItem) => {
          return innerItem.id === item.id;
        }) === -1
      );
    })
    .map(
      (item) =>
        ({
          rowTimestamp: item._rowTimestamp,
          id: item.id,
        } as EntityDeleteInput)
    );
};

export {
  getDefaultValues,
  getPatchData,
  getDistributionFormValues,
  getNonNullRows,
  getRemovedDistributions,
  getNewDistributions,
  getUpdatedDistributions,
  getDefaultDistribution,
};
