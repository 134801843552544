import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Separator, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { CurrentUserProfile } from 'common/graphql/__generated__/CurrentUserProfile';
import {
  DocumentPoolInput,
  DocumentPoolPatch,
  DocumentPoolsOrderBy
} from 'common/types/globalTypes';
import {
  EntityAction,
  EntityType,
  PanelCommonProps,
  PANEL_COMMON_WIDTH
} from 'common/types/utility';
import {
  DocumentPoolDelete,
  DocumentPoolDeleteVariables
} from 'documents/documentsPool/list/__generated__/DocumentPoolDelete';
import {
  DocumentPools,
  DocumentPoolsVariables,
  DocumentPools_documentPools
} from 'documents/documentsPool/list/__generated__/DocumentPools';
import {
  GetDocumentPoolCommonData,
  GetDocumentPoolCommonDataVariables
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Prompt } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ActionsMenu } from '../ActionMenu';
import { DOCUMENT_POOL_INITIAL_VALUES } from '../constant';
import { DocumentPoolForm } from '../DocumentPoolForm';
import { validationSchema } from '../documentPoolValidation';
import { ShimmerView } from '../EditDocumentPool/ShimmerView/ShimmerViews';
import { DocumentPoolFormValues } from '../types';
import {
  DocumentPoolCreate,
  DocumentPoolCreateVariables
} from '../__generated__/DocumentPoolCreate';
import {
  DocumentPoolUpdate,
  DocumentPoolUpdateVariables
} from '../__generated__/DocumentPoolUpdate';
import {
  GetDocumentPoolDetails,
  GetDocumentPoolDetailsVariables
} from '../__generated__/GetDocumentPoolDetails';
import { useStyles } from './index.styles';
const COMMON_DATA = loader('../../../GetDocumentPoolCommonData.graphql');
const CREATE_DOCUMENT_POOL = loader('../CreateDocumentPool.graphql');
const DOCUMENT_POOL = loader('../../list/DocumentPools.graphql');
const GET_DOCUMENTPOOL_DETAILS = loader('../GetDocumentPoolDetails.graphql');
const UPDATE_DOCUMENT_POOL = loader('../UpdateDocumentPool.graphql');
const CURRENT_USER_PROFILE = loader(
  '../../../../common/graphql/CurrentUserProfile.graphql'
);
const DELETE_DOCUMENT_POOL = loader('../../list/DeleteDocumentPool.graphql');

export const DocumentPoolView: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const [isSaveAnother, { setTrue: saveAnother, setFalse: dontSaveAnother }] =
    useBoolean(false);

  const { documentGroupId } = useParams<{
    documentGroupId: string | undefined;
  }>();
  const isNew = !documentGroupId;

  const { data: currentProfileData, loading: currentProfileLoading } = useQuery<
    CurrentUserProfile | undefined
  >(CURRENT_USER_PROFILE, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [
    getDocumentPoolDetails,
    { data: documentPoolDetailsData, loading: documentPoolDetailsLoading },
  ] = useLazyQuery<GetDocumentPoolDetails, GetDocumentPoolDetailsVariables>(
    GET_DOCUMENTPOOL_DETAILS
  );

  useEffect(() => {
    if (documentGroupId && documentGroupId !== '') {
      getDocumentPoolDetails({
        variables: {
          id: documentGroupId!,
        },
      });
    }
  }, [getDocumentPoolDetails, documentGroupId, isNew]);

  const [updateDocumentPool, { loading: updateDocumentPoolLoading }] =
    useMutation<DocumentPoolUpdate, DocumentPoolUpdateVariables>(
      UPDATE_DOCUMENT_POOL,
      { errorPolicy: 'all' }
    );

  const [documentPoolDelete] = useMutation<
    DocumentPoolDelete,
    DocumentPoolDeleteVariables
  >(DELETE_DOCUMENT_POOL, { errorPolicy: 'all' });

  let initialValues: DocumentPoolFormValues = DOCUMENT_POOL_INITIAL_VALUES;
  //In create
  if (currentProfileData && isNew) {
    initialValues = {
      ...initialValues,
      defaultDepartmentId: currentProfileData.currentUserProfile?.departmentId!,
    };
  }

  if (
    documentPoolDetailsData &&
    documentPoolDetailsData.documentPool &&
    !isNew
  ) {
    initialValues = { ...documentPoolDetailsData.documentPool };
  }

  const { loading: commonLoading, data: commonData } = useQuery<
    GetDocumentPoolCommonData,
    GetDocumentPoolCommonDataVariables
  >(COMMON_DATA, {
    variables: {
      isDocumentUpload: false,
    },
  });

  const [createDocumentPool, { loading: addDocumentPoolLoading }] = useMutation<
    DocumentPoolCreate,
    DocumentPoolCreateVariables
  >(CREATE_DOCUMENT_POOL, { errorPolicy: 'all' });

  const handleSubmit = async (values: DocumentPoolFormValues) => {
    const { ...documentPool } = values;
    if (isNew) {
      const { errors, data } = await createDocumentPool({
        variables: {
          input: {
            documentPool: [documentPool as DocumentPoolInput],
          },
        },
        update: (cache, { data }) => {
          if (data?.documentPoolCreate?.documentPools) {
            const cacheData = cache.readQuery<
              DocumentPools,
              DocumentPoolsVariables
            >({
              query: DOCUMENT_POOL,
              variables: {
                orderBy: [DocumentPoolsOrderBy.NAME_ASC],
              },
            });
            if (cacheData?.documentPools) {
              const updatedData: DocumentPools = {
                documentPools: {
                  ...cacheData?.documentPools,
                  nodes: [
                    ...data?.documentPoolCreate?.documentPools!,
                    ...cacheData?.documentPools?.nodes!,
                  ],
                  pageInfo: cacheData?.documentPools?.pageInfo!,
                },
              };
              cache.writeQuery<DocumentPools, DocumentPoolsVariables>({
                query: DOCUMENT_POOL,
                variables: {
                  orderBy: [DocumentPoolsOrderBy.NAME_ASC],
                },
                data: updatedData,
              });
            }
          }
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        if (
          !isSaveAnother &&
          data?.documentPoolCreate?.documentPools?.[0]?.id
        ) {
          history.replace(
            `/doc/groups/group/${data?.documentPoolCreate?.documentPools?.[0]?.id}`
          );
        }
        addToast('Folder created successfully', {
          appearance: 'success',
        });
      }
    } else {
      const documentPoolPatch: DocumentPoolPatch = Object.entries(
        documentPool
      ).reduce((res, [key, val]) => {
        if (val !== initialValues[key as keyof DocumentPoolFormValues]) {
          return { ...res, [key]: val };
        }
        return res;
      }, {});
      const { errors } = await updateDocumentPool({
        variables: {
          input: {
            documentPoolsUpdate: [
              {
                id: documentPoolDetailsData?.documentPool?.id,
                rowTimestamp:
                  documentPoolDetailsData?.documentPool?._rowTimestamp,
                documentPoolPatch: !isEmpty(documentPoolPatch)
                  ? documentPoolPatch
                  : undefined,
              },
            ],
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<
            GetDocumentPoolDetails,
            GetDocumentPoolDetailsVariables
          >({
            query: GET_DOCUMENTPOOL_DETAILS,
            variables: {
              id: documentPoolDetailsData?.documentPool?.id!,
            },
          });
          if (
            cacheData?.documentPool &&
            data?.documentPoolUpdate?.documentPools
          ) {
            const updatedData: GetDocumentPoolDetails = {
              documentPool: {
                ...cacheData.documentPool,
                ...data.documentPoolUpdate.documentPools[0],
              },
            };
            cache.writeQuery<
              GetDocumentPoolDetails,
              GetDocumentPoolDetailsVariables
            >({
              query: GET_DOCUMENTPOOL_DETAILS,
              variables: {
                id: documentPoolDetailsData?.documentPool?.id!,
              },
              data: updatedData!,
            });
          }
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else
        addToast('Folder saved successfully', {
          appearance: 'success',
        });
    }
  };

  const _documentPoolDelete = async () => {
    const { errors } = await documentPoolDelete({
      variables: {
        input: {
          entityDelete: [
            {
              id: documentPoolDetailsData?.documentPool?.id!,
              rowTimestamp:
                documentPoolDetailsData?.documentPool?._rowTimestamp!,
            },
          ],
        },
      },
      update: (cache, { data }) => {
        if (data?.documentPoolDelete?.deletedEntities?.length) {
          const identity = cache.identify({
            ...documentPoolDetailsData?.documentPool,
          });
          cache.evict({ id: identity });
          cache.gc();
          cache.modify({
            fields: {
              documentPools: (existingData: DocumentPools_documentPools) => {
                return {
                  ...existingData,
                };
              },
            },
          });
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/doc/groups');
    }
  };

  const onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/doc/groups');
        }}
      >
        <Stack grow horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">
              {isNew ? 'Create Folder' : 'Edit Folder'}
            </Text>
            <UnsavedIndicator
              visible={
                !isNew && !documentPoolDetailsLoading && dirty && !isSubmitting
              }
            />
          </Stack>
        </Stack>
        <Stack verticalAlign="start" horizontal tokens={{ childrenGap: 10 }}>
          <ActionMessageModal
            visible={!isNew}
            entityType={EntityType.Folder}
            action={EntityAction.Remove}
            disabled={!documentPoolDetailsData?.documentPool?._isDeletable}
            onConfirm={_documentPoolDelete}
          />
        </Stack>
      </PanelHeader>
    );
  };

  return (
    <Formik<DocumentPoolFormValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        submitForm,
        isSubmitting,
        dirty,
        resetForm,
        errors,
        setFieldValue,
      }) => {
        return (
          <DraggablePanel
            {...PanelCommonProps}
            initialWidth={PANEL_COMMON_WIDTH}
            minWidth={PANEL_COMMON_WIDTH}
            onRenderHeader={() => onRenderHeader(dirty, isSubmitting)}
            onRenderFooter={() => (
              <FooterActionBar
                createNewText="New Folder"
                addNewForm={() => {
                  history.replace('/doc/groups/group');
                }}
                disabled={
                  commonLoading || {
                    save: !dirty || Object.keys(errors).length > 0,
                    cancel: !dirty,
                  }
                }
                isSubmitting={isSubmitting}
                isCreate={isNew ? true : false}
                onCancel={() => {
                  history.replace('/doc/groups');
                }}
                onSave={async () => {
                  dontSaveAnother();
                  await submitForm();
                }}
                onSaveAnother={async () => {
                  saveAnother();
                  await submitForm();
                  resetForm();
                }}
                isLoading={addDocumentPoolLoading || updateDocumentPoolLoading}
              />
            )}
            isBlocking={isNew === true ? true : false}
            isOpen
            onDismiss={() => {
              history.replace('/doc/groups');
            }}
            isLightDismiss
          >
            <Form>
              {/* COMMON DOCUMENT GROUP FORM */}
              <Stack className={styles.formMainContainer}>
                {!isNew && (
                  <>
                    {/* ACTION MENU FOR PROTECT, NOTES AND HISTORY */}
                    {!documentPoolDetailsLoading ? (
                      <ActionsMenu
                        commonData={commonData}
                        documentPoolId={documentGroupId || ''}
                        documentPoolData={
                          documentPoolDetailsData?.documentPool!
                        }
                      />
                    ) : (
                      <ShimmerView />
                    )}
                    <Separator />
                  </>
                )}
                {!documentPoolDetailsLoading && !currentProfileLoading && (
                  <DocumentPoolForm
                    commonData={commonData}
                    setComponentFieldValue={setFieldValue}
                    isAdministrator={
                      currentProfileData?.currentUserProfile?.isAdministrator!
                    }
                    extractionTypes={
                      documentPoolDetailsData?.documentPool
                        ?.defaultDocumentTypes?.extractionTypes.nodes || []
                    }
                  />
                )}
              </Stack>
              {/* INDIVIDUAL DOCUMENT GROUP FORM */}
              <Prompt
                when={dirty && !isSubmitting}
                message="Are you sure you want to leave your changes unsaved?"
              />
            </Form>
          </DraggablePanel>
        );
      }}
    </Formik>
  );
};
