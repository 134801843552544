import { TagsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';

type OrderLookup = {
  [direction in OrderDirection]: TagsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [TagsOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [TagsOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [TagsOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [TagsOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'badgeName',
    {
      [OrderDirection.ASC]: [TagsOrderBy.BADGE_NAME_ASC],
      [OrderDirection.DESC]: [TagsOrderBy.BADGE_NAME_DESC],
    },
  ],
  [
    'badgeColor',
    {
      [OrderDirection.ASC]: [TagsOrderBy.BADGE_COLOR_ASC],
      [OrderDirection.DESC]: [TagsOrderBy.BADGE_COLOR_DESC],
    },
  ],
  [
    'isPrivate',
    {
      [OrderDirection.ASC]: [TagsOrderBy.IS_PRIVATE_ASC],
      [OrderDirection.DESC]: [TagsOrderBy.IS_PRIVATE_DESC],
    },
  ],
  [
    'isMonitored',
    {
      [OrderDirection.ASC]: [TagsOrderBy._IS_MODIFIED_ASC],
      [OrderDirection.DESC]: [TagsOrderBy.IS_MONITORED_DESC],
    },
  ],
  [
    '_isSystemGenerated',
    {
      [OrderDirection.ASC]: [TagsOrderBy._IS_SYSTEM_GENERATED_ASC],
      [OrderDirection.DESC]: [TagsOrderBy._IS_SYSTEM_GENERATED_DESC],
    },
  ],
  [
    '_isActive',
    {
      [OrderDirection.ASC]: [TagsOrderBy._IS_ACTIVE_ASC],
      [OrderDirection.DESC]: [TagsOrderBy._IS_ACTIVE_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [TagsOrderBy.NAME_ASC];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const badgeColor = (badgeColor: string) => {
  switch (badgeColor) {
    case 'YELLOW':
      return '#ffd000';
    case 'RED':
      return '#d14249';
    case 'GREEN':
      return '#29a849';
    case 'CYAN':
      return '#11e9e9';
    case 'MAGENTA':
      return '#c658ca';
    default:
      return '#0078d4';
  }
};
