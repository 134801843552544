import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { PivotCustomRender } from './PivotComponent';
import { useStyles } from './index.styles';
import { AttachSection } from './actionMenuContents/PaymentTrackingAttach';
import { PaymentTrackingNotesSection } from './actionMenuContents/PaymentTrackingNotes';
import { TagsSection } from './actionMenuContents/PaymentTrackingTags';
import { PaymentTrackingMessage } from './actionMenuContents/PaymentTrackingMessage';
import { GetPaymentDetails_payment } from '../__generated__/GetPaymentDetails';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { PaymentUploadDocument_paymentUploadDocument } from './actionMenuContents/PaymentTrackingAttach/UploadForm/__generated__/PaymentUploadDocument';
import { PivotDisplay } from './PivotDisplay';
import { PaymentTypes_secureRowLevels } from '../__generated__/PaymentTypes';
import { HistoryActionMenuView } from 'common/components/History';
import { ProtectSection } from 'common/components/Protect';
import { useToasts } from 'react-toast-notifications';
import {
  PaymentRowProtection,
  PaymentRowProtectionVariables,
} from './actionMenuContents/__generated__/PaymentRowProtection';
import {
  UserEntityMessageCounts,
  UserEntityMessageCountsVariables,
} from 'common/graphql/__generated__/UserEntityMessageCounts';
import { ListInvoiceNotesVariables } from 'common/graphql/__generated__/ListInvoiceNotes';
import {
  ListPaymentNotes,
  ListPaymentNotesVariables,
} from 'common/graphql/__generated__/ListPaymentNotes';
// const ENTITY_NOTES_LIST = loader(
//   './actionMenuContents/PaymentTrackingNotes/ListPaymentNotes.graphql'
// );
const ENTITY_NOTES_LIST = loader(
  '../../../../../common/graphql/ListPaymentNotes.graphql'
);
const PAYMENT_ROW_PROTECTION = loader(
  './actionMenuContents/PaymentRowProtection.graphql'
);
const PAYMENT_DETAILS = loader('../GetPaymentDetails.graphql');

const ENTITY_MESSAGE_COUNTS = loader(
  '../../../../../common/graphql/UserEntityMessageCounts.graphql'
);

const ActionConsts = {
  protect: 'Protect_Action',
  attach: 'Attach_Action',
  notes: 'Notes_Action',
  history: 'History_Action',
  tags: 'Tags_Action',
  messages: 'Messages_Action',
};
interface ActionMenuProps {
  payment: GetPaymentDetails_payment;
  secureRowLevels: PaymentTypes_secureRowLevels | undefined | null;
  isViewOnly?:boolean
  onUpload?: (
    fileSelected: File,
    documentData: PaymentUploadDocument_paymentUploadDocument,
    toastId: string
  ) => void;
}
export const ActionsMenu: React.FC<ActionMenuProps> = ({
  payment,
  secureRowLevels,
  onUpload,
  isViewOnly=false
}) => {
  const [selectedKey, setSelectedKey] = React.useState(ActionConsts.protect);
  const styles = useStyles();
  const { addToast } = useToasts();
  const [expandMenu, setExpandMenu] = useState(false);

  const {
    id,
    secureRowLevel,
    paymentHistoriesByEntityId,
    _isProtected,
    entityDocumentsByEntityId,
    entityTagsByEntityId,
  } = { ...payment };

  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListPaymentNotes,
    ListPaymentNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id,
    },
  });

  const getEntityNotes = () => {
    const variables: ListInvoiceNotesVariables = {
      id,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [id]);
  const [updateRowProtection, { loading: updateLoading, error: updateError }] =
    useMutation<PaymentRowProtection, PaymentRowProtectionVariables>(
      PAYMENT_ROW_PROTECTION
    );

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          entityId: id,
        },
        skip: !id,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );

  const updateRowLevel = async (selectedLevel: string | null) => {
    const inputData =
      selectedLevel !== null
        ? {
            entityId: id,
            rowSecurityId: selectedLevel,
          }
        : { entityId: id, isProtectionRemoval: true };
    await updateRowProtection({
      variables: {
        input: inputData,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAYMENT_DETAILS,
          variables: {
            id,
          },
        },
      ],
    });
    if (!updateError) {
      addToast('Security/Protection level upgraded', {
        appearance: 'success',
      });
    } else
      addToast('Failed to upgrade protection level', {
        appearance: 'error',
      });
  };
  const getSelectedSection = (key: string) => {
    switch (key) {
      case 'Protect_Action':
        return (
          <ProtectSection
            secureRowLevels={secureRowLevels?.nodes || []}
            secureRowLevelId={secureRowLevel?.id}
            updateLoading={updateLoading}
            updateRowLevel={(selectedLevel) => {
              updateRowLevel(selectedLevel);
            }}
          />
        );
      case 'Notes_Action':
        return (
          <PaymentTrackingNotesSection
            paymentTrackingId={id}
            data={entityNotesData?.payment?.notesByEntityId.nodes || []}
            listRefreshRequest={getEntityNotesMemo}
            isViewOnly={isViewOnly}
          />
        );
      case 'History_Action':
        return (
          <HistoryActionMenuView
            moduleName="Payment Tracking"
            historyData={paymentHistoriesByEntityId.nodes || []}
          />
        );
      case 'Tags_Action':
        return <TagsSection paymentId={id} isViewOnly={isViewOnly} />;
      case 'Attach_Action':
        return (
          <AttachSection
            secureRowLevel={secureRowLevels?.nodes!}
            paymentDetails={payment}
            onUpload={onUpload}
            isViewOnly={isViewOnly}
          />
        );
      case 'Messages_Action':
        return (
          <PaymentTrackingMessage
            paymentID={id}
            userMessageCounts={userMessageCounts}
            onCreateMessage={userMessageCountsRefetch}
            isViewOnly={isViewOnly}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey || ActionConsts.protect);
                }}
                aria-label="Menu_Actions"
              >
                <PivotItem
                  itemKey={ActionConsts.protect}
                  itemIcon={_isProtected ? 'Lock' : 'Unlock'}
                  headerText="Protect"
                  itemCount={0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.attach}
                  itemIcon="Attach"
                  headerText="Files"
                  itemCount={entityDocumentsByEntityId.nodes.length || 0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    entityNotesData?.payment?.notesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={paymentHistoriesByEntityId.nodes.length || 0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.tags}
                  itemIcon="Tag"
                  headerText="Tags"
                  itemCount={entityTagsByEntityId.nodes.length}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.messages}
                  itemIcon="Message"
                  headerText="Messages"
                  itemCount={
                    userMessageCounts?.userEntityMessageCounts?.totalMessages ||
                    0
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
                ariaLabel="Collapse"
              />
            </Stack>
          </Stack>

          <Stack>{getSelectedSection(selectedKey)}</Stack>
        </Stack>
      ) : (
        <Stack horizontalAlign="space-between" horizontal>
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Protect"
                iconName={_isProtected ? 'Lock' : 'Unlock'}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.protect);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Files"
                iconName="Attach"
                number={entityDocumentsByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.attach);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Notes"
                iconName="Page"
                number={entityNotesData?.payment?.notesByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.notes);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                number={paymentHistoriesByEntityId.nodes.length || 0}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.history);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Tags"
                iconName="Tag"
                number={entityTagsByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.tags);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Messages"
                iconName="Message"
                number={
                  userMessageCounts?.userEntityMessageCounts?.totalMessages!
                }
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.messages);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
              ariaLabel="Expand"
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
