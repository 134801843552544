import { makeVar, useReactiveVar } from '@apollo/client';
import { IconButton, Stack, useTheme } from '@fluentui/react';
import { isPOCreated } from 'purchaseOrder/view';
import { PurchaseOrder_purchaseOrder_purchaseOrderItems_nodes } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { PurchaseOrderCommonData } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import React, { useEffect } from 'react';
import { PurchaseOrderDataType } from '..';
import { PurchaseOrderItemProps } from '../../BasicForm';
import { POItemsForm } from './POItemsForm';
import { PurchaseOrderValues } from 'purchaseOrder/view/types';
import { useFormContext, useWatch } from 'react-hook-form';

interface POItemsModalProps {
  purchaseOrderData: PurchaseOrderDataType | undefined;
  commonData: PurchaseOrderCommonData | undefined;
  inputsDisabled: boolean;
  purchaseOrderItemAvailable: PurchaseOrderItemProps[] | undefined;
}

export type POItemProps = PurchaseOrder_purchaseOrder_purchaseOrderItems_nodes;

export const showPurchaseOrderModal = makeVar<{
  isModalOpen: boolean;
  poItem?: POItemProps | undefined;
  isNew: boolean;
}>({
  isNew: true,
  isModalOpen: false,
  poItem: undefined,
});

export const POItemsModal: React.FC<POItemsModalProps> = ({
  commonData,
  purchaseOrderItemAvailable,
  purchaseOrderData,
  inputsDisabled,
}) => {
  const poModaReactiveProps = useReactiveVar(showPurchaseOrderModal);
  const { isModalOpen } = { ...poModaReactiveProps };
  const theme = useTheme();
  const isOpen = useReactiveVar(isPOCreated);
  const {
    formState: { isDirty },
  } = useFormContext<PurchaseOrderValues>();
  const isDisabled =
    !purchaseOrderData?.purchaseOrder?._isUpdatable ||
    !purchaseOrderItemAvailable ||
    isDirty;

  const watchValue = useWatch<PurchaseOrderValues>();

  useEffect(() => {
    if (isOpen) {
      showPurchaseOrderModal({
        poItem: undefined,
        isModalOpen: true,
        isNew: true,
      });
    }
  }, [isOpen]);

  return (
    <Stack>
      <IconButton
        text="Add Item"
        iconProps={{
          iconName: 'Add',
        }}
        styles={{
          root: {
            border: `1px solid ${theme.palette.themePrimary}`,
          },
        }}
        disabled={isDisabled}
        onClick={() => {
          // setDialogVisible(true);
          showPurchaseOrderModal({
            ...poModaReactiveProps,
            isModalOpen: true,
            isNew: true,
          });
        }}
      />
      {isModalOpen && (
        <POItemsForm
          isOpen={isModalOpen}
          businessUnitId={watchValue.businessUnitId || null}
          purchaseOrderData={purchaseOrderData}
          commonData={commonData}
          inputsDisabled={inputsDisabled}
          purchaseOrderItemAvailable={purchaseOrderItemAvailable}
        />
      )}
    </Stack>
  );
};
