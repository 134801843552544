import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  navHeader: {
    padding: 20,
    fontWeight: FontWeights.semibold,
  },
  nav: {
    width: 220,
    borderRight: `1px solid ${theme.palette.neutralLight}`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));
