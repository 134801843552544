import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: 80,
    marginBottom: 100,
  },
  fieldContainer: {
    flex: 1,
  },
  mtb3: {
    margin: '3px 0px',
  },
  mt15: {
    marginTop: 15,
  },
  mt20: {
    marginTop: 20,
  },
}));
