import { Stack, Text } from '@fluentui/react'
import clsx from 'clsx'
import { useCommonStyles } from 'common/styles'
import React from 'react'
import { useStyles } from './index.styles';
export const Header = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const columnHeadings: string[] = ["Account", "Description", "Business Unit", "Document Folder"];
  return (
    <Stack horizontal>
      {
        columnHeadings.map(
          (heading, index) => {
            return (
              <Stack className={styles.root} key={index}>
                <Stack
                  verticalAlign="center"
                  className={clsx(
                    styles.linkLightHover,
                    heading === "Account" ?
                      styles.accountHeaderCell :
                      styles.headerCell
                  )}
                >
                  <Text
                    nowrap
                    block
                    className={commonStyles.semibold}
                    variant="medium"
                  >
                    {heading}
                  </Text>
                </Stack>
              </Stack>
            )
          }
        )
      }
    </Stack>
  )
}
