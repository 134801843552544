import * as Yup from 'yup';
export const validationSchema = () =>
  Yup.object({
    companyOrLastName: Yup.string().required('Required').nullable(),
    vendorServiceRate: Yup.string().when('isRateRequired', {
      is: true,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    supplierAddressesBySupplierId: Yup.array().of(
      Yup.object().shape({
        addressLine1: Yup.string().required('Required').nullable(),
        emailAddress: Yup.string()
          .email('Enter a valid email address')
          .nullable(),
      })
    ),
    supplierContactsBySupplierId: Yup.array().of(
      Yup.object().shape({
        emailAddress: Yup.string()
          .email('Enter a valid email address')
          .nullable(),
      })
    ),
  });
