import { Stack } from '@fluentui/react';
import clsx from 'clsx';
import { FormikDropdown, FormikTextField } from 'common/components';
import { RemoveRowButton } from 'common/components/Buttons';
import { useFormikContext } from 'formik';
import React from 'react';
import { DietaryRequirementsOptionsProps } from '..';
import { TravelerValues } from '../../../types';
import { useStyles } from './index.styles';
interface DietaryRequirementsInputRowProps {
  fieldName: string;
  dietaryRequirementsOptions: DietaryRequirementsOptionsProps[];
  onChange: () => void;
  onRemove: () => void;
  isFirstRow: boolean;
  rowIndex: number;
  inputsDisabled: boolean;
}
export const DietaryRequirementsInputRow: React.FC<DietaryRequirementsInputRowProps> = ({
  fieldName,
  dietaryRequirementsOptions,
  onChange,
  onRemove,
  isFirstRow,
  rowIndex,
  inputsDisabled
}) => {
  const styles = useStyles();
  const { values, setFieldValue } = useFormikContext<TravelerValues>();
  const showDeleteButton = !!values?.travelerDietaryRequirementsByTravelerId?.[rowIndex].dietaryRequirementId
  const showDescription = !!values?.travelerDietaryRequirementsByTravelerId?.[rowIndex].isDescriptionRequired

  const onDietaryRequirementsChange = async (selectedOption: DietaryRequirementsOptionsProps) => {
    onChange();
    if (selectedOption.isDescriptionRequired) {
      await setFieldValue(`${fieldName}isDescriptionRequired`, true)
    }
    else {
      await setFieldValue(`${fieldName}isDescriptionRequired`, false)
    }
    await setFieldValue(`${fieldName}description`, null)
  }

  return (
    <Stack
      horizontal
      tokens={{
        childrenGap: 20,
        padding: "10px 0px"
      }}
    >
      <Stack.Item className={styles.inputsContainer}>
        {
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            {
              !inputsDisabled &&
              <Stack.Item className={clsx(!isFirstRow && styles.removeButtonContainer)}>
                {
                  showDeleteButton &&
                  <RemoveRowButton
                    onClick={() => {
                      onRemove();
                    }}
                  />
                }
              </Stack.Item>
            }
            <FormikDropdown
              placeholder="Select Dietary Requirements"
              options={dietaryRequirementsOptions || []}
              name={`${fieldName}dietaryRequirementId`}
              onChange={(_e, option) => {
                const selectedOption = option as DietaryRequirementsOptionsProps;
                onDietaryRequirementsChange(selectedOption)
              }}
              onClear={onRemove}
              styles={{
                dropdown: {
                  width: 547
                }
              }}
              disabled={inputsDisabled}
            />
          </Stack>
        }
      </Stack.Item>
      <Stack.Item className={styles.inputsContainer}>
        {
          showDescription &&
          <FormikTextField
            placeholder="Description"
            name={`${fieldName}description`}
            disabled={inputsDisabled}
          />
        }
      </Stack.Item>
    </Stack>
  )
}
