import { makeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { SupplierCommonData_countries_nodes } from '../__generated__/SupplierCommonData';
import { AddressForm } from './AddressForm';
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
}));
interface AddressesProps {
  isEdit: boolean;
  countries: SupplierCommonData_countries_nodes[];
  isUpdatable: boolean | null | undefined;
}
export const Addresses: React.FC<AddressesProps> = ({
  countries,
  isEdit,
  isUpdatable
}) => {
  const styles = useStyles();
  return (
    <>
      <Stack
        className={styles.mainContainer}
      >
        <AddressForm
          isEdit={isEdit}
          countries={countries}
          isUpdatable={isUpdatable}
        />
      </Stack>

    </>
  )
}
