import { OccupationProps } from '..';
import { OCCUPATION_VALUES } from './constants';
import { OccupationValues } from './types';

type DefaultValueProps = Pick<OccupationProps, 'occupation'>;

export const getDefaultValues = (props: DefaultValueProps) => {
  const { occupation } = { ...props };
  const { ...occupationFields } = {
    ...occupation,
  };
  let defaultValues: OccupationValues = OCCUPATION_VALUES;
  defaultValues = { ...occupationFields };

  return defaultValues;
};
