import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  callout: {
    // padding: '15px',
    minWidth: 500,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  icon: {
    fontSize: 18,
    color: theme.palette.neutralSecondaryAlt,
  },
  container: {
    padding: '20px',
    backgroundColor: theme.palette.neutralLighterAlt,
  },
}));
