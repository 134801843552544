import { FontSizes, makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  ratingsValue: {
    color: theme.palette.themePrimary, fontSize: FontSizes.size68, fontWeight: 'bold' 
  },
  totalRating:{
    fontSize: FontSizes.size24
  },
  stackColumn: {
    flex: 1
  },
  container: {
    marginTop: 20
  },
  ratingsValueContainer: {
    marginRight: 20
  }
}));