import { makeStyles } from "@fluentui/react";
export const useStyles = makeStyles((theme) => ({
  defaultButtonContainer: {
    marginBottom: 30
  },
  formRow: {
    margin: "20px 0px"
  },
  fieldContainer: {
    flex: 1
  },
  sendCheckBoxContainer: {
    marginRight: 40
  },
  addressListButton: {
    width: 120,
    height: 40
  },
  countryRegionContainer: {
    flex: 2
  }
}));