import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 10,
  },
  marginT10: {
    marginTop: 10,
  },
}));
