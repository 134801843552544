import {
  IComboBoxOption,
  IComboBoxStyles,
  SelectableOptionMenuItemType,
} from '@fluentui/react';
import { FormikComboBox, FormikTextField } from 'common/components';
import React from 'react';
import { CategoryMatrix } from '../types';

interface UserCategoryMatrixOption extends IComboBoxOption {
  key: keyof CategoryMatrix | 'selectAll';
}
const USER_CATEGORY_MATRIX_OPTIONS: UserCategoryMatrixOption[] = [
  {
    key: 'selectAll',
    text: 'Select All',
    itemType: SelectableOptionMenuItemType.SelectAll,
  },
  { key: 'isCustomerMetric', text: 'Customer Metric' },
  { key: 'isCostMetric', text: 'Cost Metric' },
  { key: 'isEmployeeMetric', text: 'Employee Metric' },
  { key: 'isPayablesMetric', text: 'Payables Metric' },
  { key: 'isEnvironmentalMetric', text: 'Environmental Metric' },
  { key: 'isPayrollMetric', text: 'Payroll Metric' },
  { key: 'isPettyCashMetric', text: 'Petty Cash Metric' },
  { key: 'isPurchasingMetric', text: 'Purchasing Metric' },
  { key: 'isRedFlag', text: 'Red Flag' },
  { key: 'isReceivablesMetric', text: 'Receivables Metric' },
  { key: 'isSupplierMetric', text: 'Supplier Metric' },
  { key: 'isTravelMetric', text: 'Travel Metric' },
  { key: 'isVendorMetric', text: 'Vendor Metric' },
];

export const BasicForm = () => {
  return (
    <>
      <FormikTextField
        name="name"
        label="Name"
        placeholder="Enter name"
        required
      />
      <FormikTextField
        name="description"
        label="Description"
        multiline
        placeholder="Enter description"
      />
      <FormikComboBox
        scrollSelectedToTop={true}
        placeholder="Metrics"
        options={USER_CATEGORY_MATRIX_OPTIONS}
        name="metrics"
        label="Metrics"
        multiSelect
        styles={comboBoxStyles}
      />
    </>
  );
};

const comboBoxStyles: Partial<IComboBoxStyles> = {
  optionsContainerWrapper: { height: 300 },
};
