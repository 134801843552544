import { makeStyles } from '@fluentui/react';
import { TRIP_MODAL_FOOTER_HEIGHT } from '.';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.neutralLighterAlt,
    padding: '0px 20px',
    bottom: 0,
    left: 0,
    height: TRIP_MODAL_FOOTER_HEIGHT,
    width: 1350,
  },
}));
