import React from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

import {
  CreateMessageData,
  CreateNewMessage,
} from 'common/components/CreateNewMessage';
import {
  MessageSupplierCreate,
  MessageSupplierCreateVariables,
} from './__generated__/MessageSupplierCreate';
import { useToasts } from 'react-toast-notifications';
import { PivotKeys } from 'common/components/MessagesSections';
const ADD_NEW_MESSAGE = loader('./MessageSupplierCreate.graphql');
const GET_SUPPLIER_MESSAGE = loader('../SupplierMessages.graphql');
interface NewMessageProps {
  supplierID: string;
  selectedKey: string;
  onCreateMessage: () => void;
}

export const NewMessage: React.FC<NewMessageProps> = ({
  supplierID,
  selectedKey,
  onCreateMessage,
}) => {
  const { addToast } = useToasts();
  const [createMessage, { loading }] = useMutation<
    MessageSupplierCreate,
    MessageSupplierCreateVariables
  >(ADD_NEW_MESSAGE);

  const messageFilter =
    selectedKey === PivotKeys.sent
      ? { _isOutgoingMessage: { equalTo: true } }
      : { _isIncomingMessage: { equalTo: true } };

  const onSubmit = async (formData: CreateMessageData) => {
    const { messageComment, reminderDates, isResponseRequired, receivers } = {
      ...formData,
    };
    const { communicationChannel, department, user } = { ...receivers };
    const { errors } = await createMessage({
      variables: {
        input: {
          entityId: supplierID,
          message: { messageComment, reminderDates, isResponseRequired },
          communicationChannel,
          department,
          user,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_SUPPLIER_MESSAGE,
          variables: {
            id: supplierID,
            first: 10,
            filter: messageFilter,
          },
        },
      ],
    });

    if (!errors) {
      onCreateMessage();
      addToast('Message sent successfully.', {
        appearance: 'success',
      });
    } else
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
  };

  return <CreateNewMessage onSubmit={onSubmit} loading={loading} />;
};
