import { makeStyles, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { GridColumnProps } from '../columns.data';

interface HeaderProps {
  item: GridColumnProps;
}
export const Header: React.FC<HeaderProps> = ({ item }) => {
  const { headerName, flexProportion } = { ...item };

  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack
      style={{
        flex: flexProportion,
        wordBreak: 'break-word',
        minWidth: item.minWidth,
        maxWidth: item.maxWidth,
        alignItems: item.alignItems,
      }}
      className={styles.container}
    >
      <Text className={clsx(commonStyles.bold, styles.title)}>
        {headerName.toUpperCase()}
      </Text>
    </Stack>
  );
};

export const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    padding: '5px 8px',
  },
  title: {
    fontSize: 12,
  },
}));
