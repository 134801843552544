import React from 'react';
import {
  ActionButton,
  IconButton,
  IIconProps,
  ITooltipHostStyles,
  Link,
  ProgressIndicator,
  Separator,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { MessageListItem } from '../index';
import { MessageRowDetails } from './MessageRowDetails';
import { PivotKeys } from '../index';
import { ReceivedRowAction } from './messageRowActions/ReceivedRowAction';
import { SentRowAction } from './messageRowActions/SentRowAction';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import { globalMode } from 'utility/cache/ui';
import { useHistory } from 'react-router';
import { ExtendedMessage } from './ExtendedMessage';
import { getMessageUrl } from 'common/utils/getMessageUrl';
import {
  DeleteMessages,
  DeleteMessagesVariables,
} from 'common/graphql/__generated__/DeleteMessages';
import { setPanelWidth } from 'common/components/DraggablePanel';
const DELETE_MESSAGE = loader('../../../graphql/DeleteMessages.graphql');
interface MessageRowItemProps {
  item: MessageListItem | undefined;
  refetchList: (listName?: string) => void;
  listType: PivotKeys;
  isLastRow?: boolean;
  highlightUnreadMessage?: boolean;
  showNavigation?: boolean;
}
const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' },
};

export const MessageRow: React.FC<MessageRowItemProps> = ({
  item,
  refetchList,
  listType,
  isLastRow,
  highlightUnreadMessage,
  showNavigation,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const {
    id,
    _createdDate,
    messageComment,
    _sendingUser,
    _recipientUser,
    isResponseRequired,
    messageResponse,
    userMessageDetailsByThreadId,
    _isUpdatable,
    _isDeletable,
    _rowTimestamp,
    _isNewMessage,
    invoiceMessage,
    linkId,
    _isExtendedMessage,
    extendedMessage,
    linkTitle,
    linkType,
  } = { ...item };

  const history = useHistory();
  const ViewIcon: IIconProps = { iconName: 'ArrowUpRight' };
  const linkTooltipContent = `View ${
    invoiceMessage?._isAccountingEntry ? 'Accounting entry' : linkTitle
  }`;

  const [isRespondView, setRespondView] = React.useState<boolean | undefined>(
    false
  );
  const [isEditView, setEditView] = React.useState<boolean | undefined>(false);
  const [viewExtenededMessage, setViewExtenededMessage] =
    React.useState<boolean>(false);
  const [isNewMessage, setNewMessage] = React.useState<
    boolean | undefined | null
  >(_isNewMessage);

  const [deleteMessage, { loading: loadingDeleteMessage }] = useMutation<
    DeleteMessages,
    DeleteMessagesVariables
  >(DELETE_MESSAGE, {
    errorPolicy: 'all',
  });

  const openRespondView = () => {
    setNewMessage(false);
    setRespondView(true);
  };

  const closeRespondView = async () => {
    await setRespondView(false);
  };

  const openEditView = () => setEditView(true);

  const onDeleteMessage = async () => {
    const { errors } = await deleteMessage({
      variables: {
        input: {
          entityDelete: [
            {
              id: id!,
              rowTimestamp: _rowTimestamp!,
            },
          ],
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      refetchList?.(PivotKeys.sent);
      addToast('Message deleted successfully', {
        appearance: 'success',
      });
    }
  };

  return (
    <Stack
      className={
        highlightUnreadMessage &&
        isNewMessage &&
        listType === PivotKeys.received
          ? styles.messageUnreadBgColor
          : styles.messageReadBgColor
      }
    >
      <Stack
        horizontal
        className={styles.messageRowMainContainer}
        horizontalAlign="space-between"
      >
        <MessageRowDetails
          user={
            listType === PivotKeys.received ? _sendingUser! : _recipientUser!
          }
          date={_createdDate!}
          message={messageComment!}
          multipleRecipientsData={userMessageDetailsByThreadId?.nodes || []}
          listType={listType}
          refetchList={refetchList}
        />
        <Stack horizontal horizontalAlign="end" verticalAlign="center">
          {linkId && linkType && showNavigation && (
            <TooltipHost
              content={linkTooltipContent!}
              id={item?.id}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <IconButton
                onClick={() => {
                  globalMode(false);
                  setPanelWidth(undefined);
                  history.push(getMessageUrl(item!));
                }}
                iconProps={ViewIcon}
                className={styles.redirectIcon}
              />
            </TooltipHost>
          )}
          {/* Row Actions for Received List */}
          {listType === PivotKeys.received &&
            isResponseRequired &&
            !isRespondView && (
              <Stack className={styles.respondButtonContainer}>
                <ActionButton
                  iconProps={{ iconName: 'message' }}
                  onClick={openRespondView}
                  className={styles.respondButton}
                >
                  Respond
                </ActionButton>
              </Stack>
            )}
          {/* Row Actions for Sent List */}
          {listType === PivotKeys.sent && (
            <Stack
              horizontal
              tokens={{ childrenGap: 5 }}
              horizontalAlign="center"
              className={styles.actionContainer}
            >
              {_isUpdatable && (
                <IconButton
                  onClick={openEditView}
                  iconProps={{ iconName: 'Edit' }}
                  className={styles.colorPrimary}
                  ariaLabel="Update"
                />
              )}
              {_isDeletable && _isUpdatable && <Separator vertical />}
              {_isDeletable && (
                <IconButton
                  onClick={onDeleteMessage}
                  iconProps={{ iconName: 'delete' }}
                  disabled={loadingDeleteMessage}
                  className={styles.colorPrimary}
                  ariaLabel="Delete"
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      {isRespondView && (
        <ReceivedRowAction
          id={id!}
          refetchList={refetchList}
          closeReceivedRowActions={closeRespondView}
        />
      )}
      {isEditView && (
        <SentRowAction
          id={id!}
          messageComment={messageComment!}
          refetchList={refetchList}
          closeSentRowActions={setEditView}
        />
      )}
      {messageResponse && (
        <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
          <MessageRowDetails
            user={_recipientUser!}
            date={_createdDate!}
            message={messageResponse}
            isMessageResponse
            multipleRecipientsData={userMessageDetailsByThreadId?.nodes || []}
            refetchList={refetchList}
          />
        </Stack>
      )}
      {_isExtendedMessage && (
        <>
          <Stack tokens={{ childrenGap: 10 }}>
            <Link
              onClick={(event) => {
                event.preventDefault();
                setViewExtenededMessage((prevState) => !prevState);
              }}
              className={styles.moreLink}
            >
              {viewExtenededMessage ? 'Less' : 'More'}
            </Link>
          </Stack>
          {viewExtenededMessage && extendedMessage && (
            <ExtendedMessage
              extendedData={extendedMessage.messageSections.nodes}
            />
          )}
        </>
      )}
      {loadingDeleteMessage && <ProgressIndicator />}
      {!isLastRow && <Separator />}
    </Stack>
  );
};
