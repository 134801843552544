import { filterOptionProps } from '../AttachDocumentFilters';

export const toFilterVariable = (filterOptions: filterOptionProps) =>
  filterOptions.filterTypes.map((filter) => {
    switch (filter.filterKey) {
      case 'indexReferenceNumber':
      case '_documentContents':
      case 'fileReference':
      case 'indexName':
      case 'comment':
        return {
          [filter.filterKey]: {
            [filterOptions.startsWith
              ? 'startsWithInsensitive'
              : 'includesInsensitive']: filter.value,
          },
        };

      case '_documentPoolId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      default:
        return undefined;
    }
  });
