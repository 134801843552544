import { InMemoryCache, makeVar } from '@apollo/client';
import { UserRoles_availableRoles } from 'common/graphql/__generated__/UserRoles';
import { showMorePagination } from './mergePolicies';
import { darkMode, expandedNav } from './ui';

export const currentUserRole = makeVar<UserRoles_availableRoles | undefined>(
  undefined
);

export const _cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Employee: {
      fields: {
        ratingsByEntityId: showMorePagination,
        userMessagesByEntityId: showMorePagination,
      },
    },
    Payment: {
      fields: {
        userMessagesByEntityId: showMorePagination,
      },
    },
    ApprovalHistory: {
      fields: {
        userMessagesByEntityId: showMorePagination,
      },
    },
    UserMessagesConnection: showMorePagination,
    Supplier: {
      fields: {
        ratingsByEntityId: showMorePagination,
      },
    },
    DocumentPool: {
      fields: {
        entityDocumentsByDocumentPoolIdAndEntityTypeId: showMorePagination,
      },
    },
    FileCabinetPool: {
      fields: {
        entityDocumentsByDocumentPoolIdAndEntityTypeId: showMorePagination,
      },
    },
    Query: {
      fields: {
        tripBreakdownSearch: showMorePagination,
        entityDocumentSearch: showMorePagination,
        batchTransactions: showMorePagination,
        entityDocuments: showMorePagination,
        communicationChannels: showMorePagination,
        userProfiles: showMorePagination,
        approvals: showMorePagination,
        attachableApprovalDocuments: showMorePagination,
        appliableInvoiceSigningDocuments: showMorePagination,
        paymentAvailableAccountingEntries: showMorePagination,
        attachableInvoiceSigningDocuments: showMorePagination,
        attachableDocumentPools: showMorePagination,
        payCycleSearch: showMorePagination,
        approvalHistorySearch: showMorePagination,
        approvalSearch: showMorePagination,
        attachablePaymentDocuments: showMorePagination,
        userNotifications: showMorePagination,
        userMessages: showMorePagination,
        documentPools: showMorePagination,
        entityDocumentPersonalPools: showMorePagination,
        invoiceSigningSearch: showMorePagination,
        userApprovalSearch: showMorePagination,
        paymentSearch: showMorePagination,
        tags: showMorePagination,
        tagGroups: showMorePagination,
        tagCategories: showMorePagination,
        communicationGroups: showMorePagination,
        travelers: showMorePagination,
        suppliers: showMorePagination,
        attachableBatchTransactionDocuments: showMorePagination,
        fileCabinetDocumentSearch: showMorePagination,
        purchaseOrderSearch: showMorePagination,
        travelAuthorizationSearch: showMorePagination,
        availableTripTravelers: showMorePagination,
        tripTravelPlans: showMorePagination,
        lookupCorporateAccountSearch: showMorePagination,
        lookupAccountSearch: showMorePagination,
        corporateWorkgroups: showMorePagination,
        lookupAccountCoaSearch: showMorePagination,
        lookupDepotAreaSearch: showMorePagination,
        departments: showMorePagination,
        userOccupationTitles: showMorePagination,
        availablePurchaseOrderRevisions: showMorePagination,
        currentUserRole: {
          read() {
            return currentUserRole();
          },
        },
        ui: {
          read() {
            return {
              darkMode: darkMode(),
              expandedNav: expandedNav(),
            };
          },
        },
      },
    },
  },
});
