import React from 'react';
import {
  ShimmeredDetailsList,
  IColumn,
  DetailsListLayoutMode,
} from '@fluentui/react';

const ShimmerView: React.FC = () => {
  // Define placeholder columns for shimmer
  const columns: IColumn[] = Array.from({ length: 5 }, (_, index) => ({
    key: `column${index + 1}`,
    name: '', // Empty name for no column headers
    fieldName: `column${index + 1}`,
    minWidth: 200,
    maxWidth: 200,
    isResizable: true,
  }));
  return (
    <div>
      <ShimmeredDetailsList
        items={[]} // No data for standard shimmer view
        enableShimmer={true} // Always enable shimmer
        layoutMode={DetailsListLayoutMode.justified} // Layout mode for display
        shimmerLines={8} // Number of shimmer rows to display
        columns={columns} // 5 placeholder columns
        onRenderDetailsHeader={() => null} // Hide column headers
      />
    </div>
  );
};

export default ShimmerView;
