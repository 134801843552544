import { Text } from '@fluentui/react';
import React from 'react';

interface AddressRowProps {
  data: string | null | undefined;
  isLastRow?: boolean;
}

export const AddressRow: React.FC<AddressRowProps> = ({ data, isLastRow }) => {
  if (!data) return null;
  return (
    <Text>{`${data}${!isLastRow ? "," : ""}`}</Text>
  )
}

