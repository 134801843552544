import {
  PrimaryButton,
  Stack,
  TooltipHost,
  DefaultButton,
  Modal,
  Text,
} from '@fluentui/react';
import React, { useRef, useState } from 'react';
import { CloseButton } from 'common/components/Buttons';
import { useCommonStyles } from 'common/styles';
import SignatureCanvas from 'react-signature-canvas';
import { useStyles } from './index.styles';
import { useToasts } from 'react-toast-notifications';
import { ColorPickerCallout } from '../../ColorPickerCallout';
import { CreateSignatureProps } from '..';
import { useFormikContext } from 'formik';
import { SignatureValues } from '../../types';

type SignaturePadProps = Pick<CreateSignatureProps, 'onSignatureCreate'>;

export const SignaturePad: React.FC<SignaturePadProps> = ({ ...props }) => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <TooltipHost content={'Create using signature pad'}>
        <DefaultButton
          styles={{ root: { width: 200 } }}
          iconProps={{ iconName: 'PenWorkspace' }}
          text="Handwritten"
          onClick={() => setVisible(true)}
        />
      </TooltipHost>

      {visible && (
        <SignaturePadModal onDismiss={() => setVisible(false)} {...props} />
      )}
    </>
  );
};

type SignaturePadModalProps = Pick<
  CreateSignatureProps,
  'onSignatureCreate'
> & {
  onDismiss: () => void;
};
export const SignaturePadModal: React.FC<SignaturePadModalProps> = ({
  onDismiss,
  onSignatureCreate,
}) => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const signatureCanvasRef = useRef<any>(null);
  const commonStyles = useCommonStyles();
  const [penColor, setPenColor] = useState('black');
  const { setFieldValue } = useFormikContext<SignatureValues>();
  const onSubmit = () => {
    const canVasIsEmpty = signatureCanvasRef?.current?.isEmpty();

    if (canVasIsEmpty) {
      addToast('Cannot submit empty canvas', {
        appearance: 'error',
      });
    } else {
      const dataURL = signatureCanvasRef.current
        ?.getTrimmedCanvas()
        .toDataURL('image/png');
      // onSignatureCreate(dataURL);

      setFieldValue('imageUrl', dataURL);
      onDismiss();
    }
  };
  const clearCanvas = () => {
    signatureCanvasRef.current.clear();
  };
  return (
    <Modal onDismiss={() => { }} isOpen>
      <Stack className={styles.container}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ padding: 25 }}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">Create Signature</Text>
            <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
              Signature Pad
            </Text>
          </Stack>
          <CloseButton onClick={onDismiss} />
        </Stack>

        <Stack
          horizontalAlign="center"
          tokens={{ childrenGap: 20, padding: 25 }}
          className={styles.signatureContainer}
        >
          <SignatureCanvas
            ref={signatureCanvasRef}
            penColor={penColor}
            minWidth={2}
            maxWidth={4}
            throttle={20}
            backgroundColor={'transparent'}
            canvasProps={{
              className: styles.sigPad,
            }}
          />
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ padding: 25 }}
        >
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <ColorPickerCallout onColorChange={setPenColor} />
            <DefaultButton text="Clear" onClick={clearCanvas} />
          </Stack>
          <PrimaryButton text="Submit" onClick={onSubmit} />
        </Stack>
      </Stack>
    </Modal>
  );
};
