import React from 'react';
import { BlockBlobClient } from '@azure/storage-blob';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { UserProfile } from 'settings/account/userSetup/view/__generated__/UserProfile';
import { UserSetupCommonData } from 'settings/account/userSetup/__generated__/UserSetupCommonData';
import {
  UserSetupUploadDocument,
  UserSetupUploadDocumentVariables,
  UserSetupUploadDocument_userSetupUploadDocument_document,
} from './__generated__/UserSetupUploadDocument';
import { UploadFiles } from 'common/components/UploadFiles';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { useFormikContext } from 'formik';

const UPLOAD_DOCUMENT = loader('./UserSetupUploadDocument.graphql');
interface UploadFormProps {
  availableDocumentTypes: UserSetupCommonData | undefined;
  userDetails?: UserProfile | null;
  onUpload?: (
    fileSelected: File,
    documentData: UserSetupUploadDocument_userSetupUploadDocument_document,
    toastId: string
  ) => void;
}
export const UploadForm: React.FC<UploadFormProps> = ({
  availableDocumentTypes,
  userDetails,
  onUpload,
}) => {
  const { dirty } = useFormikContext();
  const [uploadDocument] = useMutation<
    UserSetupUploadDocument,
    UserSetupUploadDocumentVariables
  >(UPLOAD_DOCUMENT);

  const { updateToast, addToast } = useToasts();

  const documentTypeOptions =
    availableDocumentTypes?.userProfileAvailableDocumentTypes?.nodes.map(
      (doctype) =>
      ({
        key: doctype.id,
        text: doctype.documentType || '',
        isAccountingDocument: doctype?.isAccountingDocument,
        extractionTypes: doctype.extractionTypes,
        title:
          (!userDetails?.userProfile?.isDraft &&
            doctype?.isAccountingDocument &&
            'Upload prohibited during/ after the approval process') ||
          undefined,
        disabled:
          (!userDetails?.userProfile?.isDraft &&
            doctype?.isAccountingDocument) ||
          undefined,
      } as DocumentTypeOption)
    ) || [];

  return (
    <UploadFiles
      documentTypes={documentTypeOptions}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, fileSelected) => {
          fileSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });

            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                userSetupId: userDetails?.userProfile?.id!,
              },
            });

            if (uploadMutationResults.errors) {
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });
            }

            if (
              uploadMutationResults.data?.userSetupUploadDocument?.document &&
              uploadMutationResults.data?.userSetupUploadDocument?.document
                ._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data.userSetupUploadDocument.document,
                toastId!
              );

              const client = new BlockBlobClient(
                uploadMutationResults.data?.userSetupUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
      disableUpload={dirty}
    />
  );
};
