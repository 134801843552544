import {
  DefaultButton,
  DialogFooter,
  IDialogProps,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { DialogWrapper } from 'common/components/DialogWrapper';
import React from 'react';
import { useStyles } from './index.styles';

interface ConfirmDialogProps extends IDialogProps {
  // hidden: boolean;
  checked: boolean;
  onCancel: (param: boolean) => void;
  onConfirm: (param: string | null | undefined) => void;
  // children?: ReactNode;
  // title: string;
  // type?: DialogType;
  // subText?: string;
  // minWidth?: number;
  // isConfirmPrimaryButton?: boolean;
  // isAmendButton?: boolean;
  // isDraggable?: boolean;
  // confirmText?: string | undefined;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  checked,
  onConfirm,
  onCancel,
  ...props
}) => {
  const styles = useStyles();
  const [requestComment, setRequestComment] = React.useState<string>();

  const subText: string = checked
    ? 'You are about to promote the request as urgent.'
    : 'You are about to demote the request to a standard approval.';

  return (
    <DialogWrapper
      onDismiss={() => onCancel(!checked)}
      dialogContentProps={{
        title: 'Are you sure?',
        subText,
      }}
      {...props}
    >
      <TextField
        multiline
        rows={3}
        placeholder="Please write your comment here (optional)"
        resizable={false}
        onChange={(_event, value) => setRequestComment(value || '')}
      />
      <DialogFooter>
        {checked ? (
          <DefaultButton
            toggle
            primary={true}
            className={styles.promoteButton}
            selected={true}
            onClick={() => onConfirm(requestComment)}
            text="Promote"
          />
        ) : (
          <PrimaryButton
            text="Demote"
            onClick={() => onConfirm(requestComment)}
          />
        )}
        <DefaultButton onClick={() => onCancel(!checked)} text="Cancel" />
      </DialogFooter>
    </DialogWrapper>
  );
};
