import { useMutation } from '@apollo/client';
import {
  DetailsList,
  DetailsRow,
  IColumn,
  IDetailsFooterProps,
  IRenderFunction,
  Icon,
  IconButton,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { StyledLink } from 'common/components/StyledLink';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { FormViewProps } from '..';
import { ReactComponent as ApprovedIcon } from '../../../../../../assets/svgs/ApproveStatusIcons/Approved.svg';
import { BatchTransaction_batchTransaction_invoiceBatches_nodes } from '../../__generated__/BatchTransaction';
import { AccountingEditPanel } from './AccountingEditPanel';
import {
  TransactionRemove,
  TransactionRemoveVariables,
} from './__generated__/TransactionRemove';
import { columns } from './column.data.ts';
import { useStyles } from './index.styles';
import { useCommonStyles } from 'common/styles';

const TRANSACTION_REMOVE = loader('./TransactionRemove.graphql');
const BATCH_TRANSACTION = loader('../../BatchTransaction.graphql');
type TransactionsProps = Pick<FormViewProps, 'data'> & {};
type TransactionListItem =
  BatchTransaction_batchTransaction_invoiceBatches_nodes & {
    name: string | null;
    vendorReference: string | null;
    description: string;
    transactionType: string | null;
    invoiceDate?: string;
  };
export const Transactions: React.FC<TransactionsProps> = ({ data }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const [removeTransaction] = useMutation<
    TransactionRemove,
    TransactionRemoveVariables
  >(TRANSACTION_REMOVE, { errorPolicy: 'all' });
  const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>();
  const dataNew =
    data?.batchTransaction?.invoiceBatches?.nodes?.map((item) => {
      const { description, invoiceNumber, vendorReference } = {
        ...item.invoice,
      };
      return {
        ...item,
        description,
        name: vendorReference,
        invoiceNumber,
        transactionType: item.invoiceBatchTransactionType?.transactionType,
        invoiceDate: item.invoice?.invoiceDate
          ? dateFormat(dateConvertions(item.invoice?.invoiceDate))
          : '',
        transactionNo: item.invoice?._accountingStampTransactionReference,
        _isAccountingEntryStampedComplete:
          item?.invoice?._isAccountingEntryStampedComplete,
      };
    }) || [];
  const removeFromBatch = async (transaction: TransactionListItem) => {
    const { id, _rowTimestamp } = { ...transaction };
    const { errors } = await removeTransaction({
      variables: {
        input: {
          batchTransactionId: data?.batchTransaction?.id!,
          entityDelete: [{ id, rowTimestamp: _rowTimestamp! }],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: BATCH_TRANSACTION,
          variables: {
            id: data?.batchTransaction?.id!,
          },
        },
      ],
    });
    if (!errors?.length)
      addToast('Transaction removed from batch', { appearance: 'success' });
    else
      addToast(errors[0].message, {
        appearance: 'error',
      });
  };
  const onRenderItemColumn = (
    item?: TransactionListItem,
    _index?: number,
    column?: IColumn
  ) => {
    if (!item || !column) return undefined;

    const fieldContent = item[column.fieldName as keyof TransactionListItem] as
      | string
      | null;

    switch (column.key) {
      case 'name':
        return (
          <Stack
            className={styles.columnHeight}
            verticalAlign="center"
            horizontal
          >
            <Stack horizontal verticalAlign="center">
              {item.comment && (
                <TooltipHost content={item.comment}>
                  <Icon
                    style={{ marginRight: 3 }}
                    iconName="InfoSolid"
                    className={commonStyles.colorThemePrimary}
                  />
                </TooltipHost>
              )}
              <Text style={{ marginLeft: item.comment ? 0 : 15 }}>
                {fieldContent}
              </Text>
            </Stack>
          </Stack>
        );
      case 'appliedAmount':
        return (
          <Stack verticalAlign="center" className={styles.onrenderColumnStack}>
            <AmountTextView
              value={fieldContent}
              className={styles.contentColumnAlignRight}
            />
          </Stack>
        );
      case 'transactionType':
        return (
          <StyledLink
            onLinkClick={() => setSelectedInvoice(item.invoiceId!)}
            linkText={fieldContent}
          />
        );
      case 'action':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <IconButton
              disabled={!item._isDeletable}
              onClick={() => removeFromBatch(item)}
              iconProps={{ iconName: 'Delete' }}
            />
          </Stack>
        );
      case '_isAccountingEntryStampedComplete':
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            {item?.invoice?._isAccountingEntryStampedComplete ? (
              <IconButton
                iconProps={{
                  iconName: 'StampSmall',
                  styles: {
                    root: {
                      fill: 'black',
                    },
                  },
                }}
              />
            ) : (
              <ApprovedIcon />
            )}
          </Stack>
        );

      default:
        return (
          <Stack className={styles.columnHeight} verticalAlign="center">
            <Text>{fieldContent}</Text>
          </Stack>
        );
    }
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !data?.batchTransaction?.invoiceBatches.aggregates?.sum) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  const renderFooterColumn = (
    _item?: TransactionListItem,
    _index?: number,
    column?: IColumn
  ) => {
    if (
      column?.key === 'appliedAmount' &&
      data?.batchTransaction?.invoiceBatches?.aggregates?.sum?.appliedAmount
    ) {
      const totalAmount = data?.batchTransaction?.invoiceBatches?.aggregates
        ?.sum?.appliedAmount
        ? Number(
            data?.batchTransaction?.invoiceBatches?.aggregates?.sum
              ?.appliedAmount
          ).toFixed(2)
        : '-';
      return (
        <AmountTextView
          value={totalAmount}
          className={clsx(styles.amount, styles.amountTotal)}
        />
      );
    }
    return null;
  };

  if (!dataNew.length) return null;
  else
    return (
      <Stack>
        {
          <Stack
            className={styles.container}
            horizontal
            horizontalAlign="space-between"
          >
            <Text variant="xLarge">Transactions</Text>
          </Stack>
        }

        <Stack tokens={{ padding: '10px 0px 10px 10px' }}>
          <DetailsList
            setKey="set"
            compact
            selectionMode={SelectionMode.none}
            items={dataNew || []}
            columns={columns}
            onRenderItemColumn={onRenderItemColumn}
            onRenderDetailsFooter={renderFooter}
          />

          {selectedInvoice !== undefined && (
            <AccountingEditPanel
              id={selectedInvoice}
              batchTransactionId={data?.batchTransaction?.id}
              isOpen={selectedInvoice !== undefined}
              onDismiss={() => setSelectedInvoice(undefined)}
            />
          )}
        </Stack>
      </Stack>
    );
};
