import {
  IToggleStyleProps,
  IToggleStyles,
  Toggle,
  TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { FilterArrayType, filterCombineType } from '.';

type ToggleFilterProps = {
  placeholder?: string;
  inlineLabel?: boolean;
  filterKey: string;
  onOptionChange?: (data: FilterArrayType) => void;
  disabled?: boolean;
  defaultFilter?: boolean;
  controlled?: boolean;
  combineType?:
    | filterCombineType.AND
    | filterCombineType.NOT
    | filterCombineType.OR;
};

export const ToggleFilter: React.FC<ToggleFilterProps> = ({
  placeholder = 'Select item',
  defaultFilter = false,
  onOptionChange,
  filterKey,
  disabled = false,
  controlled = false,
  combineType = filterCombineType.AND,
  inlineLabel,
  ...props
}) => {
  const label = defaultFilter ? placeholder : undefined;
  const tooltipContent = defaultFilter ? undefined : placeholder;

  return (
    <TooltipHost content={tooltipContent}>
      <Toggle
        disabled={disabled}
        styles={getToggleStyles}
        label={placeholder}
        inlineLabel={inlineLabel}
        onChange={(_, value) => {
          onOptionChange?.({
            value: !!value,
            filterKey,
            label: label ?? '',
            combineType,
          });
        }}
        {...props}
      />
    </TooltipHost>
  );
};
function getToggleStyles(props: IToggleStyleProps): Partial<IToggleStyles> {
  return {
    root: {
      textAlign: 'center',
      border: 0,
      padding: '5px 10px 0px 0px',
      selectors: {
        ':hover': {
          borderRadius: 5,
          backgroundColor: props.theme?.palette.neutralLight,
        },
      },
    },
  };
}
