import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  width250: {
    width: 250,
  },
  width200: {
    width: 200,
  },
  currencyText: {
    fontWeight: 'bold',
  },
}));
