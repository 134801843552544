import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  textContainer: {
    justifyContent: 'center',
  },
  iconColor: {
    color: 'green',
  },
  iconWrapper: {
    alignItems: 'center',
    paddingLeft: 20,
  },
}));
