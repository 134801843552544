import {
  Callout,
  DirectionalHint,
  Icon,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useStyles } from './index.styles';
import React, { useState } from 'react';
import { userApproval_userApproval } from 'approvals/ApprovalDetails/__generated__/userApproval';
import clsx from 'clsx';
import { ApprovalHistory_approvalHistory } from 'approvals/ApprovalDetails/__generated__/ApprovalHistory';

interface VendorCalloutProps {
  data: userApproval_userApproval | ApprovalHistory_approvalHistory;
}

export const VendorCallout: React.FC<VendorCalloutProps> = ({ data }) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const calloutId = `vendorCallout${data.id}`;
  const [visible, setVisible] = useState(false);
  const {
    city,
    state,
    zipCode,
    address1,
    address2,
    lookupName,
    lookupCode,
    emailAccount,
    phone,
  } = {
    ...data.lookupName!,
  };

  return (
    <Stack>
      <Icon
        id={calloutId}
        onMouseLeave={() => setVisible(false)}
        onMouseEnter={() => setVisible(true)}
        iconName="Accept"
        className={commonStyles.colorThemePrimary}
      />
      {visible && (
        <Callout
          className={styles.callout}
          gapSpace={0}
          target={`#${calloutId}`}
          directionalHint={DirectionalHint.leftTopEdge}
          setInitialFocus
        >
          <Stack tokens={{ padding: 20 }}>
            <Stack horizontal horizontalAlign={'space-between'}>
              <Text
                className={clsx(
                  commonStyles.colorThemePrimary,
                  commonStyles.bold
                )}
              >
                {lookupName}
              </Text>
              {lookupCode && (
                <Stack className={styles.idCallStylesBox}>
                  <Text className={commonStyles.colorThemePrimary}>
                    {lookupCode}
                  </Text>
                </Stack>
              )}
            </Stack>

            <Separator />
            <Stack tokens={{ childrenGap: 10 }}>
              {address1 && <Text>{address1}</Text>}
              {address2 && <Text>{address2}</Text>}
              <Text>{`${state ? `${state},` : ''}${city ? `${city},` : ''}${
                zipCode ? `${zipCode},` : ''
              }`}</Text>
              <Text>{`${emailAccount ? `${emailAccount},` : ''} ${
                phone ? `${phone},` : ''
              }`}</Text>
            </Stack>
          </Stack>
        </Callout>
      )}
    </Stack>
  );
};
