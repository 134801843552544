import React from 'react';
import { IconButton, Toggle, TooltipHost } from '@fluentui/react';
import { useStyles } from './index.styles';

interface CloseButtonIC {
  onClick?: () => void;
  isShared: boolean;
  disabled?: boolean;
  isToggle?: boolean;
}
export const ShareNoteButton: React.FC<CloseButtonIC> = ({
  onClick,
  isShared,
  disabled,
  isToggle = false,
}) => {
  const styles = useStyles();

  if (isToggle) {
    return (
      <Toggle
        disabled={disabled}
        onClick={onClick}
        checked={!isShared}
        onText="Private"
        offText="Public"
        className={styles.toggle}
      />
    );
  }

  return (
    <TooltipHost content={isShared ? 'Public note' : 'Private note'}>
      <IconButton
        disabled={disabled}
        styles={{
          icon: isShared ? styles.shareIcon : styles.privateIcon,
        }}
        onClick={onClick}
        iconProps={{ iconName: isShared ? 'Redeye' : 'Hide' }}
      />
    </TooltipHost>
  );
};
