import { CorporateChartOfAccountsValues } from './types';

export const CORPORATE_CHART_OF_ACCOUNTS_VALUES: CorporateChartOfAccountsValues =
  {
    name: null,
    description: null,
    companyCode: null,
    businessArea: null,
    costCenter: null,
    glAccount: null,
  };
