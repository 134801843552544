import { FontWeights, Stack, Text, makeStyles } from '@fluentui/react';
import { FormHookAmount, FormHookTextField } from 'common/components/FormHooksFields';
import { AutoCompleteTextField } from 'common/components/FormHooksFields/AutoCompleteTextField';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { GetEntityDocument_entityDocument } from '../../__generated__/GetEntityDocument';

const useStyles = makeStyles(() => ({
  columnHeader: {
    fontWeight: FontWeights.semibold
  },
  flex1: {
    flex: 1
  },
  tableContainer: {
    width: 500
  },
}));

interface TaxAreaProps {
  documentData: GetEntityDocument_entityDocument | null | undefined;
}

export const TaxArea: React.FC<TaxAreaProps> = ({
  documentData
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { documentTypes, _isUpdatable } = { ...documentData };
  const { _isTaxFieldTracked } = { ...documentTypes };

  const taxFields = [
    {
      code: "indexTaxCode1",
      account: "indexTaxAccount1",
      amount: "indexTaxAmount1",
    },
    {
      code: "indexTaxCode2",
      account: "indexTaxAccount2",
      amount: "indexTaxAmount2",
    },
    {
      code: "indexTaxCode3",
      account: "indexTaxAccount3",
      amount: "indexTaxAmount3",
    },
    {
      code: "indexTaxCode4",
      account: "indexTaxAccount4",
      amount: "indexTaxAmount4",
    },
  ];

  if (!!_isTaxFieldTracked)
    return (
      <Stack
        tokens={{ childrenGap: 20 }}
      >
        <Text
          variant="xLarge"
          className={commonStyles.colorThemePrimary}>
          Taxes
        </Text>
        <Stack
          className={styles.tableContainer}
        >
          <Stack
            horizontal
            tokens={{
              childrenGap: 20
            }}
          >
            <Stack
              className={styles.flex1}
              horizontalAlign="center"
            >
              <Text className={styles.columnHeader}>Code</Text>
            </Stack>
            <Stack
              className={styles.flex1}
              horizontalAlign="center"
            >
              <Text className={styles.columnHeader}>Account</Text>
            </Stack>
            <Stack
              className={styles.flex1}
              horizontalAlign="center"
            >
              <Text className={styles.columnHeader}>Amount</Text>
            </Stack>
          </Stack>
          <Stack tokens={{ childrenGap: 5 }}>
            {
              taxFields.map((field, index) =>
                <Stack
                  horizontal
                  tokens={{
                    childrenGap: 20
                  }}
                >
                  <Stack className={styles.flex1}>
                    <FormHookTextField
                      name={field.code}
                      underlined
                      disabled={!_isUpdatable}
                    />
                  </Stack>
                  <Stack className={styles.flex1}>
                    <AutoCompleteTextField
                      id={`${field.account}-${index}`}
                      name={field.account}
                      businessUnitId={null}
                      lookUpCalloutWidth={500}
                      underlined
                      disabled={!_isUpdatable}
                    />
                  </Stack>
                  <Stack className={styles.flex1}>
                    <FormHookAmount
                      name={field.amount}
                      fixedDecimalScale
                      decimalScale={2}
                      maxLength={15}
                      underlined
                      disabled={!_isUpdatable}
                      style={{ textAlign: 'end' }}
                    />
                  </Stack>
                </Stack>
              )
            }
          </Stack>
        </Stack>
      </Stack>
    )
  else
    return null
}
