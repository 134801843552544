import * as React from 'react';
import { Stack, List } from '@fluentui/react';
import { NoDataView } from 'common/components/DataPlaceholders';
import { MessageListItem } from '..';
import { PivotKeys } from '../index';
import { MessageRow } from 'common/components/MessagesSections/MessageRow';
import { MESSAGES_PER_PAGE } from 'common/constants';
import { ShimmerView } from 'notification/view/ShimmerView';
import { Pagination } from '../Pagination';
import { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  ResetUserNewMessageCount,
  ResetUserNewMessageCountVariables,
} from 'common/graphql/__generated__/ResetUserNewMessageCount';
import { loader } from 'graphql.macro';
const GET_USER_MESSAGE_RESET_COUNT = loader(
  '../../../../common/graphql/ResetUserNewMessageCount.graphql'
);

interface SentMessageListProps {
  data: MessageListItem[];
  loadingMessages: boolean;
  refetchList: (listName?: string) => void;
  onLoadMore?: () => void;
  hasNextPage?: boolean;
}

export const SentMessageList: React.FunctionComponent<SentMessageListProps> = ({
  data = [],
  loadingMessages,
  refetchList,
  onLoadMore,
  hasNextPage,
}) => {
  const isFetchingData = loadingMessages && !data?.length;
  const [messageResetUser] = useMutation<
    ResetUserNewMessageCount,
    ResetUserNewMessageCountVariables
  >(GET_USER_MESSAGE_RESET_COUNT);

  const resetMessageCountMemo = useCallback(messageResetUser, [data]);
  useEffect(() => {
    const isNewMessage = data.filter((item) => item._isNewMessage === true);
    if (isNewMessage.length) {
      resetMessageCountMemo({
        variables: {
          input: {},
        },
      });
    }
  }, [data, resetMessageCountMemo]);

  return (
    <Stack tokens={{ padding: '10px 0px 0px 0px' }}>
      {isFetchingData && [...Array(10)].map((_e, i) => <ShimmerView key={i} />)}
      <List
        items={data}
        onRenderCell={(item) => (
          <MessageRow
            showNavigation
            refetchList={refetchList}
            item={item}
            key={item?.id}
            listType={PivotKeys.sent}
          />
        )}
      />
      <Pagination
        showProgressIndicator={
          hasNextPage === true && data?.length >= MESSAGES_PER_PAGE
        }
        onLoadMore={onLoadMore}
      />
      <NoDataView
        title="No messages found"
        show={!data.length && !loadingMessages}
      />
    </Stack>
  );
};
