import { useMutation, useQuery } from '@apollo/client';
import {
  IDropdownOption,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { BudgetUpdateModal } from './BudgetUpdateModal';
import {
  BudgetDelete,
  BudgetDeleteVariables,
} from './__generated__/BudgetDelete';
import {
  Budgets,
  BudgetsVariables,
  Budgets_budgets_nodes,
} from './__generated__/Budgets';
import { BudgetListView } from './budgetListView';
import { BudgetItemFormValues } from './types';
import { validationSchema } from './validation';
import { AlertDialog } from 'common/components/AlertDialog ';

const BUDGET = loader('./Budgets.graphql');

const DELETE_BUDGET = loader('./BudgetDelete.graphql');

export const DepartmentBudget = () => {
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const [saveFormModal, setSaveFormModal] = useState<boolean>(false);

  const [budgetOptions, setBudgetOptions] = useState<IDropdownOption[]>([]);
  const [selectedBudget, setSelectedBudget] = useState<
    Budgets_budgets_nodes | undefined
  >();
  const [primaryBudget, setPrimaryBudget] = useState<
    Budgets_budgets_nodes | undefined
  >();
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);

  const { data: budgetListData } = useQuery<Budgets, BudgetsVariables>(BUDGET, {
    variables: {
      first: TABLE_ROWS,
    },
  });

  const [deleteBudget] = useMutation<BudgetDelete, BudgetDeleteVariables>(
    DELETE_BUDGET,
    { errorPolicy: 'all' }
  );

  const _onConfirmDelete = async () => {
    const entityDelete: EntityDeleteInput[] = [
      {
        id: selectedBudget?.id!,
        rowTimestamp: selectedBudget?._rowTimestamp!,
      },
    ];
    const { errors } = await deleteBudget({
      variables: {
        input: {
          entityDelete,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: BUDGET,
          variables: {
            first: TABLE_ROWS,
          },
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      if (!!primaryBudget?.id && selectedBudget?.id !== primaryBudget?.id) {
        setSelectedBudget(primaryBudget);
      } else {
        setSelectedBudget(undefined);
      }
      addToast('Budget deleted successfully.', {
        appearance: 'success',
      });
    }
  };
  useEffect(() => {
    if (budgetListData?.budgets?.nodes.length) {
      const budgetListItems =
        budgetListData?.budgets?.nodes?.map((item) => {
          if (item.isPrimary)
            return {
              key: item.id || '',
              text: item.name + ' (Primary)' || '',
            };

          return {
            key: item.id || '',
            text: item.name || '',
          };
        }) || [];

      setBudgetOptions(budgetListItems);
    }
  }, [budgetListData]);

  useEffect(() => {
    if (!selectedBudget) {
      let budget = budgetListData?.budgets?.nodes.find(
        (node) => node.isPrimary
      );
      if (!budget) budget = budgetListData?.budgets?.nodes?.[0];
      setPrimaryBudget(budget);
      setSelectedBudget(budget);
    }
  }, [budgetListData, selectedBudget]);

  const formMethods = useForm<BudgetItemFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });
  const {
    reset,
    formState: { isDirty },
  } = { ...formMethods };
  const CONFIRM_DIALOG_TITLE = 'Unsaved changes!';
  const CONFIRM_DIALOG_SUBTEXT = 'Please save your changes to proceed.';
  return (
    <FormProvider {...formMethods}>
      <Stack
        grow
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          className={commonStyles.listTitleContainer}
        >
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Text variant="xLarge">Budgets</Text>
            {!!budgetListData?.budgets?.nodes?.length && (
              <CustomDropdown
                style={{ width: 300 }}
                placeholder="Select budget"
                options={budgetOptions}
                selectedKey={selectedBudget?.id as string}
                onChange={(_, option) => {
                  const budget = budgetListData?.budgets?.nodes.find(
                    (node) => node.id === option?.key
                  );
                  setSelectedBudget(budget);
                }}
                onClear={() => {}}
                underlined
              />
            )}
            <TooltipHost content={'Add budget'}>
              <IconButton
                iconProps={{ iconName: 'Add' }}
                ariaLabel="Add"
                onClick={() => {
                  if (isDirty) setSaveFormModal(true);
                  else {
                    setIsEditModal(false);
                    reset({
                      id: null,
                      name: null,
                      endingBudgetDate: null,
                      startingBudgetDate: null,
                      isPrimary: null,
                    });
                    setUpdateModalVisible(true);
                  }
                }}
              />
            </TooltipHost>
            <TooltipHost content={'Edit budget'}>
              <IconButton
                iconProps={{ iconName: 'Edit' }}
                ariaLabel="Edit"
                onClick={() => {
                  if (isDirty) setSaveFormModal(true);
                  else {
                    setUpdateModalVisible(true);
                    setIsEditModal(true);
                  }
                }}
                disabled={!selectedBudget?._isUpdatable}
              />
            </TooltipHost>
            <ActionMessageModal
              entityType={EntityType.Budget}
              disabled={!selectedBudget?._isDeletable}
              onConfirm={_onConfirmDelete}
            />
            {saveFormModal && (
              <AlertDialog
                hidden={!saveFormModal}
                title={CONFIRM_DIALOG_TITLE}
                subText={CONFIRM_DIALOG_SUBTEXT}
                onConfirm={async () => {
                  setSaveFormModal(false);
                }}
              />
            )}
          </Stack>
        </Stack>

        {!!selectedBudget && (
          <BudgetListView
            selectedBudget={selectedBudget}
            updateModalVisible={updateModalVisible}
            isEditModal={isEditModal}
          />
        )}
      </Stack>
      {updateModalVisible && (
        <BudgetUpdateModal
          isEditModal={isEditModal}
          selectedBudget={selectedBudget}
          setSelectedBudget={setSelectedBudget}
          setPrimaryBudget={setPrimaryBudget}
          onDismiss={() => setUpdateModalVisible(false)}
        />
      )}
    </FormProvider>
  );
};
