import { makeVar, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { globalMode } from 'utility/cache/ui';
import { BatchList } from './batch/list';
import { PostedTransaction } from './postedTransaction/list';

export const transactionHistoryPivotTabs = {
  batch: 'paymentTracking',
  postedTransaction: 'postedTransaction',
};

export const transactionHistoryTabMode = makeVar<string>(
  transactionHistoryPivotTabs.batch
);

export const TransactionHistorySection: React.FC = () => {
  const payTrackTabState = useReactiveVar(transactionHistoryTabMode);
  const globalState = useReactiveVar(globalMode);

  if (!payTrackTabState && globalState)
    transactionHistoryTabMode(transactionHistoryPivotTabs.batch);

  // defaulting to Payment Tracking list after Route change
  useEffect(() => {
    return () => {
      transactionHistoryTabMode(transactionHistoryPivotTabs.batch);
    };
  }, []);

  const tabSwitchLogic = payTrackTabState === transactionHistoryPivotTabs.batch;

  return <>{tabSwitchLogic ? <BatchList /> : <PostedTransaction />}</>;
};
