import React from 'react';
import OrganizationChart from '@dabeng/react-orgchart';
import { NodeView } from './NodeView';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  EntityApprovalMap,
  EntityApprovalMapVariables,
  EntityApprovalMap_entityApprovalMap_nodes,
} from './__generated__/EntityApprovalMap';
import { ApprovalMap, ApprovalMapVariables } from './__generated__/ApprovalMap';
import { ApprovalMapTypesOrderBy } from 'common/types/globalTypes';
import { ProgressIndicator, Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { useToasts } from 'react-toast-notifications';
import { useStyles } from './index.styles';
import './NodeView/my-node.css';
import clsx from 'clsx';
import { StampOptions } from '../StamperView';
import { useCallback } from 'react';
import { getTreeGraph } from './utils';
const ENTITY_APPROVAL_MAP = loader('./EntityApprovalMap.graphql');
const APPROVAL_MAP = loader('./ApprovalMap.graphql');

export interface HierarchyNode
  extends EntityApprovalMap_entityApprovalMap_nodes {
  children?: HierarchyNode[];
}

interface ApprovalHierarchyProps {
  entityId: string;
  getApprovalType: (approvalType: string) => void;
  stampEnteredData?: (data: StampOptions) => void;
  isApprovalEntry?: boolean;
}
/**
 * Main hierarchy module
 * This module call data from server and runs a recursive algorithm to restructure the data
 * Once the data has been restructured it feeds into the @dabeng/react-orgchart to get the graph rendered as expected
 */
export const ApprovalHierarchy: React.FC<ApprovalHierarchyProps> = ({
  entityId,
  getApprovalType,
  stampEnteredData,
  isApprovalEntry,
}) => {
  const [hierarchyData, setHierarchyData] = React.useState<HierarchyNode[]>([]);

  const Toast = useToasts();
  const [
    getEntityApprovalData,
    {
      loading: entityApprovalDataLoading,
      data: approvalEntityData,
      error: approvalHierarchyError,
    },
  ] = useLazyQuery<EntityApprovalMap, EntityApprovalMapVariables>(
    ENTITY_APPROVAL_MAP,
    { fetchPolicy: 'network-only' }
  );
  const [
    getApprovalData,
    {
      loading: approvalDataLoading,
      data: approvalData,
      error: approvalDataError,
    },
  ] = useLazyQuery<ApprovalMap, ApprovalMapVariables>(APPROVAL_MAP, {
    fetchPolicy: 'network-only',
  });

  const getEntityApproval = () => {
    getEntityApprovalData({
      variables: {
        entityId,
        orderBy: [ApprovalMapTypesOrderBy.SORT_ORDER_DESC],
      },
    });
    if (approvalHierarchyError) {
      Toast.addToast('Could not load data', { appearance: 'error' });
    }
  };
  const getApprovalDetails = () => {
    getApprovalData({
      variables: {
        entityId,
        orderBy: [ApprovalMapTypesOrderBy.SORT_ORDER_DESC],
      },
    });
    if (approvalDataError) {
      Toast.addToast('Could not load data', { appearance: 'error' });
    }
  };
  const getEntity = useCallback(getEntityApproval, []);
  const getApproval = useCallback(getApprovalDetails, []);

  React.useEffect(() => {
    if (isApprovalEntry) {
      getApproval();
    } else {
      getEntity();
    }
  }, [getApproval, getEntity, isApprovalEntry]);

  const memoizedChildrenData = useCallback(getTreeGraph, []);

  React.useEffect(() => {
    if (
      !entityApprovalDataLoading &&
      approvalEntityData?.entityApprovalMap?.nodes.length !== 0
    ) {
      // Implicity adding children property to avoid an error caused
      const childrenAdded = approvalEntityData?.entityApprovalMap?.nodes.map(
        (item) => ({
          ...item,
          children: [],
        })
      );

      let childrenDummy = memoizedChildrenData(
        childrenAdded
      ) as HierarchyNode[];
      setHierarchyData(childrenDummy);
      getApprovalType(
        approvalEntityData?.entityApprovalMap?.nodes[0].approvalType!
      );

      const stampOptions: StampOptions = {
        _accountingStampDate:
          approvalEntityData?.entityApprovalMap?.nodes[0]._accountingStampDate,
        _accountingStampTransactionReference:
          approvalEntityData?.entityApprovalMap?.nodes[0]
            ._accountingStampTransactionReference,
        _accountingStampUserName:
          approvalEntityData?.entityApprovalMap?.nodes[0]
            ._accountingStampUserName,
        _isAccountingEntryStampedComplete:
          approvalEntityData?.entityApprovalMap?.nodes[0]
            ._isAccountingEntryStampedComplete,
        _isTransactionCancelled:
          approvalEntityData?.entityApprovalMap?.nodes[0]
            ._isTransactionCancelled,
      };
      stampEnteredData?.(stampOptions);
    }
  }, [
    entityApprovalDataLoading,
    approvalEntityData,
    memoizedChildrenData,
    getApprovalType,
    stampEnteredData,
  ]);

  React.useEffect(() => {
    if (!approvalDataLoading && approvalData?.approvalMap?.nodes.length !== 0) {
      // Implicity adding children property to avoid an error caused
      const childrenAdded = approvalData?.approvalMap?.nodes.map((item) => ({
        ...item,
        children: [],
      }));

      const newData = memoizedChildrenData(childrenAdded) as HierarchyNode[];

      setHierarchyData(newData);
      getApprovalType(approvalData?.approvalMap?.nodes[0].approvalType!);
      const stampOptions: StampOptions = {
        _accountingStampDate:
          approvalData?.approvalMap?.nodes[0]._accountingStampDate,
        _accountingStampTransactionReference:
          approvalData?.approvalMap?.nodes[0]
            ._accountingStampTransactionReference,
        _accountingStampUserName:
          approvalData?.approvalMap?.nodes[0]._accountingStampUserName,
        _isAccountingEntryStampedComplete:
          approvalData?.approvalMap?.nodes[0]._isAccountingEntryStampedComplete,
        _isTransactionCancelled:
          approvalData?.approvalMap?.nodes[0]._isTransactionCancelled,
      };
      stampEnteredData?.(stampOptions);
    }
  }, [
    approvalDataLoading,
    approvalData,
    memoizedChildrenData,
    getApprovalType,
    stampEnteredData,
  ]);

  // return null
  if (hierarchyData.length > 0)
    return (
      <OrganizationChart
        datasource={hierarchyData[0]}
        chartClass="myChart"
        // Any had to be used over here because the structure is coming from
        // a javascript library and we are unknown about its type.
        NodeTemplate={(node: any) => <NodeView nodeData={node.nodeData} />}
        pan={true}
        containerClass={'orgchartcontainer'}
        zoom={true}
        zoominLimit={1.2}
        zoomoutLimit={0.5}
        draggable={false}
        collapsible={false}
      />
    );
  else return <LoadingHierarchy />;
};

const LOADING_TEXT = 'Loading hierarchy';
const LoadingHierarchy = () => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      className={styles.loaderContainer}
    >
      <Stack className={styles.loadingInnerContainer}>
        <Text className={clsx(commonStyles.semibold, styles.loadingText)}>
          {LOADING_TEXT}
        </Text>
        <ProgressIndicator />
      </Stack>
    </Stack>
  );
};
