import {
  Separator,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection2 = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <ShimmerElementsGroup
        width="500px"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 30, width: 150 },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          {
            type: ShimmerElementType.gap,
            height: 30,
            width: '100%',
          },
        ]}
      />
    </div>
  );
};

export const ShimmerSection3 = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 30, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Stack tokens={{ padding: "30px 20px 0px" }}>
        {
          Array.from({ length: 3 }, (_, ele) => ++ele).map((_, i) => {
            return (
              <Shimmer key={i} customElementsGroup={ShimmerSection()} />
            )
          })
        }
      </Stack>
      <Separator />
      <Stack className={styles.containerMargin}>
        <Shimmer customElementsGroup={ShimmerSection2()} />
      </Stack>
      <Stack tokens={{ childrenGap: 20 }} className={styles.containerMargin}>
        {
          Array.from({ length: 4 }, (_, ele) => ++ele).map((_, i) => {
            return (
              <Shimmer key={i} customElementsGroup={ShimmerSection3()} />
            )
          })
        }
      </Stack>
      <Separator />
    </React.Fragment>
  );
};
