import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const useColumns = () => {
  const { isSm, isLg } = useGetScreenWidth();

  const columns: ColumnData[] = [
    {
      key: '_urgencyLevel',
      name: '',
      fieldName: '_urgencyLevel',
      showColumnTitle: 'Urgent',
      minWidth: 10,
      maxWidth: 10,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 270,
      ...commonColumnProps,
    },
    {
      key: 'referenceNumber',
      name: 'Batch ID',
      fieldName: 'referenceNumber',
      minWidth: 120,
      ...commonColumnProps,
    },
    {
      key: 'postingDate',
      name: 'Posting Date',
      fieldName: 'postingDate',
      minWidth: 120,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'periodYear',
      name: 'Period',
      fieldName: 'periodYear',
      minWidth: 100,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'weekNumber',
      name: 'Week#',
      fieldName: 'weekNumber',
      minWidth: 70,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'currencyData',
      name: 'Currency',
      fieldName: 'currencyData',
      minWidth: 70,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: 'controlTotalAmount',
      name: 'Batch Total',
      fieldName: 'controlTotalAmount',
      minWidth: 120,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
    },
    {
      key: '_batchTransactionTotal',
      name: 'Transaction Total',
      fieldName: '_batchTransactionTotal',
      minWidth: 120,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
    },
    {
      key: '_batchTransactionCount',
      name: 'Transactions',
      fieldName: '_batchTransactionCount',
      minWidth: 120,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
    },
    {
      key: '_createdByUserName',
      name: 'Created by',
      fieldName: '_createdByUserName',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 160,
      ...commonColumnProps,
    },
  ];
  return { columns };
};
export { useColumns };
