import { useMutation } from '@apollo/client';
import {
  HoverCard,
  HoverCardType,
  IconButton,
  IPlainCardProps,
  List,
  Persona,
  PersonaSize,
  ProgressIndicator,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { NotificationDateTime } from 'common/components/DateTime';
import { EntityUserMessageFields_userMessageDetailsByThreadId_nodes } from 'common/graphql/__generated__/EntityUserMessageFields';
import {
  MessageRecipientDelete,
  MessageRecipientDeleteVariables,
} from 'common/graphql/__generated__/MessageRecipientDelete';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { MessageListItem, PivotKeys } from '../../..';
import { useStyles } from './index.styles';
const DELETE_USER_FROM_MESSAGE = loader(
  '../../../../../graphql/MessageRecipientDelete.graphql'
);
interface MultipleRecipientsProps {
  data: EntityUserMessageFields_userMessageDetailsByThreadId_nodes[];
  refetchList: (listName?: string) => void;
}

export const MultipleRecipients: React.FC<MultipleRecipientsProps> = ({
  data,
  refetchList,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const [deleteRecipient, { loading: loadingDeleteRecipient }] = useMutation<
    MessageRecipientDelete,
    MessageRecipientDeleteVariables
  >(DELETE_USER_FROM_MESSAGE, { errorPolicy: 'all' });

  const onDelete = async (id: string, rowTimestamp: string) => {
    const { errors } = await deleteRecipient({
      variables: {
        messageDetailDeleteInput: {
          entityDelete: [
            {
              id: id,
              rowTimestamp: rowTimestamp!,
            },
          ],
        },
      },
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      refetchList(PivotKeys.sent);
      addToast('Recipient deleted successfully', {
        appearance: 'success',
      });
    }
  };

  const onRenderPlainCard = (cardData: MessageListItem[]) => {
    return (
      <Stack className={styles.plainCardContainer}>
        <Stack className={styles.progressContainer}>
          {loadingDeleteRecipient && <ProgressIndicator />}
        </Stack>
        <List
          items={data}
          onRenderCell={(item) => (
            <>
              <Stack
                tokens={{ childrenGap: 20 }}
                horizontal
                horizontalAlign="space-between"
                className={styles.messageResponseContainer}
              >
                <Stack horizontal style={{}}>
                  <Stack className={styles.personaContainer}>
                    <Persona
                      text={item?._recipientUser!}
                      size={PersonaSize.size72}
                      coinSize={30}
                    />
                  </Stack>
                  <Stack>
                    <Text nowrap className={styles.recipient}>
                      {item?._recipientUser}
                    </Text>
                    {item?._receivedDate && (
                      <Text className={styles.recievedDate}>
                        {`Viewed : `}
                        <NotificationDateTime dateTime={item?._receivedDate!} />
                      </Text>
                    )}

                    {item?.messageResponse && (
                      <>
                        <Text>{item?.messageResponse}</Text>
                        <NotificationDateTime
                          dateTime={item?._respondedDate!}
                        />
                      </>
                    )}
                  </Stack>
                </Stack>
                {item?._isDeletable && (
                  <IconButton
                    iconProps={{ iconName: 'delete' }}
                    className={styles.colorPrimary}
                    onClick={() => onDelete(item.id, item._rowTimestamp!)}
                  />
                )}
              </Stack>
              <Separator />
            </>
          )}
        />
      </Stack>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderPlainCard,
    renderData: data,
  };

  return (
    <Stack horizontal>
      <Stack className={styles.justifyHoverCard}>
        <HoverCard
          plainCardProps={plainCardProps}
          instantOpenOnClick
          type={HoverCardType.plain}
        >
          <Text className={styles.hoverLinkStyle}>
            {`${data.length} Recipients`}
          </Text>
        </HoverCard>
      </Stack>
    </Stack>
  );
};
