import { IconButton } from '@fluentui/react';
import React, { useState } from 'react';
import { ChartOfAccountsModal } from './ChartOfAccountsModal';

export const CreateChartOfAccounts: React.FC<{
  chartOfAccountId: string | null;
}> = ({ chartOfAccountId }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <IconButton
        onClick={() => setIsOpen(true)}
        iconProps={{ iconName: 'Add' }}
      />
      <ChartOfAccountsModal
        chartOfAccountId={chartOfAccountId}
        visible={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
