import React from 'react';
import {
  TravelAuthorizationAvailableDocumentTypes,
  TravelAuthorizationAvailableDocumentTypesVariables,
} from '../__generated__/TravelAuthorizationAvailableDocumentTypes';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  TravelAuthorizationUploadDocument,
  TravelAuthorizationUploadDocumentVariables,
  TravelAuthorizationUploadDocument_travelAuthorizationUploadDocument,
} from './__generated__/TravelAuthorizationUploadDocument';
import { useFormContext } from 'react-hook-form';
import { TravelAuthorizationValues } from 'travelAuthorization/TravelPlan/view/interface';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import { DocumentTypeOption } from 'common/components/UploadFiles/view';
import { UploadFiles } from 'common/components/UploadFiles';
import { BlockBlobClient } from '@azure/storage-blob';
const TRAVEL_AUTHORIZATION_UPLOAD_DOCUMENT = loader(
  './TravelAuthorizationUploadDocument.graphql'
);
const TRAVEL_AUTHORIZATION_AVAILABLE_DOCUMENT_TYPES = loader(
  '../TravelAuthorizationAvailableDocumentTypes.graphql'
);

interface UploadFormProps {
  travelAuthorizationData?: TravelAuthorization_travelAuthorization;
  onUpload?: (
    fileSelected: File,
    documentData: TravelAuthorizationUploadDocument_travelAuthorizationUploadDocument,
    toastId: string
  ) => void;
}

export const UploadForm: React.FC<UploadFormProps> = ({
  travelAuthorizationData,
  onUpload,
}) => {
  const { isDraft, id } = { ...travelAuthorizationData };
  const { updateToast, addToast } = useToasts();
  const {
    formState: { isDirty },
  } = useFormContext<TravelAuthorizationValues>();

  const { data: availableDocumentTypes } = useQuery<
    TravelAuthorizationAvailableDocumentTypes,
    TravelAuthorizationAvailableDocumentTypesVariables
  >(TRAVEL_AUTHORIZATION_AVAILABLE_DOCUMENT_TYPES, {
    variables: {
      isDocumentUpload: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [uploadDocument] = useMutation<
    TravelAuthorizationUploadDocument,
    TravelAuthorizationUploadDocumentVariables
  >(TRAVEL_AUTHORIZATION_UPLOAD_DOCUMENT, { errorPolicy: 'all' });

  const documentTypeOptions =
    availableDocumentTypes?.travelAuthorizationAvailableDocumentTypes?.nodes.map(
      (doctype) =>
        ({
          key: doctype.id,
          text: doctype.documentType || '',
          isAccountingDocument: doctype.isAccountingDocument,
          title:
            isDraft && doctype.isAccountingDocument
              ? 'Upload prohibited during/after the approval process'
              : undefined,
          disabled: (!isDraft && doctype.isAccountingDocument) || undefined,
        } as DocumentTypeOption)
    ) || [];

  return (
    <UploadFiles
      documentTypes={documentTypeOptions!}
      uploadDocument={{
        uploadDocumentData: async (documentType, data, filSelected) => {
          filSelected.map(async (fileEntity, fileIndex) => {
            const toastId = `file.name.${fileEntity?.name}.${fileIndex}`;
            addToast(`Uploading ${fileEntity?.name}...`, {
              appearance: 'info',
              id: toastId,
              autoDismiss: false,
            });
            const uploadMutationResults = await uploadDocument({
              variables: {
                document: {
                  ...data,
                  documentTypeId: parseInt(documentType.key.toString()),
                  filename: fileEntity.name,
                },
                travelAuthorizationId: id!,
              },
            });
            if (uploadMutationResults.errors) {
              updateToast(toastId!, {
                content: `Upload of ${fileEntity.name} failed`,
                appearance: 'error',
                autoDismiss: true,
              });
            }
            if (
              uploadMutationResults.data?.travelAuthorizationUploadDocument
                ?.document &&
              uploadMutationResults.data?.travelAuthorizationUploadDocument
                ?.document._documentFileId
            ) {
              onUpload?.(
                fileEntity,
                uploadMutationResults.data?.travelAuthorizationUploadDocument,
                toastId!
              );
              const client = new BlockBlobClient(
                uploadMutationResults.data?.travelAuthorizationUploadDocument?.uploadLink
              );
              await client.uploadData(fileEntity);
            }
          });
        },
      }}
      disableUpload={isDirty}
    />
  );
};
