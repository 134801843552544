import { Stack, Text } from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';
import { Trip_trip } from 'travelAuthorization/TravelPlan/view/FormView/Trips/TripModal/__generated__/Trip';
interface WarningInfoProps {
  tripData: Trip_trip | null | undefined;
}
export const WarningInfo: React.FC<WarningInfoProps> = ({ tripData }) => {
  const styles = useStyles();
  if (!tripData) return null;
  const { warningInfo1, warningInfo2, warningInfo3 } = { ...tripData };
  const warningInfoArray = [warningInfo1, warningInfo2, warningInfo3];
  return (
    <Stack className={styles.container} tokens={{ childrenGap: 14 }}>
      {warningInfoArray.map((item, index) => (
        <Text key={index}>{item}</Text>
      ))}
    </Stack>
  );
};
