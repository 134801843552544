import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
  },
  groupName: {
    color: theme.palette.themePrimary,
  },
  description: {
    maxWidth: 1000,
    color: theme.palette.neutralDark,
  },
  groupSelected: {
    backgroundColor: theme.palette.neutralLighter,
  },

  linkHover: {
    height: 40,
    ':hover': {
      color: theme.palette.blue,
      backgroundColor: theme.palette.neutralLighter,
    },
  },
  linkLightHover: {
    ':hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
  textStyle: {
    fontWeight: FontWeights.semibold,
    ':hover': {
      color: theme.palette.blue,
    },
  },
}));
