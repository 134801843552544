import React, { useEffect } from 'react';
import { TravelPoliciesType } from '../../../list';
import { Modal, Spinner, Stack, makeStyles } from '@fluentui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { Header } from './Header';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { TravelPolicyAllowancesValues } from './types';
import { useToasts } from 'react-toast-notifications';
import { useMutation, useQuery } from '@apollo/client';
import { TravelPolicyExpenditureTypes } from './__generated__/TravelPolicyExpenditureTypes';
import { loader } from 'graphql.macro';
import {
  TravelPolicyUpdate,
  TravelPolicyUpdateVariables,
} from '../../TravelPolicyView/__generated__/TravelPolicyUpdate';
import { TravelPolicyAllowanceInput } from 'common/types/globalTypes';
import { getAllowanceValues, getUpdatedAllowances } from './utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validations';
const TRAVEL_POLICY_EXPENDITURE_TYPES = loader(
  './TravelPolicyExpenditureTypes.graphql'
);
const TRAVEL_POLICY_UPDATE = loader(
  '../../TravelPolicyView/TravelPolicyUpdate.graphql'
);

const useStyles = makeStyles(() => ({
  container: {
    width: '30vw',
  },
}));

interface AllowanceViewModalProps {
  travelPolicy: TravelPoliciesType;
  isNew: boolean;
  index: number;
  loading: boolean;
  hideModal: () => void;
}

export const AllowanceViewModal: React.FC<AllowanceViewModalProps> = ({
  travelPolicy,
  isNew,
  index,
  loading,
  hideModal,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const formMethods = useForm<TravelPolicyAllowancesValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { data: expenditureTypesData, loading: expenditureLoading } =
    useQuery<TravelPolicyExpenditureTypes>(TRAVEL_POLICY_EXPENDITURE_TYPES);

  const [travelPolicyUpdate, { loading: travelPolicyUpdateLoading }] =
    useMutation<TravelPolicyUpdate, TravelPolicyUpdateVariables>(
      TRAVEL_POLICY_UPDATE,
      { errorPolicy: 'all' }
    );

  const headerTitle = isNew ? 'Create' : 'Update';
  const { handleSubmit, reset, trigger } = { ...formMethods };

  const onHandleSubmit = async (values: TravelPolicyAllowancesValues) => {
    const { id, _rowTimestamp, travelPolicyAllowancesRow } = { ...values };
    if (isNew) {
      const { errors } = await travelPolicyUpdate({
        variables: {
          input: {
            id: id!,
            rowTimestamp: _rowTimestamp || '',
            travelPolicyAllowancesCreate:
              (travelPolicyAllowancesRow?.map(
                ({ id, _rowTimestamp, ...ele }) => ({
                  ...ele,
                  policyMiles: ele.policyMiles ? Number(ele.policyMiles) : 0,
                })
              ) as TravelPolicyAllowanceInput[]) || [],
          },
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Travel policy allowance created successfully.', {
          appearance: 'success',
        });
        reset();
        hideModal();
      }
    } else {
      const defaultValues = getAllowanceValues({
        isNew,
        travelPolicyData: travelPolicy,
      });
      const { travelPolicyAllowancesRow } = {
        ...defaultValues,
      } as TravelPolicyAllowancesValues;

      const updatedAllowance = getUpdatedAllowances(
        travelPolicyAllowancesRow!,
        values.travelPolicyAllowancesRow!
      );
      const { errors } = await travelPolicyUpdate({
        variables: {
          input: {
            id: id!,
            rowTimestamp: _rowTimestamp || '',
            travelPolicyAllowancesUpdate: updatedAllowance || [],
          },
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Travel policy allowance updated successfully.', {
          appearance: 'success',
        });
        reset();
      }
    }
  };

  useEffect(() => {
    const defaultValues = getAllowanceValues({
      isNew,
      travelPolicyData: travelPolicy,
    });
    reset(defaultValues);
    trigger();
  }, [travelPolicy, isNew, reset, trigger]);

  return (
    <Modal isOpen isBlocking={true} onDismiss={hideModal}>
      <FormProvider {...formMethods}>
        <Stack className={styles.container}>
          <Header
            headerTitle={headerTitle}
            isNew={isNew}
            onDismiss={hideModal}
            loading={loading}
          />
          {expenditureLoading ? (
            <Stack tokens={{ padding: 20 }}>
              <Spinner label="Loading data" />
            </Stack>
          ) : (
            <BasicForm
              expenditureTypes={
                expenditureTypesData?.travelPolicyExpenditureTypes?.nodes || []
              }
              index={index}
            />
          )}

          <Footer
            headerTitle={headerTitle}
            loading={travelPolicyUpdateLoading}
            onSave={handleSubmit(onHandleSubmit)}
            onDismiss={hideModal}
          />
        </Stack>
      </FormProvider>
    </Modal>
  );
};
