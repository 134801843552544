import { Stack } from '@fluentui/react';
import { FormikTextField } from 'common/components';
import { AdvancedFormikDropdown } from 'common/components/FormikFields/AdvancedFormikDropdown';
import React from 'react';
import { PurchaseCardCommonData } from '../../../__generated__/PurchaseCardCommonData';
import { CardAccount } from '../../__generated__/CardAccount';
import { useStyles } from './index.styles';
interface BasicFormProps {
  data: PurchaseCardCommonData | undefined;
  cardAccount?: CardAccount;
  isUpdatable: boolean | undefined | null;
}
export interface AttachedDocumentsDataType {
  documentTypeId: number | null | undefined;
  entityDocumentId: string[] | null;
}
export const BasicForm: React.FC<BasicFormProps> = ({
  data,
  isUpdatable,
  cardAccount,
}) => {
  const style = useStyles();
  const { _isManualProcess } = {
    ...cardAccount?.cardAccount?.companyCardCompanies,
  };
  const businessUnitOptions =
    data?.companyBusinessUnits?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
    })) || [];
  const documentPoolOptions =
    data?.documentPools?.nodes.map((item) => ({
      key: item.id,
      text: item.name || '',
    })) || [];

  const isDisabled = !isUpdatable;

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 50 }}
        className={style.mainContainer}
      >
        <Stack
          grow={1}
          tokens={{ childrenGap: 20 }}
          className={style.detailsContainer}
        >
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <FormikTextField
                disabled={isDisabled}
                name="description"
                label="Description"
                placeholder="Enter description"
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={style.flex50}>
              <AdvancedFormikDropdown
                disabled={isDisabled}
                label="Business Unit"
                placeholder="Select"
                options={businessUnitOptions}
                name="businessUnitId"
              />
            </Stack.Item>
          </Stack>
          {!_isManualProcess && (
            <Stack
              horizontal
              tokens={{ childrenGap: 20, padding: '0px 0px 30px 0px' }}
            >
              <Stack.Item className={style.flex50}>
                <AdvancedFormikDropdown
                  disabled={isDisabled}
                  label="Document Folder"
                  placeholder="Select"
                  options={documentPoolOptions}
                  name="documentPoolId"
                />
              </Stack.Item>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};
