import { PrimaryButton, Separator, Stack } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { formatDropdownOptions } from 'common/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  PurchaseOrderItemFormProps,
  PurchaseOrderItemLayout,
} from '../interface';

type TaxItemProps = PurchaseOrderItemLayout;

export const TaxItem: React.FC<TaxItemProps> = ({
  selectedItemType,
  purchaseOrderData,
  commonData,
}) => {
  const { setValue } = useFormContext<PurchaseOrderItemFormProps>();
  const watchValues = useWatch<PurchaseOrderItemFormProps>({
    name: ['subjectToAmount', 'itemUnitPrice', 'itemExtendedAmount'],
  });

  const watchSubjectToAmount: string | undefined = watchValues?.[0]?.toString();
  const watchItemUnitPrice: string | undefined = watchValues?.[1]?.toString();

  const fieldDisabled = !purchaseOrderData?.purchaseOrder?._isUpdatable;
  const expenditureTypeOption = useMemo(() => {
    if (
      selectedItemType?.isExpenditureTypeAllowed &&
      !selectedItemType.isUnitOfMeasureRequired
    )
      return (
        commonData?.expenditureTypes?.nodes.map((item) => ({
          key: item.id!,
          text: item.expenditureType! || '',
          ...item,
        })) || []
      );
    else {
      const expenditureTypes = formatDropdownOptions(
        selectedItemType?.unitOfMeasureGroup?.expenditureTypes.nodes,
        {
          getKey: (item) => item.id,
          getText: (item) => item.expenditureType!,
        }
      );

      return expenditureTypes;
    }
  }, [selectedItemType, commonData]);

  const taxTypeOptions = formatDropdownOptions(commonData?.taxTypes?.nodes, {
    getKey: (item) => item.id,
    getText: (item) => item.taxType!,
  });

  const getCalculate = useCallback(() => {
    setValue(
      'subjectToAmount',
      purchaseOrderData?.purchaseOrder?._subjectToTax || null
    );
  }, [purchaseOrderData, setValue]);

  useEffect(() => {
    if (selectedItemType?.isAutoCalculated) {
      getCalculate();
    }
  }, [selectedItemType, getCalculate]);

  useEffect(() => {
    const discount = (
      (parseFloat(watchItemUnitPrice || '0.00') / 100) *
      parseFloat(watchSubjectToAmount || '0.00')
    ).toFixed(2);

    setValue('itemExtendedAmount', discount, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchItemUnitPrice, watchSubjectToAmount]);

  return (
    <Stack
      tokens={{
        padding: '20px 20px',
        childrenGap: 10,
      }}
    >
      <Stack horizontal>
        <Stack
          grow={2}
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 20 }}
        >
          {selectedItemType?.isExpenditureTypeAllowed && (
            <Stack.Item>
              <FormHookDropdown
                label="Expenditure Type"
                placeholder="Select"
                options={expenditureTypeOption}
                name="expenditureTypeId"
                required
                disabled={fieldDisabled}
                styles={{ root: { width: 250 } }}
              />
            </Stack.Item>
          )}

          <Stack.Item grow={2}>
            <FormHookTextField
              name="description"
              label="Description"
              placeholder="Description"
              disabled={fieldDisabled}
              required
            />
          </Stack.Item>
        </Stack>
        <Stack grow={1} horizontalAlign="end"></Stack>
      </Stack>
      <Separator />
      <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 20 }}>
        <Stack style={{ width: 250 }}>
          <FormHookDropdown
            label="Tax Type"
            placeholder="Select Tax Type"
            name="taxTypeId"
            options={taxTypeOptions}
            disabled={fieldDisabled}
          />
        </Stack>

        <FormHookAmount
          name="subjectToAmount"
          label="Subject to Tax"
          placeholder="Subject To Tax"
          fixedDecimalScale
          decimalScale={2}
          allowNegative={false}
          disabled={fieldDisabled || !!selectedItemType?.isAutoCalculated}
          style={{ width: 200 }}
        />

        <FormHookAmount
          name="itemUnitPrice"
          label="Rate"
          decimalScale={2}
          fixedDecimalScale
          placeholder="Enter rate"
          allowNegative={false}
          suffix="%"
          disabled={fieldDisabled}
        />

        <FormHookAmount
          name="itemExtendedAmount"
          label="Tax"
          placeholder="Enter Tax"
          fixedDecimalScale
          decimalScale={2}
          allowNegative={false}
          style={{ width: 200 }}
          disabled={fieldDisabled || !!selectedItemType?.isAutoCalculated}
        />
      </Stack>
      {!selectedItemType?.isAutoCalculated && (
        <Stack horizontal>
          <PrimaryButton
            text="Get Taxable Items"
            onClick={getCalculate}
            disabled={fieldDisabled}
          />
        </Stack>
      )}
    </Stack>
  );
};
