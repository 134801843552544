import { ContextualMenu, IDragOptions, Modal, Stack, makeStyles } from '@fluentui/react';
import React, { useCallback, useEffect } from 'react';
import { Header } from './Header';
import { FormProvider, useForm } from 'react-hook-form';
import { AddressRowValues, BusinessUnitValues, CurrencyRowValues } from './types';
import { BusinessUnitModalProps } from '..';
import { getDefaultValues } from './utils';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CompanyBusinessUnitUpdate, CompanyBusinessUnitUpdateVariables } from './__generated__/CompanyBusinessUnitUpdate';
import { useToasts } from 'react-toast-notifications';
import { CompanyAddressInput, CompanyAddressUpdateTypeInput, CompanyBusinessUnitCurrencyInput, CompanyBusinessUnitCurrencyUpdateTypeInput, CompanyBusinessUnitUpdateInput, EntityDeleteInput } from 'common/types/globalTypes';
import { CompanyChartOfAccounts, CompanyChartOfAccountsVariables } from './__generated__/CompanyChartOfAccounts';
import { Addresses } from './Addresses';
import { differenceBy, intersection, isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation';
import { BusinessUnitCommonData } from './__generated__/BusinessUnitCommonData';
import { Currencies } from './Currencies';
const COMPANY_BUSINESS_UNIT_UPDATE = loader("./CompanyBusinessUnitUpdate.graphql")
const BUSINESS_UNIT_COMMON_DATA = loader("./BusinessUnitCommonData.graphql")
const COMPANY_CHART_OF_ACCOUNTS = loader('./CompanyChartOfAccounts.graphql')

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

const useStyles = makeStyles(() => ({
  container: {
    width: 900,
    maxHeight: 820,
  }
}));

type FormModalProps = BusinessUnitModalProps & {
  onClose: () => void;
}

export const FormModal: React.FC<FormModalProps> = ({
  businessUnit,
  onClose,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const { data: commonData } = useQuery<BusinessUnitCommonData>(BUSINESS_UNIT_COMMON_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [updateEnvironment, { loading: updateEnvironmentLoading }] =
    useMutation<CompanyBusinessUnitUpdate, CompanyBusinessUnitUpdateVariables>(
      COMPANY_BUSINESS_UNIT_UPDATE,
      { errorPolicy: 'all' }
    );

  const formMethods = useForm<BusinessUnitValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const [
    getCompanyChartOfAccounts,
    {
      data: companyChartOfAccountsData
    }
  ] = useLazyQuery<CompanyChartOfAccounts, CompanyChartOfAccountsVariables>(
    COMPANY_CHART_OF_ACCOUNTS
  );

  const {
    handleSubmit,
    reset,
    getValues,
  } = { ...formMethods };

  const isProductionBusinessUnit = getValues('_isProductionBusinessUnit')

  const { id, _rowTimestamp } = { ...businessUnit }

  const onHandleSubmit = async (values: BusinessUnitValues) => {
    const {
      name,
      _isProductionBusinessUnit,
      companyAddressesByCompanyIdAndEntityId,
      companyBusinessUnitCurrencies,
      ...formValues
    } = { ...values }

    if (!!id && !!_rowTimestamp) {
      const defaultValues = getDefaultValues({
        businessUnit
      })

      //Array for new addresses
      const newCompanyAddresses =
        companyAddressesByCompanyIdAndEntityId ? companyAddressesByCompanyIdAndEntityId?.filter(
          (addr) => !addr.id && addr.addressLine1 !== null)
          .map(({ id, _rowTimestamp, ...addr }) => addr)
          : [] as CompanyAddressInput[];

      //Array for deleted addresses
      const deletedCompanyAddresses =
        defaultValues.companyAddressesByCompanyIdAndEntityId &&
        (differenceBy(
          defaultValues.companyAddressesByCompanyIdAndEntityId,
          companyAddressesByCompanyIdAndEntityId || [],
          'id'
        ).filter(item => !!item.id && !!item._rowTimestamp)
          .map((addr) => ({
            id: addr.id,
            rowTimestamp: addr._rowTimestamp,
          })) as EntityDeleteInput[]);

      //Array for updated addresses
      const updatedAddresses =
        defaultValues.companyAddressesByCompanyIdAndEntityId &&
        companyAddressesByCompanyIdAndEntityId &&
        intersection(
          defaultValues.companyAddressesByCompanyIdAndEntityId.map((addr) => addr.id),
          companyAddressesByCompanyIdAndEntityId
            .filter((addr) => addr.id)
            .map((addr) => addr.id)
        ).reduce((arr, targetId) => {
          const initialAddress =
            defaultValues.companyAddressesByCompanyIdAndEntityId!.find(
              (addr) => addr.id === targetId
            )!;
          const { id, _rowTimestamp, ...updatedAddress } =
            companyAddressesByCompanyIdAndEntityId!.find(
              (addr) => addr.id === targetId
            )!;
          const patch = Object.entries(updatedAddress).reduce(
            (res, [key, val]) => {
              if (val !== initialAddress[key as keyof AddressRowValues])
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                companyAddressPatch: patch,
              },
            ];
          return arr;
        }, [] as CompanyAddressUpdateTypeInput[]);

      //Array for business unit
      const newBusinessUnit =
        companyBusinessUnitCurrencies ? companyBusinessUnitCurrencies?.filter(
          (item) => !item.id && item.currencyId !== null)
          .map(({
            id,
            _rowTimestamp,
            _isUpdatable,
            _isDeletable,
            ...item
          }) => item)
          : [] as CompanyBusinessUnitCurrencyInput[];

      //Array for deleted business unit 
      const deletedBusinessUnit =
        defaultValues.companyBusinessUnitCurrencies &&
        (differenceBy(
          defaultValues.companyBusinessUnitCurrencies,
          companyBusinessUnitCurrencies || [],
          'id'
        ).filter(item => !!item.id && !!item._rowTimestamp)
          .map((ele) => ({
            id: ele.id,
            rowTimestamp: ele._rowTimestamp,
          })) as EntityDeleteInput[]);

      //Array for updated addresses
      const updatedBusinessUnit =
        defaultValues.companyBusinessUnitCurrencies &&
        companyBusinessUnitCurrencies &&
        intersection(
          defaultValues.companyBusinessUnitCurrencies.map((ele) => ele.id),
          companyBusinessUnitCurrencies
            .filter((ele) => ele.id)
            .map((item) => item.id)
        ).reduce((arr, targetId) => {
          const initialBusinessUnit =
            defaultValues.companyBusinessUnitCurrencies?.find(
              (ele) => ele.id === targetId
            )!;
          const { id, _rowTimestamp, ...updatedBusinessUnit } =
            companyBusinessUnitCurrencies?.find(
              (addr) => addr.id === targetId
            )!;
          const patch = Object.entries(updatedBusinessUnit).reduce(
            (res, [key, val]) => {
              if (val !== initialBusinessUnit[key as keyof CurrencyRowValues])
                return { ...res, [key]: val };
              return res;
            },
            {}
          );
          if (!isEmpty(patch))
            return [
              ...arr,
              {
                id,
                rowTimestamp: _rowTimestamp,
                companyBusinessUnitCurrencyPatch: patch,
              },
            ];
          return arr;
        }, [] as CompanyBusinessUnitCurrencyUpdateTypeInput[]);

      const { errors } = await updateEnvironment({
        variables: {
          input: {
            id,
            rowTimestamp: _rowTimestamp,
            companyBusinessUnitPatch: {
              ...formValues
            },
            companyAddressCreate: newCompanyAddresses,
            companyAddressDelete: deletedCompanyAddresses,
            companyAddressUpdate: updatedAddresses,
            companyBusinessUnitCurrencyCreate: newBusinessUnit,
            companyBusinessUnitCurrencyDelete: deletedBusinessUnit,
            companyBusinessUnitCurrencyUpdate: updatedBusinessUnit,
          } as CompanyBusinessUnitUpdateInput,
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Business unit successfully.', {
          appearance: 'success',
        });
      }
    }
  }

  useEffect(() => {
    const defaultValues = getDefaultValues({
      businessUnit
    })
    reset(defaultValues)
  }, [businessUnit, reset])

  const fetchCompanyChartOfAccounts = () => {
    getCompanyChartOfAccounts({
      variables: {
        filter: {
          _isCorporate: {
            equalTo: !isProductionBusinessUnit
          },
        }
      }
    })
  }

  const fetchCompanyChartOfAccountsMemo = useCallback(
    fetchCompanyChartOfAccounts,
    [
      isProductionBusinessUnit,
    ]
  )

  useEffect(() => {
    fetchCompanyChartOfAccountsMemo()
  }, [fetchCompanyChartOfAccountsMemo])

  const { _isUpdatable } = { ...businessUnit }
  const { countries, companyAvailableCurrencies } = { ...commonData };

  return (
    <Modal isOpen isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        <FormProvider {...formMethods}>
          <Header onClose={onClose} />
          <BasicForm companyChartOfAccounts={companyChartOfAccountsData} />
          <Addresses isUpdatable={!!_isUpdatable} countries={countries} />
          <Currencies
            isUpdatable={!!_isUpdatable}
            companyCurrencies={companyAvailableCurrencies}
          />
          <Footer
            loading={updateEnvironmentLoading}
            onSubmit={handleSubmit(onHandleSubmit)}
            onCancel={onClose}
          />
        </FormProvider>
      </Stack>
    </Modal>
  );
}
