import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    alignItems: 'self-start',
    padding:"25px 20px 0px"
  },
  container: {
    width: 800
  },
  footer:{
    height: 70,
    backgroundColor: theme.palette.neutralLighterAlt,
    padding: "10px 20px 10px",
  },
  detailsLabel:{
    marginLeft: 20,
    marginBottom: 10,
  },
  addressContainer: {
    width: 400
  },
  itemSelected: {
    cursor: 'pointer',
    padding: '10px 20px',
    backgroundColor: theme.palette.neutralQuaternaryAlt,
  },
  itemHover: {
    cursor: 'pointer',
    padding: '10px 20px',
    ':hover': {
      backgroundColor: theme.palette.neutralLight,
    },
  },
  item: {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  addressStack: {
    width: 17
  },
  mT20: {
    marginTop: 20
  },
  mB20: {
    marginBottom: 20
  },
  inputContainer:{
    flex: 0.5
  }
}));