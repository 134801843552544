import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  logoContainer: {
    width: 80,
    height: 80,
    backgroundColor: 'white',
    borderRadius: 20,
    boxShadow: '2px 3px 9px -6px rgba(0,0,0,0.75)',
  },
  container: {
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  containerBox: {
    height: '100%',
  },
  button: {
    outline: 'none',
    border: 'none',
    backgroundColor: '#0369de',
    fontSize: 13,
    textDecoration: 'none',
    borderRadius: 23,
    color: 'white',
    height: 35,
    width: 70,
  },
}));
