import { SupplierValues, ContactRowValues, AddressRowValues } from './types';

export const CONTACT_INITIAL_VALUES: ContactRowValues = {
  id: null,
  _rowTimestamp: null,
  contactName: null,
  emailAddress: null,
  telephoneNumber: null,
  isSendEmailAccount: null,
  isReceiveEmailAccount: null,
};

export const ADDRESS_INITIAL_VALUES: AddressRowValues = {
  id: null,
  _rowTimestamp: null,
  addressLine1: null,
  addressLine2: null,
  cityName: null,
  countryId: null,
  stateRegionId: null,
  postalCode: null,
  telephoneNumber: null,
  emailAddress: null,
  webAddress: null,
  isSendEmailAccount: null,
  isReceiveEmailAccount: null,
  isPrimary: null,
};

export const SUPPLIER_INITIAL_VALUES: SupplierValues = {
  companyOrLastName: null,
  firstName: null,
  vendorNumber: null,
  federalTaxReference:null,
  vatNumber:null,
  customerAccount: null,
  isIndividual: false,
  isCorporation: true,
  paymentTermTypeId: null,
  isRelatedPartySupplier: null,
  isCompetitiveBidsRequired: null,
  isContractualPurchaseOrderRequired: null,
  isEnvironmentalImpactTracked: null,
  environmentalMetricId: null,
  purchaseDocumentsPoolId: null,
  bankAccountName: null,
  bankRoutingNumber: null,
  bankName: null,
  bankSortCode: null,
  bankIbanCode: null,
  bankAccountNumber: null,
  bankSwiftCode: null,
  vendorServiceTypeId: null,
  vendorServiceRate: null,
  isRateRequired: null,
  isServiceRateAllowed: null,
  isW9OnFile: null,
  w9ExpirationDate: null,
  supplierAddressesBySupplierId: [
    { ...ADDRESS_INITIAL_VALUES, isPrimary: true },
  ],
  supplierContactsBySupplierId: [],
};
