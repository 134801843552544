import { makeStyles } from '@fluentui/react';
import {
  SIGNATURE_PAD_BG_COLOR,
  SIGNATURE_PAD_BORDER_COLOR,
} from '../../SignaturePad/index.styles';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      cursor: 'pointer',
      width: 300,
      height: 115,
      borderRadius: 2,
      backgroundColor: SIGNATURE_PAD_BG_COLOR,
      border: `1px solid ${SIGNATURE_PAD_BORDER_COLOR}`,
      transition: 'all 0.1s ease',

      ':hover': {
        transition: 'all 0.3s ease',
        transform: 'scale(1.05, 1.05)',
        backgroundColor: `${SIGNATURE_PAD_BORDER_COLOR}11`,
        boxShadow: '1px 10px 20px -12px  #00000033',
        MozWindowShadow: '1px 10px 30px -12px  #00000033',
        WebkitBoxShadow: '1px 10px 30px -12px #00000033',
        border: `2px solid ${theme.palette.themePrimary}`,
      },
      ':active': {
        backgroundColor: `${SIGNATURE_PAD_BORDER_COLOR}22`,
      },
    },
    selectedOption: {
      borderRadius: 5,
      border: `3px solid ${theme.palette.themePrimary}`,
    },
    acceptIconConatiner: {
      margin: -15,
      zIndex: 10,
      width: 30,
      height: 30,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.themePrimary,
    },
    acceptIconStyles: {
      color: 'white',
    },
  };
});
