import React from 'react';
import { MemoizedHierarchyComponent } from 'common/components/PreviewHierarchyComponents/MemoizedHierarchyComponent';
import { HierarchyComponent } from './HierarchyComponent';

interface PreHierarchyModalProps {
  entityId: string | undefined;
}

export const PreHierarchyModal: React.FC<PreHierarchyModalProps> = ({
  entityId,
}) => {
  return (
    <>
      <MemoizedHierarchyComponent
        entityId={entityId}
        HierarchyComponent={() => <HierarchyComponent entityId={entityId} />}
      />
    </>
  )
}
