import React, { FC } from 'react';
import { useField } from 'formik';
import {
  Dropdown,
  IconButton,
  IDropdown,
  IDropdownOption,
  IDropdownProps,
  makeStyles,
  Stack,
} from '@fluentui/react';

type AdvancedFormikDropdownProps<T> = Omit<T, 'name'> & {
  name: string;
  onSelection?: (selected: IDropdownOption | undefined) => void;
  onRemoveSelection?: () => void;
};

export const AdvancedFormikDropdown: FC<
  AdvancedFormikDropdownProps<IDropdownProps>
> = ({ name, onRemoveSelection, onSelection, ...props }) => {
  const [field, meta, helpers] = useField<string | number | undefined | null>(
    name
  );
  const { value, onChange, ...rest } = field;
  const dropdownRef = React.createRef<IDropdown>();
  const onRemoveFocus = () => dropdownRef.current!.focus(false);
  const classStyles = useStyles();

  return (
    <Stack horizontal verticalAlign="end">
      <Stack.Item grow={1}>
        <Dropdown
          componentRef={dropdownRef}
          errorMessage={meta.error}
          selectedKey={value}
          {...rest}
          {...props}
          onChange={(event, option) => {
            helpers.setValue(option?.key);
            onChange(event);
            onSelection?.(option);
          }}
        />
      </Stack.Item>
      <Stack horizontal>
        {value && !props.required && (
          <IconButton
            type="button"
            style={{
              alignSelf: 'end',
            }}
            onClick={() => {
              onRemoveFocus();
              onRemoveSelection?.();
              helpers.setValue(null);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={classStyles.icon}
            ariaLabel="Close"
          />
        )}
      </Stack>
    </Stack>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
  },
  underline: {
    color: theme.semanticColors.inputBorder,
    backgroundColor: theme.semanticColors.inputBorder,
    height: 1,
  },
}));
