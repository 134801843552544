import { ReferenceCodeList } from 'common/graphql/__generated__/ReferenceCodeList';
import { formatDropdownOptions } from 'common/utils';

const getRefCodes = (referenceCodes: ReferenceCodeList | undefined) => {
  const refCodesOptions = {
    refCode1Options: formatDropdownOptions(
      referenceCodes?.reference1Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode2Options: formatDropdownOptions(
      referenceCodes?.reference2Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode3Options: formatDropdownOptions(
      referenceCodes?.reference3Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
    refCode4Options: formatDropdownOptions(
      referenceCodes?.reference4Codes?.nodes,
      {
        getKey: (item) => item.referenceCode,
        getText: (item) => item.name!,
        includeAll: false,
      }
    ),
  };

  return refCodesOptions;
};

export { getRefCodes };
