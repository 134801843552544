import { makeStyles, Separator, Stack } from '@fluentui/react';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { GetDocumentDetails_entityDocument } from '../__generated__/GetDocumentDetails';
import { DataView } from './DataView';
interface DocumentDetailsProps {
  documentData: GetDocumentDetails_entityDocument | undefined | null;
}
const useStyles = makeStyles(() => ({
  container: {
    margin: "30px 0px",
  },
  dataViewContainer: {
    padding: "10px 0px",
    width: "100%"
  },
}));
export const DocumentDetails: React.FC<DocumentDetailsProps> = ({ documentData }) => {
  const styles = useStyles()
  const {
    _documentType,
    extractionType,
    indexName,
    indexDescription,
    indexReferenceNumber,
    indexTransactionDate,
    indexAmount,
    currency
  } = { ...documentData };
  const date = indexTransactionDate ? dateFormat(dateConvertions(indexTransactionDate)) : "-"
  return (
    <Stack className={styles.container}>
      <Stack
        horizontal
        tokens={{ childrenGap: 5 }}
      >
        <Stack className={styles.dataViewContainer}>
          <DataView title="Document Type" description={_documentType} />
        </Stack>
        <Separator vertical />
        <Stack className={styles.dataViewContainer}>
          <DataView title="Data Extraction Type" description={extractionType?.extractionType || "-"} />
        </Stack>
      </Stack>
      <Stack className={styles.dataViewContainer}>
        <DataView title="Name" description={indexName || "-"} />
      </Stack>
      <Stack className={styles.dataViewContainer}>
        <DataView title="Description" description={indexDescription || "-"} />
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 5 }}
      >
        <Stack className={styles.dataViewContainer}>
          <DataView title="Reference Number" description={indexReferenceNumber || "-"} />
        </Stack>
        <Separator vertical />
        <Stack className={styles.dataViewContainer}>
          <DataView title="Date" description={date} />
        </Stack>
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: 5 }}
      >
        <Stack className={styles.dataViewContainer}>
          <DataView title="Amount" isAmount description={indexAmount || null} />
        </Stack>
        <Separator vertical />
        <Stack className={styles.dataViewContainer}>
          <DataView title="Currency" description={currency?.isoCode || "-"} />
        </Stack>
      </Stack>
    </Stack>
  )
}
