import { IconButton, Modal, Stack, Text, TooltipHost } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import React, { useMemo, useState } from 'react';
import { useStyles } from './index.styles'
const HEADER_TITLE = 'Preview Hierarchy';

interface MemoizedHierarchyComponentProps {
  entityId: string | undefined;
  HierarchyComponent: () => JSX.Element | null;
}

export const MemoizedHierarchyComponent: React.FC<MemoizedHierarchyComponentProps> = ({
  entityId,
  HierarchyComponent
}) => {
  const styles = useStyles();
  const [visible, setVisible] = useState(false);

  const MemoizedHierarchyComponent = useMemo(HierarchyComponent, [entityId]);

  if (entityId)
    return (
      <Stack>
        <TooltipHost content={'Preview Approval Hierarchy'}>
          <IconButton
            iconProps={{ iconName: 'Org' }}
            ariaLabel="Org"
            onClick={() => setVisible(true)}
          />
        </TooltipHost>
        {visible && (
          <Modal
            isOpen
            scrollableContentClassName={styles.noScrollContainer}
            containerClassName={styles.modalContainer}
          >
            <Stack className={styles.container}>
              <Stack
                className={styles.headerContainer}
                tokens={{ padding: 25 }}
              >
                <Stack
                  horizontalAlign="space-between"
                  horizontal
                  className={styles.alignItemsCenter}
                  tokens={{ childrenGap: 10 }}
                >
                  <Stack
                    className={styles.alignItemsCenter}
                    horizontal
                    tokens={{ childrenGap: 10 }}
                  >
                    <Text variant={'xLarge'}>{HEADER_TITLE}</Text>
                  </Stack>

                  <Stack>
                    <CloseButton onClick={() => setVisible(false)} />
                  </Stack>
                </Stack>
              </Stack>
              {MemoizedHierarchyComponent}
            </Stack>
          </Modal>
        )}
      </Stack>
    );
  else return null;

}
