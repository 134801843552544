import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  loadingScreen: {
    padding: 50,
    width: 450,
    height: 200,
    textAlign: 'center',
    verticalAlign: 'center',
  },
  modelContainer: {
    display: 'flex',
    width: 600,
    alignItems: 'center',
  },
  formContainer: {
    marginTop: 50,
    width: '100%',
    padding: 20,
  },
  footerContainer: {
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
    padding: '10px 20px',
    marginTop: 20,
  },
  cancelIconContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyItems: 'flex-end',
    paddingLeft: 5,
    paddingBottom: 10,
  },
  inlineLabelStyle: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: 20,
  },
  flexContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  containerHeader: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    cursor: 'move',
  },
  row: {
    height: 30,
  },
}));
