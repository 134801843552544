import { NetworkStatus, useQuery } from '@apollo/client';
import { SelectionMode, Stack, makeStyles } from '@fluentui/react';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { EntityDocumentFilter, EntityDocumentsOrderBy } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { GetEntityDocument_entityDocument } from 'documents/documentAssignment/folder/view/__generated__/GetEntityDocument';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Header } from '../Header';
import { Filters, filterOptionProps } from './Filters';
import { AvailablePurchaseOrderRevisions, AvailablePurchaseOrderRevisionsVariables, AvailablePurchaseOrderRevisions_availablePurchaseOrderRevisions_nodes } from './__generated__/AvailablePurchaseOrderRevisions';
import { getColumns } from './column.data';
import { ColumnStylesProps, onRenderItems } from './columns.render';
import { onHandleSearch, toFilterVariable } from './utils';
const AVAILABLE_PURCHASE_ORDER_REVISIONS = loader('./AvailablePurchaseOrderRevisions.graphql')

const useStyles = makeStyles((theme) => ({
  onRenderColumnStack: {
    height: 25,
  },
  container: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    cursor: 'move',
  },
  row: {
    height: 30
  }
}));

interface PORevisionsListProps {
  entityDocument: GetEntityDocument_entityDocument;
  onClose: () => void;
}

export type AvailablePurchaseOrderRevisionsItem = Omit<AvailablePurchaseOrderRevisions_availablePurchaseOrderRevisions_nodes, 'currency'> & {
  currency: string
};

export const PORevisionsList: React.FC<PORevisionsListProps> = ({
  entityDocument,
  onClose,
}) => {
  const styles = useStyles();
  const columnStyles: ColumnStylesProps = {
    onRenderColumnStack: styles.onRenderColumnStack,
  };
  const renderRef = useRef(false);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [filter, setFilter] = useState<EntityDocumentFilter>();
  const { id } = { ...entityDocument }

  const {
    data,
    loading,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<
    AvailablePurchaseOrderRevisions,
    AvailablePurchaseOrderRevisionsVariables
  >(AVAILABLE_PURCHASE_ORDER_REVISIONS, {
    variables: {
      entityDocumentId: id,
      first: TABLE_ROWS,
      orderBy: [
        EntityDocumentsOrderBy.INDEX_NAME_ASC,
        EntityDocumentsOrderBy.PRIMARY_KEY_ASC,
      ]
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const _renderItemColumn = useCallback(onRenderItems(
    columnStyles,
    entityDocument
  ), [
    columnStyles,
  ]);

  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);

  useEffect(() => {
    setCustomColumns();
  }, [setCustomColumns]);

  const refetching = networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.setVariables;

  const { nodes, pageInfo } = { ...data?.availablePurchaseOrderRevisions }
  const { hasNextPage } = { ...pageInfo }

  const transformedData: AvailablePurchaseOrderRevisionsItem[] = refetching
    ? []
    : nodes?.map((item) => ({
      ...item,
      currency: item.currency?.isoCode || '',
    })) || [];

  const handleSearch = async (showMore: boolean) => {
    const { queryVariables } = onHandleSearch(
      id,
      showMore,
      sortOrderParam,
      data?.availablePurchaseOrderRevisions,
      filter
    );
    if (showMore) fetchMore?.({ variables: queryVariables });
    else refetch(queryVariables);
  };

  const handleSearchMemo = useCallback(handleSearch, [sortOrderParam, filter]);

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false);
    else renderRef.current = true;
  }, [handleSearchMemo]);

  const [filtersArray, setFiltersArray] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: true,
  });

  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFiltersArray(filterOptions);
    const entityDocumentsFilterArray: EntityDocumentFilter | undefined =
      filterOptions.filterTypes?.length
        ? ({ and: toFilterVariable(filterOptions) } as EntityDocumentFilter)
        : undefined;
    setFilter(entityDocumentsFilterArray);
  };

  return (
    <>
      <Header onClose={onClose}>
        <Filters
          onFilterChange={onFilterChange}
          filterOptions={filtersArray}
        />
      </Header>
      <Stack style={{ marginTop: 80, height: "100%", paddingBottom: 20 }}>
        <InfiniteList
          loading={loading}
          items={transformedData}
          hasNextPage={hasNextPage}
          columns={gridColumns.filter((_column) => _column.isVisible)}
          onRenderItemColumn={_renderItemColumn}
          onColumnHeaderClick={(_, column) => {
            if (column) _onColumnClick(column);
          }}
          selectionMode={SelectionMode.none}
          onLoadMore={async () => {
            await handleSearch(true);
          }}
        />
      </Stack>
    </>
  )
}
