import { Callout, DirectionalHint, Label, Stack, Text } from '@fluentui/react';
import React from 'react';
import { DocumentPackageEmailModalProps } from '..';
import { useStyles } from './index.styles';
import { getStatusColor, toTitleCase } from '../utils';
import { IconState } from 'common/types/globalTypes';

type EmailCalloutProps = Pick<
  DocumentPackageEmailModalProps,
  'emailDocument'
> & {
  isOpen: boolean;
  targetId: string;
  onDismiss: () => void;
  onViewList: () => void;
};

export const EmailCallout: React.FC<EmailCalloutProps> = ({
  isOpen,
  targetId,
  emailDocument,
  onDismiss,
}) => {
  const styles = useStyles();
  if (!isOpen) return null;
  const { _recipientList, _sendIconState, sendStatusType } = {
    ...emailDocument,
  };
  return (
    <Callout
      className={styles.container}
      target={targetId}
      directionalHint={DirectionalHint.bottomCenter}
      onDismiss={onDismiss}
    >
      <Stack
        tokens={{
          padding: '20px 14px',
          childrenGap: 10,
        }}
      >
        <Stack wrap>
          <Label>Recipients</Label>
          <Text>{_recipientList}</Text>
        </Stack>
        <Stack>
          <Label>Status</Label>
          <Stack horizontal horizontalAlign="space-between">
            {_sendIconState && (
              <Text style={{ color: getStatusColor(_sendIconState) }}>
                {_sendIconState === IconState.SENT
                  ? toTitleCase(sendStatusType || '')
                  : toTitleCase(_sendIconState)}
              </Text>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
