import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  makeStyles,
  PrimaryButton,
  Stack,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  UserTemplateCopy,
  UserTemplateCopyVariables,
} from './__generated__/UserTemplateCopy';
import { generateTemplateName } from './utils';
import { DepartmentOccupationTemplatesOrderBy } from 'common/types/globalTypes';

const CREATE_USER_TEMPLATE_COPY = loader('./UserTemplateCopy.graphql');
const USER_TEMPLATES = loader('../../list/UserTemplates.graphql');

interface CopyUserTemplateProps {
  id: GqlUUID;
  rowTimestamp: string | null | undefined;
  name: string;
}
const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginRight: 10,
  },
}));
export const CopyUserTemplate: React.FC<CopyUserTemplateProps> = ({
  id,
  rowTimestamp,
  name,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>();
  const [userTemplateCopyCreate] = useMutation<
    UserTemplateCopy,
    UserTemplateCopyVariables
  >(CREATE_USER_TEMPLATE_COPY, { errorPolicy: 'all' });

  const handleSubmit = async () => {
    const inputVariables = {
      userTemplateId: id!,
      rowTimestamp: rowTimestamp!,
      newTemplateName: newName ?? generateTemplateName(name),
    };
    const { errors } = await userTemplateCopyCreate({
      variables: {
        input: inputVariables,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: USER_TEMPLATES,
          variables: {
            orderBy: DepartmentOccupationTemplatesOrderBy.NAME_ASC,
          },
        },
      ],
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      addToast('Clone created successfully', { appearance: 'success' });
      setShowDialog(false);
    }
  };

  return (
    <>
      <TooltipHost content="Clone">
        <IconButton
          iconProps={{ iconName: 'Copy' }}
          ariaLabel="Copy"
          onClick={() => setShowDialog(true)}
          className={styles.iconButton}
        />
      </TooltipHost>
      {showDialog && (
        <Dialog
          hidden={false}
          onDismiss={() => setShowDialog(false)}
          dialogContentProps={{
            title: 'Create User Template Clone',
            type: DialogType.largeHeader,
          }}
          minWidth={500}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <TextField
              name="newName"
              label="Name"
              defaultValue={generateTemplateName(name)}
              placeholder="Enter name"
              onChange={(_event, value) => {
                if (value) {
                  setNewName(value);
                  setDisabled(false);
                } else setDisabled(true);
              }}
              required
            />
          </Stack>
          <DialogFooter>
            <PrimaryButton
              text="Confirm"
              onClick={async () => {
                handleSubmit();
              }}
              disabled={disabled}
            />
            <DefaultButton onClick={() => setShowDialog(false)} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};
