import { makeVar, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Documents } from './DocumentList';
import { FolderList } from './folder/list';

export enum FileCabinetTabs {
  folder = 'folder',
  documents = 'documents',
}

export const setTabMode = makeVar<string>(FileCabinetTabs.folder);

export const FileCabinetSection: React.FC = () => {
  const batchState = useReactiveVar(setTabMode);
  useEffect(() => {
    return () => {
      setTabMode(FileCabinetTabs.folder);
    };
  }, []);

  const tabSwitchLogic = batchState === FileCabinetTabs.folder;

  return <>{tabSwitchLogic ? <FolderList /> : <Documents />}</>;
};
