import React, { useEffect, useState } from 'react';
import { bottomNavItems, getNavGroups, INavGroup, INavItem } from './navGroups';
import { Separator, Stack, IconButton } from '@fluentui/react';
import { useStyles } from './index.styles';
import { ProjectAndRole } from './ProjectAndRole';
import clsx from 'clsx';
import { useReactiveVar } from '@apollo/client';
import { expandedNav, setUserPrivileges } from 'utility/cache/ui';
import { currentUserRole } from 'utility/cache';
import { useCommonStyles } from 'common/styles';
import { NavItem } from './NavGroup/NavItem';
import { matchPath, useLocation } from 'react-router-dom';
import { NavGroup } from './NavGroup';

export const NavBar: React.FC = () => {
  const styles = useStyles();
  const [navGroups, setNavGroups] = useState<INavGroup[] | undefined>();
  const commonStyles = useCommonStyles();
  const expanded = useReactiveVar(expandedNav);
  const privileges = useReactiveVar(setUserPrivileges);
  const location = useLocation();
  const role = currentUserRole();
  const filterByRole = (item: INavItem | INavGroup) =>
    !item.restricted ||
    (item.restricted === 'administrator' && role?.isAdministrator) ||
    (item.restricted === 'supervisor' && role?.isProjectSupervisor);

  useEffect(() => {
    if (privileges.size > 0) {
      const navigationData = getNavGroups(privileges);
      setNavGroups(navigationData);
    }
  }, [privileges]);

  const bottomNavComponents = bottomNavItems
    .filter(filterByRole)
    .map((item) => (
      <NavItem
        Icon={item.Icon}
        text={item.name}
        to={item.path}
        active={
          !!matchPath(location.pathname, {
            path: item.path,
          })
        }
        smallIcon
        expanded={expanded}
        key={item.name}
        className={clsx(expanded && styles.bottomExpandedNavItem)}
      />
    ));

  return (
    <Stack
      className={clsx(
        styles.root,
        commonStyles.noScroll,
        expanded ? styles.expanded : styles.collapsed
      )}
      verticalAlign="space-between"
    >
      <Stack className={commonStyles.noScroll}>
        <ProjectAndRole expanded={expanded} />
        <Separator className={styles.separator} />

        <Stack
          className={clsx(
            styles.mainLinks,
            expanded ? styles.smallScroll : styles.hideScroll
          )}
        >
          {navGroups?.filter(filterByRole).map(({ items, ...group }) => (
            <NavGroup
              data={{ items: items?.filter(filterByRole), ...group }}
              expanded={expanded}
              key={group.name}
            />
          ))}
        </Stack>
      </Stack>
      <Stack>
        {bottomNavComponents.length > 0 && (
          <Separator
            className={clsx(styles.separator, styles.bottomSeparator)}
          />
        )}
        <Stack>
          {bottomNavComponents.slice(0, -1)}
          <Stack
            horizontal={expanded}
            verticalAlign="center"
            horizontalAlign="end"
          >
            {bottomNavComponents[bottomNavComponents.length - 1]}
            <IconButton
              iconProps={{
                iconName: expanded ? 'DoubleChevronLeft' : 'DoubleChevronRight',
              }}
              className={styles.expandCollapseBtn}
              onClick={() => expandedNav(!expanded)}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
