import { FontWeights, makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
    marginTop: 15,
  },
  modalInsideStack: {
    width: 1200,
    height: 700,
  },
  linkRoot: {
    width: '100%',
    fontSize: theme.fonts.medium.fontSize,
    color: theme.palette.black,
    ':focus': {
      color: theme.palette.black,
    },
  },
  stickyInsideStack: {
    alignItems: 'self-start',
    padding: 25,
  },
  stack: {
    padding: 25,
  },
  container: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    flex: 1,
    flexDirection: 'row',
  },
  flex50: {
    flex: 0.5,
  },
  cellAlignRight: {
    textAlign: 'right',
  },
  onrenderColumnStack: {
    height: 25,
  },
  documentsContainer: {
    margin: '0px 0px',
  },
  marginB20: {
    marginBottom: 20,
  },
  marginB200: {
    marginBottom: 200,
  },
  amountStack: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  footerRow: {
    backgroundColor: theme.palette.neutralLighter,
  },
  currencyLabel: {
    fontWeight: 'bold',
  },
  totalAmount: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
    textAlign: 'right',
  },
  amount: {
    textAlign: 'right',
    paddingRight: 25,
    display: 'block',
  },
  amountWarning:{ color: 'red' },
  differenceText: {
    color:theme.palette.themePrimary
  },
  differenceAmount:{
    marginLeft: "6px !important"
  },
  invoiceTotalLabel:{
    fontWeight: FontWeights.bold,
    textAlign:"right"
  }
}));
