import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  label: {
    paddingRight: 4,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
}));
