import { useLazyQuery } from '@apollo/client';
import { ITextFieldProps, ITextFieldStyles, IconButton, Link, Spinner, Stack, Text, TextField, useTheme } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { LookupAccountsOrderBy } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { LookupAccountCoaSearch, LookupAccountCoaSearchVariables } from '../../list/__generated__/LookupAccountCoaSearch';
import { ChartOfAccounts_chartOfAccounts_nodes } from '../../list/chartAccount/__generated__/ChartOfAccounts';
import { LookUpCallout } from './Lookup';
import { AccountsEntrySearch_lookupAccountSearch_nodes } from './__generated__/AccountsEntrySearch';
import { useStyles } from './index.styles';
const LOOKUP_ACCOUNTS_COA_SEARCH = loader("../../list/lookupAccountCoaSearch.graphql");

type AccountAutoCompleteProps = Partial<ITextFieldProps> & {
  lookUpCalloutWidth?: number;
  chartOfAccount: ChartOfAccounts_chartOfAccounts_nodes | null;
  onAccountSelect?: (account: AccountsEntrySearch_lookupAccountSearch_nodes | null) => void;
}

export const AccountAutoComplete: React.FC<AccountAutoCompleteProps> = ({
  lookUpCalloutWidth = 500,
  chartOfAccount,
  onAccountSelect,
  ...props
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [value, setValue] = useState<string | null>();
  const [disableTextfield, setDisableTextfield] = useState<boolean | null | undefined>(false);

  const textFieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: theme.palette.white,
    },
  };

  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupAccountCoaSearch,
    LookupAccountCoaSearchVariables
  >(LOOKUP_ACCOUNTS_COA_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "network-only",
  });

  const { id, disabled, ...textFieldProps } = { ...props };

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      if (chartOfAccount?.id) {
        fetchLookUp({
          variables: {
            chartOfAccountId: chartOfAccount?.id,
            first: 50,
            lookupName: value,
            orderBy: [
              LookupAccountsOrderBy.LOOKUP_ACCOUNT_ASC,
              LookupAccountsOrderBy.LOOKUP_NAME_ASC,
              LookupAccountsOrderBy.PROJECT_ACCOUNT_ASC,
              LookupAccountsOrderBy.PRIMARY_KEY_ASC,
            ]
          },
        });
        queryCalled();
      }
    }, 350),
    [value]
  );

  const renderSpinner = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disabled ? null : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              setValue(null);
              setDisableTextfield(false);
              onAccountSelect?.(null);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };

  return (
    <Stack>
      <TextField
        {...textFieldProps}
        id={id}
        autoComplete={'off'}
        value={value || ''}
        onChange={(_event, value) => {
          if (value) {
            setValue(value);
            callbackLookUp(value, () => {
              if (!calloutVisible) setCalloutVisible(true);
            });
          } else {
            setValue(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textFieldStyles}
        disabled={disabled ? true : disableTextfield ? true : false}
      />
      {calloutVisible &&
        !loading &&
        data?.lookupAccountCoaSearch?.nodes.length! > 0 && (
          <LookUpCallout
            lookUpCalloutWidth={lookUpCalloutWidth}
            onDismiss={() => setCalloutVisible(false)}
            targetID={`#${id!}`}
          >
            {data?.lookupAccountCoaSearch?.nodes.map((data, index) => {
              return (
                <Link
                  onClick={() => {
                    setValue(data.lookupAccount);
                    setCalloutVisible(false);
                    onAccountSelect?.(data)
                    setDisableTextfield(true);
                  }}
                  className={styles.resultItem}
                  key={index.toString()}
                >
                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Text className={commonStyles.colorThemePrimary}>
                      {data.lookupAccount}
                    </Text>
                    <Text nowrap>{data.lookupName}</Text>
                  </Stack>
                </Link>
              );
            })}
          </LookUpCallout>
        )}
    </Stack>
  )
}
