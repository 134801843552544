import { FormikTextField } from 'common/components';
import React from 'react';

export const BasicForm = () => {
    return <>
        <FormikTextField
            name="name"
            label="Name"
            placeholder="Enter name"
            required
        />
        <FormikTextField
            name="description"
            label="Description"
            multiline
            placeholder="Enter description"
        />
    </>
        ;
};
