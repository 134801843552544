import {
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack
} from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from "./index.styles";

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();
  return (
    <Stack
      tokens={{
        childrenGap: 10
      }}
      className={styles.fullWidthContainer}>
      {
        Array.from(Array(6).keys()).map(ele => (
          <Stack key={ele}
          >
            <ShimmerElementsGroup
              width="100%"
              shimmerElements={[
                { type: ShimmerElementType.line, height: 40, width: '100%' },
              ]}
            />
            <ShimmerElementsGroup
              width="100%"
              shimmerElements={[
                { type: ShimmerElementType.gap, height: 30, width: '100%' },
              ]}
            />
          </Stack>
        ))
      }
    </Stack>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <>
      <Stack className={clsx(styles.containerPadding, styles.containerSetting)}>
        <Shimmer customElementsGroup={ShimmerSection()} />
      </Stack>
    </>
  );
};
