export interface dataExtractModelOptionsType {
  id: number;
  isImageDocument: boolean | undefined | null;
  isPdfDocument: boolean | undefined | null;
  isRawTextDocument: boolean | undefined | null;
}

export const getFileTypesList = (
  selectedOption?: dataExtractModelOptionsType
) => {
  if (!selectedOption) return null;
  const value = Object.entries(selectedOption)?.reduce((prev, [key, val]) => {
    if (val) {
      if (key === 'isPdfDocument') return [...prev, 'application/pdf'];
      if (key === 'isImageDocument')
        return [
          ...prev,
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/heif',
          'image/webp',
          'image/tiff',
        ];
      if (key === 'isRawTextDocument') return [...prev, 'text/plain'];
      return [...prev];
    }
    return [...prev];
  }, [] as string[]);
  return value;
};
