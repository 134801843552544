import { secureRowLevelNode } from 'common/components/SecureRowLevel';
import React from 'react';
import { AccountingEntryPayCycle, AccountingEntryPayCycleVariables } from '../../../__generated__/AccountingEntryPayCycle';
import { useApolloClient, useQuery } from '@apollo/client';
import { PayCycleAvailableDocumentTypes } from 'ap/paymentCycle/__generated__/PayCycleAvailableDocumentTypes';
import { loader } from 'graphql.macro';
import { Separator, Stack, makeStyles } from '@fluentui/react';
import { DocumentList } from 'common/components/DocumentList';
import { columns } from './column.data'
import { AttachForm } from './AttachForm';
import { PayCycleUploadDocument_payCycleUploadDocument } from 'ap/paymentCycle/__generated__/PayCycleUploadDocument';
import { UploadForm } from './UploadForm';
const PAY_CYCLE_AVAILABLE_DOCUMENT_TYPE = loader(
  '../../../../../PayCycleAvailableDocumentTypes.graphql'
);
const ACCOUNTING_ENTRY_PAYCYCLE = loader('../../../AccountingEntryPayCycle.graphql');

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 24,
  },
}));

export interface AttachSectionProps {
  secureRowLevel: secureRowLevelNode[];
  paymentCycle: AccountingEntryPayCycle | null | undefined;
  onUpload?: (
    fileSelected: File,
    documentData: PayCycleUploadDocument_payCycleUploadDocument,
    toastId: string
  ) => void;
}

export const AttachSection: React.FC<AttachSectionProps> = ({
  secureRowLevel,
  paymentCycle,
  onUpload,
}) => {
  const styles = useStyles();
  const client = useApolloClient();

  const { data: availableDocumentTypes } =
    useQuery<PayCycleAvailableDocumentTypes>(PAY_CYCLE_AVAILABLE_DOCUMENT_TYPE);

  const {
    id,
    entityDocumentsByEntityId
  } = { ...paymentCycle?.payCycle }


  return (
    <Stack
      tokens={{ childrenGap: 10 }}
      className={styles.container}
    >
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        <AttachForm
          paymentCycle={paymentCycle}
          availableDocumentTypes={availableDocumentTypes}
        />
        <Separator vertical />
        <UploadForm
          availableDocumentTypes={availableDocumentTypes}
          paymentCycle={paymentCycle}
          onUpload={onUpload}
        />
      </Stack>
      {
        !!entityDocumentsByEntityId?.nodes?.length && (
          <Stack tokens={{ childrenGap: 10, padding: 10 }}>
            <DocumentList
              isNew={false}
              columns={columns}
              data={entityDocumentsByEntityId?.nodes! || []}
              isDeleteButtonVisible
              isShareAndProtectButtonsVisible
              secureRowLevel={secureRowLevel}
              onRemoveRow={(data) => {
                const cacheData = client.readQuery<
                  AccountingEntryPayCycle,
                  AccountingEntryPayCycleVariables
                >({
                  query: ACCOUNTING_ENTRY_PAYCYCLE,
                  variables: {
                    id: id!,
                  },
                });
                if (cacheData) {
                  const filteredList =
                    cacheData?.payCycle?.entityDocumentsByEntityId.nodes.filter(
                      (ele) => ele.id !== data.id
                    );
                  const updatedData: AccountingEntryPayCycle = {
                    payCycle: {
                      ...cacheData?.payCycle!,
                      entityDocumentsByEntityId: {
                        ...cacheData?.payCycle?.entityDocumentsByEntityId,
                        nodes: filteredList!,
                      },
                    },
                  };
                  client.writeQuery<
                    AccountingEntryPayCycle,
                    AccountingEntryPayCycleVariables
                  >({
                    query: ACCOUNTING_ENTRY_PAYCYCLE,
                    data: updatedData,
                    variables: {
                      id: id!,
                    },
                  });
                }
              }}
            />
          </Stack>
        )}
    </Stack>
  )
}
