import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  modalInsideStack: {
    width: 1150,
    height: 700,
  },
  stickyInsideStack: {
    alignItems: 'self-start',
    padding: 25,
  },
  stack: {
    padding: 25,
  },
  documentTypeDropdown: {
    width: 250,
  },
  paddingL25: {
    paddingLeft: 25,
  },
  dropdownWrapper: {
    width: 250,
    marginLeft: 25,
    marginBottom: 20,
  },
  footerStack: {
    paddingRight: 25,
  },
  columnHeight: {
    height: 30,
  },
}));
