import { useQuery } from '@apollo/client';
import { DayOfWeek, Stack, Text } from '@fluentui/react';
import {
  AmountTextField,
  FormikDropdown,
  FormikNumberTextField,
  FormikTextField,
} from 'common/components';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import { useCommonStyles } from 'common/styles';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import { BatchCommonData } from 'postingTracker/batchEdit/batch/__generated__/BatchCommonData';
import React from 'react';
import { BatchValues } from '../../types';
import { BatchTransaction } from '../../__generated__/BatchTransaction';
import { useStyles } from '../index.styles';
const COMMON_DATA = loader('../../../BatchCommonData.graphql');

interface BasicFormProps {
  data: BatchTransaction | undefined;
}

export const BasicForm: React.FC<BasicFormProps> = ({ data }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { values } = useFormikContext<BatchValues>();
  const { data: commonData } = useQuery<BatchCommonData>(COMMON_DATA);
  const disableFields = data ? !data?.batchTransaction?._isUpdatable : false;
  const accountingPeriodOptions =
    commonData?.companyCorporatePeriods?.nodes.map((item) => ({
      disabled: item.isEntryAllowed ? false : true,
      key: item.id,
      text:
        item._periodYear +
          ' (' +
          dateFormat(dateConvertions(item.startDate)) +
          ' - ' +
          dateFormat(dateConvertions(item.endDate)) +
          ')' || '',
    })) || [];
  const currencyOptions =
    commonData?.companyCurrencies?.nodes.map((item) => ({
      key: item.id,
      text: item.isoCode! + '-' + item.name || '',
      isoCode: item.isoCode,
    })) || [];

  const batchTotalAmount = new Decimal(
    data?.batchTransaction?.invoiceBatches?.aggregates?.sum?.appliedAmount || 0
  );

  return (
    <Stack tokens={{ childrenGap: 20, padding: 25 }}>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flexHalf}>
          <FormikTextField
            label="Batch ID"
            name="referenceNumber"
            placeholder="Enter Reference Number"
            disabled={disableFields}
            required
          />
        </Stack.Item>
        <Stack.Item className={styles.flexHalf}>
          <FormikTextField
            name="description"
            label="Description"
            placeholder="Enter Description"
            disabled={disableFields}
            required
          />
        </Stack.Item>
      </Stack>

      {/* --------------- -  ----------- */}
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flexHalf}>
          <FormikDatePicker
            name="postingDate"
            placeholder="Select Posting Date"
            label="Posting Date"
            firstDayOfWeek={DayOfWeek.Sunday}
            showWeekNumbers
            firstWeekOfYear={1}
            showMonthPickerAsOverlay
            disabled={disableFields}
            showGoToToday
          />
        </Stack.Item>
        <Stack.Item className={styles.flexHalf}>
          <FormikDropdown
            options={currencyOptions}
            name="currencyId"
            label="Currency"
            placeholder="Select Currency"
            disabled={disableFields}
            required
          />
        </Stack.Item>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flexHalf}>
          <FormikNumberTextField
            disabled={disableFields}
            inputMode="numeric"
            name="weekNumber"
            label="Week#"
            placeholder="Week number"
          />
        </Stack.Item>
        <Stack.Item className={styles.flexHalf}>
          <AmountTextField
            name="controlTotalAmount"
            label="Total"
            disabled={disableFields}
            placeholder="Enter Total Amount"
            fixedDecimalScale
            decimalScale={2}
            allowNegative={true}
          />

          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <Text className={commonStyles.colorThemePrimary}>Difference</Text>
            <AmountTextView
              style={{ marginLeft: '6px !important' }}
              className={commonStyles.colorThemePrimary}
              value={
                !!values.controlTotalAmount &&
                !isNaN(parseFloat(values?.controlTotalAmount))
                  ? batchTotalAmount
                      .minus(values.controlTotalAmount || 0)
                      .toString()
                  : '0.00'
              }
            />
          </Stack>
        </Stack.Item>
      </Stack>

      {/* --------------- -  ----------- */}
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flexHalf}>
          <FormikDropdown
            name="corporatePeriodId"
            options={accountingPeriodOptions}
            label="Period"
            placeholder="Select Period"
            disabled={disableFields}
          />
        </Stack.Item>
        <Stack.Item className={styles.flexHalf}></Stack.Item>
      </Stack>
    </Stack>
  );
};
