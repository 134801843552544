import { useMutation } from '@apollo/client';
import {
  ActionButton,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityAction, EntityType } from 'common/types/utility';
import {
  CompanyCorporateYearsOrderBy,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { CompanyCorporateYearType } from '..';
import { AccountingPeriodView } from '../../view/AccountingPeriodView';
import { PERIOD_ROW_VALUES } from '../../view/constants';
import { CorporateRangeView } from '../../view/CorporateRangeView';
import { AccountingPeriodValues } from '../../view/types';
import {
  CompanyCorporateYears,
  CompanyCorporateYearsVariables,
} from '../__generated__/CompanyCorporateYears';
import { useStyles } from './index.styles';
import {
  CompanyCorporateYearDelete,
  CompanyCorporateYearDeleteVariables,
} from './__generated__/CompanyCorporateYearDelete';
const COMPANY_CORPORATE_YEARS = loader('../CompanyCorporateYears.graphql');
const COMPANY_CORPORATE_YEAR_DELETE = loader(
  './CompanyCorporateYearDelete.graphql'
);
interface YearProps {
  isOpen: boolean;
  refetching: boolean;
  corporateYear: CompanyCorporateYearType;
  availableCount: number;
  onHeaderClick?: (corporateYear: CompanyCorporateYearType) => void;
  onEditClick: () => void;
}
export enum YearOpen {
  yes = 'Yes',
  no = 'No',
}
export enum EntryAllowed {
  yes = 'Yes',
  no = 'No',
}

export const YearList: React.FC<YearProps> = ({
  isOpen,
  corporateYear,
  availableCount = 0,
  onHeaderClick,
  onEditClick,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [hideCreatePeriodForm, { toggle: toggleCreatePeriodForm }] =
    useBoolean(false);
  const [hideCreatePeriodRangeForm, { toggle: toggleCreatePeriodRangeForm }] =
    useBoolean(false);
  const [fiscalYearId, setFiscalYearId] = useState<string | null>(null);
  const [fiscalYearData, setFiscalYearData] =
    useState<AccountingPeriodValues>();
  const [isNew, setIsNew] = useState<boolean>(true);

  const [deleteCompanyCorporateYear] = useMutation<
    CompanyCorporateYearDelete,
    CompanyCorporateYearDeleteVariables
  >(COMPANY_CORPORATE_YEAR_DELETE, { errorPolicy: 'all' });

  const onCreatePeriodClick = (fiscalYear: CompanyCorporateYearType) => {
    const fiscalYearDetails: AccountingPeriodValues = {
      id: fiscalYear.id,
      _rowTimestamp: fiscalYear._rowTimestamp,
      fiscalYear: fiscalYear.fiscalYear,
      startDate: fiscalYear.startDate,
      endDate: fiscalYear.endDate,
      isYearOpen: fiscalYear.isYearOpen,
      isEntryAllowed: fiscalYear.isEntryAllowed,
      companyCorporatePeriodsByFiscalYearId: [
        {
          ...PERIOD_ROW_VALUES,
          fiscalYearId: fiscalYear.id,
        },
      ],
    };
    setFiscalYearData(fiscalYearDetails);
    setFiscalYearId(fiscalYear.id);
    setIsNew(true);
    toggleCreatePeriodForm();
  };

  const onCreateRangeClick = (fiscalYear: CompanyCorporateYearType) => {
    setFiscalYearId(fiscalYear.id);
    setIsNew(true);
    toggleCreatePeriodRangeForm();
  };

  const deleteCorporateYear = async (corporateYear: EntityDeleteInput) => {
    const { errors } = await deleteCompanyCorporateYear({
      variables: { input: { entityDelete: [corporateYear] } },
      update: (cache, { data }) => {
        const cacheData = cache.readQuery<
          CompanyCorporateYears,
          CompanyCorporateYearsVariables
        >({
          query: COMPANY_CORPORATE_YEARS,
          variables: {
            orderBy: [CompanyCorporateYearsOrderBy.FISCAL_YEAR_ASC],
          },
        });
        if (cacheData) {
          const deletedId =
            data?.companyCorporateYearDelete?.deletedEntities![0]?.id;
          if (deletedId) {
            const filteredList = cacheData.companyCorporateYears?.nodes.filter(
              (year) => year.id !== deletedId
            );
            const newData: CompanyCorporateYears = {
              companyCorporateYears: {
                ...cacheData.companyCorporateYears!,
                nodes: filteredList!,
              },
            };
            cache.writeQuery<
              CompanyCorporateYears,
              CompanyCorporateYearsVariables
            >({
              query: COMPANY_CORPORATE_YEARS,
              variables: {
                orderBy: [CompanyCorporateYearsOrderBy.FISCAL_YEAR_ASC],
              },
              data: newData,
            });
          }
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
  };

  return (
    <>
      <Stack
        horizontal
        verticalAlign="center"
        className={clsx(
          styles.root,
          isOpen && styles.groupSelected,
          styles.linkLightHover
        )}
      >
        <Stack
          horizontal
          verticalAlign="center"
          className={styles.accountRowCell}
          tokens={{ childrenGap: 10 }}
        >
          {isOpen ? (
            <IconButton
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
              ariaLabel="Expand"
              onClick={() => onHeaderClick?.(corporateYear)}
            />
          ) : (
            <IconButton
              iconProps={{ iconName: 'ChevronRight' }}
              className={styles.iconButtonColor}
              ariaLabel="Collapse"
              onClick={() => onHeaderClick?.(corporateYear)}
            />
          )}
          <Text nowrap variant="medium">
            {corporateYear.fiscalYear}
          </Text>
          <Text variant="medium" className={styles.groupName}>
            {`(${availableCount})`}
          </Text>
        </Stack>
        <Stack className={styles.openingDateContainer}>
          <Text nowrap block variant="medium">
            {dateFormat(dateConvertions(corporateYear.startDate))}
          </Text>
        </Stack>
        <Stack className={styles.closingDateContainer}>
          <Text nowrap block variant="medium">
            {dateFormat(dateConvertions(corporateYear.endDate))}
          </Text>
        </Stack>
        <Stack className={styles.rowCell}>
          <Text nowrap block variant="medium">
            {corporateYear.isYearOpen ? YearOpen.yes : YearOpen.no}
          </Text>
        </Stack>
        <Stack className={styles.rowCell}>
          <Text nowrap block variant="medium">
            {corporateYear.isEntryAllowed ? EntryAllowed.yes : EntryAllowed.no}
          </Text>
        </Stack>
        <Stack
          className={styles.actionContainer}
          tokens={{ childrenGap: 10 }}
          horizontal
          verticalAlign="center"
        >
          <TooltipHost content="Edit">
            <IconButton
              iconProps={{ iconName: 'Edit' }}
              onClick={onEditClick}
            />
          </TooltipHost>
          <ActionMessageModal
            entityType={EntityType.AccountingPeriodYear}
            action={EntityAction.Delete}
            disabled={!corporateYear._isUpdatable}
            visible={true}
            onConfirm={() =>
              deleteCorporateYear({
                id: corporateYear.id,
                rowTimestamp: corporateYear._rowTimestamp!,
              })
            }
          />
          {isOpen && (
            <>
              <ActionButton
                onClick={() => onCreatePeriodClick(corporateYear)}
                iconProps={{ iconName: 'Add' }}
                text="New Period"
                checked
              />
              <ActionButton
                onClick={() => onCreateRangeClick(corporateYear)}
                iconProps={{ iconName: 'Add' }}
                text="Period Range"
                checked
              />
            </>
          )}
        </Stack>
      </Stack>
      {hideCreatePeriodForm && (
        <AccountingPeriodView
          visible={hideCreatePeriodForm}
          onDismiss={toggleCreatePeriodForm}
          fiscalYearData={fiscalYearData}
          isNew={isNew}
          fiscalYearId={fiscalYearId!}
          index={0}
        />
      )}
      {hideCreatePeriodRangeForm && (
        <CorporateRangeView
          visible={hideCreatePeriodRangeForm}
          onDismiss={toggleCreatePeriodRangeForm}
          isNew={isNew}
          fiscalYearId={fiscalYearId!}
        />
      )}
    </>
  );
};
