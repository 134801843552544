import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import {
  ActionButton,
  DefaultButton,
  IColumn,
  Icon,
  IconButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { AttachablePaymentDocuments_attachablePaymentDocuments_nodes } from 'ap/paymentTracking/view/PaymentTrackingView/BasicForm/PaymentDocuments/__generated__/AttachablePaymentDocuments';
import {
  AttachDocumentFilters,
  filterOptionProps,
} from 'common/components/AttachDocumentFilters';
import { toFilterVariable } from 'common/components/AttachDocumentModal/utils';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { CustomDropdown } from 'common/components/CustomDropdown';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { ATTACHABLE_TABLE_ROWS } from 'common/constants';
import {
  EntityDocumentFilter,
  EntityDocumentsOrderBy,
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  AttachableUserProfileDocuments,
  AttachableUserProfileDocumentsVariables,
} from 'settings/account/userSetup/view/__generated__/AttachableUserProfileDocuments';
import { UserProfile } from 'settings/account/userSetup/view/__generated__/UserProfile';
import { UserSetupCommonData } from 'settings/account/userSetup/__generated__/UserSetupCommonData';
import {
  AttachUserProfileDocument,
  AttachUserProfileDocumentVariables,
} from '../__generated__/AttachUserProfileDocument';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { OrderDirection } from './types';
import { toOrderByVariable } from './utils';

const ATTACHABLE_APPROVAL_DOCUMENTS = loader(
  '../../../AttachableUserProfileDocuments.graphql'
);
const ATTACH_APPROVAL_DOCUMENTS = loader(
  '../../UserAttach/AttachUserProfileDocument.graphql'
);
const USER_DETAILS = loader('../../../../view/UserProfile.graphql');
type AttachableDataType =
  AttachablePaymentDocuments_attachablePaymentDocuments_nodes;
interface AttachModalFormProps {
  AvailableDocumentTypes: UserSetupCommonData | undefined;
  userDetails: UserProfile | undefined;
}

export const AttachFormModal: React.FC<AttachModalFormProps> = ({
  ...props
}) => {
  const { dirty } = useFormikContext();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  return (
    <Stack>
      <TooltipHost content="Attach new files">
        <ActionButton
          onClick={() => setDialogVisible(true)}
          iconProps={{ iconName: 'Attach' }}
          text="Attach"
          checked
          disabled={dirty}
        />
      </TooltipHost>
      {dialogVisible && (
        <AttachForm {...props} onDismiss={() => setDialogVisible(false)} />
      )}
    </Stack>
  );
};
type AttachFormProps = AttachModalFormProps & { onDismiss: () => void };
export const AttachForm: React.FC<AttachFormProps> = ({
  AvailableDocumentTypes,
  userDetails,
  onDismiss,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [filterOptions, setFilterOptions] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: true,
  });
  const [gridColumns, setGridColumns] = useState<IColumn[]>(columns);
  const classNames = useStyles();
  const [selectedList, setselectedList] = useState<AttachableDataType[]>([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    number | null | undefined
  >();
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const {
    data: attachableUserProfileDocumentsData,
    loading: attachableLoading,
    networkStatus,
    fetchMore,
    refetch,
    variables,
  } = useQuery<
    AttachableUserProfileDocuments,
    AttachableUserProfileDocumentsVariables
  >(ATTACHABLE_APPROVAL_DOCUMENTS, {
    variables: {
      first: ATTACHABLE_TABLE_ROWS,
      userProfileId: userDetails?.userProfile?.id!,
      orderBy: [
        EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
        EntityDocumentsOrderBy.PRIMARY_KEY_DESC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const [attachDocuments, { loading: attachLoading }] = useMutation<
    AttachUserProfileDocument,
    AttachUserProfileDocumentVariables
  >(ATTACH_APPROVAL_DOCUMENTS, { errorPolicy: 'all' });

  const documentTypeOptions =
    AvailableDocumentTypes?.userProfileAvailableDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
      })
    ) || [];
  const _onAttach = async () => {
    const selectedEntities = selectedList.map((_, index) => {
      return (
        attachableUserProfileDocumentsData?.attachableUserProfileDocuments
          ?.nodes[index].id || null
      );
    });

    const { errors } = await attachDocuments({
      variables: {
        input: {
          userProfileId: userDetails?.userProfile?.id!,
          entityDocumentId: selectedEntities,
          documentTypeId: selectedDocumentType,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: USER_DETAILS,
          variables: {
            id: userDetails?.userProfile?.id,
          },
        },
      ],
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Document attached successfully', { appearance: 'success' });
      onDismiss();
    }
  };
  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        refetch({
          after: null,
          orderBy: toOrderByVariable({
            column: clickedColumn.key,
            direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
          }),
        });
      }
    },
    [gridColumns, refetch]
  );

  const _renderItemColumn = (
    item: AttachableDataType | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item?.[
        column?.fieldName as keyof AttachableDataType
      ] as string;
     
      switch (column?.key) {
        case '_documentPoolName':
          return (
            <Stack verticalAlign="center">
              <Text>{item._documentPoolName}</Text>
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign={'center'}
            >
              <Icon
                className={fileTypeColor(item.iconType || 'OTHER')}
                iconName={fileType(item.iconType || 'OTHER')}
              />
              <Text>{item.fileReference}</Text>
            </Stack>
          );
        case 'view':
           const viewDocumentVisible =
             item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case '_documentContents':
          return (
            <Stack verticalAlign="center">
              <Text>{item._documentContents}</Text>
            </Stack>
          );
        case '_uploadDate':
          return (
            <Stack verticalAlign="center">
              <Text>{getGlobalDateFormat(item._uploadDate!)}</Text>
            </Stack>
          );
        case 'indexTransactionDate':
          return (
            <Stack verticalAlign="center">
              {item.indexTransactionDate && (
                <Text>
                  {dateFormat(dateConvertions(item.indexTransactionDate!))}
                </Text>
              )}
            </Stack>
          );

        default:
          return (
            <Stack verticalAlign="center">
              <Text>
                {fieldContent === null || !fieldContent ? '' : fieldContent}
              </Text>
            </Stack>
          );
      }
    }
  };

  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFilterOptions(filterOptions);
    const entityDocumentsFilterArray: EntityDocumentFilter | undefined =
      filterOptions.filterTypes?.length
        ? ({ and: toFilterVariable(filterOptions) } as EntityDocumentFilter)
        : undefined;
    refetch({ ...variables, filter: entityDocumentsFilterArray });
  };

  const onLoadMore = () => {
    const newVariables: AttachableUserProfileDocumentsVariables = {
      ...variables,
      userProfileId: userDetails?.userProfile?.id!,
      after:
        attachableUserProfileDocumentsData?.attachableUserProfileDocuments
          ?.pageInfo.endCursor,
    };
    fetchMore?.({ variables: newVariables });
  };

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;
  const AttachDisable = selectedList.length === 0;
  return (
    <Modal isOpen isBlocking onDismiss={onDismiss}>
      <Stack className={classNames.modalInsideStack}>
        <Sticky stickyPosition={StickyPositionType.Header}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={classNames.stickyInsideStack}
          >
            <Text variant="xLarge">Attach Documents</Text>

            <CloseButton onClick={onDismiss} />
          </Stack>
        </Sticky>

        <Stack
          styles={{ root: { margin: 25 } }}
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="end">
            <CustomDropdown
              className={styles.documentTypeDropdown}
              label="Document Type"
              placeholder="All"
              selectedKey={selectedDocumentType}
              options={documentTypeOptions}
              onChange={(_, option) => {
                const optionSelected = parseInt(option?.key + '');
                refetch({ ...variables, documentTypeId: optionSelected });
                setSelectedDocumentType(optionSelected || null);
              }}
              onClear={() => {
                refetch({ ...variables, documentTypeId: null });
                setSelectedDocumentType(null);
              }}
            />
            <AttachDocumentFilters
              filterOptions={filterOptions}
              onFilterChange={onFilterChange}
            />
          </Stack>
        </Stack>

        <InfiniteList
          loading={attachableLoading}
          hasNextPage={
            attachableUserProfileDocumentsData?.attachableUserProfileDocuments
              ?.pageInfo.hasNextPage
          }
          items={
            refetching
              ? undefined
              : attachableUserProfileDocumentsData
                  ?.attachableUserProfileDocuments?.nodes!
          }
          selectionMode={SelectionMode.multiple}
          columns={gridColumns}
          onRenderItemColumn={_renderItemColumn}
          onColumnHeaderClick={_onColumnClick}
          onLoadMore={onLoadMore}
          onSelectionChanged={setselectedList}
          isSelectedOnFocus={false}
        />
        <DocumentViewModal
          onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
          {...docViewState}
        />
        <Sticky stickyPosition={StickyPositionType.Footer}>
          {attachLoading && <ProgressIndicator />}
          <Stack horizontal verticalAlign="end" className={classNames.stack}>
            <Stack
              horizontal
              tokens={{ childrenGap: 20 }}
              horizontalAlign="end"
              grow={1}
              className={styles.footerStack}
            >
              <PrimaryButton
                onClick={_onAttach}
                text="Attach Documents"
                disabled={AttachDisable}
              />
              <DefaultButton
                onClick={() => {
                  onDismiss();
                }}
                text="Cancel"
              />
            </Stack>
          </Stack>
        </Sticky>
      </Stack>
    </Modal>
  );
};
