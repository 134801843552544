import React, { ReactNode } from 'react';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
} from '@fluentui/react';
import { DangerButton } from '../DangerButton';

interface DeleteModalProps {
  isDeletable: boolean;
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  children?: ReactNode;
  title: string;
  errorTitle?: string;
  type?: DialogType;
  subText?: string;
  disbleDeleteButton?: boolean;
  confirmText?: string;
}
export const DeleteModal: React.FC<DeleteModalProps> = ({
  isDeletable,
  isOpen,
  onDismiss,
  onConfirm,
  children,
  title = 'Title',
  errorTitle = 'Delete not possible',
  subText,
  type = DialogType.largeHeader,
  disbleDeleteButton,
  confirmText
}) => {
  return (
    <Dialog
      hidden={isOpen}
      onDismiss={onDismiss}
      dialogContentProps={{
        title: isDeletable ? title : errorTitle,
        type: type,
        subText: isDeletable ? subText : '',
      }}
    >
      {children}
      <DialogFooter>
        {isDeletable && (
          <DangerButton
            onClick={onConfirm}
            text={confirmText ? confirmText : 'Delete'}
            disabled={disbleDeleteButton}
          />
        )}
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
