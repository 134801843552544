import { ITextProps, Text } from '@fluentui/react';
import React from 'react';
import { getFormattedAmountValue } from './utils';

interface AmountTextViewProps extends ITextProps {
  value: string | null;
  allowRenderZero?: boolean;
  nowrap?: boolean;
}
export const AmountTextView: React.FC<AmountTextViewProps> = ({
  value,
  allowRenderZero,
  ...props
}) => {
  const formattedValue = getFormattedAmountValue(value);
  const textValue = allowRenderZero
    ? formattedValue === ''
      ? '0.00'
      : formattedValue
    : formattedValue;
  return <Text {...props}>{textValue}</Text>;
};
