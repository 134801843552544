import {
  DirectionalHint,
  Icon,
  Image,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { IconState } from 'common/types/globalTypes';
import { dateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { ReactComponent as ApprovedIcon } from '../../../assets/svgs/ApproveStatusIcons/Approved.svg';
import { ReactComponent as DeclinedIcon } from '../../../assets/svgs/ApproveStatusIcons/Declined.svg';
import { ReactComponent as PendingIcon } from '../../../assets/svgs/ApproveStatusIcons/Pending.svg';
import { userApproval_userApproval_approvalSignaturesByEntityApprovalId_nodes } from '../__generated__/userApproval';
import { useStyles } from './index.styles';

interface SignatureViewProps {
  signatureData: userApproval_userApproval_approvalSignaturesByEntityApprovalId_nodes[];
}
export const SignatureView: React.FC<SignatureViewProps> = ({
  signatureData,
}) => {
  const styles = useStyles();

  // const newSignatureData = signatureData.filter(
  //   (item) => item.actionDate !== null
  // );

  const IconValue = (status: string) => {
    switch (status) {
      case IconState.APPROVED:
        return <ApprovedIcon />;
      case IconState.REQUESTED:
        return (
          <Icon
            styles={{
              root: {
                fontSize: 20,
              },
            }}
            iconName={'EditContact'}
            className={styles.logo}
          />
        );
      case IconState.REJECTED:
        return <DeclinedIcon />;
      case IconState.PENDING:
        return <PendingIcon />;
      default:
        return null;
    }
  };

  return (
    <Stack tokens={{ childrenGap: 20, padding: '0px 0px 25px 0px' }}>
      {signatureData.map((item) => {
        return (
          <Stack
            key={item.id}
            horizontal
            tokens={{ childrenGap: 10 }}
            verticalAlign="center"
          >
            <Stack horizontalAlign="center" style={{ width: 40 }}>
              <TooltipHost
                content={item._iconHint!}
                directionalHint={DirectionalHint.leftCenter}
              >
                {IconValue(item._iconState!)}
              </TooltipHost>
            </Stack>
            <Stack className={styles.customWidth} tokens={{ childrenGap: 5 }}>
              <Stack horizontal horizontalAlign="space-between">
                <Stack>
                  <TooltipHost content={item.additionalInformation!}>
                    <Text variant="mediumPlus">{item.userName}</Text>
                    <Text variant="small" className={styles.gray}>
                      {` - ${item.userOccupationTitle}`}
                    </Text>
                  </TooltipHost>
                  <TooltipHost content="Digitally Signed">
                    <Text variant="xSmall">{item.digitalSignature}</Text>
                  </TooltipHost>
                </Stack>
                {item.actionDate && (
                  <Text variant="mediumPlus">
                    {dateFormat(item.actionDate)}
                  </Text>
                )}
              </Stack>
            </Stack>

            {item.userSignatureDocument && item.digitalSignature && (
              <Stack
                className={styles.paddingLeft20}
                horizontal
                verticalAlign="center"
              >
                <Image
                  style={{ objectFit: 'contain', backgroundColor: 'white' }}
                  className={styles.signatureImage}
                  src={item.userSignatureDocument?._downloadLink!}
                  width={120}
                  height={50}
                />
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
