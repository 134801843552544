import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  onrenderColumnStack: {
    height: 25,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  textAlignEnd: {
    textAlign: 'end',
  },
  columnHeight: {
    height: 25,
  },
  customScroll: {
    flex: 1,
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      width: 5,
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.neutralLight,
      borderRadius: '6px',
      // width: 5,
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
}));
