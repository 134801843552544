import {
  DefaultButton,
  IContextualMenuProps,
  PrimaryButton,
  ProgressIndicator,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';
import { useFormContext } from 'react-hook-form';
import { TravelerValues } from '../interfaces';

interface FooterProps {
  isNew: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showRequestApproval: boolean;
  showAmendApproval: boolean;
  onSaveAndClose: (param: boolean) => void;
  onSave: () => void;
  onCancel: () => void;
  onToggleConfirmDialog: () => void;
  onToggleAmendDialog: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  isNew,
  isLoading,
  isDisabled,
  showRequestApproval,
  showAmendApproval,
  onSaveAndClose,
  onSave,
  onCancel,
  onToggleConfirmDialog,
  onToggleAmendDialog,
}) => {
  const styles = useStyles();

  const {
    formState: { isDirty },
  } = useFormContext<TravelerValues>();

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'saveAndCreateNew',
        text: 'Save and Close',
        disabled: isDisabled,
        onClick: (event) => {
          if (event) event.stopPropagation();
          onSave();
          onSaveAndClose(true);
        },
      },
    ],
  };

  return (
    <>
      <Stack
        className={styles.footer}
        verticalAlign="center"
        horizontalAlign="space-between"
      >
        {isLoading && <ProgressIndicator />}
        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
          }}
          verticalAlign="center"
          horizontalAlign="space-between"
          className={styles.buttonContainer}
        >
          <Stack
            horizontal
            tokens={{
              childrenGap: 20,
            }}
          >
            {isNew ? (
              <DefaultButton
                text={'Save'}
                primary
                split
                menuProps={menuProps}
                onClick={onSave}
                disabled={isDisabled}
              />
            ) : (
              <PrimaryButton
                className={styles.disabledButton}
                text="Save"
                disabled={isDisabled}
                onClick={onSave}
              />
            )}
            <DefaultButton onClick={onCancel} text="Cancel" />
          </Stack>
          {showRequestApproval && (
            <PrimaryButton
              disabled={isDirty}
              className={styles.disabledButton}
              onClick={() => onToggleConfirmDialog()}
              text="Request Approval"
            />
          )}
          {showAmendApproval && (
            <PrimaryButton
              disabled={isDirty}
              className={styles.disabledButton}
              text="Amend Traveler"
              onClick={() => onToggleAmendDialog()}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
