import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    fullWidthContainer: { display: 'flex', flexWrap: 'wrap', width: 810 },
    containerPadding: { padding: '0px 20px 20px' },
    containerSetting: {
      paddingTop: 50,
    },
  };
});
