import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  Modal,
  ProgressIndicator,
  RatingSize,
  Stack,
  TextField,
} from '@fluentui/react';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  RatingSupplierCreate,
  RatingSupplierCreateVariables,
} from 'settings/account/supplier/view/ReviewsRatings/__generated__/RatingSupplierCreate';
import { ModalFooter } from './Footer';
import { ModalHeader } from './Header';
import { useStyles } from './index.styles';
import { StarRatings } from 'common/components/StarRatings';
const RATING_SUPPLIER_CREATE = loader(
  '../../../../../../../../../../settings/account/supplier/view/ReviewsRatings/RatingSupplierCreate.graphql'
);
const SUPPLIER_DETAILS = loader('../../../TASupplierDetails.graphql');
interface ReviewFormValues {
  ratingComment?: string | null;
  ratingValue: number;
}
interface ReviewFormProps {
  supplierId: string;
  refreshList: () => void;
}
export const ReviewForm: React.FC<ReviewFormProps> = ({
  supplierId,
  refreshList,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [reviewStarsRating, setReviewStarsRating] = useState<number>(1);
  const [reviewComment, setReviewComment] = useState<string>();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [addNewReview, { loading: loadingAddNewReview }] = useMutation<
    RatingSupplierCreate,
    RatingSupplierCreateVariables
  >(RATING_SUPPLIER_CREATE);
  const handleSubmitReview = async (values: ReviewFormValues) => {
    const { errors } = await addNewReview({
      variables: {
        input: {
          rating: {
            entityId: supplierId,
            ratingValue: values.ratingValue,
            ratingComment: values.ratingComment,
          },
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: SUPPLIER_DETAILS,
          variables: {
            id: supplierId,
          },
        },
      ],
    });
    setIsModalOpen(false);
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      setReviewComment('');
      setReviewStarsRating(1);
      setIsSubmitDisabled(true);
      addToast('Rating added successfully', {
        appearance: 'success',
      });
      refreshList();
    }
  };
  useEffect(() => {
    if (reviewComment) {
      setIsSubmitDisabled(false);
    } else setIsSubmitDisabled(true);
  }, [reviewComment]);

  return (
    <Stack className={styles.container}>
      <Stack horizontalAlign="end" className={styles.defaultButtonContainer}>
        <DefaultButton
          text="Add New Review"
          onClick={() => setIsModalOpen(true)}
        />
      </Stack>
      {
        <Modal isOpen={isModalOpen} isBlocking>
          <Stack className={styles.wrapper}>
            <ModalHeader onClose={() => setIsModalOpen(false)} />
            <Stack
              tokens={{ childrenGap: 10 }}
              className={styles.ratingsFormContainer}
            >
              <StarRatings
                min={1}
                max={5}
                size={RatingSize.Large}
                onChange={(e, rating) => {
                  if (rating) setReviewStarsRating(rating);
                }}
              />
              <TextField
                label="Write your review"
                multiline
                resizable={false}
                onChange={(_e, comment) => {
                  setReviewComment(comment);
                }}
              />
            </Stack>
            {loadingAddNewReview && <ProgressIndicator />}
            <ModalFooter
              disableSubmit={isSubmitDisabled}
              onCancelPress={() => setIsModalOpen(false)}
              onSubmitPress={() =>
                handleSubmitReview({
                  ratingComment: reviewComment,
                  ratingValue: reviewStarsRating,
                })
              }
            />
          </Stack>
        </Modal>
      }
    </Stack>
  );
};
