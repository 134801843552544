import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  stickyFooter: {
    bottom: 0,
    height: 80,
    paddingBottom: 20,
    paddingTop: 20,
    position: 'absolute',
    backgroundColor: theme.palette.white,
    zIndex: 20,
    width: '1250px',
  },
  container: {
    height: 'calc(100vh - 450px)',
  },

  amountTotal: {
    fontWeight: 'bold',
    color: theme.palette.themePrimary,
  },
  footerwrapper: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.neutralLighter,
    height: 50,
    marginLeft: -35,
  },
}));
