import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  amount: {
    textAlign: 'right',
    display: 'block',
  },
  amountTotal: {
    fontWeight: 'bold',
    color: theme.palette.themePrimary,
  },
  paddingLeft25: {
    paddingLeft: 10,
  },
  link: {
    width: '100%',
    fontSize: FontSizes.medium,
    color: theme.palette.black,
    ':focus': {
      color: theme.palette.black,
    },
  }
}));
