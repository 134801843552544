import {
  ContextualMenu,
  Stack,
  useTheme,
  Text,
  Separator,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { ModalWrapper } from 'common/components/ModalWrapper';
import {
  currencyFormatter,
  CustomizedAxisTick,
  useColors,
  useRightBarColor,
} from 'dashboard/Charts/common';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useStyles } from './index.styles';
export interface ChartProps {
  chartColumns: {
    columnCaption: string | number | boolean | null;
    columnAmount: string | number | boolean | null;
  }[];
  onDismiss: () => void;
  title: string;
  isOverBudget: boolean;
}

export const BudgetStatusChart: React.FC<ChartProps> = ({
  chartColumns,
  onDismiss,
  title,
  isOverBudget,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const colors = useColors();
  const rightBarColor = useRightBarColor();

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [animation, setAnimation] = useState(true);

  return (
    <ModalWrapper
      isOpen
      containerClassName={styles.modalContainer}
      isBlocking
      dragOptions={{
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      }}
    >
      <Stack
        verticalAlign="end"
        horizontal
        tokens={{ childrenGap: 10, padding: '20px 20px 0px 20px' }}
      >
        <Text variant="xLarge">{title}</Text>
        <Stack style={{ marginLeft: 'auto' }}>
          <CloseButton onClick={() => onDismiss()} />
        </Stack>
      </Stack>
      <Separator />
      <Stack style={{ alignItems: 'center', padding: 30 }}>
        <BarChart
          height={600}
          width={chartColumns.length > 5 ? chartColumns.length * 120 : 600}
          data={chartColumns}
          margin={{
            top: 20,
            right: 20,
            left: 10,
            bottom: 20,
          }}
          barGap={20}
          maxBarSize={80}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={theme.palette.neutralQuaternary}
          />
          <XAxis
            dataKey="columnCaption"
            interval={0}
            tick={<CustomizedAxisTick fill={theme.palette.neutralPrimary} />}
          />
          <YAxis
            dataKey="columnAmount"
            tick={{ fill: theme.palette.neutralPrimary }}
          />
          <Tooltip
            formatter={(value: number) => [currencyFormatter.format(value), '']}
            separator=""
            contentStyle={{
              backgroundColor: theme.palette.white,
              borderColor: theme.palette.neutralLighter,
            }}
            itemStyle={{
              color: theme.palette.neutralPrimary,
            }}
            cursor={{
              fill: theme.palette.neutralQuaternaryAlt,
              opacity: 0.4,
            }}
            isAnimationActive={false}
            labelStyle={{ fontWeight: 600 }}
          />
          <Bar
            dataKey="columnAmount"
            onMouseEnter={(_, index) => setActiveIndex(index)}
            onMouseLeave={(_, index) => {
              if (activeIndex === index) setActiveIndex(undefined);
            }}
            isAnimationActive={animation}
            onAnimationEnd={() => setAnimation(false)}
          >
            {chartColumns.map((_entry, index) => {
              if (index === chartColumns.length - 1) {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      rightBarColor[isOverBudget ? 1 : 0][
                        index === activeIndex ? 1 : 0
                      ]
                    }
                  />
                );
              }
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    colors[index % colors.length][index === activeIndex ? 1 : 0]
                  }
                />
              );
            })}
          </Bar>
        </BarChart>
      </Stack>
    </ModalWrapper>
  );
};
