import { DefaultButton } from '@fluentui/react';
import React from 'react';
import { buttonColors } from './index.styles';

interface DeclineButtonProps {
  isDeclined: boolean | null | undefined;
  onClick: () => void;
}

export const DeclineButton: React.FC<DeclineButtonProps> = ({
  isDeclined,
  onClick,
}) => {
  return (
    <div>
      {isDeclined ? (
        <DefaultButton
          onClick={onClick}
          iconProps={{ iconName: 'CheckMark' }}
          styles={{
            root: {
              backgroundColor: buttonColors.RED_700,
              color: '#ffffff',
              border: `2px solid ${buttonColors.RED_700}`,

              width: 100,
            },
            rootPressed: {
              backgroundColor: buttonColors.RED_900,
              color: '#ffffff',
            },
            rootHovered: {
              backgroundColor: buttonColors.RED_800,
              color: '#ffffff',
            },
          }}
        >
          Decline
        </DefaultButton>
      ) : (
        <DefaultButton
          toggle
          styles={{
            root: {
              border: `2px solid ${buttonColors.RED_700}`,
              color: buttonColors.RED_700,
              width: 100,
            },
            rootPressed: {
              border: `2px solid ${buttonColors.RED_900}`,
              color: buttonColors.RED_900,
            },
            rootHovered: {
              border: `2px solid ${buttonColors.RED_800}`,
              color: buttonColors.RED_800,
            },
          }}
          selected
          onClick={onClick}
        >
          Decline
        </DefaultButton>
      )}
    </div>
  );
};

interface ApproveButtonProps {
  isApproval: boolean | null | undefined;
  onClick: () => void;
}

export const ApproveButton: React.FC<ApproveButtonProps> = ({
  isApproval,
  onClick,
}) => {
  return (
    <div>
      {isApproval ? (
        <DefaultButton
          onClick={onClick}
          iconProps={{ iconName: 'CheckMark' }}
          styles={{
            root: {
              border: `2px solid ${buttonColors.GREEN_800}`,
              backgroundColor: buttonColors.GREEN_800,
              color: '#ffffff',
              width: 100,
            },
            rootPressed: {
              backgroundColor: buttonColors.GREEN_800,
              color: '#ffffff',
            },
            rootHovered: {
              backgroundColor: buttonColors.GREEN_800,
              color: '#ffffff',
            },
          }}
        >
          Approve
        </DefaultButton>
      ) : (
        <DefaultButton
          toggle
          styles={{
            root: {
              border: `2px solid ${buttonColors.GREEN_800}`,
              color: buttonColors.GREEN_800,
              width: 100,
            },
            rootPressed: {
              border: `2px solid ${buttonColors.GREEN_800}`,
              color: buttonColors.GREEN_800,
            },
            rootHovered: {
              border: `2px solid ${buttonColors.GREEN_800}`,
              color: buttonColors.GREEN_800,
            },
          }}
          selected
          onClick={onClick}
        >
          Approve
        </DefaultButton>
      )}
    </div>
  );
};
