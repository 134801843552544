import { FieldPolicy } from '@apollo/client';

interface ConnectionField {
  nodes: any[];
}

export const showMorePagination: FieldPolicy<ConnectionField> = {
  keyArgs: false,
  merge: (existing, incoming, context) => {
    return ({
      ...incoming,
      nodes:
        context.variables && context.variables.after
          ? [...(existing?.nodes || []), ...incoming.nodes]
          : incoming.nodes,
    })
  },
};

export const noPagination: FieldPolicy<ConnectionField> = {
  merge: (existing, incoming) => ({
    ...incoming,
    nodes: [...(existing?.nodes || []), ...incoming.nodes],
  }),
};
