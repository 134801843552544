import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  inputsContainer: {
    padding: `20px 20px 30px`
  },
  paddingRL10: {
    padding: `0px 10px`
  },
  flex33:{
    flex: 0.33
  },
  flex50:{
    flex: 0.5
  },
  flex70:{
    flex: 0.7
  },
  width50 : {
    width: "50%"
  }
}));
