import { Link, Stack, Text, TooltipHost } from '@fluentui/react';
import clsx from 'clsx';
import React from 'react';
import { FolderExpand } from '../../../FolderExpand';
import { nodeMapType } from '../../../utils';
import { RightClickMenu } from '../RightClickMenu';
import { useStyles } from './index.styles';
import { setActivePoolDocument } from 'utility/cache/ui';

interface GroupSubFolderProps {
  marginLeft?: number;
  documentPool: nodeMapType;
  selectedPool: nodeMapType;
  onHeaderClick: (documentPool: nodeMapType) => void;
  _headerOnClick: (
    poolId: string,
    documentPoolItem: nodeMapType,
    filterRouteState?: string | undefined
  ) => void;
  handleRightClickMenu: (value: 'CREATE' | 'UPDATE' | 'DELETE') => void;
}

export const GroupSubFolder: React.FC<GroupSubFolderProps> = ({
  marginLeft = 50,
  documentPool,
  onHeaderClick,
  _headerOnClick,
  selectedPool,
  handleRightClickMenu,
}) => {
  const styles = useStyles();
  const activeCount =
    documentPool?._subfolderDocumentFileCount?.availableDocuments;
  return (
    <Stack
      key={documentPool?.id}
      className={clsx(styles.root)}
      tokens={{ childrenGap: 20 }}
    >
      <RightClickMenu
        id={documentPool.id}
        selectedPool={documentPool}
        onClick={(value) => {
          handleRightClickMenu(value);
        }}
      >
        <Link
          className={styles.linkHover}
          style={{
            textDecoration: 'none',
          }}
          onClick={() => (documentPool ? onHeaderClick(documentPool) : null)}
        >
          <Stack
            verticalAlign="center"
            horizontal
            tokens={{ childrenGap: 20 }}
            style={{ marginLeft: marginLeft }}
          >
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              <FolderExpand isOpen={false} />
              <Text nowrap block className={styles.textStyle} variant="medium">
                {documentPool?.name}
              </Text>
              <Text variant="medium" className={styles.groupName}>
                {`(${activeCount})`}
              </Text>
            </Stack>
          </Stack>
        </Link>
      </RightClickMenu>
      {documentPool?.children && documentPool.children.length > 0 && (
        <Stack style={{ marginTop: 5 }}>
          {documentPool.children?.map((childNode, index) => {
            const historyCount =
              childNode?._subfolderDocumentFileCount?.selectedDocuments;
            return (
              <Stack
                style={{ marginTop: 5 }}
                className={clsx(
                  childNode.id === selectedPool.id && styles.groupSelected
                )}
              >
                <TooltipHost
                  content={
                    historyCount && historyCount > 0
                      ? `History Documents: ${historyCount?.toString()}`
                      : undefined
                  }
                  calloutProps={{ gapSpace: 4 }}
                >
                  <GroupSubFolder
                    key={childNode?.id}
                    selectedPool={selectedPool}
                    marginLeft={marginLeft + 50}
                    documentPool={childNode}
                    onHeaderClick={(documentPoolItem) => {
                      setActivePoolDocument(documentPoolItem);
                      _headerOnClick(documentPoolItem.id, documentPoolItem);
                    }}
                    _headerOnClick={_headerOnClick}
                    handleRightClickMenu={(value) =>
                      handleRightClickMenu(value)
                    }
                  />
                </TooltipHost>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
