import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  diabledButton: {
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  unstampButton: {
    backgroundColor: theme.palette.red,
  },
  container: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
  },
}));
