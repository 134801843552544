import { IRatingProps, Rating, RatingSize } from '@fluentui/react';
import React from 'react';
import { RatingStyles } from './ratings.styles';

export const StarRatings: React.FC<IRatingProps> = ({
  max = 5,
  min = 0,
  size = RatingSize.Large,
  ...props
}) => {
  return (
    <Rating min={min} max={max} size={size} styles={RatingStyles} {...props} />
  );
};
