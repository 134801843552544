import { RatingSize, Stack, Text } from '@fluentui/react';
import React from 'react';
import { StarRatings } from '../../../../../../common/components/StarRatings';
import { Supplier_supplier_supplierRatingResults } from '../../__generated__/Supplier';
import { useStyles } from './index.styles';
interface ReviewCompactProps {
  ratings: Supplier_supplier_supplierRatingResults | null;
  onToggleRatings: () => void;
}
export const ReviewCompact: React.FC<ReviewCompactProps> = ({
  ratings,
  onToggleRatings
}) => {
  const styles = useStyles();

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 20 }}
      className={styles.mainContainer}
      onClick={onToggleRatings}
    >
      <StarRatings
        max={5}
        rating={Number(ratings?.starValue || "0")}
        size={RatingSize.Large}
        readOnly
      />
      {
        <Stack
          tokens={{ childrenGap: 5 }}
          horizontal
          className={styles.reviewsLabel}
        >
          <Text className={styles.boldFont}>{ratings?.countTotal || 0}</Text>
          <Text>reviews</Text>
        </Stack>
      }
    </Stack>
  )
}
