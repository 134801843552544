import { Stack } from '@fluentui/react';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { BatchTransaction } from 'postingTracker/batchEdit/batch/view/__generated__/BatchTransaction';
import React from 'react';
import { useStyles } from './index.styles';
import { DataView } from 'common/components/DataView';

interface BasicFormProps {
  data: BatchTransaction | undefined;
}

export const BasicForm: React.FC<BasicFormProps> = ({ data }) => {
  const styles = useStyles();

  const invoiceDate = data?.batchTransaction?.postingDate
    ? dateFormat(dateConvertions(data?.batchTransaction?.postingDate))
    : '';
  const period = data?.batchTransaction?.companyCorporatePeriod
    ? dateFormat(
        dateConvertions(
          data?.batchTransaction?.companyCorporatePeriod.startDate
        )
      ) +
      ' - ' +
      dateFormat(
        dateConvertions(data?.batchTransaction?.companyCorporatePeriod.endDate)
      )
    : '-';

  return (
    <Stack tokens={{ childrenGap: 20, padding: 25 }}>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flexHalf}>
          <DataView
            title={'Batch ID'}
            description={data?.batchTransaction?.referenceNumber}
          />
        </Stack.Item>
        <Stack.Item className={styles.flexHalf}>
          <DataView
            title={'Description'}
            description={data?.batchTransaction?.description}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flexHalf}>
          <DataView title={'Invoice date'} description={invoiceDate} />
        </Stack.Item>
        <Stack.Item className={styles.flexHalf}>
          <DataView
            title={'Currency'}
            description={
              data?.batchTransaction?.currency?.isoCode
                ? data?.batchTransaction?.currency?.isoCode
                : '-'
            }
          />
        </Stack.Item>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack.Item className={styles.flexHalf}>
          <DataView title={'Period'} description={period} />
        </Stack.Item>
        <Stack.Item className={styles.flexHalf}>
          <DataView
            title={'Total'}
            isAmount
            description={
              data?.batchTransaction?.controlTotalAmount
                ? data?.batchTransaction?.controlTotalAmount
                : '-'
            }
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
