import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  diabledButton: {
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
  shimmerViewMainContainer: {
    marginTop: 30,
    marginBottom: 90,
  },
  approvalHistoryContainer: {
    margin: '20px 0',
    paddingLeft: 25,
    paddingRight: 25,
  },
  requestedByText: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
  tagsContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  textFieldStyle: {
    marginTop: 10,
  },
  approvalStatus: {
    color: theme.palette.themePrimary,
    fontSize: FontSizes.size18,
  },
  mainContainer: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
  },
  documentsContainer: {
    margin: '0px 0px',
  },
  marginB20: {
    marginBottom: 20,
  },
}));
