import { makeStyles, FontSizes } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  statusText: {
    color: theme.palette.accent,
  },
  detailsContainer: {
    marginBottom: 20,
    marginTop: 20,
  },
  invoiceDetailContainer: {
    flex: 2,
  },
  invoiceRequestDetailLabel: {
    fontWeight: 'bold',
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
  invoiceRequestDetailedLabel: {
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
  actionButtonContainer: { flex: 2 },
  fileIcon: {
    fontSize: FontSizes.size18,
    color: theme.palette.themePrimary,
    marginRight: 10,
  },
  columnIcon: {
    height: 65,
    width: 1,
    backgroundColor: '#a9a9a9',
  },
  viewInvoiceContainer: { flex: 1 },
}));
