import React, { ReactNode, useMemo, useState } from 'react';
import { PrimaryButton, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useStyles } from './index.styles';
import { ApprovalHierarchy } from '../Hierarchy';
import { CloseButton } from '../Buttons';
import { StamperView, StampOptions } from '../StamperView';
import { ModalWrapper } from '../ModalWrapper';

const HEADER_TITLE = 'Approval Hierarchy';
interface HierarchyModalProps {
  entityId: string;
  isApprovalEntry?: boolean;
  /**@param  customButton render custom button instead regular Primary Button*/
  customButton?: ReactNode;
}
export const HierarchyModal: React.FC<HierarchyModalProps> = ({
  entityId,
  isApprovalEntry,
  customButton,
}) => {
  const styles = useStyles();
  const [approvalType, setApprovalType] = useState<undefined | string>();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [stampData, setStampData] = React.useState<StampOptions>();

  const HierarchyComponent = () => {
    return (
      <Stack className={styles.hierarchyContainer}>
        <ApprovalHierarchy
          stampEnteredData={(data) => setStampData(data)}
          getApprovalType={(data) => setApprovalType(data)}
          entityId={entityId}
          isApprovalEntry={isApprovalEntry}
        />
      </Stack>
    );
  };

  const MemoizedHierarchyComponent = useMemo(HierarchyComponent, [entityId]);

  return (
    <>
      {customButton ? (
        <div onClick={() => openPanel()}>{customButton}</div>
      ) : (
        <PrimaryButton text="View Hierarchy" onClick={() => openPanel()} />
      )}
      <ModalWrapper
        isOpen={isOpen}
        scrollableContentClassName={styles.noScrollContainer}
        containerClassName={styles.modalContainer}
        onDismiss={() => dismissPanel()}
      >
        <Stack className={styles.container}>
          <Stack className={styles.headerContainer} tokens={{ padding: 25 }}>
            {approvalType && (
              <Stack
                horizontalAlign="space-between"
                horizontal
                className={styles.alignItemsCenter}
                tokens={{ childrenGap: 10 }}
              >
                <Stack
                  className={styles.alignItemsCenter}
                  horizontal
                  tokens={{ childrenGap: 10 }}
                >
                  <Text variant={'xLarge'}>{HEADER_TITLE}</Text>
                  <Text
                    variant={'xLarge'}
                    className={styles.approvalTypeTextStyles}
                  >
                    {approvalType}
                  </Text>
                </Stack>
                {(stampData &&
                  !stampData?._isAccountingEntryStampedComplete &&
                  stampData?._isTransactionCancelled) ||
                (stampData?._isAccountingEntryStampedComplete &&
                  !stampData?._isTransactionCancelled) ? (
                  <Stack
                    className={styles.stampCLoseContainer}
                    horizontal
                    horizontalAlign="space-between"
                  >
                    {stampData && (
                      <Stack className={styles.fixedWidth}>
                        <StamperView invoiceDetails={stampData} />
                      </Stack>
                    )}
                    <Stack>
                      <CloseButton onClick={() => dismissPanel()} />
                    </Stack>
                  </Stack>
                ) : (
                  <CloseButton onClick={() => dismissPanel()} />
                )}
              </Stack>
            )}
          </Stack>
          {MemoizedHierarchyComponent}
        </Stack>
      </ModalWrapper>
    </>
  );
};
