import { filterOptionProps } from "..";
export const toFilterVariable = (filterOptions: filterOptionProps) =>
  filterOptions.filterTypes.map((filter) => {
    switch (filter.filterKey) {
      case 'transactionTypeId':
      return {
        [filter.filterKey]: {
          equalTo: filter.value,
        },
      };
      case 'vendorReference':
      case 'description':
      case 'invoiceNumber':
        return {
          [filter.filterKey]: {
            [filterOptions.startsWith
              ? 'startsWithInsensitive'
              : 'includesInsensitive']: filter.value,
          },
        };
      default:
        return undefined;
    }
  });