import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 800,
    padding: 25,
  },
  formContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  resourceMainContainer: {
    width: 250,
  },
  resourceColumnContainer: {
    marginTop: 10,
  },
  resourceType: {
    textAlign: 'center',
    fontWeight: FontWeights.semibold,
  },
  resourceTypeDataMainContainer: {
    marginTop: 10,
  },
  marginTop5: {
    marginTop: '5px !important',
  },
  environmentalImpact: {
    height: 32,
    justifyContent: 'center',
  },
  resourceColumnMainContainer: {
    marginLeft: '15px !important',
    width: 400,
  },
  marginLeft10: {
    marginLeft: '10px !important',
  },
  resouce: {
    width: 150,
  },
  dateValidation: {
    color: 'red',
  },
  environmentalsExistContainer: {
    width: 20,
    height: 20,
    borderRadius: 50,
    backgroundColor: 'green',
  },
  environmentalCount: {
    color: theme.palette.white,
  },
  accountingType: {
    color: theme.palette.themePrimary,
  },
  textDecorationNone: {
    textDecoration: 'none',
  },
  svgImage: {
    width: 20,
    height: 20,
  },
  containerMargin:{
    marginRight: 15
  },
  header: {
    ...theme.fonts.large,
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    justifyContent:'space-between',
    padding:25,
    paddingBottom:0,
  },
}));
