import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 400,
    backgroundColor: theme.palette.white,
  },
  item: {
    cursor: 'pointer',
    padding: '10px 10px',
    ':hover': {
      backgroundColor: theme.palette.neutralLight,
    },
  },
  suffixIconStyles: {
    cursor: 'pointer',
  },
}));
