import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  link: {
    width: '100%',
    fontSize: theme.fonts.medium.fontSize,
    color: theme.palette.black,
    ':focus': {
      color: theme.palette.black,
    },
  },
  inactiveLink: {
    width: '100%',
    fontSize: theme.fonts.medium.fontSize,
    color: theme.palette.neutralTertiary,
  },
  alignRight: {
    width: '100%',
    alignItems: 'end',
  },
  overageAmount: {
    color: 'red',
  },
  accountReference: {
    width: '100%',
  },
}));
