import {
  DirectionalHint,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import { AppRouter } from 'common/types/globalTypes';
import { GetDocumentDetails_fileCabinetHistoryTransaction_nodes } from 'documents/fileCabinet/folder/view/__generated__/GetDocumentDetails';
import React, { useState } from 'react';
import { InvoiceModal } from '../InvoiceModal';
import { PaymentsModal } from '../PaymentsModal';
import { TravelPlanModal } from '../TravelPlanModal';
import { PurchaseOrderModal } from '../PurchaseOrderModal';
interface TransactionModalProps {
  data: GetDocumentDetails_fileCabinetHistoryTransaction_nodes | undefined;
  loading: boolean;
}
export const TransactionModal: React.FC<TransactionModalProps> = ({
  data,
  loading,
}) => {
  const commonStyles = useCommonStyles();
  const [showModal, setShowModal] = useState(false);
  const { entityId, linkType } = { ...data };

  const onRenderButton = () => {
    if (!!entityId && !!linkType) {
      switch (linkType) {
        case AppRouter.TRANSACTION_SIGNING:
          return true;
        case AppRouter.PAYMENT_TRACKING:
          return true;
        case AppRouter.TRAVEL_AUTHORIZATION:
          return true;
        case AppRouter.PURCHASE_ORDER:
          return true;
        default:
          return false;
      }
    }
  };

  const onRenderModal = () => {
    if (!!data) {
      switch (linkType) {
        case AppRouter.TRANSACTION_SIGNING:
          return (
            <InvoiceModal data={data} onDismiss={() => setShowModal(false)} />
          );
        case AppRouter.PAYMENT_TRACKING:
          return (
            <PaymentsModal data={data} onDismiss={() => setShowModal(false)} />
          );
        case AppRouter.TRAVEL_AUTHORIZATION:
          return (
            <TravelPlanModal
              data={data}
              onDismiss={() => setShowModal(false)}
            />
          );
        case AppRouter.PURCHASE_ORDER:
          return (
            <PurchaseOrderModal
              data={data}
              onDismiss={() => setShowModal(false)}
            />
          );
        default:
          return null;
      }
    }
  };

  if (!loading && onRenderButton())
    return (
      <Stack>
        <Stack horizontal verticalAlign="center">
          <TooltipHost
            content={'View Transaction'}
            directionalHint={DirectionalHint.leftCenter}
          >
            <IconButton
              iconProps={{ iconName: 'DocumentApproval' }}
              onClick={() => setShowModal(true)}
              text="History"
            />
          </TooltipHost>
          <Text className={commonStyles.colorThemePrimary}>History</Text>
        </Stack>
        {showModal && entityId && linkType && !loading && onRenderModal()}
      </Stack>
    );
  else return null;
};
