import { NetworkStatus, useQuery } from '@apollo/client';
import {
  ContextualMenu,
  DefaultButton,
  DetailsRow,
  DirectionalHint,
  IColumn,
  IDetailsListProps,
  IDetailsRowBaseProps,
  IDetailsRowStyles,
  Modal,
  PrimaryButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { CloseButton } from 'common/components/Buttons';
import { FilterArrayType } from 'common/components/Filters';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { InvoiceFilter } from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useMemo, useState } from 'react';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { InvoiceFilters } from './InvoiceFilters';
import { toFilterVariable } from './InvoiceFilters/utils';
import { toOrderByVariable } from './utils';
import {
  PaymentAvailableAccountingEntries,
  PaymentAvailableAccountingEntriesVariables,
  PaymentAvailableAccountingEntries_paymentAvailableAccountingEntries_nodes,
} from './__generated__/PaymentAvailableAccountingEntries';
const PAYMENT_AVAILABLE_ACCOUNTING_ENTRIES = loader(
  './PaymentAvailableAccountingEntries.graphql'
);

export type TransactionRowItem =
  PaymentAvailableAccountingEntries_paymentAvailableAccountingEntries_nodes & {
    type: string | null;
    name: string | null;
    description: string;
    invoiceNumber: string | null;
    invoiceDate: string | null;
    amount: string;
    _rowTimestamp: string | null;
    _isUpdatable: boolean | null;
    _isDeletable: boolean | null;
    invoicePaymentId: string | null;
    isDocumentSigningPayment?: boolean | null;
  };

interface AccountingInvoicesProps {
  payCycleId: string;
  isOpen: boolean;
  dismissModal: (param: boolean) => void;
  selectedTransactions: (transactions: TransactionRowItem[]) => void;
  prevSelectedDocuments?: TransactionRowItem[];
}

export type filterOptionProps = {
  filterTypes: FilterArrayType[];
  startsWith: boolean;
};

export const AccountingInvoices: React.FC<AccountingInvoicesProps> = ({
  payCycleId,
  isOpen,
  dismissModal,
  selectedTransactions,
  prevSelectedDocuments,
}) => {
  const styles = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [selected, setSelected] = useState<TransactionRowItem[]>([]);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);

  const {
    data: attachableInvoiceSigningDocumentsData,
    loading: attachableLoading,
    variables: invoiceSigningVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<
    PaymentAvailableAccountingEntries,
    PaymentAvailableAccountingEntriesVariables
  >(PAYMENT_AVAILABLE_ACCOUNTING_ENTRIES, {
    variables: { payCycleId, first: TABLE_ROWS },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { nodes, totalCount, aggregates, pageInfo } = {
    ...attachableInvoiceSigningDocumentsData?.paymentAvailableAccountingEntries,
  };

  const _renderItemColumn = (
    item: TransactionRowItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof TransactionRowItem
      ] as string;
      switch (column?.key) {
        case 'invoiceDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{item.invoiceDate && dateFormat(item.invoiceDate)}</Text>
            </Stack>
          );
        case 'amount':
          return (
            <AmountTextView
              className={clsx(styles.contentColumnAlignRight)}
              value={item.amount}
            />
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...invoiceSigningVariables,
          after: pageInfo?.endCursor,
        },
      }),
    [fetchMore, invoiceSigningVariables, pageInfo]
  );

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      if (column?.key === 'amount') {
        return (
          <AmountTextView
            variant="medium"
            className={clsx(styles.amountStack, styles.contentColumnAlignRight)}
            value={Number(aggregates?.sum?.controlTotalAmount).toFixed(2)}
          />
        );
      }
      return undefined;
    };

  const transformedData = useMemo(() => {
    const documents = nodes?.map(
      (invoice) =>
        ({
          ...invoice,
          type: invoice.transactionType?.transactionType,
          name: invoice.vendorReference,
          description: invoice.description,
          invoiceDate: invoice.invoiceDate
            ? dateFormat(dateConvertions(invoice.invoiceDate))
            : null,
          invoiceNumber: invoice.invoiceNumber,
          amount: invoice.controlTotalAmount,
          isDocumentSigningPayment:
            invoice.transactionType?.isDocumentSigningPayment,
        } as TransactionRowItem)
    );
    if (prevSelectedDocuments?.length && documents?.length) {
      const filteredArray = documents.filter(
        ({ id: id1 }) =>
          !prevSelectedDocuments.some(({ id: id2 }) => id2 === id1)
      );
      return filteredArray;
    }

    return documents;
  }, [nodes, prevSelectedDocuments]);

  const _onAttach = async () => {
    selectedTransactions(selected);
    setIsModalOpen(false);
    dismissModal(true);
  };

  const showFooter =
    totalCount! > 0 &&
    parseFloat(aggregates?.sum?.controlTotalAmount! || '0') > 0;

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const { item } = { ...props };
    const customStyles: Partial<IDetailsRowStyles> = {};
    const tootTipMessage = 'Approved Payment Request';
    const tooltipVisible = item.transactionType?.isDocumentSigningPayment;
    if (!props) {
      return null;
    }
    if (tooltipVisible) {
      customStyles.root = {
        backgroundColor: '#1dba6e1c',
      };
      return (
        <TooltipHost
          content={tootTipMessage}
          directionalHint={DirectionalHint.leftCenter}
        >
          <DetailsRow {...props} styles={customStyles} />
        </TooltipHost>
      );
    } else return <DetailsRow {...props} />;
  };

  const [filtersArray, setFiltersArray] = useState<filterOptionProps>({
    filterTypes: [],
    startsWith: true,
  });

  const onFiltersReload = useCallback(
    async (filter: InvoiceFilter | undefined) =>
      await refetch({ ...invoiceSigningVariables, filter }),
    [invoiceSigningVariables, refetch]
  );

  const onFilterChange = (filterOptions: filterOptionProps) => {
    setFiltersArray(filterOptions);
    const invoiceDocumentsFilterArray: InvoiceFilter | undefined = filterOptions
      .filterTypes?.length
      ? ({ and: toFilterVariable(filterOptions) } as InvoiceFilter)
      : undefined;
    onFiltersReload(invoiceDocumentsFilterArray);
  };

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({
        ...invoiceSigningVariables,
        after: null,
        orderBy: toOrderByVariable(sort),
      }),
    [invoiceSigningVariables, refetch]
  );

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    reload({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  return (
    <Stack>
      <Modal
        isOpen={isModalOpen}
        isBlocking
        onDismiss={() => {
          setIsModalOpen(false);
          dismissModal(true);
        }}
        dragOptions={{
          moveMenuItemText: 'Move',
          closeMenuItemText: 'Close',
          menu: ContextualMenu,
          dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
        }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          className={styles.stickyInsideStack}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 10 }}
            >
              <Text variant="xLarge">Apply Transactions</Text>
              <InvoiceFilters
                filterOptions={filtersArray}
                onFilterChange={onFilterChange}
              />
            </Stack>
            <Text>Link Transactions to Payments</Text>
          </Stack>
          <CloseButton
            onClick={() => {
              setIsModalOpen(false);
              dismissModal(true);
            }}
          />
        </Stack>

        <Stack className={styles.modalInsideStack}>
          <InfiniteList
            loading={attachableLoading}
            items={
              networkStatus === NetworkStatus.refetch ||
              networkStatus === NetworkStatus.setVariables
                ? undefined
                : transformedData
            }
            hasNextPage={pageInfo?.hasNextPage}
            showFooter={showFooter}
            onSelectionChanged={setSelected}
            columns={gridColumns}
            onColumnHeaderClick={(_, column) => {
              if (column) _onColumnClick(column);
            }}
            onLoadMore={loadMore}
            onRenderItemColumn={_renderItemColumn}
            onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
            onRenderRow={onRenderRow}
          />

          <Stack
            horizontal
            verticalAlign="end"
            className={styles.footerActionButtons}
          >
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <PrimaryButton text="Attach" onClick={() => _onAttach()} />
              <DefaultButton
                onClick={() => {
                  setIsModalOpen(false);
                  dismissModal(true);
                }}
                text="Cancel"
              />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
};
