import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  defaultButtonContainer: {
    marginBottom: 10,
  },
  checkBoxStyle: {
    marginTop: 20,
  },
  addressTypeLabel: {
    color: theme.palette.neutralDark,
    width: 150,
  },
  deleteIcon: {
    color: theme.palette.accent,
  },
  primaryLabel: {
    fontWeight: 'bold',
    color: theme.palette.accent,
  },
  paddingBottom: {
    paddingBottom: 70,
  },
  currencyText: {
    fontWeight: 'bold',
  },
  width250: {
    width: 250,
  },
  width200: {
    width: 200,
  },
}));
