import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  activeGroup: {
    position: 'relative',
    backgroundColor: theme.palette.neutralLight,
    '::before': {
      backgroundColor: theme.palette.neutralTertiary,
      content: '""',
      width: 3,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
    },
  },
  calloutRoot: {
    background: theme.isInverted
      ? theme.palette.neutralLighterAlt
      : theme.palette.white,
    padding: '8px 0',
  },
  calloutTitle: {
    padding: '4px 14px 6px',
  },
}));
