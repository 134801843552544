import { ActionButton, Stack, TooltipHost } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Supplier_supplier } from '../../../__generated__/Supplier';
import { SupplierAvailableDocumentTypes } from '../__generated__/SupplierAvailableDocumentTypes';
import { AttachFormModal } from './AttachFormModal';
export interface AttachFormProps {
  availableDocumentTypes: SupplierAvailableDocumentTypes | undefined;
  supplier: Supplier_supplier;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  supplier,
  availableDocumentTypes,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { dirty } = useFormikContext<any>();

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={dirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          supplier={supplier}
          availableDocumentTypes={availableDocumentTypes}
          setOpen={setDialogVisible}
        />
      )}
    </>
  );
};
