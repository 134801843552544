import { FilterArrayType } from 'common/components/Filters';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { UserApprovalsOrderBy } from '../../common/types/globalTypes';
import { ToNullable } from 'utility/types';
import { UserApprovalSearchFilterTotalsVariables } from './__generated__/UserApprovalSearchFilterTotals';

type OrderLookup = {
  [direction in OrderDirection]: UserApprovalsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'name',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.DESCRIPTION_1_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.DESCRIPTION_1_DESC],
    },
  ],
  [
    'number',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.DESCRIPTION_3_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.DESCRIPTION_3_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.DESCRIPTION_2_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.DESCRIPTION_2_DESC],
    },
  ],
  [
    'date',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.DATE_1_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.DATE_1_DESC],
    },
  ],
  [
    'departmentName',
    {
      [OrderDirection.ASC]: [
        UserApprovalsOrderBy.DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        UserApprovalsOrderBy.DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'businessUnitName',
    {
      [OrderDirection.ASC]: [
        UserApprovalsOrderBy.BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        UserApprovalsOrderBy.BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'periodYear',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy._PERIOD_YEAR_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy._PERIOD_YEAR_DESC],
    },
  ],
  [
    'amount',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.AMOUNT_1_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.AMOUNT_1_DESC],
    },
  ],
  [
    'currencyData',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.CURRENCY_ISO_CODE_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.CURRENCY_ISO_CODE_DESC],
    },
  ],
  [
    'approvalGroup',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.APPROVAL_GROUP_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.APPROVAL_GROUP_DESC],
    },
  ],
  [
    'role',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.APPROVAL_PERSONA_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.APPROVAL_PERSONA_DESC],
    },
  ],
  [
    'requesterName',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.REQUESTER_NAME_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.REQUESTER_NAME_DESC],
    },
  ],
  [
    'requesterDate',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.REQUESTED_DATE_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.REQUESTED_DATE_DESC],
    },
  ],
  [
    'approvers',
    {
      [OrderDirection.ASC]: [
        UserApprovalsOrderBy.APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_ASC,
      ],
      [OrderDirection.DESC]: [
        UserApprovalsOrderBy.APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_DESC,
      ],
    },
  ],
  [
    'approvedDate',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.APPROVED_DATE_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.APPROVED_DATE_DESC],
    },
  ],
  [
    'rejectors',
    {
      [OrderDirection.ASC]: [
        UserApprovalsOrderBy.APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_ASC,
      ],
      [OrderDirection.DESC]: [
        UserApprovalsOrderBy.APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_DESC,
      ],
    },
  ],
  [
    'rejectedDate',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.REJECTED_DATE_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.REJECTED_DATE_DESC],
    },
  ],
  [
    'amendedByName',
    {
      [OrderDirection.ASC]: [
        UserApprovalsOrderBy.APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        UserApprovalsOrderBy.APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC,
      ],
    },
  ],
  [
    'amendedDate',
    {
      [OrderDirection.ASC]: [
        UserApprovalsOrderBy.APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        UserApprovalsOrderBy.APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_DESC,
      ],
    },
  ],
  [
    'additionalApprovalInformation',
    {
      [OrderDirection.ASC]: [
        UserApprovalsOrderBy.ADDITIONAL_APPROVAL_INFORMATION_ASC,
      ],
      [OrderDirection.DESC]: [
        UserApprovalsOrderBy.ADDITIONAL_APPROVAL_INFORMATION_DESC,
      ],
    },
  ],
  [
    'phaseName',
    {
      [OrderDirection.ASC]: [
        UserApprovalsOrderBy.APPROVAL_PHASE_BY_ID__PHASE_NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        UserApprovalsOrderBy.APPROVAL_PHASE_BY_ID__PHASE_NAME_DESC,
      ],
    },
  ],
  [
    'urgencyLevel',
    {
      [OrderDirection.ASC]: [UserApprovalsOrderBy.URGENCY_LEVEL_ASC],
      [OrderDirection.DESC]: [UserApprovalsOrderBy.URGENCY_LEVEL_DESC],
    },
  ],
]);

export const toOrderByVariable = (
  sort?: SortOrder,
  historyFilter: boolean = false
) => {
  const orderByArray = historyFilter
    ? [
        UserApprovalsOrderBy.DESCRIPTION_1_ASC,
        UserApprovalsOrderBy.SORT_ORDER_GROUP_DESC,
        UserApprovalsOrderBy.PRIMARY_KEY_ASC,
      ]
    : [
        UserApprovalsOrderBy.URGENCY_LEVEL_ASC,
        UserApprovalsOrderBy.DESCRIPTION_1_ASC,
        UserApprovalsOrderBy.SORT_ORDER_GROUP_DESC,
        UserApprovalsOrderBy.PRIMARY_KEY_ASC,
      ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [
    ...(orderBy ? orderBy : []),
    UserApprovalsOrderBy.SORT_ORDER_GROUP_DESC,
    UserApprovalsOrderBy.PRIMARY_KEY_ASC,
  ];
};

export const toApprovalFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'businessUnitId':
      case 'departmentId':
      case 'corporatePeriodId':
      case 'iconTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };

      default:
        return undefined;
    }
  });

type searchTotalFilterType = ToNullable<
  Omit<
    UserApprovalSearchFilterTotalsVariables,
    | 'isSigningSearch'
    | 'isHistorySearch'
    | 'isLinkSearch'
    | 'isSearchAllData'
    | 'searchRequest'
    | 'isQueuedSearch'
  >
>;
export const getFilterParams = (searchFilters: FilterArrayType[]) => {
  const searchTotalFilterArray: searchTotalFilterType = {
    filterBusinessUnitId: undefined,
    filterDepartmentId: undefined,
    filterStatusTypeId: undefined,
  };
  searchFilters.forEach((curr) => {
    switch (curr.filterKey) {
      case 'businessUnitId':
        return (searchTotalFilterArray['filterBusinessUnitId'] =
          curr.value as GqlUUID | null);
      case 'departmentId':
        return (searchTotalFilterArray['filterDepartmentId'] =
          curr.value as GqlUUID | null);
      case '_statusTypeId':
        return (searchTotalFilterArray['filterStatusTypeId'] = curr.value as
          | number
          | null);

      default:
        return searchTotalFilterArray;
    }
  });
  return searchTotalFilterArray;
};
