import { useMutation } from '@apollo/client';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityDeleteInput } from 'common/types/globalTypes';
import { EntityAction, EntityType } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { CorporateWorkGroupsItem } from '..';
import { CorporateWorkgroupDelete, CorporateWorkgroupDeleteVariables } from '../../__generated__/CorporateWorkgroupDelete';
const CORPORATE_WORKGROUP_DELETE = loader('../../CorporateWorkgroupDelete.graphql')

interface DeleteWorkGroupProps {
  selectedRows: CorporateWorkGroupsItem[];
}

export const DeleteWorkGroup: React.FC<DeleteWorkGroupProps> = ({
  selectedRows,
}) => {
  const { addToast } = useToasts();

  const [deleteCorporateWorkgroup] = useMutation<
    CorporateWorkgroupDelete,
    CorporateWorkgroupDeleteVariables
  >(CORPORATE_WORKGROUP_DELETE, { errorPolicy: 'all' });

  const _onConfirm = async () => {
    const selectedData: CorporateWorkGroupsItem[] = selectedRows;
    const deletedRows: CorporateWorkGroupsItem[] = selectedData.filter(
      (item) => item._isDeletable
    );
    const entityDelete: EntityDeleteInput[] = selectedData
      .filter((item) => item._isDeletable)
      .map((item) => {
        return { id: item.id, rowTimestamp: item._rowTimestamp! };
      });
    const { errors } = await deleteCorporateWorkgroup({
      variables: {
        input: {
          entityDelete,
        },
      },
      update(cache) {
        deletedRows.forEach((item) => {
          const identity = cache.identify({
            ...item,
          });
          cache.evict({ id: identity });
          cache.gc();
        });
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted successfully.', {
        appearance: 'success',
      });
    }
  };

  return (
    <ActionMessageModal
      entityType={EntityType.WorkGroup}
      action={EntityAction.Remove}
      disabled={!selectedRows.some((selected) => selected._isDeletable)}
      visible={!!selectedRows.length}
      multiple={{
        validCount: selectedRows.filter(
          (selected) => selected._isDeletable
        ).length,
        invalidNames: selectedRows
          .filter((selected) => !selected._isDeletable)
          .map((selected) => selected.workgroupNumber || ''),
      }}
      onConfirm={_onConfirm}
    />
  )
}
