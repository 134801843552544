import { Colors } from 'common/types/globalTypes';
import { TagEntityValues } from './types';

export const TAG_INITIAL_VALUES: TagEntityValues = {
  _isActive: true,
  badgeColor: Colors.BLUE,
  badgeName: null,
  description: null,
  isMonitored: false,
  isPrivate: false,
  name: null,
  isAccessTag: null,
};
