import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    callout: {
      width: 350,
      padding: '20px 24px',
    },
    title: {
      marginBottom: 12,
    },

    buttonContainer: {
      height: 25,
      borderRadius: 3,
      border: `3px solid ${theme.palette.neutralQuaternaryAlt}`,
    },
    errorButtonContainer: {
      height: 25,
      borderRadius: 3,
      border: `1px solid ${theme.palette.red}`,
    },
    requireTick: {
      color: theme.palette.red,
      marginLeft: 1,
    },
  };
});
