import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import { FormHookTextField } from 'common/components/FormHooksFields';
import React from 'react';

export const FormView: React.FC<{ onSave: () => void }> = ({ onSave }) => {
  return (
    <Stack tokens={{ padding: 20, childrenGap: 10 }}>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        {/* <FormHookAmount label="Sequence" name="journeySequence" /> */}
        <FormHookTextField label="Section" name="phraseType" disabled />
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <FormHookTextField
          label="Title"
          placeholder="Title"
          name="headerTitle"
        />
        <FormHookTextField
          label="City/Areas"
          placeholder="City/Areas"
          name="headerCityFromTo"
        />
      </Stack>

      <FormHookTextField
        label="Detail Information"
        placeholder="Detail Information"
        name="bodyArea1"
        multiline
        resizable={false}
        rows={3}
      />

      <Stack
        horizontal
        tokens={{ childrenGap: 20, padding: '14px 0px 0px 0px' }}
      >
        <PrimaryButton text="Save" onClick={onSave} />
        <DefaultButton text="Cancel" />
      </Stack>
    </Stack>
  );
};
