import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  DetailsRow,
  DirectionalHint,
  IColumn,
  IDetailsListProps,
  IDetailsRowBaseProps,
  IDetailsRowStyles,
  Pivot,
  PivotItem,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  MultiSelectOption,
  MultiSelectTags,
} from 'common/components/MultiSelectTags';
import { ColumnData } from 'common/components/SearchBar';
import { StatusIcon } from 'common/components/StatusIcon';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  EntityDeleteInput,
  PaymentFilter,
  PaymentsOrderBy,
  SearchRequestInput,
} from 'common/types/globalTypes';
import { EntityType } from 'common/types/utility';
import {
  getColumn,
  getSortedColumns,
  getSubtractedValue,
} from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { StringParam } from 'serialize-query-params';
import { useQueryParams } from 'use-query-params';
import {
  globalMode,
  setCurrentProfile,
  setGlobalSearchText,
} from 'utility/cache/ui';
import { PayTrackngPivotTabs, paymentTrackingTabMode } from '..';
import {
  PaymentDelete,
  PaymentDeleteVariables,
} from '../__generated__/PaymentDelete';
import { PaidAmount } from './PaidAmount';
import { PaymentTrackingSearchBar } from './PaymentTrackingSearchBar';
import {
  PaymentSearch,
  PaymentSearchVariables,
  PaymentSearch_paymentSearch,
  PaymentSearch_paymentSearch_nodes,
} from './__generated__/PaymentSearch';
import {
  PaymentSearchFilterTotals,
  PaymentSearchFilterTotalsVariables,
  PaymentSearchFilterTotals_paymentSearchFilterTotals,
} from './__generated__/PaymentSearchFilterTotals';
import {
  PaymentSearchHints,
  PaymentSearchHintsVariables,
} from './__generated__/PaymentSearchHints';
import { useStyles } from './index.styles';
import {
  getFilterParams,
  toOrderByVariable,
  toPaymentFilterVariable,
} from './utils';
import { useColumns } from './column.data';
const DELETE_DOCUMENTS = loader('../PaymentDelete.graphql');
const PAYMENTS = loader('./PaymentSearch.graphql');
const PAYMENTS_FILTER_TOTAL = loader('./PaymentSearchFilterTotals.graphql');
const PAYMENT_SEARCH_HINTS = loader('./PaymentSearchHints.graphql');
type PaymentProps = PaymentSearch_paymentSearch_nodes;
export type PaymentsItem = PaymentSearch_paymentSearch_nodes & {
  payCycle: string;
  type: string;
  account: string;
  currency: string;
  businessUnit: string;
  key: string;
  status: string | null;
  production: string | null;
};

export const PaymentTracking = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const renderRef = useRef(false);
  const globalState = useReactiveVar(globalMode);
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { columns } = useColumns();
  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });
  const currentURL = useLocation();
  const isEditPanelActive = !!matchPath(
    currentURL.pathname,
    '/pay/payment_tracking/payment/:id'
  );
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>({
    column: 'paymentReference',
    direction: OrderDirection.ASC,
  });
  const { t: searchParam } = urlParameters;
  const [historyFilter, sethistoryFilter] = useState(false);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>([]);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [selectedList, setselectedList] = useState<PaymentProps[]>([]);
  const [hintsVisibility, setHintsVisibility] = useState<boolean>(false);
  const [searchTextValue, setSearchTextValue] = useState<string | null>(
    searchParam! || null
  );
  const [searchHints, setSearchHints] = useState<number | null>();
  const payTrackTabState = useReactiveVar(paymentTrackingTabMode);
  const currentProfileData = useReactiveVar(setCurrentProfile);
  const filterParams = getFilterParams(searchFilters || []);

  const {
    loading: paymentTrackingLoading,
    data: paymentTrackingListData,
    refetch,
    networkStatus,
    fetchMore,
  } = useQuery<PaymentSearch, PaymentSearchVariables>(PAYMENTS, {
    variables: {
      first: TABLE_ROWS,
      orderBy: [
        PaymentsOrderBy.PAYMENT_REFERENCE_ASC,
        PaymentsOrderBy.PRIMARY_KEY_ASC,
      ],
      isHistorySearch: false,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: paymentSearchTotal, refetch: refetchFilterTotal } = useQuery<
    PaymentSearchFilterTotals,
    PaymentSearchFilterTotalsVariables
  >(PAYMENTS_FILTER_TOTAL, {
    variables: {
      isHistorySearch: false,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [deleteDocuments] = useMutation<PaymentDelete, PaymentDeleteVariables>(
    DELETE_DOCUMENTS,
    { errorPolicy: 'all' }
  );
  const [fetchHints, { data: hintsData }] = useLazyQuery<
    PaymentSearchHints,
    PaymentSearchHintsVariables
  >(PAYMENT_SEARCH_HINTS, {
    variables: {
      paymentSearchRequest: {
        searchText: [searchTextValue ? searchTextValue : ''],
        searchHintId: searchHints ? searchHints : null,
      },
      isHistorySearch: false,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetching =
    paymentTrackingLoading && networkStatus !== NetworkStatus.fetchMore;

  // TRANSFORMING THE DATA FOR UI BINDING WITH LIST
  const transformedData = refetching
    ? undefined
    : paymentTrackingListData?.paymentSearch?.nodes.map(
        (payment) =>
          ({
            ...payment,
            key: payment.id,
            payCycle: payment._payCycleDescription,
            type: payment.transactionType?.transactionType,
            account:
              payment.paymentAccount?.name +
              ` ${
                payment.paymentAccount?.bankAccountNumber
                  ? payment.paymentAccount?.bankAccountNumber
                  : ''
              }`,
            businessUnit: payment.businessUnit?.name,
            vendorReference: payment.vendorReference,
            paidDate: payment.paidDate,
            paymentReference: payment.paymentReference,
            currency: payment.currency?.isoCode,
            paidAmount: payment.paidAmount,
            _baseCurrencyAmount: payment._baseCurrencyAmount,
            _spotCurrencyAmount: payment._spotCurrencyAmount,
            status: payment.statusType?.statusType,
            production: payment.company?.name,
          } as PaymentsItem)
      );

  const _renderItemColumn = (
    item: PaymentsItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    const fieldContent = item?.[
      column?.fieldName as keyof PaymentsItem
    ] as string;
    switch (column?.key) {
      case 'paymentReference':
        return (
          <Stack verticalAlign="center" className={styles.onrenderColumnStack}>
            <HighLightActiveLink
              key={item?.key}
              to={`/pay/payment_tracking/payment/${item?.key}`}
              highlightText={globalSearchText}
              text={item?.paymentReference!}
            />
          </Stack>
        );
      case 'currency':
        return (
          <Stack verticalAlign="center" className={styles.currencyCell}>
            <HighlightTextView
              highlightText={globalSearchText}
              text={fieldContent}
            />
          </Stack>
        );
      case '_baseCurrencyAmount':
      case '_spotCurrencyAmount':
        return (
          <AmountColumnTextView
            value={fieldContent}
            searchText={globalSearchText}
          />
        );
      case 'paidAmount':
        return (
          <Stack horizontal horizontalAlign="end">
            <PaidAmount globalSearchText={globalSearchText} item={item} />
          </Stack>
        );
      case 'paidDate':
        return (
          <Stack verticalAlign="center" className={styles.onrenderColumnStack}>
            <HighlightTextView
              className={styles.contentColumnAlignRight}
              highlightText={globalSearchText}
              text={
                fieldContent ? dateFormat(dateConvertions(fieldContent)) : ''
              }
            />
          </Stack>
        );
      case 'status':
        return (
          <Stack
            verticalAlign="center"
            className={styles.onrenderColumnStack}
            horizontal
            horizontalAlign="space-between"
          >
            <HighlightTextView
              highlightText={globalSearchText}
              text={convertToTitleCase(item?.status!)!}
            />
            {item?.statusType && (
              <StatusIcon
                approval={false}
                iconType={item?.statusType}
                approvalData={item}
              />
            )}
          </Stack>
        );
      default:
        return (
          <Stack verticalAlign="center" className={styles.onrenderColumnStack}>
            <Text>
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Text>
          </Stack>
        );
    }
  };

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      const { totalAmount1, totalAmount2, totalAmount3 } = {
        ...paymentSearchTotal?.paymentSearchFilterTotals,
      };
      const fieldContent =
        column?.key === 'paidAmount'
          ? totalAmount1
          : column?.key === '_baseCurrencyAmount'
          ? totalAmount2
          : column?.key === '_spotCurrencyAmount'
          ? totalAmount3
          : '';

      return (
        <AmountTextView
          variant="medium"
          className={clsx(styles.amountStack, styles.contentColumnAlignCenter)}
          value={fieldContent!}
        />
      );
    };

  const searchHintsOptions: MultiSelectOption[] =
    hintsData?.paymentSearchHints?.nodes.map(
      (hint) =>
        ({
          key: hint.id?.toString(),
          text: `${hint.caption} (${hint.resultsCount})`,
        } as MultiSelectOption)
    ) || [];

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
    let direction = 'asc';
    if (clickedColumn.isSortedDescending === true) {
      direction = 'asc';
      sortColumns(clickedColumn.key, direction);
    } else {
      direction = 'desc';
      sortColumns(clickedColumn.key, direction);
    }
  };

  const sortColumns = (columnName: string, direction: string) => {
    let orderBy = {
      column: columnName,
      direction: direction as OrderDirection,
    };
    handleSortAndPagination(orderBy);
  };

  const handleSortAndPagination = (
    sortOrder?: SortOrder,
    showMore?: boolean
  ) => {
    let filter = searchFilters?.length
      ? ({ and: toPaymentFilterVariable(searchFilters) } as PaymentFilter)
      : undefined;
    const searchRequest = searchTextValue
      ? {
          searchText: [searchTextValue],
          searchHintId: searchHints,
        }
      : undefined;

    const variables: PaymentSearchVariables = {
      first: TABLE_ROWS,
      orderBy: toOrderByVariable(sortOrder),
      filter: filter,
      after: showMore
        ? paymentTrackingListData?.paymentSearch?.pageInfo?.endCursor
        : null,
      paymentSearchSearchRequest: searchRequest,
      isHistorySearch: historyFilter,
      isLinkSearch: globalState,
    };
    const queryTotalFilterVariables: PaymentSearchFilterTotalsVariables = {
      searchRequest: searchRequest,
      isHistorySearch: historyFilter,
      isLinkSearch: globalState,
      ...filterParams,
    };
    if (showMore) fetchMore?.({ variables });
    else {
      refetch(variables);
      refetchFilterTotal(queryTotalFilterVariables);
    }
  };

  const handleSearch = (showMore: boolean, newHints: boolean) =>
    handleSearchGlobal(showMore, newHints, globalState);

  // Handle Search query with globalSearch param
  const handleSearchGlobal = (
    showMore: boolean,
    newHints: boolean,
    globalValue: boolean
  ) => {
    let filter = searchFilters?.length
      ? ({ and: toPaymentFilterVariable(searchFilters) } as PaymentFilter)
      : undefined;

    const searchRequest = searchTextValue
      ? {
          searchText: [searchTextValue ? searchTextValue : ''],
          searchHintId: searchHints,
        }
      : undefined;
    const searchQueryParam =
      searchTextValue && searchTextValue.length > 0
        ? searchTextValue
        : undefined;
    const variables: PaymentSearchVariables = {
      first: TABLE_ROWS,
      after: showMore
        ? paymentTrackingListData?.paymentSearch?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
      filter: filter,
      paymentSearchSearchRequest: searchRequest,
      isHistorySearch: historyFilter,
      isLinkSearch: globalValue,
    };
    const queryTotalFilterVariables: PaymentSearchFilterTotalsVariables = {
      searchRequest: searchRequest,
      isHistorySearch: historyFilter,
      isLinkSearch: globalValue,
      ...filterParams,
    };
    const searchHintsRequest: SearchRequestInput = {
      searchText: [searchTextValue ? searchTextValue : ''],
    };
    const variableHints: PaymentSearchHintsVariables = {
      paymentSearchRequest: searchHintsRequest,
      isHistorySearch: historyFilter,
      isLinkSearch: globalValue,
    };
    setUrlParameters({ t: searchQueryParam }, 'replace');

    if (showMore) fetchMore?.({ variables });
    else {
      refetch(variables);
      refetchFilterTotal(queryTotalFilterVariables);
    }
    if (newHints && searchRequest && !searchHints) {
      setSearchHints(null);
      fetchHints({
        variables: variableHints,
      });
    }
  };

  const getNewColumns = () => {
    if (sortOrderParam?.column !== '' && sortOrderParam) {
      const columnData = getColumn(sortOrderParam?.column!, gridColumns);
      const sortedColumns = getSortedColumns(columnData, gridColumns);
      return sortedColumns;
    } else return undefined;
  };
  const getNewColumnsMemo = useCallback(getNewColumns, []);

  useEffect(() => {
    const sortedColumns = getNewColumnsMemo();
    if (sortedColumns && renderRef.current)
      setGridColumns(sortedColumns.newColumns);
  }, [getNewColumnsMemo]);

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchTextValue,
    historyFilter,
    searchHints,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, true);
  }, [searchFilters, historyFilter, sortOrderParam, handleSearchMemo]);

  useEffect(() => {
    handleSearchMemo(false, true);
    renderRef.current = true;
  }, [searchTextValue, handleSearchMemo]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, false);
  }, [searchHints, searchTextValue, handleSearchMemo]);

  const HINTS_AVAILABLE =
    searchHintsOptions.length > 0 && searchTextValue?.length! > 0;

  const showFooter =
    !refetching &&
    paymentSearchTotal?.paymentSearchFilterTotals?.totalCount1! > 0 &&
    parseFloat(paymentSearchTotal?.paymentSearchFilterTotals?.totalAmount1!) >
      0;
  const _onConfirm = async () => {
    const selectedData: PaymentProps[] = selectedList;
    const toDelete: PaymentProps[] = selectedData.map((_, index) => {
      return selectedData![index];
    });
    let deletableDocs = toDelete?.filter((item) => item._isDeletable);
    if (deletableDocs.length > 0) {
      const deleteInputArray: EntityDeleteInput[] = deletableDocs.map(
        (item) => {
          return { id: item.id, rowTimestamp: item._rowTimestamp! };
        }
      );
      const { errors } = await deleteDocuments({
        variables: {
          input: {
            entityDelete: deleteInputArray,
          },
        },

        update: (cache, { data }) => {
          const deletedIds = data?.paymentDelete?.deletedEntities?.map(
            (entity) => entity?.id
          );
          if (deletedIds) {
            const filteredList =
              paymentTrackingListData?.paymentSearch?.nodes.filter(
                (pay) => deletedIds.indexOf(pay.id) === -1
              );
            const deletedfilteredList =
              paymentTrackingListData?.paymentSearch?.nodes.filter(
                (pay) => deletedIds.indexOf(pay.id) !== -1
              );
            try {
              cache.modify({
                fields: {
                  paymentSearchFilterTotals(
                    existing: PaymentSearchFilterTotals_paymentSearchFilterTotals
                  ) {
                    if (existing?.totalCount1 !== 0) {
                      const paidAmount = deletedfilteredList?.map(
                        (item) => item.paidAmount
                      );
                      const baseCurrencyAmount = deletedfilteredList?.map(
                        (item) => item._baseCurrencyAmount
                      );
                      const spotCurrencyAmount = deletedfilteredList?.map(
                        (item) => item._spotCurrencyAmount
                      );
                      const newSum = getSubtractedValue(
                        existing?.totalAmount1,
                        paidAmount
                      );
                      const baseCurrencyAmountSum = getSubtractedValue(
                        existing?.totalAmount2,
                        baseCurrencyAmount
                      );
                      const spotCurrencyAmountSum = getSubtractedValue(
                        existing?.totalAmount3,
                        spotCurrencyAmount
                      );
                      return {
                        ...existing,
                        totalAmount1: newSum.toString(),
                        totalAmount2: baseCurrencyAmountSum.toString(),
                        totalAmount3: spotCurrencyAmountSum.toString(),
                        totalCount1:
                          (paymentSearchTotal?.paymentSearchFilterTotals
                            ?.totalCount1 || 0) - 1,
                      };
                    } else {
                      return {
                        ...existing,
                      };
                    }
                  },
                  paymentSearch(existing: PaymentSearch_paymentSearch) {
                    return {
                      ...existing,
                      pageInfo:
                        paymentTrackingListData?.paymentSearch?.pageInfo!,
                      nodes: filteredList!,
                    };
                  },
                },
              });
            } catch (error) {}
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        setselectedList([]);
        addToast('Record deleted Successfully', {
          appearance: 'success',
        });
      }
    }
  };

  const setBaseAmountHeading = () => {
    if (currentProfileData) {
      const updatedColumns: ColumnData[] = gridColumns.map((col) => {
        switch (col.fieldName) {
          case '_baseCurrencyAmount':
            return {
              ...col,
              name: `${currentProfileData.currentUserProfile?.company?.currency?.isoCode} Amount`,
            };
          case '_spotCurrencyAmount':
            return {
              ...col,
              name: `${currentProfileData.currentUserProfile?.company?.currency?.isoCode} Spot Rate`,
            };
          default:
            return { ...col };
        }
      });
      setGridColumns(updatedColumns);
    }
  };

  const setBaseAmountHeadingMemo = useCallback(setBaseAmountHeading, [
    currentProfileData,
  ]);

  useEffect(() => {
    setBaseAmountHeadingMemo();
  }, [setBaseAmountHeadingMemo]);

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const { item } = { ...props };
    const customStyles: Partial<IDetailsRowStyles> = {};
    const tootTipMessage = 'Approved Payment Request';
    const tooltipVisible = item._isTransactionPayment;
    if (!props) {
      return null;
    }
    if (tooltipVisible) {
      customStyles.root = {
        backgroundColor: '#1dba6e1c',
      };
      return (
        <TooltipHost
          content={tootTipMessage}
          directionalHint={DirectionalHint.leftCenter}
        >
          <DetailsRow {...props} styles={customStyles} />
        </TooltipHost>
      );
    } else return <DetailsRow {...props} />;
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Payments</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <ActionMessageModal
              entityType={EntityType.Payment}
              disabled={!selectedList.some((selected) => selected._isDeletable)}
              visible={selectedList.length > 0}
              multiple={{
                validCount: selectedList.filter(
                  (selected) => selected._isDeletable
                ).length,
                invalidNames: selectedList
                  .filter((selected) => !selected._isDeletable)
                  .map((cannotDelete) => cannotDelete.paymentReference),
              }}
              onConfirm={_onConfirm}
            />
            <PrimaryButton
              onClick={() => {
                history.push(`/pay/payment_tracking/payment`);
              }}
              iconProps={{
                iconName: 'Add',
              }}
              text="New Payment"
            />
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal verticalAlign="end">
            <Pivot
              selectedKey={payTrackTabState}
              onLinkClick={(item: PivotItem | undefined) => {
                setGlobalSearchText('');
                if (!isEditPanelActive) {
                  paymentTrackingTabMode(
                    item?.props.itemKey || PayTrackngPivotTabs.paymentTracking
                  );
                }
              }}
            >
              <PivotItem
                itemKey={PayTrackngPivotTabs.paymentTracking}
                headerText="Payments"
              />
              <PivotItem
                itemKey={PayTrackngPivotTabs.stampedTransaction}
                headerText="Available Transactions"
              />
            </Pivot>
          </Stack>
          <PaymentTrackingSearchBar
            urlParams={{
              searchParam: searchParam!,
            }}
            hintsAvailable={HINTS_AVAILABLE}
            columns={gridColumns}
            onToggleVisibility={(columns) => {
              setGridColumns(columns);
            }}
            onHistoryToogle={(value) => {
              globalMode(false);
              sethistoryFilter(value);
            }}
            onRefresh={() => {
              handleSearch(false, false);
            }}
            onFilterChange={(filters) => {
              setSearchFilters(filters);
            }}
            onEnterPress={(value, globalSearch) => {
              setSearchHints(null);
              setHintsVisibility(false);
              setSearchTextValue(value);
              setGlobalSearchText(value);
            }}
            onHintsViewToggle={() =>
              setHintsVisibility((prevState) => !prevState)
            }
            onGlobalToggle={(value) => handleSearchGlobal(false, true, value)}
          />
          {hintsVisibility && HINTS_AVAILABLE && (
            <MultiSelectTags
              multiSelect={false}
              options={searchHintsOptions}
              onSelectionChange={(selctedTags) => {
                const hints = selctedTags.map((item) => parseInt(item.key));
                setSearchHints(hints[0]);
              }}
            />
          )}
        </Stack>
      </Stack>

      <InfiniteList
        items={transformedData || []}
        loading={paymentTrackingLoading}
        hasNextPage={
          paymentTrackingListData?.paymentSearch?.pageInfo.hasNextPage
        }
        totalRowsCount={
          paymentSearchTotal?.paymentSearchFilterTotals?.totalCount1!
        }
        showFooter={showFooter}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onLoadMore={() => handleSearch(true, false)}
        onSelectionChanged={setselectedList}
        onRenderRow={onRenderRow}
      />
    </>
  );
};
