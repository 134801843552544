import React from 'react';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import {
  CreateMessageData,
  CreateNewMessage,
} from 'common/components/CreateNewMessage';
import {
  NewMessageApprovalCreate,
  NewMessageApprovalCreateVariables,
} from './__generated__/NewMessageApprovalCreate';
import { PivotKeys } from 'common/components/MessagesSections';

const ADD_NEW_MESSAGE = loader('./NewMessageApprovalCreate.graphql');
const GET_EMPLOYEE_USER_MESSAGE = loader('../GetApprovalMessage.graphql');
interface CreateNewMessageViewProps {
  selectedKey: string;
  invoiceID: string;
  onCreateMessage: () => void;
}

export const NewMessage: React.FC<CreateNewMessageViewProps> = ({
  invoiceID,
  selectedKey,
  onCreateMessage,
}) => {
  const { addToast } = useToasts();
  const [createMessage, { loading }] = useMutation<
    NewMessageApprovalCreate,
    NewMessageApprovalCreateVariables
  >(ADD_NEW_MESSAGE);

  const onSubmit = async (formData: CreateMessageData) => {
    const { messageComment, reminderDates, isResponseRequired, receivers } = {
      ...formData,
    };
    const messageFilter =
      selectedKey === PivotKeys.sent
        ? { _isOutgoingMessage: { equalTo: true } }
        : { _isIncomingMessage: { equalTo: true } };
    const { communicationChannel, department, user } = { ...receivers };
    const { errors } = await createMessage({
      variables: {
        input: {
          entityId: invoiceID,
          message: { messageComment, reminderDates, isResponseRequired },
          communicationChannel,
          department,
          user,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_EMPLOYEE_USER_MESSAGE,
          variables: {
            id: invoiceID,
            first: 10,
            filter: messageFilter,
          },
        },
      ],
    });

    if (!errors) {
      onCreateMessage();
      addToast('Message sent successfully.', {
        appearance: 'success',
      });
    } else
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
  };

  return <CreateNewMessage onSubmit={onSubmit} loading={loading} />;
};
