import { IconButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { OccupationRowItem } from '../list';
import { OccupationViewModal } from './OccupationViewModal';

export interface OccupationProps {
  occupation: OccupationRowItem;
}
type OccupationViewProps = Partial<OccupationProps>;
export const OccupationView: React.FC<OccupationViewProps> = ({
  occupation,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { _isUpdatable } = { ...occupation };

  return (
    <Stack horizontal verticalAlign="center">
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        onClick={() => setIsOpen(true)}
        disabled={!_isUpdatable}
      />
      {isOpen && (
        <OccupationViewModal
          occupation={occupation}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Stack>
  );
};
