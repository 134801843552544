import {
  EntityDeleteInput,
  TravelerDietaryRequirementInput,
  TravelerDietaryRequirementUpdateTypeInput,
  TravelerPolicyAllowanceInput,
  TravelerPolicyAllowanceUpdateTypeInput,
} from 'common/types/globalTypes';
import {
  TravelerDetails,
  TravelerDetails_traveler_travelerDietaryRequirementsByTravelerId_nodes,
} from './__generated__/TravelerDetails';
import {
  DIETARY_REQUIREMENTS_INITIAL_VALUES,
  TRAVELER_INITIAL_VALUES,
} from './constants';
import {
  DietaryRequirementsRowValues,
  TravelerPolicyAllowanceRow,
  TravelerValues,
} from './interfaces';
import { differenceBy, intersection, isEmpty } from 'lodash';

interface DefaultValueProps {
  isNew: boolean;
  travelerDetails: TravelerDetails | undefined;
  totalDietaryRequirements: number | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, travelerDetails, totalDietaryRequirements } = { ...props };
  let defaultValues: TravelerValues = { ...TRAVELER_INITIAL_VALUES };
  if (travelerDetails?.traveler && !isNew) {
    const newSupplierValues = Object.entries(travelerDetails.traveler).reduce(
      (prev, [key, value]) => {
        if (
          key === 'companyOrLastName' ||
          key === 'firstName' ||
          key === 'travelerDepartmentId' ||
          key === 'userOccupationTitleId' ||
          key === 'travelPolicyId' ||
          key === 'frequentFlyerNumber' ||
          key === 'travelerCompanionMasterId' ||
          key === 'travelerCompanionTickets' ||
          key === 'travelerCompanionAmount' ||
          key === 'isEconomyClassPreferred' ||
          key === 'isBusinessClassPreferred' ||
          key === 'isFirstClassPreferred' ||
          key === 'isCharterClassPreferred' ||
          key === 'isPremiumClassPreferred'
        ) {
          return { ...prev, [key]: value };
        } else if (
          (key === 'isIsleSeatPreferred' && value) ||
          (key === 'isWindowSeatPreferred' && value)
        ) {
          return { ...prev, [key]: value, seatPreference: key };
        } else return prev;
      },
      {
        travelerAddressesByTravelerId:
          travelerDetails.traveler.travelerAddressesByTravelerId.nodes.map(
            (ele) => {
              const { stateRegion, country, ...addressField } = { ...ele };
              return addressField;
            }
          ),
        travelerPolicyAllowancesByTravelerId:
          travelerDetails.traveler?._travelPolicyAllowances?.nodes.map(
            (ele) => {
              return {
                id: ele.id,
                expenditureType: ele.expenditureType,
                allowanceOverrideAmount: ele.allowanceOverrideAmount,
                _allowanceDescription: ele._allowanceDescription,
                isAllowanceCustomizable: ele.isAllowanceCustomizable,
                _travelerPolicyAllowanceId: ele._travelerPolicyAllowanceId,
                _travelerPolicyAllowanceRowTimestamp:
                  ele._travelerPolicyAllowanceRowTimestamp,
                isChecked: ele._travelerPolicyAllowanceId ? true : false,
              };
            }
          ) || [],
        travelerDietaryRequirementsByTravelerId: getDietaryRequirementsValue({
          dietaryRequirementsRows:
            travelerDetails?.traveler?.travelerDietaryRequirementsByTravelerId
              .nodes,
          totalDietaryRequirements: totalDietaryRequirements,
        }),
      }
    );
    return newSupplierValues as TravelerValues;
  }
  return defaultValues as TravelerValues;
};

interface DietaryRequirementsData {
  dietaryRequirementsRows:
    | TravelerDetails_traveler_travelerDietaryRequirementsByTravelerId_nodes[]
    | undefined;
  totalDietaryRequirements: number | undefined;
}

const getDietaryRequirementsValue = (data: DietaryRequirementsData) => {
  const { dietaryRequirementsRows, totalDietaryRequirements } = data;
  const rowsArray = dietaryRequirementsRows?.map(
    (item) =>
      ({
        id: item.id,
        _rowTimestamp: item._rowTimestamp,
        _isDeletable: item._isDeletable,
        description: item.description,
        dietaryRequirementId: item.dietaryRequirement?.id,
        isDescriptionRequired: item.dietaryRequirement?.isDescriptionRequired,
      } as DietaryRequirementsRowValues)
  );
  if (totalDietaryRequirements && rowsArray) {
    if (totalDietaryRequirements > rowsArray.length) {
      rowsArray?.push(DIETARY_REQUIREMENTS_INITIAL_VALUES);
      return rowsArray;
    } else {
      return rowsArray;
    }
  } else return [{ ...DIETARY_REQUIREMENTS_INITIAL_VALUES }];
};

export const getNewDietaryRequirementsValue = (
  rows: DietaryRequirementsRowValues[] | null
) => {
  const newDietaryRequirements =
    rows &&
    rows
      ?.filter((item) => item.dietaryRequirementId !== null && item.id === null)
      ?.map(
        ({
          id,
          _rowTimestamp,
          _isDeletable,
          isDescriptionRequired,
          ...requirement
        }) => requirement as TravelerDietaryRequirementInput
      );
  return newDietaryRequirements;
};

export const getUpdatedDietaryRequirementsValue = (
  defaultValues: DietaryRequirementsRowValues[] | null,
  updatedValues: DietaryRequirementsRowValues[] | null
) => {
  const updatedDietaryRequirements =
    defaultValues &&
    updatedValues &&
    intersection(
      defaultValues.map((requirement) => requirement.id),
      updatedValues
        .filter((requirement) => requirement.id)
        .map((requirement) => requirement.id)
    ).reduce((arr, targetId) => {
      const initialDietaryRequirements = defaultValues!.find(
        (requirement) => requirement.id === targetId
      )!;
      const {
        id,
        _rowTimestamp,
        _isDeletable,
        isDescriptionRequired,
        ...updatedRequirements
      } = updatedValues!.find((requirement) => requirement.id === targetId)!;

      const patch = Object.entries(updatedRequirements).reduce(
        (res, [key, val]) => {
          if (
            val !==
            initialDietaryRequirements[
              key as keyof DietaryRequirementsRowValues
            ]
          )
            return { ...res, [key]: val };
          return res;
        },
        {}
      );
      if (!isEmpty(patch))
        return [
          ...arr,
          {
            id,
            rowTimestamp: _rowTimestamp,
            travelerDietaryRequirementPatch: patch,
          },
        ];
      return arr;
    }, [] as TravelerDietaryRequirementUpdateTypeInput[]);
  return updatedDietaryRequirements;
};

export const getDeletedDietaryRequirementsValue = (
  defaultValues: DietaryRequirementsRowValues[] | null,
  updatedValues: DietaryRequirementsRowValues[] | null
) => {
  const deletedRows =
    defaultValues &&
    (differenceBy(defaultValues, updatedValues || [], 'id').map((addr) => ({
      id: addr.id!,
      rowTimestamp: addr._rowTimestamp!,
    })) as EntityDeleteInput[]);
  return deletedRows;
};

const getNewTravelerPolicyAllowances = (
  rows: TravelerPolicyAllowanceRow[] | null
) => {
  const newTravelerPolicyAllowances =
    !!rows?.length &&
    rows
      .filter(
        (allowance) =>
          !allowance._travelerPolicyAllowanceId && allowance.isChecked
      )
      .map(
        (ele) =>
          ({
            travelPolicyAllowanceId: ele.id,
            allowanceOverrideAmount: ele.allowanceOverrideAmount,
          } as TravelerPolicyAllowanceInput)
      );
  return newTravelerPolicyAllowances;
};

const getUpdatedTravelerPolicyAllowances = (
  defaultValues: TravelerPolicyAllowanceRow[] | null,
  updatedValues: TravelerPolicyAllowanceRow[] | null
) => {
  const updatedTravelerPolicyAllowances =
    defaultValues &&
    updatedValues &&
    intersection(
      defaultValues.map(
        (requirement) => requirement._travelerPolicyAllowanceId
      ),
      updatedValues
        .filter(
          (requirement) =>
            requirement._travelerPolicyAllowanceId && requirement.isChecked
        )
        .map((requirement) => requirement._travelerPolicyAllowanceId)
    ).reduce((arr, targetId) => {
      const initialDietaryRequirements = defaultValues!.find(
        (requirement) => requirement._travelerPolicyAllowanceId === targetId
      )!;
      const {
        id,
        _travelerPolicyAllowanceRowTimestamp,
        expenditureType,
        _travelerPolicyAllowanceId,
        isChecked,
        ...updatedRequirements
      } = updatedValues!.find(
        (requirement) => requirement._travelerPolicyAllowanceId === targetId
      )!;
      const patch = Object.entries(updatedRequirements).reduce(
        (res, [key, val]) => {
          if (
            val !==
            initialDietaryRequirements[key as keyof TravelerPolicyAllowanceRow]
          ) {
            return { ...res, [key]: val };
          }
          return res;
        },
        {}
      );
      if (!isEmpty(patch)) {
        const patchValues = {
          ...patch,
          travelPolicyAllowanceId: id,
        };
        return [
          ...arr,
          {
            id: _travelerPolicyAllowanceId,
            rowTimestamp: _travelerPolicyAllowanceRowTimestamp,
            travelerPolicyAllowancePatch: patchValues,
          },
        ];
      }
      return arr;
    }, [] as TravelerPolicyAllowanceUpdateTypeInput[]);
  return updatedTravelerPolicyAllowances;
};

const getDeletedTravelerPolicyAllowances = (
  defaultValues: TravelerPolicyAllowanceRow[] | null,
  updatedValues: TravelerPolicyAllowanceRow[] | null
) => {
  const deletedRows =
    !!updatedValues?.length &&
    (updatedValues
      .filter(
        (ele) =>
          ele.isChecked === false && ele._travelerPolicyAllowanceId !== null
      )
      .map((item) => ({
        id: item._travelerPolicyAllowanceId!,
        rowTimestamp: item._travelerPolicyAllowanceRowTimestamp!,
      })) as EntityDeleteInput[]);
  return deletedRows;
};

export {
  getNewTravelerPolicyAllowances,
  getUpdatedTravelerPolicyAllowances,
  getDeletedTravelerPolicyAllowances,
};
