import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: 80,
    marginBottom: 100,
  },
  fieldContainer: {
    flex: 1,
  },
  flex50: {
    flex: '0.5',
  },
  flex30: {
    flex: '0.3',
  },
  flex70: {
    flex: '0.7',
  },
  subTitle: {
    marginTop: 10,
  },
}));
