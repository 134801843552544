import { useMutation, useReactiveVar } from '@apollo/client';
import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentStyles,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { ProcessRequestInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorizationItem, setTAListViewOptions } from '..';
import {
  TravelAuthorizationPoGeneration,
  TravelAuthorizationPoGenerationVariables,
} from './__generated__/TravelAuthorizationPoGeneration';
import { TravelAuthorizationSearchVariables } from '../__generated__/TravelAuthorizationSearch';
const TRAVEL_AUTHORIZATION_PO_GENERATION = loader(
  './TravelAuthorizationPoGeneration.graphql'
);
const TRAVEL_AUTHORIZATION_SEARCH = loader(
  '../TravelAuthorizationSearch.graphql'
);
function getDialogStyles(): Partial<IDialogContentStyles> {
  return { title: { fontSize: 18 } };
}

interface PurchaseOrderGenerationProps {
  selectedRows: TravelAuthorizationItem[];
  variables: TravelAuthorizationSearchVariables | undefined;
}

export const PurchaseOrderGeneration: React.FC<
  PurchaseOrderGenerationProps
> = ({ selectedRows, variables }) => {
  const TAListViewOptions = useReactiveVar(setTAListViewOptions);
  const { addToast } = useToasts();
  const visible = !!selectedRows.length;
  const [consolidated, setConsolidated] = useState<boolean | null>(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [generatePurchaseOrders, { loading, data: generatedResults }] =
    useMutation<
      TravelAuthorizationPoGeneration,
      TravelAuthorizationPoGenerationVariables
    >(TRAVEL_AUTHORIZATION_PO_GENERATION, { errorPolicy: 'all' });

  const onConfirm = async () => {
    const processRequests: (ProcessRequestInput | null)[] | null =
      selectedRows.map(
        (item) =>
          ({
            rowTimestamp: item._rowTimestamp,
            id: item.id,
          } as ProcessRequestInput)
      );

    const { errors } = await generatePurchaseOrders({
      variables: {
        input: { processRequests, isPoConsolodated: consolidated },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: TRAVEL_AUTHORIZATION_SEARCH,
          variables: variables,
        },
      ],
    });
    setDialogVisible(false);
    if (!errors) {
      setResultsVisible(true);
      addToast('Purchase orders results generated', { appearance: 'info' });
    } else {
      addToast(errors[0]?.message, { appearance: 'error' });
    }
  };

  if (TAListViewOptions.isHistory || TAListViewOptions.isSigning === false)
    return (
      <Stack horizontal>
        <Stack>
          {visible && (
            <DefaultButton
              text="Generate Purchase orders"
              onClick={() => setDialogVisible(true)}
            />
          )}
        </Stack>

        {dialogVisible && (
          <Dialog
            hidden={false}
            onDismiss={() => setDialogVisible(false)}
            dialogContentProps={{
              title: `Choose optional consolidation and press confirm to generate Purchase Orders`,
              type: DialogType.largeHeader,
              styles: getDialogStyles,
            }}
          >
            <Stack horizontal tokens={{ childrenGap: 10, padding: '20px 0px' }}>
              <Checkbox
                onChange={(_, checked) => {
                  setConsolidated(checked!);
                }}
              />
              <Text>{`Consolidate (by Supplier) for the selected TA's into single purchase order(s)`}</Text>
            </Stack>
            {loading && (
              <ProgressIndicator label="Generating Purchase Orders" />
            )}
            <DialogFooter>
              <PrimaryButton
                onClick={() => {
                  onConfirm();
                }}
                text={'Confirm'}
              />
              <DefaultButton
                onClick={() => setDialogVisible(false)}
                text="Cancel"
              />
            </DialogFooter>
          </Dialog>
        )}

        {resultsVisible && (
          <Modal isOpen>
            <Stack
              tokens={{ padding: 20, childrenGap: 10 }}
              style={{ width: 400 }}
            >
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
              >
                <Text variant="large">PO Generation results</Text>
                <CloseButton
                  onClick={() => {
                    setResultsVisible(false);
                  }}
                />
              </Stack>
              {generatedResults?.travelAuthorizationPoGeneration?.processRequests?.map(
                (item, index) => {
                  return (
                    <Stack key={index}>
                      <MessageBar
                        messageBarType={
                          item?.isFail
                            ? MessageBarType.error
                            : item?.isWarning
                            ? MessageBarType.warning
                            : MessageBarType.success
                        }
                      >
                        {item?.message}
                      </MessageBar>
                    </Stack>
                  );
                }
              )}
            </Stack>
          </Modal>
        )}
      </Stack>
    );
  else return null;
};
