import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useReactiveVar,
} from '@apollo/client';
import {
  ContextualMenu,
  DatePicker,
  DayOfWeek,
  IDragOptions,
  IModalStyles,
  IconButton,
  MessageBar,
  MessageBarType,
  Modal,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { CloseButton } from 'common/components/Buttons';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import {
  SupplierApprovalCreate,
  SupplierApprovalCreateVariables,
} from 'common/graphql/__generated__/SupplierApprovalCreate';
import {
  SupplierApprovalRevoke,
  SupplierApprovalRevokeVariables,
} from 'common/graphql/__generated__/SupplierApprovalRevoke';
import {
  SupplierCompetitiveBidUpdate,
  SupplierCompetitiveBidUpdateVariables,
} from 'common/graphql/__generated__/SupplierCompetitiveBidUpdate';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  SupplierAddressInput,
  SupplierAddressUpdateTypeInput,
  SupplierApprovalRevokeInput,
  SupplierInput,
  SupplierPatch,
  UploadStatusType,
} from 'common/types/globalTypes';
import { EntityType } from 'common/types/utility';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
  SupplierDelete,
  SupplierDeleteVariables,
} from 'settings/account/supplier/__generated__/SupplierDelete';
import {
  SupplierCreate,
  SupplierCreateVariables,
  SupplierCreate_supplierCreate_supplier,
} from 'settings/account/supplier/view/__generated__/SupplierCreate';
import {
  SupplierUpdate,
  SupplierUpdateVariables,
} from 'settings/account/supplier/view/__generated__/SupplierUpdate';
import {
  SupplierDetails,
  SupplierDetailsVariables,
} from '../__generated__/SupplierDetails';
import { ActionsMenu } from './ActionMenu';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { PreviewHierarchyModal } from './PreviewHierarchyModal';
import { ReviewsRatings } from './ReviewsRatings';
import { ShimmerView } from './Shimmer/ShimmerViews';
import { SUPPLIER_INITIAL_VALUES } from './constants';
import { useStyles } from './index.styles';
import { AddressRowValues, SupplierValues } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validation';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import { setUserDefaults } from 'utility/cache/ui';

const SUPPLIER_DETAILS = loader('../SupplierDetails.graphql');
const SUPPLIER_CREATE = loader(
  '../../../../settings/account/supplier/view/SupplierCreate.graphql'
);
const SUPPLIER_UPDATE = loader(
  '../../../../settings/account/supplier/view/SupplierUpdate.graphql'
);
const SUPPLIER_DELETE = loader(
  '../../../../settings/account/supplier/SupplierDelete.graphql'
);
const SUPPLIER_APPROVAL_CREATE = loader(
  '../../../../common/graphql/SupplierApprovalCreate.graphql'
);
const SUPPLIER_APPROVAL_REVOKE = loader(
  '../../../../common/graphql/SupplierApprovalRevoke.graphql'
);
const PURCHASE_ORDER = loader('../../PurchaseOrder.graphql');
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const SUPPLIER_COMPETITIVE_BID_UPDATE = loader(
  '../../../../common/graphql/SupplierCompetitiveBidUpdate.graphql'
);

interface SupplierModalProps {
  setOpen: (open: boolean) => void;
  selectedSupplierId: string | undefined;
  isNew: boolean;
  purchaseOrderId: string | undefined;
  onSupplierCreate: (param: SupplierCreate_supplierCreate_supplier) => void;
  onSupplierDelete: () => void;
}

const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this supplier for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your supplier will be requested for approval.';
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the Supplier from the approval cycle and require re-approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

const modalStyles: Partial<IModalStyles> = {
  scrollableContent: {
    overflowY: 'visible',
  },
};

export const SupplierModal: React.FC<SupplierModalProps> = ({
  setOpen,
  selectedSupplierId,
  isNew,
  purchaseOrderId,
  onSupplierCreate,
  onSupplierDelete,
}) => {
  const { addToast, updateToast } = useToasts();
  const styles = useStyles();
  const userDefaults = useReactiveVar(setUserDefaults);
  const commonStyles = useCommonStyles();
  const fetched = useRef<boolean>(false);
  const client = useApolloClient();
  const saveAnotherPO = useRef<boolean>(false);
  const [requestComment, setRequestComment] = useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [hideConfirmRequestDialog, { toggle: toggleConfirmDialog }] =
    useBoolean(true);
  const [hideConfirmAmendDialog, { toggle: toggleConfirmAmendDialog }] =
    useBoolean(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showHierarchyModal, setShowHierarchyModal] = useState(false);

  useEffect(() => {
    setIsEdit(!isNew);
  }, [isNew]);

  const [
    supplierCompetitiveBidUpdate,
    { loading: supplierCompetitiveBidUpdateLoading },
  ] = useMutation<
    SupplierCompetitiveBidUpdate,
    SupplierCompetitiveBidUpdateVariables
  >(SUPPLIER_COMPETITIVE_BID_UPDATE, { errorPolicy: 'all' });

  const [
    fetchSupplierDetails,
    { data: supplierDetails, loading: supplierDetailsLoading, refetch },
  ] = useLazyQuery<SupplierDetails, SupplierDetailsVariables>(
    SUPPLIER_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  useEffect(() => {
    if (selectedSupplierId) {
      fetchSupplierDetails({
        variables: {
          id: selectedSupplierId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplierId]);

  const [supplierApprovalCreate, { loading: supplierApprovalLoading }] =
    useMutation<SupplierApprovalCreate, SupplierApprovalCreateVariables>(
      SUPPLIER_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );

  const [supplierApprovalRevoke, { loading: supplierApprovalRevokeLoading }] =
    useMutation<SupplierApprovalRevoke, SupplierApprovalRevokeVariables>(
      SUPPLIER_APPROVAL_REVOKE,
      { errorPolicy: 'all' }
    );

  const [createSupplier, { loading: addSupplierLoading }] = useMutation<
    SupplierCreate,
    SupplierCreateVariables
  >(SUPPLIER_CREATE, { errorPolicy: 'all' });

  const [updateSupplier, { loading: updateSupplierLoading }] = useMutation<
    SupplierUpdate,
    SupplierUpdateVariables
  >(SUPPLIER_UPDATE, { errorPolicy: 'all' });

  const [deleteSupplier, { loading: deleteSupplierLoading }] = useMutation<
    SupplierDelete,
    SupplierDeleteVariables
  >(SUPPLIER_DELETE, {
    errorPolicy: 'all',
  });

  const formMethods = useForm<SupplierValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const {
    handleSubmit,
    trigger,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = { ...formMethods };

  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack]);

  const defaultValues = getDefaultValues({ isNew: !isEdit, supplierDetails });
  const { _isUpdatable } = { ...supplierDetails?.supplier };
  const onHandleSubmit = async (values: SupplierValues) => {
    const { supplierAddressesBySupplierId, ...supplierFields } = values;
    if (!isEdit) {
      const { data, errors } = await createSupplier({
        variables: {
          input: {
            isPurchaseOrderMutation: true,
            supplier: supplierFields as SupplierInput,
            supplierAddresses: supplierAddressesBySupplierId?.map(
              ({ id, _rowTimestamp, ...addr }) => addr as SupplierAddressInput
            ),
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Record added successfully', {
          appearance: 'success',
        });
        if (saveAnotherPO.current) {
          reset(SUPPLIER_INITIAL_VALUES);
          setOpen(false);
        } else {
          setIsEdit(true);
          fetchSupplierDetails({
            variables: {
              id: data?.supplierCreate?.supplier?.id!,
            },
          });
        }
        onSupplierCreate(data?.supplierCreate?.supplier!);
      }
    } else {
      if (_isUpdatable) {
        const supplierPatch: SupplierPatch = Object.entries(
          supplierFields
        ).reduce((res, [key, val]) => {
          if (val !== defaultValues[key as keyof SupplierValues]) {
            return { ...res, [key]: val };
          }
          return res;
        }, {});

        const { id, _rowTimestamp, ...updatedAddress } =
          supplierAddressesBySupplierId![0];

        const updatedFields =
          supplierAddressesBySupplierId &&
          supplierAddressesBySupplierId?.length > 0 &&
          Object.entries(updatedAddress).reduce((res, [key, val]) => {
            if (
              val !==
              defaultValues?.supplierAddressesBySupplierId![0][
                key as keyof AddressRowValues
              ]
            )
              return { ...res, [key]: val };
            return res;
          }, {});
        const updatedAddresses = {
          id,
          rowTimestamp: _rowTimestamp,
          supplierAddressPatch: updatedFields,
        } as SupplierAddressUpdateTypeInput;

        const { data, errors } = await updateSupplier({
          variables: {
            input: {
              isPurchaseOrderMutation: true,
              id: supplierDetails?.supplier?.id!,
              rowTimestamp: supplierDetails?.supplier?._rowTimestamp!,
              supplierPatch: !isEmpty(supplierPatch)
                ? supplierPatch
                : undefined,
              supplierAddressesUpdate:
                [updatedAddresses] && [updatedAddresses].length > 0
                  ? [updatedAddresses]
                  : undefined,
            },
          },
          awaitRefetchQueries: !!purchaseOrderId,
          refetchQueries: purchaseOrderId
            ? [
                {
                  query: PURCHASE_ORDER,
                  variables: {
                    id: purchaseOrderId!,
                  },
                },
              ]
            : [],
        });
        if (errors?.length) {
          addToast(errors[0].message, {
            appearance: 'error',
          });
        } else {
          onSupplierCreate(data?.supplierUpdate?.supplier!);
          addToast('Supplier edited successfully', {
            appearance: 'success',
          });
        }
      } else {
        const { errors } = await supplierCompetitiveBidUpdate({
          variables: {
            input: {
              id: supplierDetails?.supplier?.id!,
              rowTimestamp: supplierDetails?.supplier?._rowTimestamp!,
              isCompetitiveBidsRequired: !!values.isCompetitiveBidsRequired,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: (mutationResult) => {
            if (mutationResult.data) {
              if (purchaseOrderId) {
                return [
                  {
                    query: SUPPLIER_DETAILS,
                    variables: {
                      id: supplierDetails?.supplier?.id!,
                    },
                  },
                  {
                    query: PURCHASE_ORDER,
                    variables: {
                      id: purchaseOrderId,
                    },
                  },
                ];
              } else {
                return [
                  {
                    query: SUPPLIER_DETAILS,
                    variables: {
                      id: supplierDetails?.supplier?.id!,
                    },
                  },
                ];
              }
            }
            return [];
          },
        });
        if (errors?.length) {
          addToast(errors[0].message, {
            appearance: 'error',
          });
        } else {
          addToast('Supplier edited successfully', {
            appearance: 'success',
          });
        }
      }
    }
  };

  const isLoading: boolean =
    addSupplierLoading ||
    updateSupplierLoading ||
    supplierApprovalLoading ||
    supplierApprovalRevokeLoading ||
    deleteSupplierLoading ||
    supplierCompetitiveBidUpdateLoading;

  useEffect(() => {
    if (supplierDetails && !fetched.current) {
      const defaultValues = getDefaultValues({
        isNew: !isEdit,
        supplierDetails,
      });
      reset(defaultValues);
      fetched.current = true;
    } else {
      const defaultValues = getDefaultValues({
        isNew: !isEdit,
        supplierDetails,
      });
      reset(defaultValues);
    }
  }, [supplierDetails, isNew, reset, isEdit]);

  const _isStagedApprovalRequest =
    !!supplierDetails?.supplier?._isStagedApprovalRequest;
  const _isApprovalRevocable =
    !!supplierDetails?.supplier?._isApprovalRevocable;
  const isUpdatable = isEdit ? supplierDetails?.supplier?._isUpdatable : true;

  const deleteRecord = async () => {
    const { errors } = await deleteSupplier({
      variables: {
        input: {
          isPurchaseOrderMutation: true,
          entityDelete: [
            {
              id: supplierDetails?.supplier?.id!,
              rowTimestamp: supplierDetails?.supplier?._rowTimestamp!,
            },
          ],
        },
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      onSupplierDelete();
      addToast('Record deleted successfully', {
        appearance: 'success',
      });
      setOpen(false);
    }
  };

  const heading: string = !isEdit ? 'Create Supplier' : 'Edit Supplier';
  const showRequestApproval = isEdit && _isStagedApprovalRequest;
  const showAmendApproval = isEdit && _isApprovalRevocable;
  const supplierStatus =
    isEdit && supplierDetails
      ? `(${supplierDetails?.supplier?.statusType?.statusType})`
      : '(Draft)';

  return (
    <Modal
      isOpen
      isBlocking
      onDismiss={() => setOpen(false)}
      dragOptions={DragOptions}
      styles={modalStyles}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        className={styles.stickyInsideStack}
      >
        <Stack
          horizontal
          tokens={{
            childrenGap: 10,
          }}
        >
          <Text variant="xLarge">{heading}</Text>
          <UnsavedIndicator
            visible={
              isEdit && !supplierDetailsLoading && isDirty && !isSubmitting
            }
          />
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: 10,
          }}
          verticalAlign="center"
        >
          {supplierDetails?.supplier?.id && _isApprovalRevocable && (
            <>
              <TooltipHost content={'Preview Approval Hierarchy'}>
                <IconButton
                  iconProps={{ iconName: 'Org' }}
                  ariaLabel="Org"
                  onClick={() => setShowHierarchyModal(true)}
                />
              </TooltipHost>
              {showHierarchyModal && (
                <PreviewHierarchyModal
                  entityId={supplierDetails?.supplier?.id}
                  onDismiss={() => setShowHierarchyModal(false)}
                />
              )}
            </>
          )}
          <Text variant="mediumPlus" className={commonStyles.colorThemePrimary}>
            {supplierStatus}
          </Text>
          <ActionMessageModal
            visible={isEdit}
            entityType={EntityType.Supplier}
            disabled={!supplierDetails?.supplier?._isDeletable}
            onConfirm={deleteRecord}
          />
          <CloseButton onClick={() => setOpen(false)} />
        </Stack>
      </Stack>
      <Separator />
      <ConfirmDialog
        hidden={hideConfirmRequestDialog}
        title={CONFIRM_REQUEST_DIALOG_TITLE}
        subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
        onDismiss={toggleConfirmDialog}
        minWidth={500}
        onConfirm={async () => {
          toggleConfirmDialog();
          const inputVariables: ApprovalRequestInput = {
            entityId: supplierDetails?.supplier?.id!,
            rowTimestamp: supplierDetails?.supplier?._rowTimestamp!,
            comments: requestComment,
          };
          if (requiredDate) inputVariables.requiredDate = requiredDate;
          const { errors } = await supplierApprovalCreate({
            variables: {
              input: {
                entityApproval: [inputVariables],
                urgencyLevel: urgencyLevel,
              },
            },
            awaitRefetchQueries: true,
            refetchQueries: (mutationResult) => {
              if (mutationResult.data) {
                if (purchaseOrderId) {
                  return [
                    {
                      query: SUPPLIER_DETAILS,
                      variables: {
                        id: supplierDetails?.supplier?.id!,
                      },
                    },
                    {
                      query: PURCHASE_ORDER,
                      variables: {
                        id: purchaseOrderId,
                      },
                    },
                  ];
                } else {
                  return [
                    {
                      query: SUPPLIER_DETAILS,
                      variables: {
                        id: supplierDetails?.supplier?.id!,
                      },
                    },
                  ];
                }
              }
              return [];
            },
          });
          if (errors?.length)
            addToast(errors[0].message, {
              appearance: 'error',
            });
          else {
            setRequestComment('');
            setRequiredDate('');
            addToast('Request sent for approval', {
              appearance: 'success',
            });
          }
        }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <TextField
            className={styles.requestComment}
            multiline
            rows={3}
            value={requestComment}
            placeholder="Please write your comment here (optional)"
            resizable={false}
            onChange={(_event, value) => setRequestComment(value || '')}
          />
          <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
            <DatePicker
              minDate={new Date()}
              firstDayOfWeek={DayOfWeek.Sunday}
              placeholder="Finalized by date (optional)"
              ariaLabel="Date"
              formatDate={onFormatDate}
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday={false}
              onSelectDate={(date) =>
                setRequiredDate(dateFormat(date!.toString()))
              }
            />
          </TooltipHost>
          <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
            {userDefaults?._isUserUrgencyUpdateAllowed && (
              <UrgencyToggle
                onToggle={(data) => {
                  setUrgencyLevel(data ? 0 : 1);
                }}
              />
            )}
          </Stack>
        </Stack>
      </ConfirmDialog>
      <ConfirmDialog
        isAmendButton
        hidden={hideConfirmAmendDialog}
        title={CONFIRM_AMEND_DIALOG_TITLE}
        subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
        onDismiss={toggleConfirmAmendDialog}
        onConfirm={async () => {
          toggleConfirmAmendDialog();
          const inputVariables: SupplierApprovalRevokeInput = {
            entityId: supplierDetails?.supplier?.id!,
            rowTimestamp: supplierDetails?.supplier?._rowTimestamp!,
          };
          const { errors } = await supplierApprovalRevoke({
            variables: {
              input: inputVariables,
            },
            awaitRefetchQueries: true,
            refetchQueries: (mutationResult) => {
              if (mutationResult.data) {
                if (purchaseOrderId) {
                  return [
                    {
                      query: SUPPLIER_DETAILS,
                      variables: {
                        id: supplierDetails?.supplier?.id!,
                      },
                    },
                    {
                      query: PURCHASE_ORDER,
                      variables: {
                        id: purchaseOrderId,
                      },
                    },
                  ];
                } else {
                  return [
                    {
                      query: SUPPLIER_DETAILS,
                      variables: {
                        id: supplierDetails?.supplier?.id!,
                      },
                    },
                  ];
                }
              }
              return [];
            },
          });
          if (errors?.length)
            addToast(errors[0].message, {
              appearance: 'error',
            });
          else
            addToast('Approval amended successfully', {
              appearance: 'success',
            });
        }}
      />
      <Stack
        styles={{
          root: {
            width: 850,
          },
        }}
        tokens={{
          childrenGap: 10,
        }}
      >
        <FormProvider {...formMethods}>
          {isEdit && supplierDetailsLoading ? (
            <ShimmerView />
          ) : (
            <>
              {isEdit && supplierDetails?.supplier && (
                <>
                  {supplierDetails?.supplier._isApprovalDocumentsRequired &&
                    supplierDetails?.supplier._requiredApprovalDocuments && (
                      <MessageBar messageBarType={MessageBarType.error}>
                        {supplierDetails?.supplier?._requiredApprovalDocuments}
                      </MessageBar>
                    )}
                  <ActionsMenu
                    onRemove={refetch}
                    supplier={supplierDetails}
                    onUpload={async (fileSelected, document, toastId) => {
                      const observer = client.subscribe({
                        query: DOCUMENT_UPLOAD_STATUS,
                        variables: {
                          documentId: document.document._documentFileId!,
                        },
                      });
                      const subscription = observer.subscribe((response) => {
                        const subscribedData =
                          response.data as OnDocumentUploadStatus;

                        const { status, document } = {
                          ...subscribedData.documentUploadStatus,
                        };

                        if (!document) {
                          if (status.type === UploadStatusType.VALIDATING) {
                            updateToast(toastId!, {
                              content: status.message
                                ? `Validating files ${fileSelected.name} - ${status.message}`
                                : `Validating files ${fileSelected.name}`,
                              appearance: 'info',
                              autoDismiss: false,
                            });
                          } else if (
                            status.type === UploadStatusType.EXTRACTING
                          ) {
                            updateToast(toastId!, {
                              content: status.message
                                ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                : `Extracting data from ${fileSelected.name}`,
                              appearance: 'info',
                              autoDismiss: false,
                            });
                          } else if (status.type === UploadStatusType.FAILURE) {
                            subscription.unsubscribe();
                            updateToast(toastId!, {
                              content: status.message
                                ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                : `Upload of ${fileSelected.name} failed`,
                              appearance: 'error',
                              autoDismiss: true,
                            });
                          } else if (status.type === UploadStatusType.WARNING) {
                            subscription.unsubscribe();
                            updateToast(toastId!, {
                              content: status.message
                                ? `Warning for file ${fileSelected.name}: ${status.message}`
                                : `Warning for file ${fileSelected.name}`,
                              appearance: 'warning',
                              autoDismiss: true,
                            });
                          }
                        } else {
                          subscription.unsubscribe();
                          updateToast(toastId!, {
                            content: status.message
                              ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                              : `Successfully uploaded ${fileSelected.name}`,
                            appearance: 'success',
                            autoDismiss: true,
                          });
                          refetch?.();
                        }
                      });
                    }}
                  />
                  <Separator />
                  <ReviewsRatings
                    supplier={supplierDetails?.supplier}
                    countTotal={
                      supplierDetails.supplier.supplierRatingResults?.countTotal
                    }
                  />
                  <Separator />
                </>
              )}
              <BasicForm
                isNew={!isEdit}
                isUpdatable={!!isUpdatable}
                supplier={supplierDetails?.supplier}
                onSupplierCreate={onSupplierCreate}
              />
            </>
          )}
          <Footer
            isNew={!isEdit}
            isLoading={isLoading}
            isDisabled={!isDirty || !isValid || isSubmitting}
            showRequestApproval={showRequestApproval}
            showAmendApproval={!!showAmendApproval}
            onSaveAndClose={(value) => (saveAnotherPO.current = value)}
            onSave={handleSubmit(onHandleSubmit)}
            onCancel={() => setOpen(false)}
            onToggleConfirmDialog={toggleConfirmDialog}
            onToggleAmendDialog={toggleConfirmAmendDialog}
          />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
