import { FilterArrayType } from 'common/components/Filters';
import { PaymentsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { PaymentSearchFilterTotalsVariables } from './__generated__/PaymentSearchFilterTotals';
import { ToNullable } from 'utility/types';

type OrderLookup = {
  [direction in OrderDirection]: PaymentsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'payCycle',
    {
      [OrderDirection.ASC]: [
        PaymentsOrderBy._PAY_CYCLE_DESCRIPTION_ASC,
      ],
      [OrderDirection.DESC]: [
        PaymentsOrderBy._PAY_CYCLE_DESCRIPTION_DESC,
      ],
    },
  ],
  [
    'type',
    {
      [OrderDirection.ASC]: [
        PaymentsOrderBy.PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        PaymentsOrderBy.PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC,
      ],
    },
  ],
  [
    'account',
    {
      [OrderDirection.ASC]: [
        PaymentsOrderBy.PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        PaymentsOrderBy.PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'vendorReference',
    {
      [OrderDirection.ASC]: [PaymentsOrderBy.VENDOR_REFERENCE_ASC],
      [OrderDirection.DESC]: [PaymentsOrderBy.VENDOR_REFERENCE_DESC],
    },
  ],
  [
    'paidDate',
    {
      [OrderDirection.ASC]: [PaymentsOrderBy.PAID_DATE_ASC],
      [OrderDirection.DESC]: [PaymentsOrderBy.PAID_DATE_DESC],
    },
  ],
  [
    'paymentReference',
    {
      [OrderDirection.ASC]: [PaymentsOrderBy.PAYMENT_REFERENCE_ASC],
      [OrderDirection.DESC]: [PaymentsOrderBy.PAYMENT_REFERENCE_DESC],
    },
  ],
  [
    'currency',
    {
      [OrderDirection.ASC]: [PaymentsOrderBy.CURRENCY_ID_ASC],
      [OrderDirection.DESC]: [PaymentsOrderBy.CURRENCY_ID_DESC],
    },
  ],
  [
    'paidAmount',
    {
      [OrderDirection.ASC]: [PaymentsOrderBy.PAID_AMOUNT_ASC],
      [OrderDirection.DESC]: [PaymentsOrderBy.PAID_AMOUNT_DESC],
    },
  ],
  [
    '_baseCurrencyAmount',
    {
      [OrderDirection.ASC]: [PaymentsOrderBy._BASE_CURRENCY_AMOUNT_ASC],
      [OrderDirection.DESC]: [PaymentsOrderBy._BASE_CURRENCY_AMOUNT_DESC],
    },
  ],
  [
    '_spotCurrencyAmount',
    {
      [OrderDirection.ASC]: [PaymentsOrderBy._SPOT_CURRENCY_AMOUNT_ASC],
      [OrderDirection.DESC]: [PaymentsOrderBy._SPOT_CURRENCY_AMOUNT_DESC],
    },
  ],
  [
    'businessUnit',
    {
      [OrderDirection.ASC]: [
        PaymentsOrderBy.COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        PaymentsOrderBy.COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'production',
    {
      [OrderDirection.ASC]: [PaymentsOrderBy.COMPANY_BY_COMPANY_ID__NAME_ASC],
      [OrderDirection.DESC]: [PaymentsOrderBy.COMPANY_BY_COMPANY_ID__NAME_DESC],
    },
  ],
  [
    'status',
    {
      [OrderDirection.ASC]: [
        PaymentsOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        PaymentsOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    PaymentsOrderBy.PAYMENT_REFERENCE_ASC,
    PaymentsOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;

  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const toPaymentFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'transactionTypeId':
      case 'businessUnitId':
      case 'currencyId':
      case '_companyId':
      case '_statusTypeId':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      default:
        return undefined;
    }
  });

  type searchTotalFilterType = ToNullable<
    Omit<
      PaymentSearchFilterTotalsVariables,
      'isSigningSearch' | 'isHistorySearch' | 'searchRequest' | 'isLinkSearch'
    >
  >;
  export const getFilterParams = (searchFilters: FilterArrayType[]) => {
    const searchTotalFilterArray: searchTotalFilterType = {
      filterCurrencyId: undefined,
      filterTransactionTypeId: undefined,
      filterBusinessUnitId: undefined,
      filterCompanyId: undefined,
      filterStatusTypeId: undefined,
    };
    searchFilters.forEach((curr) => {
      switch (curr.filterKey) {
        case 'transactionTypeId':
          return (searchTotalFilterArray['filterTransactionTypeId'] =
            curr.value as number | null);
        case 'businessUnitId':
          return (searchTotalFilterArray['filterBusinessUnitId'] =
            curr.value as GqlUUID | null);
        case '_companyId':
          return (searchTotalFilterArray['filterCompanyId'] =
            curr.value as GqlUUID | null);
        case '_statusTypeId':
          return (searchTotalFilterArray['filterStatusTypeId'] = curr.value as
            | number
            | null);
        case 'currencyId':
          return (searchTotalFilterArray['filterCurrencyId'] = curr.value as
            | number
            | null);
        default:
          return searchTotalFilterArray;
      }
    });
    return searchTotalFilterArray;
  };
