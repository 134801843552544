import { Callout, DirectionalHint, Stack, Text } from '@fluentui/react'
import React from 'react';

interface RentalDatesCalloutProps {
  calloutId: string;
  visible: boolean;
  dates: string;
}

export const RentalDatesCallout: React.FC<RentalDatesCalloutProps> = ({
  calloutId,
  visible,
  dates,
}) => {
  if (!visible) return null;
  return (
    <>
      <Callout
        gapSpace={0}
        target={`#${calloutId}`}
        directionalHint={DirectionalHint.rightCenter}
        setInitialFocus
      >
        <Stack tokens={{ padding: 10 }}
          style={{
            width: 300,
          }}
          wrap
        >
          <Text>{dates}</Text>
        </Stack>
      </Callout>
    </>
  )
}
