import React, { useMemo } from 'react';
import { Stack } from '@fluentui/react';
import { ApprovalHierarchy } from 'common/components/Hierarchy';

interface HierarchyModalProps {
  entityId: string;
}
export const HierarchyData: React.FC<HierarchyModalProps> = ({ entityId }) => {
  const HierarchyComponent = () => {
    return (
      <ApprovalHierarchy getApprovalType={(data) => {}} entityId={entityId} />
    );
  };

  const MemoizedHierarchyComponent = useMemo(HierarchyComponent, []);

  return <Stack>{MemoizedHierarchyComponent}</Stack>;
};
