import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    zIndex: 99,
    bottom: 0,
    padding: '10px 20px',
    height: 84,
  },
  progressContainer: {
    height: 12,
  },
}));
