import { useMutation } from '@apollo/client';
import {
  IconButton,
  ProgressIndicator,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { ShareNoteButton } from 'common/components/Buttons/ShareNoteButton/ShareNoteButton';
import {
  DeleteNote,
  DeleteNoteVariables,
} from 'common/graphql/__generated__/DeleteNote';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import {
  UpdateNote,
  UpdateNoteVariables,
} from 'common/graphql/__generated__/UpdateNote';
import { useCommonStyles } from 'common/styles';
import { getPrettierdate } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { NotesSectionProps } from '../..';
import { NoteUpdateForm } from './NoteUpdateForm';
const DELETE_NOTE = loader('../../../../graphql/DeleteNote.graphql');
const UPDATE_NOTE = loader('../../../../graphql/UpdateNote.graphql');

type NotesListItemProps = Pick<NotesSectionProps, 'listRefreshRequest'> & {
  item: NotesEntityFields;
  index: number;
  totalNotes: number;
  isDirty?: boolean;
  isViewOnly?: boolean;
};

interface NoteFormUpdateProps {
  item: NotesEntityFields;
  onCancel: () => void;
  listRefreshRequest: () => void;
}

export const NotesListItem: React.FC<NotesListItemProps> = ({
  item,
  index,
  totalNotes,
  isDirty,
  listRefreshRequest,
  isViewOnly = false,
}) => {
  const { addToast } = useToasts();
  const commonStyles = useCommonStyles();
  const [isEdit, setEdit] = useState<boolean>(false);

  const [noteUpdate, { loading: updateInProgress }] = useMutation<
    UpdateNote,
    UpdateNoteVariables
  >(UPDATE_NOTE, {
    errorPolicy: 'all',
  });

  const [noteDelete, { loading: deleteInProgress }] = useMutation<
    DeleteNote,
    DeleteNoteVariables
  >(DELETE_NOTE, {
    errorPolicy: 'all',
  });

  const onNoteDelete = async () => {
    if (!deleteInProgress) {
      const { errors } = await noteDelete({
        variables: {
          input: {
            entityDelete: [
              {
                id: item.id,
                rowTimestamp: item._rowTimestamp!,
              },
            ],
          },
        },
      });

      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        listRefreshRequest?.();
        addToast('Note has been deleted', { appearance: 'success' });
      }
    }
  };

  const onNoteUpdate = async () => {
    if (!updateInProgress) {
      const { errors } = await noteUpdate({
        variables: {
          input: {
            id: item.id,
            rowTimestamp: item._rowTimestamp || '',
            notePatch: { isShared: !item.isShared },
          },
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        listRefreshRequest?.();
        addToast('Note has been updated', { appearance: 'success' });
      }
    }
  };

  const loaderLabel = `Note ${
    updateInProgress ? 'Update' : 'Delete'
  } in progress`;

  return (
    <Stack
      key={item.id}
      tokens={{ childrenGap: 15, padding: '0px 10px 0px 10px' }}
    >
      {isEdit ? (
        <NoteUpdateForm
          item={item}
          onCancel={() => {
            setEdit(false);
          }}
          listRefreshRequest={() => {
            listRefreshRequest?.();
          }}
        />
      ) : (
        <>
          <Stack horizontal horizontalAlign="space-between">
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Text variant="medium" className={commonStyles.bold}>
                  {item._createdByUserName}
                </Text>
                <Text>
                  {item?._createdDate && getPrettierdate(item?._createdDate)}
                </Text>
              </Stack>
              <Text>{item.noteComment}</Text>
            </Stack>
            {!isViewOnly && (
              <Stack
                horizontal
                tokens={{ childrenGap: 10 }}
                horizontalAlign="center"
              >
                <IconButton
                  disabled={!item._isUpdatable || isDirty}
                  onClick={() => {
                    setEdit(true);
                  }}
                  iconProps={{ iconName: 'Edit' }}
                />

                <ShareNoteButton
                  disabled={updateInProgress || !item._isUpdatable || isDirty}
                  onClick={onNoteUpdate}
                  isShared={item.isShared}
                />
                <IconButton
                  disabled={deleteInProgress || !item._isDeletable || isDirty}
                  onClick={onNoteDelete}
                  iconProps={{ iconName: 'delete' }}
                />
              </Stack>
            )}
          </Stack>
          {(updateInProgress || deleteInProgress) && (
            <ProgressIndicator label={loaderLabel} />
          )}
          {index === totalNotes - 1 ? null : <Separator />}
        </>
      )}
    </Stack>
  );
};
