import { Stack, Text } from '@fluentui/react';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import {
  FormHookAmount,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import React, { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  PurchaseOrderItemFormProps,
  PurchaseOrderItemLayout,
} from '../../interface';
import { useStyles } from '../index.styles';

type RateAmountSectionProps = PurchaseOrderItemLayout;
export const RateAmountSection: React.FC<RateAmountSectionProps> = ({
  purchaseOrderData,
  isNew,
}) => {
  const { purchaseOrder } = { ...purchaseOrderData };
  const styles = useStyles();
  const renderRef = useRef(false);
  const renderRef2 = useRef(false);
  const watchValues = useWatch({
    name: [
      'itemExtendedAmount',
      'itemWeeks',
      'itemMonths',
      'itemDays',
      'itemQuantity',
      'itemDayAmount',
      'itemWeekAmount',
      'itemMonthAmount',
      'itemDayRate',
      'itemWeekRate',
      'itemMonthRate',
    ],
  });

  const fieldDisabled = !purchaseOrder?._isUpdatable && !isNew;

  const { setValue } = useFormContext<PurchaseOrderItemFormProps>();

  const watchItemExtendedAmount = watchValues?.[0];
  const watchWeeks = watchValues?.[1];
  const watchMonths = watchValues?.[2];
  const watchDays = watchValues?.[3];
  const watchItemQuantity = watchValues?.[4];
  const watchDayAmount = watchValues?.[5];
  const watchWeekAmount = watchValues?.[6];
  const watchMonthAmount = watchValues?.[7];
  const watchDayRate = watchValues?.[8];
  const watchWeekRate = watchValues?.[9];
  const watchMonthRate = watchValues?.[10];

  const daysExtendedTotal = watchDayAmount * (watchItemQuantity || 1);
  const monthExtendedTotal = watchMonthAmount * (watchItemQuantity || 1);
  const weekExtendedTotal = watchWeekAmount * (watchItemQuantity || 1);

  useEffect(() => {
    if (renderRef2.current) {
      const total = daysExtendedTotal + weekExtendedTotal + monthExtendedTotal;
      setValue('itemExtendedAmount', total.toString());
    } else renderRef2.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchItemQuantity, watchDayAmount, watchMonthAmount, watchWeekAmount]);

  useEffect(() => {
    if (renderRef.current) {
      setValue('itemWeekAmount', (watchWeekRate * watchWeeks).toString());
      setValue('itemDayAmount', (watchDayRate * watchDays).toString());
      setValue('itemMonthAmount', (watchMonthRate * watchMonths).toString());
    } else renderRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchDayRate,
    watchWeekRate,
    watchMonthRate,
    watchDays,
    watchWeeks,
    watchMonths,
  ]);

  return (
    <Stack horizontalAlign="end" grow={1} tokens={{ childrenGap: 10 }}>
      {!!parseFloat(watchItemExtendedAmount) && (
        <Stack
          horizontal
          horizontalAlign="center"
          grow={1}
          tokens={{ childrenGap: 10 }}
        >
          <Stack className={styles.headerContainer}>
            <Text>Rate</Text>
          </Stack>

          <Stack className={styles.headerContainer}>
            <Text>Amount</Text>
          </Stack>

          <Stack className={styles.headerContainer}>
            <Text>Total</Text>
          </Stack>
        </Stack>
      )}
      {!!watchWeeks && (
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
          <Stack grow={1} tokens={{ childrenGap: 20 }}>
            <PeriodView
              fieldDisabled={fieldDisabled}
              label="Week"
              name={'itemWeeks'}
              integerOnly
            />
          </Stack>

          <Stack grow={1}>
            <FormHookAmount
              name="itemWeekRate"
              decimalScale={2}
              fixedDecimalScale
              placeholder="Enter rate"
              disabled={fieldDisabled}
              styles={{ field: { textAlign: 'end' } }}
            />
          </Stack>

          <Stack grow={1}>
            <FormHookAmount
              name="itemWeekAmount"
              decimalScale={2}
              fixedDecimalScale
              placeholder="Enter Amount"
              disabled={fieldDisabled}
              styles={{ field: { textAlign: 'end' } }}
            />
          </Stack>

          <Stack grow={1} className={styles.amountContainer}>
            <AmountTextView
              value={weekExtendedTotal.toString()}
              className={styles.amount}
            />
          </Stack>
        </Stack>
      )}

      {!!watchMonths && (
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
          <Stack grow={1} tokens={{ childrenGap: 20 }}>
            <PeriodView
              fieldDisabled={fieldDisabled}
              label="Months"
              name={'itemMonths'}
              integerOnly
            />
          </Stack>

          <Stack grow={1}>
            <FormHookAmount
              name="itemMonthRate"
              decimalScale={2}
              fixedDecimalScale
              placeholder="Enter rate"
              disabled={fieldDisabled}
              styles={{ field: { textAlign: 'end' } }}
            />
          </Stack>

          <Stack grow={1}>
            <FormHookAmount
              name="itemMonthAmount"
              decimalScale={2}
              fixedDecimalScale
              placeholder="Enter Amount"
              disabled={fieldDisabled}
              styles={{ field: { textAlign: 'end' } }}
            />
          </Stack>

          <Stack grow={1} className={styles.amountContainer}>
            <AmountTextView
              value={monthExtendedTotal.toString()}
              className={styles.amount}
            />
          </Stack>
        </Stack>
      )}

      {!!watchDays && (
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
          <Stack grow={1} tokens={{ childrenGap: 20 }}>
            <PeriodView
              fieldDisabled={fieldDisabled}
              label="Days"
              name={'itemDays'}
              integerOnly
            />
          </Stack>

          <Stack grow={1}>
            <FormHookAmount
              name="itemDayRate"
              decimalScale={2}
              fixedDecimalScale
              placeholder="Enter rate"
              disabled={fieldDisabled}
              styles={{ field: { textAlign: 'end' } }}
            />
          </Stack>

          <Stack grow={1}>
            <FormHookAmount
              name="itemDayAmount"
              decimalScale={2}
              fixedDecimalScale
              placeholder="Enter Amount"
              disabled={fieldDisabled}
              styles={{ field: { textAlign: 'end' } }}
            />
          </Stack>

          <Stack grow={1} className={styles.amountContainer}>
            <AmountTextView
              value={daysExtendedTotal.toString()}
              className={styles.amount}
            />
          </Stack>
        </Stack>
      )}

      {!!parseFloat(watchItemExtendedAmount) && (
        <AmountTextView
          value={watchItemExtendedAmount}
          className={styles.totalAmount}
        />
      )}
    </Stack>
  );
};

const PeriodView: React.FC<{
  label: string;
  name: string;
  fieldDisabled?: boolean;
  integerOnly?: boolean;
}> = ({ name, label, fieldDisabled, integerOnly = false }) => {
  const styles = useStyles();
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 10 }}
      className={styles.weekTextContainer}
    >
      <Text className={styles.weekText}>{`${label}`}</Text>
      <FormHookTextField
        name={name}
        borderless
        underlined
        disabled={fieldDisabled}
        integerOnly={integerOnly}
      />
    </Stack>
  );
};
