import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: 500, 
      padding: '60px 25px 150px 25px',
    },
    fullWidthContainer: { 
    display: 'flex', 
    flexWrap: 'wrap', 
    width: '100%' 
  },
  };
});
