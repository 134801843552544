import { useQuery } from '@apollo/client';
import { IDropdownOption, Label, Stack, Text } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookCountryRegion,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import { Countries } from 'common/graphql/__generated__/Countries';
import { loader } from 'graphql.macro';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TravelPolicyExpenditureTypes_travelPolicyExpenditureTypes_nodes } from '../__generated__/TravelPolicyExpenditureTypes';
import { TravelPolicyAllowancesValues } from '../types';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';
import { useStyles } from './index.styles';
const COUNTRIES = loader(
  '../../../../../../../common/graphql/Countries.graphql'
);

type ExpenditureTypesOptions = IDropdownOption &
  TravelPolicyExpenditureTypes_travelPolicyExpenditureTypes_nodes;

interface BasicFormProps {
  expenditureTypes: TravelPolicyExpenditureTypes_travelPolicyExpenditureTypes_nodes[];
  index: number;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  expenditureTypes,
  index,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { control } = useFormContext<TravelPolicyAllowancesValues>();
  const expenditureTypeId = useWatch({
    control,
    name: `travelPolicyAllowancesRow.${index}.expenditureTypeId`,
  });

  const { data: countries } = useQuery<Countries>(COUNTRIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const countryOptions: IDropdownOption[] =
    countries?.countries?.nodes.map((ele) => ({
      key: ele.id,
      text: ele.country,
    })) || [];

  const expenditureTypesOptions: ExpenditureTypesOptions[] =
    expenditureTypes.map((ele) => ({
      key: ele.id,
      text: ele.expenditureType || '',
      ...ele,
    })) || [];

  const expenditure = expenditureTypesOptions.find(
    (item) => item.key === expenditureTypeId
  );

  const {
    _isMilesAllowed,
    allowanceHint,
    _isCountry1Allowed,
    _isCountry2Allowed,
    _isStateReference1Allowed,
    _isStateReference2Allowed,
  } = {
    ...expenditure,
  };

  return (
    <Stack
      tokens={{
        childrenGap: 15,
        padding: 20,
      }}
    >
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
        className={styles.inputRow}
      >
        <Stack className={styles.expenditureType}>
          <FormHookDropdown
            name={`travelPolicyAllowancesRow.${index}.expenditureTypeId`}
            label="Expenditure Type"
            placeholder="Select"
            options={expenditureTypesOptions}
          />
        </Stack>
        <Stack className={styles.policyAllowance}>
          <FormHookAmount
            name={`travelPolicyAllowancesRow.${index}.policyAllowance`}
            placeholder={allowanceHint || ''}
            label="Policy Allowance"
            fixedDecimalScale
            allowNegative={false}
            decimalScale={2}
          />
        </Stack>
        <FormHookCheckBox
          name={`travelPolicyAllowancesRow.${index}.isAllowanceCustomizable`}
          label="Customizable"
        />
      </Stack>

      {!(!_isMilesAllowed && !_isCountry1Allowed && !_isCountry2Allowed) && (
        <Stack tokens={{ padding: '20px 0px' }}>
          <Text
            variant="large"
            className={clsx(commonStyles.bold, commonStyles.colorThemePrimary)}
          >
            Matching Budget Criteria
          </Text>
          <Stack tokens={{ childrenGap: 20 }}>
            {_isCountry1Allowed && (
              <Stack>
                {_isCountry2Allowed && (
                  <Label className={clsx(commonStyles.colorThemePrimary)}>
                    From
                  </Label>
                )}
                <FormHookCountryRegion
                  countries={countryOptions}
                  regionName={`travelPolicyAllowancesRow.${index}.stateRegion1Id`}
                  countryName={`travelPolicyAllowancesRow.${index}.country1Id`}
                  CountryAutocompleteProps={{
                    label: 'Country',
                    errorMessage: '',
                  }}
                  StateRegionAutocompleteProps={{
                    label: 'State / Region / Province',
                    errorMessage: '',
                  }}
                  isStateRegionSelectable={!!_isStateReference1Allowed}
                  isCountryClearable
                  isStateRegionClearable
                />
              </Stack>
            )}

            {_isCountry2Allowed && (
              <Stack>
                <Label className={clsx(commonStyles.colorThemePrimary)}>
                  To
                </Label>
                <FormHookCountryRegion
                  countries={countryOptions}
                  regionName={`travelPolicyAllowancesRow.${index}.stateRegion2Id`}
                  countryName={`travelPolicyAllowancesRow.${index}.country2Id`}
                  CountryAutocompleteProps={{
                    label: 'Country',
                    errorMessage: '',
                  }}
                  StateRegionAutocompleteProps={{
                    label: 'State / Region / Province',
                    errorMessage: '',
                  }}
                  isStateRegionSelectable={!!_isStateReference2Allowed}
                  isCountryClearable
                  isStateRegionClearable
                />
              </Stack>
            )}
          </Stack>
          <Stack tokens={{ padding: '20px 0px' }}>
            {_isMilesAllowed && (
              <Stack>
                <Label className={commonStyles.colorThemePrimary}>
                  Or Miles
                </Label>
                <FormHookAmount
                  name={`travelPolicyAllowancesRow.${index}.policyMiles`}
                  placeholder="Enter Miles"
                  fixedDecimalScale
                  allowNegative={false}
                  decimalScale={0}
                  maxLength={6}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
