import React from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { NotesEntityFields } from 'common/graphql/__generated__/NotesEntityFields';
import {
  NotesSection,
  NotesSectionProps,
} from 'common/components/NotesSection';
import { NoteAddMutationProps } from 'common/components/NotesSection/NotesForm';
import { NotePaymentCreate, NotePaymentCreateVariables } from 'common/graphql/__generated__/NotePaymentCreate';
const ADD_NEW_NOTE = loader('../../../../../../../common/graphql/NotePaymentCreate.graphql');

type PaymentTrackingNotesSectionProps = Pick<
  NotesSectionProps,
  'listRefreshRequest'
> & {
  paymentTrackingId: string;
  data: NotesEntityFields[];
  loading?: boolean;
  isViewOnly?:boolean
};

export const PaymentTrackingNotesSection: React.FC<
  PaymentTrackingNotesSectionProps
> = ({ paymentTrackingId, data, loading, listRefreshRequest, isViewOnly }) => {
  const [createNote, { loading: createNoteInProgress, error }] = useMutation<
    NotePaymentCreate,
    NotePaymentCreateVariables
  >(ADD_NEW_NOTE);

  const noteAddMutation: NoteAddMutationProps = {
    loading: createNoteInProgress,
    createNote: async (formData) => {
      const { noteComment, isShared } = { ...formData };
      await createNote({
        variables: {
          input: {
            note: {
              entityId: paymentTrackingId,
              noteComment: noteComment!,
              isShared: isShared!,
            },
          },
        },
      });
      if (!error) listRefreshRequest?.();
      return error;
    },
  };

  return (
    <NotesSection
      dataLoading={loading!}
      noteAddMutation={noteAddMutation}
      addingNoteInProgress={createNoteInProgress}
      listRefreshRequest={listRefreshRequest}
      data={data}
      isViewOnly={isViewOnly}
    />
  );
};
