import {
  Icon,
  IconButton,
  IIconStyles,
  Label,
  Stack,
  StackItem,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { Supplier_supplier } from 'settings/account/supplier/view/__generated__/Supplier';
import { SupplierAlias } from 'settings/account/supplier/view/BasicForm/SupplierAlias';
import { SupplierModal } from '../../SupplierModal';

interface TextFieldLabelProps {
  label: string | undefined;
  required?: boolean | undefined;
  supplier: Supplier_supplier | null | undefined;
}

export const TextFieldLabel: React.FC<TextFieldLabelProps> = ({
  label,
  required = false,
  supplier,
}) => {
  const theme = useTheme();
  const iconButtonId: string = useId('iconButton');
  const iconStyles: Partial<IIconStyles> = {
    root: {
      color: theme.palette.themePrimary,
      margin: '6px 0px 0px 6px',
    },
  };
  const [supplierDialogVisible, setSupplierDialogVisible] =
    useState<boolean>(false);
  return (
    <Stack horizontal verticalAlign="baseline">
      {supplierDialogVisible && (
        <SupplierModal
          setOpen={setSupplierDialogVisible}
          supplier={supplier}
        />
      )}
      <Label
        required={required}
      >
        {label}
      </Label>
      {supplier?._isApproved && (
        <TooltipHost content={'Verified'}>
          <Icon iconName="CheckMark" styles={iconStyles} />
        </TooltipHost>
      )}
      {supplier?.id && (
        <TooltipHost content={'Edit Supplier'}>
          <IconButton
            id={iconButtonId}
            iconProps={{ iconName: 'Edit' }}
            styles={iconStyles}
            onClick={() => {
              setSupplierDialogVisible(true);
            }}
          />
        </TooltipHost>
      )}
      {supplier?._fullName && (
        <StackItem style={{ marginLeft: 'auto' }}>
          <SupplierAlias supplier={supplier} isProtected />
        </StackItem>
      )}
    </Stack>
  );
};
