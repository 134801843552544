import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: 50,
      // justifyContent: 'space-between',
    },
    groupName: {
      color: theme.palette.themePrimary,
    },
    groupSelected: {
      backgroundColor: theme.palette.neutralLighter,
    },
    linkLightHover: {
      ':hover': {
        backgroundColor: theme.palette.neutralLighter,
      },
    },
    cardAccountName: {
      ':focus': {
        color: theme.palette.themeDark,
      },
    },
    active: {
      color: theme.palette.accent,
      ':focus': {
        color: theme.palette.accent,
      },
    },
    iconButtonColor: {
      color: theme.palette.neutralDark,
    },
    accountRowCell: {
      width: 190,
      minWidth: 190,
      maxWidth: 190,
      paddingLeft: 60,
      // border: "1px solid blue",
    },
    rowCell: {
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      paddingLeft: 10,
      // border: "1px solid green",
    },
    openingDateContainer:{
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      paddingLeft: 10,
      // border: "1px solid green",
    },
    closingDateContainer:{
      width: 140,
      minWidth: 140,
      maxWidth: 140,
      paddingLeft: 10,
      // border: "1px solid green",
    },
    actionContainer:{
      // flex: 1.8,
      paddingLeft: 10,
      width: 370,
      minWidth: 370,
      maxWidth: 370,
      // border: "1px solid yellow",
    },
  };
});
