import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';
import { InvoiceSigningUrgentUpdateInput } from 'common/types/globalTypes';
import { ConfirmDialog } from 'common/components/UrgencySelector/ConfirmDialog';
import { useStyles } from './index.styles';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import {
  PurchaseOrderUrgentUpdate,
  PurchaseOrderUrgentUpdateVariables,
} from './__generated__/PurchaseOrderUrgentUpdate';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useCommonStyles } from 'common/styles';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import { setUserDefaults } from 'utility/cache/ui';
// const INVOICE_DETAILS = loader('../../../InvoiceDetails.graphql');

const PURCHASE_ORDER_URGENCY_UPDATE = loader(
  './PurchaseOrderUrgentUpdate.graphql'
);
interface UrgencyFolderProps {
  purchaseOrderData?: PurchaseOrder | undefined;
}

export const UrgencyFolder: React.FC<UrgencyFolderProps> = ({
  purchaseOrderData,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);

  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();

  const [isVisible, setIsVisible] = useState(false);

  const isUrgent = !Boolean(purchaseOrderData?.purchaseOrder?._urgencyLevel);
  const urgencyLevelToolTip = isUrgent ? 'Remove Urgency' : 'Elevate to urgent';
  const iconName = isUrgent ? 'FabricFolderFill' : 'FabricFolder';
  const iconStyle = isUrgent ? commonStyles.urgentBox : styles.standardBox;
  const isChecked = !isUrgent;
  const [invoiceSigningUrgencyUpdate] = useMutation<
    PurchaseOrderUrgentUpdate,
    PurchaseOrderUrgentUpdateVariables
  >(PURCHASE_ORDER_URGENCY_UPDATE, { errorPolicy: 'all' });

  const onSubmit = async (requestComment: string | undefined | null) => {
    const updatedUrgencyLevel =
      purchaseOrderData?.purchaseOrder?._urgencyLevel === 0 ? 1 : 0;

    const { errors } = await invoiceSigningUrgencyUpdate({
      variables: {
        input: {
          entityId: purchaseOrderData?.purchaseOrder?.id!,
          rowTimestamp: purchaseOrderData?.purchaseOrder?._rowTimestamp,
          urgencyLevel: updatedUrgencyLevel,
          explanationComment: requestComment,
        } as InvoiceSigningUrgentUpdateInput,
      },
      update(cache, { data }) {
        if (!!purchaseOrderData?.purchaseOrder?.id) {
          cache.modify({
            id: cache.identify({ ...purchaseOrderData.purchaseOrder }),
            fields: {
              _urgencyLevel: () => {
                return data?.purchaseOrderUrgentUpdate?._newUrgentLevel;
              },
            },
          });
        }
        setIsVisible(false);
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Urgency status updated successfully.', {
        appearance: 'success',
      });
    }
  };

  return (
    <Stack>
      <TooltipHost content={urgencyLevelToolTip}>
        <IconButton
          iconProps={{
            iconName: iconName,
          }}
          className={iconStyle}
          onClick={() => {
            if (userDefaults?._isUserUrgencyUpdateAllowed) setIsVisible(true);
          }}
          styles={{
            rootHovered: { color: 'redDark' },
            rootPressed: { color: 'redDark' },
          }}
        />
      </TooltipHost>
      <ConfirmDialog
        hidden={!isVisible}
        minWidth={500}
        checked={isChecked}
        onConfirm={(requestComment: string | null | undefined) =>
          onSubmit(requestComment)
        }
        onDismiss={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
      />
    </Stack>
  );
};
