import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { TABLE_ROWS } from 'common/constants';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { AttachFormProps } from '../index';
import { useStyles } from './index.styles';
import { useToasts } from 'react-toast-notifications';
import {
  DirectionalHint,
  IColumn,
  IconButton,
  IDetailsRowProps,
  IDropdownOption,
  IRenderFunction,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { SortOrder } from 'common/utils/commonTypes';
import { toOrderByVariable } from './utils';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { _columns } from './columns.data';
import { EntityDocumentFilter } from 'common/types/globalTypes';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DocumentViewModalState } from 'common/components/DocumentList';
import {
  AttachablePayCycleDocuments,
  AttachablePayCycleDocumentsVariables,
  AttachablePayCycleDocuments_attachablePayCycleDocuments_nodes,
} from 'ap/paymentCycle/__generated__/AttachablePayCycleDocuments';
import {
  AttachPayCycleDocument,
  AttachPayCycleDocumentVariables,
} from 'ap/paymentCycle/__generated__/AttachPayCycleDocument';

const ATTACHABLE_PAYCYCLE_DOCUMENTS = loader(
  '../../../../../AttachablePayCycleDocuments.graphql'
);
const ATTACH_PAYCYCLE_DOCUMENTS = loader(
  '../../../../../AttachPayCycleDocument.graphql'
);
const PAYCYCLE_DETAILS = loader('../../../../GetPayCycleDetails.graphql');
type AttachFormModalProps = AttachFormProps & {
  setOpen: (open: boolean) => void;
};
type AttachableDataType =
  AttachablePayCycleDocuments_attachablePayCycleDocuments_nodes;
export const AttachFormModal: React.FC<AttachFormModalProps> = ({
  paymentCycle,
  availableDocumentTypes,
  setOpen,
}) => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const [selectedList, setSelectedList] = useState<AttachableDataType[]>([]);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const {
    data: documentsList,
    loading: documentsLoading,
    variables: documentsVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<
    AttachablePayCycleDocuments,
    AttachablePayCycleDocumentsVariables
  >(ATTACHABLE_PAYCYCLE_DOCUMENTS, {
    variables: {
      payCycleId: paymentCycle.id,
      first: TABLE_ROWS,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [attachDocuments, { loading: attachLoading, error: attachError }] =
    useMutation<AttachPayCycleDocument, AttachPayCycleDocumentVariables>(
      ATTACH_PAYCYCLE_DOCUMENTS
    );
  const documentTypeOptions: IDropdownOption[] =
    availableDocumentTypes?.payCycleAvailableDocumentTypes?.nodes.map(
      (item) => ({
        key: item.id,
        text: item.documentType!,
      })
    ) || [];

  const _onAttach = async () => {
    const entityDocIds = selectedList.map((item) => item.id);
    const { errors } = await attachDocuments({
      variables: {
        input: {
          payCycleId: paymentCycle?.id!,
          entityDocumentId: entityDocIds,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAYCYCLE_DETAILS,
          variables: {
            id: paymentCycle?.id!,
          },
        },
      ],
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      if (!attachError)
        addToast('Document attached successfully', { appearance: 'success' });
      else addToast('Cannot attach the document', { appearance: 'error' });
      setOpen(false);
    }
  };
  const canSelectItem = useCallback(
    (item: AttachableDataType) =>
      paymentCycle?.isDraft! ? true : !item._isAccountingDocument!,
    [paymentCycle]
  );

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;
      const disableLogic = paymentCycle?.isDraft
        ? false
        : item._isAccountingDocument;
      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;

      

      switch (column.key) {
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout disabled={disableLogic!} item={item} />
            </Stack>
          );
        case 'documentType':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {item.documentTypes?.documentType}
              </Text>
            </Stack>
          );
        case 'indexAmount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <Text className={styles.contentColumnAlignRight}>
                {fieldContent}
              </Text>
            </Stack>
          );
        case 'isoCode':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {item.currency?.isoCode}
              </Text>
            </Stack>
          );
        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {getGlobalDateFormat(item._uploadDate!)}
              </Text>
            </Stack>
          );
        case 'indexTransactionDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              {item.indexTransactionDate && (
                <Text className={disableLogic ? styles.disabledText : ''}>
                  {dateFormat(dateConvertions(item.indexTransactionDate))}
                </Text>
              )}
            </Stack>
          );
        case 'action':
          const viewDocumentVisible =
            item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={disableLogic ? styles.disabledText : ''}>
                {fieldContent}
              </Text>
            </Stack>
          );
      }
    },
    [styles, paymentCycle]
  );

  const { pageInfo, nodes } = {
    ...documentsList?.attachablePayCycleDocuments,
  };

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const { item } = { ...props };
    const newProps: IDetailsRowProps | undefined = props
      ? { ...props, className: styles.row }
      : undefined;
    const tootTipMessage =
      'Upload prohibited during/after the approval process';
    const tooltipVisible = paymentCycle?.isDraft && item._isAccountingDocument;
    return (
      <>
        <TooltipHost
          content={tooltipVisible ? tootTipMessage : ''}
          directionalHint={DirectionalHint.rightCenter}
        >
          {defaultRender!(newProps)}
        </TooltipHost>
      </>
    );
  };
  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...documentsVariables,
          after: documentsList?.attachablePayCycleDocuments?.pageInfo.endCursor,
        },
      }),
    [fetchMore, documentsVariables, documentsList]
  );

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );
  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null) =>
      await refetch({ ...documentsVariables, documentTypeId }),
    [refetch, documentsVariables]
  );
  const attachDisabled = selectedList.length === 0;
  return (
    <>
      <AttachDocumentModal
        columns={_columns}
        loading={documentsLoading}
        modalWidth={1350}
        items={
          networkStatus === NetworkStatus.refetch ||
          networkStatus === NetworkStatus.setVariables
            ? undefined
            : nodes
        }
        hasNextPage={pageInfo?.hasNextPage}
        attachLoading={attachLoading}
        availableDocumentTypes={documentTypeOptions}
        setOpen={setOpen}
        onSortReload={reload}
        onLoadMore={loadMore}
        onFiltersReload={onFiltersReload}
        onRenderRow={onRenderRow}
        onDocumentTypeChange={onDocumentTypeReload}
        attachDisabled={attachDisabled}
        onSelectionChanged={setSelectedList}
        dropdownDisabled={selectedList.length > 0}
        onRenderItemColumn={onRenderItemColumn}
        onAttachDocuments={_onAttach}
        canSelectItem={canSelectItem}
      />
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
