import {
  DefaultButton,
  Dialog,
  DialogFooter,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import React, { useState } from 'react';

interface WarningModalProps {
  onCancel: () => void;
  isDataEntered: boolean;
}
export const WarningModal: React.FC<WarningModalProps> = ({
  onCancel,
  isDataEntered,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <Stack>
      <DefaultButton
        onClick={() => {
          if (isDataEntered) setVisible(true);
          else onCancel();
        }}
        text="Cancel"
      />
      {visible && (
        <Dialog
          dialogContentProps={{
            title: 'Waring',
            subText: 'Data entered will be lost. Are you sure want to cancel ?',
          }}
          hidden={false}
          minWidth={500}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <DialogFooter>
              <PrimaryButton
                onClick={() => {
                  onCancel();
                  setVisible(false);
                }}
                text="Confirm"
              />
              <DefaultButton
                onClick={() => {
                  setVisible(false);
                }}
                text="Close"
              />
            </DialogFooter>
          </Stack>
        </Dialog>
      )}
    </Stack>
  );
};
