import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { useStyles } from './index.styles';
import { AmountTextView } from '../AmountView/AmountTextView';

interface DataViewProps {
  /**  Add your custom title   */
  title: string | undefined;
  /**  Add your custom description    */
  description: string | undefined;
  // If the description is an amount
  isAmount?: boolean;
  currency?: string;
}

export const InfoView: React.FC<DataViewProps> = ({
  title,
  description,
  isAmount,
  currency,
}) => {
  const styles = useStyles();
  return (
    <Stack verticalAlign="center" className={styles.twoInputContainer}>
      <Text variant="medium" className={styles.inputContainerLabel}>
        {title}
      </Text>
      {isAmount && description ? (
        <Stack horizontal tokens={{ padding: '0px 0px 0px 25px' }}>
          <Text className={styles.currency}>
            {currency === null ? '' : currency}
          </Text>
          <AmountTextView value={description} />
        </Stack>
      ) : (
        <Text className={styles.textInputConatiner}>
          {description === null ? '' : description}
        </Text>
      )}
    </Stack>
  );
};
