import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { GetDocumentPoolCommonData } from '../../../__generated__/GetDocumentPoolCommonData';
import React, { useCallback, useState } from 'react';
import { loader } from 'graphql.macro';
import { PivotCustomRender } from '../PivotComponent';
import { GetDocumentPoolDetails_documentPool } from '../__generated__/GetDocumentPoolDetails';

import { useMutation, useQuery } from '@apollo/client';
import { useStyles } from './index.styles';
import { PivotDisplay } from 'common/components/PivotDisplay';
import { HistoryActionMenuView } from 'common/components/History';
import { ProtectSection } from 'common/components/Protect';
import {
  DocumentPoolSecurity,
  DocumentPoolSecurityVariables,
} from '../EditDocumentPool/__generated__/DocumentPoolSecurity';
import { useToasts } from 'react-toast-notifications';
import { NotesDocumentPool } from '../EditDocumentPool/NotesDocumentPool';
import {
  DocumentPoolNotes,
  DocumentPoolNotesVariables,
} from '../EditDocumentPool/NotesDocumentPool/__generated__/DocumentPoolNotes';
const DOCUMENT_POOL_NOTES = loader(
  '../EditDocumentPool/NotesDocumentPool/DocumentPoolNotes.graphql'
);
const DOCUMENT_ROW_PROTECTION = loader(
  '../EditDocumentPool/DocumentPoolSecurity.graphql'
);
const DOCUMENT_POOL_DETAILS = loader('../GetDocumentPoolDetails.graphql');
interface ActionMenuProps {
  documentPoolData: GetDocumentPoolDetails_documentPool;
  documentPoolId: string;
  commonData: GetDocumentPoolCommonData | undefined;
}
enum ActionConst {
  protect = 'Protect_Action',
  notes = 'Notes_Action',
  history = 'History_Action',
}
export const ActionsMenu: React.FC<ActionMenuProps> = ({
  documentPoolData,
  documentPoolId,
  commonData,
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    ActionConst.protect
  );
  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const { addToast } = useToasts();
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    DocumentPoolNotes,
    DocumentPoolNotesVariables
  >(DOCUMENT_POOL_NOTES, {
    variables: {
      id: documentPoolId!,
    },
  });
  const getEntityNotes = () => {
    const variables: DocumentPoolNotesVariables = {
      id: documentPoolId!,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [documentPoolId]);

  const [updateRowProtection, { loading: updateLoading }] = useMutation<
    DocumentPoolSecurity,
    DocumentPoolSecurityVariables
  >(DOCUMENT_ROW_PROTECTION, { errorPolicy: 'all' });

  const updateRowLevel = async (selectedLevel: string | null) => {
    const inputData =
      selectedLevel !== null
        ? {
          entityId: documentPoolData?.id!,
          rowSecurityId: selectedLevel,
        }
        : { entityId: documentPoolData?.id!, isProtectionRemoval: true };
    const { errors } = await updateRowProtection({
      variables: {
        input: inputData,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DOCUMENT_POOL_DETAILS,
          variables: {
            id: documentPoolData?.id,
          },
        },
      ],
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else
      addToast('Security/Protection level upgraded', {
        appearance: 'success',
      });
  };

  return (
    <Stack className={styles.wrapper}>
      {expandMenu ? (
        <Stack>
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={styles.centerAlign}
          >
            <Pivot
              selectedKey={selectedKey}
              onLinkClick={(item: PivotItem | undefined) => {
                setSelectedKey(item?.props.itemKey || ActionConst.protect);
              }}
              aria-label="Menu_Actions"
            >
              <PivotItem
                itemKey={ActionConst.protect}
                itemIcon={documentPoolData?._isProtected ? 'Lock' : 'Unlock'}
                headerText="Protect"
                itemCount={0}
                onRenderItemLink={PivotCustomRender}
              />

              <PivotItem
                itemKey={ActionConst.notes}
                itemIcon="Page"
                headerText="Notes"
                itemCount={
                  entityNotesData?.documentPool?.notesByEntityId.nodes.length
                }
                onRenderItemLink={PivotCustomRender}
              />

              <PivotItem
                itemKey={ActionConst.history}
                itemIcon="History"
                headerText="History"
                itemCount={
                  documentPoolData.documentPoolHistoriesByEntityId.nodes.length
                    ? documentPoolData.documentPoolHistoriesByEntityId.nodes
                      .length
                    : 0
                }
                onRenderItemLink={PivotCustomRender}
              />
            </Pivot>
            <IconButton
              onClick={() => setExpandMenu(false)}
              iconProps={{ iconName: 'ChevronUp' }}
              className={styles.iconButtonColor}
              ariaLabel="Collapse"
            />
          </Stack>
          <Stack>
            {selectedKey === ActionConst.protect ? (
              <ProtectSection
                secureRowLevels={commonData?.secureRowLevels?.nodes!}
                secureRowLevelId={
                  documentPoolData.documentPoolSecureRowLevels?.id
                }
                updateLoading={updateLoading}
                updateRowLevel={(selectedLevel) => {
                  updateRowLevel(selectedLevel);
                }}
              />
            ) : selectedKey === ActionConst.notes ? (
              <NotesDocumentPool
                data={entityNotesData?.documentPool?.notesByEntityId.nodes!}
                listRefreshRequest={getEntityNotesMemo}
                documentPoolId={documentPoolData.id}
              />
            ) : selectedKey === ActionConst.history ? (
              <HistoryActionMenuView
                moduleName="Document Pool"
                historyData={
                  documentPoolData?.documentPoolHistoriesByEntityId?.nodes || []
                }
              />
            ) : null}
          </Stack>
        </Stack>
      ) : (
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack horizontal verticalAlign="center">
            <PivotDisplay
              name="Protect"
              iconName={documentPoolData?._isProtected ? 'Lock' : 'Unlock'}
              onPivotClick={() => {
                setSelectedKey(ActionConst.protect);
                setExpandMenu(true);
              }}
            />
            <PivotDisplay
              name="Notes"
              iconName="Page"
              count={
                entityNotesData?.documentPool?.notesByEntityId.nodes.length
              }
              onPivotClick={() => {
                setSelectedKey(ActionConst.notes);
                setExpandMenu(true);
              }}
            />
            <PivotDisplay
              name="History"
              iconName="History"
              count={
                documentPoolData?.documentPoolHistoriesByEntityId?.nodes.length
              }
              onPivotClick={() => {
                setSelectedKey(ActionConst.history);
                setExpandMenu(true);
              }}
            />
          </Stack>
          <IconButton
            onClick={() => setExpandMenu(true)}
            iconProps={{ iconName: 'ChevronDown' }}
            className={styles.iconButtonColor}
            ariaLabel="Expand"
          />
        </Stack>
      )}
    </Stack>
  );
};
