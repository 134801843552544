import { makeVar, useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  ITextFieldProps,
  ITextFieldStyles,
  IconButton,
  Spinner,
  Stack,
  TextField,
  makeStyles,
  useTheme,
} from '@fluentui/react';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { TextFieldLabel } from './TextFieldLabel';
import { TravelerLookUpCallout } from './TravelerLookUpCallout';
import {
  LookupTravelerSearch,
  LookupTravelerSearchVariables,
  LookupTravelerSearch_lookupTravelerSearch_nodes,
} from './__generated__/LookupTravelerSearch';
import { TravelerCreate_travelerCreate_traveler } from 'common/graphql/__generated__/TravelerCreate';
import { TravelerAlias } from 'settings/account/Travelers/view/BasicForm/TravelerAlias';
import { Trip_trip } from '../../../__generated__/Trip';
const LOOKUP_TRAVELER_SEARCH = loader('./LookupTravelerSearch.graphql');

const useStyles = makeStyles((theme) => ({
  calloutContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  icon: {
    color: theme.palette.black,
  },
}));

interface TravelerAutoCompleteProps extends ITextFieldProps {
  calloutWidth?: number;
  showAddIcon?: boolean;
  fieldValueName: string;
  fieldValueId: string;
  travelerName: string | null | undefined;
  traveler: LookupTravelerSearch_lookupTravelerSearch_nodes | null | undefined;
  tripData: Trip_trip | null | undefined;
  onTravelerSelect: (
    traveler: LookupTravelerSearch_lookupTravelerSearch_nodes
  ) => void;
  onClear?: () => void;
}

export const setSelectedTraveler = makeVar<
  LookupTravelerSearch_lookupTravelerSearch_nodes | null | undefined
>(undefined);

export const TravelerAutoComplete: React.FC<TravelerAutoCompleteProps> = ({
  calloutWidth,
  showAddIcon,
  travelerName,
  traveler,
  tripData,
  onTravelerSelect,
  onClear,
  ...props
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: props.disabled
        ? theme.palette.neutralLighter
        : theme.palette.white,
    },
  };
  const renderRef = useRef(false);
  const [calloutVisible, setCalloutVisible] = useState(true);
  const selectedTraveler = useReactiveVar(setSelectedTraveler);
  const [disableTextfield, setDisableTextfield] = useState<
    boolean | null | undefined
  >(false);
  const { id, label, disabled, required, ...textfieldProps } = props;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: props.fieldValueId,
  });

  const {
    field: { onChange: onFieldValueChange, value: fieldValue },
  } = useController({
    name: props.fieldValueName,
  });

  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupTravelerSearch,
    LookupTravelerSearchVariables
  >(LOOKUP_TRAVELER_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const lookupCallBack = useCallback(
    (value) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
    },
    [fetchLookUp]
  );

  useEffect(() => {
    if (value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(undefined);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack]);

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
      queryCalled();
    }, 350),
    []
  );

  const renderSpinner = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disabled ? null : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              onChange(null);
              onClear?.();
              onFieldValueChange(null);
              setSelectedTraveler(null);
              setDisableTextfield(false);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.icon}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };

  useEffect(() => {
    if (fieldValue === null) {
      setDisableTextfield(false);
      setSelectedTraveler(undefined);
    }
  }, [fieldValue]);

  useEffect(() => {
    setSelectedTraveler(traveler);
    if (traveler) {
      setDisableTextfield(true);
    }
  }, [traveler]);

  const onTravelerCreate = (
    traveler: TravelerCreate_travelerCreate_traveler
  ) => {
    if (traveler) {
      onChange(traveler.id);
      onFieldValueChange(traveler._fullName);
      setSelectedTraveler(traveler);
      setDisableTextfield(true);
    }
  };

  const onTravelerDelete = () => {
    onChange(null);
    onFieldValueChange(null);
    setSelectedTraveler(null);
    setDisableTextfield(false);
  };

  return (
    <Stack>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="baseline"
      >
        <TextFieldLabel
          disabled={disabled}
          label={label}
          required={required}
          showAddIcon={showAddIcon}
          traveler={selectedTraveler || null}
          tripData={tripData}
          onTravelerCreate={onTravelerCreate}
          onTravelerDelete={onTravelerDelete}
        />
        {!!selectedTraveler?.id && (
          <TravelerAlias
            traveler={selectedTraveler}
            isTravelAuthMutation
            isProtected
            onAliasUpdate={onTravelerCreate}
          />
        )}
      </Stack>
      <TextField
        {...textfieldProps}
        id={id}
        autoComplete={'off'}
        errorMessage={error?.message}
        value={fieldValue || ''}
        onChange={(_event, value) => {
          if (value) {
            onFieldValueChange(value);
            onFieldValueChange(value);
            callbackLookUp(value, () => {
              if (calloutVisible === false) setCalloutVisible(true);
            });
          } else {
            onChange(null);
            onFieldValueChange(null);
            setSelectedTraveler(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textfieldStyles}
        disabled={disabled ? true : disableTextfield ? true : false}
      />
      <Stack className={styles.calloutContainer}>
        {calloutVisible && !loading && (
          <TravelerLookUpCallout
            lookUpCalloutWidth={400}
            onDismiss={() => setCalloutVisible(false)}
            onSelect={(value) => {
              if (value) {
                onTravelerSelect(value);
                onChange(value.id);
                onFieldValueChange(value._fullName);
                setSelectedTraveler(value);
                setDisableTextfield(true);
              }
            }}
            targetID={`#${id!}`}
            data={data?.lookupTravelerSearch?.nodes!}
          />
        )}
      </Stack>
    </Stack>
  );
};
