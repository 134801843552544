import { useMutation } from '@apollo/client';
import { DefaultButton, Modal, PrimaryButton, ProgressIndicator, Stack, Text } from '@fluentui/react';
import { CloseButton } from 'common/components/Buttons';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { useCommonStyles } from 'common/styles';
import { CompanyCorporateYearInput, CompanyCorporateYearPatch, CompanyCorporateYearsOrderBy, CompanyCorporateYearUpdateInput } from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { CompanyCorporateYearType } from '../../list';
import { CompanyCorporateYears, CompanyCorporateYearsVariables } from '../../list/__generated__/CompanyCorporateYears';
import { ACCOUNTING_PERIOD_VALUES } from '../constants';
import { AccountingPeriodValues } from '../types';
import { CompanyCorporateYearCreate, CompanyCorporateYearCreateVariables } from '../__generated__/CompanyCorporateYearCreate';
import { CompanyCorporateYearUpdate, CompanyCorporateYearUpdateVariables } from '../__generated__/CompanyCorporateYearUpdate';
import { validationSchema } from './companyCorporateYearValidation';
import { useStyles } from './index.styles';
import { YearBasicForm } from './YearBasicForm';
const COMPANY_CORPORATE_YEAR_CREATE = loader("../CompanyCorporateYearCreate.graphql")
const COMPANY_CORPORATE_YEARS = loader("../../list/CompanyCorporateYears.graphql")
const COMPANY_CORPORATE_YEAR = loader("../CompanyCorporateYear.graphql")
const COMPANY_CORPORATE_YEAR_UPDATE = loader("../CompanyCorporateYearUpdate.graphql");
interface AccountingYearViewProps {
  corporateYear: CompanyCorporateYearType | null | undefined;
  visible: boolean;
  onDismiss: () => void;
  isNew: boolean;
}
export const AccountingYearView: React.FC<AccountingYearViewProps> = ({
  corporateYear,
  visible,
  onDismiss,
  isNew
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { addToast } = useToasts();
  const headerTitle = isNew ? 'Create New Year' : 'Update Year';

  let initialValues: AccountingPeriodValues = ACCOUNTING_PERIOD_VALUES;
  if (corporateYear && !isNew) {
    initialValues = {
      id: corporateYear.id,
      _rowTimestamp: corporateYear._rowTimestamp,
      fiscalYear: corporateYear.fiscalYear,
      startDate: corporateYear.startDate ? dateFormat(dateConvertions(corporateYear.startDate)) : null,
      endDate: corporateYear.endDate ? dateFormat(dateConvertions(corporateYear.endDate)) : null,
      isYearOpen: corporateYear.isYearOpen,
      isEntryAllowed: corporateYear.isEntryAllowed,
      companyCorporatePeriodsByFiscalYearId: []
    }
  }

  const [createCompanyCorporateYear, { loading: createCompanyCorporateYearLoading }] = useMutation<
    CompanyCorporateYearCreate,
    CompanyCorporateYearCreateVariables
  >(COMPANY_CORPORATE_YEAR_CREATE, { errorPolicy: 'all' });
  const [companyCorporateYearUpdate, { loading: companyCorporateYearUpdateLoading }] = useMutation<
    CompanyCorporateYearUpdate,
    CompanyCorporateYearUpdateVariables
  >(COMPANY_CORPORATE_YEAR_UPDATE, { errorPolicy: 'all' });

  const handleSubmit = async (values: AccountingPeriodValues) => {
    if (isNew) {
      const { companyCorporatePeriodsByFiscalYearId, fiscalYear, startDate, endDate, id, _rowTimestamp, ...restAccountingYearFields } = values;
      const { errors } = await createCompanyCorporateYear({
        variables: {
          input: {
            companyCorporateYear: {
              fiscalYear: fiscalYear ? parseInt(fiscalYear.toString()) : null,
              startDate: startDate ? dateFormat(startDate) : null,
              endDate: endDate ? dateFormat(endDate) : null,
              ...restAccountingYearFields
            } as CompanyCorporateYearInput
          }
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<CompanyCorporateYears, CompanyCorporateYearsVariables>({
            query: COMPANY_CORPORATE_YEARS,
            variables: {
              orderBy: [CompanyCorporateYearsOrderBy.FISCAL_YEAR_ASC]
            }
          });
          if (cacheData && data?.companyCorporateYearCreate?.companyCorporateYear) {
            const updatedData: CompanyCorporateYears = {
              companyCorporateYears: {
                ...cacheData.companyCorporateYears!,
                nodes: [
                  data?.companyCorporateYearCreate?.companyCorporateYear!,
                  ...cacheData.companyCorporateYears?.nodes!
                ]
              }
            }
            cache.writeQuery<CompanyCorporateYears>({
              query: COMPANY_CORPORATE_YEARS,
              variables: {
                orderBy: [CompanyCorporateYearsOrderBy.FISCAL_YEAR_ASC]
              },
              data: updatedData,
            });
          }
        }
      });
      if (!errors) {
        addToast('Year Created Successfully', {
          appearance: 'success',
        });
        onDismiss();
      }
      else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    } else {
      const { companyCorporatePeriodsByFiscalYearId, ...restCompanyCorporateYearFields } = values
      const companyCorporateYearPatch: CompanyCorporateYearPatch = Object.entries(restCompanyCorporateYearFields).reduce(
        (res, [key, val]) => {
          if (val !== initialValues[key as keyof AccountingPeriodValues]) {
            return { ...res, [key]: val };
          }
          return res;
        },
        {}
      );
      const { errors } = await companyCorporateYearUpdate({
        variables: {
          input: {
            id: corporateYear?.id!,
            rowTimestamp: corporateYear?._rowTimestamp!,
            companyCorporateYearPatch: companyCorporateYearPatch,
          } as CompanyCorporateYearUpdateInput
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: COMPANY_CORPORATE_YEAR,
            variables: {
              id: corporateYear?.id!,
            },
          },
        ],
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
        onDismiss();
      } else {
        addToast('Year Edited Successfully', {
          appearance: 'success',
        });
      }
    }
  }

  const loading = createCompanyCorporateYearLoading || companyCorporateYearUpdateLoading;

  return (
    <>
      <Formik<AccountingPeriodValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, resetForm, dirty, errors }) => {
          const onClose = () => {
            resetForm();
            onDismiss();
          };
          const onSubmit = async () => {
            await submitForm();
          };
          return (
            <Form>
              <Modal isOpen={visible} isBlocking={true} onDismiss={onDismiss}>
                <Stack className={styles.container}>
                  <Stack
                    horizontal
                    horizontalAlign={'space-between'}
                    className={styles.dialogStyles}
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <Text
                        variant={'xLarge'}
                        className={commonStyles.colorThemePrimary}
                      >{headerTitle}
                      </Text>
                      <UnsavedIndicator
                        visible={!isNew && dirty && !isSubmitting}
                      />
                    </Stack>
                    <CloseButton onClick={onClose} />
                  </Stack>
                  <YearBasicForm
                    isNew={isNew}
                  />
                  {loading && <ProgressIndicator />}
                  <Stack
                    horizontalAlign="space-between"
                    className={styles.buttonContainer}
                    horizontal
                    tokens={{ childrenGap: 6, padding: 25 }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <PrimaryButton
                        disabled={!dirty || Object.keys(errors).length > 0}
                        text={headerTitle}
                        onClick={onSubmit}
                      />
                      <DefaultButton onClick={onClose} text="Cancel" />
                    </Stack>
                  </Stack>
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  )
}
