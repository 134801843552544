import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  header: {
    alignItems: 'self-start',
    padding: '15px 20px 15px',
    cursor: 'move',
    backgroundColor: theme.palette.neutralLighter,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  requestComment: {
    marginTop: 10,
  },
  container: {
    width: 850,
    paddingTop: 20,
    overflowY: 'scroll',
    minHeight: '50vh',
    maxHeight: '70vh',
  },
  pB50: {
    paddingBottom: 50,
  },
}));
