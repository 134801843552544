import { ReactComponent as AccommodationIcon } from 'assets/svgs/TripIcons/accomodationIcon.svg';
import { ReactComponent as AccommodationOutlineIcon } from 'assets/svgs/TripIcons/accomodationIconOutline.svg';
import { ReactComponent as VehicleRentalIcon } from 'assets/svgs/TripIcons/driveIcon.svg';
import { ReactComponent as VehicleRentalOutlineIcon } from 'assets/svgs/TripIcons/driveIconOutline.svg';
import { ReactComponent as FlightIcon } from 'assets/svgs/TripIcons/flightIcon.svg';
import { ReactComponent as FlightOutlineIcon } from 'assets/svgs/TripIcons/flightIconOutline.svg';
import { ReactComponent as GroundTransportationIcon } from 'assets/svgs/TripIcons/groundTransportIcon.svg';
import { ReactComponent as GroundTransportationOutlineIcon } from 'assets/svgs/TripIcons/groundTransportIconOutline.svg';
import { ReactComponent as OtherChargesIcon } from 'assets/svgs/TripIcons/otherChargesIcon.svg';
import { ReactComponent as OtherChargesOutlineIcon } from 'assets/svgs/TripIcons/otherChargesIconOutline.svg';
import { ReactComponent as RailIcon } from 'assets/svgs/TripIcons/railIcon.svg';
import { ReactComponent as RailOutlineIcon } from 'assets/svgs/TripIcons/railIconOutline.svg';
import { TransactionLayout } from 'common/types/globalTypes';
import React from 'react';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  TRIP_INITIAL_VALUES,
  STOP_MODAL_INITIAL_VALUES,
} from '../../constants';
import { TripItemFormValues } from '../../types';
import { LookupTravelerSearch_lookupTravelerSearch_nodes } from '../BasicForm/TravelerAutoComplete/__generated__/LookupTravelerSearch';

export const useTravelIcon = () => {
  const getIcon = (layoutType: TransactionLayout | null) => {
    switch (layoutType) {
      case TransactionLayout.TRAVEL_BOOKING_GROUND_TRANS:
        return <GroundTransportationIcon />;
      case TransactionLayout.TRAVEL_BOOKING_VEHICLE_RENTAL:
        return <VehicleRentalIcon />;
      case TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL:
        return <FlightIcon />;
      case TransactionLayout.TRAVEL_BOOKING_TRAIN_TRAVEL:
        return <RailIcon />;
      case TransactionLayout.TRAVEL_BOOKING_HOTEL:
        return <AccommodationIcon />;
      case TransactionLayout.TRAVEL_BOOKING_INCIDENTAL:
        return <OtherChargesIcon />;
    }
  };

  const getOutlineIcon = (layoutType: TransactionLayout | null) => {
    switch (layoutType) {
      case TransactionLayout.TRAVEL_BOOKING_HOTEL:
        return <AccommodationOutlineIcon />;
      case TransactionLayout.TRAVEL_BOOKING_VEHICLE_RENTAL:
        return <VehicleRentalOutlineIcon />;
      case TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL:
        return <FlightOutlineIcon />;
      case TransactionLayout.TRAVEL_BOOKING_GROUND_TRANS:
        return <GroundTransportationOutlineIcon />;
      case TransactionLayout.TRAVEL_BOOKING_INCIDENTAL:
        return <OtherChargesOutlineIcon />;
      case TransactionLayout.TRAVEL_BOOKING_TRAIN_TRAVEL:
        return <RailOutlineIcon />;
    }
  };

  return { getIcon, getOutlineIcon };
};

export const getInitialValues = (
  layout: TransactionLayout | null,
  selectedTraveler:
    | LookupTravelerSearch_lookupTravelerSearch_nodes
    | null
    | undefined,
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined,
  tripItem?: TripItemFormValues[]
) => {
  const { bookingFee } = { ...travelAuthorizationData };

  const airTravelDetails = tripItem?.find(
    (item) => item.layout === TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL
  );
  const accommodationDetails = tripItem?.find(
    (item) => item.layout === TransactionLayout.TRAVEL_BOOKING_HOTEL
  );

  if (layout === TransactionLayout.TRAVEL_BOOKING_GROUND_TRANS) {
    const {
      departureDate,
      departureDepotName,
      arrivalDepotName,
      departureArrivalDate,
      returnDate,
      returnArrivalDate,
      returnArrivalDepotName,
      returnDepotName,
    } = { ...airTravelDetails };

    const { rentalName, returnAddress1, rentalCityName } = {
      ...accommodationDetails,
    };

    const rentalNameValue = rentalName ? rentalName : '';
    const returnAddress1Value = returnAddress1 ? returnAddress1 : '';
    const rentalCityNameValue = rentalCityName ? rentalCityName : '';
    const arrivalName = `${rentalNameValue} ${returnAddress1Value} ${rentalCityNameValue}`;

    const groundTransportationDefaultValues: TripItemFormValues = {
      ...TRIP_INITIAL_VALUES,
      pickupDropName: departureDepotName || null,
      departureDate: departureDate || null,
      departureArrivalDate: departureArrivalDate || null,
      pickupArrivalName: arrivalDepotName || null,
      returnDate: returnDate || null,
      returnDropName: returnDepotName || null,
      returnArrivalDate: returnArrivalDate || null,
      returnArrivalName: returnArrivalDepotName || null,
      pickupArrivalDropName: arrivalName || null,
      returnName: arrivalName || null,
      pickupName: selectedTraveler?._pickupAddress || null,
      returnArrivalDropName: selectedTraveler?._pickupAddress || null,
    };
    return groundTransportationDefaultValues;
  } else if (layout === TransactionLayout.TRAVEL_BOOKING_INCIDENTAL) {
    const newValues = {
      ...TRIP_INITIAL_VALUES,
      bookingFeeRate: bookingFee,
    } as TripItemFormValues;
    return newValues;
  } else if (layout === TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL) {
    const newValues = {
      ...TRIP_INITIAL_VALUES,
      stopModalItems: [
        {
          ...STOP_MODAL_INITIAL_VALUES,
          // arriveDate: isDepart ? departureDate : returnDate,
          // departDate: isDepart ? departureDate : returnDate,
        },
      ],
      stopModalReturnItems: [
        {
          ...STOP_MODAL_INITIAL_VALUES,
          // arriveDate: isDepart ? departureDate : returnDate,
          // departDate: isDepart ? departureDate : returnDate,
        },
      ],
      bookingFeeRate: bookingFee,
    } as TripItemFormValues;
    return newValues;
  } else {
    return TRIP_INITIAL_VALUES;
  }
};
