import {
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,Separator,
} from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles'; 

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.line, height: 120, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection2 = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.line, height: 30, width: '20%' },
          { type: ShimmerElementType.gap, height: 40, width: '3%' },
          { type: ShimmerElementType.line, height: 30, width: '20%' },
          { type: ShimmerElementType.gap, height: 40, width: '3%' },
          { type: ShimmerElementType.line, height: 30, width: '20%' },
          { type: ShimmerElementType.gap, height: 40, width: '3%' },
          { type: ShimmerElementType.line, height: 30, width: '20%' },
          { type: ShimmerElementType.gap, height: 40, width: '3%' },
          { type: ShimmerElementType.line, height: 30, width: '20%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection3 = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '50%' },
          { type: ShimmerElementType.gap, height: 40, width: 20 },
          { type: ShimmerElementType.line, height: 40, width: '50%' },
        ]}
      />
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '50%' },
          { type: ShimmerElementType.gap, height: 40, width: 20 },
          { type: ShimmerElementType.line, height: 40, width: '50%' },
        ]}
      />
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width='100%'
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '50%' },
          { type: ShimmerElementType.gap, height: 40, width: 20 },
          { type: ShimmerElementType.line, height: 40, width: '50%' },
        ]}
      />
    </div>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Stack className={styles.containerpadding}>
        <Shimmer customElementsGroup={ShimmerSection()} />
      </Stack>

      <Separator />

      <Stack className={styles.containerpadding}>
        <Shimmer customElementsGroup={ShimmerSection2()} />
      </Stack>

      <Separator />

      <Stack className={styles.containerpadding}>
        <Shimmer customElementsGroup={ShimmerSection3()} />
      </Stack>
    </React.Fragment>
  );
};
