import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    position: 'relative',
    flexShrink: 0,
    verticalAlign: 'middle',
  },
  badge: {
    height: 8,
    zIndex: 1,
    position: 'absolute',
    width: 8,
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 4,
    top: 0,
    right: 0,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    backgroundColor: theme.palette.orangeLighter,
  },
  primaryBadge: {
    zIndex: 1,
    position: 'absolute',
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    top: 0,
    right: 0,
    width: 20,
    height: 20,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.themePrimary,
  },
  customBadgeStyle: {
    marginLeft: 10,
    marginBottom: 15,
  },
  countText: { color: 'white' },
}));
