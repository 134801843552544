import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  modalInsideStack: {
    width: 1100,
    height: 600,
  },
  stickyInsideStack: {
    alignItems: 'self-start',
    padding: 25,
  },
  stack: {
    padding: 25,
  },
  paddingL25: {
    paddingLeft: 25,
  },
  columnHeight: {
    height: 25,
  },
  disabledText: {
    color: `${theme.palette.neutralTertiary}${theme.isInverted ? '55' : ''}`,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
}));
