import { Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { ToggleButton } from '../Buttons/ToggleButton';

/**
 * MultiSelect option single structure
 */
export interface MultiSelectOption {
  key: string;
  text: string;
}

/**
 * @param options Collection of options for this MultiSelectTags
 * @param type Determine whether tags will be multi selectable or single
 */
interface MultiSelectTagsProps {
  /** @param options Collection of options for this MultiSelectTags */
  options: MultiSelectOption[];
  /** @param type Determine whether tags will be multi selectable or single */
  multiSelect?: boolean;
  /** @param onSelectionChange callback received when any changes in tag selection */
  onSelectionChange?: (selectedTags: MultiSelectOption[]) => void;
}

export const MultiSelectTags: React.FC<MultiSelectTagsProps> = ({
  options,
  multiSelect = true,
  onSelectionChange,
}) => {
  const [selectedOptions, setselectedOptions] = useState<MultiSelectOption[]>(
    []
  );

  const isSelected = (option: MultiSelectOption) =>
    selectedOptions.some((item) => item.key === option.key);

  /** function used to handle on tag click, capable of handling single or multiselect */
  const onTagPressed = (option: MultiSelectOption) => {
    let levelsSelected = [...selectedOptions];
    let position = levelsSelected.findIndex((item) => item.key === option.key);

    if (position === -1) {
      if (multiSelect) levelsSelected.push(option);
      else levelsSelected.splice(position, 1, option);
    } else levelsSelected.splice(position, 1);
    onSelectionChange?.(levelsSelected);
    setselectedOptions(levelsSelected);
  };

  return (
    <Stack wrap horizontal tokens={{ childrenGap: 10 }}>
      {options?.map((item) => {
        return (
          <ToggleButton
            onClick={() => onTagPressed(item)}
            toggled={isSelected(item)}
            key={item.key}
            text={item.text}
            iconName={isSelected(item) ? 'Accept' : ''}
          />
        );
      })}
    </Stack>
  );
};
