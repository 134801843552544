import React from 'react';
import { useTheme } from '@fluentui/react';
import { Text, View } from '@react-pdf/renderer';
import { PdfDocumentProps } from '..';
import { BasicForm } from '../BasicForm';
import { getStyles } from './index.stylesheet';

export const Transaction: React.FC<PdfDocumentProps> = ({
  invoiceData,
  transactionTypeData,
}) => {
  const { transactionType, additionalStatusType, additionalInformationStatus } =
    {
      ...invoiceData?.invoice,
    };

  const { palette } = useTheme();
  const styles = getStyles(palette);

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text>Transaction</Text>
          <Text style={styles.transactionType}>
            {transactionType?.transactionType}
          </Text>
        </View>
      </View>

      <View>
        <Text style={styles.colorThemeRed}>
          {additionalStatusType?.statusType}
        </Text>

        {additionalInformationStatus && (
          <Text style={styles.colorThemeRed}>
            {additionalStatusType?.statusType ? ', ' : ''}
            {additionalInformationStatus}
          </Text>
        )}
      </View>
      <View
        style={{
          width: '100%',
          height: 1,
          backgroundColor: palette.neutralLight,
        }}
      />
      <BasicForm data={invoiceData} transactionTypeData={transactionTypeData} />
    </View>
  );
};
