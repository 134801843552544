import { FontIcon, Stack } from '@fluentui/react';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import { FontStyleProps } from '../fonts';
import { useStyles } from './index.styles';
import trimCanvas from 'trim-canvas';

type FontOptionView = FontStyleProps & {
  name: string;
  penColor: string;
  isSelected: boolean;
  index: number;
  GetSelection?: boolean;
  selectedData?: { data: string | undefined; index: number | undefined };
  onSelectStyle?: (imageUrl: string) => void;
};

export const FontOptionView: React.FC<FontOptionView> = ({
  fontStyle,
  name,
  onSelectStyle,
  selectedData,
  isSelected,
  penColor,
  index,
  GetSelection,
}) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const styles = useStyles();

  const onClick = async () => {
    const dataURL = trimCanvas(canvas.current)?.toDataURL('image/png');
    onSelectStyle?.(dataURL!);
  };
  const onClickUseCallback = useCallback(onClick, []);

  useEffect(() => {
    if (GetSelection && selectedData?.index === index) {
      onClickUseCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetSelection]);

  const canvasCreate = useCallback(() => {
    if (canvas) {
      const getFontSizeToFit = (
        text: string,
        fontFace: string,
        maxWidth: number
      ) => {
        const ctx = document.createElement('canvas').getContext('2d');
        ctx!.font = `0.5px ${fontFace}`;
        return maxWidth / ctx!.measureText(text).width;
      };

      const ctx = canvas?.current?.getContext('2d');
      ctx!.fillStyle = 'transparent';
      ctx?.fillRect(0, 0, 250, 115);

      const fontSizeNumber = getFontSizeToFit(name, fontStyle, 125);

      setTimeout(() => {
        ctx!.font = `${Math.min(fontSizeNumber, 40)}px ${fontStyle}`;
        ctx!.fillStyle = penColor;
        ctx!.textAlign = 'center';
        ctx!.fillText(name, 125, 70);
      }, 100);
    }
  }, [canvas, penColor, name, fontStyle]);

  useEffect(() => {
    canvasCreate();
  }, [canvasCreate]);

  return (
    <Stack>
      <button
        onClick={onClick}
        className={clsx(
          styles.container,
          isSelected ? styles.selectedOption : ''
        )}
      >
        {isSelected && (
          <Stack className={styles.acceptIconConatiner}>
            <FontIcon iconName="accept" className={styles.acceptIconStyles} />
          </Stack>
        )}
        <canvas ref={canvas} width={250} height={115} />
      </button>
    </Stack>
  );
};
