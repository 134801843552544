import { makeVar } from '@apollo/client';
import { ApprovalActionTypeInput } from 'common/types/globalTypes';
import { ApprovalHistorySearch_approvalHistorySearch_nodes } from './ApprovalsAllUsers/__generated__/ApprovalHistorySearch';
import { UserApprovalSearch_userApprovalSearch_nodes } from './MyApprovals/__generated__/UserApprovalSearch';

// UserApprovalSearch_userApprovalSearch_nodes
export const setApprovalNodes = makeVar<
  UserApprovalSearch_userApprovalSearch_nodes[]
>([]);

export const setApprovalHistoryNodes = makeVar<
  ApprovalHistorySearch_approvalHistorySearch_nodes[]
>([]);

export const setLastApprovalAction = makeVar<
  ApprovalActionTypeInput | undefined
>(undefined);

export const setNextApproval = makeVar<boolean>(false);
