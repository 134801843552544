import { CorporateWorkgroupValues } from './types';

export const CORPORATE_WORKGROUP_VALUES: CorporateWorkgroupValues = {
  workgroupNumber: null,
  name: null,
  description: null,
  businessUnitId: null,
  chartOfAccountsId: null,
  productionPhaseId: null,
  isNewWorkgroupSelectable: true,
  prefixCode: null,
  productionStatusTypeId: null,
};
