import React, { useState } from 'react';
import { DepartmentGroupsViewModal } from './DepartmentGroupsViewModal';
import { PrimaryButton } from '@fluentui/react';

export const DepartmentGroupsView = () => {
  const [visible, setVisible] = useState(false);

  const _onClick = () => {
    setVisible(true);
  };

  return (
    <>
      <PrimaryButton
        iconProps={{
          iconName: 'Add',
        }}
        text="New Group"
        onClick={_onClick}
      />
      <DepartmentGroupsViewModal
        isOpen={visible}
        isEdit={false}
        onClose={() => setVisible(false)}
      />
    </>
  );
};
