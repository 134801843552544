import { NetworkStatus, useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  IColumn,
  IDetailsRowBaseProps,
  Icon,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { FilterArrayType } from 'common/components/Filters';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  PayCycleFilter,
  PayCyclesOrderBy,
  SearchRequestInput,
} from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PaymentAccountingSearchBar } from './PaymentAccountingSearchBar';
import {
  payCyclesAccEntries,
  payCyclesAccEntriesVariables,
  payCyclesAccEntries_payCycleSearch_nodes,
} from './__generated__/payCyclesAccEntries';
import { useStyles } from './index.styles';

import {
  payCycleSearchHints,
  payCycleSearchHintsVariables,
} from 'ap/paymentCycle/list/__generated__/payCycleSearchHints';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  MultiSelectOption,
  MultiSelectTags,
} from 'common/components/MultiSelectTags';
import { StatusIcon } from 'common/components/StatusIcon';
import { getColumn, getSortedColumns } from 'common/utils/columnUtilities';
import { convertToTitleCase } from 'common/utils/convertToTitleCase';
import { setGlobalSearchText } from 'utility/cache/ui';
import { toOrderByVariable, toPayCycleAccFilterVariable } from './utils';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import clsx from 'clsx';
import { useColumns } from './column.data';
const PAY_ACC_CYCLES = loader('./payCyclesAccEntries.graphql');
const PAY_CYCLES_HINTS = loader('../../list/PayCycleSearchHints.graphql');
export interface PayCycleAccItem
  extends payCyclesAccEntries_payCycleSearch_nodes {
  key: string;
  _paymentAccount: string;
  _paymentName: string;
  currencyIsoCode: string;
  status: string;
  _paymentType: string;
}

export const PayCycleAccEntryList: React.FC = () => {
  let payCyclesList: PayCycleAccItem[] = [];
  const styles = useStyles();
  const renderRef = useRef(false);
  const commonStyles = useCommonStyles();
  const { columns } = useColumns();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [isHistorySearch, setHistorySearch] = useState(false);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [selectedHint, setSelectedHint] = useState<number | null>();
  const [hintsVisibility, setHintsVisibility] = useState<boolean>(false);
  const globalSearchText = useReactiveVar(setGlobalSearchText);

  const [
    fetchPayCycles,
    {
      loading: payCyclesLoading,
      data: payCyclesData,
      networkStatus,

      fetchMore,
    },
  ] = useLazyQuery<payCyclesAccEntries, payCyclesAccEntriesVariables>(
    PAY_ACC_CYCLES,
    {
      variables: {
        isSigningSearch: false,
        isHistorySearch: false,
        first: TABLE_ROWS,
        orderBy: [PayCyclesOrderBy.PRIMARY_KEY_ASC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [fetchPayCyclesHints, { data: payCyclesHintsData }] = useLazyQuery<
    payCycleSearchHints,
    payCycleSearchHintsVariables
  >(PAY_CYCLES_HINTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetching =
    payCyclesLoading && networkStatus !== NetworkStatus.fetchMore;

  const transformedData = refetching
    ? undefined
    : payCyclesData?.payCycleSearch?.nodes.map(
        (payCycle) =>
          ({
            ...payCycle,
            key: payCycle.id,
            _paymentType: payCycle.transactionType?.transactionType,
            _paymentAccount: payCycle.paymentAccount?.bankAccountNumber,
            _paymentName: payCycle.paymentAccount?.name,
            currencyIsoCode: payCycle.currency?.isoCode,
            status: payCycle.statusType?.statusType,
            defaultPaymentDate: payCycle.defaultPaymentDate
              ? dateFormat(dateConvertions(payCycle.defaultPaymentDate))
              : null,
          } as PayCycleAccItem)
      );
  if (transformedData) payCyclesList = transformedData;

  const _renderItemColumn = (
    item: PayCycleAccItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof PayCycleAccItem
      ] as string;
      switch (column?.key) {
        case 'description':
          return (
            <Stack
              className={styles.statusColumnContainer}
              tokens={{ padding: '0px 0px 0px 20px' }}
              verticalAlign="center"
            >
              <HighLightActiveLink
                to={`/pay/payment_cycle/accounting/${item.id}`}
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );
        case '_accountingStampDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <Text className={commonStyles.contentColumnAlignRight}>
                {fieldContent}
              </Text>
            </Stack>
          );

        case 'defaultPaymentDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <HighlightTextView
                className={commonStyles.contentColumnAlignRight}
                highlightText={globalSearchText}
                text={fieldContent ? dateFormat(fieldContent) : ''}
              />
            </Stack>
          );
        case '_paymentAccount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={`${item._paymentName}  ${
                  item._paymentAccount ? `( ${item._paymentAccount})` : ''
                }`}
              />
            </Stack>
          );
        case 'controlTotalAmount':
        case '_totalPayments':
          return (
            <AmountColumnTextView
              value={fieldContent}
              searchText={globalSearchText}
            />
          );
        case '_paymentCount':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                  className={styles.paymentCount}
                />
              )}
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
              horizontal
              horizontalAlign="space-between"
            >
              <Text className={styles.statusType}>
                {convertToTitleCase(item?.status!)}
              </Text>
              <Stack verticalAlign="center" className={styles.flexEnd}>
                {item._isAccountingEntryStampedComplete ? (
                  <TooltipHost
                    content={item._accountingStampTransactionReference || ''}
                  >
                    <Icon className={styles.logo} iconName="Stamp" />
                  </TooltipHost>
                ) : (
                  <StatusIcon
                    approval={false}
                    iconType={item.statusType!}
                    approvalData={item!}
                  />
                )}
              </Stack>
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.statusColumnContainer}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      switch (column?.key) {
        case 'controlTotalAmount':
          return (
            <AmountTextView
              variant="medium"
              className={clsx(
                styles.amountStack,
                styles.contentColumnAlignRight
              )}
              value={
                payCyclesData?.payCycleSearch?.aggregates?.sum
                  ?.controlTotalAmount || ''
              }
            />
          );
        case '_paymentCount':
          return (
            <AmountTextView
              variant="medium"
              className={clsx(
                styles.amountStack,
                styles.contentColumnAlignRight
              )}
              value={
                payCyclesData?.payCycleSearch?.aggregates?.sum?._paymentCount ||
                ''
              }
            />
          );
        case '_totalPayments':
          return (
            <AmountTextView
              variant="medium"
              className={clsx(
                styles.amountStack,
                styles.contentColumnAlignRight
              )}
              value={
                payCyclesData?.payCycleSearch?.aggregates?.sum
                  ?._totalPayments || ''
              }
            />
          );
        default:
          return undefined;
      }
    };
  const handleSearch = (showMore: boolean, newHints: boolean) => {
    const searchRequest: SearchRequestInput | undefined = searchValue
      ? {
          searchText: [searchValue ? searchValue : ''],
          searchHintId: selectedHint,
        }
      : undefined;

    const searchHintsRequest: SearchRequestInput = {
      searchText: [searchValue ? searchValue : ''],
      searchHintId: null,
    };

    const variables: payCyclesAccEntriesVariables = {
      searchRequest: searchRequest,
      first: TABLE_ROWS,
      isSigningSearch: false,
      isHistorySearch: isHistorySearch,
      after: showMore
        ? payCyclesData?.payCycleSearch?.pageInfo.endCursor
        : undefined,
      filter: searchFilters?.length
        ? ({
            and: toPayCycleAccFilterVariable(searchFilters),
          } as PayCycleFilter)
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam!),
    };

    const variableHints: payCycleSearchHintsVariables = {
      SearchRequestInput: searchHintsRequest,
      isHistorySearch: isHistorySearch,
      isSigningSearch: false,
      isLinkSearch: false,
    };

    if (showMore) fetchMore?.({ variables });
    else fetchPayCycles({ variables: variables });
    if (newHints && searchRequest && !selectedHint) {
      setSelectedHint(null);
      fetchPayCyclesHints({ variables: variableHints });
    }
  };

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };
  const getNewColumns = () => {
    if (sortOrderParam?.column !== '' && sortOrderParam) {
      const columnData = getColumn(sortOrderParam?.column!, gridColumns);
      const sortedColumns = getSortedColumns(columnData, gridColumns);
      return sortedColumns;
    } else return undefined;
  };
  const getNewColumnsMemo = useCallback(getNewColumns, []);
  useEffect(() => {
    const sortedColumns = getNewColumnsMemo();
    if (sortedColumns && renderRef.current)
      setGridColumns(sortedColumns.newColumns);
  }, [getNewColumnsMemo]);
  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchValue,
    selectedHint,
    isHistorySearch,
  ]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, true);
  }, [searchFilters, sortOrderParam, isHistorySearch, handleSearchMemo]);

  useEffect(() => {
    handleSearchMemo(false, true);
    renderRef.current = true;
  }, [searchValue, handleSearchMemo]);

  useEffect(() => {
    if (renderRef.current) handleSearchMemo(false, false);
  }, [selectedHint, handleSearchMemo]);

  const searchHintsOptions: MultiSelectOption[] =
    payCyclesHintsData?.payCycleSearchHints?.nodes.map(
      (hint) =>
        ({
          key: hint.id?.toString(),
          text: `${hint.caption} (${hint.resultsCount})`,
        } as MultiSelectOption)
    ) || [];

  const HINTS_AVAILABLE =
    searchHintsOptions.length > 0 && searchValue?.length! > 0;

  const showFooter =
    !refetching &&
    payCyclesData?.payCycleSearch?.totalCount! > 0 &&
    parseFloat(
      payCyclesData?.payCycleSearch?.aggregates?.sum?.controlTotalAmount!
    ) > 0;

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">
            {`Pay Cycles `}
            <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
              Accounting Entry
            </Text>
          </Text>
        </Stack>
      </Stack>

      <Stack tokens={{ padding: '0px 25px', childrenGap: 20 }}>
        <PaymentAccountingSearchBar
          toggleActions={() => {}}
          hintsAvailable={HINTS_AVAILABLE}
          columns={gridColumns}
          onEnterPress={(value) => {
            setSearchValue(value);
            setSelectedHint(null);
            setHintsVisibility(false);
            setGlobalSearchText(value);
          }}
          onRefresh={() => handleSearch(false, false)}
          onFilterChange={(filters) => {
            setSearchFilters(filters);
          }}
          onToggleVisibility={(columns) => {
            setGridColumns(columns);
          }}
          onHistoryCheckbox={() => setHistorySearch((prevState) => !prevState)}
          onHintsViewToggle={() =>
            setHintsVisibility((prevState) => !prevState)
          }
        />
        {hintsVisibility && HINTS_AVAILABLE && (
          <MultiSelectTags
            multiSelect={false}
            options={searchHintsOptions}
            onSelectionChange={(selectedTags) => {
              const hints = selectedTags.map((item) => parseInt(item.key));
              setSelectedHint(hints[0]);
            }}
          />
        )}
      </Stack>

      <InfiniteList<PayCycleAccItem>
        items={payCyclesList}
        loading={payCyclesLoading}
        totalRowsCount={payCyclesData?.payCycleSearch?.totalCount}
        hasNextPage={payCyclesData?.payCycleSearch?.pageInfo.hasNextPage}
        showFooter={showFooter}
        columns={gridColumns.filter((column) => column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        selectionMode={SelectionMode.none}
        onLoadMore={async () => await handleSearch(true, false)}
      />
    </>
  );
};
