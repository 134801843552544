import { CommandBarButton, Stack, useTheme } from '@fluentui/react';

import clsx from 'clsx';
import { TransactionLayout } from 'common/types/globalTypes';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { TravelerAuthorizationCommonData } from 'travelAuthorization/TravelPlan/view/FormView/__generated__/TravelerAuthorizationCommonData';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { SectionProps } from '../..';
import { Trip_trip } from '../../__generated__/Trip';
import { TripCreateFormValues } from '../../types';
import { Accommodation } from './Layouts/Accommodation';
import { Flight } from './Layouts/Flight';
import { GroundTransport } from './Layouts/GroundTransport';
import { OtherCharges } from './Layouts/OtherCharges';
import { Train } from './Layouts/Train';
import { VehicleRental } from './Layouts/VehicleRental';
import { Accounting } from './Layouts/components/Accounting';
import { SuppliersSection } from './Layouts/components/SuppliersSection';
import { TripTab } from './SectionTab';
import { useStyles } from './index.styles';
import { PreferredArea } from './Layouts/components/PreferredArea';
import { useReactiveVar } from '@apollo/client';
import { setUserDefaults } from 'utility/cache/ui';

export const MiniTrips: React.FC<{
  layoutAdded: string | null;
  tripItemsState: Map<string, SectionProps>;
  inputsDisabled: boolean;
  onTripItemsUpdate: (
    mapData: Map<string, SectionProps>,
    layoutAdded: string,
    adding: boolean
  ) => void;
  commonData: TravelerAuthorizationCommonData | undefined;
  tripData: Trip_trip | null | undefined;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
  businessUnitId: string | null;
}> = ({
  tripItemsState,
  layoutAdded,
  onTripItemsUpdate,
  tripData,
  travelAuthorizationData,
  inputsDisabled,
  businessUnitId,
}) => {
  const theme = useTheme();
  const userDefaults = useReactiveVar(setUserDefaults);
  const [accountingVisibility, setAccountingVisibility] = useState(
    userDefaults?.isAccountingAreaExpanded || false
  );
  const watch = useWatch<TripCreateFormValues>();
  const { remove } = useFieldArray<TripCreateFormValues>({
    name: 'tripItems',
  });

  const tripItemsMemo = useMemo(() => {
    const arr = Array.from(tripItemsState.entries(), ([, value]) => value);
    return arr;
  }, [tripItemsState]);

  const styles = useStyles();
  const [selectedLayout, setSelectedLayout] = useState<string | null>();
  const sectionRef = useRef<HTMLDivElement>(null);
  const { _isTripPreferredAreaAllowed } = { ...userDefaults };

  useEffect(() => {
    if (layoutAdded !== '') setSelectedLayout(layoutAdded);
  }, [layoutAdded]);

  // SWITCH
  const getSelectedSection = (
    keyItem: string | null | undefined,
    tripIndex: number,
    tripSectionId: string | null | undefined
  ) => {
    if (keyItem)
      switch (keyItem) {
        case TransactionLayout.TRAVEL_BOOKING_GROUND_TRANS:
          return (
            <GroundTransport
              tripIndex={tripIndex}
              tripData={tripData}
              travelAuthorizationData={travelAuthorizationData}
            />
          );
        case TransactionLayout.TRAVEL_BOOKING_VEHICLE_RENTAL:
          return (
            <VehicleRental
              tripIndex={tripIndex}
              tripSectionId={tripSectionId}
              travelAuthorizationData={travelAuthorizationData}
            />
          );
        case TransactionLayout.TRAVEL_BOOKING_AIR_TRAVEL:
          return (
            <Flight
              tripIndex={tripIndex}
              tripData={tripData}
              travelAuthorizationData={travelAuthorizationData}
            />
          );
        case TransactionLayout.TRAVEL_BOOKING_TRAIN_TRAVEL:
          return (
            <Train
              tripIndex={tripIndex}
              tripData={tripData}
              travelAuthorizationData={travelAuthorizationData}
            />
          );
        case TransactionLayout.TRAVEL_BOOKING_HOTEL:
          return (
            <Accommodation
              tripIndex={tripIndex}
              tripSectionId={tripSectionId}
              travelAuthorizationData={travelAuthorizationData}
            />
          );
        case TransactionLayout.TRAVEL_BOOKING_INCIDENTAL:
          return (
            <OtherCharges
              tripIndex={tripIndex}
              inputsDisabled={inputsDisabled}
            />
          );
      }
    else return null;
  };

  const isFirstIndex =
    tripItemsMemo.findIndex((item) => item?.layoutType === selectedLayout) ===
    0;

  useEffect(() => {
    if (accountingVisibility && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [accountingVisibility]);

  const tripItemIndex = selectedLayout
    ? watch.tripItems?.findIndex((item) => item.layout === selectedLayout)
    : undefined;

  const isDistributionExist =
    tripItemIndex !== undefined
      ? watch.tripItems?.[tripItemIndex]?.tripDistributions?.length! > 1
      : false;

  const showPreferredArea: boolean =
    !!_isTripPreferredAreaAllowed && accountingVisibility;

  return (
    <Stack tokens={{ padding: '0px 20px 20px 20px' }}>
      <Stack style={{ marginBottom: -2, zIndex: 10 }}>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal>
            {tripItemsMemo
              .sort(
                (a, b) =>
                  parseInt(a.sortOrder || '0') - parseInt(b.sortOrder || '0')
              )
              .map((item, index) => {
                return (
                  <TripTab
                    inputsDisabled={inputsDisabled}
                    key={index}
                    itemProps={item}
                    onSelect={() => setSelectedLayout(item.layoutType)}
                    isSelected={selectedLayout === item.layoutType}
                    onRemove={() => {
                      tripItemsState.delete(item.layoutType!);
                      const tripsLayouts = Array.from(
                        tripItemsState.values()
                      ).sort(
                        (a, b) =>
                          parseInt(a.sortOrder || '0') -
                          parseInt(b.sortOrder || '0')
                      );

                      const layoutAdded =
                        tripsLayouts.length > 0
                          ? tripsLayouts?.[tripsLayouts.length - 1].layoutType
                          : '';
                      setSelectedLayout(layoutAdded);
                      onTripItemsUpdate(
                        new Map(tripItemsState),
                        layoutAdded!,
                        true
                      );

                      // const newTripItems = watch.tripItems
                      //   ?.filter((item) => item.layout !== selectedLayout)
                      //   .map((item) => (item ? item : null));
                      const tripIndexExact = watch.tripItems?.findIndex(
                        (row) => row.layout === item.layoutType
                      );
                      remove(tripIndexExact);
                      // @ts-ignore
                      // setValue('tripItems', newTripItems || [], {
                      //   shouldDirty: true,
                      // });
                    }}
                  />
                );
              })}
          </Stack>

          {!!watch.tripItems?.length && (
            <Stack horizontal verticalAlign={'start'}>
              <Stack
                horizontal
                verticalAlign="center"
                style={{ alignSelf: 'end', marginBottom: 10 }}
              >
                {isDistributionExist && <Stack className={styles.badge} />}
                <CommandBarButton
                  styles={{
                    root: {
                      height: 40,
                      borderRadius: 5,
                      backgroundColor: accountingVisibility
                        ? theme.palette.neutralLighterAlt
                        : theme.palette.white,
                    },
                  }}
                  iconProps={{ iconName: 'AddToShoppingList' }}
                  text="Accounting"
                  onClick={() => setAccountingVisibility((value) => !value)}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        {tripItemsMemo.length > 0 &&
          selectedLayout &&
          watch?.tripItems?.map((field, index) => {
            if (field.layout === selectedLayout)
              return (
                <div key={index} ref={sectionRef}>
                  <Stack
                    tokens={{ padding: '40px 40px' }}
                    className={clsx(
                      styles.formContainer,
                      isFirstIndex && styles.borderRadiusAdjust
                    )}
                  >
                    {getSelectedSection(
                      selectedLayout,
                      index,
                      field.tripSectionId
                    )}
                    <Stack
                      horizontal
                      tokens={{
                        padding: '15px ',
                      }}
                      className={clsx(styles.sectionStyle)}
                    >
                      <SuppliersSection
                        layout={field.layout}
                        index={index}
                        travelAuthorizationData={travelAuthorizationData}
                        tripData={tripData}
                        inputsDisabled={inputsDisabled}
                        accountingVisibility={accountingVisibility}
                      />
                      <PreferredArea
                        tripItemIndex={index}
                        visible={showPreferredArea}
                        selectedLayout={selectedLayout}
                        travelAuthorizationData={travelAuthorizationData}
                      />
                    </Stack>
                    {accountingVisibility && (
                      <Accounting
                        businessUnitId={businessUnitId}
                        inputsDisabled={inputsDisabled}
                        travelAuthorizationData={travelAuthorizationData}
                        tripItem={tripData}
                        tripItemIndex={index}
                        tripSectionId={field.tripSectionId}
                      />
                    )}
                  </Stack>
                </div>
              );
            else return null;
          })}
      </Stack>
    </Stack>
  );
};
