import { PrimaryButton, Stack } from '@fluentui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { GetEntityDocument } from '../../__generated__/GetEntityDocument'
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { EntityDocumentResetAccountingArea, EntityDocumentResetAccountingAreaVariables } from './__generated__/EntityDocumentResetAccountingArea';
import { EntityDocumentResetAccountingAreaInput } from 'common/types/globalTypes';
import { useToasts } from 'react-toast-notifications';
const ENTITY_DOCUMENT_RESET_ACCOUNTING_AREA = loader('./EntityDocumentResetAccountingArea.graphql');
const CONFIRM_RESET_DIALOG_TITLE = 'Are you sure you want to reset accounting details?';
const CONFIRM_RESET_DIALOG_SUBTEXT = 'This will reset all the accounting details.';


interface ResetAccountingDetailsProps {
  document: GetEntityDocument;
  onResetLoading: (value: boolean) => void;
}

export const ResetAccountingDetails: React.FC<ResetAccountingDetailsProps> = ({
  document,
  onResetLoading,
}) => {
  const { addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const [resetAccountingArea, { loading }] = useMutation<
    EntityDocumentResetAccountingArea,
    EntityDocumentResetAccountingAreaVariables
  >(ENTITY_DOCUMENT_RESET_ACCOUNTING_AREA, { errorPolicy: 'all' });

  const { _resetAccountingAllowed, id, _rowTimestamp } = { ...document.entityDocument }

  const onResetLoadingMemo = useCallback((isLoading: boolean) => {
    onResetLoading(isLoading)
  }, [onResetLoading]);

  useEffect(() => {
    onResetLoadingMemo(loading);
  }, [loading, onResetLoadingMemo]);


  if (!!_resetAccountingAllowed)
    return (
      <>
        <PrimaryButton
          text="Reset Accounting Details"
          onClick={() => setVisible(true)}
        />
        <Stack>
          <ConfirmDialog
            hidden={!visible}
            title={CONFIRM_RESET_DIALOG_TITLE}
            subText={CONFIRM_RESET_DIALOG_SUBTEXT}
            onDismiss={() => setVisible(false)}
            onConfirm={async () => {
              setVisible(false);
              if (!!id && !!_rowTimestamp) {
                const inputVariables: EntityDocumentResetAccountingAreaInput = {
                  entityDocumentId: id,
                  rowTimestamp: _rowTimestamp,
                };
                const { errors } = await resetAccountingArea({
                  variables: {
                    input: inputVariables,
                  },
                });
                if (errors?.length)
                  addToast(errors[0].message, {
                    appearance: 'error',
                  });
                else
                  addToast('Accounting details reset successfully.', {
                    appearance: 'success',
                  });
              }
            }}
          />
        </Stack>
      </>
    )
  else
    return null
}
