import {
  DefaultButton,
  DialogFooter,
  DialogType,
  IDialogProps,
  PrimaryButton,
} from '@fluentui/react';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useStyles } from 'approvals/ApprovalInputGroup/index.styles';
import { SuccessButton } from 'common/components/SuccessButton';
import React, { ReactNode } from 'react';
import { DialogWrapper } from '../DialogWrapper';

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};

interface ConfirmDialogProps extends IDialogProps {
  hidden: boolean;
  successBtnDisable?: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  children?: ReactNode;
  title: string;
  type?: DialogType;
  subText?: string;
  minWidth?: number;
  isConfirmPrimaryButton?: boolean;
  isAmendButton?: boolean;
  isDraggable?: boolean;
  confirmText?: string | undefined;
}
export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  successBtnDisable,
  onDismiss,
  onConfirm,
  children,
  title,
  type = DialogType.largeHeader,
  subText,
  minWidth,
  isConfirmPrimaryButton,
  isAmendButton,
  isDraggable,
  confirmText,
  ...props
}) => {
  const declineStyles = useStyles();

  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable]
  );

  return (
    <DialogWrapper
      onDismiss={onDismiss}
      dialogContentProps={{
        title: title,
        type: type,
        subText: subText,
      }}
      minWidth={minWidth}
      modalProps={modalProps}
      {...props}
    >
      {children}
      <DialogFooter>
        {isConfirmPrimaryButton ? (
          <PrimaryButton
            disabled={successBtnDisable}
            text="Confirm"
            onClick={onConfirm}
          />
        ) : isAmendButton ? (
          <DefaultButton
            toggle
            primary={true}
            className={declineStyles.declineButton}
            selected={true}
            onClick={onConfirm}
            text="Amend"
          />
        ) : (
          <SuccessButton
            disabled={successBtnDisable}
            text={confirmText ? confirmText : 'Confirm'}
            onClick={onConfirm}
          />
        )}

        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </DialogWrapper>
  );
};
