import { useLazyQuery } from '@apollo/client';
import { ITextFieldProps, ITextFieldStyles, Spinner, Stack, TextField, makeStyles, useTheme } from '@fluentui/react';
import { RequiredNameProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import { LookupTravelerSearch, LookupTravelerSearchVariables } from '../../../../__generated__/LookupTravelerSearch';
import _ from 'lodash';
import { TravelerLookUpCallout } from '../../../../TravelerLookUpCallout';
const LOOKUP_TRAVELER_SEARCH = loader('../../../../LookupTravelerSearch.graphql');
type TravelerNameAutocompleteProps = RequiredNameProps<ITextFieldProps>

const useStyles = makeStyles((theme) => ({
  calloutContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  icon: {
    color: theme.palette.black,
  },
}));

export const TravelerNameAutocomplete: React.FC<TravelerNameAutocompleteProps> = ({
  ...props
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: props.disabled
        ? theme.palette.neutralLighter
        : theme.palette.white,
    },
  };
  const {
    field: { onChange, value, },
    fieldState: { error, },
  } = useController({
    name: props.name,
  });
  const [calloutVisible, setCalloutVisible] = useState(true);

  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupTravelerSearch,
    LookupTravelerSearchVariables
  >(LOOKUP_TRAVELER_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { id, disabled } = { ...props }

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
      queryCalled();
    }, 350),
    []);

  const renderSpinner = () => {
    return (
      <>
        {
          loading && <Spinner />
        }
      </>
    );
  };

  return (
    <Stack>
      <TextField
        {...props}
        autoComplete="off"
        errorMessage={error?.message}
        value={value || ''}
        onChange={(_event, value) => {
          if (value) {
            onChange(value);
            callbackLookUp(value, () => {
              if (calloutVisible === false) setCalloutVisible(true);
            });
          } else {
            onChange(null);
            setCalloutVisible(false);
          }
        }}
        styles={textfieldStyles}
        onRenderSuffix={renderSpinner}
        disabled={disabled ? true : false}
      />
      <Stack className={styles.calloutContainer}>
        {calloutVisible && !loading && (
          <TravelerLookUpCallout
            lookUpCalloutWidth={400}
            onDismiss={() => setCalloutVisible(false)}
            onSelect={() => { }}
            targetID={`#${id!}`}
            data={data?.lookupTravelerSearch?.nodes!}
          />
        )}
      </Stack>
    </Stack>
  )
}
