import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isCollapsible: true,
  toggleVisibility: true,
  isVisible: true,
};
const useColumns = () => {
  const { isSm, isLg } = useGetScreenWidth();
  const columns: ColumnData[] = [
    {
      key: 'documentType',
      name: 'Type',
      fieldName: 'documentType',
      minWidth: 120,
      maxWidth: 120,
      ...commonColumnProps,
    },
    {
      key: '_documentPoolName',
      name: 'Folder',
      fieldName: '_documentPoolName',
      minWidth: 180,
      maxWidth: 180,
      ...commonColumnProps,
      isVisible: false,
    },

    {
      key: 'indexName',
      name: 'Name',
      fieldName: 'indexName',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'indexReferenceNumber',
      name: 'Number',
      fieldName: 'indexReferenceNumber',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
    },
    {
      key: 'indexTransactionDate',
      name: 'Date',
      fieldName: 'indexTransactionDate',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
      isVisible: !isSm,
    },

    {
      key: '_documentContents',
      name: 'Contents',
      fieldName: '_documentContents',
      minWidth: 200,
      maxWidth: 200,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: 'comment',
      name: 'Comments',
      fieldName: 'comment',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'indexAmount',
      name: 'Total',
      fieldName: 'indexAmount',
      minWidth: 70,
      maxWidth: 70,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
    },

    {
      key: 'isoCode',
      name: 'Currency',
      fieldName: 'isoCode',
      minWidth: 55,
      maxWidth: 55,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: 'view',
      name: '',
      fieldName: '',
      minWidth: 20,
      maxWidth: 20,
      ...commonColumnProps,
      toggleVisibility: false,
      isResizable: false,
    },
    {
      key: 'download',
      name: '',
      fieldName: '',
      minWidth: 20,
      maxWidth: 20,
      ...commonColumnProps,
      toggleVisibility: false,
      isResizable: false,
    },
    {
      key: 'fileReference',
      name: 'Documents',
      fieldName: 'fileReference',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: '_uploadDate',
      name: 'Uploaded',
      fieldName: '_uploadDate',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: '_isVerified',
      name: 'Verified',
      fieldName: '_isVerified',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: '_isSelected',
      name: 'History',
      fieldName: '_isSelected',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      ...commonColumnProps,
    },
    {
      key: '_verifiedUserName',
      name: 'Verified User',
      fieldName: '_verifiedUserName',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_uploadedByUser',
      name: 'Uploaded By',
      fieldName: '_uploadedByUser',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      ...commonColumnProps,
      isVisible: false,
    },
  ];
  return { columns };
};
export { useColumns };
