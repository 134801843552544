import {
  DayOfWeek,
  IDropdownOption,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { PayCycleCommonTypes } from 'ap/paymentCycle/__generated__/PayCycleCommonTypes';
import { FormikDropdown, FormikTextField } from 'common/components';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AmountTextField } from 'common/components/FormikFields/AmountTextField';
import { FormikDatePicker } from 'common/components/FormikFields/DatePicker';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import Decimal from 'decimal.js';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { PayCycleValues } from '../types';
import { useStyles } from './index.styles';
interface disableCondition {
  isNew: boolean;
  isUpdatable: boolean | undefined | null;
}
interface BasicFormProps {
  payCycleTypeData: PayCycleCommonTypes | undefined;
  disableConditions?: disableCondition;
  totalPaymentAmount: string | null | undefined;
  payCycleId: string | undefined;
}
export const BasicForm: React.FC<BasicFormProps> = ({
  payCycleTypeData,
  disableConditions,
  totalPaymentAmount,
  payCycleId,
}) => {
  const style = useStyles();
  const [transactionTypeOptions, setTransactionTypeOptions] = useState<
    IDropdownOption[]
  >([]);
  const [bankAccountOptions, setBankAccountOptions] = useState<
    IDropdownOption[]
  >([]);
  const [accountingPeriodOptions, setAccountingPeriodOptions] = useState<
    IDropdownOption[]
  >([]);
  const { values } = useFormikContext<PayCycleValues>();
  const [currencyValue, setCurrencyValue] = useState<string | null>();

  useEffect(() => {
    if (values.paymentAccountId) {
      payCycleTypeData?.paymentAccounts?.nodes.map((item) => {
        if (item.id === values.paymentAccountId) {
          setCurrencyValue(
            item.currency?.isoCode! + '-' + item.currency?.name!
          );
        }
      });
    } else setCurrencyValue(null);
  }, [values, payCycleTypeData]);

  useEffect(() => {
    const tTypes =
      payCycleTypeData?.paymentTransactionTypes?.nodes.map((item) => ({
        key: item.id,
        text: item.transactionType || '',
      })) || [];
    setTransactionTypeOptions(tTypes);
    const bAccount =
      payCycleTypeData?.paymentAccounts?.nodes.map((item) => {
        const bankName = `${item.name} ${
          item.bankAccountNumber ? item.bankAccountNumber : ''
        }`;
        return {
          key: item.id,
          text: bankName,
        };
      }) || [];
    setBankAccountOptions(bAccount);
    const accP =
      payCycleTypeData?.companyCorporatePeriods?.nodes.map((item) => ({
        disabled: item.isEntryAllowed ? false : true,
        key: item.id,
        text:
          item._periodYear +
            ' (' +
            dateFormat(dateConvertions(item.startDate)) +
            ' - ' +
            dateFormat(dateConvertions(item.endDate)) +
            ')' || '',
      })) || [];
    setAccountingPeriodOptions(accP);
  }, [payCycleTypeData]);

  const disableOnEdit = !disableConditions?.isNew;
  const disableOnIsUpdatable =
    !disableConditions?.isNew && !disableConditions?.isUpdatable;

  const [difference, setDifference] = useState<Decimal | null>(new Decimal(0));

  useEffect(() => {
    if (payCycleId) {
      if (totalPaymentAmount) {
        const controlTotalAmount = values.controlTotalAmount || 0;
        try {
          const difference = new Decimal(controlTotalAmount).minus(
            totalPaymentAmount
          );
          setDifference(difference);
        } catch (error) {
          setDifference(new Decimal(0).minus(totalPaymentAmount));
        }
      } else {
        setDifference(new Decimal(0));
      }
    }
  }, [payCycleId, totalPaymentAmount, values.controlTotalAmount]);

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 50 }}
      className={style.mainContainer}
    >
      <Stack
        grow={1}
        tokens={{ childrenGap: 20 }}
        className={style.detailsContainer}
      >
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item className={style.flex50}>
            <FormikDropdown
              disabled={disableOnEdit}
              label="Transaction Type"
              placeholder="Select"
              options={transactionTypeOptions}
              name="transactionTypeId"
              required
            />
          </Stack.Item>
          <Stack.Item className={style.flex50}>
            <FormikDropdown
              disabled={disableOnEdit}
              label="Bank Account"
              placeholder="Select"
              options={bankAccountOptions}
              name="paymentAccountId"
              required
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item className={style.flex50}>
            <FormikDatePicker
              disabled={disableOnIsUpdatable}
              name="defaultPaymentDate"
              placeholder="Select date"
              ariaLabel="Payment Date"
              label="Payment Date"
              firstDayOfWeek={DayOfWeek.Sunday}
              showWeekNumbers
              firstWeekOfYear={1}
              showMonthPickerAsOverlay
              showGoToToday
            />
          </Stack.Item>
          <Stack.Item className={style.flex50}>
            <TextField
              value={currencyValue ? currencyValue : ''}
              disabled
              label="Currency"
              readOnly
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item className={style.flex50}>
            <FormikDropdown
              disabled={disableOnIsUpdatable}
              label="Accounting Period"
              placeholder="Select"
              options={accountingPeriodOptions}
              name="corporatePeriodId"
            />
          </Stack.Item>
          <Stack.Item className={style.flex50}>
            <AmountTextField
              name="controlTotalAmount"
              label="Cycle Total"
              disabled={disableOnIsUpdatable}
              fixedDecimalScale
              decimalScale={2}
              allowNegative={true}
              required
            />
            {!!difference?.toNumber() && (
              <>
                <Stack horizontal>
                  <Text className={style.differenceText}>{`Difference: `}</Text>
                  <AmountTextView
                    className={style.differenceText}
                    value={difference?.toString() || ''}
                  />
                </Stack>
              </>
            )}
          </Stack.Item>
        </Stack>
        <FormikTextField
          disabled={disableOnIsUpdatable}
          name="description"
          label="Description"
          placeholder="Enter description"
          required
        />
      </Stack>
    </Stack>
  );
};
