import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  inputContainerLabel: {
    fontWeight: 'bold',
    padding: 5,
    flex: 1,
  },
  textInputConatiner: {
    flex: 1,
    padding: '5px',
  },
}));
