import { IconButton, Stack, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { Trip_trip_traveler } from '../../../__generated__/Trip';
import { AllowancesCallout } from './AllowancesCallout';

interface TravelAllowancesCalloutProps {
  traveler: Trip_trip_traveler | null | undefined
}

export const TravelAllowancesCallout: React.FC<TravelAllowancesCalloutProps> = ({
  traveler,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const calloutId = useId(`calloutInfoId`);
  if (!traveler?.travelPolicy) return null;
  return (
    <Stack>
      <TooltipHost content={"Travel Allowances"}>
        <IconButton
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          iconProps={{ iconName: 'Info' }}
          id={calloutId}
        />
      </TooltipHost>
      <AllowancesCallout
        isOpen={isOpen}
        targetID={`#${calloutId}`}
        traveler={traveler}
      />
    </Stack>
  )
}
