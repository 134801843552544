import { ActionButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { ShippingAddressModal } from './ShippingAddressModal';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
interface ShippingAddressProps {
  purchaseOrder: PurchaseOrder | undefined;
  isDisabled: boolean;
}
export const ShippingAddress: React.FC<ShippingAddressProps> = ({
  isDisabled,
  purchaseOrder,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  return (
    <>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        tokens={{
          padding: "0px 20px 0px 0px",
          childrenGap: 3
        }}
      >
        <ActionButton
          iconProps={{ iconName: 'DeliveryTruck' }}
          onClick={() => {
            setDialogVisible(true);
          }}
          text="Delivery Address"
          disabled={isDisabled}
        />
      </Stack>
      {dialogVisible && (
        <ShippingAddressModal
          setOpen={setDialogVisible}
          purchaseOrder={purchaseOrder}
        />
      )}
    </>
  )
}