import { useQuery } from '@apollo/client';
import {
  ActionButton,
  ComboBox,
  IButtonStyles,
  IComboBoxOption,
  IComboBoxStyles,
  Icon,
  Spinner,
  SpinnerSize,
  makeStyles,
  useTheme,
} from '@fluentui/react';
import { FilterArrayType } from 'common/components/Filters';
import { formatDropdownOptions } from 'common/utils';
import { loader } from 'graphql.macro';
import React from 'react';
import {
  TripTravelPlans,
  TripTravelPlansVariables,
} from './__generated__/TripTravelPlans';
const TRIP_TRAVEL_PLANS = loader('./TripTravelPlans.graphql');

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 8,
  },
}));

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    display: 'flex',
    width: 250,
    justifyContent: 'center',
  },
};

interface TravelPlansFilterProps {
  filterKey: string;
  onOptionChange?: (data: FilterArrayType) => void;
}

export const TravelPlansFilter: React.FC<TravelPlansFilterProps> = ({
  filterKey,
  onOptionChange,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const { data, loading, fetchMore } = useQuery<
    TripTravelPlans,
    TripTravelPlansVariables
  >(TRIP_TRAVEL_PLANS, {
    variables: {
      first: 20,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { tripTravelPlans } = { ...data };
  const { hasNextPage } = { ...tripTravelPlans?.pageInfo };

  const comboBoxStyles: Partial<IComboBoxStyles> = {
    container: {
      width: 250,
    },
    callout: {
      height: 430,
    },
    root: {
      backgroundColor: theme.palette.neutralLighter,
      '::after': {
        border: 0
      },
    },
    input: {
      backgroundColor: theme.palette.neutralLighter,
    },
  };

  const options: IComboBoxOption[] = formatDropdownOptions(
    tripTravelPlans?.nodes,
    {
      getKey: (item) => item.id,
      getText: (item) =>
        `${item.travelAuthorizationNumber} - ${item.travelPlan}` || '',
      includeAll: true,
    }
  );

  const _onRenderIcon = () => {
    if (loading)
      return <Spinner size={SpinnerSize.medium} className={styles.icon} />;
    else return <Icon className={styles.icon} iconName="Add" />;
  };

  const _onRenderLowerContent = () => {
    if (!hasNextPage) return null;
    return (
      <ActionButton
        text="Load More"
        styles={buttonStyles}
        onClick={() => {
          const variables: TripTravelPlansVariables = {
            first: 20,
            after: data?.tripTravelPlans?.pageInfo.endCursor,
          };
          fetchMore({ variables });
        }}
        onRenderIcon={_onRenderIcon}
      />
    );
  };

  return (
    <ComboBox
      placeholder="Travel Plan"
      options={options}
      styles={comboBoxStyles}
      allowFreeform
      autoComplete="on"
      onRenderLowerContent={_onRenderLowerContent}
      dropdownWidth={250}
      onChange={(_, option) => {
        if (option)
          onOptionChange?.({
            value: option?.key!,
            filterKey,
            label: option?.text!,
          });
      }}
    />
  );
};
