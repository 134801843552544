import { ToNullable } from 'utility/types';
import * as Yup from 'yup';

export type SignatureValues = ToNullable<SignatureFormValues>;

type SignatureFormValues = {
  imageUrl: string;
};

export const validationSchema = () =>
  Yup.object({
    imageUrl: Yup.string().nullable(),
  });
