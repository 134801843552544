import React from 'react';
import { IconButton, TooltipHost } from '@fluentui/react';

interface CloseButtonIC {
  onClick: () => void;
  disabled?: boolean;
  tooltipMessage?: string;
}
export const CloseButton: React.FC<CloseButtonIC> = ({
  onClick,
  disabled,
  tooltipMessage,
}) => {
  return (
    <TooltipHost content={disabled ? tooltipMessage : undefined}>
      <IconButton
        onClick={() => onClick?.()}
        disabled={disabled}
        iconProps={{ iconName: 'ChromeClose' }}
        ariaLabel="Close"
      />
    </TooltipHost>
  );
};
