import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  resourceMainContainer: {
    width: 250,
  },
  resourceColumnContainer: {
    marginTop: 10,
  },
  resourceType: {
    textAlign: 'center',
    fontWeight: FontWeights.semibold,
  },
  resourceTypeDataMainContainer: {
    marginTop: 10,
  },
  resourceColumnMainContainer: {
    marginLeft: '15px !important',
    width: 400,
  },
  marginLeft10: {
    marginLeft: '10px !important',
  },
  resouce: {
    width: 150,
  },
  marginTop5: {
    marginTop: '5px !important',
  },
  environmentalImpact: {
    height: 32,
    justifyContent: 'center',
  },
}));
