import {
  Separator,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,
} from '@fluentui/react';
import React from 'react';
import { useStyles } from './index.styles';

export const ShimmerSection = (): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={styles.fullWidthContainer}>
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 50, width: '100%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 20, width: '10%' },
          { type: ShimmerElementType.gap, height: 20, width: '5%' },
          { type: ShimmerElementType.line, height: 20, width: '10%' },
          { type: ShimmerElementType.gap, height: 20, width: '5%' },
          { type: ShimmerElementType.line, height: 20, width: '10%' },
          { type: ShimmerElementType.gap, height: 20, width: '5%' },
          { type: ShimmerElementType.line, height: 20, width: '10%' },
          { type: ShimmerElementType.gap, height: 20, width: '5%' },
          { type: ShimmerElementType.line, height: 20, width: '10%' },
          { type: ShimmerElementType.gap, height: 20, width: '30%' },
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 10, width: '100%' },
        ]}
      />
    </div>
  );
};

export const ShimmerSection1 = (): JSX.Element => {
  const styles = useStyles();
  var mapArr = new Array(7).fill([]);

  return (
    <>
      {mapArr.map((item, _index) => {
        return (
          <div key={_index} className={styles.fullWidthContainer}>
            <ShimmerElementsGroup
              width="100%"
              shimmerElements={[
                { type: ShimmerElementType.gap, height: 40, width: '100%' },
              ]}
            />
            <ShimmerElementsGroup
              width="100%"
              shimmerElements={[
                { type: ShimmerElementType.line, height: 30, width: '48%' },
                { type: ShimmerElementType.gap, height: 30, width: '2%' },
                { type: ShimmerElementType.line, height: 30, width: '48%' },
              ]}
            />
          </div>
        );
      })}
    </>
  );
};

export const ShimmerView: React.FC = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Stack className={styles.containerpadding}>
        <Shimmer customElementsGroup={ShimmerSection()} />
      </Stack>
      <Separator />
      <Stack className={styles.containerpadding}>
        <Shimmer customElementsGroup={ShimmerSection1()} />
      </Stack>
      <Separator />
    </React.Fragment>
  );
};
