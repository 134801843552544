import { Callout, DirectionalHint, Stack } from '@fluentui/react'
import React from 'react';
import { useStyles } from './index.styles'
import { WarningValues } from './WarningValues';
import { WarningInfo } from './WarningInfo';
import { Trip_trip } from '../../../../__generated__/Trip';
interface CalloutInfoProps {
  isOpen: boolean;
  targetID: string;
  tripData: Trip_trip | null | undefined;
}
export const CalloutInfo: React.FC<CalloutInfoProps> = ({
  isOpen,
  targetID,
  tripData,
}) => {
  const styles = useStyles();
  if (!isOpen) return null;
  return (
    <Callout
      className={styles.container}
      target={targetID}
      directionalHint={DirectionalHint.bottomCenter}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
      >
        <WarningInfo tripData={tripData} />
        <WarningValues tripData={tripData} />
      </Stack>
    </Callout>
  )
}
