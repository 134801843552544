import * as React from 'react';
import {
  Shimmer,
  ShimmerElementType,
} from '@fluentui/react/lib/Shimmer';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { Stack } from '@fluentui/react';
const wrapperClass = mergeStyles({
  padding: " 0px 25px",
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '15px 0',
    },
  },
});
const shimmerWithElementSecondRow = [
  { type: ShimmerElementType.line, height: 20, width: '20%' },
  { type: ShimmerElementType.gap, width: '3%' },
  { type: ShimmerElementType.line, height: 20, width: '20%' },
  { type: ShimmerElementType.gap, width: '3%' },
  { type: ShimmerElementType.line, height: 20, width: '20%' },
  { type: ShimmerElementType.gap, width: '3%' },
  { type: ShimmerElementType.line, height: 20, width: '20%' },
];
export const TravelPoliciesShimmer: React.FunctionComponent = () => {
  const n = 20;
  return (
    <Stack className={wrapperClass}>
      {
        [...Array(n)].map((_ele, index) => (<Shimmer shimmerElements={shimmerWithElementSecondRow} key={index} />))
      }
    </Stack>
  )
};
