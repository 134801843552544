import { IconButton, Pivot, PivotItem, Stack, makeStyles } from '@fluentui/react';
import { GlobalActions } from 'common/constants';
import React, { useCallback, useState } from 'react';
import { TravelerDetails_traveler } from '../__generated__/TravelerDetails';
import { loader } from 'graphql.macro';
import { TravelerNotes, TravelerNotesVariables } from 'common/graphql/__generated__/TravelerNotes';
import { useQuery } from '@apollo/client';
import { HistoryActionMenuView } from 'common/components/History';
import { PivotDisplay, PivotMenuItemProps } from 'common/components/PivotDisplay';
import { TravelerPropertiesCount, getUpdatedPivotActionItems, pivotActionItems } from './constants';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { Notes } from './Notes';
import { Attach } from './Attach';
import { TravelerUploadDocument_travelerUploadDocument } from 'common/graphql/__generated__/TravelerUploadDocument';
import { Tags } from './Tags';
const TRAVELER_NOTES_LIST = loader('../../../../../../../../../../../../common/graphql/TravelerNotes.graphql')

const useStyles = makeStyles((theme) => ({
  iconButtonColor: {
    marginLeft: 30,
    color: theme.palette.neutralDark,
  },
  pivotContainer: {
    padding: '0px 25px'
  }
}));

interface ActionMenuProps {
  traveler: TravelerDetails_traveler;
  onRemove?: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: TravelerUploadDocument_travelerUploadDocument,
    toastId: string
  ) => void;
}

export const ActionMenu: React.FC<ActionMenuProps> = ({
  traveler,
  onUpload,
  onRemove,
}) => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = useState<string | undefined>(GlobalActions.attach);
  const [expandMenu, setExpandMenu] = useState(false);

  const {
    id,
    travelerHistoriesByEntityId,
  } = { ...traveler };

  const {
    data: entityNotesData,
    refetch: entityNotesListRefetch
  } = useQuery<
    TravelerNotes,
    TravelerNotesVariables
  >(TRAVELER_NOTES_LIST, {
    variables: {
      id: id!,
    },
    skip: !id,
  });

  const getEntityNotes = () => {
    const variables: TravelerNotesVariables = {
      id: id!,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    id!,
  ]);

  const getSelectedSection = (key: string) => {
    switch (key) {
      case GlobalActions.notes:
        return (
          <Stack>
            <Notes
              listRefreshRequest={getEntityNotesMemo}
              id={id!}
              data={entityNotesData?.traveler?.notesByEntityId?.nodes || []}
            />
          </Stack>
        );
      case GlobalActions.history:
        return (
          <Stack>
            <HistoryActionMenuView
              moduleName="Traveler"
              numberOfItems={3}
              historyData={
                travelerHistoriesByEntityId?.nodes || []
              }
            />
          </Stack>
        );
      case GlobalActions.attach:
        return (
          <Stack>
            <Attach
              traveler={traveler}
              onUpload={onUpload}
              onRemove={onRemove}
            />
          </Stack>
        );
      case GlobalActions.tags:
        return (
          <Stack>
            <Tags travelerId={id} />;
          </Stack>
        );
      default:
        return null;
    }
  };

  let updatedPivotActionItems: PivotMenuItemProps[] = [];
  if (traveler) {
    const {
      entityTagsByEntityId,
      entityDocumentsByEntityId,
    } = { ...traveler };

    const supplierTransactionCountArray: TravelerPropertiesCount[] = [
      {
        name: 'Files',
        count: entityDocumentsByEntityId?.totalCount || 0,
      },
      {
        name: 'Notes',
        count: entityNotesData?.traveler?.notesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'History',
        count: travelerHistoriesByEntityId?.nodes?.length || 0,
      },
      {
        name: 'Tags',
        count: entityTagsByEntityId?.nodes?.length || 0,
      },
    ];
    updatedPivotActionItems = getUpdatedPivotActionItems(
      supplierTransactionCountArray
    );
  } else updatedPivotActionItems = pivotActionItems;


  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.pivotContainer}
            >
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey);
                }}
              >
                {updatedPivotActionItems.map((item, index) => {
                  return (
                    <PivotItem
                      key={index}
                      itemKey={item.itemKey}
                      itemIcon={item.iconName}
                      headerText={item.name}
                      itemCount={item.count}
                      onRenderItemLink={PivotCustomRender}
                    />
                  );
                })}
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
              />
            </Stack>
          </Stack>
          <Stack>{getSelectedSection(selectedKey!)}</Stack>
        </Stack>
      ) : (
        <Stack
          horizontalAlign="space-between"
          horizontal
          className={styles.pivotContainer}
        >
          <Stack horizontal verticalAlign="center">
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              {updatedPivotActionItems.map((item, index) => {
                return (
                  <PivotDisplay
                    key={index}
                    {...item}
                    onPivotClick={() => {
                      setSelectedKey(item.itemKey);
                      setExpandMenu(true);
                    }}
                  />
                );
              })}
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
