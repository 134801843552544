import { useMutation } from '@apollo/client';
import {
  ContextualMenu,
  IDragOptions,
  Modal,
  Stack,
  makeStyles,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  LookupAccountPatch,
  LookupAccountUpdateInput,
} from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { AccountsEntryViewProps } from '..';
import { LookupAccountCoaSearch_lookupAccountCoaSearch } from '../../list/__generated__/LookupAccountCoaSearch';
import { BasicForm } from './BasicForm';
import { Footer } from './Footer';
import { Header } from './Header';
import {
  LookupAccountCreate,
  LookupAccountCreateVariables,
} from './__generated__/LookupAccountCreate';
import {
  LookupAccountUpdate,
  LookupAccountUpdateVariables,
} from './__generated__/LookupAccountUpdate';
import { AccountEntryValues, CorporateAccount } from './types';
import { getDefaultValues } from './utils';
import { validationSchema } from './validation';
import { ChartOfAccounts_chartOfAccounts_nodes } from '../../list/chartAccount/__generated__/ChartOfAccounts';
const LOOKUP_ACCOUNTS_CREATE = loader('./LookupAccountCreate.graphql');
const LOOKUP_ACCOUNTS_UPDATE = loader('./LookupAccountUpdate.graphql');
const LOOKUP_ACCOUNT_FIELDS = loader('../../LookupAccountFields.graphql');
const LOOKUP_ACCOUNT_FRAGMENT_NAME = 'LookupAccountFields';

const useStyles = makeStyles(() => ({
  container: {
    width: 600,
    maxHeight: 600,
  },
}));

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

type AccountsEntryModalProps = Partial<AccountsEntryViewProps> & {
  onClose: () => void;
  chartOfAccount: ChartOfAccounts_chartOfAccounts_nodes;
};

export const AccountsEntryModal: React.FC<AccountsEntryModalProps> = ({
  account,
  onClose,
  chartOfAccount,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  const [createAccountEntry, { loading: createAccountEntryLoading }] =
    useMutation<LookupAccountCreate, LookupAccountCreateVariables>(
      LOOKUP_ACCOUNTS_CREATE,
      { errorPolicy: 'all' }
    );

  const [updateAccountEntry, { loading: updateAccountEntryLoading }] =
    useMutation<LookupAccountUpdate, LookupAccountUpdateVariables>(
      LOOKUP_ACCOUNTS_UPDATE,
      { errorPolicy: 'all' }
    );

  const formMethods = useForm<AccountEntryValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { handleSubmit, reset, trigger } = { ...formMethods };

  const { id, _rowTimestamp } = { ...account };
  const isEdit = !!id;

  const onHandleSubmit = async (
    values: AccountEntryValues,
    addMore: boolean,
    closeAfterComplete: boolean
  ) => {
    if (isEdit && account) {
      const defaultValues = getDefaultValues({
        account,
      });
      const lookupAccountEntryPatch: LookupAccountPatch = Object.entries(
        values
      ).reduce((res, [key, val]) => {
        if (val !== defaultValues[key as keyof AccountEntryValues]) {
          if (key === 'corporateAccount') {
            const corporateAccount = val as CorporateAccount;
            return {
              ...res,
              corporateLookupAccountId: corporateAccount?.id || null,
            };
          }
          return { ...res, [key]: val };
        }
        return res;
      }, {});
      const { errors } = await updateAccountEntry({
        variables: {
          input: {
            id,
            rowTimestamp: _rowTimestamp,
            lookupAccountPatch: !isEmpty(lookupAccountEntryPatch)
              ? lookupAccountEntryPatch
              : undefined,
          } as LookupAccountUpdateInput,
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Account edited successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const { corporateAccount, ...accountValues } = { ...values };
      const { errors, data } = await createAccountEntry({
        variables: {
          input: {
            chartOfAccountId: chartOfAccount.id || '',
            lookupAccount: {
              ...accountValues,
              lookupAccount: accountValues.lookupAccount || '',
              lookupName: accountValues.lookupName || '',
              corporateLookupAccountId: corporateAccount?.id,
            },
          },
        },
        update: (cache, { data }) => {
          if (!!data?.lookupAccountCreate?.lookupAccount?.id) {
            cache.modify({
              fields: {
                lookupAccountCoaSearch(
                  existing: LookupAccountCoaSearch_lookupAccountCoaSearch
                ) {
                  if (data?.lookupAccountCreate?.lookupAccount) {
                    const newAccountRef = cache.writeFragment({
                      data: data?.lookupAccountCreate?.lookupAccount,
                      fragment: LOOKUP_ACCOUNT_FIELDS,
                      fragmentName: LOOKUP_ACCOUNT_FRAGMENT_NAME,
                    });
                    return {
                      ...existing,
                      nodes: [newAccountRef, ...existing.nodes],
                    };
                  }
                },
              },
            });
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Account created successfully.', {
          appearance: 'success',
        });
        if (!addMore && data?.lookupAccountCreate?.lookupAccount) {
          const defaultValues = getDefaultValues({
            account: { ...data?.lookupAccountCreate?.lookupAccount },
          });
          reset(defaultValues);
        } else {
          reset();
        }
        if (closeAfterComplete) onClose();
      }
    }
  };

  const loading = createAccountEntryLoading || updateAccountEntryLoading;

  useEffect(() => {
    if (account) {
      const defaultValues = getDefaultValues({
        account,
      });
      reset(defaultValues);
    }
    trigger();
  }, [account, reset, trigger]);

  return (
    <Modal isOpen isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        <FormProvider {...formMethods}>
          <Header isEdit={isEdit} onClose={onClose} />
          <BasicForm
            corporateChartOfAccountId={
              chartOfAccount.corporateChartOfAccountId!
            }
          />
          <Footer
            isEdit={isEdit}
            loading={loading}
            onSubmit={(addMore, closeAfterComplete) => {
              const func = handleSubmit((values) =>
                onHandleSubmit(values, addMore, closeAfterComplete)
              );
              func();
            }}
            onCancel={onClose}
          />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
