import { FontSizes, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  flexHalf: {
    flex: 0.5,
  },
  status: {
    color: theme.palette.themePrimary,
    fontSize: FontSizes.size18,
  },
}));
