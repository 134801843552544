import { useMutation } from '@apollo/client';
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  makeStyles,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { DangerButton } from 'common/components/DangerButton';
import { DialogWrapper } from 'common/components/DialogWrapper';
import { TravelAuthorizationAccountingUnstamperInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import {
  TravelAuthorizationAccountingUnstamper,
  TravelAuthorizationAccountingUnstamperVariables,
} from './__generated__/TravelAuthorizationAccountingUnstamper';
const CONFIRM_STAMP_REMOVE_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT =
  'This will remove the stamp from the travel plan.';
const TRAVELER_AUTHORIZATION_ACCOUNTING_UNSTAMPER = loader(
  './TravelAuthorizationAccountingUnstamper.graphql'
);

const useStyles = makeStyles((theme) => ({
  unStampButton: {
    backgroundColor: theme.palette.red,
  },
  disabledButton: {
    marginLeft: 20,
    marginRight: 5,
    ':disabled': {
      color: theme.palette.neutralTertiary,
    },
  },
}));

interface RemoveStampProps {
  travelAuthorizationData:
    | TravelAuthorization_travelAuthorization
    | null
    | undefined;
  onRefetch: () => void;
}

export const RemoveStamp: React.FC<RemoveStampProps> = ({
  travelAuthorizationData,
  onRefetch,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [
    hideConfirmRemoveStampedDialog,
    { toggle: toggleRemoveStampedDialog },
  ] = useBoolean(true);

  const [
    unStampTransaction,
    { loading: travelAuthorizationAccountingUnstamperLoading },
  ] = useMutation<
    TravelAuthorizationAccountingUnstamper,
    TravelAuthorizationAccountingUnstamperVariables
  >(TRAVELER_AUTHORIZATION_ACCOUNTING_UNSTAMPER, { errorPolicy: 'all' });

  const {
    id,
    _rowTimestamp,
    _isAccountingEntryStampedComplete,
    _isUnstampAllowed,
  } = {
    ...travelAuthorizationData,
  };

  const onSave = async () => {
    const inputVariables: TravelAuthorizationAccountingUnstamperInput = {
      id: id!,
      rowTimestamp: _rowTimestamp!,
    };
    const { errors } = await unStampTransaction({
      variables: {
        input: inputVariables,
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      onRefetch();
      addToast('Travel plan unstamped successfully.', {
        appearance: 'success',
      });
    }
    toggleRemoveStampedDialog();
  };

  if (!_isUnstampAllowed) return null;

  return (
    <>
      <PrimaryButton
        className={styles.disabledButton}
        text="Remove Stamp"
        onClick={() => toggleRemoveStampedDialog()}
      />
      <DialogWrapper
        hidden={hideConfirmRemoveStampedDialog}
        onDismiss={toggleRemoveStampedDialog}
        dialogContentProps={{
          title: CONFIRM_STAMP_REMOVE_DIALOG_TITLE,
          type: DialogType.largeHeader,
          subText: CONFIRM_STAMP_REMOVE_DIALOG_SUBTEXT,
        }}
      >
        <DialogFooter>
          <DangerButton
            text="Remove Stamp"
            className={styles.unStampButton}
            onClick={onSave}
            disabled={travelAuthorizationAccountingUnstamperLoading}
          />
          <DefaultButton onClick={toggleRemoveStampedDialog} text="Cancel" />
        </DialogFooter>
      </DialogWrapper>
    </>
  );
};
