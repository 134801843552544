import { useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import { Cell, LabelList, Pie, PieChart } from 'recharts';
import {
  Chart,
  ChartProps,
  currencyFormatter,
  useColors,
  useStyles,
} from '../common';
import clsx from 'clsx';
import { loader } from 'graphql.macro';
import { ProductionsChartData } from './__generated__/ProductionsChartData';
import { globalMode } from 'utility/cache/ui';
import { useHistory } from 'react-router-dom';

const query = loader('./ProductionsChartData.graphql');
const Component: React.FC<ChartProps<ProductionsChartData>> = ({
  data,
  height,
  width,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const colors = useColors();
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [animation, setAnimation] = useState(true);
  const history = useHistory();

  return (
    <PieChart height={height} width={width}>
      <Pie
        data={data?.kpiCompanies?.nodes}
        cx="50%"
        cy="50%"
        outerRadius={120}
        stroke={theme.palette.neutralLighterAlt}
        dataKey="amount"
        onMouseEnter={(_: any, index: number) => setActiveIndex(index)}
        onMouseLeave={(_: any, index: number) => {
          if (activeIndex === index) setActiveIndex(undefined);
        }}
        isAnimationActive={animation}
        onAnimationEnd={() => setAnimation(false)}
        onClick={() => {
          if (activeIndex !== undefined) {
            globalMode(true);
            history.push(
              `signing?t=${data?.kpiCompanies?.nodes[activeIndex].linkSearchValue}`
            );
          }
        }}
        className={clsx(activeIndex !== undefined && styles.pointer)}
      >
        {data?.kpiCompanies?.nodes.map((_entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={colors[index % colors.length][index === activeIndex ? 1 : 0]}
          />
        ))}
        <LabelList
          position="outside"
          fill={theme.palette.neutralPrimary}
          stroke="none"
          dataKey="title"
          offset={15}
        />
        <LabelList
          position="inside"
          fill="white"
          stroke="none"
          dataKey="amount"
          formatter={(value: number) => currencyFormatter.format(value)}
          fontSize={12}
          pointerEvents="none"
        />
      </Pie>
    </PieChart>
  );
};

export const ProductionsChart: Chart<ProductionsChartData> = {
  Component,
  query,
  hasData: (data) => !!data.kpiCompanies?.nodes.length,
};
