import { useMutation, useQuery } from '@apollo/client';
import {
  FontIcon,
  Link,
  List,
  ProgressIndicator,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import clsx from 'clsx';
import { NoDataView } from 'common/components/DataPlaceholders';
import { NotificationDateTime } from 'common/components/DateTime';
import DraggablePanel from 'common/components/DraggablePanel';
import { NOTIFICATIONS_PER_PAGE } from 'common/constants';
import { PANEL_COMMON_WIDTH, PanelCommonProps } from 'common/types/utility';
import { getMessageUrl } from 'common/utils/getMessageUrl';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { globalMode } from 'utility/cache/ui';
import { PanelHeader } from '../../common/components/PanelHeader';
import { UserNotificationsOrderBy } from '../../common/types/globalTypes';
import { ShimmerView } from '../view/ShimmerView';
import { ExtendedNotificationView } from './ExtendedNotification';
import {
  GetNotification,
  GetNotificationVariables,
  GetNotification_userNotifications_nodes,
} from './__generated__/GetNotification';
import {
  ResetUserNotificationCount,
  ResetUserNotificationCountVariables,
} from './__generated__/ResetUserNotificationCount';
import { useStyles } from './index.styles';

const NOTIFICATION_DATA = loader('./GetNotification.graphql');
const GET_USER_NOTIFICATION_RESET_COUNT = loader(
  './ResetUserNotificationCount.graphql'
);

interface NotificationViewProps {
  isOpened: boolean;
  closePanel: (close: boolean) => void;
  linkID?: (linkid: string) => void;
  linkName?: (linkname: string) => void;
  isRedirect?: (redirect: boolean) => void;
}

let notificationAllData: GetNotification_userNotifications_nodes[] = [];
interface ListLoaderProps {
  label?: string;
  description?: string;
  loaderStatus?: boolean;
}

export const ListLoader: React.FunctionComponent<ListLoaderProps> = ({
  label = '',
  description = '',
  loaderStatus = false,
}) => (
  <ProgressIndicator
    label={label}
    description={description}
    barHeight={4}
    progressHidden={!loaderStatus}
  />
);

interface RenderCellProps {
  item: GetNotification_userNotifications_nodes;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
  onLinkClick?: () => void;
}

const RenderCell: React.FC<RenderCellProps> = ({
  item,
  expanded,
  onExpand,
  onLinkClick,
}) => {
  const styles = useStyles();
  return (
    <Stack>
      <Stack
        className={clsx(
          styles.notificationContainer,
          item._isNewMessage
            ? styles.messageUnreadBgColor
            : styles.messageReadBgColor
        )}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal verticalAlign="start" className={styles.wordWrap}>
            <Stack className={styles.briefcaseContainer}>
              <FontIcon iconName="work" className={styles.briefcaseIcon} />
            </Stack>
            <Stack>
              <Stack className={styles.customWidth500}>
                <Text variant="mediumPlus">{item._notificationTitle}</Text>
                <Text>{item.messageComment}</Text>
              </Stack>

              <Stack className={styles.customWidth110}>
                <NotificationDateTime dateTime={item._createdDate!} />
              </Stack>
              {item._isExtendedMessage && (
                <Stack
                  className={styles.marginTop10}
                  tokens={{ childrenGap: 10 }}
                >
                  <Link
                    onClick={() => onExpand(!expanded)}
                    className={styles.jumpToDetailContainer}
                  >
                    {expanded ? 'Less' : 'More'}
                  </Link>
                </Stack>
              )}
            </Stack>
          </Stack>
          {/*  JUMP TO SECTION */}
          {onLinkClick && (
            <Link
              onClick={onLinkClick}
              className={styles.jumpToDetailContainer}
            >
              {`View ${
                item.invoiceMessage?._isAccountingEntry
                  ? 'Accounting entry'
                  : item.linkTitle
              }`}
            </Link>
          )}
        </Stack>
      </Stack>

      {/* Extended notification */}
      {expanded && item.extendedMessage && (
        <Stack
          className={clsx(
            item?._isNewMessage
              ? styles.messageUnreadBgColor
              : styles.messageReadBgColor
          )}
        >
          <ExtendedNotificationView extendedData={item.extendedMessage} />
        </Stack>
      )}
      <Separator />
    </Stack>
  );
};

export const NotificationView: React.FC<NotificationViewProps> = ({
  isOpened,
  closePanel,
}) => {
  const styles = useStyles();
  const [expandedIds, setExpandedIds] = useState<Set<string>>(
    new Set<string>()
  );
  const {
    loading: notificationLoading,
    data: notificationData,
    fetchMore,
    refetch,
  } = useQuery<GetNotification, GetNotificationVariables>(NOTIFICATION_DATA, {
    variables: {
      first: NOTIFICATIONS_PER_PAGE,
      order_by: [UserNotificationsOrderBy._CREATED_DATE_DESC],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  // mutation query for ResetUserNotificationCount
  const [userNotificationReset] = useMutation<
    ResetUserNotificationCount,
    ResetUserNotificationCountVariables
  >(GET_USER_NOTIFICATION_RESET_COUNT);

  if (notificationData && notificationData.userNotifications) {
    notificationAllData = notificationData?.userNotifications?.nodes;
  }
  const history = useHistory();
  useEffect(() => {
    if (notificationData?.userNotifications?.nodes) {
      const isNewNotification: GetNotification_userNotifications_nodes[] =
        notificationData?.userNotifications?.nodes.filter(
          (item) => item._isNewMessage === true
        );
      if (isNewNotification?.length > 0) {
        userNotificationReset({
          variables: {
            input: {},
          },
        });
      }
    }
  }, [notificationData, userNotificationReset]);

  const handleNotificationList = (showMore?: boolean) => {
    const variables: GetNotificationVariables = {
      first: NOTIFICATIONS_PER_PAGE,
      after: showMore
        ? notificationData?.userNotifications?.pageInfo.endCursor
        : undefined,
    };
    if (showMore)
      fetchMore({
        variables,
      });
    else {
      refetch(variables);
    }
  };

  const renderWaypoint = () => {
    const { hasNextPage } = {
      ...notificationData?.userNotifications?.pageInfo,
    };

    if (hasNextPage) {
      return (
        <Waypoint
          onEnter={() => {
            handleNotificationList(true);
          }}
        >
          <div style={{ height: 40 }}>
            {notificationData?.userNotifications?.nodes.length !== 0 && (
              <ListLoader
                loaderStatus={
                  notificationLoading && notificationAllData.length !== 0
                }
              />
            )}
          </div>
        </Waypoint>
      );
    }
  };

  const flushdataClosePanel = () => {
    notificationAllData = [];
    closePanel(false);
  };
  const onRenderHeader = () => {
    return (
      <PanelHeader
        title="Notifications"
        panelIcon="ringer"
        onClose={flushdataClosePanel}
      />
    );
  };

  return (
    <DraggablePanel
      onRenderHeader={onRenderHeader}
      {...PanelCommonProps}
      isOpen={isOpened}
      initialWidth={PANEL_COMMON_WIDTH}
      minWidth={PANEL_COMMON_WIDTH}
      onDismiss={() => flushdataClosePanel()}
      isLightDismiss
    >
      {notificationLoading &&
        [...Array(10)].map((_, i) => <ShimmerView key={i} />)}

      {!notificationLoading && notificationAllData.length === 0 ? (
        <Stack className={styles.noDataViewContainer}>
          <NoDataView title="No notifications yet" show />
        </Stack>
      ) : (
        <List
          version={expandedIds}
          getKey={(item) => item.id}
          items={notificationData?.userNotifications?.nodes}
          onRenderCell={(item) =>
            item && (
              <RenderCell
                item={item}
                expanded={expandedIds.has(item.id)}
                onExpand={(expanded) => {
                  if (expanded)
                    setExpandedIds(new Set(expandedIds.add(item.id)));
                  else {
                    expandedIds.delete(item.id);
                    setExpandedIds(new Set(expandedIds));
                  }
                }}
                onLinkClick={
                  item.linkId && item.linkType
                    ? () => {
                        flushdataClosePanel();
                        globalMode(false);
                        history.push(getMessageUrl(item));
                      }
                    : undefined
                }
              />
            )
          }
        />
      )}

      {notificationAllData?.length >= NOTIFICATIONS_PER_PAGE &&
        renderWaypoint()}
    </DraggablePanel>
  );
};
