import { Separator, Stack, Text } from '@fluentui/react';
import { InfoView } from 'common/components/InfoView';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import React from 'react';
import { TravelPlanDetails_travelAuthorization } from '../__generated__/TravelPlanDetails';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { useStyles } from './index.styles';
import { ActionMenu } from './ActionMenu';
import { Trips } from './Trips';

export interface TravelPlanDetailProps {
  travelAuthorization: TravelPlanDetails_travelAuthorization | null | undefined;
  loading: boolean;
}

export const TravelPlanDetail: React.FC<TravelPlanDetailProps> = ({
  travelAuthorization,
  loading,
}) => {
  const styles = useStyles();
  const {
    comment,
    businessUnit,
    department,
    supplier,
    bookingFee,
    travelAuthorizationDate,
    currency,
    controlTotalAmount,
    tripPurpose,
    trips,
  } = { ...travelAuthorization }


  return (
    <Stack tokens={{ padding: "0px 0px 100px" }}>
      {
        loading ?
          <ShimmerView /> :
          <>
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.infoViewWrapper}
            >
              <Stack className={styles.container}>
                <InfoView
                  title='Travel Plan'
                  description={`${comment}` || '-'}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Business Unit'
                  description={businessUnit ? `${businessUnit?.name}` : '-'}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Department'
                  description={`${department ? department.name : '-'}`}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title={'Travel Agency'}
                  description={supplier?._fullName || '-'}
                />
              </Stack>
            </Stack>
            <Separator />
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.infoViewWrapper}
            >
              <Stack className={styles.container}>
                <InfoView
                  title='Booking Fee'
                  description={`${bookingFee}` || '-'}
                  isAmount
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Date'
                  description={`${travelAuthorizationDate ? dateFormat(dateConvertions(travelAuthorizationDate || '')) : '-'}`}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title='Currency'
                  description={currency ? `${currency?.isoCode}` : '-'}
                />
              </Stack>
              <Stack className={styles.container}>
                <InfoView
                  title={'Total'}
                  currency={currency?.isoCode || ''}
                  description={controlTotalAmount || '-'}
                  isAmount
                />
              </Stack>
            </Stack>
            <Separator />
            <Stack
              horizontal
              verticalAlign="center"
              className={styles.infoViewWrapper}
            >
              <Stack className={styles.container}>
                <InfoView
                  title='Purpose of Trip'
                  description={`${tripPurpose}` || '-'}
                />
              </Stack>
            </Stack>
            <Separator />
            <ActionMenu travelAuthorization={travelAuthorization} />
            <Separator />
            {
              !!trips?.nodes.length &&
              <Stack tokens={{ padding: "0px 20px", childrenGap: 10 }}>
                <Text variant="xLarge">Trips</Text>
                <Trips tripsData={trips} />
              </Stack>
            }
          </>
      }
    </Stack>
  )
}
