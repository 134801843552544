import React from 'react';
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { useStyles } from './index.styles';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { Form, Formik } from 'formik';
import { validationSchema } from './validations';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import {
  CommunicationChannels,
  CommunicationChannelsVariables,
  CommunicationChannels_communicationChannels_nodes,
} from '../list/__generated__/CommunicationChannels';
import { CommunicationChannelEntityValues } from './types';
import { CHANNEL_INITIAL_VALUES } from './constant';
import {
  CommunicationChannelCreate,
  CommunicationChannelCreateVariables,
} from './__generated__/CommunicationChannelCreate';
import {
  CommunicationChannelUpdate,
  CommunicationChannelUpdateVariables,
} from './__generated__/CommunicationChannelUpdate';
import { TABLE_ROWS } from 'common/constants';
import { CommunicationChannelsOrderBy } from 'common/types/globalTypes';
import { BasicForm } from './BasicForm';

const COMMUNICATION_CHANNEL = loader('../list/CommunicationChannels.graphql');
const CREATE_COMMUNICATION_CHANNEL = loader(
  './CommunicationChannelCreate.graphql'
);
const UPDATE_COMMUNICATION_CHANNEL = loader(
  './CommunicationChannelUpdate.graphql'
);
interface ChannelFormProps {
  channelData: CommunicationChannels_communicationChannels_nodes | null;
  visible: boolean;
  onDismiss: () => void;
  isNew: boolean;
}

export const CreateCommunicationChannelForm: React.FC<ChannelFormProps> = ({
  visible,
  onDismiss,
  channelData,
  isNew,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();

  let initialValues: CommunicationChannelEntityValues = CHANNEL_INITIAL_VALUES;
  if (channelData) {
    initialValues = {
      ...channelData,
    };
  }
  const [
    createCommunicationChannel,
    { loading: createCommunicationChannelLoading },
  ] = useMutation<
    CommunicationChannelCreate,
    CommunicationChannelCreateVariables
  >(CREATE_COMMUNICATION_CHANNEL, {
    errorPolicy: 'all',
  });

  const [
    updateCommunicationChannel,
    { loading: updateCommunicationChannelLoading },
  ] = useMutation<
    CommunicationChannelUpdate,
    CommunicationChannelUpdateVariables
  >(UPDATE_COMMUNICATION_CHANNEL, {
    errorPolicy: 'all',
  });

  const constructInputData = (values: CommunicationChannelEntityValues) => {
    return {
      name: values.name!,
      description: values.description,
      isMessages: values.isMessages,
      isReports: values.isReports,
    };
  };

  const handleSubmit = async (values: CommunicationChannelEntityValues) => {
    if (isNew) {
      const { errors } = await createCommunicationChannel({
        variables: {
          input: {
            communicationChannel: [
              {
                ...constructInputData(values),
              },
            ],
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<
            CommunicationChannels,
            CommunicationChannelsVariables
          >({
            query: COMMUNICATION_CHANNEL,
          });
          const updatedChannelsData: CommunicationChannels = {
            communicationChannels: {
              ...cacheData?.communicationChannels!,
              pageInfo: cacheData?.communicationChannels?.pageInfo!,
              nodes: [
                data?.communicationChannelCreate?.communicationChannels![0]!,
                ...cacheData?.communicationChannels?.nodes!,
              ],
            },
          };

          cache.writeQuery<
            CommunicationChannels,
            CommunicationChannelsVariables
          >({
            query: COMMUNICATION_CHANNEL,
            data: updatedChannelsData,
            variables: {
              first: TABLE_ROWS,
              filter: { notificationTypeId: { isNull: true } },
              orderBy: [CommunicationChannelsOrderBy.NAME_ASC],
            },
          });
        },
      });
      if (!errors) {
        addToast('Channel Created Successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    } else {
      const { errors } = await updateCommunicationChannel({
        variables: {
          input: {
            communicationChannelsUpdate: [
              {
                id: channelData?.id!,
                rowTimestamp: channelData?._rowTimestamp!,
                communicationChannelPatch: {
                  ...constructInputData(values),
                },
              },
            ],
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: COMMUNICATION_CHANNEL,
            variables: {
              first: TABLE_ROWS,
              filter: { notificationTypeId: { isNull: true } },
              orderBy: [CommunicationChannelsOrderBy.NAME_ASC],
            },
          },
        ],
      });
      if (!errors) {
        addToast('Channel Updated Successfully', {
          appearance: 'success',
        });
      } else
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
    }
  };
  const loading =
    createCommunicationChannelLoading || updateCommunicationChannelLoading;
  const headerTitle = isNew ? 'Create Channel' : 'Update Channel';

  return (
    <Stack>
      <Formik<CommunicationChannelEntityValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, dirty, resetForm, errors, isSubmitting }) => {
          const onClose = () => {
            resetForm();
            onDismiss();
          };
          const onSubmit = async () => {
            await submitForm();
            resetForm();
            onDismiss();
          };
          const onCancel = () => {
            resetForm();
            onDismiss();
          };

          return (
            <Form>
              <Modal isOpen={visible} isBlocking={true} onDismiss={onDismiss}>
                <Stack className={styles.container}>
                  <Stack
                    horizontal
                    horizontalAlign={'space-between'}
                    className={styles.dialogStyles}
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <Text variant={'xLarge'}>{headerTitle}</Text>
                      <UnsavedIndicator
                        visible={!isNew && dirty && !isSubmitting}
                      />
                    </Stack>
                    <CloseButton onClick={onClose} />
                  </Stack>
                  <BasicForm />
                  {loading && <ProgressIndicator />}

                  <Stack
                    horizontalAlign="space-between"
                    className={styles.buttonContainer}
                    horizontal
                    tokens={{ childrenGap: 6, padding: 25 }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <PrimaryButton
                        disabled={!dirty || Object.keys(errors).length > 0}
                        text={headerTitle}
                        onClick={onSubmit}
                      />
                      <DefaultButton onClick={onCancel} text="Cancel" />
                    </Stack>
                  </Stack>
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
