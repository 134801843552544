import React from 'react';
import { Document, Page, PDFViewer, View } from '@react-pdf/renderer';
import { commonPdfStyles } from 'common/components/PdfViewModal/common.stylesheet';

import { SignatureSection } from './SignatureSection';
import { Table } from './Table';
import { Transaction } from './Transaction';
import { AccountingEntryInvoice } from 'ap/signing/accountingEntry/view/__generated__/AccountingEntryInvoice';
import { InvoiceTransactionTypes } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceTransactionTypes';

export interface PdfDocumentProps {
  invoiceData?: AccountingEntryInvoice;
  transactionTypeData?: InvoiceTransactionTypes;
}

export const PdfDocument: React.FC<PdfDocumentProps> = ({
  invoiceData,
  transactionTypeData,
}) => {
  return (
    <PDFViewer style={{ flex: 1 }}>
      <Document>
        <Page size="A4" style={commonPdfStyles.page}>
          <View style={{ flex: 1 }}>
            <Transaction invoiceData={invoiceData} />
            <Table
              data={invoiceData!}
              transactionTypeData={transactionTypeData}
            />
            {invoiceData?.invoice?.approvalHistorySignaturesByEntityId.nodes &&
              invoiceData?.invoice?.approvalHistorySignaturesByEntityId.nodes
                .length > 0 && (
                <View style={{ top: 20, left: 10 }}>
                  <SignatureSection invoiceData={invoiceData} />
                </View>
              )}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
