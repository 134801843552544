import { Stack } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
  FormHookTextField,
} from 'common/components/FormHooksFields';
import { FormHookCheckBox } from 'common/components/FormHooksFields/FormHookCheckBox';
import Decimal from 'decimal.js';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  PurchaseOrderItemFormProps,
  PurchaseOrderItemLayout,
} from '../interface';

type PurchaseTypeItemProps = PurchaseOrderItemLayout & {
  children?: React.ReactNode;
};

export const PurchaseTypeItem: React.FC<PurchaseTypeItemProps> = ({
  selectedItemType,
  commonData,
  purchaseOrderData,
  isNew,
  children,
}) => {
  const { purchaseOrder } = { ...purchaseOrderData };
  const { isTaxAllowed, isDiscountAllowed } = { ...selectedItemType };
  const expenditureTypeOption = useMemo(() => {
    if (
      selectedItemType?.isExpenditureTypeAllowed &&
      !selectedItemType.isUnitOfMeasureRequired
    )
      return (
        commonData?.expenditureTypes?.nodes.map((item) => ({
          key: item.id!,
          text: item.expenditureType! || '',
          ...item,
        })) || []
      );
    else {
      return (
        selectedItemType?.unitOfMeasureGroup?.expenditureTypes.nodes.map(
          (item) => ({
            key: item.id!,
            text: item.expenditureType! || '',
            ...item,
          })
        ) || []
      );
    }
  }, [selectedItemType, commonData]);
  const { setValue, watch } = useFormContext<PurchaseOrderItemFormProps>();

  const calculateAmount = () => {
    const watchItemQuantity = watch('itemQuantity');
    const watchItemUnitPrice = watch('itemUnitPrice');
    const extendedAmount = new Decimal(watchItemQuantity || 1).times(watchItemUnitPrice || 0).toNumber().toFixed(2);
    setValue('itemExtendedAmount', extendedAmount);
  }

  const fieldDisabled = !purchaseOrder?._isUpdatable && !isNew;

  return (
    <Stack
      tokens={{
        childrenGap: 10,
      }}
    >
      <Stack tokens={{ childrenGap: 20, padding: '20px 20px' }}>
        <Stack horizontal grow tokens={{ childrenGap: 20 }}>
          {selectedItemType?.isExpenditureTypeAllowed && (
            <Stack.Item style={{ width: 255 }}>
              <FormHookDropdown
                label="Expenditure Type"
                placeholder="Select"
                options={expenditureTypeOption}
                name="expenditureTypeId"
                disabled={fieldDisabled}
              />
            </Stack.Item>
          )}

          <Stack.Item grow={2}>
            <FormHookTextField
              name="description"
              label="Description"
              placeholder="Description"
              disabled={fieldDisabled}
            />
          </Stack.Item>

          <Stack grow={2} />
        </Stack>

        <Stack horizontal grow tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ width: 250 }}>
            <FormHookAmount
              name="itemQuantity"
              label="Quantity"
              placeholder="Quantity"
              fixedDecimalScale
              decimalScale={0}
              allowNegative={false}
              thousandSeparator={false}
              disabled={fieldDisabled}
              onAmountChange={() => calculateAmount()}
            />
          </Stack.Item>

          <Stack.Item grow={1}>
            <FormHookAmount
              name="itemUnitPrice"
              label="Rate"
              placeholder="Rate"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={false}
              thousandSeparator
              disabled={fieldDisabled}
              onAmountChange={() => calculateAmount()}
            />
          </Stack.Item>

          <Stack.Item grow={1}>
            <FormHookAmount
              name="itemExtendedAmount"
              label="Item Total"
              placeholder="Item Total"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={false}
              thousandSeparator
              disabled={fieldDisabled}
            />
          </Stack.Item>

          <Stack grow={2} />
        </Stack>
      </Stack>

      {children}
      <Stack
        horizontal
        tokens={{
          childrenGap: 20,
          padding: '0px 20px 20px 20px',
        }}
      >
        {isDiscountAllowed && (
          <FormHookCheckBox
            name="isDiscounted"
            label="Discounted"
            disabled={fieldDisabled}
          />
        )}
        {isTaxAllowed && (
          <FormHookCheckBox
            name="isTaxable"
            label="Taxable"
            disabled={fieldDisabled}
          />
        )}
      </Stack>
    </Stack>
  );
};
