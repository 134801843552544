import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 1300,
  },
  sidebarContainer: {
    backgroundColor: theme.palette.neutralLighter,
    width: 300,
    height: '100%',
  },
  sidebarItem: {
    cursor: 'pointer',
    height: 50,
    padding: '10px 20px',
    ':hover': {
      backgroundColor: theme.palette.neutralLight,
    },
  },
  sidebarItemSelected: {
    cursor: 'pointer',
    height: 50,
    padding: '10px 20px',
    backgroundColor: theme.palette.neutralQuaternaryAlt,
  },
  indicator: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.orangeLight,
  },
  // linkHover: {
  //   ':hover': {
  //     backgroundColor: theme.palette.neutralQuaternaryAlt,
  //   },
  // },
}));
