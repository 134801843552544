import {
  Callout,
  DirectionalHint,
  Stack,
  Text,
  useTheme,
} from '@fluentui/react';
import { getGlobalDateFormat } from 'common/utils/dateFormats';
import React, { useState } from 'react';
import { useStyles } from './index.styles';
import { useId } from '@fluentui/react-hooks';
import { useCommonStyles } from 'common/styles';
import clsx from 'clsx';

interface StampItemProps {
  label: string;
  value?: string | null;
  colorClass: string;
}

const StampItem: React.FC<StampItemProps> = ({ label, value, colorClass }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      tokens={{ childrenGap: 5, padding: 5 }}
      className={clsx(colorClass, styles.separatorBorder)}
      grow
    >
      <Stack.Item disableShrink>
        <Text className={clsx(colorClass, commonStyles.semibold)}>{label}</Text>
      </Stack.Item>
      <Text className={styles.value} nowrap>
        {value || '-'}
      </Text>
    </Stack>
  );
};

export interface StampOptions {
  _isTransactionCancelled: boolean | null | undefined;
  _isAccountingEntryStampedComplete: boolean | null | undefined;
  _accountingStampDate: string | null | undefined;
  _accountingStampUserName: string | null | undefined;
  _accountingStampTransactionReference: string | null | undefined;
}

interface StamperViewProps {
  invoiceDetails: StampOptions;
}

export const StamperView: React.FC<StamperViewProps> = ({ invoiceDetails }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const containerId = useId('container');

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const isVoided =
    invoiceDetails._isTransactionCancelled &&
    !invoiceDetails._isAccountingEntryStampedComplete;
  const colorClass = isVoided ? styles.grey : styles.red;

  return (
    <Stack
      className={clsx(
        styles.fixedWidth,
        styles.border,
        colorClass,
        isCalloutVisible && styles.noBottomBorder
      )}
      onMouseEnter={() => setIsCalloutVisible(true)}
      onMouseLeave={() => setIsCalloutVisible(false)}
      id={containerId}
    >
      <Stack grow tokens={{ padding: 1 }} horizontalAlign="center">
        <Text
          variant="xLarge"
          className={clsx(colorClass, commonStyles.semibold)}
        >
          {isVoided ? 'VOIDED' : 'ENTERED'}
        </Text>
      </Stack>
      <StampItem
        label="TRANSACTION #"
        value={invoiceDetails._accountingStampTransactionReference}
        colorClass={colorClass}
      />
      {isCalloutVisible && (
        <Callout
          className={clsx(
            styles.fixedWidth,
            colorClass,
            styles.border,
            styles.noBoxShadow,
            styles.noTopBorder
          )}
          styles={{
            calloutMain: { backgroundColor: theme.palette.neutralLighterAlt },
          }}
          gapSpace={-3}
          target={`#${containerId}`}
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomCenter}
          onDismiss={() => setIsCalloutVisible(false)}
        >
          <StampItem
            label="USER"
            value={invoiceDetails._accountingStampUserName}
            colorClass={colorClass}
          />
          <StampItem
            label="DATE"
            value={
              invoiceDetails._accountingStampDate &&
              getGlobalDateFormat(invoiceDetails._accountingStampDate)
            }
            colorClass={colorClass}
          />
        </Callout>
      )}
    </Stack>
  );
};
