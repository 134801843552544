import { IDropdownOption, IStackStyles, TooltipHost } from '@fluentui/react';
import {
  FormHookAmount,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import {
  AutoCompleteTextField,
  LookUpProps,
} from 'common/components/FormHooksFields/AutoCompleteTextField';
import { FormHookTextField } from 'common/components/FormHooksFields/FormHookTextField';
import { ColumnData } from 'common/components/SearchBar';
import React from 'react';
import { PurchaseOrderItemFormProps } from '../interface';
import { useFormContext } from 'react-hook-form';

const lookUpCalloutStyles: Partial<IStackStyles> = {
  root: {
    maxHeight: 400,
    padding: 10,
  },
};

export interface FormFieldProps {
  baseField: string;
  columnData: ColumnData;
  index: number;
  _isUpdatable?: boolean | null | undefined;
  dropdownValues: {
    productionIncentivesOptions: IDropdownOption[];
    tax1099T4Options: IDropdownOption[];
  };
  refCodesOptions: {
    refCode1Options: IDropdownOption[];
    refCode2Options: IDropdownOption[];
    refCode3Options: IDropdownOption[];
    refCode4Options: IDropdownOption[];
  };
  onSelectAccount: (lookupData: LookUpProps) => void;
  businessUnitId: string | null;
}

export const FormField: React.FC<FormFieldProps> = ({
  baseField,
  columnData,
  dropdownValues,
  index,
  onSelectAccount,
  refCodesOptions,
  _isUpdatable,
  businessUnitId,
}) => {
  const isRowUpdatable = _isUpdatable;
  const { watch } = useFormContext<PurchaseOrderItemFormProps>();
  const watchPurchaseOrderItemDistribution = watch(
    'purchaseOrderItemDistribution'
  );

  switch (columnData.key) {
    case 'delete':
      return null;
    case 'accountReference':
      return (
        <TooltipHost
          content={
            watchPurchaseOrderItemDistribution?.[index]._accountName ?? ''
          }
        >
          <AutoCompleteTextField
            id={`accountReference${columnData.key}${index}`}
            name={`${baseField}.${columnData.key}`}
            width={columnData.maxWidth!}
            businessUnitId={businessUnitId}
            lookUpCalloutWidth={500}
            onVendorSelect={onSelectAccount}
            underlined
            disabled={!isRowUpdatable}
            lookupCalloutStyles={lookUpCalloutStyles}
          />
        </TooltipHost>
      );
    case 'projectReference':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          max={4}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'setReference':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          max={4}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode1':
      return (
        <FormHookDropdown
          options={refCodesOptions.refCode1Options}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 150 }}
          isCustomTitle
        />
      );
    case 'referenceCode2':
      return (
        <FormHookDropdown
          options={refCodesOptions.refCode2Options}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 150 }}
          isCustomTitle
        />
      );
    case 'referenceCode3':
      return (
        <FormHookDropdown
          options={refCodesOptions.refCode3Options}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth}
          underlined
          calloutProps={{ calloutWidth: 150 }}
          disabled={!isRowUpdatable}
          isCustomTitle
        />
      );
    case 'referenceCode4':
      return (
        <FormHookDropdown
          options={refCodesOptions.refCode4Options}
          name={`${baseField}.${columnData.key}`}
          width={columnData.maxWidth}
          underlined
          calloutProps={{ calloutWidth: 150 }}
          disabled={!isRowUpdatable}
          isCustomTitle
        />
      );
    case 'referenceCode5':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          maxLength={3}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode6':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          maxLength={3}
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'referenceCode7':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          disabled={!isRowUpdatable}
          style={{ width: columnData.maxWidth }}
        />
      );
    case 'productionIncentiveId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={dropdownValues.productionIncentivesOptions}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 200 }}
        />
      );
    case 'tax1099T4TypeId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={dropdownValues.tax1099T4Options}
          width={columnData.maxWidth}
          underlined
          disabled={!isRowUpdatable}
          calloutProps={{ calloutWidth: 200 }}
        />
      );

    case 'scheduledAmount':
      return (
        <FormHookAmount
          name={`${baseField}.${columnData.key}`}
          style={{ width: columnData.maxWidth, textAlign: 'right' }}
          fixedDecimalScale
          decimalScale={2}
          maxLength={15}
          allowNegative
          underlined
          disabled={!isRowUpdatable}
        />
      );
    default:
      return null;
  }
};
