import { makeStyles, FontSizes } from '@fluentui/react';
const RED_700 = '#D32F2F';
const RED_800 = '#C62828';
const RED_900 = '#B71C1C';
export const useStyles = makeStyles((theme) => ({
  neutralLighterAlt: {
    color: theme.palette.neutralLighterAlt,
  },
  neutralDark: {
    color: theme.palette.neutralDark,
  },
  statusText: {
    color: theme.palette.accent,
  },
  iconStyle: {
    fontSize: FontSizes.size18,
    color: theme.palette.themePrimary,
    marginLeft: 10,
  },
  callout: {
    minWidth: 150,
    maxWidth: 200,
  },
  statusIconStyle: {
    fontSize: FontSizes.size18,
    color: theme.palette.themePrimary,
    marginRight: 10,
  },
  headerStyle: {
    zIndex: 10,
    padding: 25,
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.33)`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  footerStyle: {
    backgroundColor: theme.palette.neutralLighterAlt,
    boxShadow: `-1px -4px 13px rgba(0, 0, 0, 0.15)`,
  },
  footerBackground: {
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  headerStyle2: {
    zIndex: 10,
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.33)`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  detailsContainer: {
    flex: 1,
    margin: 24,
  },
  fileIcon: {
    fontSize: FontSizes.size18,
    color: theme.palette.themePrimary,
    marginRight: 10,
  },
  approvalConfirm: {
    transition: '200ms',
  },
  tagsContainer: {
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
    marginBottom: 20,
  },
  footerContainer: {
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
  },
  declineButton: {
    color: 'white',
    backgroundColor: RED_700,
    borderColor: RED_700,
    borderStyle: 'solid',
    borderWidth: 1,
    ':hover': {
      color: 'white',
      backgroundColor: RED_800,
      borderColor: RED_800,
    },
    ':active': {
      color: 'white',
      backgroundColor: RED_900,
      borderColor: RED_900,
    },
  },
}));
