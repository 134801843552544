import { ColumnActionsMode } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const getColumns = () => {
  const columns: ColumnData[] = [
    {
      key: 'phraseType',
      name: 'Section',
      fieldName: 'phraseType',
      minWidth: 120,
      maxWidth: 120,
      ...commonColumnProps,
    },
    {
      key: 'headerTitle',
      name: 'Title',
      fieldName: 'headerTitle',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'headerCityFromTo',
      name: 'city/Areas',
      fieldName: 'headerCityFromTo',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
    },
    {
      key: 'bodyArea1',
      name: 'Detail Information',
      isMultiline: true,
      fieldName: 'bodyArea1',
      minWidth: 300,
      maxWidth: 300,
      ...commonColumnProps,
    },

    {
      key: 'action',
      name: '',
      fieldName: 'action',
      minWidth: 80,
      maxWidth: 80,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
      isResizable: false,
    },
  ];
  return columns;
};
