import { FontWeights, makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.fonts.large,
    flex: '0 1 auto',
    borderTop: `4px solid ${theme.palette.themePrimary}`,
    color: theme.palette.neutralPrimary,
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '12px 12px 14px 24px',
    cursor: 'move',
    position: 'absolute',
    zIndex: 99,
    width: '95.5%',
    backgroundColor: theme.palette.white,
  },
  title: {
    display: 'inline-block',
    maxWidth: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  container: {
    width: 800,
    height: 'calc(100vh - 60px)',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  hidden: {
    width: 0,
    height: 0,
  },
  content: {
    padding: '0 10px 10px 10px',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 64,
  },
  viewer: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  scrollableContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
