import {
  DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint, IColumn, IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles, IRenderFunction, SelectionMode, Stack,
  Text, TooltipHost
} from '@fluentui/react';
import clsx from 'clsx';
import { ActiveLink } from 'common/components/ActiveRowLink';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import React from 'react';
import { GetPayCycleDetails_payCycle_payments_nodes_invoicePayments } from '../../__generated__/GetPayCycleDetails';
import { columns } from './column.data';
import { useStyles } from './index.styles';

interface PayCycleItem
  extends GetPayCycleDetails_payCycle_payments_nodes_invoicePayments {
  corporatePeriodId: string;
  controlTotalAmount: string;
  department: string;
  invoiceNumber: string;
  vendorReference: string;
  invoiceId: string;
  isTransactionPayment: boolean;
}
export interface PaymentTransactionsProps {
  selectedTransaction: string | number | undefined;
  onTransactionClick?: (id: string | number | undefined) => void;
  selectedTransactionData?: GetPayCycleDetails_payCycle_payments_nodes_invoicePayments;
}

export const PaymentTransactions: React.FC<PaymentTransactionsProps> = ({
  onTransactionClick,
  selectedTransaction,
  selectedTransactionData,
}) => {
  let payCycleInvoices: PayCycleItem[] = [];
  const styles = useStyles();
  const transformData = selectedTransactionData?.nodes.map(
    (payCycle) =>
      ({
        corporatePeriodId:
          payCycle.invoice?.companyCorporatePeriod?._periodYear,
        controlTotalAmount: payCycle.invoice?.controlTotalAmount,
        department: payCycle.invoice?.department?.name,
        invoiceNumber: payCycle.invoice?.invoiceNumber,
        vendorReference: payCycle.invoice?.vendorReference,
        invoiceId: payCycle.invoiceId,
        isTransactionPayment: payCycle._isTransactionPayment,
      } as PayCycleItem)
  );
  if (transformData) payCycleInvoices = transformData;

  const _renderItemColumn = (
    item: PayCycleItem,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof PayCycleItem
      ] as string;
      switch (column?.key) {
        case 'vendorReference':
          return (
            <Stack verticalAlign="center" className={styles.firstColumnStyle}>
              <ActiveLink
                key={item?.invoiceId}
                to={`${item.invoiceId}`}
                onClick={() => {
                  onTransactionClick?.(item.invoiceId);
                }}
              >
                {fieldContent && fieldContent}
              </ActiveLink>
            </Stack>
          );
        case 'controlTotalAmount':
          return (
            <Stack verticalAlign="center">
              <AmountTextView
                value={item.controlTotalAmount}
                className={styles.contentColumnAlignRight}
              />
            </Stack>
          );
        default:
          return (
            <Stack verticalAlign="center">
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const renderFooterColumn = (
    _item?: PayCycleItem,
    _index?: number,
    column?: IColumn
  ) => {
    if (
      column?.key === 'controlTotalAmount' &&
      selectedTransactionData?.aggregates?.sum?.appliedAmount
    ) {
      const totalAmount = selectedTransactionData?.aggregates?.sum
        ?.appliedAmount
        ? Number(
            selectedTransactionData?.aggregates?.sum?.appliedAmount
          ).toFixed(2)
        : '-';
      return (
        <AmountTextView
          value={totalAmount}
          className={clsx(styles.amount, styles.amountTotal)}
        />
      );
    }
    return null;
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props || !selectedTransactionData?.aggregates?.sum?.appliedAmount) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const { item } = { ...props };
    const customStyles: Partial<IDetailsRowStyles> = {};
    const tootTipMessage = 'Approved Payment Request';
    const tooltipVisible = item.isTransactionPayment;
    if (!props) {
      return null;
    }
    if (tooltipVisible) {
      customStyles.root = {
        backgroundColor: "#1dba6e1c",
      };
      return (
        <TooltipHost
          content={tootTipMessage}
          directionalHint={DirectionalHint.leftCenter}
        >
          <DetailsRow {...props} styles={customStyles} />
        </TooltipHost>
      );
    } else return <DetailsRow {...props} />;
  };

  return (
    <Stack tokens={{ padding: '0px 0px 20px 0px' }}>
      <DetailsList
        items={payCycleInvoices}
        compact={true}
        columns={columns}
        selectionMode={SelectionMode.none}
        getKey={(_, index) => index?.toString()!}
        setKey="multiple"
        onRenderItemColumn={_renderItemColumn}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderDetailsFooter={renderFooter}
        selectionPreservedOnEmptyClick={true}
        onRenderRow={onRenderRow}
      />
    </Stack>
  );
};
