import { TABLE_ROWS } from 'common/constants';
import { CorporateWorkgroupsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import {
  CorporateWorkgroups,
  CorporateWorkgroupsVariables,
} from './__generated__/CorporateWorkgroups';

type OrderLookup = {
  [direction in OrderDirection]: CorporateWorkgroupsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'workgroupNumber',
    {
      [OrderDirection.ASC]: [CorporateWorkgroupsOrderBy.WORKGROUP_NUMBER_ASC],
      [OrderDirection.DESC]: [CorporateWorkgroupsOrderBy.WORKGROUP_NUMBER_DESC],
    },
  ],
  [
    'name',
    {
      [OrderDirection.ASC]: [CorporateWorkgroupsOrderBy.NAME_ASC],
      [OrderDirection.DESC]: [CorporateWorkgroupsOrderBy.NAME_DESC],
    },
  ],
  [
    'description',
    {
      [OrderDirection.ASC]: [CorporateWorkgroupsOrderBy.DESCRIPTION_ASC],
      [OrderDirection.DESC]: [CorporateWorkgroupsOrderBy.DESCRIPTION_DESC],
    },
  ],
  [
    'businessUnit',
    {
      [OrderDirection.ASC]: [
        CorporateWorkgroupsOrderBy.BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        CorporateWorkgroupsOrderBy.BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'corporateChartOfAccount',
    {
      [OrderDirection.ASC]: [
        CorporateWorkgroupsOrderBy.CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        CorporateWorkgroupsOrderBy.CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__NAME_DESC,
      ],
    },
  ],
  [
    'productionPhase',
    {
      [OrderDirection.ASC]: [
        CorporateWorkgroupsOrderBy.PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        CorporateWorkgroupsOrderBy.PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_DESC,
      ],
    },
  ],
  [
    'isNewWorkgroupSelectable',
    {
      [OrderDirection.ASC]: [
        CorporateWorkgroupsOrderBy.IS_NEW_WORKGROUP_SELECTABLE_ASC,
      ],
      [OrderDirection.DESC]: [
        CorporateWorkgroupsOrderBy.IS_NEW_WORKGROUP_SELECTABLE_DESC,
      ],
    },
  ],
  [
    'status',
    {
      [OrderDirection.ASC]: [
        CorporateWorkgroupsOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        CorporateWorkgroupsOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC,
      ],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const defaultOrderBy = [
    CorporateWorkgroupsOrderBy.WORKGROUP_NUMBER_ASC,
    CorporateWorkgroupsOrderBy.PRIMARY_KEY_ASC,
  ];
  const orderByArray = [CorporateWorkgroupsOrderBy.PRIMARY_KEY_ASC];
  if (!sort) return defaultOrderBy;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return Array.from(new Set([...(orderBy ? orderBy : []), ...orderByArray]));
};

export const onHandleSearch = (
  showMore: boolean,
  sortOrderParam: SortOrder | undefined,
  corporateWorkGroups: CorporateWorkgroups | undefined
) => {
  const variables: CorporateWorkgroupsVariables = {
    first: TABLE_ROWS,
    orderBy: toOrderByVariable(sortOrderParam),
    after: showMore
      ? corporateWorkGroups?.corporateWorkgroups?.pageInfo.endCursor
      : undefined,
  };
  return {
    queryVariables: variables,
  };
};
