import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { TripPatch, TripUpdateInput } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { TravelAuthorization_travelAuthorization } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { setUserDefaults } from 'utility/cache/ui';
import { setTripsModalState } from '..';
import {
  TravelerAuthorizationCommonData,
  TravelerAuthorizationCommonData_tripSections_nodes,
} from '../../__generated__/TravelerAuthorizationCommonData';
import { FormView } from './FormView';
import { Trip, TripVariables } from './__generated__/Trip';
import { TripCreate, TripCreateVariables } from './__generated__/TripCreate';
import { TripUpdate, TripUpdateVariables } from './__generated__/TripUpdate';
import { TripCreateFormValues } from './types';
import {
  getDefaultValues,
  getDeletedTripItems,
  getNewTripDistributions,
  getNewTripItems,
  getRemovedTripDistributions,
  getSections,
  getTripPatch,
  getTripVariables,
  getUpdateTripItems,
  getUpdatedTripDistributions,
} from './utils';
import { validationSchema } from './validations';
import { TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals } from 'travelAuthorization/TravelPlan/list/__generated__/TravelAuthorizationSearchFilterTotals';
const TRIP_CREATE = loader('./TripCreate.graphql');
const TRIP_UPDATE = loader('./TripUpdate.graphql');
const TRIP = loader('./Trip.graphql');
const COMMON_DATA = loader('../../TravelerAuthorizationCommonData.graphql');

export type SectionProps = Omit<
  TravelerAuthorizationCommonData_tripSections_nodes,
  'tripSectionItems'
>;

interface TripModalProps {
  onSave?: () => void;

  inputsDisabled: boolean;
  travelAuthorizationData:
    | Partial<TravelAuthorization_travelAuthorization>
    | null
    | undefined;
  onTripUpdate?: (data: TripUpdate | null | undefined) => void;
  businessUnitId: string | null;
  isOpen: boolean;
}
export const TripsModal: React.FC<TripModalProps> = ({
  travelAuthorizationData,
  inputsDisabled,
  onSave,
  onTripUpdate,
  businessUnitId,
  isOpen,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const [layoutAdded, setLayoutAdded] = useState<string | null>(null);
  const { addToast } = useToasts();
  const tripsModalState = useReactiveVar(setTripsModalState);
  const [tripItemsState, setTripItemsState] = useState<
    Map<string, SectionProps>
  >(new Map());
  const { data: commonData } = useQuery<TravelerAuthorizationCommonData>(
    COMMON_DATA,
    { nextFetchPolicy: 'cache-only' }
  );
  const [
    createTrip,
    { data: tripCreateResponseData, loading: createTripLoading },
  ] = useMutation<TripCreate, TripCreateVariables>(TRIP_CREATE, {
    fetchPolicy: 'network-only',
  });

  const [updateTrip, { loading: updateTripLoading }] = useMutation<
    TripUpdate,
    TripUpdateVariables
  >(TRIP_UPDATE, {
    errorPolicy: 'all',
  });

  const {
    data: tripData,
    loading: loadingTrip,
    refetch,
  } = useQuery<Trip, TripVariables>(TRIP, {
    variables: { id: tripsModalState?.trip?.id! },
    skip: tripsModalState.isNew,
  });

  const formMethods = useForm<TripCreateFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { reset, handleSubmit, trigger } = { ...formMethods };

  const onHandleSubmit = async (
    values: TripCreateFormValues,
    closeAfterComplete: boolean,
    addMore: boolean
  ) => {
    const isEdit = !tripsModalState.isNew;
    if (isEdit) {
      const defaultValues = getDefaultValues(
        tripData,
        commonData,
        userDefaults
      );
      const { trip, tripItems } = { ...defaultValues } as TripCreateFormValues;
      const tripPatch: TripPatch = getTripPatch(trip, values.trip);

      const newTripItems = getNewTripItems(values.tripItems);
      const updatedTripItems = getUpdateTripItems(tripItems, values.tripItems);
      const { deletedTripItems, deletedData } = getDeletedTripItems(
        tripItems,
        values.tripItems
      );

      const tripDistributionCreate = getNewTripDistributions(
        values,
        userDefaults
      );
      const tripDistributionDelete = getRemovedTripDistributions(
        tripData,
        values,
        deletedData
      );

      const tripDistributionUpdate = getUpdatedTripDistributions(
        values,
        defaultValues,
        deletedTripItems
      );

      const { errors, data } = await updateTrip({
        variables: {
          input: {
            tripId: tripsModalState?.trip?.id!,
            rowTimestamp: tripData?.trip?._rowTimestamp!,
            tripPatch: tripPatch,
            tripItemCreate: newTripItems,
            tripItemUpdate: updatedTripItems ? updatedTripItems : undefined,
            tripItemDelete: deletedTripItems,
            tripDistributionDelete,
            tripDistributionCreate,
            tripDistributionUpdate,
          } as TripUpdateInput,
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<Trip, TripVariables>({
            query: TRIP,
            variables: {
              id: tripsModalState?.trip?.id!,
            },
          });
          if (cacheData) {
            const updatedData: Trip = {
              trip: {
                ...cacheData?.trip!,
                ...data?.tripUpdate?.trip!,
              },
            };
            cache.writeQuery<Trip, TripVariables>({
              query: TRIP,
              variables: {
                id: tripsModalState?.trip?.id!,
              },
              data: updatedData!,
            });
          }
        },
      });

      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Trip item updated successfully.', {
          appearance: 'success',
        });
        onTripUpdate?.(data);
        setTripsModalState({
          ...tripsModalState,
          isModalOpen: !closeAfterComplete,
        });
        onSave?.();
      }
    } else {
      const variables = getTripVariables(
        values,
        travelAuthorizationData,
        userDefaults
      );

      const { errors, data } = await createTrip({
        variables,

        update: (cache, { data }) => {
          try {
            cache.modify({
              fields: {
                travelAuthorizationSearchFilterTotals(
                  existing: TravelAuthorizationSearchFilterTotals_travelAuthorizationSearchFilterTotals
                ) {
                  if (existing?.totalCount1 !== 0 && data?.tripCreate?.trip) {
                    const controlTotalAmount =
                      Number(existing?.totalAmount1) +
                        Number(data?.tripCreate?.trip?.controlTotalAmount) ?? 0;
                    return {
                      ...existing,
                      totalAmount1: controlTotalAmount.toString(),
                      totalCount1: existing.totalCount1
                        ? existing.totalCount1 + 1
                        : 1,
                    };
                  } else {
                    return {
                      ...existing,
                    };
                  }
                },
              },
            });
          } catch (error) {}
        },
      });

      if (!errors) {
        addToast('Trip Created successfully', { appearance: 'success' });
        setTripsModalState({
          isModalOpen: !closeAfterComplete,
          isNew: addMore,
          trip: addMore ? undefined : data?.tripCreate?.trip!,
        });
        if (addMore) {
          setTripItemsState(new Map());
          const defaultValues = getDefaultValues(
            undefined,
            commonData,
            userDefaults
          );
          reset({
            ...defaultValues,
          } as TripCreateFormValues);
          trigger();
        }
      } else {
        addToast(errors[0].message, { appearance: 'error' });
      }
    }
  };

  useEffect(() => {
    if (tripCreateResponseData) {
      const { _tripSectionLayout } = {
        ...tripCreateResponseData?.tripCreate?.trip?.tripItems.nodes[0],
      };
      if (_tripSectionLayout) {
        setLayoutAdded(_tripSectionLayout);
      }
    }
  }, [tripCreateResponseData]);

  const setDefault = useCallback(() => {
    if (tripData) {
      const defaultValues = getDefaultValues(
        tripData,
        commonData,
        userDefaults
      );
      const { map, initialLayout } = getSections(
        tripData,
        commonData,
        layoutAdded
      );

      if (!tripsModalState.isNew) {
        setLayoutAdded(initialLayout);
        if (map) setTripItemsState(map);
      }

      reset(defaultValues);
    }
  }, [commonData, reset, tripData, tripsModalState]);

  useEffect(() => {
    setDefault();
    if (tripsModalState.isNew) {
      trigger();
    }
  }, [setDefault, trigger, tripData, tripsModalState.isNew]);

  const isLoading = createTripLoading || updateTripLoading;

  return (
    <FormProvider {...formMethods}>
      <FormView
        isOpen={isOpen}
        refetch={refetch}
        onSave={(closeAfterComplete, addMore) => {
          handleSubmit((values) => {
            onHandleSubmit(values, closeAfterComplete, addMore);
          })();
        }}
        tripItemsState={tripItemsState}
        onTripItemsUpdate={(mapData, layoutAdded, adding) => {
          if (adding) {
            setTripItemsState(() => {
              const newMap = new Map(Array.from(mapData));
              return newMap;
            });
          }
          setLayoutAdded(layoutAdded);
        }}
        businessUnitId={businessUnitId}
        inputsDisabled={inputsDisabled}
        travelAuthorizationData={travelAuthorizationData}
        layoutAdded={layoutAdded}
        commonData={commonData}
        tripData={tripData?.trip}
        loading={isLoading}
        createTripLoading={createTripLoading}
        updateTripLoading={updateTripLoading}
        loadingTrip={loadingTrip}
      />
    </FormProvider>
  );
};
