import { useQuery } from '@apollo/client';
import { AnimationClassNames, Stack } from '@fluentui/react';
import clsx from 'clsx';
import { ModalWrapper } from 'common/components/ModalWrapper';
import { loader } from 'graphql.macro';
import { PurchaseOrder } from 'purchaseOrder/view/__generated__/PurchaseOrder';
import React, { useState } from 'react';
import { Header } from './Header';
import { InvoiceScheduleView } from './InvoiceScheduleView';
import { InvoiceSchedulesItem, ScheduleList } from './ScheduleList';
import { PurchaseOrderInvoiceSchedules_purchaseOrderInvoiceSchedules_nodes } from './ScheduleList/__generated__/PurchaseOrderInvoiceSchedules';
import { HeaderInfo, HeaderInfoVariables } from './__generated__/HeaderInfo';
import { useStyles } from './index.styles';
const HEADER_INFO = loader('./HeaderInfo.graphql');

type InvoiceScheduleType =
  PurchaseOrderInvoiceSchedules_purchaseOrderInvoiceSchedules_nodes;

interface InvoiceScheduleProps {
  isOpen: boolean;
  purchaseOrderData: PurchaseOrder | undefined;
  onDismiss: () => void;
}

export const InvoiceSchedule: React.FC<InvoiceScheduleProps> = ({
  isOpen,
  purchaseOrderData,
  onDismiss,
}) => {
  const styles = useStyles();
  const SlideLeftOut = AnimationClassNames.slideLeftOut400;
  const slideRightIn = AnimationClassNames.slideRightIn400;

  const [listMode, setListMode] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<InvoiceSchedulesItem>();
  const [selectedRows, setSelectedRows] = useState<InvoiceSchedulesItem[]>([]);

  const containerStyle = clsx({
    [styles.defaultWidth]: true,
    [styles.defaultHeight]: true,
    [styles.customHeight]: isAdd,
  });

  const { data: headerInfoData } = useQuery<HeaderInfo, HeaderInfoVariables>(
    HEADER_INFO,
    {
      variables: {
        id: purchaseOrderData?.purchaseOrder?.entityDocumentId!,
      },
      skip: !purchaseOrderData?.purchaseOrder?.entityDocumentId,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const onCreate = () => {
    setListMode(false);
    setIsAdd(true);
    setIsEdit(false);
    setSelectedRows([]);
  };

  const onEditClick = (data: InvoiceScheduleType) => {
    setListMode(false);
    setIsEdit(true);
    setSelectedInvoice(data);
    setSelectedRows([]);
  };

  const onBack = () => {
    setListMode(true);
    setIsEdit(false);
    setIsAdd(false);
    setSelectedRows([]);
  };

  const handleDismiss = () => {
    onDismiss();
    setListMode(true);
    setIsEdit(false);
    setIsAdd(false);
    setSelectedRows([]);
  };

  if (isOpen)
    return (
      <ModalWrapper isOpen={isOpen} onDismiss={handleDismiss}>
        <Stack className={containerStyle}>
          <Header
            showList={listMode}
            purchaseOrderData={purchaseOrderData}
            headerInfo={headerInfoData}
            selectedRows={selectedRows}
            onCreate={onCreate}
            onBack={onBack}
            onDismiss={handleDismiss}
          />
          {listMode ? (
            <Stack className={!listMode ? SlideLeftOut : slideRightIn}>
              <ScheduleList
                purchaseOrderData={purchaseOrderData}
                onEditClick={onEditClick}
                onRowSelection={setSelectedRows}
              />
            </Stack>
          ) : (
            <InvoiceScheduleView
              listMode={listMode}
              invoiceSchedule={selectedInvoice}
              isEdit={isEdit}
              purchaseOrderData={purchaseOrderData}
              onBack={onBack}
            />
          )}
        </Stack>
      </ModalWrapper>
    );
  else return null;
};
