import { IconButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { useCommonStyles } from 'common/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DepartmentGroupsView } from '../view';
import { NetworkStatus, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { DepartmentGroups, DepartmentGroupsVariables, DepartmentGroups_departmentGroups_nodes } from './__generated__/DepartmentGroups';
import { TABLE_ROWS } from 'common/constants';
import { DepartmentGroupsOrderBy } from 'common/types/globalTypes';
import { onHandleSearch } from './utils';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { ColumnData } from 'common/components/SearchBar';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { ColumnStylesProps, onRenderItems } from './columns.render';
import { getColumns } from './column.data';
import { useStyles } from './index.styles';
import { InfiniteList } from 'common/components/InfiniteList';
import { DeleteDepartmentGroup } from './DeleteDepartmentGroup';
const DEPARTMENT_GROUPS = loader('./DepartmentGroups.graphql');
export type DepartmentGroupRow =
  DepartmentGroups_departmentGroups_nodes
  & {
    isTravel: string;
  };

export const DepartmentGroupsList = () => {
  const styles = useStyles()
  const commonStyles = useCommonStyles();
  const [selectedRows, setSelectedRows] = useState<DepartmentGroupRow[]>([]);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>([]);
  const renderRef = useRef(false);

  const columnStyles: ColumnStylesProps = {
    onRenderColumnStack: styles.onRenderColumnStack,
  };

  const {
    loading,
    data,
    networkStatus,
    refetch,
  } = useQuery<DepartmentGroups, DepartmentGroupsVariables>(
    DEPARTMENT_GROUPS,
    {
      variables: {
        first: TABLE_ROWS,
        orderBy: [
          DepartmentGroupsOrderBy.NAME_ASC,
          DepartmentGroupsOrderBy.PRIMARY_KEY_ASC,
        ],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;

  const handleSearch = async () => {
    setSelectedRows([])
    const { queryVariables } = onHandleSearch(sortOrderParam)
    refetch(queryVariables);
  }

  const handleSearchMemo = useCallback(handleSearch, [
    sortOrderParam,
  ])

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const onRefresh = () => {
    refetch()
  }

  const {
    nodes,
  } = { ...data?.departmentGroups }

  const transformedData: DepartmentGroupRow[] = refetching ? [] :
    nodes?.map((item) => ({
      ...item,
      isTravel: !!item.isTravelDepartmentGroup ? "Yes" : "No"
    })) || [];

  const _renderItemColumn = useCallback(
    onRenderItems(
      columnStyles
    ),
    [columnStyles]
  );

  const setCustomColumns = useCallback(() => {
    setGridColumns(getColumns());
  }, []);

  useEffect(() => {
    setCustomColumns()
  }, [setCustomColumns])

  useEffect(() => {
    if (renderRef.current) handleSearchMemo();
    else renderRef.current = true;
  }, [handleSearchMemo])

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">Department Groups</Text>
          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
          >
            <IconButton
              iconProps={{ iconName: 'refresh' }}
              onClick={onRefresh}
            />
            <DeleteDepartmentGroup
              selectedRows={selectedRows}
            />
            <DepartmentGroupsView />
          </Stack>
        </Stack>
      </Stack>
      <InfiniteList
        compact={false}
        loading={loading}
        items={transformedData}
        selectionMode={SelectionMode.multiple}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onSelectionChanged={setSelectedRows}
      />
    </>
  )
}
