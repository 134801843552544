import { CorporateAccountValues } from './types';

export const CORPORATE_ACCOUNT_VALUES: CorporateAccountValues = {
  account: null,
  chartOfAccountId: null,
  // lookupAccount: null,
  lookupName: null,
  travelAccountTypeId: null,
  departmentGroupId: null,
  subAccount: null,
  isTravelAccountForAllDepartments: false,
};
