import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  chipContainer: {
    width: 270,
    height: 230,
    overflowY: 'scroll',
    padding: 20,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
}));
