import { EntityDocumentsOrderBy } from '../../../common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { FilterArrayType, filterCombineType } from 'common/components/Filters';

type OrderLookup = {
  [direction in OrderDirection]: EntityDocumentsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'documentType',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC],
    },
  ],
  [
    'indexName',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_NAME_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_NAME_DESC],
    },
  ],
  [
    'indexReferenceNumber',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_REFERENCE_NUMBER_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_REFERENCE_NUMBER_DESC],
    },
  ],
  [
    'fileReference',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.FILE_REFERENCE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.FILE_REFERENCE_DESC],
    },
  ],
  [
    '_documentContents',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._DOCUMENT_CONTENTS_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._DOCUMENT_CONTENTS_DESC],
    },
  ],
  [
    'indexAmount',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.INDEX_AMOUNT_DESC],
    },
  ],
  [
    'usedTotal',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__USED_TOTAL_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__USED_TOTAL_DESC],
    },
  ],
  [
    'remainingTotal',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__REMAINING_TOTAL_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__REMAINING_TOTAL_DESC],
    },
  ],
  [
    'isoCode',
    {
      [OrderDirection.ASC]: [
        EntityDocumentsOrderBy.COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_ASC,
      ],
      [OrderDirection.DESC]: [
        EntityDocumentsOrderBy.COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_DESC,
      ],
    },
  ],
  [
    'comment',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy.COMMENT_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy.COMMENT_DESC],
    },
  ],
  [
    '_sharedByUser',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._SHARED_BY_USER_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._SHARED_BY_USER_DESC],
    },
  ],
  [
    '_uploadDate',
    {
      [OrderDirection.ASC]: [EntityDocumentsOrderBy._UPLOAD_DATE_ASC],
      [OrderDirection.DESC]: [EntityDocumentsOrderBy._UPLOAD_DATE_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
    EntityDocumentsOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];
  return [...(orderBy ? orderBy : []), ...orderByArray];
};

export const toFilterVariable = (
  filterList: FilterArrayType[],
  filterType: string
) =>
  filterList
    .filter((filter) => filter.combineType === filterCombineType.AND)
    .map((filter) => {
      switch (filter.filterKey) {
        case '_isSelected':
        case 'documentTypeId':
          return {
            [filter.filterKey]: {
              equalTo: filter.value,
            },
          };
        case 'indexName':
        case 'indexReferenceNumber':
        case '_documentContents':
        case 'comment':
        case 'fileReference':
          return {
            [filter.filterKey]: {
              [filterType]: filter.value,
            },
          };
        default:
          return undefined;
      }
    });

export const toFilterOrVariable = (filterList: FilterArrayType[]) =>
  filterList
    .filter((filter) => filter.combineType === filterCombineType.OR)
    .map((filter) => {
      switch (filter.filterKey) {
        case '_isFolderStatusActiveTransactions':
        case '_isFolderStatusEnteredTransactions':
        case '_isFolderStatusActivePayments':
        case '_isFolderStatusEnteredPayments':
          return {
            [filter.filterKey]: {
              equalTo: filter.value,
            },
          };
        default:
          return undefined;
      }
    });
