import React from 'react';
import { loader } from 'graphql.macro';
import { useToasts } from 'react-toast-notifications';
import {
  MessageUserCreate,
  MessageUserCreateVariables,
} from './__generated__/MessageUserCreate';
import { useMutation } from '@apollo/client';
import {
  CreateMessageData,
  CreateNewMessage,
} from 'common/components/CreateNewMessage';
import { MESSAGES_PER_PAGE } from 'common/constants';
const ADD_NEW_MESSAGE = loader('./MessageUserCreate.graphql');
const GET_USER_MESSAGES = loader('../GetUserMessages.graphql');

interface CreateNewMessageViewProps {
  onMessageCreate?: () => void;
}

export const NewMessage: React.FC<CreateNewMessageViewProps> = ({
  onMessageCreate,
}) => {
  const { addToast } = useToasts();

  const [createMessage, { loading }] = useMutation<
    MessageUserCreate,
    MessageUserCreateVariables
  >(ADD_NEW_MESSAGE, { errorPolicy: 'all' });

  const onSubmit = async (formData: CreateMessageData) => {
    const { messageComment, reminderDates, isResponseRequired, receivers } = {
      ...formData,
    };
    const { communicationChannel, department, user } = { ...receivers };
    const { errors } = await createMessage({
      variables: {
        input: {
          message: { messageComment, reminderDates, isResponseRequired },
          communicationChannel,
          department,
          user,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_MESSAGES,
          variables: {
            first: MESSAGES_PER_PAGE,
            filter: { _isOutgoingMessage: { equalTo: true } },
          },
        },
      ]
    });
    if (!errors) {
      onMessageCreate?.();
      addToast('Message sent successfully.', {
        appearance: 'success',
      });
    } else
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
  };

  return <CreateNewMessage onSubmit={onSubmit} loading={loading} />;
};
