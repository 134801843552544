import React, { useEffect } from 'react';
import { useCommonStyles } from 'common/styles';
import {
  TransactionsChart,
  DepartmentsChart,
  ProductionsChart,
  TagsChart,
  ApprovalsChart,
  PayableChart,
  DocumentsChart,
  LocationsChart,
  SuppliersChart,
  TransactionStatusChart,
  MyTransactionStatusChart,
  TravelAuthorizationChart,
} from './Charts';
import { Spinner, Stack, Text } from '@fluentui/react';
import {
  ChartContainer,
  ChartContainerProps,
  ChartSize,
} from './Charts/common';
import { ReactComponent as EmptyApprovals } from 'assets/svgs/chart-placeholders/approvals.svg';
import { ReactComponent as EmptyDocuments } from 'assets/svgs/chart-placeholders/documents.svg';
import { useQuery } from '@apollo/client';
import { UserDashboardCharts } from './__generated__/UserDashboardCharts';
import { loader } from 'graphql.macro';
import { ChartLayoutType } from 'common/types/globalTypes';
import { MyPOStatusChart, POStatusChart } from './Charts/POStatusChart';
import { MyTAStatusChart, TAStatusChart } from './Charts/TAStatusChart';
import { POTagsChart } from './Charts/POTagsChart';
import { isDashBoardScreen } from 'utility/cache/ui';

export const DASHBOARD_CHARTS_QUERY = loader('./UserDashboardCharts.graphql');

const chartLayoutToContainerProps = (
  layout: ChartLayoutType
):
  | Pick<ChartContainerProps<any>, 'chart' | 'size' | 'showOnEmpty'>
  | undefined => {
  switch (layout) {
    case ChartLayoutType.KPI_AGED_PAYABLES:
      return {
        chart: PayableChart,
        size: ChartSize.Medium,
      };
    case ChartLayoutType.KPI_APPROVAL_TYPES:
      return {
        chart: ApprovalsChart,
        size: ChartSize.Medium,
        showOnEmpty: {
          Icon: EmptyApprovals,
          text: 'No pending approvals.',
        },
      };
    case ChartLayoutType.KPI_BUSINESS_UNITS:
      return {
        chart: LocationsChart,
      };
    case ChartLayoutType.KPI_COMPANIES:
      return {
        chart: ProductionsChart,
      };
    case ChartLayoutType.KPI_DEPARTMENTS:
      return {
        chart: DepartmentsChart,
      };
    case ChartLayoutType.KPI_DOCUMENT_BREAK_DOWN:
      return {
        chart: DocumentsChart,
        size: ChartSize.Medium,
        showOnEmpty: {
          Icon: EmptyDocuments,
          text: "There aren't any recent files.",
        },
      };
    case ChartLayoutType.KPI_SUPPLIERS:
      return {
        chart: SuppliersChart,
      };
    case ChartLayoutType.KPI_TAGS:
      return {
        chart: TagsChart,
        size: ChartSize.Medium,
      };
    case ChartLayoutType.KPI_PO_TAGS:
      return {
        chart: POTagsChart,
        size: ChartSize.Medium,
      };
    case ChartLayoutType.KPI_TRANSACTION_TYPES:
      return {
        chart: TransactionsChart,
      };
    case ChartLayoutType.KPI_TRANSACTION_STATUS:
      return {
        chart: TransactionStatusChart,
      };
    case ChartLayoutType.KPI_MY_TRANSACTION_STATUS:
      return {
        chart: MyTransactionStatusChart,
      };
    case ChartLayoutType.KPI_TRAVEL_AUTHORIZATION_POLICY_BREAKDOWN:
      return {
        chart: TravelAuthorizationChart,
      };
    case ChartLayoutType.KPI_PO_STATUS:
      return {
        chart: POStatusChart,
      };
    case ChartLayoutType.KPI_MY_PO_STATUS:
      return {
        chart: MyPOStatusChart,
      };
    case ChartLayoutType.KPI_TA_STATUS:
      return {
        chart: TAStatusChart,
      };
    case ChartLayoutType.KPI_MY_TA_STATUS:
      return {
        chart: MyTAStatusChart,
      };
    default:
      return undefined;
  }
};

export const Dashboard: React.FC = () => {
  const commonStyles = useCommonStyles();
  const { data, loading } = useQuery<UserDashboardCharts>(
    DASHBOARD_CHARTS_QUERY,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );
  useEffect(() => {
    isDashBoardScreen(true);
    return () => {
      isDashBoardScreen(false);
    };
  }, []);

  return (
    <Stack style={{ height: '100%', overflowX: 'hidden' }}>
      <Stack className={commonStyles.listHeaderContainer}>
        <Text variant="xLargePlus">Spend Analysis</Text>
      </Stack>

      {loading ? (
        <Stack
          grow
          verticalFill
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
        >
          <Spinner label="Loading..." />
        </Stack>
      ) : (
        <Stack
          horizontal
          wrap
          tokens={{ childrenGap: 30, padding: '15px 30px' }}
          grow
        >
          {[...(data?.userDashboardWebChartsList || [])]
            ?.sort((c1, c2) => c1.chartPosition - c2.chartPosition)
            .map((c) => {
              const props = chartLayoutToContainerProps(c.chartLayout);
              return props ? (
                <ChartContainer
                  key={c.chartLayout}
                  title={c.caption}
                  {...props}
                />
              ) : null;
            })}
        </Stack>
      )}
    </Stack>
  );
};
