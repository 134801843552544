import {
  FormHookAmount,
  FormHookDropdown,
} from 'common/components/FormHooksFields';
import { FormHookTextField } from 'common/components/FormHooksFields/FormHookTextField';
import { ColumnData } from 'common/components/SearchBar';
import React from 'react';
import { GetEntityDocument } from '../../../__generated__/GetEntityDocument';
import { AutoCompleteFF1 } from '../CustomFields/AutoCompleteRefCode1';
import { AutoCompleteFF2 } from '../CustomFields/AutoCompleteRefCode2';
import { AutoCompleteFF3 } from '../CustomFields/AutoCompleteRefCode3';
import { AutoCompleteFF4 } from '../CustomFields/AutoCompleteRefCode4';
import {
  AutoCompleteTextField,
  LookUpProps,
} from '../CustomFields/AutoCompleteTextField';
import { EntityDocumentValues } from '../../../types';
import { useFormContext, useWatch } from 'react-hook-form';
import { TooltipHost } from '@fluentui/react';

export interface FormFieldProps {
  baseField: string;
  columnData: ColumnData;
  disable?: boolean;
  isDeletable?: boolean;
  index: number;
  documentData: GetEntityDocument | undefined;
  onSelectAccount: (lookupData: LookUpProps) => void;
  tax1099T4TypesOptions: {
    key: number;
    text: string;
  }[];
}
const FormField: React.FC<FormFieldProps> = ({
  baseField,
  columnData,
  disable,
  index,
  documentData,
  onSelectAccount,
  tax1099T4TypesOptions,
}) => {
  const { nodes } = {
    ...documentData?.entityDocument?.documentFileDistributionsByDocumentFileId,
  };
  const { control } = useFormContext<EntityDocumentValues>();
  const watchInvoiceDistributions = useWatch({
    control,
    name: 'invoiceDistributions',
  });
  switch (columnData.key) {
    case 'delete':
      return null;
    case 'referenceCode6':
    case 'referenceCode7':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          style={{ width: columnData.maxWidth }}
          disabled={disable}
        />
      );
    case 'tax1099T4TypeId':
      return (
        <FormHookDropdown
          name={`${baseField}.${columnData.key}`}
          options={tax1099T4TypesOptions}
          width={columnData.maxWidth}
          underlined
          disabled={disable}
        />
      );
    case 'referenceCode1':
      return (
        <AutoCompleteFF1
          index={index}
          invoiceDistributions={nodes}
          name={`${baseField}.${columnData.key}`}
          style={{
            width: columnData.maxWidth!,
          }}
          width={columnData.maxWidth!}
          disabled={disable}
          id={`referenceCode1${index}`}
        />
      );
    case 'referenceCode2':
      return (
        <AutoCompleteFF2
          index={index}
          invoiceDistributions={nodes}
          name={`${baseField}.${columnData.key}`}
          style={{
            width: columnData.maxWidth!,
          }}
          width={columnData.maxWidth!}
          disabled={disable}
          id={`referenceCode1${index}`}
        />
      );
    case 'referenceCode3':
      return (
        <AutoCompleteFF3
          index={index}
          invoiceDistributions={nodes}
          name={`${baseField}.${columnData.key}`}
          style={{
            width: columnData.maxWidth!,
          }}
          width={columnData.maxWidth!}
          disabled={disable}
          id={`referenceCode1${index}`}
        />
      );
    case 'referenceCode4':
      return (
        <AutoCompleteFF4
          index={index}
          invoiceDistributions={nodes}
          name={`${baseField}.${columnData.key}`}
          style={{
            width: columnData.maxWidth!,
          }}
          width={columnData.maxWidth!}
          disabled={disable}
          id={`referenceCode1${index}`}
        />
      );
    case 'accountReference':
      return (
        <TooltipHost
          content={watchInvoiceDistributions?.[index]._accountName??""}
          id="tooltipId"
        >
          <AutoCompleteTextField
            id={`accountReference${columnData.key}`}
            name={`${baseField}.${columnData.key}`}
            width={columnData.maxWidth!}
            lookUpCalloutWidth={500}
            onVendorSelect={onSelectAccount}
            businessUnitId={null}
            disabled={disable}
          />
        </TooltipHost>
      );
    case 'projectReference':
    case 'setReference':
    case 'additionalDescription':
    case 'referenceCode5':
      return (
        <FormHookTextField
          name={`${baseField}.${columnData.key}`}
          underlined
          style={{ width: columnData.maxWidth }}
          disabled={disable}
        />
      );
    case 'distributionAmount':
      return (
        <FormHookAmount
          name={`${baseField}.distributionAmount`}
          underlined
          style={{ width: columnData.maxWidth, textAlign: 'end' }}
          disabled={disable}
        />
      );
    default:
      return null;
  }
};

export default FormField;
