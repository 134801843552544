import { TravelPolicyValues } from './types';

export const TRAVEL_POLICY_VALUES: TravelPolicyValues = {
  description: null,
  currencyId: null,
  isDefaultCustomizable: null,
  isContractual: null,
  isCompanion: null,
  companionTickets: null,
  companionAmount: null,
  isEconomyClassAllowed: null,
  isPremiumClassAllowed: null,
  isBusinessClassAllowed: null,
  isCharterClassAllowed: null,
  isFirstClassAllowed: null,
};
