import { TravelAuthorization_travelAuthorization_trips_nodes } from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';

const getTotal = (
  data: TravelAuthorization_travelAuthorization_trips_nodes[] | undefined,
  fieldName: keyof TravelAuthorization_travelAuthorization_trips_nodes
) => {
  return data?.reduce(
    (prev, curr) => prev + parseFloat(curr[fieldName]?.toString()! || '0.00'),
    0.0
  );
};
export { getTotal };
