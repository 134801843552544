import { DetailsList, DetailsRow, FontWeights, IColumn, IDetailsFooterProps, IRenderFunction, SelectionMode, Stack, Text, makeStyles } from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import Decimal from 'decimal.js';
import React from 'react';
import { PurchaseOrderDetails_purchaseOrder_purchaseOrderItems, PurchaseOrderDetails_purchaseOrder_purchaseOrderItems_nodes } from '../../__generated__/PurchaseOrderDetails';
import { columns } from './columns';

const useStyles = makeStyles(() => ({
  amount: {
    display: 'block',
    textAlign: 'right',
  },
  cell: {
    height: 25,
  },
  footerLabel: {
    fontWeight: FontWeights.bold,
    textAlign: "right"
  }
}));

interface POItemsProps {
  purchaseOrderItemsData: PurchaseOrderDetails_purchaseOrder_purchaseOrderItems | undefined;
}
type POItemRow = PurchaseOrderDetails_purchaseOrder_purchaseOrderItems_nodes;

export const POItems: React.FC<POItemsProps> = ({
  purchaseOrderItemsData
}) => {
  const styles = useStyles();
  const {
    nodes
  } = { ...purchaseOrderItemsData }

  const transformedData: POItemRow[] =
    nodes?.map((ele) => ({
      ...ele,
      displayPoItemUnitPrice: ele?.displayPoItemUnitPrice ? ele?.displayPoItemUnitPrice?.replace(',', '') : "0.00"
    })) || [];

  const calculateTotal = (field: string, poItemsArray: POItemRow[]) => {
    return poItemsArray.map((item) => item[field as keyof POItemRow])
      .reduce((sum, curr) => {
        return new Decimal(curr || 0).plus(sum);
      }, new Decimal(0))
      .toNumber();
  }

  const onRenderItemColumn = (
    item: POItemRow | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof POItemRow
      ] as string;
      switch (column?.key) {
        case 'displayPoItemQuantity':
        case 'displayPoItemUnitPrice':
        case '_extendedItemTotal':
          return (
            <AmountTextView
              className={styles.amount}
              value={fieldContent}
            />
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.cell}
            >
              <Text>{fieldContent && fieldContent}</Text>
            </Stack>
          );
      }
    }
  };

  const renderFooter: IRenderFunction<IDetailsFooterProps> = (props) => {
    if (!props) {
      return null;
    }
    return (
      <DetailsRow
        {...props}
        item={{}}
        onRenderItemColumn={renderFooterColumn}
        itemIndex={-1}
      />
    );
  };

  const renderFooterColumn = (
    _item?: POItemRow,
    _index?: number,
    column?: IColumn
  ) => {
    let totalValue;
    switch (column?.key) {
      case 'displayPoItemRentalTerm':
        return (
          <Stack>
            <Text className={styles.footerLabel}>Total</Text>
          </Stack>
        );
      case 'displayPoItemUnitPrice':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      case '_extendedItemTotal':
        totalValue = calculateTotal(column?.key, transformedData);
        break;
      default:
        return null;
    }
    return (
      <AmountTextView
        value={totalValue.toString()}
        className={clsx(styles.amount, styles.footerLabel)}
      />
    );
  };

  return (
    <DetailsList
      compact
      selectionMode={SelectionMode.none}
      items={transformedData}
      columns={columns}
      onRenderItemColumn={onRenderItemColumn}
      onRenderDetailsFooter={renderFooter}
    />
  )
}
