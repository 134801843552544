import { NetworkStatus, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Callout, DirectionalHint, IDropdownOption, ITextFieldStyles, IconButton, Spinner, Stack, TextField, useTheme } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { CloseButton } from 'common/components/Buttons';
import { CountryRegionSelector } from 'common/components/CountryRegionFields';
import { Countries } from 'common/graphql/__generated__/Countries';
import { DepotsOrderBy } from 'common/types/globalTypes';
import { SortOrder } from 'common/utils/commonTypes';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { setUserDefaults } from 'utility/cache/ui';
import { DepotList, DepotListRow } from './DepotList';
import { toOrderByVariable } from './DepotList/utils';
import { LookupDepotAreaSearch, LookupDepotAreaSearchVariables } from './DepotSearchAutoComplete/__generated__/LookupDepotAreaSearch';
import { useStyles } from './index.styles';
const COUNTRIES = loader("../../../../../../../../../../../../common/graphql/Countries.graphql");
const LOOKUP_DEPOT_AREA_SEARCH = loader("./DepotSearchAutoComplete/LookupDepotAreaSearch.graphql")

interface DepotSelectionCalloutProps {
  targetId: string;
  isAirport?: boolean;
  isTrain?: boolean;
  onDismiss: () => void;
  onDepotAreaSelect: (depot: DepotListRow) => void;
}

export const DepotSelectionCallout: React.FC<DepotSelectionCalloutProps> = ({
  targetId,
  isAirport,
  isTrain,
  onDismiss,
  onDepotAreaSelect,
}) => {
  const styles = useStyles();
  const id = useId(`depot-select`);
  const theme = useTheme();
  const textfieldStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: theme.palette.white,
    },
  };
  const userDefaults = useReactiveVar(setUserDefaults);
  const { _homeCountryId } = { ...userDefaults }
  const { data: countries } = useQuery<Countries>(COUNTRIES);
  const [selectedCountryId, setSelectedCountryId] = useState<number | undefined | null>(_homeCountryId)
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [depotDescription, setDepotDescription] = useState<string | null | undefined>(null);
  const [disableTextfield, setDisableTextfield] = useState<boolean | null | undefined>(false);
  const renderRef = useRef(false);
  const countryOptions: IDropdownOption[] = countries?.countries?.nodes
    .map((ele) => ({
      key: ele.id,
      text: ele.country
    })) || [];

  const [
    fetchLookUp,
    {
      data,
      loading,
      networkStatus,
      refetch,
      fetchMore,
    },
  ] = useLazyQuery<
    LookupDepotAreaSearch,
    LookupDepotAreaSearchVariables
  >(LOOKUP_DEPOT_AREA_SEARCH, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupCountryId: selectedCountryId || -1,
          lookupDepot: value,
          isAirport: !!isAirport,
          isTrain: !!isTrain,
          first: 15,
          isBus: false,
          orderBy: [
            DepotsOrderBy.STATE_REGION_ASC,
            DepotsOrderBy.CITY_AREA_ASC,
            DepotsOrderBy.PRIMARY_KEY_ASC
          ]
        },
      });
      queryCalled();
    }, 550),
    [depotDescription, selectedCountryId]
  );

  const renderSpinner = () => {
    return (
      <>
        {loading ? (
          <Spinner />
        ) : disableTextfield ? (
          <IconButton
            type="button"
            style={{
              backgroundColor: '#e9e9e900', // Background color was not setting by the class.
            }}
            onClick={() => {
              setDepotDescription(null);
              setDisableTextfield(false);
            }}
            iconProps={{ iconName: 'cancel' }}
            className={styles.suffix}
            ariaLabel="Close"
          />
        ) : null}
      </>
    );
  };

  const handleSearch = (showMore: boolean) => {
    const variables: LookupDepotAreaSearchVariables = {
      lookupCountryId: selectedCountryId || -1,
      lookupDepot: depotDescription || '',
      first: 15,
      after: showMore
        ? data?.lookupDepotAreaSearch?.pageInfo.endCursor
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam),
    };
    if (showMore) fetchMore?.({ variables });
    else refetch?.(variables);
  }

  const handleSearchMemo = useCallback(handleSearch, [
    sortOrderParam,
    selectedCountryId,
    depotDescription
  ]);

  useEffect(() => {
    if (renderRef.current) {
      handleSearchMemo(false);
    } else renderRef.current = true;
  }, [handleSearchMemo]);

  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;
  const transformedData = refetching ? undefined : data?.lookupDepotAreaSearch;


  return (
    <Callout
      target={targetId}
      directionalHint={DirectionalHint.rightCenter}
      onDismiss={onDismiss}
    >
      <Stack
        className={styles.container}
      >
        <Stack
          horizontalAlign="end"
          tokens={{
            padding: "10px 20px 0px 0px"
          }}
        >
          <CloseButton onClick={onDismiss} />
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: 20,
            padding: "10px 20px"
          }}
        >
          <Stack style={{ flex: 1 }}>
            <CountryRegionSelector
              countries={countryOptions}
              CountryAutocompleteProps={{
                label: "Country",
                errorMessage: "",
                value: selectedCountryId || null,
                onChange: (_event, value) => {
                  if (value?.key) {
                    let numberKey = parseInt(value?.key.toString());
                    setSelectedCountryId(numberKey);
                  } else setSelectedCountryId(-1);
                },
              }}
              isStateRegionSelectable={false}
            />
          </Stack>
          <Stack style={{ flex: 1 }}>
            <TextField
              label='Search'
              autoComplete={'off'}
              id={id}
              value={depotDescription || ''}
              onChange={(_event, value) => {
                if (value) {
                  setDepotDescription(value);
                  callbackLookUp(value, () => { });
                } else {
                  setDepotDescription(null);
                }
              }}
              onRenderSuffix={renderSpinner}
              styles={textfieldStyles}
              disabled={!!disableTextfield}
            />
          </Stack>
        </Stack>
        <DepotList
          depotData={transformedData}
          loading={loading}
          onDepotAreaSelect={(data) => {
            onDepotAreaSelect(data)
            onDismiss()
          }}
          onColumnClick={setSortOrderParam}
          onPagination={() => handleSearch(true)}
        />
      </Stack>
    </Callout>
  )
}
