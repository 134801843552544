import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};
const centerAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'center',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 250,
    maxWidth: 250,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 280,
    maxWidth: 280,
    ...commonColumnProps,
  },

  {
    key: 'isReports',
    name: 'Is Report',
    fieldName: 'isReports',
    minWidth: 100,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'isMessages',
    name: 'Is Messages',
    fieldName: 'isMessages',
    minWidth: 100,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: '_createdDate',
    name: 'Created Date',
    fieldName: '_createdDate',
    minWidth: 120,
    maxWidth: 160,
    styles: rightAlignHeaderStyle,
    ...commonColumnProps,
  },
  {
    key: 'action',
    name: 'Action',
    fieldName: 'action',
    minWidth: 100,
    maxWidth: 160,
    isResizable: true,
    styles: centerAlignHeaderStyle,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
  },
];
