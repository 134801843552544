import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.neutralLighterAlt,
    width: 375,
  },
  statusType: {
    color: theme.palette.yellowDark,
  },
}));
