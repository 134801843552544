import { useMutation } from '@apollo/client';
import {
  Callout,
  DefaultButton,
  DirectionalHint,
  Icon,
  IDropdownOption,
  Modal,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import clsx from 'clsx';
import { FormikDropdown } from 'common/components';
import { AccountingDetails } from 'common/components/AccountingDetails';
import { CloseButton } from 'common/components/Buttons/CloseButton';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DocumentEntityList } from 'common/components/DocumentEntityList';
import {
  EntityDocumentUpdate,
  EntityDocumentUpdateVariables,
  EntityDocumentUpdate_entityDocumentUpdate_entityDocuments,
} from 'common/graphql/__generated__/EntityDocumentUpdate';
import { EntityDocumentPatch } from 'common/types/globalTypes';
import { dateConvertions } from 'common/utils/dateFormats';
import { EntityDocuments_entityDocuments_nodes } from 'documents/documentAssignment/folder/list/__generated__/EntityDocuments';
import {
  GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes,
  GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes_nodes,
} from 'documents/__generated__/GetDocumentPoolCommonData';
import { Form, Formik, useFormikContext } from 'formik';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useToasts } from 'react-toast-notifications';
import { DOCUMENT_INITIAL_VALUES } from '../constant';
import { validationSchema } from '../documentValidations';
import {} from '../MyDocumentsList';
import { EntityDocumentValues } from '../types';
import { EntityDocumentsPersonalPool_entityDocuments_nodes } from '../__generated__/EntityDocumentsPersonalPool';
import { FormView } from './FormView';
import { useStyles } from './index.styles';
import {
  EntityDocumentRetireBalancePersonalPool,
  EntityDocumentRetireBalancePersonalPoolVariables,
} from './__generated__/EntityDocumentRetireBalancePersonalPool';
const RETIRE_BALANCE = loader(
  './EntityDocumentRetireBalancePersonalPool.graphql'
);
const UPDATE_DOC = loader(
  '../../../common/graphql/EntityDocumentUpdate.graphql'
);
const DOCUMENTSLIST = loader('../MyDocumentsList/PersonalDocuments.graphql');

export interface UploadDocumentMutationProps {
  uploadDocumentData: (
    documentType: IDropdownOption,
    fileSelected: File[],
    content?: string | null,
    indexName?: string,
    indexDescription?: string | null,
    indexReferenceNumber?: string | null,
    indexTransactionDate?: string | undefined | null,
    indexAmount?: number | null,
    indexCurrencyId?: number | null,
    comment?: string | null,
    extractionTypeId?: number | null
  ) => void;
}
type ExtractionTypes =
  GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes;
export interface DocumentTypeOptions extends IDropdownOption {
  key: string | number;
  text: string;
  isAccountingDocument: boolean | undefined;
  isSigningRequired: boolean | null;
  extractionTypes: ExtractionTypes;
}

interface UploadFormProps {
  defaultDocumentTypeId?: number | undefined;
  documentData?: EntityDocumentsPersonalPool_entityDocuments_nodes | null;
  documentOptions?: DocumentTypeOptions[];
  companyCurrencies?: IDropdownOption[];
  visible: boolean;
  uploadDocument?: UploadDocumentMutationProps;
  onDismiss: () => void;
  onUpdate?: (
    updatedDocuments: EntityDocumentUpdate_entityDocumentUpdate_entityDocuments[]
  ) => void;
}

export const UploadDocumentForm: React.FC<UploadFormProps> = ({
  defaultDocumentTypeId,
  documentData,
  visible,
  documentOptions,
  uploadDocument,
  onDismiss,
  companyCurrencies,
  onUpdate,
}) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [fileSelected, setFileSelected] = useState<File[]>([]);
  const [isAccountingDocument, setIsAccountingDocument] =
    useState<boolean>(false);
  const [documentID, setDocumentID] = useState<string>();
  const [showIsAccountingDocumentInputs, setShowIsAccountingDocumentInputs] =
    useState<boolean>(true);
  const [dataExtractModelOptions, setDataExtractModelOptions] = useState<
    IDropdownOption[]
  >([]);
  const [hideExtractionDropdown, setHideExtractionDropdown] =
    useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false);
  //When usecallback use previous states get cleard
  const onDrop = (files: File[]) => {
    setFileSelected([...fileSelected, ...files]);
  };

  const multipleIsAccountingDocumentSelected =
    isAccountingDocument && fileSelected.length > 1;

  useEffect(() => {
    if (multipleIsAccountingDocumentSelected) {
      setShowIsAccountingDocumentInputs(false);
    } else setShowIsAccountingDocumentInputs(true);
  }, [multipleIsAccountingDocumentSelected]);

  const [retireBalance, { loading: retireBalanceLoading }] = useMutation<
    EntityDocumentRetireBalancePersonalPool,
    EntityDocumentRetireBalancePersonalPoolVariables
  >(RETIRE_BALANCE, { errorPolicy: 'all' });

  const [updateDocument, { loading: updateDocLoading }] = useMutation<
    EntityDocumentUpdate,
    EntityDocumentUpdateVariables
  >(UPDATE_DOC, { errorPolicy: 'all' });

  const [documentType, setDocumentType] = useState<DocumentTypeOptions>();
  let { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    multiple: true,
  });

  const onRemoveFile = (fileIndex: number) => {
    let newFiles = [...fileSelected!].filter((_, index) => fileIndex !== index);
    setFileSelected(newFiles);
  };

  let initialValues: EntityDocumentValues = DOCUMENT_INITIAL_VALUES;
  if (documentData) {
    initialValues = {
      ...documentData,
      indexTransactionDate: documentData.indexTransactionDate
        ? dateConvertions(documentData.indexTransactionDate!)
        : null,
    };
  }
  const handleDoc = () => {
    if (documentData && documentData?.id) {
      setDocumentID(documentData.id);
      setIsAccountingDocument(
        documentData.documentTypes?.isAccountingDocument || false
      );
    }
  };

  const handleDocMemo = useCallback(handleDoc, [documentData]);
  useEffect(() => {
    handleDocMemo();
  }, [handleDocMemo]);

  useEffect(() => {
    if (defaultDocumentTypeId) {
      const optionMap = documentOptions?.map(
        (item) => item as DocumentTypeOptions
      );
      let optionFilter: DocumentTypeOptions[] | undefined;
      if (documentData && documentData.id) {
        optionFilter = optionMap?.filter(
          (item) => item.key === documentData?.entityDocumentTypeId
        );
      } else {
        optionFilter = optionMap?.filter(
          (item) => item.key === defaultDocumentTypeId
        );
      }
      if (optionFilter) {
        setDocumentType(optionFilter[0]);
        setIsAccountingDocument(optionFilter[0]?.isAccountingDocument || false);
        if (optionFilter[0]?.isAccountingDocument) {
          setFileSelected([]);
        }
      }
    }
  }, [visible, defaultDocumentTypeId, documentOptions, documentData]);

  const handleSubmit = async (values: EntityDocumentValues) => {
    if (documentID) {
      const { ...item } = values;
      const entityDocumentPatch: EntityDocumentPatch = Object.entries(
        item
      ).reduce(
        (res, [key, val]) => {
          if (val !== initialValues[key as keyof EntityDocumentValues]) {
            return { ...res, [key]: val };
          }
          return res;
        },
        {
          indexTransactionDate: values.indexTransactionDate,
        }
      );

      const { errors, data } = await updateDocument({
        variables: {
          input: {
            entityDocumentsUpdate: [
              {
                id: documentData?.id,
                rowTimestamp: documentData?._rowTimestamp,
                entityDocumentPatch: entityDocumentPatch,
              },
            ],
          },
        },
        update(cache, { data: dataSet }) {
          cache.modify({
            fields: {
              PersonalPool(existinPools = []) {
                const NewPoolData = cache.writeFragment({
                  data: dataSet?.entityDocumentUpdate?.entityDocuments,
                  fragment: DOCUMENTSLIST,
                });
                return [...existinPools, NewPoolData];
              },
            },
          });
        },
      });

      if (errors?.length) {
        setDocumentID(undefined);
        setIsAccountingDocument(false);
        setDocumentType(undefined);
        setFileSelected([]);
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        onUpdate?.(data?.entityDocumentUpdate?.entityDocuments!);
        addToast('Document updated', { appearance: 'success' });
        clearData();
      }
    } else {
      if (multipleIsAccountingDocumentSelected)
        uploadDocument?.uploadDocumentData(
          documentType!,
          fileSelected || [],
          null,
          values.indexName!,
          null,
          null,
          null,
          null,
          null,
          null,
          values.extractionTypeId
        );
      else
        uploadDocument?.uploadDocumentData(
          documentType!,
          fileSelected || [],
          values._documentContents!,
          values.indexName!,
          values.indexDescription!,
          values.indexReferenceNumber!,
          values.indexTransactionDate!,
          values.indexAmount ? parseFloat(values.indexAmount) : null,
          values.indexCurrencyId,
          values.comment!,
          values.extractionTypeId
        );
      onDismiss();
    }
  };

  const clearData = () => {
    setDocumentID(undefined);
    setIsAccountingDocument(false);
    setDocumentType(undefined);
    setFileSelected([]);
  };

  const CONFIRM_DIALOG_TITLE = 'Are you sure you want to retire balance?';
  const CONFIRM_DIALOG_SUBTEXT = '';
  const [hideConfirmDialog, setHideConfirmDialog] = useState<boolean>(true);
  const toggleConfirmDialog = () =>
    setHideConfirmDialog((prevState) => !prevState);
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const calloutId = useId(`callOutId${documentData?.id!}`);

  const isDocumentProtected = documentData
    ? !documentData?._isUpdatable
    : false;

  return (
    <Stack>
      <Formik<EntityDocumentValues>
        enableReinitialize
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, dirty, resetForm, errors, values }) => {
          return (
            <Form>
              <Modal isOpen={visible} isBlocking={true} onDismiss={onDismiss}>
                <Stack className={styles.container}>
                  <Stack
                    horizontal
                    horizontalAlign={'space-between'}
                    className={styles.dialogStyles}
                  >
                    <Stack tokens={{ childrenGap: 5 }}>
                      <Text variant={'xLarge'}>
                        {`${documentID ? 'Update' : 'Upload'}`} Documents
                      </Text>
                      {!documentID && (
                        <Text variant={'medium'}>
                          Select a document to upload for this Folder
                        </Text>
                      )}
                      {documentID &&
                        documentData?.documentFileDistributionsByDocumentFileId
                          ?.totalCount &&
                        documentData?.documentFileDistributionsByDocumentFileId
                          ?.totalCount > 0 && (
                          <>
                            <Text
                              className={styles.accountingDetailsText}
                              id={calloutId}
                              onMouseEnter={() => setIsCalloutVisible(true)}
                              onMouseLeave={() => setIsCalloutVisible(false)}
                            >
                              Accounting Details
                            </Text>
                            {isCalloutVisible && (
                              <Callout
                                gapSpace={0}
                                target={`#${calloutId}`}
                                directionalHint={DirectionalHint.leftTopEdge}
                                onDismiss={() => setIsCalloutVisible(false)}
                              >
                                <Stack>
                                  <AccountingDetails
                                    data={
                                      documentData.documentFileDistributionsByDocumentFileId
                                    }
                                  />
                                </Stack>
                              </Callout>
                            )}
                          </>
                        )}
                    </Stack>
                    <CloseButton
                      onClick={() => {
                        resetForm();
                        clearData();
                        onDismiss();
                      }}
                    />
                  </Stack>
                  <Stack
                    tokens={{ childrenGap: 20 }}
                    style={{ padding: '100px 25px 150px 25px' }}
                  >
                    {documentOptions && (
                      <CustomDropDown
                        isEdit={documentID ? true : false}
                        defaultDocumentTypeId={defaultDocumentTypeId}
                        documentData={documentData!}
                        isDocumentProtected={isDocumentProtected}
                        documentOptions={documentOptions}
                        setDocumentType={(data) => setDocumentType(data)}
                        setFileSelected={() => setFileSelected([])}
                        setIsAccountingDocument={(isAccounting) =>
                          setIsAccountingDocument(isAccounting!)
                        }
                        disabled={isDisabled}
                        onDocumentTypeChange={setDataExtractModelOptions}
                        hideExtractionDropdown={() =>
                          setHideExtractionDropdown(true)
                        }
                      />
                    )}

                    {!documentID && values.entityDocumentTypeId && (
                      <div
                        {...getRootProps()}
                        className={clsx(
                          styles.dropZone,
                          isDragActive && styles.dropZoneHover
                        )}
                      >
                        <Stack
                          grow
                          horizontalAlign="center"
                          verticalAlign="center"
                          verticalFill
                        >
                          <input
                            {...getInputProps({
                              multiple: true,
                            })}
                          />

                          {isDragActive ? (
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                              <Icon
                                iconName={'CloudUpload'}
                                className={styles.uploadIconStyle}
                              />
                              <Text className={styles.uploadIconStyle}>
                                Drop the files here...
                              </Text>
                            </Stack>
                          ) : (
                            <Text>
                              Drag 'n' drop some files here, or click to select
                              files
                            </Text>
                          )}
                        </Stack>
                      </div>
                    )}
                    {fileSelected.length > 0 && (
                      <DocumentEntityList
                        onRemoveFile={onRemoveFile}
                        filesList={fileSelected!}
                      />
                    )}
                    <FormView
                      isAccountingDocument={isAccountingDocument}
                      isDocumentProtected={isDocumentProtected}
                      showIsAccountingDocumentInputs={
                        showIsAccountingDocumentInputs
                      }
                      documentData={documentData}
                      documentType={documentType}
                      companyCurrencies={companyCurrencies}
                      onInputFieldsDisabled={setIsDisabled}
                      dataExtractModelOptions={dataExtractModelOptions}
                      isEdit={documentID ? true : false}
                      hideExtractionDropdown={hideExtractionDropdown}
                    />
                  </Stack>
                  <Stack className={styles.footerStyles}>
                    {(retireBalanceLoading || updateDocLoading) && (
                      <ProgressIndicator />
                    )}
                    <Stack
                      horizontalAlign="space-between"
                      horizontal
                      tokens={{ childrenGap: 6, padding: 25 }}
                    >
                      <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <PrimaryButton
                          disabled={
                            !dirty ||
                            Object.keys(errors).length > 0 ||
                            isSubmitting ||
                            (!documentData?.id && fileSelected.length === 0)
                          }
                          text={`${documentID ? 'Update' : 'Upload'}`}
                          onClick={async () => {
                            await submitForm();
                            resetForm();
                            clearData();
                            onDismiss();
                          }}
                        />
                        <DefaultButton
                          onClick={() => {
                            resetForm();
                            clearData();
                            onDismiss();
                          }}
                          text="Cancel"
                        />
                      </Stack>
                      <Stack>
                        {documentData?.documentAppliedAmounts
                          ?._isRetiringBalanceAllowed &&
                          documentData?.documentAppliedAmounts?.usedTotal !==
                            null &&
                          parseFloat(
                            documentData?.documentAppliedAmounts?.usedTotal
                          ) > 0 && (
                            <PrimaryButton
                              disabled={retireBalanceLoading}
                              text="Retire Balance"
                              onClick={toggleConfirmDialog}
                            />
                          )}
                      </Stack>
                    </Stack>
                  </Stack>
                  <ConfirmDialog
                    hidden={hideConfirmDialog}
                    title={CONFIRM_DIALOG_TITLE}
                    subText={CONFIRM_DIALOG_SUBTEXT}
                    onDismiss={toggleConfirmDialog}
                    onConfirm={async () => {
                      toggleConfirmDialog();
                      const { errors } = await retireBalance({
                        variables: {
                          input: {
                            entityDocumentId: documentData?.id!,
                            rowTimestamp: documentData?._rowTimestamp!,
                          },
                        },
                      });
                      if (errors?.length)
                        addToast(errors[0].message, {
                          appearance: 'error',
                        });
                      else {
                        addToast('Balance retired', { appearance: 'success' });
                        resetForm();
                        clearData();
                        onDismiss();
                      }
                    }}
                  />
                </Stack>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
interface CustomDropDown {
  isEdit: boolean;
  defaultDocumentTypeId: number | undefined;
  documentData: EntityDocuments_entityDocuments_nodes | null;
  isDocumentProtected: boolean;
  documentOptions?: DocumentTypeOptions[];
  setDocumentType: (data: DocumentTypeOptions) => void;
  setIsAccountingDocument: (isAccounting: boolean | undefined) => void;
  setFileSelected: () => void;
  disabled?: boolean;
  onDocumentTypeChange?: (options: IDropdownOption[]) => void;
  hideExtractionDropdown: () => void;
}
export const CustomDropDown: React.FC<CustomDropDown> = ({
  isEdit,
  defaultDocumentTypeId,
  documentData,
  isDocumentProtected,
  documentOptions,
  setDocumentType,
  setIsAccountingDocument,
  setFileSelected,
  disabled,
  onDocumentTypeChange,
  hideExtractionDropdown,
}) => {
  const { values, setFieldValue } = useFormikContext<EntityDocumentValues>();

  useEffect(() => {
    if (defaultDocumentTypeId && !documentData?.id) {
      setFieldValue(
        'entityDocumentTypeId',
        defaultDocumentTypeId ? defaultDocumentTypeId : null
      );
    }
  }, [defaultDocumentTypeId, documentData, documentOptions, setFieldValue]);

  useEffect(() => {
    let extractionTypesArray: GetDocumentPoolCommonData_documentPoolAvailableDocumentTypes_nodes_extractionTypes_nodes[] =
      [];
    extractionTypesArray =
      documentOptions?.find((x) => x.key === values.entityDocumentTypeId)
        ?.extractionTypes.nodes || [];
    onDocumentTypeChange?.(
      extractionTypesArray.map((ele) => ({
        key: ele.id,
        text: ele.extractionType,
      }))
    );
  }, [values.entityDocumentTypeId, documentOptions, onDocumentTypeChange]);

  return (
    <>
      <FormikDropdown
        label="Document Type"
        placeholder="Select"
        name="entityDocumentTypeId"
        options={documentOptions || []}
        onChange={(_event, option) => {
          const selectedOptions = option as DocumentTypeOptions;
          setDocumentType(selectedOptions);
          setIsAccountingDocument(selectedOptions.isAccountingDocument!);
          if (selectedOptions.isAccountingDocument) setFileSelected();
          if (isEdit) hideExtractionDropdown();
        }}
        disabled={
          disabled ||
          (!!documentData &&
            (isDocumentProtected || !documentData._isDocumentTypeUpdatable))
        }
        required
      />
    </>
  );
};
