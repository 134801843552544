import { Stack } from '@fluentui/react';
import React, { useCallback } from 'react';
import { NewMessage } from './NewMessage';
import { loader } from 'graphql.macro';
import { NetworkStatus, useQuery } from '@apollo/client';

import {
  MessagesListsProps,
  MessagesSections,
  PivotKeys,
} from 'common/components/MessagesSections';
import {
  GetApprovalMessage,
  GetApprovalMessageVariables,
} from './__generated__/GetApprovalMessage';
const GET_APPROVAL_USER_MESSAGE = loader('./GetApprovalMessage.graphql');
interface ApprovalMessagesProps
  extends Pick<MessagesListsProps, 'userMessageCounts'> {
  invoiceID: string;
  onCreateMessage: () => void;
}

export const ApprovalMessages: React.FC<ApprovalMessagesProps> = ({
  invoiceID,
  userMessageCounts,
  onCreateMessage,
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string>(
    PivotKeys.received
  );

  //For new component
  const {
    loading: loadingMessageList,
    data: messageListData,
    variables: messageListVariables,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<GetApprovalMessage, GetApprovalMessageVariables>(
    GET_APPROVAL_USER_MESSAGE,
    {
      variables: {
        id: invoiceID,
        first: 10,
        filter: { _isIncomingMessage: { equalTo: true } },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const onListChange = useCallback(
    async (listName?: string) => {
      if (listName) setSelectedKey(listName);
      const _isOutgoingMessage = { equalTo: listName === PivotKeys.sent };
      await refetch({
        ...messageListVariables,
        filter: {
          _isOutgoingMessage,
        },
      });
    },
    [refetch, messageListVariables]
  );

  const loadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        ...messageListVariables,
        after:
          messageListData?.approvalHistory?.userMessagesByEntityId.pageInfo
            .endCursor,
      },
    });
  }, [fetchMore, messageListVariables, messageListData]);

  const data =
    networkStatus === NetworkStatus.setVariables
      ? undefined
      : messageListData?.approvalHistory?.userMessagesByEntityId.nodes;

  return (
    <Stack tokens={{ padding: '10px -10px 10px -10px' }}>
      <MessagesSections
        userMessageCounts={userMessageCounts}
        entityId={invoiceID}
        data={data || []}
        loadingMessages={!data?.length && loadingMessageList}
        onListChange={onListChange}
        onLoadMore={loadMore}
        hasNextPage={
          messageListData?.approvalHistory?.userMessagesByEntityId?.pageInfo
            ?.hasNextPage
        }
      >
        <NewMessage
          onCreateMessage={onCreateMessage}
          selectedKey={selectedKey}
          invoiceID={invoiceID}
        />
      </MessagesSections>
    </Stack>
  );
};
