import { Stack, PrimaryButton, DefaultButton, makeStyles } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';
interface ModalFooterProps {
    headerTitle: string;
    onDismiss: () => void;
}

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        justifyContent: 'flex-end',
        backgroundColor: theme.palette.neutralLighterAlt,
    }
}))

export const ModalFooter: React.FC<ModalFooterProps> = ({ headerTitle, onDismiss }) => {
    const styles = useStyles()
    const { submitForm, resetForm, dirty, errors, isSubmitting } = useFormikContext();

    return <Stack
        horizontalAlign="space-between"
        className={styles.buttonContainer}
        horizontal
        tokens={{ childrenGap: 6, padding: 25 }}
    >
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <PrimaryButton
                disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
                text={headerTitle}
                onClick={async () => {
                    await submitForm();
                    resetForm();
                    onDismiss();
                }}
            />
            <DefaultButton
                onClick={() => {
                    resetForm();
                    onDismiss();
                }}
                text="Cancel"
            />
        </Stack>
    </Stack>;
};
