import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "5px 0px",
    cursor: "pointer"
  },
  reviewsLabel: {
    padding: 10,
    backgroundColor: theme.palette.neutralLight
  },
  boldFont: {
    fontWeight: 'bold'
  },
}));