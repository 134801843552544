import { TooltipHost, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { ExtendedMessageData } from 'common/components/MessagesSections/MessageRow/ExtendedMessage';
import { useCommonStyles } from 'common/styles';
import { AlignmentTypes } from 'common/types/globalTypes';
import React from 'react'
import { getAlignment } from '../utils';
import { useStyles } from './index.styles'

interface TableHeaderProps {
    column: ExtendedMessageData | null;
    index: number;
    numberOfColumns: number;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
    column,
    index,
    numberOfColumns,
}) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const getHeading = (column: ExtendedMessageData | null, index: number) => {
        if (!column) return null;
        switch (index) {
            case 0:
                return column.columnHeading1;
            case 1:
                return column.columnHeading2;
            case 2:
                return column.columnHeading3;
            case 3:
                return column.columnHeading4;
            case 4:
                return column.columnHeading5;
            default:
                return null;
        }
    };

    return (
        <TooltipHost>
            <Stack
                className={clsx(
                    styles.borderStyle,
                    numberOfColumns - 1 !== index ? styles.rightBorderWidth : null
                )}
            >
                <Text
                    className={clsx(
                        commonStyles.bold,
                        commonStyles.colorThemePrimary,
                        {
                            [styles.textAlignCenter]: getAlignment(column, index) === AlignmentTypes.CENTER,
                            [styles.textAlignEnd]: getAlignment(column, index) === AlignmentTypes.RIGHT,
                            [styles.textAlignStart]: getAlignment(column, index) === AlignmentTypes.LEFT,
                        }
                    )}
                >
                    {getHeading(column, index)}
                </Text>
            </Stack>
        </TooltipHost>
    );
};
