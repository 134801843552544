import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  DatePicker,
  DayOfWeek,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ApprovalFlowModal } from 'common/components/ApprovalFlowModal';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { PanelHeader } from 'common/components/PanelHeader';
import { SignatureOptions, SignatureView } from 'common/components/Signatures';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalBranchInput,
  ApprovalBranchesUpdateTypeInput,
  ApprovalConditionsUpdateTypeInput,
  ApprovalSelectionConditionInput,
  ApprovalState,
  ApprovalTreeBranchesTypeInput,
  ApprovalTreesUpdateTypeInput,
  EntityDeleteInput,
} from 'common/types/globalTypes';
import { EntityType, PanelCommonProps } from 'common/types/utility';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { Form, Formik, FormikHelpers } from 'formik';
import { loader } from 'graphql.macro';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ApprovalSetupCommonData } from '../__generated__/ApprovalSetupCommonData';
import {
  ApprovalDelete,
  ApprovalDeleteVariables,
} from '../list/__generated__/ApprovalDelete';
import { Approvals_approvals } from '../list/__generated__/Approvals';
import { Approval, ApprovalVariables } from './__generated__/Approval';
import {
  ApprovalCreate,
  ApprovalCreateVariables,
} from './__generated__/ApprovalCreate';
import {
  ApprovalSetupApprovalCreate,
  ApprovalSetupApprovalCreateVariables,
} from './__generated__/ApprovalSetupApprovalCreate';
import {
  ApprovalSetupApprovalRevoke,
  ApprovalSetupApprovalRevokeVariables,
} from './__generated__/ApprovalSetupApprovalRevoke';
import {
  ApprovalUpdate,
  ApprovalUpdateVariables,
} from './__generated__/ApprovalUpdate';
import { ActionsMenu } from './ActionMenu';
import { ApprovalHistory } from './ApprovalHistory';
import { ApprovalLevels } from './ApprovalLevels';
import { validationSchema } from './approvalSetupValidation';
import { BasicForm } from './BasicForm';
import {
  APPROVAL_BRANCH_INITIAL_VALUES,
  APPROVAL_LEVELS_INITIAL_VALUES,
  APPROVAL_SETUP_INITIAL_VALUES,
  REQUESTOR_INITIAL_VALUES,
} from './constants';
import { CopyApproval } from './CopyApproval';
import { useStyles } from './index.styles';
import { RequestorConditionsView } from './RequestorConditionsView';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { ApprovalBranchViewRowValues, ApprovalValues } from './types';
import { UrgencyFolder } from './UrgencyFolder';
const GET_APPROVAL_DETAILS = loader('./Approval.graphql');
const APPROVAL_TYPE_DATA = loader('../ApprovalSetupCommonData.graphql');
const CREATE_APPROVAL_SETUP = loader('./ApprovalCreate.graphql');
const APPROVAL_SETUP_APPROVAL_CREATE = loader(
  './ApprovalSetupApprovalCreate.graphql'
);
const APPROVAL_SETUP_APPROVAL_REVOKE = loader(
  './ApprovalSetupApprovalRevoke.graphql'
);
const DELETE_APPROVAL = loader('../list/ApprovalDelete.graphql');
const APPROVAL_UPDATE = loader('./ApprovalUpdate.graphql');
const APPROVALS_LIST_FIELDS = loader('../ApprovalsListFields.graphql');
const APPROVALS_FRAGMENT_NAME = 'ApprovalsListFields';
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the Approval setup from the approval cycle and require re-approval.';
const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to process this approval setup?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT = 'Your approval setup will be processed.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';
const _ = require('lodash');

export const ApprovalSetupView: React.FunctionComponent = () => {
  const history = useHistory();
  const styles = useStyles();
  const { addToast } = useToasts();
  const commonStyles = useCommonStyles();
  const { approvalId } = useParams<{ approvalId: string | undefined }>();
  const [signatureData, setSignatureData] = useState<
    SignatureOptions[] | undefined
  >([]);
  const [isSaveAnother, { setTrue: saveAnother, setFalse: dontSaveAnonther }] =
    useBoolean(false);
  const [hideConfirmAmendDialog, setHideConfirmAmendDialog] =
    useState<boolean>(true);
  const toggleConfirmAmendDialog = () =>
    setHideConfirmAmendDialog((prevState) => !prevState);
  const [hideConfirmRequestDialog, setHideConfirmRequestDialog] =
    useState<boolean>(true);
  const toggleConfirmDialog = () =>
    setHideConfirmRequestDialog((prevState) => !prevState);
  const [requestComment, setRequestComment] = useState<string>();
  const [requiredDate, setRequiredDate] = useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const isNew = !approvalId;
  const panelHeading = isNew ? 'Create Approval' : 'Edit Approval';

  const [
    getApprovalDetails,
    { data: approvalDetailsData, loading: approvalDetailsLoading },
  ] = useLazyQuery<Approval, ApprovalVariables>(GET_APPROVAL_DETAILS, {
    fetchPolicy: 'network-only',
  });
  const toBeDeletedRequesterConditions: EntityDeleteInput[] = [];
  const toBeDeletedRequesterTree: EntityDeleteInput[] = [];
  const toBeDeletedApprovalBranch: EntityDeleteInput[] = [];

  const { data: approvalTypeData } = useQuery<ApprovalSetupCommonData>(
    APPROVAL_TYPE_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [deleteApproval] = useMutation<ApprovalDelete, ApprovalDeleteVariables>(
    DELETE_APPROVAL,
    { errorPolicy: 'all' }
  );

  const { approval } = { ...approvalDetailsData };
  const approvalDetails = {
    statusType: approval?.statusType?.statusType,
  };

  const [
    approvalSetupApprovalCreate,
    { loading: approvalSetupApprovalCreateLoading },
  ] = useMutation<
    ApprovalSetupApprovalCreate,
    ApprovalSetupApprovalCreateVariables
  >(APPROVAL_SETUP_APPROVAL_CREATE, {
    errorPolicy: 'all',
    onCompleted: () => {
      setUrgencyLevel(1);
    },
  });
  const [
    approvalSetupApprovalRevoke,
    { loading: approvalSetupApprovalRevokeLoading },
  ] = useMutation<
    ApprovalSetupApprovalRevoke,
    ApprovalSetupApprovalRevokeVariables
  >(APPROVAL_SETUP_APPROVAL_REVOKE, { errorPolicy: 'all' });

  useEffect(() => {
    if (approvalId && approvalId !== '') {
      getApprovalDetails({
        variables: {
          id: approvalId,
        },
      });
    }
  }, [approvalId, isNew, getApprovalDetails]);

  useEffect(() => {
    const { nodes } = { ...approval?.approvalHistorySignaturesByEntityId };
    if (!approvalDetailsLoading && nodes?.length) setSignatureData(nodes);
  }, [approval, approvalDetailsLoading]);

  const _deleteTransaction = async () => {
    const { errors } = await deleteApproval({
      variables: {
        input: {
          entityDelete: [
            {
              id: approvalDetailsData?.approval?.id!,
              rowTimestamp: approvalDetailsData?.approval?._rowTimestamp!,
            },
          ],
        },
      },
      update: (cache, { data, errors }) => {
        if (!errors?.length) {
          setTimeout(() => {
            const identity = cache.identify({
              ...approvalDetailsData?.approval,
            });
            cache.evict({ id: identity });
            cache.gc();
          }, 200);

          cache.modify({
            fields: {
              approvals(existing: Approvals_approvals) {
                return {
                  ...existing,
                  totalCount: existing.totalCount - 1,
                } as Approvals_approvals;
              },
            },
          });
        }
      },
    });
    if (errors?.length)
      addToast(`${errors[0].message}`, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/account-management/approvals');
    }
  };

  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    const { approvalType } = { ...approval?.approvalType };
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/account-management/approvals');
        }}
      >
        <Stack grow horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {approvalType === ApprovalState.DRAFT && !isNew && (
              <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
                {approvalType}
              </Text>
            )}
            <UnsavedIndicator
              visible={
                !isNew && !approvalDetailsLoading && dirty && !isSubmitting
              }
            />
          </Stack>
          {!isNew && (
            <Stack horizontal verticalAlign="center">
              {approvalDetailsData?.approval?.statusType?.statusType ===
                'Pending' && (
                <UrgencyFolder approvalDetailsData={approvalDetailsData} />
              )}
              <CopyApproval
                id={approvalId!}
                data={approvalTypeData?.approvalTypes!}
              />
              <ActionMessageModal
                entityType={EntityType.ApprovalConfig}
                disabled={!approvalDetailsData?.approval?._isDeletable}
                onConfirm={() => {
                  _deleteTransaction();
                }}
              />
              {approvalDetailsData?.approval?.statusType?.statusType && (
                <Text className={styles.approvalStatus}>
                  {`(${approvalDetailsData?.approval?.statusType?.statusType})`}
                </Text>
              )}
            </Stack>
          )}
        </Stack>
      </PanelHeader>
    );
  };

  let initialValues: ApprovalValues = APPROVAL_SETUP_INITIAL_VALUES;
  const { nodes } = { ...approval?.approvalSelectionConditions };
  if (approval && !isNew) {
    if (approval?._isUpdatable) {
      initialValues = {
        ...approval!,
        approvalSelectionConditions:
          nodes?.length! > 0
            ? [...nodes!, ...[REQUESTOR_INITIAL_VALUES]]
            : [REQUESTOR_INITIAL_VALUES],
        approvalTrees:
          approval.approvalTrees.nodes.length > 0
            ? [
                ...approval.approvalTrees.nodes.map((tree) => ({
                  ...tree,
                  approvalBranches: [
                    ...tree.approvalBranches.nodes,
                    APPROVAL_BRANCH_INITIAL_VALUES,
                  ],
                })),
                { ...APPROVAL_LEVELS_INITIAL_VALUES },
              ]
            : [APPROVAL_LEVELS_INITIAL_VALUES],
      };
    } else {
      initialValues = {
        ...approval!,
        approvalSelectionConditions: nodes!,
        approvalTrees: approval.approvalTrees.nodes.map((tree) => ({
          ...tree,
          approvalBranches: tree.approvalBranches.nodes,
        })),
      };
    }
  }
  // MAIN QUERY FOR RECORD INSERTION
  const [createApproval, { loading: addApprovalLoading }] = useMutation<
    ApprovalCreate,
    ApprovalCreateVariables
  >(CREATE_APPROVAL_SETUP, { errorPolicy: 'all' });

  const [updateApproval, { loading: updateApprovalLoading }] = useMutation<
    ApprovalUpdate,
    ApprovalUpdateVariables
  >(APPROVAL_UPDATE, {
    errorPolicy: 'all',
  });

  const constructBranchData = (branchData: ApprovalBranchViewRowValues) => {
    return {
      description: branchData.description!,
      branchOrder: Number(branchData.branchOrder?.toString()),
      budgetLimitAmount: branchData.budgetLimitAmount,
      budgetLimitPercent: branchData.budgetLimitPercent,
      isOverBudgetSelector: branchData.isOverBudgetSelector,
      isBusinessUnitSelector: branchData.isBusinessUnitSelector,
      isDepartmentSelector: branchData.isDepartmentSelector,
      isProjectSelector: branchData.isProjectSelector,
      isUserGroupSelector: branchData.isUserGroupSelector,
      userOccupationTitleIdSelector: branchData.userOccupation?.id,
      userRankTypeIdSelector: branchData.userRank?.id,
      transactionStartAmountTotal: branchData.transactionStartAmountTotal,
      transactionEndAmountTotal: branchData.transactionEndAmountTotal,
      transactionAmountConversionTypeId:
        branchData.transactionAmountConversionTypeId,
      approverTransactionTypeId: branchData.approverTransactionTypeId,
      transactionBusinessUnitId: branchData.transactionBusinessUnitId,
      transactionDepartmentId: branchData.transactionDepartmentId,
      transactionDepartmentGroupId: branchData.transactionDepartmentGroupId,
      requesterUserId:branchData.requesterUserId,
      isTransactionNotCondition: branchData.isTransactionNotCondition,
      approverDelayDays: Number(branchData.approverDelayDays),
      approverDelayHours: Number(branchData.approverDelayHours),
      approverDelayStatusTypeId: branchData.approverDelayStatusTypeId,
    };
  };
  const handleSubmit = async (
    values: ApprovalValues,
    { resetForm }: FormikHelpers<ApprovalValues>
  ) => {
    const valuesToOmit = [
      'id',
      'approvalId',
      '_isUpdatable',
      '_isDeletable',
      '_rowTimestamp',
      'approval',
      'approvalBranches',
      '__typename',
    ];
    if (isNew) {
      let approvalConditions = values
        ?.approvalSelectionConditions!.filter(
          (data) => data.description && data.importanceLevel
        )
        .map((item) => {
          return {
            ..._.omit(item, valuesToOmit),
            importanceLevel: Number(item.importanceLevel?.toString()),
            startElapsedDays: Number(item.startElapsedDays?.toString()),
            endElapsedDays: Number(item.endElapsedDays?.toString()),
          } as ApprovalSelectionConditionInput;
        });

      const approvalDistributions = values.approvalTrees
        ?.filter(
          (trees) =>
            trees.requiredBranchApprovalCount &&
            trees.name &&
            trees.approvalLevel
        )
        .map(
          (item) =>
            ({
              approvalTree: {
                ..._.omit(item, valuesToOmit),
                approvalLevel: Number(item.approvalLevel!.toString()),
                requiredBranchApprovalCount: Number(
                  item.requiredBranchApprovalCount!.toString()
                ),
              },
              approvalBranches: item.approvalBranches
                ?.filter((data) => data.description)
                ?.map((branches) => {
                  return {
                    ...constructBranchData(branches),
                  };
                }),
            } as ApprovalTreeBranchesTypeInput)
        );

      const { data, errors } = await createApproval({
        variables: {
          input: {
            approval: {
              approvalTypeId: values.approvalTypeId!,
              name: values.name!,
              priorityNumber: Number(values.priorityNumber!.toString()),
              description: values.description!,
              approvalPhaseTypeId: values.approvalPhaseTypeId!,
              isAddApproversEligible: values.isAddApproversEligible!,
              isDelayedApproverAllowed: values.isDelayedApproverAllowed,
            },
            approvalConditions: approvalConditions,
            approvalTreeBranches: approvalDistributions!,
          },
        },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              approvals(existing: Approvals_approvals) {
                if (data?.approvalCreate?.approval) {
                  const newApprovalsRef = cache.writeFragment({
                    data: data?.approvalCreate?.approval,
                    fragment: APPROVALS_LIST_FIELDS,
                    fragmentName: APPROVALS_FRAGMENT_NAME,
                  });
                  return {
                    ...existing,
                    nodes: [newApprovalsRef, ...existing.nodes],
                  };
                }
              },
            },
          });
        },
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      }
      if (data?.approvalCreate?.approval) {
        values.approvalTrees?.pop();
        if (!isSaveAnother) {
          history.replace(
            `/account-management/approvals/approval/${data?.approvalCreate?.approval?.id}`
          );
        } else {
          resetForm();
        }
        addToast('Approval setup added successfully', {
          appearance: 'success',
        });
      }
    } else {
      //Approval conditions
      let updateApprovalConditions: ApprovalConditionsUpdateTypeInput[] = [];
      let createApprovalConditions: ApprovalSelectionConditionInput[] = [];
      let updateApprovalBranches: ApprovalBranchesUpdateTypeInput[] = []; // only to update branch
      let treeBranches: ApprovalTreeBranchesTypeInput[] = []; // for new tree and branch
      let treeUpdate: ApprovalTreesUpdateTypeInput[] = []; // only to update tree

      values.approvalSelectionConditions?.map((item, index) => {
        if (index !== values.approvalSelectionConditions?.length! - 1) {
          const arr = _.omit(item, valuesToOmit);
          if (item.id !== null) {
            const testReduce = Object.values(item).every(
              (data) => data === null
            );
            if (testReduce) {
              const tempObj: EntityDeleteInput = {
                id: item.id,
                rowTimestamp: item._rowTimestamp!,
              };
              toBeDeletedRequesterConditions.push(tempObj);
            } else {
              const tempObj: ApprovalConditionsUpdateTypeInput = {
                id: item.id,
                rowTimestamp: item._rowTimestamp,
                approvalConditionPatch: {
                  ...arr,
                  importanceLevel: Number(arr.importanceLevel?.toString()),
                  startElapsedDays: Number(item.startElapsedDays?.toString()),
                  endElapsedDays: Number(item.endElapsedDays?.toString()),
                },
              };
              updateApprovalConditions.push(tempObj);
            }
          }
          if (item.id === null) {
            const tempObj: ApprovalSelectionConditionInput = {
              ...arr,
              importanceLevel: Number(item.importanceLevel?.toString()),
              startElapsedDays: Number(item.startElapsedDays?.toString()),
              endElapsedDays: Number(item.endElapsedDays?.toString()),
            };
            createApprovalConditions.push(tempObj);
          }
        }
      });

      values.approvalTrees?.map((item, index) => {
        if (index !== values.approvalTrees?.length! - 1) {
          const arr = _.omit(item, valuesToOmit);
          if (item.id !== null) {
            const testReduce = Object.values(item).every(
              (data) => data === null
            );
            if (testReduce) {
              const tempObj: EntityDeleteInput = {
                id: item.id,
                rowTimestamp: item._rowTimestamp!,
              };
              toBeDeletedRequesterTree.push(tempObj);
            } else {
              const tempObj: ApprovalTreesUpdateTypeInput = {
                id: item.id,
                rowTimestamp: item._rowTimestamp,
                approvalTreePatch: {
                  ...arr,
                  approvalLevel: Number(item.approvalLevel),
                  requiredBranchApprovalCount: Number(
                    item.requiredBranchApprovalCount!.toString()
                  ),
                },
              };
              treeUpdate.push(tempObj);
            }
          }
          if (item.id === null) {
            const branches =
              item.approvalBranches &&
              item.approvalBranches
                ?.filter((data) => data.description)
                ?.map((data) => {
                  return {
                    ...constructBranchData(data),
                  } as ApprovalBranchInput;
                });
            const tempObj: ApprovalTreeBranchesTypeInput = {
              approvalTree: {
                ..._.omit(item, valuesToOmit),
                approvalLevel: Number(item.approvalLevel?.toString()),
                requiredBranchApprovalCount: Number(
                  item.requiredBranchApprovalCount?.toString()
                ),
              },
              approvalBranches: branches,
            };
            treeBranches.push(tempObj);
          }
        }
      });

      values.approvalTrees?.map((item, index) => {
        if (index !== values.approvalTrees?.length! - 1) {
          item.approvalBranches
            ?.filter((fil) => fil.description)
            ?.map((branches) => {
              if (item.id)
                if (branches.id !== null) {
                  const updateBranch: ApprovalBranchesUpdateTypeInput = {
                    id: branches.id,
                    rowTimestamp: branches._rowTimestamp,
                    approvalBranchPatch: {
                      ...constructBranchData(branches),
                    },
                  };
                  updateApprovalBranches.push(updateBranch);
                } else {
                  const tempObj: ApprovalTreeBranchesTypeInput = {
                    approvalTreeId: item.id,
                    approvalBranches: [
                      {
                        ...constructBranchData(branches),
                      },
                    ],
                  };
                  treeBranches.push(tempObj);
                }
            });
        }
      });

      const { errors } = await updateApproval({
        variables: {
          input: {
            id: approvalDetailsData?.approval?.id!,
            rowTimestamp: approvalDetailsData?.approval?._rowTimestamp!,
            approvalBranchesDelete: toBeDeletedApprovalBranch,
            approvalBranchesUpdate: updateApprovalBranches,
            approvalConditionsCreate: createApprovalConditions,
            approvalConditionsDelete: toBeDeletedRequesterConditions,
            approvalConditionsUpdate: updateApprovalConditions,
            approvalPatch: {
              approvalTypeId: values.approvalTypeId,
              description: values.description,
              isAddApproversEligible: values.isAddApproversEligible,
              isDelayedApproverAllowed: values.isDelayedApproverAllowed,
              name: values.name,
              priorityNumber: Number(values.priorityNumber!.toString()),
              approvalPhaseTypeId: values.approvalPhaseTypeId!,
            },
            approvalTreeBranches: treeBranches,
            approvalTreesDelete: toBeDeletedRequesterTree,
            approvalTreesUpdate: treeUpdate,
          },
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery<Approval, ApprovalVariables>({
            query: GET_APPROVAL_DETAILS,
            variables: {
              id: approvalDetailsData?.approval?.id!,
            },
          });
          const updatedData: Approval = {
            approval: {
              ...cacheData?.approval!,
              ...data?.approvalUpdate?.approval!,
            },
          };
          cache.writeQuery<Approval, ApprovalVariables>({
            query: GET_APPROVAL_DETAILS,
            variables: {
              id: approvalDetailsData?.approval?.id!,
            },
            data: updatedData!,
          });
        },
      });
      if (!errors) {
        addToast('Approval setup updated successfully.', {
          appearance: 'success',
        });
      } else {
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
      }
    }
  };

  const isLoading =
    approvalSetupApprovalCreateLoading ||
    approvalSetupApprovalRevokeLoading ||
    addApprovalLoading ||
    updateApprovalLoading;

  const onAmend = async () => {
    toggleConfirmAmendDialog();
    const { errors } = await approvalSetupApprovalRevoke({
      variables: {
        input: {
          entityId: approvalDetailsData?.approval?.id!,
          rowTimestamp: approvalDetailsData?.approval?._rowTimestamp!,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_APPROVAL_DETAILS,
          variables: {
            id: approvalDetailsData?.approval?.id!,
          },
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else
      addToast('Approval amended successfully', {
        appearance: 'success',
      });
  };

  const onProcess = async () => {
    toggleConfirmDialog();
    const { id, _rowTimestamp } = { ...approvalDetailsData?.approval };

    const { errors } = await approvalSetupApprovalCreate({
      variables: {
        input: {
          entityApproval: [
            {
              entityId: id!,
              rowTimestamp: _rowTimestamp!,
              comments: requestComment,
              requiredDate,
            },
          ],
          urgencyLevel: urgencyLevel,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_APPROVAL_DETAILS,
          variables: {
            id: id!,
          },
        },
      ],
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      setRequestComment('');
      setRequiredDate(undefined);
      addToast('Request sent for approval', {
        appearance: 'success',
      });
    }
  };

  return (
    <Formik<ApprovalValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, dirty, resetForm, errors }) => {
        return (
          <Form>
            <DraggablePanel
              {...PanelCommonProps}
              isOpen
              initialWidth={1200}
              minWidth={1200}
              isBlocking={isNew}
              onRenderFooter={() => (
                <FooterActionBar
                  isLoading={isLoading}
                  children={
                    !isNew && (
                      <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <ApprovalFlowModal entityId={approvalId!} />
                        {approvalDetailsData?.approval
                          ?._isStagedApprovalRequest && (
                          <PrimaryButton
                            className={styles.diabledButton}
                            text="Request Approval"
                            onClick={toggleConfirmDialog}
                          />
                        )}
                        {approvalDetailsData?.approval
                          ?._isApprovalRevocable && (
                          <PrimaryButton
                            className={styles.diabledButton}
                            text="Amend Approval"
                            onClick={toggleConfirmAmendDialog}
                          />
                        )}
                      </Stack>
                    )
                  }
                  createNewText="New Approval"
                  addNewForm={() => {
                    history.replace('/account-management/approvals/approval');
                  }}
                  disabled={{
                    save: !dirty || Object.keys(errors).length > 0,
                    cancel: !dirty,
                  }}
                  isSubmitting={isSubmitting}
                  isCreate={!approvalId}
                  onCancel={() => {
                    history.replace('/account-management/approvals');
                  }}
                  onSaveAnother={async () => {
                    saveAnother();
                    await submitForm();
                  }}
                  onSave={async () => {
                    dontSaveAnonther();
                    await submitForm();
                  }}
                />
              )}
              onRenderHeader={() => _onRenderHeader(dirty, isSubmitting)}
              isLightDismiss
            >
              {!isNew && approvalDetailsLoading ? (
                <Stack
                  className={styles.shimmerViewMainContainer}
                  tokens={{ childrenGap: 20 }}
                >
                  <Stack
                    className={styles.headerContainer}
                    tokens={{ childrenGap: 10 }}
                  >
                    <ShimmerView />
                  </Stack>
                </Stack>
              ) : (
                <>
                  {!isNew && approvalDetailsData?.approval?.id && (
                    <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
                      <Stack
                        className={styles.headerContainer}
                        tokens={{ childrenGap: 10 }}
                      >
                        <Stack>
                          <ActionsMenu approvalDetails={approvalDetailsData!} />
                        </Stack>
                      </Stack>
                      <Separator />
                    </Stack>
                  )}
                  <BasicForm
                    approvalDetails={approvalDetailsData}
                    isNew={isNew}
                    data={approvalTypeData!}
                  />
                  <Separator />

                  {approvalTypeData && (
                    <>
                      <RequestorConditionsView
                        aggregate={
                          approvalDetailsData?.approval
                            ?.approvalSelectionConditions.totalCount || null
                        }
                        disableCondition={!isNew ? approvalDetails : undefined}
                        deleteRow={(
                          id: string,
                          _rowTimestamp: string | null
                        ) => {
                          const tempObj: EntityDeleteInput = {
                            id: id,
                            rowTimestamp: _rowTimestamp!,
                          };
                          toBeDeletedRequesterConditions.push(tempObj);
                        }}
                        approvalTypeData={approvalTypeData}
                        isNew={isNew}
                        isUpdateble={
                          isNew
                            ? false
                            : !approvalDetailsData?.approval?._isUpdatable
                        }
                      />
                    </>
                  )}
                  <Separator />

                  <ApprovalLevels
                    deleteRow={(id: string, _rowTimestamp: string | null) => {
                      const tempObj: EntityDeleteInput = {
                        id: id,
                        rowTimestamp: _rowTimestamp!,
                      };
                      toBeDeletedRequesterTree.push(tempObj);
                    }}
                    deleteBranchRow={(id, _rowTimestamp) => {
                      const tempObj: EntityDeleteInput = {
                        id: id,
                        rowTimestamp: _rowTimestamp!,
                      };
                      toBeDeletedApprovalBranch.push(tempObj);
                    }}
                    approvalTypeData={approvalTypeData}
                    isUpdateble={
                      isNew
                        ? false
                        : !approvalDetailsData?.approval?._isUpdatable
                    }
                  />

                  {approvalDetailsData?.approval?.approvalHistoriesByEntityId &&
                    approvalDetailsData?.approval?.approvalHistoriesByEntityId
                      .nodes.length! > 0 &&
                    !isNew && (
                      <Stack>
                        <Separator />
                        <Stack
                          className={styles.approvalHistoryContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text variant="xLarge">Approval History</Text>
                          <Text
                            variant="medium"
                            className={styles.requestedByText}
                          >
                            Requested By:
                          </Text>
                          <ApprovalHistory
                            data={
                              approvalDetailsData.approval
                                .approvalHistoriesByEntityId
                            }
                          />
                        </Stack>
                      </Stack>
                    )}
                  {approvalDetailsData?.approval
                    ?.approvalHistorySignaturesByEntityId.nodes.length! > 0 &&
                    !isNew && (
                      <Stack>
                        <Separator />
                        <Stack
                          className={styles.tagsContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text variant="xLarge">Signatures</Text>
                          <SignatureView signatureData={signatureData!} />
                        </Stack>
                      </Stack>
                    )}
                </>
              )}
              <Prompt
                when={dirty && !isSubmitting}
                message="Are you sure you want to leave your changes unsaved?"
              />
              <ConfirmDialog
                hidden={hideConfirmAmendDialog}
                title={CONFIRM_AMEND_DIALOG_TITLE}
                subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
                onDismiss={toggleConfirmAmendDialog}
                onConfirm={onAmend}
              />
              <ConfirmDialog
                hidden={hideConfirmRequestDialog}
                title={CONFIRM_REQUEST_DIALOG_TITLE}
                subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
                onDismiss={toggleConfirmDialog}
                minWidth={500}
                onConfirm={onProcess}
              >
                <Stack tokens={{ childrenGap: 10 }}>
                  <TextField
                    className={styles.textFieldStyle}
                    multiline
                    rows={3}
                    value={requestComment}
                    placeholder="Please write your comment here (optional)"
                    resizable={false}
                    onChange={(_event, value) => setRequestComment(value || '')}
                  />
                  <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                    <DatePicker
                      minDate={new Date()}
                      firstDayOfWeek={DayOfWeek.Sunday}
                      placeholder="Finalized by date (optional)"
                      ariaLabel="Date"
                      formatDate={onFormatDate}
                      showWeekNumbers
                      firstWeekOfYear={1}
                      showMonthPickerAsOverlay
                      showGoToToday={false}
                      onSelectDate={(date) =>
                        setRequiredDate(dateFormat(date!.toString()))
                      }
                    />
                  </TooltipHost>
                  <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                    <UrgencyToggle
                      onToggle={(data) => {
                        setUrgencyLevel(data ? 0 : 1);
                      }}
                    />
                  </Stack>
                </Stack>
              </ConfirmDialog>
            </DraggablePanel>
          </Form>
        );
      }}
    </Formik>
  );
};
