export const getFormattedAmountValue = (value: string | null) => {
  const formattedValue = value
    ? Number(value).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    : '';

  return formattedValue;
};
