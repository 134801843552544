import { IconButton, TooltipHost } from '@fluentui/react'
import React, { useState } from 'react'
import { CorporateChartAccountsModal } from './CorporateChartAccountsModal';

export interface CreateCorporateChartAccountsProps {
  chartOfAccountId: string | null;
}

export const CreateCorporateChartAccounts: React.FC<CreateCorporateChartAccountsProps> = ({
  chartOfAccountId,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <TooltipHost content="Create Chart of Accounts">
        <IconButton
          onClick={() => setIsOpen(true)}
          iconProps={{ iconName: 'Add' }}
        />
      </TooltipHost>
      <CorporateChartAccountsModal
        chartOfAccountId={chartOfAccountId}
        visible={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}
