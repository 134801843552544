import { Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { useCommonStyles } from 'common/styles';
import React from 'react';
import { useStyles } from './index.styles';

interface ColumnHeadingsProps {
  heading: string;
  width: number;
}

export const Header = () => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const columnHeadings: ColumnHeadingsProps[] = [
    { heading: "Description", width: 300 },
    { heading: "Currency", width: 190 },
    { heading: "Contractual", width: 100 },
    { heading: "Fare Class", width: 200 },
    { heading: "Action", width: 260 },
  ];

  return (
    <Stack horizontal >
      {
        columnHeadings.map(
          (item, index) => {
            return (
              <Stack
                horizontal
                className={
                  clsx({
                    [styles.root]: true,
                    [styles.linkLightHover]: true,
                    [styles.descriptionCell]: item.heading === "Description"
                  })
                }
                horizontalAlign="space-between"
                verticalAlign="center"
                key={index}
                style={{ width: item.width }}
              >
                <Stack
                  verticalAlign="center"
                  horizontal
                  tokens={{ childrenGap: 20 }}
                >
                  <Text
                    nowrap
                    block
                    className={commonStyles.semibold}
                    variant="medium"
                  >
                    {item.heading}
                  </Text>
                </Stack>
              </Stack>
            )
          }
        )
      }
    </Stack >
  )
}
