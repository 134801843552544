import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  columnHeight: {
    height: 20,
  },
  currency: {
    display: 'block',
    paddingLeft: 20,
  },
  amount: {
    textAlign: 'right',
    display: 'block',
    paddingRight: 25,
  },
  iconButtonColor: {
    color: theme.palette.themePrimary,
  },
  container: { 
    width: 380,
    height: 250 
  },
  header: {
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  }
}));
