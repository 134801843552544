import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  actionsWrapperStyle: {
    height: 50,
    padding: '0px 25px',
    backgroundColor: theme.palette.neutralLight,
  },
  filtersWrapperStyle: {
    marginLeft: '20px !important',
  },
  signingAccountingToggle: {
    marginBottom: 0,
  },
}));
