import React from 'react';
import { useField } from 'formik';
import {
  CountryRegionSelector,
  CountryRegionSelectorProps,
} from '../CountryRegionFields';

interface CountryRegionFormFieldProps {
  countryName: string;
  regionName: string;
}

export const FormikCountryRegion: React.FC<
  CountryRegionFormFieldProps & CountryRegionSelectorProps
> = ({
  countryName,
  regionName,
  countries,
  CountryAutocompleteProps,
  StateRegionAutocompleteProps,
  ...props
}) => {
  const [countryField, countryMeta, countryHelpers] = useField<
    number | undefined
  >(countryName);

  const [regionField, regionMeta, regionHelpers] = useField<number | undefined>(
    regionName
  );

  const { value: countryValue } = countryField;
  const { value: regionValue } = regionField;

  return (
    <CountryRegionSelector
      countries={countries}
      CountryAutocompleteProps={{
        value: countryValue || null,
        onChange: (_event, value) => {
          if (value?.key) {
            let numberKey = parseInt(value?.key.toString());
            countryHelpers.setValue(numberKey);
          } else countryHelpers.setValue(-1);
          // clear region value if no country is selected
          if (!value) regionHelpers.setValue(undefined);
        },
        error: !props.disabled && countryMeta.error !== undefined,
        helperText: !props.disabled && countryMeta.error,
        errorMessage: countryMeta.error || '',
        ...CountryAutocompleteProps,
      }}
      StateRegionAutocompleteProps={{
        value: regionValue || null,
        onChange: (_event, value) => {
          if (value?.key) {
            let numberKey = parseInt(value?.key.toString());
            regionHelpers.setValue(numberKey);
          } else regionHelpers.setValue(-1);
        },
        error: !props.disabled && regionMeta.error !== undefined,
        helperText: !props.disabled && regionMeta.error,
        errorMessage: regionMeta.error || '',

        ...StateRegionAutocompleteProps,
      }}
      {...props}
    />
  );
};
