import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '70vw',
    height: '80vh',
  },
  modalContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  stickyInsideStack: {
    padding: 25,
    paddingBottom: 15,
    backgroundColor: theme.palette.neutralLight,
  },

  headerContainer: {
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.10)`,
    backgroundColor: theme.palette.white,
  },
  shareIcon: {
    color: theme.palette.neutralSecondary,
    fontSize: 20,
  },
}));
