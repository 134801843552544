import { Stack } from '@fluentui/react';
import clsx from 'clsx';
import { NoDataView } from 'common/components/DataPlaceholders';
import React from 'react';
import {
  TravelAuthorization_travelAuthorization,
  TravelAuthorization_travelAuthorization_trips_nodes,
} from 'travelAuthorization/TravelPlan/view/__generated__/TravelAuthorization';
import { setTripsModalState } from '..';
import { CellRender } from './CellRender';
import { DeleteItem } from './DeleteItem';
import { Footer } from './Footer';
import { Header } from './Header';
import { GridColumnProps, gridColumns } from './columns.data';
import { useStyles } from './index.styles';
import { getTotal } from './utils';
import { getOverageInfo } from 'common/utils';
export type TripItemProps = TravelAuthorization_travelAuthorization_trips_nodes;
interface TripListProps {
  inputsDisabled: boolean;
  travelAuthorizationData:
  | TravelAuthorization_travelAuthorization
  | null
  | undefined;
  isDirty: boolean;
}
export const TripList: React.FC<TripListProps> = ({
  inputsDisabled,
  travelAuthorizationData,
  isDirty,
}) => {
  const styles = useStyles();
  const columnLength = gridColumns.length;

  const { nodes } = { ...travelAuthorizationData?.trips };
  const transformedData = nodes?.map(
    (item) =>
    ({
      ...item,
    } as TripItemProps)
  );
  const lengthData = transformedData?.length || 0;
  const aggTotal = getTotal(transformedData, 'controlTotalAmount');
  const airFareTotal = getTotal(transformedData, 'airfareAmount');
  const hotelAccommodationTotal = getTotal(
    transformedData,
    'accommodationAmount'
  );
  const vehicleRentalTotal = getTotal(transformedData, 'vehicleRentalAmount');
  const transportationTotal = getTotal(transformedData, 'transportationAmount');
  const trainTotal = getTotal(transformedData, 'trainAmount');
  const otherTotal = getTotal(transformedData, 'incidentalAmount');
  const totalsArray = [
    aggTotal,
    airFareTotal,
    hotelAccommodationTotal,
    vehicleRentalTotal,
    transportationTotal,
    otherTotal,
    trainTotal,
  ];
  const showFooterRow = totalsArray.some((total) => total! > 0);
  return (
    <Stack tokens={{ padding: '10px 15px' }}>
      <Stack horizontal>
        {gridColumns.map((item, index) => (
          <Header item={item} key={index} />
        ))}
        {/* Buffer for aligning with the IconButton */}
        <Stack style={{ width: 30 }} />
      </Stack>
      {!lengthData && (
        <Stack tokens={{ padding: '20px 5px' }}>
          <NoDataView title="No trips added yet" show={!lengthData} />
        </Stack>
      )}

      {transformedData && (
        <Stack>
          <Stack>
            {transformedData?.map((item: TripItemProps, index: number) => {
              const { controlTotalAmount, budgetAmount } = { ...item };
              const toolTipContent = getOverageInfo(
                controlTotalAmount,
                budgetAmount
              );
              return (
                <Stack horizontal key={index} tokens={{ childrenGap: 0 }}>
                  {gridColumns.map((key: GridColumnProps, keyIndex: number) => {
                    return (
                      <Stack
                        key={keyIndex}
                        className={clsx(
                          styles.cellItemInitial,
                          index === lengthData - 1 ? styles.cellBottom : '',
                          keyIndex === columnLength - 1 ? styles.cellEnd : ''
                        )}
                        style={{
                          alignItems: key.alignItems,
                          padding: '5px 8px',
                          maxWidth: key.maxWidth,
                          minWidth: key.minWidth,
                          flex: key.flexProportion,
                        }}
                      >
                        <CellRender
                          column={key}
                          index={index}
                          isDirty={isDirty}
                          fieldContent={item[key.key as keyof TripItemProps]}
                          onItemClick={() => {
                            if (!isDirty)
                              setTripsModalState({
                                trip: item,
                                isModalOpen: true,
                                isNew: false,
                              });
                          }}
                          item={item}
                          toolTipContent={toolTipContent}
                        />
                      </Stack>
                    );
                  })}
                  <DeleteItem
                    item={item}
                    inputsDisabled={inputsDisabled || isDirty}
                    travelAuthorizationData={travelAuthorizationData}
                  />
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
      {showFooterRow && <Footer data={transformedData} />}
    </Stack>
  );
};
