import {
  ITextFieldProps,
  IconButton,
  Stack,
  TextField,
  makeStyles,
} from '@fluentui/react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.black,
    alignSelf: 'end',
    margin: '0px 4px',
  },
}));

interface ETicketFieldProps {
  name: string;
  rules?: UseControllerProps['rules'];
  defaultValue?: any;
}

export const ETicketField: React.FC<ETicketFieldProps & ITextFieldProps> = ({
  name,
  rules,
  disabled,
  ...props
}) => {
  const styles = useStyles();
  const [isDisabled, setIsDisabled] = useState(false);
  const [placeholder, setPlaceholder] = useState<string>();
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({ name });

  useEffect(() => {
    if (!value) {
      setIsDisabled(true);
      setPlaceholder('Included Above');
    } else {
      setIsDisabled(false);
      setPlaceholder(props.placeholder);
    }
  }, [value, props.placeholder]);

  return (
    <Stack
      horizontal
      verticalAlign="baseline"
      tokens={{
        childrenGap: 4,
      }}
    >
      <TextField
        value={value || ''}
        onChange={(_event, value) => {
          if (value) {
            if (value.replace(/\s/g, '').length) {
              onChange(value);
            }
          } else {
            onChange(null);
          }
        }} // send value to hook form
        onBlur={onBlur} // notify when input is touched
        errorMessage={error?.message?.toString()}
        componentRef={ref}
        {...props}
        disabled={disabled || isDisabled}
        placeholder={placeholder}
      />
      {!disabled && isDisabled && (
        <IconButton
          type="button"
          onClick={() => {
            setIsDisabled(false);
            setPlaceholder(props.placeholder);
            onChange(null);
          }}
          iconProps={{ iconName: 'cancel' }}
          className={styles.icon}
          ariaLabel="Close"
        />
      )}
    </Stack>
  );
};
