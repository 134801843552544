import {
  ActionButton,
  Callout,
  DirectionalHint,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  Checkbox,
  IDropdownOption,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useStyles } from './index.styles';
import { DropdownFilter, FilterArrayType } from 'common/components/Filters';
import { TextSearchFilter } from 'common/components/Filters/TextSearchFilter';
import { getUpdatedFilterArray } from 'common/components/Filters/utils';
import { AttachDocumentFiltersProps } from '..';
import { DocumentPoolFiles } from 'common/graphql/__generated__/DocumentPoolFiles';

type FiltersCalloutProps = AttachDocumentFiltersProps & {
  onDismiss?: () => void;
  documentPools: DocumentPoolFiles | undefined;
  calloutId: string;
};
export const FiltersCallout: React.FC<FiltersCalloutProps> = ({
  onDismiss,
  calloutId,
  onFilterChange,
  filterOptions,
  documentPools,
}) => {
  const { filterTypes: filters, startsWith: startsWithDefault } = {
    ...filterOptions,
  };
  const styles = useStyles();
  const [indexName, setIndexName] = useState<string | undefined>();
  const [filterTypes, setFilterTypes] = useState<FilterArrayType[]>([]);
  const [documentPoolId, setDocumentPoolId] = useState<string | undefined>();
  const [indexRefNumber, setIndexRefNumber] = useState<string | undefined>();
  const [comment, setComment] = useState<string | undefined>();

  const [fileReference, setFileReference] = useState<string | undefined>();
  const [contents, setContents] = useState<string | undefined>();
  const [startsWith, setStartsWith] = useState<boolean | undefined>();

  const documentPoolOptions =
    documentPools?.documentPools?.nodes.map(
      (item) =>
        ({
          key: item.id?.toString(),
          text: `${item.name}`,
        } as IDropdownOption)
    ) || [];
  const onFilterApply = () => {
    onFilterChange({ filterTypes, startsWith: startsWith! });
    onDismiss?.();
  };

  const onCancel = () => {
    onDismiss?.();
  };

  useEffect(() => {
    if (filters) {
      setFilterTypes(filters);
      setStartsWith(startsWithDefault);
      const indexNamePos = filters.findIndex(
        (item) => item.filterKey === 'indexName'
      );

      const commentPos = filters.findIndex(
        (item) => item.filterKey === 'comment'
      );

      const documentPoolNamePos = filters.findIndex(
        (item) => item.filterKey === '_documentPoolId'
      );

      const indexRefNumberPos = filters.findIndex(
        (item) => item.filterKey === 'indexReferenceNumber'
      );

      const fileReferencePos = filters.findIndex(
        (item) => item.filterKey === 'fileReference'
      );

      const contentsPos = filters.findIndex(
        (item) => item.filterKey === '_documentContents'
      );

      if (fileReferencePos !== -1)
        setFileReference(filters[fileReferencePos].value.toString());

      if (commentPos !== -1) setComment(filters[commentPos].value.toString());

      if (contentsPos !== -1)
        setContents(filters[contentsPos].value.toString());

      if (indexRefNumberPos !== -1)
        setIndexRefNumber(filters[indexRefNumberPos].value.toString());

      if (documentPoolNamePos !== -1)
        setDocumentPoolId(filters[documentPoolNamePos].value.toString());

      if (indexNamePos !== -1)
        setIndexName(filters[indexNamePos].value.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOptionChange = (filterdata: FilterArrayType) => {
    const availableFilters = [...filterTypes];
    let newFilters = getUpdatedFilterArray(filterdata, availableFilters);
    newFilters = newFilters.filter((element) => element.value);
    setFilterTypes(newFilters);
  };

  const onReset = () => {
    setDocumentPoolId(undefined);
    setContents(undefined);
    setFileReference(undefined);
    setIndexRefNumber(undefined);
    setIndexName(undefined);
    setStartsWith(true);
    setFilterTypes([]);
  };

  return (
    <Callout
      className={styles.callout}
      gapSpace={0}
      target={`#${calloutId}`}
      directionalHint={DirectionalHint.topLeftEdge}
      onDismiss={onDismiss}
      setInitialFocus
    >
      <Stack className={styles.container}>
        <Text variant={'xLarge'}>Filters</Text>

        <Stack tokens={{ childrenGap: 10, padding: '20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Name"
                defaultFilter
                value={indexName}
                filterKey={'indexName'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setIndexName(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Number"
                defaultFilter
                value={indexRefNumber}
                filterKey={'indexReferenceNumber'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setIndexRefNumber(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Contents"
                defaultFilter
                value={contents}
                filterKey={'_documentContents'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setContents(value?.value.toString());
                }}
              />
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Document"
                defaultFilter
                value={fileReference}
                filterKey={'fileReference'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setFileReference(value?.value.toString());
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
              <TextSearchFilter
                placeholder="Comments"
                defaultFilter
                value={comment}
                filterKey={'comment'}
                onOptionChange={(value) => {
                  onOptionChange(value);
                  setComment(value?.value.toString());
                }}
              />
            </Stack.Item>

            <DropdownFilter
              placeholder="Folder"
              defaultFilter
              controlled
              styles={{ dropdown: { width: 220 } }}
              selectedKey={documentPoolId}
              options={documentPoolOptions}
              filterKey={'_documentPoolId'}
              onOptionChange={(value) => {
                onOptionChange(value);
                setDocumentPoolId(value?.value.toString());
              }}
            />
          </Stack>
        </Stack>
        {/* Footer Component */}
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center">
            <ActionButton
              text="Reset"
              onClick={onReset}
              iconProps={{ iconName: 'RevToggleKey' }}
            />

            <Checkbox
              label="Starts with"
              checked={startsWith}
              onChange={(_, value) => {
                setStartsWith(value);
              }}
            />
          </Stack>
          <Stack tokens={{ padding: '10px 0px', childrenGap: 20 }} horizontal>
            <PrimaryButton text="Apply" onClick={onFilterApply} />
            <DefaultButton text="Cancel" onClick={onCancel} />
          </Stack>
        </Stack>
      </Stack>
    </Callout>
  );
};
