import { IButtonProps, IconButton, useTheme } from '@fluentui/react';
import React from 'react';

export const RemoveRowButton: React.FC<Omit<IButtonProps, 'iconProps'>> = (
  props
) => {
  const theme = useTheme();

  return (
    <IconButton
      {...props}
      iconProps={{ iconName: 'Blocked2Solid' }}
      styles={{
        icon: {
          color: theme.palette.red,
        },
        rootDisabled: {
          background: 'unset',
        },
        iconDisabled: {
          color: theme.palette.neutralQuaternary,
        },
      }}
    />
  );
};
