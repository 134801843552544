import { useLazyQuery } from '@apollo/client';
import {
  ITextFieldProps,
  ITextFieldStyles,
  Spinner,
  Stack,
  TextField,
  useTheme
} from '@fluentui/react';
import {
  LookupNameSearch_lookupNameSearch_nodes
} from 'common/components/LookUp/__generated__/LookupNameSearch';
import { LookupSupplierSearch, LookupSupplierSearchVariables } from 'common/graphql/__generated__/LookupSupplierSearch';
import { RequiredNameProps } from 'common/types/utility';
import { useField } from 'formik';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import { SupplierLookUpCallout } from 'common/components/SupplierLookUpCallout';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

const LOOKUP = loader('../../../../../../../common/graphql/LookupSupplierSearch.graphql');

type AutoCompleteProps = RequiredNameProps<ITextFieldProps> & {
  lookUpCalloutWidth?: number;
  lookupData?: LookupNameSearch_lookupNameSearch_nodes | null;
  clearTextField?: boolean | null;
};

export const CompanyNameAutoComplete: FC<AutoCompleteProps> = ({
  lookUpCalloutWidth,
  lookupData,
  clearTextField,
  ...props
}) => {
  const theme = useTheme();
  const textfiledStyles: Partial<ITextFieldStyles> = {
    suffix: {
      backgroundColor: props.disabled
        ? theme.palette.neutralLighter
        : theme.palette.white,
    },
  };
  const [field, meta, helpers] = useField<string | undefined | null>(
    props.name
  );
  const renderRef = useRef(false);
  const { value, ...rest } = field;
  const [calloutVisible, setCalloutVisible] = useState(true);

  const [fetchLookUp, { data, loading }] = useLazyQuery<
    LookupSupplierSearch,
    LookupSupplierSearchVariables
  >(LOOKUP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { id, disabled, ...textfieldProps } = props;

  const lookupCallBack = useCallback(
    (value) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
    },
    [fetchLookUp]
  );

  useEffect(() => {
    if (value && value?.length! > 0) {
      if (renderRef.current) {
        setCalloutVisible(true);
        lookupCallBack(value);
      } else {
        renderRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupCallBack]);

  const callbackLookUp = useCallback(
    _.debounce((value, queryCalled) => {
      fetchLookUp({
        variables: {
          lookupName: value,
        },
      });
      queryCalled();
    }, 350),
    []
  );

  const renderSpinner = () => {
    return (
      <>
        {
          loading && <Spinner />
        }
      </>
    );
  };

  return (
    <Stack>
      <TextField
        {...rest}
        {...textfieldProps}
        id={id}
        autoComplete={'off'}
        errorMessage={meta.error}
        value={value || ''}
        onChange={(_event, value) => {
          if (value) {
            helpers.setValue(value);
            callbackLookUp(value, () => {
              if (calloutVisible === false) setCalloutVisible(true);
            });
          } else {
            helpers.setValue(null);
            setCalloutVisible(false);
          }
        }}
        onRenderSuffix={renderSpinner}
        styles={textfiledStyles}
        disabled={disabled ? true : false}
      />
      <Stack style={{ position: 'absolute', top: 0, left: 0 }}>
        {calloutVisible && !loading &&
          <SupplierLookUpCallout
            lookUpCalloutWidth={400}
            onDismiss={() => {
              setCalloutVisible(false)
            }}
            onSelect={() => { }}
            targetID={`#${id!}`}
            data={data?.lookupSupplierSearch?.nodes!}
          />
        }
      </Stack>
    </Stack>
  );
};
