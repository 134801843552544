import React from 'react';
import { DocumentPackageEmailModalProps } from '../..';
import { FontSizes, Label, Separator, Stack, Text, makeStyles } from '@fluentui/react';
import { getStatusColor, toTitleCase } from '../../utils';
import { RecipientName } from './RecipientName';
import { IconState } from 'common/types/globalTypes';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 80
  },
  statusType: {
    fontSize: FontSizes.size18,
  },
  lastRow: {
    paddingBottom: 16
  }
}));

type EmailListProps = Pick<DocumentPackageEmailModalProps, "emailDocumentsByEntityId">

export const EmailList: React.FC<EmailListProps> = ({
  emailDocumentsByEntityId,
}) => {
  const styles = useStyles();

  return (
    <Stack className={styles.container}>
      {
        !!emailDocumentsByEntityId.length &&
        emailDocumentsByEntityId.map((email, index) => {
          const { _sendIconState, emailRecipients, sendStatusType } = { ...email };
          const isLastItem = emailDocumentsByEntityId.length - 1 === index;
          return (
            <Stack
              key={index}
            >
              <Stack
                tokens={{ childrenGap: 10 }}
              >
                <Stack
                  tokens={{
                    padding: "0px 20px 0px",
                    childrenGap: 10,
                  }}
                  className={isLastItem ? styles.lastRow : undefined}
                >
                  <Stack wrap>
                    <Label>Recipients</Label>
                  </Stack>
                  <Stack
                    horizontal
                    wrap
                    tokens={{ childrenGap: 10 }}
                  >
                    {
                      emailRecipients.nodes.map(
                        (ele) =>
                          ele._recipientDescription &&
                          <RecipientName key={ele.id} name={toTitleCase(ele._recipientDescription)} />
                      )
                    }
                  </Stack>
                  <Stack tokens={{ padding: "14px 0px 0px 0px" }}>
                    <Label>Status</Label>
                    {_sendIconState &&
                      <Text
                        style={{ color: getStatusColor(_sendIconState) }}
                        className={styles.statusType}
                      >
                        {
                          _sendIconState === IconState.SENT ?
                            toTitleCase(sendStatusType || '') :
                            toTitleCase(_sendIconState)
                        }
                      </Text>}
                  </Stack>
                </Stack>
              </Stack>
              {!isLastItem && <Separator />}
            </Stack>
          )
        })
      }
    </Stack>
  )
}
