import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  disabledStyle: {
    color: `${theme.palette.neutralTertiary}${theme.isInverted ? '55' : ''}`,
  },
  
  
  
}));
