import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '82vw',
    height: '70vh',
  },
  containerExpanded: {
    width: '82vw',
    height: '90vh',
  },
  modalContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  stickyInsideStack: {
    padding: 25,
    paddingBottom: 15,
    marginBottom: 20,
    backgroundColor: theme.palette.neutralLight,
  },
  row: {
    minWidth: '100% !important',
  },
  stickyInsideBottomStack: {
    padding: 25,
    paddingBottom: 15,
    justifyContent: 'flex-end',
  },
  amountStack: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
  },
  headerContainer: {
    boxShadow: `-7px 2px 13px rgba(0, 0, 0, 0.10)`,
    backgroundColor: theme.palette.white,
  },
  shareIcon: {
    color: theme.palette.neutralSecondary,
    fontSize: 20,
  },
  onrenderColumnStack: {
    height: 25,
  },
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
}));
