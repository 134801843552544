import {
  ContextualMenu,
  IDragOptions,
  Modal,
  Separator,
  Stack,
  makeStyles,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { FormProvider, useForm } from 'react-hook-form';
import { DepartmentGroupValues } from './types';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validations';
import { BasicForm } from './BasicForm';
import { GroupItems } from './GroupItems';
import { getDefaultValues, getDeletedItems, getNewGroupItems } from './utils';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  DepartmentGroupCreate,
  DepartmentGroupCreateVariables,
} from './__generated__/DepartmentGroupCreate';
import { useToasts } from 'react-toast-notifications';
import {
  DepartmentGroupInput,
  DepartmentGroupPatch,
  DepartmentGroupUpdateInput,
} from 'common/types/globalTypes';
import { DepartmentGroups_departmentGroups } from '../../list/__generated__/DepartmentGroups';
import { DepartmentGroupRow } from '../../list';
import { isEmpty } from 'lodash';
import {
  DepartmentGroupUpdate,
  DepartmentGroupUpdateVariables,
} from './__generated__/DepartmentGroupUpdate';
const DEPARTMENT_GROUP_CREATE = loader('./DepartmentGroupCreate.graphql');
const DEPARTMENT_GROUP_UPDATE = loader('./DepartmentGroupUpdate.graphql');
const DEPARTMENT_GROUP_FIELDS = loader('../../DepartmentGroupFields.graphql');
const DEPARTMENT_GROUP_FRAGMENT_NAME = 'DepartmentGroupFields';

const DragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div > div:first-child ',
};

const useStyles = makeStyles(() => ({
  container: {
    width: 800,
    maxHeight: 800,
  },
}));

interface DepartmentGroupsViewModalProps {
  isOpen: boolean;
  isEdit: boolean;
  departmentGroup?: DepartmentGroupRow;
  onClose: () => void;
}

export const DepartmentGroupsViewModal: React.FC<
  DepartmentGroupsViewModalProps
> = ({ isOpen, isEdit, departmentGroup, onClose }) => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const formMethods = useForm<DepartmentGroupValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const [createDepartmentGroup, { loading: createLoading }] = useMutation<
    DepartmentGroupCreate,
    DepartmentGroupCreateVariables
  >(DEPARTMENT_GROUP_CREATE, { errorPolicy: 'all' });

  const [updateDepartmentGroup, { loading: updateLoading }] = useMutation<
    DepartmentGroupUpdate,
    DepartmentGroupUpdateVariables
  >(DEPARTMENT_GROUP_UPDATE, { errorPolicy: 'all' });

  const { handleSubmit, reset, trigger } = { ...formMethods };

  const { id, _rowTimestamp } = { ...departmentGroup };

  const onHandleSubmit = async (values: DepartmentGroupValues) => {
    const { name, description, isTravelDepartmentGroup, departmentGroupItems } =
      { ...values };

    const departmentGroupPayload: DepartmentGroupInput = {
      name: name || '',
      description,
      isTravelDepartmentGroup,
    };

    if (isEdit) {
      const defaultValues = getDefaultValues({ isEdit, departmentGroup });
      const departmentGroupPatch: DepartmentGroupPatch = Object.entries(
        values
      ).reduce((res, [key, val]) => {
        if (val !== defaultValues[key as keyof DepartmentGroupValues]) {
          if (key === 'departmentGroupItems') return { ...res };
          else return { ...res, [key]: val };
        }
        return res;
      }, {});

      const newGroupItems = getNewGroupItems({
        departmentGroupItems: departmentGroup?.departmentGroupItems.nodes,
        values: departmentGroupItems || [],
      });
      const deletedGroupItems = getDeletedItems({
        departmentGroupItems: departmentGroup?.departmentGroupItems.nodes,
        values: departmentGroupItems || [],
      });

      const { errors } = await updateDepartmentGroup({
        variables: {
          input: {
            id,
            rowTimestamp: _rowTimestamp,
            departmentGroupPatch: !isEmpty(departmentGroupPatch)
              ? departmentGroupPatch
              : undefined,
            departmentGroupItemsCreate: newGroupItems,
            departmentGroupItemsDelete: deletedGroupItems,
          } as DepartmentGroupUpdateInput,
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Department group edited successfully.', {
          appearance: 'success',
        });
      }
    } else {
      const { errors } = await createDepartmentGroup({
        variables: {
          input: {
            departmentGroup: departmentGroupPayload,
            departmentGroupItems: departmentGroupItems?.map((ele) => ({
              departmentId: ele.departmentId || '',
            })),
          },
        },
        update: (cache, { data }) => {
          if (!!data?.departmentGroupCreate?.departmentGroup?.id) {
            cache.modify({
              fields: {
                departmentGroups(existing: DepartmentGroups_departmentGroups) {
                  if (data?.departmentGroupCreate?.departmentGroup) {
                    const newDepartmentGroupRef = cache.writeFragment({
                      data: data.departmentGroupCreate.departmentGroup,
                      fragment: DEPARTMENT_GROUP_FIELDS,
                      fragmentName: DEPARTMENT_GROUP_FRAGMENT_NAME,
                    });
                    return {
                      ...existing,
                      nodes: [newDepartmentGroupRef, ...existing.nodes],
                    };
                  }
                },
              },
            });
          }
        },
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        addToast('Department group created successfully.', {
          appearance: 'success',
        });
        onClose();
      }
    }
  };

  useEffect(() => {
    const defaultValues = getDefaultValues({ isEdit, departmentGroup });
    reset(defaultValues);
    trigger();
  }, [departmentGroup, isEdit, isOpen, reset, trigger]);

  const loading = createLoading || updateLoading;

  return (
    <Modal isOpen={isOpen} isBlocking dragOptions={DragOptions}>
      <Stack className={styles.container}>
        {/* START OF FORM PROVIDER */}
        <FormProvider {...formMethods}>
          {/* HEADER */}
          <Header isEdit={isEdit} onClose={onClose} />
          {/* FORM */}
          <BasicForm />
          <Separator />
          <GroupItems departmentGroup={departmentGroup} />
          <Separator />
          {/* FOOTER */}
          <Footer
            loading={loading}
            onSubmit={handleSubmit(onHandleSubmit)}
            onCancel={onClose}
          />
        </FormProvider>
        {/* END OF FORM PROVIDER */}
      </Stack>
    </Modal>
  );
};
