import React from 'react';
import { HeaderProps } from '..'
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { EntityType } from 'common/types/utility';


type DeleteWorkGroupProps = Omit<HeaderProps, 'dataLoading'>

export const DeleteWorkGroup: React.FC<DeleteWorkGroupProps> = ({
  isNew,
  corporateWorkgroupData,
  onWorkgroupDelete,
}) => {
  const {
    _isDeletable
  } = { ...corporateWorkgroupData?.corporateWorkgroup }

  return (
    <>
      <ActionMessageModal
        visible={!isNew}
        entityType={EntityType.WorkGroup}
        disabled={!_isDeletable}
        onConfirm={onWorkgroupDelete}
      />
    </>
  )
}
