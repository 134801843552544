import { makeStyles } from '@fluentui/react';
export const useStyles = makeStyles((theme) => ({
  contentColumnAlignRight: {
    display: 'block',
    textAlign: 'right',
  },
  statusType: {
    display: 'block',
    width: 100,
  },
  amountStack: {
    fontWeight: 'bold',
    marginLeft: 0,
    display: 'block',
  },
  logo: {
    fill: theme.palette.neutralPrimary,
  },
  onrenderColumnStack: {
    height: 25,
  },
  bg: {
    backgroundColor: 'red',
    color: 'blue',
    flex: 1,
  },
  currencyCell: {
    height: 25,
    alignItems: "center"
  },
  pivotContainer:{
    width: '12%'
  },
  paymentCyclesDropdownContainer:{
    width: '30%',
    paddingTop: 10,
  },
  paymentCyclesDropdown:{
    marginLeft: 60
  }
}));
